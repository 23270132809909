import React, { useEffect, useReducer, useState } from 'react'
import { Box, FormField, TextArea, TextInput } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ButtonGroup,
  CCSForm,
  Dropdown,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../components'
import { get, post } from '../../../utils/api-utils'
import { getApiErrorMessage } from '../../../utils/error-handling-utils'
import {
  validateCityField,
  validateCountryField,
  validateDescriptionField,
  validateOperationalModeField,
  validateStreetAddress2Field,
  validateStreetAddressField,
  validateWorspaceNameField,
  validateZipField
} from '../validation-utils'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'

const CreateCustomerWorkspace = ({ onSetOpen, onSuccess }) => {
  const { t } = useTranslation(['authn', 'common'])
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const initialState = {
    operational_mode: '',
    company_name: '',
    description: '',
    country_code: '',
    street_address: '',
    street_address_2: '',
    city: '',
    state_or_region: '',
    zip: ''
  }
  const [state, dispatch] = useReducer((currentState, action) => {
    switch (action.type) {
      case 'CHANGE_FIELD':
        return { ...currentState, [action.field]: action.value }
      default:
        return currentState
    }
  }, initialState)
  const [loader, setLoader] = useState(false)
  const [countries, setCountries] = useState([])
  const [listOfCountries, setListOfCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [formFieldError, setFormFieldError] = useState(initialState)
  const [validateOnChange, setValidateOnChnage] = useState(false)

  useEffect(() => {
    let params = { status: 'AVAILABLE' }
    get('/ui-doorway/ui/v1/status', {}, oidcUser.access_token)
      .then(
        (response) => {
          if (response?.data?.flags?.gts_compliance_flag) {
            params = {}
          }
        },
        (error) => {
          const backendErrorMessage = getApiErrorMessage(error, t)
          setErrorMessage(backendErrorMessage)
        }
      )
      .finally(() => {
        get('/geo/ui/v1/countries', params).then(
          (response) => {
            setListOfCountries(response.data.countries)
            setCountries(response.data.countries)
          },
          (error) => {
            const backendErrorMessage = getApiErrorMessage(error, t)
            setErrorMessage(backendErrorMessage)
          }
        )
      })
  }, [t, oidcUser.access_token])

  const handleCreateWorkspace = () => {
    setValidateOnChnage(true)
    const errorVar = { ...initialState }
    let isValid = true

    const isOperationModeNotValid = validateOperationalModeField(
      state?.operational_mode,
      t
    )
    if (isOperationModeNotValid) {
      isValid = false
      errorVar.operational_mode = isOperationModeNotValid
    }

    const isWorkspaceNameNotValid = validateWorspaceNameField(
      state?.company_name,
      t
    )
    if (isWorkspaceNameNotValid) {
      isValid = false
      errorVar.company_name = isWorkspaceNameNotValid
    }

    const isDescriptionNotValid = validateDescriptionField(
      state?.description,
      t
    )
    if (isDescriptionNotValid) {
      isValid = false
      errorVar.description = isDescriptionNotValid
    }

    const isCountryNotValid = validateCountryField(state?.country_code, t)
    if (isCountryNotValid) {
      isValid = false
      errorVar.country_code = isCountryNotValid
    }

    const isStreetAddressValid = validateStreetAddressField(
      state?.street_address,
      t
    )
    if (isStreetAddressValid) {
      isValid = false
      errorVar.street_address = isStreetAddressValid
    }

    const isStreetAddress2Valid = validateStreetAddress2Field(
      state?.street_address_2,
      t
    )
    if (isStreetAddress2Valid) {
      isValid = false
      errorVar.street_address_2 = isStreetAddress2Valid
    }

    const isCityValid = validateCityField(state?.city, t)
    if (isCityValid) {
      isValid = false
      errorVar.city = isCityValid
    }

    const isZipValid = validateZipField(state?.zip, t)
    if (isZipValid) {
      isValid = false
      errorVar.zip = isZipValid
    }

    setFormFieldError(errorVar)

    if (isValid) {
      setLoader(true)
      setErrorMessage('')
      const requestBody = {
        company_name: state?.company_name,
        description: state?.description,
        operational_mode: state?.operational_mode,
        address: {
          street_address: state?.street_address?.trim(),
          street_address_2: state?.street_address_2?.trim(),
          city: state?.city?.trim(),
          state_or_region: state?.state_or_region,
          zip: state?.zip?.trim(),
          country_code: state?.country_code
        }
      }
      post(
        '/accounts/ui/v1/managed-service/tenant',
        requestBody,
        oidcUser.access_token
      ).then(
        () => {
          setLoader(false)
          onSuccess()
        },
        (error) => {
          setLoader(false)
          let backendErrorMessage = ''
          if (error?.response?.status === 403)
            backendErrorMessage = t('common:error_messages.ERR_403')
          else backendErrorMessage = getApiErrorMessage(error, t)
          setErrorMessage(backendErrorMessage)
        }
      )
    }
  }

  return (
    <ModalDialog
      content={
        <Box margin={{ top: 'small' }} data-testid="content-container">
          <CCSForm errorMessage={errorMessage} testId="create-workspace-form">
            <Box gap="small">
              {/* Device or Subscription ownership */}
              <FormField
                name="operational_mode"
                label={t('customer_account.device_and_subscription_ownership')}
                help={t(
                  'customer_account.device_and_subscription_ownership_help'
                )}
                data-testid="ownership-form-field"
                required
                error={formFieldError?.operational_mode}
                disabled={loader}
              >
                <Dropdown
                  name="operational_mode"
                  testId="ownership-input"
                  noBorder
                  value={state.operational_mode}
                  options={[
                    {
                      label: t('customer_account.customer'),
                      value: 'CUSTOMER_OWNED_INVENTORY'
                    },
                    {
                      label: t('customer_account.msp'),
                      value: 'MSP_OWNED_INVENTORY'
                    }
                  ]}
                  onChangeDropdown={(value) => {
                    if (validateOnChange) {
                      setFormFieldError({
                        ...formFieldError,
                        operational_mode: validateOperationalModeField(value, t)
                      })
                    }
                    dispatch({
                      value,
                      field: 'operational_mode',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  disabled={loader}
                />
              </FormField>

              <Typography
                type="heading"
                level="3"
                testId="customer-workspace-detail-header"
              >
                {t('customer_account.enter_customer_workspace_details')}
              </Typography>

              {/* Workspace Name */}
              <FormField
                name="company_name"
                label={t('customer_account.customer_workspace_name')}
                help={t('customer_account.customer_workspace_name_help')}
                data-testid="company-name-form-field"
                required
                error={formFieldError?.company_name}
              >
                <TextInput
                  value={state.company_name}
                  name="company_name"
                  data-testid="company-name-form-input"
                  onChange={(event) => {
                    const inputValue = event?.target?.value
                    if (validateOnChange) {
                      setFormFieldError({
                        ...formFieldError,
                        company_name: validateWorspaceNameField(inputValue, t)
                      })
                    }
                    dispatch({
                      value: inputValue,
                      field: 'company_name',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  disabled={loader}
                />
              </FormField>

              {/* Description */}
              <FormField
                label={t('customer_account.description')}
                name="description"
                data-testid="description-form-field"
                required
                error={formFieldError?.description}
                disabled={loader}
              >
                <TextArea
                  fill
                  data-testid="description-input"
                  name="description"
                  value={state.description}
                  onChange={(event) => {
                    const inputValue = event?.target?.value
                    if (validateOnChange) {
                      setFormFieldError({
                        ...formFieldError,
                        description: validateDescriptionField(inputValue, t)
                      })
                    }
                    dispatch({
                      value: inputValue,
                      field: 'description',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  disabled={loader}
                />
              </FormField>

              {/* Country */}
              <FormField
                name="country_code"
                label="Customer country"
                data-testid="country-form-field"
                required
                error={formFieldError?.country_code}
                disabled={loader}
              >
                <Dropdown
                  name="country_code"
                  options={countries}
                  multiple={false}
                  labelKey="name"
                  value={state.country_code}
                  valueKey={{ key: 'code', reduce: true }}
                  searchPlaceholder={t('customer_account.country')}
                  emptySearchMessage={t(
                    'customer_account.country_empty_search_message'
                  )}
                  onSearch={(searchText) => {
                    const regexp = new RegExp(searchText, 'i')
                    setCountries(
                      listOfCountries.filter((o) => o?.name?.match(regexp))
                    )
                  }}
                  onChange={({ option }) => {
                    if (validateOnChange) {
                      setFormFieldError({
                        ...formFieldError,
                        country_code: validateCountryField(option?.code, t)
                      })
                    }
                    dispatch({
                      value: option?.code,
                      field: 'country_code',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  onClose={() => setCountries(listOfCountries)}
                  noBorder
                  testId="country-input"
                  disabled={loader}
                />
              </FormField>

              {/* Street Address */}
              <FormField
                label={t('customer_account.customer_address')}
                name="street_address"
                data-testid="street-address-form-field"
                required
                error={formFieldError?.street_address}
                disabled={loader}
              >
                <TextInput
                  value={state.street_address}
                  name="street_address"
                  placeholder={t('customer_account.street_address')}
                  data-testid="street-address-form-input"
                  onChange={(event) => {
                    const inputValue = event?.target?.value
                    if (validateOnChange) {
                      setFormFieldError({
                        ...formFieldError,
                        street_address: validateStreetAddressField(
                          inputValue,
                          t
                        )
                      })
                    }
                    dispatch({
                      value: inputValue,
                      field: 'street_address',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  disabled={loader}
                />
              </FormField>

              {/* Street Address 2 */}
              <FormField
                name="street_address_2"
                data-testid="street-address-2-form-field"
                error={formFieldError?.street_address_2}
                disabled={loader}
              >
                <TextInput
                  value={state.street_address_2}
                  name="street_address_2"
                  data-testid="street-address-2-form-input"
                  placeholder={t('customer_account.apt_suite_building')}
                  onChange={(event) => {
                    const inputValue = event?.target?.value
                    if (validateOnChange) {
                      setFormFieldError({
                        ...formFieldError,
                        street_address_2: validateStreetAddress2Field(
                          inputValue,
                          t
                        )
                      })
                    }
                    dispatch({
                      value: inputValue,
                      field: 'street_address_2',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  disabled={loader}
                />
              </FormField>

              {/* City */}
              <Box direction="row-responsive" gap="small">
                <FormField
                  name="city"
                  data-testid="city-form-field"
                  error={formFieldError?.city}
                  disabled={loader}
                >
                  <TextInput
                    value={state.city}
                    name="city"
                    data-testid="city-form-input"
                    placeholder={t('customer_account.city')}
                    onChange={(event) => {
                      const inputValue = event?.target?.value
                      if (validateOnChange) {
                        setFormFieldError({
                          ...formFieldError,
                          city: validateCityField(inputValue, t)
                        })
                      }
                      dispatch({
                        value: inputValue,
                        field: 'city',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                    disabled={loader}
                  />
                </FormField>

                {/* State or Region */}
                <FormField
                  name="state_or_region"
                  data-testid="state-or-region-form-field"
                  error={formFieldError?.state_or_region}
                  disabled={loader}
                >
                  <TextInput
                    value={state.state_or_region}
                    name="state_or_region"
                    data-testid="state-or-region-form-input"
                    placeholder={t(
                      'customer_account.state_or_provision_optional'
                    )}
                    onChange={(event) =>
                      dispatch({
                        value: event?.target?.value,
                        field: 'state_or_region',
                        type: 'CHANGE_FIELD'
                      })
                    }
                    disabled={loader}
                  />
                </FormField>
              </Box>

              {/* Zip Code */}
              <FormField
                name="zip"
                data-testid="zip-form-field"
                error={formFieldError?.zip}
                disabled={loader}
              >
                <TextInput
                  value={state.zip}
                  name="zip"
                  data-testid="zip-form-input"
                  placeholder={t('customer_account.zip_placeholder')}
                  onChange={(event) => {
                    const inputValue = event?.target?.value
                    if (validateOnChange) {
                      setFormFieldError({
                        ...formFieldError,
                        zip: validateZipField(inputValue, t)
                      })
                    }
                    dispatch({
                      value: inputValue,
                      field: 'zip',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  disabled={loader}
                />
              </FormField>
            </Box>
          </CCSForm>
        </Box>
      }
      footer={
        <ModalFooter
          left={
            <ButtonGroup
              buttonList={[
                {
                  testId: 'create-workspace-btn',
                  label: t('customer_account.createAccount', {
                    account: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  }),
                  primary: true,
                  isLoading: loader,
                  onClick: handleCreateWorkspace
                },
                {
                  testId: 'cancel-btn',
                  label: t('common:cancel'),
                  default: true,
                  onClick: () => {
                    onSetOpen(false)
                  }
                }
              ]}
              testId="create-workspace-action-buttons"
            />
          }
        />
      }
      header={
        <ModalHeader
          title={
            <Typography
              level="2"
              style={{ whiteSpace: 'nowrap' }}
              testId="create-workspace-header"
              type="heading"
              weight="bold"
            >
              {t('customer_account.create_customer_account', {
                account: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_CAPITALIZED
                )
              })}
            </Typography>
          }
          onClose={() => onSetOpen(false)}
        />
      }
      height="100%"
      onClose={() => onSetOpen(false)}
      position="right"
      testId="create-workspace-modal"
    />
  )
}

CreateCustomerWorkspace.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export { CreateCustomerWorkspace }
