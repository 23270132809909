import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Box } from 'grommet'
import { Previous } from 'grommet-icons'

import { Button } from '../../../../../components'

const LocationDetailsHeader = ({ isCCSManager }) => {
  const { t } = useTranslation(['location'])
  const history = useHistory()
  return (
    <Box direction="row" justify="start">
      <Button
        default
        margin={{ vertical: 'xsmall', horizontal: 'small' }}
        label={t('location:location_title')}
        icon={<Previous />}
        onClick={() => {
          history.push(
            isCCSManager
              ? {
                  pathname: '/manage-ccs/customers/customer-details',
                  tabName: 'locations'
                }
              : '/manage-account/locations'
          )
        }}
        testId="location-details-page-back-button"
      />
    </Box>
  )
}

LocationDetailsHeader.propTypes = {
  isCCSManager: PropTypes.bool
}
LocationDetailsHeader.defaultProps = {
  isCCSManager: false
}

export { LocationDetailsHeader }
