import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Layer, Text } from 'grommet'
import { StatusGoodSmall, Close } from 'grommet-icons'
import { colors } from 'grommet-theme-hpe'
import { useTranslation } from 'react-i18next'

import { localStorageWhitelistWithoutPII } from '../../../../utils/local-storage-utils'
import backgroundGradient from '../../../../images/BackgroundGradient.jpg'

const Wizard = () => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation('unified_wellness')

  // This should probably be saved in the api but using local storage for now
  useEffect(() => {
    if (
      localStorage.getItem(
        localStorageWhitelistWithoutPII.FIRST_TIME_WELLNESS_USER
      ) !== 'false'
    ) {
      localStorage.setItem(
        localStorageWhitelistWithoutPII.FIRST_TIME_WELLNESS_USER,
        'false'
      )
      setShowModal(true)
    }
  }, [setShowModal])
  const [currentStep, setCurrentStep] = useState(0)
  const prevSlide = () => {
    setCurrentStep(currentStep - 1)
  }
  const nextSlide = () => {
    setCurrentStep(currentStep + 1)
  }
  const steps = [
    {
      title: t('wizard.slide1_title'),
      text: t('wizard.slide1_text')
    },
    {
      title: t('wizard.slide2_title'),
      text: t('wizard.slide2_text')
    },
    {
      title: t('wizard.slide3_title'),
      text: t('wizard.slide3_text')
    }
  ]
  return (
    <>
      {showModal && (
        <Layer
          onEsc={() => setShowModal(false)}
          onClickOutside={() => setShowModal(false)}
        >
          <Box
            round={{ size: 'small' }}
            background={`url(${backgroundGradient})`}
            width="large"
          >
            <Box align="end" pad="xsmall">
              <Button
                label={<Close size="small" color="black" />}
                onClick={() => setShowModal(false)}
              />
            </Box>
            <Box pad={{ horizontal: 'large', bottom: 'large', top: 'medium' }}>
              <Box>
                <Text data-testid="wizard-title" size="xxlarge" color="white">
                  {steps[currentStep].title}
                </Text>
              </Box>
              <Box pad={{ top: 'small' }} width="medium">
                <Text data-testid="wizard-text" color="white">
                  {steps[currentStep].text}
                </Text>
              </Box>
            </Box>
            <Box round="small" pad="small">
              <Box pad="small">
                <Grid
                  rows={['xxxsmall']}
                  columns={['1/3', '1/3', '1/3']}
                  areas={[
                    { name: 'left', start: [0, 0], end: [0, 0] },
                    { name: 'center', start: [1, 0], end: [1, 0] },
                    { name: 'right', start: [2, 0], end: [2, 0] }
                  ]}
                >
                  <Box align="start" gridArea="left">
                    {currentStep !== 0 && (
                      <Button
                        label={t('wizard.back')}
                        onClick={() => prevSlide()}
                        data-testid="back-btn"
                        color="white"
                      />
                    )}
                  </Box>
                  <Box direction="row" justify="center" gridArea="center">
                    {steps.map((e, i) => (
                      <Button
                        onClick={() => setCurrentStep(i)}
                        /* eslint-disable-line react/no-array-index-key */ key={`circle-${i}`}
                        data-testid={`circle-${i}`}
                      >
                        <Box pad="xxsmall" align="center">
                          <StatusGoodSmall
                            size={currentStep === i ? '10px' : '8px'}
                            color={
                              currentStep === i ? 'white' : colors.text.dark
                            }
                          />
                        </Box>
                      </Button>
                    ))}
                  </Box>
                  <Box align="end" gridArea="right">
                    <Button
                      label={
                        currentStep !== steps.length - 1
                          ? t('wizard.next')
                          : t('wizard.close')
                      }
                      primary
                      onClick={() => {
                        if (currentStep !== steps.length - 1) {
                          nextSlide()
                        } else {
                          setShowModal(false)
                        }
                      }}
                      data-testid="next-btn"
                    />
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Layer>
      )}
    </>
  )
}
export default Wizard
