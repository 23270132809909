// (C) Copyright 2020 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import isEmpty from 'lodash/isEmpty'
import pullAt from 'lodash/pullAt'
import { Add, Trash } from 'grommet-icons'

import {
  FormInput,
  Button,
  Typography
} from '../../../../../../../../components'

import { getFieldArrayErrorMessage } from './utils'

const ReferenceLink = ({ formValues, setFormValues, formError }) => {
  const { t } = useTranslation(['manage'])
  const [disableAddButton, setDisableAddButton] = useState(false)
  const [showMaxLimitMsg, setShowMaxLimitMsg] = useState(false)

  const textMaxLength = 100
  const urlMaxLength = 255

  useEffect(() => {
    setShowMaxLimitMsg(false)
    const arrayLength = formValues.referenceLinks.length
    if (arrayLength > 5) {
      setDisableAddButton(true)
    } else if (
      arrayLength > 0 &&
      (isEmpty(formValues.referenceLinks[arrayLength - 1].label) ||
        isEmpty(formValues.referenceLinks[arrayLength - 1].link))
    ) {
      setDisableAddButton(true)
    } else {
      setShowMaxLimitMsg(false)
      setDisableAddButton(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])

  return (
    <Box margin={{ vertical: 'medium' }} gap="medium">
      {formValues.referenceLinks &&
        formValues.referenceLinks.map((link, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index}>
            <Box margin={{ bottom: 'small' }} direction="row" justify="between">
              <Box width="90%">
                <Box width="100%">
                  <FormInput
                    testId={`referenceLinks[${index}]-label`}
                    label={t('whats_new.create_post_form.text_label')}
                    inputType="text"
                    error={getFieldArrayErrorMessage(
                      formError.referenceLabel,
                      index
                    )}
                    maxLength={textMaxLength}
                    value={formValues.referenceLinks[index].label}
                    onChange={(event) => {
                      const tempLabel = { ...formValues }
                      tempLabel.referenceLinks[index].label = event.target.value
                      setFormValues({
                        ...tempLabel
                      })
                    }}
                  />
                  <Box alignSelf="end">
                    <Typography
                      type="text"
                      size="xsmall"
                      color="text-weak"
                      testId={`referenceLinks[${index}]-label-count`}
                    >
                      {formValues?.referenceLinks[index]?.label.length}/
                      {textMaxLength}
                    </Typography>
                  </Box>
                </Box>
                <Box width="100%">
                  <FormInput
                    testId={`referenceLinks[${index}]-link`}
                    label={t('whats_new.create_post_form.url_label')}
                    inputType="text"
                    error={getFieldArrayErrorMessage(
                      formError.referenceLinks,
                      index
                    )}
                    maxLength={urlMaxLength}
                    value={formValues.referenceLinks[index].link}
                    onChange={(event) => {
                      const tempLink = { ...formValues }
                      tempLink.referenceLinks[index].link = event.target.value
                      setFormValues({
                        ...tempLink
                      })
                    }}
                  />
                  <Box alignSelf="end">
                    <Typography
                      type="text"
                      size="xsmall"
                      color="text-weak"
                      testId={`referenceLinks[${index}]-link-count`}
                    >
                      {formValues?.referenceLinks[index]?.link.length}/
                      {urlMaxLength}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                align="center"
                margin={{ vertical: 'medium' }}
                pad={{ vertical: 'xsmall' }}
              >
                <Button
                  testId={`referenceLinks[${index}]-delete-btn`}
                  type="button"
                  label=""
                  icon={<Trash />}
                  onClick={() => {
                    pullAt(formValues.referenceLinks, index)
                    setFormValues({
                      ...formValues
                    })
                  }}
                />
              </Box>
            </Box>
          </Box>
        ))}
      <Box align="start" justify="start">
        <Button
          testId="add-link-button"
          icon={<Add />}
          size="medium"
          onClick={() => {
            const arrayLength = formValues.referenceLinks.length
            if (arrayLength === 5) {
              setShowMaxLimitMsg(true)
              return
            }

            setFormValues({
              ...formValues,
              referenceLinks: [
                ...formValues.referenceLinks,
                { label: '', link: '' }
              ]
            })
          }}
          label={t('whats_new.create_post_form.add_link')}
          secondary
          disabled={disableAddButton}
        />
      </Box>

      {showMaxLimitMsg && (
        <Typography type="text" size="small" testId="reference-link-limit">
          {t('whats_new.create_post_form.reference_link_limit')}
        </Typography>
      )}
    </Box>
  )
}

ReferenceLink.propTypes = {
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  formError: PropTypes.object.isRequired
}

export default ReferenceLink
