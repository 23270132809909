import React from 'react'
import PropTypes from 'prop-types'

import useAUTHZReducer from './authz-reducer'

const AUTHZContext = React.createContext()

export const AUTHZContextProvider = ({ children }) => {
  const stateAndDispatch = useAUTHZReducer()
  return (
    <AUTHZContext.Provider value={stateAndDispatch}>
      {children}
    </AUTHZContext.Provider>
  )
}

AUTHZContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const useAUTHZContext = () => {
  const { AUTHZState, dispatchAUTHZContext } = React.useContext(AUTHZContext)
  return { ...AUTHZState, dispatchAUTHZContext }
}
