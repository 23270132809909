import React, { useState, useEffect, useContext } from 'react'
import { Box, FormField, TextInput, CheckBox } from 'grommet'
import { useTranslation } from 'react-i18next'
import lodashGet from 'lodash/get'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Copy } from 'grommet-icons'

/* eslint-enable */
import { Button, Typography, Tooltip } from '../../../../../../../components'
import PasswordInput from '../../../../../../../components/form-input/PasswordInput'
import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import { validateRecoveryUser } from '../utils/validation-utils'
import {
  generateRandomString,
  handleCopy,
  StyledFormField
} from '../utils/page-utils'

import RecoveryConfirmationModal from './components/RecoveryConfirmationModal'

const RecoveryAccount = () => {
  const { t } = useTranslation(['common', 'iam'])
  const { oidcUser } = useReactOidc()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [randomUsername, setRandomUsername] = useState('')

  const { attemptedAdvance, formValues, setFormValues } =
    useContext(WizardContext)
  const [fieldsError, setFieldsError] = useState({
    pocEmail: '',
    recoveryPassword: ''
  })

  useEffect(() => {
    if (attemptedAdvance) {
      setFieldsError(validateRecoveryUser(formValues, t))
    }
  }, [formValues, attemptedAdvance, t])

  const copyButtonFn = ({ hover, focus }) => (
    <Tooltip
      dropProps={{ align: { left: 'right' } }}
      info={
        <>
          {hover && !focus ? t('authz:copy') : null}
          {hover && focus ? t('authz:copied') : null}
        </>
      }
      testId="copy-tooltip"
    >
      <Copy size="medium" />
    </Tooltip>
  )

  const generateString = () => {
    const newString = generateRandomString(32)
    setRandomUsername(`${newString}@recovery.auth.greenlake.hpe.com`)
  }

  const recoveryUserName = () => {
    if (lodashGet(formValues, 'recovery_user.recoveryEmail')) {
      return lodashGet(formValues, 'recovery_user.recoveryEmail')
    }
    if (!randomUsername) {
      generateString()
    }
    return randomUsername
  }

  const toggleRecoveryUser = (e) => {
    if (e.target.checked) {
      setFormValues({
        ...formValues,
        recovery_user: {
          ...formValues.recovery_user,
          recoveryUserEnabled: e.target.checked,
          pocEmail: '',
          recoveryPassword: '',
          username: oidcUser.profile.given_name,
          skipPasswordValidation: false
        }
      })
    } else {
      setShowConfirmationModal(true)
    }
  }

  const showPasswordField = (form) => {
    if (!lodashGet(form, 'recovery_user.pocEmail')) return true
    if (
      lodashGet(form, 'recovery_user.pocEmail') !==
      lodashGet(form, 'recovery_user.currentPocEmail')
    )
      return true
    return false
  }

  const onCurrentRecoveryAccountEmailChange = (e) => {
    setFormValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        recovery_user: {
          ...prevValues.recovery_user,
          pocEmail: e.target.value,
          username: oidcUser.profile.given_name,
          skipPasswordValidation: false,
          recoveryPassword: ''
        }
      }
      if (showPasswordField(updatedValues)) {
        setFormValues({
          ...formValues,
          recovery_user: {
            ...updatedValues.recovery_user,
            skipPasswordValidation: false
          }
        })
      } else {
        setFormValues({
          ...formValues,
          recovery_user: {
            ...updatedValues.recovery_user,
            skipPasswordValidation: true
          }
        })
      }
      return updatedValues
    })
  }

  return (
    <Box gap="medium" pad={{ top: 'medium', bottom: 'large' }}>
      <Typography type="heading" level="2">
        {t('iam:sso_profiles.view_edit_create.recovery_account')}
      </Typography>
      <CheckBox
        pad="none"
        label={
          <Box flex>
            {t('iam:sso_profiles.view_edit_create.enable_recovery_account')}
          </Box>
        }
        checked={lodashGet(formValues, 'recovery_user.recoveryUserEnabled')}
        onChange={(e) => toggleRecoveryUser(e)}
        data-testid="recovery-account-enabled-toggle"
      />
      {lodashGet(formValues, 'recovery_user.recoveryUserEnabled') && (
        <Box width="large" gap="xsmall">
          <StyledFormField
            label={t(
              'iam:sso_profiles.view_edit_create.recovery_account_user_name'
            )}
            help={t(
              'iam:sso_profiles.view_edit_create.recovery_account_user_name_help'
            )}
            name="recovery-account-email"
            htmlFor="user-recover-email-field"
            data-testid="recovery-account-email-field"
          >
            <Box direction="row" align="center">
              <Box direction="column">
                <Typography
                  type="text"
                  data-testid="recovery-account-email-title"
                  size="small"
                  weight={600}
                  color="light"
                >
                  {recoveryUserName()}
                </Typography>
              </Box>
              <Box direction="column">
                <Button
                  default
                  onClick={() => {
                    handleCopy(recoveryUserName())
                  }}
                  hoverIndicator="none"
                  testId="recovery-account-email-copy-btn"
                >
                  {copyButtonFn}
                </Button>
              </Box>
            </Box>
          </StyledFormField>
          <FormField
            contentProps={{ width: 'medium' }}
            label={`${t(
              'iam:sso_profiles.view_edit_create.recovery_account_contact_email'
            )}*`}
            help={t(
              'iam:sso_profiles.view_edit_create.recovery_account_contact_email_help'
            )}
            name="recovery_user"
            htmlFor="recovery_user"
            error={fieldsError.pocEmail}
            data-testid="poc-email-form-field"
          >
            <TextInput
              value={
                lodashGet(formValues, 'recovery_user.pocEmail') ||
                lodashGet(formValues, 'recovery_user.currentPocEmail')
              }
              id="recovery_email"
              name="email"
              type="text"
              data-testid="recovery-email-input"
              autoComplete="off"
              onChange={(e) => {
                onCurrentRecoveryAccountEmailChange(e)
              }}
            />
          </FormField>
          {showPasswordField(formValues) && (
            <FormField
              width="medium"
              htmlFor="recovery_password"
              name="recovery_password"
              help={t(
                'iam:sso_profiles.view_edit_create.recovery_account_password_desc'
              )}
              label={`${t(
                'iam:sso_profiles.view_edit_create.recovery_account_password'
              )}*`}
              error={fieldsError.recoveryPassword}
              data-testid="recovery-password-form-field"
            >
              <PasswordInput
                value={lodashGet(formValues, 'recovery_user.recoveryPassword')}
                id="recovery_password"
                name="recovery_password"
                data-testid="recovery-password-input"
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    recovery_user: {
                      ...formValues.recovery_user,
                      recoveryEmail: recoveryUserName(),
                      recoveryPassword: e.target.value,
                      username: oidcUser.profile.given_name
                    }
                  })
                }}
              />
            </FormField>
          )}
        </Box>
      )}
      {showConfirmationModal && (
        <RecoveryConfirmationModal
          setOpen={setShowConfirmationModal}
          handleConfirm={() => {
            setFormValues({
              ...formValues,
              recovery_user: {
                ...formValues.recovery_user,
                recoveryUserEnabled: false,
                pocEmail: '',
                recoveryPassword: '',
                recoveryEmail: ''
              }
            })
            setShowConfirmationModal(false)
          }}
        />
      )}
    </Box>
  )
}

export default RecoveryAccount
