import React, { useState } from 'react'
import { Box, CheckBox, FormField } from 'grommet'
import { Alert } from 'grommet-icons'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  Typography,
  ModalFooter,
  ButtonGroup,
  CCSForm
} from '../../../components'
import { isServiceCentric } from '../../../utils/account-utils'

const RemoveRegionModal = ({
  appRegion,
  appName,
  handleCancel,
  unprovisionApp
}) => {
  const { t } = useTranslation(['apps'])

  const [checked, setChecked] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)

  const invokeError = () => {
    setErrorMessage(t('apps:remove_terms_of_service_text'))
  }

  const handleRemoveDeployedRegion = () => {
    if (checked || newDashboardFlag) {
      unprovisionApp()
    } else {
      invokeError()
    }
  }

  const handleTerms = (value) => {
    setChecked(value)
    setErrorMessage('')
  }

  return (
    <ModalDialog
      content={
        <Box>
          <Box
            align={newDashboardFlag ? 'start' : 'center'}
            margin={{ bottom: newDashboardFlag ? 'none' : 'medium' }}
          >
            {newDashboardFlag || (
              <Alert size="xlarge" color="status-critical" />
            )}
            <Box
              align={newDashboardFlag ? 'start' : 'center'}
              gap="xsmall"
              margin={{ top: newDashboardFlag ? 'none' : 'medium' }}
            >
              <Typography
                size="xlarge"
                testId="modal-title"
                type="text"
                weight="bold"
              >
                {newDashboardFlag
                  ? t('remove_region')
                  : t('remove_app_region_title')}
              </Typography>
              <Typography
                size="medium"
                testId="modal-subtitle"
                type="text"
                textAlign={newDashboardFlag ? 'start' : 'center'}
              >
                <Trans
                  i18nKey={
                    newDashboardFlag
                      ? 'remove_service_region_subtitle'
                      : 'remove_app_region_subtitle'
                  }
                  appName={appName}
                  appRegion={appRegion}
                  t={t}
                >
                  <strong>{{ appName }}</strong>
                  <strong>{{ appRegion }}</strong>
                </Trans>
              </Typography>
            </Box>
          </Box>

          {newDashboardFlag || (
            <Box gap="medium">
              <CCSForm errorMessage={errorMessage} testId="app-term-form">
                <FormField>
                  <CheckBox
                    data-testid="app-term-checkbox"
                    name="app_terms"
                    checked={checked}
                    label={
                      <Typography
                        type="text"
                        size="small"
                        testId="app-terms-checkbox-label"
                      >
                        {t('remove_app_terms')}
                      </Typography>
                    }
                    onChange={(event) => handleTerms(event.target.checked)}
                  />
                </FormField>
              </CCSForm>
            </Box>
          )}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('keep_region'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: handleCancel
                },
                {
                  id: 1,
                  label: t('remove_region'),
                  primary: true,
                  testId: 'remove-region-btn',
                  onClick: handleRemoveDeployedRegion
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={handleCancel}
      testId="remove-apps-modal"
      width="large"
    />
  )
}

RemoveRegionModal.propTypes = {
  appRegion: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  unprovisionApp: PropTypes.func.isRequired
}

export { RemoveRegionModal }
