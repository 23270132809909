// (C) Copyright 2017-2022 Hewlett Packard Enterprise Development LP

import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { v4 as uuidv4 } from 'uuid'
import { StatusCritical, View } from 'grommet-icons'
import isEmpty from 'lodash/isEmpty'
import {
  Box,
  Grid,
  FormField,
  SelectMultiple,
  FileInput,
  DateInput,
  MaskedInput,
  Select
} from 'grommet'
import axios from 'axios'

import {
  Typography,
  FormInput,
  Notification,
  Button,
  ModalDialog,
  CCSForm,
  ButtonGroup,
  FormTextArea,
  ToggleButton
} from '../../../../../components'
import { get, getErrorMessage, put } from '../../../../../utils/api-utils'
import { getCustomerAccount } from '../../../../../utils/feature-flag-utils'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import ReferenceLink from './create-post/steps/WhatsNewDetails/ReferenceLink'
import {
  validateNewDetails,
  validateSelectedFile
} from './create-post/steps/WhatsNewDetails/utils'
import PreviewPost from './PreviewPost'
import PreviewAnnouncement from './PreviewAnnouncement'
import { getEditPostRequestBody } from './create-post/utils'
import { getFormattedData } from './create-post/steps/ReviewPost/utils'

const EditPost = ({ postData, closeEdit, refreshData }) => {
  const { t } = useTranslation(['manage'])
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()

  const [formError, setFormError] = useState({})
  const [formValues, setFormValues] = useState(postData)
  const [apiErrorMessage, setApiErrorMessage] = useState(null)
  const approvalEnabled = LDFlags['glcp-whatsnew-approval']

  const [awsFilePath, setAwsFilePath] = useState(formValues?.media || '')
  const [numFiles, setNumFiles] = useState(
    formValues?.selectedFile?.length || 0
  )

  const [listOfServices, setListOfServices] = useState([])
  const [services, setServices] = useState([])
  const custAccountLoaded = getCustomerAccount()
  const [showPreview, setShowPreview] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [summaryFlag, setSummaryFlag] = useState(
    formValues?.summaryFlag || false
  )
  const [showPreviewAnnouncement, setShowPreviewAnnouncement] = useState(false)
  const [publicationLocation, setPublicationLocation] = useState([])

  const platformCustomerId = custAccountLoaded?.platform_customer_id || ''
  const shortDescMaxLength = 2000
  const titleMaxLength = 100
  const summaryMaxLength = 440

  const handleFinish = (editData) => {
    const postRequestBody = getEditPostRequestBody(editData, approvalEnabled)
    let URL = `/whatsnew/v1/manage/blogs/${postData.blogID}`
    if (approvalEnabled) {
      URL = `/whatsnew/v1/review/blogs/${postData.blogID}`
    }
    put(URL, postRequestBody, oidcUser.access_token, {
      'x-request-id': uuidv4(),
      'X-Account-ID': platformCustomerId
    }).then(
      (response) => {
        if (response?.status === 200) {
          refreshData()
        }
      },
      (error) => {
        setApiErrorMessage(getErrorMessage(error, t))
      }
    )
  }
  const onChange = (event) => {
    const nextValue = event.value
    setFormValues({
      ...formValues,
      scheduleDate: nextValue
    })
  }
  const handleSubmit = (editData) => {
    const errorObj = validateNewDetails(editData, t)
    if (!isEmpty(errorObj)) {
      setErrorMessage(t('whats_new.create_post_form.missing_required_fields'))
    } else if (formValues.uploading) {
      setErrorMessage(t('whats_new.create_post_form.file_uploading_message'))
    } else {
      handleFinish(editData)
    }
  }

  const onDelete = (onConfirm) => {
    if (formValues?.media) {
      get(
        `/whatsnew/v1/blog/medialink?op=delete&path=${formValues?.media}`,
        {},
        oidcUser.access_token,
        false,
        {},
        {
          'X-Account-ID': platformCustomerId
        }
      ).then(
        (response) => {
          if (response?.status === 204) {
            setNumFiles(0)
            setFormValues({
              ...formValues,
              fileName: '',
              selectedFile: [],
              media: '',
              mediaType: ''
            })
            setAwsFilePath('')
            onConfirm()
          }
        },
        (error) => {
          setApiErrorMessage(getErrorMessage(error, t))
        }
      )
    } else {
      setNumFiles(0)
      setFormValues({
        ...formValues,
        fileName: '',
        selectedFile: [],
        media: ''
      })
      setFormError((formError.uploadFile = []))
      onConfirm()
    }
  }

  const handleCancel = (onConfirm) => {
    if (!isEmpty(formValues?.selectedFile)) {
      onDelete(onConfirm)
    }
  }

  useEffect(() => {
    get(
      '/whatsnew/v1/blog/service/category',
      {},
      oidcUser.access_token,
      false,
      {},
      {
        'X-Account-ID': platformCustomerId
      }
    ).then(
      (response) => {
        if (response?.data) {
          setListOfServices(response.data.items)
          setServices(response.data.items)
        }
      },
      (error) => {
        setApiErrorMessage(getErrorMessage(error, t))
      }
    )
    get(
      `/whatsnew/v1/blog/service/category?q=publicationLocation`,
      {},
      oidcUser.access_token,
      false,
      {},
      {
        'X-Account-ID': platformCustomerId
      }
    ).then(
      (response) => {
        if (response?.data) {
          setPublicationLocation(response.data.items)
        }
      },
      (error) => {
        setApiErrorMessage(getErrorMessage(error, t))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t])

  useEffect(() => {
    const errorObj = validateNewDetails(formValues, t)
    setFormError(errorObj)
    if (isEmpty(errorObj)) {
      setErrorMessage('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, t])

  const uploadMediaToAWS = (operation, filePath, file) => {
    get(
      `/whatsnew/v1/blog/medialink?op=${operation}&path=${filePath}`,
      {},
      oidcUser.access_token,
      false,
      {},
      {
        'X-Account-ID': platformCustomerId
      }
    ).then(
      (response) => {
        if (response?.data) {
          const url = response?.data?.signedURL
          axios
            .put(url, file, {
              headers: {
                'Content-Type': file.type
              }
            })
            .then(
              (awsResponse) => {
                if (awsResponse.status === 200) {
                  setAwsFilePath(response.data?.path)
                  setFormValues((currentFormValues) => ({
                    ...currentFormValues,
                    media: response.data?.path,
                    fileName: file.name,
                    mediaType: file.type,
                    selectedFile: [file],
                    uploading: false
                  }))
                }
                setUploading(false)
              },
              (error) => {
                setApiErrorMessage(getErrorMessage(error, t))
                setUploading(false)
                setFormValues((currentFormValues) => ({
                  ...currentFormValues,
                  uploading: false
                }))
              }
            )
        }
      },
      (error) => {
        setApiErrorMessage(getErrorMessage(error, t))
      }
    )
  }

  const handleFileUpload = (files) => {
    const errorObj = {}
    setFormError(errorObj)
    const errorFile = validateSelectedFile(files[0], t)
    if (errorFile) {
      errorObj.uploadFile = errorFile
      setFormError(errorObj)
    }

    if (isEmpty(errorObj)) {
      if (files.length > 0) {
        setNumFiles(files.length)
        setFormValues({
          ...formValues,
          fileName: files[0].name,
          selectedFile: files,
          uploading: true
        })
        setUploading(true)
        if (awsFilePath === '') {
          uploadMediaToAWS('post', files[0].name, files[0])
        } else {
          const filePath = awsFilePath.substring(
            0,
            awsFilePath.lastIndexOf('/') + 1
          )
          uploadMediaToAWS('replace', filePath + files[0].name, files[0])
        }
      }
    }
  }

  const getPublishedDateValue = (publishDate) => {
    try {
      return new Date(publishDate)?.toISOString()
    } catch (e) {
      return publishDate
    }
  }

  return (
    <Box width="100%" margin={{ bottom: 'small' }}>
      <CCSForm errorMessage={errorMessage} testId="edit-post-form">
        <Grid
          columns={['1/3', '2/3']}
          gap={{
            row: 'small',
            column: 'small'
          }}
        >
          {!formValues?.active ? (
            <>
              <Typography
                type="text"
                weight="bold"
                size="medium"
                testId="edit-post-publishDate-label"
              >
                {t('whats_new.create_post_form.publish_date')}
              </Typography>
              <FormField
                testId="edit-post-schedule-date"
                name="scheduleDate"
                error={formError.scheduleDate}
              >
                <DateInput
                  format="mm/dd/yyyy"
                  value={getPublishedDateValue(formValues.scheduleDate)}
                  onChange={onChange}
                  placeholder="mm/dd/yyyy"
                  data-testid="edit-schedule-date"
                  calendarProps={{
                    bounds: [new Date().toISOString(), '2050-12-31'],
                    alignSelf: 'center'
                  }}
                />
              </FormField>
              <Typography
                type="text"
                weight="bold"
                size="medium"
                testId="edit-post-publishTime-label"
              >
                {t('whats_new.create_post_form.publish_time')}
              </Typography>
              <Box direction="column">
                <FormField
                  name="scheduleTime"
                  data-testid="edit-post-scheduleTime"
                  error={formError.scheduleTime}
                >
                  <MaskedInput
                    mask={[
                      {
                        length: [1, 2],
                        options: Array.from({ length: 12 }, (v, k) => k + 1),
                        regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                        placeholder: 'hh'
                      },
                      { fixed: ':' },
                      {
                        length: 2,
                        options: ['00', '15', '30', '45'],
                        regexp: /^[0-5][0-9]$|^[0-9]$/,
                        placeholder: 'mm'
                      },
                      { fixed: ' ' },
                      {
                        length: 2,
                        options: ['am', 'pm'],
                        regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                        placeholder: 'ap'
                      }
                    ]}
                    value={formValues.scheduleTime}
                    data-testid="edit-schedule-time-input"
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        scheduleTime: event.target.value
                      })
                    }}
                  />
                </FormField>
                <Typography type="text" sime="smalll" testId="timezone-title">
                  {`(GMT${formValues?.timeZone})`}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography
                type="text"
                weight="bold"
                size="medium"
                testId="edit-post-publishDate-label"
              >
                {t('whats_new.create_post_form.publish_date')}
              </Typography>
              <Typography
                type="text"
                size="medium"
                testId="edit-post-publishDate-value"
              >
                {formValues.publishedDate}
              </Typography>
            </>
          )}

          <Typography
            type="text"
            weight="bold"
            size="medium"
            testId="edit-post-title-label"
          >
            {t('whats_new.create_post_form.title_field_label')}
          </Typography>
          <Box direction="column">
            <FormInput
              testId="edit-post-title"
              label={t('whats_new.create_post_form.title_field_label')}
              inputType="text"
              maxLength="100"
              value={formValues.title || ''}
              placeholder={t('whats_new.create_post_form.title_placeholder')}
              error={formError.title}
              onChange={(event) => {
                setFormValues({
                  ...formValues,
                  title: event.target.value
                })
              }}
              required
            />
            <Box alignSelf="end">
              <Typography type="text" size="xsmall" color="text-weak">
                {formValues.title.length}/{titleMaxLength}
              </Typography>
            </Box>
          </Box>
          <Typography
            type="text"
            weight="bold"
            size="medium"
            testId="edit-service-catalog-label"
          >
            {t('whats_new.create_post_form.service_catalog')}
          </Typography>
          <FormField
            width="100%"
            label=""
            name="serviceCatalog"
            data-testid="edit-service-catalog"
            error={formError.serviceCategory}
          >
            <SelectMultiple
              value={formValues.serviceCategory}
              placeholder={t('whats_new.create_post_form.service_placeholder')}
              options={services}
              onSearch={(searchText) => {
                const regexp = new RegExp(searchText, 'i')
                setServices(listOfServices.filter((o) => o.match(regexp)))
              }}
              onClose={() => setServices(listOfServices)}
              onChange={({ value }) => {
                setFormValues({
                  ...formValues,
                  serviceCategory: value
                })
              }}
            />
          </FormField>
          <Typography
            type="text"
            weight="bold"
            size="medium"
            testId="edit-short-description-label"
          >
            {t('whats_new.create_post_form.full_description')}
          </Typography>
          <Box direction="column">
            <FormTextArea
              testId="edit-short-description"
              inputType="text"
              name="shortDescription"
              label=""
              rows="15"
              maxLength="2000"
              error={formError.shortDescription}
              value={formValues.shortDescription || ''}
              onChange={(event) => {
                setFormValues({
                  ...formValues,
                  shortDescription: event.target.value
                })
              }}
            />
            <Box alignSelf="end">
              <Typography
                type="text"
                testId="short-description-count"
                size="xsmall"
              >
                {formValues.shortDescription.length}/{shortDescMaxLength}
              </Typography>
            </Box>
          </Box>
          <Typography
            type="text"
            weight="bold"
            size="medium"
            testId="edit-media-label"
          >
            {t('whats_new.create_post_form.media_label')}
          </Typography>

          <Box direction="column" width="inherit">
            <FormField
              label=""
              name="upload-file"
              error={formError.uploadFile}
              data-testid="upload-file-title"
            >
              <FileInput
                data-testid="edit-media"
                multiple={false}
                disabled={uploading}
                value={formValues.selectedFile}
                onChange={(event, { files }) => {
                  if (event?.target?.files && event?.target?.files?.length) {
                    setNumFiles(files.length)
                    setFormValues({
                      ...formValues,
                      fileName: files[0].name,
                      selectedFile: files
                    })
                    handleFileUpload(files)
                  }
                }}
                confirmRemove={({ onConfirm, onCancel }) => (
                  <>
                    {formValues?.media ? (
                      <ModalDialog
                        testId="delete-file-modal"
                        onClose={() => {
                          onCancel()
                        }}
                        header={
                          <Typography
                            type="heading"
                            testId="delete-file-title"
                            level={3}
                          >
                            {t('whats_new.create_post_form.remove_file')}
                          </Typography>
                        }
                        content={
                          <Box margin={{ vertical: 'medium' }}>
                            <Typography
                              type="text"
                              size="large"
                              testId="delete-file-desc"
                            >
                              {t('whats_new.create_post_form.remove_file_desc')}
                            </Typography>
                          </Box>
                        }
                        footer={
                          <ButtonGroup
                            testId="two-buttons"
                            buttonList={[
                              {
                                id: 2,
                                label: t('whats_new.cancel_btn'),
                                secondary: true,
                                testId: 'cancel-delete-file-btn',
                                onClick: onCancel,
                                type: 'reset'
                              },
                              {
                                id: 1,
                                label: t(
                                  'whats_new.create_post_form.remove_file_btn'
                                ),
                                primary: true,
                                type: 'submit',
                                onClick: () => {
                                  onDelete(onConfirm)
                                },
                                testId: 'delete-file-btn'
                              }
                            ]}
                          />
                        }
                        position="center"
                        width="medium"
                      />
                    ) : (
                      handleCancel(onConfirm)
                    )}
                  </>
                )}
                messages={{
                  browse:
                    numFiles > 0
                      ? t('whats_new.create_post_form.replace_file')
                      : t('whats_new.create_post_form.select_file'),
                  dropPrompt: t('whats_new.create_post_form.drag_drop')
                }}
              />
            </FormField>
            {uploading && (
              <Typography
                type="text"
                size="small"
                testId="uploading-media-label"
              >
                {t('whats_new.create_post_form.file_uploading')}
              </Typography>
            )}
          </Box>
          <Typography
            type="text"
            weight="bold"
            size="medium"
            testId="edit-publication-location-label"
          >
            {t('whats_new.publication_location')}
          </Typography>
          <FormField
            width="100%"
            label=""
            name="publicationLocation"
            data-testid="edit-publication-location"
          >
            <Select
              data-testid="edit-publication-location-field"
              value={formValues.publicationLocation || ''}
              placeholder={t('whats_new.create_post_form.service_placeholder')}
              options={publicationLocation}
              clear
              onChange={({ value }) => {
                setFormValues({
                  ...formValues,
                  publicationLocation: value
                })
              }}
              dropAlign={{ top: 'top', left: 'left' }}
            />
          </FormField>
          <>
            <Typography
              type="text"
              weight="bold"
              size="medium"
              testId="edit-reference-link-label"
            >
              {t('whats_new.create_post_form.reference_link_title')}
            </Typography>
            <Box direction="column">
              <ReferenceLink
                formValues={formValues}
                setFormValues={setFormValues}
                formError={formError}
              />
              <Box align="end" margin={{ vertical: 'medium' }}>
                <Button
                  default
                  label={t('whats_new.create_post_form.preview_post')}
                  icon={<View size="small" />}
                  onClick={() => {
                    setShowPreview(true)
                  }}
                  testId="preview-btn"
                  reverse
                />
              </Box>
            </Box>
          </>

          <VisibilityWrapper hideFor={{ feature: 'glcp-whatsnew-istanbul' }}>
            <Typography
              type="text"
              weight="bold"
              size="medium"
              testId="edit-home-page-announcement-label"
            >
              {t('whats_new.create_post_form.home_page_announcement')}
            </Typography>
            <Box direction="column">
              <ToggleButton
                reverse
                checked={summaryFlag}
                onChange={(event) => {
                  setSummaryFlag(event.target.checked)
                  setFormValues({
                    ...formValues,
                    summaryFlag: event.target.checked
                  })
                }}
                testId="announcement-toggle-btn"
              />
            </Box>
            {summaryFlag && (
              <>
                <Typography
                  type="text"
                  weight="bold"
                  size="medium"
                  testId="edit-announcement-summary-label"
                >
                  {t('whats_new.create_post_form.summary_title')}
                </Typography>

                <Box direction="column">
                  <FormTextArea
                    width="100%"
                    testId="edit-announcement-summary"
                    label=""
                    inputType="text"
                    name="summary"
                    rows="3"
                    maxLength={summaryMaxLength}
                    error={formError.summary}
                    value={formValues.summary || ''}
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        summary: event.target.value
                      })
                    }}
                    required
                  />
                  <Box alignSelf="end">
                    <Typography
                      type="text"
                      size="xsmall"
                      color="text-weak"
                      testId="summary-count"
                    >
                      {formValues?.summary?.length || 0}/{summaryMaxLength}
                    </Typography>
                  </Box>
                  <Box align="end" margin={{ vertical: 'medium' }}>
                    <VisibilityWrapper
                      hideFor={{ feature: 'glcp-whatsnew-istanbul' }}
                    >
                      {formValues?.summary?.length && (
                        <Button
                          default
                          label={t(
                            'whats_new.create_post_form.preview_announcement_btn'
                          )}
                          icon={<View size="small" />}
                          onClick={() => {
                            setShowPreviewAnnouncement(true)
                          }}
                          testId="preview-announcement-btn"
                          reverse
                        />
                      )}
                    </VisibilityWrapper>
                  </Box>
                </Box>
              </>
            )}
          </VisibilityWrapper>
        </Grid>
        <Box margin={{ top: 'medium' }}>
          <ButtonGroup
            testId="edit-button-group"
            justifyGroup="start"
            buttonList={[
              {
                label:
                  formValues.state === 'Rejected'
                    ? t('whats_new.approval.re_submit_for_approval')
                    : t('whats_new.create_post_form.save_changes_btn'),
                onClick: () => {
                  handleSubmit(formValues)
                },
                testId: 'edit-save-btn',
                primary: true
              },
              {
                label: t('whats_new.cancel_btn'),
                onClick: () => {
                  closeEdit()
                },
                testId: 'edit-cancel-btn'
              }
            ]}
          />
        </Box>
      </CCSForm>
      {apiErrorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setApiErrorMessage(null)}
          testId="critical-notification"
          text={apiErrorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
      {showPreview && (
        <PreviewPost
          item={getFormattedData(formValues)}
          closePreview={() => {
            setShowPreview(false)
          }}
        />
      )}
      {showPreviewAnnouncement && (
        <PreviewAnnouncement
          Post={getFormattedData(formValues)}
          closeModel={() => {
            setShowPreviewAnnouncement(false)
          }}
        />
      )}
    </Box>
  )
}

EditPost.propTypes = {
  postData: PropTypes.object.isRequired,
  closeEdit: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
}

export default EditPost
