/* eslint-disable */
import { oidcLog } from '@axa-fr/react-oidc-context'
import { localStorageWhitelistWithoutPII } from './local-storage-utils'
/* eslint-enable */

export const enableOidcLogs = () => {
  const isOidcLogsEnabled = JSON.parse(
    localStorage.getItem(localStorageWhitelistWithoutPII.OIDC_LOGS)
  )
  let oidcLogLevel = oidcLog.NONE
  if (isOidcLogsEnabled) {
    // oidcLogLevel = oidcLog.INFO
    // enable logs for OpenID login in development mode
    oidcLogLevel = oidcLog.DEBUG
  }
  return oidcLogLevel
}

export const V2_LOGGER = {
  prefix: 'GLCP-v2-session'
}
