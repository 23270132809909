import React from 'react'
import PropTypes from 'prop-types'

import useReportingReducer from './ReportingReducer'

const ReportingContext = React.createContext()

export const ReportingContextProvider = ({ children }) => {
  const stateAndDispatch = useReportingReducer()
  return (
    <ReportingContext.Provider value={stateAndDispatch}>
      {children}
    </ReportingContext.Provider>
  )
}

ReportingContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const useReportContext = () => {
  const { ReportingState, dispatchReportingContext } =
    React.useContext(ReportingContext)
  return { ...ReportingState, dispatchReportingContext }
}
