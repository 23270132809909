/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Box, Button, NameValueList, NameValuePair } from 'grommet'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../components'

import StatusIcon from './StatusIcon'

const RecentDeliveriesModal = ({ onSetOpen, onClosePopup, deliveryInfo }) => {
  const { t } = useTranslation(['authz', 'automations', 'common'])
  const [data, setData] = useState([])
  const keyMapping = {
    responseCode: t('automations:webhooks.status_field'),
    date: t('automations:webhooks.date_field'),
    id: t('automations:webhooks.id_field'),
    resourceUri: t('automations:webhooks.resource_uri_field'),
    requestHeaders: t('automations:webhooks.request_headers_field'),
    requestBody: t('automations:webhooks.requestBody_field'),
    responseHeaders: t('automations:webhooks.response_headers_field'),
    responseBody: t('automations:webhooks.response_body_field')
  }

  useEffect(() => {
    if (deliveryInfo && deliveryInfo.length > 0) {
      const deliveriesData = deliveryInfo.map((info) => ({
        responseCode: info.httpResponseCode,
        date: info.createdAt
          ? dayjs(info.createdAt).format('MM/DD/YYYY, hh:mm:ss A')
          : 'N/A',
        id: info.id,
        resourceUri: info.resourceUri,
        requestHeaders: JSON.stringify(info.requestHeaders),
        requestBody: JSON.stringify(info.requestBody),
        responseHeaders: JSON.stringify(info.responseHeaders),
        responseBody: JSON.stringify(info.responseBody)
      }))
      setData(deliveriesData)
    }
  }, [deliveryInfo])

  const closePopup = () => {
    onSetOpen(false)
    onClosePopup()
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              type="heading"
              level="2"
              margin={{ bottom: 'medium' }}
              data-testid="recentDeliveryModalHeader"
            >
              {t('automations:webhooks.recent_deliveries_modal_header')}
            </Typography>
          }
        />
      }
      content={data.map((deliveryData, index) => (
        <NameValueList
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          responsive
          nameProps={{ width: 'auto' }}
          data-testid={`recent-delivery-details-list-${index}`}
        >
          {Object?.entries(keyMapping).map((datum) => (
            <NameValuePair
              name={
                <Typography
                  type="text"
                  emphasis
                  testId="recent-deliveries-object-key"
                >
                  {datum[1]}
                </Typography>
              }
              key={datum[1]}
            >
              {(() => {
                switch (datum[0]) {
                  case 'responseCode':
                    return (
                      <Box direction="row" align="center" gap="xsmall">
                        <StatusIcon status="ERROR" />
                        <Typography
                          type="text"
                          testId="recent-deliveries-object-value"
                        >
                          {deliveryData[datum[0]]}
                        </Typography>
                      </Box>
                    )
                  case 'requestHeaders':
                  case 'requestBody':
                  case 'responseHeaders':
                  case 'responseBody':
                    return (
                      <Box
                        background={{
                          color: 'background-back'
                        }}
                      >
                        <Typography
                          type="text"
                          testId="recent-deliveries-object-value"
                          wordBreak="break-all"
                        >
                          {typeof deliveryData[datum[0]] === 'object'
                            ? Object.entries(deliveryData[datum[0]]).map(
                                ([key, value]) => (
                                  <Box key={key}>
                                    <strong style={{ fontWeight: 500 }}>
                                      {key}
                                    </strong>
                                    : {value}
                                  </Box>
                                )
                              )
                            : deliveryData[datum[0]]}
                        </Typography>
                      </Box>
                    )
                  default:
                    return (
                      <Typography
                        type="text"
                        testId="recent-deliveries-object-value"
                        wordBreak="break-all"
                      >
                        {deliveryData[datum[0]]}
                      </Typography>
                    )
                }
              })()}
            </NameValuePair>
          ))}
        </NameValueList>
      ))}
      footer={
        <ModalFooter
          right={
            <Box
              direction="row"
              justify="end"
              gap="medium"
              margin={{ top: 'small' }}
            >
              <Button primary label="Close" onClick={closePopup} />
            </Box>
          }
        />
      }
      onClose={closePopup}
      position="center"
      height="auto"
      overflow="hidden"
      width="auto"
      data-testid="recentDeliveryModal"
    />
  )
}

export default RecentDeliveriesModal
