import { useHistory } from 'react-router-dom'
import { Box } from 'grommet'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'

import useLD from '../../../hooks/ld/useLD'
import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../components'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'
import {
  isServiceCentric,
  loadCustomerAccount
} from '../../../utils/account-utils'
import { useVisibilityContext } from '../../../context/visibility-context'
import { displayNotification } from '../../../utils/notificiation-utils'
import {
  getWorkspaceString,
  WKSPC,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'
import { displayAccountLaunchError } from '../utils'
import { useUPSContext } from '../../../context/ups-context'
import useLogger from '../../../hooks/logs/useLogger'

const RemoveTenantApplicationModal = ({
  customer,
  handleClose,
  provisionedAppCount
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const { oidcUser } = useReactOidc()
  const history = useHistory()
  const custAccountLoaded = getCustomerAccount()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const { dispatchUPSContext } = useUPSContext()
  const [errorNotification, setErrorNotification] = useState(null)
  const [loading, setLoading] = useState(false)
  const LDFlags = useFlags()
  const ld = useLD()
  const logger = useLogger()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const newDashboardFlag = isServiceCentric(LDFlags)
  const handleAccountLaunch = (currentAccount, customerAccountToLaunch) => {
    setLoading(true)
    sessionStorage.setItem('swapMspAccount', JSON.stringify(currentAccount))
    customerAccountToLaunch.platform_customer_id =
      customerAccountToLaunch.customer_id
    customerAccountToLaunch.account_type = 'TENANT'
    loadCustomerAccount(
      customerAccountToLaunch,
      oidcUser,
      dispatchVisibilityContext,
      history,
      null,
      null,
      ld,
      dispatchUPSContext,
      logger
    ).then(
      () => {
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        displayAccountLaunchError(error, t, setErrorNotification)
      }
    )
  }
  return (
    <>
      <ModalDialog
        header={
          <ModalHeader
            title={
              <Typography
                level="1"
                testId="modal-title"
                type="heading"
                weight="bold"
              >
                {newDashboardFlag
                  ? t('customer_account.remove_service_manager')
                  : t('customer_account.remove_application')}
              </Typography>
            }
          />
        }
        content={
          <Box margin={{ top: 'medium' }}>
            <Typography type="text" size="medium">
              <Trans
                i18nKey={
                  newDashboardFlag
                    ? 'customer_account.remove_service_desc'
                    : 'customer_account.remove_app_desc'
                }
                t={t}
                provisionedAppCount={provisionedAppCount}
              >
                <strong>{{ provisionedAppCount }}</strong>
                <>
                  {{
                    account: getWorkspaceString(showWorkspaceString, t, WKSPC)
                  }}
                </>
              </Trans>
            </Typography>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    label: t('customer_account.cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    onClick: handleClose,
                    disabled: loading
                  },
                  {
                    label: t('customer_account.launch_account', {
                      account: getWorkspaceString(
                        showWorkspaceString,
                        t,
                        WKSPC_CAPITALIZED
                      )
                    }),
                    primary: true,
                    testId: 'launch-account-btn',
                    isLoading: loading,
                    onClick: () =>
                      handleAccountLaunch(custAccountLoaded, customer)
                  }
                ]}
                testId="modal-action-buttons"
              />
            }
          />
        }
        onClose={handleClose}
        testId="remove-tenant-application-modal"
        width="large"
      />
      {errorNotification &&
        displayNotification(errorNotification, 'error', setErrorNotification)}
    </>
  )
}

RemoveTenantApplicationModal.propTypes = {
  customer: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  provisionedAppCount: PropTypes.number.isRequired
}

export { RemoveTenantApplicationModal }
