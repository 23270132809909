import React from 'react'
import PropTypes from 'prop-types'
import { Box, CheckBox } from 'grommet'

import { Typography } from '../../../../components'

const DropdownCustomRender = ({
  name,
  description,
  isSelected,
  showCheckbox
}) => {
  return (
    <Box
      direction="row"
      justify="between"
      pad={{ horizontal: 'medium', vertical: 'small' }}
      align="center"
      data-testid={`option-${name}`}
    >
      <Box direction="row" align="start" gap="small">
        {showCheckbox && <CheckBox checked={isSelected} />}
        <Box direction="column" width="medium">
          <Typography type="text" emphasis>
            {name}
          </Typography>
          {description && <Typography type="text">{description}</Typography>}
        </Box>
      </Box>
    </Box>
  )
}

DropdownCustomRender.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  showCheckbox: PropTypes.bool
}

DropdownCustomRender.defaultProps = {
  isSelected: false,
  showCheckbox: false
}

export { DropdownCustomRender }
