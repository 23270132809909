import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { FormInput } from '../../../../../../../../components'
import { getErrorMsgFromSingleTier, displayRatesFormatter } from '../utils'

const RateInputBox = ({ value, onBlur, error, resetGlobalErrMsg, ...rest }) => {
  const [localValue, setLocalValue] = useState('')
  const [localError, setLocalError] = useState()
  const { t } = useTranslation(['device', 'common'])

  useEffect(() => {
    setLocalValue(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    setLocalError(error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <FormInput
      inputType="text"
      key={rest.key}
      value={localValue}
      onChange={(event) => {
        if (resetGlobalErrMsg) {
          resetGlobalErrMsg()
        }
        setLocalValue(event.target.value)
        setLocalError(getErrorMsgFromSingleTier(t, event.target.value))
      }}
      name="showback-rates-input-table-inputs"
      onBlur={(e) => {
        onBlur(e.target.value)
        setLocalValue(displayRatesFormatter(e.target.value))
      }}
      error={localError}
      {...rest}
    />
  )
}

RateInputBox.defaultProps = {
  value: '',
  error: undefined,
  resetGlobalErrMsg: undefined
}

RateInputBox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resetGlobalErrMsg: PropTypes.func,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.string
}

export default RateInputBox
