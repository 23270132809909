const substituteStatusLabel = (label, t) => {
  let userFriendlyLabel = ''
  switch (label) {
    case 'UNVERIFIED':
      userFriendlyLabel = `${t('users.unverified')}`
      break
    case 'VERIFIED':
      userFriendlyLabel = `${t('users.verified')}`
      break
    default:
      userFriendlyLabel = ''
  }
  return userFriendlyLabel
}

const getAllAccessRulesList = (t, isTenantWorkspaceGroup) => {
  return isTenantWorkspaceGroup
    ? [
        {
          label: t('users.msp_and_all_tenants'),
          value: 0,
          access_rules: {
            msp: true,
            tenants: ['ALL']
          }
        },
        {
          label: t('users.msp_only'),
          value: 1,
          access_rules: {
            msp: true,
            tenants: []
          }
        },
        {
          label: t('users.all_tenants_only'),
          value: 2,
          access_rules: {
            msp: false,
            tenants: ['ALL']
          }
        },
        {
          label: t('users.msp_and_specific_tenants'),
          value: 3,
          access_rules: {
            msp: true,
            tenants: ['specific']
          }
        },
        {
          label: t('users.specific_tenants_only'),
          value: 4,
          access_rules: {
            msp: false,
            tenants: ['specific']
          }
        }
      ]
    : [
        {
          label: t('users.msp_and_tenants'),
          value: 0,
          access_rules: {
            msp: true,
            tenants: ['ALL']
          }
        },
        {
          label: t('users.msp_only'),
          value: 1,
          access_rules: {
            msp: true,
            tenants: []
          }
        },
        {
          label: t('users.tenants_only'),
          value: 2,
          access_rules: {
            msp: false,
            tenants: ['ALL']
          }
        }
      ]
}

export { substituteStatusLabel, getAllAccessRulesList }
