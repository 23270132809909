import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { Button } from '../../components'

const ProfileButtons = ({ updateLabel, edited }) => {
  const { t } = useTranslation(['authn'])
  return (
    <Box
      fill="horizontal"
      direction="row"
      justify="end"
      gap="medium"
      border={{
        color: 'border-weak',
        size: 'xsmall',
        style: 'solid',
        side: 'top'
      }}
      pad={{ top: 'medium' }}
      margin={{ top: 'medium' }}
    >
      {/* Sometimes if the reset button is clicked quickly after making a form change and
          the form change fails validation with a blur setting, the form doesn't reset.
          The reset button click doesn't seem to register. This is intermittently reproducible here:
          https://storybook.grommet.io/?path=/story/input-form--validate-on-blur
          Grommet issue filed: https://github.com/grommet/grommet/issues/4863. Since it doesn't
          seem to be our issue, leaving as-is. The workaround is to reset less quickly
          or to click reset again. */}
      <Button
        default
        testId="profile-button-discard"
        label={t('authn:preferences.discard_changes')}
        size="medium"
        type="reset"
        disabled={!edited}
      />

      <Button
        primary
        testId="profile-button-submit"
        type="submit"
        label={updateLabel}
        size="medium"
      />
    </Box>
  )
}

export default ProfileButtons

ProfileButtons.propTypes = {
  updateLabel: PropTypes.string.isRequired,
  edited: PropTypes.bool.isRequired
}
