// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box, Grid, PageContent, PageHeader, ResponsiveContext } from 'grommet'
import { ShareRounded } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Card, Typography, Button } from '../../../components'
import { Layout } from '../../commoncomponents/layout/Layout'

const getLaunchPadRegions = async () => {
  try {
    const response = await fetch('/regions.json')
    const fetchedRegionsList = await response.json()
    const regionsList = fetchedRegionsList?.['launchpad-config']?.regions
    return regionsList
  } catch (error) {
    console.error('Error fetching regions', error)
    return null
  }
}

const LaunchPadContent = () => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation('dashboard')
  const [launchPadRegions, setLaunchPadRegions] = useState([])
  const history = useHistory()
  const header = document.querySelector('greenlake-header')

  const brand = header.shadowRoot.querySelector('hpe-brand')
  brand.ready = false

  const style = document.createElement('style')
  style.innerHTML = `
    greenlake-header::part(user-user-profile-dropdown-hpe-account-details),
    greenlake-header::part(user-user-profile-dropdown-hpe-greenlake-preferences),
    greenlake-header::part(user-user-profile-dropdown-visit-hpe),
    greenlake-header::part(workspace-menu)
     {
      display: none;
    }
  `
  document.getElementsByTagName('head')[0].appendChild(style)

  useEffect(() => {
    const fetchData = async () => {
      const regions = await getLaunchPadRegions()
      setLaunchPadRegions(regions)
      if (!regions || regions.length === 0) {
        history.push('/onboarding/choose-account')
      }
    }
    fetchData()
  }, [history])

  const handleRegionCardClick = (currentItem) => {
    const routeTo = `${currentItem.fqdn}/onboarding/choose-account?login_hint=${oidcUser?.profile?.email}`
    window.open(routeTo, '_blank')
  }
  const size = useContext(ResponsiveContext)
  const gridElements = {
    columns: {
      small: ['flex'],
      medium: { count: 1, size: 'flex' },
      large: { count: 1, size: 'flex' },
      xlarge: { count: 1, size: 'flex' }
    }
  }

  return (
    <Box>
      <PageContent data-testid="launchpad">
        <Box data-testid="launchpad-pageHeader">
          <PageHeader
            responsive
            title={t('dashboard:launchpad.title')}
            subtitle={t('dashboard:launchpad.subtitle')}
          />
        </Box>
        <Grid
          columns={gridElements.columns[size]}
          gap="small"
          margin={{ top: 'medium', bottom: 'large' }}
          width={{ max: 'large' }}
          data-testid="regions-container"
        >
          {launchPadRegions?.map((item) => (
            <Card
              key={item.fqdn}
              cardWidth="auto"
              align="center"
              cardHeight="auto"
              direction="row"
              title={
                <Typography type="heading" level={2}>
                  {item.name}
                </Typography>
              }
              action={
                <Button
                  icon={<ShareRounded />}
                  testId="launch-button"
                  label={t('dashboard:common.launch')}
                  onClick={() => handleRegionCardClick(item)}
                  secondary
                  reverse
                />
              }
              testId={`${item.name}-card`}
            />
          ))}
        </Grid>
      </PageContent>
    </Box>
  )
}

const Launchpad = () => {
  return (
    <Layout hideHeaderOptions={['nav', 'bell', 'apps', 'help', 'context']}>
      <Box gap="large" pad={{ top: 'medium' }} align="center">
        <LaunchPadContent />
      </Box>
    </Layout>
  )
}
export default Launchpad
