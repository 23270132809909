import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable as GrommetTable } from 'grommet'
import { FormDown, CircleInformation } from 'grommet-icons'
import debounce from 'lodash/debounce'
import isArray from 'lodash/isArray'

import { Loader } from '../loader/Loader'
import { Typography } from '../typography/Typography'
import { dimensions } from '../utils'
import ActionButton from '../action-button/ActionButton'
import { Pagination } from '../pagination/Pagination'
import { Search } from '../search/Search'
import { Ruler } from '../ruler/Ruler'
import { Notification } from '../notification/Notification'

// Summary info displayed on top of the table indicating number of selected records, number
// of total records and the entity name
const TableSummary = ({
  numOfSelected,
  numOfRecords,
  summaryText,
  entityName,
  isLoading
}) => {
  let summaryComp = null
  if (summaryText) {
    summaryComp = (
      <Box direction="row" gap="xxsmall">
        <Typography type="paragraph">{summaryText}</Typography>
      </Box>
    )
  } else if (numOfSelected > 0) {
    const summaryInfo = `${numOfSelected} of ${numOfRecords} ${entityName} selected`

    summaryComp = (
      <Box direction="row" gap="xxsmall">
        <Typography type="text">{summaryInfo}</Typography>
      </Box>
    )
  } else if (!isLoading) {
    summaryComp = (
      <Box direction="row" gap="xxsmall">
        <Typography type="text">{numOfRecords}</Typography>
        <Typography type="text">{entityName}</Typography>
      </Box>
    )
  } else {
    summaryComp = <Typography type="text">{`Loading ${entityName}`}</Typography>
  }

  return <>{summaryComp}</>
}

TableSummary.propTypes = {
  /**
   * Number of selected records
   */
  numOfSelected: PropTypes.number,

  /**
   * Total number of records
   */
  numOfRecords: PropTypes.number,

  /**
   * Text for the summary displayed in table; pass in a localized string; default is ''
   */
  summaryText: PropTypes.string,

  /**
   * deprecated - prioritize use of summaryText else
   * entityName is the name of entity displayed; default is 'item(s)'
   */
  entityName: PropTypes.string,

  /**
   * True, if table is loading
   */
  isLoading: PropTypes.bool.isRequired
}

TableSummary.defaultProps = {
  numOfSelected: 0,
  numOfRecords: 0,
  summaryText: '',
  entityName: 'item(s)'
}

export const DataTable = ({
  grid,
  summary,
  selection,
  search,
  filterButton,
  testId,
  pagination,
  showFilter,
  toolbar,
  loading,
  extraTopSection,
  button,
  margin,
  ...rest
}) => {
  // value to serach on
  const defaultVal = (search && search.defaultVal) || ''
  const [searchValue, setSearchValue] = React.useState(defaultVal || '')
  useEffect(() => {
    if (search && Object.prototype.hasOwnProperty.call(search, 'defaultVal')) {
      setSearchValue(search.defaultVal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVal])
  const [debouncedSearchValue, setDebouncedSearchValue] =
    React.useState(defaultVal)

  // state to show warningNotification if no action is selected
  const [warningMsg, setWarningMsg] = useState('')

  // state to show alert notification if user select reaches or exceeds limit
  const [alertMsg, setAlertMsg] = useState('')
  // state to track loading indicator
  const [isLoading, setIsLoading] = useState(true)
  // array of selected items
  const [selectionArr, setSelectionArr] = useState([])

  // UX wanted minimum number of rows per page as 10
  const minNumberRowsPerPage = 10

  /**
   * TODO: Change this to detect row click instead
   */
  const interactiveElementsInDataTable = {
    checkbox: true,
    button: true,
    svg: true,
    path: true
  }

  const {
    columns,
    data,
    height,
    onClickRow,
    defaultActions,
    pad,
    ...restGrid
  } = grid

  const initialSelection = selection && selection.initSelection

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchValue = useCallback(
    debounce((value) => {
      setDebouncedSearchValue(value)
    }, 500),
    []
  )

  useEffect(() => {
    if (selection || search) {
      setIsLoading(true)
    }
    if (selection && selection.onSelectionChange) {
      setSelectionArr([])
      selection.onSelectionChange([])
    }
    if (search) search.onSearchValueChange(debouncedSearchValue)
    return () => {
      // cancel debounce timeout on unmount
      handleDebouncedSearchValue.cancel()
    }
  }, [debouncedSearchValue]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      // reset loading indicator when new data is being set
      setIsLoading(false)
      setSelectionArr(initialSelection || [])
    }
  }, [data, initialSelection])

  const handleClickRow = (e) => {
    if (interactiveElementsInDataTable[e.target.type || e.target.tagName]) {
      e.stopPropagation()
      return
    }
    if (onClickRow) onClickRow(e)
  }

  let tableColumns = columns
  if (defaultActions) {
    tableColumns = [
      ...tableColumns,
      {
        property: 'Edit',
        sortable: false,
        header: '',
        plain: true,
        render: (datum) => {
          return (
            <ActionButton
              actions={defaultActions}
              datum={datum}
              testId="default-action-btn"
            />
          )
        }
      }
    ]
  }

  // Comparable data like numbers, dates etc will be aligned to the right, while other types of data, such as text and serial numbers, will be aligned to the left.
  tableColumns.forEach((col) => {
    if (!col.align) {
      if (col?.type && ['numeric', 'date'].includes(col?.type)) {
        col.align = 'end'
      } else {
        col.align = 'start'
      }
    }
  })

  tableColumns.forEach((col) => {
    if (!col.sortable) {
      col.sortable = false
    }
  })

  const getDisabledRowIds = () => {
    // check if the selection limit is reached and determine disabled selection
    if (selection?.limit && selectionArr.length >= selection.limit) {
      // create an array of all possible indexes (1-based)
      const allPrimaryKeys = Array.from(data, (elem) => {
        let val
        if (selection.primaryKey) val = elem[selection.primaryKey]
        else {
          const primaryCol = tableColumns.find((col) => col.primary)
          val = elem[primaryCol.property]
        }
        return val
      })

      // filter the indexes that are not included in the current selection
      const disabledSelectionIndexes = allPrimaryKeys.filter(
        (primaryKey) => !selectionArr.includes(primaryKey)
      )
      return disabledSelectionIndexes
    }
    return []
  }

  const table = (
    <Box fill="horizontal">
      <Box
        margin={margin}
        height={height}
        overflow={height ? 'auto' : { horizontal: 'auto' }}
      >
        <GrommetTable
          style={{ tableLayout: 'auto' }}
          sortable={tableColumns.some((col) => {
            return col.sortable
          })}
          select={
            selection && selection.onSelectionChange ? selectionArr : null
          }
          onSelect={
            selection && selection.onSelectionChange
              ? (select) => {
                  if (!select?.length) setWarningMsg('')
                  setAlertMsg('')

                  let sels
                  // check if selected amount reaches or exceeds limit
                  if (selection?.limit) {
                    if (select?.length > selection?.limit) {
                      if (selectionArr?.length) {
                        const itemsNotSelected = select.filter((item) => {
                          return !selectionArr.includes(item)
                        })

                        const remainingSel = itemsNotSelected.slice(
                          0,
                          selection?.limit - selectionArr?.length
                        )
                        sels = [...selectionArr, ...remainingSel]
                      } else {
                        sels = select.slice(0, selection.limit)
                      }
                    } else {
                      sels = select
                    }

                    if (sels?.length >= selection?.limit)
                      setAlertMsg(selection.limitMessage)
                  } else {
                    sels = select
                  }
                  setSelectionArr(sels)
                  // notify component user of selection change
                  selection.onSelectionChange(sels)
                }
              : null
          }
          primaryKey={selection ? selection.primaryKey : undefined}
          columns={[...tableColumns]}
          disabled={getDisabledRowIds()}
          data={data}
          fill="horizontal"
          // only use click handler when provided
          onClickRow={onClickRow ? handleClickRow : null}
          pad={pad}
          placeholder={
            isLoading || loading ? (
              <Box
                fill
                align="center"
                justify="center"
                direction="row"
                pad="large"
                gap="small"
                background={{ color: 'background-front', opacity: 'strong' }}
              >
                <Loader label="Loading..." testId="spinner-with-text" />
              </Box>
            ) : null
          }
          verticalAlign={{ body: 'top' }}
          data-testid="table"
          {...restGrid}
          {...rest}
        />
      </Box>
      {/* only show Ruler when totalItems of data table > items per single page in data table (or) 
      when you have rows per page feature ,show Ruler when total Items of data table > 10(UX recommended)   */}
      {pagination &&
        (pagination?.totalItems > pagination?.itemsPerPage ||
          (pagination?.setItemsPerPage &&
            pagination?.totalItems > minNumberRowsPerPage)) && (
          <Ruler margin={{ top: 'none', bottom: 'xsmall' }} />
        )}
      {pagination && (
        <Pagination
          itemsPerPage={pagination?.itemsPerPage}
          totalItems={pagination?.totalItems}
          setItemsPerPage={pagination?.setItemsPerPage}
          rowDropDownLabel={pagination?.rowDropDownLabel}
          defaultRowsValue={pagination?.defaultRowsValue}
          page={pagination?.page}
          setPage={({ page: nextPage }) => {
            const updatedPage = !nextPage ? 1 : nextPage
            pagination.setPage(updatedPage)
          }}
          pageIdxInfo={pagination?.pageIdxInfo}
          rowCountOptions={pagination?.rowCountOptions}
          testId={`pagination-${testId}`}
        />
      )}
    </Box>
  )
  const getActionBtn = () => {
    const formattedActions = selection?.bulkActions?.actions?.map((action) => {
      const selectionBased = action?.selectionBased !== false // default to true unless selectionBased is explicitly false
      return {
        ...action,
        onClick: () => {
          if (selectionArr?.length || !selectionBased) {
            setWarningMsg('')
            action.onClick()
          } else {
            setWarningMsg(selection?.selectionInfoMessage)
          }
        }
      }
    })
    return (
      <ActionButton
        label={selection?.bulkActions?.actionDropdown?.label}
        icon={<FormDown />}
        showOneActionAsDropDown
        actions={formattedActions}
        customRenderer={selection?.bulkActions?.customRenderer}
        testId="bulk-actions"
        dropAlign={{
          right: 'right',
          top: 'bottom'
        }}
        reverse
      />
    )
  }

  const bulkActions = () => {
    let actionBtn = <></>
    if (
      selection?.bulkActions?.actions &&
      isArray(selection?.bulkActions?.actions)
    ) {
      if (!selection?.bulkActions?.actions.filter((a) => !a.hidden).length) {
        actionBtn = <></>
      } else if (
        selection?.bulkActions?.customRenderer &&
        selection?.bulkActions?.actionDropdown?.visibility
      ) {
        actionBtn = selection?.bulkActions?.customRenderer(
          getActionBtn(),
          selection?.bulkActions?.actionDropdown?.visibility,
          selection?.primaryKey
        )
      } else {
        actionBtn = getActionBtn()
      }
    } else {
      actionBtn = (
        <Box style={{ whiteSpace: 'nowrap' }} data-testid="bulk-actions">
          {selection?.bulkActions}
        </Box>
      )
    }
    return actionBtn
  }

  return (
    <Box direction="column" data-testid={testId}>
      {(search || selection || showFilter) && (
        <Box
          direction={toolbar?.direction || 'row'}
          gap="large"
          align={toolbar?.direction === 'column' ? 'start' : 'end'}
          fill="horizontal"
          flex={false}
          justify="between"
          pad="none"
        >
          {search?.onSearchValueChange ? (
            <Box
              align={toolbar?.direction === 'column' ? 'start' : 'center'}
              direction={toolbar?.direction || 'row'}
              fill="horizontal"
              gap="small"
              pad="none"
            >
              <Search
                width={search.width}
                value={searchValue}
                handleCustomSearch={(value) => {
                  setSearchValue(value)
                  handleDebouncedSearchValue(value)
                }}
                placeholder={search.placeholder}
                testId="search-field"
                responsive={search.responsive}
              />
              <Box width="100%">{filterButton || null}</Box>
              {button || null}
            </Box>
          ) : (
            // do not use showFilter prop, this will be cleaned up
            <>{showFilter && <Box>{filterButton}</Box>}</>
          )}
          {selection?.bulkActions && bulkActions()}
        </Box>
      )}

      {summary && (
        <Box align="start" pad={{ top: 'xsmall' }} data-testid="table-summary">
          <TableSummary
            numOfSelected={selectionArr.length}
            numOfRecords={
              pagination ? pagination.totalItems : data && data.length
            }
            summaryText={summary.summaryText}
            entityName={summary.entityName}
            isLoading={isLoading}
          />
        </Box>
      )}
      {warningMsg && !selectionArr?.length ? (
        <Box margin={{ top: 'medium' }}>
          <Notification
            testId="status-warning-notification"
            text={warningMsg}
            backgroundColor="status-warning"
            type="inline"
          />
        </Box>
      ) : null}
      {alertMsg && selectionArr?.length >= selection.limit ? (
        <Box margin={{ top: 'medium' }}>
          <Notification
            testId="limit-alert-notification"
            text={alertMsg}
            backgroundColor="status-unknown"
            icon={
              <CircleInformation
                style={{ marginTop: '3px', marginLeft: '4px' }}
              />
            }
            type="inline"
          />
        </Box>
      ) : null}
      {extraTopSection}
      <Box
        basis="100%"
        align="center"
        justify="center"
        pad={
          (search && search.onSearchValueChange) || summary
            ? { top: 'medium' }
            : null
        }
      >
        {selection && selection.onSelectionChange ? (
          <Box fill="horizontal">{table}</Box>
        ) : (
          table
        )}
      </Box>
    </Box>
  )
}

DataTable.propTypes = {
  grid: PropTypes.shape({
    /**
     * array of objects representing table columns
     */
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        property: PropTypes.string.isRequired,
        // don't allow passing in element; Safari crashes with selectable
        // tables if an element is passed in
        header: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
          .isRequired,
        primary: PropTypes.bool,
        render: PropTypes.func,
        size: PropTypes.oneOf(dimensions),
        search: PropTypes.bool,
        sortable: PropTypes.bool,
        units: PropTypes.string,
        hidden: PropTypes.bool,
        /**
         * if no type is provided than columns will be by default treated as string and will be left aligned.
         * To Override pass align property.
         */
        type: PropTypes.oneOf(['numeric', 'string', 'date'])
      })
    ).isRequired,
    /**
     * Type can be 'numeric', 'string' and 'date' which will be used to align column data with its header.
     */

    /**
     * array of objects representing table data; original value must be undefined or
     * loading will not display on first load
     */
    data: PropTypes.arrayOf(PropTypes.object),

    /**
     * handler triggered on row click
     */
    onClickRow: PropTypes.func,

    /**
     * Size at which rows start scrolling; this is the same as grommet's DataTable size property
     * If set, all columns will be the same width and table won't be resizeable.
     */
    height: PropTypes.oneOf(dimensions),

    /**
     * Array of default actions. A DropButton will dispaly if multiple actions; otherwise, action will display.
     */
    defaultActions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.element,
        onClick: PropTypes.func.isRequired
      })
    ),

    /**
     * Allow body pad only for now
     */
    pad: PropTypes.shape({
      body: PropTypes.shape({
        horizontal: PropTypes.oneOf(dimensions),
        vertical: PropTypes.oneOf(dimensions)
      })
    })
  }).isRequired,

  summary: PropTypes.shape({
    /**
     * Use this prop if the summary details is localized; pass in a localized string to display as summaryText
     * Consider this use for loading scenarios and selection scenerios; default is ''
     */
    summaryText: PropTypes.string,

    /**
     * deprecated - prioritize the use of summaryText else
     * entityName is the name of entity displayed; default is 'item(s)'
     */
    entityName: PropTypes.string
  }),

  selection: PropTypes.shape({
    /**
     * use to limit dataTable selection, checkboxes would be disabled after the limit is reached
     *  */
    limit: PropTypes.number,

    /**
     * this is the message that will be displayed on top of the grid once selected reach the limit defined above
     *  */
    limitMessage: PropTypes.string,
    /**
     * initial selected items (checkbox will be checked)
     */

    initSelection: PropTypes.array,

    /**
     * handler called on any selection changes
     */
    onSelectionChange: PropTypes.func.isRequired,

    /**
     * primary key has to be a string that matches a unique field key of row data (grid.data)
     * primary key also support "dot paths" expression to reference nested value, for example, 'contact.email'
     */
    primaryKey: PropTypes.string.isRequired,
    bulkActions: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.shape({
        actionDropdown: PropTypes.shape({
          /**
           * label for action dropdown
           */
          label: PropTypes.string,
          /**
           * visibility:{rbac:{},hideFor:{}} 'rbac permissions and hideFor  for Action dropdown through Visibility wrapper ,
           */
          visibility: PropTypes.object
        }),
        /**
         *  customRenderer: (element, visibility,index) => {}
         */
        customRenderer: PropTypes.func,
        actions: PropTypes.arrayOf(
          PropTypes.shape({
            /**
             * label for individual actions
             */
            label: PropTypes.string,
            /**
             *  icon for individual action
             */
            icon: PropTypes.element,
            /**
             * If true, action is hidden
             */
            hidden: PropTypes.bool,
            /**
             * click functioncality of individual action
             */
            onClick: PropTypes.func.isRequired,
            /**
             * visibility:{rbac:{},hideFor:{}} 'rbac permissions and hideFor  for individual actions through Visibility wrapper ,
             */
            visibility: PropTypes.object,
            /**
             * data-testid, will be used for testing
             */
            testId: PropTypes.string,
            /**
             * selectionBased: If we need the action to be performed without selecting any rows in data table
             * default value: true
             */
            selectionBased: PropTypes.bool
          })
        )
      })
    ]),
    /**
     * info message displayed when no row in the Data table is selected and when you click on Actions
     *  TODO: please change selectionInfoMessage to required once evrey Bulk actions change to Action dropdown
     */
    selectionInfoMessage: PropTypes.string
  }),

  /**
   * search object containing search related properties
   */
  search: PropTypes.shape({
    /**
     * width of the search field; default is medium
     */
    width: PropTypes.oneOf(dimensions),
    /**
     * default search value for the datatable
     */
    defaultVal: PropTypes.string,

    /**
     * string repesenting search field's placeholder text
     */
    placeholder: PropTypes.string.isRequired,

    /**
     * handler called on any search value changes
     */
    onSearchValueChange: PropTypes.func.isRequired,

    /**
     * Whether to change search into a drop-down at small sizes
     */
    responsive: PropTypes.bool
  }),

  /**
   * FilterButton component to display next to search if provided
   */
  filterButton: PropTypes.element,

  /**
   * data-testid, will be used for testing
   */
  testId: PropTypes.string.isRequired,

  pagination: PropTypes.shape({
    totalItems: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number,
    setItemsPerPage: PropTypes.func,
    page: PropTypes.number,
    setPage: PropTypes.func,
    pageIdxInfo: PropTypes.string,
    rowDropDownLabel: PropTypes.string,
    defaultRowsValue: PropTypes.number,
    rowCountOptions: PropTypes.arrayOf(PropTypes.number)
  }),

  // don't use this prop, this will cleaned up
  showFilter: PropTypes.bool,

  toolbar: PropTypes.shape({
    direction: PropTypes.oneOf(['column', 'row'])
  }),

  margin: PropTypes.object,

  // This allows the user to set when the data table loader shows
  // The internal loading condition is called isLoading
  // This one (external) is called just loading
  loading: PropTypes.bool,
  extraTopSection: PropTypes.any,

  /**
   * Allow user to add button
   */
  button: PropTypes.element
}

DataTable.defaultProps = {
  summary: undefined,
  selection: undefined,
  search: undefined,
  filterButton: undefined,
  pagination: undefined,
  showFilter: undefined,
  toolbar: undefined,
  loading: false,
  extraTopSection: null,
  button: undefined,
  margin: undefined
}
