import React from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'

import { Typography, DataTable } from '../../../components'

import { getApprovalState } from './utils'

const ApprovalDetails = ({ notificationDetails }) => {
  const { t } = useTranslation(['manage'])

  const reviewerColumns = [
    {
      property: 'reviewer',
      type: 'string',
      header: t('notification.approval.reviewer'),
      primary: true,
      render: (datum) => (
        <Box direction="column">
          <Typography
            size="medium"
            type="text"
            weight="bold"
            testId="page-title"
            color="text-weak"
          >
            {`${datum?.first_name} ${datum?.last_name}`}
          </Typography>
          <Typography size="small" type="text" testId="page-title">
            {datum?.id}
          </Typography>
        </Box>
      )
    },
    {
      property: 'comment',
      type: 'string',
      header: t('notification.approval.comment'),
      render: (datum) => (
        <Box width={{ max: 'small', min: 'small' }}>
          <Typography
            level="small"
            type="text"
            testId="page-title"
            wordBreak="break-word"
          >
            {datum?.comment ? datum?.comment : '--'}
          </Typography>
        </Box>
      )
    },
    {
      property: 'approval_action',
      type: 'string',
      header: t('notification.approval.approval_status'),
      render: (datum) => (
        <Typography level="small" type="text" testId="page-title">
          {getApprovalState(datum?.approver_action, t)}
        </Typography>
      )
    }
  ]

  return (
    <Box
      pad={{ horizontal: 'xlarge', top: 'none', right: 'xsmall' }}
      width="large"
      justify="between"
      align="start"
      alignSelf="center"
    >
      <Box
        direction="row"
        pad={{ bottom: 'small', top: 'xsmall' }}
        margin={{ bottom: 'small' }}
        border={{ side: 'top', color: 'border-weak', size: 'small' }}
        width="large"
      >
        <Box pad={{ bottom: 'small', top: 'small', right: 'small' }}>
          <Typography
            level="2"
            type="heading"
            weight="bold"
            testId="approval-status-title"
            margin="none"
          >
            {t('notification.approval.approval_status')}:
          </Typography>
        </Box>
        <Box
          pad={{ top: 'small', bottom: 'small' }}
          data-testid="notification-summary"
        >
          <Typography
            level="2"
            type="heading"
            weight="bold"
            testId="approval-status"
            margin="none"
          >
            {getApprovalState(notificationDetails?.approval_state, t)}
          </Typography>
        </Box>
      </Box>
      {notificationDetails?.reviewers?.length > 0 && (
        <Box
          direction="column"
          pad={{ bottom: 'small', top: 'none' }}
          margin={{ bottom: 'small' }}
        >
          <Box
            border={{ side: 'bottom', color: 'border-weak' }}
            pad={{ bottom: 'small', top: 'small' }}
          >
            <Typography
              level="2"
              type="heading"
              weight="bold"
              testId="reviewer-title"
            >
              {t('notification.approval.reviewer')}
            </Typography>
          </Box>
          <Box>
            <DataTable
              grid={{
                columns: reviewerColumns,
                data: notificationDetails?.reviewers || []
              }}
              testId="approval-details-datatable"
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

ApprovalDetails.prototypes = {
  notificationDetails: PropTypes.object
}

export default ApprovalDetails
