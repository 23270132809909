import {
  isStagingCluster,
  isIntegrationCluster,
  isQACluster,
  isProductCluster
} from '../../../utils/feature-flag-utils'

export function getEnvConfig() {
  if (isQACluster() || isIntegrationCluster() || isStagingCluster()) {
    return {
      COVEO_ORG_ID: 'hewlettpackardnonproduction2mogyl6nm',
      COVEO_ORG_ENVIRONMENT: 'prod',
      SUPPORT_CENTER_DOMAIN: 'https://ext-itg.support.hpe.com'
    }
  }
  if (isProductCluster()) {
    return {
      COVEO_ORG_ID: 'hewlettpackardproductioniwmg9b9w',
      COVEO_ORG_ENVIRONMENT: 'prod',
      SUPPORT_CENTER_DOMAIN: 'https://support.hpe.com'
    }
  }

  return {
    COVEO_ORG_ID: 'hewlettpackardnonproduction2mogyl6nm',
    COVEO_ORG_ENVIRONMENT: 'prod',
    SUPPORT_CENTER_DOMAIN: 'https://ext-itg.support.hpe.com'
  }
}
