import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CheckBoxGroup, FormField, SelectMultiple } from 'grommet'

const Facet = ({ controller, title, facetFlag }) => {
  const { t } = useTranslation(['support_hub_search', 'location'])
  const [facetState, setFacetState] = useState({
    options: controller.state.values.map((item) => ({
      value: item.value,
      state: item.state,
      numberOfResults: item.numberOfResults
    })),
    searchTerm: '',
    showSelectMultiple: controller.state.values.length > 4,
    initialRender: true,
    searching: false
  })
  let dataFacet

  const getLocalizedOption = useCallback(
    (option) => {
      const translationKey = `${controller.state.facetId}.${option}`
      const translatedValue = t(translationKey)
      return translatedValue !== translationKey ? translatedValue : option
    },
    [controller.state.facetId, t]
  )

  const translatedOptions = facetState.options.map((option) => {
    return {
      // Pass option.value to getLocalizedOption
      label: getLocalizedOption(option.value)?.replace('/', '/\u200B'), // Insert 0 width space
      value: option.value,
      state: option.state,
      numberOfResults: option.numberOfResults
    }
  })

  const sortedOptions = translatedOptions
    .filter((option) => option.label !== undefined) // Filter out options with undefined labels
    .sort((a, b) => a.label.localeCompare(b.label))

  useEffect(() => {
    if (controller) {
      const updateFacetState = () => {
        const updatedOptions = controller.state.values
          .filter((value) =>
            (getLocalizedOption(value.value)
              ? getLocalizedOption(value.value).toLowerCase()
              : value.value.toLowerCase()
            ).includes(facetState.searchTerm.toLowerCase())
          )
          .map((value) => ({
            value: value.value,
            state: value.state,
            numberOfResults: value.numberOfResults
          }))

        setFacetState((prevState) => ({
          ...prevState,
          options: updatedOptions
        }))
      }

      const unsubscribe = controller.subscribe(updateFacetState)

      // Initial update to state
      updateFacetState()

      return () => {
        unsubscribe()
      }
    }

    return undefined // Explicitly return undefined if controller is not available
  }, [controller, facetState.searchTerm, getLocalizedOption])

  if (
    !controller.state.values.filter(
      (value) => value.state !== 'idle' || value.numberOfResults > 0
    ).length
  ) {
    return null
  }

  const handleFacetValuesChange = (nextValues) => {
    if (Array.isArray(nextValues)) {
      // Check if any value is currently selected only for multiple selections/deselections
      const anyValueSelected = controller.state.values.some((facetValue) =>
        controller.isValueSelected(facetValue)
      )

      if (anyValueSelected) {
        // If any value is selected, trigger deselectAll on clear all or select all
        controller.deselectAll()
        return
      }

      // Handle "Select All" or "Clear All"
      nextValues.forEach((item) => {
        const valueToToggle = controller.state.values.find(
          (facetValue) => facetValue.value === (item.value || item)
        )
        if (valueToToggle) {
          controller.toggleSelect(valueToToggle)
        }
      })
    } else {
      // Single value selection
      const valueToToggle = controller.state.values.find(
        (facetValue) => facetValue.value === nextValues.value
      )
      if (valueToToggle) {
        controller.toggleSelect(valueToToggle)
      }
    }
  }

  const handleCheckBoxFacetChange = (nextValues) => {
    const nextValueStrings = nextValues.map((item) => item.value || item)

    controller.state.values.forEach((facetValue) => {
      const isSelected = controller.isValueSelected(facetValue)
      const shouldSelect = nextValueStrings.includes(facetValue.value)

      if (isSelected !== shouldSelect) {
        controller.toggleSelect(facetValue)
      }
    })
  }

  const handleSearch = (text) => {
    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
    const exp = new RegExp(escapedText, 'i')

    const filteredValues = controller.state.values.filter((value) => {
      const valueToMatch = getLocalizedOption(value.value) || value.value
      return exp.test(valueToMatch)
    })

    setFacetState((prevState) => ({
      ...prevState,
      searchTerm: text,
      options: filteredValues.map((value) => value.value),
      searching: true // Indicate that a search is in progress
    }))
  }

  const facet = (
    <SelectMultiple
      id={title}
      placeholder={t('location:select')}
      searchPlaceholder={t('support_hub_search:facet_search_placeholder')}
      data-testid="facet-checkbox"
      dropHeight="medium"
      labelKey="label"
      valueKey="value"
      value={controller.state.values
        .filter((facetValue) => controller.isValueSelected(facetValue))
        .map((facetValue) => {
          return facetValue.value
        })}
      onChange={(nextValues) => {
        handleFacetValuesChange(nextValues.option)
      }}
      options={sortedOptions}
      showSelectedInline={facetFlag}
      margin={facetFlag ? 'xsmall' : undefined}
      dropAlign={facetFlag ? { top: 'top' } : undefined}
      onSearch={controller.state.values.length >= 10 ? handleSearch : undefined}
      onClose={() => {
        setFacetState((prevState) => ({
          ...prevState,
          options: controller.state.values.map((item) => ({
            value: item.value,
            state: item.state,
            numberOfResults: item.numberOfResults
          })),
          searchTerm: '' // Reset the search term when closing the facet
        }))
      }}
      messages={{
        showMore: `+ ${t('select_multiple.show_more', {
          remaining: '{remaining}'
        })}`,
        clearAll: t('select_multiple.clear_all'),
        selectAll: t('select_multiple.select_all'),
        selected: t('select_multiple.selected', {
          selected: '{selected}'
        }),
        summarizedValue: t('select_multiple.summarized_value', {
          selected: '{selected}',
          total: '{total}'
        })
      }}
    />
  )

  if (
    facetState.initialRender &&
    facetState.options.length <= 4 &&
    !facetState.searching
  ) {
    dataFacet = (
      <CheckBoxGroup
        id={title}
        data-testid="check-box-facet"
        options={sortedOptions.map((value) => {
          return value
        })}
        labelKey="label"
        onChange={({ value: nextValues }) => {
          handleCheckBoxFacetChange(nextValues)
        }}
        value={controller.state.values
          .filter((facetValue) => controller.isValueSelected(facetValue))
          .map((facetValue) => facetValue.value)}
      />
    )
  } else {
    dataFacet = facet
  }

  return (
    <FormField label={t(title)} data-testid={title}>
      {!facetFlag ? facet : dataFacet}
    </FormField>
  )
}

Facet.propTypes = {
  controller: PropTypes.shape({
    state: PropTypes.object,
    subscribe: PropTypes.func,
    showMoreValues: PropTypes.func,
    showLessValues: PropTypes.func,
    isValueSelected: PropTypes.func,
    toggleSelect: PropTypes.func,
    facetSearch: PropTypes.object,
    unsubscribe: PropTypes.func,
    deselectAll: PropTypes.func
  }).isRequired,
  title: PropTypes.string.isRequired,
  facetFlag: PropTypes.bool.isRequired
}

export default Facet
