import {
  validateIPAddress,
  validateIPv6Address,
  validateSubnetMask,
  validateIpv6SubnetMask
} from '../../../utils/validation-utils'
import { post, put } from '../../../utils/api-utils'
import { getApiErrorMessage } from '../../../utils/error-handling-utils'

export const addAccessRuleDynamicFields = (
  t,
  protocolVersion,
  ipv6LdFlagValue
) => {
  let singleIpLabel = ''
  let ipRangeStartingLabel = ''
  let ipRangeEndingLabel = ''
  let ipSubnetMaskLabel = ''
  let ipSubnetMaskSubLabel = ''

  if (protocolVersion === 'IPv4') {
    singleIpLabel = t('ip_access_rules.ipv4_address')
    ipRangeStartingLabel = t('ip_access_rules.starting_ipv4_addr')
    ipRangeEndingLabel = t('ip_access_rules.ending_ipv4_addr')
    ipSubnetMaskLabel = t('ip_access_rules.ipv4_subnet_mask')
    ipSubnetMaskSubLabel = t('ip_access_rules.ipv4_use_cidr_notation')
  } else if (protocolVersion === 'IPv6') {
    singleIpLabel = t('ip_access_rules.ipv6_address')
    ipRangeStartingLabel = t('ip_access_rules.starting_ipv6_addr')
    ipRangeEndingLabel = t('ip_access_rules.ending_ipv6_addr')
    ipSubnetMaskLabel = t('ip_access_rules.ipv6_subnet_mask')
    ipSubnetMaskSubLabel = t('ip_access_rules.ipv6_use_cidr_notation')
  } else {
    singleIpLabel = t('ip_access_rules.ip_address')
    ipRangeStartingLabel = t('ip_access_rules.starting_ip_addr')
    ipRangeEndingLabel = t('ip_access_rules.ending_ip_addr')
    ipSubnetMaskLabel = t('ip_access_rules.ip_subnet_mask')
  }
  return {
    SINGLE_IP: [
      {
        name: 'single_ip',
        label: ipv6LdFlagValue
          ? singleIpLabel
          : t('ip_access_rules.ip_address'),
        example: {
          IPV4: t('ip_access_rules.single_ipv4_example'),
          IPV6: t('ip_access_rules.single_ipv6_example')
        },
        placeholder: {
          IPV4: t('ip_access_rules.ipv4_placeholder'),
          IPV6: t('ip_access_rules.ipv6_placeholder')
        },
        validate: {
          IPV4: validateIPAddress,
          IPv6: validateIPv6Address
        }
      }
    ],
    IP_SUBNET_MASK: [
      {
        name: 'ip_subnet_mask',
        label: ipv6LdFlagValue
          ? ipSubnetMaskLabel
          : t('ip_access_rules.ip_subnet_mask'),
        ...(ipv6LdFlagValue && { ipSubnetMaskSubLabel }),
        example: {
          IPV4: t('ip_access_rules.ipv4_subnet_example'),
          IPV6: t('ip_access_rules.ipv6_subnet_example')
        },
        placeholder: {
          IPV4: t('ip_access_rules.ipv4_subnet_placeholder'),
          IPV6: t('ip_access_rules.ipv6_subnet_placeholder')
        },
        validate: {
          IPV4: validateSubnetMask,
          IPv6: validateIpv6SubnetMask
        }
      }
    ],
    IP_RANGE: [
      {
        name: 'starting_ip',
        label: ipv6LdFlagValue
          ? ipRangeStartingLabel
          : t('ip_access_rules.starting_ip_addr'),
        example: {
          IPV4: t('ip_access_rules.ipv4_range_example'),
          IPV6: t('ip_access_rules.ipv6_range_example')
        },
        placeholder: {
          IPV4: t('ip_access_rules.ipv4_placeholder'),
          IPV6: t('ip_access_rules.ipv6_placeholder')
        },
        validate: {
          IPV4: validateIPAddress,
          IPv6: validateIPv6Address
        }
      },
      {
        name: 'ending_ip',
        label: ipv6LdFlagValue
          ? ipRangeEndingLabel
          : t('ip_access_rules.ending_ip_addr'),
        placeholder: {
          IPV4: t('ip_access_rules.ipv4_placeholder'),
          IPV6: t('ip_access_rules.ipv6_placeholder')
        },
        validate: {
          IPV4: validateIPAddress,
          IPv6: validateIPv6Address
        }
      }
    ]
  }
}

export const formatRuleData = (rule) => {
  return {
    ip_access_rule_id: rule.ip_access_rule_id,
    desc: rule.description,
    protocolVersion: rule.protocol_version,
    ipFormat: rule.ip_rule_format,
    ...(rule.single_ip && { single_ip: rule.single_ip }),
    ...(rule.ip_subnet_mask && { ip_subnet_mask: rule.ip_subnet_mask }),
    ...(rule.starting_ip && { starting_ip: rule.starting_ip }),
    ...(rule.ending_ip && { ending_ip: rule.ending_ip })
  }
}

export const handleConfirmation = (
  t,
  onSuccess,
  setErrorMessage,
  data,
  mode,
  setOpen,
  oidcUser
) => {
  const { protocolVersion, ipFormat, desc, ...rest } = data
  const reqBody = {
    protocol_version: protocolVersion.toUpperCase(),
    ip_rule_format: ipFormat,
    description: desc,
    ...rest
  }
  delete reqBody.access_rule
  const editReqBody = { ...reqBody }
  delete editReqBody.ip_access_rule_id
  const apiCall =
    mode === 'add'
      ? post(`/accounts/ui/v1/ip-access-rule`, reqBody, oidcUser.access_token)
      : put(
          `/accounts/ui/v1/ip-access-rule?ip_access_rule_id=${reqBody.ip_access_rule_id}`,
          editReqBody,
          oidcUser.access_token
        )
  apiCall.then(
    () => {
      setErrorMessage('')
      onSuccess(
        mode === 'add'
          ? t('ip_access_rules.access_rule_created')
          : t('ip_access_rules.access_rule_updated')
      )
      setOpen(false)
    },
    (error) => {
      const errorMsg = getApiErrorMessage(error, t)
      setErrorMessage(errorMsg)
    }
  )
}
