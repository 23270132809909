import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, CheckBox, TextArea } from 'grommet'
import { StatusCritical } from 'grommet-icons'
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import {
  Typography,
  Dropdown,
  Notification
} from '../../../../../../../components'
import { statusOptions } from '../../../constants'
import {
  validateForm,
  validateEmail
} from '../../../../../../../utils/validation-utils'

const validateFields = (formValues, i18nTranslate) => {
  const requiredString = i18nTranslate('required')
  let emailValid
  const { emailAddresses, status } = formValues

  let emailMessage = emailAddresses ? '' : requiredString
  const emails = emailAddresses.split(',')
  let invalidEmail
  let duplicateEmail
  const trimEmail = []

  emails.every((email) => {
    const trimmed_email = email?.trim()
    const validateFormat = validateEmail(trimmed_email, false)
    if (trimmed_email.length !== 0) {
      trimEmail?.push(trimmed_email)
    }
    if (validateFormat === i18nTranslate('notification.invalid_email')) {
      emailValid = false
      invalidEmail = email
      return false
    }
    emailValid = true
    return true
  })
  if (trimEmail?.length === 0) {
    emailValid = false
    invalidEmail = ''
  }
  if (emailAddresses) {
    if (!emailValid) {
      emailMessage = i18nTranslate('notification.invalid_email_message', {
        invalidEmail
      })
    }
  }
  let duplicates
  if (emailValid) {
    duplicates = trimEmail.filter(
      (item, index) => index !== trimEmail.indexOf(item)
    )

    if (duplicates.length > 0) {
      duplicates?.every((email) => {
        duplicateEmail = email
        emailMessage = i18nTranslate('notification.duplicate_email_error', {
          duplicateEmail
        })
        return false
      })
    }
  }

  const formValid = !!(
    emailAddresses &&
    emailValid &&
    status &&
    duplicates.length <= 0
  )
  return {
    emailAddresses: emailMessage,
    status: status ? '' : requiredString,
    isValid: formValid
  }
}

export const validateEmailTypeFields = (formValues, i18nTranslate) => {
  const res = validateForm(formValues, i18nTranslate, validateFields)
  return res
}

const EmailType = ({ setBannerCheck, setEmailCheck, isEdit }) => {
  const { t } = useTranslation(['manage'])
  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)

  const [errorMessage, setErrorMessage] = useState({})
  const [wizardError, setWizardError] = useState(null)

  const [disableEmail, setDisableEmail] = useState(true)
  const [emailChecked, setEmailChecked] = useState(false)

  const stateOptions = [t('notification.active'), t('notification.resolved')]
  const LDFlags = useFlags()

  useEffect(() => {
    if (attemptedAdvance) {
      const validation = validateFields(formValues, t)
      setErrorMessage(validation)
    }
    setBannerCheck(formValues?.banner)
    setEmailCheck(formValues?.email)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, attemptedAdvance, t, setBannerCheck, setEmailCheck])

  useEffect(() => {
    if (formValues?.status === 'Critical') {
      setDisableEmail(true)
      setEmailChecked(true)
      setFormValues({
        ...formValues,
        email: true
      })
    } else {
      setEmailChecked(false)
      setFormValues({
        ...formValues,
        email: false
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formValues?.status === 'Critical') {
      setDisableEmail(true)
      setEmailChecked(true)
      setFormValues({
        ...formValues,
        email: true
      })
    } else {
      setDisableEmail(true)
      setEmailChecked(false)
      setFormValues({
        ...formValues,
        email: false
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.status])

  return (
    <Box
      gap="medium"
      width="medium"
      justify="between"
      align="start"
      alignSelf="center"
    >
      <Box width="medium" margin={{ bottom: 'xsmall' }}>
        <FormField
          name="description"
          margin={{ bottom: 'none' }}
          error={errorMessage?.emailAddresses}
          data-testid="email-address-form-field"
          label={t('notification.email_label')}
          required
        >
          <TextArea
            size="medium"
            value={formValues.emailAddresses}
            disabled={isEdit}
            name="emailAddresses"
            placeholder={t('notification.email_address_placeholder')}
            data-testid="email-address-field"
          />
        </FormField>
      </Box>
      <Box width="medium" margin={{ bottom: 'xsmall' }}>
        <FormField
          label={t('notification.status')}
          name="status"
          required
          error={errorMessage.status}
        >
          <Dropdown
            name="status"
            testId="status-select-dropdown"
            placeholder={t('notification.placeholder_status')}
            noBorder
            value={formValues.status}
            options={statusOptions}
            onChangeDropdown={(values) => {
              setFormValues({
                ...formValues,
                status: values
              })
            }}
          />
        </FormField>
      </Box>
      {isEdit && (
        <Box width="medium" margin={{ bottom: 'xsmall' }}>
          <FormField label={t('notification.state')} name="notification_state">
            <Dropdown
              name="notification_state"
              testId="state-select-dropdown"
              placeholder={t('notification.placeholder_state')}
              noBorder
              value={formValues.state}
              options={stateOptions}
              onChangeDropdown={(values) => {
                setFormValues({
                  ...formValues,
                  state: values
                })
              }}
            />
          </FormField>
        </Box>
      )}
      {!LDFlags['glcp-notifications-feature-schedule-jhansi'] ? (
        <>
          <Box>
            <Typography
              type="text"
              size="small"
              color="black"
              testId="email-checkbox-description-label"
            >
              {t('notification.email_checkbox')}
            </Typography>
            <Typography
              type="text"
              size="small"
              color="black"
              testId="email-checkbox-description"
            >
              {t('notification.email_checkbox_description')}
            </Typography>
            <FormField
              margin={{ bottom: 'medium' }}
              name="banner"
              data-testid="email-notification-checkbox-formfield"
            >
              <CheckBox
                pad="none"
                data-testid="test-email-checkbox"
                name="banner"
                checked={emailChecked}
                disabled={disableEmail || isEdit}
                label={t('notification.email')}
              />
            </FormField>
          </Box>
          <Box>
            <Typography
              type="text"
              size="small"
              color="black"
              testId="banner-checkbox-label"
            >
              {t('notification.banner_checkbox')}
            </Typography>
            <Typography
              type="text"
              size="small"
              color="black"
              testId="banner-checkbox-description"
            >
              {t('notification.banner_checkbox_description')}
            </Typography>
            <FormField
              margin={{ bottom: 'medium' }}
              name="banner"
              data-testid="banner-checkbox-formfield"
            >
              <CheckBox
                pad="none"
                data-testid="test-banner-checkbox"
                name="banner"
                disabled={isEdit}
                checked={formValues.banner}
                label={t('notification.banner')}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    banner: event.target.checked
                  })
                }}
              />
            </FormField>
          </Box>
        </>
      ) : (
        <>
          {' '}
          <Box fill margin={{ bottom: 'medium', top: 'small' }}>
            <Typography type="heading" size="small" color="text-strong">
              {t('notification.delivery_medium')}
            </Typography>
          </Box>
          {emailChecked && (
            <Box margin={{ bottom: 'small' }}>
              <FormField
                name="email"
                data-testid="email-notification-checkbox-formfield"
              >
                <CheckBox
                  pad="none"
                  data-testid="test-email-checkbox"
                  name="banner"
                  checked={emailChecked}
                  disabled
                  label={t('notification.new_email_checkbox')}
                  onChange={(event) => {
                    setFormValues({
                      ...formValues,
                      email: event.target.checked
                    })
                  }}
                />
              </FormField>
              <Typography
                type="text"
                size="small"
                color="text-strong"
                testId="email-checkbox-description"
              >
                {t('notification.new_email_checkbox_description')}
              </Typography>
            </Box>
          )}
          <Box margin={{ bottom: 'small' }}>
            <FormField name="banner" data-testid="banner-checkbox-formfield">
              <CheckBox
                pad="none"
                data-testid="test-banner-checkbox"
                disabled={isEdit}
                name="banner"
                checked={formValues.banner}
                label={t('notification.banner')}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    banner: event.target.checked
                  })
                }}
              />
            </FormField>
            <Typography
              type="text"
              size="small"
              color="text-strong"
              testId="banner-checkbox-description"
            >
              {t('notification.new_banner_checkbox_description')}
            </Typography>
          </Box>
          <Box>
            <FormField name="portal" data-testid="portal-checkbox-formfield">
              <CheckBox
                pad="none"
                data-testid="test-portal-checkbox"
                disabled
                name="banner"
                checked
                label={t('notification.notification_center')}
              />
            </FormField>
            <Typography
              type="text"
              size="small"
              color="text-strong"
              testId="portal-checkbox-description"
            >
              {t('notification.portal_checkbox_description')}
            </Typography>
          </Box>
        </>
      )}
      {wizardError && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setWizardError(null)}
          testId="critical-inline-notification"
          text={wizardError}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}

EmailType.prototypes = {
  setBannerCheck: PropTypes.func.isRequired
}

export default EmailType
