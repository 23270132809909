import { get, getErrorMessage } from '../../../../utils/api-utils'

export const getApplications = ({ request, token, setErrorMessage, t }) => {
  const url = `/support-assistant/v1alpha1/applications`
  return get(url, request, token).then(
    (resp) => {
      setErrorMessage(null)
      return resp.data
    },
    (err) => {
      setErrorMessage(getErrorMessage(err, t))
      return {
        app_list: [],
        paginate: {
          total_count: 0
        }
      }
    }
  )
}

export const getApplicationDetails = ({
  applicationId,
  token,
  setErrorMessage,
  t
}) => {
  const url = `/support-assistant/v1alpha1/application`
  return get(`${url}/${applicationId}`, {}, token).then(
    (resp) => {
      setErrorMessage(null)
      return resp.data
    },
    (err) => {
      setErrorMessage(getErrorMessage(err, t))
      return null
    }
  )
}

export const getAppInstances = ({ request, token, setErrorMessage, t }) => {
  const url = `/support-assistant/v1alpha1/app-instances`
  return get(url, request, token).then(
    (resp) => {
      setErrorMessage(null)
      return resp.data
    },
    (err) => {
      setErrorMessage(getErrorMessage(err, t))
      return {
        instances: [],
        pagination: {
          total_count: 0
        }
      }
    }
  )
}

export const getAppInstanceDetails = ({
  appInstanceId,
  token,
  setErrorMessage,
  t
}) => {
  const url = `/support-assistant/v1alpha1/app-instance/${appInstanceId}`
  return get(url, {}, token).then(
    (resp) => {
      setErrorMessage(null)
      return resp.data
    },
    (err) => {
      setErrorMessage(getErrorMessage(err, t))
      return null
    }
  )
}
