import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { Typography } from '../../components'

const CardCarousel = ({ carousel }) => {
  return (
    carousel !== undefined &&
    Object.entries(carousel).map((obj) => (
      <Box margin={{ bottom: 'small' }} role="listitem">
        <Typography type="text" size="small">
          {`${obj[0]}:`}
        </Typography>
        {obj[0].toString() === 'Case Status' ? (
          <Typography type="text" size="small" color="brand" weight={600}>
            {obj[1]}
          </Typography>
        ) : (
          <Typography type="text" size="small" weight={600}>
            {obj[1]}
          </Typography>
        )}
      </Box>
    ))
  )
}

CardCarousel.propTypes = {
  carousel: PropTypes.array.isRequired
}

export default CardCarousel
