// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Previous, UserManager } from 'grommet-icons'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Typography, MenuList, Button } from '../../components'
import { customRenderer } from '../commoncomponents/CustomRenderer'

import ManageCcsRouter from './router'

const ManageCcs = ({ errorNotification }) => {
  const history = useHistory()
  const { t } = useTranslation(['manage', 'device', 'common'])
  const { pathname } = useLocation()

  const handleMenuItemClick = (item) => {
    history.push(item.routeTo)
  }

  const handleBackBtnClick = () => {
    history.push('/manage-ccs/home')
  }

  const menuData = [
    {
      id: 1,
      label: t('overview_tab'),
      routeTo: '/manage-ccs/overview',
      testId: 'overview-link',
      isHidden: () => true // this is not required, will be completely removed after confirmation
    },
    {
      id: 2,
      label: t('notification.tab_title'),
      routeTo: '/manage-ccs/notifications',
      testId: 'notifications-link',
      visibility: {
        rbac: {
          resource: '/ccs/notification',
          permission: 'ccs.notification.edit'
        }
      }
    },
    {
      id: 3,
      label: t('customers_tab'),
      routeTo: '/manage-ccs/customers',
      testId: 'customers-link',
      visibility: {
        rbac: {
          resource: '/ccs/accounts/platform/customer',
          permission: 'ccs.accounts.platform.customer.view'
        }
      }
    },
    {
      id: 4,
      label: t('users_tab'),
      routeTo: '/manage-ccs/users',
      testId: 'users-link',
      visibility: {
        rbac: {
          permission: 'ccs.accounts.users.view.all',
          resource: '/ccs/accounts/user'
        }
      }
    },
    {
      id: 5,
      label: t('devices'),
      routeTo: '/manage-ccs/devices',
      testId: 'devices-link',
      visibility: {
        rbac: {
          resource: '/ccs/device-management',
          permission: 'ccs.device-management.view'
        }
      }
    },
    {
      id: 6,
      label: t('orders_tab'),
      routeTo: '/manage-ccs/orders',
      testId: 'orders-link',
      visibility: {
        rbac: {
          permission: 'ccs.activate.view',
          resource: '/ccs/activate/tac'
        }
      }
    },
    {
      id: 7,
      label: t('subscriptions'),
      routeTo: '/manage-ccs/subscriptions',
      testId: 'subscriptions-link',
      visibility: {
        rbac: {
          resource: '/ccs/device-management',
          permission: 'ccs.device-management.view'
        }
      }
    },

    {
      id: 8,
      label: t('firmware_title'),
      routeTo: '/manage-ccs/firmware',
      testId: 'firmware-link',
      visibility: {
        rbac: {
          resource: '/ccs/activate/firmware',
          permission: 'ccs.activate.firmware.view'
        }
      }
    },
    {
      id: 9,
      label: t('applications.title'),
      routeTo: '/manage-ccs/applications',
      testId: 'applications-link',
      visibility: {
        rbac: {
          resource: '/ccs/app-catalog/application',
          permission: 'ccs.app-catalog.view'
        }
      }
    },

    {
      id: 10,
      label: t('whats_new.title'),
      routeTo: '/manage-ccs/whats-new',
      testId: 'whats-new-link',
      visibility: {
        rbac: {
          resource: '/ccs/whats-new',
          permission: 'ccs.whats-new.view'
        },
        hideFor: { feature: 'glcp-whats-new' }
      }
    }
  ]

  const getSelectedListId = () => {
    const data = menuData.find((item) => item.routeTo === pathname)
    return data.id
  }

  return (
    <Box>
      {errorNotification}
      <Box direction="row" justify="start" pad="small">
        <Button
          default
          margin={{ vertical: 'xsmall', horizontal: 'small' }}
          label={t('common:nav_bar.home')}
          icon={<Previous />}
          onClick={handleBackBtnClick}
          testId="manage-account-btn"
        />
      </Box>
      <Box margin={{ horizontal: 'xlarge', vertical: 'medium' }}>
        <Box
          direction="row-responsive"
          gap="small"
          align="center"
          justify="start"
          margin={{ horizontal: 'medium', vertical: 'small' }}
        >
          <Typography
            level="1"
            size="large"
            type="text"
            weight="normal"
            testId="manage-ccs-icon"
          >
            <UserManager color="text-strong" size="80" />
          </Typography>
          <Box margin={{ left: 'medium' }}>
            <Typography
              level="1"
              type="heading"
              weight="normal"
              testId="manage-ccs-title"
            >
              {t('manage_ccs.title')}
            </Typography>
          </Box>
        </Box>
        <Box
          gap="xlarge"
          pad={{ vertical: 'medium' }}
          direction="row-responsive"
        >
          <MenuList
            defaultActiveId={getSelectedListId()}
            highlightOnSelect
            menuData={menuData}
            menuItemPadding={{
              horizontal: 'small',
              vertical: 'xsmall'
            }}
            testId="menulist"
            width="small"
            onClickMenuItem={handleMenuItemClick}
            customRenderer={customRenderer}
          />
          <ManageCcsRouter />
        </Box>
      </Box>
    </Box>
  )
}

ManageCcs.propTypes = {
  errorNotification: PropTypes.element
}

ManageCcs.defaultProps = {
  errorNotification: null
}

export default ManageCcs
