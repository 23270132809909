import { Box, Stack } from 'grommet'
import { Notification } from 'grommet-icons'
import React from 'react'

export const BellNotificationIcon = () => {
  return (
    <Stack anchor="top-right">
      <Notification color="text" />

      <Box
        background="status-critical"
        pad={{ horizontal: 'xxsmall', top: 'xsmall' }}
        round={{ size: 'full' }}
        border={{ size: 'small', color: 'status-critical' }}
      />
    </Stack>
  )
}
