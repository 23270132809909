import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Layer, Box, FormField, Select, SelectMultiple } from 'grommet'
import { CircleInformation, StatusCritical } from 'grommet-icons'
import { isEmpty, debounce } from 'lodash'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Typography,
  Button,
  Loader,
  Notification
} from '../../../../../../../../components'
import { get, getErrorMessage } from '../../../../../../../../utils/api-utils'
import {
  WKSPC,
  WKSPC_CAPITALIZED,
  getWorkspaceString
} from '../../../../../../../../utils/common-utils'

const EditAccountModal = ({
  setInitialSettings,
  accountData,
  setAccountData,
  setOpenEditAccountModal,
  formValues,
  setFormValues,
  defaultOptions,
  setAccountLabel,
  accountLabel,
  setDefaultOptions,
  initialDefaultOptions,
  accountDetails,
  currentIndex
}) => {
  const { t } = useTranslation(['manage', 'common'])
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const saCustomerRolesV2 = LDFlags['glcp-sa-customer-roles-v2']
  const [accountOptions, setAccountOptions] = useState(
    accountDetails?.accountOptions
  )
  const [selectedAccount, setSelectedAccount] = useState({
    label: accountDetails?.account,
    value: accountDetails?.accountID
  })
  const [showEmptyError, setShowEmptyError] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const { oidcUser } = useReactOidc()
  const [searchMessage, setSearchMessage] = useState(
    t('common:search_no_result')
  )
  const [message, setMessage] = useState('')
  const [selectedValue, setSelectedValue] = useState(accountDetails?.accountID)
  const [roleLoader, setRoleLoader] = useState(false)
  const [showRoleOptions, setShowRoleOptions] = useState(false)
  const [roleOptions, setRoleOptions] = useState([])
  const [defaultRoleOptions, setDefaultRoleOptions] = useState([])
  const [savePrevRole, setSavePrevRole] = useState(accountDetails?.roles)
  const [roleSlugs, setRoleSlugs] = useState([])
  const [roleNames, setRoleNames] = useState([t('notification.all_roles')])
  const [errorMessage, setErrorMessage] = useState(null)

  const initialRender = useRef(true)

  const handleDebouncedSearchValue = debounce((value) => {
    setSearchTerm(value)
  }, 500)

  useEffect(() => {
    // API call to get list of customers
    if (initialRender.current) {
      initialRender.current = false
    } else {
      const url = `/support-assistant/v1alpha1/customers`
      const request = {
        limit: 20,
        ...(searchTerm?.trimStart()?.length && {
          search_string: searchTerm?.trimStart()
        })
      }
      get(url, request, oidcUser.access_token).then(
        (response) => {
          if (
            response?.data?.pagination?.total_count > 20 &&
            !isEmpty(searchTerm)
          ) {
            setSearchMessage(t('notification.refine_search'))
            setAccountOptions([])
          } else {
            setSearchMessage(t('common:search_no_result'))
            const accountArray = response?.data?.customers?.map((value) => ({
              value: value?.customer_id,
              label: value?.contact?.company_name
            }))

            const newArray = []
            setAccountOptions(accountArray)
            accountArray.forEach((option) => {
              const findElement = defaultOptions.find(
                (account) => account?.value === option?.value
              )

              if (findElement === undefined) {
                newArray.push(option)
              }
            })

            setDefaultOptions(defaultOptions.concat(newArray))
          }
        },
        (error) => {
          console.log('error', error)
        }
      )
    }
  }, [oidcUser.access_token, searchTerm, t]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // API call to get list of customers
    if (!isEmpty(selectedValue)) {
      setRoleLoader(true)
      const roleArray = []
      roleArray.push({
        value: t('notification.all_roles'),
        label: t('notification.all_roles')
      })
      setDefaultRoleOptions(roleArray)
      const url = saCustomerRolesV2
        ? `/support-assistant/v2alpha1/customer-roles/${selectedValue}`
        : `/ui-doorway/ui/v2/cm/customers/${selectedValue}/roles`
      get(url, {}, oidcUser.access_token).then(
        (response) => {
          if (response?.data?.pagination?.total_count > 0) {
            response?.data?.roles?.map((value) =>
              roleArray.push({
                value: `${value?.application_id}_${value?.slug}`,
                label: `${value?.application_name} - ${value?.name}`,
                role_value: value?.slug
              })
            )
            const newArray = []
            roleArray.forEach((option) => {
              const findElement = defaultRoleOptions.find(
                (role) => role?.value === option?.value
              )

              if (findElement === undefined) {
                newArray.push(option)
              }
            })
            setDefaultRoleOptions(defaultRoleOptions.concat(newArray))
          }
          setRoleOptions(roleArray)
          setRoleLoader(false)
          setShowRoleOptions(true)
        },
        (error) => {
          setRoleOptions(roleArray)
          setRoleLoader(false)
          setShowRoleOptions(true)
          setErrorMessage(getErrorMessage(error, t))
        }
      )
    }
  }, [oidcUser.access_token, selectedValue, t]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Layer
        position="center"
        onClickOutside={() => {
          setInitialSettings({})
          setOpenEditAccountModal(false)
        }}
        onEsc={() => {
          setInitialSettings({})
          setOpenEditAccountModal(false)
        }}
        data-testid="add-account-layer"
      >
        <Box
          pad={{
            horizontal: 'medium',
            bottom: 'medium'
          }}
          gap="small"
          width="medium"
        >
          <Typography type="heading" level="2" margin={{ bottom: 'small' }}>
            {`${t('edit')} ${getWorkspaceString(
              showWorkspaceString,
              t,
              WKSPC
            )}`}
          </Typography>
          <Box>
            <FormField
              name="editAccount"
              data-testid="edit-account-select-formfield"
              label={getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_CAPITALIZED
              )}
              margin={{ bottom: 'small' }}
            >
              <Select
                id="select-with-search"
                name="select-with-search"
                placeholder={t('notification.account_placeholder', {
                  account: getWorkspaceString(showWorkspaceString, t, WKSPC),
                  an: showWorkspaceString
                    ? t('common:article.a')
                    : t('common:article.an')
                })}
                searchPlaceholder={t('notification.search')}
                data-testid="edit-account-select-field"
                options={accountOptions}
                value={selectedValue}
                emptySearchMessage={searchMessage}
                onChange={({ option }) => {
                  if (option?.value !== selectedValue) {
                    setSelectedAccount(option)
                    setSelectedValue(option?.value)
                    setShowEmptyError(false)
                    setSavePrevRole([])
                    setRoleOptions([])
                    setDefaultRoleOptions([])
                    setSavePrevRole([t('notification.all_roles')])
                  }
                }}
                labelKey="label"
                valueKey={{ key: 'value', reduce: true }}
                onClose={() => {
                  setAccountOptions(defaultOptions)
                  setDefaultOptions(initialDefaultOptions)
                }}
                onSearch={(text) => handleDebouncedSearchValue(text)}
              />
            </FormField>
            {roleLoader && (
              <Box direction="row" align="center" justify="center">
                <Loader testId="acc-role-loader" />
              </Box>
            )}
            {showRoleOptions && !roleLoader && (
              <FormField
                name="editRole"
                data-testid="edit-role-select-form-field"
                label="Roles"
                margin={{ bottom: 'large' }}
              >
                <SelectMultiple
                  data-testid="edit-account-role-select"
                  id="role"
                  name="role"
                  placeholder={t('notification.placeholder_role')}
                  searchPlaceholder={t('notification.placeholder_role_search')}
                  options={roleOptions || []}
                  value={savePrevRole}
                  labelKey="label"
                  valueKey={{ key: 'value', reduce: true }}
                  onChange={({ value: nextValue }) => {
                    if (nextValue.length === 0) {
                      setRoleSlugs([])
                      setRoleNames([t('notification.all_roles')])
                      setSavePrevRole([t('notification.all_roles')])
                    } else if (
                      nextValue.length > 0 &&
                      nextValue.includes(t('notification.all_roles')) &&
                      savePrevRole.includes(t('notification.all_roles'))
                    ) {
                      const index = nextValue.indexOf(
                        t('notification.all_roles')
                      )
                      if (index > -1) {
                        const newArray = nextValue.splice(index - 1, 1)
                        const selectedRoles = []
                        const selectedRoleSlugs = []
                        newArray.forEach((sel) => {
                          const obj = roleOptions?.find((role) => {
                            return role.value === sel
                          })
                          selectedRoles.push(obj?.label)
                          selectedRoleSlugs.push(obj?.role_value)
                        })
                        setSavePrevRole(newArray)
                        setRoleSlugs(selectedRoleSlugs)
                        setRoleNames(selectedRoles)
                      }
                    } else if (
                      nextValue.length > 0 &&
                      nextValue.includes(t('notification.all_roles')) &&
                      !savePrevRole.includes(t('notification.all_roles'))
                    ) {
                      setRoleSlugs([])
                      setRoleNames([t('notification.all_roles')])
                      setSavePrevRole([t('notification.all_roles')])
                    } else {
                      setSavePrevRole(nextValue)
                      const selectedRoles = []
                      const selectedRoleSlugs = []
                      nextValue.forEach((sel) => {
                        const obj = roleOptions?.find((role) => {
                          return role.value === sel
                        })
                        selectedRoles.push(obj?.label)
                        roleSlugs.push(obj?.role_value)
                        selectedRoleSlugs.push(obj?.role_value)
                      })
                      setRoleSlugs(selectedRoleSlugs)
                      setRoleNames(selectedRoles)
                    }
                  }}
                  onSearch={(text) => {
                    // The line below escapes regular expression special characters:
                    // [ \ ^ $ . | ? * + ( )
                    const escapedText = text.replace(
                      /[-\\^$*+?.()|[\]{}]/g,
                      '\\$&'
                    )

                    // Create the regular expression with modified value which
                    // handles escaping special characters. Without escaping special
                    // characters, errors will appear in the console
                    const exp = new RegExp(escapedText, 'i')
                    setRoleOptions(
                      defaultRoleOptions.filter((o) => exp.test(o?.label))
                    )
                  }}
                  onClose={() => setRoleOptions(defaultRoleOptions)}
                />
              </FormField>
            )}
          </Box>
          {showEmptyError && (
            <Box
              margin={{ top: 'large' }}
              direction="row"
              background="validation-critical"
              pad="small"
              round="xsmall"
            >
              <Typography
                type="text"
                size="medium"
                testId="account-error-text"
                icon={<CircleInformation />}
              >
                {message}
              </Typography>
            </Box>
          )}
          <Box direction="row" justify="end" gap="medium">
            <Button
              default
              label={t('cancel')}
              onClick={() => {
                setOpenEditAccountModal(false)
                setInitialSettings({})
              }}
              testId="cancel-account-btn"
            />
            <Button
              primary
              type="submit"
              data-testid="edit-account-confirm-button"
              label={t('save')}
              onClick={() => {
                if (!isEmpty(selectedAccount)) {
                  setShowEmptyError(false)
                  const newAccountData = accountData.slice()
                  const newAccountLabel = accountLabel.slice()
                  const newDataObj = {
                    account: selectedAccount?.label,
                    accountID: selectedAccount?.value,
                    roles: savePrevRole,
                    roleNames,
                    accountOptions
                  }
                  const roleNameMapping = {}
                  roleSlugs.forEach((val, index) => {
                    roleNameMapping[val] = roleNames[index]
                  })
                  const accObj = {
                    accountName: selectedAccount,
                    accountRoles: roleSlugs,
                    accountRoleNames: roleNameMapping
                  }
                  newAccountData.splice(currentIndex, 1, newDataObj)
                  newAccountLabel.splice(currentIndex, 1, accObj)
                  setAccountData(newAccountData)
                  setAccountLabel(newAccountLabel)

                  setFormValues({
                    ...formValues,
                    accounts: newAccountData,
                    selectedAccountName: newAccountLabel,
                    selectedAccountRoles: roleSlugs
                  })

                  setOpenEditAccountModal(false)
                  setSelectedAccount({})
                  setSelectedValue('')
                } else {
                  setShowEmptyError(true)
                  setMessage(
                    t('notification.account_select_error', {
                      account: getWorkspaceString(
                        showWorkspaceString,
                        t,
                        WKSPC
                      ),
                      an: showWorkspaceString
                        ? t('common:article.a')
                        : t('common:article.an')
                    })
                  )
                }
              }}
              testId="save-preferences-btn"
            />
          </Box>
        </Box>
        {errorMessage && (
          <Notification
            backgroundColor="status-critical"
            onClose={() => setErrorMessage(null)}
            testId="critical-inline-notification"
            text={errorMessage}
            icon={<StatusCritical size="medium" />}
          />
        )}
      </Layer>
    </>
  )
}
export { EditAccountModal }

EditAccountModal.propTypes = {
  setInitialSettings: PropTypes.func,
  accountData: PropTypes.array,
  setAccountData: PropTypes.func,
  setOpenEditAccountModal: PropTypes.func,
  setFormValues: PropTypes.func,
  formValues: PropTypes.object,
  defaultOptions: PropTypes.array,
  setAccountLabel: PropTypes.func.isRequired,
  accountLabel: PropTypes.array.isRequired,
  setDefaultOptions: PropTypes.func.isRequired,
  initialDefaultOptions: PropTypes.array.isRequired,
  accountDetails: PropTypes.object.isRequired,
  currentIndex: PropTypes.number.isRequired
}

EditAccountModal.defaultProps = {
  setInitialSettings: () => {},
  accountData: [],
  setAccountData: () => {},
  setOpenEditAccountModal: () => {},
  setFormValues: () => {},
  formValues: {},
  defaultOptions: []
}
