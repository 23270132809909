import { Box } from 'grommet'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useTranslation, Trans } from 'react-i18next'

import {
  Button,
  ModalDialog,
  ModalHeader,
  ModalFooter,
  Typography
} from '../../../../../components'
import { del } from '../../../../../utils/api-utils'

const DeleteRoleConfirmationModal = ({
  closeDeleteModal,
  role,
  setApiError
}) => {
  const history = useHistory()
  const [isDisabled, setIsDisabled] = useState(false)
  const { t } = useTranslation(['authz', 'common'])
  const handleDeleteRole = async () => {
    try {
      setIsDisabled(true)
      const response = await del(`/authorization/v2alpha1/roles/${role.id}`, {})

      if (response.status === 204) {
        closeDeleteModal()
        history.push('/manage-account/identity/roles', {
          roleDeleteNotification: {
            title: t('iam:roles.delete_role_success_title'),
            message: role?.displayName,
            severity: 'info'
          }
        })
      } else {
        closeDeleteModal()
        setApiError(response.statusText)
        setIsDisabled(false)
      }
    } catch (error) {
      closeDeleteModal()
      setApiError(error)
    }
  }

  return (
    <ModalDialog
      onClickOutside={() => {
        closeDeleteModal()
      }}
      header={
        <ModalHeader
          title={
            <Typography
              level="2"
              style={{ whiteSpace: 'nowrap' }}
              testId="header-title"
              type="heading"
              weight="bold"
            >
              {t('authz:roles.delete_role')}
            </Typography>
          }
        />
      }
      content={
        isEmpty(role) ? null : (
          <Typography type="text" margin={{ top: 'medium' }}>
            <Trans i18nKey="authz:roles.del_role_confirmation" t={t}>
              <b>{{ role: role?.displayName }}</b>
            </Trans>
          </Typography>
        )
      }
      onClose={closeDeleteModal}
      testId="center-modal-dialog"
      footer={
        <ModalFooter
          right={
            <Box direction="row" gap="medium">
              <Button
                label={<b>{t('common:cancel')}</b>}
                secondary
                testId="secondary-btn"
                onClick={closeDeleteModal}
              />
              <Button
                label={t('authz:roles.delete_role')}
                primary
                testId="primary-btn"
                onClick={handleDeleteRole}
                disabled={isDisabled}
              />
            </Box>
          }
        />
      }
    />
  )
}

DeleteRoleConfirmationModal.propTypes = {
  closeDeleteModal: PropTypes.func.isRequired,
  role: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired
  }),
  setApiError: PropTypes.func.isRequired
}

DeleteRoleConfirmationModal.defaultProps = {
  role: {}
}

export { DeleteRoleConfirmationModal }
