export const compareLocAddresses = (oldAddresses, newAddresses) => {
  // LM BE has bit complex requirements of payload when editing address
  // if only updating than just send the updated fields with address id
  // when creating need make sure it does not have id property
  // when deleting just id and type needs to be passed
  // hence below comparison required

  const addresses = []
  const streetAdd = newAddresses.find((add) => {
    return add.type === 'street'
  })
  if (streetAdd != null) {
    addresses.push(streetAdd)
  }
  const newShippingAdd = newAddresses.find((add) => {
    return add.type === 'shipping_receiving'
  })
  const oldShippingAdd = oldAddresses.find((add) => {
    return add.type === 'shipping_receiving'
  })
  if (newShippingAdd == null) {
    if (oldAddresses.length > 1) {
      // this means we are deleting shipping address
      addresses.push({ id: oldShippingAdd.id, type: 'shipping_receiving' })
    }
  } else {
    if (oldAddresses.length === 1 && newShippingAdd != null) {
      // this means we are creating shipping address, so need to delete id property else BE gives error
      delete newShippingAdd.id
    } else {
      // only add id for update
      newShippingAdd.id = oldShippingAdd?.id
    }
    addresses.push(newShippingAdd)
  }
  return addresses
}

export const compareLocContacts = (oldContacts, newContacts, locationId) => {
  const updatedContactList = []
  oldContacts.forEach((contact) => {
    // if contact not present in new list than we are deleting it
    const checkContactDeleted = newContacts.findIndex((newContact) => {
      return newContact.id === contact.id
    })
    if (checkContactDeleted === -1) {
      // contact not present in new list
      updatedContactList.push({
        id: contact.id,
        type: contact.type
      })
    }
  })
  // identify newly added contacts by checking Id
  newContacts.forEach((newContact) => {
    if (!Object.prototype.hasOwnProperty.call(newContact, 'id')) {
      updatedContactList.push({
        ...newContact,
        ...{ location_id: locationId }
      })
    }
  })
  return updatedContactList
}
