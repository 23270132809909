import React, { useState, useEffect, useCallback } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { ShieldSecurity, StatusGood } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'

/* eslint-enable */
import { get, del, getErrorMessage } from '../../../../../utils/api-utils'
import { downloadMetadataFile } from '../../saml-sso/common-utils'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  Button,
  Typography,
  Tile,
  Notification,
  ActionButton
} from '../../../../../components'
import { NotifyTeamModal } from '../../saml-sso/components/NotifyTeamModal'
import DeleteSSOModal from '../../saml-sso/components/DeleteSSOModal'
import { ViewSamlAttributes } from '../../saml-sso/components/ViewSamlAttributes'
import { pullSSOConfigStatus } from '../../saml-sso/utils/api-utils'

const ConfigureSaml = ({
  ssoData,
  domain,
  showNotifyUsers,
  setClaimDomainModal,
  hideNotifyUsers
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const history = useHistory()
  const [data, setData] = useState(ssoData)
  const { oidcUser } = useReactOidc()
  const [notifyDomain, setNotifyDomain] = useState(domain)
  const [viewSsoAttDomain, setSsoAttDomain] = useState()
  const [notifyTeamModal, setNotifyTeamModal] = useState(showNotifyUsers)
  const [showDeleteNotification, setDeleteNotification] = useState(false)
  const [viewSamlAttModal, setViewSamlAttModal] = useState(false)
  const [selectedSAML, setSelectedSAML] = useState(null)
  const [deleteSSOConfirmation, setDeleteSSOConfirmation] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [submitDisabled, setSubmitDisabled] = useState(false)

  useEffect(() => {
    setData(ssoData)
  }, [ssoData])

  useEffect(() => {
    if (showNotifyUsers && domain) {
      setNotifyTeamModal(true)
      setNotifyDomain(domain)
    }
  }, [showNotifyUsers, domain])

  const closeNotifyTeamModal = () => {
    hideNotifyUsers(false)
    setNotifyTeamModal(false)
  }
  const getAllSAML = useCallback(() => {
    get('/authn/v1/saml/config', {}, oidcUser.access_token).then(
      (response) => {
        const tableData = response.data.domains.map((d) => {
          return {
            ...d,
            created_at: dayjs(d.created_at).format('MMMM DD, YYYY')
          }
        })
        setData(tableData)
      },
      () => {
        setData([])
      }
    )
  }, [oidcUser.access_token])

  const handleConfirmDelete = () => {
    setSubmitDisabled(true)
    del(
      `/authn/v1/saml/async/config/${selectedSAML.domain}`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        pullSSOConfigStatus(
          response?.data?.task_tracking_id,
          oidcUser,
          () => {
            setSubmitDisabled(false)
            setDeleteSSOConfirmation(false)
            setDeleteNotification(true)
            getAllSAML()
          },
          (error) => {
            setSubmitDisabled(false)
            setErrorMessage(getErrorMessage(error, t))
          }
        )
      },
      (error) => {
        setSubmitDisabled(false)
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }

  const customRenderer = (actionBtn, visibility, idx) => {
    return (
      <VisibilityWrapper
        key={idx} // eslint-disable-line react/no-array-index-key
        rbac={visibility.rbac}
      >
        {actionBtn}
      </VisibilityWrapper>
    )
  }

  return (
    <Box margin={{ bottom: 'medium' }}>
      {data.length === 0 ? (
        <Box align="center" pad={{ top: 'medium', bottom: 'medium' }}>
          <Box width="medium" align="center">
            <ShieldSecurity color="green" size="large" />
            <Typography
              type="text"
              size="medium"
              textAlign="center"
              testId="sso-desc"
              margin={{ top: 'small' }}
            >
              {t('manage_account.sso.setup_saml_connection_msg')}
            </Typography>
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/accounts/platform/customer/saml',
                permission: 'ccs.accounts.platform.customer.edit'
              }}
            >
              <Button
                margin={{ top: 'small' }}
                secondary
                label={t('manage_account.sso.setup_saml_connection')}
                testId="set-saml-connection"
                onClick={() => {
                  setClaimDomainModal(true)
                }}
              />
            </VisibilityWrapper>
          </Box>
        </Box>
      ) : (
        <Box size="100%">
          <Box fill justify="center" data-testid="domains-list">
            {data.map((ssoDomain, idx) => {
              return (
                <Box
                  key={ssoDomain.domain}
                  fill
                  direction="row"
                  flex
                  margin={{ top: 'medium' }}
                >
                  <Tile
                    boxShadow
                    justify="center"
                    alignContent="center"
                    align="center"
                    layout="row"
                    logo={<></>}
                    actionBtn={
                      <>
                        <Box direction="row" align="center">
                          <Box margin={{ right: 'small' }}>
                            {/* <More /> */}
                            <ActionButton
                              actions={[
                                {
                                  label: t(
                                    'manage_account.sso.saml_connection_dropdown.view_details'
                                  ),
                                  onClick: () => {
                                    history.push(
                                      `/manage-account/sso/${ssoDomain.domain}/details`
                                    )
                                  },
                                  visibility: {
                                    rbac: {
                                      resource:
                                        '/ccs/accounts/platform/customer',
                                      permission:
                                        'ccs.accounts.platform.customer.edit'
                                    }
                                  },
                                  testId: 'view-details'
                                },
                                {
                                  label: t(
                                    'manage_account.sso.saml_connection_dropdown.download_metadata_file'
                                  ),
                                  onClick: () => {
                                    downloadMetadataFile(
                                      ssoDomain.domain,
                                      oidcUser.access_token
                                    )
                                  },
                                  visibility: {
                                    rbac: {
                                      resource:
                                        '/ccs/accounts/platform/customer',
                                      permission:
                                        'ccs.accounts.platform.customer.edit'
                                    }
                                  }
                                },
                                {
                                  label: t(
                                    'saml_sso.table_actions.view_saml_att'
                                  ),
                                  onClick: () => {
                                    setSsoAttDomain(ssoDomain.domain)
                                    setViewSamlAttModal(true)
                                  },
                                  visibility: {
                                    rbac: {
                                      resource:
                                        '/ccs/accounts/platform/customer',
                                      permission:
                                        'ccs.accounts.platform.customer.edit'
                                    }
                                  }
                                },
                                {
                                  label: t(
                                    'manage_account.sso.saml_connection_dropdown.notify_users'
                                  ),
                                  onClick: () => {
                                    setNotifyDomain(ssoDomain.domain)
                                    setNotifyTeamModal(true)
                                  },
                                  visibility: {
                                    rbac: {
                                      resource:
                                        '/ccs/accounts/platform/customer',
                                      permission:
                                        'ccs.accounts.platform.customer.edit'
                                    }
                                  }
                                },
                                {
                                  label: t('saml_sso.table_actions.delete'),
                                  onClick: () => {
                                    setDeleteSSOConfirmation(true)
                                    setSelectedSAML(ssoDomain)
                                  },
                                  visibility: {
                                    rbac: {
                                      resource:
                                        '/ccs/accounts/platform/customer/saml',
                                      permission:
                                        'ccs.accounts.platform.customer.edit'
                                    }
                                  }
                                }
                              ]}
                              testId="multipleactions-action-btn"
                              customRenderer={customRenderer}
                              showOneActionAsDropDown
                            />{' '}
                          </Box>
                        </Box>
                      </>
                    }
                    subTitle={
                      <Box>
                        <Typography type="text" testId="ip-id">
                          {ssoDomain.domain || ''}
                        </Typography>
                      </Box>
                    }
                    testId={`app-tile-${idx}`}
                    title={
                      <Box>
                        <Typography type="heading" level={3} testId="server-id">
                          {ssoDomain.domain || ''}
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
      {notifyTeamModal && (
        <NotifyTeamModal
          domain={notifyDomain}
          onSetOpen={closeNotifyTeamModal}
        />
      )}
      {viewSamlAttModal && (
        <ViewSamlAttributes
          domain={viewSsoAttDomain}
          onSetOpen={setViewSamlAttModal}
        />
      )}
      {deleteSSOConfirmation && (
        <DeleteSSOModal
          onSetOpen={setDeleteSSOConfirmation}
          onConfirmDelete={handleConfirmDelete}
          domainName={selectedSAML.domain}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          submitDisabled={submitDisabled}
        />
      )}
      {showDeleteNotification && (
        <Notification
          icon={<StatusGood color="text-strong" />}
          onClose={() => setDeleteNotification(false)}
          testId="status-good-notification"
          text="Domain Removed Successfully"
        />
      )}
    </Box>
  )
}
ConfigureSaml.propTypes = {
  ssoData: PropTypes.array.isRequired,
  domain: PropTypes.string,
  hideNotifyUsers: PropTypes.func.isRequired,
  setClaimDomainModal: PropTypes.func.isRequired,
  showNotifyUsers: PropTypes.bool.isRequired
}

ConfigureSaml.defaultProps = {
  domain: null
}

export default ConfigureSaml
