import PropTypes from 'prop-types'
import { Box, Button, Anchor } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import { useState } from 'react'

import { useNewServiceRedirect as useServiceRedirect } from '../hooks'
import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../shims/imports'
import { Dropdown } from '../../components'
import { useAppCatalogContext } from '../../context/app-catalog-context'
import { getRegionNameByRegionCode } from '../../pages/applications/utils'

const NewServiceLaunchModal = ({
  provisions,
  isServiceManager,
  serviceManagerName,
  setShowModal,
  isServiceDetailLaunch,
  isServiceProvision
}) => {
  const { t } = useTranslation(['dashboard', `services`])
  const { regionListData } = useAppCatalogContext()

  const category = provisions?.at(0)?.categories?.at(0)
  const serviceName = provisions?.at(0)?.name
  const serviceOfferSlug = provisions?.at(0)?.slug

  const launchableProvisions =
    provisions
      ?.map(({ slug, application_provision, service_provision }) => ({
        slug,
        ...((isServiceDetailLaunch && isServiceProvision) ||
        (!isServiceDetailLaunch && service_provision)
          ? service_provision
          : application_provision)
      }))
      ?.filter(({ provision_status }) => provision_status === 'PROVISIONED') ||
    []

  const regionOptions = launchableProvisions.map(({ region }) => ({
    value: region,
    label: getRegionNameByRegionCode(region, regionListData) || region
  }))

  const [selectedRegion, setSelectedRegion] = useState(regionOptions[0].value)

  const serviceRedirect = useServiceRedirect()

  return (
    <ModalDialog
      width={isServiceDetailLaunch ? 'large' : 'medium'}
      header={
        <ModalHeader
          title={
            <Typography textAlign="start" type="heading" level={2}>
              {isServiceDetailLaunch && `${t('dashboard:common.launch')} `}
              {serviceManagerName}
            </Typography>
          }
          subtitle={
            isServiceDetailLaunch ? null : t(`common.category.${category}`)
          }
        />
      }
      content={
        <Box margin={{ top: 'small' }} direction="column" justify="between">
          {isServiceDetailLaunch ? (
            <>
              <Typography type="paragraph" textAlign="start" fill>
                {isServiceManager || isServiceProvision ? (
                  t('dashboard:service_details.service_launch_prompt', {
                    serviceName: `${serviceName}`
                  })
                ) : (
                  <Trans>
                    {t(
                      'dashboard:service_details.service_manager_launch_prompt',
                      {
                        serviceName: `${serviceName}`,
                        serviceManagerName: `${serviceManagerName}`
                      }
                    )}
                  </Trans>
                )}
              </Typography>

              {!isServiceManager && (
                <Typography type="text" textAlign="start">
                  <Trans>
                    {t(
                      'dashboard:service_details.service_manager_launch_prompt_note_1',
                      {
                        serviceName: `${serviceName}`,
                        serviceManagerName: `${serviceManagerName}`
                      }
                    )}
                    <Anchor
                      label={t(
                        'dashboard:service_details.service_manager_launch_prompt_link'
                      )}
                      weight={500}
                      href="https://support.hpe.com/connect/s/createcase"
                      target="_blank"
                      testId="service-manager-launch"
                    />
                    {t(
                      'dashboard:service_details.service_manager_launch_prompt_note_2'
                    )}
                  </Trans>
                </Typography>
              )}
            </>
          ) : (
            <Typography type="text" margin={{ bottom: 'medium' }}>
              {t(`services:${serviceOfferSlug}.short_description`, serviceName)}
            </Typography>
          )}
          <Box pad={{ top: 'small' }}>
            <Typography type="text">{t('common.deployment_region')}</Typography>
            <Dropdown
              options={regionOptions}
              onChangeDropdown={(value) => {
                setSelectedRegion(value)
              }}
              defaultValue={selectedRegion}
              value={selectedRegion}
              testId="service-centric-launch-dropdown"
            />
          </Box>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <Box direction="row" justify="end" gap="small">
              <Button
                label={t('common.cancel')}
                onClick={() => setShowModal(false)}
              />
              <Button
                primary
                label={t('common.launch')}
                onClick={() =>
                  serviceRedirect(
                    launchableProvisions.find(
                      ({ region }) => region === selectedRegion
                    ),
                    serviceOfferSlug
                  )
                }
              />
            </Box>
          }
        />
      }
      onClose={() => {}}
      testId="service-centric-launch-modal"
    />
  )
}

NewServiceLaunchModal.propTypes = {
  provisions: PropTypes.array.isRequired,
  setShowModal: PropTypes.func.isRequired,
  isServiceManager: PropTypes.bool,
  serviceManagerName: PropTypes.string,
  isServiceDetailLaunch: PropTypes.bool,
  isServiceProvision: PropTypes.bool
}

NewServiceLaunchModal.defaultProps = {
  isServiceManager: true,
  serviceManagerName: '',
  isServiceDetailLaunch: false,
  isServiceProvision: false
}

export default NewServiceLaunchModal
