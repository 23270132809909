import React from 'react'
import { Markdown, Anchor } from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Typography } from '../../components'

const ServiceMarkdown = ({
  service,
  serviceManagerName,
  serviceManagerPath,
  featuresSupported
}) => {
  const { serviceSlug, serviceManagerSlug, name: serviceName } = service
  const { t } = useTranslation(['dashboard', `services`])
  /*
  const SmallHeading = (props) => (
    <Typography
      {...props}
      type="heading"
      size="small"
      level="2"
      margin={{ vertical: 'none' }}
    />
  )
 */
  const components = {
    h1: (props) => (
      <Typography
        type="heading"
        level={1}
        margin={{ bottom: 'small' }}
        {...props}
      />
      // SmallHeading
    ),
    h2: (props) => (
      <Typography
        type="heading"
        level={2}
        margin={{ top: 'none', bottom: 'xsmall' }}
        {...props}
      />
      // SmallHeading
    ),
    p: {
      props: {
        fill: true,
        margin: { top: 'none', bottom: 'medium' }
      }
    },
    ul: (props) => (
      <Typography
        as="ul"
        type="paragraph"
        fill
        style={{
          // don't let text wrap beneath bullet
          listStylePosition: 'outside',
          paddingLeft: '1em' // left align bullet with p text
        }}
        {...props}
      />
    ),
    AnchorMarkdown: () => (
      <Anchor
        label={serviceManagerName}
        weight={500}
        href={`/services/service-catalog/${serviceManagerPath}`}
      />
    )
  }

  const getServiceDetailMessage = (serviceDetailKey) =>
    t(`services:${serviceSlug}.${serviceDetailKey}`, '')

  // get messages, filter whether item exists, map them to the appropriate string value, then convert to a string with newlines
  const overviewsTemplate = ['overview_desc1', 'overview_desc2']
    .map(getServiceDetailMessage)
    .filter(Boolean)
    .join('\n')

  const capabilitiesTemplate = [
    'capabilities_item1',
    'capabilities_item2',
    'capabilities_item3',
    'capabilities_item4',
    'capabilities_item5',
    'capabilities_item6',
    'capabilities_item7',
    'capabilities_item8'
  ]
    .map(getServiceDetailMessage)
    .filter(Boolean)
    .map((capability) => `* ${capability}`) // add asterisk
    .join('\n')

  // if service slug does not equal the service manager slug, then we should render the service manager description
  const serviceManagerTemplate = ![serviceSlug, 'OTHER'].includes(
    serviceManagerSlug
  )
    ? `
## ${t('dashboard:common.service_manager')}
${
  serviceManagerSlug === 'GLC'
    ? t('dashboard:service_detail.glc_service_manager_desc', {
        serviceName,
        serviceManagerName
      })
    : t(
        `dashboard:service_detail.service_manager_desc${
          featuresSupported?.includes('DEEP_LINKING') ? '_deep_linking' : ''
        }`,
        {
          serviceName,
          serviceManagerName
        }
      )
}
`
    : ''

  const template = `
## ${t('dashboard:service_detail.overview')}
${overviewsTemplate}

${serviceManagerTemplate}

## ${t('dashboard:service_detail.capabilities')}
${capabilitiesTemplate}
`
  return <Markdown components={components}>{template}</Markdown>
}

ServiceMarkdown.propTypes = {
  service: PropTypes.object.isRequired,
  serviceManagerName: PropTypes.string,
  serviceManagerPath: PropTypes.string,
  featuresSupported: PropTypes.array.isRequired
}

ServiceMarkdown.defaultProps = {
  serviceManagerName: undefined,
  serviceManagerPath: '#'
}

export default ServiceMarkdown
