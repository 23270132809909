import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Data,
  DataFilters,
  DataSearch,
  DataSummary,
  DataTable,
  Toolbar
} from 'grommet'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import uniqBy from 'lodash/uniqBy'
import { CircleInformation, ShareRounded } from 'grommet-icons'

import { get } from '../../../../utils/api-utils'
import { WizardContext } from '../../../../components/wizard/WizardContext'
import { displayApiError } from '../../../../utils/error-handling-utils'
import {
  IAAS_KEY_TYPE,
  NOT_CLAIMED_STATUS,
  getExpirationValue
} from '../../utils'
import { getSubscriptionTiers } from '../../../../utils/dm-sm-common-utils'
import { Loader, NoDataInfo } from '../../../../components'
import { GREENLAKE_SUPPORT_URL } from '../../../../utils/error-utils'

const defaultView = {
  search: ''
}

const AddSubscriptions = ({ onAssignInstance }) => {
  const { t } = useTranslation(['licensing', 'common'])
  const { oidcUser } = useReactOidc()
  const { formValues, setFormValues } = useContext(WizardContext)
  const { productSquid } = useParams()

  // It holds the option for Subscription Tier filter
  const [licenseTierList, setLicenseTierList] = useState([])

  const [errorMessage, setErrorMessage] = useState('')
  const LDFlags = useFlags()
  const {
    'glcp-dm-silver-peak': dmSilverPeakFlag,
    'glcp-dm-pce': dmPCEFlag, // PCE LD is added to check PCE workflow in StandAlone Account just for testing purpose untill all services support PCE changes
    'glcp-dm-uxi': dmUXIFlag
  } = LDFlags

  // Total item in the inventory irrespective of search, filter & pagination
  const [total, setTotal] = useState(0)
  // It holds data & data count in the current page
  const [result, setResult] = useState({ data: [] })
  // It holds search, filter, page & limit/step value
  const [view, setView] = useState(defaultView)
  // To display loader when BE api is in progress to show No data component
  const [loading, setLoading] = useState(true)
  // To show no data component
  const [hideNoData, setHideNoData] = useState(false)

  // useEffect to fetch subscription tiers for filter
  useEffect(() => {
    let isCurrent = true
    getSubscriptionTiers({ LDFlags, oidcUser, magicLink: true }).then(
      (response) => {
        if (!isCurrent) return
        setLicenseTierList(response || [])
      },
      (error) => {
        setErrorMessage(error)
      }
    )

    return () => {
      isCurrent = false
    }
  }, [oidcUser.access_token, dmSilverPeakFlag, dmPCEFlag, dmUXIFlag]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect to fetch subscriptions from squid for addition
  useEffect(() => {
    get(
      '/ui-doorway/ui/v1/license/orders',
      {
        squid: productSquid,
        ...(view.search?.length > 0 && { 'key-pattern': view.search }),
        ...(view.properties?.['subscription-tier']?.length > 0 && {
          'subscription-tier': view.properties['subscription-tier']
            .map(({ value }) => value)
            .join(',')
        })
      },
      oidcUser.access_token
    )
      .then(
        (response) => {
          // Filter subscriptions which is not claimed & either its non iaas subscription or has a application list attached(for iaas)
          const subscriptionList = response?.data?.subscription_keys?.filter(
            (value) =>
              value.claim_status === NOT_CLAIMED_STATUS &&
              (value?.key_type !== IAAS_KEY_TYPE ||
                value?.available_applications?.length)
          )

          // Stores current list diplayed in table and total count in the current search & filter state
          setResult({
            data: subscriptionList,
            filteredTotal: subscriptionList?.length
          })

          // It keeps count of max list among initial set & filtered set
          setTotal((prevTotal) => Math.max(prevTotal, subscriptionList?.length))
          if (subscriptionList?.length) setHideNoData(true)
        },
        (error) => {
          // If API error out when user perform search or sort, display error
          // Else no data component will suffix as it contains contact support link
          if (total) setErrorMessage(error)
        }
      )
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, view])

  // useEffect to check if user selected data has iaas subscription to enable Assign instance step
  useEffect(() => {
    const iaasSubscriptionCount = formValues?.selectedProductList?.filter(
      (value) =>
        value?.key_type === IAAS_KEY_TYPE &&
        value?.available_applications?.length
    )?.length
    if (iaasSubscriptionCount) {
      onAssignInstance(true)
    } else {
      onAssignInstance(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.selectedProductList])

  // columns to be dispalyed in datatable
  const columns = [
    {
      property: 'subscription_key',
      header: t('subscription_key'),
      primary: true
    },
    {
      property: 'subscription_tier',
      header: t('tier')
    },
    {
      property: 'end_date',
      header: t('expiration_date'),
      render: (datum) => getExpirationValue(datum?.end_date)
    }
  ]

  // To handle checkbox selection
  const handleSelectionChange = (selectedData) => {
    const newlySelectedRowsDetails =
      result?.data?.filter((value) =>
        selectedData?.includes(value?.subscription_key)
      ) || []

    const existingSelectedRowsDetails =
      formValues?.selectedProductList?.filter((value) =>
        selectedData?.includes(value?.subscription_key)
      ) || []

    const selectedRowsDetails = uniqBy(
      [...newlySelectedRowsDetails, ...existingSelectedRowsDetails],
      'subscription_key'
    )
    setFormValues({
      ...formValues,
      selectedProductList: selectedRowsDetails,
      selectedApplication: {},
      claimedAllSubscriptions: selectedRowsDetails?.length === total
    })
  }

  const getSubscriptionContent = () => {
    if (!hideNoData)
      return (
        <Box margin={{ bottom: 'medium' }}>
          <NoDataInfo
            icon={<CircleInformation color="text" size="large" />}
            title={t('licensing:add_products.no_available_subscriptions')}
            titleHeadingLevel={2}
            subtitle={t(
              'licensing:add_products.no_available_subscriptions_desc'
            )}
            action={
              <Button
                label={t('common:contact_support')}
                secondary
                testId="contact-support-btn"
                href={GREENLAKE_SUPPORT_URL}
                target="_blank"
                icon={<ShareRounded />}
                reverse
              />
            }
            testId="no-subscriptions-info"
          />
        </Box>
      )
    return (
      <Data
        properties={{
          'subscription-tier': {
            label: t('tier'),
            options: licenseTierList
          }
        }}
        data={result?.data}
        total={total}
        filteredTotal={result?.filteredTotal}
        defaultView={defaultView}
        view={view}
        onView={setView}
        messages={{
          dataSummary: {
            items: t('subscriptions_lowercase'),
            itemsSingle: t('subscription_lowercase')
          },
          dataFilters: {
            clear: t('common:clear_filters_sentence'),
            heading: t('filter_subscriptions')
          },
          dataForm: {
            submit: t('common:apply_filters_sentence')
          }
        }}
      >
        <Toolbar direction="column">
          <Toolbar>
            <DataSearch
              data-testid="search-field"
              placeholder={t('add_products.search_subscription_key')}
              width="medium"
            />
            <DataFilters layer clearFilters data-testid="filter-search-btn" />
          </Toolbar>
          <Toolbar>
            <DataSummary data-testid="table-summary" />
          </Toolbar>
        </Toolbar>
        <DataTable
          columns={columns}
          select={
            formValues?.selectedProductList?.map(
              ({ subscription_key: key }) => key
            ) || []
          }
          onSelect={handleSelectionChange}
          data-testid="subscriptions-datatable"
        />
      </Data>
    )
  }

  return (
    <Box
      direction="column"
      justify="between"
      margin={{ bottom: 'small', top: 'medium' }}
    >
      {loading ? (
        <Box direction="row" align="center" justify="center">
          <Loader testId="subscriptions-loader" />
        </Box>
      ) : (
        getSubscriptionContent()
      )}
      {errorMessage && displayApiError(errorMessage, t, setErrorMessage)}
    </Box>
  )
}

AddSubscriptions.propTypes = {
  onAssignInstance: PropTypes.func.isRequired
}

export { AddSubscriptions }
