// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box } from 'grommet'

import { put } from '../../utils/api-utils'
import { Typography } from '../../components'
import {
  WKSPC_CAPITALIZED,
  getWorkspaceString,
  getDeviceTypeLabel
} from '../../utils/common-utils'
import {
  getCustomerAccountType,
  isCoP,
  isGLOP
} from '../../utils/feature-flag-utils'
import { isServiceCentric } from '../../utils/account-utils'
import { getDateByMonthAbvDayYear } from '../../utils/dm-sm-common-utils'

import {
  NO_VALUE,
  generateCSVReportAPICall,
  generateCSVReportViaReportingAPICall,
  getFormattedFilterValues
} from './utils'
import TruncateButton from './components/TruncateButton'
import SubscriptionTierList from './devices-datatable/components/SubscriptionTierList'

// Columns that needs to be displayed in Datatable at any time. These columns will not appear under Select Column Settings checkbox group to hide.
export const fixedDatatableColumns = [
  'unique_key',
  'device_model',
  'application_name',
  'subscription_tier_description'
]

// Add unique key in the datatable data to set primary key
export const setCombinedKey = (deviceTableData) => {
  const Data = deviceTableData?.map((value) => {
    return {
      unique_key: `${value?.serial_number} : ${value.part_number}`,
      ...value
    }
  })
  return Data
}

const isLocationColumnVisible = (LDFlags) => {
  const { 'glcp-dm-view-location': dmViewLocationFlag } = LDFlags
  return !isCoP() && !isGLOP() && dmViewLocationFlag
}

const getMacAddressOrAccount = (t, LDFlags) => {
  const { 'glcp-switch-to-workspace': showWorkspaceString } = LDFlags
  const showMspFeature = getCustomerAccountType() === 'MSP'
  let macorAccountColumn = []

  if (showMspFeature) {
    macorAccountColumn = [
      {
        property: 'account_name',
        type: 'string',
        header: t('account', {
          account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
        }),
        render: (datum) => {
          return datum?.account_name || NO_VALUE
        }
      }
    ]
  } else {
    macorAccountColumn = [
      {
        property: 'mac_address',
        type: 'string',
        header: t('mac_address')
      }
    ]
  }
  return macorAccountColumn
}

// Columns supported under Devices Datatable
export const getDevicesDatatableColumns = (
  t,
  LDFlags,
  theme,
  showOnPremWorkspaceColumn
) => {
  const {
    'glcp-dm-view-location': dmViewLocationFlag,
    'glcp-subscrption-tags': subcriptionTagsFlag,
    'glcp-dscc-dm-sm': glopDevicesPreOnBoardingFlag,
    'glcp-multi-license-support': glcpMultiLicenseSupportFlag
  } = LDFlags
  const newDashboardFlag = isServiceCentric(LDFlags)
  const showMspFeature = getCustomerAccountType() === 'MSP'

  return [
    {
      property: 'unique_key',
      type: 'string',
      header: t('serial_number'),
      primary: true,
      render: (datum) => (
        <Typography
          testId="t13"
          type="text"
          emphasis
          wordBreak="break-all"
          {...theme?.dataTable.primary}
        >
          {datum?.serial_number}
        </Typography>
      )
    },
    {
      property: 'device_model',
      type: 'string',
      header: t('model')
    },
    ...(isGLOP()
      ? []
      : [
          {
            property: 'device_type',
            type: 'string',
            header: t('type'),
            render: (datum) => {
              return getDeviceTypeLabel(t, datum?.display_device_type)
            }
          }
        ]),
    {
      property: 'application_name',
      type: 'string',
      header: t(
        newDashboardFlag || isGLOP() ? 'service_manager' : 'application'
      ),
      render: (datum) => {
        return datum?.application_name || NO_VALUE
      }
    },
    {
      property: 'subscription_tier_description',
      type: 'string',
      header: t('subscription_tier'),
      render: (datum) => {
        const data = datum.subscriptions
        return glcpMultiLicenseSupportFlag ? (
          <SubscriptionTierList data={data} />
        ) : (
          datum?.subscription_tier_description || NO_VALUE
        )
      }
    },
    ...(isLocationColumnVisible(LDFlags)
      ? [
          {
            property: 'location_name',
            type: 'string',
            header: t('location_name'),
            render: (datum) => {
              return datum?.location_name || NO_VALUE
            }
          }
        ]
      : []),
    ...getMacAddressOrAccount(t, LDFlags),
    ...(!isCoP() && !isGLOP()
      ? [
          {
            property: 'region',
            type: 'string',
            header: t('service_region'),
            render: (datum) => {
              return datum?.ccs_region || NO_VALUE
            }
          }
        ]
      : []),
    ...(glopDevicesPreOnBoardingFlag && showOnPremWorkspaceColumn
      ? [
          {
            property: 'on_prem_workspace_name',
            type: 'string',
            header: t('onprem_workspace'),
            render: (datum) => {
              return datum?.on_prem_workspace_name || '--'
            }
          }
        ]
      : []),
    ...(newDashboardFlag || dmViewLocationFlag || isGLOP()
      ? []
      : [
          {
            property: 'subscription_end',
            type: 'date',
            header: t('expiration_date'),
            render: (datum) => getDateByMonthAbvDayYear(datum?.subscription_end)
          }
        ]),
    ...(!isGLOP()
      ? [
          {
            property: 'tags',
            type: 'numeric',
            header: t('tags.tags_title'),
            align: subcriptionTagsFlag ? 'start' : '',
            render: (datum) => {
              return subcriptionTagsFlag && newDashboardFlag ? (
                <TruncateButton datum={datum} type="DeviceInventory" />
              ) : (
                datum.tags?.length || 0
              )
            }
          }
        ]
      : []),
    ...(showMspFeature
      ? [
          {
            property: 'mac_address',
            type: 'string',
            header: t('mac_address')
          }
        ]
      : []),
    ...(dmViewLocationFlag && !isCoP()
      ? [
          {
            property: 'subscription_end',
            type: 'date',
            header: t('expiration_date'),
            render: (datum) => getDateByMonthAbvDayYear(datum?.subscription_end)
          }
        ]
      : []),
    ...(isLocationColumnVisible(LDFlags)
      ? [
          {
            property: 'location',
            type: 'string',
            header: t('location'),
            render: (datum) => {
              const address = []
              if (datum?.city) address.push(datum?.city)
              if (datum?.state) address.push(datum?.state)
              if (datum?.country) address.push(datum?.country)
              return (
                <Box width="xsmall">
                  <Typography testId="location-info" truncate="tip" type="text">
                    {address?.toString()?.replaceAll(',', ', ') || NO_VALUE}
                  </Typography>
                </Box>
              )
            }
          }
        ]
      : [])
  ]
}

// To validate tags on submit & to submit tags if validation succeeds
export const submitManageTag = (
  assigned = [],
  unassigned = [],
  validate,
  devices,
  oidcUser,
  handleManageModalOpen,
  setPullTagsRefresh,
  pullTagsRefresh
) => {
  return new Promise((resolve, reject) => {
    const devicesList = devices?.map((value) => {
      return {
        serial_number: value.serial_number,
        device_type: value.device_type,
        part_number: value.part_number
      }
    })
    put(
      `/ui-doorway/ui/v1/devices/tags?only_validate=${validate}`,
      {
        create_tags: [...assigned],
        delete_tags: [...unassigned],
        devices: [...devicesList]
      },
      oidcUser.access_token
    ).then(
      (response) => {
        if (!validate) {
          // Refresh tags list in filter
          setPullTagsRefresh(!pullTagsRefresh)
        }
        // TOOD: show notifications
        const failedDevices = response?.data?.failed_devices
        if (failedDevices?.length) {
          const listOfFailedDevices = []
          failedDevices.forEach((device) => {
            // TODO: device type is not returned from API but we need it
            listOfFailedDevices.push({
              serial_number: device.serial_number,
              mac_address: device.mac_address,
              part_number: device.part_number,
              reason: device.reason,
              reason_code: device.reason_code,
              severity: device.severity,
              display_device_type: device.device_type
            })
          })
          return resolve(listOfFailedDevices)
        }
        resolve(null)
        return null
      },
      (error) => {
        reject(error)
        handleManageModalOpen(false)
      }
    )
  })
}

// Action to invoke when user clicks Export devcies generate report Button
export const onGenerateReport = ({
  report_name,
  description,
  email,
  all_entries,
  columns: csvColumns,
  t,
  oidcUser,
  tabName,
  searchTerm,
  accountFilterData,
  filterOptions,
  LDFlags,
  pollingTerms,
  setPollingTerms,
  setNotification,
  setShowExportModal,
  setApiError
}) => {
  const {
    'glcp-glasgow-reportfw-devices-export': reportingDevicesExportLdFlag,
    'glcp-reportfw-dashboard': reportingDashboardFlag
  } = LDFlags
  setPollingTerms({ ...pollingTerms, exportLoading: true })
  const modifiedFilterOptions = getFormattedFilterValues(filterOptions)
  let request = all_entries
    ? {}
    : {
        unassigned_only: tabName === 'requireAssignment',
        ...(['NOT_LICENSED', 'LICENSED'].includes(tabName) && {
          device_license_type: tabName
        }),
        ...(searchTerm?.length && { search_string: searchTerm }),
        ...(Object.keys(modifiedFilterOptions)?.length &&
          modifiedFilterOptions),
        ...(accountFilterData?.length && {
          tenant_platform_customer_ids: accountFilterData?.map(
            (option) => option?.customer_id
          )
        }),
        ...(modifiedFilterOptions?.subscription_end_date_in_millis && {
          device_license_type: 'LICENSED'
        })
      }

  if (!reportingDevicesExportLdFlag) {
    generateCSVReportAPICall(
      email,
      csvColumns,
      request,
      oidcUser.access_token,
      setNotification,
      (response) => {
        // on success
        if (response?.data?.task_tracking_id) {
          setPollingTerms({
            ...pollingTerms,
            status: 'IN_PROGRESS',
            id: response?.data?.task_tracking_id,
            exportLoading: false
          })
        }
        setShowExportModal(false)
      },
      (val) => setPollingTerms({ ...pollingTerms, exportLoading: val }), // setLoading
      t
    )
  } else {
    request = {
      delivery: {
        emailComponents: {
          recipientEmail: email
        }
      },
      query_elements: {
        datasource_name: ['uid_di'],
        columns: csvColumns,
        filters: all_entries
          ? {}
          : {
              ...request
            }
      }
    }
    if (reportingDashboardFlag) {
      request.name = report_name?.trim()
      request.description = description?.trim()
    }
    setPollingTerms({
      ...pollingTerms,
      status: 'IN_PROGRESS',
      id: 0,
      exportLoading: true
    })
    generateCSVReportViaReportingAPICall(
      request,
      oidcUser.access_token,
      setNotification,
      (response) => {
        // on success
        if (response?.data) {
          setPollingTerms({
            ...pollingTerms,
            status: 'DONE',
            id: 0,
            exportLoading: false
          })
        }
        setShowExportModal(false)
      },
      (val) => {
        setPollingTerms({ ...pollingTerms, exportLoading: false }) // setLoading
        if (!reportingDashboardFlag) {
          setShowExportModal(false)
        } else {
          setShowExportModal(true)
          setApiError(val)
        }
      },
      t,
      reportingDashboardFlag
    )
  }
}

// To fetch devices list service api endpoint based on tabname.
// i.e., ADI('requireAssignment','allDevices') or SM('NOT_LICENSED','LICENSED')
export const getDevicesUrl = ({ tabName }) => {
  if (['NOT_LICENSED', 'LICENSED'].includes(tabName))
    return '/ui-doorway/ui/v1/license/devices/search'
  return '/ui-doorway/ui/v1/devices/filter'
}

// To fetch devices list service api request body based on tabname.
// i.e., ADI('requireAssignment','allDevices') or SM('NOT_LICENSED','LICENSED')
export const getDevicesRequestBody = ({
  modifiedFilterOptions,
  tabName,
  searchTerm,
  accountFilterData
}) => {
  return {
    ...(['NOT_LICENSED', 'LICENSED'].includes(tabName)
      ? {
          device_license_type: tabName,
          ...(searchTerm?.length && { device_pattern: searchTerm })
        }
      : {
          unassigned_only: tabName === 'requireAssignment',
          ...(searchTerm?.length && { search_string: searchTerm }),
          ...(modifiedFilterOptions?.subscription_end_date_in_millis && {
            device_license_type: 'LICENSED'
          })
        }),
    ...(Object.keys(modifiedFilterOptions)?.length && modifiedFilterOptions),
    ...(accountFilterData?.length && {
      tenant_platform_customer_ids: accountFilterData?.map(
        (option) => option.customer_id
      )
    })
  }
}
