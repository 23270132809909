import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Ruler, Typography } from '../../../components'

import HighlightString from './HighLightString'
import LastUpdatedTooltip from './LastUpdatedTooltip'

const ExcerptFormatDate = ({ result, formattedDate }) => {
  const { t } = useTranslation(['support_hub_search'])
  return (
    <Box direction="row" gap="large">
      <Box width="90%">
        <Text
          dangerouslySetInnerHTML={{
            __html: HighlightString(result.excerpt, result.excerptHighlights)
          }}
        />
      </Box>
      <Box direction="row" align="center" gap="small" width="12%">
        <Box height="xxsmall">
          <Ruler
            orientation="vertical"
            margin={{ top: 'xxsmall', bottom: 'xxsmall' }}
            background="border-weak"
          />
        </Box>

        <Typography type="text">
          <LastUpdatedTooltip
            formattedDate={formattedDate}
            lastUpdated={t('last_updated')}
          />
        </Typography>
      </Box>
    </Box>
  )
}

ExcerptFormatDate.propTypes = {
  result: PropTypes.object.isRequired,
  formattedDate: PropTypes.string.isRequired
}

export default ExcerptFormatDate
