// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, Markdown } from 'grommet'
import PropTypes from 'prop-types'

import { getprimaryField, getActionField, getRedirectPath } from '../utils'
import { Typography } from '../../../../../components'

export const DefaultComponent = ({ content, renderHint, setOpenDropdown }) => {
  const primaryField = getprimaryField(renderHint?.renderingHints)
  const actionField = getActionField(renderHint?.renderingHints)
  const history = document.querySelector('greenlake-header')?.historyObject

  return (
    <Box direction="column" gap="small">
      {content?.results.map((item, index) => (
        <Box
          pad={{ horizontal: 'xsmall', vertical: 'xsmall' }}
          direction="column"
          align="start"
          alignContent="start"
          onClick={() => {
            const path = getRedirectPath(actionField, item)
            history.push(path)
            setOpenDropdown(false)
          }}
        >
          <Typography type="text" testId={`gs-default-title-${index}`} emphasis>
            {item[primaryField]}
          </Typography>
          {item?.highlight && (
            <Typography
              type="text"
              size="small"
              testId={`gs-default-desc-${index}`}
            >
              <Markdown type="text" testId={`gs-default-desc-${index}`}>
                {item?.highlight[Object.keys(item?.highlight)[0]]?.[0]}
              </Markdown>
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  )
}

DefaultComponent.propTypes = {
  content: PropTypes.any.isRequired,
  renderHint: PropTypes.any.isRequired,
  setOpenDropdown: PropTypes.func.isRequired
}
