import { Select, Box } from 'grommet'
import { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Typography } from '../../../components'

const ResultsPerPage = ({ controller, options }) => {
  const { t } = useTranslation('auditlogs')
  const [selected, setSelected] = useState(options[1])
  const location = useLocation()
  const params = new URLSearchParams(location.hash.slice(1))
  const numberOfResultsParam = params.get('numberOfResults')
  const numberOfResults = parseInt(numberOfResultsParam, 10)

  useEffect(() => {
    if (numberOfResults && !Number.isNaN(numberOfResults)) {
      if (options.includes(numberOfResults) && numberOfResults !== 0) {
        setSelected(numberOfResults)
      } else {
        setSelected(options[1])
        controller.set(options[1])
      }
    } else {
      setSelected(options[1])
      controller.set(options[1])
    }
  }, [controller, numberOfResults, options])

  useEffect(() => {
    const unsubscribe = controller.subscribe(() => {})
    // Cleanup the subscription when the component unmounts
    return () => {
      unsubscribe()
    }
  }, [controller])

  return (
    <Box direction="row" align="center" gap="small">
      <Typography type="text">{t('items_per_page')}</Typography>
      <Box width="xsmall">
        <Select
          data-testid="items-per-page"
          gap="small"
          options={options}
          value={selected}
          onChange={({ option }) => {
            setSelected(option)
            controller.set(option)
          }}
        />
      </Box>
    </Box>
  )
}

ResultsPerPage.propTypes = {
  controller: PropTypes.shape({
    state: PropTypes.object,
    subscribe: PropTypes.func,
    set: PropTypes.func,
    isSetTo: PropTypes.func
  }).isRequired,
  options: PropTypes.array.isRequired
}

export default ResultsPerPage
