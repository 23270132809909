import { Box, FormField } from 'grommet'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  Loader,
  ModalDialog,
  ButtonGroup,
  Typography,
  Logo,
  Dropdown,
  CCSForm
} from '../../../components'
import { get } from '../../../utils/api-utils'
import {
  useAppCatalogContext,
  AppCatalogActions
} from '../../../context/app-catalog-context'
import { doLaunchAppAction, getRegionNameByRegionCode } from '../utils/utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import arubaImage from '../../../images/aruba.png'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'

const LaunchAppModal = ({ handleClose, appId }) => {
  const { t } = useTranslation(['common', 'apps'])
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const { oidcUser } = useReactOidc()
  const { regionListData, dispatchAppCatalogContext } = useAppCatalogContext()
  const [regionOptions, setRegionOptions] = useState(null)
  const [appName, setAppName] = useState('')
  const [appLogo, setAppLogo] = useState('')
  const [provision, setProvision] = useState([])
  const [selectedDetails, setSelectedDetails] = useState({
    region: '',
    customerId: '',
    loginURL: '',
    instanceId: ''
  })

  const custAccountLoaded = getCustomerAccount()
  const accountType = custAccountLoaded?.account_type || ''

  useEffect(() => {
    let isCurrent = true
    async function setRegions() {
      await get('/geo/ui/v1/regions', {}, oidcUser.access_token).then(
        (response) => {
          if (!isCurrent) return
          const { regions } = response.data
          if (regions) {
            dispatchAppCatalogContext({
              type: AppCatalogActions.SET_REGION_LIST,
              data: regions
            })
          }
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
    }
    if (regionListData.length === 0) {
      setRegions()
    }
    return () => {
      isCurrent = false
    }
  }, [oidcUser.access_token, dispatchAppCatalogContext, regionListData, t])

  useEffect(() => {
    let isCurrent = true
    if (regionListData.length > 0) {
      get(
        `/ui-doorway/ui/v1/applications/provisions`,
        {
          provision_status: 'PROVISIONED',
          application_id: appId
        },
        oidcUser.access_token
      ).then(
        (response) => {
          if (!isCurrent) return
          if (
            response?.data?.provisions?.length &&
            response?.data?.provisions[0].name
          ) {
            const { provisions } = response.data
            setProvision(provisions)
            setAppName(provisions[0].name)
            setAppLogo(provisions[0].logo || arubaImage)
            // can remove this filter later if API supported filtered by msp_conversion_status
            const regionsData = []
            provisions.forEach((val) => {
              if (
                val.msp_conversion_status !== 'MSP_CONVERSION_FAILED' &&
                val.msp_conversion_status !== 'MSP_CONVERSION_INITIATED'
              )
                regionsData.push({
                  value: val.region,
                  label: getRegionNameByRegionCode(val.region, regionListData),
                  customerId: val.application_customer_id,
                  loginURL: val.login_url,
                  instanceId: val.application_instance_id
                })
            })
            setRegionOptions(regionsData)
          }
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
    }
    return () => {
      isCurrent = false
    }
  }, [oidcUser.access_token, appId, regionListData, t])

  const launchApp = () => {
    handleClose()
    doLaunchAppAction(
      selectedDetails.instanceId,
      oidcUser.access_token,
      selectedDetails.customerId,
      accountType,
      provision[0].slug
    )
  }

  return (
    <>
      {showErrorNotification}
      <ModalDialog
        content={
          <Box>
            <Box gap="xsmall">
              <Typography type="heading" testId="add-devices-title">
                {t('apps:launch_application')}
              </Typography>
              <Typography
                type="paragraph"
                size="medium"
                testId="add-devices-subtitle"
              >
                {t('apps:launch_application_subtitle')}
              </Typography>
            </Box>
            <Box
              direction="row"
              margin={{ top: 'medium' }}
              gap="small"
              align="center"
            >
              <Logo testId="logo" size="xxsmall" url={appLogo} />
              <Typography type="text" size="small" testId="app-name">
                <Trans
                  i18nKey="apps:app_details"
                  t={t}
                  appName={appName}
                  count={provision && provision.length}
                >
                  <strong>{{ appName }}</strong>
                </Trans>
              </Typography>
            </Box>
            <Box width="large" margin={{ top: 'small' }}>
              {regionOptions === null ? (
                <Box
                  height="xsmall"
                  width="xsmall"
                  align="center"
                  justify="center"
                  alignSelf="center"
                >
                  <Loader testId="loader-spinner" />
                </Box>
              ) : (
                <CCSForm
                  onSubmit={() => launchApp()}
                  onReset={() => handleClose()}
                  errorMessage=""
                  testId="provisioned-region-dropdown-form"
                  validate="blur"
                >
                  <>
                    <FormField name="provisioned_region" required>
                      <Dropdown
                        name="provisioned_region"
                        testId="provisioned-region-dropdown"
                        noBorder
                        placeholder={t('apps:select_region')}
                        options={regionOptions}
                        value={selectedDetails.region}
                        onChange={(event) => {
                          const res = regionOptions.find(
                            (obj) => obj.value === event.target.value
                          )
                          setSelectedDetails({
                            region: event.target.value,
                            customerId: res.customerId,
                            loginURL: res.loginURL,
                            instanceId: res.instanceId
                          })
                        }}
                      />
                    </FormField>
                    <Box margin={{ top: 'medium' }}>
                      <ButtonGroup
                        buttonList={[
                          {
                            id: 2,
                            label: t('apps:cancel'),
                            default: true,
                            testId: 'close-modal-btn',
                            type: 'reset'
                          },
                          {
                            id: 1,
                            label: t('apps:launch_application'),
                            primary: true,
                            type: 'submit',
                            testId: 'launch-app-btn'
                          }
                        ]}
                        testId="two-buttons"
                      />
                    </Box>
                  </>
                </CCSForm>
              )}
            </Box>
          </Box>
        }
        onClose={() => {}}
        testId="basic-long-content-modal-dialog"
        width="medium"
      />
    </>
  )
}

LaunchAppModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired
}

export { LaunchAppModal }
