/* eslint-disable react/prop-types */
import { useState, React } from 'react'
import { Box, Button } from 'grommet'
import { Trash, CircleInformation } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  NoDataInfo,
  DataTable,
  ModalDialog
} from '../../../components'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import AddSubscription from './AddSubscription'

const SubscriptionInfo = ({
  subscriptionInfo,
  webhookInfo,
  handleDelete,
  setLoading,
  onToastNotification
}) => {
  const { t } = useTranslation(['common'])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false)
  const toggleModal = () => {
    if (!isModalOpen && subscriptionInfo && subscriptionInfo.length >= 5) {
      setIsLimitModalOpen(true)
    } else {
      setIsModalOpen(!isModalOpen)
    }
  }

  const handleClose = () => {
    setIsLimitModalOpen(false)
  }

  const columns = [
    {
      property: 'type',
      header: t('automations:webhooks.type'),
      primary: true,
      render: (datum) => (
        <Typography
          size="medium"
          weight={500}
          type="text"
          fontSize="small"
          fontWeight={500}
          lineHeight="large"
          textAlign="left"
          whiteSpace="nowrap"
        >
          {datum.eventType}
        </Typography>
      )
    },
    {
      property: 'createdBy',
      header: (
        <Typography
          size="medium"
          weight="bold"
          type="text"
          fontSize="small"
          fontWeight={500}
          lineHeight="large"
          textAlign="left"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {t('automations:webhooks.identity_auth')}
        </Typography>
      )
    },
    {
      property: 'delete',
      header: '',
      render: (data) => (
        <Box style={{ padding: '10px' }}>
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/events/subscription',
              permission: 'ccs.events.subscription.delete'
            }}
          >
            <Button
              data-testid="delete-button-title"
              icon={<Trash />}
              onClick={() => handleDelete(data)}
              plain
            />
          </VisibilityWrapper>
        </Box>
      )
    }
  ]

  return (
    <>
      {subscriptionInfo && subscriptionInfo.length > 0 ? (
        <Box
          direction="row"
          justify="between"
          width="large"
          margin={{ bottom: 'medium' }}
        >
          <Typography
            level="2"
            size="medium"
            type="heading"
            weight="normal"
            testId="events2-workspaces-title"
          >
            {t('automations:webhooks.event_subs')}
          </Typography>
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/events/subscription',
              permission: 'ccs.events.subscription.create'
            }}
          >
            <Button
              secondary
              label={t('automations:webhooks.add_subscription')}
              onClick={toggleModal}
            />
          </VisibilityWrapper>
        </Box>
      ) : (
        <Typography
          level="2"
          size="medium"
          type="heading"
          weight="normal"
          testId="events2-workspaces-title"
        >
          {t('automations:webhooks.event_subs')}
        </Typography>
      )}
      {subscriptionInfo && subscriptionInfo.length > 0 ? (
        <Box width="large" height="auto" gap="large">
          <DataTable
            grid={{
              columns,
              data: subscriptionInfo,
              pad: {
                header: { horizontal: 'xsmall' },
                body: { horizontal: 'xsmall', vertical: 'xsmall' }
              }
            }}
            primaryKey="type"
            testId="subscription-info-table"
          />
        </Box>
      ) : (
        <Box width="large" height="188px" justify="center">
          <NoDataInfo
            icon={<CircleInformation color="black" size="large" />}
            title={t('automations:webhooks.no_subscriptions')}
            gap="small"
            action={
              <VisibilityWrapper
                rbac={{
                  resource: '/ccs/events/subscription',
                  permission: 'ccs.events.subscription.create'
                }}
              >
                <Button
                  primary
                  label={t('automations:webhooks.add_subscription_lowercase')}
                  testId="add-subscription-btn"
                  onClick={toggleModal}
                />
              </VisibilityWrapper>
            }
            testId="no-subscription-info"
          />
        </Box>
      )}
      {isModalOpen && (
        <AddSubscription
          isOpen={isModalOpen}
          onSetOpen={toggleModal}
          setLoading={setLoading}
          onToastNotification={onToastNotification}
          subscriptionInfo={subscriptionInfo}
          webhookInfo={webhookInfo}
        />
      )}
      {isLimitModalOpen && (
        <ModalDialog
          content={
            <Box>
              <Box direction="column">
                <h2 style={{ fontWeight: '500' }}>
                  {t('automations:webhooks.create_subs_panel_title')}
                </h2>
              </Box>
              <Box direction="column">
                {t('automations:webhooks.subs_register_limit_msg')}
              </Box>
            </Box>
          }
          footer={
            <Box style={{ display: 'flex', alignItems: 'end' }}>
              <Button
                primary
                label={t('common:close')}
                onClick={handleClose}
                testId="webhook-reach-limit-close-btn"
              />
            </Box>
          }
          onClose={handleClose}
          testId="webhook-reach-limit-modal"
          width="large"
        />
      )}
      {/* Your modal component */}
    </>
  )
}
export default SubscriptionInfo
