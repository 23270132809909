/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, FormField } from 'grommet'
import { StatusCritical } from 'grommet-icons'
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import { Dropdown, Notification } from '../../../../../../../components'
import { validateForm } from '../../../../../../../utils/validation-utils'
import { targetOptions, targetOptionsWithPCE } from '../../../constants'

const validateFields = (formValues, i18nTranslate) => {
  const requiredString = i18nTranslate('required')

  const {
    categoryService,
    topic,
    subService,
    subServiceOptions,
    topicOptions,
    isEdit,
    isUpdateFlow
  } = formValues
  let subServiceValidation = true
  let topicValidation = true

  if (!isEmpty(subServiceOptions) && isEmpty(subService)) {
    subServiceValidation = false
  }

  if (!isEmpty(topicOptions) && isEmpty(topic)) {
    topicValidation = false
  }

  return {
    categoryService:
      categoryService || isEdit || isUpdateFlow ? '' : requiredString,
    topic: topicValidation || isEdit || isUpdateFlow ? '' : requiredString,
    subService:
      subServiceValidation || isEdit || isUpdateFlow ? '' : requiredString,
    isValid:
      (categoryService && topicValidation && subServiceValidation) ||
      isEdit ||
      isUpdateFlow
  }
}

export const validateCategoryFields = (formValues, i18nTranslate) => {
  const res = validateForm(formValues, i18nTranslate, validateFields)
  return res
}

const Category = ({
  selectedType,
  categoryResponse,
  isEdit,
  isUpdateFlow,
  targetList,
  appSlug
}) => {
  const { t } = useTranslation(['manage'])
  const LDFlags = useFlags()
  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)

  const [errorMessage, setErrorMessage] = useState({})
  const [wizardError, setWizardError] = useState(null)
  const [subServiceOptions, setSubServiceOptions] = useState([])
  const [topicOptions, setTopicOptions] = useState([])
  const [subTopicOptions, setSubTopicOptions] = useState([])

  const [subService, setSubService] = useState('')
  const [topic, setTopic] = useState('')
  const [categoryService, setCategoryService] = useState('')
  const [populateTopicOptions, setPopulateTopicOptions] = useState(false)
  const [targets, setTargets] = useState([])
  const [serviceSlug, setServiceSlug] = useState(appSlug)

  useEffect(() => {
    if (attemptedAdvance) {
      const validation = validateFields(formValues, t)
      setErrorMessage(validation)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, attemptedAdvance, t])

  useEffect(() => {
    if (formValues?.status === 'Critical') {
      setFormValues({
        ...formValues,
        email: true
      })
    } else {
      setFormValues({
        ...formValues,
        email: false
      })
    }

    if (selectedType === t('notification.services')) {
      setFormValues({
        ...formValues,
        categoryService: formValues?.target
      })
    }

    if (LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
      setTargets(targetList)
    } else if (LDFlags['glcp-notifications-pce-target']) {
      setTargets(targetOptionsWithPCE)
    } else {
      setTargets(targetOptions)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(formValues?.categoryService)) {
      const selectedService = formValues?.categoryService
      let service = {}

      if (!LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
        service = categoryResponse[selectedService]
      } else {
        service = categoryResponse[serviceSlug]
      }

      let subServiceArray
      if (!LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
        subServiceArray = service ? Object?.keys(service) : []
      } else {
        subServiceArray = service
          ? Object?.keys(service)
          : Object?.keys(categoryResponse?.DEFAULT)
      }
      if (
        subServiceArray?.length === 1 &&
        subServiceArray[0] === t('notification.default')
      ) {
        if (!isEmpty(categoryService)) {
          setTimeout(() => {
            setFormValues({
              ...formValues,
              subService: t('notification.default'),
              subServiceOptions: [],
              subTopic: '',
              topic: ''
            })
            setSubServiceOptions([])
          }, 100)
        } else {
          setTimeout(() => {
            setFormValues({
              ...formValues,
              subService: t('notification.default'),
              subServiceOptions: []
            })
            setSubServiceOptions([])
          }, 100)
        }
      } else if (!isEmpty(categoryService)) {
        setTimeout(() => {
          setFormValues({
            ...formValues,
            subServiceOptions: subServiceArray,
            subTopic: '',
            topic: '',
            subService: ''
          })
          setSubServiceOptions(subServiceArray)
        }, 300)
      } else {
        setTimeout(() => {
          setFormValues({
            ...formValues,
            subServiceOptions: subServiceArray
          })
          setSubServiceOptions(subServiceArray)
        }, 100)
      }
    }
  }, [categoryResponse, formValues?.categoryService, categoryService])

  useEffect(() => {
    if (
      !isEmpty(formValues?.categoryService) &&
      !isEmpty(formValues?.subService)
    ) {
      if (!isEmpty(subService)) {
        setFormValues({
          ...formValues,
          topic: '',
          subTopic: ''
        })
      }
      const selectedService = formValues?.categoryService
      let service = {}

      if (!LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
        service = categoryResponse[selectedService]
      } else {
        service = categoryResponse[serviceSlug]
      }

      const subServices = formValues?.subService

      let topics
      if (!LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
        topics = subServices ? Object?.keys(service[subServices]) : []
      } else {
        topics =
          subServices && service
            ? Object?.keys(service[subServices])
            : Object?.keys(categoryResponse?.DEFAULT?.Default)
      }
      setTopicOptions(topics)
      setPopulateTopicOptions(!populateTopicOptions)
    }
  }, [categoryResponse, formValues?.subService, subServiceOptions])

  useEffect(() => {
    setFormValues({
      ...formValues,
      topicOptions
    })
  }, [populateTopicOptions])

  useEffect(() => {
    setFormValues({
      ...formValues,
      subServiceOptions
    })
  }, [subServiceOptions])

  useEffect(() => {
    setTopicOptions([])
    setFormValues({
      ...formValues,
      topicOptions: []
    })
  }, [categoryResponse])

  useEffect(() => {
    if (
      !isEmpty(formValues?.categoryService) &&
      !isEmpty(formValues?.subService) &&
      !isEmpty(formValues?.topic)
    ) {
      if (!isEmpty(topic)) {
        setFormValues({
          ...formValues,
          subTopic: ''
        })
      }
      const selectedService = formValues?.categoryService
      let service = {}

      if (!LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
        service = categoryResponse[selectedService]
      } else {
        service = categoryResponse[serviceSlug]
      }

      const subServices = formValues?.subService

      let topics
      if (!LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
        topics = service[subServices]
      } else {
        topics =
          subServices && service
            ? service[subServices]
            : categoryResponse?.DEFAULT?.Default
      }
      if (!(subServices && service)) {
        setFormValues({
          ...formValues,
          appSlug: 'DEFAULT'
        })
      }

      const subTopics = topics[formValues?.topic]

      setSubTopicOptions(subTopics)
    }
  }, [categoryResponse, formValues?.topic])

  return (
    <Box
      gap="medium"
      width="medium"
      justify="between"
      align="start"
      alignSelf="center"
    >
      <Box width="medium" margin={{ bottom: 'xsmall' }}>
        <FormField
          label={t('notification.service')}
          name="categoryService"
          required
          error={errorMessage.categoryService}
        >
          <Dropdown
            name="categoryService"
            testId="select-service-dropdown"
            noBorder
            disabled={
              selectedType === t('notification.services') ||
              isEdit ||
              isUpdateFlow
            }
            placeholder={t('notification.placeholder_service')}
            labelKey="label"
            valueKey={{ key: 'value' }}
            value={
              selectedType === t('notification.services')
                ? formValues?.target
                : formValues?.categoryService
            }
            options={targets || []}
            onChangeDropdown={(values) => {
              setFormValues({
                ...formValues,
                categoryService: values?.value,
                appSlug: values?.slug,
                appName: values?.label
              })
              setCategoryService(values?.value)
              setServiceSlug(values?.slug)
            }}
          />
        </FormField>
      </Box>
      {!isEmpty(formValues?.categoryService) && !isEmpty(subServiceOptions) && (
        <Box width="medium" margin={{ bottom: 'xsmall' }}>
          <FormField
            label={t('notification.sub_service')}
            name="subService"
            error={errorMessage.subService}
          >
            <Dropdown
              name="subService"
              disabled={isEdit || isUpdateFlow}
              testId="select-sub-service-dropdown"
              noBorder
              placeholder={t('notification.placeholder_sub_service')}
              labelKey="label"
              valueKey={{ key: 'value', reduce: true }}
              value={formValues.subService}
              options={subServiceOptions || []}
              onChangeDropdown={(values) => {
                setFormValues({
                  ...formValues,
                  subService: values
                })
                setSubService(values)
              }}
            />
          </FormField>
        </Box>
      )}
      {!isEmpty(formValues?.subService) && !isEmpty(topicOptions) && (
        <Box width="medium" margin={{ bottom: 'xsmall' }}>
          <FormField
            label={t('notification.topics')}
            name="topic"
            required
            error={errorMessage.topic}
          >
            <Dropdown
              name="topic"
              testId="select-topic-dropdown"
              disabled={isEdit || isUpdateFlow}
              noBorder
              placeholder={t('notification.placeholder_topic')}
              labelKey="label"
              valueKey={{ key: 'value', reduce: true }}
              value={formValues?.topic}
              options={topicOptions || []}
              onChangeDropdown={(values) => {
                setFormValues({
                  ...formValues,
                  topic: values
                })
                setTopic(values)
              }}
            />
          </FormField>
        </Box>
      )}
      {!isEmpty(formValues?.topic) && !isEmpty(subTopicOptions) && (
        <Box width="medium" margin={{ bottom: 'xsmall' }}>
          <FormField label={t('notification.sub_topics')} name="subTopic">
            <Dropdown
              name="subTopic"
              testId="select-sub-topic-dropdown"
              noBorder
              disabled={isEdit || isUpdateFlow}
              placeholder={t('notification.placeholder_sub_topic')}
              labelKey="label"
              valueKey={{ key: 'value', reduce: true }}
              value={formValues?.subTopic}
              options={subTopicOptions || []}
              onChangeDropdown={(values) => {
                setFormValues({
                  ...formValues,
                  subTopic: values
                })
              }}
            />
          </FormField>
        </Box>
      )}

      {wizardError && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setWizardError(null)}
          testId="critical-inline-notification"
          text={wizardError}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}

Category.prototypes = {
  setBannerCheck: PropTypes.func.isRequired
}

export default Category
