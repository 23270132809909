import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Box, FormField } from 'grommet'
import { Trash } from 'grommet-icons'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  Notification,
  CCSForm,
  Dropdown,
  Button
} from '../../../components'
import { post, get, patch } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import {
  displayDeviceTypeEnum,
  getContactValue,
  getUserName
} from '../../../utils/common-utils'

import { getNumNetworkingDevices } from './utils/common-methods'
import { DevicesSummary, WarningModal } from './components'

const ServiceDeliveryContactModal = ({
  setModalOpen,
  datum,
  openSuccessModal,
  mode,
  isCCSManager,
  customerId
}) => {
  const { t } = useTranslation(['device', 'common'])
  const LDFlags = useFlags()
  const dmUXIFlag = LDFlags['glcp-dm-uxi']
  const dmBridgeFlag = LDFlags['glcp-5g-bridge']
  const { oidcUser } = useReactOidc()
  const initDeviceStats = {
    COMPUTE: 0,
    AP: 0,
    GATEWAY: 0,
    SWITCH: 0,
    STORAGE: 0,
    PCE: 0,
    SENSOR: 0,
    BRIDGE: 0
  }

  const [deviceStats, setDeviceStats] = useState(initDeviceStats)
  const [assignedDevicesCount, setAssignedDevicesCount] = useState(0)
  const [errorMessage, setErrorMessage] = useState(null)
  const [listOfContact, setListOfContact] = useState([])
  const [selectedContact, setSelectedContact] = useState(null)
  const [assignedContact, setAssignedContact] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [formError, setFormError] = useState('')
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [showServiceDeliveryModal, setShowServiceDeliveryModal] =
    useState(false)
  const [appendList, setAppendList] = useState(true)
  // for paginating on scroll contact
  const itemsPerPage = 50
  const [page, setPage] = useState(0)
  const [totalItems, setTotalItems] = useState(itemsPerPage)

  useEffect(() => {
    // Logic to show stats in Device Summary Card
    const statsObj = datum?.reduce((a, value) => {
      const dType = value?.display_device_type
      if (Object.values(displayDeviceTypeEnum)?.includes(dType)) {
        switch (dType) {
          case displayDeviceTypeEnum.COMPUTE:
          case displayDeviceTypeEnum.DHCI_COMPUTE:
            a.COMPUTE = (a.COMPUTE || 0) + 1
            break
          case displayDeviceTypeEnum.STORAGE:
          case displayDeviceTypeEnum.DHCI_STORAGE:
            a.STORAGE = (a.STORAGE || 0) + 1
            break
          case displayDeviceTypeEnum.GATEWAY:
          case displayDeviceTypeEnum.SD_WAN_GW:
            a.GATEWAY = (a.GATEWAY || 0) + 1
            break
          case displayDeviceTypeEnum.BRIDGE:
            a.BRIDGE = (a.BRIDGE || 0) + 1
            break
          default:
            a[dType] = (a[dType] || 0) + 1
        }
      } else console.error('Invalid device type', value) // To debug if it breaks in future
      return a
    }, initDeviceStats)
    setDeviceStats(statsObj)

    // API call to get assigned contact stats
    const url = isCCSManager
      ? `/support-assistant/v1alpha1/stats`
      : '/ui-doorway/ui/v1/devices/stats'

    const reqBody = {
      devices:
        datum?.map((value) => {
          return {
            serial_number: value?.serial_number,
            part_number: value?.part_number,
            mac_address: value?.mac_address || ''
          }
        }) || [],
      filter: { assigned_contact: true }
    }
    if (isCCSManager) reqBody.platform_customer_id = customerId
    post(url, reqBody, oidcUser.access_token).then(
      (response) => {
        if (response?.data?.count) {
          setAssignedDevicesCount(response?.data?.count)
          setShowWarningModal(true)
        } else setShowServiceDeliveryModal(true)
      },
      () => {
        setAssignedDevicesCount('ERROR')
        setShowWarningModal(true)
      }
    )
  }, [datum, oidcUser.access_token]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // API call to get list of contact
    const url = isCCSManager
      ? `/support-assistant/v1alpha1/users`
      : '/ui-doorway/ui/v1/um/users'

    const request = {
      limit: itemsPerPage,
      offset: (page || 0) * itemsPerPage,
      include_unverified: false,
      ...(searchTerm?.trimStart()?.length && {
        search_string: searchTerm?.trimStart()
      })
    }
    if (isCCSManager) request.platform_customer_id = customerId
    get(url, request, oidcUser.access_token).then(
      (response) => {
        const contactResponse = response?.data?.users
        if (contactResponse) {
          let modifiedContactResponse = []
          if (appendList) {
            modifiedContactResponse = [...listOfContact, ...contactResponse]
            if (selectedContact)
              modifiedContactResponse = [
                ...modifiedContactResponse,
                selectedContact
              ]
            modifiedContactResponse = uniqBy(
              modifiedContactResponse,
              'contact.email'
            )
          } else modifiedContactResponse = contactResponse
          setListOfContact(modifiedContactResponse)
          setTotalItems(response?.data?.pagination?.total_count || totalItems)
        }
      },
      (error) => {
        setErrorMessage(displayApiError(error, t, setErrorMessage))
      }
    )
  }, [oidcUser.access_token, page, searchTerm, t]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = () => {
    if (assignedContact) {
      // API call to assign contact to selected devices
      const url = isCCSManager
        ? `/support-assistant/v1alpha1/devices`
        : '/ui-doorway/ui/v1/devices'

      const reqBody = {
        devices:
          datum?.map((value) => {
            return {
              serial_number: value?.serial_number,
              ...([
                displayDeviceTypeEnum.STORAGE,
                displayDeviceTypeEnum.DHCI_STORAGE,
                displayDeviceTypeEnum.COMPUTE,
                displayDeviceTypeEnum.DHCI_COMPUTE,
                displayDeviceTypeEnum.PCE
              ].includes(value?.display_device_type)
                ? { part_number: value?.part_number }
                : { mac_address: value?.mac_address }),
              device_type: value?.device_type,
              contact_id: assignedContact?.contact?.email,
              contact_name: getUserName(
                assignedContact?.contact?.first_name,
                assignedContact?.contact?.last_name,
                assignedContact?.contact?.email
              )
            }
          }) || []
      }
      if (isCCSManager) reqBody.platform_customer_id = customerId
      patch(url, reqBody, oidcUser.access_token).then(
        () => {
          setModalOpen(false)
          openSuccessModal()
        },
        (error) => {
          setErrorMessage(displayApiError(error, t, setErrorMessage))
        }
      )
    } else setFormError(t('required'))
  }

  const buttonList = [
    {
      label: t('common:cancel'),
      default: true,
      testId: 'cancel-btn',
      onClick: () => setModalOpen(false)
    },
    {
      label: t('common:save'),
      primary: true,
      testId: 'save-btn',
      onClick: handleSave
    }
  ]

  const handleAssignbtn = () => {
    setAssignedContact(selectedContact)
  }

  const handleDebouncedSearchValue = debounce((value) => {
    setAppendList(false)
    setPage(0)
    setSearchTerm(value)
  }, 500)

  return (
    <>
      {showWarningModal && (
        <WarningModal
          title={t('assign_sdc')}
          content={
            (assignedDevicesCount === 'ERROR' && ( // NOSONAR
              <Notification
                backgroundColor="status-warning"
                testId="warning-inline-notification"
                type="inline"
                text={
                  datum?.length > 1
                    ? t('assign_contact_status_error_multi')
                    : t('assign_contact_status_error_single')
                }
              />
            )) ||
            (assignedDevicesCount === 1 &&
              t('single_device_assign_sdc_content')) ||
            t('multiple_device_assign_sdc_content', {
              deviceCount: assignedDevicesCount
            })
          }
          onCancel={() => {
            setShowWarningModal(false)
            setModalOpen(false)
          }}
          onContinue={() => {
            setShowWarningModal(false)
            setShowServiceDeliveryModal(true)
          }}
        />
      )}
      {showServiceDeliveryModal && (
        <ModalDialog
          width="large"
          testId="service-delivery-contact-modal-dialog"
          onClose={() => {
            setModalOpen(false)
          }}
          header={
            <ModalHeader
              title={
                <Typography
                  type="heading"
                  level="1"
                  weight="bold"
                  style={{
                    whiteSpace: 'nowrap'
                  }}
                  testId="service-delivery-contact-modal-title"
                >
                  {t('service_delivery_contact')}
                </Typography>
              }
            />
          }
          content={
            <Box flex={{ shrink: 0 }}>
              <>
                <Typography
                  type="text"
                  size="large"
                  margin={{ top: 'xsmall' }}
                  testId="service-delivery-contact-modal-subtitle"
                >
                  {t('service_delivery_contact_description')}
                </Typography>
                {assignedDevicesCount ? (
                  <Box margin={{ top: 'medium' }}>
                    <Notification
                      backgroundColor="status-warning"
                      testId="warning-inline-notification"
                      type="inline"
                      text={
                        (assignedDevicesCount === 'ERROR' && // NOSONAR
                          (datum?.length > 1
                            ? t('assign_contact_status_error_multi')
                            : t('assign_contact_status_error_single'))) ||
                        (assignedDevicesCount === 1 &&
                          t('single_device_sdc_assigned')) || (
                          <Trans i18nKey="multiple_device_sdi_assigned" t={t}>
                            <strong>
                              {{ devicesAssigned: assignedDevicesCount }}
                            </strong>
                          </Trans>
                        )
                      }
                    />
                  </Box>
                ) : null}
                {mode !== 'SINGLE' && datum?.length && (
                  <DevicesSummary
                    numComputeDevices={deviceStats.COMPUTE}
                    numAccessPoints={deviceStats.AP}
                    numNetworkingDevices={getNumNetworkingDevices(
                      dmUXIFlag,
                      dmBridgeFlag,
                      deviceStats
                    )}
                    numGateways={deviceStats.GATEWAY}
                    numSwitches={deviceStats.SWITCH}
                    numStorageDevices={deviceStats.STORAGE}
                    numPCEDevices={deviceStats.PCE}
                    numSensors={dmUXIFlag ? deviceStats.SENSOR : 0}
                    numBridges={dmBridgeFlag ? deviceStats.BRIDGE : 0}
                  />
                )}

                <CCSForm
                  validate="submit"
                  errorMessage=""
                  testId="service-delivery-contact-form"
                >
                  <Box
                    margin={{ top: 'medium', bottom: 'xxsmall' }}
                    direction="row-responsive"
                    gap="medium"
                  >
                    <FormField
                      data-testid="service-delivery-contact-field"
                      label={t('service_delivery_contact')}
                      name="name"
                      width="xxlarge"
                      required={{ indicator: false }}
                      error={formError || undefined}
                    >
                      <Dropdown
                        name="name"
                        options={listOfContact}
                        labelKey={(option) => {
                          return getUserName(
                            option?.contact?.first_name,
                            option?.contact?.last_name,
                            option?.contact?.email
                          )
                        }}
                        valueKey={(option) => {
                          return option?.contact?.email
                        }}
                        value={selectedContact || ''}
                        searchPlaceholder={t('search')}
                        emptySearchMessage={t('no_data_found')}
                        onSearch={(searchText) =>
                          handleDebouncedSearchValue(searchText)
                        }
                        onChange={({ option }) => {
                          setFormError('')
                          setSelectedContact(option)
                        }}
                        noBorder
                        onClose={() => {
                          if (searchTerm) {
                            setListOfContact([])
                            setPage(0)
                            setSearchTerm('')
                            setAppendList(true)
                          }
                        }}
                        testId="service-delivery-contact-input"
                        onMore={() => {
                          if (
                            totalItems > itemsPerPage &&
                            page < totalItems / itemsPerPage - 1
                          ) {
                            setAppendList(true)
                            setPage(page + 1)
                          }
                        }}
                      />
                    </FormField>

                    <Button
                      testId="assign-btn"
                      label={t('assign')}
                      secondary
                      type="submit"
                      onClick={handleAssignbtn}
                      margin={{ vertical: '32px' }} // Using px as an exception case to align Formfield & Button to achieve field level error since it was not achievable with Tshirt size.
                    />
                  </Box>
                </CCSForm>
                <Box
                  margin={{ top: 'medium' }}
                  pad={{ vertical: 'xsmall', horizontal: 'small' }}
                  border={{ side: 'bottom', color: 'border-weak' }}
                >
                  <Typography
                    level="4"
                    type="heading"
                    testId="assigned-contact-header"
                  >
                    {t('assigned_contact_title')}
                  </Typography>
                </Box>
                <Box height="xsmall" pad="small">
                  {assignedContact ? (
                    <Box
                      direction="row"
                      justify="between"
                      pad={{ vertical: 'small' }}
                    >
                      <Box direction="row" gap="xlarge">
                        <Typography
                          type="text"
                          size="medium"
                          weight="bold"
                          style={{
                            whiteSpace: 'nowrap'
                          }}
                          testId="name-key"
                        >
                          {t('name')}
                        </Typography>
                        <Typography
                          type="text"
                          size="medium"
                          testId="name-value"
                        >
                          {getContactValue(
                            assignedContact?.contact?.first_name,
                            assignedContact?.contact?.last_name,
                            assignedContact?.contact?.email
                          )}
                        </Typography>
                      </Box>
                      <Box>
                        <Trash
                          data-testid="trash-btn"
                          onClick={() => {
                            setAssignedContact(null)
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Typography
                      type="text"
                      size="medium"
                      testId="no-contact-assigned-description"
                    >
                      {t('select_contact_to_assign')}
                    </Typography>
                  )}
                </Box>
              </>
            </Box>
          }
          footer={
            <ModalFooter
              right={
                <ButtonGroup
                  buttonList={buttonList}
                  testId="modal-action-btns"
                />
              }
            />
          }
        />
      )}
      {errorMessage}
    </>
  )
}

ServiceDeliveryContactModal.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  openSuccessModal: PropTypes.func.isRequired,
  datum: PropTypes.array.isRequired,
  mode: PropTypes.oneOf(['BULK', 'SINGLE']),
  isCCSManager: PropTypes.bool,
  customerId: PropTypes.string
}

ServiceDeliveryContactModal.defaultProps = {
  mode: 'BULK',
  isCCSManager: false,
  customerId: undefined
}

export { ServiceDeliveryContactModal }
