import React from 'react'
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useHistory
} from 'react-router-dom'

import { checkGotoChooseAccount } from '../../../../utils/common-utils'

import UsersPage from './Users'
import UserDetailsPage from './UserDetails'

const WorkspaceUserRouter = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  checkGotoChooseAccount(history)
  return (
    <Switch>
      <Route exact path={path}>
        <UsersPage />
      </Route>
      <Route exact path={`${path}/:userId`}>
        <UserDetailsPage />
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  )
}

export { WorkspaceUserRouter }
