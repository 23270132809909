import PropTypes from 'prop-types'
import { Box } from 'grommet'

import { Typography } from '../../../../components'

const SelectContainer = ({ heading, subHeading, title, subtitle }) => {
  return (
    <Box>
      <Box>
        {heading && (
          <Typography type="heading" level="2">
            {heading}
          </Typography>
        )}
        {subHeading && (
          <Typography type="text" color="text-weak">
            {subHeading}
          </Typography>
        )}
      </Box>
      <Box gap="xsmall" pad={{ top: 'small' }}>
        <Box>
          {title && (
            <Typography type="text" color="text-weak" emphasis>
              {title}*
            </Typography>
          )}
          {subtitle && (
            <Typography type="paragraph" color="text-weak">
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

SelectContainer.propTypes = {
  /**
   * String heading.
   */

  heading: PropTypes.string,

  /**
   * String subheading.
   */
  subHeading: PropTypes.string,

  /**
   * String title.
   */
  title: PropTypes.string,

  /**
   * String subtitle.
   */
  subtitle: PropTypes.string
}

SelectContainer.defaultProps = {
  heading: '',
  subHeading: '',
  title: '',
  subtitle: ''
}

export default SelectContainer
