import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box } from 'grommet'

import {
  Typography,
  ModalDialog,
  ModalHeader,
  ModalFooter,
  ButtonGroup
} from '../../../../../../../../components'

const RecoveryConfirmationModal = ({ setOpen, handleConfirm }) => {
  const { t } = useTranslation(['iam', 'common'])
  const title = t('iam:sso_profiles.view_edit_create.recovery_modal_title')
  const message = t('iam:sso_profiles.view_edit_create.recovery_modal_message')

  return (
    <ModalDialog
      width="large"
      testId="recovery-account-confirmation-modal"
      onClose={() => setOpen(false)}
      header={
        <ModalHeader
          title={
            <Typography
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
              level={2}
              testId="recovery-account-modal-header-title"
              type="heading"
            >
              {title}
            </Typography>
          }
          onClose={() => setOpen(false)}
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <Typography
            type="text"
            testId="recovery-account-modal-confirmation-message"
          >
            {message}
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('common:cancel'),
                  default: true,
                  testId: 'recovery-account-modal-cancel-btn',
                  onClick: () => setOpen(false)
                },
                {
                  id: 1,
                  label: t('iam:sso_profiles.view_edit_create.disable'),
                  primary: true,
                  testId: 'recovery-account-modal-confirm-btn',
                  onClick: handleConfirm
                }
              ]}
              testId="recovery-account-modal-two-buttons"
            />
          }
        />
      }
    />
  )
}

RecoveryConfirmationModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired
}

export default RecoveryConfirmationModal
