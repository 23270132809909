import { Anchor } from 'grommet' // Replace with SB Anchor once href is made as optional prop
import lodashGet from 'lodash/get'

import { Typography } from '../../components'
import { get, post } from '../../utils/api-utils'
import { displayNotification } from '../../utils/notificiation-utils'
import {
  displayNotificationContent,
  handleErrorNotification
} from '../../utils/reporting-utils'
import {
  getApiErrorMessage,
  displayApiError
} from '../../utils/error-handling-utils'
import { GTS_SUPPORT_URL, SUPPORT_URL } from '../../utils/error-utils'
import { GTS_STATUS_ENUM } from '../acct-onboarding/utils'

export const getCountryName = (countryCode, countryList) => {
  let countryName
  if (countryList?.length) {
    countryName = countryList?.find(
      (country) => country.code === countryCode
    )?.name
  }
  return countryName || countryCode || ''
}

export const getExportReportStatus = (id, status, setStatus, token) => {
  get(`/ui-doorway/ui/v1/csv-reports/status/${id}`, {}, token).then(
    (response) => {
      if (response.status === 200) {
        const { status: responseStatus } = response.data
        if (responseStatus && responseStatus !== status) {
          setStatus(responseStatus)
        }
      }
    },
    (error) => {
      if (error.response.status === 404 && status === 'IN_PROGRESS') {
        // if 404, the task is expired, we can stop polling
        setStatus(null)
      }
    }
  )
}

export const sortByEnum = {
  company_name: {
    asc: '+ACCOUNT_SORT_BY_ALPHABETIC',
    desc: '-ACCOUNT_SORT_BY_ALPHABETIC'
  },
  accessed_at: {
    asc: '+ACCOUNT_SORT_BY_RECENT',
    desc: '-ACCOUNT_SORT_BY_RECENT'
  },
  created_at: {
    asc: '+ACCOUNT_SORT_BY_RECENT_CREATE',
    desc: '-ACCOUNT_SORT_BY_RECENT_CREATE'
  },
  device_subscription_ownership: {
    asc: '+ACCOUNT_SORT_BY_OPERATIONAL_MODE',
    desc: '-ACCOUNT_SORT_BY_OPERATIONAL_MODE'
  }
}

export const deviceSubscriptionOwnershipEnum = (operationalMode, t) => {
  const modes = {
    DEFAULT: t(`account_type.mode_msp_short`),
    CUSTOMER_OWNED_INVENTORY: t('customer_account.customer'),
    MSP_OWNED_INVENTORY: t(`account_type.mode_msp_short`)
  }
  return modes[operationalMode]
}

export const DESCRIPTION_MAX_LIMIT = 125

export const getRegionNameByRegionCode = (regionCode, regionList) => {
  let regionName
  if (regionList?.length) {
    regionName = regionList?.find((region) => region.code === regionCode)?.name
  }
  return regionName || regionCode || ''
}

export const MAX_LIST_LENGTH = 6

export const TRUNC_LIST_LENGTH = 3

export const NO_DATA = '--'

export const PHONE_PLACEHOLDER = '+1  (650) 829-2034'

export const CUSTOMER_READ_MODE = 'VIEW'

export const CUSTOMER_EDIT_MODE = 'EDIT'
export const displayAccountLaunchError = (
  error,
  t,
  setErrorNotification,
  accountInfo = {},
  ldFlagValue = null
) => {
  let errorMessage
  const accStatus = error?.response?.data?.account_status?.split('.')[1]
  if (error?.response?.status === 451) {
    let suppUrl
    if (lodashGet(error, 'response.data.reason') === 'IP_ACCESS_BLOCK') {
      suppUrl = SUPPORT_URL
      errorMessage = error?.response?.data?.message
    } else {
      suppUrl = GTS_SUPPORT_URL
      errorMessage = error?.response?.data?.message
      if (
        ldFlagValue &&
        (accStatus === GTS_STATUS_ENUM.BLOCKED ||
          accStatus === GTS_STATUS_ENUM.CONFIRMED_BLOCKED)
      ) {
        errorMessage = t('authn:gtcaas.error_msg_for_customer_workspace', {
          workspace_name: accountInfo?.company_name
        })
      }
    }
    setErrorNotification(
      <Typography type="text" size="xsmall" testId="gts-error-message">
        <>
          {errorMessage}{' '}
          <Anchor
            label={t('customer_account.contact_support')}
            size="xsmall"
            onClick={() => {
              window.open(suppUrl, '_blank')
            }}
            data-testid="contact-support-anchor"
          />
        </>
      </Typography>
    )
  } else if (
    ldFlagValue &&
    error?.response?.status === 423 &&
    (accStatus === GTS_STATUS_ENUM.PENDING ||
      accStatus === GTS_STATUS_ENUM.TIMEOUT)
  ) {
    errorMessage = t('authn:gtcaas.pending_msg_for_customer_workspace', {
      workspace_name: accountInfo?.company_name
    })
    setErrorNotification(errorMessage)
  } else {
    setErrorNotification(getApiErrorMessage(error, t))
  }
}

export const getExportCSVColumns = (
  t,
  getWorkspaceString,
  showWorkspaceString,
  WKSPC_CAPITALIZED,
  LDFlags
) => {
  const mspCustomerOwnLd = LDFlags['glcp-msp-customer-own']
  const columns = [
    'CUSTOMER_ID',
    'COMPANY_NAME',
    'DESCRIPTION',
    'ADDRESS',
    'CREATED_AT',
    'UPDATED_AT',
    'ACCESSED_AT',
    'PHONE_NUMBER',
    'EMAIL',
    ...(mspCustomerOwnLd ? ['DEVICE_AND_SUBSCRIPTION_OWNERSHIP'] : []),
    'SUBSCRIBED_DEVICES_COUNT',
    'ASSIGNED_DEVICES_COUNT',
    'CUSTOMER_LOGO'
  ]

  return columns.map((val) => {
    return val === 'COMPANY_NAME'
      ? {
          label: t(`customer_account.${val.toLowerCase()}`, {
            company: getWorkspaceString(
              showWorkspaceString,
              t,
              WKSPC_CAPITALIZED
            )
          }),
          value: val
        }
      : { label: t(`customer_account.${val.toLowerCase()}`), value: val }
  })
}

export const generateTenantsReportViaReportingAPICall = (
  request,
  token,
  setSuccessNotification,
  setErrorNotification,
  onSuccess,
  onError,
  t,
  reportingDashboardFlag
) => {
  post(`/report-mgmt/ui/v1/exports`, request, token).then(
    (response) => {
      onSuccess(response)
      const refId = response?.data?.tracking_id
      const repName = response?.data?.reportName
      setSuccessNotification(
        displayNotification(
          displayNotificationContent(reportingDashboardFlag, t, refId, repName),
          'info',
          setSuccessNotification
        )
      )
    },
    (error) => {
      if (!reportingDashboardFlag)
        handleErrorNotification(error, t, setErrorNotification, displayApiError)
      onError(error?.response)
    }
  )
}

export const getExportDescriptionBasedOnLd = (LDFlags, t) => {
  const {
    'glcp-switch-to-workspace': showWorkspaceString,
    'glcp-reportfw-tenants-export': reportingTenantsExportLdFlag,
    'glcp-reportfw-dashboard': reportingDashboardFlag
  } = LDFlags
  let desc = ''
  if (
    !reportingDashboardFlag ||
    (reportingDashboardFlag && !reportingTenantsExportLdFlag)
  ) {
    if (showWorkspaceString) {
      desc = !reportingTenantsExportLdFlag
        ? t('customer_account.export_customer_accounts_desc_workspace')
        : t('customer_account.export_customer_accounts_desc2_reporting')
    } else {
      desc = !reportingTenantsExportLdFlag
        ? t('customer_account.export_customer_accounts_desc2')
        : t('customer_account.export_customer_accounts_desc2_reporting')
    }
  } else {
    desc = t('reporting_dashboard:reporting_export_modal.tenants_desc')
  }
  return desc
}
