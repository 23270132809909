import React from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { FormInput } from '../../../../components'
import {
  NAME_MAX_LEN,
  DESC_MAX_LEN
} from '../../../manage-account/location-management/location-validation-utils'

const EditLocationModalContent = ({
  name,
  description,
  type,
  setDescription,
  setName,
  setRequestBody,
  locNameFieldError,
  setLocNameFieldError,
  locDescriptionFieldError,
  setLocDescriptionFieldError
}) => {
  const { t } = useTranslation(['location'])
  return (
    <Box width="medium" pad={{ top: 'medium' }}>
      <FormInput
        inputType="text"
        label={t('name')}
        name="editlocationName"
        testId="edit-location-name"
        value={name}
        onChange={(e) => {
          setLocNameFieldError('')
          setRequestBody((prevState) => ({
            ...prevState,
            name: e.target.value
          }))
          setName(e.target.value)
        }}
        required
        error={locNameFieldError}
        maxLength={NAME_MAX_LEN}
      />
      <FormInput
        inputType="text"
        label={t('description')}
        name="edit_location_description"
        testId="edit-location-description"
        value={description}
        onChange={(e) => {
          setLocDescriptionFieldError('')
          setRequestBody((prevState) => ({
            ...prevState,
            description: e.target.value
          }))
          setDescription(e.target.value)
        }}
        error={locDescriptionFieldError}
      />
      <FormInput
        disabled
        inputType="text"
        value={type}
        label={t('type')}
        name="edit_location_type"
        testId="edit-location_type"
        maxLength={DESC_MAX_LEN}
      />
    </Box>
  )
}
EditLocationModalContent.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  setRequestBody: PropTypes.func.isRequired,
  locNameFieldError: PropTypes.string.isRequired,
  setLocNameFieldError: PropTypes.func.isRequired,
  locDescriptionFieldError: PropTypes.string.isRequired,
  setLocDescriptionFieldError: PropTypes.func.isRequired
}
export { EditLocationModalContent }
