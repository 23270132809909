// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useCallback, useEffect, useState } from 'react'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, PageHeader } from 'grommet'
import { Trash } from 'grommet-icons'
import isEqual from 'lodash/isEqual'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ActionButton,
  Anchor,
  Button,
  DataTable,
  Loader,
  Typography
} from '../../../../../components'
import { get } from '../../../../../utils/api-utils'
import { displayApiError } from '../../../../../utils/error-handling-utils'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { getCCSAppDetails } from '../../../utils'
import EditRRPModal from '../../assignments/selected-assignment/EditRRPModal'
import {
  getCustomerAccount,
  isCoP,
  isGLOP,
  isMSP as isMSPAccountLoaded
} from '../../../../../utils/feature-flag-utils'
import { AssignRoleModal } from '../../common-components/assign-roles-modal'
import {
  allScopesRRPName,
  mspDefaultRRPId,
  nonScopedDefaultRRPId
} from '../../utils'
import { getAllAccessRulesList } from '../utils'
import { customRenderer } from '../../../../commoncomponents/CustomRenderer'
import { WorkspaceListModal } from '../../common-components/assign-roles-modal/WorkspaceListModal'
import { isServiceCentric } from '../../../../../utils/account-utils'
import {
  AUTHORIZATION,
  TENANT
} from '../../../authentication/saml-sso/sso-connection/steps/constants'

import { LimitedAccessModal } from './LimitedAccessModal'
import { RemoveRoleAssignmentModal } from './RemoveRoleAssignmentModal'

const UserAssignmentsWithRRP = ({
  user,
  inCCSManager,
  customerDetails,
  setUserType,
  userType,
  onSuccessUpdate,
  userSSOMode,
  isbulkInvite
}) => {
  const { oidcUser } = useReactOidc()
  const { t: commonT } = useTranslation(['common'])
  const { t: authnT } = useTranslation(['authn'])
  const { t: authzT } = useTranslation(['authz'])
  const { ccsApplicationId } = getCCSAppDetails()
  const username = inCCSManager ? user?.username : user?.email
  const custAccountLoaded = getCustomerAccount()
  const { platform_customer_id: pcid } = custAccountLoaded
  const sessionPlatformCid = custAccountLoaded?.platform_customer_id || ''

  const platformCid = inCCSManager ? customerDetails?.id : sessionPlatformCid
  const isMsp = inCCSManager
    ? customerDetails?.account_type === 'MSP'
    : isMSPAccountLoaded()

  const [roleAssignments, setRoleAssignments] = useState([])
  const [userData, setUserData] = useState({
    email: username,
    first_name: '',
    last_name: ''
  })
  const [selectedRoleAssignment, setSelectedRoleAssignment] = useState({})
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [showNotification, setShowNotification] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selectedWorkspaceIds, setselectedWorkspaceIds] = useState([])
  const [showWorkspaceModal, setShowWorkspaceModal] = useState(false)
  const [workspaceGroup, setWorkspaceGroup] = useState([])

  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const isTenantWorkspaceGroup = LDFlags['tenant-workspace-group'] && isMsp
  const rrpWorkForMSP = LDFlags['glcp-msp-rrp'] && isMsp
  const isCop = isCoP()
  const authSettingsGlop = LDFlags['glcp-jit-v2'] || isGLOP()

  const hideActions = userSSOMode
    ? authSettingsGlop &&
      (userSSOMode === AUTHORIZATION || userSSOMode === TENANT)
    : (!inCCSManager &&
        !LDFlags['glcp-edit-federated-user-role'] &&
        user?.user_type !== 'NON_FEDERATED') ||
      (userType === 'MSP' && custAccountLoaded?.account_type === 'TENANT')

  // modals
  const [showAssignRoleModal, setShowAssignRoleModal] = useState(false)
  const [showEditRRPModal, setShowEditRRPModal] = useState(false)
  const [showLimitedAccessModal, setShowLimitedAccessModal] = useState(false)
  const [showRemoveRoleAssignmentModal, setShowRemoveRoleAssignmentModal] =
    useState(false)

  const [refreshCount, setRefreshCount] = useState(0)
  const refreshTable = () => {
    setRefreshCount(refreshCount + 1)
  }

  const getRRPName = (datum) => {
    return (
      (datum?.resource_restriction_policies?.length &&
        datum?.resource_restriction_policies[0].name) ||
      '--'
    )
  }

  const isDefaultRRPID = (datum) => {
    return (
      datum?.resource_restriction_policies?.length &&
      (datum?.resource_restriction_policies[0]
        ?.resource_restriction_policy_id === nonScopedDefaultRRPId ||
        datum?.resource_restriction_policies[0]
          ?.resource_restriction_policy_id === mspDefaultRRPId)
    )
  }

  useEffect(() => {
    if (!platformCid) return
    setLoading(true)
    const url = inCCSManager
      ? `/support-assistant/v1alpha1/user-role-assignments`
      : `/authorization/ui/v2/customers/${platformCid}/users/${username}/role_assignments`

    get(
      url,
      {
        ...(inCCSManager && {
          platform_customer_id: platformCid,
          username
        })
      },
      oidcUser.access_token
    ).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          const roles = response?.data?.roles
          setUserData((val) => ({
            ...val.contact,
            first_name: response?.data?.user_first_name,
            last_name: response?.data?.user_last_name,
            email: response?.data?.user_name
          }))
          setUserType(response?.data?.user_type)
          setRoleAssignments(roles)
        } else {
          setRoleAssignments([])
        }
        setLoading(false)
      },
      (error) => {
        setShowErrorNotification(
          displayApiError(error, commonT, setShowErrorNotification)
        )
        setRoleAssignments([])
        setLoading(false)
      }
    )
  }, [
    oidcUser.access_token,
    commonT,
    ccsApplicationId,
    username,
    platformCid,
    refreshCount,
    inCCSManager,
    setUserType,
    LDFlags
  ])

  const fetchWorkspace = useCallback(
    (tenantIds) => {
      if (isTenantWorkspaceGroup) {
        let queryParams = '?group-type=Workspace'

        if (tenantIds) {
          tenantIds?.forEach((tenantId) => {
            queryParams += `&group-ids=${tenantId}`
          })
        }
        get(
          `/groups-management/v1alpha1/groups${queryParams}`,
          null,
          oidcUser.access_token,
          false,
          {},
          { 'X-workspaceID': pcid }
        ).then(
          (response) => {
            if (tenantIds) {
              setselectedWorkspaceIds(response?.data?.items ?? [])
              setShowWorkspaceModal(true)
            } else {
              setWorkspaceGroup(response?.data?.items ?? [])
            }
          },
          (error) => {
            setShowErrorNotification(
              displayApiError(error, commonT, setShowErrorNotification)
            )
          }
        )
      }
    },
    [commonT, oidcUser, isTenantWorkspaceGroup, pcid, setWorkspaceGroup]
  )

  useEffect(() => {
    fetchWorkspace()
  }, [fetchWorkspace])

  const getUserFullName = (firstName, lastName, email) => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    return firstName || lastName || email || ''
  }

  const getSubTitle = () => {
    let subtitle = ''
    if (newDashboardFlag) {
      subtitle = authnT('users.user_role_assignments_service_subheading')
    } else if (isbulkInvite) {
      subtitle = `Review roles to be assigned copied from ${getUserFullName(
        user?.first_name,
        user?.last_name,
        user?.email
      )}`
    } else {
      subtitle = authnT('users.user_role_assignments_subheading')
    }
    return subtitle
  }

  const accessRuleColumn = [
    // TODO: handle MSP, UX is in progress For now we are showing access rules
    {
      header: authzT('assignments.access_rule_header'),
      type: 'string',
      property: 'access_rule',
      render: (datum) => {
        let isSpecificWorkspace = false
        const accessRule = getAllAccessRulesList(
          authnT,
          isTenantWorkspaceGroup
        ).filter(
          (val) =>
            datum.access_rules && isEqual(val.access_rules, datum.access_rules)
        )
        let accessRuleName = accessRule.length ? accessRule[0].label : '--'
        let isTenantAndSpecificWorkspace = false
        if (isTenantWorkspaceGroup) {
          if (
            accessRuleName === '--' &&
            datum.access_rules?.tenants?.length > 0 &&
            datum.access_rules?.msp
          ) {
            isSpecificWorkspace = true
            accessRuleName = getAllAccessRulesList(
              authnT,
              isTenantWorkspaceGroup
            )[3]?.label
            isTenantAndSpecificWorkspace = true
          } else if (
            accessRuleName === '--' &&
            datum.access_rules?.tenants?.length > 0 &&
            !datum.access_rules?.msp
          ) {
            isSpecificWorkspace = true
            accessRuleName = getAllAccessRulesList(
              authnT,
              isTenantWorkspaceGroup
            )[4]?.label
          }
        }
        let accessRuleNameTrans = (
          <Typography size="medium" type="text" testId="page-title">
            {accessRuleName}
          </Typography>
        )
        if (isSpecificWorkspace) {
          if (isTenantAndSpecificWorkspace) {
            accessRuleNameTrans = (
              <Typography type="text">
                <Trans i18nKey="users.msp_and_specific_tenants_link" t={authnT}>
                  <Anchor
                    label={authnT('users.workspace_groups')}
                    href="#"
                    testId="workspace-anchor"
                    onClick={() => {
                      fetchWorkspace(datum.access_rules?.tenants)
                    }}
                    focusIndicator={false}
                  />
                </Trans>
              </Typography>
            )
          } else {
            accessRuleNameTrans = (
              <Anchor
                label={accessRuleName}
                href="#"
                testId="workspace-anchor"
                onClick={() => {
                  fetchWorkspace(datum.access_rules?.tenants)
                }}
                focusIndicator={false}
              />
            )
          }
        }
        return accessRuleNameTrans
      }
    }
  ]

  const resourceAccessColumn = [
    {
      header: authzT('rrp.resource_access'),
      type: 'string',
      property: 'resource_restriction_policy',
      render: (datum) => {
        const rrpName = getRRPName(datum)
        if (
          rrpName === allScopesRRPName &&
          datum?.application_id !== ccsApplicationId
        )
          return (
            <Typography size="medium" type="text" testId="resource-access">
              {authzT('rrp.full_access')}
            </Typography>
          )

        if (
          rrpName === '--' ||
          datum?.application_id === ccsApplicationId ||
          isDefaultRRPID(datum)
        )
          return (
            <Typography size="medium" type="text" testId="resource-access">
              {authzT('rrp.none')}
            </Typography>
          )

        return (
          <Box style={{ width: 'fit-content' }}>
            <Anchor
              label={authzT('rrp.limited_access')}
              href="#"
              testId="limited-access-anchor"
              onClick={() => {
                setSelectedRoleAssignment(datum)
                setShowLimitedAccessModal(true)
              }}
              focusIndicator={false}
            />
          </Box>
        )
      }
    }
  ]

  const accessColumnForMSP = rrpWorkForMSP
    ? [...accessRuleColumn, ...resourceAccessColumn]
    : accessRuleColumn

  const columns = [
    {
      header: authzT('application'),
      type: 'string',
      property: 'application_name',
      render: (datum) => (
        <Typography type="text" size="medium" testId="application-name">
          {datum?.application_name}
        </Typography>
      )
    },
    {
      header: authzT('roles.role'),
      type: 'string',
      property: 'role_name'
    },
    ...(isMsp ? accessColumnForMSP : resourceAccessColumn),
    {
      header: '',
      property: '',
      render: (datum) => {
        return (
          !isbulkInvite && (
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/authorization',
                permission: 'ccs.authorization.edit'
              }}
            >
              {(!isMsp || rrpWorkForMSP) &&
              !(
                userType === 'MSP' &&
                custAccountLoaded?.account_type === 'TENANT'
              ) ? (
                <ActionButton
                  actions={[
                    {
                      label: authzT('rrp.edit_resource_access'),
                      onClick: () => {
                        setSelectedRoleAssignment(datum)
                        setShowEditRRPModal(true)
                      },
                      hidden:
                        datum.application_id === ccsApplicationId ||
                        !(!hideActions || isCop)
                    },
                    {
                      label: authzT('assignments.remove_role'),
                      onClick: () => {
                        setSelectedRoleAssignment(datum)
                        setShowRemoveRoleAssignmentModal(true)
                      },
                      visibility: {
                        rbac: {
                          resource: '/ccs/authorization',
                          permission: 'ccs.authorization.delete'
                        }
                      }
                    }
                  ]}
                  customRenderer={(element, visibility, index) =>
                    customRenderer(element, visibility, index)
                  }
                  testId="multipleactions-action-btn"
                />
              ) : (
                isMsp &&
                !(
                  userType === 'MSP' &&
                  custAccountLoaded?.account_type === 'TENANT'
                ) && (
                  <Trash
                    onClick={() => {
                      setSelectedRoleAssignment(datum)
                      setShowRemoveRoleAssignmentModal(true)
                    }}
                    style={{ cursor: 'pointer' }}
                    testId="remove-role-icon"
                  />
                )
              )}
            </VisibilityWrapper>
          )
        )
      }
    }
  ]

  return (
    <>
      {showErrorNotification}
      {showNotification}
      {loading ? (
        <Box justify="center" align="center">
          <Loader testId="loader-spinner" />
        </Box>
      ) : (
        <>
          <PageHeader
            pad={{ top: 'small' }}
            responsive
            size="small"
            title={
              !isbulkInvite && (
                <Typography
                  level="2"
                  type="heading"
                  testId="role-assignments-title"
                >
                  {authzT('roles_title')}
                </Typography>
              )
            }
            subtitle={
              <Typography
                level="4"
                type="heading"
                testId="role-assignments-subtitle"
              >
                {getSubTitle()}
              </Typography>
            }
            actions={
              (isCop || !hideActions) &&
              !isbulkInvite && (
                <VisibilityWrapper
                  rbac={{
                    resource: '/ccs/authorization',
                    permission: 'ccs.authorization.edit'
                  }}
                >
                  <Button
                    label={authnT('users.assign_role')}
                    secondary
                    testId="assign-role-btn"
                    onClick={() => {
                      setShowAssignRoleModal(true)
                    }}
                  />
                </VisibilityWrapper>
              )
            }
          />
          <DataTable
            margin={{ top: 'small' }}
            grid={{
              columns,
              data: roleAssignments
            }}
            testId="user-roles-datatable"
          />
        </>
      )}
      {showAssignRoleModal && (
        <AssignRoleModal
          userData={userData}
          setModal={setShowAssignRoleModal}
          reloadUsersTable={refreshTable}
          existingRoleAssignments={roleAssignments}
          customerDetails={customerDetails}
          inCCSManager={inCCSManager}
          setShowNotification={setShowNotification}
          onSuccessAssign={onSuccessUpdate}
        />
      )}
      {showEditRRPModal && (
        <EditRRPModal
          setShowEditRRPModal={setShowEditRRPModal}
          username={username}
          selectedRoleAssignment={selectedRoleAssignment}
          allRoleAssignments={roleAssignments}
          reloadTable={refreshTable}
          customerDetails={customerDetails}
          inCCSManager={inCCSManager}
          setShowNotification={setShowNotification}
        />
      )}
      {showLimitedAccessModal && (
        <LimitedAccessModal
          showActions={
            isCop ||
            !hideActions ||
            (isbulkInvite &&
              !(
                userType === 'MSP' &&
                custAccountLoaded?.account_type === 'TENANT'
              ))
          }
          setShowLimitedAccessModal={setShowLimitedAccessModal}
          rrpData={selectedRoleAssignment?.resource_restriction_policies}
          selectedRoleAssignment={selectedRoleAssignment}
          inCCSManager={inCCSManager}
        />
      )}
      {showRemoveRoleAssignmentModal && (
        <RemoveRoleAssignmentModal
          setShowRemoveRoleAssignmentModal={setShowRemoveRoleAssignmentModal}
          username={username}
          selectedRoleAssignment={selectedRoleAssignment}
          reloadTable={refreshTable}
          customerId={customerDetails?.id}
          inCCSManager={inCCSManager}
          setShowNotification={setShowNotification}
          onSuccessRemoval={onSuccessUpdate}
        />
      )}
      {showWorkspaceModal && (
        <WorkspaceListModal
          setShowModal={setShowWorkspaceModal}
          workspaceGroup={workspaceGroup}
          selectedWorkspaceIds={selectedWorkspaceIds}
        />
      )}
    </>
  )
}

UserAssignmentsWithRRP.propTypes = {
  user: PropTypes.object,
  inCCSManager: PropTypes.bool,
  customerDetails: PropTypes.object,
  setUserType: PropTypes.func,
  userType: PropTypes.string,
  onSuccessUpdate: PropTypes.func,
  userSSOMode: PropTypes.string,
  isbulkInvite: PropTypes.bool
}

UserAssignmentsWithRRP.defaultProps = {
  user: {},
  inCCSManager: false,
  customerDetails: {},
  setUserType: () => {},
  userType: null,
  onSuccessUpdate: () => {},
  userSSOMode: null,
  isbulkInvite: false
}

export { UserAssignmentsWithRRP }
