import React from 'react'
import PropTypes from 'prop-types'

import useVisibilityReducer from './visibility-reducer'

const VisibilityContext = React.createContext()

export const VisibilityContextProvider = ({ children }) => {
  const stateAndDispatch = useVisibilityReducer()
  return (
    <VisibilityContext.Provider value={stateAndDispatch}>
      {children}
    </VisibilityContext.Provider>
  )
}

VisibilityContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const useVisibilityContext = () => {
  const { VisibilityState, dispatchVisibilityContext } =
    React.useContext(VisibilityContext)
  return { ...VisibilityState, dispatchVisibilityContext }
}
