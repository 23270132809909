import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, DateInput, MaskedInput, CheckBox } from 'grommet'
import { FormClock } from 'grommet-icons'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'

import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import { validateForm } from '../../../../../../../utils/validation-utils'

const getDate = (
  expirationDate,
  expirationTime,
  start_date,
  start_time,
  start_date_checkbox,
  banner,
  i18nTranslate
) => {
  const dateToday = dayjs().unix()

  let expEpochTime
  let scheduleEpochTime
  let scheduleDate
  let errorMessage = ''
  let errorMessageDate = ''
  const validationResult = {
    expirationDate: true,
    expirationTime: true,
    start_date: true,
    start_time: true,
    time_error: '',
    date_error: ''
  }

  if (start_date_checkbox) {
    errorMessage = i18nTranslate('required')
    if (isEmpty(start_date) || isEmpty(start_time)) {
      validationResult.start_date = false
      validationResult.start_time = false
      validationResult.time_error = errorMessage
      validationResult.date_error = errorMessage
    }
  }

  if (banner) {
    errorMessage = i18nTranslate('required')
    if (
      (isEmpty(expirationDate) && !isEmpty(expirationTime)) ||
      (!isEmpty(expirationDate) && isEmpty(expirationTime))
    ) {
      validationResult.expirationDate = false
      validationResult.expirationTime = false
      validationResult.time_error = errorMessage
      validationResult.date_error = errorMessage
    }
  }

  if (start_date_checkbox && !isEmpty(start_time) && !isEmpty(start_date)) {
    scheduleDate = dayjs(start_date).format('MM/DD/YYYY')
    scheduleEpochTime = dayjs(`${scheduleDate} ${start_time}`).unix()
  } else {
    scheduleEpochTime = dayjs(scheduleDate).unix()
  }

  if (banner && !isEmpty(expirationTime) && !isEmpty(expirationDate)) {
    const expDate = dayjs(expirationDate).format('MM/DD/YYYY')
    expEpochTime = dayjs(`${expDate} ${expirationTime}`).unix()
  } else {
    expEpochTime = dayjs(expirationDate).unix()
  }

  // Check if the schedule date and Expiration Date is greater than the current date and Expiration Date should be greater than Schedule Date
  if (scheduleEpochTime < dateToday && expEpochTime < dateToday) {
    if (!isEmpty(start_time) && !isEmpty(expirationTime)) {
      errorMessage = i18nTranslate('notification.time_check')
      validationResult.expirationTime = false
      validationResult.start_time = false
      validationResult.time_error = errorMessage
    } else {
      errorMessageDate = i18nTranslate('notification.date_check')
      validationResult.expirationDate = false
      validationResult.start_date = false
      validationResult.time_error = errorMessage
      validationResult.date_error = errorMessageDate
    }
  } else if (
    expEpochTime < dateToday &&
    !isEmpty(expirationTime) &&
    !isEmpty(expirationDate)
  ) {
    if (!isEmpty(expirationTime)) {
      errorMessage = i18nTranslate('notification.time_check')
      validationResult.expirationTime = false
      validationResult.time_error = errorMessage
    } else {
      errorMessageDate = i18nTranslate('notification.date_check')
      validationResult.expirationDate = false
      validationResult.time_error = errorMessage
      validationResult.date_error = errorMessageDate
    }
  } else if (
    scheduleEpochTime < dateToday &&
    !isEmpty(start_date) &&
    !isEmpty(start_time)
  ) {
    if (!isEmpty(start_time)) {
      errorMessage = i18nTranslate('notification.time_check')
      validationResult.start_time = false
      validationResult.time_error = errorMessage
    } else {
      errorMessage = i18nTranslate('notification.time_check')
      errorMessageDate = i18nTranslate('notification.date_check')
      validationResult.start_date = false
      validationResult.date_error = errorMessageDate
    }
  } else if (
    (scheduleEpochTime > expEpochTime || scheduleEpochTime === expEpochTime) &&
    !isEmpty(expirationTime) &&
    !isEmpty(start_time)
  ) {
    if (!isEmpty(expirationTime)) {
      errorMessage = i18nTranslate('notification.banner_schedule_time_check')
      validationResult.expirationTime = false
      validationResult.time_error = errorMessage
    } else {
      errorMessageDate = i18nTranslate(
        'notification.banner_schedule_date_check'
      )
      validationResult.expirationTime = false
      validationResult.time_error = errorMessage
      validationResult.date_error = errorMessageDate
    }
  } else if (
    !isEmpty(expirationTime) &&
    isEmpty(expirationDate) &&
    !isEmpty(start_date) &&
    !isEmpty(start_time)
  ) {
    errorMessageDate = i18nTranslate('notification.no_date_error')
    validationResult.expirationDate = false
    validationResult.date_error = errorMessage
  } else if (
    !isEmpty(expirationTime) &&
    !(expirationTime?.includes('AM') || expirationTime?.includes('PM'))
  ) {
    errorMessage = i18nTranslate('notification.no_am_pm_error')
    validationResult.expirationTime = false
    validationResult.time_error = errorMessage
    validationResult.date_error = errorMessageDate
  } else if (
    !isEmpty(start_time) &&
    isEmpty(start_date) &&
    isEmpty(expirationTime) &&
    isEmpty(expirationDate)
  ) {
    errorMessageDate = i18nTranslate('notification.no_date_error')
    validationResult.start_date = false
    validationResult.time_error = errorMessage
    validationResult.date_error = errorMessageDate
  } else if (
    !isEmpty(start_time) &&
    !(start_time?.includes('AM') || start_time?.includes('PM'))
  ) {
    errorMessage = i18nTranslate('notification.no_am_pm_error')
    validationResult.start_time = false
    validationResult.time_error = errorMessage
    validationResult.date_error = errorMessageDate
  }
  return Object.values(validationResult)
}

const validateFields = (formValues, i18nTranslate) => {
  const {
    expirationDate,
    expirationTime,
    banner,
    start_date,
    start_time,
    start_date_checkbox
  } = formValues

  const expirationInUnix = getDate(
    expirationDate,
    expirationTime,
    start_date,
    start_time,
    start_date_checkbox,
    banner,
    i18nTranslate
  )

  return {
    expirationDate: expirationInUnix[0] ? '' : expirationInUnix[5],
    expirationTime: expirationInUnix[1] ? '' : expirationInUnix[4],
    start_date: expirationInUnix[2] ? '' : expirationInUnix[5],
    start_time: expirationInUnix[3] ? '' : expirationInUnix[4],
    isValid:
      (banner ? !!expirationInUnix[0] && !!expirationInUnix[1] : true) &&
      (start_date_checkbox
        ? !!expirationInUnix[2] && !!expirationInUnix[3]
        : true)
  }
}

export const validateScheduleNotification = (formValues, i18nTranslate) => {
  return validateForm(formValues, i18nTranslate, validateFields)
}

const ScheduleNotification = ({ isEdit, isUpdateFlow }) => {
  const { t } = useTranslation(['manage'])

  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)
  const [errorMessage, setErrorMessage] = useState({})

  useEffect(() => {
    if (attemptedAdvance) {
      const validation = validateFields(formValues, t)
      setErrorMessage(validation)
    }
  }, [formValues, attemptedAdvance, t])

  const onChange = (event) => {
    const nextValue = event.value
    setFormValues({
      ...formValues,
      expirationDate: nextValue
    })
  }

  const onChangeSchedule = (event) => {
    const nextValue = event.value
    setFormValues({
      ...formValues,
      start_date: nextValue
    })
  }

  return (
    <Box
      gap="xsmall"
      width="medium"
      justify="between"
      align="start"
      alignSelf="center"
    >
      <Box margin={{ bottom: 'medium' }}>
        <FormField
          name="schedule_checkbox"
          label={t('notification.schedule')}
          help={t('notification.schedule_field_description')}
        >
          <CheckBox
            pad="none"
            testId="schedule-checkbox"
            disabled={isEdit || isUpdateFlow}
            name="schedule_checkbox"
            checked={formValues.start_date_checkbox}
            label={t('notification.schedule_notification')}
            onChange={(event) => {
              setFormValues({
                ...formValues,
                start_date_checkbox: event.target.checked
              })
            }}
          />
        </FormField>

        {formValues?.start_date_checkbox && (
          <Box
            direction="column"
            gap="medium"
            align="center"
            margin={{ bottom: 'small', top: 'none' }}
          >
            <Box>
              <FormField
                data-testid="dismiss-date-form-field"
                name="start_date"
                error={errorMessage.start_date}
              >
                <DateInput
                  format="mm/dd/yyyy"
                  value={formValues?.start_date}
                  onChange={onChangeSchedule}
                  testId="schedule-date-input"
                  placeholder="MM/DD/YYYY"
                  calendarProps={{
                    bounds: [
                      new Date().toISOString(),
                      dayjs().add(30, 'd').toISOString()
                    ],
                    alignSelf: 'center'
                  }}
                />
              </FormField>
              <Box width="medium">
                <FormField
                  name="start_time"
                  testId="schedule-time-input"
                  error={errorMessage.start_time}
                >
                  <MaskedInput
                    data-testid="schedule-time-input-masked"
                    icon={<FormClock />}
                    mask={[
                      {
                        length: [1, 2],
                        options: Array.from({ length: 12 }, (v, k) => k + 1),
                        regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                        placeholder: 'HH'
                      },
                      { fixed: ':' },
                      {
                        length: 2,
                        options: ['00', '15', '30', '45'],
                        regexp: /^[0-5][0-9]$|^[0-9]$/,
                        placeholder: 'MM'
                      },
                      { fixed: ' ' },
                      {
                        length: 2,
                        options: ['AM', 'PM'],
                        regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                        placeholder: 'AM/PM'
                      }
                    ]}
                    value={formValues.start_time}
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        start_time: event.target.value
                      })
                    }}
                  />
                </FormField>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {formValues?.banner && (
        <Box
          direction="column"
          gap="medium"
          align="center"
          margin={{ bottom: 'large' }}
        >
          <Box>
            <Box width="medium">
              <FormField
                data-testid="dismiss-date-form-field"
                name="expirationDate"
                error={errorMessage.expirationDate}
                label={t('notification.banner_expiration')}
                help={t('notification.banner_expiration_description')}
              >
                <DateInput
                  format="mm/dd/yyyy"
                  value={formValues?.expirationDate}
                  onChange={onChange}
                  data-testid="expiration-date-input"
                  placeholder="MM/DD/YYYY"
                  calendarProps={{
                    bounds: [new Date().toISOString(), '2050-12-31'],
                    alignSelf: 'center'
                  }}
                />
              </FormField>
            </Box>
            <Box width="medium">
              <FormField
                margin={{ bottom: 'medium' }}
                name="expirationTime"
                testId="banner-expiration-time"
                error={errorMessage.expirationTime}
              >
                <MaskedInput
                  data-testid="banner-expiration-time-masked"
                  icon={<FormClock />}
                  mask={[
                    {
                      length: [1, 2],
                      options: Array.from({ length: 12 }, (v, k) => k + 1),
                      regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                      placeholder: 'HH'
                    },
                    { fixed: ':' },
                    {
                      length: 2,
                      options: ['00', '15', '30', '45'],
                      regexp: /^[0-5][0-9]$|^[0-9]$/,
                      placeholder: 'MM'
                    },
                    { fixed: ' ' },
                    {
                      length: 2,
                      options: ['AM', 'PM'],
                      regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                      placeholder: 'AM/PM'
                    }
                  ]}
                  value={formValues.expirationTime}
                  onChange={(event) => {
                    setFormValues({
                      ...formValues,
                      expirationTime: event.target.value
                    })
                  }}
                />
              </FormField>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

ScheduleNotification.prototypes = {
  isEdit: PropTypes.func.isRequired
}

export default ScheduleNotification
