import React from 'react'
import { Card, CardBody, CardFooter, CardHeader } from 'grommet'
import PropTypes from 'prop-types'

export const WidgetLayout = ({
  header,
  body,
  footer,
  testId,
  noElevation,
  widgetBodyCustomStyle
}) => {
  return (
    <Card data-testid={testId} elevation={noElevation ? 'none' : 'medium'} flex>
      {header && <CardHeader>{header}</CardHeader>}
      {body && (
        <CardBody
          justify="center"
          pad={{ horizontal: 'medium', bottom: 'medium' }}
          {...widgetBodyCustomStyle}
        >
          {body}
        </CardBody>
      )}
      {footer && (
        <CardFooter
          height={{ max: 'small' }}
          pad={{ horizontal: 'xxsmall' }}
          border={{ side: 'top', color: { light: 'light-2' }, size: 'small' }}
        >
          {footer}
        </CardFooter>
      )}
    </Card>
  )
}

WidgetLayout.propTypes = {
  header: PropTypes.element,
  body: PropTypes.element.isRequired,
  footer: PropTypes.element,
  testId: PropTypes.string.isRequired,
  noElevation: PropTypes.bool,
  widgetBodyCustomStyle: PropTypes.object
}

WidgetLayout.defaultProps = {
  header: undefined,
  footer: undefined,
  noElevation: false,
  widgetBodyCustomStyle: {}
}
