import React from 'react'
import { Box } from 'grommet'
import { Alert } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { NoDataInfo } from '../../../components'

const DataLoadFailure = ({ testId }) => {
  const { t } = useTranslation(['local-gateway'])

  return (
    <Box
      align="center"
      justify="center"
      pad="medium"
      {...(testId && { 'data-testid': `${testId}-load-failure` })}
    >
      <NoDataInfo
        icon={<Alert size="large" />}
        subtitle={t('api_messages.unable_to_load_data')}
        testId={`${testId}-no-data-icon`}
      />
    </Box>
  )
}

DataLoadFailure.defaultProps = {
  testId: undefined
}

DataLoadFailure.propTypes = {
  testId: PropTypes.string
}

export default DataLoadFailure
