import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'

export const getCreatePostRequestBody = (
  formValues,
  addReviewer,
  selectedReviewers
) => {
  const body = {}
  body.title = formValues?.title
  body.shortDescription = formValues?.shortDescription
  body.serviceCategory = formValues?.serviceCategory

  const publishDateDetails = {
    date: formValues?.scheduleDate
      ? dayjs(formValues?.scheduleDate).format('MM/DD/YYYY')
      : '',
    time: formValues?.scheduleTime || '',
    timeZone: formValues?.timezone
  }
  body.publishDate = publishDateDetails
  body.referenceLinks = formValues?.referenceLinks.filter(
    (links) => !isEmpty(links.label)
  )
  body.media = formValues?.media
  body.mediaType = formValues?.mediaType
  if (addReviewer) {
    body.reviewers = selectedReviewers || []
  }
  body.summaryFlag = formValues?.summaryFlag
  if (formValues?.summaryFlag) {
    body.summary = formValues?.summary
  }
  body.publicationLocation = formValues?.publicationLocation

  return body
}

export const getEditPostRequestBody = (formValues, addReviewer) => {
  const body = {}
  body.title = formValues?.title
  body.shortDescription = formValues?.shortDescription
  body.serviceCategory = formValues?.serviceCategory
  const publishDateDetails = {
    date: formValues?.scheduleDate
      ? dayjs(formValues?.scheduleDate).format('MM/DD/YYYY')
      : '',
    time: formValues?.scheduleTime || '',
    timeZone: formValues?.timeZone
  }

  body.publishDate = publishDateDetails
  body.referenceLinks = formValues?.referenceLinks.filter(
    (links) => !isEmpty(links.label)
  )
  body.media = formValues?.media
  body.mediaType = formValues?.mediaType
  if (addReviewer) {
    body.reviewers = formValues?.reviewers || []
  }
  body.summaryFlag = formValues?.summaryFlag
  if (formValues?.summaryFlag) {
    body.summary = formValues?.summary
  }
  body.publicationLocation = formValues?.publicationLocation

  return body
}
