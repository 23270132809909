import { useEffect } from 'react'

import { getProvisionStatus, getConversionStatus } from './utils'

export const usePollProvision = (
  appStatus,
  customerId,
  setFunc,
  token,
  serviceProvisionId // leave undefined if not service provision
) => {
  useEffect(() => {
    let refreshTimer = null
    async function polling() {
      if (
        appStatus.status === 'PROVISION_INITIATED' ||
        appStatus.status === 'UNPROVISION_INITIATED' ||
        appStatus.status === 'UNPROVISION_FAILED'
      ) {
        getProvisionStatus(
          customerId,
          appStatus.status,
          token,
          setFunc,
          serviceProvisionId
        )
        refreshTimer = setTimeout(() => {
          polling()
        }, 5000)
      }
    }
    polling()
    return () => {
      clearTimeout(refreshTimer)
    }
  }, [appStatus, customerId, setFunc, token, serviceProvisionId])
}

export const usePollConversion = (
  convStatus,
  customerId,
  setProv,
  setConv,
  token
) => {
  useEffect(() => {
    let refreshTimer = null
    async function polling() {
      if (convStatus.status === 'MSP_CONVERSION_INITIATED') {
        getConversionStatus(
          customerId,
          convStatus.status,
          token,
          setProv,
          setConv
        )
        refreshTimer = setTimeout(() => {
          polling()
        }, 5000)
      }
    }
    polling()
    return () => {
      clearTimeout(refreshTimer)
    }
  }, [convStatus, customerId, setProv, setConv, token])
}
