// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useState } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  ModalDialog,
  ModalFooter,
  Typography,
  ButtonGroup,
  Notification
} from '../../../../../../components'
import { displayApiError } from '../../../../../../utils/error-handling-utils'
import { post } from '../../../../../../utils/api-utils'

const RemoveSubscriptionModal = ({
  setModalOpen,
  removeSubscriptions,
  modalHeading,
  modalDescription,
  modalRemoveBtn,
  onRemoveSuccess
}) => {
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const { oidcUser } = useReactOidc()

  const onRemoveSubscription = () => {
    post(
      `/ui-doorway/ui/v1/license/unclaim`,
      {
        subscription_keys: removeSubscriptions
      },
      oidcUser.access_token
    ).then(
      () => {
        onRemoveSuccess()
      },
      (err) => {
        setShowErrorNotification(err)
      }
    )
  }
  const { t } = useTranslation(['device'])
  return (
    <>
      <ModalDialog
        width="medium"
        testId="remove-subscriptions-modal-dialog"
        onclose={() => {
          setModalOpen(false)
        }}
        content={
          <Box>
            <Typography
              type="heading"
              testId="remove-subscription-modal-title"
              level="2"
            >
              {modalHeading}
            </Typography>
            <Notification
              type="inline"
              status="critical"
              testId="notification"
              text={t('remove_subscriptions_modal_warning')}
              margin={{ top: 'medium', bottom: 'small' }}
            />
            <Typography
              type="paragraph"
              testId="remove-subscription-modal-description"
            >
              {modalDescription}
            </Typography>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    label: t('cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    onClick: () => setModalOpen(false)
                  },
                  {
                    label: modalRemoveBtn,
                    primary: true,
                    testId: 'remove-subscriptions-btn',
                    onClick: onRemoveSubscription
                  }
                ]}
                testId="remove-subscritpion-modal-footer-buttons"
              />
            }
          />
        }
      />
      {showErrorNotification &&
        displayApiError(showErrorNotification, t, setShowErrorNotification)}
    </>
  )
}

RemoveSubscriptionModal.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  removeSubscriptions: PropTypes.array.isRequired,
  modalHeading: PropTypes.string.isRequired,
  modalDescription: PropTypes.string.isRequired,
  modalRemoveBtn: PropTypes.string.isRequired,
  onRemoveSuccess: PropTypes.func.isRequired
}

export { RemoveSubscriptionModal }
