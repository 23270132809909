import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { Typography } from '../../../../components'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { InstalledAppList, FormAlert } from '../../../applications/components'
import { isMSP } from '../../../../utils/feature-flag-utils'

const RegionsTab = ({
  serviceDetailData,
  provisions,
  isServiceProvision,
  regionsCanBeProvisioned,
  setPageData,
  t
}) => {
  provisions.forEach((provision) => {
    provision.regionLabel = serviceDetailData?.available_regions?.find(
      ({ code }) => code === provision.region
    )?.name
  })
  const featuresSupported =
    serviceDetailData?.service_offer?.features_supported || []
  const normalInstances = provisions?.filter(
    (instance) =>
      (!isMSP() &&
        (instance.provision_status === 'PROVISIONED' ||
          instance.provision_status === 'PROVISION_INITIATED' ||
          instance.provision_status === 'PROVISION_FAILED' ||
          instance.provision_status === 'UNPROVISION_INITIATED') &&
        (!instance.workspace_transfer_status ||
          instance.workspace_transfer_status === 'TRANSFER_COMPLETED')) ||
      (isMSP() &&
        (instance.provision_status === 'PROVISIONED' ||
          instance.provision_status === 'PROVISION_INITIATED' ||
          instance.provision_status === 'PROVISION_FAILED' ||
          instance.provision_status === 'UNPROVISION_INITIATED') &&
        ((!isServiceProvision &&
          (!instance.msp_conversion_status ||
            instance.msp_conversion_status === 'MSP_CONVERTED')) ||
          (isServiceProvision &&
            (!instance.workspace_transfer_status ||
              instance.workspace_transfer_status === 'TRANSFER_COMPLETED'))))
  )
  const mspConversionFailedInstances = provisions?.filter(
    (instance) =>
      isMSP() &&
      (instance.provision_status === 'PROVISIONED' ||
        instance.provision_status === 'UNPROVISION_INITIATED') &&
      (instance.msp_conversion_status === 'MSP_CONVERSION_FAILED' ||
        instance.msp_conversion_status === 'MSP_CONVERSION_INITIATED')
  )
  const isGLC = serviceDetailData?.slug === 'GLC'
  return (
    <Box margin={{ top: 'medium' }}>
      <Box justify="between" direction="row" margin={{ bottom: 'large' }}>
        <Box>
          <Typography type="heading" level="2" testId="services-regions-title">
            {t('dashboard:service_regions.title')}
          </Typography>
          <Typography
            type="text"
            size="large"
            testId="services-regions-sub-copy"
          >
            {isGLC
              ? t('apps:deployment_regions_subtitle_launch_only')
              : t('dashboard:service_details.region_tab_prompt')}
          </Typography>
        </Box>
      </Box>
      <VisibilityWrapper
        rbac={{
          resource: '/ccs/app-provision/provisions',
          permission: 'ccs.app-provision.edit'
        }}
      >
        {regionsCanBeProvisioned.length === 0 && !isGLC && (
          <FormAlert message={t('apps:new_dashboard_unavailable_regions')} />
        )}
      </VisibilityWrapper>
      {normalInstances?.map((instance) => (
        <InstalledAppList
          appInfo={instance}
          refreshAppList={setPageData}
          appType="installed"
          isServiceProvision={isServiceProvision}
          key={instance.application_instance_id}
          featuresSupported={featuresSupported}
        />
      ))}
      {mspConversionFailedInstances.length !== 0 && (
        <Box direction="column" margin={{ top: 'large' }}>
          <Box
            direction="row"
            border={{ side: 'bottom', color: 'border-weak' }}
            pad={{ bottom: 'medium', top: 'medium' }}
            align="center"
          >
            <Typography
              size="xlarge"
              type="text"
              emphasis
              testId="failed-apps-title"
            >
              {t('apps:failed_to_convert')}
            </Typography>
          </Box>

          {mspConversionFailedInstances?.map((instance) => (
            <InstalledAppList
              appInfo={instance}
              refreshAppList={setPageData}
              appType="failed"
              isServiceProvision={isServiceProvision}
              key={instance.application_instance_id}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}

RegionsTab.propTypes = {
  serviceDetailData: PropTypes.object.isRequired,
  provisions: PropTypes.array.isRequired,
  isServiceProvision: PropTypes.bool,
  regionsCanBeProvisioned: PropTypes.array.isRequired,
  setPageData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

RegionsTab.defaultProps = {
  isServiceProvision: false
}

export default RegionsTab
