import React from 'react'
import PropTypes from 'prop-types'
import { Box, CheckBox, Text } from 'grommet'
import { useTranslation } from 'react-i18next'

const ListItem = ({ label, checked, setChecked, testId, ...rest }) => {
  const { t } = useTranslation(['common'])
  return (
    <Box direction="row" align="center" {...rest}>
      <CheckBox
        a11yTitle={t(label)}
        checked={checked}
        onChange={() => setChecked(!checked)}
        margin={{ vertical: 'xsmall', right: 'small' }}
      />
      <Text data-testid={testId} weight={500}>
        {t(label)}
      </Text>
    </Box>
  )
}

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired
}
export default ListItem
