import React from 'react'
import PropTypes from 'prop-types'
import { Box, List } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  Button,
  ModalDialog,
  ModalFooter
} from '../../../../components'
// import { getMfaAccounts } from '../utils/api-utils'

export const ViewEnabledAccounts = ({ handleCloseViewEnabled, accounts }) => {
  const { t } = useTranslation(['authn', 'common'])

  return (
    <>
      <ModalDialog
        content={
          <Box>
            <Box gap="xsmall">
              <Typography
                type="heading"
                level="1"
                testId="enabled-mfa-accounts-title"
              >
                {t('security.mfa_enabled_accounts')}
              </Typography>
              <Typography
                type="paragraph"
                size="large"
                testId="enabled-mfa-accounts-subtitle"
              >
                {t('security.mfa_enabled_accounts_subtitle')}
              </Typography>
            </Box>

            {/* TODO: Replace data when the API is ready */}
            <Box
              margin={{ top: 'medium' }}
              direction="column"
              justify="between"
              height="small"
              overflow="auto"
            >
              <List
                pad={{ top: 'medium', bottom: 'medium', left: 'small' }}
                border={{ side: 'bottom', color: '#BBBBBB' }}
                data={accounts}
              />
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <Box direction="row" gap="medium">
                <Button
                  primary
                  label={t('common:close')}
                  onClick={handleCloseViewEnabled}
                  testId="close-mfa-enabled-accounts-btn"
                />
              </Box>
            }
          />
        }
        onClose={handleCloseViewEnabled}
        testId="view-mfa-enabled-accounts-dialog"
        width="medium"
      />
    </>
  )
}

ViewEnabledAccounts.propTypes = {
  handleCloseViewEnabled: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired
}
