/* eslint-disable react/prop-types */

import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box, Spinner, Text } from 'grommet'
import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { StatusGood, StatusCritical } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import {
  Button,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../components'
import { post, getErrorResponse } from '../../../utils/api-utils'
import Markdown from '../../commoncomponents/Markdown'

const TestApi = ({ handleHideDialog, data }) => {
  const { t } = useTranslation(['automations'])
  const { oidcUser } = useReactOidc()
  const [resData, setResData] = useState({})
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [errorResponse, setErrorResponse] = useState(null)

  const onTestApi = useCallback(async () => {
    setLoading(true)
    try {
      await post(
        `/events/v1beta1/webhooks/${id}/verify`,
        {},
        oidcUser?.access_token
      ).then((res) => {
        setResData(res.data)
        setLoading(false)
      })
    } catch (error) {
      setErrorResponse(getErrorResponse(error, t))
      setLoading(false)
    }
  }, [oidcUser?.access_token, id, t])

  useEffect(() => {
    onTestApi()
  }, [onTestApi])

  const codeError = errorResponse?.code
  const messageError = errorResponse?.message

  return (
    <>
      <ModalDialog
        header={
          <ModalHeader
            title={
              <Typography
                type="heading"
                level="2"
                testId="events-webhook-group-side-panel-title"
              >
                {t('automations:webhooks.test_webhook')}
              </Typography>
            }
            onClose={() => {
              handleHideDialog(false)
            }}
          />
        }
        content={
          <>
            <Box
              margin={{ top: 'small' }}
              width={{ min: 'medium', max: 'full' }}
            >
              <Markdown
                components={{
                  a: {
                    component: Text
                  }
                }}
              >
                {t('automations:webhooks.test_webhook_name', {
                  webhookName: data?.name
                })}
              </Markdown>
              <Markdown
                components={{
                  a: {
                    component: Text
                  }
                }}
              >
                {t('automations:webhooks.test_webhook_desc', {
                  webhookUrl: data?.webhookUrl
                })}
              </Markdown>

              <Box margin={{ top: 'medium' }}>
                <Box direction="row" align="center" gap="small">
                  {loading && <Spinner />}
                  {loading && (
                    <Text>{t('automations:webhooks.testing_label')}</Text>
                  )}
                </Box>
                {!loading && (
                  <Box>
                    <Box gap="small" direction="row" align="center">
                      {resData.destinationResponse &&
                      resData.destinationResponse?.statusCode === 200 ? (
                        <StatusGood color="status-ok" />
                      ) : (
                        <StatusCritical color="status-critical" />
                      )}
                      <Typography type="text">
                        {!errorResponse
                          ? t('automations:webhooks.test_status_code')
                          : t('automations:webhooks.unable_to_test')}{' '}
                        {resData.destinationResponse
                          ? resData.destinationResponse?.statusCode
                          : codeError}
                      </Typography>
                    </Box>
                    <Typography type="text" margin={{ top: 'small' }}>
                      {resData.destinationResponse
                        ? resData.destinationResponse.message || ''
                        : messageError}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              direction="row"
              justify="start"
              gap="medium"
              margin={{ top: 'small' }}
              pad={{ top: 'medium' }}
            >
              <Button
                primary
                label={t('automations:webhooks.test_close')}
                onClick={() => {
                  handleHideDialog(false)
                }}
                testId="edit-webhook-cancel-btn"
              />
              {!loading && (
                <Button
                  secondary
                  label={t('automations:webhooks.retest')}
                  onClick={onTestApi}
                  testId="edit_webhook_btn_label"
                />
              )}
            </Box>
          </>
        }
        footer={<></>}
        onClose={() => handleHideDialog(false)}
        position="right"
        height="100%"
        overflow="hidden"
        testId="edit-webhook-side-panel"
        width="440px"
      />
    </>
  )
}
export default TestApi
