// (C) Copyright 2017-2022 Hewlett Packard Enterprise Development LP

import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Anchor, Box, Grid, Tip } from 'grommet'
import keys from 'lodash/keys'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { Image as MediaImage, FormSchedule } from 'grommet-icons'

import { Typography } from '../../../../../components'

const DetailsBox = ({ data, detailsSubHeading }) => {
  const { t } = useTranslation(['manage'])

  const RenderReference = () => {
    return (
      <Box direction="column" data-testid="details-value-referenceLinks">
        {data.referenceLinks.map((element, index) => (
          <Anchor
            label={element?.label}
            href={element?.link}
            target="_blank"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}
      </Box>
    )
  }

  return (
    <Box width="100%" margin={{ bottom: 'small' }}>
      <Grid
        columns={['1/3', '2/3']}
        gap={{
          row: 'small',
          column: 'small'
        }}
      >
        {keys(detailsSubHeading).map((item) => (
          <Fragment key={item}>
            <Typography
              type="text"
              weight="bold"
              size="medium"
              testId={`details-${item}`}
            >
              {detailsSubHeading[item]}
            </Typography>
            {isUndefined(data[item]) || isEmpty(data[item]) ? (
              <Typography
                type="text"
                color="dark-5"
                testId={`details-value-${item}`}
              >
                --
              </Typography>
            ) : (
              <Box direction="row" gap="small">
                {item === 'publishedDate' && !data.active ? (
                  <Tip
                    dropProps={{ align: { bottom: 'top' } }}
                    content={
                      <Typography type="text">
                        {t('whats_new.create_post_form.scheduled_desc', {
                          publishedDate: data[item]
                        })}
                      </Typography>
                    }
                  >
                    <Box direction="row">
                      <Typography
                        type="text"
                        size="medium"
                        icon={<FormSchedule />}
                        reverse
                      >
                        {data[item]}
                      </Typography>
                    </Box>
                  </Tip>
                ) : (
                  <>
                    {item === 'fileName' && (
                      <Box margin={{ right: 'xsmall' }}>
                        <MediaImage />
                      </Box>
                    )}
                    {item === 'referenceLinks' ? (
                      <RenderReference />
                    ) : (
                      <Typography
                        type="text"
                        size="medium"
                        testId={`details-value-${item}`}
                      >
                        {data[item]}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            )}
          </Fragment>
        ))}
      </Grid>
    </Box>
  )
}

DetailsBox.propTypes = {
  data: PropTypes.object.isRequired,
  detailsSubHeading: PropTypes.object.isRequired
}

export default DetailsBox
