// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React, Suspense, lazy } from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'
import { isTenant } from '../../../utils/feature-flag-utils'

import { AccountTypeContextProvider } from './context/AccountTypeContext'

const AccountTypeOverview = lazy(() => import('./AccountTypeOverview'))
const CheckEligibilityWizard = lazy(() =>
  import('./check-eligibility/CheckEligibility')
)
const ReviewDevicesWizard = lazy(() => import('./review-devices/ReviewDevices'))
const ReviewApplicationsWizard = lazy(() =>
  import('./review-applications/ReviewApplications')
)

const AccountTypesRouter = () => {
  const { path } = useRouteMatch()
  const { t } = useTranslation(['common'])
  return (
    <AccountTypeContextProvider>
      <Suspense fallback={<LazyLoading />}>
        {!isTenant() ? (
          <Switch>
            <Route exact path={path}>
              <AccountTypeOverview />
            </Route>
            <Route path="/manage-account/account-type-overview/check-eligibility">
              <CheckEligibilityWizard />
            </Route>
            <Route path="/manage-account/account-type-overview/review-devices">
              <ReviewDevicesWizard />
            </Route>
            <Route path="/manage-account/account-type-overview/review-applications">
              <ReviewApplicationsWizard />
            </Route>
            <Redirect to="/not-found" />
          </Switch>
        ) : (
          <Redirect
            to={{
              pathname: '/home',
              errorMessage: t('permission_denied')
            }}
          />
        )}
      </Suspense>
    </AccountTypeContextProvider>
  )
}

export default AccountTypesRouter
