import { Box, Page, PageContent } from 'grommet'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { Anchor, Loader, Typography } from '../../../components'
import { get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import {
  ViewCustomerDetails,
  EditCustomerDetails,
  DeployedApplicationDetails
} from '../customer-details'
import { CUSTOMER_EDIT_MODE, CUSTOMER_READ_MODE } from '../utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'

const CustomerDetails = () => {
  const { customerId } = useParams()
  const { t } = useTranslation(['authn', 'authz', 'common'])
  const { oidcUser } = useReactOidc()
  const history = useHistory()
  const custAccountLoaded = getCustomerAccount()
  const { platform_customer_id: pcid } = custAccountLoaded
  const LDFlags = useFlags()
  const isTenantWorkspaceGroup = LDFlags['tenant-workspace-group']
  const [customerAccountDetails, setCustomerAccountDetails] = useState(null)
  const [listOfCountries, setListOfCountries] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentMode, setCurrentMode] = useState(CUSTOMER_READ_MODE)
  const [workspaceGroupList, setWorkspaceGroupList] = useState(null)
  const [pullRefresh, setPullRefresh] = useState(false)
  const [successNotification, setSuccessNotification] = useState(null)
  const [errorNotification, setErrorNotification] = useState(null)

  // To fetch available country list
  useEffect(() => {
    let params = { status: 'AVAILABLE' }
    get('/ui-doorway/ui/v1/status', {}, oidcUser.access_token)
      .then(
        (response) => {
          if (response?.data?.flags?.gts_compliance_flag) {
            params = {}
          }
        },
        (error) => {
          setErrorNotification(error)
        }
      )
      .finally(() => {
        get('/geo/ui/v1/countries', params).then(
          (response) => {
            setListOfCountries(response?.data?.countries || [])
          },
          (error) => {
            setErrorNotification(error)
          }
        )
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token])

  useEffect(() => {
    if (isTenantWorkspaceGroup) {
      get(
        `/groups-management/v1alpha1/members/${customerId}/groups`,
        {},
        oidcUser?.access_token,
        false,
        {},
        { 'X-workspaceID': pcid }
      ).then(
        (response) => {
          setWorkspaceGroupList(response?.data?.items)
        },
        (error) => {
          setErrorNotification(error)
        }
      )
    }
  }, [t, oidcUser, customerId, isTenantWorkspaceGroup, pcid])

  useEffect(() => {
    get(
      `/accounts/ui/v1/managed-service/tenant/${customerId}`,
      {},
      oidcUser.access_token
    )
      .then(
        (response) => {
          const {
            address: {
              street_address: streetAddress,
              street_address_2: streetAddress2,
              city,
              state_or_region: stateOrRegion,
              zip,
              country_code: countryCode
            } = {},
            company_name: companyName,
            description,
            email: emailId,
            phone_number: phoneNumber,
            created_at: createdAt,
            updated_at: updatedAt,
            customer_logo: { logo, logo_filename: logoFilename } = {},
            operational_mode: operationalMode
          } = response.data || ''
          const data = {
            customer_logo: { logo, logo_filename: logoFilename },
            company_name: companyName,
            description,
            address: {
              country_code: countryCode,
              street_address: streetAddress,
              street_address_2: streetAddress2,
              city,
              state_or_region: stateOrRegion,
              zip
            },
            customer_contact: {
              email: emailId || '',
              phone: phoneNumber || ''
            },
            creation_date: createdAt,
            last_updated: updatedAt,
            operational_mode: operationalMode
          }
          setCustomerAccountDetails(data)
        },
        (error) => {
          setErrorNotification(error)
        }
      )
      .then(() => setIsLoading(false))
  }, [oidcUser.access_token, customerId, t, pullRefresh])

  return (
    <Page kind="narrow">
      <PageContent data-testid="customer-details-page">
        {isLoading ? (
          <Box
            fill
            align="center"
            justify="center"
            direction="row"
            pad="large"
            gap="small"
          >
            <Loader testId="customer-detail-page-loader" />
          </Box>
        ) : (
          <>
            {customerAccountDetails && (
              <>
                {currentMode === CUSTOMER_EDIT_MODE ? (
                  <EditCustomerDetails
                    customerAccountDetails={customerAccountDetails}
                    countryList={listOfCountries}
                    setMode={setCurrentMode}
                    onSuccess={() => {
                      setPullRefresh(pullRefresh + 1)
                      setSuccessNotification(
                        t('customer_account.account_updated_success')
                      )
                      setCurrentMode(CUSTOMER_READ_MODE)
                    }}
                  />
                ) : (
                  <ViewCustomerDetails
                    customerAccountDetails={customerAccountDetails}
                    countryList={listOfCountries}
                    setMode={setCurrentMode}
                  />
                )}
                <DeployedApplicationDetails />
                {isTenantWorkspaceGroup && (
                  <Box>
                    <Box pad={{ vertical: 'medium' }}>
                      <Typography level="2" type="heading">
                        {t(
                          'authz:workspace_group.tenant_detail_workspace_header'
                        )}
                      </Typography>
                    </Box>
                    {workspaceGroupList?.map((workspaceGroup) => (
                      <Anchor
                        label={workspaceGroup?.displayName}
                        href="#"
                        onClick={() => {
                          history.push(
                            `/manage-account/identity/workspace-groups/${workspaceGroup?.id}`
                          )
                        }}
                        testId="workspace-anchor"
                      />
                    ))}
                  </Box>
                )}
              </>
            )}
            {errorNotification &&
              displayApiError(errorNotification, t, setErrorNotification)}
            {successNotification &&
              displayNotification(
                successNotification,
                'info',
                setSuccessNotification
              )}
          </>
        )}
      </PageContent>
    </Page>
  )
}

export default CustomerDetails
