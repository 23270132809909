// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { generateReport } from '../pages/manage-account/subscriptions/utils'

export const handleExport =
  (
    ptype,
    filterOptions,
    setLoading,
    sortProps,
    searchTerm,
    reportingDashboardFlag,
    oidcUser,
    setNotification,
    setShowExportModal,
    t
  ) =>
  (selectedDetails, setApiError, setShowValidationWarning) => {
    if (!selectedDetails?.all_entries && filterOptions?.tags?.length) {
      setShowValidationWarning(t('licensing:tags_export_validation_warning'))
      setLoading(false)
    } else
      generateReport({
        ...selectedDetails,
        setApiError,
        productType: ptype,
        setLoading,
        filterOptions,
        sortProps,
        searchTerm,
        reportingDashboardFlag,
        oidcUser,
        setNotification,
        setShowExportModal,
        t
      })
  }
