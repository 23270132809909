import React, { Suspense, lazy } from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'

import { AUTHZContextProvider } from '../../../context/authz-context'
import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'
import APIRouter from '../api/router'
import { isOrg, isV2 } from '../../../utils/feature-flag-utils'

import { AuthzV2RolesRouter } from './authzV2-roles/router'
import useFetchUserRoles from './custom-hooks/ui-doorway/useFetchUserRoles'
import { WorkspaceUserRouter } from './workspace-users/router'

const RolesRouter = lazy(() => import('./roles/router'))
const UsersRouter = lazy(() => import('./users/router'))
const GroupsRouter = lazy(() => import('../../igc-service/groups/router'))
const ScopeGroupsRouter = lazy(() => import('./scope-groups/router'))
const ScopeGroupsV2Router = lazy(() => import('./scopegroups/router'))

const WorkspaceGroupsRouter = lazy(() => import('./workspace-groups/router'))
const IdentityPage = lazy(() => import('./Identity'))

const IdentityRouterContent = () => {
  const { path } = useRouteMatch()
  useFetchUserRoles()

  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route exact path={path}>
          <IdentityPage />
        </Route>
        {isV2() && (
          <Route path="/manage-account/identity/roles">
            <AuthzV2RolesRouter />
          </Route>
        )}
        <Route path="/manage-account/identity/roles">
          <RolesRouter />
        </Route>
        {isOrg() && (
          <Route path="/manage-account/identity/users">
            <WorkspaceUserRouter />
          </Route>
        )}
        <Route path="/manage-account/identity/users">
          <UsersRouter />
        </Route>
        {isOrg() && (
          <Route path="/manage-account/identity/groups">
            <GroupsRouter isWorkspace />
          </Route>
        )}
        {isOrg() && (
          <Route path="/manage-account/identity/scopegroups">
            <ScopeGroupsV2Router />
          </Route>
        )}
        <Route path="/manage-account/identity/scope-groups">
          <ScopeGroupsRouter />
        </Route>
        <Route path="/manage-account/identity/workspace-groups">
          <WorkspaceGroupsRouter />
        </Route>
        <Route path="/manage-account/identity/api-client">
          <APIRouter />
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  )
}

const IdentityRouter = () => {
  return (
    <AUTHZContextProvider>
      <IdentityRouterContent />
    </AUTHZContextProvider>
  )
}

export default IdentityRouter
