import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { Box } from 'grommet'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ButtonGroup,
  Notification
} from '../../../../../../components'

const DeleteConfirmationModal = ({
  onSetOpen,
  handleDelete,
  isReviewNotification,
  isSchedule
}) => {
  const { t } = useTranslation(['manage', 'common'])

  const handleCancel = () => {
    onSetOpen(false)
  }

  const handleSave = () => {
    onSetOpen(false)
    handleDelete()
  }

  return (
    <ModalDialog
      position="center"
      onClickOutside={() => {
        onSetOpen(false)
      }}
      testId="confirmation-dialog"
      onEsc={() => {
        onSetOpen(false)
      }}
      content={
        isReviewNotification || isSchedule ? (
          <Box gap="medium" margin={{ top: 'medium' }}>
            <Notification
              type="inline"
              text={t(
                'notification.approval.review_notification_delete_warning'
              )}
              backgroundColor="status-critical"
              testId="err-inline-notification"
            />
            <Typography type="text" size="large">
              {!isSchedule && isReviewNotification
                ? t(
                    'notification.approval.review_notification_delete_confirmation_msg'
                  )
                : t(
                    'notification.approval.schedule_notification_delete_confirmation_msg'
                  )}
            </Typography>
          </Box>
        ) : (
          <Box gap="medium" margin={{ top: 'medium' }}>
            <Typography type="text" size="large">
              <Trans
                i18nKey="notification.delete_confirmation_message"
                t={t}
                boldText={1}
              >
                <strong>{1}</strong>
              </Trans>
            </Typography>
          </Box>
        )
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-delete-confirm-btn',
                  onClick: handleCancel
                },
                {
                  id: 1,
                  label: t('notification.confirm_delete_header'),
                  primary: true,
                  testId: 'confirm-delete-button',
                  onClick: handleSave
                }
              ]}
              testId="delete-confirmation-buttons"
            />
          }
        />
      }
      header={
        <ModalHeader
          title={
            <Typography size="xxlarge" type="text" weight="bold">
              {t('notification.confirm_delete_header')}
            </Typography>
          }
        />
      }
      onClose={handleCancel}
      width="large"
    />
  )
}
export { DeleteConfirmationModal }

DeleteConfirmationModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isReviewNotification: PropTypes.bool.isRequired,
  isSchedule: PropTypes.bool.isRequired
}
