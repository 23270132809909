// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import PropTypes from 'prop-types'
import { Box, FormField } from 'grommet'
import { StatusWarning } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { get } from '../../../utils/api-utils'
import { Notification, Button } from '../../../components'
import { WizardContext } from '../../../components/wizard/WizardContext'
import { PillItemAdder } from '../../device-management/components/item-adder/PillItemAdder'
import { CreateSelect } from '../../device-management/components/CreateSelect'
import { getApiErrorMessage } from '../../../utils/error-handling-utils'
import { PillItemAdderSubscription } from '../../manage-account/subscriptions/common-components/item-adder/PillItemAdderSubscription'
import { AddFormSubscription } from '../../manage-account/subscriptions/common-components/item-adder/AddFormSubscription'
import { isGLOP } from '../../../utils/feature-flag-utils'
import { TAG_NAME_MAX_LENGTH } from '../../device-management/utils'

const PillAddForm = ({
  fields,
  addBtnLabel,
  onAdd,
  isNewAssignment,
  setIsNewAssignment
}) => {
  const [, setErrorStateChanged] = useState(false)
  const { t } = useTranslation(['device', 'common'])

  const formFieldEls =
    fields &&
    fields.map(({ label, name, valueComp, error, testId }, idx) => {
      let formattedError
      if (isNewAssignment && label === 'Name') {
        formattedError = t('tags.tag_create_or_select')
      } else {
        formattedError = error?.length === 0 ? undefined : error
      }
      return (
        <FormField
          key={idx} /* eslint-disable-line react/no-array-index-key */
          data-testid={`${testId}-form-field`}
          label={label}
          name={name}
          htmlFor={name}
          width="medium"
          // TODO: localize
          error={formattedError}
        >
          {valueComp}
        </FormField>
      )
    })

  const reg = new RegExp('^[A-Za-z0-9 _\\.:=+\\-@]*$')
  const validateTag = (label, val) => {
    // do error checking
    const lowerCaseLabel = label.toLocaleLowerCase()
    let errorMsg = ''
    if (lowerCaseLabel === 'name') {
      if (!val || val?.length < 1 || val?.length > 128) {
        errorMsg = t('tags.validate_tag_name_length', {
          lower_case_label: lowerCaseLabel
        })
      } else if (!reg.test(val)) {
        errorMsg = t('tags.validate_allowed_msg')
      }
    } else if (lowerCaseLabel === 'value') {
      if (val?.length > 256) {
        errorMsg = t('tags.validate_tag_value_length', {
          lower_case_label: lowerCaseLabel
        })
      } else if (!reg.test(val)) {
        errorMsg = t('tags.validate_allowed_msg')
      }
    }
    return errorMsg
  }

  return (
    <Box
      direction="row"
      gap="medium"
      margin={{ top: 'medium', bottom: 'small' }}
      justify="center"
    >
      {formFieldEls}
      <Box align="center" margin={{ right: 'small' }}>
        <Button
          testId="assign-btn"
          label={addBtnLabel}
          secondary
          onClick={() => {
            setIsNewAssignment(false)
            fields.forEach((field) => {
              field.error = validateTag(field.label, field.value?.value)
            })
            setErrorStateChanged((prev) => !prev)

            if (fields.every((field) => !field.error)) {
              // add if no error
              onAdd()
            }
          }}
          margin={{
            top: 'medium',
            bottom: 'xxsmall'
          }}
        />
      </Box>
    </Box>
  )
}

PillAddForm.propTypes = {
  /**
   * Array of objects representing label and value component
   */
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.object,
      valueComp: PropTypes.element.isRequired
    }).isRequired
  ).isRequired,

  /**
   * button label for add/assign button
   */
  addBtnLabel: PropTypes.string.isRequired,

  onAdd: PropTypes.func.isRequired,

  isNewAssignment: PropTypes.bool.isRequired,

  setIsNewAssignment: PropTypes.func.isRequired
}

const TagsEdit = ({
  isSubscriptionTagsModal,
  onChange,
  initialTags,
  isNewAssignment,
  setIsNewAssignment,
  selectedLicenseData
}) => {
  const { t } = useTranslation(['device', 'common'])
  const [formData, setFormData] = useState({
    tagName: undefined,
    tagValue: ''
  })
  const [allNameOptions, setAllNameOptions] = useState([])
  const [addedTagsList, setAddedTagsList] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [errorTagName, seterrorTagName] = useState('')
  const [tagsMap, setTagsMap] = useState(new Map())
  const [allValueOptions, setAllValueOptions] = useState([])
  const [selectedNameOption, setSelectedNameOption] = useState({})
  const [selectedValueOption, setSelectedValueOption] = useState({})
  const [tagsData, setTagsData] = useState([])
  // The name option, if any, that was created in the most recent option click using `Create <name>` option.
  const [lastCreatedNameOption, setLastCreatedNameOption] = useState(null)
  const { oidcUser } = useReactOidc()
  const [searchText, setSearchText] = useState('')
  const { formValues, setFormValues } = useContext(WizardContext)
  const subscriptionTagsFlag = useFlags()['glcp-subscrption-tags'] || isGLOP()

  useEffect(() => {
    if (lastCreatedNameOption && subscriptionTagsFlag) {
      setSearchText('')
    }
  }, [lastCreatedNameOption, subscriptionTagsFlag])

  useEffect(() => {
    setAddedTagsList(initialTags)
  }, [initialTags])

  useEffect(() => {
    if (!searchText && subscriptionTagsFlag) {
      get('/tags/v1beta1/tags', {}, oidcUser.access_token).then(
        (response) => {
          if (response.data && response.data.items) {
            const responseData = [...response.data.items]
            const newTagsData = []
            responseData.forEach((newTag) => {
              newTagsData.push({ ...newTag, name: newTag.key })
            })

            if (lastCreatedNameOption) {
              newTagsData.unshift({ name: lastCreatedNameOption.value })
            }
            setTagsData(newTagsData)
          }
        },
        (error) => {
          setErrorMessage(getApiErrorMessage(error, t))
        }
      )
    }
  }, [
    initialTags,
    oidcUser,
    t,
    setTagsData,
    setSearchText,
    searchText,
    lastCreatedNameOption,
    subscriptionTagsFlag
  ])

  useEffect(() => {
    const newTagsMap = new Map()
    if (tagsData) {
      tagsData.forEach((val) => {
        const tagVals = newTagsMap.get(val.name)
        if (tagVals) {
          const found = tagVals.find((tagVal) => {
            return tagVal.value === val.value
          })
          if (!found) tagVals.push({ value: val.value, persisted: true })
        } else {
          newTagsMap.set(val.name, [{ value: val.value, persisted: true }])
        }
      })

      if (addedTagsList) {
        addedTagsList.forEach((val) => {
          const tagVals = newTagsMap.get(val.name)
          if (tagVals) {
            const found = tagVals.find((tagVal) => {
              return tagVal.value === val.value
            })
            if (!found) tagVals.push({ value: val.value, persisted: false })
          } else {
            newTagsMap.set(val.name, [{ value: val.value, persisted: false }])
          }
        })
      }
    }
    if (subscriptionTagsFlag) {
      if (!searchText) {
        const newInitMap = new Map()
        const optionValueArr = []
        let count = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of newTagsMap) {
          newInitMap.set(key, value)
          optionValueArr.push({ value: key })
          count += 1
          // Only copy first 5 keys and values.
          if (count === 5) {
            break
          }
        }
        setTagsMap(newInitMap)
      } else {
        setTagsMap(newTagsMap)
      }
    } else {
      setTagsMap(newTagsMap)
    }
  }, [addedTagsList, tagsData, searchText, subscriptionTagsFlag])

  useEffect(() => {
    const tagNames = Array.from(tagsMap.keys())
    let tagNamesArr = []
    const setTagsNameArr =
      subscriptionTagsFlag === true ? subscriptionTagsFlag : searchText
    if (setTagsNameArr) {
      tagNamesArr = tagNames ? [] : [{}]
      if (tagNames) {
        tagNames.forEach((val) => {
          if (
            val
              .toLowerCase()
              .trim()
              .indexOf(searchText.toLocaleLowerCase().trim()) !== -1
          )
            tagNamesArr.push({ value: val })
        })
      }
    }

    setAllNameOptions(tagNamesArr)
    if (subscriptionTagsFlag) {
      setAllValueOptions([])
    }
  }, [searchText, tagsMap, subscriptionTagsFlag])

  useEffect(() => {
    if (
      formValues &&
      formValues.tagsAssigned &&
      formValues.tagsAssigned.length > addedTagsList.length
    ) {
      setAddedTagsList([...addedTagsList, ...formValues.tagsAssigned])
    } else if (
      formValues &&
      formValues.tagsAssigned &&
      formValues.tagsAssigned.length < addedTagsList.length
    ) {
      setFormValues({ ...formValues, tagsAssigned: addedTagsList })
    }
  }, [addedTagsList]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleNameValueChange = (option) => {
    setLastCreatedNameOption(null)
    setSelectedNameOption(option)
    setSelectedValueOption({})
    setFormData((prevFormData) => {
      return { ...prevFormData, tagValue: '' }
    })

    const tagName = option.value.trim()
    if (!tagName || tagName.length === 0) {
      setAllValueOptions([])
      return
    }
    if (subscriptionTagsFlag) {
      setAllValueOptions(
        tagsMap.get(tagName)
          ? tagsMap.get(tagName).filter(({ value }) => value !== '')
          : []
      )
    } else {
      setAllValueOptions(tagsMap.get(tagName) || [])
    }
    setFormData((prevFormData) => {
      return { ...prevFormData, tagName }
    })
  }

  const handleSearchByName = (searchTxt) => {
    if (isSubscriptionTagsModal && searchTxt?.length > TAG_NAME_MAX_LENGTH) {
      setErrorMessage(
        t('tags.validate_tag_name_length', { lower_case_label: 'name' })
      )
      return
    }
    if (!subscriptionTagsFlag && !searchTxt) {
      setSearchText('')
      setTagsData([])
      return
    }
    setSearchText(searchTxt)
    const searchpartialText = subscriptionTagsFlag ? searchTxt || '' : searchTxt
    if (subscriptionTagsFlag) {
      get(
        '/tags/v1beta1/tags',
        {
          filter: `contains(key,'${searchpartialText}')`
        },
        oidcUser.access_token
      ).then(
        (response) => {
          if (response.data && response.data.items) {
            const responseData = [...response.data.items]
            const newTagsData = []
            responseData.forEach((newTag) => {
              newTagsData.push({ ...newTag, name: newTag.key })
            })
            setTagsData(newTagsData)
          }
        },
        (error) => {
          setErrorMessage(getApiErrorMessage(error, t))
        }
      )
    } else {
      get(
        '/ui-doorway/ui/v1/devices/tags',
        { partial_name: searchTxt, partial_value: null },
        oidcUser.access_token
      ).then(
        (response) => {
          if (response.data && response.data.tags) {
            setTagsData([...response.data.tags])
          }
        },
        (error) => {
          setErrorMessage(getApiErrorMessage(error, t))
        }
      )
    }
  }

  const fields = [
    {
      label: t('tags.create_tags_dlg_name_field'),
      name: 'tagname',
      value: selectedNameOption,
      error: errorTagName,
      valueComp: (
        <CreateSelect
          allOptions={allNameOptions}
          onValueChange={handleNameValueChange}
          onSearch={handleSearchByName}
          tagNameMaxLength={TAG_NAME_MAX_LENGTH}
          isSubscriptionTagsModal={isSubscriptionTagsModal}
          onCreate={(option) => {
            setIsNewAssignment(false)
            setLastCreatedNameOption(option)
            setSelectedNameOption(option)

            setSelectedValueOption({})
            setFormData((prevFormData) => {
              return { ...prevFormData, tagName: option.value.trim() }
            })
            setTimeout(() => {
              if (subscriptionTagsFlag) {
                setLastCreatedNameOption(option)
              } else {
                setAllNameOptions((prevOptions) => [...prevOptions, option])
              }
            }, 200)
            setAllValueOptions([])
            setFormData((prevFormData) => {
              return { ...prevFormData, tagValue: '' }
            })
          }}
          name="tagname"
          placeholder={t('tags.create_tags_dlg_name_placeholder')}
          plain
          value={selectedNameOption}
          requiredMesssage={t('tags.create_tags_dlg_name_required')}
          data-testid="tag-name-input"
        />
      ),
      required: !selectedNameOption?.value,
      testId: 'name-select'
    },
    {
      label: t('tags.create_tags_dlg_value_field'),
      name: 'value',
      value: selectedValueOption,
      valueComp: (
        <CreateSelect
          labelKey="value"
          allOptions={allValueOptions}
          onValueChange={(option) => {
            setSelectedValueOption(option)
            setFormData((prevFormData) => {
              return { ...prevFormData, tagValue: option.value.trim() }
            })
          }}
          onCreate={(option) => {
            setSelectedValueOption(option)
            setFormData((prevFormData) => {
              return { ...prevFormData, tagValue: option.value.trim() }
            })
            setAllValueOptions([...allValueOptions, option])
          }}
          name="value"
          placeholder={t('tags.create_tags_dlg_value_placeholder')}
          plain
          value={selectedValueOption}
          requiredMesssage={t('tags.create_tags_dlg_value_required')}
          data-testid="tag-value-input"
        />
      ),
      required: false,
      testId: 'value-select'
    }
  ]

  const handlePillDeleteBtn = (tag) => {
    setAddedTagsList((prevList) => {
      const newList = [...prevList]
      const index = newList.findIndex(({ name, value }) => {
        return tag.name === name && tag.value === value
      })
      newList.splice(index, 1)
      onChange(newList)

      const tagToDeleteValues = tagsMap.get(tag.name)
      if (tagToDeleteValues) {
        const foundIndex = tagToDeleteValues.findIndex((val) => {
          return val.value === tag.value
        })

        if (foundIndex !== -1 && !tagToDeleteValues[foundIndex].persisted) {
          setTagsMap((prevMap) => {
            const newMap = new Map(prevMap)
            newMap.get(tag.name).splice(foundIndex, 1)
            if (newMap.get(tag.name).length === 0) {
              newMap.delete(tag.name)
            }
            return newMap
          })
        }
      }
      return newList
    })
  }

  const handleAdd = () => {
    setErrorMessage('')
    if (formData.tagName?.toLowerCase() === 'hpe') {
      setErrorMessage(t('tags.tag_contains_hpe_error_msg'))
      return
    }
    if (
      addedTagsList.some(
        ({ name }) => name.toLowerCase() === formData.tagName.toLowerCase()
      )
    ) {
      setErrorMessage(t('tags.tags_duplicated_error_msg'))
      return
    }

    if (formData.tagName === undefined) {
      seterrorTagName(t('tags.validate_tag_name_length'))
    }
    if (formData.tagName !== undefined) {
      seterrorTagName('')
      setAddedTagsList((prevList) => {
        const list = [
          ...prevList,
          { name: formData.tagName, value: formData.tagValue }
        ]
        onChange(list)
        return list
      })

      setTagsMap((prevTagsMap) => {
        const newTagsMap = new Map(prevTagsMap)
        const tagValues = newTagsMap.get(formData.tagName)
        if (tagValues) {
          const found = tagValues.find((val) => {
            return val.value === formData.tagValue
          })
          if (!found) {
            tagValues.push({ value: formData.tagValue, persisted: false })
          }
        } else {
          newTagsMap.set(formData.tagName, [
            { value: formData.tagValue, persisted: false }
          ])
        }
        return newTagsMap
      })

      setFormData({ tagName: undefined, tagValue: '' })
      setSelectedNameOption({})
      setSelectedValueOption({})
    }
  }

  return (
    <>
      {isSubscriptionTagsModal ? (
        <PillItemAdderSubscription
          addForm={
            <AddFormSubscription
              fields={fields}
              addBtnLabel={t('assign')}
              onAdd={handleAdd}
              isNewAssignment={isNewAssignment}
              setIsNewAssignment={setIsNewAssignment}
              heading={t('device:tags.new_tag')}
              error={errorTagName}
            />
          }
          selectedLicenseData={selectedLicenseData}
          tagsAssigned={t('device:tags.no_tags_assigned')}
          itemList={addedTagsList}
          heading={t('device:tags.manage_subscription_tags_dlg_plural_title')}
          onDelete={handlePillDeleteBtn}
          testId="add-subscription-tags-pill"
        />
      ) : (
        <PillItemAdder
          addForm={
            <PillAddForm
              fields={fields}
              addBtnLabel={t('assign')}
              onAdd={handleAdd}
              isNewAssignment={isNewAssignment}
              setIsNewAssignment={setIsNewAssignment}
            />
          }
          tagsAssigned={t('device:tags.no_tags_assigned')}
          itemList={addedTagsList}
          heading={t('device:tags.tags_to_be_assigned')}
          onDelete={handlePillDeleteBtn}
          testId="add-devices-pill"
        />
      )}
      {errorMessage && (
        <Notification
          backgroundColor="status-warning"
          icon={<StatusWarning color="text-strong" />}
          onClose={() => setErrorMessage(null)}
          text={errorMessage}
          testId="warning-notification"
          position="top"
        />
      )}
    </>
  )
}

TagsEdit.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialTags: PropTypes.array,
  isNewAssignment: PropTypes.bool.isRequired,
  setIsNewAssignment: PropTypes.func.isRequired,
  selectedLicenseData: PropTypes.array,
  isSubscriptionTagsModal: PropTypes.bool
}

TagsEdit.defaultProps = {
  initialTags: [],
  selectedLicenseData: [],
  isSubscriptionTagsModal: false
}

export { TagsEdit }
