import React from 'react'
import { Grommet, Box } from 'grommet'
import { deepMerge } from 'grommet/utils'
import { hpe } from 'grommet-theme-hpe'
import { StatusGoodSmall, Next, Previous } from 'grommet-icons'
import PropTypes from 'prop-types'

export const ThemeMode = ({ children }) => {
  // Temporary theme modifications for Discover 2023. Changes should be made in
  // grommet-theme-hpe once design is finalized.
  const modifiedTheme = deepMerge(hpe, {
    global: {
      colors: {
        text: {
          dark: '#FFFFFF',
          light: '#555555'
        },
        'text-strong': {
          dark: '#FFFFFF',
          light: '#333333'
        }
      }
    },
    button: {
      default: {
        font: { weight: 600 }
      },
      secondary: {
        font: {
          weight: 500
        }
      },
      toolbar: {
        border: {
          radius: '6px',
          width: '1px',
          color: 'border'
        },
        font: {
          weight: 500
        }
      }
    },
    heading: {
      level: {
        1: {
          font: {
            weight: 500
          }
        }
      }
    },
    paragraph: {
      large: {
        size: '22px',
        height: '28px',
        maxWidth: '25em'
      },
      xlarge: {
        size: '24px',
        height: '30px',
        maxWidth: '25em'
      },
      // This block applies size-specific weights to paragraph to ensure
      // that as paragraph sizes get larger, the weight decreases.
      // This block can be removed once grommet theme structure is enhanced
      // to support size-specific weights.
      extend: ({ size }) => `
        ${['xxlarge'].includes(size) ? 'font-weight: 400;' : ''};
      `
    },
    tabs: {
      header: {
        alignSelf: undefined
      }
    },
    text: {
      large: {
        size: '22px',
        height: '28px'
      },
      xlarge: {
        size: '24px',
        height: '30px'
      }
    },
    pageHeader: {
      // Needs to be removed after Grommet upgrade
      responsive: {
        breakpoints: ['xsmall', 'small']
      }
    },
    carousel: {
      icons: {
        current: StatusGoodSmall,
        previous: () => (
          <Box
            border={{ color: 'border-strong', size: 'xsmall' }}
            pad="small"
            margin={{ horizontal: 'xsmall' }}
            background="background"
            round="large"
          >
            <Previous color="text-strong" size="medium" />
          </Box>
        ),
        next: () => (
          <Box
            border={{ color: 'border-strong', size: 'xsmall' }}
            pad="small"
            margin={{ horizontal: 'xsmall' }}
            background="background"
            round="large"
          >
            <Next color="text-strong" size="medium" />
          </Box>
        ),
        color: 'background'
      }
    },
    icon: {
      size: {
        medium: '18px'
      }
    }
  })

  const body = (
    <Grommet
      theme={modifiedTheme}
      style={{ minHeight: '88vh' }}
      data-testid="theme"
    >
      {children}
    </Grommet>
  )
  return body
}

ThemeMode.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType])
    .isRequired
}
