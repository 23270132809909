import { Trans } from 'react-i18next'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { StatusCriticalSmall } from 'grommet-icons'

import { Typography, Anchor } from '../components'

export const displayNotificationContent = (
  reportingDashboardFlag,
  t,
  refId,
  reportName
) => (
  <Trans
    i18nKey={
      reportingDashboardFlag
        ? 'common:export_modal.generate_report_notification_new'
        : 'common:export_modal.generate_report_notification'
    }
    t={t}
    values={{
      id: refId,
      name: reportName
    }}
    components={{ div: <div />, bold: <strong />, span: <WordBreak /> }}
  />
)

export const handleErrorNotification = (
  error,
  t,
  setNotification,
  displayApiError
) => {
  const overrideMessage =
    error?.response?.status === 403
      ? t('common:error_messages.ERR_403_reportexport')
      : undefined
  setNotification(displayApiError(error, t, setNotification, overrideMessage))
}

export const LinkToReporting = ({ t }) => (
  <Box gap="xsmall">
    <Typography type="heading" level="2" testId="toreporting-heading">
      {t('reporting_dashboard:reporting_export_modal.modal_toreporting_title')}
    </Typography>
    <Typography type="paragraph" size="small" testId="toreporting-content">
      <Trans
        i18nKey="reporting_dashboard:reporting_export_modal.modal_toreporting"
        t={t}
      >
        <Anchor
          label={t('reporting_dashboard:reporting_export_modal.reporting_page')}
          size="small"
          href="/manage-account/reporting"
          testId="reporting-anchor"
        />
      </Trans>
    </Typography>
  </Box>
)

export const ModalFailureMessage = ({ t, errorCode }) => {
  const errorCodeMessage = () => {
    if (errorCode?.status === 403) {
      return t('common:error_messages.ERR_403_reportexport')
    }
    if (errorCode?.status === 429) {
      return errorCode?.data?.message
    }
    return t(
      'reporting_dashboard:reporting_export_modal.create_report_failure_desc'
    )
  }
  return (
    <Box
      data-testid="failed-err"
      background="validation-critical"
      pad="small"
      round="xsmall"
      margin={{ bottom: 'small' }}
    >
      <Typography
        data-testid="api-error-heading"
        type="text"
        size="medium"
        emphasis
        icon={<StatusCriticalSmall color="status-critical" />}
      >
        {t('reporting_dashboard:reporting_export_modal.create_report_failure')}
      </Typography>
      <Typography
        data-testid="api-error-desc"
        type="text"
        size="medium"
        margin={{ left: 'medium' }}
      >
        {errorCodeMessage()}
      </Typography>
    </Box>
  )
}

const WordBreak = ({ children }) => (
  <span
    style={{
      wordBreak: 'break-word',
      overflowWrap: 'break-word'
    }}
  >
    {children}
  </span>
)

LinkToReporting.propTypes = {
  t: PropTypes.func.isRequired
}
ModalFailureMessage.propTypes = {
  t: PropTypes.func.isRequired,
  errorCode: PropTypes.number.isRequired
}
WordBreak.propTypes = {
  children: PropTypes.node.isRequired
}
