import React from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'

import { Typography, DataTable } from '../../../components'

const HistoryDetails = ({ historyDetails }) => {
  const { t } = useTranslation(['manage'])

  const reviewerColumns = [
    {
      property: 'reviewer',
      type: 'string',
      primary: true,
      render: (datum) => (
        <Box direction="column" width={{ min: 'medium', max: 'medium' }}>
          <Typography
            size="medium"
            type="text"
            weight="bold"
            testId="page-title"
            color="text-weak"
          >
            {datum?.firstName
              ? `${datum?.firstName} ${datum?.lastName}`
              : datum?.userID}
          </Typography>
          <Typography size="small" type="text" testId="page-title">
            {datum?.comment ? datum?.comment : '--'}
          </Typography>
        </Box>
      )
    },
    {
      property: 'publish_date',
      type: 'date',
      render: (datum) => (
        <Box width={{ max: 'small', min: 'xsmall' }}>
          <Typography
            level="small"
            type="text"
            testId="page-title"
            wordBreak="break-word"
          >
            {datum?.timestamp
              ? `${dayjs(datum?.timestamp * 1000).format(
                  'MM/DD/YYYY HH:mm:ss'
                )}`
              : '--'}
          </Typography>
        </Box>
      )
    }
  ]

  return (
    <Box
      pad={{ horizontal: 'xlarge', top: 'xsmall', right: 'xsmall' }}
      width="large"
      justify="between"
      align="start"
      alignSelf="center"
    >
      <Box
        pad={{ top: 'small' }}
        border={{ side: 'top', color: 'border-weak', size: 'small' }}
        width="large"
      >
        <Typography level="2" type="heading" weight="bold" testId="page-title">
          {t('notification.approval.history_comment')}
        </Typography>
      </Box>
      {historyDetails?.items?.length > 0 ? (
        <Box
          direction="column"
          pad={{ bottom: 'small', top: 'xsmall' }}
          margin={{ bottom: 'medium' }}
        >
          <DataTable
            grid={{
              columns: reviewerColumns,
              data: historyDetails?.items || []
            }}
            testId="approval-details-datatable"
          />
        </Box>
      ) : (
        <Typography
          level="2"
          type="text"
          margin={{ top: 'small' }}
          testId="page-title"
        >
          {t('notification.approval.no_comment')}
        </Typography>
      )}
    </Box>
  )
}

HistoryDetails.prototypes = {
  notificationDetails: PropTypes.object
}

export default HistoryDetails
