import React, { useContext } from 'react'
import { Box } from 'grommet'
import dayjs from 'dayjs'

import { List, Typography } from '../../../../components'
import { WizardContext } from '../../../../components/wizard/WizardContext'
import { IAAS_KEY_TYPE } from '../../utils'
import { WizardSubscriptionListHeader } from '..'

const ServiceDeploymentRequired = () => {
  const { formValues } = useContext(WizardContext)

  const getProductForServiceDeployment = () => {
    return formValues?.serviceRequiredProductList?.filter((value) => {
      return (
        value.key_type === IAAS_KEY_TYPE &&
        !value?.available_applications?.length
      )
    })
  }

  return (
    <>
      <WizardSubscriptionListHeader />
      <List
        data={getProductForServiceDeployment()}
        pad={{ vertical: 'medium' }}
        border="top"
        customRender={(datum) => (
          <Box direction="row" justify="between">
            <Box gap="xsmall">
              {/* TODO: Modify subscription tier after BE confirms the field */}
              <Typography type="heading" level="2" testId="subscription-tier">
                {datum?.subscription_tier}
              </Typography>
              <Typography type="text" size="small" testId="subscription-key">
                {datum?.subscription_key}
              </Typography>
            </Box>
            <Box>
              {/* TODO: Modify expiration date after BE confirms the field */}
              <Typography type="heading" level="2" testId="expires">
                {datum?.end_date
                  ? dayjs(datum?.end_date).format('MM/DD/YYYY')
                  : ''}
              </Typography>
            </Box>
          </Box>
        )}
        eventType="custom-render"
        testId="selected-subscriptions-list"
      />
    </>
  )
}

export { ServiceDeploymentRequired }
