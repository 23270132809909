import { useState } from 'react'
import { Box } from 'grommet'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import {
  Button,
  CCSForm,
  FormInput,
  FormTextArea,
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../components'
import {
  GROUP_DESC_MAX_LENGTH,
  GROUP_NAME_MAX_LENGTH,
  GROUP_SCHEMAS
} from '../constants'
import { getErrorMessage, post } from '../../../../utils/api-utils'

const CreateGroupModal = ({ onSetOpen, setParentNotification }) => {
  const history = useHistory()
  const { t } = useTranslation(['common', 'iam'])
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState({
    groupName: '',
    groupDesc: ''
  })
  const closeModal = () => {
    onSetOpen(false)
  }

  const createGroup = async () => {
    if (isEmpty(formValues?.groupName?.trim())) {
      setErrorMsg(t('common:this_is_required'))
    } else {
      const url = '/identity/v2alpha1/scim/v2/Groups'
      const requestBody = {
        schemas: [GROUP_SCHEMAS?.ENTIRE],
        displayName: formValues?.groupName.trim()
      }
      requestBody[GROUP_SCHEMAS?.PARTIAL] = {
        groupDescription: formValues?.groupDesc
      }
      setLoading(true)
      await post(url, requestBody).then(
        (response) => {
          setLoading(false)
          if (response?.data) {
            const { id, displayName } = response.data
            history.push(`/manage-account/organization/groups/${id}`, {
              displayName
            })
          }
        },
        (error) => {
          setLoading(false)
          setParentNotification(getErrorMessage(error, t), 'error')
        }
      )
    }
  }

  return (
    <ModalDialog
      position="right"
      height="100%"
      overflow="hidden"
      header={
        <ModalHeader
          title={
            <Box>
              <Typography
                type="heading"
                level="2"
                margin={{ bottom: 'small' }}
                emphasis
              >
                {t('iam:organization_groups.create_group_title')}
              </Typography>
              <Box width="medium">
                <Typography type="text">
                  {t('iam:organization_groups.create_group_subtitle')}
                </Typography>
              </Box>
            </Box>
          }
          onClose={closeModal}
        />
      }
      content={
        <Box margin={{ top: 'small' }}>
          <CCSForm
            value={formValues}
            onChange={setFormValues}
            errorMessage=""
            testId="create-group-modal"
          >
            <Box>
              <FormInput
                inputType="text"
                name="groupName"
                label={`${t('iam:organization_groups.create_group_name')}*`}
                maxLength={GROUP_NAME_MAX_LENGTH}
                error={errorMsg}
                onChange={() => {
                  setErrorMsg('')
                }}
                testId="create-group-name"
              />
              <FormTextArea
                input="text"
                name="groupDesc"
                label={t('iam:organization_groups.create_group_desc')}
                labelHelper={t(
                  'iam:organization_groups.create_group_desc_help'
                )}
                maxLength={GROUP_DESC_MAX_LENGTH}
                testId="create-group-desc"
              />
            </Box>
          </CCSForm>
          <Box align="center" margin={{ top: 'xsmall' }}>
            {loading && <Loader testId="create-groups-loader" />}
          </Box>
        </Box>
      }
      footer={
        <ModalFooter
          left={
            <Box direction="row" gap="medium">
              <Button
                primary
                label={t('iam:organization_groups.create_group_create_btn')}
                onClick={createGroup}
                disabled={loading}
                testId="create-group-btn"
              />
              <Button
                label={t('common:cancel')}
                onClick={closeModal}
                disabled={loading}
                testId="create-group-cancel-btn"
              />
            </Box>
          }
        />
      }
      onClose={closeModal}
      onClickOutside={closeModal}
      testId="create-group-dialog"
    />
  )
}

CreateGroupModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  setParentNotification: PropTypes.func.isRequired
}

export default CreateGroupModal
