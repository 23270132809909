import groupBy from 'lodash/groupBy'

import { OTHER } from '../constants'

import { Service } from './Service'

/**
 * This shim adds mock support for provisioned services that are not defined in the service definitions file
 */
export const createServiceMocks = (
  provisionsByServiceManagerSlug,
  services
) => {
  const servicesByServiceManagerSlug = groupBy(services, 'serviceManagerSlug')

  return (
    Object.entries(provisionsByServiceManagerSlug)
      // specifically filter for provisioned services that are not found in the service definitions file
      .filter(
        ([serviceManagerSlug]) =>
          !servicesByServiceManagerSlug[serviceManagerSlug] &&
          /**
           * There are situations where we have duplicate services in this list when there is a service shim for a service (i.e. not a service manager) and the
           * service is also returned from the backend (provisions api). In this situation, we are giving preference towards showing the service from the service
           * shim, not the service being returned from the backend
           */
          !servicesByServiceManagerSlug[OTHER]?.map(
            ({ serviceSlug }) => serviceSlug
          ).includes(serviceManagerSlug)
      )
      .map(([, instances]) => {
        // all app instances have the same top level metadata, so we can just use the first one
        const [
          { slug, description, tenant_only_supported, ...serviceInstance }
        ] = instances
        /**
         * Since we already have the service instance data, update regions immediately after we insantiate the Service object.
         * Additionally, these service mocks do not have localization files, so we need to continue to derive the description from the backend.
         */
        return Object.assign(
          new Service({
            ...serviceInstance,
            serviceManagerSlug: slug,
            serviceSlug: slug
          }),
          {
            description,
            tenant_only_supported,
            regions: instances.map(
              ({
                region: code,
                catalog_visible,
                application_instance_id: serviceManagerId,
                provision_status: provisionStatus,
                application_customer_id: serviceManagerCustomerId,
                msp_conversion_status: mspConversionStatus
              }) => ({
                code,
                catalog_visible,
                provisionStatus,
                mspConversionStatus,
                serviceManagerCustomerId,
                serviceManagerId
              })
            )
          }
        )
      })
  )
}
