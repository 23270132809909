import React from 'react'
import PropTypes from 'prop-types'

import useAppCatalogReducer from './AppCatalogReducer'

const AppCatalogContext = React.createContext()

export const AppCatalogContextProvider = ({ children }) => {
  const stateAndDispatch = useAppCatalogReducer()
  return (
    <AppCatalogContext.Provider value={stateAndDispatch}>
      {children}
    </AppCatalogContext.Provider>
  )
}

AppCatalogContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const useAppCatalogContext = () => {
  const { CatalogState, dispatchAppCatalogContext } =
    React.useContext(AppCatalogContext)
  return { ...CatalogState, dispatchAppCatalogContext }
}
