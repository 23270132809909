import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, DropButton, Page, PageContent, PageHeader, Anchor } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { FormDown, Previous } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import delay from 'lodash/delay'
import cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'

import PermissionList from '../commoncomponents/PermissionsList'
import AssignedUsers from '../AssignedUsers'
import { Layout } from '../../layout/Layout'
import { Typography, Button, Loader } from '../../../../components'
import { displayNotification } from '../../../../utils/notificiation-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { del, get, put } from '../../../../utils/api-utils'
import DeleteRoles from '../../../manage-account/identity/roles/delete-roles/DeleteRoles'
import DeleteConfirmation from '../../../manage-account/identity/roles/delete-roles/DeleteConfirmation'
import {
  AUTHZActions,
  useAUTHZContext
} from '../../../../context/authz-context'
import VisibilityWrapper from '../../visibility-wrapper/VisibilityWrapper'
import { getCustomerAccount } from '../../../../utils/feature-flag-utils'
import { isServiceCentric } from '../../../../utils/account-utils'

import RoleDynamicViewEditForm from './RoleDynamicViewEditForm/RoleDynamicForm'

const RoleDetailsViewEdit = ({ inCCSManager }) => {
  const { t } = useTranslation(['authz', 'manage', 'common'])
  const history = useHistory()
  const { applicationId, slug } = useParams()
  const [readonly, setReadonly] = useState(true)
  const { dispatchAUTHZContext, roleAppMap } = useAUTHZContext()
  const [showDelete, setShowDelete] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [showNotification, setShowNotification] = useState(null)
  const [role, setRole] = useState({})
  const [loading, setLoading] = useState(false)
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']

  const custAccountLoaded = getCustomerAccount()
  const platformCid = custAccountLoaded?.platform_customer_id || ''

  const detailsFields = [
    { name: 'name', label: t('roles.role_name') },
    {
      name: 'description',
      type: 'textarea',
      label: t('roles.role_description'),
      labelHelper: t('desc_max_chars')
    },
    {
      name: 'application_name',
      label: newDashboardFlag
        ? t('roles.service_manager_name_header')
        : t('roles.application_name_header'),
      disabled: true
    },
    { name: 'createdAt', label: t('date_created'), disabled: true },
    { name: 'lastUpdated', label: t('last_updated'), disabled: true }
  ]

  const handleBackBtnClick = useCallback(() => {
    dispatchAUTHZContext({
      type: AUTHZActions.CLEAR_SELECTED_ROLE_DETAILS
    })
    setShowNotification(null)
    history.push(
      `/${
        inCCSManager && saCustomRoleLDFlag ? 'manage-ccs' : 'manage-account'
      }/identity/roles`
    )
  }, [
    dispatchAUTHZContext,
    history,
    setShowNotification,
    inCCSManager,
    saCustomRoleLDFlag
  ])

  const getRole = useCallback(() => {
    setLoading(true)
    get(
      `/ui-doorway/ui/v1/um/customers/roles/${slug}`,
      { application_id: applicationId },
      oidcUser.access_token
    ).then(
      (response) => {
        const data = response?.data
        setRole({
          ...data,
          resourcePolicies: cloneDeep(data?.resource_policies),
          application_id: applicationId,
          createdAt: dayjs.unix(data?.created_at).format('MMMM DD, YYYY'),
          lastUpdated: dayjs.unix(data?.updated_at).format('MMMM DD, YYYY')
        })
        setLoading(false)
      },
      (error) => {
        let pageCustomErrorMessage = ''
        if (error?.response?.status === 403) {
          pageCustomErrorMessage = t('common:error_messages.ERR_403')
        }
        setShowNotification(
          displayApiError(error, t, handleBackBtnClick, pageCustomErrorMessage)
        )
        setLoading(false)
      }
    )
  }, [
    oidcUser.access_token,
    t,
    setShowNotification,
    setRole,
    applicationId,
    handleBackBtnClick,
    slug
  ])

  useEffect(() => {
    if (applicationId && slug) {
      getRole()
    }
  }, [applicationId, slug, getRole])

  const onEdit = () => setReadonly(false)

  const deleteRole = () => {
    if (!platformCid) return

    setConfirmOpen(false)
    setShowNotification(null)
    setLoading(true)
    const url = `/authorization/ui/v1/customers/${platformCid}/applications/${role?.application_id}/roles/${role.slug}`
    del(url, {}, oidcUser?.access_token).then(
      () => {
        setLoading(false)
        setShowNotification(
          displayNotification(
            t('roles.del_progress_notification'),
            'info',
            setShowNotification
          )
        )
        delay(() => {
          history.push(
            `/${
              inCCSManager && saCustomRoleLDFlag
                ? 'manage-ccs'
                : 'manage-account'
            }/identity/roles`
          )
        }, 3000)
      },
      (error) => {
        setShowNotification(displayApiError(error, t, setShowNotification))
      }
    )
  }

  const onSubmitForm = ({ value }) => {
    if (!platformCid) return

    if (value) {
      const requestBody = {
        name: value.name,
        description: value.description,
        resource_policies: {
          add: [],
          update: [],
          delete: []
        }
      }
      put(
        `/authorization/ui/v1/customers/${platformCid}/applications/${role.application_id}/roles/${role.slug}`,
        requestBody,
        oidcUser.access_token
      ).then(
        () => {
          setReadonly(true)
        },
        (error) => {
          setShowNotification(displayApiError(error, t, setShowNotification))
        }
      )
    } else {
      setReadonly(true)
    }
  }

  const onSubmitPermissions = async (resourcePolicies) => {
    if (!platformCid) return

    const requestBody = {
      name: role.name,
      description: role.description,
      resource_policies: resourcePolicies
    }
    try {
      const response = await put(
        `/authorization/ui/v1/customers/${platformCid}/applications/${role.application_id}/roles/${role.slug}`,
        requestBody,
        oidcUser.access_token
      )
      if (response.data && response.status === 200) {
        setReadonly(true)
        dispatchAUTHZContext({
          type: AUTHZActions.CLEAR_SELECTED_ROLE_UPDATE_FORM
        })
      }
    } catch (error) {
      setShowNotification(displayApiError(error, t, setShowNotification))
    } finally {
      getRole()
    }
  }

  const onCancelForm = () => setReadonly(true)

  const onDuplicateClick = () => {
    dispatchAUTHZContext({
      type: AUTHZActions.SET_CREATE_ROLE_DATA,
      data: {
        name: role.name,
        createOption: 'duplicate_existing',
        application: role.application_id
      }
    })
    dispatchAUTHZContext({
      type: AUTHZActions.SET_SELECTED_ROLE,
      data: {
        ...role,
        application: role.application_id,
        resourcePolicies: role.resource_policies
      }
    })
    const permissionsSelected = []
    const res = role.resource_policies
    for (let j = 0; j < res.length; j += 1) {
      for (let i = 0; i < res[j].permissions.length; i += 1) {
        permissionsSelected.push(
          `${res[j].resource.matcher}~${res[j].permissions[i].slug}`
        )
      }
    }
    dispatchAUTHZContext({
      type: AUTHZActions.PERMISSIONS_SELECTED,
      data: permissionsSelected
    })
    history.push(
      `/${
        inCCSManager && saCustomRoleLDFlag ? 'manage-ccs' : 'manage-account'
      }/identity/roles/create/${role.application_id}/${role.slug}`
    )
    roleAppMap.set(role.application_id, {
      ...roleAppMap.get(role.application_id),
      duplicateFlag: true
    })
  }

  return (
    <Page kind="narrow">
      <PageContent>
        {showNotification !== null && showNotification}
        {loading ? (
          <Box
            height="xsmall"
            align="center"
            justify="center"
            alignSelf="center"
          >
            <Loader testId="role-detail-view-loader" orientation="horizontal" />
          </Box>
        ) : (
          <PageHeader
            responsive
            parent={
              <Anchor
                label={t('roles_permissions_title')}
                icon={<Previous />}
                onClick={() => handleBackBtnClick()}
                data-testid="role-details_back-btn"
              />
            }
            title={
              <Typography
                type="heading"
                level="1"
                color="text-strong"
                testId="network-operations-admin"
                truncate
              >
                {role?.name}
              </Typography>
            }
            actions={
              <VisibilityWrapper
                hideFor={{
                  account: ['TENANT']
                }}
                rbac={{
                  resource: '/ccs/authorization',
                  permission: 'ccs.authorization.edit'
                }}
              >
                {!role?.predefined && !role?.tags?.readonly && (
                  <DropButton
                    label={t('manage:users.actions')}
                    icon={<FormDown />}
                    reverse
                    data-testid="role-details-action-btn"
                    dropAlign={{ top: 'bottom', left: 'left' }}
                    dropContent={
                      <>
                        <>
                          <VisibilityWrapper
                            rbac={{
                              resource: '/ccs/authorization',
                              permission: 'ccs.authorization.delete'
                            }}
                          >
                            <Box
                              justify="start"
                              pad={{
                                vertical: 'xsmall',
                                horizontal: 'small'
                              }}
                              width="small"
                            >
                              <Button
                                testId="role-details_delete-role-btn"
                                onClick={setShowDelete}
                                showOneActionAsDropDown
                              >
                                {t('authz:roles.delete_role')}
                              </Button>
                            </Box>
                          </VisibilityWrapper>
                          <Box
                            justify="start"
                            pad={{ vertical: 'xsmall', horizontal: 'small' }}
                            width="small"
                          >
                            <Button
                              testId="role-details_duplicate-role-btn"
                              onClick={onDuplicateClick}
                              showOneActionAsDropDown
                            >
                              {t('authz:roles.duplicate')}
                            </Button>
                          </Box>
                        </>
                      </>
                    }
                  />
                )}
              </VisibilityWrapper>
            }
          />
        )}
        {role?.application_id && (
          <>
            <RoleDynamicViewEditForm
              detailsFields={detailsFields}
              values={role}
              onEdit={onEdit}
              readonly={readonly}
              isEditable={!role?.predefined}
              onSubmit={onSubmitForm}
              onCancel={onCancelForm}
            />
            <PermissionList
              isEditable={!role?.predefined}
              showEdit
              selectedRole={role}
              setRole={setRole}
              onSubmit={onSubmitPermissions}
              inCCSManager={inCCSManager}
            />
            <AssignedUsers role={role} showSearch inCCSManager={inCCSManager} />
          </>
        )}
        {showDelete && (
          <DeleteRoles
            setOpen={setShowDelete}
            select={role}
            setConfirm={setConfirmOpen}
          />
        )}
        {confirmOpen && (
          <DeleteConfirmation
            setConfirm={setConfirmOpen}
            select={role}
            deleteSelectedRoles={deleteRole}
          />
        )}
      </PageContent>
    </Page>
  )
}

const RoleDetailsViewEditPage = ({ inCCSManager }) => {
  const LDFlags = useFlags()
  const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']

  return inCCSManager && saCustomRoleLDFlag ? (
    <RoleDetailsViewEdit inCCSManager={inCCSManager} />
  ) : (
    <Layout>
      <RoleDetailsViewEdit />
    </Layout>
  )
}
RoleDetailsViewEditPage.propTypes = {
  inCCSManager: PropTypes.bool
}

RoleDetailsViewEditPage.defaultProps = {
  inCCSManager: false
}

RoleDetailsViewEdit.propTypes = {
  inCCSManager: PropTypes.bool
}

RoleDetailsViewEdit.defaultProps = {
  inCCSManager: false
}

export default RoleDetailsViewEditPage
