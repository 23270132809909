const treeBuilder = (data, result = []) => {
  result = data.map((sub) => ({
    ...sub,
    subs: sub.permissions,
    children: sub.children.length ? treeBuilder(sub.children, result) : []
  }))
  return result
}
const getPermissionsData = (data) => {
  const result = { defaultPermission: [], mandatoryPermission: [] }
  data.forEach((datum) => {
    if (datum?.default_permissions?.length) {
      datum.default_permissions.forEach((perm) => {
        const matcher = `${datum.slug}~${perm.slug}`
        result.defaultPermission.push(matcher)
      })
    }
    if (datum?.tags?.mandatory) {
      result.mandatoryPermission.push(`${datum.slug}`)
    }
    if (datum?.children?.length) {
      result.defaultPermission = result.defaultPermission.concat(
        getPermissionsData(datum.children).defaultPermission
      )
      result.mandatoryPermission = result.mandatoryPermission.concat(
        getPermissionsData(datum.children).mandatoryPermission
      )
    }
  })
  return result
}
export { treeBuilder, getPermissionsData }
