// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useEffect, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Anchor } from 'grommet'
import * as allIcons from 'grommet-icons'
import { useHistory } from 'react-router-dom'

import { get } from '../../../../utils/api-utils'
import { Loader, NoDataInfo, Typography, Widget } from '../../../../components'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

const RunTimeWidget = (props) => {
  const { data, url, title, subTitle, testId, noDataSummary, action } = props
  const [serviceStats, setServiceStats] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [showError, setShowError] = useState(false)

  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['common'])
  const EmptyStateIcon = allIcons[noDataSummary?.icon]
  const history = useHistory()

  useEffect(() => {
    setIsLoading(true)
    get(url, {}, oidcUser.access_token).then(
      (response) => {
        setServiceStats(response?.data)
        setIsLoading(false)
      },
      (error) => {
        setIsLoading(false)
        setShowError(true)
        console.error(`Failed to fetch ${url}:`, error)
      }
    )
  }, [oidcUser.access_token, url])

  const updatedData = data.map((item) => ({
    ...item,
    value: serviceStats[item.valueKey]
  }))

  const DisplayWidget = () => {
    if (showError) {
      const ErrorStateIcon = allIcons.CircleInformation
      return (
        <Widget
          title={title}
          subTitle={subTitle}
          testId={testId}
          onSelectClick={null}
          action={action}
          widgetBodyCustomStyle={{ justify: 'center' }}
        >
          <NoDataInfo
            gap="small"
            gapTitleSubtitle={false}
            icon={<ErrorStateIcon size="large" color="text-weak" />}
            title={t('dashboard.widgets.failed_to_load')}
            subtitle={t('dashboard.widgets.try_refresh')}
          />
        </Widget>
      )
    }
    if (
      serviceStats[
        Object.keys(serviceStats).find((item) => item.includes('total_'))
      ] === 0
    ) {
      return (
        <Widget
          title={title}
          subTitle={subTitle}
          testId={testId}
          onSelectClick={null}
          action={action}
          widgetBodyCustomStyle={{ justify: 'center' }}
        >
          <NoDataInfo
            gap="small"
            gapTitleSubtitle={false}
            icon={<EmptyStateIcon size="large" color="text-weak" />}
            title={noDataSummary.message}
            subtitle={
              <VisibilityWrapper
                rbac={noDataSummary.addButtonRbac}
                key={`no-data-info-add-button-${testId}`}
              >
                <Typography type="text">
                  <Trans i18nKey="dashboard.widgets.show_them_here" t={t}>
                    <Anchor
                      label={noDataSummary.anchorText}
                      onClick={() => history.push(action)}
                      testId={`${testId}-no-data-info-anchor`}
                    />
                  </Trans>
                </Typography>
              </VisibilityWrapper>
            }
          />
        </Widget>
      )
    }
    return <Widget {...props} data={updatedData} />
  }

  return isLoading ? (
    <Widget
      title={title}
      subTitle={subTitle}
      testId={testId}
      onSelectClick={null}
      widgetBodyCustomStyle={{ justify: 'center', align: 'center', fill: true }}
    >
      <Loader data-testid={`${testId}-loader`} plain />
    </Widget>
  ) : (
    DisplayWidget()
  )
}

RunTimeWidget.propTypes = {
  data: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  noDataSummary: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired
}

export default RunTimeWidget
