import { post, getErrorMessage } from '../../../../../utils/api-utils'

const validateAndClaimRequest = async (
  formValues,
  i18nTranslate,
  setLoading
) => {
  let returnVal
  const { domainName } = formValues
  if (domainName?.trim() === '') {
    return {
      errorMessage: i18nTranslate('domains.required'),
      isValid: false
    }
  }
  setLoading(true)
  const url = `/identity/v1alpha1/domain-requests`
  await post(url, { name: domainName }).then(
    (response) => {
      setLoading(false)
      returnVal = {
        isValid: true,
        domainRes: response
      }
    },
    (error) => {
      setLoading(false)
      const backendErrorMessage = getErrorMessage(error, i18nTranslate)
      returnVal = {
        isValid: false,
        errorMessage: backendErrorMessage
      }
    }
  )

  return returnVal
}

export default validateAndClaimRequest
