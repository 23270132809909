import { useReducer } from 'react'

import AUTHZActions from './actions'

const initialContext = {
  // global shared application and application instances lists
  provAppsList: null,

  // roles landing page context
  selectedRole: {}, // one role selected from table
  selectedRoleUpdateForm: {
    resource_policies: {
      add: [],
      update: [],
      delete: []
    },
    name: '',
    description: ''
  },

  // create role page
  permissionsSelected: [], // selected in tree: only array of permission name
  permissionsData: null, // create role permission tree data
  selectedTreeRolePermissions: [], // display selected role after add, array of object permission
  roleAppMap: new Map(), // display all roles associated with provisioned Apps for new create role flow
  selectedAppRole: null, // application and role selected on create role modal

  // read/write assignment page
  rolesSelected: [], // role selected from tree, tree checkboxes
  scopesSelected: [], // scope selected from tree, tree checkboxes

  users: [], // create assignment list of all users
  selectedUsers: [], // selected from dropdown, hold all data object belongs to specific user

  rolesData: null, // create assignment role tree data
  scopesData: null, // create assignment scope tree data
  selectedTreeRoleAssignments: [], // display create assignment pills landing page
  selectedTreeScopeAssignments: {}, // display create assignment pills landing page
  selectedTreeAccessRules: {}, // display selected access roles for each page

  roleAssignmentData: [], // create assignment role tree data

  // resource restriction policy context
  // rrp table view
  scopeGroupsListLength: null,
  // edit rrp
  rrpDetails: null,

  // scopes modal - can be used everywhere where scope modal is open
  scopesDataMap: new Map(),
  onModalScopesMap: new Map(),
  openDetails: false,
  scopeResourceInstances: new Map(),

  // Create Role page, use Mandatory resources' permission list to ensure mandatory permissions
  mandatoryResPermissions: [],
  userCCSRoles: null // users current ccs role slugs, used in invite user, assign role
}

const clearPermissionsData = () => {
  return {
    permissionsSelected: [],
    permissionsData: null,
    selectedTreeRolePermissions: []
  }
}
const clearScopeModalData = () => {
  return {
    onModalScopesMap: new Map()
  }
}
const AUTHZReducer = (state, { type, data }) => {
  const { selectedTreeScopeAssignments } = state || {}
  switch (type) {
    case AUTHZActions.SET_SELECTED_ROLE:
      return { ...state, selectedRole: data }
    case AUTHZActions.SET_SELECTED_ROLE_DETAILS:
      return { ...state, selectedRole: data, openDetails: true }
    case AUTHZActions.CLEAR_SELECTED_ROLE_DETAILS:
      return { ...state, selectedRole: {}, openDetails: false }
    case AUTHZActions.SET_SELECTED_ROLE_UPDATE_FORM:
      return { ...state, selectedRoleUpdateForm: data }
    case AUTHZActions.CLEAR_SELECTED_ROLE_UPDATE_FORM:
      return {
        ...state,
        selectedRoleUpdateForm: {
          resource_policies: {
            add: [],
            update: [],
            delete: []
          },
          name: '',
          description: ''
        }
      }
    case AUTHZActions.PERMISSIONS_SELECTED:
      return { ...state, permissionsSelected: data }
    case AUTHZActions.SET_PERMISSIONS_DATA:
      return { ...state, permissionsData: data }
    case AUTHZActions.SET_CREATE_ROLE_DATA:
      return { ...state, selectedAppRole: data }
    case AUTHZActions.SET_ROLE_APP_MAP_DATA:
      return { ...state, roleAppMap: data }
    case AUTHZActions.CLEAR_PERMISSIONS_DATA:
      return { ...state, ...clearPermissionsData() }
    case AUTHZActions.SET_SCOPES_SELECTED:
      return { ...state, scopesSelected: data }
    case AUTHZActions.SET_ROLES_SELECTED:
      return { ...state, rolesSelected: data }
    case AUTHZActions.SET_SCOPES_DATA:
      return { ...state, scopesData: data }
    case AUTHZActions.SET_ROLES_DATA:
      return { ...state, rolesData: data }
    case AUTHZActions.SET_TREE_ROLE_ASSIGNMENTS:
      return { ...state, selectedTreeRoleAssignments: data }
    case AUTHZActions.SET_ROLE_ACCESS_RULES:
      return { ...state, selectedTreeAccessRules: data }
    case AUTHZActions.SET_TREE_ROLE_PERMISSIONS:
      return { ...state, selectedTreeRolePermissions: data }
    case AUTHZActions.SET_TREE_SCOPE_ASSIGNMENTS:
      return {
        ...state,
        selectedTreeScopeAssignments: {
          ...selectedTreeScopeAssignments,
          ...data
        }
      }
    case AUTHZActions.CLEAR_TREE_SCOPE_ASSIGNMENTS:
      return { ...state, selectedTreeScopeAssignments: data || {} }
    case AUTHZActions.CLEAR_CREATE_ASSIGNMENT_SELECTIONS:
      return {
        ...state,
        selectedTreeRoleAssignments: [],
        selectedTreeScopeAssignments: {},
        rolesSelected: [],
        scopesSelected: [],
        selectedTreeAccessRules: {},
        roleAssignmentData: [],
        rolesData: null,
        scopesData: null
      }

    case AUTHZActions.SET_USERS:
      return { ...state, allUsers: data }
    case AUTHZActions.SET_SELECTED_USERS:
      return { ...state, selectedUsers: data }
    case AUTHZActions.SET_PROV_APPS_LIST:
      return { ...state, provAppsList: data }
    case AUTHZActions.SET_ROLE_ASSIGNMENT_DATA:
      return { ...state, roleAssignmentData: data }
    case AUTHZActions.SET_SCOPE_GROUPS_LIST_LENGTH: {
      return {
        ...state,
        scopeGroupsListLength: data
      }
    }
    case AUTHZActions.CLEAR_SCOPE_GROUP_TABLE_DATA:
      return { ...state, scopeGroupsListLength: null }
    case AUTHZActions.SET_RRP_DETAILS: {
      return {
        ...state,
        rrpDetails: data
      }
    }
    case AUTHZActions.SET_SCOPE_DATA_MAP: {
      return {
        ...state,
        scopesDataMap: data
      }
    }
    case AUTHZActions.CLEAR_SCOPE_DATA_MAP: {
      return {
        ...state,
        scopesDataMap: new Map()
      }
    }
    case AUTHZActions.SET_ON_MODAL_SCOPES_MAP: {
      return {
        ...state,
        onModalScopesMap: data
      }
    }
    case AUTHZActions.CLEAR_SCOPE_MODAL_DATA:
      return { ...state, ...clearScopeModalData() }
    case AUTHZActions.SET_SCOPE_RESOURCE_INSTANCES: {
      return {
        ...state,
        scopeResourceInstances: data
      }
    }
    case AUTHZActions.CLEAR_SCOPE_RESOURCE_INSTANCES: {
      return {
        ...state,
        scopeResourceInstances: new Map()
      }
    }
    case AUTHZActions.SET_MANDATORY_RES_PERMISSIONS:
      return { ...state, mandatoryResPermissions: data }
    case AUTHZActions.SET_USER_CCS_ROLES:
      return { ...state, userCCSRoles: data }
    default:
      return state
  }
}

const useAUTHZReducer = () => {
  const [AUTHZState, dispatchAUTHZContext] = useReducer(
    AUTHZReducer,
    initialContext
  )
  return { AUTHZState, dispatchAUTHZContext }
}

export default useAUTHZReducer
