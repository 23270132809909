import { useHistory } from 'react-router-dom'

export const ActivateMagicLinkRedirection = () => {
  const history = useHistory()
  const searchParams = new URLSearchParams(window.location.search)
  const subscriptionProductSquid = searchParams?.get('squid')
  const workflow = searchParams?.get('flow')
  if (subscriptionProductSquid && workflow === 'subscription') {
    sessionStorage.setItem('subscriptionProductSquid', subscriptionProductSquid)
    history.push('/')
  } else {
    history.push('/error/invalid-link')
  }
  return null
}
