import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'

const ConfigureAuthenticationPage = lazy(() =>
  import('./ConfigureAuthenticationPage')
)

const ConfigureAuthenticationRouter = () => {
  const { path } = useRouteMatch()
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route path={`${path}`}>
          <ConfigureAuthenticationPage />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default ConfigureAuthenticationRouter
