// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ShareRounded } from 'grommet-icons'

import {
  Button,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../../components'

const LimitedAccessModal = ({
  setShowLimitedAccessModal,
  rrpData,
  selectedRoleAssignment,
  inCCSManager,
  showActions
}) => {
  const { t: authzT } = useTranslation(['authz'])
  const history = useHistory()
  const handleRRPlick = (rrpId) => {
    if (rrpId) {
      if (inCCSManager)
        history.push(`/manage-ccs/customers/scope-groups/${rrpId}`)
      else history.push(`/manage-account/identity/scope-groups/${rrpId}`)
    }
  }
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="1" testId="limited-access-title">
              {authzT('rrp.limited_access')}
            </Typography>
          }
        />
      }
      content={
        <Box gap="medium" margin={{ top: 'medium' }}>
          <Typography type="text" size="large" testId="limited-access-desc">
            <Trans
              i18nKey="rrp.limited_access_desc"
              t={authzT}
              values={{
                appName: selectedRoleAssignment.application_name,
                roleName: selectedRoleAssignment.role_name
              }}
              components={{ b: <strong /> }}
            />
          </Typography>
          <Box height={{ max: 'medium' }} overflow="auto">
            {rrpData?.map((data) => (
              <Box
                direction="row"
                align="center"
                justify="between"
                pad={{ horizontal: 'small', vertical: 'medium' }}
                key={data?.resource_restriction_policy_id}
              >
                <Typography type="text" size="medium" testId="rrp">
                  {data?.name}
                </Typography>
                {showActions && (
                  <Box
                    data-testid="open-rrp-detail"
                    onClick={() =>
                      handleRRPlick(data?.resource_restriction_policy_id)
                    }
                  >
                    <ShareRounded size="medium" />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <Box gap="medium" direction="row" justify="end">
              <Button
                label={authzT('rrp.close')}
                default
                testId="secondary-btn"
                onClick={() => setShowLimitedAccessModal(false)}
              />
            </Box>
          }
        />
      }
      onClose={() => setShowLimitedAccessModal(false)}
      testId="limited-access-modal"
      width="medium"
    />
  )
}

LimitedAccessModal.propTypes = {
  setShowLimitedAccessModal: PropTypes.func.isRequired,
  rrpData: PropTypes.array.isRequired,
  selectedRoleAssignment: PropTypes.object.isRequired,
  inCCSManager: PropTypes.bool,
  showActions: PropTypes.bool.isRequired
}

LimitedAccessModal.defaultProps = {
  inCCSManager: false
}

export { LimitedAccessModal }
