import lodashGet from 'lodash/get'

import { get, post, put } from '../../../../utils/api-utils'
import {
  displayApiError,
  getApiErrorMessage
} from '../../../../utils/error-handling-utils'
import { displayNotification } from '../../../../utils/notificiation-utils'
import {
  displayNotificationContent,
  handleErrorNotification
} from '../../../../utils/reporting-utils'

export const getDeviceList = ({
  token,
  t,
  param,
  setTotalItems,
  setErrorMessage,
  inCCSManager,
  setCountOnFirstLoad,
  countOnFirstLoad,
  reportingActivateExportLdFlag
} = {}) => {
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/activate-devices`
    : '/ui-doorway/ui/v1/activate/devices'

  return get(url, param, token).then(
    (response) => {
      if (
        reportingActivateExportLdFlag &&
        countOnFirstLoad !== undefined &&
        !countOnFirstLoad
      ) {
        setCountOnFirstLoad(response?.data?.pagination?.total_count || 0)
      }
      setTotalItems(lodashGet(response, 'data.pagination.total_count', 0))
      return lodashGet(response, 'data.devices', [])
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
      return []
    }
  )
}
export const getAllModelsOptions = ({
  token,
  t,
  param,
  setErrorMessage,
  setSelectedRows,
  isCCSManager
} = {}) => {
  const url = isCCSManager
    ? '/support-assistant/v1alpha1/device-models'
    : '/ui-doorway/ui/v1/activate/devices/models'

  return get(url, param, token).then(
    (response) => {
      setSelectedRows([])
      return response.data
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
      return []
    }
  )
}
export const getDeviceDetails = (
  token,
  t,
  serial,
  macAddr,
  setErrorMessage,
  setLoading
) => {
  return get(
    `/ui-doorway/ui/v1/activate/devices/details/${serial}`,
    { mac_address: macAddr },
    token
  ).then(
    (response) => {
      setLoading(false)
      return response?.data?.devices[0] || []
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
      setLoading(false)
      return []
    }
  )
}

export const editDeviceDetails = (
  token,
  t,
  requestBody,
  setCurrentMode,
  setSuccessMessage,
  setErrorMessage,
  fetchDeviceDetails
) => {
  return put(
    `/ui-doorway/ui/v1/activate/devices/edit`,
    requestBody,
    token
  ).then(
    () => {
      setCurrentMode('VIEW')
      fetchDeviceDetails()
      setSuccessMessage(t('device_updated_successfully'))
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
    }
  )
}

export const actionMoveToFolder = (
  token,
  t,
  requestBody,
  onSetOpen,
  refreshDataTable,
  setErrorMessage
) => {
  post(`/ui-doorway/ui/v1/activate/devices/folder`, requestBody, token).then(
    (response) => {
      if (response.status === 200) {
        onSetOpen(false)
        refreshDataTable()
      }
    },
    (error) => {
      onSetOpen(false)
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
    }
  )
}
export const generateReportForActivateAPICall = (
  request,
  token,
  setNotification,
  onSuccess,
  setLoading,
  onSetOpen,
  t,
  reportingDashboardFlag,
  setApiError
) => {
  post(`/report-mgmt/ui/v1/exports`, request, token).then(
    (response) => {
      onSuccess(response)
      const refId = response?.data?.tracking_id
      const repName = response?.data?.reportName
      setNotification(
        displayNotification(
          displayNotificationContent(reportingDashboardFlag, t, refId, repName),
          'info',
          setNotification
        )
      )
      onSetOpen(false)
    },
    (error) => {
      setLoading(false)
      if (!reportingDashboardFlag) {
        handleErrorNotification(error, t, setNotification, displayApiError)
        onSetOpen(false)
      } else {
        setApiError(error?.response)
        onSetOpen(true)
      }
    }
  )
}
