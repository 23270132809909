import { Anchor } from 'grommet'
import { Trans } from 'react-i18next'

import { post } from '../../utils/api-utils'
import { AUDIT_LOG_CATEGORY } from '../../utils/common-utils'
import { GREENLAKE_SUPPORT_URL } from '../../utils/error-utils'

export const handleProductClaim = ({
  formValues,
  history,
  t,
  oidcUser,
  squid
}) => {
  const promises = []
  const selectedSubscriptions = formValues?.selectedProductList
  const selectedDevices = formValues?.selectedDevicesList
  const claimedAllSubscriptions = formValues?.claimedAllSubscriptions
  const claimedAllDevices = formValues?.claimedAllDevices
  // Invoke subscriptions claim api if user has subscriptions selected
  if (selectedSubscriptions?.length) {
    const requestBody = {
      ...(claimedAllSubscriptions && { claim_all: true }),
      subscriptions: selectedSubscriptions?.map((value) => {
        const applicationInfo =
          formValues?.selectedApplication[value?.subscription_key]
        return {
          subscription_key: value?.subscription_key,
          ...(applicationInfo && {
            application: {
              applicationId: applicationInfo?.application_id,
              region: applicationInfo?.ccs_region
            }
          })
        }
      })
    }
    promises.push(
      post(
        '/ui-doorway/ui/v1/license/claim',
        requestBody,
        oidcUser.access_token
      )
    )
  }
  // Invoke devices claim api if user has devices selected
  if (selectedDevices?.length) {
    const requestBody = {
      squid,
      ...(claimedAllDevices
        ? { claim_all: true, devices: [{ app_category: 'COMPUTE' }] } // BE has a DTO validation for devices as required. Hence passing single app_category entry under devices field
        : {
            devices: selectedDevices?.map((value) => {
              return {
                serial_number: value?.serial_number,
                part_number: value?.part_number,
                mac_address: value?.mac_address,
                device_type: value?.device_type,
                // BE needs app_category field for claim api
                // As suggested by BE, hardcoding app_category as COMPUTE for discover
                app_category: 'COMPUTE'
              }
            })
          })
    }
    promises.push(
      post('/ui-doorway/ui/v1/devices', requestBody, oidcUser.access_token)
    )
  }
  Promise.allSettled(promises).then(([Resp1, Resp2]) => {
    let isPromisesSuccess = false
    // if 2 api's are invoked, then look for both the api success response else check for the single api success response
    if (promises?.length === 2)
      isPromisesSuccess =
        Resp1?.status === 'fulfilled' && Resp2?.status === 'fulfilled'
    else isPromisesSuccess = Resp1?.status === 'fulfilled'

    if (isPromisesSuccess) {
      // On both api success, navigate to home page and display toast success notification
      history.push({
        pathname: '/home',
        successMessage: t('product_claim_accept_msg')
      })
    } else {
      // On any api failure, navigate to home page and display inline error notification below the header
      history.push({
        pathname: '/home',
        bannerErrorMessage: (
          <Trans i18nKey="product_claim_error_msg" t={t}>
            <Anchor
              style={{ whiteSpace: 'wrap' }}
              weight="normal"
              onClick={() =>
                history.push({
                  pathname: '/manage-account/auditlogs',
                  state: {
                    name: [
                      AUDIT_LOG_CATEGORY.SUBSCRIPTION_MANAGEMENT,
                      AUDIT_LOG_CATEGORY.DEVICE_MANAGEMENT
                    ]
                  }
                })
              }
            />
            <Anchor
              style={{ whiteSpace: 'wrap' }}
              weight="normal"
              href={GREENLAKE_SUPPORT_URL}
              target="_blank"
            />
          </Trans>
        )
      })
    }
  })
}
