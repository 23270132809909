import React from 'react'

import { ChatContextProvider } from './context/chat-context'
import { SessionContextProvider } from './context/session-context'
import ChatBotContainer from './ChatBotContainer'

const ChatBot = React.memo(() => {
  return (
    <ChatContextProvider>
      <SessionContextProvider>
        <ChatBotContainer />
      </SessionContextProvider>
    </ChatContextProvider>
  )
})
export { ChatBot }
