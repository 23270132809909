import { Anchor, Page, PageContent, PageHeader } from 'grommet'
import { Previous } from 'grommet-icons'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '../../../components'
import { del } from '../../../utils/api-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'
import DeleteConfirmation from '../../manage-account/identity/roles/delete-roles/DeleteConfirmation'
import DeleteRoles from '../../manage-account/identity/roles/delete-roles/DeleteRoles'
import { Layout } from '../layout/Layout'
import Markdown from '../Markdown'
import VisibilityWrapper from '../visibility-wrapper/VisibilityWrapper'

import CreateRoleModal from './create-role/create-role-modal/CreateRoleModal'
import DataTable from './RolesDataTable'

const Roles = ({ inCCSManager }) => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [rolesToDelete, setRolesToDelete] = useState([])
  const [deleteDlgOpen, setDeleteDlgOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [showNotification, setShowNotification] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [successTitle, setSuccessTitle] = useState(null)

  const history = useHistory()
  const location = useLocation()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authz', 'common', 'iam'])
  const LDFlags = useFlags()
  const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']

  // Allow HPE theme styling to spread onto Typography instance
  const {
    pageHeader: { subtitle }
  } = useContext(ThemeContext)

  const custAccountLoaded = getCustomerAccount()
  const platformCid = custAccountLoaded?.platform_customer_id || ''

  const handleBackBtnClick = () => {
    history.push(
      `${
        inCCSManager && saCustomRoleLDFlag
          ? '/manage-ccs/identity'
          : '/manage-account/identity'
      }`
    )
  }

  const deleteSelectedRoles = (recordsToDelete) => {
    setConfirmOpen(false)
    setShowNotification(null)

    /**
     * TODO: currently only single object delete is allowed
     */
    if (recordsToDelete[0].predefined !== true && platformCid) {
      const url = `/authorization/ui/v1/customers/${platformCid}/applications/${recordsToDelete[0].application_id}/roles/${recordsToDelete[0].slug}`
      del(url, {}, oidcUser.access_token).then(
        () => {
          setShowNotification(
            displayNotification(
              t('roles.del_progress_notification'),
              'info',
              setShowNotification
            )
          )
        },
        (error) => {
          setShowNotification(displayApiError(error, t, setShowNotification))
        }
      )
      setRolesToDelete([])
    } else {
      setShowNotification(
        displayNotification(
          t('roles.del_error_notification'),
          'error',
          setShowNotification
        )
      )
    }
  }

  useEffect(() => {
    setSuccessTitle(location?.state?.notificationTitle || null)
    const message = location?.state?.notificationMessage ? (
      <Markdown>{location?.state?.notificationMessage}</Markdown>
    ) : null
    setSuccessMessage(message)
    window.history.replaceState({}, '')
  }, [location])

  return (
    <Page data-testid="roles-card">
      <PageContent>
        {showNotification !== null && showNotification}
        {deleteDlgOpen && (
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/authorization',
              permission: 'ccs.authorization.edit'
            }}
          >
            <DeleteRoles
              setOpen={setDeleteDlgOpen}
              select={rolesToDelete}
              setConfirm={setConfirmOpen}
            />
          </VisibilityWrapper>
        )}
        {confirmOpen && (
          <DeleteConfirmation
            setConfirm={setConfirmOpen}
            select={rolesToDelete}
            deleteSelectedRoles={deleteSelectedRoles}
          />
        )}
        {createDialogOpen && (
          <CreateRoleModal
            inCCSManager={inCCSManager}
            setOpen={setCreateDialogOpen}
          />
        )}
        <PageHeader
          responsive
          parent={
            <Anchor
              label={t('iam:workspace_identity.title', {
                Workspace: t('common:business_object:wkspc_capitalized')
              })}
              icon={<Previous />}
              onClick={handleBackBtnClick}
              data-testId="identity-title"
            />
          }
          pad={{ top: 'medium' }}
          title={
            <Typography level="1" type="heading" testId="page-title">
              {t('roles_permissions_title')}
            </Typography>
          }
          subtitle={
            <Typography
              type="paragraph"
              testId="page-description"
              {...subtitle}
            >
              {t('roles.roles_subtitle')}
            </Typography>
          }
          actions={
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/authorization',
                permission: 'ccs.authorization.edit'
              }}
              hideFor={{ account: ['TENANT'] }}
            >
              <Button
                style={{ whiteSpace: 'nowrap' }}
                primary
                label={t('roles.create_role')}
                onClick={() => {
                  setCreateDialogOpen(true)
                }}
                testId="roles-create-btn"
              />
            </VisibilityWrapper>
          }
        />
        <DataTable
          rolesToDelete={rolesToDelete}
          inCCSManager={inCCSManager}
          setRolesToDelete={setRolesToDelete}
          setDeleteDlgOpen={setDeleteDlgOpen}
        />
        {successMessage &&
          displayNotification(
            successMessage,
            'info',
            setSuccessMessage,
            successTitle
          )}
      </PageContent>
    </Page>
  )
}

const RolesPage = ({ inCCSManager }) => {
  const LDFlags = useFlags()
  const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']

  return inCCSManager && saCustomRoleLDFlag ? (
    <Roles inCCSManager={inCCSManager} align="start" justify="start" />
  ) : (
    <Layout>
      <Roles align="start" justify="start" />
    </Layout>
  )
}

Roles.propTypes = {
  inCCSManager: PropTypes.bool
}

Roles.defaultProps = {
  inCCSManager: false
}

RolesPage.propTypes = {
  inCCSManager: PropTypes.bool
}

RolesPage.defaultProps = {
  inCCSManager: false
}

export default RolesPage
