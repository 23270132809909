import { validateEmail } from '../../../../../../../../utils/validation-utils'

export const validateContactFields = (contactDetails, i18nTranslate) => {
  const requiredFields = ['emailAddress', 'firstName', 'lastName', 'country']
  const errorObj = {}
  requiredFields.forEach((k) => {
    if (!contactDetails[k]) {
      errorObj[k] = i18nTranslate('Required')
    }
  })

  const isValidEmail = validateEmail(contactDetails.emailAddress)
  if (isValidEmail !== true) {
    errorObj.emailAddress = isValidEmail
  }
  return errorObj
}

export const getContactInformationUpdater = (
  contactType,
  formValues,
  setFormValues
) => {
  const currentContactDetails = {
    soldTo: formValues.soldToContactDetails,
    billTo: formValues.billToContactDetails,
    payer: formValues.payerContactDetails
  }
  const contactDetailsKeyMap = {
    soldTo: 'soldToContactDetails',
    billTo: 'billToContactDetails',
    payer: 'payerContactDetails'
  }

  const contactDetailsKey = contactDetailsKeyMap[contactType]
  const currentDetails = currentContactDetails[contactType] || {}
  return (key, value) => {
    currentDetails[key] = value
    setFormValues({
      ...formValues,
      [contactDetailsKey]: currentDetails
    })
  }
}

export const getContactDetails = (contactType, formValues) => {
  const contactDetailsKeyMap = {
    soldTo: 'soldToContactDetails',
    billTo: 'billToContactDetails',
    payer: 'payerContactDetails'
  }

  return formValues[contactDetailsKeyMap[contactType]]
}
