// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React, Suspense, lazy } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import { checkGotoChooseAccount } from '../../utils/common-utils'
import LazyLoading from '../commoncomponents/LazyLoading/LazyLoading'

import Preferences from './components/preferences/Preferences'
import useFetchIsCCSManager from './custom-hooks/useFetchIsCCSManager'

const NotificationLogs = lazy(() =>
  import('./NotificationLogs/NotificationLogs')
)

const NotificationPage = lazy(() => import('./Notifications'))
const NotificationDetails = lazy(() =>
  import('./components/NotificationDetails')
)
const EventDetails = lazy(() =>
  import('./NotificationLogs/DetailsPage/EventDetails')
)

const NotificationRouter = () => {
  const history = useHistory()
  checkGotoChooseAccount(history)
  const { isCCSManager, isLoading } = useFetchIsCCSManager()

  return (
    <Suspense fallback={<LazyLoading />}>
      {isLoading && <LazyLoading />}
      {!isLoading && (
        <Switch>
          {isCCSManager && <Redirect to="/manage-ccs/notifications" />}
          <Route exact path="/notifications/preferences">
            <Preferences />
          </Route>
          <Route exact path="/notifications/email-preferences">
            <NotificationPage />
          </Route>
          <Route exact path="/notifications/:id">
            <NotificationDetails />
          </Route>
          <Route exact path="/notifications/alerts/:id">
            <EventDetails />
          </Route>
          <Route exact path="/notifications/tasks/:id">
            <EventDetails />
          </Route>
          <Route exact path="/notification-logs">
            <NotificationLogs />
          </Route>
          <Route exact path="/notifications">
            <NotificationPage />
          </Route>
          <Redirect to="/not-found" />
        </Switch>
      )}
    </Suspense>
  )
}

export default NotificationRouter
