import React, { useContext, memo } from 'react'
import PropTypes from 'prop-types'
import { LinkNext, ShareRounded } from 'grommet-icons'
import { Box, Heading, Text, ResponsiveContext } from 'grommet'
import { useHistory } from 'react-router-dom'

import { Card } from '../../../../components'

const SupportCard = memo(
  ({
    title,
    description,
    testId,
    onClick,
    isLinkExternal,
    isIconInTitle,
    href,
    target
  }) => {
    const size = useContext(ResponsiveContext)
    const router = useHistory()

    const cardPad = {
      xsmall: 'medium',
      small: 'medium'
    }

    const getRoundCard = (breakpoint) => {
      const mapRound = {
        xsmall: 'medium',
        small: 'medium'
      }

      return mapRound[breakpoint] ? mapRound[breakpoint] : 'small'
    }

    const textResponsive = {
      title: {
        sizes: {
          xsmall: '16px',
          small: '16px'
        },
        weight: {
          xsmall: 600,
          small: 600
        }
      },
      description: {
        sizes: {
          xsmall: '16px',
          small: '16px'
        }
      }
    }

    const iconProps = { color: 'brand', size: 'medium' }

    const titleComponent = (
      <Heading
        level="3"
        size={textResponsive.title.sizes[size]}
        testId={`${testId}-title`}
        margin="none"
        weight={textResponsive.title.weight[size]}
      >
        {title}
      </Heading>
    )

    const actionIcon = isLinkExternal ? (
      <ShareRounded {...iconProps} />
    ) : (
      <LinkNext {...iconProps} />
    )

    const renderIconAction = (sizeArg) => {
      const iconActionSizesMap = {
        xsmall: 'medium',
        small: 'medium'
      }

      return (
        <Box
          pad={
            iconActionSizesMap[sizeArg] && { top: iconActionSizesMap[sizeArg] }
          }
        >
          {actionIcon}
        </Box>
      )
    }

    const customContent = (
      // when we don't have description we don't need gap below the title
      <Box fill="horizontal" gap="xsmall">
        {isIconInTitle ? (
          <Box flex justify="between" direction="row">
            {titleComponent}
            {actionIcon}
          </Box>
        ) : (
          titleComponent
        )}

        {description && (
          <Box pad={isIconInTitle ? { right: 'medium' } : 'none'}>
            <Text
              size={textResponsive.description.sizes[size]}
              testId={`${testId}-summary`}
            >
              {description}
            </Text>
          </Box>
        )}
      </Box>
    )

    const handleClick = (e) => {
      if (onClick) {
        onClick(e)
      }

      if (!isLinkExternal) {
        e.preventDefault()
        router.push(href)
      }
    }

    return (
      <Card
        as={href ? 'a' : undefined}
        href={href}
        style={{ textDecoration: 'none', color: 'inherit' }}
        target={isLinkExternal ? target : undefined}
        onClick={handleClick}
        // we use undefined because action: PropTypes.element, it is not expecting to have false or null
        action={
          description && !isIconInTitle ? renderIconAction(size) : undefined
        }
        gap="medium"
        pad={cardPad[size]}
        cardWidth="100%"
        testId={`${testId}-wrapper`}
        // we use undefined because CustomContent: PropTypes.element, it is not expecting to have false or null
        CustomContent={customContent}
        title={titleComponent}
        round={getRoundCard(size)}
        tabIndex="0"
      />
    )
  },
  (old, newS) => JSON.stringify(old) === JSON.stringify(newS)
)

SupportCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func,
  testId: PropTypes.string.isRequired,
  isLinkExternal: PropTypes.bool,
  isIconInTitle: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string
}

SupportCard.defaultProps = {
  isLinkExternal: false,
  description: null,
  isIconInTitle: false,
  href: undefined,
  target: undefined,
  onClick: () => {}
}

export { SupportCard }
