import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

const PortalCustomization = lazy(() => import('./PortalCustomization'))

const PortalCustomizationRouter = () => {
  const { path } = useRouteMatch()
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route exact path={path}>
          <PortalCustomization />
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  )
}

export default PortalCustomizationRouter
