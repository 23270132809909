import {
  Anchor,
  Box,
  Button,
  Grid,
  ResponsiveContext,
  ThemeContext
} from 'grommet'
import { AppsRounded } from 'grommet-icons'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  CATEGORY,
  CENTRAL,
  DATASVS,
  GLC,
  HPECC,
  INFOSIGHT,
  OPSRAMP,
  PRIVATE_CLOUD,
  PRIVATE_CLOUD_BUSINESS,
  PRIVATE_CLOUD_ENTERPRISE,
  SD,
  WELLNESS
} from '../constants'
import { Service } from '../shims/Service'
import { Tabs, Typography, VisibilityWrapper } from '../shims/imports'
import getServiceDefinitions from '../shims/serviceDefinitions'

import FeaturedServicesCard from './FeaturedServicesCard'

const FeaturedServices = ({ services, t, glcpLastAccessed }) => {
  const { global } = useContext(ThemeContext)
  const size = useContext(ResponsiveContext)
  const history = useHistory()
  const LDFlags = useFlags()

  const categories = useMemo(() => {
    // currently only Service Mangers will be shown in recent services
    const recentServiceManagerSlugs = glcpLastAccessed.map(
      ({ serviceSlug }) => serviceSlug
    )

    const recommendedServiceCount = 8
    const {
      MANAGEMENT_AND_GOVERNANCE,
      COMPUTE,
      NETWORKING,
      PRIVATE_CLOUD: PRIVATE_CLOUD_CATEGORY,
      STORAGE,
      WORKLOADS,
      RECOMMENDED
    } = CATEGORY

    // TODO: This logic should be revisited. If we are showing only un provisioned services under featured service section,
    // after user provision all the services this section would be empty?
    const nonProvisionedServices = services.filter((service) => {
      service.regions.every(
        ({ provisionStatus, catalog_visible }) =>
          provisionStatus !== 'PROVISIONED' && catalog_visible
      )
      const notInRecentServices = !recentServiceManagerSlugs.includes(
        service.serviceSlug
      )
      return notInRecentServices
    })

    const serviceSlugs = services.map(({ serviceSlug }) => serviceSlug)

    const createPrivateCloud = () => {
      // routes to private cloud comparison page
      const pceHybridService = new Service({
        name: 'Private Cloud',
        category: PRIVATE_CLOUD_CATEGORY,
        serviceSlug: PRIVATE_CLOUD,
        termsOfServiceUrl: ''
      })

      const serviceDefinitions = getServiceDefinitions(LDFlags)

      const pceService = serviceDefinitions.find(
        ({ serviceSlug }) => serviceSlug === PRIVATE_CLOUD_ENTERPRISE
      )

      const pcbeService = serviceDefinitions.find(
        ({ serviceSlug }) => serviceSlug === PRIVATE_CLOUD_BUSINESS
      )

      const resolveDetailsPath = () => {
        const detailsPathResolver = [
          [
            serviceSlugs.includes(GLC) && serviceSlugs.includes(DATASVS), // GLC & Storage
            pceHybridService.detailsPath
          ],
          [
            serviceSlugs.includes(GLC) && !serviceSlugs.includes(DATASVS), // GLC only
            pceService?.detailsPath
          ],
          [
            !serviceSlugs.includes(GLC) && serviceSlugs.includes(DATASVS), // Storage only
            pcbeService?.detailsPath
          ]
        ]

        const [, detailsPath] =
          detailsPathResolver.find(([predicate]) => predicate) || []

        return detailsPath
      }

      pceHybridService.detailsPath = resolveDetailsPath()

      // If Private Cloud is navigable, then add the card
      return pceHybridService.detailsPath ? [pceHybridService] : []
    }

    /**
     * Organizing services by serviceSlug for recommended services,
     * as well as insert intertitial cards for Private Cloud
     */
    const serviceBySlug = keyBy(
      [
        ...nonProvisionedServices,
        // only add private cloud when GLC is available
        ...createPrivateCloud()
      ],
      'serviceSlug'
    )

    const serviceByCategory = groupBy(nonProvisionedServices, 'category')

    /**
     * Creating an array of services based on the dictionary definition below.
     * Updating the array will update the order of services in the Recommeded
     * Services tab.
     */
    const recommendedServices = [
      CENTRAL,
      DATASVS,
      HPECC,
      GLC,
      PRIVATE_CLOUD,
      OPSRAMP,
      WELLNESS,
      SD,
      INFOSIGHT
    ]
      .map((slug) => serviceBySlug[slug]) // Pull the service by slug
      .filter((service) => service) // Filter out unrepresented services (i.e.: provisioned)
      .slice(0, recommendedServiceCount) // Will only render 8 recommended services

    // Creating an arbitrarily ordered set of categorized services.
    const categorizedServices = [
      PRIVATE_CLOUD_CATEGORY,
      COMPUTE,
      STORAGE,
      NETWORKING,
      WORKLOADS,
      MANAGEMENT_AND_GOVERNANCE
    ]
      .map((slug) => [slug, serviceByCategory[slug]]) // Pull services by category
      .filter(([, serviceObjects]) => serviceObjects) // Filter out unrepresented categories (i.e.: provisioned)

    // Only show recommended services tab when there are services available
    const recommendedServicesTab = recommendedServices.length
      ? [[RECOMMENDED, recommendedServices]]
      : []

    return [...recommendedServicesTab, ...categorizedServices]
  }, [glcpLastAccessed, services, LDFlags])

  const grid = {
    columns: {
      small: {
        count: 2,
        size: 'auto'
      },
      medium: {
        count: 3,
        size: 'auto'
      },
      large: {
        count: 3,
        size: 'auto'
      },
      xlarge: {
        count: 4,
        size: 'auto'
      }
    },
    rows: ['auto'],
    gap: {
      small: 'medium',
      medium: 'medium',
      large: 'medium',
      xlarge: 'medium'
    }
  }

  // TODO: Remove logic and functionality associated with resizeObserver and 'resize' event dispatch
  // when the Grommet Tabs component is updated to support the use case of injected tabs which update
  // after the  useLayoutEffect hook lifecycle.

  const tabs = categories.map(([category, _services], i) => {
    return (
      _services && {
        id: i,
        label: `${t(`common.category.${category}`)} (${_services.length})`,
        content: (
          <Grid
            rows={['auto']}
            columns={grid.columns[size]}
            gap={grid.gap[size]}
            pad={{ top: 'medium' }}
          >
            {services &&
              _services.map((service) => (
                <FeaturedServicesCard
                  service={service}
                  key={`${category}-${service.serviceSlug}`}
                  t={t}
                />
              ))}
          </Grid>
        ),
        testId: `${category}-tab`
      }
    )
  })

  return (
    <Box margin={{ bottom: 'large' }}>
      <VisibilityWrapper
        rbac={{
          resource: '/ccs/app-provision/provisions',
          permission: 'ccs.app-provision.view'
        }}
      >
        <Box
          direction="row"
          justify="between"
          align="center"
          margin={{ top: 'none', bottom: 'large' }}
        >
          <Typography level={2} type="heading" margin="none">
            {t('dashboard:dashboard.featured_services')}
          </Typography>
          <Anchor
            href="/services/service-catalog"
            label={t('dashboard:common.view_catalog')}
            plain
            onClick={(e) => {
              e.preventDefault()
              history.push('/services/service-catalog')
            }}
          />
        </Box>
        {tabs.length === 0 ? (
          <Box
            align="center"
            width="50%"
            margin={{ horizontal: 'auto' }}
            gap="medium"
            testId="dashboard-empty-featured-tabs"
          >
            <Tabs tabsList={tabs} testId="dashboard-empty-featured-tab" />
            <Box align="center" gap="small">
              <AppsRounded size="xxlarge" color={global.colors.green.dark} />
              <Typography
                type="paragraph"
                size="large"
                textAlign="center"
                margin="none"
              >
                {t('dashboard:dashboard.featured_services_empty')}
              </Typography>
            </Box>
            <Button
              label={t('dashboard:my_services.title')}
              primary
              onClick={() => history.push('/services/my-services')}
            />
          </Box>
        ) : (
          <Tabs tabsList={tabs} testId="dashboard-featured-tabs" />
        )}
      </VisibilityWrapper>
    </Box>
  )
}

FeaturedServices.propTypes = {
  services: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  glcpLastAccessed: PropTypes.array.isRequired
}

export default FeaturedServices
