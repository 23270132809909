import { Box } from 'grommet'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../components'
import Markdown from '../../commoncomponents/Markdown'

const RemoveUserFromGroupConfirmation = ({
  loading,
  onSetOpen,
  user,
  userGroupToRemove,
  handleRemove
}) => {
  const closeModal = () => onSetOpen(false)
  const { t } = useTranslation(['common', 'iam'])

  return (
    <ModalDialog
      header={
        loading ? null : (
          <ModalHeader
            title={
              <Typography
                type="heading"
                level="2"
                testId="remove-user-from-group-confirm-heading"
              >
                {t('iam:igc_users.remove_user_from_group_modal_title')}
              </Typography>
            }
          />
        )
      }
      content={
        loading ? (
          <Box
            fill
            align="center"
            justify="center"
            pad={{ vertical: 'small', horizontal: 'large' }}
          >
            <Loader
              label={t('common:removing')}
              testId="remove-user-from-group-loader"
            />
          </Box>
        ) : (
          <Box gap="small" margin={{ top: 'xsmall' }}>
            <Markdown>
              {t('iam:igc_users.remove_user_from_group_modal_first_subtitle', {
                username: user?.displayName || user?.userName,
                groupName: userGroupToRemove?.displayName
              })}
            </Markdown>
            <Typography type="text">
              {t('iam:igc_users.remove_user_from_group_modal_second_subtitle')}
            </Typography>
          </Box>
        )
      }
      footer={
        loading ? null : (
          <ModalFooter
            right={
              <Box direction="row" gap="small">
                <Button
                  label={t('common:cancel')}
                  onClick={closeModal}
                  testId="cancel-btn"
                />
                <Button
                  label={t(
                    'iam:igc_users.remove_user_from_group_modal_remove_btn'
                  )}
                  primary
                  onClick={() => {
                    handleRemove(userGroupToRemove)
                    closeModal()
                  }}
                  testId="remove-user-from-group-btn"
                />
              </Box>
            }
          />
        )
      }
      onClose={closeModal}
      onClickOutside={closeModal}
      width={loading ? 'small' : 'large'}
      testId="remove-group-modal-dialog"
    />
  )
}

RemoveUserFromGroupConfirmation.propTypes = {
  /**
   * loading flag
   */
  loading: PropTypes.bool,

  /**
   * Callback function to close modal
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * User details
   */
  user: PropTypes.object.isRequired,

  /**
   * User group to remove
   */
  userGroupToRemove: PropTypes.object.isRequired,

  /**
   * Handle remove for user group
   */
  handleRemove: PropTypes.func.isRequired
}

RemoveUserFromGroupConfirmation.defaultProps = {
  loading: false
}

export default RemoveUserFromGroupConfirmation
