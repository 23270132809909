import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'

import {
  Button,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../../components'

const DeleteRoles = ({ setOpen, select, setConfirm }) => {
  const { t } = useTranslation(['authz', 'common'])

  const closeModal = () => {
    setOpen(false)
  }

  const openConfirmation = () => {
    setOpen(false)
    setConfirm(true)
  }

  return (
    <>
      <ModalDialog
        content={
          <Typography
            type="text"
            margin={{ top: '15px' }}
            style={{ maxHeight: '250px', overflow: 'auto' }}
            testId="t1"
          >
            <Trans i18nKey="roles.del_role_msg" count={select.length} t={t}>
              You’re about to delete the{' '}
              <strong>
                {' '}
                {{
                  roleInfo: select.length === 1 ? select[0].name : select.name
                }}{' '}
              </strong>{' '}
              role. This action cannot be undone.
            </Trans>
          </Typography>
        }
        footer={
          <ModalFooter
            right={
              <Box direction="row" gap="small">
                <Button
                  label={t('common:cancel')}
                  secondary
                  onClick={closeModal}
                  testId="secondary-btn"
                />
                <Button
                  onClick={openConfirmation}
                  testId="primary-btn"
                  primary
                  label={t('roles.delete_role', { count: select.length })}
                />
              </Box>
            }
          />
        }
        header={
          <ModalHeader
            subtitle=""
            title={
              <Typography
                size="xxlarge"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
                testId="header-title"
                type="text"
                weight="bold"
              >
                {t('roles.delete_role_hd', { count: select.length })}
              </Typography>
            }
            testId="t1"
          />
        }
        onClose={closeModal}
        width="medium"
        testId="basic-long-content-modal-dialog"
      />
    </>
  )
}

export default DeleteRoles

DeleteRoles.propTypes = {
  setOpen: PropTypes.func,
  select: PropTypes.array,
  setConfirm: PropTypes.func
}
DeleteRoles.defaultProps = {
  setOpen: () => {},
  select: [],
  setConfirm: () => {}
}
