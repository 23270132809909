import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Edit, StatusCritical, Trash } from 'grommet-icons'
// TODO: Uncomment when Reorder comes in place
// import { CaretDownFill, CaretUpFill } from 'grommet-icons'
// import styled from 'styled-components'

import {
  ButtonGroup,
  Typography,
  Notification
} from '../../../../../components'
import { AddRuleModal } from '../add-rule/AddRuleModal'
import { ViewRuleDetails } from '../ViewRuleDetails'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import { DeleteModal } from './DeleteModal'

// TODO: Uncomment when Reorder comes in place
// const StyledButton = styled(Button)`
//   padding: 4px;
//   width: 32px;
//   height: 32px;
//   background: ${(props) => (props.disabled ? '#EFEFEF33' : '#EFEFEF')};
// `

const EditRules = ({
  rulesList,
  folderData,
  refreshRule,
  inCCSManager,
  customerId
}) => {
  const { t } = useTranslation(['device'])
  // TODO: Uncomment when Reorder comes in place
  // const rulesCount = rulesList.length
  const [deleteRuleModal, setDeleteRuleModal] = useState(false)
  const [editRuleModal, setEditRuleModal] = useState(false)
  const [ruleData, setRuleData] = useState({})
  const [deleteData, setDeleteData] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [viewRuleDetails, setViewRuleDetails] = useState(false)

  // TODO: Uncomment when Reorder comes in place
  // const swapRules = (index1, index2) => {
  //   const newList = [...rulesList]
  //   ;[newList[index1], newList[index2]] = [rulesList[index2], rulesList[index1]]
  //   setRulesList(newList)
  // }
  return (
    <Box data-testid="rules-list">
      {rulesList.map((rule, idx) => (
        <Box
          direction="row"
          gap="medium"
          margin={{ bottom: 'medium' }}
          key={idx} /* eslint-disable-line react/no-array-index-key */
          align="center"
        >
          <Box direction="row" justify="between" align="center" width="large">
            <Box
              direction="column"
              onClick={() => {
                const ruleValue = {
                  rule_name: rule.rule_name,
                  rule_id: rule.rule_id
                }
                setRuleData({ ...folderData, ...ruleValue })
                setViewRuleDetails(true)
              }}
            >
              <Typography
                size="medium"
                color="text-strong"
                testId="rule-name"
                type="text"
                weight="bold"
              >
                {rule.rule_name}
              </Typography>
              <Typography
                size="small"
                color="text-weak"
                testId="rule-index"
                type="text"
              >
                {`${t('rule')} ${idx + 1}`}
              </Typography>
            </Box>
            <VisibilityWrapper
              rbac={{
                resource: inCCSManager
                  ? '/ccs/activate/tac'
                  : '/ccs/activate/customer',
                permission: 'ccs.activate.edit'
              }}
            >
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: <Trash size="small" color="text-strong" />,
                    default: true,
                    testId: 'rule-delete-btn',
                    onClick: () => {
                      const ruleValue = {
                        rule_name: rule.rule_name,
                        rule_id: rule.rule_id
                      }
                      setDeleteData({ ...folderData, ...ruleValue })
                      setDeleteRuleModal(true)
                    }
                  },
                  {
                    id: 1,
                    label: <Edit size="small" color="text-strong" />,
                    default: true,
                    testId: 'rule-edit-btn',
                    onClick: () => {
                      const ruleValue = {
                        rule_name: rule.rule_name,
                        rule_id: rule.rule_id
                      }
                      setRuleData({ ...folderData, ...ruleValue })
                      setEditRuleModal(true)
                    }
                  }
                ]}
                testId="rule-action-buttons"
              />
            </VisibilityWrapper>
          </Box>
          {/* TODO: Uncomment when Reorder comes in place
          <Box direction="column" gap="xsmall">
            <StyledButton
              default
              icon={<CaretUpFill />}
              onClick={() => swapRules(index, index - 1)}
              disabled={index === 0}
              testId="rule-reorder-up-btn"
            />
            <StyledButton
              default
              icon={<CaretDownFill />}
              onClick={() => swapRules(index, index + 1)}
              disabled={index === rulesCount - 1}
              testId="rule-reorder-down-btn"
            />
          </Box> */}
        </Box>
      ))}
      {deleteRuleModal && (
        <DeleteModal
          onSetOpen={setDeleteRuleModal}
          data={deleteData}
          setErrorMessage={setErrorMessage}
          refreshRule={refreshRule}
        />
      )}
      {editRuleModal && (
        <AddRuleModal
          onSetOpen={setEditRuleModal}
          data={ruleData}
          refreshRule={refreshRule}
          inCCSManager={inCCSManager}
          customerId={customerId}
        />
      )}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          icon={<StatusCritical color="text-strong" />}
          onClose={() => setErrorMessage(null)}
          position="top"
          testId="api-notification"
          text={errorMessage}
        />
      )}
      {viewRuleDetails && (
        <ViewRuleDetails
          data={ruleData}
          handleClose={() => setViewRuleDetails(false)}
          inCCSManager={inCCSManager}
          customerId={customerId}
        />
      )}
    </Box>
  )
}

EditRules.propTypes = {
  rulesList: PropTypes.array.isRequired,
  // TODO: Uncomment when Reorder comes in place
  // setRulesList: PropTypes.func.isRequired,
  folderData: PropTypes.shape({
    folder_name: PropTypes.string.isRequired,
    folder_id: PropTypes.string
  }).isRequired,
  refreshRule: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  inCCSManager: PropTypes.bool
}

EditRules.defaultProps = {
  customerId: undefined,
  inCCSManager: undefined
}

export { EditRules }
