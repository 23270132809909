// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { FormField, TextArea, TextInput } from 'grommet'
import styled from 'styled-components'

import { ssoModeEnum } from '../../../../constants'

export const defaultGeneral = {
  general: {
    profileName: '',
    authorizationMethod: ssoModeEnum.AUTHENTICATION_ONLY
  }
}

export const attributeMappingDefaultFormValues = {
  attribute_mapping: {
    email: 'NameId',
    // countryCode: 'Country',
    firstName: 'FirstName',
    lastName: 'LastName',
    ccsAttribute: 'hpe_ccs_attribute'
  }
}

export const defaultFieldsError = {
  entityId: '',
  loginUrl: '',
  logoutUrl: '',
  uploadFile: '',
  signingCert: '',
  metadataFile: '',
  metadataUrl: '',
  validateUrl: '',
  configOptions: ''
}

export const StyledFormField = styled(FormField)`
  & > div {
    border: none;
  }
`

export const HiddenInput = styled.input.attrs(() => ({
  type: 'file',
  id: 'upload-file'
}))`
  opacity: 0;
  position: absolute;
  z-index: -1;
`

export const mbInBytes = 1000000

export const StyledTextArea = styled(TextArea)`
  background: rgba(0, 0, 0, 0.04);
  color: #444444;
  opacity: 1;
  border: none;
`

export const StyledTextInput = styled(TextInput)`
  background: rgba(0, 0, 0, 0.04);
  color: #444444;
  opacity: 1;
  border: none;
`

export const SAML_SSO_SETUP_GUIDE_LINK =
  'https://support.hpe.com/hpesc/public/docDisplay?docId=a00120892en_us&page=GUID-F86AA0D3-D2D7-4B10-A041-6496E97D0633.html'

export const handleCopy = (value) => {
  navigator.clipboard.writeText(value)
}

export function generateRandomString(length) {
  const crypto = window.crypto || window.msCrypto
  const array = new Uint32Array(length)
  crypto.getRandomValues(array)
  return Array.from(array, (byte) =>
    String.fromCharCode((byte % 36) + (byte % 36 < 10 ? 48 : 87))
  ).join('')
}
