import React from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'

import Webhooks from './Webhooks'

const WebhookRouter = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path}>
        <Webhooks />
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default WebhookRouter
