/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { Box, DataTable, Text, Spinner } from 'grommet'
import { CircleInformation } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { NoDataInfo } from '../../../components'

import StatusIcon from './StatusIcon'
import RecentDeliveriesModal from './RecentDeliveriesModal'

const RecentDeliveryInfo = ({ deliveryInfo }) => {
  const { t } = useTranslation(['automations'])
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedDelivery, setSelectedDelivery] = useState(null)

  useEffect(() => {
    if (deliveryInfo) {
      setIsLoading(false)
    }
  }, [deliveryInfo])

  const columns = [
    {
      property: 'httpResponseCode',
      header: t('automations:webhooks.response'),
      primary: true,
      render: (datum) => (
        <Box direction="row" align="center" gap="xsmall">
          <StatusIcon status="ERROR" />
          <Text data-testid="httpsResponseCode">{datum.httpResponseCode}</Text>
        </Box>
      )
    },
    {
      property: 'createdAt',
      header: t('automations:webhooks.date'),
      render: (datum) =>
        datum.createdAt ? (
          <Text data-testid="createdAt">
            {dayjs(datum.createdAt).format('MM/DD/YYYY, hh:mm:ss A')}
          </Text>
        ) : (
          'N/A'
        )
    }
  ]

  const onRowClick = (event) => {
    setSelectedDelivery(event.datum)
    setIsOpen(true)
  }

  const closePopup = () => {
    setIsOpen(false)
  }

  const renderContent = () => {
    return deliveryInfo && deliveryInfo?.length > 0 ? (
      <Box width="medium" height="auto">
        <DataTable
          columns={columns}
          data={deliveryInfo}
          onClickRow={onRowClick}
          data-testid="events-recent-del-table"
        />
      </Box>
    ) : (
      <Box width="large" height="small" justify="center">
        <NoDataInfo
          icon={<CircleInformation color="black" size="large" />}
          title={t('automations:webhooks.no_recent_deliveries')}
          gap="xsmall"
          gapTitleSubtitle="xsmall"
          testId="no-delivery-info"
        />
      </Box>
    )
  }

  return (
    <Box>
      {isLoading ? <Spinner /> : renderContent()}

      {isOpen && selectedDelivery && (
        <RecentDeliveriesModal
          onSetOpen={setIsOpen}
          onClosePopup={closePopup}
          deliveryInfo={[selectedDelivery]}
        />
      )}
    </Box>
  )
}

export default RecentDeliveryInfo
