import React from 'react'
import { Box } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  Typography,
  ModalHeader,
  ModalFooter,
  ButtonGroup
} from '../../../components'
import {
  getMSPCustomerAccount,
  isInventoryOwnedWorkspace,
  setCustomerAccount
} from '../../../utils/feature-flag-utils'
import {
  getWorkspaceString,
  WKSPC,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'

const UnassignDevicesModal = ({
  handleCancel,
  handleViewAssignedDevices,
  assignedDeviceCount
}) => {
  const { t } = useTranslation(['apps', 'common'])
  const swappedMSPAccount = getMSPCustomerAccount()
  const history = useHistory()
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const handleReturnToMSPClick = () => {
    setCustomerAccount(swappedMSPAccount)
    sessionStorage.removeItem('swapMspAccount')
    history.push('/onboarding/choose-account')
  }

  const isMspManagedTenant = () => {
    return swappedMSPAccount && !isInventoryOwnedWorkspace()
  }
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              testId="modal-title"
              type="heading"
              level="1"
              weight="bold"
            >
              {t('unassign_devices')}
            </Typography>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <Typography type="text" size="medium">
            <Trans
              i18nKey={
                isMspManagedTenant()
                  ? 'detected_tenant_devices_msp'
                  : 'detected_devices'
              }
              t={t}
              assignedDeviceCount={assignedDeviceCount}
            >
              {{
                workspace: getWorkspaceString(showWorkspaceString, t, WKSPC)
              }}
              <strong>{{ assignedDeviceCount }}</strong>
            </Trans>
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: handleCancel
                },
                ...(isMspManagedTenant()
                  ? [
                      {
                        id: 1,
                        label: t('common:nav_bar.return_to_MSP', {
                          workspace: getWorkspaceString(
                            showWorkspaceString,
                            t,
                            WKSPC_CAPITALIZED
                          )
                        }),
                        secondary: true,
                        testId: 'return-to-msp-btn',
                        onClick: handleReturnToMSPClick
                      }
                    ]
                  : [
                      {
                        id: 1,
                        label: t('view_assigned_devices'),
                        primary: true,
                        testId: 'go-to-device-btn',
                        onClick: handleViewAssignedDevices
                      }
                    ])
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={handleCancel}
      testId="remove-apps-modal"
      width="large"
    />
  )
}

UnassignDevicesModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleViewAssignedDevices: PropTypes.func.isRequired,
  assignedDeviceCount: PropTypes.number.isRequired
}

export { UnassignDevicesModal }
