import React from 'react'
import PropTypes from 'prop-types'
import { Tabs as GrommetTabs, Tab } from 'grommet'
import { Archive, Flag, Inbox } from 'grommet-icons'

const Tabs = ({ setTab }) => {
  return (
    <GrommetTabs justify="start" data-testid="tab-row">
      <Tab title="Inbox" icon={<Inbox />} onClick={() => setTab('inbox')} />
      <Tab title="Flagged" icon={<Flag />} onClick={() => setTab('flagged')} />
      <Tab
        title="Archived"
        icon={<Archive />}
        onClick={() => setTab('archived')}
      />
    </GrommetTabs>
  )
}

Tabs.propTypes = {
  setTab: PropTypes.func.isRequired
}

export default Tabs
