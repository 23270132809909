import { useReducer } from 'react'

import AppCatalogActions from './AppCatalogActions'

const initialContext = {
  appDetailData: null,
  currRegion: '',
  regionListData: []
}

const AppCatalogReducer = (state, { type, data }) => {
  switch (type) {
    case AppCatalogActions.SET_APP_DETAIL:
      return { ...state, appDetailData: data }
    case AppCatalogActions.SET_CURR_REGION:
      return { ...state, currRegion: data }
    case AppCatalogActions.CLEAR_CURR_REGION:
      return { ...state, currRegion: '' }
    case AppCatalogActions.SET_REGION_LIST:
      return { ...state, regionListData: data }
    case AppCatalogActions.CLEAR_APP_DETAIL:
      return { ...state, appDetailData: null }
    default:
      return state
  }
}

const useAppCatalogReducer = () => {
  const currentApp = JSON.parse(sessionStorage.getItem('app'))

  if (currentApp !== null) {
    initialContext.appDetailData = currentApp
  }
  const [CatalogState, dispatchAppCatalogContext] = useReducer(
    AppCatalogReducer,
    initialContext
  )
  return { CatalogState, dispatchAppCatalogContext }
}

export default useAppCatalogReducer
