import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, Notification } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ButtonGroup
} from '../../../../../components'
import {
  getWorkspaceString,
  WKSPC_PLURAL
} from '../../../../../utils/common-utils'

const AcknowledgeDomainModal = ({
  onSetOpen,
  domainName,
  claimedAccountsCount,
  handleAcknowledgeAndContinue
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const handleCancel = () => {
    onSetOpen(false)
  }

  return (
    <Box>
      <ModalDialog
        width="medium"
        testId="acknowledge-domain-modal"
        content={
          <Box gap="medium">
            <Box gap="xsmall">
              <Typography type="text" size="small">
                {t('saml_sso.add_saml_connection')}
              </Typography>
              <Typography type="heading" level={2}>
                {t('saml_sso.claim_domain')}
              </Typography>
            </Box>
            <Notification
              status="info"
              message={
                <Typography type="text" testId="claimed-domain">
                  {t('saml_sso.domain_claimed_accounts', {
                    domain: domainName,
                    claimedAccountsCount,
                    accounts: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_PLURAL
                    )
                  })}
                </Typography>
              }
            />
            <Box>
              <Typography type="text">
                {t('saml_sso.sso_connection_steps')}
              </Typography>
              <ul>
                <li>{t('saml_sso.sso_connection_step_1')}</li>
                <li>{t('saml_sso.sso_connection_step_2')}</li>
              </ul>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('saml_sso.cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    onClick: handleCancel
                  },
                  {
                    id: 1,
                    label: t('saml_sso.acknowledge_and_continue'),
                    primary: true,
                    testId: 'acknowledge-continue-btn',
                    onClick: () => handleAcknowledgeAndContinue()
                  }
                ]}
                testId="two-buttons"
              />
            }
          />
        }
        onClose={() => onSetOpen(false)}
      />
    </Box>
  )
}

AcknowledgeDomainModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  domainName: PropTypes.string.isRequired,
  claimedAccountsCount: PropTypes.number.isRequired,
  handleAcknowledgeAndContinue: PropTypes.func.isRequired
}

export { AcknowledgeDomainModal }
