import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, TextInput } from 'grommet'
import debounce from 'lodash/debounce'
import { Search } from 'grommet-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { displayApiError } from '../../../../../../utils/error-handling-utils'
import { DataTable, ActionButton } from '../../../../../../components'
import { getPaginationShowIdx } from '../../../../../../utils/common-utils'
import { getLocations } from '../../../../utils'

const itemsPerPage = 20

const LocationsDataTable = ({ customerId }) => {
  const history = useHistory()
  const { t } = useTranslation(['common', 'location'])
  const { oidcUser } = useReactOidc()
  const [locationList, setLocationList] = useState([])
  const [query, setQuery] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(null)

  const [totalCount, setTotalCount] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalCount, itemsPerPage, t)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchValue = useCallback(
    debounce((value) => {
      setQuery(value)
    }, 300),
    []
  )
  const fetchingLocations = useCallback(() => {
    getLocations(
      {
        page_size: itemsPerPage,
        page_id: page,
        search_string: query,
        platform_customer_id: customerId
      },
      oidcUser.access_token,
      (response) => {
        if (response?.data?.pagination?.total_count > 0) {
          setTotalCount(response?.data?.pagination?.total_count)
          setLocationList(response?.data?.data)
        } else {
          setTotalCount(0)
          setLocationList([])
        }
      },
      (error) => {
        setLocationList([])
        setTotalCount(0)
        setShowErrorMessage(displayApiError(error, t, setShowErrorMessage))
      } // setLoading
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, query, page, t, customerId])

  useEffect(() => {
    fetchingLocations()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page])

  const columns = [
    {
      property: 'name',
      type: 'string',
      header: t('location:name')
    },
    {
      property: 'city',
      type: 'string',
      header: t('location:city')
    },
    {
      property: 'country',
      type: 'string',
      header: t('location:country')
    },
    {
      property: '',
      header: '',
      render: (datum) => (
        <ActionButton
          actions={[
            {
              label: t('location:view'),
              testId: 'location-view-btn',
              onClick: () => {
                history.push({
                  pathname: `/manage-ccs/customers/locations/${datum.id}`,
                  state: { customerId }
                })
              }
            }
          ]}
          dropAlign={{
            left: 'left',
            top: 'bottom'
          }}
          showOneActionAsDropDown
          testId="location-action-btn"
        />
      )
    }
  ]

  return (
    <Box pad={{ horizontal: 'xxsmall', top: 'small' }}>
      <Box flex={{ shrink: 0 }} width="medium">
        <TextInput
          icon={<Search />}
          placeholder={t('location:location_search')}
          onChange={(event) => handleDebouncedSearchValue(event.target.value)}
          data-testid="location-search-filter"
        />
      </Box>
      <DataTable
        pagination={{
          totalItems: totalCount,
          itemsPerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        grid={{
          columns,
          data: locationList
        }}
        summary={{
          entityName: t('location:location_title')
        }}
        testId="locations-table"
      />
      {showErrorMessage}
    </Box>
  )
}

LocationsDataTable.propTypes = {
  customerId: PropTypes.string.isRequired
}

export { LocationsDataTable }
