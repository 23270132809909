import { CircleInformation } from 'grommet-icons'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Loader, NoDataInfo } from '../../../components'
import { useVisibilityContext } from '../../../context/visibility-context'

const NoViewPermission = () => {
  const { t } = useTranslation(['iam'])
  const { v2RbacPolicies } = useVisibilityContext()

  return (
    <Box alignSelf="center" width="medium">
      {v2RbacPolicies ? (
        <NoDataInfo
          title={t('iam:igc_general.no_perm_title')}
          titleHeadingLevel={2}
          subtitle={t('iam:igc_general.no_perm_desc')}
          icon={<CircleInformation size="xlarge" />}
          testId="no-user-read-perm-warning"
        />
      ) : (
        <Box direction="row" align="center" justify="center">
          <Loader testId="no-permission-loader" />
        </Box>
      )}
    </Box>
  )
}

export default NoViewPermission
