import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Page, PageContent, PageHeader } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Button } from '../../components'
import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { displayNotification } from '../../utils/notificiation-utils'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED,
  WKSPC_PLURAL,
  WKSPC_PLURAL_CAPITALIZED
} from '../../utils/common-utils'
import { isGLOP } from '../../utils/feature-flag-utils'

import {
  AddCustomerModal,
  CustomerDatatable,
  CreateCustomerWorkspace
} from './components'

const CustomerAccount = () => {
  const { t } = useTranslation(['authn'])
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false)
  const [successNotification, setSuccessNotification] = useState(null)
  const [refreshCount, setRefreshCount] = useState(0)
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const mspCustomerOwnLd = LDFlags['glcp-msp-customer-own']

  return (
    <Page>
      <PageContent>
        <Box gap="xsmall" margin={{ bottom: 'medium', top: 'large' }}>
          <PageHeader
            responsive
            pad={{ vertical: 'none' }}
            title={t('customer_account.title', {
              accounts: getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_PLURAL_CAPITALIZED
              )
            })}
            subtitle={t('customer_account.subtitle', {
              accounts: getWorkspaceString(showWorkspaceString, t, WKSPC_PLURAL)
            })}
            actions={
              <VisibilityWrapper
                rbac={{
                  resource: '/ccs/accounts/platform/customer',
                  permission: 'ccs.accounts.platform.customer.edit'
                }}
              >
                <Button
                  primary
                  label={
                    mspCustomerOwnLd || isGLOP()
                      ? t('customer_account.create_customer_account', {
                          account: getWorkspaceString(
                            showWorkspaceString,
                            t,
                            WKSPC_CAPITALIZED
                          )
                        })
                      : t('customer_account.addCustomerButton')
                  }
                  onClick={() => setShowCreateCustomerModal(true)}
                  testId="add-customer-account-button"
                />
              </VisibilityWrapper>
            }
            data-testid="customer-account-title"
          />
        </Box>
        <CustomerDatatable refreshCount={refreshCount} />
        {showCreateCustomerModal &&
          (mspCustomerOwnLd ? (
            <CreateCustomerWorkspace
              onSetOpen={setShowCreateCustomerModal}
              onSuccess={() => {
                setShowCreateCustomerModal(false)
                setSuccessNotification(
                  t('customer_account.customer_added_message')
                )
                setRefreshCount(refreshCount + 1)
              }}
            />
          ) : (
            <AddCustomerModal
              setShowCreateCustomerModal={setShowCreateCustomerModal}
              onSuccess={() => {
                setShowCreateCustomerModal(false)
                setSuccessNotification(
                  t('customer_account.customer_added_message')
                )
                setRefreshCount(refreshCount + 1)
              }}
            />
          ))}
        {successNotification &&
          displayNotification(
            successNotification,
            'info',
            setSuccessNotification
          )}
      </PageContent>
    </Page>
  )
}

export default CustomerAccount
