// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react'
import { Box, Heading, Markdown, PageHeader } from 'grommet'
import { CircleInformation, Previous } from 'grommet-icons'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { debounce } from 'lodash'

import {
  Anchor,
  Button,
  DataTable,
  NoDataInfo,
  Typography
} from '../../../components'
import { Layout } from '../../commoncomponents/layout/Layout'
import { get, getErrorMessage } from '../../../utils/api-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import { useVisibilityContext } from '../../../context/visibility-context'
import NoViewPermission from '../commoncomponents/NoViewPermission'
import {
  getOrganizationId,
  isAssociateWorkspace
} from '../../../utils/feature-flag-utils'

import { getAuthorizationText } from './constants'

const SsoProfiles = () => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation(['common', 'iam'])
  const [loading, setLoading] = useState(false)
  const [refreshCount] = useState(0)
  const [ssoProfilesData, setSsoProfilesData] = useState({ items: [] })
  const [domainsData, setDomainsData] = useState({ items: [] })

  // Query parameters
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [page, setPage] = useState(1)
  const [name, setName] = useState('')
  const [sort, setSort] = useState('name asc')

  // Notifications
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')
  const setNotificationInfo = (message, severity, title = '') => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  // Permissions
  const { v2RbacPolicies, v2RbacRootWkspcPolicies } = useVisibilityContext()
  const v2SsoProfilesReadPerm =
    v2RbacPolicies?.includes('identity.sso-profiles.read') &&
    v2RbacPolicies?.includes('identity.sso-routing-rules.read')
  const v2SsoProfilesCreatePerm =
    v2RbacPolicies?.includes('identity.sso-profiles.create') &&
    v2RbacPolicies?.includes('identity.sso-routing-rules.create')
  const v2AssociateSsoProfilesReadPerm =
    v2RbacRootWkspcPolicies?.includes('identity.sso-profiles.read') &&
    v2RbacRootWkspcPolicies?.includes('identity.sso-routing-rules.read')
  const v2AssociateSsoProfilesCreatePerm =
    v2RbacRootWkspcPolicies?.includes('identity.sso-profiles.create') &&
    v2RbacRootWkspcPolicies?.includes('identity.sso-routing-rules.create')
  const [isAssociated, setIsAssociated] = useState(false)
  const getPageIndexInfo = () => {
    const total = ssoProfilesData?.total || 0
    if (total > 0) {
      const startIndex = 1 + (page - 1) * itemsPerPage
      const endIndex = itemsPerPage * page < total ? itemsPerPage * page : total
      // TODO: Create translation key for SSO Profiles table
      return t('iam:domains.data_table_showing_label', {
        startIndex,
        endIndex,
        total
      })
    }
    return ''
  }

  const handleBackBtnClick = () => {
    history.push('/manage-account/organization')
  }

  const columns = [
    {
      property: 'name',
      header: t('iam:sso_profiles.column_name'),
      primary: true,
      sortable: true
    },
    {
      property: 'ssoMode',
      header: t('iam:sso_profiles.column_authorization'),
      sortable: false,
      render: (datum) => {
        const ssoMode = datum?.ssoMode
        const authorizationText = getAuthorizationText(ssoMode)
        const text = authorizationText || '--'

        return <Typography type="text">{text}</Typography>
      }
    },
    {
      property: 'domains',
      header: t('iam:sso_profiles.column_domains'),
      sortable: false,
      render: (datum) => {
        const domains = datum?.domains
        const text = domains || '--'
        return <Typography type="text">{text}</Typography>
      }
    }
  ]

  useEffect(() => {
    const orgId = getOrganizationId()
    if (orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (res) => {
          if (res?.data && res?.data?.associatedWorkspace) {
            setIsAssociated(
              isAssociateWorkspace(res?.data?.associatedWorkspace?.id)
            )
          }
        },
        (err) => {
          setNotificationInfo(getErrorMessage(err, t), 'error')
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { notification, notificationError } = location?.state || {}

    const message = notification ? <Markdown>{notification}</Markdown> : null
    const errorMessage = notificationError ? (
      <Markdown>{notificationError}</Markdown>
    ) : null

    if (message) {
      setNotificationInfo(message, 'info')
    } else if (errorMessage) {
      setNotificationInfo(errorMessage, 'error')
    }

    location.state = {
      ...location.state,
      notification: null
    }
  }, [location])

  useEffect(() => {
    let isMounted = true
    setLoading(true)

    const handleSuccess = (response, setData) => {
      if (isMounted && response?.data?.items) {
        setData(response.data)
      }
    }

    const handleError = (error, setData) => {
      if (isMounted) {
        setNotificationInfo(getErrorMessage(error, t), 'error')
        setData({ items: [] })
      }
    }

    const fetchData = async (url, setData) => {
      try {
        const response = await get(url)
        handleSuccess(response, setData)
      } catch (error) {
        handleError(error, setData)
      }
    }

    const buildQueryParams = () => {
      const nameQuery =
        name?.trim()?.length > 0
          ? `&filter=contains(name,'${encodeURIComponent(name.trim())}')`
          : ''
      const sortQuery = sort ? `&sort=${encodeURIComponent(sort)}` : ''
      return `${nameQuery}${sortQuery}`
    }

    const getSsoProfilesData = async () => {
      const queryParams = buildQueryParams()
      const ssoProfilesUrl = `/internal-platform-tenant-ui/v2/sso-profiles?offset=${
        (page - 1) * itemsPerPage
      }&limit=${itemsPerPage}${queryParams}`
      const domainsUrl = `/identity/v1alpha1/domains`

      await fetchData(ssoProfilesUrl, setSsoProfilesData)
      await fetchData(domainsUrl, setDomainsData)
    }

    if (
      (!isAssociated && v2SsoProfilesReadPerm) ||
      (isAssociated && v2AssociateSsoProfilesReadPerm)
    ) {
      getSsoProfilesData().finally(() => {
        if (isMounted) {
          setLoading(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [
    page,
    itemsPerPage,
    name,
    refreshCount,
    sort,
    t,
    v2SsoProfilesReadPerm,
    v2AssociateSsoProfilesReadPerm,
    isAssociated
  ])

  const createSsoProfileLinkSubtitle = () => {
    return (!isAssociated && v2SsoProfilesCreatePerm) ||
      (isAssociated && v2AssociateSsoProfilesCreatePerm)
      ? t('iam:sso_profiles.create_sso_profile_subtitle_v2')
      : t('iam:sso_profiles.create_sso_profile_subtitle')
  }

  const renderCreateSsoProfileLink = () => {
    return (
      <Box width="large">
        <NoDataInfo
          icon={<CircleInformation size="large" />}
          title={t('iam:sso_profiles.no_data_title')}
          subtitle={createSsoProfileLinkSubtitle()}
          gapTitleSubtitle="small"
          action={
            ((isAssociated &&
              v2RbacRootWkspcPolicies?.includes(
                'identity.sso-profiles.create'
              )) ||
              (!isAssociated &&
                v2RbacPolicies?.includes('identity.sso-profiles.create'))) &&
            ((isAssociated &&
              v2RbacRootWkspcPolicies?.includes(
                'identity.sso-routing-rules.create'
              )) ||
              (!isAssociated &&
                v2RbacPolicies?.includes(
                  'identity.sso-routing-rules.create'
                ))) && (
              <Button
                primary
                label={t('iam:sso_profiles.create_sso_profile')}
                onClick={() => {
                  history.push(
                    '/manage-account/organization/sso-profiles/add-sso-profile'
                  )
                }}
                testId="no-data-sso-profile-btn"
              />
            )
          }
          testId="sso-profile-no-data-info"
        />
      </Box>
    )
  }

  const renderDomainCreationLink = () => {
    return (
      <Box width="large">
        <NoDataInfo
          icon={<CircleInformation size="large" />}
          title={t('iam:sso_profiles.no_data_title')}
          subtitle={t('iam:sso_profiles.no_data_subtitle')}
          gap="small"
          gapTitleSubtitle="small"
          action={
            ((isAssociated &&
              v2RbacRootWkspcPolicies?.includes(
                'identity.domain-requests.create'
              )) ||
              (!isAssociated &&
                v2RbacPolicies?.includes(
                  'identity.domain-requests.create'
                ))) && (
              <Button
                primary
                label={t('iam:domains.add_domain')}
                onClick={() => {
                  history.push(
                    '/manage-account/organization/domains/add-domain'
                  )
                }}
                testId="no-data-add-domain-btn"
              />
            )
          }
          testId="sso-profile-no-data-info"
        />
      </Box>
    )
  }

  const debouncedSearchTerm = debounce((newSearchTerm) => {
    setName(newSearchTerm)
    setPage(1)
  }, 500) // Adjust debounce time (ms) as needed

  const handleRowClick = (datum) => {
    history.push(`/manage-account/organization/sso-profiles/${datum.id}`)
  }

  const renderSsoProfilesTable = () => {
    return (
      <div>
        <Box width="large">
          <Typography
            normalize
            type="text"
            size="medium"
            testId="sso-profiles-page-subtitle"
            margin={{ bottom: 'medium' }}
          >
            {t('iam:sso_profiles.subtitle')}
          </Typography>
        </Box>

        <DataTable
          summary={{
            entityName:
              ssoProfilesData?.items?.length === 1
                ? t('common:item_singular')
                : t('common:item_plural')
          }}
          grid={{
            columns,
            data: ssoProfilesData?.items,
            onClickRow: ({ datum }) => handleRowClick(datum)
          }}
          loading={loading}
          search={{
            onSearchValueChange: (value) => {
              setLoading(true)
              debouncedSearchTerm(value)
            },
            placeholder: t('common:search')
          }}
          button={
            <Box align="end" width="medium">
              {((isAssociated &&
                v2RbacRootWkspcPolicies?.includes(
                  'identity.sso-profiles.create'
                )) ||
                (!isAssociated &&
                  v2RbacPolicies?.includes('identity.sso-profiles.create'))) &&
                ((isAssociated &&
                  v2RbacRootWkspcPolicies?.includes(
                    'identity.sso-routing-rules.create'
                  )) ||
                  (!isAssociated &&
                    v2RbacPolicies?.includes(
                      'identity.sso-routing-rules.create'
                    ))) && (
                  <Button
                    secondary
                    label={t('iam:sso_profiles.create_sso_profile')}
                    onClick={() => {
                      history.push(
                        '/manage-account/organization/sso-profiles/add-sso-profile'
                      )
                    }}
                    testId="data-table-add-sso-profile-btn"
                  />
                )}
            </Box>
          }
          pagination={{
            totalItems: ssoProfilesData?.total || 0,
            itemsPerPage,
            setItemsPerPage,
            page,
            setPage,
            rowDropDownLabel: t('iam:domains.row_drop_down_label'),
            pageIdxInfo: getPageIndexInfo(),
            rowCountOptions: [20, 50, 100],
            testId: 'sso-profiles-pagination'
          }}
          onSort={({ property, direction }) => {
            setSort(`${property} ${direction}`)
          }}
          testId="sso-profiles-datatable"
        />
      </div>
    )
  }

  const selectRenderingScenario = () => {
    if (ssoProfilesData?.items?.length !== 0 || name?.length > 0 || loading) {
      return renderSsoProfilesTable()
    }
    if (
      ssoProfilesData?.items?.length === 0 &&
      domainsData?.items?.length === 0 &&
      !loading
    ) {
      return renderDomainCreationLink()
    }
    if (
      ssoProfilesData?.items?.length === 0 &&
      domainsData?.items?.length !== 0 &&
      !loading
    ) {
      return renderCreateSsoProfileLink()
    }
    return null
  }

  return (
    <Box
      data-testid="sso-profiles-page"
      pad={{ horizontal: 'xlarge', bottom: 'large' }}
      width="xxlarge"
      alignSelf="center"
    >
      <PageHeader
        responsive
        title={
          <Heading
            level="1"
            type="heading"
            margin="none"
            data-testid="sso-profiles-page-title"
          >
            {t('iam:sso_profiles.title')}
          </Heading>
        }
        subtitle={
          ((isAssociated &&
            v2RbacRootWkspcPolicies?.includes('identity.sso-profiles.read')) ||
            (!isAssociated &&
              v2RbacPolicies?.includes('identity.sso-profiles.read'))) &&
          ((isAssociated &&
            v2RbacRootWkspcPolicies?.includes(
              'identity.sso-routing-rules.read'
            )) ||
            (!isAssociated &&
              v2RbacPolicies?.includes('identity.sso-routing-rules.read'))) && (
            <Box width="large">
              <Typography
                normalize
                type="text"
                size="large"
                testId="sso-profiles-page-subtitle"
              >
                <Trans i18nKey="iam:domains.sso_profiles_subtitle_text" t={t}>
                  <Anchor
                    label={t('common:learn_more')}
                    weight="bold"
                    href="#"
                    onClick={(event) => {
                      event?.preventDefault()
                      dispatchEvent(new Event('context-help'))
                    }}
                    target="_blank"
                    testId="domain_setup_anchor"
                  />
                </Trans>
              </Typography>
            </Box>
          )
        }
        parent={
          <Anchor
            label={t('iam:organization.governance_title')}
            icon={<Previous />}
            href="#"
            onClick={(event) => {
              event.preventDefault()
              handleBackBtnClick()
            }}
            margin={{ bottom: 'xsmall' }}
            testId="sso-profiles-back-btn"
          />
        }
      />
      {(!isAssociated && v2SsoProfilesReadPerm) ||
      (isAssociated && v2AssociateSsoProfilesReadPerm)
        ? selectRenderingScenario()
        : (v2RbacPolicies || v2RbacRootWkspcPolicies) && <NoViewPermission />}
      {notifMsg &&
        displayNotification(notifMsg, notifSeverity, setNotifMsg, notifTitle)}
    </Box>
  )
}

const SsoProfilesPage = () => {
  return (
    <Layout>
      <SsoProfiles align="start" justify="start" />
    </Layout>
  )
}

export default SsoProfilesPage
