// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { Box } from 'grommet'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import lodashGet from 'lodash/get'

import { ObjectList } from '../../../../../../components'
import { WizardContext } from '../../../../../../components/wizard/WizardContext'
import { renameKeys } from '../../../../../../utils/common-utils'
import { getAuthorizationText, idp, ssoModeEnum } from '../../../constants'

const ReviewAndCreate = () => {
  const { formValues } = useContext(WizardContext)
  const { t } = useTranslation(['iam'])

  const tempI18NKeys = {
    general: {
      profileName: t('iam:sso_profiles.view_edit_create.profile_name'),
      domain: t('iam:sso_profiles.view_edit_create.domain'),
      authorization: t('iam:sso_profiles.view_edit_create.authorization')
    },
    samlAttributes: {
      email: t('iam:sso_profiles.view_edit_create.email_address'),
      first_name: t('iam:sso_profiles.view_edit_create.first_name'),
      last_name: t('iam:sso_profiles.view_edit_create.last_name'),
      ccs_attribute: t('iam:sso_profiles.view_edit_create.ccs_attribute')
    },
    identityProviderConfiguration: {
      entityId: t('iam:sso_profiles.view_edit_create.greenlake_entity_id'),
      acsUrl: t('iam:sso_profiles.view_edit_create.acs_url'),
      defaultRelayState: t(
        'iam:sso_profiles.view_edit_create.default_relay_state'
      )
    },
    greenLakeConfiguration: {
      entity_id: t('iam:sso_profiles.view_edit_create.idp_entity_id'),
      login_url: t('iam:sso_profiles.view_edit_create.login_url'),
      logout_url: t('iam:sso_profiles.view_edit_create.logout_url'),
      signing_certificate: t('iam:sso_profiles.view_edit_create.certificate')
    },
    recoveryUser: {
      recoveryEmail: t('iam:sso_profiles.view_edit_create.recovery_username'),
      pocEmail: t('iam:sso_profiles.view_edit_create.recovery_email')
    },
    sessionTimeout: {
      sessionTimeout: t('iam:sso_profiles.view_edit_create.session_timeout')
    }
  }

  const getGeneralObject = () => {
    return {
      profileName: lodashGet(formValues, 'general.profileName'),
      domain: lodashGet(formValues, 'domain'),
      authorization: getAuthorizationText(
        lodashGet(formValues, 'general.authorizationMethod')
      )
    }
  }

  const getSamlAttributesObject = () => {
    return {
      email: lodashGet(formValues, 'attribute_mapping.email'),
      // country: lodashGet(formValues, 'attribute_mapping.countryCode'),
      first_name: lodashGet(formValues, 'attribute_mapping.firstName'),
      last_name: lodashGet(formValues, 'attribute_mapping.lastName'),
      ccs_attribute:
        lodashGet(formValues, 'general.authorizationMethod') ===
        ssoModeEnum.AUTHORIZATION
          ? lodashGet(formValues, 'attribute_mapping.ccsAttribute')
          : ''
    }
  }

  const getIdentityProviderConfigurationObject = () => {
    return {
      entityId: idp.entityIdUrl,
      acsUrl: idp.acsUrl,
      defaultRelayState: idp.defaultRelayStateUrl
    }
  }

  const getGreenLakeConfigurationObject = () => {
    return {
      entity_id: lodashGet(formValues, 'saml_idp_config.entity_id'),
      login_url: lodashGet(formValues, 'saml_idp_config.login_url'),
      logout_url: lodashGet(formValues, 'saml_idp_config.logout_url'),
      signing_certificate: lodashGet(
        formValues,
        'saml_idp_config.signing_certificate'
      )
    }
  }

  const getRecoveryUserObject = () => {
    return {
      recoveryEmail: lodashGet(formValues, 'recovery_user.recoveryEmail'),
      pocEmail: lodashGet(formValues, 'recovery_user.pocEmail')
    }
  }

  const getSessionTimeoutObject = () => {
    return {
      sessionTimeout: lodashGet(formValues, 'timeout')
    }
  }

  const getFilteredObject = (obj, filteredValues = []) => {
    const filteredObjectValues = {}
    Object.entries(obj).forEach(([key, value]) => {
      if (filteredValues.includes(key)) {
        filteredObjectValues.id = { ...filteredObjectValues.id, [key]: value }
      }
    })
    return filteredObjectValues.id
  }

  const general = renameKeys(
    tempI18NKeys.general,

    getFilteredObject(getGeneralObject(), Object.keys(tempI18NKeys.general))
  )

  const samlAttributes = renameKeys(
    tempI18NKeys.samlAttributes,

    getFilteredObject(
      getSamlAttributesObject(),
      Object.keys(tempI18NKeys.samlAttributes)
    )
  )

  const identityProviderConfiguration = renameKeys(
    tempI18NKeys.identityProviderConfiguration,

    getFilteredObject(
      getIdentityProviderConfigurationObject(),
      Object.keys(tempI18NKeys.identityProviderConfiguration)
    )
  )

  const greenLakeConfiguration = renameKeys(
    tempI18NKeys.greenLakeConfiguration,
    getFilteredObject(
      getGreenLakeConfigurationObject(),
      Object.keys(tempI18NKeys.greenLakeConfiguration)
    )
  )

  const recoveryUser = renameKeys(
    tempI18NKeys.recoveryUser,
    getFilteredObject(
      getRecoveryUserObject(),
      Object.keys(tempI18NKeys.recoveryUser)
    )
  )

  const sessionTimeout = renameKeys(
    tempI18NKeys.sessionTimeout,
    getFilteredObject(
      getSessionTimeoutObject(),
      Object.keys(tempI18NKeys.sessionTimeout)
    )
  )

  return (
    <Box gap="medium" pad={{ bottom: 'medium' }}>
      <ObjectList
        data={general}
        testId="review-general"
        title={t('iam:sso_profiles.view_edit_create.general')}
        showRuler={false}
      />
      <ObjectList
        data={samlAttributes}
        testId="review-saml-attributes"
        title={t('iam:sso_profiles.view_edit_create.saml_attributes')}
        showRuler={false}
      />
      <ObjectList
        data={identityProviderConfiguration}
        testId="review-identity-provider-configuration"
        title={t(
          'iam:sso_profiles.view_edit_create.identity_provider_configuration'
        )}
        showRuler={false}
      />
      <ObjectList
        data={greenLakeConfiguration}
        testId="review-greenlake-configuration"
        title={t('iam:sso_profiles.view_edit_create.greenlake_configuration')}
        showRuler={false}
      />
      <ObjectList
        data={recoveryUser}
        testId="review-recovery-user"
        title="Recovery user"
        showRuler={false}
      />
      <ObjectList
        data={sessionTimeout}
        testId="review-session-timeout"
        title={t('iam:sso_profiles.view_edit_create.session_timeout')}
        showRuler={false}
      />
    </Box>
  )
}

export default ReviewAndCreate
