// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Box, Grid, ResponsiveContext } from 'grommet'
import { useTranslation } from 'react-i18next'
import { groupBy, uniqBy, debounce } from 'lodash'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { FilterButton, Loader } from '../../../../components'
import {
  Layout,
  Dropdown,
  Typography,
  PageHeader,
  isGLOP
} from '../../../shims/imports'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { useServices, useRegions, useAxiosAuth } from '../../../hooks'
import VisibilityWrapper from '../../../../pages/commoncomponents/visibility-wrapper/VisibilityWrapper'
import SideMenu from '../../../components/SideMenu'
import ServiceCatalogCard from '../../../components/ServiceCatalogCard'
import { getRegionName } from '../../../utils'
import { ALL_REGION, HPECC, BACKUP_AND_RECOVERY } from '../../../constants'
import ServiceSearch from '../../../components/ServiceSearch'

const ServiceCatalog = () => {
  const axios = useAxiosAuth()
  const [allServices, setAllServices] = useState([])
  const [filteredServices, setFilteredServices] = useState([])
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState({})
  const [regions, setRegions] = useState([])
  const [selectedRegion, setSelectedRegion] = useState(ALL_REGION)
  const { t } = useTranslation([
    'dashboard',
    'services_catalog',
    'services',
    'authn'
  ])
  const { servicesLegacy: services, isLoaded } = useServices()
  const { regions: regionsData, isLoaded: regionsLoaded } = useRegions()
  const LDFlags = useFlags()
  const dynamicEvalFlag = LDFlags['glcp-dynamic-eval-enablement']
  const evalFlag = LDFlags['glcp-evaluation-service']
  const [evalSlugs, setEvalSlugs] = useState([])
  const size = useContext(ResponsiveContext)
  const isScreenSmall = size === 'small' || size === 'xsmall'

  const responsiveColumns = {
    small: { count: 1, size: 'full' },
    medium: { count: 2, size: '1/2' },
    large: { count: 3, size: '1/3' },
    xlarge: { count: 4, size: '1/4' }
  }

  const toolbarDirection = ['xsmall', 'small', 'medium'].includes(size)
    ? 'column'
    : 'row'

  const [searchQuery, setSearchQuery] = useState('')
  // Inside the ServiceCatalog component

  useEffect(() => {
    const catalog_visible_services = services.filter(
      ({ catalog_visible }) => catalog_visible
    )
    setAllServices(catalog_visible_services)
  }, [services])

  useEffect(() => {
    const regionsCodes = [
      ...new Set([ALL_REGION, ...allServices.map(({ region }) => region)])
    ]

    const regionsToSet = regionsCodes.map((region) => ({
      label:
        region === ALL_REGION
          ? t('common.all_regions')
          : getRegionName(regionsLoaded, regionsData, region),
      value: region
    }))

    setRegions(regionsToSet)
  }, [allServices, t, regionsLoaded, regionsData])

  // Service Category names
  const serviceCategories = useMemo(() => {
    return uniqBy(allServices, 'category').map(({ category }) => ({
      valueLabel: t(`dashboard:common.category.${category}`),
      valueName: category
    }))
  }, [allServices, t])

  // devounce function to update state of the search query
  const debouncedSearchData = debounce((searchStr) => {
    setSearchQuery(searchStr.toLowerCase().trim())
  }, 500)

  const findIntersectingObjects = (arr1, arr2) => {
    const categoriesSet = new Set(arr1.map((item) => item[0]))
    const intersectingObjects = arr2.filter((item) =>
      categoriesSet.has(item[0])
    )

    return intersectingObjects
  }

  // making an API call to get eval slugs for filter
  const getEvalSlugsData = () => {
    axios
      .get('/billing/v1beta1/catalog')
      .then((response) => {
        const data = response.data.items.map((obj) => obj.service)
        setEvalSlugs(data)
      })
      .catch((err) => {
        setShowErrorNotification(
          displayApiError(err, t, setShowErrorNotification)
        )
      })
  }

  useEffect(() => {
    const getEvalData = () => {
      getEvalSlugsData()
    }
    if (evalFlag && dynamicEvalFlag) {
      getEvalData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicEvalFlag, evalFlag])

  useEffect(() => {
    const filteredBySearch = allServices.filter((item) => {
      const description = t(
        `services:${item.serviceSlug}.short_description`,
        item.description
      )

      const search =
        !searchQuery ||
        item.name?.toLowerCase().includes(searchQuery) ||
        description?.toLowerCase().includes(searchQuery)

      return search
    })

    const filteredByRegion = filteredBySearch.filter(
      ({ region }) => selectedRegion === ALL_REGION || region === selectedRegion
    )

    const servicesBySlug = uniqBy(filteredByRegion, 'slug')

    const filteredByCategory = groupBy(servicesBySlug, 'category')

    const filteredData = Object.entries(filteredByCategory).filter(
      ([category]) => {
        return (
          !selectedFilters.category ||
          selectedFilters.category.includes(category)
        )
      }
    )

    const desiredSlugs = dynamicEvalFlag
      ? evalSlugs
      : [HPECC, BACKUP_AND_RECOVERY]
    let filteredServicesEval = []

    if (selectedFilters.evaluation) {
      filteredServicesEval = Object.entries(filteredByCategory).flatMap(
        ([, servs]) => {
          return (
            !selectedFilters.evaluation ||
            servs.filter((service) => desiredSlugs.includes(service.slug))
          )
        }
      )
    }
    filteredServicesEval = Object.entries(
      groupBy(filteredServicesEval, 'category')
    )

    if (filteredServicesEval.length === 0) {
      setFilteredServices(filteredData)
    } else {
      setFilteredServices(
        findIntersectingObjects(filteredData, filteredServicesEval)
      )
    }
  }, [
    allServices,
    searchQuery,
    selectedRegion,
    selectedFilters,
    dynamicEvalFlag,
    evalSlugs,
    t
  ])

  const serviceCount = filteredServices.reduce((total, num) => {
    return total + num[1].length
  }, 0)

  // fix categories list
  return (
    <Layout>
      {showErrorNotification !== null && showErrorNotification}
      <Box pad={{ horizontal: 'xlarge', vertical: 'medium' }}>
        <Box margin={{ bottom: 'large' }}>
          <PageHeader
            primaryHeader={t('common.services')}
            subHeader={t('common.services_subtitle')}
            testId="service-centric-catalog-pageheader"
          />
        </Box>
        <Grid
          rows={['auto']}
          columns={size === 'xlarge' ? ['small', 'flex'] : ['full']}
          gap="large"
        >
          <SideMenu active="catalog" />
          <Box gap={isGLOP() ? 'small' : 'large'}>
            <Box
              direction={isScreenSmall ? 'column' : 'row'}
              justify={isScreenSmall ? 'center' : 'between'}
              align={isScreenSmall ? 'start' : 'center'}
              gap="large"
            >
              <Box>
                <Typography level={2} type="heading">
                  {t('service_catalog.title')}
                </Typography>
                <Typography type="paragraph">
                  {t('service_catalog.subtitle')}
                </Typography>
              </Box>
              <VisibilityWrapper hideFor={{ deployment: ['GLOP'] }}>
                <Box>
                  <Dropdown
                    options={regions}
                    defaultVal={ALL_REGION}
                    onChangeDropdown={(e) => {
                      setSelectedRegion(e)
                    }}
                    testId="service-centric-catalog-region-dropdown"
                  />
                </Box>
              </VisibilityWrapper>
            </Box>

            <VisibilityWrapper hideFor={{ deployment: ['GLOP'] }}>
              <Box direction="column">
                <Box
                  direction={toolbarDirection || 'row'}
                  gap="medium"
                  margin={{ bottom: 'small' }}
                >
                  <ServiceSearch
                    debouncedSearchData={debouncedSearchData}
                    placeholder={t('service_catalog.search_placeholder')}
                    testId="service-catalog-search-box"
                  />
                  <Box width="100%">
                    <FilterButton
                      dialogHeight="100%"
                      title={t('authn:filter')}
                      dialogPosition="right"
                      initialSelectedFilters={selectedFilters}
                      filterAttributes={
                        evalFlag
                          ? [
                              {
                                label: t('service_catalog.service_categories'),
                                name: 'category',
                                values: serviceCategories,
                                height: 'auto'
                              },
                              {
                                label: t('service_catalog.evaluations'),
                                name: 'evaluation',
                                values: [
                                  {
                                    valueLabel: t(
                                      'service_catalog.evaluation_available'
                                    ),
                                    valueName: 'Evaluation Available'
                                  }
                                ]
                              }
                            ]
                          : [
                              {
                                label: t('service_catalog.service_categories'),
                                name: 'category',
                                values: serviceCategories,
                                height: 'auto'
                              }
                            ]
                      }
                      onFilterValuesChange={(values) => {
                        setSelectedFilters(values)
                      }}
                      testId="service-catalog-filter"
                    />
                  </Box>
                </Box>
                {serviceCount > 0 && (
                  <Box>
                    <Typography type="text" testId="service-catalog-count">
                      {`${serviceCount} ${t('service_catalog.total_services')}`}
                    </Typography>
                  </Box>
                )}
              </Box>
            </VisibilityWrapper>
            <Box>
              {filteredServices && isLoaded ? (
                filteredServices.map(([category, serviceOfferings], index) => {
                  // Determine if this is the last category to style
                  // appropriately.
                  const lastCategory =
                    index === Object.keys(filteredServices).length - 1
                      ? null
                      : {
                          color: 'border-weak',
                          opacity: 'weak',
                          size: 'xsmall',
                          side: 'bottom'
                        }

                  return (
                    <Box key={category}>
                      <Typography type="heading" level={3}>
                        {t(`dashboard:common.category.${category}`)}
                      </Typography>
                      <Box
                        margin={{ bottom: 'large' }}
                        pad={{ bottom: 'medium' }}
                        border={lastCategory}
                      >
                        <Box
                          columns={{
                            count: 'fill',
                            size: ['15%', 'small', 'flex']
                          }}
                          gap="large"
                          pad={{ vertical: 'medium' }}
                        >
                          <Grid
                            columns={responsiveColumns[size]}
                            gap="large"
                            pad={{ vertical: 'medium' }}
                          >
                            {serviceOfferings.map((service) => {
                              return (
                                <ServiceCatalogCard
                                  service={service}
                                  t={t}
                                  key={service.serviceSlug}
                                />
                              )
                            })}
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  )
                })
              ) : (
                <Box align="center" justify="center" alignSelf="center">
                  <Loader testId="loader-spinner" />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Layout>
  )
}
export default ServiceCatalog
