import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Anchor, Box, Form, FormField, FileInput, Text } from 'grommet'
import { CircleAlert } from 'grommet-icons'
import PropTypes from 'prop-types'

import { Notification, Button, Typography } from '../../../../components'
import { FILE_EXTENSION_CONSTANTS } from '../../FileExtensionConstants'

const FileUpload = ({ caseNumber }) => {
  const { t } = useTranslation(['support_cases'])
  const MAX_FILE_SIZE = 2147483648 // 2 GB
  const [numFiles, setNumFiles] = useState(0)
  const [busy, setBusy] = useState(false)
  const [fileRecords, setFileRecords] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const [success, setSuccess] = useState(false)
  const [visible, setVisible] = useState()
  const onClose = () => setVisible(undefined)
  const [showBtn, setShowBtn] = useState(false)
  const [formValue, setFormValue] = useState({})
  const fileInputRef = useRef()
  const [aggregateThresholdVal, setAggregateThresholdVal] = useState(10)

  const isFileFormatValid = (fileRec) => {
    let extension = ''
    if (fileRec?.name?.includes('.')) {
      extension = fileRec.name.split('.').pop().toLowerCase()
      if (!FILE_EXTENSION_CONSTANTS.includes(extension)) return true
    } else {
      return true
    }
    return false
  }
  const isFileSizeValid = (fileRec) => {
    return fileRec.size > MAX_FILE_SIZE
  }
  const populateErrorMessage = useCallback(
    (sizeError, typeError) => {
      if (sizeError && typeError) {
        setErrorMsg(
          t(
            'support_cases:file_upload.unsupported_file_format_max_size_error_msg'
          )
        )
        // eslint-disable-next-line no-else-return
      } else if (sizeError) {
        setErrorMsg(
          t('support_cases:file_upload.unsupported_max_size_error_msg')
        )
      } else if (typeError) {
        setErrorMsg(
          t('support_cases:file_upload.unsupported_file_formats_error_msg')
        )
      } else {
        setShowBtn(true)
        setErrorMsg('')
      }
    },
    [t]
  )
  const validateFileInput = useCallback(() => {
    const fileRecArray =
      fileRecords && numFiles > 0 ? Array.from(fileRecords) : undefined
    let typeError = false
    let sizeError = false
    if (fileRecArray) {
      setSuccess(false)
      setShowBtn(false)
      fileRecArray.forEach((fileRec) => {
        if (typeof fileRec === 'object') {
          typeError = typeError || isFileFormatValid(fileRec)
          sizeError = sizeError || isFileSizeValid(fileRec)
        }
      })
      populateErrorMessage(sizeError, typeError)
    } else {
      setShowBtn(false)
      setErrorMsg('')
    }
  }, [fileRecords, numFiles, populateErrorMessage])

  const handleFiles = (files) => {
    setAggregateThresholdVal(files.length)
    if (files.length > 10) {
      setShowBtn(false)
      setErrorMsg(t('support_cases:file_upload.max_10_files'))
    } else {
      setNumFiles(files.length)
      setFileRecords([...files])
    }
  }

  const handleSubmit = () => {
    if (numFiles > 0) {
      setBusy(true)
      setTimeout(() => {
        setFileRecords([])
        setNumFiles(0)
        fileInputRef.current.value = null
        setFormValue({})
        setVisible(true)
        setShowBtn(true)
        setBusy(false)
        setSuccess(true)
      }, 1500)
    }
  }
  useEffect(() => {
    validateFileInput()
  }, [fileRecords, validateFileInput, t])
  return (
    <Box direction="row-responsive" align="center" margin={{ top: 'small' }}>
      <Box>
        {visible && (
          <Notification
            testId="file-upload-notification-id"
            toast
            status="normal"
            text={
              t('support_cases:file_upload.file_upload_successfull_msg') +
              caseNumber
            }
            onClose={onClose}
            margin={{ top: 'large' }}
          />
        )}
      </Box>
      <Box margin={{ right: 'medium' }}>
        <Form
          validate="blur"
          messages={{
            required: t('support_cases:common.this_is_required_field')
          }}
          value={formValue}
          onSubmit={handleSubmit}
          onChange={(fileData) => {
            setFormValue(fileData)
          }}
        >
          <Typography
            type="text"
            weight="normal"
            size="small"
            data-testid="file-upload-label"
          >
            {t('support_cases:file_upload.file_upload_label')}
          </Typography>
          <Box width="medium">
            <FormField
              width="large"
              name="file-upload-form-field"
              htmlFor="file-input"
              info={
                <Box direction="row" gap="xsmall">
                  <Typography
                    weight="normal"
                    size="xsmall"
                    type="text"
                    data-testid="file-upload-info-msg"
                  >
                    {t('support_cases:file_upload.max_file_size_limit')}
                  </Typography>
                  <Typography
                    weight="normal"
                    size="xsmall"
                    type="text"
                    data-testid="file-upload-supported-file-formats"
                  >
                    <Anchor
                      weight="normal"
                      data-testid="file-upload-supported-extentions"
                      onClick={() => {}}
                      label={t(
                        'support_cases:file_upload.supported_file_formats'
                      )}
                    />
                  </Typography>
                </Box>
              }
              data-testid="file-upload-form-field"
              validateOn="change"
              error={errorMsg}
            >
              <FileInput
                testId="file-input"
                data-testid="fileInput"
                id="file-input"
                name="file-input"
                ref={fileInputRef}
                multiple={{
                  aggregateThreshold: aggregateThresholdVal
                }}
                messages={{
                  dropPromptMultiple: t(
                    'support_cases:file_upload.file_upload_placeholder'
                  ),
                  browse:
                    numFiles > 0
                      ? t('support_cases:file_upload.select_more_files')
                      : t('support_cases:file_upload.select_file')
                }}
                onChange={(event, { files }) => handleFiles(files)}
                renderFile={(file) => {
                  let extension = ''
                  if (file?.name?.includes('.')) {
                    extension = file.name.split('.').pop().toLowerCase()
                  }
                  const showAlert =
                    !FILE_EXTENSION_CONSTANTS.includes(extension) ||
                    file.size > MAX_FILE_SIZE
                  return (
                    <Box
                      direction="row"
                      gap="xsmall"
                      align="center"
                      margin="small"
                    >
                      {showAlert && <CircleAlert />}
                      <Text weight={500} truncate>
                        {file.name}
                      </Text>
                    </Box>
                  )
                }}
              />
            </FormField>
          </Box>
          {showBtn && (
            <Box direction="row" gap="small" margin={{ top: 'small' }}>
              <Button
                testId="file-upload-button"
                primary
                busy={busy}
                success={success}
                type="submit"
                label={t('support_cases:file_upload.upload_button')}
              />
            </Box>
          )}
        </Form>
      </Box>
    </Box>
  )
}
FileUpload.propTypes = {
  caseNumber: PropTypes.string.isRequired
}
export default FileUpload
