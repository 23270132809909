// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React from 'react'
import {
  Anchor,
  Box,
  Text,
  Heading,
  NameValueList,
  NameValuePair,
  Markdown
} from 'grommet'
import { ShareRounded, LinkNext } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import i18next from 'i18next'

import { Typography } from '../../../../components'
import { Carousel } from '../../../../components/carousel/Carousel'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { EVAL_STATES } from '../../../../dashboard/constants'

import ExploreCard from './ExploreCard'

const OverviewTab = ({
  serviceDetailData,
  isServiceProvision,
  screenSize,
  hasEval,
  requestEvalStatus,
  setEvalModalFormOpen,
  t
}) => {
  const serviceOffer = serviceDetailData?.service_offer || {}
  const serviceManager = serviceDetailData?.service_manager || {}
  const availableRegions = serviceDetailData?.available_regions || []
  const {
    name: serviceOfferName,
    id: serviceOfferId,
    slug,
    overview,
    is_service_manager: isServiceManager,
    terms_of_service_url: termsOfServiceUrl,
    documentation_url: documentationUrl,
    workspace_types: workspaceTypes,
    static_launch_url: staticLaunchUrl,
    contact_sales_url: contactSalesUrl,
    eval_url: evalUrl,
    capabilities
  } = serviceOffer
  const {
    name: serviceManagerName,
    id: managerServiceId,
    slug: serviceManagerSlug
  } = serviceManager
  const screenshots = serviceOffer.screenshots || []
  const videos = serviceOffer.videos || []
  const featuresSupported = serviceOffer.features_supported || []
  const Overview = () => {
    if (i18next.exists(`services:${slug}.overview_desc1`)) {
      return (
        <Text data-testid="overview-description-0">
          {t(`services:${slug}.overview_desc1`, '')}
          <br />
          {t(`services:${slug}.overview_desc2`, '')}
        </Text>
      )
    }
    const overviewArray = overview?.split('\n')
    return overviewArray
      ? overviewArray.map((paragraph, index) => (
          <>
            <Text key={paragraph} data-testid={`overview-description-${index}`}>
              {paragraph}
            </Text>
            <br />
          </>
        ))
      : null
  }

  const MAX_CAPABILITY_ITEMS = 12
  const Capabilities = () =>
    i18next.exists(`services:${slug}.capabilities_item1`)
      ? Array.from(Array(MAX_CAPABILITY_ITEMS).keys()).map((index) =>
          i18next.exists(`services:${slug}.capabilities_item${index}`) ? (
            <Text
              as="li"
              key={`capability-${index}`}
              data-test-id={`capability-${index}`}
            >
              {t(`services:${slug}.capabilities_item${index}`)}
            </Text>
          ) : (
            ''
          )
        )
      : capabilities?.map((capability, index) => (
          <Text as="li" key={capability} data-test-id={`capability-${index}`}>
            {capability}
          </Text>
        )) || ''
  const LDFlags = useFlags()
  const jhansiPageUpdateFlag = LDFlags['glcp-jhansi-service-detail-page-update']
  // LD flag for available locations
  const availableRegionDetailFlag = LDFlags['glcp-available-region-detail']

  return (
    <Box
      margin={{ top: 'small' }}
      pad={{ top: 'medium' }}
      direction={['large', 'xlarge'].includes(screenSize) ? 'row' : 'column'}
      gap={screenSize === 'xlarge' ? 'xlarge' : 'large'}
      justify="between"
    >
      <Box width="xlarge">
        <Heading
          level="2"
          margin={{ bottom: 'xsmall', top: 'none' }}
          data-testid="overview-title"
        >
          {t('dashboard:service_detail.overview')}
        </Heading>

        {jhansiPageUpdateFlag && (!!screenshots.length || !!videos.length) && (
          <Box
            margin={{ top: 'medium', bottom: 'medium' }}
            data-testid="screenshot-carousel-container"
          >
            <Carousel screenshots={screenshots} videos={videos} />
          </Box>
        )}

        <Overview />
        {!isServiceManager && !staticLaunchUrl && !isServiceProvision && (
          <Box>
            <Heading
              level="2"
              margin={{ bottom: 'xsmall', top: 'medium' }}
              data-testid="service-manager"
            >
              {t('dashboard:common.service_manager')}
            </Heading>
            <Markdown
              components={{
                AnchorMarkdown: () => (
                  <Anchor
                    label={serviceManagerName}
                    weight={500}
                    href={`/services/service-catalog/${managerServiceId}`}
                  />
                )
              }}
            >
              {t(
                `dashboard:service_detail.${
                  serviceManagerSlug === 'GLC' ? 'glc_' : ''
                }service_manager_desc${
                  serviceManagerSlug !== 'GLC' &&
                  featuresSupported?.includes('DEEP_LINKING')
                    ? '_deep_linking'
                    : ''
                }`,
                {
                  serviceName: serviceOfferName,
                  serviceManagerName
                }
              )}
            </Markdown>
          </Box>
        )}
        <Heading
          level="2"
          margin={{ bottom: 'xsmall', top: 'medium' }}
          data-testid="capabilities-title"
        >
          {t('dashboard:service_detail.capabilities')}
        </Heading>
        <Text
          as="ul"
          type="paragraph"
          style={{
            paddingLeft: '1em' // left align bullet with p text
          }}
          margin={{ top: 'none' }}
        >
          <Capabilities />
        </Text>
      </Box>

      <Box width="medium" gap="medium">
        {jhansiPageUpdateFlag &&
          (evalUrl || hasEval || contactSalesUrl ? (
            <Box
              flex
              gap="medium"
              data-testid="explore-section-container"
              margin={{ bottom: 'medium' }}
            >
              <Typography
                type="heading"
                level={2}
                testId="explore-section-heading"
              >
                {t('dashboard:service_detail_cards.heading')}
              </Typography>
              {hasEval && (
                <VisibilityWrapper
                  rbac={{
                    permission: 'ccs.orders.edit',
                    resource: '/ccs/orders'
                  }}
                >
                  {(requestEvalStatus.status === EVAL_STATES.null ||
                    requestEvalStatus.status === EVAL_STATES.expired) && (
                    <ExploreCard
                      title={t(
                        'dashboard:service_detail_cards.request_eval.title'
                      )}
                      description={t(
                        'dashboard:service_detail_cards.request_eval.description'
                      )}
                      icon={<LinkNext color="text-weak" size="large" />}
                      hasEval={hasEval}
                      setEvalModalFormOpen={setEvalModalFormOpen}
                      testId="request-eval-card"
                    />
                  )}
                  <ExploreCard
                    title={t('dashboard:service_detail_cards.get_quote.title')}
                    description={t(
                      'dashboard:service_detail_cards.get_quote.description'
                    )}
                    icon={<ShareRounded color="text-weak" size="large" />}
                    path={evalUrl}
                    setEvalModalFormOpen={setEvalModalFormOpen}
                    testId="get-quote-card"
                  />
                </VisibilityWrapper>
              )}
              {contactSalesUrl && !hasEval && (
                <ExploreCard
                  title={t(
                    'dashboard:service_detail_cards.contact_sales.title'
                  )}
                  description={t(
                    'dashboard:service_detail_cards.contact_sales.description'
                  )}
                  icon={<ShareRounded color="text-weak" size="large" />}
                  path={contactSalesUrl}
                  testId="contact-sales-card"
                />
              )}
            </Box>
          ) : (
            <></>
          ))}
        <Box data-testid="details-container">
          <Heading
            level={2}
            margin={{ horizontal: 'none', top: 'none', bottom: 'medium' }}
          >
            {t('dashboard:service_detail.details')}
          </Heading>
          <NameValueList pairProps={{ direction: 'column' }}>
            <NameValuePair name={t('dashboard:common.supported_workspaces')}>
              {workspaceTypes?.map((type) => (
                <Text key={type} data-testid="supported-workspaces">
                  {t(`dashboard:workspace_types.${type}`)}
                </Text>
              ))}
            </NameValuePair>
            {serviceOfferId && (
              <NameValuePair name={t('dashboard:common.service_id')}>
                {serviceOfferId}
              </NameValuePair>
            )}
            <NameValuePair name={t('dashboard:common.documentation')}>
              {documentationUrl ? (
                <Anchor
                  href={documentationUrl}
                  target="_blank"
                  rel="noopener nonreferrer"
                  data-testid="documentation-url"
                >
                  {documentationUrl}
                </Anchor>
              ) : (
                '--'
              )}
            </NameValuePair>
            <NameValuePair name={t('dashboard:common.terms_of_service')}>
              {termsOfServiceUrl ? (
                <Anchor
                  href={termsOfServiceUrl}
                  target="_blank"
                  rel="noopener nonreferrer"
                  data-testid="terms-of-service"
                >
                  {termsOfServiceUrl}
                </Anchor>
              ) : (
                '--'
              )}
            </NameValuePair>
            <VisibilityWrapper hideFor={{ deployment: ['GLOP'] }}>
              <NameValuePair name={t('dashboard:common.available_regions')}>
                {availableRegions?.map(({ name, locations }) => (
                  <Box
                    direction="row"
                    key={name}
                    data-testid="available-regions"
                  >
                    <Text weight={500}>{name}</Text>
                    {availableRegionDetailFlag && locations && (
                      <Text margin={{ left: 'xxsmall' }}>
                        {` - ${locations.join('; ')}`}
                      </Text>
                    )}
                  </Box>
                ))}
              </NameValuePair>
            </VisibilityWrapper>
          </NameValueList>
        </Box>
      </Box>
    </Box>
  )
}

OverviewTab.propTypes = {
  serviceDetailData: PropTypes.object.isRequired,
  isServiceProvision: PropTypes.bool,
  screenSize: PropTypes.string.isRequired,
  hasEval: PropTypes.bool.isRequired,
  requestEvalStatus: PropTypes.object.isRequired,
  setEvalModalFormOpen: PropTypes.func,
  t: PropTypes.func.isRequired
}

OverviewTab.defaultProps = {
  setEvalModalFormOpen: () => {},
  isServiceProvision: false
}

export default OverviewTab
