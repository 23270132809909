import React, { useEffect, useState } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  useLocation
} from 'react-router-dom'

import Users from '../../igc-service/users/Users'
import { UserDetails } from '../../igc-service/users/UserDetails'
import GroupsRouter from '../../igc-service/groups/router'
import DomainsPage from '../../igc-service/domains/DomainsPage'
import AddDomain from '../../igc-service/domains/components/AddDomainWizard'
import DomainDetails from '../../igc-service/domains/DomainDetails'
import SsoProfilesRouter from '../../igc-service/sso-profiles/router'
import WorkspacesRouter from '../../igc-service/workspaces/router'
import { checkGotoChooseAccount } from '../../../utils/common-utils'
import { LIFECYCLE_STATE } from '../account-details/constants'
import { get } from '../../../utils/api-utils'
import { getOrganizationId, isV2 } from '../../../utils/feature-flag-utils'

import OrganizationGovernancePage from './OrganizationGovernance'

const OrganizationGovernanceRouter = () => {
  const { path } = useRouteMatch()
  const orgId = getOrganizationId()
  const v2 = isV2()
  const history = useHistory()
  const location = useLocation() // required to call API within useEffect
  checkGotoChooseAccount(history)
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(v2)

  useEffect(() => {
    // API call will happen only if the V2 session (authzV2Enabled) is established and organization ID is not null
    if (v2 && orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`)
        .then((response) => {
          if (response?.data) {
            setActive(response?.data?.lifecycleState === LIFECYCLE_STATE.ACTIVE)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [v2, orgId, location?.state?.triggerEffect])

  return (
    <>
      {!loading && v2 && (
        <Switch>
          <Route exact path={path}>
            <OrganizationGovernancePage />
          </Route>
          {active && (
            <Switch>
              <Route exact path={`${path}/users`}>
                <Users />
              </Route>
              <Route exact path={`${path}/users/:userId`}>
                <UserDetails />
              </Route>
              <Route path={`${path}/groups`}>
                <GroupsRouter />
              </Route>
              <Route exact path={`${path}/domains`}>
                <DomainsPage />
              </Route>
              <Route exact path={`${path}/domains/add-domain`}>
                <AddDomain />
              </Route>
              <Route exact path={`${path}/domains/:id`}>
                <DomainDetails />
              </Route>
              <Route path={`${path}/sso-profiles`}>
                <SsoProfilesRouter />
              </Route>
              <Route path={`${path}/workspaces`}>
                <WorkspacesRouter />
              </Route>
              <Redirect to="/not-found" />
            </Switch>
          )}
        </Switch>
      )}
      {!loading && !v2 && <Redirect to="/not-found" />}
    </>
  )
}

export { OrganizationGovernanceRouter }
