/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, Grid, Text, Layer, Anchor, Markdown } from 'grommet'
import {
  Hpe,
  CirclePlay,
  StatusCriticalSmall,
  StatusGoodSmall,
  CircleInformation,
  StatusWarningSmall,
  Close
} from 'grommet-icons'
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import VisibilityWrapper from '../../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import { Typography, Ruler, Button } from '../../../../../../../components'
import {
  reviewColumnsBannerEmail,
  reviewColumnsPortal,
  reviewColumnsBannerFlorence,
  reviewColumnsEmailFlorence,
  scheduleColumns,
  notificationContentColumns,
  categoryColumns,
  reviewColumnsBannerEmailNewFlow,
  reviewColumnsBannerNewFlow,
  reviewColumnsEmailNewFlow,
  reviewColumnsPortalNewFlow
} from '../../../constants'
import { validateForm } from '../../../../../../../utils/validation-utils'
import {
  getDetailsForReview,
  formExpiration,
  getScheduleReviewDetails,
  getBannerReviewDetails,
  getNotificationContentReviewDetails,
  getCategoryReviewDetails
} from '../../../utils'

const validateFields = () => {
  return {
    isValid: true
  }
}

export const validateFormFields = (formValues, i18nTranslate) => {
  return validateForm(formValues, i18nTranslate, validateFields)
}

const Review = ({
  bannerCheck,
  emailCheck,
  selectedType,
  isEdit,
  notificationDetails,
  initialFormValues
}) => {
  const { t } = useTranslation(['manage', 'common'])
  const LDFlags = useFlags()

  const { formValues } = useContext(WizardContext)
  const [showPreviewBanner, setShowPreviewBanner] = useState(false)
  const [showPreviewEmail, setShowPreviewEmail] = useState(false)
  const [compareEditedNoti, setCompareEditedNoti] = useState({
    status: false,
    expirationDate: false,
    expirationTime: false,
    summary: false,
    description: false,
    emailContentTitle: false,
    emailContentDetails: false,
    email: false,
    state: false
  })

  useEffect(() => {
    const compare = {
      status: false,
      expirationDate: false,
      expirationTime: false,
      summary: false,
      description: false,
      emailContentTitle: false,
      emailContentDetails: false,
      email: false,
      state: false
    }
    if (isEdit) {
      Object.keys(compareEditedNoti).forEach((key) => {
        if (key !== 'expirationDate' && key !== 'expirationTime') {
          if (initialFormValues[key] !== formValues[key]) {
            compare[key] = true
          }
        } else {
          const newttl = formValues?.expirationDate
            ? formExpiration(formValues)
            : ''

          if (
            dayjs(newttl * 1000).format('MM/DD/YYYY hh:mm A') !==
            dayjs(notificationDetails?.ttl * 1000).format('MM/DD/YYYY hh:mm A')
          ) {
            compare[key] = true
          }
        }
      })
      setCompareEditedNoti(compare)
    }
  }, [initialFormValues])

  const getColor = () => {
    const severity = formValues.status ? formValues.status.toLowerCase() : ''
    let element
    switch (severity) {
      case 'critical':
        element =
          'linear-gradient(0deg, rgba(252, 97, 97, 0.24), rgba(252, 97, 97, 0.24)), #FFFFFF'
        break
      case 'warning':
        element = '#FFEFD2'
        break
      case 'ok':
      case 'resolved':
        element = '#C7FAE8'
        break
      default:
        element = '#EFEFEF'
    }
    return element
  }

  const getIconPreview = (status) => {
    const severity = status ? status.toLowerCase() : ''
    let element
    switch (severity) {
      case 'critical':
        element = <StatusCriticalSmall color="status-critical" />
        break
      case 'warning':
        element = <StatusWarningSmall color="status-warning" />
        break
      case 'ok':
      case 'resolved':
        element = <StatusGoodSmall color="status-ok" />
        break
      default:
        element = <CircleInformation />
    }
    return element
  }

  const getDetail = () => {
    if (bannerCheck && emailCheck) {
      if (!LDFlags['glcp-notifications-feature-schedule-jhansi']) {
        return getDetailsForReview(
          t,
          formValues,
          reviewColumnsBannerEmail,
          selectedType,
          LDFlags,
          isEdit,
          compareEditedNoti
        )
      }
      return getDetailsForReview(
        t,
        formValues,
        reviewColumnsBannerEmailNewFlow,
        selectedType,
        LDFlags,
        isEdit,
        compareEditedNoti
      )
    }
    if (bannerCheck && !emailCheck) {
      if (!LDFlags['glcp-notifications-feature-schedule-jhansi']) {
        return getDetailsForReview(
          t,
          formValues,
          reviewColumnsBannerFlorence,
          selectedType,
          LDFlags,
          isEdit,
          compareEditedNoti
        )
      }
      return getDetailsForReview(
        t,
        formValues,
        reviewColumnsBannerNewFlow,
        selectedType,
        LDFlags,
        isEdit,
        compareEditedNoti
      )
    }
    if (!bannerCheck && emailCheck) {
      if (!LDFlags['glcp-notifications-feature-schedule-jhansi']) {
        return getDetailsForReview(
          t,
          formValues,
          reviewColumnsEmailFlorence,
          selectedType,
          LDFlags,
          isEdit,
          compareEditedNoti
        )
      }
      return getDetailsForReview(
        t,
        formValues,
        reviewColumnsEmailNewFlow,
        selectedType,
        LDFlags,
        isEdit,
        compareEditedNoti
      )
    }
    if (!LDFlags['glcp-notifications-feature-schedule-jhansi']) {
      return getDetailsForReview(
        t,
        formValues,
        reviewColumnsPortal,
        selectedType,
        LDFlags,
        isEdit,
        compareEditedNoti
      )
    }
    return getDetailsForReview(
      t,
      formValues,
      reviewColumnsPortalNewFlow,
      selectedType,
      LDFlags,
      isEdit,
      compareEditedNoti
    )
  }

  return (
    <Box
      gap="medium"
      width="large"
      justify="between"
      align="start"
      alignSelf="center"
    >
      {!LDFlags['glcp-notifications-feature-schedule-jhansi'] ? (
        <Box>
          <Grid
            gap={{ row: 'small', column: 'small' }}
            fill="horizontal"
            columns={['small', 'medium']}
            pad={{ top: 'small' }}
          >
            {getDetail()}
          </Grid>
        </Box>
      ) : (
        <Box>
          <Box margin={{ top: 'small' }}>
            <Typography
              margin={{ right: 'xsmall' }}
              size="large"
              type="text"
              colour="black"
              weight="bold"
              testId="preview-banner"
            >
              {t('notification.service')}
            </Typography>
            <Grid
              gap={{ row: 'small', column: 'small' }}
              fill="horizontal"
              columns={['small', 'medium']}
              pad={{ top: 'small' }}
            >
              {getDetail()}
            </Grid>
          </Box>
          <Box margin={{ top: 'medium' }}>
            <Typography
              margin={{ right: 'xsmall' }}
              size="large"
              type="text"
              weight="bold"
              testId="preview-banner"
            >
              {t('notification.category')}
            </Typography>
            <Grid
              gap={{ row: 'small', column: 'small' }}
              fill="horizontal"
              columns={['small', 'medium']}
              pad={{ top: 'small' }}
            >
              {getCategoryReviewDetails(
                t,
                formValues,
                categoryColumns,
                LDFlags['glcp-notifications-feature-dynamic-app-istanbul']
              )}
            </Grid>
          </Box>
          <Box margin={{ top: 'medium' }}>
            <Typography
              margin={{ right: 'xsmall' }}
              size="large"
              type="text"
              weight="bold"
              testId="preview-banner"
            >
              {t('notification.notification_content')}
            </Typography>
            <Grid
              gap={{ row: 'small', column: 'small' }}
              fill="horizontal"
              columns={['small', 'medium']}
              pad={{ top: 'small' }}
            >
              {getNotificationContentReviewDetails(
                t,
                formValues,
                notificationContentColumns,
                isEdit,
                compareEditedNoti
              )}
            </Grid>
          </Box>
          {formValues?.start_date_checkbox && (
            <Box>
              <Typography
                margin={{ right: 'xsmall', top: 'medium' }}
                size="large"
                type="text"
                weight="bold"
                testId="preview-banner"
              >
                {t('notification.schedule_notification')}
              </Typography>
              <Typography
                margin={{ right: 'xsmall', top: 'xsmall' }}
                size="small"
                type="text"
                testId="preview-banner"
              >
                {t('notification.schedule_des')}
              </Typography>
              <Grid
                gap={{ row: 'small', column: 'small' }}
                fill="horizontal"
                columns={['small', 'medium']}
                pad={{ top: 'small' }}
              >
                {getScheduleReviewDetails(t, formValues, scheduleColumns)}
              </Grid>
            </Box>
          )}
          {formValues?.banner && formValues?.expirationDate && (
            <Box>
              <Typography
                margin={{ right: 'xsmall', top: 'medium' }}
                size="large"
                type="text"
                weight="bold"
                testId="preview-banner"
              >
                {t('notification.banner_expiration')}
              </Typography>
              <Grid
                gap={{ row: 'small', column: 'small' }}
                fill="horizontal"
                columns={['small', 'medium']}
                pad={{ top: 'small' }}
              >
                {getBannerReviewDetails(
                  t,
                  formValues,
                  scheduleColumns,
                  isEdit,
                  compareEditedNoti
                )}
              </Grid>
            </Box>
          )}
        </Box>
      )}

      {LDFlags['glcp-notifications-feature-schedule-jhansi'] && bannerCheck && (
        <Box
          justify="end"
          alignSelf="end"
          alignContent="end"
          direction="row"
          onClick={() => {
            setShowPreviewBanner(true)
          }}
          margin={{ bottom: 'small', right: 'xlarge' }}
        >
          <Typography
            margin={{ right: 'xsmall' }}
            size="medium"
            type="text"
            weight="bold"
            testId="preview-banner"
          >
            {t('notification.preview_banner')}
          </Typography>
          <CirclePlay />
        </Box>
      )}

      <Box
        direction="row"
        justify="end"
        alignSelf="end"
        alignContent="end"
        margin={{ bottom: 'small' }}
        gap="small"
      >
        {!LDFlags['glcp-notifications-feature-schedule-jhansi'] &&
          bannerCheck && (
            <Button
              label={t('notification.preview_banner')}
              testId="preview-banner"
              secondary
              onClick={() => {
                setShowPreviewBanner(true)
              }}
              icon={<CirclePlay />}
            />
          )}
        {!LDFlags['glcp-notifications-feature-schedule-jhansi'] &&
          emailCheck && (
            <Button
              label={t('notification.preview_email')}
              testId="preview-email"
              secondary
              onClick={() => {
                setShowPreviewEmail(true)
              }}
              icon={<CirclePlay />}
            />
          )}
      </Box>

      {LDFlags['glcp-notifications-feature-schedule-jhansi'] && emailCheck && (
        <Box
          justify="end"
          alignSelf="end"
          alignContent="end"
          direction="row"
          onClick={() => {
            setShowPreviewEmail(true)
          }}
          width="large"
          margin={{ bottom: 'small', right: 'xlarge' }}
        >
          <Typography
            margin={{ right: 'xsmall' }}
            size="medium"
            type="text"
            weight="bold"
            testId="preview-email"
          >
            {t('notification.preview_email')}
          </Typography>
          <CirclePlay />
        </Box>
      )}

      {showPreviewBanner && (
        <Layer
          animate
          modal={false}
          position="center"
          onEsc={() => setShowPreviewBanner(false)}
          background={getColor()}
          onClickOutside={() => setShowPreviewBanner(false)}
        >
          <Box
            align="center"
            justify="between"
            direction="row"
            width="1400px"
            background={getColor()}
            round="xsmall"
            margin={{ bottom: 'xxsmall' }}
          >
            <Box
              align="center"
              justify="center"
              direction="row"
              margin={{ horizontal: 'small', top: 'small' }}
            >
              {getIconPreview(formValues.status)}
              <Text margin={{ horizontal: 'xsmall' }} size="large">
                {formValues?.summary}
                <Text margin={{ horizontal: 'xsmall' }} size="medium">
                  <VisibilityWrapper
                    hideFor={{
                      feature: 'glcp-notifications-feature-unsubscribe-helsinki'
                    }}
                  >
                    {formValues?.url_text ? (
                      <Anchor
                        href={formValues?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formValues?.url_text}
                      </Anchor>
                    ) : (
                      <Anchor
                        href={formValues?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formValues?.url}
                      </Anchor>
                    )}
                  </VisibilityWrapper>
                </Text>
              </Text>
            </Box>
            <Box margin={{ horizontal: 'small' }}>
              <Close
                size="medium"
                onClick={() => setShowPreviewBanner(false)}
              />
            </Box>
          </Box>
        </Layer>
      )}
      {showPreviewEmail && (
        <Layer
          position="center"
          modal
          plain={false}
          animate
          onClickOutside={() => setShowPreviewEmail(false)}
        >
          <Box pad="medium" responsive={false} flex={false} width="large">
            <Box
              align="center"
              justify="between"
              direction="row"
              fill="horizontal"
            >
              <Box direction="column">
                <Hpe color="#00C781" size="medium" />
                <Typography
                  type="heading"
                  level="4"
                  testId="email-preview-heading"
                  textAlign="left"
                >
                  {t('notification.email_org_title')}
                </Typography>
                <Typography
                  type="heading"
                  level="5"
                  testId="email-preview-heading"
                  textAlign="left"
                >
                  {t('notification.email_org_title2')}
                </Typography>
              </Box>
              <Box
                direction="column"
                align="end"
                alignContent="end"
                alignSelf="end"
                justify="end"
              >
                <Typography
                  size="medium"
                  type="text"
                  weight="bold"
                  testId="email-preview-label"
                  textAlign="right"
                >
                  {t('common:hpe_greenlake')}
                </Typography>
                <Typography
                  size="medium"
                  type="text"
                  weight="bold"
                  testId="email-preview-label"
                  textAlign="right"
                >
                  {t('notification.email_preview_status_title')}
                </Typography>
              </Box>
            </Box>
            <Box align="left" margin={{ top: 'medium' }}>
              <Typography
                type="heading"
                level="4"
                testId="email-preview-heading"
                textAlign="left"
                margin={{ bottom: 'medium' }}
              >
                {formValues?.emailContentTitle}
              </Typography>
              {LDFlags['glcp-notifications-feature-glasgow-admin'] && (
                <Typography
                  size="medium"
                  type="text"
                  testId="email-preview-salutation"
                  textAlign="left"
                  margin={{ bottom: 'small' }}
                >
                  <Trans
                    i18nKey="notification.email_preview_salutation"
                    t={t}
                    components={[<strong />]}
                  />
                </Typography>
              )}
              <Box
                align="left"
                justify="left"
                overflow="auto"
                wrap={false}
                responsive={false}
              >
                <Text textAlign="left">
                  <Markdown>{formValues?.emailContentDetails}</Markdown>

                  <VisibilityWrapper
                    hideFor={{
                      feature: 'glcp-notifications-feature-unsubscribe-helsinki'
                    }}
                  >
                    {formValues?.url?.length > 0 ? (
                      <>
                        <br />
                        <b>For Reference: </b>
                      </>
                    ) : (
                      <></>
                    )}
                    {formValues?.url_text?.length > 0 ? (
                      <Anchor
                        href={formValues?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formValues?.url_text}
                      </Anchor>
                    ) : (
                      <Anchor
                        href={formValues?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formValues?.url}
                      </Anchor>
                    )}
                  </VisibilityWrapper>
                </Text>
              </Box>
            </Box>
            <Box align="left" justify="left" margin={{ top: 'small' }}>
              <Box
                align="center"
                justify="center"
                direction="row"
                margin={{ top: 'medium' }}
                gap="medium"
              >
                <Text textAlign="left">{t('notification.terms_label')}</Text>
                <Text textAlign="left">{t('notification.privacy_label')}</Text>
                {LDFlags['glcp-notifications-feature-unsubscribe-helsinki'] && (
                  <Text textAlign="left">
                    {t('notification.unsubscribe_label')}
                  </Text>
                )}
              </Box>
              <Ruler margin={{ bottom: 'xsmall', top: 'small' }} />
              <Text textAlign="left" margin={{ top: 'medium' }}>
                {t('notification.email_preview_end_label')}
              </Text>
              <Text textAlign="left">
                {t('notification.email_preview_end_label2')}
              </Text>
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  )
}

Review.prototypes = {
  bannerCheck: PropTypes.func.isRequired
}

export default Review
