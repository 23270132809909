// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useReducer } from 'react'

import ReportingActions from './ReportingActions'

const defaultReportingDataTableState = {
  /* reports list */
  reports: null,
  /* pagination */
  page: 1,
  limit: 10,
  count: 0,
  /* sorting */
  sortProperty: 'startTime',
  sortDirection: 'desc',
  /* dropdown filter */
  selectedStatusDropdownOption: 'All reports',

  /* columns to be shown */
  visibleColumns: [],
  /* filter by search term */
  debouncedSearchTerm: ''
}
const defaultReportingDataTableFilterState = {
  /* filter button selections */
  time_range: 'all_time'
}
const initialContext = {
  reportingDataTableState: defaultReportingDataTableState,
  reportingDataTableFilterState: defaultReportingDataTableFilterState,
  reportsMetaDataObj: {},
  cachedPreviewDataObj: {},
  createReportSuccessApiData: null
}

const ReportingReducer = (state, action) => {
  switch (action.type) {
    case ReportingActions.SET_REPORTS_DATATABLE_INFO: {
      const dataTableState = action.data || {}
      return {
        ...state,
        reportingDataTableState: {
          ...state.reportingDataTableState,
          ...dataTableState
        }
      }
    }
    case ReportingActions.SET_REPORTS_DATATABLE_FILTER_INFO: {
      const dataTableFilterState = action.data || {}
      return {
        ...state,
        reportingDataTableFilterState: {
          ...defaultReportingDataTableFilterState,
          ...dataTableFilterState
        }
      }
    }
    case ReportingActions.RESET_REPORTS_DATATABLE_INFO: {
      return {
        ...state,
        reportingDataTableState: {
          ...defaultReportingDataTableState,
          visibleColumns: state.reportingDataTableState.visibleColumns
        }
      }
    }
    case ReportingActions.RESET_REPORTS_DATATABLE_FILTER_INFO: {
      return {
        ...state,
        reportingDataTableFilterState: {
          ...defaultReportingDataTableFilterState
        }
      }
    }
    case ReportingActions.SET_REPORTS_METADATA: {
      const reportsMetaDataObj = action.data
      return {
        ...state,
        reportsMetaDataObj
      }
    }
    case ReportingActions.SET_CREATE_REPORTS_API_DATA: {
      const createReportSuccessApiData = action.data
      return {
        ...state,
        createReportSuccessApiData
      }
    }
    case ReportingActions.SET_REPORTS_PREVIEW_DATA: {
      const cachedPreviewDataObj = action.data
      return {
        ...state,
        cachedPreviewDataObj
      }
    }

    default:
      return state
  }
}

const useReportingReducer = () => {
  const [ReportingState, dispatchReportingContext] = useReducer(
    ReportingReducer,
    initialContext
  )
  return { ReportingState, dispatchReportingContext }
}

export default useReportingReducer
