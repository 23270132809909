import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { Typography } from '../../../../../../../components'

import { RateInput } from './RateInput'

const RatesEditor = ({ formValues, setFormValues, resetGlobalErrMsg }) => {
  dayjs.extend(utc)

  const { t } = useTranslation(['device', 'common'])

  return (
    <Box direction="column" gap="small">
      <Box direction="row" gap="medium">
        <Box direction="column">
          <Typography
            emphasis
            type="text"
            size="small"
            margin={{ top: '0px', bottom: '0px' }}
            testId="showback-rates-editor-label-subscription"
          >
            {t('showback_rates.wizard_label_subscription')}
          </Typography>
          <Typography
            emphasis
            type="text"
            size="small"
            margin={{ top: '0px', bottom: '0px' }}
            testId="showback-rates-editor-label-service"
          >
            {t('showback_rates.wizard_label_service')}
          </Typography>
        </Box>
        <Box direction="column">
          <Typography
            type="text"
            size="small"
            margin={{ top: '0px', bottom: '0px' }}
            testId="showback-rates-editor-value-subscription"
          >
            {formValues.subscriptionKey}
          </Typography>
          <Typography
            type="text"
            size="small"
            margin={{ top: '0px', bottom: '0px' }}
            testId="showback-rates-editor-value-service"
          >
            {formValues.ratesService}
          </Typography>
        </Box>
      </Box>
      <Box direction="column" gap="xsmall">
        <Typography
          type="heading"
          level={2}
          margin={{ top: '0px', bottom: '0px' }}
          testId="showback-rates-editor-meters-label"
        >
          {t('showback_rates.wizard_meters_label')}
        </Typography>
      </Box>
      {formValues?.ratesData?.pricePlans?.map((pricePlan) => {
        return (
          <RateInput
            pricePlan={pricePlan}
            key={pricePlan?.uom}
            formValues={formValues}
            setFormValues={setFormValues}
            resetGlobalErrMsg={resetGlobalErrMsg}
          />
        )
      })}
    </Box>
  )
}
RatesEditor.defaultProps = {
  resetGlobalErrMsg: undefined
}
RatesEditor.propTypes = {
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  resetGlobalErrMsg: PropTypes.func
}

export { RatesEditor }
