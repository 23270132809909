import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, DateInput, MaskedInput, TextArea } from 'grommet'
import { FormClock } from 'grommet-icons'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'

import { WizardContext } from '../../../../../../../../components/wizard/WizardContext'
import { Typography } from '../../../../../../../../components'
import { validateForm } from '../../../../../../../../utils/validation-utils'

const getDate = (expirationDate, expirationTime, i18nTranslate) => {
  const dateToday = dayjs().unix()

  let expEpochTime
  let errorMessage = ''
  let errorMessageDate = ''

  if (!isEmpty(expirationTime) && !isEmpty(expirationDate)) {
    const expDate = dayjs(expirationDate).format('MM/DD/YYYY')
    expEpochTime = dayjs(`${expDate} ${expirationTime}`).unix()
  } else {
    expEpochTime = dayjs(expirationDate).unix()
  }

  if (expEpochTime < dateToday) {
    if (!isEmpty(expirationTime)) {
      errorMessage = i18nTranslate('notification.time_check')
      return [true, false, errorMessage]
    }
    errorMessageDate = i18nTranslate('notification.date_check')
    return [false, true, errorMessage, errorMessageDate]
  }

  if (!isEmpty(expirationTime) && isEmpty(expirationDate)) {
    errorMessageDate = i18nTranslate('notification.no_date_error')
    return [false, true, errorMessage, errorMessageDate]
  }
  if (
    !isEmpty(expirationTime) &&
    !(expirationTime?.includes('AM') || expirationTime?.includes('PM'))
  ) {
    errorMessage = i18nTranslate('notification.no_am_pm_error')
    return [true, false, errorMessage, errorMessageDate]
  }
  return [true, true, errorMessage, errorMessageDate]
}

const validateFields = (formValues, i18nTranslate) => {
  const { summary, expirationDate, expirationTime, description } = formValues
  const summaryMaxLength = 256
  const descriptionMaxLength = 2048
  let errorMessage
  let descriptionMessageValid

  const expirationInUnix = getDate(
    expirationDate,
    expirationTime,
    i18nTranslate
  )

  if (isEmpty(summary)) {
    errorMessage = i18nTranslate('required')
  } else if (summary?.length >= summaryMaxLength) {
    errorMessage = i18nTranslate('notification.max_length')
  }

  if (!isEmpty(description)) {
    if (description?.length >= descriptionMaxLength) {
      descriptionMessageValid = false
    } else {
      descriptionMessageValid = true
    }
  } else {
    descriptionMessageValid = true
  }

  const bannerMessageValid = summary && summary.length <= summaryMaxLength

  return {
    summary: bannerMessageValid ? '' : errorMessage,
    expirationDate: expirationInUnix[0] ? '' : expirationInUnix[3],
    expirationTime: expirationInUnix[1] ? '' : expirationInUnix[2],
    description: descriptionMessageValid
      ? ''
      : i18nTranslate('notification.max_length'),
    isValid:
      !!bannerMessageValid &&
      !!expirationInUnix[0] &&
      !!descriptionMessageValid &&
      !!expirationInUnix[1]
  }
}

export const validateBannerMessage = (formValues, i18nTranslate) => {
  return validateForm(formValues, i18nTranslate, validateFields)
}

const BannerNotification = () => {
  const { t } = useTranslation(['manage'])

  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)
  const [errorMessage, setErrorMessage] = useState({})

  useEffect(() => {
    if (attemptedAdvance) {
      const validation = validateFields(formValues, t)
      setErrorMessage(validation)
    }
  }, [formValues, attemptedAdvance, t])

  const onChange = (event) => {
    const nextValue = event.value
    setFormValues({
      ...formValues,
      expirationDate: nextValue
    })
  }

  return (
    <Box
      gap="medium"
      width="medium"
      justify="between"
      align="start"
      alignSelf="center"
    >
      <Box
        direction="column"
        gap="medium"
        align="center"
        margin={{ bottom: 'large' }}
      >
        <Box>
          <Box width="medium">
            <Typography
              type="text"
              size="small"
              color="black"
              testId="banner-checkbox-description-label"
            >
              {t('notification.expiration_date')}
            </Typography>
            <Typography
              type="text"
              size="small"
              color="black"
              testId="dismissable-checkbox-description"
            >
              {t('notification.expiration_description')}
            </Typography>
            <FormField
              margin={{ bottom: 'medium' }}
              data-testid="dismiss-date-form-field"
              name="expirationDate"
              error={errorMessage.expirationDate}
            >
              <DateInput
                format="mm/dd/yyyy"
                value={formValues?.expirationDate}
                onChange={onChange}
                data-testid="expiration-date-input"
                placeholder="MM/DD/YYYY"
                calendarProps={{
                  bounds: [new Date().toISOString(), '2050-12-31'],
                  alignSelf: 'center'
                }}
              />
            </FormField>
          </Box>
          <Box width="medium">
            <Typography
              type="text"
              size="small"
              color="black"
              testId="banner-exp-label"
            >
              {t('notification.expiration_time')}
            </Typography>
            <Typography
              type="text"
              size="small"
              color="black"
              testId="banner-expiration-label"
            >
              {t('notification.expiration_description')}
            </Typography>
            <Box width="medium">
              <FormField
                margin={{ bottom: 'medium' }}
                name="expirationTime"
                testId="banner-expiration-time"
                error={errorMessage.expirationTime}
              >
                <MaskedInput
                  data-testid="banner-expiration-time-masked"
                  icon={<FormClock />}
                  mask={[
                    {
                      length: [1, 2],
                      options: Array.from({ length: 12 }, (v, k) => k + 1),
                      regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                      placeholder: 'HH'
                    },
                    { fixed: ':' },
                    {
                      length: 2,
                      options: ['00', '15', '30', '45'],
                      regexp: /^[0-5][0-9]$|^[0-9]$/,
                      placeholder: 'MM'
                    },
                    { fixed: ' ' },
                    {
                      length: 2,
                      options: ['AM', 'PM'],
                      regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                      placeholder: 'AM/PM'
                    }
                  ]}
                  value={formValues.expirationTime}
                  onChange={(event) => {
                    setFormValues({
                      ...formValues,
                      expirationTime: event.target.value
                    })
                  }}
                />
              </FormField>
            </Box>
            <Box width="medium" margin={{ bottom: 'medium' }}>
              <Typography
                type="text"
                size="small"
                color="black"
                testId="banner-checkbox-summary-label"
              >
                {t('notification.banner_message')}
              </Typography>
              <Typography
                type="text"
                size="small"
                color="black"
                testId="banner-checkbox-summary"
              >
                {t('notification.length_check')}
              </Typography>
              <FormField
                name="summary"
                margin={{ bottom: 'none' }}
                error={errorMessage.summary}
                data-testid="set-up-banner-summary"
                required
              >
                <TextArea
                  size="medium"
                  value={formValues.summary}
                  name="summary"
                  disabled
                  placeholder={t('notification.message')}
                  data-testid="banner-summary"
                />
              </FormField>
            </Box>
            <Box width="medium">
              <Typography
                type="text"
                size="small"
                color="black"
                testId="banner-checkbox-description-label"
              >
                {t('notification.additional_content_label')}
              </Typography>
              <Typography
                type="text"
                size="small"
                color="black"
                testId="banner-checkbox-description"
              >
                {t('notification.additional_content_description')}
              </Typography>
              <FormField
                name="description"
                margin={{ bottom: 'none' }}
                error={errorMessage?.description}
                data-testid="banner-description-formfield"
              >
                <TextArea
                  size="medium"
                  value={formValues.description}
                  name="description"
                  placeholder={t('notification.summary')}
                  data-testid="banner-description"
                  disabled
                />
              </FormField>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BannerNotification
