import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import { isFederatedAccount } from '../../utils/common-utils'

import ProfileDetails from './details/ProfileDetails'
import ProfilePreferences from './preferences/ProfilePreferences'
import ProfileSecurity from './security/ProfileSecurity'
import { ResetPassword } from './reset-password'

const ProfileRouter = ({ user }) => {
  return (
    <Switch>
      {!isFederatedAccount() && (
        <Route path={['/profile/password', '/profile/password:accountlist']}>
          <ResetPassword fromProfile />
        </Route>
      )}
      <Route path={['/profile/security', '/profile/security:accountlist']}>
        <ProfileSecurity />
      </Route>
      <Route path={['/profile/details', '/profile/details:accountlist']}>
        <ProfileDetails user={user} />
      </Route>
      <Route
        path={['/profile/preferences', '/profile/preferences:accountlist']}
      >
        <ProfilePreferences />
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default ProfileRouter

ProfileRouter.propTypes = {
  user: PropTypes.object.isRequired
}
