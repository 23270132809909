import { lazy, React, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

const LocationDetailsPage = lazy(() =>
  import(
    '../../commoncomponents/location-management/location-details/LocationDetailsPage'
  )
)
const CreateLocationPage = lazy(() => import('./CreateLocationPage'))
const ValidateLocationPage = lazy(() => import('./ValidateLocationPage'))
const LocationManagementPage = lazy(() => import('./LocationManagementPage'))

const LocationManagementRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route path="/manage-account/locations/create-location">
          <VisibilityWrapper
            fallbackComponent={<Redirect to="/home" />}
            hideFor={{
              deployment: ['COP', 'GLOP']
            }}
            rbac={{
              resource: '/ccs/location-management',
              permission: 'ccs.location-management.edit'
            }}
          >
            <CreateLocationPage />
          </VisibilityWrapper>
        </Route>
        <Route
          exact
          path="/manage-account/locations/validate-location/:locationId"
        >
          <VisibilityWrapper
            fallbackComponent={<Redirect to="/home" />}
            hideFor={{
              deployment: ['COP', 'GLOP']
            }}
            rbac={{
              resource: '/ccs/location-management',
              permission: 'ccs.location-management.edit'
            }}
          >
            <ValidateLocationPage />
          </VisibilityWrapper>
        </Route>
        <Route exact path="/manage-account/locations/:locationId">
          <VisibilityWrapper
            fallbackComponent={<Redirect to="/home" />}
            hideFor={{
              deployment: ['COP', 'GLOP']
            }}
            rbac={{
              resource: '/ccs/location-management',
              permission: 'ccs.location-management.view'
            }}
          >
            <LocationDetailsPage />
          </VisibilityWrapper>
        </Route>
        <Route exact path="/manage-account/locations">
          <VisibilityWrapper
            fallbackComponent={<Redirect to="/home" />}
            hideFor={{
              deployment: ['COP', 'GLOP']
            }}
            rbac={{
              resource: '/ccs/location-management',
              permission: 'ccs.location-management.view'
            }}
          >
            <LocationManagementPage />
          </VisibilityWrapper>
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  )
}

export default LocationManagementRouter
