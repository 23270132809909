import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { Box } from 'grommet'
import { Copy } from 'grommet-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { get } from '../../../utils/api-utils'
import {
  Typography,
  Anchor,
  FormInput,
  Button,
  Notification
} from '../../../components'

import OVADownloadModal from './OVADownloadModal'
import LoadingContainer from './LoadingContainer'

dayjs.extend(advancedFormat)

const RegisterGateway = () => {
  const { t } = useTranslation(['local-gateway'])
  const { oidcUser } = useReactOidc()

  const { gatewayId } = useParams()
  const [expiration, setExpiration] = useState()
  const [token, setToken] = useState()

  const [loadingToken, setLoadingToken] = useState(true)
  const [loadingTokenError, setLoadingTokenError] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [textCopied, setTextCopied] = useState(false)

  useEffect(() => {
    setLoadingToken(true)
    setLoadingTokenError(false)
    get(
      `/platform/acpmgr/activationCode`,
      {
        gateway_id: gatewayId
      },
      oidcUser.access_token
    )
      .then(
        (response) => {
          if (response.data) {
            setToken(response.data.activationCode)
            setExpiration(
              dayjs(response.data.expiration).format('MMMM Do, h:mm A')
            )
          }
        },
        () => {
          setLoadingTokenError(true)
        }
      )
      .finally(() => setLoadingToken(false))
  }, [gatewayId, oidcUser])

  const copyText = () => {
    navigator.clipboard.writeText(token)
    setTextCopied(true)
  }

  return (
    <>
      <Box margin={{ bottom: 'large' }}>
        <Box>
          <Box direction="row" pad={{ bottom: 'small' }}>
            <Typography type="text" size="large">
              <strong>{t('registration_step_one')}</strong>
              &nbsp;
              {t('registration_step_one_title')}
            </Typography>
          </Box>
          <Box direction="row" pad={{ bottom: 'medium' }}>
            <Typography
              type="text"
              testId="registration_step_one_text_p1"
              size="medium"
            >
              {t('registration_step_one_text_p1')}
              &nbsp;
              <Anchor
                testId="registration_step_one_link_text"
                onClick={() => setShowDownloadModal(true)}
                weight="bold"
                size="medium"
                label={t('registration_step_one_link_text')}
              />
              &nbsp;
              {t('registration_step_one_text_p2')}
            </Typography>
          </Box>
          <Box direction="row" pad={{ bottom: 'small' }}>
            <Typography type="text" testId="registration_step_two" size="large">
              <strong>{t('registration_step_two')}</strong>
              &nbsp;
              {t('registration_step_two_title')}
            </Typography>
          </Box>
          <Typography
            type="text"
            testId="registration_step_two_text_p1"
            size="medium"
          >
            {t('registration_step_two_text_p1')}
          </Typography>
          <Box direction="row" align="center" pad={{ top: 'medium' }} wrap>
            <Box pad={{ right: 'large' }}>
              <Typography
                type="text"
                testId="registration_token"
                size="large"
                weight="bold"
              >
                {t('registration_token')}
              </Typography>
              {expiration && (
                <Box direction="row">
                  <Typography
                    type="text"
                    testId="token_expire_text"
                    size="small"
                  >
                    {t('token_expire_text')}
                  </Typography>
                  <Typography
                    type="text"
                    testId="token_expire_value"
                    size="small"
                    margin={{ left: 'small' }}
                  >
                    {expiration}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box flex="grow">
              <LoadingContainer
                loading={loadingToken}
                loadingError={loadingTokenError}
                testId="token"
              >
                <FormInput
                  name="copy-token"
                  testId="copy-token"
                  disabled
                  value={token}
                  action={
                    <Button
                      default
                      hoverIndicator="none"
                      onClick={copyText}
                      icon={<Copy size="medium" />}
                      testId="copy-btn"
                    />
                  }
                  inputType="text"
                />
              </LoadingContainer>
            </Box>
          </Box>
        </Box>
      </Box>
      <OVADownloadModal
        setShowDownloadModal={setShowDownloadModal}
        showDownloadModal={showDownloadModal}
      />
      {textCopied && (
        <Notification
          backgroundColor="status-unknown"
          text={
            <span data-testid="copied-notification">{t(`text_copied`)}</span>
          }
          onClose={() => {
            setTextCopied(false)
          }}
        />
      )}
    </>
  )
}
export default RegisterGateway
