// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Trans } from 'react-i18next'

import {
  getStatusForMultiStatusResponse,
  getNotificationMessageForMultiStatusResponse
} from '../pages/ccs-manager/pages/support-access/utils'

import { displayNotification } from './notificiation-utils'

export const CheckResourcePermissions = (roleDetails, rbacPolicies) => {
  let output = true
  Object.entries(roleDetails).forEach(([resource, permissionsArray]) => {
    const permissions = rbacPolicies?.effects[resource]
    if (permissions) {
      permissionsArray.forEach((permission) => {
        if (permissions[permission] === false) {
          output = false
        }
      })
    } else {
      output = false
    }
  })
  return output
}

export const multiStatusNotification = (
  response,
  setNotifyComponent,
  successMessage,
  partialApprovalMessage,
  successHeading,
  errorHeading,
  t
) => {
  if (!errorHeading) {
    errorHeading = t('manage:error')
  } else if (!successHeading) {
    successHeading = t('manage:success')
  }
  const { success, failed } = response.data
  const successCases = success?.join(', ')
  const failedCases = failed
    ?.map((failedUser) => {
      const [caseId, errorMsg] = Object.entries(failedUser)[0]
      return t('{{errorMsg}}', { case: caseId, errorMsg })
    })
    .join('\n')

  const approveSuccessMsg = successCases && (
    <Trans i18nKey={successMessage} values={{ cases: successCases }} />
  )

  const approveFailedMsg = failedCases && `\n ${failedCases}`

  const status = getStatusForMultiStatusResponse(response)
  const autoClose = !(failed?.length > 0 && success?.length > 0)

  let notifyHeading = ''
  if (success?.length > 0 && failed?.length > 0) {
    notifyHeading = t(partialApprovalMessage)
  } else if (success?.length > 0) {
    notifyHeading = t(successHeading)
  }

  const notification = displayNotification(
    getNotificationMessageForMultiStatusResponse(
      approveSuccessMsg,
      notifyHeading,
      approveFailedMsg,
      t(errorHeading)
    ),
    status,
    setNotifyComponent,
    '',
    autoClose
  )

  return notification
}
