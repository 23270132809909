import React from 'react'
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom'

import { checkGotoChooseAccount } from '../../../utils/common-utils'

import WorkspacesPage from './WorkspacesPage'
import IgcWorkspaceDetailsPage from './components/IgcWorkspaceDetailsPage'

const WorkspacesRouter = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  checkGotoChooseAccount(history)

  return (
    <Switch>
      <Route exact path={`${path}/:workspaceId`}>
        <IgcWorkspaceDetailsPage />
      </Route>
      <Route exact path={`${path}`}>
        <WorkspacesPage />
      </Route>
    </Switch>
  )
}

export default WorkspacesRouter
