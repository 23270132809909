import React, {
  useContext,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Box, CheckBox } from 'grommet'
import { useTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'

import { Typography } from '../../../../../../../components'
import { WizardContext } from '../../../../../../../components/wizard/WizardContext'

import { CheckingLoader } from './CheckingLoader'
import DataErrorHandling from './DataErrorHandling'
import { RatesEditor } from './RatesEditor'
import useLoadingRates from './hooks/useLoadingRates'

const AddShowbackRateStep = forwardRef((props, ref) => {
  dayjs.extend(utc)
  const { t } = useTranslation(['device', 'common'])
  const { formValues, setFormValues } = useContext(WizardContext)
  const [isLoading, setIsLoading] = useState(false)

  // this loading will skip sku check since already in workflow
  const loadingRates = useLoadingRates({
    initialFormValues: {},
    setIsLoading,
    subscriptionKey: formValues?.subscriptionKey,
    setFormValues,
    skipLoadingRates: true,
    hasInitialRates: false,
    skipSkuCheck: true,
    callBackFunc: null
  })

  useEffect(() => {
    if (formValues.isRatesStepOn && !formValues.ratesData) {
      loadingRates()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.isRatesStepOn])

  useImperativeHandle(ref, () => ({
    handleFormValidationError: (err) => {
      if (!isEqual(err, formValues.ratesErrors))
        setFormValues({ ...formValues, ratesErrors: err })
    }
  }))

  return (
    <>
      {!formValues.isLoadingRatesFailed && (
        <Box margin={{ bottom: 'small' }}>
          <CheckBox
            data-testid="showback-rates-wizard-enable-checkbox"
            label={
              <Typography
                type="text"
                margin={{ top: '0px', bottom: '0px' }}
                testId="showback-rates-wizard-enable-checkbox-label"
              >
                {t('showback_rates.wizard_add_showback_rates')}
              </Typography>
            }
            checked={formValues.isRatesStepOn}
            onChange={(event) => {
              let newValues = { isRatesStepOn: event.target.checked }
              if (event.target.checked === false) {
                newValues = {
                  ...newValues,
                  ratesData: null,
                  ratesErrors: {}
                }
              }
              setFormValues({
                ...formValues,
                ...newValues
              })
            }}
          />
        </Box>
      )}
      {formValues.isRatesStepOn && isLoading && <CheckingLoader />}
      {formValues.isRatesStepOn &&
        !isLoading &&
        formValues.isLoadingRatesFailed && <DataErrorHandling />}
      {formValues.isRatesStepOn &&
        !isLoading &&
        !formValues.isLoadingRatesFailed && (
          <Box pad={{ left: 'small' }}>
            <RatesEditor
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Box>
        )}
    </>
  )
})
export { AddShowbackRateStep }
