import PropTypes from 'prop-types'
import { Box, Tag, Grid, Tip } from 'grommet'
import { useTranslation } from 'react-i18next'
import { DocumentText } from 'grommet-icons'

import ListItemLink from '../components/ResultListTemplateHelper/ListItemLink'
import { Ruler, Typography } from '../../../components'

import ExcerptFormatDate from './ExcerptFormatDate'

const DocumentTemplate = ({ result, engine }) => {
  const { t } = useTranslation(['support_hub_search'])
  const universalDate = result.raw.hpescuniversaldate
  const publicationTitle = result.raw.kmdocpublicationtitle
  const date = new Date(universalDate)
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })

  const popularBadge = result.isRecommendation ? (
    <Tip
      content={
        <Box
          width={{ max: '163px', min: 'fit-content' }}
          gap="small"
          responsive={false}
        >
          <Typography type="text">{t('popularTip')}</Typography>
        </Box>
      }
      dropProps={{
        align: { bottom: 'top' }
      }}
    >
      <Tag
        size="small"
        align="center"
        background="rgba(255, 188, 68, 0.1)"
        border={false}
        value={t('popular')}
      />
    </Tip>
  ) : null

  return (
    <Grid
      pad={{ top: 'small' }}
      rows={['auto', 'auto']}
      columns={['auto']}
      gap="small"
    >
      {/* Title Section */}
      <Box gap="xsmall" direction="row" align="center">
        <DocumentText data-testid="document-icon" size="small" />
        <ListItemLink result={result} engine={engine} />
        <Box direction="row" margin={{ left: 'xsmall' }} gap="xsmall" wrap>
          {publicationTitle && (
            <Typography type="text" color="text-weak">
              {t('in')} {publicationTitle}
            </Typography>
          )}

          <Box pad={{ left: 'small' }}>{popularBadge}</Box>
        </Box>
      </Box>

      <ExcerptFormatDate result={result} formattedDate={formattedDate} />

      <Ruler background="border" margin={{ top: 'xsmall', bottom: 'none' }} />
    </Grid>
  )
}

DocumentTemplate.propTypes = {
  result: PropTypes.object.isRequired,
  engine: PropTypes.object.isRequired
}

export default DocumentTemplate
