// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import lodashGet from 'lodash/get'

import { validateForm } from '../../../../../../../utils/validation-utils'
import { ssoModeEnum } from '../../../../constants'

export const validateGeneralFields = (formValues, t) => {
  const requiredString = t('iam:sso_profiles.view_edit_create.required')
  const errorString = t('iam:sso_profiles.view_edit_create.invalid_char')
  const userInput = new RegExp(/^[^<>(){}]*$/)
  const profileName = lodashGet(formValues, 'general.profileName')
  const ccsAttribute = lodashGet(formValues, 'attribute_mapping.ccsAttribute')

  let valid = true

  if (
    lodashGet(formValues, 'general.authorizationMethod') ===
    ssoModeEnum.AUTHORIZATION
  ) {
    const userInputValidate =
      userInput.test(profileName) && userInput.test(ccsAttribute)
    valid = userInputValidate && profileName && ccsAttribute
  } else {
    const userInputValidate = userInput.test(profileName)
    valid = userInputValidate && profileName
  }

  return {
    profileName:
      (profileName ? '' : requiredString) ||
      (userInput.test(profileName) ? '' : errorString),
    ccsAttribute:
      (ccsAttribute ? '' : requiredString) ||
      (userInput.test(ccsAttribute) ? '' : errorString),
    isValid: valid
  }
}

export const validateConfigureSettingsFields = (formValues, t) => {
  const requiredString = t('iam:sso_profiles.view_edit_create.required')
  const errorString = t('iam:sso_profiles.view_edit_create.invalid_char')
  const userInput = new RegExp(/^[^<>(){}]*$/)
  // const countryCode = lodashGet(formValues, 'attribute_mapping.countryCode')
  const lastName = lodashGet(formValues, 'attribute_mapping.lastName')
  const firstName = lodashGet(formValues, 'attribute_mapping.firstName')
  const email = lodashGet(formValues, 'attribute_mapping.email')

  const userInputValidate =
    userInput.test(email) &&
    userInput.test(lastName) &&
    userInput.test(firstName)

  const valid = userInputValidate && firstName && lastName && email
  return {
    // countryCode:
    //   (countryCode ? '' : requiredString) ||
    //   (userInput.test(countryCode) ? '' : errorString),
    email:
      (email ? '' : requiredString) ||
      (userInput.test(email) ? '' : errorString),
    lastName:
      (lastName ? '' : requiredString) ||
      (userInput.test(lastName) ? '' : errorString),
    firstName:
      (firstName ? '' : requiredString) ||
      (userInput.test(firstName) ? '' : errorString),
    isValid: valid
  }
}

export const validateConfigureSettings = (formValues, t) => {
  return validateForm(formValues, t, validateConfigureSettingsFields)
}

export const validateFields = (formValues, t) => {
  const configOptionValidate =
    lodashGet(formValues, 'saml_idp_config.config_option') !== ''
  if (!configOptionValidate) {
    return {
      isValid: false,
      configOptions: t('iam:sso_profiles.view_edit_create.required')
    }
  }

  const urlValidate = new RegExp(/^(http|https):\/\/[^ "]+$/)
  const userInput = new RegExp(/^[^<>(){}]*$/)
  const errorString = t('iam:sso_profiles.view_edit_create.invalid_char')
  const requiredString = t('iam:sso_profiles.view_edit_create.required')

  const logoutUrl = lodashGet(formValues, 'saml_idp_config.logout_url').trim()
  const entityId = lodashGet(formValues, 'saml_idp_config.entity_id').trim()
  const loginUrl = lodashGet(formValues, 'saml_idp_config.login_url').trim()
  const signingCert = lodashGet(
    formValues,
    'saml_idp_config.signing_certificate'
  ).trim()
  const userInputValidate =
    urlValidate.test(logoutUrl) &&
    urlValidate.test(loginUrl) &&
    userInput.test(entityId) &&
    userInput.test(logoutUrl) &&
    userInput.test(loginUrl) &&
    userInput.test(signingCert)
  const metadataUrl = lodashGet(
    formValues,
    'saml_idp_config.metadata_url'
  ).trim()
  const formValid =
    userInputValidate && entityId && loginUrl && logoutUrl && signingCert
  return {
    entityId:
      (entityId ? '' : requiredString) ||
      (userInput.test(entityId) ? '' : errorString),
    signingCert:
      (signingCert ? '' : requiredString) ||
      (userInput.test(signingCert) ? '' : errorString),
    loginUrl:
      (loginUrl ? '' : requiredString) ||
      (userInput.test(loginUrl) ? '' : errorString) ||
      (urlValidate.test(loginUrl)
        ? ''
        : t('iam:sso_profiles.view_edit_create.invalid_url')),
    logoutUrl:
      (logoutUrl ? '' : requiredString) ||
      (userInput.test(logoutUrl) ? '' : errorString) ||
      (urlValidate.test(logoutUrl)
        ? ''
        : t('iam:sso_profiles.view_edit_create.invalid_url')),
    uploadFile: formValid ? '' : requiredString,
    validateUrl: formValid ? '' : requiredString,
    metadataFile: formValid ? '' : requiredString,
    metadataUrl: metadataUrl ? '' : requiredString,
    isValid: !!formValid
  }
}

export const validateXMLFile = (file) => {
  const allowedExtensions = /(\.xml)$/i
  if (!allowedExtensions.exec(file.name)) {
    return false
  }
  return true
}

export const validateIDPDetails = (formValues, t) => {
  return validateForm(formValues, t, validateFields)
}

export const validateTimeoutField = (formValues, t) => {
  const minTimeout = 6
  const maxTimeout = 1440
  const timeout = lodashGet(formValues, 'timeout')

  const timeoutNumber = Number(timeout)
  const isNumberValid =
    !Number.isNaN(timeoutNumber) &&
    timeoutNumber >= minTimeout &&
    timeoutNumber <= maxTimeout

  let result
  if (isNumberValid) {
    result = ''
  } else {
    result = timeout
      ? t('iam:sso_profiles.view_edit_create.invalid_session_timeout', {
          minTimeout,
          maxTimeout
        })
      : t('iam:sso_profiles.view_edit_create.required')
  }

  return {
    timeout: result,
    isValid: isNumberValid
  }
}

export const validateRecoveryPassword = (password, username) => {
  const passwordRegex = new RegExp(
    /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=\w*[\W_]).*$/
  )
  return passwordRegex.test(password) && !password.includes(username)
}

export const validateRecoveryUser = (formValues, t) => {
  let passwordValid = true
  if (!lodashGet(formValues, 'recovery_user.skipPasswordValidation')) {
    passwordValid = validateRecoveryPassword(
      lodashGet(formValues, 'recovery_user.recoveryPassword'),
      lodashGet(formValues, 'recovery_user.username')
    )
  }
  const emailRegex = RegExp('[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+') // NOSONAR
  const emailValid = emailRegex.test(
    lodashGet(formValues, 'recovery_user.pocEmail')
  )
  const formValid =
    !lodashGet(formValues, 'recovery_user.recoveryUserEnabled') ||
    (emailValid && passwordValid)
  return {
    pocEmail: emailValid
      ? ''
      : t('iam:sso_profiles.view_edit_create.invalid_email'),
    recoveryPassword: passwordValid
      ? ''
      : t('iam:sso_profiles.view_edit_create.invalid_password'),
    isValid: Boolean(formValid)
  }
}
