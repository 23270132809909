import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, DropButton, CheckBox } from 'grommet'
import { Support, More } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import { Button, Typography, Tile } from '../../../../../components'
import { displayNotification } from '../../../../../utils/notificiation-utils'

import AddServer from './AddServer'
import EditServer from './EditServer'

const ConfigureRadius = ({
  cb,
  editMode,
  showAddServerModal,
  closeAddServerModal,
  radiusServerDetails,
  setNotifyComponent,
  setSmartCardChecked,
  smartCardChecked,
  isSetSmartCardEdited
}) => {
  const [addServerModal, setAddServerModal] = useState(showAddServerModal)
  const [editServerModal, setEditServerModal] = useState(false)
  const [serverDetail, setServerDetail] = useState(radiusServerDetails)
  const { t } = useTranslation(['authn', 'common'])
  const serverType = {
    0: 'Primary',
    1: 'Secondary'
  }
  const [editServerCode, setEditServerCode] = useState(0)
  const addServerCb = (data) => {
    setServerDetail(serverDetail.concat(data))
    cb(serverDetail.concat(data))
  }

  useEffect(() => {
    setAddServerModal(showAddServerModal)
  }, [showAddServerModal])

  useEffect(() => {
    setServerDetail(radiusServerDetails)
  }, [radiusServerDetails])

  const editServerCb = (data, serverName) => {
    const detailIndex = serverType[0] === serverName ? 0 : 1
    const clonedServerDetail = [...serverDetail]
    clonedServerDetail[detailIndex] = { ...serverDetail[detailIndex], ...data }
    setServerDetail(clonedServerDetail)
    cb(clonedServerDetail, detailIndex)

    const notification = displayNotification(
      t('manage_account.radius.update_server_notification'),
      'info',
      setNotifyComponent
    )
    setNotifyComponent(notification)
  }

  const closeModals = (val) => {
    setAddServerModal(val)
    setEditServerModal(val)
    closeAddServerModal(val)
  }

  const selectedDropdownItem = () => {
    const formattedServerDetail = [...serverDetail]
    formattedServerDetail.pop()
    setServerDetail(formattedServerDetail)
    cb(formattedServerDetail)

    const notification = displayNotification(
      t('manage_account.radius.delete_server_notification'),
      'info',
      setNotifyComponent
    )
    setNotifyComponent(notification)
  }

  return (
    <>
      {addServerModal && (
        <AddServer
          onSetOpen={closeModals}
          serverType="radius"
          cb={addServerCb}
          serverName={serverType[serverDetail.length === 1 ? 1 : 0]}
          serverInfo={serverDetail[editServerCode] || {}}
          serverDetail={serverDetail}
        />
      )}
      {editServerModal && (
        <EditServer
          onSetOpen={closeModals}
          serverType="radius"
          cb={editServerCb}
          serverName={serverType[editServerCode]}
          serverInfo={serverDetail[editServerCode] || {}}
          serverDetail={serverDetail}
          editServerCode={editServerCode}
        />
      )}
      {serverDetail.length > 0 ? (
        <Box
          fill
          data-testid="server-details"
          justify="center"
          border={{ side: 'top', color: 'border-weak' }}
        >
          {serverDetail.map((server, index) => {
            return (
              <Box
                fill
                direction="row"
                flex
                key={server.ip}
                margin={{ top: 'medium' }}
              >
                <Tile
                  boxShadow
                  justify="center"
                  paramTile={null}
                  alignContent="center"
                  align="center"
                  layout="row"
                  logo={<></>}
                  actionBtn={
                    <>
                      {editMode ? (
                        <Box direction="row" align="center">
                          {index > 0 ? (
                            <Box margin={{ right: 'small' }}>
                              {/* <More /> */}
                              <DropButton
                                data-testid="delete-server-dropdown"
                                margin={{ top: 'small', right: 'medium' }}
                                dropProps={{
                                  align: { top: 'bottom', right: 'left' }
                                }}
                                dropContent={
                                  <Box pad="xsmall">
                                    <Box
                                      align="start"
                                      pad="xsmall"
                                      onClick={selectedDropdownItem}
                                    >
                                      <Typography
                                        size="xsmall"
                                        type="text"
                                        weight="normal"
                                      >
                                        {t(
                                          'manage_account.radius.delete_server'
                                        )}
                                      </Typography>
                                    </Box>

                                    {index !== 0 && (
                                      <Box
                                        align="start"
                                        pad="xsmall"
                                        testId={`edit-server-btn${server.ip}`}
                                        onClick={() => {
                                          setEditServerModal(true)
                                          setEditServerCode(index)
                                        }}
                                      >
                                        <Typography
                                          size="xsmall"
                                          type="text"
                                          weight="normal"
                                        >
                                          Modify Server
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                }
                              >
                                <More />
                              </DropButton>{' '}
                            </Box>
                          ) : (
                            ''
                          )}
                          {index === 0 && (
                            <Box margin={{ left: 'small', right: 'small' }}>
                              <Button
                                default
                                label={t(
                                  'manage_account.authentication.modify'
                                )}
                                testId="edit-server-btn"
                                onClick={() => {
                                  setEditServerModal(true)
                                  setEditServerCode(index)
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      ) : (
                        ''
                      )}
                    </>
                  }
                  subTitle={
                    <Box>
                      <Typography type="text" testId="ip-id">
                        {server.ip}
                      </Typography>
                    </Box>
                  }
                  testId="app-tile"
                  title={
                    <Box>
                      <Typography
                        type="text"
                        size="large"
                        weight="bold"
                        testId="server-id"
                      >
                        {`${serverType[index]} Server`}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            )
          })}
          <Box margin={{ vertical: 'large' }}>
            <Typography type="text" size="large" weight="bold">
              {t('manage_account.radius.smart_card_title')}
            </Typography>
            <Typography type="text" size="small">
              {t('manage_account.radius.smart_card_description')}
            </Typography>
            <Box margin={{ top: 'medium' }}>
              <CheckBox
                pad="none"
                checked={smartCardChecked}
                label={t('manage_account.radius.smart_card_checkbox_label')}
                onChange={(event) => {
                  setSmartCardChecked(event.target.checked)
                  isSetSmartCardEdited(true)
                }}
                disabled={!editMode}
                data-testid="smart-card-checkbox"
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          {editMode && (
            <Box align="center" pad={{ top: 'medium', bottom: 'large' }}>
              <Box width="medium" align="center">
                <Support color="green" size="large" />
                <Typography
                  type="text"
                  size="medium"
                  textAlign="center"
                  testId="sso-desc"
                  margin={{ top: 'small' }}
                >
                  {t('manage_account.radius.setup_radius_server_msg')}
                </Typography>
                <Button
                  margin={{ top: 'small' }}
                  pad="small"
                  size="small"
                  secondary
                  label={t('manage_account.radius.setup_radius_server')}
                  testId="setup-radius-btn"
                  onClick={() => {
                    setAddServerModal(true)
                  }}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  )
}

ConfigureRadius.propTypes = {
  cb: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  showAddServerModal: PropTypes.bool,
  closeAddServerModal: PropTypes.func.isRequired,
  radiusServerDetails: PropTypes.array.isRequired,
  setNotifyComponent: PropTypes.func.isRequired,
  setSmartCardChecked: PropTypes.func.isRequired,
  smartCardChecked: PropTypes.bool.isRequired,
  isSetSmartCardEdited: PropTypes.func.isRequired
}

ConfigureRadius.defaultProps = {
  showAddServerModal: false,
  editMode: false
}

export default ConfigureRadius
