import React from 'react'
import { Grommet } from 'grommet'
import { hpe } from 'grommet-theme-hpe'
import { useTranslation } from 'react-i18next'
import { ContextualHelpFromPath } from '@glcp/contextual-help'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { escapeRegExp } from 'lodash'

import { defaultViewAllUrl } from '../../../dashboard/constants'
import { topicsPerPage } from '../../../configs/contextual-help-config'
import { isV2 } from '../../../utils/feature-flag-utils'

const resolveSubPath = (map, path) => {
  let id
  if (map && path) {
    id = map[path]
    if (!id) {
      // no id for that path. try a subset of the path
      const nextPath = path.substring(0, path.lastIndexOf('/'))
      id = resolveSubPath(map, nextPath)
    }
  }
  return id
}

const mappingToRE = (mapping) => {
  let re
  if (mapping.indexOf('*') !== -1) {
    // pull out path elements
    re = mapping
      .split('/')
      .map((element) => {
        if (element.indexOf('*') === -1) {
          return escapeRegExp(element)
        }
        // replace any ** with an RE
        return element
          .split('**')
          .map((subelement) =>
            // replace any * with an RE
            subelement
              .split('*')
              .map((e) => escapeRegExp(e))
              .join('[^/]*')
          )
          .join('.*')
      })
      .join('\\/')
    re = `^${re}$`
  }
  return re
}

const resolveMapping = (mapping, variants = []) => {
  const overrides = mapping?.overrides || {}
  let map = mapping
  if (map?.mapping) {
    // The original mapping flag is just the LD flag value
    // But the newer flags can put the mapping under a "mapping" member
    map = map.mapping
  }

  for (let i = 0; i < variants.length; i += 1) {
    if (overrides[variants[i]]) {
      // merge in any mapping overrides for this variant
      map = { ...map, ...overrides[variants[i]] }
    }
  }
  return map
}

const getVariants = () => {
  const variants = []

  // If this session has authz V2 enabled, use any mappings in
  // LDFlags['glcp-contextual-help-mapping'].overrides.v2
  if (isV2()) {
    variants.push('v2')
  }
  return variants
}

export const ContextualHelp = ({ proxyOverride }) => {
  const { i18n } = useTranslation()
  const LDFlags = useFlags()

  // Use the domain prefix from the mapping LD flag if present
  // for example ext-itg.support.hpe.com.
  let proxy = LDFlags['glcp-contextual-help-mapping']?.hpe_support_domain
  if (proxy && !proxy.match(/^http[s]?:\/\//)) {
    proxy = `https://${proxy}`
  }
  if (window.NODE_ENV === 'development' && proxyOverride) {
    proxy = proxyOverride
  }

  const onFeedback = (requestBody) => {
    // On user feedback, post a custom event containing the feedback.
    // The ContextualHelpProvider in the main app listens to this event
    // and posts the data to qualtrics via a ui-doorway api.
    dispatchEvent(new CustomEvent('ch-feedback', { detail: requestBody }))
  }

  const resolveTopic = (path) => {
    let id
    const map = resolveMapping(
      LDFlags['glcp-contextual-help-mapping'],
      getVariants()
    )

    if (map && path) {
      // first check for a match of the path
      id = map[path]
      if (!id) {
        // look for wildcarded matches
        const mappings = Object.keys(map)
        for (let i = 0; i < mappings.length; i += 1) {
          const re = mappingToRE(mappings[i])
          if (re && path.match(re)) {
            id = map[mappings[i]]
            break
          }
        }
      }
      if (!id) {
        // look for subpath match
        id = resolveSubPath(map, path)
      }
    }

    return id
  }

  let content = null
  if (LDFlags['glcp-contextual-help']) {
    content = (
      <Grommet theme={hpe} style={{ height: '100%' }}>
        <ContextualHelpFromPath
          locale={i18n.language}
          proxy={proxy}
          onLocationChange={(path) => {
            let id = resolveTopic(path)
            if (!id)
              id = isV2()
                ? topicsPerPage.overrides.v2[path]
                : topicsPerPage.mapping[path]
            return id
          }}
          hideTopicUrl
          viewAllUrl={defaultViewAllUrl}
          onFeedback={
            LDFlags['glcp-contextual-help-phase-two'] ? onFeedback : undefined
          }
          pinnable={!!LDFlags['glcp-contextual-help-phase-two']}
        />
      </Grommet>
    )
  } else {
    const bodyClasses = document.body.className.split(' ')
    document.body.className = bodyClasses.filter((item) => item !== 'helpOpen')
  }
  return content
}
