// Copyright 2024 Hewlett Packard Enterprise Development LP

import { get, getErrorMessage } from '../../../utils/api-utils'
import { getDataBasedOnSetting } from '../../../utils/ups-utils'

export function getPreferences(
  t,
  oidcUser,
  dispatch,
  setOriginalState,
  setErrorMessage
) {
  return get(
    '/accounts/ui/v1/user/profile/preferences',
    {},
    oidcUser.access_token
  ).then(
    (response) => {
      const idleTimeout = response.data.idle_timeout / 60
      const currentUser = {
        // include all of the fields, even ununsed ones, since the save API is a PUT
        language: response.data.language,
        timeout_number: idleTimeout,
        theme: response.data.theme,
        maintenance_notifications: response.data.maintenance_notifications,
        update_notifications: response.data.update_notifications,
        multi_fa_enabled: response.data.multi_fa_enabled,
        multi_fa_registration: response.data.multi_fa_registration,
        mfa_enforced: response.mfa_enforced,
        // TODO: CCS-2709 - Add support for console settings
        // console_interface: 'Clean',
        // console_zoom_accessibility: '100% (Default)',
        edited: false
      }
      dispatch({
        value: currentUser,
        type: 'SET_STATE'
      })
      setOriginalState(currentUser)
    },
    (error) => {
      const backendErrorMessage = getErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
    }
  )
}

export function getPreferencesFromUps(
  t,
  oidcUser,
  dispatch,
  setOriginalState,
  setErrorMessage,
  setGlobalPreferences
) {
  return get(
    '/user-prefs/v1alpha1/preferences',
    { category: 'globalpreferences' },
    oidcUser.access_token
  ).then(
    (response) => {
      const globalPrefData = response?.data?.data
      const language = getDataBasedOnSetting(globalPrefData, 'language')?.value
      const timeout =
        getDataBasedOnSetting(globalPrefData, 'sessionTimeout')?.value / 60
      const currentUser = {
        language,
        timeout_number: timeout
      }
      dispatch({
        value: currentUser,
        type: 'SET_STATE'
      })
      setOriginalState(currentUser)
      setGlobalPreferences(globalPrefData)
    },
    (error) => {
      const backendErrorMessage = getErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
    }
  )
}
