import {
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useHistory
} from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

import { checkGotoChooseAccount } from '../../../utils/common-utils'

import Groups from './Groups'
import GroupDetails from './GroupDetails'

const GroupsRouter = ({ isWorkspace }) => {
  const { path } = useRouteMatch()

  const history = useHistory()
  checkGotoChooseAccount(history)
  return (
    <Switch>
      <Route exact path={path}>
        <Groups isWorkspace={isWorkspace} />
      </Route>
      <Route exact path={`${path}/:groupId`}>
        <GroupDetails isWorkspace={isWorkspace} />
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  )
}

GroupsRouter.propTypes = {
  /**
   * True if called from v2 workspace, false if called from IGC pages
   */
  isWorkspace: PropTypes.bool
}

GroupsRouter.defaultProps = {
  isWorkspace: false
}

export default GroupsRouter
