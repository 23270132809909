import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import isEmpty from 'lodash/isEmpty'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ButtonGroup,
  FormInput
} from '../../../../../../../components'

const QuickApprove = ({
  onSetOpen,
  handleApprove,
  approveComment,
  setApproveComment
}) => {
  const { t } = useTranslation(['manage', 'common'])
  const [errorMessage, setErrorMessage] = useState('')

  const handleCancel = () => {
    onSetOpen(false)
    setApproveComment('')
  }

  const handleSave = () => {
    if (!isEmpty(approveComment) && approveComment?.length > 1024) {
      setErrorMessage(
        t('notification.approval.confirm_approve_length_validation')
      )
    } else {
      setErrorMessage('')
      onSetOpen(false)
      handleApprove()
    }
  }

  return (
    <ModalDialog
      position="center"
      onClickOutside={() => {
        onSetOpen(false)
      }}
      testId="confirmation-dialog"
      onEsc={() => {
        onSetOpen(false)
      }}
      content={
        <Box margin={{ top: 'medium' }}>
          <Typography size="medium" type="text">
            {t('notification.approval.confirm_approve_content')}
          </Typography>
          <FormInput
            name="approve-comment-text-input"
            testId="approve-comment-text"
            inputType="text"
            value={approveComment}
            error={errorMessage}
            onChange={(event) => {
              setApproveComment(event.target.value)
              if (event.target.value?.length > 1024) {
                setErrorMessage(
                  t('notification.approval.confirm_approve_length_validation')
                )
              } else {
                setErrorMessage('')
              }
            }}
          />
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-approve-confirm-btn',
                  onClick: handleCancel
                },
                {
                  id: 1,
                  label: t('Approve'),
                  primary: true,
                  testId: 'confirm-approve-button',
                  onClick: handleSave
                }
              ]}
              testId="approve-confirmation-buttons"
            />
          }
        />
      }
      header={
        <ModalHeader
          title={
            <Typography level="1" type="heading" weight="bold">
              {t('notification.approval.confirm_approve_header')}
            </Typography>
          }
        />
      }
      onClose={handleCancel}
      width="large"
    />
  )
}
export { QuickApprove }

QuickApprove.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  handleApprove: PropTypes.func.isRequired,
  setApproveComment: PropTypes.func.isRequired,
  approveComment: PropTypes.string.isRequired
}
