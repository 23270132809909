import React, { useState, useContext, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, FormField, CheckBox, SelectMultiple } from 'grommet'
import { StatusCritical } from 'grommet-icons'
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { isEmpty } from 'lodash'

import { get } from '../../../../../../../utils/api-utils'
import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import {
  Typography,
  Dropdown,
  Notification,
  Loader
} from '../../../../../../../components'
import {
  statusOptions,
  targetOptions,
  targetOptionsWithPCE
} from '../../../constants'
import { validateForm } from '../../../../../../../utils/validation-utils'

const validateFields = (formValues, i18nTranslate) => {
  const requiredString = i18nTranslate('required')
  const { target, status, region, instance, role } = formValues

  const formValid =
    target &&
    status &&
    region.length > 0 &&
    instance.length > 0 &&
    role.length > 0

  return {
    target: target ? '' : requiredString,
    status: status ? '' : requiredString,
    region: region.length > 0 ? '' : requiredString,
    instance: instance.length > 0 ? '' : requiredString,
    role: role.length > 0 ? '' : requiredString,
    isValid: !!formValid
  }
}

export const validateCreateFields = (formValues, i18nTranslate) => {
  return validateForm(formValues, i18nTranslate, validateFields)
}

const NotificationTarget = ({
  setBannerCheck,
  setEmailCheck,
  regionList,
  setRegionList,
  roleList,
  setRoleList,
  instanceList,
  setInstanceList,
  isEdit,
  setAppSlug
}) => {
  const { t } = useTranslation(['manage'])
  const LDFlags = useFlags()
  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)

  const { oidcUser } = useReactOidc()
  const [errorMessage, setErrorMessage] = useState({})
  const [wizardError, setWizardError] = useState(null)
  const [regionOptions, setRegionOptions] = useState([
    t('notification.all_regions')
  ])
  const [regionSelected, setRegionSelected] = useState([
    t('notification.all_regions')
  ])

  const [instanceOptions, setInstanceOptions] = useState([
    {
      value: t('notification.all_instances'),
      label: t('notification.all_instances')
    }
  ])

  const [roleOptions, setRoleOptions] = useState([t('notification.all_roles')])
  const [disableRole, setDisableRole] = useState(true)

  const [disableRegion, setDisableRegion] = useState(true)
  const [disableInstance, setDisableInstance] = useState(true)
  const [selectedTarget, setSelectedTarget] = useState('')
  const [savePrev, setSavePrev] = useState([t('notification.all_regions')])
  const [savePrevInst, setSavePrevInst] = useState([
    t('notification.all_instances')
  ])
  const [savePrevRole, setSavePrevRole] = useState([
    t('notification.all_roles')
  ])
  const [defaultRegionOptions, setDefaultRegionOptions] = useState([
    t('notification.all_regions')
  ])
  const [defaultInstanceOptions, setDefaultInstanceOptions] = useState([])
  const [defaultRoleOptions, setDefaultRoleOptions] = useState([])
  const [disableEmail, setDisableEmail] = useState(true)
  const [emailChecked, setEmailChecked] = useState(false)
  const [loadingRegions, setLoadingRegions] = useState(true)
  const [loadingRoles, setLoadingRoles] = useState(true)
  const [targetAllAppOptions, setTargetAllAppOptions] = useState([])

  const stateOptions = [t('notification.active'), t('notification.resolved')]

  useEffect(() => {
    if (attemptedAdvance) {
      const validation = validateFields(formValues, t)
      setErrorMessage(validation)
    }
    setBannerCheck(formValues?.banner)
    setEmailCheck(formValues?.email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, attemptedAdvance, t, setBannerCheck, setEmailCheck])

  const fetchRegions = useCallback(
    (centralList) => {
      setLoadingRegions(true)
      get(
        `/notifications-svc/ui/v1alpha1/get-region-app-instances`,
        {},
        oidcUser.access_token
      )
        .then(
          (response) => {
            const perRegionApps = response.data
            const regionArray = []
            const instanceArray = []
            let regionCodes = [t('notification.all_regions')]
            centralList.forEach((central) => {
              const appArray = [t('notification.all_regions')]
              const regionObject = perRegionApps[central?.id]
              if (regionObject) {
                const regions = Object.keys(regionObject)
                regionCodes = regionCodes.concat(regions)
                const instanceObject = {}
                Object.entries(regionObject).forEach((key) => {
                  const instList = key[1]?.map((val) => ({
                    value: val?.instance_id,
                    label: val?.short_name
                  }))
                  instanceObject[key[0]] = instList
                })
                regionArray.push({
                  app_id: central?.id,
                  regionList: appArray?.concat(regions),
                  central: central?.central
                })
                instanceArray.push({
                  app_id: central?.id,
                  instanceList: instanceObject,
                  central: central?.central
                })
              }
            })
            regionCodes = [...new Set(regionCodes)]
            const allRegion = [
              {
                app_id: 'PLATFORM',
                regionList: regionCodes,
                central: 'PLATFORM'
              }
            ]
            setRegionList(allRegion.concat(regionArray))
            setInstanceList(instanceArray)
          },
          () => {
            setWizardError(t('notification.per_region_error'))
            const regionArray = []
            const instanceArray = []
            centralList.forEach((app) => {
              regionArray.push({
                app_id: app.id,
                regionList: [t('notification.all_regions')],
                central: app.central
              })
              instanceArray.push({
                app_id: app.id,
                instanceList: [
                  {
                    value: t('notification.all_instances'),
                    label: t('notification.all_instances')
                  }
                ],
                central: app.central
              })
            })
            const allRegion = [
              {
                app_id: 'PLATFORM',
                regionList: [t('notification.all_regions')],
                central: 'PLATFORM'
              }
            ]
            const allInstance = [
              {
                app_id: 'PLATFORM',
                regionList: [
                  {
                    value: t('notification.all_instances'),
                    label: t('notification.all_instances')
                  }
                ],
                central: 'PLATFORM'
              }
            ]
            setRegionList(allRegion.concat(regionArray))
            setInstanceList(allInstance.concat(instanceArray))
          }
        )
        .finally(() => {
          setLoadingRegions(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oidcUser.access_token, t]
  )

  const fetchRoles = useCallback(
    (centralList) => {
      setLoadingRoles(true)
      const roleOptionsArray = []
      get(
        `/notifications-svc/ui/v1alpha1/get-app-roles`,
        {},
        oidcUser.access_token
      )
        .then(
          (response) => {
            const appRoles = response.data

            centralList?.forEach((central) => {
              const appArray = [
                {
                  value: t('notification.all_roles'),
                  label: t('notification.all_roles')
                }
              ]
              const roleObject = appRoles[central?.id]
              let mapRoleList = []
              if (roleObject) {
                mapRoleList = roleObject?.roles?.map((val) => ({
                  value: val?.slug,
                  label: val?.name
                }))
              }
              roleOptionsArray.push({
                app_id: central?.id,
                roleList: appArray?.concat(mapRoleList),
                central: central?.central
              })

              setRoleList(roleOptionsArray)
            })
          },
          () => {
            centralList.forEach((central) => {
              roleOptionsArray.push({
                app_id: central?.id,
                roleList: [
                  {
                    value: t('notification.all_roles'),
                    label: t('notification.all_roles')
                  }
                ],
                central: central?.central
              })
            })
            setRoleList(roleOptionsArray)
            setWizardError(t('notification.all_roles_error'))
          }
        )
        .finally(() => {
          setLoadingRoles(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oidcUser.access_token, t]
  )

  const fetchAppList = useCallback(() => {
    get(
      `/notifications-svc/ui/v1alpha1/centrals`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        fetchRegions(response.data)
        fetchRoles(response.data)
      },
      () => {
        setLoadingRoles(false)

        setLoadingRegions(false)
      }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t])

  useEffect(() => {
    if (!isEmpty(formValues?.target) && !isEmpty(selectedTarget)) {
      setRegionSelected([t('notification.all_regions')])
      setFormValues({
        ...formValues,
        region: [t('notification.all_regions')],
        instance: [t('notification.all_instances')],
        role: [t('notification.all_roles')],
        instanceLabels: [t('notification.all_instances')],
        roleLabel: [t('notification.all_roles')],
        selectedInstanceName: [t('notification.all_instances')],
        selectedRoleName: [t('notification.all_roles')],
        subService: '',
        topic: '',
        subTopic: '',
        categoryService: formValues?.target
      })
    }
    if (!isEmpty(formValues?.target)) {
      setDisableRegion(false)
      setDisableRole(false)
    } else {
      setDisableRegion(true)
      setDisableRole(true)
    }
    if (formValues?.target === 'PLATFORM') {
      const regionOpt = regionList.find(
        (region) => region?.central === 'PLATFORM'
      )
      setDisableRole(true)
      if (regionOpt?.regionList) {
        setRegionOptions(regionOpt?.regionList)
        setDefaultRegionOptions(regionOpt?.regionList)
      }
      setRoleOptions([
        {
          value: t('notification.all_roles'),
          label: t('notification.all_roles')
        }
      ])
      setDefaultRoleOptions([
        {
          value: t('notification.all_roles'),
          label: t('notification.all_roles')
        }
      ])
    } else if (formValues?.target === 'ARUBA_CENTRAL') {
      const regionOpt = regionList.find(
        (region) => region?.central === 'ARUBA_CENTRAL'
      )
      const roleOpt = roleList.find((role) => role?.central === 'ARUBA_CENTRAL')

      if (regionOpt?.regionList) {
        setRegionOptions(regionOpt?.regionList)
        setDefaultRegionOptions(regionOpt?.regionList)
      }
      setRoleOptions(roleOpt?.roleList)
      setDefaultRoleOptions(roleOpt?.roleList)
    } else if (formValues?.target === 'STORAGE_CENTRAL') {
      const regionOpt = regionList.find(
        (region) => region?.central === 'STORAGE_CENTRAL'
      )
      const roleOpt = roleList.find(
        (role) => role?.central === 'STORAGE_CENTRAL'
      )
      if (regionOpt?.regionList) {
        setRegionOptions(regionOpt?.regionList)
        setDefaultRegionOptions(regionOpt?.regionList)
      }
      setRoleOptions(roleOpt?.roleList)
      setDefaultRoleOptions(roleOpt?.roleList)
    } else if (formValues?.target === 'COMPUTE_CENTRAL') {
      const regionOpt = regionList.find(
        (region) => region?.central === 'COMPUTE_CENTRAL'
      )
      const roleOpt = roleList.find(
        (role) => role?.central === 'COMPUTE_CENTRAL'
      )
      if (regionOpt?.regionList) {
        setRegionOptions(regionOpt?.regionList)
        setDefaultRegionOptions(regionOpt?.regionList)
      }
      setRoleOptions(roleOpt?.roleList)
      setDefaultRoleOptions(roleOpt?.roleList)
    } else if (formValues?.target === 'PCE') {
      const regionOpt = regionList.find((region) => region?.central === 'PCE')
      const roleOpt = roleList.find((role) => role?.central === 'PCE')
      if (regionOpt?.regionList) {
        setRegionOptions(regionOpt?.regionList)
        setDefaultRegionOptions(regionOpt?.regionList)
      } else {
        setRegionOptions([t('notification.all_regions')])
        setDefaultRegionOptions([t('notification.all_regions')])
      }
      setRoleOptions(roleOpt?.roleList)
      setDefaultRoleOptions(roleOpt?.roleList)
    } else if (formValues?.target === 'OPS_RAMP') {
      const regionOpt = regionList.find(
        (region) => region?.central === 'OPS_RAMP'
      )
      const roleOpt = roleList.find((role) => role?.central === 'OPS_RAMP')
      if (regionOpt?.regionList) {
        setRegionOptions(regionOpt?.regionList)
        setDefaultRegionOptions(regionOpt?.regionList)
      }
      setRoleOptions(roleOpt?.roleList)
      setDefaultRoleOptions(roleOpt?.roleList)
    } else if (formValues?.target === 'SUSTAINABLITY_IC') {
      const regionOpt = regionList.find(
        (region) => region?.central === 'SUSTAINABLITY_IC'
      )
      const roleOpt = roleList.find(
        (role) => role?.central === 'SUSTAINABLITY_IC'
      )
      if (regionOpt?.regionList) {
        setRegionOptions(regionOpt?.regionList)
        setDefaultRegionOptions(regionOpt?.regionList)
      }
      setRoleOptions(roleOpt?.roleList)
      setDefaultRoleOptions(roleOpt?.roleList)
    } else if (formValues?.target === 'GREENLAKE_FLEX') {
      const regionOpt = regionList.find(
        (region) => region?.central === 'GREENLAKE_FLEX'
      )
      const roleOpt = roleList.find(
        (role) => role?.central === 'GREENLAKE_FLEX'
      )
      if (regionOpt?.regionList) {
        setRegionOptions(regionOpt?.regionList)
        setDefaultRegionOptions(regionOpt?.regionList)
      }
      setRoleOptions(roleOpt?.roleList)
      setDefaultRoleOptions(roleOpt?.roleList)
    } else {
      const regionOpt = regionList.find(
        (region) => region?.app_id === formValues?.target
      )
      const roleOpt = roleList.find(
        (role) => role?.app_id === formValues?.target
      )
      if (regionOpt?.regionList) {
        setRegionOptions(regionOpt?.regionList)
        setDefaultRegionOptions(regionOpt?.regionList)
      } else {
        setRegionOptions([t('notification.all_regions')])
        setDefaultRegionOptions([t('notification.all_regions')])
      }
      if (roleOpt?.roleList) {
        setRoleOptions(roleOpt?.roleList)
        setDefaultRoleOptions(roleOpt?.roleList)
      } else {
        setRoleOptions([
          {
            value: t('notification.all_roles'),
            label: t('notification.all_roles')
          }
        ])
        setDefaultRoleOptions([
          {
            value: t('notification.all_roles'),
            label: t('notification.all_roles')
          }
        ])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTarget])

  useEffect(() => {
    if (!isEmpty(formValues?.instance) && !isEmpty(selectedTarget)) {
      setFormValues({
        ...formValues,
        instance: [t('notification.all_instances')],
        selectedInstanceName: [t('notification.all_instances')]
      })
    }
    if (
      !isEmpty(formValues?.target) &&
      !regionSelected.includes(t('notification.all_regions'))
    ) {
      setDisableInstance(false)
      if (
        formValues?.target === 'PLATFORM' &&
        !regionSelected.includes(t('notification.all_regions'))
      ) {
        let platFormInstOptions = [
          {
            value: t('notification.all_instances'),
            label: t('notification.all_instances')
          }
        ]
        instanceList?.forEach((central) => {
          Object?.entries(central?.instanceList).forEach((key) => {
            if (regionSelected?.includes(key[0]?.toLowerCase())) {
              platFormInstOptions = platFormInstOptions.concat(key[1])
            }
          })
        })
        setInstanceOptions(platFormInstOptions)
        setDefaultInstanceOptions(platFormInstOptions)
      } else if (
        formValues?.target !== 'PLATFORM' &&
        !regionSelected.includes(t('notification.all_regions'))
      ) {
        let app = instanceList?.find(
          (selectedApp) => selectedApp?.central === formValues?.target
        )
        if (
          LDFlags['glcp-notifications-feature-dynamic-app-istanbul'] &&
          (formValues?.targetType === 'SERVICES' ||
            formValues?.targetType === 'PLATFORM')
        ) {
          app = instanceList.find(
            (selectedApp) => selectedApp?.app_id === formValues?.target
          )
        }
        let appInstOptions = [
          {
            value: t('notification.all_instances'),
            label: t('notification.all_instances')
          }
        ]
        Object?.entries(app?.instanceList).forEach((key) => {
          if (regionSelected?.includes(key[0]?.toLowerCase())) {
            appInstOptions = appInstOptions.concat(key[1])
          }
        })
        setInstanceOptions(appInstOptions)
        setDefaultInstanceOptions(appInstOptions)
      }
    } else {
      setDisableInstance(true)
      setFormValues({
        ...formValues,
        instance: [t('notification.all_instances')],
        selectedInstanceName: [t('notification.all_instances')]
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelected])

  const fetchTargetAppList = useCallback(() => {
    get(
      `/notifications-svc/ui/v1alpha1/get-all-apps`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        const allApps = response?.data
        const appList = []
        const centralList = []
        Object.entries(allApps).forEach((keyVal) => {
          const app = {
            label: keyVal[1]?.name,
            value: keyVal[0],
            slug: keyVal[1]?.slug
          }
          const centralItem = {
            id: keyVal[0],
            central: keyVal[1]?.name
          }
          appList.push(app)
          centralList.push(centralItem)
        })
        const sortedAppList = appList.sort((a, b) => {
          const app1 = a?.label.toLowerCase()
          const app2 = b?.label.toLowerCase()
          return app1.localeCompare(app2)
        })
        setTargetAllAppOptions([
          {
            label: 'HPE GreenLake platform',
            value: 'PLATFORM',
            slug: 'PLATFORM'
          },
          ...sortedAppList
        ])
        fetchRegions(centralList)
        fetchRoles(centralList)
      },
      () => {}
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t])

  useEffect(() => {
    if (LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
      if (
        formValues?.targetType === 'SERVICES' ||
        formValues?.targetType === 'PLATFORM'
      ) {
        fetchTargetAppList()
      } else {
        fetchAppList()
      }
    } else {
      fetchAppList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRegions, fetchAppList])

  useEffect(() => {
    if (formValues?.region[0] !== t('notification.all_regions')) {
      setRegionSelected(formValues?.region)
    }
    if (formValues?.status === 'Critical') {
      setDisableEmail(true)
      setEmailChecked(true)
      setFormValues({
        ...formValues,
        email: true
      })
    } else {
      setEmailChecked(false)
      setFormValues({
        ...formValues,
        email: false
      })
    }
    if (LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
      if (formValues?.targetType === 'CENTRALS') {
        setTargetAllAppOptions(targetOptions)
      } else {
        setTargetAllAppOptions([])
      }
    } else if (LDFlags['glcp-notifications-pce-target']) {
      setTargetAllAppOptions(targetOptionsWithPCE)
    } else {
      setTargetAllAppOptions(targetOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formValues?.status === 'Critical') {
      setDisableEmail(true)
      setEmailChecked(true)
      setFormValues({
        ...formValues,
        email: true
      })
    } else {
      setDisableEmail(true)
      setEmailChecked(false)
      setFormValues({
        ...formValues,
        email: false
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.status])

  return (
    <>
      {loadingRegions || loadingRoles ? (
        <Box direction="row" align="center" justify="center">
          <Loader testId="devices-loader" />
        </Box>
      ) : (
        <Box
          gap="medium"
          width="medium"
          justify="between"
          align="start"
          alignSelf="center"
        >
          <Box
            direction="column"
            gap="medium"
            align="center"
            margin={{ bottom: 'large' }}
          >
            <Box width="medium" margin={{ bottom: 'xsmall' }}>
              <FormField
                label={t('notification.service')}
                name="target"
                required
                error={errorMessage.target}
              >
                <Dropdown
                  name="target"
                  testId="select-target-dropdown"
                  disabled={isEdit}
                  noBorder
                  placeholder={t('notification.placeholder_service')}
                  labelKey="label"
                  valueKey={{ key: 'value' }}
                  value={formValues.target}
                  options={targetAllAppOptions}
                  onChangeDropdown={(value) => {
                    setFormValues({
                      ...formValues,
                      target: value?.value,
                      target_name: value?.label,
                      appSlug: value?.slug,
                      appName: value?.label
                    })
                    setSelectedTarget(value?.value)
                    setAppSlug(value?.slug)
                  }}
                />
              </FormField>
            </Box>
            <>
              <Box width="medium" margin={{ bottom: 'xsmall' }}>
                <FormField
                  label={t('notification.region')}
                  name="region"
                  required
                  error={errorMessage.region}
                >
                  <SelectMultiple
                    data-testid="region-dropdown"
                    id="region"
                    name="region"
                    placeholder={t('notification.placeholder_region')}
                    searchPlaceholder={t(
                      'notification.placeholder_region_search'
                    )}
                    options={regionOptions || []}
                    value={formValues?.region}
                    disabled={disableRegion || isEdit}
                    onChange={({ value: nextValue }) => {
                      if (
                        nextValue.length > 0 &&
                        nextValue.includes(t('notification.all_regions')) &&
                        savePrev.includes(t('notification.all_regions'))
                      ) {
                        const index = nextValue.indexOf(
                          t('notification.all_regions')
                        )
                        if (index > -1) {
                          const newArray = nextValue.splice(index - 1, 1)
                          setRegionSelected(newArray)
                          setFormValues({
                            ...formValues,
                            region: newArray
                          })
                          setSavePrev(newArray)
                        }
                      } else if (
                        nextValue.length > 0 &&
                        nextValue.includes(t('notification.all_regions')) &&
                        !savePrev.includes(t('notification.all_regions'))
                      ) {
                        setRegionSelected([t('notification.all_regions')])
                        setFormValues({
                          ...formValues,
                          region: [t('notification.all_regions')]
                        })
                        setSavePrev([t('notification.all_regions')])
                      } else {
                        setRegionSelected(nextValue)
                        setFormValues({
                          ...formValues,
                          region: nextValue
                        })
                        setSavePrev(nextValue)
                      }
                    }}
                    onSearch={(text) => {
                      // The line below escapes regular expression special characters:
                      // [ \ ^ $ . | ? * + ( )
                      const escapedText = text.replace(
                        /[-\\^$*+?.()|[\]{}]/g,
                        '\\$&'
                      )

                      // Create the regular expression with modified value which
                      // handles escaping special characters. Without escaping special
                      // characters, errors will appear in the console
                      const exp = new RegExp(escapedText, 'i')
                      setRegionOptions(
                        defaultRegionOptions.filter((o) => exp.test(o))
                      )
                    }}
                    onClose={() => setRegionOptions(defaultRegionOptions)}
                  />
                </FormField>
              </Box>
              <Box width="medium" margin={{ bottom: 'xsmall' }}>
                <FormField
                  label={t('notification.instance')}
                  name="instance"
                  required
                  error={errorMessage.instance}
                >
                  <SelectMultiple
                    data-testid="instance-dropdown"
                    id="instance"
                    name="instance"
                    placeholder={t('notification.placeholder_instance')}
                    searchPlaceholder={t(
                      'notification.placeholder_instance_search'
                    )}
                    options={instanceOptions || []}
                    value={formValues?.instance}
                    labelKey="label"
                    valueKey={{ key: 'value', reduce: true }}
                    onChange={({ value: nextValue }) => {
                      if (
                        nextValue.length > 0 &&
                        nextValue.includes(t('notification.all_instances')) &&
                        savePrevInst.includes(t('notification.all_instances'))
                      ) {
                        const index = nextValue.indexOf(
                          t('notification.all_instances')
                        )
                        if (index > -1) {
                          const newArray = nextValue.splice(index - 1, 1)
                          const selectedInstances = []
                          const instanceIdNameMapping = {}
                          newArray.forEach((sel) => {
                            const obj = instanceOptions?.find((instance) => {
                              return instance.value === sel
                            })
                            selectedInstances.push(obj?.label)
                            instanceIdNameMapping[sel] = obj?.label
                          })
                          setSavePrevInst(newArray)
                          setFormValues({
                            ...formValues,
                            instance: newArray,
                            selectedInstanceName: selectedInstances,
                            instanceIdNameMapping
                          })
                        }
                      } else if (
                        nextValue.length > 0 &&
                        nextValue.includes(t('notification.all_instances')) &&
                        !savePrevInst.includes(t('notification.all_instances'))
                      ) {
                        setFormValues({
                          ...formValues,
                          instance: [t('notification.all_instances')],
                          selectedInstanceName: [
                            t('notification.all_instances')
                          ],
                          instanceIdNameMapping: {}
                        })
                        setSavePrevInst([t('notification.all_instances')])
                      } else {
                        const selectedInstances = []
                        const instanceIdNameMapping = {}
                        nextValue.forEach((sel) => {
                          const obj = instanceOptions?.find((instance) => {
                            return instance.value === sel
                          })
                          selectedInstances.push(obj?.label)
                          instanceIdNameMapping[sel] = obj?.label
                        })
                        setFormValues({
                          ...formValues,
                          instance: nextValue,
                          selectedInstanceName: selectedInstances,
                          instanceIdNameMapping
                        })
                        setSavePrevInst(nextValue)
                      }
                    }}
                    disabled={disableInstance || isEdit}
                    onSearch={(text) => {
                      // The line below escapes regular expression special characters:
                      // [ \ ^ $ . | ? * + ( )
                      const escapedText = text.replace(
                        /[-\\^$*+?.()|[\]{}]/g,
                        '\\$&'
                      )

                      // Create the regular expression with modified value which
                      // handles escaping special characters. Without escaping special
                      // characters, errors will appear in the console
                      const exp = new RegExp(escapedText, 'i')
                      setInstanceOptions(
                        defaultInstanceOptions.filter((o) => exp.test(o?.label))
                      )
                    }}
                    onClose={() => setInstanceOptions(defaultInstanceOptions)}
                  />
                </FormField>
              </Box>
              <Box width="medium" margin={{ bottom: 'xsmall' }}>
                <FormField
                  label={t('notification.roles')}
                  name="role"
                  required
                  error={errorMessage.role}
                  disabled={disableRole}
                >
                  <SelectMultiple
                    data-testid="role-dropdown"
                    id="role"
                    name="role"
                    disabled={disableRole || isEdit}
                    placeholder={t('notification.placeholder_role')}
                    searchPlaceholder={t(
                      'notification.placeholder_role_search'
                    )}
                    options={roleOptions || []}
                    value={formValues?.role}
                    labelKey="label"
                    valueKey={{ key: 'value', reduce: true }}
                    onChange={({ value: nextValue }) => {
                      if (
                        nextValue.length > 0 &&
                        nextValue.includes(t('notification.all_roles')) &&
                        savePrevRole.includes(t('notification.all_roles'))
                      ) {
                        const index = nextValue.indexOf(
                          t('notification.all_roles')
                        )
                        if (index > -1) {
                          const newArray = nextValue.splice(index - 1, 1)
                          const selectedRoles = []
                          const roleSlugNameMap = {}
                          newArray.forEach((sel) => {
                            const obj = roleOptions?.find((role) => {
                              return role.value === sel
                            })
                            selectedRoles.push(obj?.label)
                            roleSlugNameMap[sel] = obj?.label
                          })
                          setFormValues({
                            ...formValues,
                            role: newArray,
                            selectedRoleName: selectedRoles,
                            roleSlugNameAppMap: roleSlugNameMap
                          })
                          setSavePrevRole(newArray)
                        }
                      } else if (
                        nextValue.length > 0 &&
                        nextValue.includes(t('notification.all_roles')) &&
                        !savePrevRole.includes(t('notification.all_roles'))
                      ) {
                        setSavePrevRole([t('notification.all_roles')])
                        setFormValues({
                          ...formValues,
                          role: [t('notification.all_roles')],
                          selectedRoleName: [t('notification.all_roles')],
                          roleSlugNameAppMap: {}
                        })
                      } else {
                        setSavePrevRole(nextValue)
                        const selectedRoles = []
                        const roleSlugNameMap = {}
                        nextValue.forEach((sel) => {
                          const obj = roleOptions?.find((role) => {
                            return role.value === sel
                          })
                          selectedRoles.push(obj?.label)
                          roleSlugNameMap[sel] = obj?.label
                        })
                        setFormValues({
                          ...formValues,
                          role: nextValue,
                          selectedRoleName: selectedRoles,
                          roleSlugNameAppMap: roleSlugNameMap
                        })
                      }
                    }}
                    onSearch={(text) => {
                      // The line below escapes regular expression special characters:
                      // [ \ ^ $ . | ? * + ( )
                      const escapedText = text.replace(
                        /[-\\^$*+?.()|[\]{}]/g,
                        '\\$&'
                      )

                      // Create the regular expression with modified value which
                      // handles escaping special characters. Without escaping special
                      // characters, errors will appear in the console
                      const exp = new RegExp(escapedText, 'i')
                      setRoleOptions(
                        defaultRoleOptions.filter((o) => exp.test(o?.label))
                      )
                    }}
                    onClose={() => setRoleOptions(defaultRoleOptions)}
                  />
                </FormField>
              </Box>
            </>

            <Box width="medium" margin={{ bottom: 'xsmall' }}>
              <FormField
                label={t('notification.status')}
                name="status"
                required
                error={errorMessage.status}
              >
                <Dropdown
                  name="status"
                  testId="status-select-dropdown"
                  placeholder={t('notification.placeholder_status')}
                  noBorder
                  value={formValues.status}
                  options={statusOptions}
                  onChangeDropdown={(values) => {
                    setFormValues({
                      ...formValues,
                      status: values
                    })
                  }}
                />
              </FormField>
            </Box>
            {isEdit && (
              <Box width="medium" margin={{ bottom: 'xsmall' }}>
                <FormField label={t('notification.state')} name="state">
                  <Dropdown
                    name="state"
                    testId="state-select-dropdown"
                    placeholder={t('notification.placeholder_state')}
                    noBorder
                    value={formValues.state}
                    options={stateOptions}
                    onChangeDropdown={(values) => {
                      setFormValues({
                        ...formValues,
                        state: values
                      })
                    }}
                  />
                </FormField>
              </Box>
            )}
            {!LDFlags['glcp-notifications-feature-schedule-jhansi'] ? (
              <>
                <Box>
                  <Typography
                    type="text"
                    size="small"
                    color="text-strong"
                    testId="email-checkbox-description-label"
                  >
                    {t('notification.email_checkbox')}
                  </Typography>
                  <Typography
                    type="text"
                    size="small"
                    color="text-strong"
                    testId="email-checkbox-description"
                  >
                    {t('notification.email_checkbox_description')}
                  </Typography>
                  <FormField
                    margin={{ bottom: 'medium' }}
                    name="banner"
                    data-testid="email-notification-checkbox-formfield"
                  >
                    <CheckBox
                      pad="none"
                      data-testid="test-email-checkbox"
                      name="banner"
                      checked={emailChecked}
                      disabled={disableEmail || isEdit}
                      label={t('notification.email')}
                      onChange={(event) => {
                        setFormValues({
                          ...formValues,
                          email: event.target.checked
                        })
                      }}
                    />
                  </FormField>
                </Box>
                <Box>
                  <Typography
                    type="text"
                    size="small"
                    color="text-strong"
                    testId="banner-checkbox-label"
                  >
                    {t('notification.banner_checkbox')}
                  </Typography>
                  <Typography
                    type="text"
                    size="small"
                    color="text-strong"
                    testId="banner-checkbox-description"
                  >
                    {t('notification.banner_checkbox_description')}
                  </Typography>
                  <FormField
                    margin={{ bottom: 'medium' }}
                    name="banner"
                    data-testid="banner-checkbox-formfield"
                  >
                    <CheckBox
                      pad="none"
                      data-testid="test-banner-checkbox"
                      disabled={isEdit}
                      name="banner"
                      checked={formValues.banner}
                      label={t('notification.banner')}
                      onChange={(event) => {
                        setFormValues({
                          ...formValues,
                          banner: event.target.checked
                        })
                      }}
                    />
                  </FormField>
                </Box>
              </>
            ) : (
              <>
                <Box fill margin={{ bottom: 'medium', top: 'small' }}>
                  <Typography type="heading" size="small" color="text-strong">
                    {t('notification.delivery_medium')}
                  </Typography>
                </Box>
                {emailChecked && (
                  <Box margin={{ bottom: 'small' }}>
                    <FormField
                      name="email"
                      data-testid="email-notification-checkbox-formfield"
                    >
                      <CheckBox
                        pad="none"
                        data-testid="test-email-checkbox"
                        name="banner"
                        checked={emailChecked}
                        disabled
                        label={t('notification.new_email_checkbox')}
                        onChange={(event) => {
                          setFormValues({
                            ...formValues,
                            email: event.target.checked
                          })
                        }}
                      />
                    </FormField>
                    <Typography
                      type="text"
                      size="small"
                      color="text-strong"
                      testId="email-checkbox-description"
                    >
                      {t('notification.new_email_checkbox_description')}
                    </Typography>
                  </Box>
                )}
                <Box margin={{ bottom: 'small' }}>
                  <FormField
                    name="banner"
                    data-testid="banner-checkbox-formfield"
                  >
                    <CheckBox
                      pad="none"
                      data-testid="test-banner-checkbox"
                      disabled={isEdit}
                      name="banner"
                      checked={formValues.banner}
                      label={t('notification.banner')}
                      onChange={(event) => {
                        setFormValues({
                          ...formValues,
                          banner: event.target.checked
                        })
                      }}
                    />
                  </FormField>
                  <Typography
                    type="text"
                    size="small"
                    color="text-strong"
                    testId="banner-checkbox-description"
                  >
                    {t('notification.new_banner_checkbox_description')}
                  </Typography>
                </Box>
                <Box>
                  <FormField
                    name="portal"
                    data-testid="portal-checkbox-formfield"
                  >
                    <CheckBox
                      pad="none"
                      data-testid="test-portal-checkbox"
                      disabled
                      name="banner"
                      checked
                      label={t('notification.notification_center')}
                    />
                  </FormField>
                  <Typography
                    type="text"
                    size="small"
                    color="text-strong"
                    testId="portal-checkbox-description"
                  >
                    {t('notification.portal_checkbox_description')}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          {wizardError && (
            <Notification
              backgroundColor="status-critical"
              onClose={() => setWizardError(null)}
              testId="critical-inline-notification"
              text={wizardError}
              icon={<StatusCritical size="medium" />}
            />
          )}
        </Box>
      )}
    </>
  )
}

NotificationTarget.prototypes = {
  setBannerCheck: PropTypes.func.isRequired
}

export default NotificationTarget
