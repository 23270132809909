import { Route, Switch, Redirect } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'
import { Layout } from '../../commoncomponents/layout/Layout'
import { CCSManagerContextProvider } from '../../../context/ccs-manager-context'

const FolderDetailsPage = lazy(() =>
  import('../commoncomponent/folder-details/FolderDetailsPage')
)
const ArubaActivate = lazy(() => import('./ArubaActivate'))
const DeviceDetails = lazy(() =>
  import('./devices/device-details/DeviceDetails')
)

const ArubaActivateRouterContent = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route path="/manage-account/activate/devices/:serial">
          <DeviceDetails />
        </Route>
        <Route path="/manage-account/activate/folders/folder-details">
          <FolderDetailsPage />
        </Route>
        <Route path="/manage-account/activate">
          <ArubaActivate />
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  )
}

const ArubaActivateRouter = () => {
  return (
    <CCSManagerContextProvider>
      <Layout>
        <ArubaActivateRouterContent />
      </Layout>
    </CCSManagerContextProvider>
  )
}

export default ArubaActivateRouter
