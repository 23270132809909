import CustomConsoleLog from './CustomConsoleLog'

// Examples:
// =========
// logger.log({ msg: 'Test: only msg' })
// logger.log({ page: 'App.js', msg: 'Test: page & msg' })
// logger.log({
//   page: 'App.js',
//   section: 'Init',
//   msg: 'Test: page, section & msg'
// })

export default function useLogger() {
  const logger = {
    log: (consoleProps) => CustomConsoleLog(consoleProps)
  }
  return logger
}
