import React, { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { setupInterceptor } from '../utils/api-utils'
import {
  VerifyUser,
  SignUpPage,
  VerifyEmailPage,
  SignOutPage,
  EndSession
} from '../pages/acct-onboarding'
import { useCCSContext } from '../context/ccs-context'
import {
  VisibilityActions,
  useVisibilityContext
} from '../context/visibility-context'
import ResetPasswordPage from '../pages/acct-onboarding/reset-password/reset-password'
import ResetPasswordSuccessPage from '../pages/acct-onboarding/reset-password/reset-password-success'
import AccessErrorPage from '../pages/error/AccessError'
import { formatLDFlags } from '../utils/launchdarkly-utils'
import SessionErrorPage from '../pages/error/SessionError'
import { COPCertificateRedirection } from '../pages/acct-onboarding/cop-redirection/COPCertificateRedirection'
import useLogger from '../hooks/logs/useLogger'
import { setFromCentralSessionStorage } from '../utils/common-utils'
import { isV2, isCoP } from '../utils/feature-flag-utils'
import AppUnavailablePage from '../pages/error/AppUnavailable'
import SREMaintenancePage from '../pages/error/SREMaintenance'
import LoginTermsConditionModal from '../pages/acct-onboarding/login-terms-condition/LoginTermsConditionModal'
import { ActivateMagicLinkRedirection } from '../pages/acct-onboarding/activate-magic-link/ActivateMagicLink'
import InvalidLink404Error from '../pages/error/InvalidLink404Error'

import PostAuthAppRouter from './post-auth-app-router'

const CommonAppRouter = () => {
  const history = useHistory()
  const { dispatchCCSContext, csrfToken } = useCCSContext()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const LDFlags = useFlags()
  const logger = useLogger()
  const pageInfoLog = {
    page: CommonAppRouter.name,
    section: 'Initialize',
    type: 'success',
    count: 'Calling:common-app-router'
  }
  logger?.log({ ...pageInfoLog, msg: 'Common App Router loaded successfully!' })

  const sreManualMaintenance = LDFlags && LDFlags['glcp-sre-manual-maintenance']

  useEffect(() => {
    setupInterceptor(dispatchCCSContext, csrfToken, history, isV2())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isCoP()) {
      if (sreManualMaintenance) {
        history.push('/sre-maintenance')
      } else if (
        window?.location?.href?.endsWith('/sre-maintenance') &&
        sreManualMaintenance !== undefined
      ) {
        history.push('/')
      }
    }
  }, [sreManualMaintenance]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // set the session storage to determine if it is from central
    // needed for COP
    if (isCoP()) setFromCentralSessionStorage()
  }, [])

  useEffect(() => {
    dispatchVisibilityContext({
      type: VisibilityActions.SET_HIDE_WHEN,
      data: {
        key: 'feature',
        value: formatLDFlags(LDFlags)
      }
    })
  }, [LDFlags, dispatchVisibilityContext])

  return (
    <Switch>
      {/* Un-authenticated routes like sign-up will go here */}
      <Route exact path="/accept-certificate/signup">
        <COPCertificateRedirection routerPath="/onboarding/sign-up" />
      </Route>
      <Route exact path="/accept-certificate/login-banner">
        <COPCertificateRedirection routerPath="/terms-and-condition" />
      </Route>
      <Route path="/accept-certificate">
        <COPCertificateRedirection />
      </Route>
      <Route path="/terms-and-condition">
        <LoginTermsConditionModal />
      </Route>
      <Route path="/onboarding/verify-user">
        <VerifyUser />
      </Route>
      <Route exact path="/onboarding/sign-up">
        <SignUpPage />
      </Route>
      <Route exact path="/onboarding/verify-email/:email">
        <VerifyEmailPage />
      </Route>
      <Route exact path="/onboarding/reset-password">
        <ResetPasswordPage />
      </Route>
      <Route exact path="/onboarding/update-password">
        <VerifyUser updatePassword />
      </Route>
      <Route exact path="/onboarding/reset-password-success">
        <ResetPasswordSuccessPage />
      </Route>
      <Route path="/sign-out">
        <SignOutPage />
      </Route>
      <Route path="/end-session">
        <EndSession />
      </Route>
      <Route path="/error/access">
        <AccessErrorPage />
      </Route>
      <Route path="/error/session">
        <SessionErrorPage />
      </Route>
      <Route path="/error/app-unavailable">
        <AppUnavailablePage />
      </Route>
      <Route path="/error/invalid-link">
        <InvalidLink404Error />
      </Route>
      <Route exact path="/sre-maintenance">
        <SREMaintenancePage />
      </Route>
      <Route exact path="/activate">
        <ActivateMagicLinkRedirection />
      </Route>
      <Route path="/">
        <PostAuthAppRouter />
      </Route>
    </Switch>
  )
}

export default CommonAppRouter
