import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { FormField, TextArea, TextInput } from 'grommet'
import lodashGet from 'lodash/get'

import { WizardContext } from '../../../../../../../../components/wizard/WizardContext'

export const ManualLeftColumn = ({ fieldsError }) => {
  const { formValues, setFormValues } = useContext(WizardContext)
  const { t } = useTranslation(['authn', 'iam'])

  return (
    <>
      <FormField
        name="entity_id"
        label={`${t('iam:sso_profiles.view_edit_create.entity_id')}*`}
        error={fieldsError.entityId}
        data-testid="entity-id-form-field"
      >
        <TextInput
          name="entity_id"
          data-testid="entity-id-input"
          value={lodashGet(formValues, 'saml_idp_config.entity_id')}
          onChange={(event) => {
            setFormValues({
              ...formValues,
              saml_idp_config: {
                ...formValues.saml_idp_config,
                entity_id: event.target.value
              }
            })
          }}
        />
      </FormField>
      <FormField
        name="login_url"
        label={`${t('iam:sso_profiles.view_edit_create.domain_login_url')}*`}
        error={fieldsError.loginUrl}
        data-testid="login-url-form-field"
      >
        <TextInput
          name="login_url"
          data-testid="login-url-input"
          value={lodashGet(formValues, 'saml_idp_config.login_url')}
          onChange={(event) => {
            setFormValues({
              ...formValues,
              saml_idp_config: {
                ...formValues.saml_idp_config,
                login_url: event.target.value
              }
            })
          }}
        />
      </FormField>
      <FormField
        name="logout_url"
        label={`${t('iam:sso_profiles.view_edit_create.domain_logout_url')}*`}
        error={fieldsError.logoutUrl}
        data-testid="logout-url-form-field"
      >
        <TextInput
          name="logout_url"
          data-testid="logout-url-input"
          value={lodashGet(formValues, 'saml_idp_config.logout_url')}
          onChange={(event) => {
            setFormValues({
              ...formValues,
              saml_idp_config: {
                ...formValues.saml_idp_config,
                logout_url: event.target.value
              }
            })
          }}
        />
      </FormField>
      <FormField
        name="signing_certificate"
        label={`${t('iam:sso_profiles.view_edit_create.certificate')}*`}
        margin="none"
        error={fieldsError.signingCert}
        data-testid="signing-cert-form-field"
      >
        <TextArea
          name="signing_certificate"
          value={lodashGet(formValues, 'saml_idp_config.signing_certificate')}
          onChange={(event) => {
            setFormValues({
              ...formValues,
              saml_idp_config: {
                ...formValues.saml_idp_config,
                signing_certificate: event.target.value
              }
            })
          }}
          data-testid="signing-cert-input"
        />
      </FormField>
    </>
  )
}

ManualLeftColumn.propTypes = {
  fieldsError: PropTypes.shape({
    entityId: PropTypes.string.isRequired,
    loginUrl: PropTypes.string.isRequired,
    logoutUrl: PropTypes.string.isRequired,
    signingCert: PropTypes.string.isRequired
  }).isRequired
}

export default ManualLeftColumn
