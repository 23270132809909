import React, { useCallback, useState } from 'react'
import { FormPrevious } from 'grommet-icons'
import { PageContent, Page, Anchor, Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'

import { Layout } from '../commoncomponents/layout/Layout'
import { ChatBot } from '../support-chat/ChatBot'
import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'

import { CaseCreationContextProvider } from './creation/context/CaseCreationContext'
import CaseForm from './creation/components/CaseForm'
import CaseConfirmation from './creation/components/CaseConfirmation'

const CaseCreationPageContent = () => {
  const { t } = useTranslation(['support_hub'])
  const [caseData, setCaseData] = useState({})
  const [isCaseCreationSuccess, setIsCaseCreationSuccess] = useState(false)
  const history = useHistory()
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      history.push('/support')
    }
  }
  const handleCaseCreation = useCallback(({ data }) => {
    if (data && data.caseNumber) {
      setCaseData(data)
      setIsCaseCreationSuccess(true)
    }
  }, [])

  return (
    <>
      <Box
        direction="row"
        justify="start"
        pad={{ top: 'small', bottom: 'small', left: 'small' }}
      >
        <VisibilityWrapper hideFor={{ feature: 'glcp-support-home' }}>
          <Anchor
            data-testid="support-hub-link"
            icon={<FormPrevious color="brand" />}
            onClick={() => history.push('/support')}
            label={t('support_hub:support_title')}
            weight="normal"
            onKeyDown={handleKeyDown}
            tabIndex="0"
          />
        </VisibilityWrapper>
      </Box>
      <Page kind="narrow">
        <PageContent>
          <CaseCreationContextProvider>
            {isCaseCreationSuccess ? (
              <CaseConfirmation caseData={caseData} />
            ) : (
              <CaseForm caseCreationCallback={handleCaseCreation} />
            )}
          </CaseCreationContextProvider>
        </PageContent>
        <VisibilityWrapper hideFor={{ feature: 'glcp-support-chat' }}>
          <ChatBot />
        </VisibilityWrapper>
      </Page>
    </>
  )
}

const CaseCreationPage = () => {
  return (
    <Layout>
      <VisibilityWrapper
        hideFor={{
          feature: 'glcp-support-cases',
          deployment: ['COP', 'GLOP']
        }}
        fallbackComponent={<Redirect to="/support" />}
      >
        <CaseCreationPageContent />
      </VisibilityWrapper>
    </Layout>
  )
}

export default CaseCreationPage
