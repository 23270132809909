// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

export const ssoModeEnum = Object.freeze({
  AUTHENTICATION_ONLY: 'authentication-only',
  AUTHORIZATION: 'authorization'
})

export const authorizationEnum = Object.freeze({
  LOCAL: 'Local',
  SAML: 'SAML'
})

export const idpConfigOptions = Object.freeze({
  UPLOAD_FROM_FILE: 'Upload a metadata XML file',
  SPECIFY_URL: 'Specify a metadata URL',
  MANUAL: 'Manual entry of configuration details'
})

export const getAuthorizationText = (status) => {
  let text = ''
  switch (status) {
    case ssoModeEnum?.AUTHENTICATION_ONLY:
      text = authorizationEnum.LOCAL
      break
    case ssoModeEnum?.AUTHORIZATION:
      text = authorizationEnum.SAML
      break
    default:
      break
  }
  return text
}

export const idp = Object.freeze({
  entityIdUrl: 'https://sso.common.cloud.hpe.com',
  acsUrl: 'https://sso.common.cloud.hpe.com/sp/ACS.saml2',
  defaultRelayStateUrl: 'https://common.cloud.hpe.com'
})
