import React, { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import {
  Box,
  FormField,
  TextInput,
  Page,
  PageContent,
  PageHeader,
  Anchor,
  Heading
} from 'grommet'
import { Previous } from 'grommet-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

import {
  Typography,
  Button,
  Notification,
  ActionButton,
  FormTextArea,
  CCSForm,
  Ruler,
  ButtonGroup
} from '../../../components'
import { Layout } from '../../commoncomponents/layout/Layout'
import { get, del, put } from '../../../utils/api-utils'

import RegisterGateway from './RegisterGateway'
import StatusDisplay from './StatusDisplay'
import DeleteGatewayModal from './DeleteGatewayModal'
import { convertStatus, convertAppIdToType, gatewayInfoValidate } from './utils'
import GreenLakeAppCard from './GreenLakeAppCard'
import LoadingContainer from './LoadingContainer'

dayjs.extend(advancedFormat)

const GatewayDetails = () => {
  const { t } = useTranslation(['local-gateway', 'common'])
  const history = useHistory()
  const { gatewayId } = useParams()
  const { oidcUser } = useReactOidc()

  const handleBackBtnClick = () => {
    history.push(`/manage-account/local-gateway/`)
  }

  const [editMode, setEditMode] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [gatewayDetails, setGatewayDetails] = useState({})
  const [gatewayDataNames, setGatewayDataNames] = useState([])
  const [tempEditValues, setTempEditValues] = useState({})
  const [editValidation, setEditValidation] = useState({})

  const [loadingGateway, setLoadingGateway] = useState(true)
  const [loadingGatewayError, setLoadingGatewayError] = useState(false)

  const [deleteGatewaySuccess, setDeleteGatewaySuccess] = useState(false)
  const [deleteGatewayError, setDeleteGatewayError] = useState(false)

  const [editGatewaySuccess, setEditGatewaySuccess] = useState(false)
  const [editGatewayError, setEditGatewayError] = useState(false)

  useEffect(() => {
    setLoadingGateway(true)
    setLoadingGatewayError(false)
    get(`/platform/acpmgr/gateway/${gatewayId}`, {}, oidcUser.access_token)
      .then(
        (response) => {
          if (response.data) {
            setGatewayDetails(response.data)
          }
        },
        () => {
          setLoadingGatewayError(true)
        }
      )
      .finally(() => setLoadingGateway(false))
  }, [gatewayId, oidcUser])

  // get names for edit collision check
  useEffect(() => {
    get(`/platform/acpmgr/getGateways`, {}, oidcUser.access_token).then(
      (response) => {
        if (response.data && response.data.gateways) {
          setGatewayDataNames(
            response.data.gateways
              .filter((e) => e.id !== gatewayId)
              .map((g) => g.name)
          )
        }
      }
    )
  }, [history, oidcUser, gatewayId])

  const deleteGateway = () => {
    del(
      `/platform/acpmgr/gateway/${gatewayId}`,
      {},
      oidcUser.access_token
    ).then(
      () => {
        setDeleteGatewaySuccess(true)
        handleBackBtnClick()
      },
      () => {
        setDeleteGatewayError(true)
      }
    )
  }

  const updateGateway = () => {
    if (!editValidation.isValid) return
    const updatedGatewayDetails = { ...gatewayDetails, ...tempEditValues }
    updatedGatewayDetails.name = updatedGatewayDetails.name.trim()
    updatedGatewayDetails.description = updatedGatewayDetails.description.trim()

    put(
      `/platform/acpmgr/gateway/${gatewayId}`,
      updatedGatewayDetails,
      oidcUser.access_token
    ).then(
      () => {
        setGatewayDetails(updatedGatewayDetails)
        setEditMode(false)
        setEditGatewaySuccess(true)
      },
      () => {
        setEditGatewayError(true)
      }
    )
  }

  const boldText = t('gateway_details.complete_setup')

  return (
    <Page kind="narrow">
      <PageContent>
        <PageHeader
          title={gatewayDetails.name}
          parent={
            <Anchor
              label={t('common:local_gateway.tile_title')}
              icon={<Previous />}
              onClick={handleBackBtnClick}
              data-testid="manage-account-btn"
            />
          }
          actions={
            !(loadingGateway || loadingGatewayError) && (
              <Box>
                <ActionButton
                  actions={[
                    {
                      label: t('gateway_details.delete'),
                      onClick: () => setShowDeleteModal(true)
                    }
                  ]}
                  dropAlign={{
                    left: 'left',
                    top: 'bottom'
                  }}
                  showOneActionAsDropDown
                  testId="single-drop-action-btn"
                />
              </Box>
            )
          }
          data-testid="gateway-name-page-header"
        />
        {/* status notification */}
        {convertStatus(gatewayDetails.status) === 'unregistered' && (
          <Box margin={{ bottom: 'medium' }}>
            <Notification
              backgroundColor="status-warning"
              testId="warning-inline-notification"
              text={
                <Trans
                  i18nKey="gateway_details.complete_setup_text"
                  t={t}
                  boldText={boldText}
                >
                  <strong>{{ boldText }}</strong>
                </Trans>
              }
              type="inline"
            />
          </Box>
        )}
        {/* details header */}
        <PageHeader
          responsive
          size="small"
          pad={{ vertical: 'none' }}
          title={t('gateway_details.details')}
          actions={
            !(loadingGateway || loadingGatewayError) && !editMode ? (
              <Button
                label={t('gateway_details.edit')}
                testId="details-edit-btn"
                onClick={() => {
                  // make editable
                  setEditMode(true)
                  // copy values from description
                  setTempEditValues({ ...gatewayDetails })
                }}
              />
            ) : (
              <ButtonGroup
                buttonList={[
                  {
                    label: t('common:cancel'),
                    secondary: true,
                    testId: 'cancel-edit-btn',
                    onClick: () => {
                      setEditMode(false)
                      setEditValidation({})
                      setEditGatewayError(false)
                    }
                  },
                  {
                    label: t('gateway_details.save_changes'),
                    primary: true,
                    type: 'submit',
                    testId: 'save-edit-btn',
                    onClick: () => {
                      updateGateway()
                    },
                    disabled: !editValidation.isValid,
                    style: { flexShrink: 0 }
                  }
                ]}
              />
            )
          }
        />
        <Ruler />
        <LoadingContainer
          loading={loadingGateway}
          loadingError={loadingGatewayError}
          testId="details"
        >
          <>
            <Box margin={{ bottom: 'small' }}>
              {editGatewayError && (
                <Notification
                  type="inline"
                  backgroundColor="status-critical"
                  testId="gateway-edit-error-notification"
                  text={t('api_messages.edit_gateway_error')}
                />
              )}
            </Box>

            <Box
              direction="row"
              pad={{ bottom: 'small' }}
              data-testid="container-status"
            >
              <Box width="medium">
                <Typography type="text" weight="bold" testId="status">
                  {t('gateway_details.status')}
                </Typography>
              </Box>
              <Box width="large">
                <StatusDisplay
                  status={gatewayDetails.status}
                  statusMessage={gatewayDetails.statusMessage}
                />
              </Box>
            </Box>
            <CCSForm
              errorMessage={editValidation.message || ''}
              testId="gateway-details-form"
            >
              <>
                <Box
                  direction="row"
                  pad={{ bottom: 'small' }}
                  data-testid="container-name"
                >
                  <Box width="medium">
                    <Typography type="text" weight="bold">
                      {t('gateway_details.name')}
                    </Typography>
                  </Box>
                  <Box width="large" data-testid="name-value">
                    {!editMode ? (
                      gatewayDetails.name
                    ) : (
                      <FormField required data-testid="container-gateway-name">
                        <TextInput
                          id="name-field"
                          width="large"
                          type="text"
                          autoComplete="off"
                          value={tempEditValues.name}
                          data-testid="edit-name-field"
                          onFocus={() => {
                            setEditGatewayError(false)
                          }}
                          onChange={(event) => {
                            setTempEditValues({
                              ...tempEditValues,
                              name: event.target.value
                            })
                            setEditValidation(
                              gatewayInfoValidate(
                                {
                                  gatewayDetails: {
                                    name: String(event.target.value).trim()
                                  }
                                },
                                t,
                                gatewayDataNames
                              )
                            )
                          }}
                        />
                      </FormField>
                    )}
                  </Box>
                </Box>
                {/* description */}
                <Box
                  direction="row"
                  pad={{ bottom: 'small' }}
                  data-testid="container-description"
                >
                  <Box width="medium">
                    <Typography type="text" weight="bold">
                      {t('gateway_details.description')}
                    </Typography>
                  </Box>
                  <Box width="large" data-testid="description-value">
                    {!editMode ? (
                      gatewayDetails.description
                    ) : (
                      <FormTextArea
                        width="large"
                        inputType="text"
                        name="form-text-input-1"
                        required
                        testId="edit-description-field"
                        onFocus={() => setEditGatewayError(false)}
                        value={tempEditValues.description}
                        onChange={(event) => {
                          setTempEditValues({
                            ...tempEditValues,
                            description: event.target.value
                          })
                          setEditValidation(
                            gatewayInfoValidate(
                              {
                                gatewayDetails: {
                                  name: tempEditValues.name
                                }
                              },
                              t,
                              gatewayDataNames
                            )
                          )
                        }}
                      />
                    )}
                  </Box>
                </Box>
                {/* creation date */}
                <Box
                  direction="row"
                  pad={{ bottom: 'small' }}
                  data-testid="container-date"
                  margin={{ bottom: 'small' }}
                >
                  <Box width="medium">
                    <Typography type="text" weight="bold">
                      {t('gateway_details.creation_date')}
                    </Typography>
                  </Box>
                  <Box width="large">
                    {dayjs(gatewayDetails.creationDate).format('MMMM Do, YYYY')}
                  </Box>
                </Box>
              </>
            </CCSForm>
          </>
        </LoadingContainer>

        {/* Applications heading */}
        <PageHeader
          size="small"
          pad={{ vertical: 'none' }}
          title={
            <Heading level={2} margin="none">
              {t('gateway_details.applications')}
            </Heading>
          }
        />
        <Ruler />
        <LoadingContainer
          loading={loadingGateway}
          loadingError={loadingGatewayError}
          testId="apps"
        >
          {gatewayDetails && gatewayDetails.apps && (
            <Box
              pad={{ bottom: 'small' }}
              data-testid="container-application"
              margin={{ bottom: 'small' }}
              width="large"
            >
              {gatewayDetails.apps.map((appId) => {
                if (convertAppIdToType() === 'greenlake_proxy') {
                  return (
                    <GreenLakeAppCard
                      key={appId}
                      gatewayId={gatewayId}
                      appId={appId}
                    />
                  )
                }
                return null
              })}
            </Box>
          )}
        </LoadingContainer>
        {convertStatus(gatewayDetails.status) === 'unregistered' && (
          <>
            <PageHeader
              size="small"
              pad={{ vertical: 'none' }}
              title={
                <Heading level={2} margin="none">
                  {t('gateway_details.complete_setup')}
                </Heading>
              }
            />
            <Ruler />
            <RegisterGateway />
          </>
        )}
      </PageContent>
      {showDeleteModal && (
        <DeleteGatewayModal
          setShowModal={setShowDeleteModal}
          deleteGateway={deleteGateway}
          gatewayName={gatewayDetails.name}
        />
      )}

      {(deleteGatewaySuccess || deleteGatewayError) && (
        <Notification
          backgroundColor={
            deleteGatewaySuccess ? 'status-ok' : 'status-critical'
          }
          text={
            <span
              data-testid={
                deleteGatewaySuccess
                  ? 'delete-notification-status-ok'
                  : 'delete-notification-status-critical'
              }
            >
              {deleteGatewaySuccess
                ? t('api_messages.delete_gateway_success')
                : t(`api_messages.delete_gateway_error`)}
            </span>
          }
          onClose={() => {
            setDeleteGatewaySuccess(false)
            setDeleteGatewayError(false)
          }}
        />
      )}
      {editGatewaySuccess && (
        <Notification
          backgroundColor="status-ok"
          text={
            <span data-testid="gateway-edit-success-notification">
              {t('api_messages.edit_gateway_success')}
            </span>
          }
          onClose={() => {
            setEditGatewaySuccess(false)
          }}
        />
      )}
    </Page>
  )
}

const GatewayDetailsPage = () => {
  return (
    <Layout>
      <GatewayDetails align="start" justify="start" />
    </Layout>
  )
}
export default GatewayDetailsPage
