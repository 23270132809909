import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { FormInput } from '../../../../components'

const EmailTextInput = ({ isSubmitting, errorMsg, setErrorMsg }) => {
  const { t } = useTranslation(['common'])
  return (
    <FormInput
      name="email"
      inputType="text"
      label={`${t('common:email')}*`}
      labelHelper={t('iam:igc_users.email_text_input')}
      required
      onChange={(value) => {
        if (!isEmpty(value?.target?.value)) {
          setErrorMsg('')
        }
      }}
      error={errorMsg}
      disabled={isSubmitting}
      testId="add-user-email"
    />
  )
}

EmailTextInput.propTypes = {
  /**
   * Button disable flag
   */
  isSubmitting: PropTypes.bool.isRequired,

  /**
   * Error message to display
   */
  errorMsg: PropTypes.string,

  /**
   * Set error message to display
   */
  setErrorMsg: PropTypes.func
}

EmailTextInput.defaultProps = {
  errorMsg: '',
  setErrorMsg: () => {}
}

export default EmailTextInput
