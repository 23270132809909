import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import map from 'lodash/fp/map'
import compose from 'lodash/fp/compose'

import { get } from '../../../../../../utils/api-utils'
import { DataTable } from '../../../../../../components'
import { displayApiError } from '../../../../../../utils/error-handling-utils'

const BillingAccountDataTable = ({ customerId }) => {
  const { t } = useTranslation(['manage'])
  const [apiError, setApiError] = useState(null)

  const [viewData, setViewData] = useState([])
  const { oidcUser } = useReactOidc()

  const itemsPerPage = 10
  const [page, setPage] = useState(1)
  const [totalAccounts, setTotalAccounts] = useState(0)
  const [pageData, setPageData] = useState([])

  const formatRowDetails = (data) => {
    const orders = data
    return compose(
      map((item) => {
        const newItem = { ...item }
        newItem.soldToId = item?.soldTo?.partyId || ''

        return newItem
      })
    )(orders)
  }

  const fetchBillingAccount = useCallback(() => {
    get(
      '/marketplace/v1alpha1/billing-accounts',
      {},
      oidcUser.access_token,
      false,
      {},
      { 'x-account-id': customerId }
    ).then(
      (response) => {
        if (response?.data?.items) {
          const formattedData = formatRowDetails(response?.data?.items || [])
          setViewData(formattedData)
          setPageData(formattedData?.slice(0, itemsPerPage))
          setTotalAccounts(formattedData?.length)
        } else {
          setViewData([])
          setPageData([])
        }
        setApiError(null)
      },
      (error) => {
        setViewData([])
        setPageData([])
        setApiError(error, t)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t])

  useEffect(() => {
    fetchBillingAccount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPageData(viewData?.slice(0, itemsPerPage))
    const finalPageData = viewData?.slice(
      page === 1 ? 0 : itemsPerPage * (page - 1),
      page * itemsPerPage
    )
    setPageData(finalPageData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const columns = [
    {
      property: 'soldToId',
      type: 'string',
      header: t('billing_accounts.sold_to_Id')
    },
    {
      property: 'name',
      type: 'string',
      header: t('billing_accounts.billing_account_name')
    },
    {
      property: 'status',
      type: 'string',
      header: t('billing_accounts.account_status')
    },
    {
      property: 'createdAt',
      type: 'date',
      header: t('billing_accounts.created_on'),
      render: (datum) => {
        return dayjs(datum?.createdAt).format('MM/DD/YYYY')
      }
    },
    {
      property: 'lastUpdatedAt',
      type: 'date',
      header: t('billing_accounts.last_updated'),
      render: (datum) => {
        return dayjs(datum?.lastUpdatedAt).format('MM/DD/YYYY')
      }
    }
  ]

  return (
    <Box pad={{ horizontal: 'xxsmall', top: 'small' }}>
      <DataTable
        pagination={{
          totalItems: totalAccounts,
          itemsPerPage,
          page,
          setPage
        }}
        grid={{
          columns,
          data: pageData
        }}
        summary={{
          entityName: t('billing_accounts.summary_count')
        }}
        testId="billing-account-table"
      />

      {apiError &&
        displayApiError(apiError, t, () => {
          setApiError(null)
        })}
    </Box>
  )
}

BillingAccountDataTable.propTypes = {
  customerId: PropTypes.string.isRequired
}

export { BillingAccountDataTable }
