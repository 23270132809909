import React, { useEffect, useState } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { Trash } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { uniqBy, isEqual } from 'lodash'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  Typography,
  Button,
  Search,
  ButtonGroup,
  Tooltip,
  Loader,
  Notification
} from '../../../../components'
import NewPermissionsModal from '../create-role/NewPermissionsModal'
import { getPermissionsData } from '../../treeBuilder/treeBuilder'
import {
  AUTHZActions,
  useAUTHZContext
} from '../../../../context/authz-context'
import VisibilityWrapper from '../../visibility-wrapper/VisibilityWrapper'
import { get } from '../../../../utils/api-utils'
import { displayNotification } from '../../../../utils/notificiation-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'

const PermissionList = ({
  onSubmit,
  showEdit,
  isEditable,
  selectedRole,
  setRole,
  inCCSManager
}) => {
  const { t } = useTranslation(['authz'])
  const {
    dispatchAUTHZContext,
    selectedRoleUpdateForm,
    mandatoryResPermissions
  } = useAUTHZContext()
  const [edit, setEdit] = useState(false)
  const [count, setCount] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [showNotification, setShowNotification] = useState(null)
  const [loading, setLoading] = useState(false)
  const { oidcUser } = useReactOidc()

  const application = {
    applicationId: selectedRole.application_id,
    selectedApplication: selectedRole.application_name
  }
  const [policies, setPolicies] = useState(selectedRole.resourcePolicies || [])
  const [backupRoles, setBackupRoles] = useState(
    JSON.parse(JSON.stringify(selectedRole.resource_policies || []))
  )

  const resourcePolicies = selectedRole.resourcePolicies || []

  useEffect(() => {
    let policiesList = []
    const list = selectedRole.resourcePolicies || []
    if (!searchText) {
      policiesList = list
    } else {
      const search = searchText.toLowerCase()
      list.forEach((policy) => {
        if (
          policy?.resource?.matcher?.toLowerCase().includes(search) ||
          policy?.resource?.name?.toLowerCase().includes(search.toLowerCase())
        ) {
          policiesList.push(policy)
        } else {
          const permissions = policy?.permissions.filter(
            (p) =>
              p?.slug?.toLowerCase().includes(search) ||
              p?.name?.toLowerCase().includes(search.toLowerCase())
          )
          if (permissions.length) {
            policiesList.push({
              ...policy,
              permissions
            })
          }
        }
      })
    }
    const newCount = policiesList.reduce(
      (res, item) => res + item?.permissions?.length,
      0
    )
    setCount(newCount)
    setPolicies(policiesList.filter((item) => item?.permissions?.length))
  }, [searchText, selectedRole, setPolicies])

  useEffect(() => {
    if (application.applicationId && !mandatoryResPermissions.length) {
      setLoading(true)
      get(
        `/authorization/ui/v1/applications/${application.applicationId}/application_resources`,
        {},
        oidcUser.access_token
      ).then(
        (response) => {
          const permissionData = getPermissionsData(response?.data)
          // store mandatory res data to context
          dispatchAUTHZContext({
            type: AUTHZActions.SET_MANDATORY_RES_PERMISSIONS,
            data: permissionData?.mandatoryPermission || []
          })
          setLoading(false)
        },
        (error) => {
          setShowNotification(displayApiError(error, t, setShowNotification))
          setLoading(false)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    application.applicationId,
    oidcUser.access_token,
    mandatoryResPermissions.length
  ])

  const onEdit = () => {
    setEdit(true)
  }

  const onAddPermission = () => {
    setShowModal(true)
  }

  const handleSubmit = () => {
    setEdit(false)
    const data = {
      add: [],
      delete: [],
      update: []
    }
    let backupData = [...backupRoles]
    selectedRole?.resourcePolicies.forEach((item) => {
      if (!item?.permissions || !item?.permissions?.length) {
        data.delete.push(item?.resource)
      } else {
        const backupResource = backupRoles.find(
          (br) => item.resource.matcher === br.resource.matcher
        )
        if (backupResource) {
          backupData = backupData.filter(
            (br) => item.resource.matcher !== br.resource.matcher
          )
          if (!isEqual(backupResource.permissions, item?.permissions)) {
            data.update.push(item)
          }
        } else {
          data.add.push(item)
        }
      }
    })
    if (backupData.length) {
      data.delete = uniqBy(
        [...data.delete, ...backupData.map((item) => item.resource)],
        'matcher'
      )
    }
    onSubmit(data)
    const newResources = policies.filter(
      (item) => item?.permissions?.length > 0
    )
    setBackupRoles(JSON.parse(JSON.stringify(newResources)))
  }

  const handleReset = () => {
    setEdit(false)
    dispatchAUTHZContext({
      type: AUTHZActions.CLEAR_PERMISSIONS_DATA
    })
    selectedRole.resourcePolicies = JSON.parse(JSON.stringify(backupRoles))
    selectedRole.resource_policies = JSON.parse(JSON.stringify(backupRoles))
    dispatchAUTHZContext({
      type: AUTHZActions.SET_SELECTED_ROLE,
      data: {
        ...selectedRole
      }
    })
    setRole({
      ...selectedRole
    })
  }

  const isMandatory = (policy) => {
    const mandatory =
      policy.permissions.length === 1 &&
      mandatoryResPermissions.includes(policy.resource.matcher)
    if (mandatory) {
      // validate failed, display notification
      setShowNotification(
        displayNotification(
          t('roles.selected_resource_need_one_permission'),
          'warning',
          setShowNotification
        )
      )
    } else {
      setShowNotification(null)
    }
    return mandatory
  }

  const deletePermission = (datum) => {
    const updatedPolicies = resourcePolicies.map((resourcePolicy) => {
      if (resourcePolicy.resource.matcher === datum.resource) {
        const item = resourcePolicy.permissions.filter((permission) => {
          return permission.slug !== datum.slug
        })

        resourcePolicy.permissions = item
        const formUpdatedPolicyIndex =
          selectedRoleUpdateForm.resource_policies.update &&
          selectedRoleUpdateForm.resource_policies.update.findIndex(
            (updatedResourcePolicy) => {
              return (
                updatedResourcePolicy.resource.matcher ===
                resourcePolicy.resource.matcher
              )
            }
          )

        if (formUpdatedPolicyIndex !== -1) {
          selectedRoleUpdateForm.resource_policies.update[
            formUpdatedPolicyIndex
          ].permissions = resourcePolicy.permissions
        } else {
          selectedRoleUpdateForm.resource_policies.update.push(resourcePolicy)
        }
      }
      return resourcePolicy
    })
    setPolicies(updatedPolicies.filter((item) => item?.permissions?.length))
    dispatchAUTHZContext({
      type: AUTHZActions.SET_SELECTED_ROLE_UPDATE_FORM,
      data: selectedRoleUpdateForm
    })
  }

  return (
    <>
      {showNotification}
      <Box
        align="center"
        gap="medium"
        direction="row"
        justify="between"
        margin={{ bottom: 'small', top: 'large' }}
      >
        <Typography
          level="2"
          type="heading"
          weight="normal"
          testId="dynamic-view-edit-permissions-form-title"
        >
          <>{t('assignments.permission_plural')}</>
        </Typography>
        <VisibilityWrapper
          hideFor={{
            account: ['TENANT']
          }}
          rbac={{
            resource: '/ccs/authorization',
            permission: 'ccs.authorization.edit'
          }}
        >
          <Box justify="start" direction="row" gap="small">
            <>
              {showEdit && !edit && isEditable && (
                <Button
                  secondary
                  onClick={onEdit}
                  testId="edit-permission-btn"
                  label={t('common:edit')}
                />
              )}
              {showEdit && edit && (
                <Button
                  secondary
                  onClick={onAddPermission}
                  testId="add-permissions-btn"
                  label={t('assignments.add_permissions')}
                />
              )}
            </>
          </Box>
        </VisibilityWrapper>
      </Box>
      <Box
        margin={{ bottom: 'small', top: 'small' }}
        border={{ side: 'bottom', color: 'border-weak' }}
        pad={selectedRole?.tags?.readonly && { bottom: 'medium' }}
      >
        {selectedRole?.tags?.readonly && (
          <Notification
            type="inline"
            backgroundColor="status-warning"
            text={t('roles.readonly_text', {
              roleName: selectedRole?.name
            })}
            testId="warning-inline-notification"
          />
        )}
      </Box>
      <Box>
        <Box margin={{ vertical: 'medium' }}>
          <Search
            value={searchText}
            testId="search-permissions-field"
            handleCustomSearch={setSearchText}
          />
        </Box>
        {loading ? (
          <Loader testId="permissions-list-loader" />
        ) : (
          policies &&
          policies.length > 0 &&
          policies.map((policy) => (
            <Box key={policy?.resource?.matcher} margin={{ top: 'small' }}>
              <Typography
                type="text"
                emphasis
                testId={`permissions-heading-${policy?.resource?.matcher}`}
                margin={{ vertical: 'xsmall', horizontol: 'xsmall' }}
              >
                <Tooltip
                  dropProps={{ align: { bottom: 'top' } }}
                  info={policy?.resource?.matcher}
                  testId={`permissions-tooltip-${policy?.resource?.matcher}`}
                >
                  {policy?.resource?.name ?? policy?.resource?.matcher}
                </Tooltip>
              </Typography>
              {policy?.permissions?.map((permission) => (
                <Box
                  direction="row"
                  justify="between"
                  key={`${permission.slug}`}
                >
                  <Typography
                    type="text"
                    key={permission.slug}
                    testId={`permissions-subheading-${permission.slug}`}
                    margin={{ vertical: 'xxsmall', bottom: 'xsmall' }}
                  >
                    <Tooltip
                      dropProps={{ align: { bottom: 'top' } }}
                      info={permission.slug}
                      testId={`permissions-tooltip-${permission.slug}`}
                    >
                      {permission.name ?? permission.slug}
                    </Tooltip>
                  </Typography>
                  {edit && count > 1 && (
                    <Button
                      onClick={() =>
                        !isMandatory(policy) &&
                        deletePermission({
                          slug: permission.slug,
                          resource: policy.resource.matcher
                        })
                      }
                      icon={<Trash />}
                      testId={`remove-button-${permission.slug}`}
                    />
                  )}
                </Box>
              ))}
            </Box>
          ))
        )}
        {edit && (
          <Box
            direction="row"
            justify="start"
            gap="small"
            margin={{ top: 'small' }}
          >
            <ButtonGroup
              buttonList={[
                {
                  id: 1,
                  label: t('manage:save_changes'),
                  primary: true,
                  testId: 'permission-edit-save-btn',
                  onClick: handleSubmit
                },
                {
                  id: 2,
                  label: t('manage:cancel'),
                  default: true,
                  testId: 'permission-edit-cancel-btn',
                  onClick: handleReset
                }
              ]}
              testId="two-buttons"
            />
          </Box>
        )}
      </Box>
      {showModal && (
        <NewPermissionsModal
          open={showModal}
          setRole={setRole}
          setOpen={setShowModal}
          application={application}
          selectedRole={selectedRole}
          setPolicies={setPolicies}
          inCCSManager={inCCSManager}
          rolesPagePolicies={resourcePolicies}
        />
      )}
    </>
  )
}

PermissionList.propTypes = {
  showEdit: PropTypes.bool,
  selectedRole: PropTypes.any,
  setRole: PropTypes.func,
  onSubmit: PropTypes.func,
  isEditable: PropTypes.bool,
  inCCSManager: PropTypes.bool
}

PermissionList.defaultProps = {
  showEdit: false,
  selectedRole: {},
  setRole: null,
  onSubmit: null,
  isEditable: false,
  inCCSManager: false
}

export default PermissionList
