import { FormField, TextInput, TextArea, CheckBoxGroup } from 'grommet'
import styled from 'styled-components'
import lodashGet from 'lodash/get'

import { isCoP } from '../../../../../../../utils/feature-flag-utils'
import { getErrorMessage, post } from '../../../../../../../utils/api-utils'

import { validateXMLFile } from './validation-utils'

// All the page-utils such as functions,objects can be listed here
export const HiddenInput = styled.input.attrs(() => ({
  type: 'file',
  id: 'upload-file'
}))`
  opacity: 0;
  position: absolute;
  z-index: -1;
`
export const StyledFormField = styled(FormField)`
  & > div {
    border: none;
  }
`
export const StyledTextInput = styled(TextInput)`
  background: rgba(0, 0, 0, 0.04);
  color: #444444;
  opacity: 1;
  border: none;
`
export const StyledTextArea = styled(TextArea)`
  background: rgba(0, 0, 0, 0.04);
  color: #444444;
  opacity: 1;
  border: none;
`
export const StyledCheckBoxGroup = styled(CheckBoxGroup)`
  label {
    font-weight: normal;
  }
`

export const defaultAuthorizationMethods = {
  auth_method: '',
  scope_of_sso: ''
}

export const defaultAuthSettingError = {
  method: '',
  scope: ''
}

export const defaultSessionBasedSamlModalError = {
  hpeCcsAttribute: ''
}

export const defaultFieldsError = {
  entityId: '',
  loginUrl: '',
  logoutUrl: '',
  uploadFile: '',
  signingCert: '',
  metadataFile: '',
  metadataUrl: '',
  validateUrl: ''
}

export const mbInBytes = 1000000

export const IDPDetailsDefaultFormValues = {
  saml_idp_config: {
    entity_id: '',
    logout_url: '',
    login_url: '',
    signing_certificate: '',
    file_info: {},
    metadata_url: '',
    config_option: ''
  }
}

export const convertFileSizeToString = (bytes, i18nTranslate) => {
  let retString = ''
  // Following the UX in showing the size as KB with one decimal precision,
  // as the file size is unlikely to be very large (The BE team currently has
  // asked the UI to limit the size to 1 MB).
  // The exception to KB is when a very small file size might show 0.0, use bytes
  // instead.
  if (bytes < 50) {
    retString = i18nTranslate('saml_sso.cert_size_bytes', {
      size: bytes
    })
  } else if (bytes >= mbInBytes - 50) {
    // Corner corner case, but may as well show it in MB for readability
    // Anything that would round to up/show 1000.0 KB should be 1.0 MB instead.
    const mBytes = bytes / mbInBytes
    const mBytesStr = mBytes.toFixed(1)
    retString = i18nTranslate('saml_sso.cert_size_mb', {
      size: mBytesStr
    })
  } else {
    const kBytes = bytes / 1000
    const kBytesStr = kBytes.toFixed(1)
    retString = i18nTranslate('saml_sso.cert_size_kb', {
      size: kBytesStr
    })
  }
  return retString
}

export const recoveryUserDefaultFormValues = !isCoP()
  ? {
      recovery_user: {
        recoveryUserEnabled: true,
        recoveryEmail: '',
        recoveryPassword: '',
        pocEmail: ''
      }
    }
  : {}

export const attributeMappingDefaultFormValues = {
  attribute_mapping: {
    email: 'NameId',
    firstName: 'FirstName',
    lastName: 'LastName',
    hpeCcsAttribute: 'hpe_ccs_attribute',
    idpValue: '',
    idleTimeout: 60,
    assertionEncrytion: false,
    encryptionMethod: ''
  }
}

export const samlEncryptionDefaultFormValues = {
  saml_encryption: {
    common_name: '',
    country_code: '',
    country: '',
    state: '',
    city: '',
    organization: '',
    organization_unit: '',
    valid_days: 365,
    subject_alternative_names: '',
    key_algorithm: '',
    key_size: '',
    signature_algorithm: ''
  }
}

export const copyToClipboard = (value) => {
  navigator.clipboard.writeText(value)
}

export const SAML_SSO_SETUP_GUIDE_LINK =
  'https://support.hpe.com/hpesc/public/docDisplay?docId=a00120892en_us&page=GUID-F86AA0D3-D2D7-4B10-A041-6496E97D0633.html'

export const ENCRYPTION_METHOD_ENUM = {
  SELF_SIGNED_METHOD: 'SELF_SIGNED_METHOD',
  CSR_METHOD: 'CSR_METHOD'
}

export const ENCRYPTION_STATUS_ENUM = {
  SELF_SIGNED_CERTIFICATE_GENERATED: 'SELF_SIGNED_CERTIFICATE_GENERATED',
  CSR_GENERATED: 'CSR_GENERATED',
  CSR_CERTIFICATE_GENERATED: 'CSR_CERTIFICATE_GENERATED'
}

export const encryptionMethodi18nKey = {
  SELF_SIGNED_METHOD: 'saml_sso.saml_encryption.self_signed_certificate',
  CSR_METHOD:
    'saml_sso.saml_encryption.certificate_authority_signed_certificate'
}

export const encryptionMethodOption = (t) => {
  return Object.keys(ENCRYPTION_METHOD_ENUM)?.map((value) => ({
    label: t(encryptionMethodi18nKey[value]),
    value
  }))
}

export const IMPORT_METHOD_ENUM = {
  FILE: 'FILE',
  TEXT: 'TEXT'
}

export const MAPPING_ATTRIBUTES_LINK =
  'https://support.hpe.com/hpesc/public/docDisplay?docId=a00120892en_us&page=GUID-237A2D36-D5D3-4514-915F-42B2ACDF825C.html'

export const conditionallyClearFormValues = (formValues, setFormValues) => {
  if (lodashGet(formValues, 'saml_idp_config.entity_id') !== '') {
    setFormValues({
      ...formValues,
      saml_idp_config: {
        ...formValues.saml_idp_config,
        file_info: {},
        entity_id: '',
        login_url: '',
        logout_url: '',
        signing_certificate: ''
      }
    })
  }
}

export const verifyFile = (
  event,
  fieldsError,
  setFieldsError,
  formValues,
  setFormValues,
  setAttemptedAdvance,
  setFormError,
  i18nTranslate,
  accessToken,
  api,
  defaultErrorFields
) => {
  if (event.target.files.length > 0 && event.target.files[0]) {
    const file = event.target.files[0]

    if (!validateXMLFile(file) || (file.size && file.size > mbInBytes)) {
      conditionallyClearFormValues(formValues, setFormValues)

      if (!validateXMLFile(file)) {
        setFieldsError({
          ...fieldsError,
          metadataFile: i18nTranslate('saml_sso.invalid_file_type')
        })
      } else {
        setFieldsError({
          ...fieldsError,
          metadataFile: i18nTranslate('saml_sso.invalid_file')
        })
      }
    } else {
      const fileSize = file.size
        ? convertFileSizeToString(file.size, i18nTranslate)
        : ''
      setFieldsError(defaultErrorFields)

      setAttemptedAdvance(false)

      const formData = new FormData()
      formData.append('metadata_file', file)

      post(api, formData, accessToken, {
        'Content-Type': 'multipart/form-data'
      }).then(
        (response) => {
          setFormValues({
            ...formValues,
            saml_idp_config: {
              ...formValues.saml_idp_config,
              file_info: { name: file.name, size: fileSize },
              entity_id: response.data.entity_id || response.data.entityId,
              login_url: response.data.login_url || response.data.loginUrl,
              logout_url: response.data.logout_url || response.data.logoutUrl,
              signing_certificate:
                response.data.signing_certificate || response.data.certificate
            }
          })
        },
        (error) => {
          conditionallyClearFormValues(formValues, setFormValues)
          setFormError(getErrorMessage(error, i18nTranslate))
        }
      )
    }
  }
}
