import { useContext } from 'react'
import DayJS from 'react-dayjs'
import PropTypes from 'prop-types'
import { Box, NameValueList, NameValuePair, ResponsiveContext } from 'grommet'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'

import { Loader, Typography } from '../../../../components'

const GroupDetailDetailsSection = ({ groupDetailsData, parentLoading }) => {
  const { t } = useTranslation(['common', 'iam'])
  const size = useContext(ResponsiveContext)
  const {
    nameValuePair: { name }
  } = useContext(ThemeContext)

  return (
    <Box width="xxlarge" pad={{ top: 'medium' }}>
      <Typography type="heading" level="2">
        {t('common:details')}
      </Typography>
      <Box pad={{ top: 'medium' }} direction="column" justify="start">
        <Box width="large" align="start" fill gap="medium">
          {parentLoading ? (
            <Loader testId="group-details-name-value-pair-loader" />
          ) : (
            <NameValueList
              nameProps={{ width: 'small' }}
              pairProps={{ direction: size === 'xsmall' ? 'column' : 'row' }}
            >
              <NameValuePair
                name={
                  <Typography
                    testId="group-details-name-text"
                    type="text"
                    {...name}
                  >
                    {t('common:name')}
                  </Typography>
                }
              >
                <Typography testId="group-details-name" type="text">
                  {groupDetailsData?.displayName || '--'}
                </Typography>
              </NameValuePair>
              <NameValuePair
                name={
                  <Typography
                    testId="group-details-description-text"
                    type="text"
                    {...name}
                  >
                    {t('common:description')}
                  </Typography>
                }
              >
                <Typography testId="group-details-description" type="text">
                  {groupDetailsData[
                    'urn:ietf:params:scim:schemas:extensions:hpe-greenlake:2.0:Group'
                  ]?.groupDescription || '--'}
                </Typography>
              </NameValuePair>

              <NameValuePair
                name={
                  <Typography
                    testId="group-details-created-date-text"
                    type="text"
                    {...name}
                  >
                    {t('common:date_created')}
                  </Typography>
                }
              >
                <Typography testId="group-details-created-date" type="text">
                  {groupDetailsData?.meta?.created ? (
                    <DayJS format="LL">{groupDetailsData?.meta?.created}</DayJS>
                  ) : (
                    '--'
                  )}
                </Typography>
              </NameValuePair>

              <NameValuePair
                name={
                  <Typography
                    testId="group-details-last-updated-text"
                    type="text"
                    {...name}
                  >
                    {t('common:last_updated')}
                  </Typography>
                }
              >
                <Typography testId="group-details-last-updated" type="text">
                  {groupDetailsData?.meta?.lastModified ? (
                    <DayJS format="LL">
                      {groupDetailsData?.meta?.lastModified}
                    </DayJS>
                  ) : (
                    '--'
                  )}
                </Typography>
              </NameValuePair>
            </NameValueList>
          )}
        </Box>
      </Box>
    </Box>
  )
}

GroupDetailDetailsSection.propTypes = {
  groupDetailsData: PropTypes.object.isRequired,
  parentLoading: PropTypes.bool
}

GroupDetailDetailsSection.defaultProps = {
  parentLoading: false
}

export default GroupDetailDetailsSection
