import React, { useState } from 'react'
import { Box } from 'grommet'
import { CircleAlert } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  Typography,
  ModalDialog,
  ModalHeader,
  FormInput,
  Button,
  CCSForm
} from '../../../../components'

const SearchCustomerCaseModal = ({ onSetOpen, cb }) => {
  const { t } = useTranslation(['common', 'support_access_manager'])
  const [caseInfo, setCaseInfo] = useState({
    case_id: '',
    pcid: ''
  })

  const [errorOccurred] = useState('')

  const closeDetailsModal = () => {
    onSetOpen(false)
  }
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Box>
              <Typography level="2" testId="case-title" type="heading">
                {t('support_access_manager:search_customer_case_modal.title')}
              </Typography>
              <Typography
                margin={{ bottom: 'small', top: 'small' }}
                type="text"
                testId="case-desc"
              >
                {t('support_access_manager:search_customer_case_modal.desc')}
              </Typography>
            </Box>
          }
          onClose={closeDetailsModal}
        />
      }
      content={
        <Box
          direction="column"
          gap="small"
          justify="between"
          align="start"
          margin={{ top: 'medium' }}
        >
          <CCSForm
            value={caseInfo}
            onChange={setCaseInfo}
            errorMessage={errorOccurred}
            testId="search-customer-case-form"
            validate="blur"
            onSubmit={() => {
              closeDetailsModal()
              cb(caseInfo)
            }}
            buttons={
              <Box direction="row" justify="end" gap="medium">
                <Button
                  primary
                  testId="submit-enable-sa-btn"
                  type="submit"
                  label={t(
                    'support_access_manager:search_customer_case_modal.search_case'
                  )}
                />

                <Button
                  default
                  testId="clear-search-btn"
                  alignSelf="end"
                  label={t('common:cancel')}
                  onClick={closeDetailsModal}
                />
              </Box>
            }
          >
            <Box align="start" margin={{ bottom: 'small' }}>
              <FormInput
                testId="case-form-field"
                width="100%"
                required
                label={t(
                  'support_access_manager:search_customer_case_modal.case_id'
                )}
                name="case_id"
                validate={(value) => {
                  if (value === '') {
                    return false
                  }
                  let msg = ''
                  if (value.length > 50) {
                    msg = {
                      message: t(
                        'support_access_manager:search_customer_case_modal.case_id_error_msg_updated'
                      )
                    }
                    if (msg && value) {
                      msg = msg.message
                      return msg || false
                    }
                  }

                  if (msg && value) {
                    msg = msg.message
                  }
                  return msg || false
                }}
                inputType="text"
                placeholder={t(
                  'support_access_manager:search_customer_case_modal.case_id_placeholder'
                )}
              />
            </Box>
            <Box align="start">
              <FormInput
                testId="pcid-form-field"
                width="100%"
                required
                label={t(
                  'support_access_manager:search_customer_case_modal.pcid'
                )}
                name="pcid"
                validate={(value) => {
                  if (value === '') {
                    return false
                  }
                  let msg = ''
                  if (value.length > 32) {
                    msg = {
                      message: t(
                        'support_access_manager:search_customer_case_modal.pcid_error_msg'
                      )
                    }
                    if (msg && value) {
                      msg = msg.message
                      return msg || false
                    }
                  }
                  if (msg && value) {
                    msg = msg.message
                  }
                  return msg || false
                }}
                inputType="text"
                placeholder={t(
                  'support_access_manager:search_customer_case_modal.pcid_placeholder'
                )}
              />
            </Box>
            <Box
              direction="row"
              background="background-contrast"
              round="xsmall"
              margin={{ top: 'medium', bottom: 'medium' }}
              pad="small"
            >
              <Box margin={{ top: 'xsmall', right: 'medium', bottom: 'small' }}>
                <CircleAlert />
              </Box>
              <Box>
                <Typography type="text" testId="support-access-details">
                  {t(
                    'support_access_manager:search_customer_case_modal.modal_details'
                  )}
                </Typography>
              </Box>
            </Box>
          </CCSForm>
        </Box>
      }
      position="right"
      height="100%"
      onClose={closeDetailsModal}
      width="medium"
      testId="search-customer-case-dialog"
    />
  )
}

export default SearchCustomerCaseModal

SearchCustomerCaseModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  cb: PropTypes.func.isRequired
}
