export const lifeCycleStateEnum = Object.freeze({
  VERIFIED: 'VERIFIED',
  PENDING: 'PENDING',
  CLAIMED_ELSEWHERE: 'CLAIMED-ELSEWHERE',
  WEAKLY_CLAIMED: 'WEAKLY-CLAIMED'
})

const ClaimEnum = (t) => {
  return Object.freeze({
    VERIFIED: t('iam:domains.claim_verified_value'),
    PENDING: t('iam:domains.claim_pending_value'),
    CLAIMED_ELSEWHERE: t('iam:domains.claim_claimed_elsewhere_value'),
    WEAKLY_CLAIMED: t('iam:domains.claim_weakly_claimed_value')
  })
}

export const getDomainStatusText = (status, t) => {
  let text = ''
  switch (status) {
    case lifeCycleStateEnum?.VERIFIED:
      text = ClaimEnum(t)?.VERIFIED
      break
    case lifeCycleStateEnum?.PENDING:
      text = ClaimEnum(t)?.PENDING
      break
    case lifeCycleStateEnum?.CLAIMED_ELSEWHERE:
      text = ClaimEnum(t)?.CLAIMED_ELSEWHERE
      break
    case lifeCycleStateEnum?.WEAKLY_CLAIMED:
      text = ClaimEnum(t)?.WEAKLY_CLAIMED
      break
    default:
      break
  }
  return text
}
