// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types'

import { GetRenderingHints } from './utils'
import { QnAComponent } from './components/QnAComponent'
import { DefaultComponent } from './components/DefaultComponent'
import { AccordionComponent } from './components/AccordionComponent'
import { ActionComponent } from './components/ActionComponent'

export const RenderDomainComponent = ({
  content,
  renderHints,
  handleListClick,
  handleActionClick,
  setOpenDropdown,
  i18nTranslation
}) => {
  // Get the renderingHints of the hit's category

  const renderHint = GetRenderingHints(content?.category, renderHints)

  if (renderHint?.renderingHints?.displayType === 'SUMMARY') {
    return (
      <QnAComponent
        content={content}
        handleListClick={handleListClick}
        handleActionClick={handleActionClick}
        setOpenDropdown={setOpenDropdown}
        i18nTranslation={i18nTranslation}
      />
    )
  }
  if (renderHint?.renderingHints?.displayType === 'DEFAULT') {
    return (
      <DefaultComponent
        content={content}
        renderHint={renderHint}
        setOpenDropdown={setOpenDropdown}
      />
    )
  }
  if (renderHint?.renderingHints?.displayType === 'ACCORDION') {
    return (
      <AccordionComponent
        content={content}
        renderHint={renderHint}
        setOpenDropdown={setOpenDropdown}
      />
    )
  }
  if (renderHint?.renderingHints?.displayType === 'ACTION') {
    return (
      <ActionComponent
        content={content}
        renderHint={renderHint}
        setOpenDropdown={setOpenDropdown}
      />
    )
  }
  return <></>
}
RenderDomainComponent.propTypes = {
  content: PropTypes.any.isRequired,
  renderHints: PropTypes.any.isRequired,
  handleListClick: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  setOpenDropdown: PropTypes.func.isRequired,
  i18nTranslation: PropTypes.any.isRequired
}
