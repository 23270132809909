import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'
import omitBy from 'lodash/omitBy'
import { StatusCritical } from 'grommet-icons'

import {
  DataTable,
  FilterButton,
  Notification
} from '../../../../../../components'
import { getPaginationShowIdx } from '../../../../../../utils/common-utils'
import SideDetailsPanel from '../../../../common-components/SideDetailsPanel'
import {
  tableDetails,
  tableFilters,
  searchPlaceholders,
  searchValueKeys,
  ordersSidePanelDataKeys as sidePanelKeys
} from '../../customer-details'
import { getTableData, getActivateOrderDetails } from '../../customer-apis'

const OrdersDataTable = ({ customerId }) => {
  const type = 'orders'
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage', 'common'])
  const [columns, setColumns] = useState([])
  const [tableData, setTableData] = useState([])
  const [showDetailsPanel, setShowDetailsPanel] = useState(false)
  const [rowDetails, setRowDetails] = useState(null)
  const [searchVal, setSearchVal] = useState('')
  const [filterOptions, setFilterOptions] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)

  // for pagination
  const itemsPerPage = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  useEffect(() => {
    const details = tableDetails[type]
    const updatedCols = Object.keys(details).map((col) => {
      return {
        property: col,
        header: t(`customer_details.${details[col].name}`)
      }
    })
    updatedCols.push({
      property: 'pos_id',
      primary: true,
      render: () => <></>
    })
    setColumns(updatedCols)
    const request = {
      limit: itemsPerPage,
      page: page - 1,
      platform_customer_id: customerId,
      ...filterOptions
    }

    if (searchVal.trimStart().length > 0)
      request[searchValueKeys[type]] = searchVal.trimStart()

    getTableData(
      type,
      oidcUser.access_token,
      request,
      setTotalItems,
      setErrorMessage,
      t
    ).then((data) => {
      setTableData(data)
    })
  }, [customerId, oidcUser.access_token, t, searchVal, filterOptions, page])

  const createRowdata = (datum) => {
    return sidePanelKeys.map((key) =>
      datum[key] !== undefined
        ? {
            key: t(`customer_details.${key}`),
            value: datum[key]
          }
        : null
    )
  }

  const fetchSidePanelData = (datum) => {
    const param = datum.customer_po || datum.pos_id
    getActivateOrderDetails(
      param,
      oidcUser.access_token,
      setErrorMessage,
      t
    ).then((data) => {
      let obj
      data.forEach((o) => {
        obj = { ...obj, ...o?.aop_sales_order_data }
      })
      setRowDetails(createRowdata(obj))
      setShowDetailsPanel(true)
    })
  }

  return (
    <Box margin={{ bottom: 'small' }}>
      <DataTable
        pagination={{
          totalItems,
          itemsPerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        filterButton={
          <FilterButton
            filterAttributes={tableFilters(t)[type]}
            onFilterValuesChange={(filters) => {
              const formattedValues = Object.fromEntries(
                Object.entries(filters).map(([key, val]) => [
                  key,
                  val.map((v) => v.trimStart()).join()
                ])
              )
              setFilterOptions(omitBy(formattedValues, (v) => v.length === 0))
              setPage(1)
            }}
            testId="customer-detail-filter-btn"
          />
        }
        grid={{
          columns,
          data: tableData,
          onClickRow: (data) => fetchSidePanelData(data.datum)
        }}
        search={{
          onSearchValueChange: (value) => {
            setPage(1)
            setSearchVal(value)
          },
          placeholder: searchPlaceholders(t)[type]
        }}
        summary={{ entityName: t(`customer_details.${type}`) }}
        testId="generic-data-table"
      />
      {showDetailsPanel ? (
        <SideDetailsPanel
          data={rowDetails}
          onClose={(e) => setShowDetailsPanel(e)}
          type={type}
        />
      ) : null}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-inline-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}

OrdersDataTable.propTypes = {
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
}

export { OrdersDataTable }
