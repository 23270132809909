import React from 'react'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { MailOption } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  Typography,
  ModalFooter,
  ButtonGroup
} from '../../../../../components'
import { post } from '../../../../../utils/api-utils'
import { getWorkspaceString, WKSPC } from '../../../../../utils/common-utils'

const NotifyTeamModal = ({ domain, onSetOpen }) => {
  const { t } = useTranslation(['authn'])
  const { oidcUser } = useReactOidc()
  const handleSkip = () => {
    onSetOpen(false)
  }
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const handleSendEmail = () => {
    post(
      `/accounts/ui/v1/customer/saml/notify/${domain}`,
      {},
      oidcUser.access_token
    ).then(() => {
      onSetOpen(false)
    })
  }

  return (
    <Box>
      <ModalDialog
        width="medium"
        testId="notify-team-modal"
        content={
          <Box gap="small">
            <Box direction="row" justify="start">
              <Box pad="small" background="rgba(0, 0, 0, 0.04)" round="large">
                <MailOption />
              </Box>
            </Box>
            <Box gap="xsmall">
              <Typography type="heading" level={2}>
                {t('saml_sso.notifyActiveUsers')}
              </Typography>
              <Typography type="text">
                {t('saml_sso.notifyActiveUsersDesc', {
                  account: getWorkspaceString(showWorkspaceString, t, WKSPC)
                })}
              </Typography>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('saml_sso.skipForNow'),
                    default: true,
                    testId: 'skip-btn',
                    onClick: handleSkip
                  },
                  {
                    id: 1,
                    label: t('saml_sso.sendEmail'),
                    primary: true,
                    testId: 'send-email-btn',
                    onClick: handleSendEmail
                  }
                ]}
                testId="two-buttons"
              />
            }
          />
        }
        onClose={() => onSetOpen(false)}
      />
    </Box>
  )
}

NotifyTeamModal.propTypes = {
  domain: PropTypes.string.isRequired,
  onSetOpen: PropTypes.func.isRequired
}

export { NotifyTeamModal }
