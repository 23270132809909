import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, Select, CheckBox } from 'grommet'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { WizardContext } from '../../../../../../../../components/wizard/WizardContext'
import { FormInput, Typography } from '../../../../../../../../components'
import { validateForm } from '../../../../../../../../utils/validation-utils'
import { get } from '../../../../../../../../utils/api-utils'
import { displayApiError } from '../../../../../../../../utils/error-handling-utils'

import {
  validateContactFields,
  getContactInformationUpdater,
  getContactDetails
} from './utils'

export const validateContactDetails = (
  contactType,
  formValues,
  i18nTranslate
) => {
  const contactDetails = getContactDetails(contactType, formValues)

  const errorObj = validateContactFields(contactDetails, i18nTranslate)

  if (!isEmpty(errorObj)) {
    return Promise.reject(
      new Error(
        i18nTranslate(
          'billing_accounts.create_billing_account_wizard.missing_required_fields'
        )
      )
    )
  }

  return validateForm(formValues, i18nTranslate)
}

const ContactDetails = ({ contactType }) => {
  const { t } = useTranslation(['manage'])

  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)
  const [formError, setFormError] = useState({})
  const [countries, setCountries] = useState([])
  const [listOfCountries, setListOfCountries] = useState([])
  const [apiError, setApiError] = useState(null)

  const contactDetails = getContactDetails(contactType, formValues)
  const { useSoldToContactForBillTo, useSoldToContactForPayer } = formValues
  const updateConactInformation = getContactInformationUpdater(
    contactType,
    formValues,
    setFormValues
  )

  useEffect(() => {
    if (attemptedAdvance) {
      const errorObj = validateContactFields(contactDetails, t)
      setFormError(errorObj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, attemptedAdvance, t])

  useEffect(() => {
    get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      (response) => {
        setListOfCountries(response.data.countries)
        setCountries(response.data.countries)
      },
      (error) => {
        setApiError(error)
      }
    )
  }, [t])

  return (
    <Box
      width="medium"
      justify="between"
      align="start"
      margin={{ bottom: 'medium' }}
    >
      <Box direction="column" gap="small" width="100%">
        <Box width="100%">
          <FormInput
            testId="firstname"
            label={t(
              'billing_accounts.create_billing_account_wizard.firstName'
            )}
            inputType="text"
            value={contactDetails.firstName || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.first_name_placeholder'
            )}
            error={formError.firstName}
            onChange={(event) => {
              updateConactInformation('firstName', event.target.value)
            }}
            required
          />
        </Box>

        <Box width="100%">
          <FormInput
            testId="lastname"
            label={t('billing_accounts.create_billing_account_wizard.lastName')}
            inputType="text"
            value={contactDetails.lastName || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.last_name_placeholder'
            )}
            error={formError.lastName}
            onChange={(event) => {
              updateConactInformation('lastName', event.target.value)
            }}
            required
          />
        </Box>

        <Typography type="heading" level="4">
          {t('Address')}
        </Typography>

        <Box width="100%">
          <FormInput
            testId="house-number"
            label={t(
              'billing_accounts.create_billing_account_wizard.houseNumber'
            )}
            inputType="text"
            value={contactDetails.houseNumber || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.house_number_placeholder'
            )}
            error={formError.houseNumber}
            onChange={(event) => {
              updateConactInformation('houseNumber', event.target.value)
            }}
            required
          />
        </Box>

        <Box width="100%">
          <FormInput
            testId="street-name"
            label={t(
              'billing_accounts.create_billing_account_wizard.streetName'
            )}
            inputType="text"
            value={contactDetails.streetName || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.street_name_placeholder'
            )}
            error={formError.streetName}
            onChange={(event) => {
              updateConactInformation('streetName', event.target.value)
            }}
            required
          />
        </Box>

        <Box width="100%">
          <FormInput
            testId="city-name"
            label={t('billing_accounts.create_billing_account_wizard.cityName')}
            inputType="text"
            value={contactDetails.cityName || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.city_name_placeholder'
            )}
            error={formError.cityName}
            onChange={(event) => {
              updateConactInformation('cityName', event.target.value)
            }}
            required
          />
        </Box>

        <Box width="100%">
          <FormInput
            testId="state"
            label={t('billing_accounts.create_billing_account_wizard.state')}
            inputType="text"
            value={contactDetails.state || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.state_placeholder'
            )}
            error={formError.state}
            onChange={(event) => {
              updateConactInformation('state', event.target.value)
            }}
            required
          />
        </Box>

        <Box width="100%">
          <FormInput
            testId="postal-code"
            label={t(
              'billing_accounts.create_billing_account_wizard.postalCode'
            )}
            inputType="text"
            value={contactDetails.postalCode || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.postal_code_placeholder'
            )}
            error={formError.postalCode}
            onChange={(event) => {
              updateConactInformation('postalCode', event.target.value)
            }}
            required
          />
        </Box>

        <FormField
          width="100%"
          label={t('billing_accounts.create_billing_account_wizard.country')}
          name="country"
          data-testid="country"
          error={formError.country}
          required
        >
          <Select
            id="country"
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.country_placeholder'
            )}
            options={countries}
            multiple={false}
            labelKey="name"
            value={contactDetails?.country?.code || ''}
            valueKey={{ key: 'code', reduce: true }}
            searchPlaceholder={t(
              'billing_accounts.create_billing_account_wizard.country_search_placeholder'
            )}
            emptySearchMessage={t(
              'billing_accounts.create_billing_account_wizard.no_countries_found'
            )}
            onSearch={(searchText) => {
              const regexp = new RegExp(searchText, 'i')
              setCountries(listOfCountries.filter((o) => o.name.match(regexp)))
            }}
            onChange={({ option }) => {
              updateConactInformation('country', option)
            }}
            onClose={() => setCountries(listOfCountries)}
          />
        </FormField>

        <Box width="100%">
          <FormInput
            testId="email-address"
            label={t(
              'billing_accounts.create_billing_account_wizard.emailAddress'
            )}
            inputType="text"
            value={contactDetails.emailAddress || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.email_address_placeholder'
            )}
            error={formError.emailAddress}
            onChange={(event) => {
              updateConactInformation('emailAddress', event.target.value)
            }}
            required
          />
        </Box>

        <Box width="100%">
          <FormInput
            testId="phonenumber"
            label={t(
              'billing_accounts.create_billing_account_wizard.phoneNumber'
            )}
            inputType="text"
            value={contactDetails.phoneNumber || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.phone_number_placeholder'
            )}
            onChange={(event) => {
              updateConactInformation('phoneNumber', event.target.value)
            }}
          />
        </Box>
        {contactType === 'soldTo' && (
          <Box gap="small">
            <CheckBox
              id="use-sold-to-details-for-bill-to-contact"
              label={t(
                'billing_accounts.create_billing_account_wizard.use_sold_to_contact_details_for_bill_to_contact'
              )}
              checked={useSoldToContactForBillTo}
              onChange={(event) => {
                setFormValues((currFormValues) => {
                  return {
                    ...currFormValues,
                    useSoldToContactForBillTo: event.target.checked,
                    billToContactDetails: event.target.checked
                      ? currFormValues.soldToContactDetails
                      : {}
                  }
                })
              }}
            />

            <CheckBox
              id="use-sold-to-details-for-payer-contact"
              label={t(
                'billing_accounts.create_billing_account_wizard.use_sold_to_contact_details_for_payer_contact'
              )}
              checked={useSoldToContactForPayer}
              onChange={(event) => {
                setFormValues((currFormValues) => {
                  return {
                    ...currFormValues,
                    useSoldToContactForPayer: event.target.checked,
                    payerContactDetails: event.target.checked
                      ? currFormValues.soldToContactDetails
                      : {}
                  }
                })
              }}
            />
          </Box>
        )}
      </Box>

      {apiError &&
        displayApiError(
          apiError,
          t,
          () => {
            setApiError(null)
          },
          t(
            'billing_accounts.create_billing_account_wizard.failed_to_load_country_data'
          )
        )}
    </Box>
  )
}

ContactDetails.propTypes = {
  contactType: PropTypes.oneOf(['soldTo', 'billTo', 'payer']).isRequired
}

export default ContactDetails
