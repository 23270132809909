// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Anchor, Box, ResponsiveContext, Tag } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Previous } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import dayjs from 'dayjs'

import {
  isGLOP,
  isMSPTenantCoP
} from '../../../../../../utils/feature-flag-utils'
import { Typography } from '../../../../../../components'
import {
  APP_SUPPORTED_SUBSCRIPTION_TIERS,
  VALID_PRODUCT_ATTR_START_PATTERN,
  INVALID_PRODUCT_ATTR_START_PATTERN,
  VM_COUNT_PRODUCT_ATTR,
  BACKUP_CAPACITY_PRODUCT_ATTR
} from '../../../constants'
import { Layout } from '../../../../../commoncomponents/layout/Layout'
import { ManageSubscriptionTagsModal } from '../../../common-components/ManageSubscriptionTags'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { get } from '../../../../../../utils/api-utils'
import { displayApiError } from '../../../../../../utils/error-handling-utils'
import {
  getOnPremWOrkpsaceAnchor,
  getOnPremWorkSpaces
} from '../../../../../../utils/dm-sm-common-utils'
import { AssignToOnPremWorkSpaceModal } from '../../../../../commoncomponents/device-management'
import { displayNotification } from '../../../../../../utils/notificiation-utils'
import { getAssignToOnPremWorkSpacePayloadInDetails } from '../../../utils'
import { SubscriptionDetailsActionButton } from '../../../../../commoncomponents/device-management/CommonVisibility'

import ReviewShowbackRatesDetails from './showbackRate/ReveiwShowbackRatesDetails'

const ServiceSubscriptionDetails = () => {
  const { t } = useTranslation(['device', 'common', 'manage'])
  const LDFlags = useFlags()
  const history = useHistory()
  const { subscriptionKey } = useParams()
  const size = useContext(ResponsiveContext)
  const [onPremWorkSpaceData, setOnPremWorkSpaceData] = useState([])
  const sidePanelKeys = {
    product_description: t('subscription_name'),
    subscription_key: t('subscription_key'),
    subscription_type: t('subscription_type'),
    ...(LDFlags['glcp-show-service-subscription-tier'] && {
      subscription_tier_description: t('tier')
    }),
    license_state_type: t('license_state_type'),
    quantity: t('quantity'),
    subscription_start: t('start_date'),
    subscription_end: t('expiration_date'),
    vm_count: t('vm_count'),
    backup_capacity: t('backup_capacity'),
    ...(onPremWorkSpaceData?.length
      ? { on_prem_workspace_name: t('onprem_workspace') }
      : {})
  }

  const [manageTagsModal, setManageTagsModal] = useState(false)
  const [manageRatesModal, setManageRatesModal] = useState(false)
  const [serviceSubs, setServiceSubs] = useState([])
  const [errorNotification, setErrorNotification] = useState(null)
  const [refreshTable, setRefreshTable] = useState(false)
  const [isRatesStepAvailable, setIsRatesStepAvailable] = useState(false)
  const { oidcUser } = useReactOidc()
  const glopDevicesPreOnBoardingFlag = LDFlags['glcp-dscc-dm-sm']
  const [showOnPremWorkSpaceModal, setOnPremWorkSpaceModal] = useState(false)
  const [successNotification, setSuccessNotification] = useState(null)
  useEffect(() => {
    if (glopDevicesPreOnBoardingFlag) {
      getOnPremWorkSpaces(oidcUser.access_token, t, setErrorNotification).then(
        (data) => {
          setOnPremWorkSpaceData(data?.items)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [glopDevicesPreOnBoardingFlag])
  const createRowdataForTable = (datum) => {
    const additionalSubscriptionProductDetail = []
    datum?.product_attributes?.forEach((attr) => {
      if (
        attr?.name?.startsWith(VALID_PRODUCT_ATTR_START_PATTERN) &&
        !attr?.name?.startsWith(INVALID_PRODUCT_ATTR_START_PATTERN)
      ) {
        additionalSubscriptionProductDetail?.push({
          key: attr?.name_display,
          value: attr?.value_display,
          testId: attr?.name
        })
      }
    })

    const licenseData = Object.keys(sidePanelKeys).map((key) => {
      if (['backup_capacity', 'vm_count'].includes(key)) {
        const valueObject = datum?.product_attributes?.find(
          (item) =>
            item.name ===
            (key === 'vm_count'
              ? VM_COUNT_PRODUCT_ATTR
              : BACKUP_CAPACITY_PRODUCT_ATTR)
        )
        let valueStringToConcatinate = `${valueObject?.value || ''}`
        if (valueStringToConcatinate) {
          const unit = key === 'vm_count' ? ' VM' : ' GB'
          valueStringToConcatinate += unit
          return {
            key: sidePanelKeys[key],
            value: valueStringToConcatinate || '',
            testId: key
          }
        }
        return { key: null, value: null }
      }

      if (
        key === 'app_id' &&
        !APP_SUPPORTED_SUBSCRIPTION_TIERS?.includes(
          serviceSubs?.subscription_tier
        )
      ) {
        return { key: null, value: null }
      }

      return typeof sidePanelKeys[key] === 'object'
        ? {
            data: datum,
            renderer: sidePanelKeys[key].renderer,
            testId: key
          }
        : {
            key: sidePanelKeys[key],
            value: datum[key],
            testId: key
          }
    })
    return [...licenseData, ...additionalSubscriptionProductDetail]
  }

  const panelData = createRowdataForTable(serviceSubs)

  const getValue = (val) => {
    let fieldVal = val
    if (!val) {
      fieldVal = '--'
    }
    return fieldVal
  }
  const handleManageModalOpen = (open) => {
    setManageTagsModal(open)
  }
  const handleManageRatesModal = (isOpen) => {
    setManageRatesModal(isOpen)
  }

  useEffect(() => {
    const formattable = new Set([
      'activation_date',
      'cancellation_date',
      'reactivation_date',
      'subscription_end',
      'subscription_start',
      'suspension_date'
    ])

    const formatValue = (key, value) => {
      if (formattable.has(key)) {
        return dayjs(value).format('MMMM D, YYYY')
      }
      return value
    }
    const url = `/ui-doorway/ui/v1/license/service-subscriptions?product_type=SERVICE&product_type=ONPREM_SERVICE`
    get(
      url,
      {
        subscription_key: subscriptionKey
      },
      oidcUser.access_token
    ).then(
      (resp) => {
        let subs = resp?.data?.subscriptions[0]
        subs = {
          ...subs,
          ...Object.keys(subs?.appointments).reduce((acc, key) => {
            acc[key] = formatValue(key, subs?.appointments[key])
            return acc
          }, {})
        }
        setServiceSubs(subs)
      },
      (error) => {
        setServiceSubs([])
        setErrorNotification(displayApiError(error, t, setErrorNotification))
      }
    )
  }, [oidcUser.access_token, t, refreshTable, subscriptionKey])

  const onClickTag = (item) => {
    history.push({
      pathname: `/manage-account/tags/tag-details/${item?.name}:${item?.value}`,
      search: 'resourceType=SERVICE_SUBSCRIPTION'
    })
  }

  const manageRateButton =
    isRatesStepAvailable === true &&
    LDFlags['glcp-cds-rate-interface'] &&
    !isMSPTenantCoP() &&
    !isGLOP()
      ? [
          {
            label: t('showback_rates.manage_rates_action_label'),
            onClick: () => {
              setManageRatesModal(true)
            },
            testId: 'manage-showback-rates-btn',
            visibility: {
              rbac: {
                resource: '/ccs/device-management/showback-rate',
                permission: 'ccs.device-showback-rate.manage'
              }
            }
          }
        ]
      : []

  return (
    <Box>
      <Box
        pad={{ horizontal: 'large' }}
        width="large"
        alignSelf="center"
        data-testid="service-subscription-details-card"
        margin="large"
      >
        <Box margin={{ bottom: 'xsmall' }}>
          <Anchor
            size="xsmall"
            label={t('service_subscriptions')}
            icon={<Previous size="small" />}
            data-testid="service-subscriptions-btn"
            margin={{ botton: 'large' }}
            onClick={() => {
              history.push('/services/service-subscriptions')
            }}
          />
        </Box>
        <Box direction="row" justify="between" margin={{ bottom: 'large' }}>
          <Box>
            <Typography
              level="1"
              type="heading"
              testId="header-title-subscription-key"
              truncate
            >
              {subscriptionKey}
            </Typography>
          </Box>
          <SubscriptionDetailsActionButton
            setManageTagsModal={setManageTagsModal}
            setOnPremWorkSpaceModal={setOnPremWorkSpaceModal}
            onPremWorkSpaceData={onPremWorkSpaceData?.length}
            manageRateButton={manageRateButton}
          />
        </Box>
        <Box
          direction="row-responsive"
          justify="between"
          border={{ side: 'bottom', color: 'border-weak' }}
          testId="details-header"
        >
          <Box margin={{ bottom: 'small' }}>
            <Typography level="3" type="heading">
              {t('details')}
            </Typography>
          </Box>
        </Box>
        <Box
          margin={{ vertical: 'medium' }}
          direction="column"
          flex={{ shrink: 0 }}
          testId="details-table"
        >
          {panelData.map((datum) => {
            if (datum?.renderer) {
              return datum?.renderer(datum?.data)
            }
            return datum?.key ? (
              <Box
                direction={size === 'xsmall' ? 'column' : 'row-responsive'}
                align="start"
                justify="start"
                margin={{ top: 'small' }}
                key={datum[0]}
              >
                <Box width="small" align="start">
                  <Typography
                    size="small"
                    type="text"
                    margin={{ bottom: 'xsmall' }}
                    testId={`${datum?.testId}-key`}
                    color="text-strong"
                    truncate
                  >
                    {datum?.key}
                  </Typography>
                </Box>
                <Box
                  width="medium"
                  align="start"
                  margin={{ left: 'medium' }}
                  pad={{ left: 'small' }}
                >
                  <Typography
                    size="small"
                    width="medium"
                    type="text"
                    margin={{ bottom: 'small', left: 'large' }}
                    testId={`${datum?.testId}-value`}
                    color="text-strong"
                  >
                    {datum?.key === t('onprem_workspace')
                      ? getOnPremWOrkpsaceAnchor(
                          datum?.value,
                          serviceSubs?.on_prem_workspace_rid,
                          history
                        )
                      : getValue(datum?.value)}
                  </Typography>
                </Box>
              </Box>
            ) : null
          })}
        </Box>
        <VisibilityWrapper
          hideFor={{
            deployment: ['GLOP']
          }}
        >
          <Box
            border={{ side: 'bottom', color: 'border-weak' }}
            testId="tag-details-header"
          >
            <Box margin={{ bottom: 'small' }}>
              <Typography level="3" type="heading">
                {t('tags.tags_title')}
              </Typography>
            </Box>
          </Box>
          <Box
            gap="small"
            direction="row-responsive"
            wrap
            margin={{ bottom: 'large' }}
          >
            {serviceSubs?.tags?.length
              ? serviceSubs?.tags?.map((item, idx) => {
                  return (
                    <Box
                      margin={{ top: 'small' }}
                      key={`pill-${idx}`} /* eslint-disable-line react/no-array-index-key */
                    >
                      <Tag
                        name={item.name}
                        alignSelf="start"
                        value={item.value}
                        data-testid={`${item.value}-${idx}`}
                        onClick={() => {
                          onClickTag(item)
                        }}
                      />
                    </Box>
                  )
                })
              : t('tags.no_tags_assigned')}
          </Box>
        </VisibilityWrapper>
        <VisibilityWrapper
          hideFor={{
            feature: 'glcp-cds-rate-interface',
            account: ['MSP', 'TENANT'],
            deployment: ['COP', 'GLOP']
          }}
          rbac={{
            resource: '/ccs/device-management/showback-rate',
            permission: 'ccs.device-showback-rate.view'
          }}
        >
          <Box
            gap="small"
            direction="row-responsive"
            wrap
            margin={{ bottom: 'large' }}
          >
            <ReviewShowbackRatesDetails
              manageRatesModal={manageRatesModal}
              handleManageRatesModal={handleManageRatesModal}
              setErrorNotification={setErrorNotification}
              subscriptionKey={subscriptionKey}
              setIsRatesStepAvailable={setIsRatesStepAvailable}
            />
          </Box>
        </VisibilityWrapper>
      </Box>
      {manageTagsModal && (
        <ManageSubscriptionTagsModal
          setModalOpen={handleManageModalOpen}
          initialTags={serviceSubs?.tags}
          manageTagsData={serviceSubs}
          setShowErrorNotification={setErrorNotification}
          selectedSubscriptionsKey={[subscriptionKey]}
          onSuccess={() => {
            setRefreshTable(!refreshTable)
          }}
        />
      )}
      {showOnPremWorkSpaceModal && (
        <AssignToOnPremWorkSpaceModal
          setOnPremWorkSpaceModal={setOnPremWorkSpaceModal}
          onPremWorkSpaceData={onPremWorkSpaceData}
          requestPayload={getAssignToOnPremWorkSpacePayloadInDetails(
            serviceSubs?.subscription_key
          )}
          onSuccess={() => {
            setSuccessNotification(
              displayNotification(
                t('manage:onprem_workspace.service_subscription_notification'),
                'info',
                setSuccessNotification
              )
            )
            setOnPremWorkSpaceModal(false)
            setRefreshTable(!refreshTable)
          }}
          selectedRows={[serviceSubs]}
          type="service_subscriptions"
        />
      )}
      {successNotification}
      {errorNotification}
    </Box>
  )
}

const ServiceSubscriptionDetailsPage = () => {
  return (
    <Layout>
      <ServiceSubscriptionDetails />
    </Layout>
  )
}
export default ServiceSubscriptionDetailsPage
