import React, { useEffect, useState } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useHistory
} from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { checkGotoChooseAccount } from '../../utils/common-utils'
import { get } from '../../utils/api-utils'
import { getOrganizationId } from '../../utils/feature-flag-utils'

import IGCServicePage from './IgcService'
import Users from './users/Users'
import GroupsRouter from './groups/router'
import DomainsPage from './domains/DomainsPage'
import DomainDetails from './domains/DomainDetails'
import AddDomain from './domains/components/AddDomainWizard'
import { UserDetails } from './users/UserDetails'
import SsoProfilesRouter from './sso-profiles/router'
import WorkspacesRouter from './workspaces/router'

const IGCServiceRouter = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { oidcUser } = useReactOidc()
  const organizationId = getOrganizationId()
  const account = JSON.parse(sessionStorage?.getItem('account'))
  const pcid = account?.platform_customer_id
  const [authizedView, setAuthizedView] = useState(true)

  useEffect(() => {
    setIsLoading(true)

    get(
      '/service-catalog/v1alpha1/service-offers',
      { static_launch_url: '/organization' },
      oidcUser.access_token
    ).then(
      (response) => {
        const serviceID = response?.data?.items[0]?.id || ''
        // if can't find the IGC service id, no need to check if IGC provisioned or not
        // redirect to page not found
        if (serviceID === '') {
          setAuthizedView(false)
          setIsLoading(false)
        }
        // Call API to check is current workspace provisioned the IGC
        // if not, redirect to page not found
        // if yes, setServiceOfferID to display the page
        get(
          `/service-catalog/v1beta1/service-provisions`,
          {
            filter: `organizationId eq ${organizationId} and ServiceOfferId eq ${serviceID}`
          },
          oidcUser.access_token
        ).then(
          (resp) => {
            const provisionedWorkspaceId =
              resp?.data?.items[0]?.workspace?.id || ''
            if (
              provisionedWorkspaceId === '' ||
              provisionedWorkspaceId !== pcid
            ) {
              // if not the same pcid, means IGC is not provisioned by this pcid,
              // redirect to page not found
              setAuthizedView(false)
            }
            setIsLoading(false)
          },
          () => {
            // no need to show error, redirect to page not found
            setAuthizedView(false)
            setIsLoading(false)
          }
        )
      },
      () => {
        // no need to show error, redirect to page not found
        setAuthizedView(false)
        setIsLoading(false)
      }
    )
  }, [oidcUser, organizationId, pcid])

  const { path } = useRouteMatch()
  const history = useHistory()
  checkGotoChooseAccount(history)

  return (
    <>
      {!isLoading && authizedView && (
        <Switch>
          <Route exact path={path}>
            <IGCServicePage />
          </Route>
          <Route exact path={`${path}/users`}>
            <Users />
          </Route>
          <Route path={`${path}/workspaces`}>
            <WorkspacesRouter />
          </Route>
          <Route path={`${path}/sso-profiles`}>
            <SsoProfilesRouter />
          </Route>
          <Route exact path={`${path}/domains`}>
            <DomainsPage />
          </Route>
          <Route exact path={`${path}/domains/add-domain`}>
            <AddDomain />
          </Route>
          <Route exact path={`${path}/domains/:id`}>
            <DomainDetails />
          </Route>
          <Route path={`${path}/groups`}>
            <GroupsRouter />
          </Route>
          <Route exact path={`${path}/users/:userId`}>
            <UserDetails />
          </Route>
          <Redirect to="/not-found" />
        </Switch>
      )}
      {!isLoading && !authizedView && (
        <Switch>
          <Redirect to="/not-found" />
        </Switch>
      )}
    </>
  )
}

export default IGCServiceRouter
