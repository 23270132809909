import React from 'react'
import { Checkmark } from 'grommet-icons'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { Typography } from '../../../components'

const FormAlert = ({ message }) => {
  return (
    <Box
      background="rgba(255, 188, 68, 0.24)"
      pad="xsmall"
      round="medium"
      margin={{ top: 'small' }}
      gap="xsmall"
      direction="row"
      align="center"
      width="large"
      data-testid="form-alert-error"
    >
      <Checkmark size="small" />
      <Typography
        type="text"
        size="small"
        testId="unavailable-regions-error-msg"
      >
        {message}
      </Typography>
    </Box>
  )
}

FormAlert.propTypes = { message: PropTypes.string.isRequired }
export { FormAlert }
