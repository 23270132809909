import React from 'react'
import { Box } from 'grommet'
import { Scorecard } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import { ErrorInfo } from '../../../../components'

const NoEvents = () => {
  const { t } = useTranslation('unified_wellness')
  return (
    <Box
      data-testid="no-events-page"
      height="large"
      align="center"
      justify="center"
    >
      <ErrorInfo
        ErrorIcon={<Scorecard size="large" />}
        align="center"
        title={t('no_events.title')}
        errorInfo={t('no_events.description')}
      />
    </Box>
  )
}
export default NoEvents
