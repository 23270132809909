import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'

import { MenuList } from '../menu-list/MenuList'

export const GLCPHelpMenu = ({ helpNavContent, testId }) => {
  const [render, setRender] = useState(1)
  const menuData = helpNavContent.map((menuListprops) => {
    return {
      id: menuListprops.id,
      label: menuListprops.label,
      testId: `${menuListprops.label}-${menuListprops.id}`,
      isHidden: () => menuListprops.isHidden
    }
  })

  const renderHelpMenu = helpNavContent.map((fragment) => {
    return <Box flex>{fragment.content}</Box>
  })

  const handleClick = (item) => {
    setRender(item.id)
  }

  return (
    <Box height={{ min: '540px' }} flex>
      <Box
        border={{ side: 'bottom', color: 'border-weak' }}
        pad={{ bottom: 'small', horizontal: 'small' }}
      >
        <MenuList
          defaultActiveId={1}
          highlightOnSelect
          menuData={menuData}
          testId="help-menu"
          menuItemStyles={{
            padding: {
              horizontal: 'small',
              vertical: 'none'
            },
            align: 'center'
          }}
          navStyles={{
            gap: 'none',
            pad: 'none',
            responsive: false,
            direction: 'row',
            justify: 'between',
            background: 'none'
          }}
          onClickMenuItem={(item) => {
            handleClick(item)
          }}
        />
      </Box>
      <Box key={`${render}`} testId={testId} height={{ min: 'medium' }} flex>
        {renderHelpMenu[render - 1]}
      </Box>
    </Box>
  )
}

GLCPHelpMenu.propTypes = {
  helpNavContent: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
      renderDefault: PropTypes.oneOf(['help', 'feedback', 'custom'])
    })
  ).isRequired,
  testId: PropTypes.string.isRequired
}

GLCPHelpMenu.defaultProps = {}
