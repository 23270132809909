import React, { useState } from 'react'
import { Box, FormField, TextInput, Anchor, Text } from 'grommet'
import { CircleAlert } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { post, getErrorMessage } from '../../../utils/api-utils'
import { Button, Typography, CCSForm } from '../../../components'
import { Layout } from '../../commoncomponents/layout/Layout'
import { validateEmail } from '../../../utils/validation-utils'
import { getWorkspaceString, WKSPC } from '../../../utils/common-utils'

const ResetPasswordButton = (
  <Button
    primary
    type="submit"
    label={
      <Box pad={{ vertical: 'xsmall' }}>
        <Text size="xlarge" weight="bold">
          Reset Password
        </Text>
      </Box>
    }
    fill="horizontal"
    size="large"
    margin={{ vertical: 'small' }}
    testId="reset-password-submit"
  />
)

const SuccessInfo = (
  <Box
    round="4px"
    gap="xsmall"
    direction="row"
    align="center"
    data-testid="success-info-box"
  >
    <CircleAlert size="small" />
    <Typography type="text" size="xsmall" testId="success-info-message">
      Password reset directions were sent to your email.
    </Typography>
  </Box>
)

const ResetPassword = () => {
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const [state, setState] = useState({
    email: ''
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const { t } = useTranslation(['common', 'authn'])
  const handleResetPassword = () => {
    setErrorMessage('')

    post(`/accounts/ui/v1/user/password-recovery/${state.email}`, {}).then(
      () => {
        setEmailSent(true)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }

  return (
    <Box align="center" justify="center">
      <Box width="medium">
        <Typography
          type="heading"
          level="1"
          margin={{ top: 'medium', bottom: 'small' }}
          testId="reset-password-header"
        >
          Reset Password
        </Typography>
        <Typography
          type="text"
          size="xlarge"
          margin={{ bottom: 'medium' }}
          testId="reset-password-subheader"
        >
          {t('authn:acct_onboarding.reset-password-subheader', {
            account: getWorkspaceString(showWorkspaceString, t, WKSPC)
          })}
        </Typography>
        <CCSForm
          value={state}
          buttons={ResetPasswordButton}
          onSubmit={handleResetPassword}
          errorMessage={errorMessage}
          testId="reset-password-form"
          validate="blur"
        >
          <>
            <FormField
              label="Email"
              name="email"
              required
              data-testid="reset-password-form-email-field"
              validate={(value) => {
                return validateEmail(value)
              }}
            >
              <TextInput
                value={state.email}
                name="email"
                placeholder="example@my.com"
                data-testid="reset-password-form-email-input"
                onChange={(event) => {
                  if (emailSent) setEmailSent(false)
                  setState({
                    email: event.target.value
                  })
                }}
              />
            </FormField>
            {emailSent && SuccessInfo}
          </>
        </CCSForm>
        <Anchor
          margin={{ top: 'xsmall' }}
          label={<Text weight="bold">Back to Sign in</Text>}
          href="/"
          data-testid="back-to-sign-in"
        />
      </Box>
    </Box>
  )
}

const ResetPasswordPage = () => {
  return (
    <Layout hideHeaderOptions={['no-header']}>
      <ResetPassword />
    </Layout>
  )
}
export default ResetPasswordPage
