// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import omit from 'lodash/omit'
import flatMapDepth from 'lodash/flatMapDepth'

import { isCoP, isGLOP, isMSP } from '../../utils/feature-flag-utils'
import { get, post } from '../../utils/api-utils'
import { displayNotification } from '../../utils/notificiation-utils'
import {
  displayNotificationContent,
  handleErrorNotification
} from '../../utils/reporting-utils'
import { displayApiError } from '../../utils/error-handling-utils'
import { displayDeviceTypeEnum } from '../../utils/common-utils'
import {
  getDateByMonthAbvDayYear,
  getDateInMilliseconds
} from '../../utils/dm-sm-common-utils'

export const getFormattedFilterValues = (filters) => {
  let modifiedFilters = { ...filters }
  if (modifiedFilters?.location_dropdown?.length) {
    const locationFilters = modifiedFilters?.location_dropdown?.reduce(
      (prev, current) => {
        const locationData = current?.value?.split(':')
        const key =
          locationData[0] === 'search_id'
            ? 'location_ids'
            : 'location_search_strings'
        prev[key] = prev[key] || []
        prev[key].push(locationData[1])
        return prev
      },
      {}
    )
    modifiedFilters = { ...modifiedFilters, ...locationFilters }
    delete modifiedFilters.location_dropdown
  }
  const formattedValues = Object.fromEntries(
    Object.entries(modifiedFilters).map(([key, val]) => {
      switch (key) {
        case 'application_customer_ids':
        case 'location_ids':
        case 'location_search_strings':
        case 'application_ids':
          return [key, val]
        case 'subscription_end_date_in_millis':
          return [key, getDateInMilliseconds(val)]
        case 'device_with_no_sdi_location':
          return [key, true]
        case 'tags': {
          const tagsFilterList = []
          val.forEach((tag) => {
            const [name, value = ''] = tag.value.split(':')
            /**
             * TODO: need a way for backend to allow adding fitlers for tags
             * with same name.  Currently, this has a bug where if there are
             * two tags with same names and diff values, the last tag wins
             */
            tagsFilterList.push({ name, value })
          })
          return [key, tagsFilterList]
        }
        default: {
          let value = val?.join()
          if (key === 'subscription_tier')
            value = value?.replace('BRIDGE_SUBSCRIPTION_TIER', 'BRIDGE')
          return [key, value]
        }
      }
    })
  )
  return formattedValues
}

export const getFormattedTagsString = (name, value) => {
  let formattedString = name
  if (value) formattedString = `${formattedString}:${value}`
  return formattedString
}

export const formatMAC = (macAddress, curr, key) => {
  if (curr.length) {
    curr = curr.replace(new RegExp(/[^a-fA-F0-9]/, 'g'), '')
    for (let i = 0; i < curr.length; i += 1) {
      if ([2, 5, 8, 11, 14].includes(i))
        curr = `${curr.slice(0, i)}:${curr.slice(i)}`
    }
  }
  if (!key || key.match(new RegExp(/[a-fA-F0-9]/))) {
    let mac = macAddress
    if (curr.length >= 17) mac = curr.slice(0, 17)
    else if ([2, 5, 8, 11, 14].includes(curr.length)) {
      if (curr.length === mac.length - 1) mac = curr.slice(0, -1)
      else mac = `${curr}:`
    } else mac = curr
    return mac
  }
  return macAddress
}

export const NOT_APPLICABLE = 'Not applicable'

export const COMPUTE_SUPPORT_LINK =
  'https://support.hpe.com/hpesc/public/docDisplay?docId=sd00001293en_us&page=GUID-BC7D1D1B-AE36-4F00-A1FB-C1B9E01DF101.html'

export const getDeviceTypeForSubscription = (displayDeviceType) => {
  // Possible values are STORAGE, COMPUTE, AP, SWITCH & GATEWAY
  return (
    ([
      displayDeviceTypeEnum.COMPUTE,
      displayDeviceTypeEnum.DHCI_COMPUTE
    ].includes(displayDeviceType) &&
      'COMPUTE') ||
    ([
      displayDeviceTypeEnum.STORAGE,
      displayDeviceTypeEnum.DHCI_STORAGE
    ].includes(displayDeviceType) &&
      'STORAGE') ||
    displayDeviceType
  )
}

export const getDeviceModalForSubscription = (result, LDFlags, deviceType) => {
  if (deviceType === 'COMPUTE')
    return result?.platform_subscription_category || 'ALL'
  if (deviceType === 'STORAGE' && LDFlags['glcp-capex-subscription'])
    return `${result?.device_model} (${result?.serial_number})`
  return result?.device_model
}

export const NO_VALUE = '--'

export const sampleCSVData = [
  {
    serial_number: '1ABC123CD4',
    mac_address: '11:22:33:44:AA:BB',
    part_number: '123456-ABC1',
    name1_value: 'value1',
    name2_value: '<blank>',
    location_name: 'Tottenham',
    contact_email: 'user@hpe.com',
    host_name: 'host_1',
    ip_address: '12.23.34.45', // NOSONAR
    snmp_profile: 'snmpprofile1',
    device_type: 'DHCI_STORAGE',
    device_model: 'A1234',
    subscription_key: 'A3456789',
    tag: 'test6:value6; tag1:v1; test-tag1:test-value1;'
  },
  {
    serial_number: '5EFG678HI9',
    mac_address: '44:33:22:11:BB:AA',
    part_number: '456789-DEF2',
    name1_value: '',
    name2_value: 'value2',
    location_name: '',
    contact_email: 'user@hpe.com',
    host_name: 'host_2',
    ip_address: '11.22.33.44', // NOSONAR
    snmp_profile: 'snmpprofile2',
    device_type: 'STORAGE',
    device_model: 'A123',
    subscription_key: 'B7891234',
    tag: 'test6:value7; tag1:v2; test-tag1:test-value2;'
  }
]

export const sampleCsvHeaderEnum = {
  COMPUTE: [
    { label: 'Serial_No', key: 'serial_number' },
    {
      label: 'Product_ID',
      key: 'part_number'
    },
    { label: 'tag:name1', key: 'name1_value' },
    { label: 'tag:name2', key: 'name2_value' },
    { label: 'Location_Name (Optional)', key: 'location_name' },
    { label: 'Contact_Email (Optional)', key: 'contact_email' }
  ],
  STORAGE: [
    { label: 'Serial Number', key: 'serial_number' },
    {
      label: 'Part Number',
      key: 'part_number'
    },
    {
      label: 'Device Type',
      key: 'device_type'
    },
    {
      label: 'Device Model',
      key: 'device_model'
    },
    {
      label: 'Subscription Key',
      key: 'subscription_key'
    },
    { label: 'Tags', key: 'tag' }
  ],
  NETWORK: [
    { label: 'Serial_No', key: 'serial_number' },
    {
      label: 'MAC_Address',
      key: 'mac_address'
    },
    { label: 'tag:name1', key: 'name1_value' },
    { label: 'tag:name2', key: 'name2_value' },
    { label: 'Location_Name (Optional)', key: 'location_name' },
    { label: 'Contact_Email (Optional)', key: 'contact_email' }
  ],
  COP: [
    { label: 'Serial_No', key: 'serial_number' },
    {
      label: 'MAC_Address',
      key: 'mac_address'
    },
    { label: 'Part_Number', key: 'part_number' },
    { label: 'tag:name1', key: 'name1_value' },
    { label: 'tag:name2', key: 'name2_value' }
  ],
  COP_THIRD_PARTY: [
    { label: 'HostName', key: 'host_name' },
    { label: 'IP_Address', key: 'ip_address' },
    {
      label: 'MAC_Address',
      key: 'mac_address'
    },
    { label: 'Serial_No', key: 'serial_number' },
    { label: 'SNMP_Profile', key: 'snmp_profile' }
  ]
}

const HPE_CSV = 'csv_file'
const THIRD_PARTY_CSV = 'third_party_csv_file'

export const getCsvHeader = (deviceType, dmSdiLdFlag, addingMethod) => {
  if (isCoP() && addingMethod === HPE_CSV) return sampleCsvHeaderEnum.COP
  if (isCoP() && addingMethod === THIRD_PARTY_CSV)
    return sampleCsvHeaderEnum.COP_THIRD_PARTY
  if (isGLOP()) return sampleCsvHeaderEnum[deviceType]
  if (dmSdiLdFlag) return sampleCsvHeaderEnum[deviceType]
  return sampleCsvHeaderEnum[deviceType]?.slice(0, 4)
}

export const getExportCSVColumns = (
  t,
  isSDIEnabled,
  tabName,
  filterOptions,
  LDFlags,
  computeDevicesCount
) => {
  const columns = [
    'SERIAL_NUMBER',
    'DEVICE_MODEL',
    'MAC_ADDRESS',
    'PART_NUMBER',
    'DEVICE_TYPE',
    'SUBSCRIPTION_KEY',
    'SUBSCRIPTION_TIER',
    'SUBSCRIPTION_TIER_DESCRIPTION',
    'SUBSCRIPTION_EXPIRATION',
    'ARCHIVED',
    'APPLICATION_ID',
    'APPLICATION_NAME',
    'APPLICATION_INSTANCE_ID',
    'APPLICATION_INSTANCE_NAME',
    'APPLICATION_CUSTOMER_ID',
    'CCS_REGION',
    'TAGS',
    ...(LDFlags['glcp-show-devices-support-end-date']
      ? ['SUPPORT_END_DATE', 'SUPPORT_LEVEL']
      : []),
    ...(tabName !== 'LICENSED' &&
    tabName !== 'NOT_LICENSED' &&
    !filterOptions?.subscription_tier &&
    !filterOptions?.subscription_end_date_in_millis &&
    LDFlags['glcp-ilo-os-hostnames'] &&
    computeDevicesCount
      ? ['NAME', 'SECONDARY_NAME']
      : []),
    ...(isMSP() ? ['ACCOUNT_NAME', 'TENANT_PLATFORM_CUSTOMER_ID'] : [])
  ]
  const sdiColumns = [
    'CONTACT_ID',
    'CONTACT_NAME',
    'LOCATION_ID',
    'LOCATION_NAME'
  ].map((sdiColumn) => ({
    label: t(`location:${sdiColumn.toLowerCase()}`),
    value: sdiColumn
  }))

  return [
    ...columns.map((columnValue) => {
      if (columnValue === 'TAGS')
        return {
          label: t('tags.tags_title'),
          value: columnValue
        }
      return {
        label: t(columnValue.toLowerCase()),
        value: columnValue
      }
    }),
    ...(isSDIEnabled ? sdiColumns : [])
  ]
}

export const getExportReportStatus = (id, status, setStatus, token) => {
  get(`/ui-doorway/ui/v1/csv-reports/status/${id}`, {}, token).then(
    (response) => {
      if (response.status === 200) {
        const { status: responseStatus } = response.data
        if (responseStatus && responseStatus !== status) {
          setStatus(responseStatus)
        }
      }
    },
    (error) => {
      if (error.response.status === 404 && status === 'IN_PROGRESS') {
        // if 404, the task is expired, we can stop polling
        setStatus(null)
      }
    }
  )
}

export const generateCSVReportAPICall = (
  email,
  columns,
  request,
  token,
  setNotification,
  onSuccess,
  setLoading,
  t
) => {
  const columnsQuery = columns.map((v) => `column_names=${v}`).join('&')
  post(
    `/ui-doorway/ui/v1/export/device-inventory?email_address=${email}&${columnsQuery}`,
    request,
    token
  ).then(
    (response) => {
      onSuccess(response)
      setNotification(
        displayNotification(
          t('common:export_modal.generating_report'),
          'info',
          () => setNotification(false)
        )
      )
    },
    (error) => {
      setNotification(displayApiError(error, t))
      setLoading(false)
    }
  )
}

export const generateCSVReportViaReportingAPICall = (
  request,
  token,
  setNotification,
  onSuccess,
  onError,
  t,
  reportingDashboardFlag
) => {
  post(`/report-mgmt/ui/v1/exports`, request, token).then(
    (response) => {
      onSuccess(response)
      const refId = response?.data?.tracking_id
      const repName = response?.data?.reportName
      setNotification(
        displayNotification(
          displayNotificationContent(reportingDashboardFlag, t, refId, repName),
          'info',
          setNotification
        )
      )
    },
    (error) => {
      if (!reportingDashboardFlag)
        handleErrorNotification(error, t, setNotification, displayApiError)
      onError(error?.response)
    }
  )
}

export const BAAS_ATTR_VALUE = 'blockaas'

export const VGW_DEVICE_MODEL = 'VGW'

export const VGW_DEVICE_PARTNUM = 'MC-VA'

const metaErrorMessages = {
  not_found_devices_compute: 'not_found_devices_compute',
  already_added_devices_compute: 'already_added_devices_compute',
  blocked_devices_compute: 'blocked_devices_compute',
  not_found_devices: 'not_found_devices',
  already_added_devices: 'already_added_devices',
  blocked_devices: 'blocked_devices'
}

export const formatComputeClaimDeviceError = (errorList, metaData, t) => {
  Object?.entries(metaData)?.forEach((value) => {
    const serialPartList = value[1]?.split(',')
    serialPartList.forEach((serialPartValue) => {
      const errorMsg = t(
        metaErrorMessages[`${value[0]}_compute`] || 'blocked_devices_compute',
        {
          serialNo: serialPartValue?.split(':')[0],
          partNo: serialPartValue?.split(':')[1]
        }
      )
      errorList.push(errorMsg)
    })
  })
}

export const formatNetworkStorageClaimDeviceError = (
  errorList,
  metaData,
  t
) => {
  Object?.entries(metaData)?.forEach((value) => {
    const errorMsgi18nKey = t(
      metaErrorMessages[value[0]] || 'blocked_devices',
      { serialNo: value[1] }
    )
    errorList.push(errorMsgi18nKey)
  })
}

// To display title under Apply Subscription Wizard Modal
export const getDeviceTypeLabel = (deviceType, t, capexSubscriptionLD) => {
  const deviceTypeEnum = {
    AP: t('access_points'),
    SWITCH: t('switches'),
    GATEWAY: isCoP() ? t('controllers') : t('gateways'),
    SD_WAN_GW: t('sd_wan_gateways'),
    SENSOR: t('sensors'),
    COMPUTE: t('servers'),
    STORAGE: capexSubscriptionLD ? t('storage_device') : t('storage_devices'),
    NW_THIRD_PARTY: t('third_party'),
    BRIDGE: t('bridge')
  }
  return deviceTypeEnum[deviceType] || deviceType
}

export const assignToAppWizardDefaultvalues = {
  networkDeviceApplication: '',
  networkDeviceApplicationInstance: '',
  storageDeviceApplication: '',
  storageDeviceApplicationInstance: '',
  computeDeviceApplication: '',
  computeDeviceApplicationInstance: '',
  pceDeviceApplication: '',
  pceDeviceApplicationInstance: '',
  tenantId: ''
}

export const TAG_NAME_MAX_LENGTH = 128

// To get description of Export devices modal based on workspace string & reporting framework LD
export const getExportDescriptionBasedOnLd = (LDFlags, t) => {
  const {
    'glcp-switch-to-workspace': showWorkspaceString,
    'glcp-glasgow-reportfw-devices-export': reportingDevicesExportLdFlag,
    'glcp-reportfw-dashboard': reportingDashboardFlag
  } = LDFlags
  let desc = ''
  if (!reportingDashboardFlag) {
    if (showWorkspaceString) {
      desc = !reportingDevicesExportLdFlag
        ? t('export_csv_desc_workspace')
        : t('export_csv_report')
    } else {
      desc = !reportingDevicesExportLdFlag
        ? t('export_csv_desc2')
        : t('export_csv_report')
    }
  } else desc = t('reporting_dashboard:reporting_export_modal.di_desc')
  return desc
}

export const PRODUCT_NUMBER_SUPPORT_URL =
  'https://support.hpe.com/hpesc/public/docDisplay?docId=a00120892en_us&page=GUID-D02DF9F8-A990-4399-8876-8036668BD9CE.html'

export const getIaaSApplySubscriptionRequestBody = (deviceDetails) => {
  let iaasDevices = []
  if (deviceDetails?.STORAGE) {
    Object.entries(deviceDetails.STORAGE).forEach((datum) => {
      const data = datum[1].map((obj) => {
        const newRequestBody = {
          device_serial: obj.serial_number,
          part_number: obj.part_number,
          device_type: obj.device_type
        }
        return newRequestBody
      })
      iaasDevices = [...iaasDevices, ...data]
    })
  }

  if (deviceDetails?.PCE) {
    Object.entries(deviceDetails.PCE).forEach((datum) => {
      const data = datum[1].map((obj) => {
        const newRequestBody = {
          device_serial: obj.serial_number,
          part_number: obj.part_number,
          device_type: obj.device_type
        }
        return newRequestBody
      })
      iaasDevices = [...iaasDevices, ...data]
    })
  }
  return iaasDevices
}

export const getSubscriptionsDisabledRows = (
  subscriptionList,
  checkedSubscription
) => {
  const checkedLicenseList =
    checkedSubscription?.map(({ subscription_key: key }) => key) || []
  const disabledRows = subscriptionList
    ?.filter(
      (license) =>
        checkedLicenseList?.length &&
        !checkedLicenseList?.includes(license?.subscription_key)
    )
    ?.map(({ subscription_key: key }) => {
      return key
    })
  return disabledRows || []
}

export const handleSubscriptionSelectRow = ({
  selectedRow,
  viewData,
  checkedLicenses,
  setCheckedLicenses,
  count,
  setRowsToDisable,
  setRemainingDevices
}) => {
  let remDevices = count
  const selectedLicensesArr = selectedRow?.reduce((prev, licenseKey) => {
    let licenceObj = viewData?.find(
      (license) => license.subscription_key === licenseKey
    )
    if (!licenceObj)
      licenceObj = checkedLicenses?.find(
        (license) => license.subscription_key === licenseKey
      )
    if (licenceObj) {
      remDevices =
        remDevices - licenceObj?.available_quantity < 0
          ? 0
          : remDevices - licenceObj?.available_quantity
      prev.push(licenceObj)
    }
    return prev
  }, [])
  setCheckedLicenses(selectedLicensesArr)
  const disabledRows =
    remDevices === 0
      ? getSubscriptionsDisabledRows(viewData, selectedLicensesArr)
      : []
  setRowsToDisable(disabledRows || [])
  setRemainingDevices(remDevices)
}

export const handleSubmit = ({
  remainingDevices,
  setNoSelectionErrorMsg,
  licenseTierOptions,
  licenseTierValue,
  formValues,
  setFormValues,
  selectedLicenses,
  deviceType,
  modelType,
  checkedLicenses,
  licenseTier,
  licenseTierDescription,
  onSetOpen,
  actionOnSubmit
}) => {
  if (remainingDevices !== 0) {
    setNoSelectionErrorMsg(true)
  } else {
    setNoSelectionErrorMsg(false)
    const licenseTierDesc =
      licenseTierOptions?.find((value) => value.value === licenseTierValue)
        ?.label || ''
    const modifiedFormValues = {
      ...formValues,
      selectedLicenses: {
        ...selectedLicenses,
        [deviceType]: {
          ...selectedLicenses[deviceType],
          [modelType]: checkedLicenses
        }
      },
      licenseTier: {
        ...licenseTier,
        [deviceType]: {
          ...licenseTier?.[deviceType],
          [modelType]: licenseTierValue
        }
      },
      licenseTierDescription: {
        ...licenseTierDescription,
        [deviceType]: {
          ...licenseTierDescription?.[deviceType],
          [modelType]: licenseTierDesc
        }
      }
    }
    setFormValues(modifiedFormValues)
    if (actionOnSubmit) actionOnSubmit(modifiedFormValues)
    else onSetOpen(false)
  }
}
export const initialDeviceTypeState = {
  AP: {},
  GATEWAY: {},
  SD_WAN_GW: {},
  SWITCH: {},
  NW_THIRD_PARTY: {},
  COMPUTE: {},
  STORAGE: {},
  SENSOR: {},
  PCE: {},
  BRIDGE: {}
}
export const accordionPanelTheme = (deepMerge, hpe) => {
  return deepMerge(hpe, {
    accordion: {
      panel: {
        border: {
          color: 'transparent'
        }
      },
      hover: {
        background: 'white'
      },
      border: {
        color: 'transparent'
      }
    }
  })
}
export const getLicenseNotSelectedCount = (
  deviceData,
  deviceType,
  formValues,
  isGlobalNotif = false
) => {
  let licenseNotSelectedCount = 0
  if (isGlobalNotif) {
    Object.entries(formValues.deviceDetails).forEach((currDeviceType) => {
      const deviceDataArray = Object.entries(currDeviceType[1])
      if (
        Object.keys(formValues.selectedLicenses?.[currDeviceType[0]])
          ?.length !== deviceDataArray.length
      ) {
        licenseNotSelectedCount += deviceDataArray.length
      }
    })
    return licenseNotSelectedCount
  }
  const deviceDataArray = Object.entries(deviceData)
  if (
    Object.keys(formValues.selectedLicenses?.[deviceType])?.length !==
    deviceDataArray.length
  ) {
    deviceDataArray.forEach((device) => {
      if (!formValues.selectedLicenses?.[deviceType]?.[device[0]])
        licenseNotSelectedCount += device[1]?.length
    })
  }
  return licenseNotSelectedCount
}

export const isSingleDeviceSelected = (formValues, getFlattenedObj) => {
  return (
    flatMapDepth(
      omit(formValues?.deviceDetails, 'platformSubCatEnum'),
      getFlattenedObj,
      2
    )?.length === 1
  )
}
export const getIndex = (formValues, setIsSingleDeviceType, setActiveIndex) => {
  if (
    Object.keys(formValues?.deviceDetails).length === 1 ||
    (Object.keys(formValues?.deviceDetails).length === 2 &&
      formValues?.deviceDetails?.COMPUTE)
  ) {
    setIsSingleDeviceType(true)
    switch (Object.keys(formValues?.deviceDetails)[0]) {
      case 'AP':
        setActiveIndex([0])
        break
      case 'GATEWAY':
        setActiveIndex([1])
        break
      case 'SD_WAN_GW':
        setActiveIndex([2])
        break
      case 'SWITCH':
        setActiveIndex([3])
        break
      case 'SENSOR':
        setActiveIndex([4])
        break
      case 'BRIDGE':
        setActiveIndex([5])
        break
      case 'NW_THIRD_PARTY':
        setActiveIndex([6])
        break
      case 'COMPUTE':
        setActiveIndex([7])
        break
      case 'STORAGE':
        setActiveIndex([8])
        break
      case 'PCE':
        setActiveIndex([9])
        break
      default:
        setActiveIndex([])
    }
  }
}
export const getLicenseTier = (
  details,
  oidcUser,
  setLicenseTierOptions,
  setErrorMsg,
  t
) => {
  post(
    '/ui-doorway/ui/v1/license/tiers/devices',
    {
      key_type: 'BASE',
      devices: details?.map((value) => {
        return {
          serial_number: value?.serial_number,
          part_number: value?.part_number
        }
      })
    },
    oidcUser.access_token
  ).then(
    (response) => {
      // To combine tiers from different objects
      const subscriptionTiers =
        response?.data?.supported_subscription_tiers?.reduce((prev, curr) => {
          return { ...prev, ...curr.subscription_tiers_description }
        }, {})
      const modifiedSubscriptionTiers = Object.entries(subscriptionTiers)?.map(
        ([value, label]) => ({
          value,
          label
        })
      )
      setLicenseTierOptions(modifiedSubscriptionTiers || [])
    },
    (error) => {
      setLicenseTierOptions([])
      setErrorMsg(displayApiError(error, t, setErrorMsg))
    }
  )
}

export const commonColumns = (t) => [
  {
    property: 'subscription_tier_description',
    type: 'string',
    header: t('subscription_tier_sentence'),
    render: (datum) =>
      datum?.subscription_tiers_description?.[datum?.subscription_tier]
  },
  {
    property: 'quantity',
    type: 'numeric',
    header: t('available')
  },
  {
    property: 'expiration',
    type: 'date',
    header: t('expiration_date_sentence'),
    render: (datum) => getDateByMonthAbvDayYear(datum?.subscription_end)
  }
]
