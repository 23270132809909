// Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Box, Layer } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../components'
import { UPSActions, useUPSContext } from '../../../../context/ups-context'
import { post } from '../../../../utils/api-utils'
import { getDataBasedOnSetting } from '../../../../utils/ups-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { displayNotification } from '../../../../utils/notificiation-utils'

export const DismissWidgetsModal = ({ onClose, setNotification }) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['common', 'user_personalisation'])
  const { commonpreferences, dispatchUPSContext } = useUPSContext()

  const [showLoader, setLoader] = useState(false)

  const handleWidgetsPreferenceToggle = async () => {
    setNotification(null)
    setLoader(true)
    try {
      const category = 'commonpreferences'
      const categoryData = getDataBasedOnSetting(
        commonpreferences,
        'showWidgets'
      )
      const requestBody = {
        category,
        data: [{ ...categoryData, value: false }]
      }
      const response = await post(
        `/user-prefs/v1alpha1/save-preferences`,
        requestBody,
        oidcUser.access_token
      )
      if (response?.status === 200) {
        dispatchUPSContext({
          type: UPSActions.SET_CATEGORY_DATA,
          data: requestBody
        })
        setNotification(
          displayNotification(
            t('dashboard.widgets.widgets_dismissed'),
            'info',
            setNotification
          )
        )
        onClose()
      }
    } catch (error) {
      const overrideMessage = t(
        'user_personalisation:common_preferences.widgets.home_hide_error'
      )
      setNotification(
        displayApiError(error, t, setNotification, overrideMessage)
      )
    } finally {
      setLoader(false)
    }
  }

  return (
    <>
      {showLoader && (
        <Layer position="center" data-testid="widget-loader-layer" plain>
          <Loader testId="loader-spinner" />
        </Layer>
      )}
      <ModalDialog
        position="center"
        width="medium"
        testId="dismiss-widget-modal"
        header={
          <ModalHeader
            title={
              <Box align="start" pad="xsmall">
                <Typography level="2" type="heading">
                  {t('dashboard.widgets.dismiss_widgets_modal.title')}
                </Typography>
              </Box>
            }
          />
        }
        content={
          <Box align="start" pad="xsmall">
            {t('dashboard.widgets.dismiss_widgets_modal.subtitle')}
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <Box direction="row" gap="small">
                <Button
                  data-testid="dismiss-widget-cancel-btn"
                  label={t('cancel')}
                  default
                  onClick={() => onClose()}
                />
                <Button
                  data-testid="dismiss-widget-accept-btn"
                  label={t('dashboard.widgets.buttons.confirm_dismiss')}
                  primary
                  onClick={handleWidgetsPreferenceToggle}
                />
              </Box>
            }
          />
        }
        onClose={onClose}
      />
    </>
  )
}

DismissWidgetsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired
}
