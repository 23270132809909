import { useEffect, useRef } from 'react'
import useSWR, { useSWRConfig } from 'swr'

import { useEventFetcher } from './data'

const getUrlKey = ({ wantedPage, limit, filter }) =>
  `${filter ? `filter=${filter}&` : ''}wanted-page=${wantedPage}&limit=${limit}`
const mkFilter = (nonSearchFilters, search) => {
  const filters = nonSearchFilters.slice()
  if (search) {
    filters.unshift(`search eq '${search}'`)
  }
  return filters.join(' and ')
}
const getClosestPage = ({ pageDataRef, wantedPage, limit, filter }) => {
  const closestPage = (pageDataRef.current?.previousPages || []).reduce(
    (acc, cur) => {
      if (
        cur.limit === limit &&
        cur.filter === filter &&
        (Math.abs(wantedPage - cur.page) < Math.abs(wantedPage - acc.page) ||
          !acc.next)
      ) {
        return cur
      }
      return acc
    },
    {
      next: '',
      page: 1,
      limit,
      filter
    }
  )
  return closestPage
}
// This must be used in combination with the useSavePageData prop
const useEventsUrl = ({ page, limit, filters, search }) => {
  const pageDataRef = useRef({
    urls: new Map(),
    previousPages: []
  })
  const filter = mkFilter(filters, search)
  const urlKey = getUrlKey({
    wantedPage: page,
    limit,
    filter
  })
  let url = pageDataRef.current?.urls?.get(urlKey)
  if (!url) {
    const closestPage = getClosestPage({
      pageDataRef,
      wantedPage: page,
      limit,
      filter
    })
    if (page > 1 && !closestPage.next) {
      throw new Error('Failed to get necessary next param')
    }
    url = `/wellness/v2beta1/events?${urlKey}&current-page=${closestPage.page}&next=${closestPage.next}`
  }
  // You can only update refs in a useEffect because they are not instantiated until after the first render is done
  useEffect(() => {
    const urlMap = pageDataRef.current?.urls || new Map()
    if (url && !urlMap.has(urlKey)) {
      urlMap.set(urlKey, url)
      pageDataRef.current = {
        previousPages: pageDataRef.current?.previousPages || [],
        urls: urlMap
      }
    }
  })
  return [url, pageDataRef]
}
// This will save a call
const useSaveNext = ({ pageDataRef, data, limit, filters, search }) => {
  const next = data?.next || ''
  const page = data?.page || 1
  const filter = mkFilter(filters, search)
  useEffect(() => {
    // It is only worth caching if it has "next", because we need that for our requests
    if (next) {
      const urlKey = getUrlKey({ wantedPage: page, limit, filter })
      // Filter out previous calls with the exact same data
      const previousPages = (pageDataRef.current?.previousPages || []).filter(
        (prevPage) =>
          getUrlKey({ ...prevPage, wantedPage: prevPage.page }) !== urlKey
      )
      pageDataRef.current = {
        previousPages: [
          ...previousPages,
          {
            next,
            page,
            limit,
            filter
          }
        ],
        urls: pageDataRef.current?.urls || new Map()
      }
    }
  }, [pageDataRef, next, page, limit, filter])
}
// This preloads the swr cache with another call
const usePreload = ({ pageDataRef, enable, page, limit, filters, search }) => {
  const filter = mkFilter(filters, search)
  const urlKey = getUrlKey({
    wantedPage: page,
    limit,
    filter
  })
  // We use null instead of undefined like we normally would because useSWR expects null as the type to not make a request
  let url = null
  if (enable) {
    url = pageDataRef.current?.urls?.get(urlKey) || null
    if (!url) {
      const closestPage = getClosestPage({
        pageDataRef,
        wantedPage: page,
        limit,
        filter
      })
      // fetching doesn't work unless you are fetching page 1 or have a next param to work with
      if (closestPage.next || page === 1) {
        url = `/wellness/v2beta1/events?${urlKey}&current-page=${closestPage.page}&next=${closestPage.next}`
      }
    }
  }
  // We don't care about the result, this is just for caching purposes
  const swrOptions = {
    revalidateIfStale: false
  }
  const eventFetcher = useEventFetcher()
  useSWR(url, eventFetcher, swrOptions)
  useEffect(() => {
    if (url) {
      const urlMap = pageDataRef.current?.urls || new Map()
      urlMap.set(urlKey, url)
      pageDataRef.current = {
        previousPages: pageDataRef.current?.previousPages || [],
        urls: urlMap
      }
    }
  }, [pageDataRef, urlKey, url])
}
const tabFilters = {
  inbox: 'archive ne true',
  flagged: 'flag eq true',
  archived: 'archive eq true'
}
/**
 * Makes a request for the events given the current parameters.
 *
 * This hook manages the pagination which requires tracking previous pages requested
 * in order to provide a "current-page" and "next" parameter to the API. This hook
 * will also preload the next page and the other tabs to make for a snappier UI.
 */
export const useEventsRequest = ({
  page,
  limit,
  filters: filtersParam,
  tab,
  search,
  swrOptions
}) => {
  const tabFilter = tabFilters[tab]
  const [preloadTab0, preloadTab1] = Object.values(tabFilters).filter(
    (tf) => tf !== tabFilter
  )
  const filters = search ? filtersParam : [...filtersParam, tabFilter]
  const [eventsUrl, pageDataRef] = useEventsUrl({
    page,
    limit,
    filters,
    search
  })
  const eventFetcher = useEventFetcher()
  const { data, error, mutate } = useSWR(eventsUrl, eventFetcher, swrOptions)
  useSaveNext({
    pageDataRef,
    data,
    limit,
    filters,
    search
  })
  usePreload({
    pageDataRef,
    enable: !!data?.next,
    page: page + 1,
    limit,
    filters,
    search
  })
  usePreload({
    pageDataRef,
    enable: !search,
    page: 1,
    limit,
    filters: [...filtersParam, preloadTab0],
    search
  })
  usePreload({
    pageDataRef,
    enable: !search,
    page: 1,
    limit,
    filters: [...filtersParam, preloadTab1],
    search
  })
  const { mutate: mutateGlobal } = useSWRConfig()
  const clearCache = () => {
    // From https://github.com/vercel/swr/issues/1887#issuecomment-1171269211
    mutateGlobal(
      (key) => key.startsWith('/wellness/v2beta1/events'),
      undefined,
      false
    )
  }
  return {
    data,
    error,
    mutate,
    clearCache
  }
}
