import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import cloneDeep from 'lodash/cloneDeep'

import { displayApiError } from '../../../../../../../../utils/error-handling-utils'
import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  FormOverallAlert
} from '../../../../../../../../components'
import { post, put } from '../../../../../../../../utils/api-utils'
import { RatesEditor } from '../RatesEditor'
import {
  validateShowbackRates,
  getRatesValidationResult,
  hasNoRatesChange,
  convertToCreatingRatesPayload
} from '../utils'

const ManageSubscriptionRatesModal = ({
  setModalOpen,
  onSuccess,
  setShowErrorNotification,
  subscriptionKey,
  formValues,
  setFormValues
}) => {
  const { t } = useTranslation(['device', 'common'])
  const [errorMessage, setErrorMessage] = useState('')
  const { oidcUser } = useReactOidc()
  const resetGlobalErrMsg = () => {
    if (errorMessage !== '') {
      setErrorMessage('')
    }
  }
  const handleSave = async () => {
    const ratesValidationErrors = validateShowbackRates(formValues, t)
    const validatinoResult = getRatesValidationResult(
      ratesValidationErrors,
      formValues,
      t,
      true
    )
    const hasNoChanges = await hasNoRatesChange(
      formValues.initialRates,
      formValues.ratesPayload
    )
    if (validatinoResult.isValid === false) {
      setFormValues({
        ...formValues,
        ratesErrors: { ...formValues.ratesErrors, ...ratesValidationErrors }
      })
      setErrorMessage(t('showback_rates.wizard_meters_validation_overall'))
    } else if (hasNoChanges) {
      setErrorMessage(t('showback_rates.wizard_meters_validation_no_changes'))
    } else {
      // differential new create and update
      const createOrUpdateTheRates = formValues.hasNoExistedRates
        ? post(
            `/cds/v1/rates/${subscriptionKey}`,
            convertToCreatingRatesPayload(formValues.ratesPayload),
            oidcUser.access_token
          )
        : put(
            `/cds/v1/rates/${subscriptionKey}`,
            formValues.ratesPayload,
            oidcUser.access_token
          )

      createOrUpdateTheRates
        .then(() => {
          setModalOpen(false)
          onSuccess(true)
        })
        .catch(() => {
          setShowErrorNotification(null)
          const failedToSaveMsg = t(
            'device:showback_rates.manage_rates_failed_to_save'
          )
          setErrorMessage(failedToSaveMsg)
          setTimeout(() => {
            setShowErrorNotification(
              displayApiError({}, t, setErrorMessage, failedToSaveMsg)
            )
          })
        })
    }
  }
  const buttonList = [
    {
      label: t('cancel'),
      default: true,
      testId: 'cancel-btn',
      onClick: () => {
        setFormValues((prevValues) => ({
          ...prevValues,
          ratesData: cloneDeep(formValues.initialRates),
          ratesPayload: {
            ...prevValues.ratesPayload,
            pricePlans: cloneDeep(formValues.initialRates.pricePlans)
          },
          ratesErrors: {}
        }))
        setModalOpen(false, true)
      }
    },
    {
      label: t('save'),
      primary: true,
      testId: 'save-btn',
      onClick: () => {
        setTimeout(() => {
          handleSave()
        }, 200)
      }
    }
  ]

  return (
    <>
      <ModalDialog
        width="xlarge"
        testId="manage-showback-rates-modal-dialog"
        onClose={() => {
          setModalOpen(false, true)
        }}
        header={
          <ModalHeader
            title={
              <Typography
                type="heading"
                level="1"
                emphasis
                testId="manage-showback-rates-title"
              >
                {t('device:showback_rates.manage_rates_action_label')}
              </Typography>
            }
          />
        }
        content={
          <Box flex={false}>
            <Typography
              type="text"
              size="large"
              margin={{ top: 'xsmall', bottom: '0px' }}
              testId="manage-showback-rates-subtitle"
            >
              {t('device:showback_rates.manage_rates_sub_title')}
            </Typography>
            <Box
              margin={{ top: 'medium' }}
              pad={{ right: 'small' }}
              width="xlarge"
              direction="column"
            >
              <RatesEditor
                key={subscriptionKey}
                formValues={formValues}
                setFormValues={setFormValues}
                resetGlobalErrMsg={resetGlobalErrMsg}
              />
              {errorMessage && <FormOverallAlert message={errorMessage} />}
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            right={<ButtonGroup buttonList={buttonList} testId="modal-btns" />}
          />
        }
      />
    </>
  )
}

ManageSubscriptionRatesModal.propTypes = {
  formValues: PropTypes.object.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  setFormValues: PropTypes.func.isRequired,
  setShowErrorNotification: PropTypes.func.isRequired,
  subscriptionKey: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export { ManageSubscriptionRatesModal }
