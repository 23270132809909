import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Typography,
  ModalDialog,
  CCSForm,
  ButtonGroup
} from '../../../../../components'
import { getWorkspaceString, WKSPC } from '../../../../../utils/common-utils'

const DeleteSSOModal = ({
  onSetOpen,
  onConfirmDelete,
  domainName,
  errorMessage,
  setErrorMessage,
  submitDisabled
}) => {
  const { t } = useTranslation(['authn'])
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  return (
    <ModalDialog
      width="medium"
      testId="delete-user-modal-dialog"
      content={
        <CCSForm
          errorMessage={errorMessage}
          onSubmit={onConfirmDelete}
          testId="delete-sso-modal"
        >
          <>
            <Box>
              <Typography type="heading" level="2" testId="delete-title">
                {t('saml_sso.delete_connection_header')}
              </Typography>
              <Typography type="text" size="large" textId="delete-message">
                <>
                  <Text type="text" weight="bold">
                    {domainName}
                  </Text>{' '}
                  {t('manage_account.saml_sso.delete_modal_message', {
                    account: getWorkspaceString(showWorkspaceString, t, WKSPC)
                  })}
                </>
              </Typography>
            </Box>
            <Box margin={{ vertical: 'small' }}>
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('saml_sso.cancel'),
                    default: true,
                    testId: 'cancel-delete-btn',
                    onClick: () => {
                      setErrorMessage('')
                      onSetOpen(false)
                    }
                  },
                  {
                    id: 1,
                    label: t('users.delete'),
                    primary: true,
                    type: 'submit',
                    disabled: submitDisabled,
                    testId: 'delete-btn'
                  }
                ]}
                testId="two-buttons"
              />
            </Box>
          </>
        </CCSForm>
      }
      onClose={() => onSetOpen(false)}
    />
  )
}

DeleteSSOModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  domainName: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool.isRequired
}

export default DeleteSSOModal
