import { useReducer } from 'react'

import { getCustomerAccount } from '../../utils/feature-flag-utils'

import VisibilityActions from './actions'

const initialContext = {
  hideWhen: {
    account: null,
    deployment: null,
    connectivity: null,
    feature: new Set()
  },
  rbacPolicies: null,
  v2RbacPolicies: null,
  v2RbacRootWkspcPolicies: null,
  isSupportEngineer: null
}

const VisibilityReducer = (state, action) => {
  switch (action.type) {
    case VisibilityActions.SET_HIDE_WHEN: {
      return {
        ...state,
        hideWhen: {
          ...state.hideWhen,
          [action.data.key]: action.data.value
        }
      }
    }
    case VisibilityActions.SET_RBAC_POLICIES: {
      return {
        ...state,
        rbacPolicies: action.data
      }
    }
    case VisibilityActions.SET_V2_RBAC_POLICIES: {
      return {
        ...state,
        v2RbacPolicies: action.data
      }
    }
    case VisibilityActions.SET_IS_SUPPORT_ENGINEER: {
      return {
        ...state,
        isSupportEngineer: action.data
      }
    }
    case VisibilityActions.SET_V2_ROOT_WKSPC_POLICIES: {
      return {
        ...state,
        v2RbacRootWkspcPolicies: action.data
      }
    }
    default:
      return state
  }
}

const useVisibilityReducer = () => {
  const custAccount = getCustomerAccount()
  if (custAccount && custAccount.account_type) {
    initialContext.hideWhen.account = custAccount.account_type
  }
  if (window.$settings.isCoP) {
    initialContext.hideWhen.deployment = 'COP'
  }
  if (window.$settings.isGLOP) {
    initialContext.hideWhen.deployment = 'GLOP'
  }
  if (window.$settings.isGLOP && window.$settings.isConnected) {
    initialContext.hideWhen.connectivity = 'GLOP-CONNECTED'
  }
  if (window.$settings.isGLOP && window.$settings.isConnected === false) {
    initialContext.hideWhen.connectivity = 'GLOP-DISCONNECTED'
  }
  const [VisibilityState, dispatchVisibilityContext] = useReducer(
    VisibilityReducer,
    initialContext
  )
  return { VisibilityState, dispatchVisibilityContext }
}

export default useVisibilityReducer
