// Copyright 2024 Hewlett Packard Enterprise Development LP
import isEmpty from 'lodash/isEmpty'

export const statusMapping = (t) => ({
  PENDING: t('global_search_administration.status.pending'),
  APPROVED: t('global_search_administration.status.approved'),
  IN_REVIEW: t('global_search_administration.status.in_review')
})

export const getUpdateRunRequestBody = (
  runDetails,
  docDetails,
  status,
  questionData,
  summaryData
) => {
  const requestBody = {
    ...docDetails,
    run_id: runDetails.id,
    approved: status === 'Approved'
  }

  const update = {}

  const updatedQuestions = []
  runDetails.queries.forEach((questionObj) => {
    const { query_id, query } = questionObj
    if (questionData[query_id].trim() !== query) {
      updatedQuestions.push({
        id: query_id,
        text: questionData[query_id]
      })
    }
  })
  if (updatedQuestions.length > 0) {
    update.questions = updatedQuestions
  }

  if (runDetails.summary !== summaryData.summary.trim()) {
    update.summary = {
      ...summaryData
    }
  }

  if (!isEmpty(update)) {
    requestBody.update = update
  }
  return requestBody
}

export const getQuestionsObj = (runDetails) => {
  const obj = {}
  runDetails.queries.forEach((questionObj) => {
    obj[questionObj.query_id] = questionObj.query
  })
  return obj
}

export const setFiltersInSession = (filters) => {
  sessionStorage.setItem(
    'global_search_curation_filters',
    JSON.stringify(filters)
  )
}

export const getFilterFromSession = () => {
  return JSON.parse(sessionStorage.getItem('global_search_curation_filters'))
}
