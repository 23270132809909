// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box, NameValueList, NameValuePair } from 'grommet'
import { FormDown } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Typography, ActionButton, Loader } from '../../../../components'
import { Layout } from '../../layout/Layout'
import VisibilityWrapper from '../../visibility-wrapper/VisibilityWrapper'
import { get, del, post } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import ConfirmDeleteModal from '../../../manage-account/location-management/components/ConfirmDeleteModal'
import { AssignLocationContactsModal } from '../AssignContacts/AssignLocationContactsModal'

import {
  ValidationStatusBox,
  LocationDetailSection,
  AddressesSection,
  ValidationSection,
  SectionTitle,
  Contacts,
  LocationDetailsHeader,
  LocationDetailsTagsSection
} from './components'
import { ManageLocationTags } from './components/ManageLocationTags'

const LocationDetailsPage = ({ customerId, isCCSManager }) => {
  const history = useHistory()
  const { oidcUser } = useReactOidc()
  const { locationId } = useParams()
  const { t } = useTranslation(['location', 'common', 'device'])

  const [locationDetailsData, setLocationDetailsData] = useState()
  const [devicesData, setDevicesData] = useState({
    network: 0,
    storage: 0,
    compute: 0
  })
  const [deviceDataLoading, setDeviceDataLoading] = useState(true)
  const [deviceDataError, setDeviceDataError] = useState(false)

  const [streetAddress, setStreetAddress] = useState()
  const [shippingAddress, setShippingAddress] = useState()

  const [locationDetailsContacts, setLocationDetailsContacts] = useState()
  const [primaryContacts, setPrimaryContacts] = useState()
  const [securityContacts, setSecurityContacts] = useState()
  const [operationContacts, setOperationContacts] = useState()
  const [shippingContacts, setShippingContacts] = useState()
  const [loading, setLoading] = useState(true)
  const [showErrorNotification, setShowErrorNotification] = useState(null)

  const [deleteClicked, setDeleteClicked] = useState(false)

  const [showEditContactsModal, setShowEditContactsModal] = useState(false)
  const [showEditLocationModal, setShowEditLocationModal] = useState(false)
  const [showEditAddressModal, setShowEditAddressModal] = useState(false)

  const LDFlags = useFlags()

  const [tagsData, setTagsData] = useState([])
  const [showEditTagsModal, setShowEditTagsModal] = useState(false)

  const deleteLocItem = {
    label: t('common:delete'),
    onClick: () => {
      setDeleteClicked(true)
    },
    testId: 'delete-location-button'
  }

  const editLocItems = [
    {
      label: t('location:edit_location_detail'),
      onClick: () => {
        setShowEditLocationModal(true)
      },
      testId: 'edit-location-details-button'
    },
    {
      label: t('edit_location_contacts'),
      onClick: () => {
        setShowEditContactsModal(true)
      },
      testId: 'edit-location-contacts-button'
    },
    {
      label: t('location:edit_address'),
      onClick: () => {
        setShowEditAddressModal(true)
      },
      testId: 'edit-location-addresses-button'
    },
    ...(!isCCSManager && LDFlags['glcp-locations-tags']
      ? [
          {
            label: t('device:manage_tags'),
            onClick: () => {
              setShowEditTagsModal(true)
            },
            testId: 'edit-location-tags-button'
          }
        ]
      : [])
  ]

  const deleteHandler = (locId) => {
    if (locId) {
      const url = isCCSManager
        ? `/support-assistant/v1alpha1/location/${locId}`
        : `/ui-doorway/ui/v1/locations/${locId}`
      del(url, {}, oidcUser.access_token).then(
        (response) => {
          if (response.status === 204) {
            setDeleteClicked(false)
            history.push('/manage-account/locations')
          }
        },
        (error) => {
          setDeleteClicked(false)
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
    }
  }

  const loadLocationDetails = useCallback(() => {
    const url = isCCSManager
      ? `/support-assistant/v1alpha1/location/${locationId}`
      : `/ui-doorway/ui/v1/locations/${locationId}`

    get(
      url,
      {
        ...(isCCSManager && {
          platform_customer_id: customerId
        })
      },
      oidcUser.access_token
    ).then(
      (locationDetailsResponse) => {
        setLocationDetailsData(locationDetailsResponse.data)
        setLocationDetailsContacts(locationDetailsResponse.data.contacts)
      },
      (error) => {
        setLoading(false)
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadDevicesDetails = useCallback(() => {
    const url = isCCSManager
      ? `/support-assistant/v1alpha1/stats`
      : `/ui-doorway/ui/v1/devices/stats`

    post(
      url,
      {
        ...(isCCSManager && {
          platform_customer_id: customerId
        }),
        filter: {
          location_id: locationId
        },
        group_by: ['app_category']
      },
      oidcUser.access_token
    ).then(
      (devicesStatsResponse) => {
        const { NETWORK, STORAGE, COMPUTE } =
          devicesStatsResponse.data.app_category
        setDevicesData({
          network: NETWORK,
          storage: STORAGE,
          compute: COMPUTE
        })
        setDeviceDataLoading(false)
      },
      (error) => {
        setDeviceDataLoading(false)
        setDeviceDataError(true)
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadTagsDetails = useCallback(() => {
    get(
      `/ui-doorway/ui/v1/locations/tags/${locationId}`,
      {},
      oidcUser.access_token
    ).then(
      (tagsResponse) => {
        setTagsData(tagsResponse?.data?.tags || [])
      },
      (error) => {
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (locationId) {
      loadLocationDetails()
    }
  }, [
    loadLocationDetails,
    locationId,
    showEditContactsModal,
    showEditLocationModal,
    showEditAddressModal,
    isCCSManager,
    customerId
  ])

  useEffect(() => {
    if (locationId) {
      loadDevicesDetails()
    }
  }, [loadDevicesDetails, locationId])

  useEffect(() => {
    if (locationId && LDFlags['glcp-locations-tags']) {
      loadTagsDetails()
    }
  }, [LDFlags, loadTagsDetails, locationId, showEditTagsModal])

  useEffect(() => {
    if (locationDetailsData && locationDetailsContacts) {
      const primary = []
      const security = []
      const operation = []
      const shipping = []

      locationDetailsContacts.forEach((contact) => {
        const contactType = contact.type
        switch (contactType) {
          case 'primary':
            primary.push(contact)
            break
          case 'shipping_receiving':
            shipping.push(contact)
            break
          case 'security':
            security.push(contact)
            break
          case 'operations':
            operation.push(contact)
            break
          default:
            break
        }
      })
      setPrimaryContacts(primary)
      setSecurityContacts(security)
      setOperationContacts(operation)
      setShippingContacts(shipping)

      const streetAdd = locationDetailsData.addresses.find((add) => {
        return add.type === 'street'
      })
      setStreetAddress(streetAdd)
      const shippingAdd = locationDetailsData.addresses.find((add) => {
        return add.type === 'shipping_receiving'
      })
      setShippingAddress(shippingAdd)
      setLoading(false)
    }
  }, [locationDetailsData, locationDetailsContacts])

  const locationDetailsContent = (
    <>
      <LocationDetailsHeader isCCSManager={isCCSManager} />
      {!loading && (
        <Box align="center" pad="small">
          <Box width="large">
            {/* heading */}
            <Box direction="row" justify="between" align="start">
              <Box data-testid="location-details-header">
                <Typography level="1" type="heading">
                  {locationDetailsData?.name}
                </Typography>
                <Typography type="paragraph">
                  {locationDetailsData?.description}
                </Typography>
              </Box>
              <VisibilityWrapper
                rbac={{
                  permission: 'ccs.location-management.edit',
                  resource: '/ccs/location-management'
                }}
              >
                <ActionButton
                  label={t('common:actions')}
                  icon={<FormDown />}
                  reverse
                  actions={[
                    ...editLocItems,
                    ...(!isCCSManager
                      ? [
                          {
                            label: t('location:validate_location'),
                            onClick: () => {
                              history.push({
                                pathname: `/manage-account/locations/validate-location/${locationId}`,
                                state: { locationDetailsData, tagsData }
                              })
                            },
                            testId: 'validate-location-button'
                          },
                          deleteLocItem
                        ]
                      : [])
                  ]}
                  dropAlign={{
                    right: 'right',
                    top: 'bottom'
                  }}
                  testId="location-details-drop-menu"
                />
              </VisibilityWrapper>
            </Box>
            {(locationDetailsData?.validated ||
              locationDetailsData?.validation_expired) && (
              <VisibilityWrapper
                rbac={{
                  permission: 'ccs.location-management.view',
                  resource: '/ccs/location-management'
                }}
              >
                {/* validation status */}
                <ValidationStatusBox
                  validated={locationDetailsData?.validated}
                  validated_at={locationDetailsData?.validated_at}
                  validation_expired={locationDetailsData?.validation_expired}
                  validation_cycle={locationDetailsData?.validation_cycle}
                  expired_at={locationDetailsData?.expired_at}
                  locationName={locationDetailsData?.name}
                />
              </VisibilityWrapper>
            )}
            {/* details */}
            <LocationDetailSection
              id={locationId}
              name={locationDetailsData.name}
              description={locationDetailsData.description}
              type={locationDetailsData.type}
              showEditLocationModal={showEditLocationModal}
              setShowEditLocationModal={setShowEditLocationModal}
              isCCSManager={isCCSManager}
              customerId={customerId}
            />
            {/* Contacts */}
            <Box data-testid="location-contacts-section">
              <SectionTitle title={t('location:contacts')} />
              {/* Contacts data */}
              <NameValueList>
                <Contacts
                  contacts={primaryContacts}
                  type={t('location:primary')}
                />
                {shippingContacts?.length > 0 && (
                  <Contacts
                    contacts={shippingContacts}
                    type={t('location:shipping_and_receiving')}
                  />
                )}
                {securityContacts?.length > 0 && (
                  <Contacts
                    contacts={securityContacts}
                    type={t('location:security')}
                  />
                )}
                {operationContacts?.length > 0 && (
                  <Contacts
                    contacts={operationContacts}
                    type={t('location:operations')}
                  />
                )}
              </NameValueList>
            </Box>
            {/* Addresses */}
            <AddressesSection
              locationDetailsData={locationDetailsData}
              setShippingAddress={setShippingAddress}
              setStreetAddress={setStreetAddress}
              streetAddress={streetAddress}
              shippingAddress={shippingAddress}
              setShowErrorNotification={setShowErrorNotification}
              showEditAddressModal={showEditAddressModal}
              setShowEditAddressModal={setShowEditAddressModal}
              isCCSManager={isCCSManager}
              customerId={customerId}
            />
            {/* Tags Section */}
            {LDFlags['glcp-locations-tags'] && !isCCSManager && (
              <LocationDetailsTagsSection tagsData={tagsData} />
            )}
            {/* Device */}
            {!deviceDataLoading && !deviceDataError && (
              <Box data-testid="location-devices-section">
                <SectionTitle title={t('location:devices')} />
                <NameValueList valueProps={{ width: ['auto', 'large'] }}>
                  <NameValuePair name={t('location:storage')}>
                    {devicesData.storage}
                  </NameValuePair>
                  <NameValuePair name={t('location:networking')}>
                    {devicesData.network}
                  </NameValuePair>
                  <NameValuePair name={t('location:compute')}>
                    {devicesData.compute}
                  </NameValuePair>
                </NameValueList>
              </Box>
            )}
            {deviceDataLoading && (
              <Box
                height="xsmall"
                width="xsmall"
                align="center"
                justify="center"
                alignSelf="center"
              >
                <Loader testId="loader-spinner" />
              </Box>
            )}
            {(locationDetailsData?.validated ||
              locationDetailsData?.validation_expired) && (
              <VisibilityWrapper
                rbac={{
                  permission: 'ccs.location-management.view',
                  resource: '/ccs/location-management'
                }}
              >
                <ValidationSection
                  validated_at={locationDetailsData?.validated_at}
                  validated_by={`${locationDetailsData?.validated_by_name} (${locationDetailsData?.validated_by_email})`}
                  validation_cycle={locationDetailsData?.validation_cycle}
                />
              </VisibilityWrapper>
            )}
          </Box>
        </Box>
      )}
      {deleteClicked && (
        <ConfirmDeleteModal
          id={locationId}
          setDeleteClicked={setDeleteClicked}
          deleteHandler={deleteHandler}
        />
      )}
      {showEditContactsModal && (
        <AssignLocationContactsModal
          locationDetailsData={locationDetailsData}
          setShowEditContactsModal={setShowEditContactsModal}
          setShowErrorNotification={setShowErrorNotification}
          setLocationDetailsContacts={setLocationDetailsContacts}
          isCCSManager={isCCSManager}
          customerId={customerId}
          handleClose={() => {
            setShowEditContactsModal(false)
          }}
        />
      )}
      {showEditTagsModal && (
        <ManageLocationTags
          locationId={locationId}
          initialTags={tagsData}
          setShowEditTagsModal={setShowEditTagsModal}
          setShowErrorNotification={setShowErrorNotification}
        />
      )}
      {showErrorNotification}
    </>
  )
  return isCCSManager ? (
    locationDetailsContent
  ) : (
    <Layout>{locationDetailsContent}</Layout>
  )
}
LocationDetailsPage.propTypes = {
  customerId: PropTypes.string,
  isCCSManager: PropTypes.bool
}
LocationDetailsPage.defaultProps = {
  customerId: null,
  isCCSManager: false
}
export default LocationDetailsPage
