import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Notification,
  Typography
} from '../../../../components'
import { getErrorMessage, post } from '../../../../utils/api-utils'

const UnclaimSubscriptionModal = ({
  currentCustomerId,
  subscriptionKey,
  setShowUnclaimModal,
  onSuccess
}) => {
  const { t } = useTranslation(['manage'])
  const [errorMessage, setErrorMessage] = useState('')
  const { oidcUser } = useReactOidc()
  const handleUnclaim = () => {
    const url = `/support-assistant/v1alpha1/unclaim-subscription/${subscriptionKey}`
    const requestBody = {
      headers: {
        platform_customer_id: currentCustomerId
      }
    }
    post(url, requestBody, oidcUser.access_token).then(
      () => {
        setErrorMessage('')
        setShowUnclaimModal(false)
        onSuccess()
      },
      (error) => {
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
      }
    )
  }
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              level="1"
              testId="unclaim-subscription-title"
              type="heading"
            >
              {t('unclaim_subscription')}
            </Typography>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }} gap="medium">
          <Typography type="text" size="medium" testId="unclaim-sub-desc1">
            {t('unclaim_subscription_description')}
          </Typography>
          <Typography type="text" size="medium" testId="unclaim-sub-desc2">
            <Trans
              i18nKey="unclaim_subscription_confirm_msg"
              t={t}
              subscriptionKey={subscriptionKey}
            >
              <strong>{{ subscriptionKey }}</strong>
            </Trans>
          </Typography>
          {errorMessage.length ? (
            <Notification
              backgroundColor="status-critical"
              onClose={() => setErrorMessage('')}
              testId="unclaim-subscription-error-message"
              text={errorMessage}
              type="inline"
            />
          ) : null}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  secondary: true,
                  testId: 'cancel-btn',
                  onClick: () => setShowUnclaimModal(false)
                },
                {
                  id: 1,
                  label: t('unclaim_subscription'),
                  primary: true,
                  testId: 'unclaim-subscription-btn',
                  onClick: handleUnclaim
                }
              ]}
              testId="unclaim-subscription-buttons"
            />
          }
        />
      }
      onClose={() => setShowUnclaimModal(false)}
      testId="unclaim-subscription-modal-dialog"
      width="medium"
    />
  )
}

UnclaimSubscriptionModal.propTypes = {
  currentCustomerId: PropTypes.string.isRequired,
  subscriptionKey: PropTypes.string.isRequired,
  setShowUnclaimModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
}

UnclaimSubscriptionModal.defaultProps = {
  onSuccess: () => {}
}

export { UnclaimSubscriptionModal }
