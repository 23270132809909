import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import {
  Box,
  Button,
  DateInput,
  FormField,
  Grommet,
  Layer,
  Select,
  Stack,
  Text,
  TextInput
} from 'grommet'
import { Close, Filter, Search } from 'grommet-icons'
import { colors, hpe } from 'grommet-theme-hpe'
import { deepMerge } from 'grommet/utils'
import { useTranslation } from 'react-i18next'

import { deviceValues, serviceValues } from '../../utils/data'
import { useBodyScroll } from '../../../utils/useBodyScroll'

import MultiSelect from './MultiSelect'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
// We need
const theme = deepMerge(hpe, {
  formField: {
    border: false,
    content: {
      margin: 'none'
    }
  }
})
const FilterPanel = ({ setFilters }) => {
  const { t } = useTranslation('unified_wellness')
  const [dateRange, setDateRange] = useState([])
  const [status, setStatus] = useState('all')
  const [state, setState] = useState('all')
  const [caseStatus, setCaseStatus] = useState('all')
  const [services, setServices] = useState(new Set(serviceValues))
  const [devices, setDevices] = useState(new Set(deviceValues))
  const [deviceSearch, setDeviceSearch] = useState('')
  const [filterCount, setFilterCount] = useState(0)
  const [showFilter, setShowFilter] = useState(false)
  // This is a workaround for a grommet bug
  const [remountKey, setRemountKey] = useState(0)
  const getCompleteState = (count) =>
    JSON.parse(
      JSON.stringify({
        dateRange,
        status,
        state,
        caseStatus,
        services: Array.from(services.values()),
        devices: Array.from(devices.values()),
        filterCount: count
      })
    )
  const [previousState, setPreviousState] = useState(getCompleteState(0))
  useBodyScroll(!showFilter)
  /**
   * This function gets a list of all the filters and sends it to the event list.
   */
  const applyFilter = () => {
    const filters = []
    if (dateRange.length) {
      filters.push(
        `initialtimestamp ge ${dateRange[0]} and initialtimestamp le ${dayjs(
          dateRange[1]
        )
          .endOf('day')
          .toISOString()}`
      )
    }
    if (status !== 'all') {
      filters.push(`severity eq '${status}'`)
    }
    if (state !== 'all') {
      switch (state) {
        case 'read':
          filters.push('read eq true')
          break
        case 'unread':
          filters.push('read ne true')
          break
        case 'flagged':
          filters.push('flag eq true')
          break
        case 'unflagged':
          filters.push('flag ne true')
          break
        case 'archived':
          filters.push('archive eq true')
          break
        default:
          break
      }
    }
    if (caseStatus !== 'all') {
      switch (caseStatus) {
        case 'open':
          filters.push(`status eq 'open'`)
          break
        case 'closed':
          filters.push(`status eq 'closed'`)
          break
        default:
          break
      }
    }
    if (services.size < serviceValues.length && services.size > 0) {
      filters.push(
        `service in (${[...services].map((e) => `'${e}'`).join(', ')})`
      )
    }
    if (devices.size < deviceValues.length && devices.size > 0) {
      filters.push(
        `product in (${[...devices].map((e) => `'${e}'`).join(', ')})`
      )
    }
    setFilterCount(filters.length)
    setFilters(filters)
    setPreviousState(getCompleteState(filters.length))
  }
  const searchFilteredDevices = deviceValues.filter((value) =>
    deviceSearch
      ? value.toLowerCase().indexOf(deviceSearch.toLowerCase()) >= 0
      : true
  )
  const clearFilters = () => {
    setDateRange([])
    setStatus('all')
    setState('all')
    setCaseStatus('all')
    setServices(new Set(serviceValues))
    setDevices(new Set(deviceValues))
    setFilterCount(0)
    setPreviousState(
      JSON.parse(
        JSON.stringify({
          dateRange: [],
          status: 'all',
          state: 'all',
          caseStatus: 'all',
          services: serviceValues,
          devices: deviceValues,
          filterCount: 0
        })
      )
    )
  }
  const revertFilters = () => {
    setDateRange(previousState.dateRange)
    setStatus(previousState.status)
    setState(previousState.state)
    setCaseStatus(previousState.caseStatus)
    setServices(new Set(previousState.services))
    setDevices(new Set(previousState.devices))
    setFilterCount(previousState.filterCount)
  }
  return (
    <>
      <Box margin={{ left: 'medium' }} direction="row" align="center">
        <Stack anchor="top-right">
          <Box pad={{ right: 'xsmall' }}>
            <Button
              kind="toolbar"
              type="button"
              icon={<Filter />}
              onClick={() => setShowFilter(true)}
              data-testid="filter-button"
            />
          </Box>
          {filterCount > 0 && (
            <Box
              background={colors.text.light}
              pad={{ horizontal: 'xsmall' }}
              round
              data-testid="filter-count"
            >
              <Text size="xsmall" color="white">
                {filterCount}
              </Text>
            </Box>
          )}
        </Stack>
        {filterCount > 0 && (
          <Box
            onClick={() => {
              clearFilters()
              setFilters([])
            }}
            data-testid="filter-clear"
          >
            <Text>{t('filter.clear_filters')}</Text>
          </Box>
        )}
      </Box>
      {showFilter && (
        <Layer
          onEsc={() => {
            revertFilters()
            setShowFilter(false)
          }}
          onClickOutside={() => {
            revertFilters()
            setShowFilter(false)
          }}
          position="right"
          full="vertical"
          modal
        >
          <Box
            width="medium"
            pad="medium"
            style={{ overflow: 'scroll', height: '100%' }}
          >
            <div>
              <Box direction="row" justify="between">
                <Box>
                  <Text
                    data-testid="details-title"
                    size="xxlarge"
                    weight="bold"
                  >
                    {t('filter.filter')}
                  </Text>
                </Box>
                <Box>
                  <Button
                    data-testid="details-close-btn"
                    label={<Close size="small" color="black" />}
                    onClick={() => {
                      revertFilters()
                      setShowFilter(false)
                    }}
                  />
                </Box>
              </Box>
              <FormField htmlFor="date-input" label={t('filter.date_reported')}>
                <DateInput
                  key={remountKey}
                  format="mm/dd/yyyy-mm/dd/yyyy"
                  value={dateRange}
                  onChange={({ value }) => {
                    // Basically, if the DateInput component is fubared, increment the remountKey in order to trigger a remount
                    // otherwise use the value as normal
                    if (value.length && typeof value[0] === 'undefined') {
                      setRemountKey(remountKey + 1)
                    } else {
                      setDateRange(value)
                    }
                  }}
                  calendarProps={{ margin: { top: 'small' } }}
                  data-testid="date-input"
                  id="date-input"
                />
              </FormField>
              <FormField htmlFor="status-select" label={t('filter.status')}>
                <Select
                  options={[
                    { label: t('status.all'), value: 'all' },
                    { label: t('status.critical'), value: 'critical' },
                    { label: t('status.warning'), value: 'warning' },
                    { label: t('status.notice'), value: 'notice' }
                  ]}
                  labelKey="label"
                  valueKey={{ key: 'value', reduce: true }}
                  value={status}
                  onChange={(option) => setStatus(option.value)}
                  data-testid="status-select"
                  id="status-select"
                />
              </FormField>
              <FormField htmlFor="state-select" label={t('filter.state')}>
                <Select
                  options={[
                    { label: t('filter.all'), value: 'all' },
                    { label: t('filter.read'), value: 'read' },
                    { label: t('filter.unread'), value: 'unread' },
                    { label: t('filter.flagged'), value: 'flagged' },
                    { label: t('filter.unflagged'), value: 'unflagged' }
                  ]}
                  labelKey="label"
                  valueKey={{ key: 'value', reduce: true }}
                  value={state}
                  onChange={(option) => setState(option.value)}
                  data-testid="state-select"
                  id="state-select"
                />
              </FormField>
              <FormField
                htmlFor="case-status-select"
                label={t('filter.case_status')}
              >
                <Select
                  options={[
                    { label: t('filter.all'), value: 'all' },
                    { label: t('filter.open'), value: 'open' },
                    { label: t('filter.closed'), value: 'closed' }
                  ]}
                  labelKey="label"
                  valueKey={{ key: 'value', reduce: true }}
                  value={caseStatus}
                  onChange={(option) => setCaseStatus(option.value)}
                  data-testid="case-status-select"
                  id="case-status-select"
                />
              </FormField>
              <FormField label={t('filter.services')}>
                <MultiSelect
                  options={serviceValues.map((value) => ({
                    label: t(`services.${value}`),
                    value
                  }))}
                  onChange={setServices}
                  values={services}
                  data-testid="services-multiselect"
                />
              </FormField>
              <Grommet theme={theme}>
                <FormField label={t('filter.devices')} />
              </Grommet>
              <Box width="medium" margin={{ vertical: 'xsmall' }}>
                <TextInput
                  placeholder={t('search.search')}
                  value={deviceSearch}
                  onChange={(event) => setDeviceSearch(event.target.value)}
                  icon={<Search />}
                  data-testid="device-search-input"
                />
              </Box>
              <Box direction="row" justify="between">
                <Text size="xsmall" data-testid="search-count-text">
                  <Text data-testid="device-checked-count">{devices.size}</Text>
                  {` ${t('search.of')} `}
                  <Text data-testid="device-options-count">
                    {deviceValues.length}
                  </Text>
                </Text>
                <Box
                  onClick={() => {
                    if (devices.size === 0) {
                      setDevices(new Set(deviceValues))
                    } else {
                      setDevices(new Set())
                    }
                  }}
                >
                  <Text
                    data-testid={
                      devices.size === 0
                        ? 'device-select-all-btn'
                        : 'device-clear-all-btn'
                    }
                    weight="bold"
                  >
                    {devices.size === 0
                      ? t('filter.select_all')
                      : t('filter.clear_all')}
                  </Text>
                </Box>
              </Box>
              <FormField>
                {searchFilteredDevices.length ? (
                  <MultiSelect
                    options={searchFilteredDevices.map((value) => ({
                      label: value.startsWith('HPE') ? value : `HPE ${value}`,
                      value
                    }))}
                    onChange={setDevices}
                    values={devices}
                    data-testid="devices-multiselect"
                  />
                ) : (
                  <Text
                    margin="xsmall"
                    color="text-weak"
                    data-testid="devices-noresults"
                  >
                    {t('filter.no_results')}
                  </Text>
                )}
              </FormField>
              <Box
                direction="row"
                justify="start"
                gap="small"
                pad={{ top: 'small' }}
              >
                <Button
                  primary
                  label={t('filter.apply')}
                  onClick={() => {
                    applyFilter()
                    setShowFilter(false)
                  }}
                />
                <Button
                  secondary
                  label={t('filter.clear')}
                  onClick={clearFilters}
                />
              </Box>
            </div>
          </Box>
        </Layer>
      )}
    </>
  )
}

FilterPanel.propTypes = {
  setFilters: PropTypes.func.isRequired
}

export default FilterPanel
