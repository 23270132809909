import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../components'
import { Layout } from '../../commoncomponents/layout/Layout'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'
import {
  getWorkspaceString,
  WKSPC_PLURAL,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'

import CountryUpdateForm from './country-update-form'

const getAccountLabels = (t, showWorkspaceString) => {
  return {
    accountHeading: {
      label: t('authn:acct_onboarding.account-heading-label', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      }),
      subHeading: t('authn:acct_onboarding.account-heading-subheading', {
        accounts: getWorkspaceString(showWorkspaceString, t, WKSPC_PLURAL)
      })
    },
    profileHeading: {
      label: 'Profile Update Required',
      subHeading: 'Which country is your primary place of residence?'
    },
    adminActionHeading: {
      label: 'Administrator Action Required',
      subHeading: t('authn:acct_onboarding.admin-action-heading-subheading', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      })
    }
  }
}
const CountryUpdateHeader = ({ labels }) => (
  <Box>
    <Typography type="heading" level="1" testId="choose-country-header">
      {labels.label}
    </Typography>
    <Typography
      type="text"
      size="xlarge"
      margin={{ bottom: 'large' }}
      testId="country-location-text"
    >
      {labels.subHeading}
    </Typography>
  </Box>
)

CountryUpdateHeader.propTypes = {
  labels: PropTypes.shape({
    label: PropTypes.string,
    subHeading: PropTypes.string
  }).isRequired
}

const AdminComponent = ({ t, showWorkspaceString }) => {
  const accountLabels = getAccountLabels(t, showWorkspaceString)
  const custAccountLoaded = getCustomerAccount()
  return (
    <Box>
      <CountryUpdateHeader
        labels={
          custAccountLoaded?.defaulted_country_code
            ? accountLabels.accountHeading
            : accountLabels.profileHeading
        }
      />
      <CountryUpdateForm admin />
    </Box>
  )
}
AdminComponent.propTypes = {
  t: PropTypes.func.isRequired,
  showWorkspaceString: PropTypes.bool.isRequired
}
const NonAdminComponent = ({ t, showWorkspaceString }) => {
  const accountLabels = getAccountLabels(t, showWorkspaceString)
  return (
    <Box>
      <CountryUpdateHeader
        labels={
          JSON.parse(sessionStorage.getItem('defaultedProfileCountryCode'))
            ? accountLabels.profileHeading
            : accountLabels.adminActionHeading
        }
      />
      <CountryUpdateForm admin={false} />
    </Box>
  )
}
NonAdminComponent.propTypes = {
  t: PropTypes.func.isRequired,
  showWorkspaceString: PropTypes.bool.isRequired
}
const ChooseCountryContent = () => {
  const { t } = useTranslation(['common', 'authn'])
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const rbacObj = {
    resource: '/ccs/accounts/platform/customer',
    permission: 'ccs.accounts.platform.customer.edit'
  }

  return (
    <Box
      direction="row"
      justify="center"
      margin={{ top: 'large', bottom: 'large' }}
    >
      <Box width="large">
        <VisibilityWrapper
          rbac={rbacObj}
          fallbackComponent={
            <NonAdminComponent
              showWorkspaceString={showWorkspaceString}
              t={t}
            />
          }
        >
          <AdminComponent showWorkspaceString={showWorkspaceString} t={t} />
        </VisibilityWrapper>
      </Box>
    </Box>
  )
}

const ChooseCountryPage = () => {
  return (
    <Layout hideHeaderOptions={['nav', 'bell', 'apps']}>
      <ChooseCountryContent />
    </Layout>
  )
}

export default ChooseCountryPage
