// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useHistory } from 'react-router-dom'
import lodashGet from 'lodash/get'
import { StatusCritical } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

import {
  Notification,
  Anchor,
  Loader,
  Typography,
  Wizard
} from '../../../../../components'
import { validateForm } from '../../../../../utils/validation-utils'
import {
  getErrorMessage,
  get,
  post,
  del,
  put
} from '../../../../../utils/api-utils'
import { displayApiError } from '../../../../../utils/error-handling-utils'
import { ssoModeEnum } from '../../constants'

import General from './steps/General'
import {
  validateConfigureSettingsFields,
  validateGeneralFields,
  validateIDPDetails,
  validateRecoveryUser,
  validateTimeoutField
} from './steps/utils/validation-utils'
import ConfigureSettings from './steps/ConfigureSettings'
import ConfigureIdp from './steps/ConfigureIdp'
import AddIDPDetails from './steps/add-idp-details/AddIDPDetails'
import ReviewAndCreate from './steps/ReviewAndCreate'
import { pullSSOConfigStatus } from './steps/utils/api-utils'
import Timeout from './steps/Timeout'
import {
  attributeMappingDefaultFormValues,
  defaultGeneral
} from './steps/utils/page-utils'
import RecoveryAccount from './steps/recovery-account/RecoveryAccount'

const AddSsoProfile = () => {
  const history = useHistory()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['common', 'iam'])
  const { id } = useParams()
  const [configValues] = useState(null)
  const [multiDomainErrorInfo, setMultiDomainErrorInfo] = useState({})
  const [disableFinishOnSubmit, setDisableFinishOnSubmit] = useState(true)
  const [errorMsg, setErrorMsg] = useState(null)
  const [profileLoading, setProfileLoading] = useState(false)
  const [routingLoading, setRoutingLoading] = useState(false)
  const [apiErrorNotification, setApiErrorNotification] = useState(null)
  const [profileData, setProfileData] = useState(null)
  const [routingData, setRoutingData] = useState(null)
  const [createdSsoProfileData, setCreatedSsoProfileData] = useState(null)
  const [updatedSsoProfileData, setUpdatedSsoProfileData] = useState(null)
  const [initialFormData, setInitialFormData] = useState({})

  const handleRoutingRuleCreateErrorAndNavigate = (profileId) => {
    if (id) {
      history.push(`/manage-account/organization/sso-profiles/${profileId}`, {
        notificationError: t(
          'iam:sso_profiles.view_edit_create.domain_assignment_error'
        )
      })
    } else {
      history.push(`/manage-account/organization/sso-profiles`, {
        notificationError: t(
          'iam:sso_profiles.view_edit_create.domain_assignment_error'
        )
      })
    }
  }

  const actionOnExitFn = useCallback(() => {
    if (id) {
      if (updatedSsoProfileData) {
        const ssoProfileName = updatedSsoProfileData?.name
        history.push(
          `/manage-account/organization/sso-profiles/${updatedSsoProfileData.id}`,
          {
            notification: t(
              'iam:sso_profiles.view_edit_create.profile_updated',
              {
                ssoProfileName
              }
            )
          }
        )
      } else {
        history.push(`/manage-account/organization/sso-profiles/${id}`)
      }
    } else if (createdSsoProfileData) {
      const ssoProfileName = createdSsoProfileData?.name
      history.push(`/manage-account/organization/sso-profiles`, {
        notification: t('iam:sso_profiles.view_edit_create.profile_created', {
          ssoProfileName
        })
      })
    } else {
      history.push('/manage-account/organization/sso-profiles')
    }
  }, [createdSsoProfileData, history, id, updatedSsoProfileData, t])

  useEffect(() => {
    if (createdSsoProfileData || updatedSsoProfileData) {
      actionOnExitFn()
    }
  }, [createdSsoProfileData, updatedSsoProfileData, actionOnExitFn])

  const ssoProfileRequestBody = (formValues) => {
    const attributeMapping = [
      {
        name: 'Email',
        expression: lodashGet(formValues, 'attribute_mapping.email').toString()
      },
      {
        name: 'FirstName',
        expression: lodashGet(
          formValues,
          'attribute_mapping.firstName'
        ).toString()
      },
      {
        name: 'LastName',
        expression: lodashGet(
          formValues,
          'attribute_mapping.lastName'
        ).toString()
      },
      {
        name: 'IdleSessionTimeout',
        expression: lodashGet(formValues, 'timeout').toString()
      }
      // Uncomment the below code if CountryCode is needed
      // {
      //   name: "CountryCode",
      //   expression: lodashGet(formValues, 'attribute_mapping.countryCode').toString()
      // },
    ]

    // Check if ccsAttribute has a value and add it to the array if it does
    const ccsAttribute = lodashGet(
      formValues,
      'attribute_mapping.ccsAttribute'
    ).toString()
    if (
      lodashGet(formValues, 'general.authorizationMethod') ===
      ssoModeEnum.AUTHORIZATION
    ) {
      attributeMapping.push({
        name: 'HPECCSAttribute',
        expression: ccsAttribute
      })
    }

    const body = {
      name: lodashGet(formValues, 'general.profileName').toString(),
      attributeMapping,
      samlIdpConfig: {
        entityId: lodashGet(formValues, 'saml_idp_config.entity_id'),
        loginUrl: lodashGet(formValues, 'saml_idp_config.login_url'),
        logoutUrl: lodashGet(formValues, 'saml_idp_config.logout_url'),
        certificate: lodashGet(
          formValues,
          'saml_idp_config.signing_certificate'
        )
      }
    }

    if (lodashGet(formValues, 'recovery_user.pocEmail')) {
      if (lodashGet(formValues, 'recovery_user.recoveryPassword')) {
        body.recoveryUser = {
          username: lodashGet(formValues, 'recovery_user.recoveryEmail'),
          password: lodashGet(formValues, 'recovery_user.recoveryPassword'),
          recoveryEmail: lodashGet(formValues, 'recovery_user.pocEmail')
        }
      } else {
        body.recoveryUser = {
          username: lodashGet(formValues, 'recovery_user.recoveryEmail'),
          recoveryEmail: lodashGet(formValues, 'recovery_user.pocEmail')
        }
      }
    }

    return body
  }

  const routingRuleRequestBody = (formValues, ssoProfileId) => {
    return {
      conditions: {
        domains: [lodashGet(formValues, 'domain')]
      },
      actions: {
        idp: {
          ssoProfileId,
          ssoMode: lodashGet(formValues, 'general.authorizationMethod')
        }
      }
    }
  }

  const createRoutingRule = async (
    formValues,
    i18nTranslate,
    newProfileData
  ) => {
    if (lodashGet(formValues, 'domain')) {
      const routingRuleRequest = routingRuleRequestBody(
        formValues,
        newProfileData.id
      )

      try {
        const result = await post(
          '/internal-identity/v1alpha1/sso-routing-rules',
          routingRuleRequest
        )

        pullSSOConfigStatus(
          result.headers.get('location'),
          oidcUser,
          () => {
            setErrorMsg('')
            if (id) {
              setUpdatedSsoProfileData(newProfileData)
            } else {
              setCreatedSsoProfileData(newProfileData)
            }
          },
          () => {
            handleRoutingRuleCreateErrorAndNavigate(newProfileData.id)
          }
        )
      } catch (error) {
        handleRoutingRuleCreateErrorAndNavigate(newProfileData.id)
      }
    } else {
      setErrorMsg('')
      if (id) {
        setUpdatedSsoProfileData(newProfileData)
      } else {
        setCreatedSsoProfileData(newProfileData)
      }
    }
  }

  const createSSOProfile = async (formValues, setFormError, i18nTranslate) => {
    try {
      const ssoProfileRequest = ssoProfileRequestBody(formValues)

      const response = await post(
        '/identity/v1alpha1/sso-profiles',
        ssoProfileRequest
      )

      await createRoutingRule(formValues, i18nTranslate, response.data)
    } catch (error) {
      const backendErrorMessage = getErrorMessage(error, i18nTranslate)
      setFormError(backendErrorMessage)
      setDisableFinishOnSubmit(false)
    }
  }

  const removeAttribute = (obj, attribute) => {
    const { [attribute]: removed, ...rest } = obj
    return rest
  }

  const hasFormChanged = (formData, ignoreAttribute = '') => {
    const filteredFormData = removeAttribute(formData, ignoreAttribute)
    const filteredInitialFormData = removeAttribute(
      initialFormData,
      ignoreAttribute
    )

    return (
      JSON.stringify(filteredFormData) !==
      JSON.stringify(filteredInitialFormData)
    )
  }

  const updateSsoProfile = async (formValues, setFormError, i18nTranslate) => {
    try {
      if (!hasFormChanged(formValues)) {
        setFormError(t('iam:sso_profiles.view_edit_create.no_changes_error'))
        setDisableFinishOnSubmit(false)
        return
      }

      const ssoProfileRequest = ssoProfileRequestBody(formValues)

      if (hasFormChanged(formValues, 'domain')) {
        await put(
          `/identity/v1alpha1/sso-profiles/${id}?dry-run=${true}`,
          ssoProfileRequest
        )
      }

      if (routingData) {
        try {
          const url = `/internal-identity/v1alpha1/sso-routing-rules/${routingData.id}`
          const response = await del(url, {})
          if (response.status === 202) {
            await pullSSOConfigStatus(
              response.headers.get('location'),
              oidcUser,
              async () => {
                let ssoUpdateResponse = null
                if (hasFormChanged(formValues, 'domain')) {
                  ssoUpdateResponse = await put(
                    `/identity/v1alpha1/sso-profiles/${id}`,
                    ssoProfileRequest
                  )
                }

                await createRoutingRule(
                  formValues,
                  i18nTranslate,
                  ssoUpdateResponse?.data || profileData
                )
              },
              (error) => {
                const backendErrorMessage = getErrorMessage(
                  error,
                  i18nTranslate
                )
                setFormError(backendErrorMessage)
                setDisableFinishOnSubmit(false)
              }
            )
          } else {
            setFormError(
              t('iam:sso_profiles.view_edit_create.remove_domain_error', {
                domain: routingData.conditions.domains[0]
              })
            )
            setDisableFinishOnSubmit(false)
          }
        } catch (error) {
          setFormError(
            t('iam:sso_profiles.view_edit_create.remove_domain_error', {
              domain: routingData.conditions.domains[0]
            })
          )
          setDisableFinishOnSubmit(false)
        }
      } else {
        let response = null
        if (hasFormChanged(formValues, 'domain')) {
          response = await put(
            `/identity/v1alpha1/sso-profiles/${id}`,
            ssoProfileRequest
          )
        }

        await createRoutingRule(
          formValues,
          i18nTranslate,
          response?.data || profileData
        )
      }
    } catch (error) {
      const backendErrorMessage = getErrorMessage(error, i18nTranslate)
      setFormError(backendErrorMessage)
      setDisableFinishOnSubmit(false)
    }
  }

  const actionOnSubmit = async (formValues, setFormError, i18nTranslate) => {
    setFormError('')
    if (id) {
      await updateSsoProfile(formValues, setFormError, i18nTranslate)
    } else {
      await createSSOProfile(formValues, setFormError, i18nTranslate)
    }
  }

  const wizardButtons = {
    next: {
      label: t('common:button.next'),
      submitForm: false
    },
    finish: id
      ? t('iam:sso_profiles.view_edit_create.save_changes')
      : t('common:create')
  }

  const validateFormData = () => {
    return new Promise((resolve) => {
      resolve()
    })
  }
  const steps = [
    {
      title: t('iam:sso_profiles.view_edit_create.select_domain'),
      childComponents: <General />,
      description: (
        <Typography type="text" size="large" weight={400}>
          <Trans
            i18nKey="iam:sso_profiles.view_edit_create.select_domain_description"
            t={t}
          >
            <Anchor
              testId="select_domain_learn_anchor"
              label={t('common:learn_more')}
              weight="bold"
              onClick={() => dispatchEvent(new Event('context-help'))}
            />
          </Trans>
        </Typography>
      ),
      validateForm: (formValues) => {
        return validateForm(formValues, t, validateGeneralFields)
      }
    },
    {
      title: t('iam:sso_profiles.view_edit_create.map_saml_attributes'),
      description: (
        <Typography type="text" size="large" weight={400}>
          <Trans
            i18nKey="iam:sso_profiles.view_edit_create.map_saml_attributes_description"
            t={t}
          >
            <Anchor
              testId="map_saml_attributes_learn_anchor"
              label={t('common:learn_more')}
              weight="bold"
              onClick={() => dispatchEvent(new Event('context-help'))}
            />
          </Trans>
        </Typography>
      ),
      childComponents: <ConfigureSettings configValues={configValues} />,
      validateForm: (formValues) => {
        return validateForm(formValues, t, validateConfigureSettingsFields)
      }
    },
    {
      title: t('iam:sso_profiles.view_edit_create.configure_idp'),
      description: (
        <Typography type="text" size="large" weight={400}>
          <Trans
            i18nKey="iam:sso_profiles.view_edit_create.configure_idp_description"
            t={t}
          >
            <Anchor
              testId="configure_idp_learn_anchor"
              label={t('common:learn_more')}
              weight="bold"
              onClick={() => dispatchEvent(new Event('context-help'))}
            />
          </Trans>
        </Typography>
      ),
      childComponents: <ConfigureIdp />,
      validateForm: () => validateFormData()
    },
    {
      title: t('iam:sso_profiles.view_edit_create.identity_provider_details'),
      description: (
        <Typography type="text" size="large" weight={400}>
          <Trans
            i18nKey="iam:sso_profiles.view_edit_create.identity_provider_details_description"
            t={t}
          >
            <Anchor
              testId="identity_provider_details_learn_anchor"
              label={t('common:learn_more')}
              weight="bold"
              onClick={() => dispatchEvent(new Event('context-help'))}
            />
          </Trans>
        </Typography>
      ),
      childComponents: (
        <AddIDPDetails
          multiDomainErrorInfo={multiDomainErrorInfo}
          setMultiDomainErrorInfo={setMultiDomainErrorInfo}
        />
      ),
      validateForm: (formValues) => validateIDPDetails(formValues, t)
    },
    {
      title: t('iam:sso_profiles.view_edit_create.create_recovery_account'),
      description: (
        <Typography type="text" size="large" weight={400}>
          <Trans
            i18nKey="iam:sso_profiles.view_edit_create.recovery_account_description"
            t={t}
          >
            <Anchor
              testId="recovery_account_learn_anchor"
              label={t('common:learn_more')}
              weight="bold"
              onClick={() => dispatchEvent(new Event('context-help'))}
            />
          </Trans>
        </Typography>
      ),
      childComponents: <RecoveryAccount />,
      validateForm: (formValues) => {
        return validateForm(formValues, t, validateRecoveryUser)
      }
    },
    {
      title: t('iam:sso_profiles.view_edit_create.specify_session_timeout'),
      description: (
        <Typography type="text" size="large" weight={400}>
          <Trans
            i18nKey="iam:sso_profiles.view_edit_create.specify_session_timeout_description"
            t={t}
          >
            <Anchor
              testId="session_timeout_learn_anchor"
              label={t('common:learn_more')}
              weight="bold"
              onClick={() => dispatchEvent(new Event('context-help'))}
            />
          </Trans>
        </Typography>
      ),
      childComponents: <Timeout />,
      validateForm: (formValues) => {
        return validateForm(formValues, t, validateTimeoutField)
      }
    },
    {
      title: id
        ? t('iam:sso_profiles.view_edit_create.review_save_changes')
        : t('iam:sso_profiles.view_edit_create.review_create'),
      description: t('iam:sso_profiles.view_edit_create.review_description'),
      childComponents: <ReviewAndCreate />,
      validateForm: () => validateFormData()
    }
  ]

  useEffect(() => {
    if (id) {
      setProfileLoading(true)
      setRoutingLoading(true)

      get(`/identity/v1alpha1/sso-profiles/${id}`, {}).then(
        (ssoResponse) => {
          setProfileData(ssoResponse?.data)
          setProfileLoading(false)
        },
        (error) => {
          setProfileLoading(false)
          setApiErrorNotification(
            displayApiError(error, t, setApiErrorNotification)
          )
        }
      )
      get(`/internal-identity/v1alpha1/sso-routing-rules`, {
        'sso-profile-id': id
      }).then(
        (res) => {
          if (res?.data?.items?.length) {
            setRoutingData(res?.data?.items[0])
          }
          setRoutingLoading(false)
        },
        (error) => {
          setRoutingLoading(false)
          setApiErrorNotification(
            displayApiError(error, t, setApiErrorNotification)
          )
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, t])

  const getValueFromArrayObj = (key, arr = []) => {
    return arr.find((obj) => obj.name === key)?.expression
  }

  const formDefaultValues = () => {
    const initialData = {
      general: id
        ? {
            profileName: profileData?.name,
            authorizationMethod: getValueFromArrayObj(
              'HPECCSAttribute',
              profileData?.attributeMapping
            )
              ? ssoModeEnum.AUTHORIZATION
              : ssoModeEnum.AUTHENTICATION_ONLY
          }
        : defaultGeneral.general,
      domain: routingData?.conditions?.domains[0],
      currentDomain: routingData?.conditions?.domains[0],
      attribute_mapping: id
        ? {
            email:
              getValueFromArrayObj('Email', profileData?.attributeMapping) ??
              '',
            firstName:
              getValueFromArrayObj(
                'FirstName',
                profileData?.attributeMapping
              ) ?? '',
            lastName:
              getValueFromArrayObj('LastName', profileData?.attributeMapping) ??
              '',
            ccsAttribute:
              getValueFromArrayObj(
                'HPECCSAttribute',
                profileData?.attributeMapping
              ) ??
              attributeMappingDefaultFormValues.attribute_mapping.ccsAttribute
          }
        : attributeMappingDefaultFormValues.attribute_mapping,
      timeout:
        getValueFromArrayObj(
          'IdleSessionTimeout',
          profileData?.attributeMapping
        ) || 30,
      saml_idp_config: {
        entity_id: profileData?.samlIdpConfig?.entityId,
        login_url: profileData?.samlIdpConfig?.loginUrl,
        logout_url: profileData?.samlIdpConfig?.logoutUrl,
        signing_certificate: profileData?.samlIdpConfig?.certificate,
        metadata_url: '',
        file_info: {},
        config_option: id ? 'Manual entry of configuration details' : ''
      },
      recovery_user: {
        currentPocEmail: profileData?.recoveryUser?.recoveryEmail,
        pocEmail: profileData?.recoveryUser?.recoveryEmail,
        recoveryEmail: profileData?.recoveryUser?.username,
        recoveryUserEnabled: !!profileData?.recoveryUser?.username,
        skipPasswordValidation: !!id
      }
    }
    setInitialFormData(initialData)
    return initialData
  }

  return (
    <>
      {id && (routingLoading || profileLoading) ? (
        <Box direction="row" align="center" justify="center" pad="medium">
          <Loader testId="saml-view-loader" />
        </Box>
      ) : (
        <Box>
          <Wizard
            testId="add-sso-profile-wizard"
            title={
              id
                ? t('iam:sso_profiles.view_edit_create.edit_profile')
                : t('iam:sso_profiles.create_sso_profile')
            }
            formDefaultValues={formDefaultValues}
            actionOnSubmit={(formValues, setFormError) => {
              actionOnSubmit(formValues, setFormError, t)
            }}
            disableFinishOnSubmit={disableFinishOnSubmit}
            buttonLabels={wizardButtons}
            actionOnExit={() => actionOnExitFn()}
            cancelStrings={{
              continueLabel: t(
                'iam:sso_profiles.view_edit_create.stay_in_wizard'
              ),
              exitLabel: t('iam:sso_profiles.view_edit_create.leave_wizard'),
              heading: t(
                'iam:sso_profiles.view_edit_create.leave_without_finishing'
              ),
              text: t(
                'iam:sso_profiles.view_edit_create.lose_progress_warning',
                {
                  action: id
                    ? t('iam:sso_profiles.view_edit_create.update')
                    : t('common:create')
                }
              )
            }}
            steps={steps}
          />
          {errorMsg && (
            <Notification
              backgroundColor="status-critical"
              icon={<StatusCritical color="text-strong" />}
              onClose={() => setErrorMsg(null)}
              text={errorMsg}
              testId="api-notification"
              position="top"
            />
          )}
        </Box>
      )}
      {apiErrorNotification}
    </>
  )
}

export default AddSsoProfile
