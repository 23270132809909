import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, TextInput, TextArea, FormField } from 'grommet'

import { WizardContext } from '../../../../../components/wizard/WizardContext'

const GatewayDetails = () => {
  const { t } = useTranslation(['local-gateway'])
  const {
    formValues,
    formValues: { gatewayDetails },
    formValues: {
      gatewayDetails: { name, description }
    },
    setFormValues
  } = useContext(WizardContext)

  return (
    <Box width="large" margin={{ bottom: 'medium' }}>
      <FormField name="name" label={t('name')} required htmlFor="name-field">
        <TextInput
          name="name-field"
          id="name-field"
          type="text"
          placeholder={t('step_1_name_placeholder')}
          autoComplete="off"
          value={name}
          data-testid="name-input"
          onChange={(e) => {
            const newFormValues = {
              ...formValues,
              gatewayDetails: {
                ...gatewayDetails,
                name: e.target.value
              }
            }
            setFormValues(newFormValues)
          }}
        />
      </FormField>
      <FormField
        name="description"
        label={t('description')}
        data-testid="container-description"
        htmlFor="description-field"
      >
        <TextArea
          id="description-field"
          name="description-field"
          data-testid="description-input"
          placeholder={t('step_1_description_placeholder')}
          value={description}
          onChange={(e) => {
            setFormValues({
              ...formValues,
              gatewayDetails: {
                ...gatewayDetails,
                description: e.target.value
              }
            })
          }}
        />
      </FormField>
    </Box>
  )
}
export default GatewayDetails
