import {
  Box,
  Grid,
  Notification as GrommetNotification,
  NameValueList,
  NameValuePair,
  Text
} from 'grommet'
import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext } from 'styled-components'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Trans, useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEqual from 'lodash/isEqual'

import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  ButtonGroup,
  Notification,
  Loader
} from '../../../../../components'
import { isMSP } from '../../../../../utils/feature-flag-utils'
import { getErrorMessage, put } from '../../../../../utils/api-utils'
import { getCCSAppDetails } from '../../../utils'
import { displayNotification } from '../../../../../utils/notificiation-utils'
import {
  allScopesRRPName,
  getRoleAssignmentSuccessText,
  getRoleUpdateAction
} from '../../utils'
import { getUserName as getUserFullName } from '../../../../../utils/common-utils'

const AssignRoleConfirmationModal = ({
  setModal,
  userData,
  selectedRoleDetails,
  existingRoleAssignments,
  reloadUsersTable,
  accessRulesList,
  inCCSManager,
  customerId,
  setShowNotification,
  onSuccessAssign
}) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authn', 'authz', 'common'])
  const { ccsApplicationId } = getCCSAppDetails()
  const LDFlags = useFlags()
  const isTenantWorkspaceGroup = LDFlags['tenant-workspace-group'] && isMSP
  const rrpWorkForMSP = LDFlags['glcp-msp-rrp'] && isMSP
  const [errorMessage, setErrorMessage] = useState(null)
  const [action, setAction] = useState('') // ADD, OVERWRITE, SWAP_ROLE, MERGE, MERGE_OVERWRITE
  const [loading, setLoading] = useState(false)

  const {
    nameValuePair: { name }
  } = useContext(ThemeContext)

  const filteredPrevRolesByApp = existingRoleAssignments.filter(
    (data) => data.application_id === selectedRoleDetails?.application_id
  )

  const filteredPrevRolesByRoleSlug = filteredPrevRolesByApp.filter(
    (data) => data.slug === selectedRoleDetails?.role_slug
  )

  // set role update action - ADD, OVERWRITE, SWAP_ROLE, MERGE, MERGE_OVERWRITE
  useEffect(() => {
    if (action === '')
      setAction(
        getRoleUpdateAction(
          filteredPrevRolesByApp,
          filteredPrevRolesByRoleSlug,
          selectedRoleDetails,
          ccsApplicationId,
          inCCSManager
        )
      )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // formatting the new role assignment for request body
  const newRoleAssignment = {
    role: {
      slug: selectedRoleDetails.role_slug,
      application_id: selectedRoleDetails.application_id
    },
    ...(selectedRoleDetails.access_rules && {
      access_rules: selectedRoleDetails.access_rules
    }),
    ...(selectedRoleDetails.application_id !== ccsApplicationId &&
      (!isMSP() || rrpWorkForMSP) && {
        resource_restriction_policies:
          selectedRoleDetails.resource_restriction_policies
            ?.map((rrp) => rrp?.id)
            .filter((rrp) => !['', null, undefined].includes(rrp)) || []
      })
  }

  const getUserName = () => {
    return getUserFullName(
      userData?.first_name,
      userData?.last_name,
      userData?.email
    )
  }

  const getRequestBody = () => {
    let requestBody = {}
    if (action === 'ADD') {
      requestBody = {
        add: [newRoleAssignment]
      }
    } else {
      const formattedExistingRoles = existingRoleAssignments.map((data) => ({
        role: {
          slug: data?.slug,
          application_id: data?.application_id
        },
        ...(data.access_rules && {
          access_rules: data.access_rules
        }),
        ...(data.resource_restriction_policies && {
          resource_restriction_policies: data.resource_restriction_policies
            .length
            ? data?.resource_restriction_policies?.map(
                (rrp) => rrp?.resource_restriction_policy_id
              )
            : []
        })
      }))
      if (action === 'SWAP_ROLE') {
        // remove matched role
        const filteredRoleAssignments = formattedExistingRoles.filter(
          (data) =>
            !(
              data?.role?.application_id ===
                selectedRoleDetails.application_id &&
              data?.role?.slug === selectedRoleDetails.role_slug
            )
        )
        requestBody = {
          overwrite: [...filteredRoleAssignments, newRoleAssignment]
        }
      } else if (action === 'MERGE') {
        // action = "MERGE"
        const filteredRoleAssignments = formattedExistingRoles.filter(
          (data) =>
            data.role.application_id !== selectedRoleDetails.application_id
        )
        requestBody = {
          overwrite: [...filteredRoleAssignments, newRoleAssignment]
        }
      } else if (action === 'MERGE_OVERWRITE') {
        // action = "MERGE_OVERWRITE"
        const filteredRoleAssignments = formattedExistingRoles.filter(
          (data) =>
            data.role.application_id === selectedRoleDetails.application_id &&
            data.role.slug !== selectedRoleDetails.role_slug
        )

        const filteredOtherApplicationAssignments =
          formattedExistingRoles.filter(
            (data) =>
              data.role.application_id !== selectedRoleDetails.application_id
          )

        const filteredAssignments = filteredRoleAssignments
          .map((data) => {
            if (data.access_rules.msp && data.access_rules?.tenants?.length) {
              return {}
            }

            if (data.access_rules.msp && selectedRoleDetails.access_rules.msp) {
              data.access_rules.msp = false
            }
            if (
              data.access_rules?.tenants?.length &&
              selectedRoleDetails.access_rules?.tenants?.length
            ) {
              data.access_rules.tenants = []
            }

            if (!data.access_rules.msp && !data.access_rules?.tenants?.length) {
              return {}
            }

            return data
          })
          .filter((data) => data.access_rules)

        requestBody = {
          overwrite: [
            ...filteredAssignments,
            ...filteredOtherApplicationAssignments,
            newRoleAssignment
          ]
        }
      } else {
        // action = "OVERWRITE"
        // this is for apps with support_one_role_only, remove existing roles
        const filteredRoleAssignments = formattedExistingRoles.filter(
          (data) =>
            data.role.application_id !== selectedRoleDetails.application_id
        )
        requestBody = {
          overwrite: [...filteredRoleAssignments, newRoleAssignment]
        }
      }
    }
    return requestBody
  }

  const assignRoleAPICall = () => {
    setLoading(true)
    const requestBody = inCCSManager
      ? {
          roles: getRequestBody(),
          username: userData.email,
          platform_customer_id: customerId
        }
      : getRequestBody()
    const url = inCCSManager
      ? `/support-assistant/v1alpha1/user-roles`
      : `/authorization/ui/v2/customers/users/${userData.email}/roles`

    put(url, requestBody, oidcUser.access_token).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          setErrorMessage(null)
          setLoading(false)
          const message = getRoleAssignmentSuccessText(
            selectedRoleDetails.role_name,
            getUserName(),
            t
          )
          setShowNotification(
            displayNotification(message, 'info', setShowNotification)
          )
          setModal(false)
          reloadUsersTable()
          onSuccessAssign()
        }
      },
      (error) => {
        setLoading(false)
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(
          typeof errorMsg === 'object' ? JSON.stringify(errorMsg) : errorMsg
        )
      }
    )
  }

  const getPreviousRRPName = () => {
    const res = (
      selectedRoleDetails?.support_one_role_only
        ? filteredPrevRolesByApp
        : filteredPrevRolesByRoleSlug
    )
      .filter((data) => data.resource_restriction_policies)
      .map((data) => data.resource_restriction_policies.map((val) => val.name))
      .flat()
    return res.includes(allScopesRRPName)
      ? t('authz:rrp.full_access')
      : res?.map((rrp) => (
          <Typography
            size="medium"
            color="text-strong"
            testId={rrp}
            type="text"
            wordBreak="break-word"
            key={rrp}
          >
            {rrp}
          </Typography>
        ))
  }

  const getNewRRPName = () => {
    return selectedRoleDetails?.limit_resource_access
      ? selectedRoleDetails?.resource_restriction_policies?.map((rrp) => (
          <Typography
            size="medium"
            color="text-strong"
            testId={rrp?.name}
            type="text"
            wordBreak="break-word"
            key={rrp?.name}
          >
            {rrp?.name}
          </Typography>
        ))
      : t('authz:rrp.full_access')
  }

  const getPreviousAccessRuleName = () => {
    let existingRoleAccessRules = filteredPrevRolesByRoleSlug
      .filter((data) => data.access_rules)
      .map((data) => data.access_rules)
    if (
      selectedRoleDetails?.support_one_role_only &&
      action === 'MERGE_OVERWRITE'
    ) {
      const getAccessRules = (roles, condition) => {
        return roles
          .filter((data) => data?.access_rules && condition(data.access_rules))
          .map((data) => data.access_rules)
      }
      // Determine the condition based on selectedRoleDetails
      let condition
      if (
        selectedRoleDetails?.access_rules?.msp &&
        !selectedRoleDetails?.access_rules?.tenants?.length
      ) {
        condition = (access_rules) => access_rules.msp
      } else if (
        !selectedRoleDetails?.access_rules?.msp &&
        selectedRoleDetails?.access_rules?.tenants?.length
      ) {
        condition = (access_rules) => !access_rules.msp
      } else {
        condition = () => true
      }
      // Get existing role access rules based on the determined condition
      existingRoleAccessRules = getAccessRules(
        filteredPrevRolesByApp,
        condition
      )
      if (existingRoleAccessRules.length === 0) {
        existingRoleAccessRules = filteredPrevRolesByApp?.map(
          (data) => data.access_rules
        )
      }
    } else if (
      selectedRoleDetails?.support_one_role_only &&
      action === 'MERGE'
    ) {
      existingRoleAccessRules = filteredPrevRolesByApp
        .filter((data) => data.access_rules)
        .map((data) => data.access_rules)
    }
    const returnVal =
      existingRoleAccessRules
        .map((accessRule) => {
          let label = accessRulesList.find((val) =>
            isEqual(val.access_rules, accessRule)
          )?.label
          if (isTenantWorkspaceGroup) {
            if (
              !label &&
              accessRule?.tenants &&
              accessRule?.tenants[0] !== 'ALL' &&
              accessRule?.msp
            ) {
              label = accessRulesList[3]?.label
            } else if (
              !label &&
              accessRule?.tenants &&
              accessRule?.tenants[0] !== 'ALL' &&
              !accessRule?.msp
            ) {
              label = accessRulesList[4]?.label
            }
          }
          return label
        })
        .join(', ') || '--'
    return returnVal
  }

  const getNewAccessRuleName = () => {
    // if support_one_role_only:
    if (selectedRoleDetails?.support_one_role_only) {
      // let hasMSP = false
      // let hasTanent = false
      // filteredPrevRolesByRoleSlug.forEach((item) => {
      //   if (item?.access_rules?.msp) {
      //     hasMSP = true
      //   }
      //   if (item?.access_rules?.tenants?.length) {
      //     hasTanent = true
      //   }
      // })
      let newAccessRule = ''
      // if (hasMSP && hasTanent) {
      //   newAccessRule = accessRulesList.find(
      //     (data) => data.value === selectedRoleDetails.access_rules_index
      //   )?.label
      // } else {
      newAccessRule = accessRulesList.find(
        (data) => data.value === selectedRoleDetails.access_rules_index
      )?.label
      // }
      return newAccessRule
    }
    // if not support_one_role_only
    return accessRulesList.find(
      (data) => data.value === selectedRoleDetails.access_rules_index
    )?.label
  }

  // for showing data in UI
  const getNewRoleAssignmentUIData = () => {
    return {
      application: selectedRoleDetails?.application_name,
      role: selectedRoleDetails?.role_name,
      ...(isMSP() && { access_rule: getNewAccessRuleName() }),
      ...(selectedRoleDetails?.application_id !== ccsApplicationId &&
        (!isMSP() || rrpWorkForMSP) && {
          additional_resource_restrictions: getNewRRPName()
        })
    }
  }

  // for showing data in UI
  const getPreviousRoleAssignmentUIData = () => {
    let previousRoleAssignment = filteredPrevRolesByRoleSlug?.length
      ? filteredPrevRolesByRoleSlug[0]
      : {
          application_name:
            filteredPrevRolesByApp?.[0]?.application_name || '--',
          role_name:
            filteredPrevRolesByApp?.map((val) => val?.role_name)?.join(', ') ||
            '--'
        }
    const prevAccessRules = filteredPrevRolesByApp.filter(
      (val) => val.access_rules
    )
    const checkExistingMSPandTenantsAccess = (rules) => {
      return rules.some((rule) => {
        const existingAccessRules = rule?.access_rules
        return (
          existingAccessRules?.msp && existingAccessRules?.tenants?.length > 0
        )
      })
    }
    const isMSPandTenantsAccess =
      checkExistingMSPandTenantsAccess(prevAccessRules)
    if (
      selectedRoleDetails?.support_one_role_only &&
      action === 'MERGE_OVERWRITE' &&
      isMSP()
    ) {
      let roleName = '--'

      const accessRuleType = (() => {
        if (
          selectedRoleDetails?.access_rules?.msp &&
          selectedRoleDetails?.access_rules?.tenants?.length === 0 &&
          !isMSPandTenantsAccess
        ) {
          return 'MSP_ONLY'
        }
        if (
          !selectedRoleDetails?.access_rules?.msp &&
          selectedRoleDetails?.access_rules?.tenants?.length &&
          !isMSPandTenantsAccess
        ) {
          return 'TENANTS_ONLY'
        }
        return 'MSP_AND_TENANTS'
      })()

      switch (accessRuleType) {
        case 'MSP_ONLY':
          roleName =
            filteredPrevRolesByApp
              ?.filter((val) => val?.access_rules?.tenants?.length === 0)
              ?.map((val) => val?.role_name)
              ?.join(', ') || '--'
          break
        case 'TENANTS_ONLY':
          roleName =
            filteredPrevRolesByApp
              ?.filter((val) => val?.access_rules && !val?.access_rules?.msp)
              ?.map((val) => val?.role_name)
              ?.join(', ') || '--'
          break
        case 'MSP_AND_TENANTS':
          roleName =
            prevAccessRules?.length === 2
              ? prevAccessRules.map((val) => val.role_name).join(', ') || '--'
              : prevAccessRules[0]?.role_name || '--'
          break
        default:
          break
      }
      previousRoleAssignment = {
        ...previousRoleAssignment,
        role_name: roleName
      }
    } else if (
      selectedRoleDetails?.support_one_role_only &&
      action === 'MERGE' &&
      isMSP()
    ) {
      previousRoleAssignment = {
        ...previousRoleAssignment,
        role_name:
          filteredPrevRolesByApp?.map((val) => val?.role_name)?.join(', ') ||
          '--'
      }
    }
    return {
      application: previousRoleAssignment?.application_name,
      role: previousRoleAssignment?.role_name,
      ...(isMSP() && { access_rule: getPreviousAccessRuleName() }),
      ...(existingRoleAssignments?.application_id !== ccsApplicationId &&
        selectedRoleDetails?.application_id !== ccsApplicationId &&
        (!isMSP() || rrpWorkForMSP) && {
          additional_resource_restrictions: getPreviousRRPName()
        })
    }
  }

  // for rendering the columns in confirmation modal
  const renderData = ({ heading, data }) => (
    <Box gap="medium">
      <Typography
        type="heading"
        level="3"
        testId={heading?.toLowerCase()?.split(' ')?.join('-')}
      >
        {heading}
      </Typography>

      <NameValueList>
        {Object.entries(data).map((datum) => (
          <NameValuePair
            key={datum[0]}
            name={
              <Typography
                testId={datum[0]}
                type="text"
                wordBreak="break-word"
                {...name}
              >
                {t(`users.${datum[0]}`)}
              </Typography>
            }
          >
            <Typography
              testId={`${datum[0]}-value`}
              type="text"
              wordBreak="break-word"
            >
              <>{datum[1] ? datum[1] : '--'}</>
            </Typography>
          </NameValuePair>
        ))}
      </NameValueList>
    </Box>
  )

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Box gap="xsmall">
              <Typography type="heading" level="1" testId="header-title">
                <Trans
                  i18nKey="users.assign_role_to_username"
                  t={t}
                  values={{
                    userName: getUserName()
                  }}
                />
              </Typography>
              <Typography type="text" size="large" testId="header-title">
                {t('users.assign_role_confirmation_modal_subtitle')}
              </Typography>
            </Box>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }} gap="medium">
          {selectedRoleDetails?.support_one_role_only &&
            (isMSP() ? (
              <GrommetNotification
                status="info"
                message={
                  <Trans
                    i18nKey="users.msp_support_one_role_only_msg"
                    t={t}
                    values={{
                      applicationName: selectedRoleDetails?.application_name
                    }}
                    // Grommet text component is needed otherwise the Box
                    // from Typography component forces a line break
                    components={[<Text color="text-strong" weight={500} />]}
                  />
                }
              />
            ) : (
              <GrommetNotification
                status="info"
                message={
                  <Trans
                    i18nKey="users.non_msp_support_one_role_only_msg"
                    t={t}
                    values={{
                      applicationName: selectedRoleDetails?.application_name
                    }}
                    components={[<Text color="text-strong" weight={500} />]}
                  />
                }
              />
            ))}

          {action === '' ? (
            <Loader testId="loader" />
          ) : (
            <Grid
              columns={['1/2', '1/2']}
              areas={[['left', 'right']]}
              rows={['fill']}
            >
              <Box gridArea="left" pad={{ right: 'small' }} data-testid="left">
                {action === 'ADD'
                  ? renderData({
                      heading: t('users.confirm_role'),
                      data: getNewRoleAssignmentUIData()
                    })
                  : renderData({
                      heading: t('users.currently_assigned'),
                      data: getPreviousRoleAssignmentUIData()
                    })}
              </Box>
              {action !== 'ADD' && (
                <Box
                  gridArea="right"
                  pad={{ left: 'small' }}
                  data-testid="right"
                >
                  {renderData({
                    heading: t('users.overwrite_access_with'),
                    data: getNewRoleAssignmentUIData()
                  })}
                </Box>
              )}
            </Grid>
          )}
          {errorMessage && (
            <Notification
              backgroundColor="status-critical"
              onClose={() => {
                setErrorMessage(null)
              }}
              testId="assign-role-error-notification"
              text={errorMessage}
              type="inline"
            />
          )}
          {loading && (
            <Box direction="row" align="center" justify="center">
              <Loader testId="assign-role-loader" />
            </Box>
          )}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('users.cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: () => setModal(false)
                },
                {
                  id: 1,
                  label: t('users.change_role_assignment_label'),
                  primary: true,
                  testId: 'confirm-assignment-role-btn',
                  onClick: assignRoleAPICall,
                  disabled: loading
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={() => setModal(false)}
      testId="assign-role-confirmation-modal"
      width="large"
    />
  )
}

AssignRoleConfirmationModal.propTypes = {
  userData: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  selectedRoleDetails: PropTypes.object.isRequired,
  reloadUsersTable: PropTypes.func,
  existingRoleAssignments: PropTypes.array.isRequired,
  accessRulesList: PropTypes.array.isRequired,
  inCCSManager: PropTypes.bool,
  customerId: PropTypes.string,
  setShowNotification: PropTypes.func,
  onSuccessAssign: PropTypes.func
}

AssignRoleConfirmationModal.defaultProps = {
  reloadUsersTable: () => {},
  inCCSManager: false,
  customerId: undefined,
  setShowNotification: () => {},
  onSuccessAssign: () => {}
}

export { AssignRoleConfirmationModal }
