import dayjs from 'dayjs'

export const IAAS_KEY_TYPE = 'IAAS'
export const NOT_CLAIMED_STATUS = 'NOT_CLAIMED'

export const getExpirationValue = (
  subscriptionEnd,
  type = 'MONTH_ABV_DAY_YEAR'
) => {
  if (subscriptionEnd && dayjs(subscriptionEnd).isValid()) {
    switch (type) {
      case 'MONTH_ABV_DAY_YEAR':
        return dayjs(subscriptionEnd).format('MMM D, YYYY') // Month abbreviation Day, Year (Feb 17, 2014)
      case 'MONTH_DAY_YEAR_BY_SLASH':
        return dayjs(subscriptionEnd).format('MM/DD/YYYY') // Month/Day/Year (02/17/2014)
      default:
        return dayjs(subscriptionEnd).format('MMM D, YYYY') // Month abbreviation Day, Year (Feb 17, 2014)
    }
  }
  return '--'
}

export const isSelectionErrorExist = (formValues) => {
  return !(
    formValues?.selectedDevicesList?.length ||
    formValues?.selectedProductList?.length
  )
}
