import { displayApiError } from '../../../../../utils/error-handling-utils'
import { post } from '../../../../../utils/api-utils'
import { displayNotification } from '../../../../../utils/notificiation-utils'
import {
  displayNotificationContent,
  handleErrorNotification
} from '../../../../../utils/reporting-utils'

export const generateReportForSubscriptionsAPICall = (
  request,
  token,
  setNotification,
  onSuccess,
  onError,
  t,
  reportingFlag
) => {
  post(`/report-mgmt/ui/v1/exports`, request, token).then(
    (response) => {
      onSuccess(response)
      const refId = response?.data?.tracking_id
      const repName = response?.data?.reportName
      setNotification(
        displayNotification(
          displayNotificationContent(reportingFlag, t, refId, repName),
          'info',
          setNotification
        )
      )
    },
    (error) => {
      if (!reportingFlag)
        handleErrorNotification(error, t, setNotification, displayApiError)
      onError(error?.response)
    }
  )
}

export const getExportCSVColumns = (t) => {
  const columns = [
    'SUBSCRIPTION_KEY',
    'SUBSCRIPTION_TYPE',
    'SUBSCRIPTION_TIER_DESCRIPTION',
    'AVAILABLE_QUANTITY',
    'QUANTITY',
    'SUBSCRIPTION_START',
    'SUBSCRIPTION_END',
    'CONTRACT'
  ]

  return columns.map((columnValue) => {
    return {
      label: t(`licensing:export.${columnValue.toLowerCase()}`),
      value: columnValue
    }
  })
}
