export const getPasswordLengthError = (value, i18nTranslate) => {
  if (Number.isNaN(value) || value === '')
    return {
      message: i18nTranslate('authn:cop_local_authentication.password_error'),
      status: 'error'
    }
  const limits = Number(value)
  return limits >= 8 && limits <= 72
    ? undefined
    : {
        message: i18nTranslate('authn:cop_local_authentication.password_error'),
        status: 'error'
      }
}

export const getInactiveAccountLockoutError = (value, i18nTranslate) => {
  if (Number.isNaN(value) || value === '')
    return {
      message: i18nTranslate('authn:cop_local_authentication.in_active_error'),
      status: 'error'
    }
  if (value) {
    const duration = Number(value)
    return duration >= 30 && duration <= 365
      ? undefined
      : {
          message: i18nTranslate(
            'authn:cop_local_authentication.in_active_error'
          ),
          status: 'error'
        }
  }
  return undefined
}

export const getMinimumPasswordAgeError = (
  passwordExpirationCheck,
  passwordAge,
  i18nTranslate
) => {
  const minPasswordAge = Number(passwordAge)
  const passwordExpirationDays = Number(passwordExpirationCheck)
  if (minPasswordAge >= 0 && minPasswordAge <= 365) {
    return minPasswordAge > passwordExpirationDays
      ? {
          message: i18nTranslate(
            'authn:cop_local_authentication.minimum_password_age_validation_error'
          ),
          status: 'error'
        }
      : undefined
  }
  return {
    message: i18nTranslate(
      'authn:cop_local_authentication.minimum_password_age_range_error'
    ),
    status: 'error'
  }
}

export const getFailedAttemptsLimitError = (value, i18nTranslate) => {
  if (Number.isNaN(value) || value === '')
    return {
      message: i18nTranslate(
        'authn:cop_local_authentication.failed_attempt_no_value_error'
      ),
      status: 'error'
    }
  if (value) {
    const limits = Number(value)
    return limits >= 1 && limits <= 15
      ? undefined
      : {
          message: i18nTranslate(
            'authn:cop_local_authentication.failed_attempt_limit_error'
          ),
          status: 'error'
        }
  }
  return undefined
}

export const getPasswordExpiryError = (value, i18nTranslate) => {
  if (Number.isNaN(value) || value === '')
    return {
      message: i18nTranslate('authn:cop_local_authentication.in_active_error'),
      status: 'error'
    }
  if (value) {
    const duration = Number(value)
    return duration >= 30 && duration <= 365
      ? undefined
      : {
          message: i18nTranslate(
            'authn:cop_local_authentication.in_active_error'
          ),
          status: 'error'
        }
  }
  return undefined
}

export const getFailedAttemptsTimeError = (value, i18nTranslate) => {
  if (Number.isNaN(value) || value === '')
    return {
      message: i18nTranslate(
        'authn:cop_local_authentication.failed_attempt_time_error'
      ),
      status: 'error'
    }
  if (value) {
    const minutes = Number(value)
    return minutes >= 1 && minutes <= 120
      ? undefined
      : {
          message: i18nTranslate(
            'authn:cop_local_authentication.failed_attempt_time_error'
          ),
          status: 'error'
        }
  }
  return undefined
}
