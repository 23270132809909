/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import { Box, NameValueList, NameValuePair, TextArea, Text } from 'grommet'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import has from 'lodash/has'
import { Copy } from 'grommet-icons'

const DisplayObjectValue = ({ inputVariation, id, inputVariationName }) => {
  const textareaRef = useRef(null)
  const [textareaheight, setTextareaheight] = useState('auto')
  const [notify, setNotify] = useState(false)
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      setTextareaheight(`${textareaRef.current.scrollHeight}px`)
    }
  }, [])

  const copyToClipboard = (variation) => {
    const variation_text = JSON.stringify(variation)
    navigator.clipboard.writeText(variation_text)
    setNotify(true)
    setTimeout(() => {
      setNotify(false)
    }, 2000)
  }
  return (
    <>
      <NameValueList
        data-testid="LD-status-details-variation-name"
        nameProps={{ width: 'medium' }}
        margin={{ top: 'medium' }}
        testId="LD-status-details-variation-name"
      >
        <NameValuePair
          name={inputVariationName}
          key={`variation-pair-name-${id}`}
        >
          <Box
            testId="Copy-Container"
            data-testid="Copy-Container"
            direction="row"
            margin={{ right: 'small' }}
            justify="end"
          >
            {notify && (
              <Text
                weight="normal"
                data-testid="successnotify"
                margin={{ right: 'small' }}
                size="medium"
              >
                Text copied
              </Text>
            )}
            <Box
              data-testid="Copy-Icon-Container"
              testId="Copy-Icon-Container"
              width="xsmall"
              align="center"
              style={{ position: 'relative', display: 'inline-block' }}
            >
              <Copy
                data-testid="Copy-Icon"
                size="medium"
                cursor="pointer"
                onClick={() => copyToClipboard(inputVariation)}
                onMouseOver={() => setToggle(true)}
                onMouseOut={() => setToggle(false)}
              />
              {toggle && (
                <Box
                  width="100%"
                  style={{
                    position: 'absolute',
                    zIndex: 1,
                    bottom: '120%',
                    transition: 'ease-in-out 1.0s'
                  }}
                >
                  <Text
                    size="xsmall"
                    data-testid="tooltip"
                    style={{
                      border: '1px solid #DDDDDD',
                      padding: '3px 15px',
                      borderRadius: '5px',
                      boxShadow:
                        'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                    }}
                  >
                    Copy To Clipboard
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </NameValuePair>
      </NameValueList>
      {typeof inputVariation === 'object' && !isEmpty(inputVariation) ? (
        <TextArea
          style={{ minHeight: textareaheight }}
          key={`LD-status-details-variation-value-${id}`}
          ref={textareaRef}
          plain
          readOnly
          value={JSON.stringify(inputVariation, undefined, 4)}
        />
      ) : (
        <></>
      )}
    </>
  )
}

function FeatureFlagsVariationsDisplay({ variations }) {
  // for Recieving values as string as well as array
  const displayStringValue = (value, id) => {
    if (has(value, 'appInstanceIDs')) {
      return value.appInstanceIDs.map((subvalue) => (
        <Text
          key={`LD-status-details-variation-value-${id}`}
          testId="LD-status-details-variation-subValue"
        >
          {subvalue}
        </Text>
      ))
    }
    return <Text key={`LD-status-details-variation-value-${id}`}>{value}</Text>
  }

  // eslint-disable-next-line consistent-return
  // for Recieving values as boolean
  const displayBooleanValue = (value, id) => {
    return (
      <Text
        key={`LD-status-details-variation-value-${id}`}
        testId="LD-status-details-variation-value"
        data-testId="LD-status-details-variation-value"
      >
        {value.toString()}
      </Text>
    )
  }

  return (
    <Box margin={{ top: 'small' }} overflow={{ vertical: 'auto' }}>
      {variations?.map((variation) => {
        const {
          _id: id,
          name: inputVariationName,
          value: Variationvalue
        } = variation
        return (
          <>
            {typeof Variationvalue === 'boolean' ? ( // checking boolean
              <NameValueList
                data-testId="LD-status-details-variation-id"
                testId="LD-status-details-variation-id"
                nameProps={{ width: 'medium' }}
              >
                <NameValuePair
                  name={id}
                  key={`LD-status-details-variation-${id}`}
                >
                  {displayBooleanValue(Variationvalue, id)}
                </NameValuePair>
              </NameValueList>
            ) : (
              <>
                {(typeof Variationvalue === 'object' && // checking string and object
                  has(Variationvalue, 'appInstanceIDs')) ||
                typeof Variationvalue === 'string' ? (
                  <>
                    <NameValuePair
                      name={id}
                      key={`LD-status-details-variation-${id}`}
                      testId="LD-status-details-variation-id"
                      margin="none"
                    />
                    <NameValueList
                      testId="LD-status-details-variation-name"
                      nameProps={{ width: 'small' }}
                    >
                      <NameValuePair
                        name={inputVariationName}
                        key={`variation-pair-name-${id}`}
                      >
                        {displayStringValue(Variationvalue, id)}
                      </NameValuePair>
                    </NameValueList>
                  </>
                ) : (
                  <>
                    {inputVariationName === undefined && // checking obj with no name key
                    typeof Variationvalue === 'object' ? (
                      <>
                        <NameValuePair
                          name={id}
                          key={`LD-status-details-variation-${id}`}
                          testId="LD-status-details-variation-id"
                        />
                        <NameValueList nameProps={{ width: 'medium' }}>
                          {Object.entries(Variationvalue).map(
                            ([subValueKey, subValue]) => (
                              <NameValuePair
                                name={subValueKey}
                                key={`LD-status-details-variation-key-${id}`}
                              >
                                {subValue.map((subvalue) => {
                                  return (
                                    <Text
                                      key={`LD-status-details-variation-value-${id}`}
                                      testId="LD-status-details-variation-subValue"
                                    >
                                      {subvalue}
                                    </Text>
                                  )
                                })}
                              </NameValuePair>
                            )
                          )}
                        </NameValueList>
                      </>
                    ) : (
                      // for Object with name key
                      <>
                        <NameValuePair
                          name={id}
                          key={`LD-status-details-variation-${id}`}
                        />
                        <DisplayObjectValue
                          inputVariation={Variationvalue}
                          id={id}
                          inputVariationName={inputVariationName}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )
      })}
    </Box>
  )
}

FeatureFlagsVariationsDisplay.propTypes = {
  variations: PropTypes.array.isRequired
}

DisplayObjectValue.prototype = {
  id: PropTypes.string.isRequired,
  inputVariation: PropTypes.object.isRequired,
  inputVariationName: PropTypes.string.isRequired
}

export default FeatureFlagsVariationsDisplay
