import isEmpty from 'lodash/isEmpty'

import { get } from '../../utils/api-utils'
import { getCustomerAccount, isCoP } from '../../utils/feature-flag-utils'

export const doAutoLaunchAppAction = (
  instanceId,
  redirectUrl,
  token,
  customerId,
  accountType,
  setError
) => {
  if (instanceId && token) {
    get(
      `/authn/v1/onboarding/login-url/${instanceId}`,
      redirectUrl && redirectUrl !== '' ? { query_url: redirectUrl } : {},
      token
    ).then(
      (response) => {
        const { login_url: loginURL } = response.data
        if (loginURL) {
          sessionStorage.removeItem('redirect-query')

          if (isCoP()) {
            sessionStorage.setItem('cop-central-url', loginURL)
            sessionStorage.removeItem('from-central')
          }

          if (customerId) {
            const { platform_customer_id: pcid } = getCustomerAccount()
            window.location.replace(
              `${loginURL}?cid=${customerId}&account_type=${accountType}&workspace_id=${pcid}`
            )
          } else {
            // if no cid, append app in url for AT&T use case
            window.location.replace(
              `${loginURL}?account_type=${accountType}&app=${instanceId}`
            )
          }
        }
      },
      (error) => {
        sessionStorage.removeItem('redirect-query')
        setError(error.message)
      }
    )
  }
}

export const autoLaunch = async (
  app,
  redirectUrl,
  token,
  accountType,
  setError,
  t
) => {
  await get(
    `/app-provision/ui/v1/provisions`,
    { provision_status: 'PROVISIONED' },
    token
  ).then(
    async (response) => {
      const { provisions } = await response.data
      if (provisions && !isEmpty(app)) {
        const provision = provisions.filter(
          (item) => item.application_instance_id === app
        )
        // provision item should be 1, application_instance_id is unique
        if (provision.length === 1) {
          const { application_customer_id: cid } = provision[0]
          if (!isEmpty(cid)) {
            doAutoLaunchAppAction(
              app,
              redirectUrl,
              token,
              cid,
              accountType,
              setError
            )
          }
        } else if (provision.length > 1) {
          sessionStorage.removeItem('redirect-query')
          setError(t('apps:redirect_app_error_same_id'))
        } else {
          sessionStorage.removeItem('redirect-query')
          setError(t('apps:redirect_app_error_id_not_found'))
        }
      }
    },
    (error) => {
      setError(error.message)
    }
  )
}
