import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  getMSPCustomerAccount,
  isInventoryOwnedWorkspace
} from '../../utils/feature-flag-utils'
import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { useVisibilityContext } from '../../context/visibility-context'

import AddProductsWizardPhase2 from './magic-link-phase2/AddProductsWizardPhase2'
import { InvalidLink } from './components'
import { AddProductsSuccessTemplate } from './subscriptions/components'
import AddProductsWizard from './subscriptions/AddProductsWizard'

const MagicLinkRouter = () => {
  const swappedMSPAccount = getMSPCustomerAccount()
  const LDFlags = useFlags()
  const { hideWhen } = useVisibilityContext()

  return (
    <Switch>
      <Route exact path="/add-products/invalid-link">
        <InvalidLink />
      </Route>
      <Route exact path="/add-products/success">
        <AddProductsSuccessTemplate />
      </Route>
      <Route exact path="/add-products/:productSquid">
        {!swappedMSPAccount && isInventoryOwnedWorkspace() ? (
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/device-management',
              permission: 'ccs.device-management.edit'
            }}
            hideFor={{ account: 'TAC' }}
            fallbackComponent={
              <Redirect
                to={{
                  pathname:
                    hideWhen?.account === 'TAC' ? '/manage-ccs/home' : '/home',
                  isMagicAccessDenied: true
                }}
              />
            }
          >
            {LDFlags['glcp-magic-link-phase2'] ? (
              <AddProductsWizardPhase2 />
            ) : (
              <AddProductsWizard />
            )}
          </VisibilityWrapper>
        ) : (
          <Redirect
            to={{
              pathname: '/home',
              isMagicAccessDenied: true
            }}
          />
        )}
      </Route>
    </Switch>
  )
}

export default MagicLinkRouter
