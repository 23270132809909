import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { Copy } from 'grommet-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { get } from '../../../utils/api-utils'
import {
  Card,
  Button,
  ModalDialog,
  ModalHeader,
  Typography,
  ModalFooter,
  FormInput,
  Notification
} from '../../../components'

const OVADownloadModal = ({ showDownloadModal, setShowDownloadModal }) => {
  const { t } = useTranslation(['local-gateway'])
  const { oidcUser } = useReactOidc()
  const [checksum, setChecksum] = useState()
  const [textCopied, setTextCopied] = useState(false)
  const [showClose, setShowClose] = useState(false)
  const [globalTradeError, setGlobalTradeError] = useState(false)
  const [downloadStarted, setDownloadStarted] = useState(false)
  const [showDownloadURLError, setShowDownloadURLError] = useState(false)

  const close = () => {
    setShowClose(false)
    setChecksum(undefined)
    setDownloadStarted(false)
    setShowDownloadModal(false)
  }

  const downloadFile = () => {
    setShowDownloadURLError(false)
    setDownloadStarted(true)
    setGlobalTradeError(false)
    get(`/opg-download/ui/v1/ova/url/small`, {}, oidcUser.access_token).then(
      (response) => {
        if (response.data) {
          const { url, checksum: resCheckSum } = response.data
          if (url) {
            window.location.href = url
          }
          if (resCheckSum) {
            setChecksum(resCheckSum)
          }
          setShowClose(true)
          setDownloadStarted(false)
        } else {
          setShowDownloadURLError(true)
          close()
        }
      },
      (error) => {
        const {
          status,
          data: { message }
        } = error.response
        if (status === 403) {
          setGlobalTradeError(message)
          setShowClose(true)
        } else {
          setShowDownloadURLError(true)
          close()
        }
        setDownloadStarted(false)
      }
    )
  }

  const copyText = () => {
    navigator.clipboard.writeText(checksum)
    setTextCopied(true)
  }

  return (
    <>
      {showDownloadModal && (
        <ModalDialog
          header={
            <ModalHeader
              title={
                <Box pad="small" margin={{ bottom: 'small' }}>
                  <Typography
                    type="heading"
                    level="1"
                    testId="ova-modal-header-title"
                  >
                    {t('ova_modal_title')}
                  </Typography>
                </Box>
              }
              onClose={close}
            />
          }
          content={
            <Box>
              <Box pad={{ left: 'small', bottom: 'medium' }}>
                <Typography
                  type="text"
                  size="medium"
                  testId="ova-download-description"
                >
                  {t('ova_download_description')}
                </Typography>
              </Box>
              {checksum && (
                <Card
                  background="background-contrast"
                  testId="check-sum"
                  width="large"
                  elevation="none"
                  CustomContent={
                    <>
                      <Box pad={{ bottom: 'xsmall' }}>
                        <Typography
                          type="text"
                          size="medium"
                          testId="checksum-description"
                        >
                          {t('checksum_description')}
                        </Typography>
                      </Box>
                      <Typography
                        type="text"
                        size="xsmall"
                        testId="checksum-label"
                      >
                        {t('checksum_label')}
                      </Typography>
                      <Box width="100%">
                        <FormInput
                          id="copy-checksum"
                          name="copy-checksum"
                          testId="copy-checksum"
                          disabled
                          value={checksum}
                          action={
                            <Button
                              default
                              hoverIndicator="none"
                              onClick={copyText}
                              icon={<Copy size="medium" />}
                              testId="copy-btn"
                            />
                          }
                          inputType="text"
                        />
                      </Box>
                    </>
                  }
                />
              )}
              {globalTradeError && (
                <Notification
                  backgroundColor="status-warning"
                  text={
                    <span data-testid="global-trade-notification">
                      {`${t(`global_trade_notification`)} ${globalTradeError}`}
                    </span>
                  }
                  type="inline"
                />
              )}
            </Box>
          }
          footer={
            <ModalFooter
              right={
                <Box direction="row" gap="medium">
                  {!downloadStarted && (
                    <Button
                      label={showClose ? t('common:close') : t('common:cancel')}
                      testId="cancel-btn"
                      onClick={close}
                    />
                  )}
                  {!showClose && (
                    <Button
                      label={
                        downloadStarted
                          ? t('downloading')
                          : t('download_ova_button')
                      }
                      primary={!downloadStarted}
                      testId="download-btn"
                      onClick={downloadFile}
                    />
                  )}
                </Box>
              }
            />
          }
          onClose={close}
          testId="app-instance-details-modal-dialog"
          width="large"
        />
      )}
      {textCopied && (
        <Notification
          backgroundColor="status-unknown"
          text={
            <span data-testid="copied-notification">{t(`text_copied`)}</span>
          }
          onClose={() => {
            setTextCopied(false)
          }}
          autoClose
        />
      )}
      {showDownloadURLError && (
        <Notification
          backgroundColor="status-warning"
          testId="gateway-download-error-notification"
          text={t('api_messages.ova_download_error')}
          onClose={close}
        />
      )}
    </>
  )
}

OVADownloadModal.propTypes = {
  setShowDownloadModal: PropTypes.func.isRequired,
  showDownloadModal: PropTypes.bool.isRequired
}

export default OVADownloadModal
