import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { Box, Button, Select, SelectMultiple, Text, Tip } from 'grommet'
import {
  AddCircle,
  BarChart,
  CircleInformation,
  LineChart,
  StatusCriticalSmall,
  StatusInfoSmall,
  StatusWarningSmall,
  SubtractCircle
} from 'grommet-icons'
import { colors } from 'grommet-theme-hpe'
import { range } from 'lodash-es'
import useSWR from 'swr'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  dateRanges,
  serviceValues as defaultServiceValues,
  roundTime
} from '../../utils/data'
import Chart from '../chart/Chart'
import DataError from '../errorPages/DataError'
import { get } from '../../../../utils/api-utils'

import CustomDateSelection from './CustomDateSelection'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
const shadowAndCorners = {
  boxShadow: 'rgb(208 208 208 / 66%) -3px 5px 14px -2px',
  borderRadius: '13px',
  minWidth: '1400px'
}
const calcNumBuckets = (dateRange) => {
  // we need to divide the time ranges evenly and send the number of buckets to the api
  // for the defaults we have set buckets from ux
  // for custom anything under a week can be days
  // anything over a week we make our best guess and hope its not prime and too large
  const diff = dateRange[1].diff(dateRange[0], 'day') + 1
  let numBuckets
  let chartTimeScale = 'day'
  if (diff === 1) {
    numBuckets = 24
    chartTimeScale = 'hour'
  } else if (diff <= 7) {
    numBuckets = diff
  } else if (diff === 30) {
    numBuckets = 6
  } else if (diff === 90) {
    numBuckets = 3
  } else {
    numBuckets = range(10, 2).filter((x) => diff % x === 0)[0] || diff
    if (diff > 365) {
      chartTimeScale = 'year'
    }
  }
  return { numBuckets, chartTimeScale }
}

const WellnessSummary = () => {
  const [isExpanded, setIsExpanded] = useState(true)
  const { t } = useTranslation('unified_wellness')
  const [dateSelection, setDateSelection] = useState('today')
  const [showCalendar, setShowCalendar] = useState(false)
  const [serviceValues, setServiceValues] = useState(defaultServiceValues)
  const [dateRange, setDateRange] = useState(dateRanges.today)
  const [chartType, setChartType] = useState('bar')
  // This can cause client / server rendering mismatches so it needs to be rendered as a second pass
  const [lastUpdated, setLastUpdated] = useState(undefined)
  useEffect(() => {
    setLastUpdated(dayjs().format('MMMM D, YYYY hh:mm A'))
  }, [setLastUpdated])

  const { numBuckets, chartTimeScale } = calcNumBuckets(dateRange)

  const serviceFilters = `service in (${serviceValues
    .map((e) => `'${e}'`)
    .join(', ')})`
  const serviceFiltersUrlParam =
    serviceValues.length < defaultServiceValues.length &&
    serviceValues.length > 0
      ? `&filter=${serviceFilters}`
      : ''

  const listUrl = `/wellness/v2beta1/event-count?start-time=${dateRange[0].toISOString()}&end-time=${dateRange[1].toISOString()}&num-buckets=${numBuckets}${serviceFiltersUrlParam}`
  const summaryUrl = `/wellness/v2beta1/summary?start-time=${dateRange[0].toISOString()}&end-time=${dateRange[1].toISOString()}${serviceFiltersUrlParam}`
  const { oidcUser } = useReactOidc()
  const fetcher = (url) =>
    get(url, {}, oidcUser.access_token).then(({ data }) => data)
  const { data, error } = useSWR(listUrl, fetcher)

  const { data: summaryData, error: summaryError } = useSWR(summaryUrl, fetcher)
  const totals = data?.items?.reduce(
    (accumulator, currentValue) => ({
      notice: accumulator.notice + currentValue.notice,
      critical: accumulator.critical + currentValue.critical,
      warning: accumulator.warning + currentValue.warning
    }),
    { notice: 0, critical: 0, warning: 0 }
  )

  const selectDate = ({ value }) => {
    if (value === 'custom') {
      setShowCalendar(true)
      return
    }

    setDateSelection(value)
    setDateRange(dateRanges[value])
  }
  const setCustomDate = (customDateRange) => {
    setDateSelection('custom')
    setDateRange(customDateRange)
  }
  const formatChartData = (buckets) => {
    const startTime = dateRange[0].startOf('day')
    const endTime = dateRange[1].endOf('day')
    const hourOrDay = endTime.diff(startTime, 'day') === 0 ? 'hour' : 'day'
    const daysPerGroup = (endTime.diff(startTime, hourOrDay) + 1) / numBuckets
    let bucketStart = startTime
    let bucketEnd = startTime.add(daysPerGroup - 1, hourOrDay).endOf(hourOrDay)
    const nullsAdded = []
    const bucketsHash = {}
    buckets?.forEach((bucket) => {
      // we round to avoid issues with daylight savings time.
      // ideally we should not round since we dont know that the direction we round is always correct given an incorrect backend response
      bucketsHash[roundTime(dayjs(bucket.time), hourOrDay).toISOString()] =
        bucket
    })
    while (endTime.diff(bucketEnd, hourOrDay) >= 0) {
      if (bucketsHash[bucketStart.toISOString()]) {
        nullsAdded.push({
          ...bucketsHash[bucketStart.toISOString()],
          time: bucketStart.toISOString()
        })
      } else {
        nullsAdded.push({
          time: bucketStart.toISOString(),
          critical: 0,
          warning: 0,
          notice: 0
        })
      }
      bucketStart = bucketEnd.add(1, hourOrDay).startOf(hourOrDay)
      bucketEnd = bucketEnd.add(daysPerGroup, hourOrDay).endOf(hourOrDay)
    }
    return nullsAdded
  }
  const defaultDates = [
    { label: t('summary.default_dates.today'), value: 'today' },
    { label: t('summary.default_dates.last_7'), value: 'last_7' },
    { label: t('summary.default_dates.last_30'), value: 'last_30' },
    { label: t('summary.default_dates.last_90'), value: 'last_90' }
  ]
  // the legend is referenced multiple places in the final summary JSX
  // so we define it here instead of inline to reduce duplication
  const legend = (
    <Box direction="row">
      <Box direction="row" align="center" pad={{ horizontal: 'small' }}>
        <StatusCriticalSmall
          size="small"
          color={colors['status-critical'].light}
        />
        <Text
          size="xsmall"
          margin={{ left: 'xxsmall', right: 'small' }}
          data-testid="critical-label"
        >
          {t('status.critical')}
        </Text>
        <Text size="xsmall" weight="bold" data-testid="critical-count">
          {totals && totals.critical}
        </Text>
      </Box>
      <Box direction="row" align="center" pad={{ horizontal: 'small' }}>
        <StatusWarningSmall
          size="small"
          color={colors['status-warning'].light}
        />
        <Text
          size="xsmall"
          margin={{ left: 'xxsmall', right: 'small' }}
          data-testid="warning-label"
        >
          {t('status.warning')}
        </Text>
        <Text size="xsmall" weight="bold" data-testid="warning-count">
          {totals && totals.warning}
        </Text>
      </Box>
      <Box direction="row" align="center" pad={{ horizontal: 'small' }}>
        <StatusInfoSmall size="small" color={colors.blue.light} />
        <Text
          size="xsmall"
          margin={{ left: 'xxsmall', right: 'small' }}
          data-testid="info-label"
        >
          {t('status.notice')}
        </Text>
        <Text size="xsmall" weight="bold" data-testid="info-count">
          {totals && totals.notice}
        </Text>
      </Box>
    </Box>
  )
  return (
    <Box style={shadowAndCorners} pad="medium" margin={{ bottom: 'large' }}>
      {/* top row with title, subtitle, expand button and drop downs */}
      <Box>
        {/* title, subtitle */}
        <Box direction="row" justify="between">
          <Box>
            <Text size="xlarge" weight="bold" data-testid="summary-title">
              {t('summary.title')}
            </Text>
            <Text data-testid="summary-last-updated">
              {t('summary.last_updated')} {lastUpdated}
            </Text>
          </Box>
          {/* expand button */}
          <Box>
            <Button
              onClick={() => setIsExpanded(!isExpanded)}
              data-testid="expand-button"
            >
              {isExpanded ? <SubtractCircle /> : <AddCircle />}
            </Button>
          </Box>
        </Box>
      </Box>
      {!isExpanded && (
        <Box direction="row" pad={{ top: 'small' }}>
          <Box
            direction="row"
            border={{ side: 'right', color: colors.border.light, size: '1px' }}
            gap="small"
          >
            {/* Events */}
            <Box direction="row" gap="small">
              <Text data-testid="wellness-events-count-minimized">
                {summaryData?.events}
              </Text>
              <Text
                margin={{ right: 'small' }}
                data-testid="wellness-events-label-minimized"
              >
                {t('summary.wellness_events')}
              </Text>
            </Box>
            {/* Cases */}
            <Box direction="row" gap="small">
              <Text data-testid="automatic-cases-count-minimized">
                {summaryData?.cases}
              </Text>
              <Text
                margin={{ right: 'small' }}
                data-testid="automatic-cases-label-minimized"
              >
                {t('summary.automatic_cases')}
              </Text>
            </Box>
          </Box>

          {legend}
        </Box>
      )}
      <Box
        height={isExpanded ? undefined : '0px'}
        style={{ visibility: isExpanded ? 'visible' : 'hidden' }}
      >
        <Box>
          {/* dropdowns */}
          <Box direction="row" justify="end">
            <Box width="medium" margin={{ right: 'small' }}>
              <Text size="xsmall">{t('summary.date_label')}</Text>
              <Select
                options={[
                  ...defaultDates,
                  {
                    label:
                      dateSelection === 'custom' && dateRange.length
                        ? `${dateRange[0].format(
                            'M/D/YY'
                          )} - ${dateRange[1].format('M/D/YY')}`
                        : t('summary.default_dates.custom'),
                    value: 'custom'
                  }
                ]}
                labelKey="label"
                valueKey={{ key: 'value', reduce: true }}
                value={dateSelection}
                onChange={(option) => selectDate(option)}
                data-testid="date-select"
                id="date-select-button"
              />

              <CustomDateSelection
                show={showCalendar}
                setDateRange={setCustomDate}
                setShowCalendar={setShowCalendar}
                cancelCustomDate={() => setShowCalendar(false)}
              />
            </Box>
            <Box>
              <Text size="xsmall">{t('summary.services_label')}</Text>
              <SelectMultiple
                placeholder={t('summary.services_label')}
                options={defaultServiceValues.map((serviceValue) => ({
                  label: t(`services.${serviceValue}`),
                  value: serviceValue
                }))}
                labelKey="label"
                valueKey={{
                  key: 'value',
                  reduce: true
                }}
                value={serviceValues}
                onChange={({ value }) => setServiceValues(value)}
                data-testid="services-select"
                id="service-select-button"
              />
            </Box>
            <Box
              direction="row"
              align="end"
              pad={{ left: 'small' }}
              data-testid="chart-type-select-buttons"
            >
              <Box
                border="all"
                round={{ size: 'xsmall', corner: 'left' }}
                style={{
                  backgroundColor:
                    chartType === 'bar' ? colors.text.light : undefined
                }}
                data-testid="button-bar-wrapper"
              >
                <Button
                  plain
                  onClick={() => setChartType('bar')}
                  data-testid="bar-chart-icon"
                  label={
                    <Box
                      align="center"
                      pad={{ vertical: 'xsmall', horizontal: 'small' }}
                    >
                      <BarChart
                        color={chartType === 'bar' ? 'white' : undefined}
                      />
                    </Box>
                  }
                />
              </Box>
              <Box
                border="all"
                round={{ size: 'xsmall', corner: 'right' }}
                style={{
                  backgroundColor:
                    chartType === 'line' ? colors.text.light : undefined
                }}
                data-testid="button-line-wrapper"
              >
                <Button
                  plain
                  onClick={() => setChartType('line')}
                  data-testid="line-chart-icon"
                  label={
                    <Box
                      align="center"
                      pad={{ vertical: 'xsmall', horizontal: 'small' }}
                    >
                      <LineChart
                        color={chartType === 'line' ? 'white' : undefined}
                      />
                    </Box>
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box direction="row">
          <Box width="small">
            {/* Events */}
            <Box margin={{ bottom: 'small' }}>
              <Box direction="row">
                <Text
                  size="medium"
                  margin={{ right: 'small' }}
                  data-testid="wellness-events-label"
                >
                  {t('summary.wellness_events')}
                </Text>
                <Tip
                  content={t('summary.tooltip_events')}
                  data-testid="events-tooltip"
                >
                  <CircleInformation
                    data-testid="wellness-events-info"
                    size="medium"
                  />
                </Tip>
              </Box>
              <Box height="xxsmall">
                {!summaryError ? (
                  <Text size="xxlarge" data-testid="wellness-events-count">
                    {summaryData?.events}
                  </Text>
                ) : (
                  <Text size="medium" data-testid="wellness-events-count">
                    {t('no_summary.unable_to_load')}
                  </Text>
                )}
              </Box>
            </Box>
            {/* Cases */}
            <Box>
              <Box direction="row">
                <Text
                  size="medium"
                  margin={{ right: 'small' }}
                  data-testid="automatic-cases-label"
                >
                  {t('summary.automatic_cases')}
                </Text>
                <Tip
                  content={t('summary.tooltip_cases')}
                  data-testid="automatic-cases-tooltip"
                >
                  <CircleInformation
                    size="medium"
                    data-testid="automatic-cases-info"
                  />
                </Tip>
              </Box>
              <Box height="xxsmall">
                {!summaryError ? (
                  <Text size="xxlarge" data-testid="automatic-cases-count">
                    {summaryData?.cases}
                  </Text>
                ) : (
                  <Text size="medium" data-testid="automatic-cases-count">
                    {t('no_summary.unable_to_load')}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
          {/* chart */}
          {!error && (
            <Box width="100%">
              <Chart
                data={formatChartData(data?.items)}
                timeScale={chartTimeScale}
                type={chartType}
              />
              <Box pad={{ left: 'large' }}>{legend}</Box>
            </Box>
          )}
        </Box>
        {error && (
          // we want to center this in relation to the larger box but still fit around the counts
          <Box
            style={{
              position: 'relative',
              left: '50%',
              top: '-84px',
              transform: 'translateX(-50%)',
              height: '72px'
            }}
          >
            <DataError />
          </Box>
        )}
      </Box>
    </Box>
  )
}
export default WellnessSummary
