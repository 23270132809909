import { React, Suspense, lazy } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

const AllAccountPage = lazy(() => import('./AllAccount'))

const AccountDetailsRouter = () => {
  const { path } = useRouteMatch()
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route path={path}>
          <AllAccountPage />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default AccountDetailsRouter
