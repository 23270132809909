import { Redirect, useRouteMatch } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { AppCatalogContextProvider } from '../context/app-catalog-context'
import NewMyServices from '../pages/services/my-services/NewMyServices'
import NewServiceCatalog from '../pages/services/service-catalog/NewServiceCatalog'
import NewServiceDetail from '../pages/services/service-catalog/service-detail/NewServiceDetail'
import AddServiceSubscriptionWizard from '../pages/manage-account/subscriptions/pages/service-subscriptions/components/AddServiceSubscriptionWizard'
import ServiceSubscriptionDetailsPage from '../pages/manage-account/subscriptions/pages/service-subscriptions/components/ServiceSubscriptionDetails'

import { Route, Switch } from './shims/imports'
import ServiceCatalog from './pages/services/service-catalog'
import ServiceDetail from './pages/services/service-catalog/[service]'
import MyServices from './pages/services/my-services'
import ServiceSubscriptions from './pages/services/service-subscriptions'
import PrivateCloud from './pages/services/service-catalog/private-cloud'

const ServiceCatalogRouterContent = () => {
  const { path } = useRouteMatch()
  const serviceRegistryFlag = useFlags()['glcp-service-registry']
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to="/services/my-services" />
      </Route>
      <Route exact path="/applications/my-apps">
        <Redirect to="/services/my-services" />
      </Route>
      <Route exact path="/applications/available-apps">
        <Redirect to="/services/service-catalog" />
      </Route>
      <Route exact path="/services/my-services">
        {serviceRegistryFlag ? <NewMyServices /> : <MyServices />}
      </Route>
      <Route exact path="/services/service-catalog">
        {serviceRegistryFlag ? <NewServiceCatalog /> : <ServiceCatalog />}
      </Route>
      <Route exact path="/services/service-catalog/private-cloud">
        <PrivateCloud />
      </Route>
      <Route exact path="/applications/installed-apps/:id">
        <Redirect to="/services/my-services" />
      </Route>
      <Route exact path="/applications/app-details/:id">
        <Redirect to="/services/service-catalog" />
      </Route>
      <Route
        exact
        path={`/services/service-catalog/:${
          serviceRegistryFlag ? 'id' : 'service'
        }`}
      >
        {serviceRegistryFlag ? <NewServiceDetail /> : <ServiceDetail />}
      </Route>
      <Route
        exact
        path={`/services/service-catalog/:${
          serviceRegistryFlag ? 'id' : 'service'
        }/:fromServiceSubs`}
      >
        {serviceRegistryFlag ? <NewServiceDetail /> : <ServiceDetail />}
      </Route>
      <Route exact path="/services/service-subscriptions/:subscriptionKey">
        <ServiceSubscriptionDetailsPage />
      </Route>
      <Route exact path="/services/service-subscriptions">
        <ServiceSubscriptions />
      </Route>
      <Route exact path="/services/add-service-subscriptions">
        <AddServiceSubscriptionWizard />
      </Route>
      {/* Redirect to not-found is added because, if any users tries to enter an invalid URL for services, like /services/my-services/123 it would show not found page instead of blank page. */}
      <Redirect to="/not-found" />
    </Switch>
  )
}

const ServiceCatalogRouter = () => {
  return (
    <AppCatalogContextProvider>
      <ServiceCatalogRouterContent />
    </AppCatalogContextProvider>
  )
}

export { ServiceCatalogRouter }
