export const passwordComplexityOptions = [
  'lowercase',
  'uppercase',
  'numerals',
  'symbols'
]
export const INACTIVITY_TIMEOUT_DEFAULT_VAL = 30
export const WEB_INACTIVITY_TIMEOUT_RANGE = { start: 5, end: 1440 }
export const CLI_INACTIVITY_TIMEOUT_RANGE = { start: 1, end: 1440 }
export const TOTAL_CONCURRENT_SESSIONS_RANGE = { start: 1, end: 100 }
export const LOGIN_BANNER_MAX_LENGTH = 2500
