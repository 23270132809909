import { FormField, TextInput, Box } from 'grommet'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ButtonGroup,
  CCSForm,
  Dropdown,
  ModalDialog,
  Typography
} from '../../../../../components'
import { getErrorMessage, post } from '../../../../../utils/api-utils'

const AddAliasModal = ({
  setAliasModal,
  customerId,
  refreshDataTable,
  setAddAliasNotification
}) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage'])
  const [errorMessage, setErrorMessage] = useState('')
  const [aliasName, setAliasName] = useState('')
  const [aliasType, setAliasType] = useState('CUSTOMER_NAME')

  const handleSubmit = () => {
    const requestBody = {
      alias: aliasName,
      type: aliasType,
      platform_customer_id: customerId
    }
    const url = '/support-assistant/v1alpha1/customer-alias'
    post(url, requestBody, oidcUser.access_token).then(
      () => {
        refreshDataTable()
        setAliasModal(false)
        setErrorMessage('')
        setAddAliasNotification(true)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }
  const handleAddAnotherAction = () => {
    if (aliasName === '') {
      setErrorMessage('Invalid Input')
    } else {
      const requestBody = {
        alias: aliasName,
        type: aliasType,
        platform_customer_id: customerId
      }

      const url = '/support-assistant/v1alpha1/customer-alias'
      post(url, requestBody, oidcUser.access_token).then(
        () => {
          setAliasName('')
          refreshDataTable()
          setErrorMessage('')
          setAddAliasNotification(true)
        },
        (error) => {
          const backendErrorMessage = getErrorMessage(error, t)
          setErrorMessage(backendErrorMessage)
        }
      )
    }
  }
  const handleClose = () => {
    setAliasModal(false)
  }
  return (
    <ModalDialog
      width="medium"
      testId="add-alias-modal"
      content={
        <Box gap="small">
          <Typography type="heading" testId="customer-signup-title">
            {t('aliases.modal_title')}
          </Typography>
          <CCSForm
            errorMessage={errorMessage}
            validate="blur"
            onSubmit={handleSubmit}
            testId="add-alias-form"
          >
            <>
              <FormField
                required
                label={t('aliases.alias_name')}
                name="alias"
                data-testid="alias-field"
              >
                <TextInput
                  name="alias"
                  data-testid="alias-input"
                  placeholder={t('aliases.alias_placeholder')}
                  value={aliasName}
                  onChange={(e) => {
                    setAliasName(e.target.value)
                  }}
                />
              </FormField>
              <FormField
                data-testid="subscription-tier-form-field"
                label={t('aliases.type')}
                name="alias_type"
                required
              >
                <Dropdown
                  noBorder
                  testId="alias-type-input"
                  name="alias_type"
                  options={[
                    {
                      label: t('aliases.customer_name'),
                      value: 'CUSTOMER_NAME'
                    },
                    {
                      label: t('aliases.party_site_id'),
                      value: 'PARTY_SITE_ID'
                    }
                  ]}
                  value={aliasType}
                  onChangeDropdown={(value) => setAliasType(value)}
                />
              </FormField>
              <Box margin={{ vertical: 'small' }}>
                <ButtonGroup
                  buttonList={[
                    {
                      id: 3,
                      label: t('aliases.cancel'),
                      default: true,
                      testId: 'cancel-btn',
                      onClick: handleClose
                    },
                    {
                      id: 2,
                      label: t('aliases.add_another'),
                      secondary: true,
                      testId: 'add-another-btn',
                      onClick: handleAddAnotherAction
                    },
                    {
                      id: 1,
                      label: t('aliases.add'),
                      primary: true,
                      testId: 'add-btn',
                      type: 'submit'
                    }
                  ]}
                  testId="two-buttons"
                />
              </Box>
            </>
          </CCSForm>
        </Box>
      }
      onClose={handleClose}
    />
  )
}
AddAliasModal.propTypes = {
  setAliasModal: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  refreshDataTable: PropTypes.func.isRequired,
  setAddAliasNotification: PropTypes.func.isRequired
}
export { AddAliasModal }
