import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'

/* eslint-enable */
import { post, getErrorMessage } from '../../../../utils/api-utils'
import {
  ButtonGroup,
  CCSForm,
  ModalDialog,
  Typography,
  ModalHeader
} from '../../../../components'

const UnclaimDeviceModal = ({
  onSetOpen,
  count,
  openSuccessModal,
  customerId,
  selectedDevices
}) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['device', 'common'])
  const [errorMessage, setErrorMessage] = useState('')
  const confirmAction = () => {
    const requestBody = {
      platform_customer_id: customerId,
      devices: selectedDevices.map((device) => ({
        serial_number: device?.serial_number,
        part_number: device?.part_number,
        device_type: device?.device_type
      }))
    }
    const url = '/support-assistant/v1alpha1/device-unclaim'
    post(url, requestBody, oidcUser.access_token).then(
      () => {
        onSetOpen(false)
        setErrorMessage('')
        openSuccessModal()
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }
  return (
    <ModalDialog
      header={
        <ModalHeader
          subtitle={t('unclaim_devices_subtitle')}
          title={
            <Typography
              level="1"
              type="heading"
              testId="heading-unclaim-devices-title"
            >
              <Trans i18nKey="unclaim_devices_title" t={t} count={count}>
                <strong>{{ count }}</strong>
              </Trans>
            </Typography>
          }
        />
      }
      content={
        <Box>
          <CCSForm
            onSubmit={() => confirmAction()}
            onReset={() => onSetOpen(false)}
            errorMessage={errorMessage}
            testId="unclaim-devices-title"
            validate="blur"
          >
            <Box
              margin={{ top: 'medium', bottom: 'xxsmall' }}
              direction="column"
              justify="between"
              gap="small"
            >
              <ButtonGroup
                buttonList={[
                  {
                    id: 1,
                    label: t('submit'),
                    primary: true,
                    type: 'submit',
                    testId: 'unclaim-devices-action-btn'
                  },
                  {
                    id: 2,
                    label: t('cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    type: 'reset'
                  }
                ]}
                testId="two-buttons"
              />
            </Box>
          </CCSForm>
        </Box>
      }
      testId="unclaim-devices-modal-dialog"
      onClose={() => onSetOpen(false)}
      width="medium"
    />
  )
}

UnclaimDeviceModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  openSuccessModal: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  selectedDevices: PropTypes.array.isRequired
}

export { UnclaimDeviceModal }
