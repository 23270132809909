import { startCase } from 'lodash'

import { getBaseUrl } from '../../../utils/api-utils'

export const ldFlagsStatusPaginate = (array, pageSize, pageNumber) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}

export const ldFlagsStatusSearch = (targetStr, data) => {
  if (targetStr?.length > 0) {
    return data.filter((item) => {
      return item?.key?.includes(targetStr)
    })
  }
  return data
}

export const getEnvFilteredData = (inputData, headerFilter) => {
  const envKey = Object.keys(headerFilter)[0]
  if (envKey && headerFilter[envKey] !== 0) {
    const inputStatus =
      headerFilter[envKey] === 1 || headerFilter[envKey] === 3 ? 'ON' : 'OFF'
    const dataFilteredByStatus = inputData.filter((el) => {
      return el[envKey]?.status === inputStatus
    })
    if (headerFilter[envKey] === 3 || headerFilter[envKey] === 4) {
      return dataFilteredByStatus.filter((el) => {
        return el[envKey]?.hasRules
      })
    }
    return dataFilteredByStatus
  }
  return inputData
}

export const ldFlagsStatusLang = {
  title: 'Feature Flags',
  key: 'Feature Flag Key',
  table_summary: 'Feature Flag Keys',
  search_placeholder: 'Search Feature Flag Key',
  ON: 'ON',
  OFF: 'OFF',
  ALL: 'All',
  on_rules: 'ON(Rules)',
  off_rules: 'OFF(Rules)',
  details_last_modified: 'Last Modified:',
  details_variations: 'Variations:',
  targetOn: 'Target On:',
  targetOff: 'Target Off:',
  rules: '(Rules)'
}

export const ldFlagsEnvs = {
  'glcp-production': 'env_prod',
  'glcp-stage': 'env_pavo',
  'glcp-qa': 'env_mira',
  'glcp-dev-integration': 'env_triton',
  'glcp-dev': 'env_dev',
  'glcp-hotfix': 'env_hotfix'
}

export const variationTypes = ['targetOn', 'targetOff']
const isCypressTesting = !!window.Cypress

const ldAPIKey = 'api-c622a394-2f1a-4034-9894-5d5a0e505fec'
const ldFlagsStatusAPI =
  'https://app.launchdarkly.com/api/v2/flags/greenlake-platform'
const testOnlyMainAPI = '/test-only/flags/greenlake-platform/main'
const testOnlyRowAPI = '/test-only/flags/greenlake-platform/row'

export const getAllLDEnvData = () => {
  return fetch(
    isCypressTesting
      ? getBaseUrl(testOnlyMainAPI) + testOnlyMainAPI
      : ldFlagsStatusAPI,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ldAPIKey
      }
    }
  )
}

export const getLDDetailsPerFlag = (flag) => {
  const perFlagAPI = isCypressTesting
    ? `${getBaseUrl(testOnlyRowAPI) + testOnlyRowAPI}?flag=${flag}`
    : `${ldFlagsStatusAPI}/${flag}`
  return fetch(perFlagAPI, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: ldAPIKey
    }
  })
}

export const envDisplayNameConversion = (inputString) => {
  if (inputString?.length > 0) {
    const inputStringArr = inputString.trim().split(' ')
    let capitalizedString = ''

    inputStringArr.forEach((word) => {
      if (!word.startsWith('GLCP') && !word.startsWith('(')) {
        capitalizedString = `${capitalizedString} ${word}`
      }
    })

    return startCase(capitalizedString.trim())
  }

  return 'Sample Text'
}
