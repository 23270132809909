export const updateLocationTags = (initialTags, updatedTags) => {
  let assigned = []
  let unassigned = []

  if (updatedTags?.length > 0) {
    assigned = updatedTags.filter(
      (item) =>
        // if initialTags array doesn't contain the item then it's newly assigned
        // selectedTags array contains all assigned tags
        !initialTags.find(
          (tag) => item.name === tag.name && item.value === tag.value
        )
    )
  }

  if (initialTags?.length > 0) {
    unassigned = initialTags.filter(
      (item) =>
        // if selectedTags array doesn't contain the item from initialTags array
        // then it was unassigned; selectedTags array contains all assigned tags
        !updatedTags.find(
          (tag) => item.name === tag.name && item.value === tag.value
        )
    )
  }

  return { assigned, unassigned }
}
