import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, FormField, TextInput } from 'grommet'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ModalDialog,
  Typography,
  ModalFooter,
  CCSForm,
  ButtonGroup
} from '../../../../components'
import { getErrorMessage, put } from '../../../../utils/api-utils'

const TransferSubscriptionModal = ({
  currentCustomerId,
  subscriptionKey,
  setShowTransferModal,
  onSuccess
}) => {
  const { t } = useTranslation(['manage'])
  const { oidcUser } = useReactOidc()
  const [customerID, setCustomerID] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const handleTransfer = () => {
    const url = `/support-assistant/v1alpha1/subscription-transfer`
    put(
      url,
      {
        subscription_key: subscriptionKey,
        platform_customer_id: currentCustomerId,
        new_customer_id: customerID
      },
      oidcUser.access_token
    ).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          setErrorMessage('')
          setCustomerID('')
          setShowTransferModal(false)
          onSuccess()
        }
      },
      (error) => {
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
      }
    )
  }
  return (
    <ModalDialog
      width="medium"
      testId="transfer-modal-dialog"
      content={
        <Box gap="small">
          <Box>
            <Typography type="heading" testId="transfer-subscription-title">
              {t('transfer')}
            </Typography>
          </Box>
          <Box>
            <Typography type="text" size="medium" testId="subscription-key">
              {t('subscription_key')}
            </Typography>
            <Typography
              type="text"
              size="medium"
              weight="bold"
              testId="subscription-key-value"
            >
              {subscriptionKey}
            </Typography>
          </Box>
          <Box>
            <Typography
              type="text"
              size="medium"
              testId="customer-id"
              margin={{ top: 'small' }}
            >
              {t('customer_id')}
            </Typography>
            <Typography
              type="text"
              size="medium"
              weight="bold"
              testId="customer-id-value"
            >
              {currentCustomerId}
            </Typography>
          </Box>
          <Box>
            <CCSForm errorMessage={errorMessage} testId="new-customer-form">
              <FormField
                data-testid="new-customer-field"
                label={t('new_customer_id')}
                width="medium"
              >
                <TextInput
                  data-testid="serial-number-input"
                  value={customerID}
                  onChange={(event) => setCustomerID(event.target.value)}
                />
              </FormField>
            </CCSForm>
          </Box>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  secondary: true,
                  testId: 'cancel-btn',
                  onClick: () => setShowTransferModal(false)
                },
                {
                  id: 1,
                  label: t('transfer'),
                  primary: true,
                  testId: 'transfer-btn',
                  onClick: handleTransfer,
                  disabled: customerID.length === 0
                }
              ]}
              testId="transfer-subscription-buttons"
            />
          }
        />
      }
      onClose={() => setShowTransferModal(false)}
    />
  )
}

TransferSubscriptionModal.propTypes = {
  currentCustomerId: PropTypes.string.isRequired,
  subscriptionKey: PropTypes.string.isRequired,
  setShowTransferModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
}

TransferSubscriptionModal.defaultProps = {
  onSuccess: () => {}
}

export { TransferSubscriptionModal }
