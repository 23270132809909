import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import SupportModal from './common/SupportModal'

const ViewCasesModal = ({
  onDialogChangeState,
  onChangeDropdown,
  onCancel,
  canContinue,
  currentLink,
  viewCasesList
}) => {
  const { t } = useTranslation(['support_hub'])

  return (
    <SupportModal
      title={t('modal_view_cases.title')}
      subtitle={t('modal_view_cases.subtitle')}
      testId="view-cases-modal"
      testIdDropDown="view-cases-dropdown"
      options={viewCasesList}
      onDialogChangeState={onDialogChangeState}
      onChangeDropdown={onChangeDropdown}
      onCancel={onCancel}
      canContinue={canContinue}
      href={currentLink}
    />
  )
}

ViewCasesModal.propTypes = {
  onDialogChangeState: PropTypes.func.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  canContinue: PropTypes.bool.isRequired,
  currentLink: PropTypes.string,
  viewCasesList: PropTypes.array.isRequired
}

ViewCasesModal.defaultProps = {
  onCancel: () => {},
  currentLink: undefined
}

export { ViewCasesModal }
