import { Box } from 'grommet'
import { LinkNext } from 'grommet-icons'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Card, Typography } from '../shims/imports'

const FeaturedServicesCard = ({
  service: { detailsPath, name, category, serviceSlug, description },
  t
}) => {
  const history = useHistory()
  const LDFlags = useFlags()

  // if wellness LD flag is Off, should not show wellness dashboard
  const showWellness =
    !LDFlags['glcp-unified-wellness-dashboard'] && serviceSlug === 'WD'

  return (
    <>
      {!showWellness && (
        <Card
          a11yTitle={`${t('dashboard:common.view_details')} ${name}`}
          cardWidth="100%"
          onClick={(e) => {
            e.preventDefault()
            history.push(`/services/service-catalog/${detailsPath}`)
          }}
          action={<LinkNext color="green" />}
          description={t(
            `services:${serviceSlug}.short_description`,
            description
          )}
          title={
            <Box pad={{ bottom: 'brand' }}>
              <Typography type="heading" level={3}>
                {name}
              </Typography>
              <Typography type="text" size="xsmall">
                {t(`dashboard:common.category.${category}`)}
              </Typography>
            </Box>
          }
          testId="service-centric-featured-services-card"
        />
      )}
    </>
  )
}

FeaturedServicesCard.propTypes = {
  service: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export default FeaturedServicesCard
