import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'
import { Hpe } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Typography, Logo, Search } from '../../../../../../components'

const RoleDetailsTab = ({ roleDetails }) => {
  const { t } = useTranslation(['manage'])
  const {
    description,
    application_name: applicationName,
    application_logo: applicationLogo,
    predefined,
    resource_policies: resourcePolicies = []
  } = roleDetails

  const [searchTxt, setSearchTxt] = useState('')
  const [policies, setPolicies] = useState(resourcePolicies)
  const [permissionsCount, setPermissionsCount] = useState(0)

  useEffect(() => {
    let policiesList = []
    if (!searchTxt) {
      policiesList = resourcePolicies
    } else {
      const search = searchTxt.toLowerCase()
      resourcePolicies.forEach((policy) => {
        if (policy?.resource?.matcher?.toLowerCase()?.includes(search)) {
          policiesList.push(policy)
        } else {
          const permissions = policy.permissions.filter((p) =>
            p.slug.toLowerCase().includes(search)
          )
          if (permissions.length) {
            policiesList.push({
              ...policy,
              permissions
            })
          }
        }
      })
    }
    const count = policiesList.reduce(
      (res, item) => res + item.permissions.length,
      0
    )
    setPermissionsCount(count)
    setPolicies(policiesList)
  }, [searchTxt, resourcePolicies, setPolicies])

  return (
    <Box>
      <Typography
        type="text"
        weight="bold"
        size="xlarge"
        testId="role-details-heading"
        margin={{ vertical: 'medium' }}
      >
        {t('customer_roles.details')}
      </Typography>
      <Typography type="text" testId="role-description-heading">
        {t('customer_roles.description')}
      </Typography>
      <Typography type="text" testId="description-value">
        {description}
      </Typography>
      <Typography
        type="text"
        margin={{ top: 'medium' }}
        testId="role-type-heading"
      >
        {t('customer_roles.role_type')}
      </Typography>
      <Typography type="text" testId="role-type-value">
        {predefined
          ? t('customer_roles.built_in_role')
          : t('customer_roles.custom_role')}
      </Typography>
      <Typography
        type="text"
        margin={{ top: 'medium', bottom: 'xsmall' }}
        testId="application-heading"
      >
        {t('customer_roles.application')}
      </Typography>
      <Box direction="row" align="center">
        {roleDetails?.application_id ===
        '00000000-0000-0000-0000-000000000000' ? (
          <Hpe color="brand" size="small" />
        ) : (
          applicationLogo !== null && (
            <Logo
              testId="application_logo"
              size="xxsmall"
              url={applicationLogo}
            />
          )
        )}
        <Typography
          type="text"
          margin={{ left: 'small' }}
          testId="application-value"
        >
          {applicationName}
        </Typography>
      </Box>
      <Box margin={{ vertical: 'medium' }}>
        <Typography
          type="text"
          weight="bold"
          size="xlarge"
          testId="permissions-subheading"
        >
          <>
            {t('customer_roles.permissions')} ({permissionsCount})
          </>
        </Typography>
      </Box>
      {resourcePolicies?.length > 0 && (
        <Box>
          <Box margin={{ bottom: 'small' }}>
            <Search
              size="small"
              placeholder=""
              value={searchTxt}
              testId="search-field"
              handleCustomSearch={setSearchTxt}
            />
          </Box>
          {policies.map((policy) => (
            <Box key={policy?.resource?.matcher} margin={{ top: 'small' }}>
              <Typography
                type="text"
                weight="bold"
                size="medium"
                testId={`permissions-subheading-${policy?.resource?.matcher}`}
                margin="xsmall"
              >
                {policy?.resource?.matcher}
              </Typography>
              {policy?.permissions?.map((permission) => (
                <Typography
                  type="text"
                  key={permission?.slug}
                  testId={`permissions-subheading-${permission?.slug}`}
                  margin={{ vertical: 'xxsmall', horizontal: 'xsmall' }}
                >
                  {permission?.slug}
                </Typography>
              ))}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

RoleDetailsTab.propTypes = {
  roleDetails: PropTypes.object.isRequired
}

export { RoleDetailsTab }
