import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { WizardContext } from '../../../../../components/wizard/WizardContext'
import { AssignLocationContacts } from '../../AssignContacts/AssignLocationContacts'

export const AssignContacts = ({ customerId, isCCSManager }) => {
  const { formValues, setFormError, setFormValues } = useContext(WizardContext)
  const [contactList, setContactList] = useState(formValues.contacts)

  useEffect(() => {
    setFormValues({
      ...formValues,
      contacts: [...contactList]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactList])

  return (
    <>
      <AssignLocationContacts
        setFormError={setFormError}
        contactList={contactList}
        setContactList={setContactList}
        customerId={customerId}
        isCCSManager={isCCSManager}
      />
    </>
  )
}

AssignContacts.propTypes = {
  customerId: PropTypes.string,
  isCCSManager: PropTypes.bool
}

AssignContacts.defaultProps = {
  customerId: null,
  isCCSManager: false
}
