import { createContext, useState, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'

import useChatReducer from './ChatReducer'
import ChatActions from './ChatActions'

const ChatContext = createContext()

export const ChatContextProvider = ({ children }) => {
  const stateAndDispatch = useChatReducer()
  const [disableInput, setDisableInput] = useState(false)
  const [textAreaHeight, setTextAreaHeight] = useState(25)

  const { ChatState, dispatchChatContext } = stateAndDispatch
  const { selectedButtons } = ChatState

  const updateSelectedButton = useCallback(
    (messageId, buttonValue) => {
      dispatchChatContext({
        type: ChatActions.UPDATE_SELECTED_BUTTONS,
        payload: { messageId, buttonValue }
      })
    },
    [dispatchChatContext]
  )

  const clearSelectedButton = useCallback(
    (messageId) => {
      dispatchChatContext({
        type: ChatActions.UPDATE_SELECTED_BUTTONS,
        payload: { messageId, buttonValue: null }
      })
    },
    [dispatchChatContext]
  )

  const contextValue = {
    ...stateAndDispatch,
    disableInput,
    selectedButtons,
    clearSelectedButton,
    updateSelectedButton,
    setDisableInput,
    textAreaHeight,
    setTextAreaHeight
  }
  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  )
}

ChatContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const useChatContext = () => {
  const {
    ChatState,
    dispatchChatContext,
    selectedButtons,
    clearSelectedButton,
    updateSelectedButton,
    disableInput,
    setDisableInput,
    textAreaHeight,
    setTextAreaHeight
  } = useContext(ChatContext)
  return {
    ...ChatState,
    dispatchChatContext,
    selectedButtons,
    clearSelectedButton,
    updateSelectedButton,
    disableInput,
    setDisableInput,
    textAreaHeight,
    setTextAreaHeight
  }
}
