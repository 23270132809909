import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, NameValueList, NameValuePair, ResponsiveContext } from 'grommet'
import { ThemeContext } from 'styled-components'

import { Typography } from '../typography/Typography'
import { Ruler } from '../ruler/Ruler'
import { Button } from '../button/Button'

export const ObjectList = ({
  data,
  title,
  testId,
  editBtnTitle,
  editAction,
  showRuler
}) => {
  const getTypographyValue = (objectValue) => {
    if (objectValue && typeof objectValue === 'object' && objectValue.value)
      return objectValue.value.toString()
    if (objectValue) return objectValue.toString()
    return '--'
  }

  // Allow HPE theme styling to spread onto Typography instance
  const theme = useContext(ThemeContext)
  const size = useContext(ResponsiveContext)

  return (
    <Box data-testid={testId}>
      {title && typeof title === 'string' && (
        <>
          <Box
            direction="row-responsive"
            align="center"
            gap="small"
            justify="between"
            margin={showRuler ? { top: 'medium' } : { top: 'xxsmall' }}
          >
            <Typography
              level="2"
              type="heading"
              testId={`${testId}-head-2`}
              margin={showRuler ? 'none' : { top: 'none', bottom: 'xsmall' }}
            >
              {title}
            </Typography>
            {editBtnTitle && typeof editBtnTitle === 'string' && (
              <Button
                alignSelf="start"
                aria-label={`${editBtnTitle} ${title}`}
                testId={`${testId}-edit-button`}
                label={
                  <Typography
                    type="text"
                    testId={`${testId}-edit-button-title`}
                    {...theme?.button.default.font}
                  >
                    {editBtnTitle}
                  </Typography>
                }
                onClick={() => {
                  editAction()
                }}
              />
            )}
          </Box>
          {showRuler ? <Ruler /> : null}
        </>
      )}
      <NameValueList
        pairProps={{ direction: size === 'xsmall' ? 'column' : 'row' }}
      >
        {Object.keys(data).map((key) => (
          <NameValuePair
            key={`${testId}-${key}`}
            name={
              <Typography
                type="text"
                testId="obj-list-label"
                {...theme?.nameValuePair.name}
              >
                {key}
              </Typography>
            }
          >
            <Typography
              type={
                data[key] && typeof data[key] === 'object'
                  ? data[key].type
                  : 'text'
              }
              wordBreak="break-all"
              testId="obj-list-value"
            >
              {getTypographyValue(data[key])}
            </Typography>
          </NameValuePair>
        ))}
      </NameValueList>
    </Box>
  )
}

ObjectList.propTypes = {
  /**
   * data will be in object type only
   * Eg. { a: 'A', b: 'B' }
   */
  data: PropTypes.object.isRequired,
  /**
   * title prop is used to display the list title
   * This is string type for now. If required enhance it later
   */
  title: PropTypes.string,
  /**
   * It will be used for component reference to test.
   * This is mandatory.
   */
  testId: PropTypes.string.isRequired,

  editBtnTitle: PropTypes.string,
  editAction: PropTypes.func,
  showRuler: PropTypes.bool
}

ObjectList.defaultProps = {
  title: undefined,
  editBtnTitle: undefined,
  showRuler: true,
  editAction: () => {}
}
