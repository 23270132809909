import { get, post } from '../../../../utils/api-utils'
import {
  displayApiError,
  getApiErrorMessage
} from '../../../../utils/error-handling-utils'

import { pullSSOConfigStatus } from './utils/api-utils'

export const downloadMetadataFile = async (domainName, accessToken) => {
  get(`/authn/v1/saml/sp-metadata/${domainName}`, {}, accessToken).then(
    (resp) => {
      // TODO: temporary fix to show the xml data in different tab until the BE fixes the CORS error
      if (resp && resp.data && resp.data.metadata_url) {
        window.open(resp.data.metadata_url)
      }
      // getFile(resp.data.metadata_url).then((blob) => {
      //   const url = window.URL.createObjectURL(new Blob([blob.data]))
      //   const link = document.createElement('a')
      //   link.href = url
      //   link.setAttribute(
      //     'download',
      //     `${domainName.replace(/\..*/i, '')}-metadata.xml`
      //   )
      //   document.body.appendChild(link)
      //   link.click()
      //   link.parentNode.removeChild(link)
      // })
    },
    (error) => {
      console.error(error)
    }
  )
}

export const getDomainsInfo = (domains) => {
  const domainsInfo = {}
  let domain = ''
  domains?.forEach((domainName, index) => {
    if (index === domains?.length - 1 && domains.length !== 1) {
      domainsInfo.lastDomain = domainName
    } else if (index === 0) {
      domain = domainName
    } else {
      domain = `${domain}, ${domainName}`
    }
  })
  domainsInfo.remainingDomains = domain
  return domainsInfo
}

export const handleGenerateNewCertificate = ({
  state,
  setLoading,
  domain,
  samlEncryptionData,
  certificateSigningOption,
  oidcUser,
  setCertificateSuccessfullyGenerated,
  setErrorNotification,
  setErrorMessage,
  onSetOpen,
  t
}) => {
  const encryption_data =
    certificateSigningOption ||
    samlEncryptionData?.encryption_properties?.encryption_method
  setLoading(true)
  post(
    '/authn/v1/saml/async/encryption',
    {
      domain,
      assertion_encryption: true,
      encryption_properties: {
        encryption_method: encryption_data,
        regenerate_encryption: !!samlEncryptionData,
        key_algorithm: state.key_algorithm,
        key_size: state.key_size,
        signature_algorithm: state.signature_algorithm,
        common_name: state.common_name,
        country_code: state.country_code,
        state: state.state,
        city: state.city,
        organization: state.organization,
        organization_unit: state.organization_unit,
        valid_days: state.valid_days,
        subject_alternative_names: state.subject_alternative_name
      }
    },
    oidcUser.access_token
  ).then(
    (response) => {
      pullSSOConfigStatus(
        response?.data?.task_tracking_id,
        oidcUser,
        () => {
          setLoading(false)
          onSetOpen(false)
          setCertificateSuccessfullyGenerated(true)
        },
        (error) => {
          setErrorMessage(getApiErrorMessage(error, t))
        }
      )
    },
    (error) => {
      setErrorNotification(displayApiError(error, t, setErrorNotification))
    }
  )
}

export const getValidPropertiesLength = (object) => {
  return Object.values(object).filter((x) => x)?.length
}
