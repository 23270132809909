import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

const BillingExportPage = lazy(() => import('./BillingExportPage'))

const BillingExportRouterContent = () => {
  const { path } = useRouteMatch()

  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route path={`${path}`}>
          <BillingExportPage />
        </Route>
      </Switch>
    </Suspense>
  )
}

const BillingExportRouter = () => {
  return <BillingExportRouterContent />
}

export default BillingExportRouter
