import React from 'react'
import PropTypes from 'prop-types'
/* eslint-disable */
import { AuthenticationContext } from '@axa-fr/react-oidc-context'
/* eslint-enable */

/* Mock AuthenticationProvider from react-oidc-context library */
const StubbedAuthProvider = ({ children }) => {
  const contextValue = {
    oidcUser: {
      id_token: '',
      access_token: 'sample.access.token',
      refresh_token: '',
      token_type: 'Bearer',
      scope: 'openid profile email',
      profile: {
        auth_time: 0,
        email: 'devmockuser@email.com',
        family_name: 'Dev',
        given_name: 'User',
        jti: '',
        'pi.sri': '',
        sub: 'devmockuser@email.com'
      },
      expires_at: 0,
      state: { url: '/' }
    },
    userManager: {
      signoutRedirect: async (params) => {
        const timeout = (ms) =>
          new Promise((resolve) =>
            setTimeout(() => {
              window.location.replace(params.extraQueryParams.TargetResource)
              resolve()
            }, ms)
          )
        return timeout(2000)
      }
    },
    login: () => {},
    logout: () => {}
  }
  return (
    <AuthenticationContext.Provider value={contextValue}>
      {children}
    </AuthenticationContext.Provider>
  )
}

StubbedAuthProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default StubbedAuthProvider
