// (C) Copyright 2024 Hewlett Packard Enterprise Development LP)
import React, { useState, useContext, useEffect, useCallback } from 'react'
import { Box, FormField, Grommet, RadioButtonGroup } from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { deepMerge } from 'grommet/utils'
import { hpe } from 'grommet-theme-hpe'
import debounce from 'lodash/debounce'

import {
  FormInput,
  FormTextArea,
  Dropdown,
  Loader,
  Typography
} from '../../../../../../../components'
import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import { validateForm } from '../../../../../../../utils/validation-utils'
import { get, getErrorMessage } from '../../../../../../../utils/api-utils'
import { roleKindMapping } from '../../../../../utils'

const DESCRIPTION_MAX_LENGTH = 256
const NAME_MAX_LENGTH = 64

const roleNameValidation = (roleName) => {
  return roleName.match(/^(?! *$)[A-Za-z0-9 .-]+$/)
}

export const validateRoleInfo = (formValues, i18nTranslate) => {
  const validateOk = formValues?.isCreateNewRole
    ? !isEmpty(formValues?.roleName) && roleNameValidation(formValues?.roleName)
    : !isEmpty(formValues.newRoleName) &&
      roleNameValidation(formValues?.newRoleName)

  if (!validateOk) {
    return Promise.reject(new Error(''))
  }
  return validateForm(formValues, i18nTranslate)
}

const GeneralRoleInfo = ({ onLoadingChange }) => {
  const {
    formValues,
    setFormValues,
    formError,
    setFormError,
    attemptedAdvance,
    setAttemptedAdvance
  } = useContext(WizardContext)
  const [roleNameError, setRoleNameError] = useState()
  const [newRoleNameError, setNewRoleNameError] = useState()
  const [roleToDuplicateError, setRoleToDuplicateError] = useState()
  const [roleToDuplicate, setRoleToDuplicate] = useState()
  const [roleList, setRoleList] = useState([])
  const [permissionList, setPermissionList] = useState()
  const [loading, setLoading] = useState(false)
  const [searchedText, setSearchedText] = useState('')
  const { t } = useTranslation(['common', 'iam'])

  const getRoles = async () => {
    setLoading(true)
    await get('/internal-platform-tenant-ui/v2/roles', {
      service: 'all',
      ...(searchedText.trimStart().length > 0 && {
        search: searchedText.trimStart()
      })
    })
      .then(
        async (response) => {
          if (response?.data?.roles) {
            if (response?.data?.roles?.length === 0) {
              setRoleList([])
              return
            }
            // filtered linked roles
            const roles = response.data?.roles?.filter(
              (role) => role?.type !== 'LINKED'
            )
            setRoleList(roles)

            // Set selected role to duplicate
            if (formValues?.duplicateRoleFromAction) {
              const duplicateRole = roles.find(
                (role) => role.role_id === formValues?.roleToDuplicate?.id
              )
              if (duplicateRole) {
                setRoleToDuplicate(duplicateRole)
              }
            }
          } else {
            setFormError(
              t('iam:roles_error.no_role_error_msg', {
                role: t('common:business_object.role'),
                roles: t('common:business_object.role_plural')
              })
            )
            setRoleToDuplicateError(
              t('iam:roles_error.role_name_required', {
                role: t('common:business_object.role_capitalized')
              })
            )
          }
        },
        (error) => {
          setFormError(getErrorMessage(error, t))
        }
      )
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    // Pass the loading state back to parent
    onLoadingChange(loading)
  }, [loading, onLoadingChange])

  useEffect(() => {
    const retrieveRoleData = async () => {
      setLoading(true)
      try {
        const response = await get(
          `/authorization/v2alpha1/roles?grn=${encodeURIComponent(
            roleToDuplicate?.role_grn
          )}`,
          {}
        )
        if (response?.data) {
          const roleData = response?.data?.items.find(
            (item) => item?.grn === roleToDuplicate?.role_grn
          )
          setPermissionList(roleData?.permissions)
        }
        setLoading(false)
      } catch (error) {
        setFormError(getErrorMessage(error, t))
        setLoading(false)
      }
    }
    if (roleToDuplicate) {
      retrieveRoleData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, formValues?.roleToDuplicate])

  useEffect(() => {
    if (!formValues?.isCreateNewRole) {
      const permissions = formValues?.duplicateRoleFromAction
        ? formValues?.roleToDuplicate?.permissions
        : permissionList
      const permissionsToDuplidate = permissions?.map((permission) => ({
        permission_name: permission,
        // TODO request BE API to return permission description for role
        permission_description: ''
      }))
      if (permissionsToDuplidate) {
        setFormValues({
          ...formValues,
          permissions: permissionsToDuplidate
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionList])

  useEffect(() => {
    if (
      !isEmpty(roleToDuplicate?.service_id) &&
      !isEmpty(roleToDuplicate?.service_name)
    ) {
      setFormValues({
        ...formValues,
        serviceName: roleToDuplicate?.service_name,
        appId: roleToDuplicate?.service_id
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleToDuplicate])

  useEffect(() => {
    if (formValues && attemptedAdvance) {
      if (formValues.isCreateNewRole) {
        if (isEmpty(formValues?.roleName)) {
          setRoleNameError(
            t('iam:roles_error.role_name_required', {
              role: t('common:business_object.role_capitalized')
            })
          )
        }
      } else if (isEmpty(formValues?.roleToDuplicate)) {
        if (formError) {
          setFormError(formError)
          setAttemptedAdvance(false)
        }
        setRoleToDuplicateError(
          t('iam:roles_error.role_to_duplicate_error', {
            role: t('common:business_object.role_capitalized')
          })
        )
      } else if (isEmpty(formValues.newRoleName)) {
        setNewRoleNameError(
          t('iam:roles_error.new_role_name_required', {
            role: t('common:business_object.role_capitalized')
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemptedAdvance])

  const roleValues = {
    roleName: '',
    newRoleName: '',
    description: '',
    serviceName: '',
    appId: '',
    application: undefined,
    permissions: [],
    roleToDuplicate: undefined
  }

  useEffect(() => {
    // reset form values when create role from create role dialog
    // from role main page. Exceptions are:
    // - Duplicate role Action: this is not needed since the
    //   radio button is disable and hence switching between
    //   creating new role and duplicate role is disallowed
    // - In last step, click 'edit to step 1' will redirect to this page
    //   with original values
    if (
      Object.is(false, formValues?.duplicateRoleFromAction) &&
      Object.is(false, formValues?.editInStep1)
    ) {
      setFormValues({
        ...formValues,
        ...roleValues
      })
      setRoleToDuplicate(undefined)
    }

    if (!formValues?.editInStep1) {
      formValues.editInStep1 = true
    }

    if (Object.is(false, formValues?.isCreateNewRole)) {
      getRoles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.isCreateNewRole, searchedText])

  const createRoleOptions = [
    {
      label: t('iam:roles_permission.copy_permission_label_v2', {
        role: t('common:business_object.role')
      }),
      value: 'copy_role_permissions'
    },
    {
      label: t('iam:roles.create_new_role_label_v2', {
        role: t('common:business_object.role')
      }),
      value: 'create_new_role'
    }
  ]

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchValue = useCallback(
    debounce((text) => {
      setSearchedText(text)
    }, 500),
    []
  )

  const dropdownRoleRender = (role) => (
    <Box
      direction="row"
      justify="between"
      align="center"
      pad={{ horizontal: 'small', vertical: 'small' }}
      fill
    >
      <Box>
        <Typography size="small" type="text">
          {role?.service_name}
        </Typography>
        <Typography type="text" emphasis>
          {role.role_display_name}
        </Typography>
        <Typography size="small" type="text">
          {role.role_description}
        </Typography>
      </Box>
      <Typography size="small" type="text">
        {roleKindMapping.get(role?.type)}
      </Typography>
    </Box>
  )

  const roleDescription = () => (
    <FormTextArea
      name="role-description"
      label={t('iam:roles.role_description_label')}
      maxLength={DESCRIPTION_MAX_LENGTH}
      value={formValues?.description || ''}
      onChange={(event) => {
        setFormValues({
          ...formValues,
          description: event?.target?.value
        })
      }}
      testId="role-description-text-area-formfield"
    />
  )
  const createNewRole = () => (
    <Box gap="medium">
      <FormInput
        required={isEmpty(formValues?.roleName)}
        inputType="text"
        name="role-name"
        label={t('iam:roles_details.role_name', {
          role: t('common:business_object.role_capitalized')
        })}
        maxLength={NAME_MAX_LENGTH}
        value={formValues?.roleName || ''}
        error={roleNameError}
        onChange={({ target: { value } }) => {
          if (!isEmpty(roleNameError)) {
            setRoleNameError('')
          }
          if (value.length !== 0 && !roleNameValidation(value)) {
            setRoleNameError(t('iam:roles_error.allowed_chars_warning'))
          }
          setFormValues({
            ...formValues,
            roleName: value
          })
        }}
        testId="role-name-form-field"
      />
      {roleDescription()}
    </Box>
  )

  const copyFromExistingRole = () => (
    <Box gap="medium">
      {formValues?.duplicateRoleFromAction && (
        <FormInput
          required
          inputType="text"
          disabled
          name="role-to-duplicate"
          label={t('iam:roles.duplicate_role_label', {
            role: t('common:business_object.role_capitalized')
          })}
          value={formValues?.roleToDuplicate?.displayName}
          testId="role-to-duplicate"
        />
      )}
      {Object.is(false, formValues?.duplicateRoleFromAction) && (
        <FormField
          required
          label={t('iam:roles.duplicate_role_label', {
            role: t('common:business_object.role_capitalized')
          })}
          name="role-to-duplicate"
          data-testid="role-to-duplicate"
          error={roleToDuplicateError}
        >
          <Dropdown
            name="actions"
            testId="actions-dropdown"
            placeholder={t('iam:users.multi_select_box_placeholder')}
            options={roleList}
            labelKey="role_display_name"
            valueKey="role_id"
            value={roleToDuplicate || formValues?.roleToDuplicate}
            onChangeDropdown={(role) => {
              if (!isEmpty(roleToDuplicateError)) {
                setRoleToDuplicateError('')
              }
              setRoleToDuplicate(role)
              setFormValues({
                ...formValues,
                serviceName: role.service_name,
                appId: role.service_id,
                roleToDuplicate: role,
                newRoleName: t('iam:roles:duplicate_role_name', {
                  duplicatedRoleName: `${role?.role_display_name}`
                }),
                description: role?.role_description
              })
            }}
            onSearch={(searchText) => {
              handleDebouncedSearchValue(searchText)
            }}
            emptySearchMessage={
              loading
                ? t('common:loading')
                : t('iam:roles_error.no_matches_msg')
            }
            customRender={(role) => dropdownRoleRender(role)}
            size="small"
            onClose={() => {
              setSearchedText('')
            }}
          />
        </FormField>
      )}
      {formValues?.roleToDuplicate && (
        <Box gap="medium">
          <FormInput
            required
            inputType="text"
            name="new-role-name"
            label={t('iam:roles.new_role_name_label', {
              role: t('common:business_object.role')
            })}
            value={formValues?.newRoleName}
            error={newRoleNameError}
            maxLength={NAME_MAX_LENGTH}
            onChange={({ target: { value } }) => {
              if (!isEmpty(newRoleNameError)) {
                setNewRoleNameError('')
              }
              if (value.length !== 0 && !roleNameValidation(value)) {
                setNewRoleNameError(t('iam:roles_error.allowed_chars_warning'))
              }
              setFormValues({
                ...formValues,
                newRoleName: value
              })
            }}
            testId="new-role-name-form-field"
          />
          {roleDescription()}
        </Box>
      )}
    </Box>
  )

  return (
    <>
      {loading && <Loader testId="role-create-loader" />}
      <Box
        gap="medium"
        width="medium"
        justify="between"
        alignSelf="center"
        margin={{
          top: 'medium',
          bottom: 'large'
        }}
      >
        <Grommet
          theme={deepMerge(hpe, {
            radioButton: {
              font: {
                weight: 'normal'
              }
            }
          })}
        >
          <FormField
            name="role-create-form-field-radio"
            data-testid="role-create-form-field-radio"
          >
            <RadioButtonGroup
              labelKey="label"
              valueKey="value"
              color="brand"
              name="radio-group-deal-id"
              options={createRoleOptions}
              value={
                formValues?.isCreateNewRole
                  ? 'create_new_role'
                  : 'copy_role_permissions'
              }
              disabled={formValues?.duplicateRoleFromAction}
              onChange={(event) => {
                setFormValues({
                  ...formValues,
                  ...roleValues,
                  isCreateNewRole: event.target.value === 'create_new_role'
                })
                setRoleToDuplicate(undefined)
              }}
            />
          </FormField>
        </Grommet>
        {formValues?.isCreateNewRole ? createNewRole() : copyFromExistingRole()}
      </Box>
    </>
  )
}

GeneralRoleInfo.propTypes = {
  onLoadingChange: PropTypes.func.isRequired
}

GeneralRoleInfo.defaults = {
  onLoadingChange: () => {}
}

export default GeneralRoleInfo
