import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Box, Button, Layer, Markdown, Menu, Text } from 'grommet'
import { Archive, Close, FlagFill } from 'grommet-icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { Ruler } from '../../../../components/ruler/Ruler'
import SeverityIcon from '../eventList/SeverityIcon'
import { put } from '../../../../utils/api-utils'
import { eventPropType, allowCaseCreation } from '../../utils/data'

import CaseState from './CaseState'
import EventDetails from './EventDetails'
import ImpactedResources from './ImpactedResources'

const CustFlagFill = styled(FlagFill)`
  path[fill-rule='evenodd'] {
    stroke: #000;
    stroke-width: 2;
    fill: #000;
  }
`
const DetailsPanel = ({
  event,
  mutateEvent,
  clearCache,
  closePanel,
  caseOpened,
  trackCaseOpened
}) => {
  const { t } = useTranslation('unified_wellness')
  const { oidcUser } = useReactOidc()
  // helper function since all these actions are fairly verbose and mostly the same
  const tagActionCreator = (tag, value) => () => {
    // We need to clear the swr cache any time we change user data to prevent stale data being shown
    clearCache()
    mutateEvent(
      put(
        `/wellness/v2beta1/events/${event.uuid}/tags`,
        {
          tags: {
            [tag]: value
          }
        },
        oidcUser.access_token
      ).then(({ data }) => data),
      {
        ...event,
        [tag]: value
      }
    )
  }
  const markAsRead = tagActionCreator('read', true)
  const markAsUnread = tagActionCreator('read', false)
  const flag = tagActionCreator('flag', true)
  const unflag = tagActionCreator('flag', false)
  const archive = tagActionCreator('archive', true)
  const unarchive = tagActionCreator('archive', false)
  const openCase = () => {
    trackCaseOpened()
    mutateEvent(
      put(
        `/wellness/v2beta1/events/${event.uuid}/case`,
        {},
        oidcUser.access_token
      ).then(({ data }) => data),
      {
        ...event,
        status: {
          ...(event.status || {}),
          currentState: 'pendingCaseCreation'
        }
      }
    )
  }
  const recommendations = event.causes?.flatMap((cause) =>
    cause.recommendation ? [cause.recommendation] : []
  ) || ['--']
  return (
    <Layer
      onEsc={() => closePanel()}
      onClickOutside={() => closePanel()}
      position="right"
      full="vertical"
      modal
    >
      <Box
        width="large"
        pad="medium"
        style={{ overflow: 'scroll', height: '100%' }}
        data-testid="details-panel"
      >
        <div>
          <Box direction="row" justify="between">
            <Box align="start">
              <CaseState event={event} caseOpened={caseOpened} />
              <Box>
                <Text data-testid="details-title" size="xxlarge" weight="bold">
                  {event.title || '--'}
                </Text>
              </Box>
            </Box>
            <Box>
              <Button
                data-testid="details-close-btn"
                label={<Close size="small" color="black" />}
                onClick={() => closePanel()}
              />
            </Box>
          </Box>
          <Box
            data-testid="details-info-bar"
            direction="row"
            pad={{ vertical: 'small' }}
          >
            <Box pad={{ right: 'large' }}>
              <Text size="small">{t('details.status')}</Text>
              <Box align="center" direction="row">
                <SeverityIcon
                  size="small"
                  severity={event.condition.severity}
                />
                <Box pad={{ left: 'xsmall' }}>
                  <Text>{t(`status.${event.condition.severity}`)}</Text>
                </Box>
              </Box>
            </Box>
            <Box>
              <Text size="small">{t('details.date_reported')}</Text>
              <Text>
                {event.status?.initialTimestamp &&
                  dayjs(event.status.initialTimestamp).format(
                    'MMMM D, YYYY hh:mm A'
                  )}
              </Text>
            </Box>
            {(event.flag || event.archive) && (
              <Box pad={{ left: 'large' }}>
                <Text size="small">{t('details.state')}</Text>
                <Box direction="row" align="center">
                  {event.flag && (
                    <Box direction="row" align="center">
                      <CustFlagFill size="small" />
                      <Box pad={{ left: 'xsmall' }}>
                        <Text>{t('details.flagged')}</Text>
                      </Box>
                    </Box>
                  )}
                  {event.flag && event.archive && (
                    <Box pad={{ right: 'xsmall' }}>
                      <Text>,</Text>
                    </Box>
                  )}
                  {event.archive && (
                    <Box direction="row" align="center">
                      <Archive size="small" />
                      <Box pad={{ left: 'xsmall' }}>
                        <Text>{t('details.archived')}</Text>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
          <Box pad={{ vertical: 'small' }}>
            <Text data-testid="details-event-explanation">
              {event.body && <Markdown>{event.body?.content}</Markdown>}
            </Text>
          </Box>
          <Box align="start">
            <Menu
              data-testid="details-action-menu"
              label={t('details.actions')}
              items={[
                event.read
                  ? {
                      label: t('details.action_menu.mark_as_unread'),
                      onClick: markAsUnread
                    }
                  : {
                      label: t('details.action_menu.mark_as_read'),
                      onClick: markAsRead
                    },
                event.flag
                  ? {
                      label: t('details.action_menu.unflag'),
                      onClick: unflag
                    }
                  : { label: t('details.action_menu.flag'), onClick: flag },
                allowCaseCreation(event)
                  ? {
                      label: t('details.action_menu.open_a_case'),
                      onClick: openCase
                    }
                  : undefined,
                event.archive
                  ? {
                      label: t('details.action_menu.unarchive'),
                      onClick: unarchive
                    }
                  : {
                      label: t('details.action_menu.archive'),
                      onClick: archive
                    }
              ].filter((item) => !!item)}
              primary
            />
          </Box>
          <Ruler orientation="horizontal" testId="ruler" />
        </div>
        <div>
          <Box>
            <Box pad={{ bottom: 'medium' }}>
              <Text
                data-testid="details-recommended-action-title"
                size="xlarge"
                weight="bold"
              >
                {t('details.recommended_action')}
              </Text>
            </Box>
            {recommendations.map((rec) => (
              <Text data-testid="details-recommended-action-text" key={rec}>
                <Markdown>{rec}</Markdown>
              </Text>
            ))}
          </Box>
          <Ruler orientation="horizontal" testId="ruler" />
        </div>
        <div>
          <EventDetails event={event} />
          <Ruler orientation="horizontal" testId="ruler" />
        </div>
        <div>
          <ImpactedResources event={event} />
        </div>
      </Box>
    </Layer>
  )
}

DetailsPanel.propTypes = {
  event: eventPropType.isRequired,
  mutateEvent: PropTypes.func.isRequired,
  clearCache: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  caseOpened: PropTypes.bool.isRequired,
  trackCaseOpened: PropTypes.func.isRequired // this let's the UI keep track of what cases it opened in this session
}

export default DetailsPanel
