import dayjs from 'dayjs'

export function calculateExpirationTs(validated_at, validation_cycle) {
  const current_validated_ts = dayjs(validated_at)
  return current_validated_ts.add(validation_cycle, 'month')
}

export function isItWithin30days(validated_at, validation_cycle) {
  const expiration_ts = calculateExpirationTs(validated_at, validation_cycle)
  const timeDifference = expiration_ts.diff(dayjs(), 'day', true)
  if (timeDifference > 0 && timeDifference <= 30) {
    return true
  }
  return false
}
export const NON_GLP_USER_NAME = 'NON-GLP'
