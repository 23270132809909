import { Box, Button, ResponsiveContext, Anchor } from 'grommet'
import {
  ShareRounded,
  Previous,
  CircleInformation,
  CircleAlert
} from 'grommet-icons'
import { Trans, useTranslation } from 'react-i18next'
import { useContext, useEffect, useState, useCallback } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useHistory } from 'react-router-dom'

import {
  Typography,
  Layout,
  useParams,
  Tabs,
  VisibilityWrapper,
  PageHeader,
  Notification,
  isGLOP
} from '../../../../dashboard/shims/imports'
import { displayApiError } from '../../../../utils/error-handling-utils'
import {
  useAxiosAuth,
  useRecentServices,
  useNewServiceRedirect as useServiceRedirect,
  usePollServiceDetail,
  useRegions
} from '../../../../dashboard/hooks'
import {
  NewServiceLaunchModal as ServiceLaunchModal,
  NewProvisionModal as ProvisionModal,
  RequestEvalModal,
  EvalButtonGroup,
  RequestEvalStatusText
} from '../../../../dashboard/components'
import {
  SERVICE_PROVISION_FEATURE,
  GLC,
  EVAL_STATES,
  INTERNAL_REDIRECT_SERVICES,
  ORG_SINGLETON_SERVICE_PROVISIONING,
  EVAL_LABEL
} from '../../../../dashboard/constants'
import { getOrganizationId, isMSP } from '../../../../utils/feature-flag-utils'
import { Loader } from '../../../../components'

import OverviewTab from './OverviewTab'
import RegionsTab from './RegionsTab'
import ServiceManagerTab from './ServiceManagerTab'

const NewServiceDetail = () => {
  const axios = useAxiosAuth()
  const { id, fromServiceSubs } = useParams()
  const history = useHistory()

  const [showLaunchModal, setShowLaunchModal] = useState(false)
  const [showProvisionModal, setShowProvisionModal] = useState(false)
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [serviceDetailData, setServiceDetailData] = useState({})
  const [serviceNotFound, setServiceNotFound] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(fromServiceSubs ? 1 : 0)
  const [isLoading, setIsLoading] = useState(true)
  // Todo: handle this regionlist fetch in re-infra
  // no api or codes added for context api to avoid duplication
  useRegions()

  // Request Eval
  const [evalModalFormOpen, setEvalModalFormOpen] = useState(false)
  const [requestEvalStatus, setRequestEvalStatus] = useState({})
  const [successEval, setSuccessEval] = useState(false)
  const [hasEval, setHasEval] = useState(false)
  const [evalServiceData, setEvalServiceData] = useState({})
  const [evalUrl, setEvalUrl] = useState('')
  const [configureDeviceNotification, setConfigureDeviceNotification] =
    useState(false)
  const LDFlags = useFlags()
  const evalFlag = LDFlags['glcp-evaluation-service']
  const dynamicEvalFlag = LDFlags['glcp-dynamic-eval-enablement']
  const serviceDetailFlag = LDFlags['glcp-jhansi-service-detail-page-update']
  const { t } = useTranslation([
    'apps',
    'common',
    'dashboard',
    'services',
    'device'
  ])
  const { platform_customer_id: pcid } = JSON.parse(
    sessionStorage?.getItem('account')
  )
  const [formLoader, setFormLoader] = useState(false)
  const [evalFieldsMetaData, setEvalFieldsMetaData] = useState({})
  const [, setRecentServices] = useRecentServices()
  const serviceRedirect = useServiceRedirect()
  const screenSize = useContext(ResponsiveContext)
  const isLargeScreen = screenSize === 'large' || screenSize === 'xlarge'

  const serviceOffer = serviceDetailData?.service_offer || {}
  const serviceManager = serviceDetailData?.service_manager || {}
  const availableRegions = serviceDetailData?.available_regions || []
  const {
    is_service_manager: isServiceManager,
    name: serviceName,
    slug: serviceSlug,
    categories: serviceCategories = [],
    features_supported: featuresSupported = [],
    test_drive_url: testDriveUrl,
    static_launch_url: staticLaunchUrl,
    contact_sales_url: contactSalesUrl,
    documentation_url: serviceDocumentationUrl,
    eval_url: serviceEvalUrl,
    workspace_types: workspaceTypes = []
  } = serviceOffer
  const { slug: serviceManagerSlug, name: serviceManagerName } = serviceManager
  const dmEnableDefaultRegion = LDFlags['glcp-dm-enable-default-region']

  const isServiceProvision =
    featuresSupported?.includes(SERVICE_PROVISION_FEATURE) &&
    LDFlags['glcp-service-provision']

  const isDeepLinking = featuresSupported?.includes('DEEP_LINKING')

  const isProvisionSupported =
    !featuresSupported?.includes('SKIP_SERVICE_MGR_PROVISIONING') ||
    isServiceProvision

  const isTenantOnlySupported =
    workspaceTypes?.includes('TENANT') && !workspaceTypes?.includes('MSP')

  // TO-DO code cleanup iam
  // added false to control service level igc which is removed
  // while code cleanup clean the logicin code level cleanup clean this
  const isIGCFeature =
    featuresSupported?.includes(ORG_SINGLETON_SERVICE_PROVISIONING) &&
    featuresSupported?.includes(SERVICE_PROVISION_FEATURE) &&
    false
  const provisionsData =
    isIGCFeature && serviceDetailData?.org_singleton_service_provisions !== null
      ? serviceDetailData?.org_singleton_service_provisions
      : serviceDetailData?.provisions
  const provisions =
    provisionsData
      ?.filter(({ application_provision, service_provision }) =>
        isServiceProvision ? service_provision : application_provision
      )
      ?.map(({ application_provision, service_provision, name, slug }) => ({
        ...(isServiceProvision ? service_provision : application_provision),
        name,
        slug
      })) || []

  const provisionedInstances = provisions.filter(
    ({ provision_status }) => provision_status === 'PROVISIONED'
  )

  const igcAllowedLaunch =
    isIGCFeature &&
    Boolean(staticLaunchUrl) &&
    provisions?.length > 0 &&
    provisionedInstances.length > 0 &&
    provisions[0]?.organization_id !== null &&
    provisions[0]?.organization_id === getOrganizationId() &&
    provisions[0]?.platform_customer_id === pcid

  const regionsCanBeProvisioned =
    serviceDetailData.available_regions?.filter(
      (availableRegion) =>
        !provisions
          .filter(
            ({ provision_status }) =>
              ![
                'PROVISION_FAILED',
                'UNPROVISIONED',
                'UNPROVISION_FAILED'
              ].includes(provision_status)
          )
          ?.map(({ region }) => region)
          ?.includes(availableRegion.code)
    ) || []

  const setPageData = useCallback(() => {
    axios
      .get(`/service-catalog/v1alpha1/detailed-service-offers/${id}`)
      .then(
        ({ data }) => {
          setServiceDetailData(data)
        },
        (error) => {
          if (error.status === 404) {
            setServiceNotFound(true)
          }
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }, [axios, id, t])

  // move to new file
  const getDaysLeft = () => {
    const currentDate = new Date()
    const endDate = Date.parse(requestEvalStatus.endDate)
    const timeDifference = endDate - currentDate
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
  }

  const padResponsive = {
    xlarge: 'large',
    large: 'medium',
    medium: 'medium',
    small: 'large',
    xsmall: 'large'
  }

  useEffect(setPageData, [setPageData])

  const [countries, setCountries] = useState([])
  const [listOfCountries, setlistOfCountries] = useState([])
  useEffect(() => {
    axios.get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      (response) => {
        setCountries(response.data.countries)
        setlistOfCountries(response.data.countries)
      },
      (err) => {
        setShowErrorNotification(
          displayApiError(err, t, setShowErrorNotification)
        )
      }
    )
  }, [t, axios])

  // Eval Get Request
  const findEvalResponse = (evalResponse, slug) => {
    const evalObject = evalResponse.find((item) => item.service === slug) || {
      status: ''
    }
    return evalObject
  }

  const fetchEvalStatus = () => {
    axios
      .get('/ui-doorway/ui/v1/license/eval')
      .then((response) => {
        const evalResponse = response?.data
        const evalObject = findEvalResponse(evalResponse, serviceSlug)
        setRequestEvalStatus(evalObject)
        setEvalServiceData({
          name: serviceName,
          documentationUrl: serviceDocumentationUrl,
          regions: availableRegions,
          slug: serviceSlug
        })
        setEvalUrl(serviceEvalUrl)
        setHasEval(true)
      })
      .catch((err) => {
        setShowErrorNotification(
          displayApiError(err, t, setShowErrorNotification)
        )
      })
  }

  const getEvalData = () => {
    setFormLoader(true)
    axios
      .get(`/billing/v1beta1/catalog?service=${serviceSlug}`)
      .then((response) => {
        setHasEval(true)
        // add the region options.
        let regionOptions = availableRegions
        regionOptions = regionOptions.map((region) => ({
          name: region.name,
          code: region.code
        }))
        const metadata = response.data.metadata.marketplaceInfo
        metadata.options.find(
          (option) => option.name === 'region'
        ).allowedValues = regionOptions
        setEvalFieldsMetaData(metadata)
        setFormLoader(false)
        fetchEvalStatus()
      })
      .catch((err) => {
        setFormLoader(false)
        if (err.response?.status !== 404) {
          setShowErrorNotification(
            displayApiError(err, t, setShowErrorNotification)
          )
        }
      })
  }
  useEffect(() => {
    if (featuresSupported?.includes(EVAL_LABEL) && evalFlag) {
      if (dynamicEvalFlag) {
        getEvalData()
      } else {
        fetchEvalStatus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evalFlag, successEval, serviceSlug, dynamicEvalFlag, featuresSupported])

  usePollServiceDetail(
    setServiceDetailData,
    provisions,
    id,
    isServiceProvision,
    isIGCFeature
  )

  const closeModal = () => {
    setShowProvisionModal(false)
  }

  const isServiceManagerProvisioned = () => provisionedInstances.length >= 1

  // Disable regular launch button
  const disableLaunchButton =
    (isServiceManager && provisionedInstances.length === 0) ||
    Boolean(staticLaunchUrl) ||
    (!isServiceManager && !isServiceManagerProvisioned()) ||
    (isMSP() && isTenantOnlySupported)

  const showStaticLaunch = isIGCFeature
    ? igcAllowedLaunch
    : Boolean(staticLaunchUrl) &&
      (!isServiceProvision ||
        (isServiceProvision && provisionedInstances.length > 0))

  const isGLC = isServiceManager
    ? serviceSlug === GLC
    : serviceManagerSlug === GLC

  const showProvisioningSpinner = () =>
    provisionedInstances.length === 0 &&
    provisions.some(
      ({ provision_status: provisionStatus }) =>
        provisionStatus === 'PROVISION_INITIATED'
    )

  const tabs = [
    {
      id: 1,
      label: t('dashboard:service_detail.overview'),
      content: (
        <OverviewTab
          serviceDetailData={serviceDetailData}
          isServiceProvision={isServiceProvision}
          screenSize={screenSize}
          hasEval={hasEval}
          requestEvalStatus={requestEvalStatus}
          setEvalModalFormOpen={setEvalModalFormOpen}
          t={t}
        />
      ),
      testId: 'overview-tab'
    },
    ...(!isServiceManager &&
    !isServiceProvision &&
    !isIGCFeature &&
    !staticLaunchUrl &&
    serviceManagerSlug &&
    serviceManagerSlug !== 'OTHER'
      ? [
          {
            id: 2,
            label: t('dashboard:common.service_manager'),
            content: (
              <ServiceManagerTab
                serviceDetailData={serviceDetailData}
                setIsLoading={setIsLoading}
                setActiveTabIndex={setActiveTabIndex}
                history={history}
                t={t}
              />
            ),
            testId: 'service-manager-tab'
          }
        ]
      : []),
    ...((isServiceManager || isServiceProvision) &&
    (!staticLaunchUrl || isServiceProvision || isIGCFeature)
      ? [
          {
            id: 3,
            label: `${t('dashboard:common.regions')} (${
              provisions.filter(
                (provision) => provision.provision_status === 'PROVISIONED'
              ).length
            })`,
            content: (
              <RegionsTab
                serviceDetailData={serviceDetailData}
                provisions={provisions}
                regionsCanBeProvisioned={regionsCanBeProvisioned}
                setShowProvisionModal={setShowProvisionModal}
                setPageData={setPageData}
                isServiceProvision={isServiceProvision}
                t={t}
              />
            ),
            testId: 'regions-tab'
          }
        ]
      : [])
  ]

  if (serviceNotFound) history.push('/not-found')

  const renderTenantOnlySupportedLabel =
    isMSP() && isTenantOnlySupported
      ? t('dashboard:service_details.enable')
      : t('dashboard:service_details.provision')

  return (
    <Layout>
      {successEval && (
        <Notification
          testId="eval-notification"
          status="normal"
          text={t('dashboard:eval.eval_success_notification', {
            cumulativeServiceName: serviceDetailData.service_offer.name
          })}
          onClose={() => setSuccessEval(false)}
        />
      )}
      {showErrorNotification !== null && showErrorNotification}
      <Box
        direction="row"
        fill="horizontal"
        pad={{
          horizontal: screenSize === 'xlarge' ? 'medium' : 'xsmall',
          vertical: 'medium'
        }}
      >
        <Button
          {...(!isLargeScreen && { pad: { horizontal: 'small' } })}
          icon={<Previous />}
          as="a"
          href={
            fromServiceSubs
              ? '/services/service-subscriptions'
              : '/services/service-catalog'
          }
          onClick={(e) => {
            e.preventDefault()
            history.push(
              fromServiceSubs
                ? '/services/service-subscriptions'
                : '/services/service-catalog/'
            )
          }}
          label={
            fromServiceSubs
              ? t('device:service_subscriptions')
              : t('dashboard:service_catalog.title')
          }
          direction="row"
        />
      </Box>
      {isLoading ? (
        <Box align="center" pad="small">
          <Loader testId="loader-spinner" />
        </Box>
      ) : (
        <Box align="center" pad={{ bottom: 'medium' }}>
          {showProvisionModal && (
            <ProvisionModal
              regionsCanBeProvisioned={regionsCanBeProvisioned}
              serviceDetailData={serviceDetailData}
              closeModal={closeModal}
              type={provisionedInstances.length === 0 ? 'provision' : 'add'}
              setPageData={setPageData}
              setShowErrorNotification={setShowErrorNotification}
              isServiceProvision={isServiceProvision}
              setConfigureDeviceNotification={setConfigureDeviceNotification}
              setActiveTabIndex={setActiveTabIndex}
              isOrgServiceProvision={isIGCFeature}
            />
          )}
          {showLaunchModal &&
            provisionedInstances.length !== 0 &&
            !isIGCFeature && (
              <ServiceLaunchModal
                provisions={serviceDetailData?.provisions}
                isServiceManager={isServiceManager}
                serviceManagerName={serviceManagerName}
                setShowModal={setShowLaunchModal}
                isServiceDetailLaunch
                isServiceProvision={isServiceProvision}
              />
            )}
          <Box width="xxlarge" pad={{ horizontal: padResponsive[screenSize] }}>
            <Box
              direction={isLargeScreen ? 'row' : 'column'}
              align={isLargeScreen ? 'center' : 'start'}
              justify="between"
              margin={{ bottom: 'medium' }}
              gap="medium"
            >
              <Box>
                <PageHeader
                  testId="service-details-main"
                  primaryHeader={serviceName}
                  subHeader={`
                ${t(`dashboard:common.category.${serviceCategories?.at(0)}`)}
                `}
                />
              </Box>
              <Box gap="small" direction="row" justify="between">
                {!serviceDetailFlag && (
                  <>
                    {contactSalesUrl && !hasEval && (
                      <Button
                        label={t('dashboard:service_detail.contact_sales')}
                        data-testid={`${serviceSlug}-service-detail-contact-sales-button`}
                        icon={<ShareRounded />}
                        reverse
                        href={contactSalesUrl}
                        target="_blank"
                      />
                    )}
                    {hasEval && (
                      <VisibilityWrapper
                        rbac={{
                          permission: 'ccs.orders.edit',
                          resource: '/ccs/orders'
                        }}
                      >
                        <Box direction="row">
                          <EvalButtonGroup
                            serviceSlug={serviceSlug}
                            evalStates={EVAL_STATES}
                            evalUrl={evalUrl}
                            requestEvalStatus={requestEvalStatus}
                            setEvalModalFormOpen={setEvalModalFormOpen}
                          />
                        </Box>
                      </VisibilityWrapper>
                    )}
                  </>
                )}
                {testDriveUrl && provisionedInstances.length === 0 && (
                  <Button
                    as="a"
                    secondary
                    label={t('dashboard:service_detail.test_drive')}
                    href={testDriveUrl}
                    target="_blank"
                    data-testid={`${serviceSlug}-service-detail-test-drive-button`}
                  />
                )}
                <VisibilityWrapper
                  rbac={{
                    resource: isServiceProvision
                      ? '/ccs/service-catalog/service-provision'
                      : '/ccs/app-provision/provisions',
                    permission: isServiceProvision
                      ? 'ccs.service-catalog.edit'
                      : 'ccs.app-provision.edit'
                  }}
                >
                  {isProvisionSupported &&
                    // If there's any provision with provision_status excluding 'UNPROVISION_FAILED' and 'PROVISION_FAILED' then hide the provision button
                    provisions.filter(
                      (instance) =>
                        instance?.provision_status &&
                        !['UNPROVISION_FAILED', 'PROVISION_FAILED'].includes(
                          instance?.provision_status
                        )
                    ).length === 0 &&
                    regionsCanBeProvisioned.length !== 0 && (
                      <Button
                        primary
                        data-testid={
                          isServiceManager || isServiceProvision
                            ? `${serviceSlug}-service-detail-provision-button`
                            : `${serviceSlug}-service-detail-setup-button`
                        }
                        label={
                          isServiceManager || isServiceProvision
                            ? renderTenantOnlySupportedLabel
                            : t('dashboard:service_details.setup')
                        }
                        onClick={() => setShowProvisionModal(true)}
                      />
                    )}
                  {regionsCanBeProvisioned.length !== 0 &&
                    provisionedInstances.length >= 1 &&
                    isProvisionSupported && (
                      <VisibilityWrapper
                        rbac={{
                          resource: isServiceProvision
                            ? '/ccs/service-catalog/service-provision'
                            : '/ccs/app-provision/provisions',
                          permission: isServiceProvision
                            ? 'ccs.service-catalog.edit'
                            : 'ccs.app-provision.edit'
                        }}
                      >
                        <Box>
                          <Button
                            label={t('dashboard:service_details.add_region')}
                            data-testid={`${serviceDetailData?.service_offer?.slug}-service-detail-add-region-button`}
                            onClick={() => setShowProvisionModal(true)}
                            primary
                            testId="add-region-button"
                          />
                        </Box>
                      </VisibilityWrapper>
                    )}
                  {!isGLC && showProvisioningSpinner() && (
                    <Box direction="row" gap="small" align="center">
                      <Typography
                        type="text"
                        testId="provisioning-spinner-text"
                      >
                        {t('dashboard:service_details.provisioning')}
                      </Typography>
                      <Loader testId="provisioning-spinner-loader" />
                    </Box>
                  )}
                </VisibilityWrapper>
                {/* Adding icon check for wellness as external launch icon is not needed for that service */}
                {/* Adding target check for wellness as new tab launch is not needed for that service */}
                {/* TO-DO code cleanup iam */}
                {/* added code to control igc as service */}
                {/* while code cleanup clean remove the code and handle in api */}
                <VisibilityWrapper hideFor={{ deployment: ['GLOP'] }}>
                  {showStaticLaunch && serviceSlug !== 'IGC' && (
                    <Button
                      primary
                      data-testid={
                        isServiceManager ||
                        staticLaunchUrl ||
                        (!isServiceManager && serviceManagerSlug === 'OTHER')
                          ? `${serviceSlug}-service-detail-static-launch-button`
                          : `${serviceSlug}-service-detail-static-launch-service-manager-button`
                      }
                      label={
                        isServiceManager ||
                        staticLaunchUrl ||
                        (!isServiceManager && serviceManagerSlug === 'OTHER')
                          ? t('dashboard:common.launch')
                          : t('dashboard:common.launch_service_manager')
                      }
                      icon={
                        !INTERNAL_REDIRECT_SERVICES.includes(serviceSlug) ? (
                          <ShareRounded />
                        ) : (
                          ''
                        )
                      }
                      reverse
                      href={staticLaunchUrl}
                      target={
                        INTERNAL_REDIRECT_SERVICES.includes(serviceSlug)
                          ? '_self'
                          : '_blank'
                      }
                      onClick={() => {
                        setRecentServices(
                          isServiceManager ? serviceSlug : serviceManagerSlug // When service can be launched we should always use service slug
                        )
                      }}
                    />
                  )}
                </VisibilityWrapper>
                {disableLaunchButton || (
                  <Button
                    primary
                    data-testid={
                      isServiceManager || isServiceProvision || isDeepLinking
                        ? `${serviceSlug}-service-detail-launch-button`
                        : `${serviceSlug}-service-detail-launch-service-manager-button`
                    }
                    label={
                      isServiceManager || isServiceProvision || isDeepLinking
                        ? t('dashboard:common.launch')
                        : t('dashboard:common.launch_service_manager')
                    }
                    onClick={() => {
                      if (provisionedInstances.length === 1) {
                        serviceRedirect(provisionedInstances[0], serviceSlug)
                      } else {
                        setShowLaunchModal(true)
                      }
                    }}
                  />
                )}
              </Box>
            </Box>
            {hasEval && (
              <VisibilityWrapper
                rbac={{
                  permission: 'ccs.orders.edit',
                  resource: '/ccs/orders'
                }}
              >
                <RequestEvalStatusText
                  evalStates={EVAL_STATES}
                  getDaysLeft={getDaysLeft}
                  requestEvalStatus={requestEvalStatus}
                />
              </VisibilityWrapper>
            )}
            {isIGCFeature &&
              !igcAllowedLaunch &&
              provisions?.length > 0 &&
              provisionedInstances.length > 0 && (
                <Box>
                  <Notification
                    backgroundColor="status-unknown"
                    icon={
                      <CircleInformation
                        style={{ marginTop: '3px', marginLeft: '4px' }}
                      />
                    }
                    testId="igc_provision_notification_message"
                    text={
                      <Trans i18nKey="dashboard:service_detail.provisioned_in_other_workspace">
                        {{
                          workspace: provisions[0]?.platform_customer_name
                        }}
                      </Trans>
                    }
                    type="inline"
                  />
                </Box>
              )}
            {provisionedInstances?.length > 0 &&
              configureDeviceNotification &&
              dmEnableDefaultRegion && (
                <Box
                  gap="small"
                  direction="row"
                  align="center"
                  data-testid="device-configuration-anchor-info"
                  background="background-back"
                  margin={{ bottom: 'medium' }}
                  pad="xsmall"
                >
                  <CircleAlert size="medium" />
                  <Typography
                    type="text"
                    size="small"
                    testId="device-configuration-anchor-label"
                  >
                    <Trans
                      i18nKey="dashboard:service_catalog.default_service_notification"
                      t={t}
                    >
                      <Anchor
                        label={t('dashboard:common.device_configuration')}
                        target="_blank"
                        testId="device-configuration-anchor"
                        onClick={(e) => {
                          e.preventDefault()
                          history.push('/devices/device-configuration')
                        }}
                      />
                    </Trans>
                  </Typography>
                </Box>
              )}
            {isGLOP() ? (
              <OverviewTab
                serviceDetailData={serviceDetailData}
                screenSize={screenSize}
                hasEval={hasEval}
                setEvalModalFormOpen={setEvalModalFormOpen}
                t={t}
              />
            ) : (
              <Tabs
                activeIndex={activeTabIndex}
                onActive={(tabIndex) => {
                  setActiveTabIndex(tabIndex)
                }}
                tabsList={tabs}
                testId="serviceDetailTabs"
              />
            )}
          </Box>
        </Box>
      )}
      {evalModalFormOpen && (
        <RequestEvalModal
          countries={countries}
          setCountries={setCountries}
          listOfCountries={listOfCountries}
          evalServiceData={evalServiceData}
          setEvalModalFormOpen={setEvalModalFormOpen}
          setShowErrorNotification={setShowErrorNotification}
          setSuccessEval={setSuccessEval}
          formLoader={formLoader}
          evalFieldsMetaData={evalFieldsMetaData}
        />
      )}
    </Layout>
  )
}
export default NewServiceDetail
