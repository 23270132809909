// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import { History } from 'grommet-icons'
import PropTypes from 'prop-types'

import { Typography } from '../../../../../components'

import SuggestionContainer from './SuggestionContainer'

const RecentSearches = ({ query, index, t }) => {
  return (
    <SuggestionContainer>
      <Box direction="column" gap="small">
        {index === 0 && (
          <Typography type="text" size="xsmall">
            {t('recent_searches')}
          </Typography>
        )}
        <Box direction="row" gap="small">
          <Box pad={{ top: 'xxsmall' }}>
            <History />
          </Box>
          <Typography type="text" weight={500}>
            {query}
          </Typography>
        </Box>
      </Box>
    </SuggestionContainer>
  )
}

RecentSearches.propTypes = {
  query: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
}

export default RecentSearches
