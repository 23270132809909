import React from 'react'
import { Box, NameValueList, NameValuePair } from 'grommet'
import PropTypes from 'prop-types'

import { Typography } from '../../../components'

const FeatureFlagsRulesDisplay = ({ rules }) => {
  const renderSingleClause = (inputClause) => {
    const { _id: id } = inputClause
    return (
      <NameValueList
        nameProps={{ width: 'xsmall' }}
        data-testid={`clause-value-list-${id}`}
        key={`clause-value-list-${id}`}
      >
        <NameValuePair name={inputClause.contextKind}>
          <Box
            direction="column"
            key={`clause-value-list-${id}-innerValueContainer`}
          >
            <Box direction="column">
              {inputClause.values.map((subValue) => {
                return (
                  <Typography
                    type="text"
                    key={`subValue-${subValue}`}
                    testId="LD-status-details-clause-subValue"
                  >
                    {subValue}
                  </Typography>
                )
              })}
            </Box>
          </Box>
        </NameValuePair>
      </NameValueList>
    )
  }
  return rules?.map((rule) => {
    const { _id: ruleId } = rule
    return (
      <Box
        direction="column"
        gap="small"
        margin={{ bottom: 'small' }}
        key={`rule-${ruleId}-container`}
      >
        <Box pad={{ left: 'small' }}>
          {rule?.clauses?.map((clause) => {
            return renderSingleClause(clause)
          })}
        </Box>
      </Box>
    )
  })
}

FeatureFlagsRulesDisplay.propTypes = {
  rules: PropTypes.array.isRequired
}

export default FeatureFlagsRulesDisplay
