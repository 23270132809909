import { Route, Switch, useRouteMatch } from 'react-router-dom'
import React from 'react'

import GetStartedPage from './GetStarted'

const GetStartedRouter = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={path}>
        <GetStartedPage />
      </Route>
    </Switch>
  )
}

export default GetStartedRouter
