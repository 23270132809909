import dayjs from 'dayjs'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { get } from '../../../utils/api-utils'

const escalationPropType = {
  trigger: PropTypes.oneOf([
    'case',
    'auto',
    'manual',
    'heartbeat',
    'email',
    'alert',
    'autosupport'
  ]).isRequired,
  user: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  lastUpdatedAt: PropTypes.string.isRequired,
  crm: PropTypes.string.isRequired,
  caseId: PropTypes.string.isRequired,
  caseNumber: PropTypes.string.isRequired,
  href: PropTypes.shape({
    external: PropTypes.string.isRequired,
    internal: PropTypes.string.isRequired
  }),
  status: PropTypes.string.isRequired,
  caseAction: PropTypes.string.isRequired,
  wellnessModelUUID: PropTypes.string.isRequired
}

const causePropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  body: PropTypes.shape({
    type: PropTypes.string.isRequired,
    mediaType: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
  }).isRequired,
  recommendation: PropTypes.string
})

const caseStatusPropType = PropTypes.shape({
  currentState: PropTypes.oneOf([
    'pendingCaseCreation',
    'pendingEscalation',
    'timeoutCaseCreation',
    'escalated',
    'correlated',
    ''
  ]),
  request: PropTypes.oneOf(['new']),
  initialTimestamp: PropTypes.string, // ISO 8601
  lastTimestamp: PropTypes.string, // ISO 8601
  expiresAt: PropTypes.string // ISO 8601
})

export const eventPropType = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  flag: PropTypes.bool,
  read: PropTypes.bool,
  archive: PropTypes.bool,
  glcpAttributes: PropTypes.shape({
    productName: PropTypes.string,
    serviceName: PropTypes.string
  }),
  condition: PropTypes.shape({
    name: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(['critical', 'warning', 'notice']).isRequired
  }).isRequired,
  status: caseStatusPropType,
  body: PropTypes.shape({
    type: PropTypes.string.isRequired,
    mediaType: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
  }),
  asset: PropTypes.shape({
    urn: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired
  }),
  causes: PropTypes.arrayOf(causePropType),
  escalation: PropTypes.arrayOf(escalationPropType)
})

const severityMap = {
  'non-critical': 'warning',
  info: 'notice',
  '': 'notice'
}
export const cleanseEvents = (events) => {
  return events.map((event) => {
    const read = event.read || false
    const flag = event.flag || false
    const archive = event.archive || false

    return {
      ...event,
      read,
      flag,
      archive,
      condition: {
        ...event.condition,
        severity:
          severityMap[event.condition.severity.toLowerCase()] ||
          event.condition.severity.toLowerCase()
      }
    }
  })
}
const fetchEventsCreator = (oidcUser) => (url) =>
  get(url, {}, oidcUser.access_token).then(({ data }) => {
    return {
      events: cleanseEvents(data.items || []),
      total: data.total,
      next: data.next,
      page: data.page
    }
  })
export const useEventFetcher = () => {
  const { oidcUser } = useReactOidc()
  return fetchEventsCreator(oidcUser)
}
export const serviceValues = [
  'Compute',
  'GreenLake Services',
  'Networking',
  'Storage'
]
export const deviceValues = [
  'HPE Alletra 5000',
  'HPE Alletra 6000',
  'HPE Alletra 9000',
  'HPE Alletra Storage MP',
  'Edgeline e920t',
  'Edgeline e920',
  'ProLiant DL20 Gen10 Plus',
  'ProLiant DL325 Gen10 Plus',
  'ProLiant DL345 Gen11',
  'ProLiant DL385 Gen10',
  'ProLiant DL385 Gen10 Plus',
  'ProLiant DL360 Gen10',
  'ProLiant DL360 Gen10 Plus',
  'ProLiant DL360 Gen11',
  'ProLiant DL380 Gen10',
  'ProLiant DL380 Gen10 Plus',
  'ProLiant DL580 Gen10',
  'ProLiant ML350 Gen10',
  'ProLiant ML110 Gen10'
]

export const dateRanges = {
  today: [dayjs().startOf('day'), dayjs().endOf('day')],
  last_7: [dayjs().subtract(6, 'day').startOf('day'), dayjs().endOf('day')],
  last_30: [dayjs().subtract(29, 'day').startOf('day'), dayjs().endOf('day')],
  last_90: [dayjs().subtract(89, 'day').startOf('day'), dayjs().endOf('day')]
}

export const allowCaseCreation = (event) =>
  ![
    'pendingCaseCreation',
    'pendingEscalation',
    'escalated',
    'correlated'
  ].includes(event.status?.currentState || '') && !event.escalation?.[0]

export const roundTime = (time, scale) => {
  const diffToStart = time.diff(time.startOf(scale))
  const diffToEnd = time.endOf(scale).diff(time)
  if (diffToEnd < diffToStart) {
    return time.add(1, scale).startOf(scale)
  }
  return time.startOf(scale)
}
