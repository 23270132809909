/* eslint-disable no-unused-vars */
import React, { useState, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { FormField, Select, Box, CheckBox, Anchor } from 'grommet'
import { StatusGoodSmall } from 'grommet-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  validatePhoneNumber,
  validateZipCode
} from '../../utils/validation-utils'
import { Typography } from '../shims/imports'
import { HPECC, BACKUP_AND_RECOVERY, VCF } from '../constants'
import { useAxiosAuth } from '../hooks'
import {
  ModalDialog,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  CCSForm,
  Loader,
  DynamicFormContent,
  FormInput,
  List
} from '../../components'

const RequestEvalModal = ({
  evalServiceData,
  setEvalModalFormOpen,
  setSuccessEval,
  evalFieldsMetaData,
  formLoader,
  countries,
  setCountries,
  listOfCountries
}) => {
  const LDFlags = useFlags()
  const serviceRegistryFlag = LDFlags['glcp-service-registry']
  const axios = useAxiosAuth()
  const { t } = useTranslation(['common', 'dashboard', 'manage', 'authn'])
  const { oidcUser } = useReactOidc()
  const [errorMessage, setErrorMessage] = useState('')
  const [errorField, setErrorField] = useState({})
  const [checked, setChecked] = useState(false)
  const showServerFamily = evalServiceData.slug === HPECC
  const serverFamily = [
    { id: 'PROLIANT', value: 'HPE Alletra 4000' },
    { id: 'ALLETRA_4K', value: 'HPE ProLiant' }
  ]
  const acceptTermsUrl = {
    [HPECC]: 'https://www.hpe.com/psnow/doc/a50009054ENW',
    [BACKUP_AND_RECOVERY]:
      'https://www.hpe.com/psnow/doc/a50005384enw.pdf?jumpid=in_pdfviewer-psnow',
    [VCF]: 'https://www.hpe.com/psnow/doc/a50009420ENW'
  }
  const [loader, setLoader] = useState(false)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)

  const PHONE = 'EvalRequest.PhoneNumber'
  const COMPANY = 'EvalRequest.company'
  const ADDRESS = 'EvalRequest.Address'
  const CITY = 'EvalRequest.City'
  const ZIP = 'EvalRequest.Zip'
  const STATE = 'EvalRequest.StateOrProvinceCode'
  const EMAIL = 'EvalRequest.email'
  const COUNTRY = 'EvalRequest.Country'

  const handleTerms = (value) => {
    setChecked(value)
    setErrorMessage('')
  }
  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value }
        default:
          return currentState
      }
    },
    {
      firstName: oidcUser.profile.given_name,
      lastName: oidcUser.profile.family_name,
      emailAddress: '',
      company: '',
      address: '',
      city: '',
      state: '',
      country: countries[0]?.code,
      zip: '',
      acceptTerms: checked,
      region: serviceRegistryFlag
        ? evalServiceData.regions?.at(0)?.code
        : evalServiceData.regions?.at(0)?.id,
      service: evalServiceData.slug,
      serverFamily: showServerFamily ? serverFamily[0].id : null,
      phoneNumber: ''
    }
  )

  const postEvalRequest = () => {
    setLoader(true)
    setErrorMessage('')
    const reqBody = state

    axios.post(`/billing/v1alpha1/eval`, reqBody).then(
      () => {
        setLoader(false)
        setEvalModalFormOpen(false)
        setSuccessEval(true)
      },
      (error) => {
        const errorObject = error.response.data
        const errorSubject =
          errorObject?.errorDetails?.at(0)?.issues?.at(0)?.subject || ''

        const evalErrorMessage =
          errorObject?.errorDetails?.at(0)?.issues?.at(0)?.description || ''

        const errFieldArray = [
          EMAIL,
          COMPANY,
          ADDRESS,
          PHONE,
          CITY,
          COUNTRY,
          ZIP,
          STATE
        ]
        setSubmitButtonDisabled(true)
        if (errFieldArray.includes(errorSubject)) {
          setErrorField({
            field: errorSubject,
            message: evalErrorMessage
          })
        } else {
          setErrorMessage(errorObject?.message || '')
        }
        setLoader(false)
      }
    )
  }
  const footerButtons = (
    <ModalFooter
      left={
        <ButtonGroup
          buttonList={[
            {
              label: t('dashboard:eval.request_eval'),
              primary: true,
              testId: 'confirm-action-btn',
              isLoading: loader,
              type: 'submit',
              disabled: submitButtonDisabled
            },
            {
              label: t('dashboard:common.cancel'),
              onClick: () => {
                setEvalModalFormOpen(false)
              },
              testId: 'cancel-btn',
              type: 'reset'
            }
          ]}
          testId="action-confirmation"
        />
      }
    />
  )

  return (
    <ModalDialog
      onClose={() => {}}
      header={
        <Box pad={{ right: 'medium' }}>
          <ModalHeader
            title={
              <Typography
                level="1"
                style={{ whiteSpace: 'nowrap' }}
                testId="header-title"
                type="heading"
              >
                {t('dashboard:eval.request_eval')}
              </Typography>
            }
            onClose={() => {
              setEvalModalFormOpen(false)
            }}
          />
          <Box pad={{ top: 'small', bottom: 'small' }} width="medium">
            <Typography level="2" testId="t12" type="heading" weight={400}>
              {t('dashboard:eval.eval_service_name', {
                serviceName: evalServiceData.name
              })}
            </Typography>
          </Box>
        </Box>
      }
      content={
        formLoader ? (
          <Box margin="medium" align="center" justify="center">
            <Loader />
          </Box>
        ) : (
          <CCSForm
            value={state}
            testId="eval-from"
            errorMessage={errorMessage}
            onSubmit={postEvalRequest}
            validate="submit"
            buttons={footerButtons}
          >
            {Object.keys(evalFieldsMetaData).length > 0 ? (
              <>
                <DynamicFormContent
                  translation={t}
                  schema={evalFieldsMetaData}
                  formData={state}
                  onChange={(key, value) =>
                    dispatch({ type: 'CHANGE_FIELD', field: key, value })
                  }
                  apiErrors={errorField}
                  setSubmitDisabled={setSubmitButtonDisabled}
                />
              </>
            ) : (
              <>
                <Box margin={{ top: 'small' }} width="medium">
                  <Typography
                    style={{ maxHeight: 'large' }}
                    testId="dialog-content"
                    type="text"
                  >
                    {t(
                      `dashboard:eval.evaluation_intro_text_${evalServiceData.slug}`,
                      {
                        serviceName: evalServiceData.name
                      }
                    )}
                  </Typography>
                  <Box margin={{ top: '15px' }}>
                    <Anchor
                      target="_blank"
                      href={evalServiceData.documentationUrl}
                      label={t('dashboard:eval.view_documentation')}
                    />
                  </Box>
                </Box>
                <Box margin={{ top: 'small' }} width="medium">
                  <Typography level="3" testId="t3" type="heading">
                    {t('dashboard:eval.prerequisites')}
                  </Typography>
                  <Typography
                    style={{ maxHeight: 'large' }}
                    testId="dialog-content"
                    type="text"
                  >
                    {t(
                      `dashboard:eval.prereq_details_${evalServiceData.slug}`,
                      {
                        serviceName: evalServiceData.name
                      }
                    )}
                  </Typography>
                </Box>
                <Box margin={{ top: 'small' }} width="medium">
                  <Typography level="3" testId="t3" type="heading">
                    {t('dashboard:eval.contact_info')}
                  </Typography>
                </Box>
                <Box width="medium">
                  <FormInput
                    disabled
                    inputType="text"
                    placeholder={oidcUser.profile.given_name}
                    label={t('dashboard:eval.first_name')}
                    name="form-disabled-text-input"
                    testId="form-disabled-text-input"
                  />
                  <FormInput
                    disabled
                    inputType="text"
                    placeholder={oidcUser.profile.family_name}
                    label={t('dashboard:eval.last_name')}
                    name="form-disabled-text-input"
                    testId="form-disabled-text-input"
                  />
                </Box>
                <Box width="medium">
                  <FormInput
                    label={t('dashboard:eval.business_email')}
                    name="emailAddress"
                    testId="email-field"
                    value={state.emailAddress}
                    inputType="text"
                    required
                    disabled={loader}
                    error={errorField.field === EMAIL && errorField.message}
                    onChange={(option) => {
                      setSubmitButtonDisabled(false)
                      dispatch({
                        value: option.target.value,
                        field: 'emailAddress',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                    placeholder="user@hpe.com"
                  />
                  <FormInput
                    label={t('dashboard:eval.company_name')}
                    name="company"
                    testId="company-field"
                    value={state.company}
                    inputType="text"
                    error={errorField.field === COMPANY && errorField.message}
                    required
                    disabled={loader}
                    labelHelper={t('dashboard:eval.company_help')}
                    onChange={(option) => {
                      setSubmitButtonDisabled(false)
                      dispatch({
                        value: option.target.value,
                        field: 'company',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                  />

                  <FormInput
                    label={t('dashboard:eval.address')}
                    name="address"
                    testId="company-address-field"
                    inputType="text"
                    value={state.address}
                    required
                    error={
                      errorField.field === ADDRESS && (
                        <Box background="white">
                          <Typography size="xsmall" testId="t3" type="text">
                            <>
                              {t('dashboard:eval.address_error_msg', {
                                field: errorField.message
                              })}
                              <Anchor
                                label={t(
                                  'dashboard:eval.submit_support_ticket'
                                )}
                                size="xsmall"
                                weight={500}
                                href="https://h41390.www4.hpe.com/support/index.html?form=osqbm"
                                target="_blank"
                                testId="address_error"
                              />
                            </>
                          </Typography>
                        </Box>
                      )
                    }
                    labelHelper={t('dashboard:eval.address_help')}
                    disabled={loader}
                    onChange={(option) => {
                      setSubmitButtonDisabled(false)
                      dispatch({
                        value: option.target.value,
                        field: 'address',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                  />
                  <FormInput
                    label={t('dashboard:eval.city')}
                    name="city"
                    testId="city-field"
                    inputType="text"
                    value={state.city}
                    error={errorField.field === CITY && errorField.message}
                    required
                    disabled={loader}
                    onChange={(option) => {
                      setSubmitButtonDisabled(false)
                      dispatch({
                        value: option.target.value,
                        field: 'city',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                  />

                  <FormInput
                    label={t('dashboard:eval.state_province_code')}
                    name="state"
                    testId="state-field"
                    value={state.state}
                    required
                    disabled={loader}
                    error={errorField.field === STATE && errorField.message}
                    onChange={(option) => {
                      setSubmitButtonDisabled(false)
                      dispatch({
                        value: option.target.value,
                        field: 'state',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                  />
                  <FormInput
                    label={t('authn:customer_account.zip')}
                    name="zip"
                    testId="zip-field"
                    value={state.zip}
                    inputType="text"
                    required
                    disabled={loader}
                    validate={(value) => {
                      if (!validateZipCode(value))
                        return t('authn:customer_account.max_chars_exceeded')
                      return true
                    }}
                    error={errorField.field === ZIP && errorField.message}
                    onChange={(option) => {
                      setSubmitButtonDisabled(false)
                      dispatch({
                        value: option.target.value,
                        field: 'zip',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                  />
                  <FormField
                    label={t('dashboard:eval.country')}
                    name="country_code"
                    testId="country-field"
                    data-testid="sign-up-country-form-field"
                    error={errorField.field === COUNTRY && errorField.message}
                  >
                    <Select
                      name="country_code"
                      placeholder={t('customer_account.country')}
                      options={countries}
                      multiple={false}
                      labelKey="name"
                      value={state.country || ''}
                      valueKey={{ key: 'code', reduce: true }}
                      searchPlaceholder={t('authn:customer_account.country')}
                      emptySearchMessage={t('dashboard:eval.no_country_error')}
                      onSearch={(searchText) => {
                        const regexp = new RegExp(searchText, 'i')
                        setCountries(
                          listOfCountries.filter((o) => o.name.match(regexp))
                        )
                      }}
                      onChange={({ option }) => {
                        dispatch({
                          value: option.code,
                          field: 'country',
                          type: 'CHANGE_FIELD'
                        })
                      }}
                      onClose={() => setCountries(listOfCountries)}
                    />
                  </FormField>
                  <FormInput
                    label={t('manage:org_workspace.phone_number')}
                    name="phoneNumber"
                    testId="phone-number"
                    value={state.phoneNumber}
                    inputType="text"
                    required
                    disabled={loader}
                    validate={(value) => {
                      if (!validatePhoneNumber(value)) {
                        return t('dashboard:eval.phone_number_error')
                      }
                      return true
                    }}
                    error={errorField.field === PHONE && errorField.message}
                    onChange={(option) => {
                      setSubmitButtonDisabled(false)
                      dispatch({
                        value: option.target.value,
                        field: 'phoneNumber',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                  />
                  {showServerFamily && (
                    <FormField
                      label={t('dashboard:eval.server_family')}
                      name="server-family"
                    >
                      <Select
                        name="server_family"
                        placeholder="Select"
                        options={serverFamily}
                        defaultValue={serverFamily[0].id}
                        multiple={false}
                        value={state.serverFamily}
                        onChange={({ option }) => {
                          dispatch({
                            value: option.id,
                            field: 'serverFamily',
                            type: 'CHANGE_FIELD'
                          })
                        }}
                      />
                    </FormField>
                  )}
                  <Box margin={{ top: '15px' }}>
                    <Typography level="3" testId="t3" type="heading">
                      {t('dashboard:eval.deployment')}
                    </Typography>
                    <Typography
                      style={{ maxHeight: 'large', overflow: 'auto' }}
                      testId="dialog-content"
                      type="text"
                    >
                      {t('dashboard:eval.select_region')}
                    </Typography>
                  </Box>
                  <FormField
                    label={t('dashboard:common.deployment_region')}
                    name="deployment-region"
                  >
                    <Select
                      name="deployment-regions"
                      placeholder="Select"
                      options={evalServiceData.regions}
                      multiple={false}
                      value={state.region}
                      labelKey="name"
                      valueKey={
                        serviceRegistryFlag
                          ? { key: 'code', reduce: true }
                          : { key: 'id', reduce: true }
                      }
                      onChange={({ option }) => {
                        dispatch({
                          value: serviceRegistryFlag ? option.code : option.id,
                          field: 'region',
                          type: 'CHANGE_FIELD'
                        })
                      }}
                    />
                  </FormField>
                  <FormField testId="app-terms-checkbox-label">
                    <CheckBox
                      required
                      name="app_terms"
                      checked={checked}
                      label={
                        <Typography type="text" size="medium">
                          <Trans
                            i18nKey="apps:terms_of_service_confirm_text"
                            t={t}
                          >
                            <Anchor
                              label={t('apps:terms_of_service')}
                              weight={500}
                              href={acceptTermsUrl[evalServiceData.slug]}
                              target="_blank"
                              testId="app-terms-anchor"
                            />
                          </Trans>
                        </Typography>
                      }
                      onChange={(event) => {
                        handleTerms(event.target.checked)
                        dispatch({
                          value: event.target.checked,
                          field: 'acceptTerms',
                          type: 'CHANGE_FIELD'
                        })
                      }}
                    />
                  </FormField>
                </Box>
                <Box
                  margin={{ top: '15px' }}
                  width="medium"
                  pad={{ bottom: 'small' }}
                >
                  <Typography level="3" testId="t3" type="heading">
                    {t('dashboard:eval.next_steps_title')}
                  </Typography>
                  <List
                    testId="eval-list"
                    customRender={(datum) => (
                      <Box direction="row">
                        <Box pad={{ top: 'small' }} margin={{ right: 'small' }}>
                          <StatusGoodSmall size="6px" />
                        </Box>
                        <Box>
                          <Typography
                            type="text"
                            size="medium"
                            testId="bulleted-list-value"
                          >
                            {datum}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    data={
                      evalServiceData.slug === HPECC
                        ? [
                            t('dashboard:eval.eval_step_1'),
                            t('dashboard:eval.eval_step_2')
                          ]
                        : [t('dashboard:eval.eval_step_1')]
                    }
                  />
                </Box>
              </>
            )}
          </CCSForm>
        )
      }
      height="100%"
      position="right"
      testId="side-drawer-dialog"
    />
  )
}

RequestEvalModal.propTypes = {
  evalServiceData: PropTypes.object.isRequired,
  setEvalModalFormOpen: PropTypes.func.isRequired,
  setSuccessEval: PropTypes.func.isRequired,
  evalFieldsMetaData: PropTypes.object.isRequired,
  formLoader: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  setCountries: PropTypes.any.isRequired,
  listOfCountries: PropTypes.array.isRequired
}
export default RequestEvalModal
