import React, { useState, useReducer, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Box, FormField, TextInput } from 'grommet'
import PropTypes from 'prop-types'

import { Typography, CCSForm } from '../../../components'
import { getErrorMessage, get, post } from '../../../utils/api-utils'
import {
  confirmPasswordsMatch,
  getPassowrdGuideLinesMessage,
  passwordValidation
} from '../../../utils/validation-utils'
import ProfileButtons from '../ProfileButtons'

import SuccessModal from './SuccessModal'

const initialState = {
  current_password: '',
  new_password: '',
  confirm_password: '',
  edited: false
}

const ResetPassword = ({ fromProfile }) => {
  const { oidcUser } = useReactOidc()
  const [verifyError, setVerifyError] = useState('')
  const { t } = useTranslation(['common'])
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [passwordGuideLines, setPasswordGuideLines] = useState({})
  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          setUpdateSuccess(false)
          return { ...currentState, [action.field]: action.value, edited: true }
        case 'RESET_STATE':
          return {
            ...initialState
          }
        default:
          return currentState
      }
    },
    {
      ...initialState
    }
  )

  const handleDiscardChangesClick = () => {
    dispatch({
      type: 'RESET_STATE'
    })
  }

  useEffect(() => {
    get(
      '/accounts/ui/v1/cop-auth/local-authentication',
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        setPasswordGuideLines(response?.data)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setVerifyError(backendErrorMessage)
      }
    )
  }, [oidcUser.access_token, t])

  const sendPasswordAndVerify = () => {
    post(
      '/accounts/ui/v1/user/password-change',
      {
        old_password: state.current_password,
        new_password: state.new_password
      },
      oidcUser.access_token
    ).then(
      () => {
        setVerifyError('')
        dispatch({ type: 'RESET_STATE' })
        setUpdateSuccess(true)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setVerifyError(backendErrorMessage)
      }
    )
  }

  const resetPasswordPage = (
    <Box
      width="medium"
      pad={{ bottom: 'large' }}
      margin={{ top: 'small', left: 'small' }}
    >
      <Typography
        level="2"
        type="heading"
        weight="normal"
        testId="password-title"
      >
        {fromProfile ? t('password') : t('password_expired_warning')}
      </Typography>
      <Typography
        size="medium"
        type="text"
        weight="normal"
        testId="password-subtitle"
      >
        {t('common:reset_password')}
      </Typography>
      {Object.keys(passwordGuideLines).length ? (
        <Box margin={{ top: 'small' }}>
          <Typography
            size="medium"
            type="text"
            weight="normal"
            testId="password-description"
          >
            {getPassowrdGuideLinesMessage(passwordGuideLines, t)}
          </Typography>
        </Box>
      ) : null}
      <CCSForm
        value={state}
        testId="update-password-form"
        validate="blur"
        onSubmit={sendPasswordAndVerify}
        onReset={handleDiscardChangesClick}
        onValidate={() => setUpdateSuccess(false)}
        errorMessage={verifyError}
        buttons={
          <ProfileButtons updateLabel="Update Password" edited={state.edited} />
        }
      >
        <Box pad={{ top: 'medium' }}>
          <FormField
            margin={{ bottom: 'medium' }}
            label={t('current_password')}
            name="current_password"
            data-testid="current-password-form-field"
            required
          >
            <TextInput
              type="password"
              name="current_password"
              onChange={(event) =>
                dispatch({
                  value: event.target.value,
                  field: 'current_password',
                  type: 'CHANGE_FIELD'
                })
              }
              data-testid="current_password"
            />
          </FormField>
          <FormField
            margin={{ bottom: 'medium' }}
            label={t('new_password')}
            name="new_password"
            data-testid="new-password-form-field"
            required
            validate={
              passwordGuideLines.password_length ? null : passwordValidation(t)
            }
          >
            <TextInput
              type="password"
              name="new_password"
              onChange={(event) =>
                dispatch({
                  value: event.target.value,
                  field: 'new_password',
                  type: 'CHANGE_FIELD'
                })
              }
              data-testid="new_password"
            />
          </FormField>
          <FormField
            margin={{ bottom: 'medium' }}
            label={t('confirm_password')}
            name="confirm_password"
            data-testid="confirm-password-form-field"
            validate={() =>
              confirmPasswordsMatch(
                state.new_password,
                state.confirm_password,
                t
              )
            }
            required
          >
            <TextInput
              type="password"
              name="confirm_password"
              onChange={(event) =>
                dispatch({
                  value: event.target.value,
                  field: 'confirm_password',
                  type: 'CHANGE_FIELD'
                })
              }
              data-testid="confirm_password"
            />
          </FormField>
          {updateSuccess && (
            <SuccessModal setUpdateSuccess={setUpdateSuccess} />
          )}
        </Box>
      </CCSForm>
    </Box>
  )
  return fromProfile ? (
    resetPasswordPage
  ) : (
    <Box justify="center" align="center">
      {resetPasswordPage}
    </Box>
  )
}

ResetPassword.propTypes = {
  fromProfile: PropTypes.bool
}
ResetPassword.defaultProps = {
  fromProfile: false
}

export default ResetPassword
