import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  Button
} from '../../../../../../../../components'

const FailedToRetrieveRatesModal = ({ onSetOpen }) => {
  const { t } = useTranslation(['common', 'device'])
  return (
    <ModalDialog
      width="medium"
      testId="export-csv-error-dialog"
      content={
        <Box direction="column" gap="small">
          <Typography
            type="heading"
            level={2}
            testId="retrieve-rates-error-header"
          >
            {t('device:showback_rates.manage_rates_action_label')}
          </Typography>
          <Typography
            type="text"
            size="medium"
            testId="retrieve-rates-error-content"
          >
            {t('device:showback_rates.manage_rates_failed_to_get_rates')}
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <Box direction="row" gap="medium">
              <Button
                testId="retrieve-rates-error-close-btn"
                label={t('common:close')}
                primary
                onClick={() => onSetOpen(false)}
              />
            </Box>
          }
        />
      }
      onClose={() => onSetOpen(false)}
    />
  )
}

FailedToRetrieveRatesModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired
}

export default FailedToRetrieveRatesModal
