import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { Box, Text } from 'grommet'
import lodashGet from 'lodash/get'

import { Button, Typography } from '../../../../../../../../components'
import { WizardContext } from '../../../../../../../../components/wizard/WizardContext'
import {
  StyledFormField,
  HiddenInput,
  defaultFieldsError
} from '../../utils/page-utils'
import { verifyFile } from '../../../../../../../manage-account/authentication/saml-sso/sso-connection/steps/utils/page-utils'

export const MetadataFileLeftColumn = ({ fieldsError, setFieldsError }) => {
  const { formValues, setFormValues, setAttemptedAdvance, setFormError } =
    useContext(WizardContext)
  const { t } = useTranslation(['iam'])
  const [isDragOver, setIsDragOver] = useState(false)

  const api = '/identity/v1alpha1/sso-profiles/metadata'

  const clearSelectedFile = () => {
    setFormValues({
      ...formValues,
      saml_idp_config: {
        ...formValues.saml_idp_config,
        entity_id: '',
        login_url: '',
        logout_url: '',
        signing_certificate: '',
        file_info: {}
      }
    })
    setFieldsError({ ...fieldsError })
  }
  const handleDrop = useCallback(
    (event) => {
      event.preventDefault()
      setIsDragOver(false)
      const file = event.dataTransfer.files[0]
      if (file) {
        verifyFile(
          { target: { files: [file] } },
          fieldsError,
          setFieldsError,
          formValues,
          setFormValues,
          setAttemptedAdvance,
          setFormError,
          t,
          null,
          api,
          defaultFieldsError
        )
      }
    },
    [
      fieldsError,
      formValues,
      setAttemptedAdvance,
      setFieldsError,
      setFormError,
      setFormValues,
      t
    ]
  )

  const handleDragOver = (event) => {
    event.preventDefault()
    setIsDragOver(true)
  }

  const handleDragLeave = () => {
    setIsDragOver(false)
  }

  return (
    <Box align="start" margin={{ top: 'medium' }}>
      {/* TODO: CCS-2882 - Change to use Grommet FileInput once it's supported */}
      <Box data-testid="upload-file-form-field-label">
        <Text size="xsmall">{`${t(
          'iam:sso_profiles.view_edit_create.metadata_file'
        )}*`}</Text>
      </Box>
      <StyledFormField
        htmlFor="upload-file"
        label={t('iam:sso_profiles.view_edit_create.max_file_size')}
        isRequired
        error={fieldsError.metadataFile}
        data-testid="upload-file-form-field"
      >
        <Box
          width="xlarge"
          pad={{ horizontal: 'small' }}
          border={{
            color: isDragOver ? 'brand' : 'border',
            size: 'small',
            style: 'dashed',
            side: 'all'
          }}
          round="xsmall"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          background={isDragOver ? 'light-2' : 'transparent'}
        >
          {!lodashGet(formValues, 'saml_idp_config.file_info.name') ? (
            <>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'stretch'
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexGrow: '1',
                    justifyContent: 'center'
                  }}
                  data-testid="drag-and-drop-field"
                >
                  {t('iam:sso_profiles.view_edit_create.drag_and_drop')}
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    default
                    label={t('iam:sso_profiles.view_edit_create.select_file')}
                    testId="upload-button"
                    onClick={() => {
                      document.getElementById('upload-file').click()
                    }}
                    style={{ flexGrow: 0 }}
                  />
                </Box>
              </Box>
              <HiddenInput
                data-cy="hidden-input"
                onChange={(e) => {
                  verifyFile(
                    e,
                    fieldsError,
                    setFieldsError,
                    formValues,
                    setFormValues,
                    setAttemptedAdvance,
                    setFormError,
                    t,
                    null,
                    api,
                    defaultFieldsError
                  )
                }}
              />
            </>
          ) : (
            <>
              <Box justify="between" direction="row" align="center">
                <Box direction="row" gap="xsmall">
                  <Typography
                    type="text"
                    weight="bold"
                    testId="file-name"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {lodashGet(formValues, 'saml_idp_config.file_info.name')}
                  </Typography>
                </Box>
                <Button
                  onClick={clearSelectedFile}
                  label={t('iam:sso_profiles.view_edit_create.replace_file')}
                  testId="delete-file-button"
                />
              </Box>
            </>
          )}
        </Box>
      </StyledFormField>
    </Box>
  )
}

MetadataFileLeftColumn.propTypes = {
  fieldsError: PropTypes.shape({
    metadataFile: PropTypes.string.isRequired
  }).isRequired,
  setFieldsError: PropTypes.func.isRequired
}

export default MetadataFileLeftColumn
