import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Anchor, Box, Text } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  isMSPTenantCoP,
  isCoP,
  isGLOP
} from '../../../../../../utils/feature-flag-utils'
import { post } from '../../../../../../utils/api-utils'
import { getApiErrorMessage } from '../../../../../../utils/error-handling-utils'
import { validLicenseKey } from '../../../../../../utils/validation-utils'
import {
  AssignTags,
  validateAssignTags
} from '../../../../../device-management/add-devices/steps/AssignTags'
import { ReviewAddSubscriptionWizard } from '../../../../../device-management/add-device-subscriptions/steps/ReviewAddSubscriptionWizard'
import { Typography, Wizard } from '../../../../../../components'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  checkDelayedActivationStatus,
  checkDuplicateWarningStatus,
  checkOnpremWorkspace
} from '../../../utils'

import {
  AddShowbackRateStep,
  convertToCreatingRatesPayload,
  useHasShowbackRatesPermission,
  checkRatesAvailability,
  getShowBackRatesStep
} from './showbackRate'
import { AddServiceSubscriptionStep } from './AddServiceSubscriptionStep'
import OnpremInfoStep, { validateOnpremInfo } from './OnpremInfoStep'

const AddServiceSubscriptionWizard = () => {
  const [tagsReqStatus, setTagsReqStatus] = useState(false)
  const [ratesReqStatus, setRatesReqStatus] = useState(false)
  const [hasTagsStep, setHasTagsStep] = useState(false)
  const [isOnpremWorkspace, setIsOnpremWorkspace] = useState(false)
  const [hasRatesStep, setHasRatesStep] = useState(false)
  const [isRatesStepAvailable, setIsRatesStepAvailable] = useState(null)
  const [ratesOrderData, setRatesOrderData] = useState(null)
  const { t } = useTranslation(['device', 'common', 'licensing'])
  const history = useHistory()
  const {
    'glcp-saas-delay-activation': delayedActivationSaaSFlag,
    'glcp-saas-duplicate-warning': duplicateWarningSaaSFlag,
    'glcp-subscrption-tags': subscriptionTagsFlag,
    'glcp-cds-rate-interface': showbackRateLDFlag,
    'glcp-dscc-dm-sm': dsccDmSmFlag
  } = useFlags()
  const { oidcUser } = useReactOidc()
  const hasRatesPermission = useHasShowbackRatesPermission(true)
  const handleRedirection = () => {
    if ((hasTagsStep && !tagsReqStatus) || (hasRatesStep && !ratesReqStatus)) {
      return
    }
    history.push({
      pathname: '/services/service-subscriptions',
      addSubsrciptionSucccess: true
    })
  }
  const resetTagsAndRatesSteps = () => {
    setRatesReqStatus(false)
    setTagsReqStatus(false)
    setHasRatesStep(false)
    setHasTagsStep(false)
  }

  const isShowbackRatesVisible = useMemo(() => {
    return !isMSPTenantCoP() && !isGLOP() && showbackRateLDFlag
  }, [showbackRateLDFlag])

  useEffect(() => {
    if (tagsReqStatus || ratesReqStatus) {
      handleRedirection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsReqStatus, ratesReqStatus])

  const addingShowbackRatesToSubscription = (
    ratesPayload,
    subscriptionKey,
    setFormError
  ) => {
    post(
      `/cds/v1/rates/${subscriptionKey}`,
      ratesPayload,
      oidcUser.access_token
    ).then(
      () => {
        setRatesReqStatus(true)
      },
      (error) => {
        setFormError(
          getApiErrorMessage(
            error,
            t,
            t('showback_rates.wizard_msg_failed_to_save')
          )
        )
        resetTagsAndRatesSteps()
      }
    )
  }

  const addSubscription = (formValues, setFormError) => {
    const hasTagsToAssign =
      formValues?.tagsAssigned?.length && subscriptionTagsFlag
    post(
      `/ui-doorway/ui/v1/license/${formValues.subscriptionKey}`,
      {
        tagsSummary: formValues.tagsAssigned,
        onPremWorkspaceId: formValues.workspaceId,
        onPremWorkspaceName: formValues.workspaceName
      },
      oidcUser.access_token
    ).then(
      () => {
        const hasRatesToAdd =
          formValues.isRatesStepOn &&
          formValues?.ratesPayload?.pricePlans &&
          isShowbackRatesVisible
        if (hasTagsToAssign) {
          setHasTagsStep(true)
        }
        if (hasRatesToAdd) {
          setHasRatesStep(true)
          addingShowbackRatesToSubscription(
            convertToCreatingRatesPayload(formValues?.ratesPayload),
            formValues.subscriptionKey,
            setFormError
          )
        }
        if (!hasRatesToAdd) {
          history.push({
            pathname: '/services/service-subscriptions',
            addSubsrciptionSucccess: true
          })
        }
      },
      (error) => {
        setFormError(getApiErrorMessage(error, t))
        resetTagsAndRatesSteps()
      }
    )
  }

  const handleFinish = (formValues, setFormError) => {
    return addSubscription(formValues, setFormError)
  }

  const switchRatesStepStatus = (isRatesAvailable) => {
    if (isShowbackRatesVisible && hasRatesPermission) {
      setIsRatesStepAvailable(isRatesAvailable)
    }
  }

  const validateSubscriptionKey = (formValues) => {
    const { subscriptionKey } = formValues
    if (!validLicenseKey(subscriptionKey)) {
      return Promise.resolve(new Error(t('invalid_key_format')))
    }
    let isduplicatePromise = Promise.resolve(false)
    let isdelayedPromise = Promise.resolve(false)
    let isOnpremWorkspacePromise = Promise.resolve(false)
    let isRatesAvailablePromise = Promise.resolve(
      isShowbackRatesVisible ? !!ratesOrderData : true
    )
    if (duplicateWarningSaaSFlag) {
      isduplicatePromise = checkDuplicateWarningStatus(
        oidcUser,
        subscriptionKey,
        t
      )
    }
    if (delayedActivationSaaSFlag) {
      isdelayedPromise = checkDelayedActivationStatus(
        oidcUser,
        subscriptionKey,
        t
      )
    }
    if (dsccDmSmFlag) {
      isOnpremWorkspacePromise = checkOnpremWorkspace(
        oidcUser,
        subscriptionKey,
        t
      )
    }
    if (isShowbackRatesVisible && hasRatesPermission) {
      isRatesAvailablePromise = checkRatesAvailability(
        subscriptionKey,
        oidcUser.access_token,
        setRatesOrderData
      )
    }

    return Promise.allSettled([
      isduplicatePromise,
      isdelayedPromise,
      isOnpremWorkspacePromise,
      isRatesAvailablePromise
    ]).then(
      ([isDuplicateObj, isDelayedObj, isOnpremObj, isRatesAvailableObj]) => {
        if (
          isDuplicateObj?.status === 'fulfilled' &&
          isDelayedObj?.status === 'fulfilled' &&
          isOnpremObj?.status === 'fulfilled' &&
          isRatesAvailableObj?.status === 'fulfilled'
        ) {
          const isDuplicate = isDuplicateObj?.value?.isDuplicate
          const isAlreadyClaimed = isDuplicateObj?.value?.alreadyClaimed
          const isDelayed = isDelayedObj?.value
          const isRatesAvailable = isRatesAvailableObj?.value
          const isOnpremWorkspaceVal = isOnpremObj?.value?.is_glp_onprem_sku
          setIsOnpremWorkspace(isOnpremWorkspaceVal)
          switchRatesStepStatus(isRatesAvailable)
          if (isAlreadyClaimed) {
            return Error(isAlreadyClaimed)
          }
          if (!isDuplicate && !isDelayed && !isAlreadyClaimed) {
            return null
          }
          if (isDuplicate && isDelayed) return Error('BOTH')
          return new Error(
            isDelayed ? 'DELAYED_ACTIVATION' : 'SUBSCRIPTION_KEY_CLAIMED'
          )
        }
        if (dsccDmSmFlag && isOnpremObj?.reason) {
          return new Error(isOnpremObj?.reason?.message)
        }
        if (isDuplicateObj?.reason) {
          return new Error(isDuplicateObj?.reason?.message)
        }
        return new Error(isDelayedObj?.reason?.message)
      }
    )
  }

  const wizardButtons = {
    next: {
      submitForm: true
    },
    cancel: {
      label: t('cancel')
    }
  }

  const addSubscriptionWizardRef = useRef()
  const addShowbackRateRef = useRef()

  const showbackRatesStep = (
    <VisibilityWrapper
      rbac={{
        resource: '/ccs/device-management/showback-rate',
        permission: 'ccs.device-showback-rate.manage'
      }}
    >
      <AddShowbackRateStep ref={addShowbackRateRef} />
    </VisibilityWrapper>
  )

  const steps = useMemo(() => {
    return !isCoP()
      ? [
          {
            childComponents: (
              <AddServiceSubscriptionStep
                ref={addSubscriptionWizardRef}
                ratesOrderData={ratesOrderData}
              />
            ),
            disableStepTotal: isShowbackRatesVisible,
            description: t('licensing:add_subscription_desc'),
            title: t('add_service_subscription'),
            showFormError: false,
            validateForm: async (formValues) => {
              return new Promise((res, rej) => {
                validateSubscriptionKey(formValues).then((err) => {
                  const newErr =
                    addSubscriptionWizardRef.current.handleFormValidationError(
                      err
                    )
                  if (newErr) {
                    rej(newErr)
                  } else {
                    res()
                  }
                })
              })
            }
          },
          ...(isOnpremWorkspace
            ? [
                {
                  childComponents: <OnpremInfoStep />,
                  title: t('licensing:onprem_info_step_title'),
                  description: (
                    <Box width="large" pad={{ right: 'medium' }}>
                      <Typography
                        type="text"
                        testId="step-description"
                        size="large"
                      >
                        <Trans i18nKey="licensing:onprem_info_step_desc" t={t}>
                          <Anchor
                            data-testid="onprem-info-step-anchor"
                            label={
                              <Text bold size="large">
                                {t('licensing:onprem_info_step_desc_link')}
                              </Text>
                            }
                            onClick={() =>
                              dispatchEvent(new Event('context-help'))
                            }
                          />
                        </Trans>
                      </Typography>
                    </Box>
                  ),
                  validateForm: async (formValues) => {
                    return new Promise((res, rej) =>
                      validateOnpremInfo(formValues, t).then(
                        () => {
                          res()
                        },
                        () => {
                          rej(new Error(t('licensing:required_fields_error')))
                        }
                      )
                    )
                  },
                  submitFormOnChange: false
                }
              ]
            : []),

          {
            childComponents: <AssignTags />,
            title: t('tags.manage_tag_wizard_title'),
            description: t('tags.manage_tag_wizard_desc'),
            validateForm: async (formValues) => {
              return new Promise((res, rej) =>
                validateAssignTags(formValues, t).then(
                  () => {
                    res()
                  },
                  () => {
                    rej(new Error())
                  }
                )
              )
            },
            submitFormOnChange: false
          },
          ...getShowBackRatesStep(
            isRatesStepAvailable,
            isShowbackRatesVisible,
            showbackRatesStep,
            addShowbackRateRef,
            t
          ),
          {
            childComponents: <ReviewAddSubscriptionWizard />,
            validateForm: async () => {
              return null
            },

            title: t('review_add_service_subscription_title'),
            description: t('review_add_service_subscription_desc'),
            submitFormOnChange: true
          }
        ]
      : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOnpremWorkspace,
    isRatesStepAvailable,
    isShowbackRatesVisible,
    ratesOrderData
  ])

  const showbackRatesDefaultValues = isShowbackRatesVisible
    ? {
        isRatesStepOn: true,
        hasNoExistedRates: null,
        ratesService: '',
        ratesPayload: {},
        ratesData: null,
        ratesErrors: {},
        isRatesStepAvailable: false,
        isLoadingRatesFailed: false
      }
    : {}

  return (
    <Box>
      <Wizard
        testId="add-service-wizard"
        title={t('add_service_subscription')}
        buttonLabels={wizardButtons}
        actionOnExit={() => {
          history.push('/services/service-subscriptions')
        }}
        actionOnSubmit={handleFinish}
        formDefaultValues={{
          subscriptionKey: '',
          deviceList: [],
          tagsAssigned: [],
          selectedApp: null,
          isService: true,
          isOnpremWorkspace: false,
          workspaceId: '',
          workspaceName: '',
          understandDuplicateSubscriptionDisclaimer: false,
          ...showbackRatesDefaultValues
        }}
        cancelStrings={{
          continueLabel: t('common:continue'),
          exitLabel: t('exit'),
          heading: t('exit_without_finishing'),
          text: t('wizard_cancel_modal_desc')
        }}
        steps={steps}
      />
    </Box>
  )
}

export default AddServiceSubscriptionWizard
