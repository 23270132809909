/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Card,
  CardBody,
  Grid,
  Heading,
  ThemeContext,
  Tag
} from 'grommet'
import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Apps, AppsRounded, List } from 'grommet-icons'
import { groupBy } from 'lodash'
import { useHistory } from 'react-router-dom'

import { useServiceRedirect } from '../../../hooks'
import {
  DataTable,
  Typography,
  isGLOP,
  getCustomerAccountType
} from '../../../shims/imports'
import { getRegionName } from '../../../utils'
import { Loader } from '../../../../components'
import VisibilityWrapper from '../../../../pages/commoncomponents/visibility-wrapper/VisibilityWrapper'
import { SERVICES_DISABLE_LAUNCH_FROM_MSP } from '../../../constants'

const TogglableTable = ({
  services,
  servicesWithRegions,
  regionsData,
  regionsLoaded
}) => {
  const accountType = getCustomerAccountType()
  const [servicesToDisplay, setServicesToDisplay] = useState([])
  // TODO: this TogglableTable component should be refactored to use the new service schema,
  //  so it doesn't have to map between legacy => new schema on app launch
  const { t } = useTranslation(['dashboard', 'services'])
  const history = useHistory()
  const {
    global: { colors }
  } = React.useContext(ThemeContext)

  const ServiceLaunchButton = ({ service, region }) => {
    const serviceRedirect = useServiceRedirect()
    const serviceWithRegions = servicesWithRegions.find(
      ({ serviceSlug }) => serviceSlug === service.serviceSlug
    )
    return (
      <span>
        <Button
          secondary
          label={t('common.launch')}
          a11yTitle={`${t('common.launch')} ${service.name}, ${region}`}
          onClick={() =>
            serviceRedirect(serviceWithRegions, region, service.serviceSlug)
          }
        />
      </span>
    )
  }

  ServiceLaunchButton.propTypes = {
    service: PropTypes.object.isRequired,
    region: PropTypes.string.isRequired
  }

  useEffect(() => {
    const servicesToSet = services.filter(
      (service) =>
        service.provision_status === 'PROVISIONED' &&
        service.msp_conversion_status !== 'MSP_CONVERSION_FAILED' &&
        service.msp_conversion_status !== 'MSP_CONVERSION_INITIATED'
    )

    setServicesToDisplay(servicesToSet)
  }, [services])

  const servicesByRegion = useMemo(
    () => groupBy(servicesToDisplay, 'region'),
    [servicesToDisplay]
  )

  const [renderState, setRenderState] = useState('grid')

  const nameColumn = {
    header: 'Name',
    property: 'title',
    render: ({ title }) => (
      <Typography type="text" testId="toggle-table-title" emphasis>
        {title}
      </Typography>
    ),
    sortable: true
  }

  const regionColumn = {
    header: 'Region',
    property: 'region',
    sortable: true
  }

  const typeColumn = {
    header: 'Type',
    property: 'type',
    sortable: true
  }

  const renderTenantOnlySupported = (service) => {
    return accountType === 'MSP' &&
      (service?.tenant_only_supported ||
        SERVICES_DISABLE_LAUNCH_FROM_MSP.includes(service.serviceSlug)) ? (
      <span>
        <Tag
          value={t('dashboard:service_details.enabled_for_customer_workspaces')}
          align="center"
          style={{
            border: '2px solid rgb(1, 169, 130)',
            fontSize: 19,
            color: 'rgb(51, 51, 51)'
          }}
        />
      </span>
    ) : (
      <ServiceLaunchButton service={service} region={service.region} />
    )
  }

  const launchColumn = {
    header: (
      <Box direction="row" justify="end">
        <Button
          tip={t('dashboard:common.switch_to_grid_view')}
          onClick={() => {
            setRenderState('grid')
          }}
          icon={<Apps />}
        />
      </Box>
    ),
    property: 'launchButton',
    sortable: false,
    render: ({ service }) => (
      <Box direction="row" justify="end">
        {servicesWithRegions.length > 0 && renderTenantOnlySupported(service)}
      </Box>
    )
  }

  const tableColumns = isGLOP()
    ? [nameColumn, typeColumn, launchColumn]
    : [nameColumn, regionColumn, typeColumn, launchColumn]

  return (
    <Box>
      {servicesToDisplay.length === 0 && (
        <Box>
          <Box
            direction="column"
            align="center"
            width="50%"
            margin={{ horizontal: 'auto' }}
          >
            <AppsRounded size="large" color={colors.green.dark} />
            <Box pad={{ top: 'small', bottom: 'medium' }}>
              <Typography type="text" size="large" textAlign="center">
                {t('my_services.empty')}
              </Typography>
            </Box>
            <Button
              label={t('my_services.view_catalog')}
              primary
              onClick={() => history.push('/services/service-catalog')}
            />
          </Box>
        </Box>
      )}
      {servicesToDisplay.length > 0 && (
        <Box>
          {renderState === 'grid' && (
            <Box margin={{ vertical: '3px' }} pad={{ horizontal: '3px' }}>
              {Object.entries(servicesByRegion).map(
                ([region, _apps], i, appEntries) => (
                  <Box
                    direction="column"
                    border={{
                      color: 'border-weak',
                      size: 'xsmall',
                      style: appEntries.length - 1 === i ? 'hidden' : 'solid',
                      side: 'bottom'
                    }}
                    key={region}
                    pad={{ top: i > 0 ? 'small' : 'none', bottom: 'medium' }}
                    margin={{ bottom: 'medium' }}
                  >
                    <Box
                      direction="row"
                      justify="between"
                      align="center"
                      margin={{ bottom: 'small' }}
                    >
                      <Heading
                        level={3}
                        margin="none"
                        testId="myservices-region-header"
                      >
                        <VisibilityWrapper hideFor={{ deployment: ['GLOP'] }}>
                          {getRegionName(regionsLoaded, regionsData, region) ??
                            region}
                        </VisibilityWrapper>
                      </Heading>
                      {i === 0 && (
                        <Box direction="row" justify="end">
                          <Button
                            onClick={() => {
                              setRenderState('table')
                            }}
                            tip={t('dashboard:common.switch_to_list_view')}
                            icon={<List />}
                          />
                        </Box>
                      )}
                    </Box>
                    <Grid
                      columns={{
                        count: 'fill',
                        size: ['medium', 'flex']
                      }}
                      gap="small"
                      pad={{ bottom: 'small' }}
                    >
                      {_apps.map((service) => (
                        <Card key={region + service.name} title={service.name}>
                          <CardBody
                            pad="medium"
                            direction="row"
                            align="center"
                            justify="between"
                          >
                            <Box>
                              <Heading level={4} margin="none">
                                {service.name}
                              </Heading>
                              <Typography type="text" testId="service-category">
                                {t(`common.category.${service.category}`)}
                              </Typography>
                            </Box>

                            {servicesWithRegions.length > 0 ? (
                              accountType === 'MSP' &&
                              (service?.tenant_only_supported ||
                                SERVICES_DISABLE_LAUNCH_FROM_MSP?.includes(
                                  service?.slug
                                )) ? (
                                <span>
                                  <Tag
                                    value={t(
                                      'dashboard:service_details.enabled_for_customer_workspaces'
                                    )}
                                    align="center"
                                    style={{
                                      border: '2px solid rgb(1, 169, 130)',
                                      fontSize: 19,
                                      color: 'rgb(51, 51, 51)',
                                      whiteSpace: 'wrap'
                                    }}
                                  />
                                </span>
                              ) : (
                                <ServiceLaunchButton
                                  service={service}
                                  region={region}
                                />
                              )
                            ) : (
                              <Loader testId="loader-spinner" />
                            )}
                          </CardBody>
                        </Card>
                      ))}
                    </Grid>
                  </Box>
                )
              )}
            </Box>
          )}
          {renderState === 'table' && (
            <Box>
              <DataTable
                grid={{
                  columns: tableColumns,

                  data: (() => {
                    const sorted = servicesToDisplay.map((service, index) => {
                      const { name, region, category } = service
                      return {
                        service,
                        name,
                        id: index,
                        title: name,
                        region: t(`dashboard:regions.${region}`),
                        type: t(`common.category.${category}`)
                      }
                    })

                    return sorted.sort((a, b) => {
                      if (a.title < b.title) {
                        return -1
                      }

                      if (a.title > b.title) {
                        return 1
                      }

                      return 0
                    })
                  })(),
                  primaryKey: 'id',
                  verticalAlign: 'middle',
                  border: {
                    header: {
                      color: 'transparent',
                      side: 'bottom',
                      size: 'small'
                    },
                    body: {
                      color: 'border-weak',
                      side: 'bottom',
                      size: 'xsmall'
                    }
                  },
                  pad: {
                    header: { bottom: 'medium' },
                    body: { horizontal: 'none', vertical: 'medium' }
                  }
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

TogglableTable.propTypes = {
  services: PropTypes.array.isRequired,
  servicesWithRegions: PropTypes.array.isRequired,
  regionsLoaded: PropTypes.bool.isRequired,
  regionsData: PropTypes.array.isRequired
}

export default TogglableTable
