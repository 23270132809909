import React from 'react'
import { Box, Text } from 'grommet'
import capitalize from 'lodash-es/capitalize'
import { useTranslation } from 'react-i18next'

import { eventPropType } from '../../utils/data'

const ImpactedResources = ({ event }) => {
  const { t } = useTranslation('unified_wellness')
  const urnSplit = event.asset.urn.split(':')
  let serialNumber = event.asset.urn
  let type
  if (urnSplit.length > 1) {
    serialNumber = urnSplit[urnSplit.length - 1]
    type = capitalize(urnSplit[urnSplit.length - 2])
  }
  // For MVP, impacted resources is always a list of 1.
  const resourcesData = [
    {
      name: event.asset.name,
      serialNumber,
      type,
      service: capitalize(event.glcpAttributes?.serviceName)
    }
  ]
  return (
    <Box data-testid="impacted-resources">
      <Box pad={{ bottom: 'medium' }}>
        <Text size="xlarge" weight="bold">
          {t('details.impacted_resources.impacted_resources')}
        </Text>
      </Box>
      <Box direction="row" gap="xlarge">
        <Box>
          <Box
            data-testid="impacted-resources-name-title"
            pad={{ bottom: 'small' }}
          >
            <Text weight="bold">{t('details.impacted_resources.name')}</Text>
          </Box>
          <Box
            data-testid="impacted-resources-serial-number-title"
            pad={{ bottom: 'small' }}
          >
            <Text weight="bold">
              {t('details.impacted_resources.serial_number')}
            </Text>
          </Box>
          <Box
            data-testid="impacted-resources-type-title"
            pad={{ bottom: 'small' }}
          >
            <Text weight="bold">{t('details.impacted_resources.type')}</Text>
          </Box>
          <Box
            data-testid="impacted-resources-service-title"
            pad={{ bottom: 'small' }}
          >
            <Text weight="bold">{t('details.impacted_resources.service')}</Text>
          </Box>
        </Box>
        <Box>
          <Box pad={{ bottom: 'small' }} data-testid="impacted-resources-name">
            <Text>{resourcesData[0].name || '--'}</Text>
          </Box>
          <Box
            pad={{ bottom: 'small' }}
            data-testid="impacted-resources-serial-number"
          >
            <Text>{resourcesData[0].serialNumber || '--'}</Text>
          </Box>
          <Box pad={{ bottom: 'small' }} data-testid="impacted-resources-type">
            <Text>{resourcesData[0].type || '--'}</Text>
          </Box>
          <Box
            pad={{ bottom: 'small' }}
            data-testid="impacted-resources-service"
          >
            <Text>{resourcesData[0].service || '--'}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

ImpactedResources.propTypes = {
  event: eventPropType.isRequired
}

export default ImpactedResources
