import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionPanel, Box } from 'grommet'
import { Key } from 'grommet-icons'
import PropTypes from 'prop-types'

import { Typography, NoDataInfo, Button } from '../../../../../../components'
import { WizardContext } from '../../../../../../components/wizard/WizardContext'

const ServiceRolePermissions = ({ showPermissionDialog }) => {
  const { t } = useTranslation(['iam'])
  const { formValues, setFormError } = useContext(WizardContext)
  const { application, serviceName, permissions } = formValues

  const getPermissions = () => {
    return permissions?.map((permission, idx) => (
      <Box
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        pad={{ vertical: 'small', horizontal: 'small' }}
        direction="column"
        data-testid="permission-container"
      >
        <Typography type="text">{permission.permission_description}</Typography>
        <Typography type="text">{permission.permission_name}</Typography>
      </Box>
    ))
  }

  const handlePermissionDialog = () => {
    showPermissionDialog(true)
  }

  return (
    <>
      {permissions?.length > 0 ? (
        <Accordion>
          <AccordionPanel
            data-testid="permision-accordion-panel"
            label={
              <Box
                width="large"
                direction="row-responsive"
                align="between"
                justify="between"
                pad={{ left: 'xsmall' }}
                margin={{ vertical: 'medium' }}
              >
                <Typography type="heading" level="3" size="medium">
                  {application ? application?.name : serviceName}
                </Typography>
                <Typography type="text" size="xsmall">
                  {t('iam:roles_permission.permissions_selected_label', {
                    permissionSelectedAmount: `${permissions?.length}`
                  })}
                </Typography>
              </Box>
            }
          >
            {getPermissions()}
          </AccordionPanel>
        </Accordion>
      ) : (
        <Box gap="large">
          <Typography type="heading" level="2" size="medium">
            {t('iam:roles_permission.permission_title')} (0)
          </Typography>
          <Box pad="medium">
            <NoDataInfo
              action={
                <Button
                  label={t('iam:roles_permission.add_permission_title')}
                  onClick={() => {
                    setFormError('')
                    handlePermissionDialog()
                  }}
                  secondary
                  testId="add-permissions-btn"
                />
              }
              icon={<Key size="large" />}
              testId="no-permissions"
              subtitle={t(
                'iam:roles_permission.no_permission_available_subtitle'
              )}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

ServiceRolePermissions.propTypes = {
  showPermissionDialog: PropTypes.func
}

ServiceRolePermissions.defaultProps = {
  showPermissionDialog: () => {}
}

export default ServiceRolePermissions
