import { Box, TextArea } from 'grommet'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { getErrorMessage, patch } from '../../../../../../utils/api-utils'
import {
  ModalHeader,
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  Typography,
  CCSForm
} from '../../../../../../components'

const ToggleGTStatus = ({
  gtState,
  setGTStatus,
  openSuccessModal,
  DeviceData
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const { oidcUser } = useReactOidc()
  const [errorMessage, setErrorMessage] = useState('')
  const [inputText, setInputText] = useState('')
  const myStatus = gtState
    ? { title: 'Enable', subTitle: 'Allow' }
    : { title: 'Disable', subTitle: 'Deny' }
  const handleSubmit = () => {
    const requestBody = {
      devices: [
        {
          serialNumber: DeviceData?.serial_number,
          partNumber: DeviceData?.part_number
        }
      ],
      audit_logs: { description: inputText }
    }
    const operation = gtState ? 'FIRMWARE_LOCK_OVERRIDE' : 'FIRMWARE_LOCK'
    patch(
      `/support-assistant/v1alpha1/firmware-blocked-devices?operation=${operation}`,
      requestBody,
      oidcUser.access_token
    ).then(
      () => {
        setGTStatus(false)
        setErrorMessage('')
        openSuccessModal(gtState)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }

  return (
    <ModalDialog
      height="medium"
      width="medium"
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="2" testId="modal-title">
              {gtState
                ? t('device:global_trade.enable_gt_firmware_status_title')
                : t('device:global_trade.disable_gt_firmware_status_title')}
            </Typography>
          }
        />
      }
      content={
        <Box width="large">
          <Box gap="large" margin={{ top: 'xsmall', bottom: 'medium' }}>
            <Typography
              type="text"
              size="medium"
              testId="override-gt-status-desc-1"
            >
              {gtState
                ? t('device:global_trade.enable_gt_firmware_status_subtitle')
                : t('device:global_trade.disable_gt_firmware_status_subtitle')}
            </Typography>
          </Box>
          <CCSForm testId="enable-access-form" errorMessage={errorMessage}>
            <TextArea
              value={inputText}
              placeholder={t('device:global_trade.gt_placeholder')}
              onChange={(event) => setInputText(event.target.value)}
              rows={7}
            />
          </CCSForm>
          <Box gap="large" margin={{ vertical: 'xsmall' }}>
            <ModalFooter
              right={
                <ButtonGroup
                  buttonList={[
                    {
                      id: 2,
                      label: t('common:cancel'),
                      default: true,
                      testId: 'cancel-btn',
                      onClick: () => setGTStatus(false)
                    },
                    {
                      id: 1,
                      label: myStatus.title,
                      primary: true,
                      testId: 'confirm-btn',
                      onClick: handleSubmit,
                      disabled: inputText === ''
                    }
                  ]}
                  testId="two-buttons"
                />
              }
            />
          </Box>
        </Box>
      }
      testId="disable-ip-access-modal"
    />
  )
}

ToggleGTStatus.propTypes = {
  gtState: PropTypes.bool.isRequired,
  setGTStatus: PropTypes.func.isRequired,
  openSuccessModal: PropTypes.func.isRequired,
  DeviceData: PropTypes.object.isRequired
}

export { ToggleGTStatus }
