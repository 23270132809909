import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Button,
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Notification,
  Typography
} from '../../../../components'
import { del, getErrorMessage } from '../../../../utils/api-utils'

import { pullSSOConfigStatus } from './add-sso-profile/steps/utils/api-utils'

const DeleteSsoProfileModal = ({
  id,
  ssoRoutingRule,
  setShowModal,
  onSuccess
}) => {
  const { t } = useTranslation(['common', 'iam'])
  const [errorMessage, setErrorMessage] = useState('')
  const [deleteLoading, setDeleteLoading] = useState(false)
  const { oidcUser } = useReactOidc()

  const handleSuccess = () => {
    setDeleteLoading(false)
    setErrorMessage('')
    setShowModal(false)
    onSuccess()
  }

  const handleError = (error, domainRemoved) => {
    const errorMsg = getErrorMessage(error, t)
    const message = domainRemoved
      ? t('iam:sso_profiles.delete.sso_profile_remove_err', {
          errorMsg
        })
      : t('iam:sso_profiles.delete.sso_profile_and_domain_remove_failed', {
          errorMsg
        })

    setErrorMessage(message)
    setDeleteLoading(false)
  }

  const deleteSsoProfile = async (domainRemoved) => {
    try {
      const url = `/identity/v1alpha1/sso-profiles/${id}`
      const response = await del(url, {})

      if (response.status === 204) {
        handleSuccess()
      }
    } catch (error) {
      handleError(error, domainRemoved)
    }
  }

  const deleteRoutingRule = async () => {
    try {
      const url = `/internal-identity/v1alpha1/sso-routing-rules/${ssoRoutingRule.id}`
      const response = await del(url, {})

      if (response.status === 202) {
        await pullSSOConfigStatus(
          response.headers.get('location'),
          oidcUser,
          () => deleteSsoProfile(true),
          (error) => {
            const errorMsg = getErrorMessage(error, t)
            setErrorMessage(
              t('iam:sso_profiles.delete.delete_route_error_msg', {
                domainName: ssoRoutingRule.conditions.domains[0],
                errorMsg
              })
            )
            setDeleteLoading(false)
          }
        )
      } else {
        setErrorMessage(
          t('iam:sso_profiles.delete.delete_route_error_msg', {
            domainName: ssoRoutingRule.conditions.domains[0]
          })
        )
        setDeleteLoading(false)
      }
    } catch (error) {
      const errorMsg = getErrorMessage(error, t)
      setErrorMessage(
        t('iam:sso_profiles.delete.delete_route_error_msg', {
          domainName: ssoRoutingRule.conditions.domains[0],
          errorMsg
        })
      )
      setDeleteLoading(false)
    }
  }

  const handleDelete = async () => {
    setDeleteLoading(true)
    setErrorMessage('')
    if (ssoRoutingRule) {
      await deleteRoutingRule()
    } else {
      await deleteSsoProfile(false)
    }
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              testId="confirmation-header-title"
              type="heading"
              color="text-strong"
              weight="bold"
              level="3"
            >
              {t('iam:sso_profiles.delete.delete_sso_header')}
            </Typography>
          }
        />
      }
      content={
        <Box margin={{ top: 'small' }}>
          <Typography
            size="medium"
            testId="confirmation-header-subtitle"
            type="text"
            color="text-strong"
          >
            <Trans
              i18nKey="iam:sso_profiles.delete.delete_sso_heading"
              t={t}
              components={[<span style={{ fontWeight: 500 }} />]}
            />
          </Typography>
          <ul>
            <li>{t('iam:sso_profiles.delete.prevent_login_msg')}</li>
            <li>{t('iam:sso_profiles.delete.not_affect_logged_in_users')}</li>
          </ul>
          <Typography
            size="medium"
            testId="confirmation-footer-subtitle"
            type="text"
            color="text-strong"
            margin={{ bottom: 'small' }}
          >
            {t('iam:sso_profiles.delete.delete_sso_profile')}
          </Typography>
          {errorMessage.length ? (
            <Notification
              backgroundColor="status-critical"
              testId="delete-sso-profile-error-message"
              text={errorMessage}
              type="inline"
            />
          ) : null}
          {deleteLoading && (
            <Box margin={{ top: 'small' }} align="center">
              <Loader
                testId="delete-sso-profile-loader"
                label={t('iam:sso_profiles.delete.deleting_sso_profile')}
                orientation="horizontal"
              />
            </Box>
          )}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            !deleteLoading && (
              <Box direction="row" gap="medium">
                <Button
                  label={t('common:cancel')}
                  onClick={() => {
                    setShowModal(false)
                  }}
                  testId="cancel-btn"
                />
                <Button
                  primary
                  label={t('common:delete')}
                  onClick={handleDelete}
                  testId="delete-btn"
                  disabled={deleteLoading}
                />
              </Box>
            )
          }
        />
      }
      onClose={() => setShowModal(false)}
      testId="remove-sso-profile-modal-dialog"
      width="medium"
    />
  )
}

DeleteSsoProfileModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ssoRoutingRule: PropTypes.object,
  setShowModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
}

DeleteSsoProfileModal.defaultProps = {
  onSuccess: () => {},
  id: undefined,
  ssoRoutingRule: null
}

export { DeleteSsoProfileModal }
