export const customerDetails = {
  id: {
    name: 'id'
  },
  msp_id: {
    name: 'msp_id'
  },
  created_on: {
    name: 'created_at'
  },
  account_type: {
    name: 'account_type'
  },
  account_status: {
    name: 'account_status'
  }
}

export const tableDetails = {
  devices: {
    serial_number: {
      name: 'serial_number'
    },
    model: {
      name: 'model'
    },
    mac_address: {
      name: 'mac_address'
    },
    device_type: {
      name: 'device_type'
    }
  },
  orders: {
    ship_date: {
      name: 'ship_date'
    },
    // order_class: {
    //   name: 'class'
    // }, //
    part_number: {
      name: 'part_number'
    },
    serial_number: {
      name: 'serial_number'
    },
    mac_address: {
      name: 'mac_address'
    },
    // sold_to: {
    //   name: 'sold_to'
    // }, //
    // ship_to: {
    //   name: 'ship_to'
    // }, //
    end_user_name: {
      name: 'end_user_name'
    },
    // customer: {
    //   name: 'customer'
    // }, //
    customer_po: {
      name: 'customer_po'
    },
    // so: {
    //   name: 'so_order'
    // }, //
    status: {
      name: 'status'
    }
    // device_status: {
    //   name: 'dev_status'
    // } //
  },
  users: {
    email: {
      name: 'username'
    },
    first_name: {
      name: 'first_name'
    },
    last_name: {
      name: 'last_name'
    },
    user_status: {
      name: 'status'
    },
    user_type: {
      name: 'user_type'
    }
  },
  subscriptions: {
    subscription_key: {
      name: 'key'
    },
    start_date: {
      name: 'start_date'
    },
    end_date: {
      name: 'end_date'
    },
    product_sku: {
      name: 'sku'
    },
    quantity: {
      name: 'quantity'
    }
  },
  applications: {
    application_customer_id: {
      name: 'application_customer_id'
    },
    application_name: {
      name: 'application_name'
    },
    application_instance_name: {
      name: 'application_instance_name'
    },
    provision_status: {
      name: 'provision_status'
    },
    region: {
      name: 'region'
    }
  }
}

export const tableKeys = {
  subscriptions: {
    attributes: { key: 'attribute_type', value: 'attribute_value' },
    support_attributes: { key: 'attribute_name', value: 'attribute_value' },
    product_attributes: { key: 'name_display', value: 'value_display' },
    application_instances: {
      customKeys: true,
      keys: ['application_id', 'application_instance_id']
    },
    parties: {
      customKeys: true,
      keys: ['id', 'function', 'country_id', 'global_id']
    }
  }
}

export const tableFilters = (t) => ({
  devices: [
    {
      label: t('device_type'),
      name: 'display_device_types',
      selectionType: 'radio',
      values: [
        { valueLabel: t('access_points'), valueName: 'AP' },
        { valueLabel: t('switches'), valueName: 'SWITCH' },
        { valueLabel: t('gateways'), valueName: 'GATEWAY' },
        {
          valueLabel: t('servers'),
          valueName: 'COMPUTE,DHCI_COMPUTE'
        },
        {
          valueLabel: t('storage'),
          valueName: 'STORAGE,DHCI_STORAGE'
        }
      ]
    }
  ],
  orders: [],
  users: [
    {
      label: t('user_status'),
      name: 'user_status',
      selectionType: 'radio',
      values: [
        { valueLabel: t('VERIFIED'), valueName: 'VERIFIED' },
        { valueLabel: t('UNVERIFIED'), valueName: 'UNVERIFIED' },
        { valueLabel: t('BLOCKED'), valueName: 'BLOCKED' },
        {
          valueLabel: t('DELETE_IN_PROGRESS'),
          valueName: 'DELETE_IN_PROGRESS'
        },
        { valueLabel: t('DELETED'), valueName: 'DELETED' },
        { valueLabel: t('SUSPENDED'), valueName: 'SUSPENDED' }
      ]
    },
    {
      label: t('first_name'),
      name: 'first_name',
      selectionType: 'text'
    },
    {
      label: t('last_name'),
      name: 'last_name',
      selectionType: 'text'
    },
    {
      label: t('customer_details.user_type'),
      name: 'user_type',
      selectionType: 'radio',
      values: [
        { valueLabel: t('FEDERATED'), valueName: 'FEDERATED' },
        { valueLabel: t('NON_FEDERATED'), valueName: 'NON_FEDERATED' }
      ]
    }
  ],
  subscriptions: [
    {
      label: t('subscription_key'),
      name: 'subscription_key',
      selectionType: 'text'
    },
    {
      label: t('sku'),
      name: 'sku',
      selectionType: 'text'
    },
    {
      label: t('evaluation_type'),
      name: 'evaluation_type',
      selectionType: 'radio',
      values: [
        {
          valueLabel: t('PAID'),
          valueName: 'NONE'
        },
        { valueLabel: t('EVAL'), valueName: 'EVAL' }
      ]
    }
  ],
  applications: [
    {
      name: 'application_id',
      label: t('application_id'),
      selectionType: 'text'
    },
    {
      name: 'username',
      label: t('username'),
      selectionType: 'text'
    },
    {
      name: 'region',
      label: t('region'),
      selectionType: 'text'
    },
    {
      label: t('provision_status'),
      name: 'provision_status',
      selectionType: 'radio',
      values: [
        {
          valueLabel: t('PROVISION_INITIATED'),
          valueName: 'PROVISION_INITIATED'
        },
        { valueLabel: t('PROVISIONED'), valueName: 'PROVISIONED' },
        { valueLabel: t('PROVISION_FAILED'), valueName: 'PROVISION_FAILED' },
        {
          valueLabel: t('UNPROVISION_INITIATED'),
          valueName: 'UNPROVISION_INITIATED'
        },
        { valueLabel: t('UNPROVISIONED'), valueName: 'UNPROVISIONED' },
        { valueLabel: t('UNPROVISION_FAILED'), valueName: 'UNPROVISION_FAILED' }
      ]
    }
  ]
})

export const searchPlaceholders = (t) => ({
  devices: t('search_by_serial_model_or_mac'),
  orders: t('search_orders'),
  users: t('search_by_username_first_last_name'),
  subscriptions: t('search_subscription_key'),
  applications: t('search_application_id')
})

export const searchValueKeys = {
  devices: 'search_string',
  orders: 'order_id',
  subscriptions: 'subscription_key_pattern',
  users: 'search_string',
  applications: 'application_id'
}

export const ordersSidePanelDataKeys = [
  'customer_po',
  'part_number',
  'serial_number',
  'status',
  'end_user_name',
  'ship_date',
  'bill_to_name',
  'invoice_date',
  'source',
  'order_no',
  'obj_key',
  'category',
  'pos_id',
  'qty',
  'ext_cost',
  'invoice_no',
  'line_type',
  'zip_code',
  'party_id',
  'country_party_id',
  'global_party_id'
]
