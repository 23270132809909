import React, { useState } from 'react'
import { Box, CheckBox, FormField } from 'grommet'
import { Alert } from 'grommet-icons'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  Typography,
  ModalFooter,
  ButtonGroup,
  CCSForm
} from '../../../components'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'

const DeleteTenantAccountModal = ({
  customer,
  handleCancel,
  handleDeleteCustomerAccount
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const [checked, setChecked] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const invokeError = () => {
    setErrorMessage(t('authn:customer_account.remove_terms_of_service_text'))
  }

  const handleDeleteAccount = () => {
    if (checked) {
      handleDeleteCustomerAccount()
    } else {
      invokeError()
    }
  }

  const handleTerms = (value) => {
    setChecked(value)
    setErrorMessage('')
  }

  return (
    <ModalDialog
      content={
        <Box>
          <Box align="center" margin={{ bottom: 'medium' }}>
            <Alert size="xlarge" color="status-critical" />
            <Box align="center" gap="xsmall" margin={{ top: 'medium' }}>
              <Typography
                size="xlarge"
                testId="modal-title"
                type="text"
                weight="bold"
              >
                {t('customer_account.delete_customer_account_title', {
                  account: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
              </Typography>
              <Typography
                size="medium"
                testId="modal-subtitle"
                type="text"
                textAlign="center"
              >
                <Trans
                  i18nKey="customer_account.delete_customer_account_desc"
                  t={t}
                  companyName={customer?.company_name}
                >
                  <strong>{{ companyName: customer?.company_name }}</strong>
                </Trans>
              </Typography>
            </Box>
          </Box>

          <Box gap="medium">
            <CCSForm
              errorMessage={errorMessage}
              testId="customer-account-term-form"
            >
              <FormField>
                <CheckBox
                  data-testid="customer-account-term-checkbox"
                  name="customer_account_terms"
                  checked={checked}
                  label={
                    <Typography
                      type="text"
                      size="small"
                      testId="customer-account-terms-checkbox-label"
                    >
                      {t('customer_account.remove_customer_account_terms')}
                    </Typography>
                  }
                  onChange={(event) => handleTerms(event.target.checked)}
                />
              </FormField>
            </CCSForm>
          </Box>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  label: t('customer_account.keep_account', {
                    account: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  }),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: handleCancel,
                  style: { whiteSpace: 'nowrap' }
                },
                {
                  label: t('customer_account.delete_account', {
                    account: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  }),
                  primary: true,
                  testId: 'delete-account-btn',
                  onClick: handleDeleteAccount,
                  style: { whiteSpace: 'nowrap' }
                }
              ]}
              testId="modal-action-buttons"
            />
          }
        />
      }
      onClose={handleCancel}
      testId="delete-customer-account-modal"
      width="large"
    />
  )
}

DeleteTenantAccountModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  handleDeleteCustomerAccount: PropTypes.func.isRequired
}

export { DeleteTenantAccountModal }
