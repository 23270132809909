import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  ModalHeader,
  Tabs,
  Typography
} from '../../../../../../components'
import AssignedUsers from '../../../../../commoncomponents/identity/AssignedUsers'

import { RoleDetailsTab } from './RoleDetailsTab'
import { AssignedUsersTab } from './AssignedUsersTab'

const RoleDetailSidePanel = ({
  roleDetails,
  setShowPanel,
  isTACCustomerSelected
}) => {
  const LDFlags = useFlags()
  const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']
  const { t } = useTranslation(['manage'])
  return (
    <ModalDialog
      onClickOutside={() => setShowPanel(false)}
      header={
        <ModalHeader
          title={
            <Typography
              level="1"
              testId="header-title"
              type="heading"
              weight="bold"
            >
              {roleDetails?.name}
            </Typography>
          }
          onClose={() => setShowPanel(false)}
        />
      }
      content={
        <Box margin={{ vertical: 'medium' }} direction="column">
          <Tabs
            justify="start"
            alignControls="start"
            testId="role-details-tabs"
            flex={{ shrink: 0 }}
            tabsList={[
              {
                content: (
                  <Box>
                    <RoleDetailsTab roleDetails={roleDetails} />
                  </Box>
                ),
                id: 1,
                label: t('customer_roles.details'),
                testId: 'role-details-tab'
              },
              {
                content: (
                  <Box>
                    {saCustomRoleLDFlag && isTACCustomerSelected ? (
                      <AssignedUsers role={roleDetails} inCCSManager />
                    ) : (
                      <AssignedUsersTab roleDetails={roleDetails} />
                    )}
                  </Box>
                ),
                id: 2,
                label: t('customer_roles.assigned_users'),
                testId: 'assigned-users-tab'
              }
            ]}
          />
        </Box>
      }
      height="100%"
      width="medium"
      onClose={() => setShowPanel(false)}
      position="right"
      testId="role-details-side-panel"
    />
  )
}

RoleDetailSidePanel.propTypes = {
  roleDetails: PropTypes.object.isRequired,
  setShowPanel: PropTypes.func.isRequired,
  isTACCustomerSelected: PropTypes.bool
}

RoleDetailSidePanel.defaultProps = {
  isTACCustomerSelected: false
}

export { RoleDetailSidePanel }
