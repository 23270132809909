import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  Typography
} from '../../../components'

const DiscardModal = ({ onDiscard, onContinue }) => {
  const { t } = useTranslation('authn', 'common')
  return (
    <ModalDialog
      width="medium"
      testId="discard-modal-dialog"
      content={
        <Box>
          <Typography type="heading" testId="discard-changes-title">
            {t('customer_account.discard_modal_title')}
          </Typography>
          <Typography
            type="text"
            size="medium"
            testId="discard-changes-description"
            margin={{ top: 'xsmall' }}
          >
            {t('customer_account.discard_changes_desc')}
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  label: t('customer_account.continue_editing'),
                  default: true,
                  testId: 'continue-btn',
                  onClick: onContinue,
                  style: { flexShrink: 0 }
                },
                {
                  label: t('common:button.discard_changes'),
                  primary: true,
                  testId: 'discard-btn',
                  onClick: onDiscard,
                  style: { flexShrink: 0 }
                }
              ]}
              testId="discard-modal-action-buttons"
            />
          }
        />
      }
      onClose={onContinue}
    />
  )
}

DiscardModal.propTypes = {
  onDiscard: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired
}

export { DiscardModal }
