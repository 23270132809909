import { Notification } from '../../../components'

export const getGlopErrorMessage = (
  error,
  t,
  fallbackMessage,
  overrideMessage
) => {
  if (overrideMessage) {
    return overrideMessage
  }
  const errorCode = error?.response?.data?.errorCode
  return t(`${errorCode}`) === errorCode
    ? fallbackMessage || error?.response?.data?.message
    : t(`${errorCode}`)
}

export const displayGlopError = (
  error,
  t,
  callback,
  status = 'status-critical',
  title,
  fallbackMessage,
  overrideMessage,
  type = 'default',
  autoClose = true
) => {
  const text = getGlopErrorMessage(error, t, fallbackMessage, overrideMessage)
  const handleClose = () => {
    callback(null)
  }

  return (
    <Notification
      testId="notification_error"
      text={text}
      title={title}
      onClose={handleClose}
      backgroundColor={status}
      type={type}
      autoClose={autoClose}
    />
  )
}
