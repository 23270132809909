import React, { useEffect, useState } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  Button,
  Loader,
  Notification,
  Typography
} from '../../../../components'
import CreateOrganizationModal from '../../../igc-service/commoncomponents/CreateOrganizationModal'
import JoinOrganizationModal from '../../../igc-service/commoncomponents/JoinOrganizationModal'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { get, getErrorMessage } from '../../../../utils/api-utils'
import { displayNotification } from '../../../../utils/notificiation-utils'
import { LIFECYCLE_STATE } from '../../account-details/constants'

const JoinOrganizationInfoTile = ({ setNotification, refreshParent }) => {
  const { t } = useTranslation(['common', 'iam'])
  const [showCreateOrg, setShowCreateOrg] = useState(false)
  const [showJoinOrg, setShowJoinOrg] = useState(false)
  const [orgList, setOrgList] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    get(`/organizations/v2alpha1/organizations`, {}).then(
      (response) => {
        if (
          response?.data &&
          response?.data?.items &&
          response?.data?.items?.length > 0
        ) {
          setOrgList(
            response?.data?.items?.filter(
              (org) => org?.lifecycleState === LIFECYCLE_STATE.ACTIVE
            )
          )
        }
        setLoading(false)
      },
      (error) => {
        setNotification(
          displayNotification(
            getErrorMessage(error, t),
            'error',
            setNotification
          )
        )
        setLoading(false)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, setNotification])

  return (
    <>
      <Box direction="column" justify="start" align="start">
        <Box gap="medium">
          <Notification
            status="info"
            testId="join-org-inline-notification"
            text={t('iam:organization.inactive_org_info', {
              workspace: t('common:business_object.wkspc'),
              organizationGovernance: t('iam:organization.governance_title')
            })}
            type="inline"
          />
        </Box>
        {loading ? (
          <Loader testId="inactive-org-info-loader" />
        ) : (
          <>
            <Typography
              normalize
              type="text"
              size="medium"
              testId="activate-org-desc"
            >
              {orgList
                ? t('iam:organization.inactive_org_description_v3', {
                    workspaces: t('common:business_object.wkspc_plural')
                  })
                : t('iam:organization.inactive_org_description_v2', {
                    workspaces: t('common:business_object.wkspc_plural')
                  })}
            </Typography>
            <VisibilityWrapper
              rbac={{
                permission: 'ccs.authorization.edit'
              }}
            >
              {orgList && (
                <Button
                  testId="join-org-btn"
                  onClick={() => {
                    setShowJoinOrg(true)
                  }}
                  label={t('iam:organization.join_organization.label')}
                  secondary
                  margin={{ top: 'medium' }}
                  style={{ width: '100%' }}
                />
              )}
            </VisibilityWrapper>
            <VisibilityWrapper
              rbac={{
                permission: 'ccs.accounts.platform.customer.edit'
              }}
            >
              <Button
                testId="create-org-btn"
                onClick={() => {
                  setShowCreateOrg(true)
                }}
                label={t(
                  'iam:organization.join_organization.create_new_org.title'
                )}
                secondary
                margin={{ top: 'medium' }}
                style={{ width: '100%' }}
              />
            </VisibilityWrapper>
          </>
        )}
        {showJoinOrg && orgList && (
          <JoinOrganizationModal
            orgList={orgList}
            showDialog={setShowJoinOrg}
            setNotification={setNotification}
            refreshParent={refreshParent}
          />
        )}
        {showCreateOrg && (
          <CreateOrganizationModal
            showDialog={setShowCreateOrg}
            setNotification={setNotification}
            refreshParent={refreshParent}
          />
        )}
      </Box>
    </>
  )
}

JoinOrganizationInfoTile.propTypes = {
  setNotification: PropTypes.func,
  refreshParent: PropTypes.func
}

JoinOrganizationInfoTile.defaultProps = {
  setNotification: () => {},
  refreshParent: () => {}
}

export default JoinOrganizationInfoTile
