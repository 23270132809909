import { Box } from 'grommet'
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  Typography
} from '../../../../../components'

const SaveChangesModal = ({ closeModal, onSave }) => {
  const { t } = useTranslation(['authn', 'common'])

  return (
    <ModalDialog
      width="medium"
      testId="save-changes-modal-dialog"
      content={
        <Box>
          <Typography type="heading" testId="save-changes-title">
            {t('cop_local_authentication.update_button')}
          </Typography>
          <Typography
            type="text"
            size="medium"
            testId="save-changes-description"
            margin={{ top: 'xsmall' }}
          >
            {t('cop_local_authentication.save_changes_description')}
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('common:cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: closeModal
                },
                {
                  id: 1,
                  label: t('common:save'),
                  primary: true,
                  testId: 'save-btn',
                  onClick: onSave
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={closeModal}
    />
  )
}

SaveChangesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default SaveChangesModal
