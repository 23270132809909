// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
const ReportingActions = {
  SET_REPORTS_DATATABLE_INFO: 'SET_REPORTS_DATATABLE_INFO',
  SET_REPORTS_DATATABLE_FILTER_INFO: 'SET_REPORTS_DATATABLE_FILTER_INFO',
  SET_REPORTS_METADATA: 'SET_REPORTS_METADATA',
  SET_CREATE_REPORTS_API_DATA: 'SET_CREATE_REPORTS_API_DATA',
  RESET_REPORTS_DATATABLE_INFO: 'RESET_REPORTS_DATATABLE_INFO',
  RESET_REPORTS_DATATABLE_FILTER_INFO: 'RESET_REPORTS_DATATABLE_FILTER_INFO',
  SET_REPORTS_PREVIEW_DATA: 'SET_REPORTS_PREVIEW_DATA'
}
export default ReportingActions
