import {
  Anchor,
  Box,
  Button,
  Card,
  CardBody,
  Grid,
  Heading,
  Paragraph,
  Text,
  ThemeContext,
  Tag
} from 'grommet'
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Apps, AppsRounded, List } from 'grommet-icons'
import { uniqBy } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  DataTable,
  getCustomerAccountType,
  isOrgWorkSpace
} from '../shims/imports'
import { useServiceRedirect, useLocalStorage } from '../hooks'
import { localStorageWhitelistWithoutPII } from '../../utils/local-storage-utils'
import { SERVICES_DISABLE_LAUNCH_FROM_MSP } from '../constants'

import ServiceLaunchModal from './ServiceLaunchModal'

const serviceLDFlags = {
  UXI: 'glcp-dm-uxi'
}

const TogglableRecentServices = ({ services, glcpLastAccessed }) => {
  const { t, i18n } = useTranslation(['dashboard'])
  const serviceRedirect = useServiceRedirect()
  const { global } = useContext(ThemeContext)
  const LDFlags = useFlags()
  const accountType = getCustomerAccountType()

  const isServiceEnabled = (serviceSlug) =>
    !serviceLDFlags[serviceSlug] || LDFlags[serviceLDFlags[serviceSlug]]

  const [recentServicesView, setRecentServicesView] = useLocalStorage(
    localStorageWhitelistWithoutPII.RECENT_SERVICES_VIEW,
    'grid'
  )
  const [showModal, setShowModal] = useState(false)
  const [modalInfo, setModalInfo] = useState({})
  const headingTitle = t('dashboard.recent_services.title')
  const history = useHistory()

  const uniqueServices = useMemo(
    () => uniqBy(glcpLastAccessed, 'name'),
    [glcpLastAccessed]
  )
  const handleLaunch = (service) => {
    if (service.redirectUrl) {
      serviceRedirect(service)
    } else if (
      service.regions.filter(
        ({ provisionStatus }) => provisionStatus === 'PROVISIONED'
      ).length === 1
    ) {
      // We've ensured there's only one 'PROVISIONED' region
      // so we can can just grab that region and pass its code.
      const [region] = service.regions.filter(
        ({ provisionStatus }) => provisionStatus === 'PROVISIONED'
      )
      serviceRedirect(service, region.code)
    } else {
      setShowModal(true)
      setModalInfo({
        serviceName: service.name,
        slug: service.serviceSlug
      })
    }
  }

  const renderTenantOnlySupportedServices = (service) => {
    return accountType === 'MSP' &&
      (service?.tenant_only_supported ||
        SERVICES_DISABLE_LAUNCH_FROM_MSP?.includes(service?.serviceSlug)) ? (
      <span>
        <Tag
          value={t('dashboard:service_details.enabled_for_customer_workspaces')}
          align="center"
          size="small"
          border={{ size: 'small', color: 'brand' }}
          color="background"
          style={{
            whiteSpace: 'nowrap'
          }}
        />
      </span>
    ) : (
      <Button
        onClick={() => handleLaunch(service)}
        label={t('common.launch')}
        a11yTitle={`${t('common.launch')} ${service.name}`}
        secondary
      />
    )
  }

  return (
    <Box margin={{ bottom: 'large' }}>
      {showModal && (
        <ServiceLaunchModal
          services={services}
          modalInfo={modalInfo}
          setShowModal={setShowModal}
        />
      )}
      {glcpLastAccessed.length === 0 ? (
        <Box>
          <Heading level={2}>{headingTitle}</Heading>
          <Box
            align="center"
            width="50%"
            margin={{ horizontal: 'auto' }}
            gap="medium"
          >
            <Box align="center" gap="small">
              <AppsRounded size="xxlarge" color={global.colors.green.dark} />
              <Paragraph size="large" textAlign="center" margin="none">
                {t('dashboard.recent_services.empty')}
              </Paragraph>
            </Box>
            <Button
              label={t('dashboard.recent_services.view_catalog')}
              primary
              onClick={() => history.push('/services/service-catalog')}
            />
          </Box>
        </Box>
      ) : (
        <Box>
          {recentServicesView === 'grid' && (
            <Box margin={{ vertical: '3px' }} pad={{ horizontal: '3px' }}>
              <Box direction="column" margin={{ bottom: 'medium' }}>
                <Box
                  direction="row"
                  justify="between"
                  align="center"
                  margin={{ bottom: 'small' }}
                >
                  <Heading level={2}>{headingTitle}</Heading>
                  <Box direction="row" align="center" gap="small">
                    <Button
                      tip={t('dashboard:common.switch_to_list_view')}
                      icon={<List />}
                      onClick={() => {
                        setRecentServicesView('table')
                      }}
                    />
                    <Anchor
                      href="/services/my-services"
                      onClick={(e) => {
                        e.preventDefault()
                        history.push('/services/my-services')
                      }}
                    >
                      {t('common.my_services')}
                    </Anchor>
                  </Box>
                </Box>
                <Grid
                  columns={{
                    count: 'fill',
                    size: ['medium', 'flex']
                  }}
                  gap="small"
                  pad={{ bottom: 'small' }}
                >
                  {uniqueServices
                    .filter(
                      ({ serviceSlug }) =>
                        !isOrgWorkSpace() || serviceSlug === 'PCE'
                    )
                    .map((service) => (
                      <Card
                        key={`${service.region}-${service.name}`}
                        title={service.name}
                      >
                        <CardBody
                          pad="medium"
                          direction="row"
                          align="center"
                          justify="between"
                        >
                          <Box>
                            <Heading level={3} margin="none">
                              {service.name}
                            </Heading>
                            <Text size="small">
                              {t(`common.category.${service.category}`)}
                            </Text>
                          </Box>
                          {renderTenantOnlySupportedServices(service)}
                        </CardBody>
                      </Card>
                    ))}
                </Grid>
              </Box>
            </Box>
          )}
          {recentServicesView === 'table' && (
            <Box>
              <DataTable
                testId="toggleTable"
                grid={{
                  verticalAlign: 'middle',
                  background: {
                    header: 'white'
                  },
                  border: {
                    header: {
                      color: 'transparent',
                      side: 'bottom',
                      size: 'small'
                    },
                    body: {
                      color: 'border-weak',
                      side: 'bottom',
                      size: 'xsmall'
                    }
                  },
                  pad: {
                    header: { horizontal: 'auto' },
                    body: { horizontal: 'auto', vertical: 'medium' }
                  },
                  columns: [
                    {
                      header: (
                        <Heading level={2} margin="none">
                          {headingTitle}
                        </Heading>
                      ),
                      property: 'title',
                      sortable: false
                    },
                    {
                      header: 'Type',
                      property: 'type',
                      sortable: true
                    },
                    {
                      header: 'Last Accessed',
                      property: 'last_accessed',
                      sortable: true,
                      render: ({ last_accessed }) => {
                        /* Always based on browser/computer settings */
                        const { timeZone } =
                          Intl.DateTimeFormat().resolvedOptions()
                        return last_accessed
                          ? new Date(last_accessed).toLocaleString(
                              i18n.language,
                              {
                                timeZone,
                                dateStyle: 'medium',
                                timeStyle: 'short'
                              }
                            )
                          : ''
                      }
                    },
                    {
                      property: 'path',
                      header: (
                        <Box direction="row" align="center" gap="small">
                          <Button
                            tip={t('dashboard:common.switch_to_grid_view')}
                            icon={<Apps />}
                            onClick={() => {
                              setRecentServicesView('grid')
                            }}
                          />
                          <Anchor
                            href="/services/my-services"
                            onClick={(e) => {
                              e.preventDefault()
                              history.push('/services/my-services')
                            }}
                          >
                            {t('common.my_services')}
                          </Anchor>
                        </Box>
                      ),
                      sortable: false,
                      render: (service) => {
                        return renderTenantOnlySupportedServices(service)
                      }
                    }
                  ],
                  sort: {
                    direction: 'desc',
                    property: 'last_accessed'
                  },
                  data: (() => {
                    return glcpLastAccessed
                      .filter(
                        ({ serviceSlug }) =>
                          isServiceEnabled(serviceSlug) &&
                          (!isOrgWorkSpace() || serviceSlug === 'PCE')
                      )
                      .map(({ name, category, utc, ...rest }) => {
                        return {
                          ...rest,
                          name,
                          title: name,
                          type: t(`common.category.${category}`),
                          last_accessed: utc,
                          path: '#'
                        }
                      })
                  })()
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

TogglableRecentServices.propTypes = {
  services: PropTypes.object.isRequired,
  glcpLastAccessed: PropTypes.array.isRequired
}

export default TogglableRecentServices
