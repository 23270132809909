import React from 'react'
import PropTypes from 'prop-types'

import useCCSReducer from './CCSReducer'

const CCSContext = React.createContext()

export const CCSContextProvider = ({ children }) => {
  const stateAndDispatch = useCCSReducer()
  return (
    <CCSContext.Provider value={stateAndDispatch}>
      {children}
    </CCSContext.Provider>
  )
}

CCSContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const useCCSContext = () => {
  const { CCSState, dispatchCCSContext } = React.useContext(CCSContext)
  return { ...CCSState, dispatchCCSContext }
}
