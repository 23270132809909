// Copyright 2024 Hewlett Packard Enterprise Development LP

import { Route, Switch, Redirect } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useTranslation } from 'react-i18next'

import { Layout } from '../commoncomponents/layout/Layout'
import FolderDetailsPage from '../manage-account/commoncomponent/folder-details/FolderDetailsPage'
import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { getCCSAppDetails } from '../manage-account/utils'
import { displayApiError } from '../../utils/error-handling-utils'
import { get } from '../../utils/api-utils'
import {
  CCSManagerActions,
  CCSManagerContextProvider,
  useCCSManagerContext
} from '../../context/ccs-manager-context'
import { AUTHZContextProvider } from '../../context/authz-context'
import Roles from '../commoncomponents/identity/Roles'
import CreateRolesWizard from '../commoncomponents/identity/create-role/CreateRolesWizard'
import RoleDetailsViewEditPage from '../commoncomponents/identity/roles-details-view-edit/RoleDetailsViewEdit'

import IdentityPage from './pages/identity/IdentityPage'
import SupportAccess from './pages/support-access/SupportAccess'
import CreateLocationPage from './pages/locations/CreateLocationPage'
import CustomerDetails from './pages/customers/components/CustomerDetails'
import ManageCcs from './ManageCcs'
import ActivateDevicesDetailsPage from './pages/customers/components/activate-devices-details/ActivateDevicesDetails'
import UserDetailsPage from './pages/user-details/UserDetails'
import NotificationDetails from './pages/notifications/components/NotificationDetails'
import CreateNotificationWizard from './pages/notifications/components/create-notification/CreateNotificationWizard'
import { AppDetailsPage } from './pages/applications/pages'
import { CustomerUserDetailsPage } from './pages/customers/pages'
import { RRPDetailsPage } from './pages/customers/pages/rrp-details/RRPDetailsPage'
import CCSManagerHomePage from './CCSManagerHomePage'
import WhatsNewPostDetails from './pages/whats-new/WhatsNewPostDetails'
import CCSLocationDetails from './pages/locations/CCSLocationDetails'
import SetUpAccountPage from './pages/set-up-account/set-up-account'
import EditNotificationRequestWizard from './pages/notifications/components/notification-approval-flow/edit-notification-request/EditNotificationRequestWizard'
import NotificationApprovalDetails from './pages/notifications/components/notification-approval-flow/NotificationApprovalDetails'
import EditPublishedNotificationWizard from './pages/notifications/components/EditPublishedNotification/EditPublishedNotificationWizard'
import SupportAccessPage from './pages/support-access/SupportAccessPage'
import SupportAccessUsersPage from './pages/support-access/SupportAccessUsers'
import DocumentCuration from './pages/search-administration/document-curation/DocumentCuration'
import SearchAdministrationPage from './pages/search-administration'

const CCSManagerContent = () => {
  const { t } = useTranslation(['common'])
  const { oidcUser } = useReactOidc()

  const { dispatchCCSManagerContext, userCCSRoles } = useCCSManagerContext()
  const ccsApplicationId = getCCSAppDetails()?.ccsApplicationId

  const [showErrorNotification, setShowErrorNotification] = useState(null)

  useEffect(() => {
    if (!userCCSRoles) {
      const url = `/support-assistant/v1alpha1/tac-user-role-assignments`
      get(url, {}, oidcUser.access_token).then(
        (response) => {
          const roleSlugs =
            response?.data?.roles
              ?.filter((data) => data?.application_id === ccsApplicationId)
              .map((data) => data?.slug) || []
          dispatchCCSManagerContext({
            type: CCSManagerActions.SET_USER_CCS_ROLES,
            data: roleSlugs
          })
        },
        (error) => {
          setShowErrorNotification(displayApiError(error, t))
          dispatchCCSManagerContext({
            type: CCSManagerActions.SET_USER_CCS_ROLES,
            data: []
          })
        }
      )
    }
  }, [
    ccsApplicationId,
    oidcUser.access_token,
    t,
    dispatchCCSManagerContext,
    userCCSRoles
  ])

  return (
    <Switch>
      <Route exact path="/manage-ccs/home">
        <CCSManagerHomePage />
      </Route>
      <Route exact path="/manage-ccs/identity">
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/authorization',
            permission: 'ccs.authorization.view'
          }}
          fallbackComponent={<Redirect to="/manage-ccs/home" />}
        >
          <IdentityPage />
        </VisibilityWrapper>
      </Route>

      <Route exact path="/manage-ccs/search-administration">
        <VisibilityWrapper
          hideFor={{
            deployment: ['GLOP'],
            feature: 'glcp-search-administration'
          }}
          rbac={{
            resource: '/ccs/global-search/administration',
            permission: 'ccs.global-search-administration.view'
          }}
          fallbackComponent={<Redirect to="/manage-ccs/home" />}
        >
          <SearchAdministrationPage />
        </VisibilityWrapper>
      </Route>

      <Route
        exact
        path="/manage-ccs/search-administration/document-curation/:docId"
      >
        <VisibilityWrapper
          hideFor={{
            deployment: ['GLOP'],
            feature: 'glcp-search-administration'
          }}
          rbac={{
            resource: '/ccs/global-search/administration-curation',
            permission: 'ccs.global-search-curation.view'
          }}
          fallbackComponent={<Redirect to="/manage-ccs/home" />}
        >
          <DocumentCuration />
        </VisibilityWrapper>
      </Route>

      <Route exact path="/manage-ccs/support-access">
        <VisibilityWrapper
          hideFor={{
            deployment: ['GLOP'],
            feature: 'glcp-support-access-manager'
          }}
          fallbackComponent={<Redirect to="/manage-ccs/home" />}
        >
          <SupportAccess />
        </VisibilityWrapper>
      </Route>
      <Route exact path="/manage-ccs/support-access/search-incident">
        <VisibilityWrapper
          hideFor={{
            deployment: ['GLOP'],
            feature: 'glcp-support-access-manager'
          }}
          fallbackComponent={<Redirect to="/manage-ccs/home" />}
        >
          <SupportAccessPage />
        </VisibilityWrapper>
      </Route>
      <Route exact path="/manage-ccs/support-access/users">
        <VisibilityWrapper
          hideFor={{
            deployment: ['GLOP'],
            feature: 'glcp-support-access-manager'
          }}
          fallbackComponent={<Redirect to="/manage-ccs/home" />}
        >
          <SupportAccessUsersPage />
        </VisibilityWrapper>
      </Route>
      <Route exact path="/manage-ccs/identity/roles">
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/authorization',
            permission: 'ccs.authorization.view'
          }}
        >
          <Roles inCCSManager />
        </VisibilityWrapper>
      </Route>
      <Route
        exact
        path="/manage-ccs/identity/roles/create/:applicationId/:slug"
      >
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/authorization',
            permission: 'ccs.authorization.view'
          }}
        >
          <CreateRolesWizard inCCSManager />
        </VisibilityWrapper>
      </Route>
      <Route exact path="/manage-ccs/identity/roles/create/:applicationId">
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/authorization',
            permission: 'ccs.authorization.view'
          }}
        >
          <CreateRolesWizard inCCSManager />
        </VisibilityWrapper>
      </Route>
      <Route
        exact
        path="/manage-ccs/identity/roles/roleviewedit/:applicationId/:slug"
      >
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/authorization',
            permission: 'ccs.authorization.view'
          }}
        >
          <RoleDetailsViewEditPage inCCSManager />
        </VisibilityWrapper>
      </Route>
      <Route exact path="/manage-ccs/onboarding/set-up-account">
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/accounts/platform/customer',
            permission: 'ccs.accounts.platform.customer.edit'
          }}
        >
          <SetUpAccountPage />
        </VisibilityWrapper>
      </Route>
      <Route exact path="/manage-ccs/customers/customer-details">
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/accounts/platform/customer',
            permission: 'ccs.accounts.platform.customer.view'
          }}
        >
          <CustomerDetails />
        </VisibilityWrapper>
      </Route>
      <Route path="/manage-ccs/customers/customer-details/folders/folder-details">
        <VisibilityWrapper
          rbac={{
            permission: 'ccs.activate.view',
            resource: '/ccs/activate/tac'
          }}
        >
          <FolderDetailsPage inCCSManager />
        </VisibilityWrapper>
      </Route>
      <Route path="/manage-ccs/customers/locations/create-location">
        <VisibilityWrapper
          hideFor={{
            feature: 'glcp-glasgow-sdi-ccs-manager'
          }}
          rbac={{
            resource: '/ccs/location-management',
            permission: 'ccs.location-management.edit'
          }}
        >
          <CreateLocationPage />
        </VisibilityWrapper>
      </Route>
      <Route exact path="/manage-ccs/customers/locations/:locationId">
        <VisibilityWrapper
          hideFor={{
            feature: 'glcp-glasgow-sdi-ccs-manager'
          }}
          rbac={{
            resource: '/ccs/location-management',
            permission: 'ccs.location-management.view'
          }}
        >
          <CCSLocationDetails />
        </VisibilityWrapper>
      </Route>
      <Route path="/manage-ccs/customers/users/:username">
        <VisibilityWrapper
          rbac={{
            permission: 'ccs.accounts.users.view.all',
            resource: '/ccs/accounts/user'
          }}
        >
          <CustomerUserDetailsPage />
        </VisibilityWrapper>
      </Route>
      <Route path="/manage-ccs/devices/:serial">
        <VisibilityWrapper
          rbac={{
            permission: 'ccs.activate.view',
            resource: '/ccs/activate/tac'
          }}
        >
          <ActivateDevicesDetailsPage />
        </VisibilityWrapper>
      </Route>
      <Route path="/manage-ccs/users/:username">
        <VisibilityWrapper
          rbac={{
            permission: 'ccs.accounts.users.view.all',
            resource: '/ccs/accounts/user'
          }}
        >
          <UserDetailsPage />
        </VisibilityWrapper>
      </Route>
      <Route path="/manage-ccs/applications/:appId">
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/app-catalog/application',
            permission: 'ccs.app-catalog.view'
          }}
        >
          <AppDetailsPage />
        </VisibilityWrapper>
      </Route>
      <Route path="/manage-ccs/customers/scope-groups/:rrpId">
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/authorization',
            permission: 'ccs.authorization.view'
          }}
        >
          <RRPDetailsPage />
        </VisibilityWrapper>
      </Route>
      <Route exact path="/manage-ccs/notifications/create-notification">
        <VisibilityWrapper
          rbac={{
            permission: 'ccs.notification.edit',
            resource: '/ccs/notification'
          }}
        >
          <CreateNotificationWizard />
        </VisibilityWrapper>
      </Route>
      <Route
        exact
        path="/manage-ccs/notifications/edit-notification-request/:notificationId"
      >
        <VisibilityWrapper
          rbac={{
            permission: 'ccs.notification.edit',
            resource: '/ccs/notification'
          }}
        >
          <EditNotificationRequestWizard />
        </VisibilityWrapper>
      </Route>
      <Route
        exact
        path="/manage-ccs/notifications/edit-published-notification-request/:notificationId"
      >
        <VisibilityWrapper
          rbac={{
            permission: 'ccs.notification.edit',
            resource: '/ccs/notification'
          }}
        >
          <EditPublishedNotificationWizard />
        </VisibilityWrapper>
      </Route>
      <Route
        exact
        path="/manage-ccs/notifications/approval/:notificationId/:type"
      >
        <NotificationApprovalDetails />
      </Route>
      <Route
        exact
        path="/manage-ccs/notifications/scheduled-notification/:notificationId"
      >
        <NotificationDetails isSchedule />
      </Route>
      <Route exact path="/manage-ccs/notifications/:notificationId">
        <NotificationDetails />
      </Route>

      <Route exact path="/manage-ccs/whats-new/:blogID">
        <WhatsNewPostDetails />
      </Route>

      <Route path="/manage-ccs">
        <ManageCcs errorNotification={showErrorNotification} />
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  )
}

const CCSManagerModule = () => {
  return (
    <AUTHZContextProvider>
      <CCSManagerContextProvider>
        <Layout hideHeaderOptions={['apps']}>
          <CCSManagerContent />
        </Layout>
      </CCSManagerContextProvider>
    </AUTHZContextProvider>
  )
}

export default CCSManagerModule
