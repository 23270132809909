import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ButtonGroup
} from '../../../../components'
import { actionMoveToFolder } from '../../utils'

const ConfirmationModal = ({
  onSetOpen,
  folderData,
  data,
  refreshDataTable,
  setErrorMessage,
  customerId
}) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()

  const { folder_name: foldername } = folderData

  const handleMoveToFolder = () => {
    const requestBody = {
      ...folderData,
      devices: data,
      platform_customer_id: customerId
    }
    actionMoveToFolder(
      oidcUser.access_token,
      t,
      requestBody,
      onSetOpen,
      refreshDataTable,
      setErrorMessage
    )
  }

  return (
    <ModalDialog
      width="large"
      testId="move-confirmation-modal-dialog"
      content={
        <Box>
          <Typography type="heading" testId="move-confirmation-title">
            {t('move_confirmation_title')}
          </Typography>
          <Typography
            type="text"
            size="medium"
            testId="move-confirmation-subtitle"
          >
            <Trans i18nKey="move_confirmation_subtitle" t={t} foldername>
              <strong>{{ foldername }}</strong>
            </Trans>
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: () => onSetOpen(false)
                },
                {
                  id: 1,
                  label: t('move_confirmation_btn_text'),
                  primary: true,
                  testId: 'move-confirmation-btn',
                  onClick: handleMoveToFolder
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={() => onSetOpen(false)}
    />
  )
}

ConfirmationModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  folderData: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  refreshDataTable: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  customerId: PropTypes.string
}
ConfirmationModal.defaultProps = {
  customerId: undefined
}

export { ConfirmationModal }
