import React from 'react'
import PropTypes from 'prop-types'

import useUsageMonitoringReducer from './UsageMonitoringReducer'

const UsageMonitoringContext = React.createContext()

export const UsageMonitoringContextProvider = ({ children }) => {
  const stateAndDispatch = useUsageMonitoringReducer()
  return (
    <UsageMonitoringContext.Provider value={stateAndDispatch}>
      {children}
    </UsageMonitoringContext.Provider>
  )
}

UsageMonitoringContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const useUsageMonitoringContext = () => {
  const { UsageMonitoringState, dispatchUsageMonitoringContext } =
    React.useContext(UsageMonitoringContext)
  return { ...UsageMonitoringState, dispatchUsageMonitoringContext }
}
