import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ButtonGroup
} from '../../../components'

const DiscardModal = ({ showDiscardModal, buttonList, setDiscardModal }) => {
  const { t } = useTranslation(['authn'])

  return (
    showDiscardModal && (
      <ModalDialog
        width="medium"
        testId="discard-modal-dialog"
        content={
          <Box>
            <Typography type="heading" testId="discard-modal-title">
              {t(`customer_account.discard_modal_title`)}
            </Typography>
            <Typography
              type="text"
              size="medium"
              testId="discard-modal-description"
            >
              {t(`customer_account.discard_modal_message`)}
            </Typography>
          </Box>
        }
        footer={
          <ModalFooter
            right={<ButtonGroup buttonList={buttonList} testId="two-buttons" />}
          />
        }
        onClose={() => setDiscardModal(false)}
      />
    )
  )
}

DiscardModal.propTypes = {
  showDiscardModal: PropTypes.bool.isRequired,
  buttonList: PropTypes.array.isRequired,
  setDiscardModal: PropTypes.func.isRequired
}

export default DiscardModal
