// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { string } from 'yup'

import { CHARACTER_LENGTH } from '../pages/manage-account/account-details/constants'

const passwordVerifyMessage = (t) => {
  return {
    message: t('common:password_does_not_meet'),
    status: 'error'
  }
}

export const passwordValidation = (t) => [
  {
    // One of each character class
    regexp:
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])(?=.*[~!@#$%^&*()\-_=+[\]{}|;:,.<>/?])/,
    ...passwordVerifyMessage(t)
  },
  {
    // No more than two repeated characters
    regexp: /^(?!.*(.).*\1.*\1)/,
    ...passwordVerifyMessage(t)
  },
  {
    // Minimum 8 chars (and max 255)
    regexp: /^(.){8,255}$/,
    ...passwordVerifyMessage(t)
  },
  (pword) => {
    // At least 5 unique characters
    // Also possible with a regex. Could only happen with 8 characters.
    let response
    const charArray = []
    for (let i = 0; i < pword.length; i += 1) {
      if (charArray.indexOf(pword[i]) === -1) {
        charArray.push(pword[i])
      }
    }
    if (charArray.length < 5) {
      response = {
        ...passwordVerifyMessage
      }
    }
    return response
  }
]

export const confirmPasswordsMatch = (newPassword, confirmPassword, t) => {
  return newPassword === confirmPassword
    ? undefined
    : { message: t('common:password_not_match'), status: 'error' }
}

export const validateLength = (t, value, maxLength, required = false) => {
  if (required && !value?.trim()?.length) {
    return t('iam:required_label')
  }
  return value?.trim()?.length <= maxLength
    ? true
    : t('manage:org_workspace_edit_validation.validate_length')
}

export const maxLengthCheck = (value, maxLength) => {
  return value.length <= maxLength ? value : value.slice(0, maxLength)
}

// For the Wizard, handle both local validation and server-based (REST) validation. Return
// a Promise for both, localizing the error messages and convert them into an Error object.
// This allows the Wizard to handle all cases in the same manner.
export const validateForm = (
  formValues,
  i18nTranslate,
  localValidation,
  serverValidation
) => {
  if (localValidation) {
    const validation = localValidation(formValues, i18nTranslate)
    // if local validation fails localize the message and return a Promise
    if (!validation.isValid) {
      let errorMessage = validation.message
      if (!errorMessage) {
        // assign the generic message for the form level error
        errorMessage = i18nTranslate('common:form_error')
      }
      return Promise.reject(new Error(errorMessage))
    }
  }

  // local validation was successful -- try REST-based validation
  if (serverValidation) {
    return new Promise((resolve, reject) => serverValidation(resolve, reject))
  }

  // no server-based validation -- return success
  return Promise.resolve({ message: 'success' })
}

export const validateSerialNumber = (serialNumber) => {
  // TODO: need to replace once validations are finalized
  return serialNumber.length > 1 && !serialNumber.startsWith(' ')
}

export const validateSubscriptionKey = (subscriptionKey) => {
  // TODO: need to replace once validations are finalized
  return subscriptionKey.length > 1 && !subscriptionKey.startsWith(' ')
}

export const validLicenseKey = (key) => {
  const minLength = 3
  const maxLength = 50
  const regexp = new RegExp(`^[a-z,A-Z,0-9]{${minLength},${maxLength}}$`)
  return regexp.test(key)
}

export const validateMACAddress = (macAddress) => {
  const regexp = new RegExp(`^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$`)
  return regexp.test(macAddress)
}

export const validateCloudActivationKey = (cloudActivationKey) => {
  const minLength = 3
  const maxLength = 50
  const regexp = new RegExp(`^[A-Za-z0-9-]{${minLength},${maxLength}}$`)
  return regexp.test(cloudActivationKey)
}

export const validateEmail = (email, message) => {
  if (!string().email().isValidSync(email)) {
    return message || 'Invalid email format'
  }
  return true
}

export const validatePhoneNumber = (phone) => {
  const res = phone.replace(/\s/g, '')
  if (!/^\+?[\d]+$/.test(res) || res.length > 30) {
    return false
  }
  return true
}

export const validatePortNumber = (portNumber, msg) => {
  // regex to validate 1-65535 as valid portnumbers
  const regexp = new RegExp(
    '^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$'
  )
  return regexp.test(portNumber) ? undefined : { message: msg, status: 'error' }
}

export const validateIpOrHostname = (input, msg) => {
  const hostNameRegExp = new RegExp(
    '^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])$'
  )

  return hostNameRegExp.test(input) ||
    /(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(
      input
    )
    ? undefined
    : { message: msg, status: 'error' }
}

export const validateProductID = (productID) => {
  // check for non empty product id value
  return productID?.length > 1 && !productID?.startsWith(' ')
}

export const validateCustomerID = (customerID) => {
  const minLength = 3
  const maxLength = 20
  const regexp = new RegExp(`^[A-Za-z0-9-]{${minLength},${maxLength}}$`)
  return regexp.test(customerID)
}

export const validateCompanyName = (name) => {
  // company name length should be less than 100
  const maxLength = 100
  return name.length > maxLength
}

export const validateName = (name, msg) => {
  // regex to match alpha numeric & special characters - !@#$%^&*()_-+={}[].:;<>~,|`
  const regexp = new RegExp(/^[A-Za-z0-9!@#$%^&*()_+={[}\]:;<>,|`~.-]{3,50}$/)
  return regexp.test(name) ? undefined : { message: msg, status: 'error' }
}

export const validateFieldWithEmptySpaces = (value, msg) => {
  return value.trim().length > 0 ? undefined : { message: msg, status: 'error' }
}

export const validateUser = (user, msg) => {
  // regex to match alpha numeric & special characters - !@#$%^&*()_-+={}[].:;<>~,|`
  const regexp = new RegExp(/^[A-Za-z0-9!@#$%^&*()_+={[}\]:;<>,|`~.-]{3,128}$/)
  return regexp.test(user) ? undefined : { message: msg, status: 'error' }
}

export const validateUserPassword = (pwd, msg) => {
  // regex to match alpha numeric & special characters - !@#$%^&*()_-+={}[].:;<>~,|`
  const regexp = new RegExp(/^[A-Za-z0-9!@#$%^&*()_+={[}\]:;<>,|`~.-]*$/)
  return regexp.test(pwd) ? undefined : { message: msg, status: 'error' }
}

export const validatePreSharedKey = (key, msg) => {
  // regex to match alpha numeric charcters
  const minLength = 3
  const maxLength = 50
  const regexp = new RegExp(`^[A-Za-z0-9-]{${minLength},${maxLength}}$`)
  return regexp.test(key) ? undefined : { message: msg, status: 'error' }
}

export const confirmPreSharedKeyMatch = (key, confirmKey, msg) => {
  return key === confirmKey ? undefined : { message: msg, status: 'error' }
}

export const validateIPAddress = (ipAddress) => {
  // regex to match IP address
  const expression =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  if (expression.test(ipAddress)) return undefined
  return { message: 'Invalid IP Address Format', status: 'error' }
}

export const validateSubnetMask = (subnetMask) => {
  // regex to match IPv4 subnet mask
  const expression =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([1-9]|1\d|2\d|3[0-2])$/
  if (expression.test(subnetMask)) return undefined
  return { message: 'Invalid Subnet Mask Format', status: 'error' }
}

export const validateIPv6Address = (ipAddress) => {
  // regex to match IPv6 address, matches regular and condensed
  const hex = `[0-9A-Fa-f]{1,4}`
  const expression = `^(((${hex}:){7}(${hex}|:))|((${hex}:){6}(:${hex}|:))|((${hex}:){5}(((:${hex}){1,2})|:))|((${hex}:){4}(((:${hex}){1,3})|:))|((${hex}:){3}(((:${hex}){1,4})|:))|((${hex}:){2}(((:${hex}){1,5})|:))|((${hex}:){1}(((:${hex}){1,6})|:))|(:(((:${hex}){1,7})|:)))$`
  const regExp = new RegExp(expression)
  if (ipAddress && ipAddress.match(regExp)) return undefined
  return { message: 'Invalid IPv6 Address Format', status: 'error' }
}

export const validateIpv6SubnetMask = (subnetMask) => {
  // regex to match IPv6 subnet mask, matches regular and condensed
  const hex = `[0-9A-Fa-f]{1,4}`
  const expression = `^(((${hex}:){7}(${hex}|:))|((${hex}:){6}(:${hex}|:))|((${hex}:){5}(((:${hex}){1,2})|:))|((${hex}:){4}(((:${hex}){1,3})|:))|((${hex}:){3}(((:${hex}){1,4})|:))|((${hex}:){2}(((:${hex}){1,5})|:))|((${hex}:){1}(((:${hex}){1,6})|:))|(:(((:${hex}){1,7})|:)))/([1-9]|[1-9]\\d|1[0-1]\\d|12[0-8])$`
  const regExp = new RegExp(expression)
  if (subnetMask && subnetMask.match(regExp)) return undefined
  return { message: 'Invalid IPv6 Subnet Mask Format', status: 'error' }
}

export const validatePartNumber = (partNumber) => {
  // regex to match alpha numeric charcters
  const minLength = 3
  const maxLength = 50
  const regexp = new RegExp(`^[A-Za-z0-9-]{${minLength},${maxLength}}$`)
  return regexp.test(partNumber)
}
export const validateTenantName = (name) => {
  // Tenant name length should be less than 100
  const maxLength = 100
  return name?.length > maxLength
}

export const validateDescription = (description) => {
  // Description length should be less than 100
  const maxLength = 100
  return description.length > maxLength
}

export const validateZipCode = (zipcode) => {
  const maxLength = 10
  return maxLength >= zipcode?.length
}

export const validateStreetAddress = (address) => {
  const maxLength = 220
  return maxLength >= address?.length
}

export const validateCity = (city) => {
  const maxLength = 40
  return maxLength >= city?.length
}

export const validateOTP = (otp) => {
  const regexp = new RegExp(`^[0-9]{6}$`)
  return regexp.test(otp)
}

export const validateIPRange = (ipRange) => {
  if (
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(3[0-2]|[1-2][0-9]|[0-9]))$/.test(
      ipRange
    )
  )
    return undefined
  return {
    message: 'Invalid IP Address Range. Expected format x.x.x.x/x',
    status: 'error'
  }
}

export const validateUserInput = (userInput) => {
  if (/^[^<>(){}]*$/.test(userInput)) return undefined
  return {
    message: 'Invalid Characters',
    status: 'error'
  }
}

export const validateUserInputStrict = (userInput) => {
  if (/^[^<>(){}`$\\#]*$/.test(userInput)) return undefined
  return {
    message: 'Invalid Characters',
    status: 'error'
  }
}

export const validateFileInput = (value, maxSize, allowedTypes, t) => {
  if (!value) return true
  if (typeof value === 'object') {
    if (value.size > maxSize) return t('common:file_max_size_msg')
    if (!allowedTypes.includes(value.type)) return 'Invalid file type'
  }
  return true
}

export const getPassowrdGuideLinesMessage = (passwordGuideLines, t) => {
  const getPermittedSymbols = () => {
    return (
      passwordGuideLines?.permitted_symbols || t('common:all_symbols_message')
    )
  }

  const passwordObject = {
    numerals: t('common:numerals'),
    symbols: t('common:symbols', {
      permittedSymbols: getPermittedSymbols()
    }),
    uppercase: t('common:uppercase'),
    lowercase: t('common:lowercase')
  }
  let passwordClasses = ''
  const passwordComplexicityLength =
    passwordGuideLines?.password_complexity?.length
  if (passwordGuideLines?.password_length && passwordComplexicityLength) {
    passwordGuideLines.password_complexity.forEach((element, index) => {
      if (passwordComplexicityLength === 1) {
        passwordClasses = `${passwordClasses}${passwordObject[element]}`
      } else if (passwordComplexicityLength - 1 === index) {
        const conjunction = 'and '
        passwordClasses = `${passwordClasses}${conjunction}${passwordObject[element]}`
      } else {
        const conjunction = ', '
        passwordClasses = `${passwordClasses}${passwordObject[element]}${conjunction}`
      }
    })
  }
  let guidelineMessage = ''
  if (passwordGuideLines?.password_length && passwordComplexicityLength) {
    guidelineMessage = t('common:password_case1', {
      passwordLength: passwordGuideLines?.password_length,
      complexityLength: passwordGuideLines?.password_complexity.length,
      passwordClass: passwordClasses
    })
  } else if (passwordGuideLines.password_length) {
    guidelineMessage = t('common:password_case2', {
      passwordLength: passwordGuideLines?.password_length
    })
  } else {
    guidelineMessage = t('common:password_case3', {
      passwordLength: passwordGuideLines?.password_length,
      symbols: passwordObject.symbols
    })
  }
  return guidelineMessage
}

export const validateNumberRange = (number, start, end, message) => {
  if (number >= start && number <= end) return undefined
  return { message, status: 'error' }
}

export const validateMaxLength = (value, maxLength, message) => {
  return value?.trim()?.length <= maxLength
    ? undefined
    : { message, status: 'error' }
}

export const ALLOWED_IMAGE_TYPES = ['image/png', 'image/jpeg']
export const WHATS_NEW_ALLOWED_IMAGE_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg'
]
export const WHATS_NEW_ALLOWED_VIDEO_TYPE = ['video/mp4']
export const IMAGE_FILE_SIZE = { logo: 1024 * 1024, favicon: 1024 * 256 }

export const validateIdleSessionTimeout = (
  value,
  minLength,
  maxLength,
  message
) => {
  return value >= minLength && value <= maxLength
    ? undefined
    : { message, status: 'error' }
}

export const validateHttpProtocol = (url) => {
  if (typeof url !== 'string') return false
  return url?.indexOf('http://') === 0 || url?.indexOf('https://') === 0
}

export const validateTenantDescription = (value, maxLength, message) => {
  return value?.trim()?.length <= maxLength
    ? { message, status: 'info' }
    : { message, status: 'error' }
}

export const validateOrganizationName = (name, msg) => {
  const maxOrgNameLength = CHARACTER_LENGTH?.ORG_NAME
  const regexp = new RegExp(`^[^<>(){}]{0,${maxOrgNameLength}}$`)
  return regexp.test(name) ? { message: msg, status: 'error' } : undefined
}

export const validateOrganizationDescription = (name, msg) => {
  const maxOrgDescLength = CHARACTER_LENGTH?.ORG_DESC
  const regexp = new RegExp(`^[^<>(){}]{0,${maxOrgDescLength}}$`)
  return regexp.test(name) ? { message: msg, status: 'error' } : undefined
}

export const validateOrganizationPhoneNumber = (phone) => {
  const maxOrgPhoneLength = CHARACTER_LENGTH?.ORG_PHONE
  const regexp = new RegExp(`^[0-9+() .-]{0,${maxOrgPhoneLength}}$`)
  if (!regexp.test(phone) || phone.length > maxOrgPhoneLength) {
    return false
  }
  return true
}

export const validateReportName = (name, t) => {
  const regexp1 = new RegExp(
    `<script\\b[^<]*(?:(?!<\\/script>)<[^<]*)*<\\/script>`,
    'gi'
  )
  const regexp2 = new RegExp(`[<>]`, 'g')
  if (regexp1.test(name) || regexp2.test(name) || validateHttpProtocol(name)) {
    return t('reporting_dashboard:create_report.invalid_report_name')
  }
  return true
}
