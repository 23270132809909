import React from 'react'
import PropTypes from 'prop-types'

export const EmailSent = ({ fillColor, strokeColor, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="email-sent-svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.4 4L72.3229 4L72.3229 4C71.2686 3.99997 70.3591 3.99994 69.6099 4.06114C68.8193 4.12574 68.0328 4.26838 67.2761 4.65396C66.1471 5.2292 65.2292 6.14709 64.654 7.27606C64.2684 8.03282 64.1257 8.81926 64.0611 9.60995C63.9999 10.3591 64 11.2686 64 12.3229L64 12.3229L64 12.4V35.6L64 35.6771C64 36.7314 63.9999 37.6409 64.0611 38.3901C64.1257 39.1808 64.2684 39.9672 64.654 40.7239C65.2292 41.8529 66.1471 42.7708 67.2761 43.346C68.0328 43.7316 68.8193 43.8743 69.6099 43.9389C70.3591 44.0001 71.2686 44 72.3229 44H72.4H99.6H99.6771C100.731 44 101.641 44.0001 102.39 43.9389C103.181 43.8743 103.967 43.7316 104.724 43.346C105.853 42.7708 106.771 41.8529 107.346 40.7239C107.732 39.9672 107.874 39.1808 107.939 38.3901C108 37.6409 108 36.7314 108 35.6771V35.6V12.4V12.3229C108 11.2686 108 10.3591 107.939 9.60995C107.874 8.81926 107.732 8.03282 107.346 7.27606C106.771 6.14709 105.853 5.2292 104.724 4.65396C103.967 4.26838 103.181 4.12574 102.39 4.06114C101.641 3.99993 100.731 3.99997 99.6771 4L99.6 4H72.4ZM69.092 8.21799C69.1909 8.1676 69.3923 8.09225 69.9357 8.04786C70.5023 8.00156 71.2469 8 72.4 8H99.6C100.753 8 101.498 8.00156 102.064 8.04786C102.608 8.09225 102.809 8.1676 102.908 8.21799C103.284 8.40974 103.59 8.7157 103.782 9.09202C103.8 9.12765 103.822 9.1766 103.844 9.25005L85.9238 25.3778L68.0052 11.0429C68.011 10.5981 68.0229 10.2411 68.0479 9.93568C68.0922 9.39235 68.1676 9.19091 68.218 9.09202C68.4097 8.7157 68.7157 8.40974 69.092 8.21799ZM68 16.1613V35.6C68 36.7531 68.0016 37.4977 68.0479 38.0643C68.0922 38.6077 68.1676 38.8091 68.218 38.908C68.4097 39.2843 68.7157 39.5903 69.092 39.782C69.1909 39.8324 69.3923 39.9078 69.9357 39.9521C70.5024 39.9984 71.2469 40 72.4 40H99.6C100.753 40 101.498 39.9984 102.064 39.9521C102.608 39.9078 102.809 39.8324 102.908 39.782C103.284 39.5903 103.59 39.2843 103.782 38.908C103.832 38.8091 103.908 38.6077 103.952 38.0643C103.998 37.4977 104 36.7531 104 35.6V14.4907L87.3379 29.4866L86.0762 30.6222L84.7506 29.5617L68 16.1613Z"
        fill={fillColor}
      />
      <path
        d="M50 12L2 12"
        stroke={strokeColor}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M50 24L26 24"
        stroke={strokeColor}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M50 36L38 36"
        stroke={strokeColor}
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  )
}

EmailSent.propTypes = {
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
}

EmailSent.defaultProps = {
  fillColor: 'black',
  strokeColor: 'black',
  width: '108',
  height: '48'
}
