// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
// These Enums is to verify the GTS Status during polling or loading the workspace.
export const GTS_STATUS_ENUM = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  BLOCKED: 'BLOCKED',
  CONFIRMED_BLOCKED: 'CONFIRMED_BLOCKED',
  TIMEOUT: 'TIMEOUT'
}

export const WORKSPACE_STATUS_ENUM = {
  IN_PROGRESS: 'IN_PROGRESS'
}

// Function to anonymize user email
export const hashFunction = async (email) => {
  const msgUint8 = new TextEncoder().encode(`glpusr|-|${email}`) // encode as (utf-8) Uint8Array
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8) // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  const hashHex = hashArray
    .map((hash) => hash.toString(16).padStart(2, '0'))
    .join('') // convert bytes to hex string
  return hashHex
}
