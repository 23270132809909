import React, { useContext } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import { WizardContext } from '../../../../../../../../components/wizard/WizardContext'
import { Typography, ObjectList } from '../../../../../../../../components'

import {
  getStepNumber,
  getFormattedPartyDetails,
  getFormattedContactDetails
} from './utils'

const ReviewDetails = () => {
  const { t } = useTranslation(['manage'])
  const { formValues, setActiveStep, steps } = useContext(WizardContext)
  const {
    billingAccountName,
    billingAccountDescription,
    soldToPartyDetails,
    billToPartyDetails,
    payerPartyDetails,
    soldToContactDetails,
    billToContactDetails,
    payerContactDetails,
    useSoldToDetailsForBillToParty,
    useSoldToDetailsForPayerParty,
    useSoldToContactForBillTo,
    useSoldToContactForPayer
  } = formValues

  return (
    <Box width="100%" flex="grow" align="start" margin={{ bottom: 'medium' }}>
      <Box direction="column" justify="between" align="start">
        <Box width="medium">
          <ObjectList
            testId="billing-account-details"
            data={{
              [t(
                'billing_accounts.create_billing_account_wizard.billingAccountName'
              )]: billingAccountName,
              [t(
                'billing_accounts.create_billing_account_wizard.billingAccountDescription'
              )]: billingAccountDescription
            }}
            title={t(
              'billing_accounts.create_billing_account_wizard.billing_account_details_step_title'
            )}
            editAction={() => setActiveStep(1)}
            editBtnTitle={t(
              'billing_accounts.create_billing_account_wizard.edit'
            )}
          />
        </Box>

        <Box width="medium">
          <ObjectList
            testId="sold-to-party-details"
            data={getFormattedPartyDetails(
              {
                ...soldToPartyDetails,
                country: soldToPartyDetails?.country?.name
              },
              t
            )}
            title={t(
              'billing_accounts.create_billing_account_wizard.sold_to_party_details_step_title'
            )}
            editAction={() => setActiveStep(2)}
            editBtnTitle={t(
              'billing_accounts.create_billing_account_wizard.edit'
            )}
          />

          <Typography type="text" size="medium">
            {`${t(
              'billing_accounts.create_billing_account_wizard.use_sold_to_party_details_for_bill_to_party'
            )}: ${useSoldToDetailsForBillToParty ? 'Yes' : 'No'}`}
          </Typography>

          <Typography type="text" size="medium">
            {`${t(
              'billing_accounts.create_billing_account_wizard.use_sold_to_party_details_for_payer_party'
            )}: ${useSoldToDetailsForPayerParty ? 'Yes' : 'No'}`}
          </Typography>
        </Box>

        {!useSoldToDetailsForBillToParty && (
          <Box width="medium">
            <ObjectList
              testId="bill-to-party-details"
              data={getFormattedPartyDetails(
                {
                  ...billToPartyDetails,
                  country: billToPartyDetails?.country?.name
                },
                t
              )}
              title={t(
                'billing_accounts.create_billing_account_wizard.bill_to_party_details_step_title'
              )}
              editAction={() =>
                setActiveStep(getStepNumber('billToPartyStep', steps))
              }
              editBtnTitle={t(
                'billing_accounts.create_billing_account_wizard.edit'
              )}
            />
          </Box>
        )}

        {!useSoldToDetailsForPayerParty && (
          <Box width="medium">
            <ObjectList
              testId="bill-to-party-details"
              data={getFormattedPartyDetails(
                {
                  ...payerPartyDetails,
                  country: payerPartyDetails?.country?.name
                },
                t
              )}
              title={t(
                'billing_accounts.create_billing_account_wizard.payer_party_details_step_title'
              )}
              editAction={() =>
                setActiveStep(getStepNumber('payerPartyStep', steps))
              }
              editBtnTitle={t(
                'billing_accounts.create_billing_account_wizard.edit'
              )}
            />
          </Box>
        )}

        <Box width="medium">
          <ObjectList
            testId="sold-to-contact-details"
            data={getFormattedContactDetails(
              {
                ...soldToContactDetails,
                country: soldToContactDetails?.country?.name
              },
              t
            )}
            title={t(
              'billing_accounts.create_billing_account_wizard.sold_to_contact_details_step_title'
            )}
            editAction={() =>
              setActiveStep(getStepNumber('soldToContactStep', steps))
            }
            editBtnTitle={t(
              'billing_accounts.create_billing_account_wizard.edit'
            )}
          />

          <Typography type="text" size="medium">
            {`${t(
              'billing_accounts.create_billing_account_wizard.use_sold_to_contact_details_for_bill_to_contact'
            )}: ${
              useSoldToContactForBillTo
                ? t('billing_accounts.yes')
                : t('billing_accounts.no')
            }`}
          </Typography>

          <Typography type="text" size="medium">
            {`${t(
              'billing_accounts.create_billing_account_wizard.use_sold_to_contact_details_for_payer_contact'
            )}: ${
              useSoldToContactForPayer
                ? t('billing_accounts.yes')
                : t('billing_accounts.no')
            }`}
          </Typography>
        </Box>

        {!useSoldToContactForBillTo && (
          <Box width="medium">
            <ObjectList
              testId="bill-to-contact-details"
              data={getFormattedContactDetails(
                {
                  ...billToContactDetails,
                  country: billToContactDetails?.country?.name
                },
                t
              )}
              title={t(
                'billing_accounts.create_billing_account_wizard.bill_to_contact_details_step_title'
              )}
              editAction={() =>
                setActiveStep(getStepNumber('billToContactStep', steps))
              }
              editBtnTitle={t(
                'billing_accounts.create_billing_account_wizard.edit'
              )}
            />
          </Box>
        )}

        {!useSoldToContactForPayer && (
          <Box width="medium">
            <ObjectList
              testId="payer-contact-details"
              data={getFormattedContactDetails(
                {
                  ...payerContactDetails,
                  country: payerContactDetails?.country?.name
                },
                t
              )}
              title={t(
                'billing_accounts.create_billing_account_wizard.payer_contact_details_step_title'
              )}
              editAction={() =>
                setActiveStep(getStepNumber('payerContactStep', steps))
              }
              editBtnTitle={t(
                'billing_accounts.create_billing_account_wizard.edit'
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
export default ReviewDetails
