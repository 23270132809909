// Copyright 2024 Hewlett Packard Enterprise Development LP
import { DataTable, Box, Data } from 'grommet'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'
import clone from 'lodash/clone'
import isEmpty from 'lodash/isEmpty'

import { Typography, Loader } from '../../../../../components'
import { get } from '../../../../../utils/api-utils'

import {
  statusMapping,
  setFiltersInSession,
  getFilterFromSession
} from './utils'

const filterDocuments = (documents, filter) => {
  let filteredDocuments = clone(documents)
  const { search, properties } = filter
  if (properties) {
    const { folder_name, review_status } = properties
    if (review_status && review_status.length > 0) {
      filteredDocuments = documents.filter((doc) => {
        return review_status.includes(doc.review_status)
      })
    }
    if (folder_name) {
      filteredDocuments = filteredDocuments.filter((doc) => {
        return folder_name.includes(doc.folder_name)
      })
    }
  }

  if (search) {
    // The line below escapes regular expression special characters:
    // [ \ ^ $ . | ? * + ( )
    const escapedText = search.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')

    // Create the regular expression with modified value which
    // handles escaping special characters. Without escaping special
    // characters, errors will appear in the console
    const exp = new RegExp(escapedText, 'i')
    filteredDocuments = filteredDocuments.filter((doc) =>
      exp.test(doc.title.replace(/[\n\t]/g, '').replace(/\s{2,}/g, ' '))
    )
  }
  return filteredDocuments
}

const DocumentsList = () => {
  const { t } = useTranslation(['manage'])
  const history = useHistory()
  const { oidcUser } = useReactOidc()
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState([])
  const [filteredDocuments, setFilteredDocuments] = useState([])
  const [filters, setFilters] = useState({})

  const columns = [
    {
      property: 'title',
      header: (
        <Typography level="3" type="heading">
          {t('global_search_administration.documents_table.header_doc_title')}
        </Typography>
      )
    },
    {
      property: 'review_status',
      header: (
        <Typography level="3" type="heading">
          {t('global_search_administration.documents_table.header_doc_status')}
        </Typography>
      ),
      render: (datum) => {
        return (
          <Typography type="text">
            {statusMapping(t)[datum.review_status]}
          </Typography>
        )
      }
    },
    {
      property: 'type',
      header: (
        <Typography level="3" type="heading">
          {t('global_search_administration.documents_table.header_doc_type')}
        </Typography>
      )
    },
    {
      property: 'folder_name',
      header: (
        <Typography level="3" type="heading">
          {t('global_search_administration.documents_table.header_folder_name')}
        </Typography>
      )
    }
  ]

  const handleRowClick = ({ datum }) => {
    history.push(
      `/manage-ccs/search-administration/document-curation/${datum.id}`,
      { doc_type: datum.type }
    )
  }

  useEffect(() => {
    setLoading(true)
    get(
      '/global-search-administration/v1alpha1/curation/documents',
      {},
      oidcUser.access_token
    ).then((response) => {
      setDocuments(response.data.items)
      setFilteredDocuments(response.data.items)
      const view = getFilterFromSession()
      if (view && !isEmpty(view)) {
        setFilters(view)
        setFilteredDocuments(filterDocuments(response.data.items, view))
      }
      setFilters(view)
      setLoading(false)
    })
  }, [oidcUser.access_token])

  return (
    <Box fill>
      <Typography level="2" type="heading" margin="small">
        {t('global_search_administration.documents_table.title')}
      </Typography>

      {loading ? (
        <Box align="center">
          <Loader testId="loader" />
        </Box>
      ) : (
        <Box width="100%">
          <Data
            total={documents.length}
            toolbar
            data={filteredDocuments}
            view={filters}
            onView={(view) => {
              setFilters(view)
              setFilteredDocuments(filterDocuments(documents, view))
              setFiltersInSession(view)
            }}
            properties={{
              title: {
                search: true,
                filter: false
              },
              type: {
                label: t(
                  'global_search_administration.documents_table.header_doc_type'
                ),
                filter: true,
                search: false
              },
              review_status: {
                label: t(
                  'global_search_administration.documents_table.header_doc_status'
                ),
                filter: true,
                search: false,
                options: [
                  {
                    label: t('global_search_administration.status.pending'),
                    value: 'PENDING'
                  },
                  {
                    label: t('global_search_administration.status.approved'),
                    value: 'APPROVED'
                  },
                  {
                    label: t('global_search_administration.status.in_review'),
                    value: 'IN_REVIEW'
                  }
                ]
              },
              folder_name: {
                label: t(
                  'global_search_administration.documents_table.header_folder_name'
                ),
                filter: true,
                search: false
              }
            }}
          >
            <DataTable
              primaryKey="id"
              data-testid="curation-documents-list"
              fill
              step={20}
              onClickRow={handleRowClick}
              columns={columns}
            />
          </Data>
        </Box>
      )}
    </Box>
  )
}

export default DocumentsList
