import React from 'react'
import { StatusGoodSmall } from 'grommet-icons'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { Typography } from '../../../components'

import { ldFlagsStatusLang } from './utils'

const FeatureFlagsStatusCell = ({ flagStatusCellData }) => {
  return (
    <Box direction="row" gap="xsmall" style={{ alignItems: 'center' }}>
      <Box>
        {flagStatusCellData.status === 'ON' && (
          <StatusGoodSmall color="status-ok" data-testid="ld-on-icon" />
        )}
      </Box>
      <Box data-testid="ld-status-cell-content">
        {ldFlagsStatusLang[flagStatusCellData.status]}
      </Box>
      <Box data-testid="ld-status-cell-has-rules">
        <Typography type="text" size="small" emphasis>
          {flagStatusCellData.hasRules ? ldFlagsStatusLang.rules : null}
        </Typography>
      </Box>
    </Box>
  )
}

FeatureFlagsStatusCell.propTypes = {
  flagStatusCellData: PropTypes.object.isRequired
}
export default FeatureFlagsStatusCell
