import { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { buildDateRange } from '@glcp/coveo-headless'
import { SelectMultiple, Form, FormField, CheckBoxGroup } from 'grommet'
import { useTranslation } from 'react-i18next'

import { getDaysBetweenDates } from '../../utils/date-utils'

const getKeyForRange = (value) => {
  return `[${value.start}..${value.end}]`
}

const generateDateRange = (daysAgo) => {
  const currentDate = new Date()
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - daysAgo
  )
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 1
  )

  return buildDateRange({ start: startDate, end: endDate })
}

const DateFacet = ({ controller, title, facetFlag }) => {
  const { t } = useTranslation(['support_hub_search', 'location'])
  const dateFormat = 'YYYY/MM/DD@HH:mm:ss'
  const [state, setState] = useState(controller.state)

  useEffect(() => {
    const unsubscribe = controller.subscribe(() => setState(controller.state))
    return () => {
      unsubscribe()
    }
  }, [controller])

  const handleDateFacetChange = (selectedOptions) => {
    controller.state.values.forEach((facetValue) => {
      const isSelected = controller.isValueSelected(facetValue)
      const shouldSelect = selectedOptions.includes(getKeyForRange(facetValue))

      if (isSelected !== shouldSelect) {
        controller.toggleSelect(facetValue)
      }
    })
  }

  const getLabel = useCallback(
    (option) => {
      const valueObject = controller.state.values.find(
        (val) => getKeyForRange(val) === option
      )
      return valueObject
        ? t('last_no_days', {
            days: getDaysBetweenDates(valueObject.start, dateFormat)
          })
        : option
    },
    [controller.state.values, dateFormat, t]
  )

  if (
    !state.values.filter(
      (value) => value.state !== 'idle' || value.numberOfResults > 0
    ).length
  ) {
    return null
  }

  const selectedValues = controller.state.values
    .filter((value) => controller.isValueSelected(value))
    .map((value) => getKeyForRange(value))

  const options = controller.state.values
    .filter((value) => value.start && value.end)
    .map((value) => getKeyForRange(value))

  return (
    <Form>
      <FormField label={title} data-testid={title}>
        {!facetFlag ? (
          <SelectMultiple
            placeholder={t('location:select')}
            data-testid="date-facet-checkbox"
            labelKey={getLabel}
            value={selectedValues}
            options={options}
            onChange={(event) => {
              handleDateFacetChange(event.value)
            }}
            margin={facetFlag ? 'xsmall' : undefined}
            dropAlign={facetFlag ? { top: 'top' } : undefined}
            showSelectedInline={facetFlag}
          />
        ) : (
          <CheckBoxGroup
            data-testid="date-facet-checkbox"
            options={options.map((option) => ({
              label: getLabel(option),
              value: option
            }))}
            value={selectedValues}
            onChange={(event) => {
              handleDateFacetChange(event.value)
            }}
          />
        )}
      </FormField>
    </Form>
  )
}

const customDateRanges = [
  generateDateRange(7),
  generateDateRange(30),
  generateDateRange(60),
  generateDateRange(90)
]

DateFacet.propTypes = {
  controller: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  facetFlag: PropTypes.bool.isRequired
}

export { customDateRanges }
export default DateFacet
