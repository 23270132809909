import React, { useState, useEffect } from 'react'
import { Box, Tag, Image, Anchor, Video } from 'grommet'
import PropTypes from 'prop-types'
import { StatusCritical } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import isEmpty from 'lodash/isEmpty'

import {
  ModalDialog,
  Typography,
  ModalHeader,
  Notification,
  Loader
} from '../../../../../components'
import { get, getErrorMessage } from '../../../../../utils/api-utils'
import { getCustomerAccount } from '../../../../../utils/feature-flag-utils'
import {
  WHATS_NEW_ALLOWED_IMAGE_TYPES,
  WHATS_NEW_ALLOWED_VIDEO_TYPE
} from '../../../../../utils/validation-utils'

const PreviewPost = ({ item, closePreview }) => {
  const { t } = useTranslation(['common'])
  const { oidcUser } = useReactOidc()
  const custAccountLoaded = getCustomerAccount()
  const platformCustomerId = custAccountLoaded?.platform_customer_id || ''
  const [awsResponse, setawsResponse] = useState({})
  const [apiErrorMessage, setApiErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const operation = 'view'
    if (item?.media) {
      setLoading(true)
      get(
        `/whatsnew/v1/blog/medialink?op=${operation}&path=${item?.media}`,
        {},
        oidcUser.access_token,
        false,
        {},
        {
          'X-Account-ID': platformCustomerId
        }
      ).then(
        (response) => {
          if (response?.data) {
            const data = response?.data
            data.mediaType = item?.mediaType
            setawsResponse(data)
            setLoading(false)
          }
        },
        (error) => {
          const errorMessage = getErrorMessage(error, t)
          setApiErrorMessage(errorMessage)
          setLoading(false)
        }
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t])

  return (
    <Box>
      <ModalDialog
        width="large"
        testId="preview-model"
        header={
          <ModalHeader
            onClose={() => {
              closePreview()
            }}
          />
        }
        onClose={() => {}}
        content={
          <Box pad={{ horizontal: 'xxsmall', top: 'small' }} overflow="auto">
            {loading ? (
              <Box direction="row" align="center" justify="center">
                <Loader testId="programs-loader" />
              </Box>
            ) : (
              <Box pad="small" gap="small" height={{ min: 'auto' }}>
                {item?.publishDate && (
                  <Typography
                    color="text-weak"
                    size="small"
                    type="text"
                    testId="post-datetime"
                  >
                    {item?.publishedDate}
                  </Typography>
                )}
                <Box height={{ min: 'auto' }}>
                  <Typography
                    level="1"
                    type="heading"
                    testId="whatsnew-post-title"
                  >
                    {item?.title}
                  </Typography>
                </Box>
                <Box height={{ min: 'auto' }}>
                  <Typography
                    type="text"
                    size="medium"
                    testId="whatsnew-post-desc"
                  >
                    {item?.shortDescription}
                  </Typography>
                </Box>
                <Box
                  data-testid="media-render-component"
                  height={{ min: 'auto' }}
                >
                  {WHATS_NEW_ALLOWED_IMAGE_TYPES.includes(
                    awsResponse?.mediaType
                  ) && <Image fit="cover" src={awsResponse?.signedURL} />}
                  {WHATS_NEW_ALLOWED_VIDEO_TYPE.includes(
                    awsResponse?.mediaType
                  ) && (
                    <Video
                      controls="over"
                      fit="contain"
                      autoPlay={false}
                      alignSelf="center"
                    >
                      <source
                        src={awsResponse?.signedURL}
                        type={awsResponse?.mediaType}
                      />
                    </Video>
                  )}
                </Box>
                {!isEmpty(item.referenceLinks) && (
                  <Box width="auto" direction="column" height={{ min: 'auto' }}>
                    <Typography
                      type="heading"
                      testId="reference-link-title"
                      level="2"
                    >
                      {t('whats_new.reference_link_title')}
                    </Typography>
                    <Box direction="column" data-testid="reference-links">
                      {item.referenceLinks.map((element, index) => (
                        <Anchor
                          label={element?.label}
                          href={element?.link}
                          target="_blank"
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                        />
                      ))}
                    </Box>
                  </Box>
                )}
                <Box
                  width="auto"
                  direction="row-responsive"
                  height={{ min: 'auto' }}
                  pad={{ vertical: 'small' }}
                  gap="small"
                >
                  {item.serviceCategory &&
                    item.serviceCategory.map((serviceCategory) => (
                      <Tag
                        name={serviceCategory}
                        data-testid={`service-value-${serviceCategory}`}
                      />
                    ))}
                </Box>
              </Box>
            )}
          </Box>
        }
      />
      {apiErrorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setApiErrorMessage(null)}
          testId="critical-inline-notification"
          text={apiErrorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}
PreviewPost.propTypes = {
  item: PropTypes.object.isRequired,
  closePreview: PropTypes.func.isRequired
}
export default PreviewPost
