import { VisibilityActions } from '../../../context/visibility-context'
import { get } from '../../../utils/api-utils'
import { getApiErrorMessage } from '../../../utils/error-handling-utils'
import { setCustomerAccount } from '../../../utils/feature-flag-utils'

export const loadCurrentCustomerAccount = (
  custAccount,
  oidcUser,
  setErrorMessage,
  t,
  dispatchVisibilityContext
) => {
  return new Promise((resolve, reject) => {
    get(
      `/accounts/ui/v1/user/load-account/${custAccount?.platform_customer_id}`,
      {},
      oidcUser.access_token
    ).then(
      () => {
        setCustomerAccount(custAccount)
        dispatchVisibilityContext({
          type: VisibilityActions.SET_HIDE_WHEN,
          data: {
            key: 'account',
            value: custAccount.account_type
          }
        })
        resolve()
      },
      (error) => {
        setErrorMessage(getApiErrorMessage(error, t))
        reject(error)
      }
    )
  })
}

export const launchApplication = (
  instanceId,
  redirectUrl,
  bearerToken,
  accountType,
  setError,
  t,
  setLaunchingApp
) => {
  setLaunchingApp(true)
  get(
    `/ui-doorway/ui/v1/cop/app-onboard/login-url`,
    {
      ...(redirectUrl && { query_url: redirectUrl }),
      ...(instanceId && { instance_id: instanceId })
    },
    bearerToken
  ).then(
    (response) => {
      const { application_customer_id: customerId, login_url: loginURL } =
        response.data
      if (loginURL) {
        sessionStorage.removeItem('redirect-query')

        sessionStorage.setItem('cop-central-url', loginURL)
        sessionStorage.removeItem('from-central')

        window.location.replace(
          `${loginURL}?cid=${customerId}&account_type=${accountType}`
        )
      }
    },
    (error) => {
      sessionStorage.removeItem('redirect-query')
      setError(getApiErrorMessage(error, t))
      setLaunchingApp(false)
    }
  )
}
