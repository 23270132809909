// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
  Redirect
} from 'react-router-dom'
import { React, Suspense, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import LazyLoading from '../commoncomponents/LazyLoading/LazyLoading'
import { checkGotoChooseAccount } from '../../utils/common-utils'
import { isInventoryOwnedWorkspace } from '../../utils/feature-flag-utils'
import LicenseDetailsPage from '../manage-account/subscriptions/pages/licenses/license-details/LicenseDetails'

const DeviceManagementPage = lazy(() => import('./DeviceManagement'))
const AssignToApplication = lazy(() =>
  import('./assign-to-application/AssignToApplication')
)
const DeviceDetailsPage = lazy(() => import('./device-details/DeviceDetails'))
const ApplySubscriptionWizardFromDevices = lazy(() =>
  import('./apply-subscription/ApplySubscriptionWizardFromDevices')
)
const NewApplySubscription = lazy(() =>
  import('./apply-subscription/steps/NewApplySubscription')
)
const AddDeviceWizard = lazy(() => import('./add-devices/AddDeviceWizard'))
const AddDeviceSubscriptionWizard = lazy(() =>
  import('./add-device-subscriptions/AddDeviceSubscriptionWizard')
)
const ReplaceSubscription = lazy(() =>
  import('./replace-subscription/ReplaceSubscription')
)

const DeviceManagementRouter = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const LDFlags = useFlags()
  const { pathname } = useLocation()
  checkGotoChooseAccount(history)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (path === '/manage-account/device-management') {
      if (pathname.includes('/manage-account/device-management/devices')) {
        const uiPath = pathname.replace(
          '/manage-account/device-management/devices',
          '/devices/inventory'
        )
        history.push(uiPath)
      } else {
        const uiPath = pathname.replace(
          '/manage-account/device-management',
          '/devices'
        )
        history.push(uiPath)
      }
    }
  }, [path, pathname, history])

  return (
    <Suspense fallback={<LazyLoading />}>
      {isInventoryOwnedWorkspace() ? (
        <Switch>
          <Route exact path={`${path}/subscriptions/:licenseKey`}>
            <LicenseDetailsPage />
          </Route>
          <Route path={`${path}/inventory/add-devices`}>
            <AddDeviceWizard />
          </Route>
          <Route path={`${path}/inventory/add-device-subscriptions`}>
            <AddDeviceSubscriptionWizard />
          </Route>
          <Route path={`${path}/inventory/assign-to-application`}>
            <AssignToApplication />
          </Route>
          <Route path={`${path}/inventory/replace-subscription`}>
            <ReplaceSubscription />
          </Route>
          <Route exact path={`${path}/inventory/apply-subscription`}>
            {LDFlags['glcp-multi-license-support'] ? (
              <NewApplySubscription />
            ) : (
              <ApplySubscriptionWizardFromDevices />
            )}
          </Route>
          <Route
            exact
            path={`${path}/inventory/:colonDelimitSerialPartDevicetype`}
          >
            <DeviceDetailsPage />
          </Route>
          {/* NOTE: Always add any routes above this route */}
          <Route path={`${path}`}>
            <DeviceManagementPage />
          </Route>
        </Switch>
      ) : (
        <Redirect
          to={{
            pathname: '/home',
            errorMessage: t('permission_denied')
          }}
        />
      )}
    </Suspense>
  )
}

export default DeviceManagementRouter
