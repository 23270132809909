// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import dayjs from 'dayjs'
import omit from 'lodash/omit'
import lodashGet from 'lodash/get'

import { get, getErrorMessage } from '../../../../utils/api-utils'
import { getApiErrorMessage } from '../../../../utils/error-handling-utils'

export const getActivateOrders = async (
  request,
  accessToken,
  setTotalItems,
  setErrorMessage,
  t
) => {
  const url = `/support-assistant/v1alpha1/orders`
  return get(url, request, accessToken).then(
    (resp) => {
      setErrorMessage(null)
      return resp.data
    },
    (err) => {
      setErrorMessage(getErrorMessage(err, t))
      setTotalItems(0)
      return err
    }
  )
}

export const getUsers = async (
  request,
  accessToken,
  setTotalItems,
  setErrorMessage,
  t
) => {
  const url = `/support-assistant/v1alpha1/users`
  return get(url, request, accessToken).then(
    (resp) => {
      resp.data.users = resp.data.users.map((r) => {
        return {
          email: r.contact.email,
          first_name: r.contact.first_name,
          last_name: r.contact.last_name,
          user_status: r.user_status,

          street_address: r.contact.address
            ? r.contact.address.street_address
            : '',
          city: r.contact.address ? r.contact.address.city : '',
          state_or_region: r.contact.address
            ? r.contact.address.state_or_region
            : '',
          zip: r.contact.address ? r.contact.address.zip : '',
          country_code: r.contact.address ? r.contact.address.country_code : '',

          nickname: r.contact.nickname,
          phone_number: r.contact.phone_number,

          created_by: r.created_by,

          language: r.preferences ? r.preferences.language : '',
          timezone: r.preferences ? r.preferences.timezone : '',
          idle_timeout: r.preferences ? r.preferences.idle_timeout : '',
          maintenance_notifications: r.preferences
            ? r.preferences.maintenance_notifications
            : '',
          update_notifications: r.preferences
            ? r.preferences.update_notifications
            : '',
          multi_fa_enabled: r.preferences ? r.preferences.multi_fa_enabled : '',
          theme: r.theme,
          user_type: r.user_type,
          created_at: !r.created_at
            ? r.created_at
            : dayjs(r.created_at).format('MM/DD/YYYY HH:mm'),
          updated_at: !r.updated_at
            ? r.updated_at
            : dayjs(r.updated_at).format('MM/DD/YYYY HH:mm')
        }
      })
      setErrorMessage(null)
      return resp.data
    },
    (err) => {
      setErrorMessage(getErrorMessage(err, t))
      setTotalItems(0)
      return {
        users: [],
        pagination: {
          total_count: 0
        }
      }
    }
  )
}

export const getSubscriptions = async (
  request,
  accessToken,
  setTotalItems,
  setErrorMessage,
  t
) => {
  const url = `/support-assistant/v1alpha1/subscriptions`
  return get(url, request, accessToken).then(
    (resp) => {
      resp.data.subscriptions = resp.data.subscriptions.map((r) => {
        return {
          ...omit(r, [
            'appointments',
            'supported_device_types',
            'attributes',
            'support_attributes',
            'product_attributes',
            'application_instances',
            'parties',
            'onprem_workspace_rid',
            'key_type',
            'resource_id'
          ]),
          start_date: !r?.appointments?.subscription_start
            ? r?.appointments?.subscription_start
            : dayjs(r?.appointments?.subscription_start).format(
                'MM/DD/YYYY HH:mm'
              ),
          end_date: !r?.appointments?.subscription_end
            ? r?.appointments?.subscription_end
            : dayjs(r?.appointments?.subscription_end).format(
                'MM/DD/YYYY HH:mm'
              ),
          activation_date: !r?.appointments?.activation_date
            ? r?.appointments?.activation_date
            : dayjs(r?.appointments?.activation_date).format(
                'MM/DD/YYYY HH:mm'
              ),
          suspension_date: !r?.appointments?.suspension_date
            ? r?.appointments?.suspension_date
            : dayjs(r?.appointments?.suspension_date).format(
                'MM/DD/YYYY HH:mm'
              ),
          reactivation_date: !r?.appointments?.reactivation_date
            ? r?.appointments?.reactivation_date
            : dayjs(r?.appointments?.reactivation_date).format(
                'MM/DD/YYYY HH:mm'
              ),
          cancellation_date: !r?.appointments?.cancellation_date
            ? r?.appointments?.cancellation_date
            : dayjs(r?.appointments?.cancellation_date).format(
                'MM/DD/YYYY HH:mm'
              ),
          delayed_activation_date: !r?.appointments?.delayed_activation_date
            ? r?.appointments?.delayed_activation_date
            : dayjs(r?.appointments?.delayed_activation_date).format(
                'MM/DD/YYYY HH:mm'
              ),
          supported_device_types: r?.supported_device_types?.join(', '),
          attributes: r?.attributes,
          support_attributes: r?.support_attributes,
          product_attributes: r?.product_attributes,
          application_instances: r?.application_instances,
          parties: r?.parties,
          end_date_in_millis: r?.appointments?.subscription_end,
          start_date_in_millis: r?.appointments?.subscription_start
        }
      })
      setErrorMessage(null)
      return resp.data
    },
    (err) => {
      setErrorMessage(getErrorMessage(err, t))
      setTotalItems(0)
      return {
        subscriptions: [],
        pagination: {
          total_count: 0
        }
      }
    }
  )
}

export const getProvisions = async (
  request,
  accessToken,
  setTotalItems,
  setErrorMessage,
  t
) => {
  const url = `/support-assistant/v1alpha1/customer-provisions`
  return get(url, request, accessToken).then(
    (resp) => {
      resp.data.provisions = resp.data.provisions.map((p) => {
        return {
          username: p.username,
          provision_status: p.provision_status,
          region: p.region,
          application_id: p.application_id,
          platform_customer_id: p.platform_customer_id,
          application_instance_id: p.application_instance_id,
          application_customer_id: p.application_customer_id,
          application_name: p.application_name,
          application_instance_name: p.application_instance_name
        }
      })
      setErrorMessage(null)
      return resp.data
    },
    (err) => {
      setErrorMessage(getErrorMessage(err, t))
      setTotalItems(0)
      return {
        provisions: [],
        pagination: {
          total_count: 0
        }
      }
    }
  )
}

export const getFolders = async (
  request,
  accessToken,
  setTotalItems,
  setErrorMessage,
  t
) => {
  const url = `/support-assistant/v1alpha1/user-folders`
  return get(url, request, accessToken).then(
    (resp) => {
      setErrorMessage(null)
      return resp.data
    },
    (err) => {
      setErrorMessage(getErrorMessage(err, t))
      setTotalItems(0)
      return {
        provisions: [],
        pagination: {
          total_count: 0
        }
      }
    }
  )
}

export const getAliases = async (request, accessToken, setErrorMessage, t) => {
  const url = `/support-assistant/v1alpha1/aliases`
  return get(url, request, accessToken).then(
    (resp) => {
      return resp.data
    },
    (err) => {
      setErrorMessage(getErrorMessage(err, t))
      return {
        aliases: []
      }
    }
  )
}

export const getTenants = async (
  request,
  accessToken,
  setTotalItems,
  setErrorMessage,
  t
) => {
  const url = `/support-assistant/v1alpha1/tenants`
  return get(url, request, accessToken).then(
    (resp) => {
      setErrorMessage(null)
      resp.data.tenants = resp?.data?.customers.map((value) => {
        return {
          id: value?.customer_id,
          name: value?.contact?.company_name,
          description: value?.contact?.description,
          operational_mode: value?.operational_mode,
          street_address: value?.contact?.address?.street_address,
          street_address_2: value?.contact?.address?.street_address_2,
          city: value?.contact?.address?.city,
          state_or_region: value?.contact?.address?.state_or_region,
          zip: value?.contact?.address?.zip,
          country: value?.contact?.address?.country_code,
          created_at: value?.account?.created_at,
          updated_at: value?.account?.updated_at,
          accessed_at: value?.account?.accessed_at,
          phone_number: value?.contact?.phone_number,
          email: value?.contact?.email,
          logo: value?.customer_logo?.logo,
          logo_filename: value?.customer_logo?.logo_filename
        }
      })
      return resp.data
    },
    (err) => {
      setErrorMessage(getErrorMessage(err, t))
      setTotalItems(0)
      return {
        tenants: [],
        pagination: {
          total_count: 0
        }
      }
    }
  )
}

export const getLogs = async (
  request,
  accessToken,
  setTotalItems,
  setErrorMessage,
  t
) => {
  const url = `/support-assistant/v1alpha1/auditlogs`
  return get(url, request, accessToken).then(
    (resp) => {
      resp.data.audits = resp.data.audits.map((audit) => {
        return {
          audit_id: audit.audit_id,
          category: audit.category,
          description: audit.description,
          ip_address: audit?.additional_info?.ip_address || '',
          target: audit?.additional_info?.target || '',
          time: dayjs(audit.audit_created_at).format('MM/DD/YYYY HH:mm:ss'),
          index: audit.index,
          app_instance_id: audit.app_instance_id,
          app_slug: audit.app_slug,
          customer_id: audit.customer_id,
          customer_name: audit.customer_name,
          has_details: audit?.has_details,
          username: audit.username
        }
      })
      return resp.data
    },
    (err) => {
      setErrorMessage(getErrorMessage(err, t))
      setTotalItems(0)
      return {
        audits: [],
        pagination: {
          total_count: 0
        }
      }
    }
  )
}

export const getResourceRestrictions = async (
  request,
  accessToken,
  setErrorMessage,
  t
) => {
  const url = `/support-assistant/v1alpha1/resource-restrictions`
  return get(url, request, accessToken).then(
    (resp) => {
      return resp.data
    },
    (err) => {
      setErrorMessage(getErrorMessage(err, t))
      return {
        policies: [],
        pagination: {
          total_count: 0
        }
      }
    }
  )
}

export const getTableData = async (
  type,
  accessToken,
  request,
  setTotalItems,
  setErrorMessage,
  t
) => {
  switch (type) {
    case 'orders':
      return getActivateOrders(
        request,
        accessToken,
        setTotalItems,
        setErrorMessage,
        t
      ).then(({ aop_sales_order_data: data, pagination }) => {
        setTotalItems(pagination?.total_count)
        return data
      })
    case 'users':
      return getUsers(
        request,
        accessToken,
        setTotalItems,
        setErrorMessage,
        t
      ).then(({ users, pagination }) => {
        setTotalItems(pagination.total_count)
        return users
      })
    case 'subscriptions':
      return getSubscriptions(
        request,
        accessToken,
        setTotalItems,
        setErrorMessage,
        t
      ).then(({ subscriptions, pagination }) => {
        setTotalItems(pagination.total_count)
        return subscriptions
      })
    case 'applications':
      return getProvisions(
        request,
        accessToken,
        setTotalItems,
        setErrorMessage,
        t
      ).then(({ provisions, pagination }) => {
        setTotalItems(pagination.total_count)
        return provisions
      })
    case 'folders':
      return getFolders(
        request,
        accessToken,
        setTotalItems,
        setErrorMessage,
        t
      ).then(({ folders, pagination }) => {
        setTotalItems(lodashGet(pagination, 'total_count', 0))
        return folders
      })
    case 'logs':
      return getLogs(
        request,
        accessToken,
        setTotalItems,
        setErrorMessage,
        t
      ).then(({ audits, pagination }) => {
        setTotalItems(pagination.total_count)
        return audits
      })
    case 'tenants':
      return getTenants(
        request,
        accessToken,
        setTotalItems,
        setErrorMessage,
        t
      ).then(({ tenants, pagination }) => {
        setTotalItems(pagination.total_count)
        return tenants
      })
    case 'resource-restrictions':
      return getResourceRestrictions(
        request,
        accessToken,
        setErrorMessage,
        t
      ).then(({ policies, pagination }) => {
        setTotalItems(pagination.total_count)
        return policies
      })
    default:
      return []
  }
}

export const getCustomerDetails = (
  token,
  t,
  customerId,
  setCustomerData,
  setErrorMessage,
  setLoading
) => {
  setLoading(true)
  const url = `/support-assistant/v1alpha1/customer-detail`
  return get(url, { platform_customer_id: customerId }, token).then(
    (resp) => {
      const {
        customer_id: id,
        contact: {
          company_name: companyName,
          email,
          address: {
            street_address: streetAddress,
            street_address_2: streetAddress2,
            city,
            state_or_region: stateOrRegion,
            country_code: country,
            zip
          },
          phone_number: phone,
          description,
          customer_logo: customerLogo
        },
        account: {
          created_at: createdAt,
          account_type: accType,
          status: accountStatus
        },
        msp_id: mspId,
        activate_customer_id: activateId,
        preferences: { multi_fa_enabled: multiFaEnabled },
        operational_mode: operationalMode
      } = resp.data
      const data = {
        id,
        name: companyName,
        created_on: dayjs(createdAt).format('MM/DD/YYYY HH:mm'),
        account_type: accType,
        account_status: accountStatus,
        msp_id: mspId,
        activate_id: activateId,
        email,
        phone_number: phone,
        street_address: streetAddress,
        street_address_2: streetAddress2,
        city,
        state_or_region: stateOrRegion,
        country,
        zip,
        multi_fa_enabled: multiFaEnabled,
        description,
        operational_mode: operationalMode,
        logo: customerLogo?.logo,
        logo_filename: customerLogo?.logo_filename
      }
      setCustomerData(data)
      setErrorMessage(null)
      setLoading(false)
    },
    (err) => {
      setErrorMessage(getApiErrorMessage(err, t))
      setLoading(false)
    }
  )
}

export const getActivateOrderDetails = async (
  param,
  token,
  setErrorMessage,
  t
) => {
  const url = `/support-assistant/v1alpha1/order/${param}`
  return get(url, {}, token).then(
    (resp) => resp.data,
    (err) => setErrorMessage(err, t)
  )
}

export const getAllSubscriptionTiers = (token, setErrorMessage, t, LDFlags) => {
  const deviceTypes = [
    'AP',
    'SWITCH',
    'GATEWAY',
    ...(LDFlags['glcp-dm-silver-peak'] ? ['SD_WAN_GW'] : []),
    'STORAGE',
    'DHCI_STORAGE',
    'COMPUTE',
    'DHCI_COMPUTE',
    ...(LDFlags['glcp-dm-uxi'] ? ['SENSOR'] : [])
  ].join(',')
  const url = `/support-assistant/v1alpha1/license-tiers`
  return get(url, { device_group: deviceTypes }, token).then(
    (resp) => resp.data,
    (err) => setErrorMessage(getErrorMessage(err, t))
  )
}
