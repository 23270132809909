import { Box, ResponsiveContext, PageHeader } from 'grommet'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Previous } from 'grommet-icons'
import React, { useCallback, useState, useContext } from 'react'

import { Button } from '../../../../components'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { getCustomerAccount } from '../../../../utils/feature-flag-utils'

import { SupportAccessUsersDatatable } from './SupportAccessUsersDatatable'
import { SAInviteUserModal } from './SupportAccessInviteUserModal'

const Users = () => {
  const { t } = useTranslation(['common', 'authz', 'support_access_manager'])
  const [inviteUsersLayerOpen, setInviteUsersLayerOpen] = useState(false)
  const [notifyComponent, setNotifyComponent] = useState(null)
  const [refreshCount, setRefreshCount] = useState(0)
  const refreshPage = useCallback(() => {
    setRefreshCount(refreshCount + 1)
  }, [refreshCount])

  const history = useHistory()
  const handleBackBtnClick = () => {
    history.push('/manage-ccs/support-access')
  }

  const custAccountLoaded = getCustomerAccount()
  const size = useContext(ResponsiveContext)

  return (
    <Box data-testid="users-page" pad={{ bottom: 'medium' }}>
      {notifyComponent}
      <Box direction="row" justify="start" pad="small">
        <Button
          default
          margin={{ vertical: 'xsmall', horizontal: 'small' }}
          sam_card_title
          label={t('support_access_manager:support_access_title')}
          icon={<Previous />}
          onClick={handleBackBtnClick}
          testId="users-back-btn"
        />
      </Box>
      {inviteUsersLayerOpen && (
        <SAInviteUserModal
          onSetOpen={setInviteUsersLayerOpen}
          onSuccess={refreshPage}
          setNotifyComponent={setNotifyComponent}
        />
      )}

      <Box
        gap="xsmall"
        margin={{
          horizontal: ['small', 'xsmall'].includes(size) ? 'large' : 'xlarge'
        }}
      >
        <Box gap="large">
          <Box
            direction={['xsmall', 'small'].includes(size) ? 'column' : 'row'}
            justify="between"
          >
            {custAccountLoaded && (
              <Box>
                <PageHeader
                  responsive
                  title={t('support_access_manager:invite_user.title')}
                  subtitle={t(
                    'support_access_manager:invite_user.add_users_description'
                  )}
                  data-testid="sam-page-header"
                />
              </Box>
            )}
            <Box justify="center">
              <VisibilityWrapper>
                <Button
                  primary
                  label={t('authz:invite_users_title')}
                  onClick={() => {
                    setInviteUsersLayerOpen(true)
                  }}
                  testId="invite-users-btn"
                />
              </VisibilityWrapper>
            </Box>
          </Box>
        </Box>
        <Box margin={{ top: 'medium' }}>
          <SupportAccessUsersDatatable
            refreshCount={refreshCount}
            refreshDataTable={refreshPage}
          />
        </Box>
      </Box>
    </Box>
  )
}
const SupportAccessUsersPage = () => {
  return <Users align="start" justify="start" />
}
export default SupportAccessUsersPage
