import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { PropTypes } from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { Typography } from '../../../../../../../components'

import { ratesFormatter } from './currencyFormatter'
import { isValidMinCommit } from './utils'

const RateReview = ({ pricePlan, currency }) => {
  dayjs.extend(utc)
  const { t, i18n } = useTranslation(['device', 'common'])
  // the i18n.language is the locale
  const formatterProps = {
    currency,
    locale: i18n.language,
    isFullValue: true
  }

  const renderRateRevision = (revision) => {
    const tiers = revision.tiers || {}
    const starts = {
      first: '0.0',
      second: Object.keys(tiers).filter((key) => {
        return key !== '0.0'
      })[0]
    }
    const values = {
      first: tiers[starts.first],
      second: starts.second ? tiers[starts.second] : undefined
    }
    const renderTiers = !isValidMinCommit(revision?.minCommit) ? (
      <Box direction="row" gap="medium" key={revision.id}>
        <Box width={{ min: 'small' }}>
          <Typography
            type="text"
            emphasis
            margin={{ vertical: 'none' }}
            testId={`showback-rates-review-no-reserve-label-${pricePlan.uom}`}
          >
            {t('showback_rates.wizard_meters_no_reserve_commit')}
          </Typography>
        </Box>
        <Box direction="row" gap="small">
          <Typography
            wordBreak="break-all"
            type="text"
            emphasis
            margin={{ vertical: 'none' }}
            testId={`showback-rates-review-no-reserve-value-part1-${pricePlan.uom}`}
          >
            {`${ratesFormatter({ ...formatterProps, value: values.first })}/${
              pricePlan.uom
            }`}
          </Typography>
          <Typography
            wordBreak="break-all"
            type="text"
            margin={{ vertical: 'none' }}
            testId={`showback-rates-review-no-reserve-value-part2-${pricePlan.uom}`}
          >
            {`${t('showback_rates.wizard_meters_review_for')} 0 ${
              pricePlan.uom
            } ${t('showback_rates.wizard_meters_form_and_up')}`}
          </Typography>
        </Box>
      </Box>
    ) : (
      <Box direction="column" key={revision.id}>
        {starts.first && (
          <Box direction="row" gap="medium">
            <Box width={{ min: 'small' }}>
              <Typography
                type="text"
                emphasis
                margin={{ vertical: 'none' }}
                testId={`showback-rates-review-up-to-label-first-${pricePlan.uom}`}
              >
                {t('showback_rates.wizard_meters_form_up_to_commit')}
              </Typography>
            </Box>
            <Box
              direction="row"
              gap="small"
              testId="showback-rates-review-review-up-to-value"
            >
              <Typography
                type="text"
                wordBreak="break-all"
                emphasis
                margin={{ vertical: 'none' }}
                testId={`showback-rates-review-up-to-label-first-value-part1-${pricePlan.uom}`}
              >
                {`${ratesFormatter({
                  ...formatterProps,
                  value: values.first
                })}/${pricePlan.uom}`}
              </Typography>
              <Typography
                wordBreak="break-all"
                type="text"
                margin={{ vertical: 'none' }}
                testId={`showback-rates-review-up-to-label-first-value-part2-${pricePlan.uom}`}
              >
                {`${t('showback_rates.wizard_meters_review_for')} 0 to <=${
                  revision.minCommit
                } ${pricePlan.uom}`}
              </Typography>
            </Box>
          </Box>
        )}
        {starts.second && (
          <Box direction="row" gap="medium">
            <Box width={{ min: 'small' }}>
              <Typography
                type="text"
                emphasis
                margin={{ vertical: 'none' }}
                testId={`showback-rates-review-beyond-label-${pricePlan.uom}`}
              >
                {t('showback_rates.wizard_meters_form_beyond_commit')}
              </Typography>
            </Box>
            <Box
              direction="row"
              gap="small"
              testId="showback-rates-review-beyond-value"
            >
              <Typography
                wordBreak="break-all"
                type="text"
                emphasis
                margin={{ vertical: 'none' }}
                testId={`showback-rates-review-beyond-value-part1-${pricePlan.uom}`}
              >
                {`${ratesFormatter({
                  ...formatterProps,
                  value: values.second
                })}/${pricePlan.uom}`}
              </Typography>
              <Typography
                wordBreak="break-all"
                type="text"
                margin={{ vertical: 'none' }}
                testId={`showback-rates-review-beyond-value-part2-${pricePlan.uom}`}
              >
                {`${t('showback_rates.wizard_meters_review_for')} >${
                  revision.minCommit
                } ${pricePlan.uom} ${t(
                  'showback_rates.wizard_meters_form_and_up'
                )}`}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    )
    return (
      <Box direction="column" key={`revision-ct-${revision.id}`}>
        <Box direction="row" gap="medium">
          <Box width="small">
            <Typography
              type="text"
              emphasis
              margin={{ vertical: 'none' }}
              testId={`showback-rates-review-effective-date-label-${pricePlan.uom}`}
            >
              {t('showback_rates.wizard_meters_review_effective_start_date')}
            </Typography>
          </Box>
          <Typography
            type="text"
            margin={{ vertical: 'none' }}
            testId={`showback-rates-review-effective-date-${pricePlan.uom}`}
          >
            {dayjs.utc(revision.effectiveDate).format('MM/DD/YYYY')}
          </Typography>
          <Box />
        </Box>
        {renderTiers}
      </Box>
    )
  }

  return (
    <Box direction="column" gap="small" margin={{ top: 'small' }}>
      <Typography
        type="text"
        size="large"
        margin={{ vertical: 'none' }}
        testId={`showback-rates-review-uom-${pricePlan.uom}`}
      >
        {pricePlan.uom}
      </Typography>
      <Box direction="column" gap="small">
        {pricePlan.rateRevisions.map((revision) => {
          return renderRateRevision(revision)
        })}
      </Box>
    </Box>
  )
}

RateReview.propTypes = {
  pricePlan: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired
}

export { RateReview }
