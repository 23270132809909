import React from 'react'
import PropTypes from 'prop-types'
import { Box, Tag } from 'grommet'

import { Typography } from '../../../../components'

export const PillItemAdder = ({
  addForm,
  itemList,
  heading,
  onDelete,
  testId,
  tagsAssigned
}) => {
  const tagsEls = itemList.map((item, idx) => {
    return (
      <Box
        margin="xsmall"
        gap="medium"
        alignSelf="start"
        data-testid={`assigned-tags-${idx}`}
        key={`pill-${idx}`} /* eslint-disable-line react/no-array-index-key */
      >
        <Tag
          testId={`${item?.name}-${item?.value}`}
          name={item?.name}
          value={item?.value}
          onRemove={() => {
            onDelete(item)
          }}
        />
      </Box>
    )
  })
  return (
    <Box data-testid={testId}>
      {addForm}

      <Box
        height={{ min: 'auto' }}
        margin={{ top: 'medium', bottom: 'small' }}
        border={{ side: 'bottom', color: 'border-weak' }}
      >
        <Typography level="2" type="heading" testId="selected-tags-header">
          {heading}
        </Typography>
      </Box>
      <Box height="small">
        {itemList && itemList.length > 0 ? (
          <Box
            data-testid="assigned-tags"
            direction="row"
            wrap
            style={{ overflow: 'auto' }}
          >
            {tagsEls}
          </Box>
        ) : (
          <Box data-testid="no-tags-assigned-description">{tagsAssigned}</Box>
        )}
      </Box>
    </Box>
  )
}

PillItemAdder.propTypes = {
  /**
   * Add Component of type AddForm
   */
  addForm: PropTypes.element.isRequired,

  /**
   * Pill data
   */
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string
    })
  ),

  /**
   * Heading string for section showing added items/pills
   */
  heading: PropTypes.string.isRequired,
  tagsAssigned: PropTypes.string,

  /**
   * Delete handler
   */
  onDelete: PropTypes.func.isRequired,

  /**
   * Test id
   */
  testId: PropTypes.string.isRequired
}

PillItemAdder.defaultProps = {
  itemList: [],
  tagsAssigned: ''
}
