const defaultAddPermissions = {
  // Must be pre-filled when duplicate is clicked
}

const getNoMandatoryResourcePermissionList = (policies, mandatoryRes) => {
  const mandatoryResNoPermission = []
  const policiesSet = new Set()
  // 2N instead of N square
  policies.forEach((each) => {
    policiesSet.add(each?.resource?.matcher)
  })
  mandatoryRes.forEach((each) => {
    if (!policiesSet.has(each)) {
      mandatoryResNoPermission.push(each)
    }
  })
  // it will return the invalid no permission resource list
  // for display the warning message
  return mandatoryResNoPermission
}

const validateAddPermissionsForm = (
  contextRole,
  mandatoryResPermissions,
  t
) => {
  if (
    !contextRole.resourcePolicies ||
    contextRole.resourcePolicies.length === 0
  ) {
    return Promise.reject(new Error(t('roles.wiz_step2_error')))
  }
  const invalidResourceList =
    getNoMandatoryResourcePermissionList(
      contextRole?.resourcePolicies,
      mandatoryResPermissions
    ) || []
  if (invalidResourceList.length > 0) {
    return Promise.reject(
      new Error(
        t('roles.mandatory_resource_no_permission', {
          resource: invalidResourceList?.join(', ')
        })
      )
    )
  }
  return Promise.resolve({ message: 'success' })
}

export {
  defaultAddPermissions,
  validateAddPermissionsForm,
  getNoMandatoryResourcePermissionList
}
