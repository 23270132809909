import { Box, Card } from 'grommet'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'

import {
  ButtonGroup,
  Loader,
  ModalDialog,
  ModalFooter,
  Notification,
  Typography
} from '../../../components'
import { get } from '../../../utils/api-utils'

const LoginTermsConditionModal = () => {
  const history = useHistory()
  const [termsConditionModalContent, setTermsConditionModalContent] =
    useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    get('/accounts/pingfed-ui/v1/cop-auth/login-banner').then(
      (response) => {
        if (response?.data?.message) {
          setIsLoading(false)
          setTermsConditionModalContent(response?.data?.message)
        } else {
          setIsLoading(false)
          sessionStorage.setItem('ccs-cop-login-banner', true)
          history.push('/')
        }
      },
      (error) => {
        setIsLoading(false)
        setErrorMsg(
          error?.message ||
            'The server encountered an error. Please contact Administrator or try again later.'
        )
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLoading ? (
    <Box direction="row" align="center" justify="center">
      <Loader testId="login-terms-banner-loader" />
    </Box>
  ) : (
    <ModalDialog
      width="large"
      testId="login-terms-condition-modal-dialog"
      content={
        <Box>
          <Typography
            size="small"
            type="heading"
            testId="login-terms-condition-agree-title"
          >
            Terms of usage agreement
          </Typography>
          <Typography
            type="text"
            margin={{ vertical: 'medium' }}
            color="#757575"
            testId="login-terms-condition-agree-sub-title"
          >
            By clicking the “Agree and enter” button below, you confirm that you
            have read and understood the terms of use. Clicking “Agree and
            enter” will proceed to your workspace.
          </Typography>
          <Card
            height="small"
            background="background-contrast"
            type="text"
            size="medium"
            data-testid="login-terms-condition-content"
            overflow="auto"
          >
            <Box pad="medium" gap="small">
              <Typography
                type="text"
                testId="login-terms-condition-content-warning"
              >
                Warning:
              </Typography>
              <Typography type="text">{termsConditionModalContent}</Typography>
            </Box>
          </Card>
          {errorMsg && (
            <Notification
              backgroundColor="status-critical"
              testId="login-terms-condition-error-notification"
              text={errorMsg}
              type="inline"
              margin={{ top: 'small' }}
            />
          )}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  label: 'Agree and enter',
                  primary: true,
                  testId: 'login-terms-condition-agree-btn',
                  onClick: () => {
                    sessionStorage.setItem('ccs-cop-login-banner', true)
                    history.push('/')
                  }
                }
              ]}
            />
          }
        />
      }
    />
  )
}

export default LoginTermsConditionModal
