import { useCallback, useState, useEffect } from 'react'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { get } from '../../../../../utils/api-utils'
import {
  AUTHZActions,
  useAUTHZContext
} from '../../../../../context/authz-context'
import { useVisibilityContext } from '../../../../../context/visibility-context'
import { getCCSAppDetails } from '../../../utils'

const useFetchUserRoles = () => {
  const { userCCSRoles, dispatchAUTHZContext } = useAUTHZContext()
  const ccsApplicationId = getCCSAppDetails()?.ccsApplicationId
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { oidcUser } = useReactOidc()
  const { hideWhen } = useVisibilityContext()

  const fetchUserRoles = useCallback(async () => {
    if (!userCCSRoles && !isLoading) {
      setIsLoading(true)
      try {
        const url =
          hideWhen?.account === 'TAC'
            ? `/support-assistant/v1alpha1/tac-user-role-assignments`
            : `/ui-doorway/ui/v1/user/role-assignments`
        const resp = await get(url, {}, oidcUser.access_token)
        if (resp?.status === 200 && resp?.data) {
          const roleSlugs =
            resp?.data?.roles
              ?.filter((data) => data?.application_id === ccsApplicationId)
              .map((data) => data?.slug) || []
          dispatchAUTHZContext({
            type: AUTHZActions.SET_USER_CCS_ROLES,
            data: roleSlugs
          })
        }
      } catch (err) {
        setError(err)
        console.error(err)
        dispatchAUTHZContext({
          type: AUTHZActions.SET_USER_CCS_ROLES,
          data: []
        })
      } finally {
        setIsLoading(false)
      }
    }
  }, [
    dispatchAUTHZContext,
    oidcUser.access_token,
    ccsApplicationId,
    userCCSRoles,
    isLoading,
    hideWhen
  ])
  useEffect(() => {
    fetchUserRoles()
  }, [fetchUserRoles])
  return { error, isLoading }
}

export default useFetchUserRoles
