import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'

import {
  isGLOP,
  isOrg,
  isInventoryOwnedWorkspace
} from '../../../../utils/feature-flag-utils'
import {
  WKSPC_CAPITALIZED,
  getWorkspaceString
} from '../../../../utils/common-utils'

const HomeQuickActionsData = () => {
  const LDFlags = useFlags()
  const { t } = useTranslation(['authn', 'common'])
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const mspCustomerOwnLd = LDFlags['glcp-msp-customer-own']
  const multiple_accounts = JSON.parse(
    sessionStorage.getItem('multiple-accounts')
  )
  const quickActionsData = [
    {
      id: 1,
      title: 'dashboard.quick_links_card.manage_workspace',
      linkKey: 'manage-workspace',
      routeTo: '/manage-account',
      testId: 'manage-workspace',
      visible: true
    },
    {
      id: 2,
      title: 'dashboard.quick_links_card.device_inventory',
      linkKey: 'device-inventory',
      routeTo: '/devices/inventory',
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      },
      hidden: !isInventoryOwnedWorkspace(),
      testId: 'onboard-devices-anchor',
      visible: true
    },
    {
      id: 3,
      title: 'dashboard.quick_links_card.service_subscriptions',
      linkKey: 'service-subscriptions',
      routeTo: '/services/service-subscriptions',
      testId: 'add-subscriptions-anchor',
      visible: true,
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      },
      hideFor: { deployment: ['GLOP'] },
      hidden: !isInventoryOwnedWorkspace()
    },
    {
      id: 4,
      visible: true,
      title: mspCustomerOwnLd
        ? t('customer_account.create_customer_account', {
            account: getWorkspaceString(
              showWorkspaceString,
              t,
              WKSPC_CAPITALIZED
            )
          })
        : t('dashboard.quick_actions_card.add_customer'),
      linkKey: 'create-customer-account',
      routeTo: '/customer-account',
      testId: 'add-customer-anchor',

      hideFor: { account: ['TENANT', 'STANDALONE', 'BASIC_ORGANIZATION'] },
      rbac: {
        resource: '/ccs/accounts/platform/customer',
        permission: 'ccs.accounts.platform.customer.edit'
      }
    },
    {
      id: 5,
      visible: true,
      title: 'dashboard.quick_links_card.user_management',
      linkKey: 'user-management',
      routeTo: '/manage-account/identity/users',
      testId: 'manage-iam-anchor',

      rbac: {
        resource: isOrg() && !isGLOP() ? undefined : '/ccs/authorization',
        permission:
          isOrg() && !isGLOP()
            ? 'ccs.accounts.user.view-all'
            : 'ccs.authorization.view'
      }
    },
    {
      id: 6,
      visible: true,
      title: 'dashboard.quick_links_card.locations',
      linkKey: 'create-location',
      routeTo: '/manage-account/locations',
      testId: 'create-location-anchor',

      rbac: {
        resource: '/ccs/location-management',
        permission: 'ccs.location-management.view'
      },
      hideFor: { deployment: ['GLOP'] },
      hidden: !isInventoryOwnedWorkspace()
    },
    {
      id: 7,
      visible: true,
      title: 'dashboard.quick_links_card.switch_workspace',
      linkKey: 'switch-workspace',
      routeTo: '/switch-account',
      testId: 'switch-workspace',

      hidden: !multiple_accounts
    },
    {
      id: 8,
      visible: true,
      title: t('common:manage_account.reporting_title'),
      linkKey: 'reporting',
      routeTo: '/manage-account/reporting',
      testId: 'reporting-anchor',

      hideFor: {
        feature: 'glcp-reportfw-dashboard'
      },
      hidden: !isInventoryOwnedWorkspace()
    },
    {
      id: 9,
      visible: true,
      title: 'dashboard.quick_links_card.feedback',
      linkKey: 'feedback',
      hrefTo:
        'https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=gl&tof=gf',
      testId: 'feedback'
    },
    {
      id: 10,
      visible: true,
      title: 'dashboard.quick_links_card.support_hub',
      routeTo: '/support',
      testId: 'support-hub',
      linkKey: 'support-hub',
      hideFor: {
        account: ['MSP', 'TENANT', 'BASIC_ORGANIZATION'],
        deployment: ['COP', 'GLOP'],
        feature: 'glcp-support-home'
      }
    }
  ]
  return quickActionsData
}

export { HomeQuickActionsData }
