import join from 'lodash/join'
import dayjs from 'dayjs'

export const convertUTCToLocalTime = (publishDateUTC = {}) => {
  const publishTime = publishDateUTC?.time || ''
  const publishDate = dayjs(publishDateUTC?.date).format('YYYY-MM-DD')
  const [time, meridian] = publishTime.split(' ')
  let hour = time.split(':')[0]
  const minute = time.split(':')[1]
  hour = parseInt(hour, 10)
  if (meridian === 'pm' && hour < 12) {
    hour += 12
  } else if (meridian === 'am' && hour === 12) {
    hour = 0
  }

  hour = hour.toString().padStart(2, '0')
  const ISOFormatDateInput = `${publishDate}T${hour}:${minute}:00Z`
  const dateObj = new Date(ISOFormatDateInput)

  const localTimeFormat = dayjs(dateObj).format('MM/DD/YYYY hh:mm a')
  return localTimeFormat
}

export const getFormattedData = (formData) => {
  const formattedData = { ...formData }
  formattedData.serviceCategoryList = join(formData?.serviceCategory, ', ')
  const publishedDateLocal = convertUTCToLocalTime(formData?.publishDate)
  formattedData.publishedDate = publishedDateLocal
  formattedData.scheduleDate = publishedDateLocal?.substring(0, 10)
  formattedData.scheduleTime = publishedDateLocal?.substring(11)
  formattedData.timeZone = dayjs().format('Z')
  if (formData?.media) {
    formattedData.fileName = formData?.media.substring(
      formData?.media.lastIndexOf('/') + 1
    )
    formattedData.mediaPath = formData?.media.substring(
      0,
      formData?.media.lastIndexOf('/') + 1
    )
    const blob = new Blob()
    const selectedFile = new File([blob], formattedData.fileName, {
      type: 'image/jpeg',
      lastModified: Date.now()
    })
    formattedData.selectedFile = [selectedFile]
  }

  formattedData.referenceLinkArray = join(formData?.referenceLinkArray, ', ')
  formattedData.summaryEnabled = formattedData.summaryFlag ? 'Yes' : 'No'
  return formattedData
}

export const getSubHeading = (postDetails, LDIstanbulFlag, t) => {
  const dataDetailsSubHeading = {
    publishedDate: t('whats_new.create_post_form.publish_date'),
    title: t('whats_new.create_post_form.title_field_label'),
    serviceCategoryList: t('whats_new.create_post_form.service_catalog'),
    shortDescription: t('whats_new.create_post_form.full_description'),
    fileName: t('whats_new.create_post_form.media_label'),
    publicationLocation: t('whats_new.publication_location'),
    referenceLinks: t('whats_new.create_post_form.reference_link_title')
  }
  if (LDIstanbulFlag) {
    dataDetailsSubHeading.summaryEnabled = t(
      'whats_new.create_post_form.home_page_announcement'
    )
  }
  if (LDIstanbulFlag && postDetails?.summaryFlag) {
    dataDetailsSubHeading.summary = t(
      'whats_new.create_post_form.summary_title'
    )
  }
  return dataDetailsSubHeading
}
