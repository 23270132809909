import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Anchor, Box, Button, Grid, ResponsiveContext } from 'grommet'
import { AppsRounded, StatusCritical } from 'grommet-icons'
import { useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import PropTypes from 'prop-types'

import { useAxiosAuth } from '../../../dashboard/hooks'
import { getErrorMessage } from '../../../utils/api-utils'
import { Loader, Notification, Tabs, Typography } from '../../../components'
import { CATEGORY } from '../../../dashboard/constants'
import { isServiceEnabled } from '../../../dashboard/utils'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import FeaturedServicesCard from './NewFeaturedServicesCard'

const FeaturedServices = ({ t }) => {
  const [featuredServicesData, setFeaturedServicesData] = useState([])
  const [isFeaturedServicesLoading, setIsFeaturedServicesLoading] =
    useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const [recommendedCategoryServices, setRecommendedCategoryServices] =
    useState({})

  const size = useContext(ResponsiveContext)
  const history = useHistory()
  const LDFlags = useFlags()
  const axios = useAxiosAuth()
  const wellnessFlag = LDFlags['glcp-unified-wellness-dashboard']
  const glcpRecommendServices = LDFlags['glcp-recommend-services']?.slugs
  useEffect(() => {
    axios
      .get('/service-catalog/v1alpha1/featured-services')
      .then(({ data: { items } }) => {
        setFeaturedServicesData(
          items?.filter((item) => item?.services?.length > 0) || []
        )
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error, t))
      })
      .finally(() => {
        setIsFeaturedServicesLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios])

  const categories = useMemo(() => {
    // if wellness LD flag is Off, filtering out the wellness dashboard service
    const filteredData = featuredServicesData.map((data) => {
      const { category, services } = data

      const filteredService = services.filter((service) => {
        if (
          (service.service_offer.slug !== 'WD' || wellnessFlag) &&
          glcpRecommendServices?.includes(service.service_offer.slug)
        ) {
          setRecommendedCategoryServices((currentService) => ({
            ...currentService,
            [service.service_offer.slug]: service
          }))
        }
        return service.service_offer.slug !== 'WD' || wellnessFlag
      })
      return { category, services: filteredService }
    })

    return filteredData
  }, [wellnessFlag, featuredServicesData, glcpRecommendServices])

  const grid = {
    columns: {
      small: {
        count: 2,
        size: 'auto'
      },
      medium: {
        count: 3,
        size: 'auto'
      },
      large: {
        count: 3,
        size: 'auto'
      },
      xlarge: {
        count: 4,
        size: 'auto'
      }
    },
    rows: ['auto'],
    gap: {
      small: 'medium',
      medium: 'medium',
      large: 'medium',
      xlarge: 'medium'
    }
  }

  const tabs =
    // enabling recommended tab after recommend services available
    (
      Object.keys(recommendedCategoryServices).length > 0
        ? [
            {
              category: CATEGORY.RECOMMENDED,
              services: [
                ...glcpRecommendServices
                  .map((key) => recommendedCategoryServices[key])
                  .filter((data) => data)
              ]
            },
            ...categories
          ]
        : [...categories]
    ).map((data, i) => {
      const { category } = data
      const services = data?.services?.filter(({ service_offer: { slug } }) =>
        isServiceEnabled(slug, LDFlags)
      )
      return (
        services?.length > 0 && {
          id: i,
          label: `${t(`dashboard:common.category.${category}`)} (${
            services.length
          })`,
          content: (
            <Grid
              rows={['auto']}
              columns={grid.columns[size]}
              gap={grid.gap[size]}
              pad={{ top: 'medium' }}
            >
              {services?.length > 0 &&
                services.map((service) => (
                  <FeaturedServicesCard
                    key={service.service_offer.id} // Each child in a list should have a unique "key" prop.
                    service={service.service_offer}
                    category={category}
                    t={t}
                  />
                ))}
            </Grid>
          ),
          testId: `${category}-tab`
        }
      )
    })

  const FeaturedServicesContent = () =>
    tabs.length === 0 ? (
      <Box
        align="center"
        width="50%"
        margin={{ horizontal: 'auto' }}
        gap="medium"
        testId="dashboard-empty-featured-tabs"
      >
        <Box align="center" gap="small">
          <AppsRounded size="xxlarge" color="brand" />
          <Typography type="text" size="large" textAlign="center" margin="none">
            {t('dashboard:dashboard.featured_services_empty')}
          </Typography>
        </Box>
        <Button
          label={t('dashboard:my_services.title')}
          primary
          onClick={() => history.push('/services/my-services')}
        />
      </Box>
    ) : (
      <Tabs tabsList={tabs} testId="dashboard-featured-tabs" />
    )

  return (
    <Box margin={{ bottom: 'large' }}>
      <VisibilityWrapper
        rbac={{
          resource: '/ccs/app-provision/provisions',
          permission: 'ccs.app-provision.view'
        }}
      >
        {errorMessage && (
          <Notification
            backgroundColor="status-critical"
            icon={<StatusCritical />}
            onClose={() => setErrorMessage('')}
            position="top"
            testId="featured-services-error-notification"
            text={errorMessage}
          />
        )}
        <Box
          direction="row"
          justify="between"
          align="center"
          margin={{ top: 'none', bottom: 'large' }}
        >
          <Typography level={2} type="heading" margin="none">
            {t('dashboard:dashboard.featured_services')}
          </Typography>
          <Anchor
            href="/services/service-catalog"
            label={t('dashboard:common.view_catalog')}
            plain
            onClick={(e) => {
              e.preventDefault()
              history.push('/services/service-catalog')
            }}
          />
        </Box>
        {isFeaturedServicesLoading && !errorMessage ? (
          <Box align="center" justify="center" alignSelf="center">
            <Loader testId="loader-spinner" />
          </Box>
        ) : (
          <FeaturedServicesContent />
        )}
      </VisibilityWrapper>
    </Box>
  )
}

FeaturedServices.propTypes = {
  t: PropTypes.func.isRequired
}

export default FeaturedServices
