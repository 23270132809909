import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import lodashGet from 'lodash/get'

import useLD from '../../../hooks/ld/useLD'
import { Typography, Button, Card, ActionButton } from '../../../components'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { AccountLogo } from '../../commoncomponents/account-logo/AccountLogo'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'
import { loadCustomerAccount } from '../../../utils/account-utils'
import { useVisibilityContext } from '../../../context/visibility-context'
import RplError from '../../commoncomponents/error/RplError'
import { displayApiError } from '../../../utils/error-handling-utils'
import { GTS_SUPPORT_URL, SUPPORT_URL } from '../../../utils/error-utils'
import { useUPSContext } from '../../../context/ups-context'
import useLogger from '../../../hooks/logs/useLogger'

const CustomerCard = ({ customer, setCustomerAccountLoading }) => {
  const { t } = useTranslation(['authn'])
  const history = useHistory()
  const custAccountLoaded = getCustomerAccount()
  const { oidcUser } = useReactOidc()
  const ld = useLD()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const { dispatchUPSContext } = useUPSContext()
  const [apiError, setApiError] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const logger = useLogger()
  const handleViewCustomerAccount = (customerId) => {
    history.push(`/customer-account/view/${customerId}`)
  }

  const handleEditCustomerAccount = (customerId) => {
    history.push(`/customer-account/edit/${customerId}`)
  }

  dayjs.extend(relativeTime)

  const [actionStatus, setActionStatus] = useState({})

  const handleAccountLaunch = (currentAccount, customerAccountToLaunch) => {
    setSubmitted(true)
    setCustomerAccountLoading(true)
    sessionStorage.setItem('swapMspAccount', JSON.stringify(currentAccount))
    customerAccountToLaunch.platform_customer_id =
      customerAccountToLaunch.customer_id
    customerAccountToLaunch.account_type = 'TENANT'

    // clean up action errors
    setActionStatus({
      ...actionStatus,
      [customerAccountToLaunch?.platform_customer_id]: <></>
    })

    loadCustomerAccount(
      customerAccountToLaunch,
      oidcUser,
      dispatchVisibilityContext,
      history,
      null,
      null,
      ld,
      dispatchUPSContext,
      logger
    ).then(
      () => {
        setSubmitted(false)
        setCustomerAccountLoading(false)
      },
      (error) => {
        setSubmitted(false)
        setCustomerAccountLoading(false)
        let errorInfo
        if (error?.response?.status === 451) {
          if (lodashGet(error, 'response.data.reason') === 'IP_ACCESS_BLOCK') {
            errorInfo = (
              <RplError
                message={error?.response?.data?.message}
                supportUrl={SUPPORT_URL}
              />
            )
          } else {
            // GTS error
            errorInfo = (
              <RplError
                message={error?.response?.data?.message}
                supportUrl={GTS_SUPPORT_URL}
              />
            )
          }
          // account card to display error when account can't be launched
          setActionStatus({
            ...actionStatus,
            [customerAccountToLaunch?.platform_customer_id]: errorInfo
          })
        } else {
          setApiError(error)
        }
      }
    )
  }

  return (
    <>
      {apiError && displayApiError(apiError, t, setApiError)}
      <Card
        cardWidth="medium"
        testId={`${customer.customer_id}-card`}
        key={`${customer.customer_id}-card`}
        CustomContent={
          <>
            <Box direction="row" justify="between" width="100%">
              <Box direction="row">
                <AccountLogo
                  account={customer}
                  logoOnly
                  title={customer.company_name}
                  logoSize="xsmall"
                />
              </Box>
              <Box direction="row" align="center">
                <ActionButton
                  actions={[
                    {
                      label: t('customer_account.view'),
                      onClick: () =>
                        handleViewCustomerAccount(customer.customer_id),
                      visibility: {
                        rbac: {
                          resource: '/ccs/accounts/platform/customer',
                          permission: 'ccs.accounts.platform.customer.view'
                        }
                      }
                    },
                    {
                      label: t('customer_account.edit'),
                      onClick: () =>
                        handleEditCustomerAccount(customer.customer_id),
                      visibility: {
                        rbac: {
                          resource: '/ccs/accounts/platform/customer',
                          permission: 'ccs.accounts.platform.customer.edit'
                        }
                      }
                    }
                  ]}
                  testId={`customer-account-action-btn-${customer.customer_id}`}
                  dropAlign={{
                    right: 'right',
                    top: 'bottom'
                  }}
                  customRenderer={(actionBtn, visibility, idx) => {
                    return (
                      <VisibilityWrapper
                        key={idx} // eslint-disable-line react/no-array-index-key
                        rbac={visibility.rbac}
                      >
                        {actionBtn}
                      </VisibilityWrapper>
                    )
                  }}
                />
                <Button
                  secondary
                  label={t('customer_account.launch')}
                  margin={submitted ? { left: 'xxsmall' } : { left: 'medium' }}
                  onClick={() =>
                    handleAccountLaunch(custAccountLoaded, customer)
                  }
                  testId={`${customer.customer_id}-launch-btn`}
                  isLoading={submitted}
                />
              </Box>
            </Box>
            <Box margin={{ top: 'small' }}>
              <Typography
                type="text"
                size="large"
                weight="bold"
                color="text-strong"
                testId="account-title"
                truncate="tip"
              >
                {customer.company_name}
              </Typography>
              <Typography
                type="text"
                size="small"
                margin={{ top: 'xsmall' }}
                testId="account-desc"
              >
                {customer.description}
              </Typography>
              <Box
                direction="row"
                gap="xsmall"
                margin={{ top: 'small' }}
                align="center"
              >
                <Typography
                  type="text"
                  size="small"
                  testId="account-last-visited"
                >
                  <>
                    {`${t('customer_account.last_visited')}: `}
                    {customer.accessed_at &&
                    !Number.isNaN(+customer.accessed_at)
                      ? dayjs().to(
                          new Date(parseInt(customer.accessed_at, 10) * 1000)
                        )
                      : 'N/A'}
                  </>
                </Typography>
              </Box>
            </Box>
            {actionStatus && (
              <Box width="100%">
                {actionStatus[customer.platform_customer_id]}
              </Box>
            )}
          </>
        }
      />
    </>
  )
}

CustomerCard.propTypes = {
  customer: PropTypes.object.isRequired,
  setCustomerAccountLoading: PropTypes.func.isRequired
}

export { CustomerCard }
