import React from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { ModalDialog, Typography, ModalHeader } from '../../../components'

const UnableToDeleteTenantAccountModal = ({ handleCancel }) => {
  const { t } = useTranslation(['authn'])
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              level="2"
              testId="unable-to-delete-title"
              type="heading"
              weight="bold"
            >
              {t('customer_account.unable_to_delete')}
            </Typography>
          }
          onClose={handleCancel}
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <Typography
            type="text"
            size="medium"
            testId="unable-to-delete-content"
          >
            {t('customer_account.unable_to_delete_desc')}
          </Typography>
        </Box>
      }
      onClose={handleCancel}
      testId="unable-to-delete-tenant-modal"
      width="medium"
    />
  )
}

UnableToDeleteTenantAccountModal.propTypes = {
  handleCancel: PropTypes.func.isRequired
}

export { UnableToDeleteTenantAccountModal }
