import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Box, Text } from 'grommet'
import { FormDown, FormUp } from 'grommet-icons'
import capitalize from 'lodash-es/capitalize'
import { useTranslation } from 'react-i18next'

import { eventPropType } from '../../utils/data'

const EventDetails = ({ event }) => {
  const { t } = useTranslation('unified_wellness')
  const [collapse, setCollapse] = useState(false)
  const urnSplit = event.asset.urn.split(':')
  let impactedResource
  if (urnSplit.length > 1) {
    impactedResource = capitalize(urnSplit[urnSplit.length - 2])
  }
  return (
    <Box>
      <Box
        direction="row"
        align="center"
        justify="between"
        pad={{ bottom: collapse ? undefined : 'medium' }}
      >
        <Text data-testid="event-details-title" size="xlarge" weight="bold">
          {t('details.event_details.event_details')}
        </Text>
        <Box
          data-testid="event-details-collapse-btn"
          onClick={() => setCollapse(!collapse)}
        >
          {collapse ? <FormDown /> : <FormUp />}
        </Box>
      </Box>
      {!collapse && (
        <>
          <Box
            data-testid="event-details-service-section"
            pad={{ bottom: 'small' }}
          >
            <Box>
              <Text weight="bold">{t('details.event_details.service')}</Text>
            </Box>
            <Box>
              <Text>
                {capitalize(event.glcpAttributes?.serviceName || '--')}
              </Text>
            </Box>
          </Box>
          <Box
            data-testid="event-details-device-section"
            pad={{ bottom: 'small' }}
          >
            <Box>
              <Text weight="bold">
                {t('details.event_details.device_model')}
              </Text>
            </Box>
            <Box>
              <Text>{event.glcpAttributes?.productName || '--'}</Text>
            </Box>
          </Box>
          <Box
            data-testid="event-details-impacted-resource-section"
            pad={{ bottom: 'small' }}
          >
            <Box>
              <Text weight="bold">
                {t('details.event_details.impacted_resource')}
              </Text>
            </Box>
            <Box>
              <Text>{impactedResource}</Text>
            </Box>
          </Box>
          <Box
            data-testid="event-details-case-number-section"
            pad={{ bottom: 'small' }}
          >
            <Box>
              <Text weight="bold">
                {t('details.event_details.case_number')}
              </Text>
            </Box>
            <Box>
              <Text>{event.escalation?.[0]?.caseNumber || '--'}</Text>
            </Box>
          </Box>
          <Box
            data-testid="event-details-case-status-section"
            pad={{ bottom: 'small' }}
          >
            <Box>
              <Text weight="bold">
                {t('details.event_details.case_status')}
              </Text>
            </Box>
            <Box>
              <Text>{capitalize(event.escalation?.[0]?.status || '--')}</Text>
            </Box>
          </Box>
          <Box
            data-testid="event-details-last-updated-section"
            pad={{ bottom: 'small' }}
          >
            <Box>
              <Text weight="bold">
                {t('details.event_details.last_modified')}
              </Text>
            </Box>
            <Box>
              <Text>
                {event.escalation?.[0]?.lastUpdatedAt
                  ? dayjs(event.escalation[0].lastUpdatedAt).format(
                      'MMMM D, YYYY hh:mm A'
                    )
                  : '--'}
              </Text>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

EventDetails.propTypes = {
  event: eventPropType.isRequired
}

export default EventDetails
