import React from 'react'
import { CircleInformation } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'

import { NoDataInfo } from '../../../components'

export const NoResults = () => {
  const { t } = useTranslation('support_hub_search')
  return (
    <Box data-testid="no-result-template" pad={{ top: 'large' }}>
      <NoDataInfo
        testId="no-results-found-search"
        title={t('no_results_found')}
        subtitle={t('no_results_message')}
        icon={<CircleInformation size="large" />}
      />
    </Box>
  )
}

export default NoResults
