// Copyright 2024 Hewlett Packard Enterprise Development LP
import { useReducer } from 'react'

import actions from './actions'

export const initialAuditLogContext = {
  selectedApplication: {
    application_id: '00000000-0000-0000-0000-000000000000',
    app_slug: 'CCS',
    application_name: 'HPE GreenLake Platform',
    application_customer_id: '00000000-0000-0000-0000-000000000000'
  },
  applicationData: {
    '00000000-0000-0000-0000-000000000000': {
      // default application
      auditLogsResponse: {},
      appConfig: {},
      selectedColumns: [],
      advancedSearchPayload: {},
      advancedSearchQuery: '',
      allLogsCount: 0,
      totalAuditLogsCount: 0,
      sort: {
        property: 'audit_created_at',
        direction: 'desc',
        propertyPath: 'audit_created_at'
      }
    }
  }
}

const getAuditLogContext = () => {
  const auditlogsContext = JSON.parse(
    sessionStorage.getItem('auditlog_context')
  )
  return auditlogsContext
}

const setAuditLogContext = (newContext) => {
  sessionStorage.setItem('auditlog_context', JSON.stringify(newContext))
}

export const resetAuditLogContext = () => {
  sessionStorage.setItem(
    'auditlog_context',
    JSON.stringify(initialAuditLogContext)
  )
}

const getUpdatedApplicationData = (currentState, key, val) => {
  const selectedApplicationId =
    currentState?.selectedApplication?.application_id
  const updatedApplicationData = {
    ...currentState.applicationData,
    [selectedApplicationId]: {
      ...currentState.applicationData[selectedApplicationId],
      [key]: val
    }
  }

  return updatedApplicationData
}

const AuditLogsReducer = (state, action) => {
  switch (action.type) {
    case actions.SET_APPLICATION: {
      const updatedState = {
        ...state,
        selectedApplication: action.data
      }
      setAuditLogContext(updatedState)
      return updatedState
    }
    case actions.SET_AUDIT_RESPONSE: {
      const updatedState = {
        ...state,
        applicationData: getUpdatedApplicationData(
          state,
          'auditLogsResponse',
          action.data
        )
      }

      setAuditLogContext(updatedState)
      return updatedState
    }
    case actions.SET_APPLICATION_CONFIG: {
      const updatedState = {
        ...state,
        applicationData: getUpdatedApplicationData(
          state,
          'appConfig',
          action.data
        )
      }
      setAuditLogContext(updatedState)
      return updatedState
    }
    case actions.SET_SELECTED_COLUMNS: {
      const updatedState = {
        ...state,
        applicationData: getUpdatedApplicationData(
          state,
          'selectedColumns',
          action.data
        )
      }
      setAuditLogContext(updatedState)
      return updatedState
    }
    case actions.SET_ADVANCED_SEARCH_PAYLOAD: {
      const updatedState = {
        ...state,
        applicationData: getUpdatedApplicationData(
          state,
          'advancedSearchPayload',
          action.data
        )
      }
      setAuditLogContext(updatedState)
      return updatedState
    }
    case actions.SET_ADVANCED_SEARCH_QUERY: {
      const updatedState = {
        ...state,
        applicationData: getUpdatedApplicationData(
          state,
          'advancedSearchQuery',
          action.data
        )
      }
      setAuditLogContext(updatedState)
      return updatedState
    }
    case actions.SET_ALL_LOGS_COUNT: {
      const updatedState = {
        ...state,
        applicationData: getUpdatedApplicationData(
          state,
          'allLogsCount',
          action.data
        )
      }
      return updatedState
    }
    case actions.SET_TOTAL_AUDIT_LOGS_COUNT: {
      const updatedState = {
        ...state,
        applicationData: getUpdatedApplicationData(
          state,
          'totalAuditLogsCount',
          action.data
        )
      }
      return updatedState
    }
    case actions.SET_SORT_CONFIG: {
      const updatedState = {
        ...state,
        applicationData: getUpdatedApplicationData(state, 'sort', action.data)
      }
      setAuditLogContext(updatedState)
      return updatedState
    }
    case actions.CLEAR_ADVANCED_SEARCH: {
      const updatedState = {
        ...state,
        applicationData: {
          ...state.applicationData,
          [state.selectedApplication.application_id]: {
            ...state.applicationData[
              state?.selectedApplication?.application_id
            ],
            advancedSearchPayload: {},
            advancedSearchQuery: ''
          }
        }
      }
      setAuditLogContext(updatedState)
      return updatedState
    }
    case actions.CLEAR_AUDIT_LOGS: {
      const updatedState = {
        ...state,
        applicationData: {
          ...state.applicationData,
          [state.selectedApplication.application_id]: {
            ...state.applicationData[
              state?.selectedApplication?.application_id
            ],
            auditLogsResponse: {},
            allLogsCount: 0
          }
        }
      }
      setAuditLogContext(updatedState)
      return updatedState
    }
    default:
      return state
  }
}

const useAuditLogsReducer = () => {
  const storedContext = getAuditLogContext()
  let initialContext = initialAuditLogContext
  if (storedContext !== null) {
    initialContext = storedContext
  }
  const [AuditLogsState, dispatchAuditLogsContext] = useReducer(
    AuditLogsReducer,
    initialContext
  )
  return { AuditLogsState, dispatchAuditLogsContext }
}

export default useAuditLogsReducer
