// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
/** This is a Prototype page to demo MirageJS+Cypress */
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Box,
  Grid,
  ResponsiveContext,
  Page,
  PageContent,
  PageHeader
} from 'grommet'
import { StatusCritical } from 'grommet-icons'
import { Trans, useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { get } from '../../utils/api-utils'
import { AppCatalogContextProvider } from '../../context/app-catalog-context'
import { Notification, Typography, Anchor } from '../../components'
import { Layout } from '../commoncomponents/layout/Layout'
import { FeatureAppCards } from '../applications/featured-apps'
import SwitchAccountButton from '../commoncomponents/SwitchAccountButton/SwitchAccountButton'
import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { AccountLogo } from '../commoncomponents/account-logo/AccountLogo'
import { getCustomerAccount } from '../../utils/feature-flag-utils'
import useLogger from '../../hooks/logs/useLogger'
import { isServiceCentric } from '../../utils/account-utils'
import { PostModel } from '../whats-new/components/PostModel'
import { getDataBasedOnSetting } from '../../utils/ups-utils'
import { useUPSContext } from '../../context/ups-context'
import {
  useVisibilityContext,
  VisibilityActions
} from '../../context/visibility-context'
import { CheckResourcePermissions } from '../../utils/ccs-manager-utils'

import { RightPanel } from './right-panel/RightPanel'
import { QuickActions } from './quick-actions'
import { Recommended } from './recommended'
import { autoLaunch, doAutoLaunchAppAction } from './auto-launch-util'

const HomeContent = () => {
  // auto launch feature
  // URL: /home?app=application_id, if only one app provisioned,
  // should direct launch to app
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const { oidcUser } = useReactOidc()
  const { rbacPolicies, dispatchVisibilityContext } = useVisibilityContext()
  const size = useContext(ResponsiveContext)

  const [errorMessage, setErrorMessage] = useState(useLocation().errorMessage)
  const { t } = useTranslation(['authn', 'apps'])
  const [redirecting, setRedirecting] = useState(false)

  const query = new URLSearchParams(sessionStorage.getItem('redirect-query'))
  const app = query && !isEmpty(query.get('app')) ? query.get('app') : null
  const redirectUrl =
    query && !isEmpty(query.get('redirect_uri'))
      ? query.get('redirect_uri')
      : null

  const setClearAll = () => {
    setErrorMessage(null)
    setRedirecting(false)
  }

  const custAccountLoaded = getCustomerAccount()
  const accountType = custAccountLoaded?.account_type || ''
  const [showModel, setShowModel] = useState(false)
  const [postItem, setPostItems] = useState([])
  const { commonpreferences, upsDataResolved } = useUPSContext()

  const whatsNewData = getDataBasedOnSetting(commonpreferences, 'showWhatsNew')

  useEffect(() => {
    if (app !== null && !isEmpty(app)) {
      setRedirecting(true)
      // For AT&T special case only,
      // the app is not provisioned/no app instance id/no customer id
      // check if app instance id is in allowed list, if yes, do redirect withOut check provisioned or not
      if (
        !isEmpty(redirectUrl) &&
        LDFlags['glcp-white-list-app-provision']?.appInstanceIDs?.includes(app)
      ) {
        doAutoLaunchAppAction(
          app,
          redirectUrl,
          oidcUser.access_token,
          null,
          accountType,
          setErrorMessage
        )
      } else {
        autoLaunch(
          app,
          redirectUrl,
          oidcUser.access_token,
          accountType,
          setErrorMessage,
          t
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser, LDFlags])

  useEffect(() => {
    if (
      LDFlags['glcp-whatsnew-istanbul'] &&
      !redirecting &&
      redirectUrl === null &&
      whatsNewData?.value &&
      upsDataResolved
    ) {
      if (!sessionStorage.getItem('NewPostModel')) {
        get('/whatsnew/v1/dashboard/blogs', {}, oidcUser.access_token).then(
          (response) => {
            if (response?.data) {
              sessionStorage.setItem('NewPostModel', true)
              if (!isEmpty(response.data.blogs)) {
                setPostItems(response.data.blogs)
                setShowModel(true)
              }
            }
          }
        )
      } else {
        setShowModel(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, LDFlags, upsDataResolved])

  useEffect(() => {
    if (
      LDFlags['glcp-support-access-manager'] &&
      rbacPolicies &&
      upsDataResolved
    ) {
      const isSupportEngineer = CheckResourcePermissions(
        {
          '/ccs/workspace-access-se': ['ccs.workspace-access-se.view']
        },
        rbacPolicies
      )

      dispatchVisibilityContext({
        type: VisibilityActions.SET_IS_SUPPORT_ENGINEER,
        data: isSupportEngineer
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LDFlags, upsDataResolved, rbacPolicies])

  const parentGrid = {
    columns: {
      small: ['auto'],
      medium: ['flex'],
      large: ['flex', ['small', 'medium']],
      xlarge: ['flex', 'medium']
    },
    rows: 'auto',
    gap: {
      small: 'large',
      medium: 'large',
      large: 'large',
      xlarge: 'xlarge'
    }
  }

  // whether or not to render the AppCatalogContextProvider and FeatureAppCards
  const [showFeatured, setShowFeatured] = useState(true)
  const showRedirectPage = () => {
    return (
      <Box fill pad="xlarge" justify="center" data-testid="find-accounts">
        <Typography
          margin={{ top: 'large', bottom: 'small' }}
          type="heading"
          level="1"
          testId="find-accounts-title"
        >
          {newDashboardFlag
            ? t('acct_onboarding.redirecting_to_service')
            : t('acct_onboarding.redirecting_to_app')}
        </Typography>
        <Typography type="text" size="xlarge" testId="find-accounts-subtitle">
          <Trans i18nKey="acct_onboarding.cancel_redirect" t={t}>
            <Anchor
              data-testid="anchor-to-home"
              label={t('acct_onboarding.click_here')}
              href="/"
              onClick={() => setClearAll()}
              weight="bold"
              testId="click-to-home"
            />
          </Trans>
        </Typography>
      </Box>
    )
  }
  return (
    <Page>
      <PageContent>
        <Box data-testid="home" alignSelf="center" gap="large">
          {redirecting && !errorMessage ? (
            showRedirectPage()
          ) : (
            <>
              <PageHeader
                responsive
                title={
                  custAccountLoaded && (
                    <Box direction="row" gap="medium" alignContent="center">
                      <AccountLogo
                        account={custAccountLoaded}
                        title={custAccountLoaded.company_name}
                        logoSize="xsmall"
                      />
                      <Typography
                        type="heading"
                        testId="heading-home"
                        style={{ maxWidth: '1050px' }} // Using px as an exception case to avoid text overlap with the Button since it was not achievable with Tshirt size.
                      >
                        {custAccountLoaded.company_name}
                      </Typography>
                    </Box>
                  )
                }
                actions={
                  <Box testId="cust-info-home">
                    <VisibilityWrapper hideFor={{ deployment: ['COP'] }}>
                      <SwitchAccountButton />
                    </VisibilityWrapper>
                  </Box>
                }
                margin={{ bottom: 'large' }}
                pad="none"
              />

              <>
                <Grid
                  columns={parentGrid.columns[size]}
                  rows={parentGrid.rows}
                  gap={parentGrid.gap[size]}
                >
                  <Box gap={size === 'small' ? 'xlarge' : 'large'}>
                    {/* When there are no FeatureAppCards, don't render the AppCatalogContextProvider.
                     * Othwerise, Grid thinks it has two children (AppCatalogContextProvider + QuickActions)
                     * and will improperly render a gap above Quick Actions. */}

                    {showFeatured && (
                      <AppCatalogContextProvider>
                        <FeatureAppCards setShowFeatured={setShowFeatured} />
                      </AppCatalogContextProvider>
                    )}
                    <QuickActions />
                  </Box>
                  <Box gap="large">
                    <VisibilityWrapper
                      hideFor={{
                        feature: 'glcp-service-centric-experience-phase-1'
                      }}
                    >
                      <RightPanel />
                    </VisibilityWrapper>
                    <Recommended />
                  </Box>
                  <VisibilityWrapper
                    hideFor={{ feature: 'glcp-whatsnew-istanbul' }}
                  >
                    {showModel && (
                      <PostModel
                        Posts={postItem}
                        closeModel={() => setShowModel(false)}
                      />
                    )}
                  </VisibilityWrapper>
                </Grid>
              </>
            </>
          )}
          {errorMessage ? (
            <Notification
              backgroundColor="status-critical"
              icon={<StatusCritical color="text-strong" />}
              onClose={() => setClearAll()}
              position="top"
              testId="error-notification"
              text={errorMessage}
            />
          ) : null}
        </Box>
      </PageContent>
    </Page>
  )
}

const HomePage = () => {
  const logger = useLogger()

  const pageInfoLog = {
    page: HomePage.name || 'Home/Dashboard',
    section: 'Load',
    type: 'success',
    count: 'Calling:home'
  }
  logger?.log({ ...pageInfoLog, msg: 'Home/Dashboard loaded successfully!' })
  return (
    <Layout>
      <HomeContent align="start" justify="start" />
    </Layout>
  )
}
export default HomePage
