export const validateBillingAccountFields = (formValues, i18nTranslate) => {
  const errorObj = {}

  if (!formValues.billingAccountName) {
    errorObj.name = i18nTranslate('Required')
  }
  if (!formValues.billingAccountDescription) {
    errorObj.description = i18nTranslate('Required')
  }
  return errorObj
}
