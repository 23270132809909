// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useState, useEffect } from 'react'
import { Box, Nav } from 'grommet'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography, Button } from '../../../../../components'

const CustomNav = styled(Nav)`
  div[class*='StyledBoxGap'] {
    display: none;
  }
  button + div[class*='StyledBoxGap'] {
    display: block;
  }
`

export const CustomMenuList = ({
  menuData,
  testId,
  defaultActiveId,
  highlightOnSelect,
  onClickMenuItem
}) => {
  const [active, setActive] = useState(defaultActiveId)

  useEffect(() => {
    setActive(defaultActiveId)
  }, [defaultActiveId])

  const handleMenuItemClick = (item) => {
    if (highlightOnSelect) {
      setActive(item.id)
    }
    onClickMenuItem(item)
  }

  return (
    <CustomNav
      direction="column"
      background="background-front"
      pad="none"
      data-testid={testId}
      gap="small"
      justify="start"
    >
      {menuData?.length > 0 &&
        menuData.map((item) => (
          <Button
            key={item.id}
            onClick={() => handleMenuItemClick(item)}
            active={item.id === active && highlightOnSelect}
            testId={item.testId}
          >
            {() => (
              <Box pad="none" width="100%" align="start">
                <Typography
                  type="text"
                  testId={`gs-menu-item-${item.testId}`}
                  weight={item.id === active ? 'bold' : 'normal'}
                >
                  {item.label}
                </Typography>
              </Box>
            )}
          </Button>
        ))}
    </CustomNav>
  )
}

CustomMenuList.propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      testId: PropTypes.string.isRequired
    })
  ).isRequired,
  defaultActiveId: PropTypes.number,
  highlightOnSelect: PropTypes.bool.isRequired,
  onClickMenuItem: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired
}

CustomMenuList.defaultProps = {
  defaultActiveId: 0
}
