import React, { useState } from 'react'
import { Box } from 'grommet'
import { useTranslation, Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Wizard, Typography, Loader, Anchor } from '../../../../components'

import VerifyAndAddDomainModal from './steps/VerifyAndAddDomainModal'
import validateAndClaimRequest from './steps/utils'
import ViewDomainClaimContent from './ViewDomainClaimContent'

const AddDomain = () => {
  const [domainRequestData, setDomainRequestData] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation(['iam', 'common'])
  const history = useHistory()
  const wizardButtons = {
    next: {
      label: t('domains.claim_button'),
      submitForm: false
    },
    finish: t('common:close')
  }
  const actionOnExitFn = () => {
    if (domainRequestData?.name) {
      const domainName = domainRequestData?.name
      history.push(`/manage-account/organization/domains`, {
        notificationText: t('iam:domains.domain_add_notify', { domainName })
      })
    } else {
      history.push('/manage-account/organization/domains')
    }
  }
  const steps = [
    {
      childComponents: (
        <VerifyAndAddDomainModal
          errorMsg={errorMsg}
          setReadable={domainRequestData?.name}
          setErrorMsg={setErrorMsg}
        />
      ),
      description: (
        <Typography
          type="text"
          size="large"
          weight={400}
          testId="start-domain-claim-desc"
        >
          <Trans i18nKey="domains.domain_claim_desc" t={t}>
            <Anchor
              label={t('common:learn_more')}
              weight="bold"
              onClick={() => dispatchEvent(new Event('context-help'))}
            />
          </Trans>
        </Typography>
      ),
      title: t('domains.identity_domain_title'),
      validateForm: (domainName) => ({
        then: async (resolve, reject) => {
          try {
            if (!domainRequestData) {
              const resDomain = await validateAndClaimRequest(
                domainName,
                t,
                setLoading
              )
              if (resDomain?.isValid) {
                setDomainRequestData(resDomain?.domainRes?.data)
                resolve()
              } else {
                setErrorMsg(resDomain?.errorMessage)
              }
            } else {
              resolve()
            }
          } catch (error) {
            // Handle any errors that occur during the validation
            setErrorMsg(error?.message)
            reject()
          }
        }
      }),
      submitFormOnChange: false
    },
    {
      childComponents: (
        <Box margin={{ bottom: 'medium' }}>
          <ViewDomainClaimContent
            domainClaimTXT={domainRequestData?.dnsTxtRecord}
            domainName={domainRequestData?.name}
          />
        </Box>
      ),
      description: <></>,
      title: t('domains.owner_ship'),
      validateForm: () => {
        return new Promise((resolve) => {
          resolve()
        })
      }
    }
  ]
  return (
    <Box>
      {loading && (
        <Loader
          testId="domain-loader-Id"
          label={t('common:loading')}
          modal
          size="xsmall"
          orientation="horizontal"
        />
      )}
      <Wizard
        testId="add-domain-wizard"
        title={t('domains.wizard_title')}
        actionOnSubmit={() => actionOnExitFn()}
        buttonLabels={wizardButtons}
        actionOnExit={() => actionOnExitFn()}
        formDefaultValues={{
          domainName: ''
        }}
        actionOnCancel={() => actionOnExitFn()}
        steps={steps}
      />
    </Box>
  )
}

export default AddDomain
