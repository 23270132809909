import { Box, SelectMultiple, InfiniteScroll, Page, PageHeader } from 'grommet'
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import debounce from 'lodash/debounce'
import trimEnd from 'lodash/trimEnd'
import { Article, ShareRounded } from 'grommet-icons'

import {
  Loader,
  NoDataInfo,
  Typography,
  Search,
  Button
} from '../../components'
import { Layout } from '../commoncomponents/layout/Layout'
import { get } from '../../utils/api-utils'
import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { displayApiError } from '../../utils/error-handling-utils'

import { PostDetail } from './components/PostDetails'
import DisableAnnouncement from './components/DisableAnnouncement'
import { releaseNotesButtonLink } from './constants'

const WhatsNewContent = () => {
  const { t } = useTranslation(['common'])
  const itemsPerPage = 10

  const { oidcUser } = useReactOidc()

  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [viewData, setViewData] = useState([])
  const [services, setServices] = useState([])
  const [listOfServices, setListOfServices] = useState([])
  const [finalUrl, setFinalUrl] = useState('')
  const [searchData, setSearchData] = useState('')
  const debouncedSetSearchTerm = debounce(setSearchData, 500)
  const [valueMultiple, setValueMultiple] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [refreshPage, setRefreshPage] = useState(false)
  const [showDisableModal, setShowDisableModal] = useState(false)

  const currentVideoPlaying = useRef(null)

  useEffect(() => {
    get('/whatsnew/v1/blog/service/category', {}, oidcUser.access_token).then(
      (response) => {
        if (response?.data) {
          setListOfServices(response.data.items)
          setServices(response.data.items)
        }
      },
      (error) => {
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t])

  const fetchBlogs = useCallback(() => {
    const request = {
      size: itemsPerPage,
      start: (page - 1) * itemsPerPage
    }

    if (page === 1) {
      setLoading(true)
    }
    get(`/whatsnew/v1/blogs${finalUrl}`, request, oidcUser.access_token).then(
      (response) => {
        setRefreshPage(false)
        setLoading(false)
        if (response?.data?.blogs) {
          const formattedData = response?.data?.blogs || []
          let data = []
          if (page === 1) {
            data = formattedData
            setTotalItems(response.data.totalCount)
          } else {
            data = [...viewData, ...formattedData]
          }
          setViewData(data)
        }
      },
      (error) => {
        setViewData([])
        setLoading(false)
        setRefreshPage(false)
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalUrl, oidcUser.access_token, t, page])

  useEffect(() => {
    fetchBlogs()
  }, [fetchBlogs])

  useEffect(() => {
    const queryString = new URLSearchParams()

    if (searchData?.length > 2) {
      queryString.append('q', `${searchData}`)
    }

    if (valueMultiple?.length) {
      valueMultiple.forEach((serviceCategory) =>
        queryString.append('serviceCategory', `${serviceCategory}`)
      )
    }

    const url = trimEnd(`?${queryString.toString()}`, '?')
    setFinalUrl(url)

    if (url?.length) {
      setPage(1)
    }
  }, [searchData, valueMultiple])

  const onMoreHandler = () => {
    if (
      totalItems > viewData.length &&
      (page - 1) * itemsPerPage <= totalItems
    ) {
      setRefreshPage(true)
      setPage(page + 1)
    }
  }

  const InfiniteScrollBlogs = () => {
    return (
      <Box data-testid="infinite-scroll-box">
        <InfiniteScroll step={9} items={viewData} onMore={onMoreHandler}>
          {(item) => (
            <Box key={item.blogID}>
              <PostDetail
                item={item}
                currentVideoPlaying={currentVideoPlaying}
              />
            </Box>
          )}
        </InfiniteScroll>
        {refreshPage && (
          <Box align="center">
            <Loader testId="whats-new-refresh-onMore" />
          </Box>
        )}
      </Box>
    )
  }

  const ReleaseNotesButton = () => (
    <Button
      label={t('whats_new.release_notes_button')}
      secondary
      icon={<ShareRounded />}
      reverse
      size="medium"
      testId="release-notes-btn"
      href={releaseNotesButtonLink}
      onClick={() => {
        setShowDisableModal(true)
      }}
      target="_blank"
    />
  )

  return (
    <Page>
      <VisibilityWrapper
        hideFor={{ feature: 'glcp-whats-new', deployment: ['COP'] }}
      >
        <Box
          width="large"
          alignSelf="center"
          justify="center"
          gap="small"
          direction="column"
        >
          <Box
            data-testid="header-box"
            justify="between"
            alignSelf="center"
            width="large"
            pad={{ horizontal: 'small' }}
          >
            <PageHeader
              data-testid="whats-new-title"
              title={t('whats_new.title')}
              subtitle={
                <Typography
                  type="text"
                  size="large"
                  testId="whats-new-description"
                >
                  {t('whats_new.description')}
                </Typography>
              }
              actions={<ReleaseNotesButton />}
            />
          </Box>
          <Box width="large" alignSelf="center" gap="small" pad="small">
            {(viewData.length > 0 ||
              searchData.length > 0 ||
              valueMultiple.length > 0) && (
              <Box gap="medium" direction="row-responsive" align="end">
                <Box>
                  <Search
                    handleCustomSearch={(value) => {
                      debouncedSetSearchTerm(value.trim())
                    }}
                    placeholder={t('whats_new.search_placeholder')}
                    testId="search-field"
                  />
                </Box>
                <Box>
                  <Typography
                    testId="service-category-label"
                    type="text"
                    size="xsmall"
                    emphasis
                  >
                    {t('whats_new.service_category_label')}
                  </Typography>
                  <SelectMultiple
                    data-testid="service-category-select"
                    width="medium"
                    value={valueMultiple}
                    placeholder={t('whats_new.select_items')}
                    options={services}
                    onSearch={(searchValue) => {
                      const regexp = new RegExp(searchValue, 'i')
                      setServices(listOfServices.filter((o) => o.match(regexp)))
                    }}
                    onClose={() => {
                      setServices(listOfServices)
                      setPage(1)
                    }}
                    onChange={({ value }) => {
                      setValueMultiple(value)
                      setPage(1)
                    }}
                  />
                </Box>
              </Box>
            )}
            {loading ? (
              <Box direction="row" align="center" justify="center">
                <Loader testId="whats-new-loader" />
              </Box>
            ) : (
              <>
                {viewData?.length ? (
                  <InfiniteScrollBlogs />
                ) : (
                  <Box pad={{ vertical: 'large' }} gap="large">
                    <NoDataInfo
                      icon={<Article size="large" />}
                      testId="whats-new-no-data-info"
                      subtitle={t('whats_new.no_data_subtitle')}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
          {showErrorNotification}
        </Box>
        {showDisableModal && (
          <DisableAnnouncement
            closeAnnouncementModal={() => {
              setShowDisableModal(false)
            }}
          />
        )}
      </VisibilityWrapper>
    </Page>
  )
}

function WhatsNewCustomerPage() {
  return (
    <Layout>
      <WhatsNewContent align="center" justify="center" />
    </Layout>
  )
}

export default WhatsNewCustomerPage
