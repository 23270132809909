import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import lodashGet from 'lodash/get'

import ReuseableDiscardChangesModal from './ReuseableDiscardChangesModal'

const DiscardChangesModal = ({ closeModal, onContinue, goBack }) => {
  const { t } = useTranslation(['device'])
  const history = useHistory()
  const { customerData } = useLocation()
  return (
    <ReuseableDiscardChangesModal
      testId="discard-folder-changes-modal-dialog"
      footerButtonslist={[
        {
          label: t('cancel'),
          default: true,
          testId: 'cancel-btn',
          onClick: closeModal
        },
        {
          label: t('continue'),
          primary: true,
          testId: 'continue-btn',
          onClick: () => {
            if (goBack) {
              closeModal()
              sessionStorage.removeItem('folderDetailParam')
              if (
                lodashGet(history, 'location.pathname') ===
                '/manage-ccs/customers/customer-details/folders/folder-details'
              )
                history.push({
                  pathname: '/manage-ccs/customers/customer-details',
                  customerData
                })
              else if (
                history?.location?.pathname ===
                '/manage-account/activate/folders/folder-details'
              ) {
                history.push('/manage-account/activate/folders')
              } else history.goBack()
            } else onContinue()
          }
        }
      ]}
      footerTestId="discard-changes-actions-buttons"
      onClose={closeModal}
    />
  )
}

DiscardChangesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  goBack: PropTypes.bool.isRequired
}

export { DiscardChangesModal }
