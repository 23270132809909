import React from 'react'
import PropTypes from 'prop-types'
import { Box, Markdown, Anchor } from 'grommet'
import sortBy from 'lodash/sortBy'
import i18next from 'i18next'

import getFormFields from './utils'

export const DynamicFormContent = ({
  schema,
  formData,
  onChange,
  translation: t,
  apiErrors,
  setSubmitDisabled
}) => {
  const {
    headerTextI18nKey,
    footerTextI18nKey,
    options,
    headerText = '',
    footerText = ''
  } = schema

  const sortedOptions = sortBy(options, ['index'])

  // set default values
  options.forEach((obj) => {
    // Set default value for select menu input type
    if (obj?.type === 'preselectValue') {
      if (obj?.allowedValues.length === 1) {
        formData[obj.name] = obj?.allowedValues[0].code
      } else {
        obj?.allowedValues.forEach((allowedValue) => {
          if (allowedValue.isDefault === true) {
            // set default value if not already set
            if (!formData[obj.name]) {
              formData[obj.name] = allowedValue.code
            }
          }
        })
      }
    }
  })

  return (
    <Box
      direction="column"
      margin={{ top: 'small' }}
      data-testid="dynamic-form-component"
      width="medium"
    >
      {headerTextI18nKey && (
        <Markdown
          data-testid="header-text"
          key="header"
          components={{
            a: {
              component: Anchor,
              props: { target: '_blank', testId: `header-link`, label: '' }
            }
          }}
        >
          {i18next.exists(headerTextI18nKey)
            ? t(headerTextI18nKey)
            : headerText}
        </Markdown>
      )}
      {getFormFields(
        t,
        sortedOptions,
        formData,
        onChange,
        apiErrors,
        setSubmitDisabled
      )}
      {footerTextI18nKey && (
        <Markdown
          data-testid="footer-text"
          key="footer"
          components={{
            a: {
              component: Anchor,
              props: { target: '_blank', testId: `footer-link`, label: '' }
            }
          }}
        >
          {i18next.exists(footerTextI18nKey)
            ? t(footerTextI18nKey)
            : footerText}
        </Markdown>
      )}
    </Box>
  )
}

DynamicFormContent.propTypes = {
  schema: PropTypes.object.isRequired,
  /* JSON Schema */
  formData: PropTypes.object.isRequired,
  /* Form data */
  onChange: PropTypes.func.isRequired,
  /* Function to handle form data change */
  translation: PropTypes.func,
  /* Translation function */
  apiErrors: PropTypes.object,
  /* API errors */
  setSubmitDisabled: PropTypes.func.isRequired
  /* Disable and enableSubmitButton */
}

DynamicFormContent.defaultProps = {
  translation: () => {},
  apiErrors: {}
}
