import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'
import { StatusCritical } from 'grommet-icons'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  CCSManagerActions,
  useCCSManagerContext
} from '../../../../../../context/ccs-manager-context'
import {
  getPaginationShowIdx,
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../../../../utils/common-utils'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  ActionButton,
  AvatarInfo,
  DataTable,
  Notification,
  Typography
} from '../../../../../../components'
import { getTableData } from '../../customer-apis'
import {
  DeleteTenantModal,
  EditTenantModal
} from '../../../../common-components/tenant-actions'

const TenantsDataTable = ({ customerId }) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage', 'common'])
  dayjs.extend(relativeTime)

  const { dispatchCCSManagerContext } = useCCSManagerContext()
  const [tenantsData, setTenantsData] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [selectedRowData, setSelectedRowData] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [detailClicked, setDetailClicked] = useState(false)
  const { 'glcp-switch-to-workspace': showWorkspaceString } = useFlags()

  // modals
  const [editTenantModal, setEditTenantModal] = useState(false)
  const [deleteTenantModal, setDeleteTenantModal] = useState(false)

  // for refresh table
  const [refreshCount, setRefreshCount] = useState(0)
  const refreshTable = () => {
    setRefreshCount(refreshCount + 1)
  }

  // for pagination
  const itemsPerPage = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  const handleDetailClick = (datum) => {
    // to reload the customer details page with new customer id
    dispatchCCSManagerContext({
      type: CCSManagerActions.SET_SELECTED_CUSTOMER,
      data: { id: datum.id }
    })
    setDetailClicked(true)
  }

  const columns = [
    {
      property: 'name',
      type: 'string',
      header: t('name'),
      render: (datum) => (
        <Box direction="row" align="center" gap="small">
          <Box flex={{ shrink: 0 }}>
            <AvatarInfo
              avatarChar="one"
              primaryInfo={datum?.name || ''}
              secondaryInfo=""
              testId="account-logo"
              avatarOnly
              {...(datum?.logo && { avatarSrc: datum?.logo })}
            />
          </Box>
          <Typography type="text" size="medium" weight="bold">
            {datum?.name}
          </Typography>
        </Box>
      )
    },
    {
      property: 'id',
      type: 'string',
      header: t('customer_id', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      })
    },
    {
      property: 'description',
      type: 'string',
      header: t('description')
    },
    {
      property: 'accessed_at',
      type: 'date',
      header: t('last_visited'),
      render: (datum) => {
        if (datum?.accessed_at) {
          const date = datum?.accessed_at * 1000
          const weekDifference = dayjs().diff(date, 'week')
          return weekDifference > 4
            ? dayjs(date).format('MMM D, YYYY')
            : dayjs(date).fromNow()
        }
        return '--'
      }
    },
    {
      property: 'actions',
      header: '',
      primary: true,
      render: (datum) => (
        <ActionButton
          showOneActionAsDropDown
          actions={[
            {
              label: t('view_details'),
              onClick: () => {
                handleDetailClick(datum)
              },
              visibility: {
                rbac: {
                  permission: 'ccs.accounts.platform.customer.view',
                  resource: '/ccs/accounts/platform/customer'
                }
              }
            },
            {
              label: t('edit_tenant'),
              onClick: () => {
                setSelectedRowData(datum)
                setEditTenantModal(true)
              },
              visibility: {
                rbac: {
                  permission: 'ccs.accounts.platform.customer.edit',
                  resource: '/ccs/accounts/platform/customer'
                }
              }
            },
            {
              label: t('delete_tenant'),
              onClick: () => {
                setSelectedRowData(datum)
                setDeleteTenantModal(true)
              },
              visibility: {
                rbac: {
                  permission: 'ccs.accounts.platform.customer.delete',
                  resource: '/ccs/accounts/platform/customer'
                }
              }
            }
          ]}
          testId="tenants-table-action-btn"
          customRenderer={(actionBtn, visibility, idx) => {
            return (
              <VisibilityWrapper
                key={idx} // eslint-disable-line react/no-array-index-key
                rbac={visibility.rbac}
              >
                {actionBtn}
              </VisibilityWrapper>
            )
          }}
        />
      )
    }
  ]
  useEffect(() => {
    let isCurrent = true
    const request = {
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      platform_customer_id: customerId,
      ...(searchVal.trimStart().length > 0 && {
        search_string: searchVal.trimStart()
      })
    }

    // if view detail is clicked, the customer id changes for customer details page, we go to users tab as the page reloads
    // so we are blocking this API call
    if (!detailClicked)
      getTableData(
        'tenants',
        oidcUser.access_token,
        request,
        setTotalItems,
        setErrorMessage,
        t
      ).then((data) => {
        if (!isCurrent) return
        setTenantsData(data)
      })
    return () => {
      isCurrent = false
    }
  }, [
    customerId,
    oidcUser.access_token,
    searchVal,
    page,
    t,
    refreshCount,
    detailClicked
  ])

  return (
    <Box margin={{ vertical: 'small' }}>
      <DataTable
        pagination={{
          totalItems,
          itemsPerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        grid={{
          columns,
          data: tenantsData,
          pad: { body: { horizontal: 'small', vertical: 'small' } }
        }}
        search={{
          onSearchValueChange: (value) => {
            setPage(1)
            setSearchVal(value)
          },
          placeholder: t('search-box-placeholder')
        }}
        summary={{ entityName: t('tenants') }}
        testId="tenants-data-table"
      />
      {editTenantModal && (
        <EditTenantModal
          tenantData={selectedRowData}
          setShowModal={setEditTenantModal}
          refreshTenantsList={refreshTable}
        />
      )}
      {deleteTenantModal && (
        <DeleteTenantModal
          tenantData={selectedRowData}
          setShowModal={setDeleteTenantModal}
          refreshTenantsList={refreshTable}
        />
      )}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-inline-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}

TenantsDataTable.propTypes = {
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
}

export { TenantsDataTable }
