import { get } from '../../../../../../../utils/api-utils'

// list all the api-utils here
export const pullSSOConfigStatus = (location, oidcUser, onDone, onError) => {
  let timerId = null
  get(location, {}, oidcUser.access_token, true).then(
    (response) => {
      if (response?.data?.status === 'DONE') {
        if (
          response?.data?.response?.status_code === 200 ||
          response?.data?.response?.status_code === 204
        ) {
          // done with success
          // clear timer
          if (timerId) clearTimeout(timerId)
          onDone()
        } else {
          // done with error
          const errorMsg = response?.data?.response?.data?.message
          let error = null
          if (errorMsg) {
            error = {
              response: {
                data: {
                  message: errorMsg
                }
              }
            }
          } else {
            error = {
              response: {
                data: {
                  response: {
                    status_code: response?.data?.response?.status_code || 0
                  }
                }
              }
            }
          }
          // clear timer
          if (timerId) clearTimeout(timerId)
          onError(error)
        }
      } else if (!timerId) {
        timerId = setTimeout(
          pullSSOConfigStatus,
          5000,
          location,
          oidcUser,
          onDone,
          onError
        )
      }
    },
    (error) => {
      // clear timer
      if (timerId) clearTimeout(timerId)
      onError(error)
    }
  )
}
