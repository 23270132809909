import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useHistory } from 'react-router'

import {
  Anchor,
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../../components'

const WorkspaceListModal = ({
  setShowModal,
  workspaceGroup,
  selectedWorkspaceIds
}) => {
  const { t } = useTranslation(['authz'])
  const history = useHistory()

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              type="heading"
              level="2"
              size="medium"
              testId="workspace-header-title"
            >
              {t('workspace_group.card_title')}
            </Typography>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <Box margin={{ bottom: 'small' }}>
            <Typography type="paragraph" testId="workspace-header-desc">
              {t('workspace_group.workspace_group_desc')}
            </Typography>
          </Box>
          <Box margin={{ bottom: 'medium', left: 'small' }}>
            {selectedWorkspaceIds.map((selectedWorkspace) => {
              return (
                <Typography type="text">
                  <Anchor
                    href={`/manage-account/identity/workspace-groups/${selectedWorkspace?.id}`}
                    label={
                      workspaceGroup.filter(
                        (workspace) => workspace?.id === selectedWorkspace?.id
                      )[0]?.displayName
                    }
                    plain
                    onClick={(e) => {
                      e.preventDefault()
                      history.push(
                        `/manage-account/identity/workspace-groups/${selectedWorkspace?.id}`
                      )
                    }}
                  />
                </Typography>
              )
            })}
          </Box>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('self-delete.close'),
                  primary: true,
                  testId: 'cancel-btn',
                  onClick: () => {
                    setShowModal(false)
                  }
                }
              ]}
              testId="cancel-button"
            />
          }
        />
      }
      onClose={() => {
        setShowModal(false)
      }}
      testId="workspacegroup-list-modal"
    />
  )
}

WorkspaceListModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  selectedWorkspaceIds: PropTypes.array,
  workspaceGroup: PropTypes.array
}

WorkspaceListModal.defaultProps = {
  workspaceGroup: [],
  selectedWorkspaceIds: []
}

export { WorkspaceListModal }
