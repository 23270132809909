import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../../components'
import { WizardContext } from '../../../../../components/wizard/WizardContext'
import { TagsEdit } from '../../../tags/TagsEdit'

export const AssignTags = () => {
  const { t } = useTranslation(['location'])
  const { formValues, setFormValues } = useContext(WizardContext)
  const [isNewAssignment, setIsNewAssignment] = useState(false)

  const [currentTags] = useState(formValues.tags || [])

  return (
    <>
      <Typography type="text">{t('assign_tags_location')}</Typography>
      <TagsEdit
        onChange={(tags) => {
          setFormValues({
            ...formValues,
            tags: [...tags]
          })
        }}
        initialTags={currentTags}
        isNewAssignment={isNewAssignment}
        setIsNewAssignment={setIsNewAssignment}
      />
    </>
  )
}
