// StatusCard.js
import React, { useState } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { Close } from 'grommet-icons'

import { Typography } from '../../../components'

import StatusIcon from './StatusIcon'

const StatusCard = ({ webhookStatus, showCard, t }) => {
  const [isCardVisible, setCardVisible] = useState(true)

  const handleClose = () => {
    if (webhookStatus === 'WARNING') {
      setCardVisible(false)
    }
  }

  if (!isCardVisible || !showCard) {
    return null
  }

  const statusColorMap = {
    PENDING: 'status-unknown',
    WARNING: 'status-warning',
    ERROR: 'status-critical'
  }

  return (
    <Box
      margin={{ top: 'small' }}
      background={{
        color: statusColorMap[webhookStatus],
        opacity: 0.24
      }}
      round="xsmall"
      style={{ whiteSpace: 'wrap' }}
      pad={{ vertical: 'xsmall', horizontal: 'small' }}
      direction="row"
      justify="between"
    >
      <Typography
        type="text"
        testId={`${webhookStatus.toLowerCase()}-card`}
        style={{ whiteSpace: 'wrap' }}
        size="medium"
        icon={<StatusIcon status={webhookStatus} />}
      >
        {t(`automations:webhooks.${webhookStatus.toLowerCase()}_card_msg`)}
      </Typography>
      {webhookStatus === 'WARNING' && <Close onClick={handleClose} />}
    </Box>
  )
}

StatusCard.propTypes = {
  webhookStatus: PropTypes.string.isRequired,
  showCard: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}

export default StatusCard
