import React, { useEffect, useState, useContext } from 'react'
import { Box, Grid, PageHeader, ResponsiveContext } from 'grommet'
import { StatusCritical } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import { SideMenu, MyServicesWrapper } from '../../../dashboard/components'
import { Loader, Notification } from '../../../components'
import { getErrorMessage } from '../../../utils/api-utils'
import { Layout } from '../../commoncomponents/layout/Layout'
import { useAxiosAuth } from '../../../dashboard/hooks'
import NewTogglableMyServices from '../components/NewTogglableMyServices'

const NewMyServices = () => {
  const [myServicesData, setMyServicesData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [regions, setRegions] = useState([])
  const [selectedRegion, setSelectedRegion] = useState('all')
  const { t } = useTranslation(['dashboard'])
  const size = useContext(ResponsiveContext)
  const axios = useAxiosAuth()

  useEffect(() => {
    axios
      .get('/service-catalog/v1alpha1/my-services')
      .then(({ data: { items } }) => {
        // TO-DO code cleanup iam
        // added code to control igc as service
        // while code cleanup clean remove the code and handle in api
        // adding this due to avoid localstorage IGC
        const filterServiceByIgcSlug = items?.map((services) => {
          return {
            region: services.region,
            provisions: services?.provisions?.filter(
              (service) => service.slug !== 'IGC'
            )
          }
        })
        setMyServicesData(filterServiceByIgcSlug || [])
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error, t))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [axios, t])

  useEffect(() => {
    const regionsValue = myServicesData?.map((item) => item.region)
    const regionsName = ['all', ...new Set(regionsValue)]
    const regionsToSet = regionsName.map((region) => ({
      label: region === 'all' ? t('common.all_regions') : region,
      value: region
    }))
    setRegions(regionsToSet)
  }, [myServicesData, t])

  return (
    <Layout>
      <Box>
        <Box pad={{ horizontal: 'xlarge', vertical: 'xsmall' }}>
          {errorMessage && (
            <Notification
              backgroundColor="status-critical"
              icon={<StatusCritical />}
              onClose={() => setErrorMessage('')}
              position="top"
              testId="my-services-error-notification"
              text={errorMessage}
            />
          )}
          <Box margin={{ bottom: 'medium' }}>
            <PageHeader
              title={t('common.services')}
              subtitle={t('common.services_subtitle')}
              testId="service-centric-services-header"
            />
          </Box>
          <Grid
            rows={['auto']}
            columns={size === 'xlarge' ? ['small', 'flex'] : ['full']}
            gap="large"
          >
            <SideMenu active="my-services" />
            <Box>
              <MyServicesWrapper
                regions={regions}
                setSelectedRegion={setSelectedRegion}
              />
              {isLoading && !errorMessage && (
                <Box align="center" justify="center" alignSelf="center">
                  <Loader testId="loader-spinner" />
                </Box>
              )}
              {!isLoading && (
                <NewTogglableMyServices
                  myServicesData={myServicesData}
                  selectedRegion={selectedRegion}
                />
              )}
            </Box>
          </Grid>
        </Box>
      </Box>
    </Layout>
  )
}

export default NewMyServices
