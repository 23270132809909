import { Box, FormField } from 'grommet'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ButtonGroup,
  CCSForm,
  Dropdown,
  ModalDialog,
  Typography,
  ModalHeader
} from '../../../../components'
import { getFolderList } from '../../../manage-account/utils'

const MoveToFolderModal = ({
  onSetOpen,
  count,
  openConfirmationModal,
  customerId,
  isCCSManager
}) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const [folderData, setFolderData] = useState({
    folder_name: '',
    folder_uuid: ''
  })
  const [listOfFolders, setListOfFolders] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  // for paginating on scroll
  const itemsPerPage = 50
  const [page, setPage] = useState(0)
  const [totalItems, setTotalItems] = useState(itemsPerPage)

  useEffect(() => {
    const param = {
      limit: itemsPerPage,
      page,
      ...(isCCSManager && { platform_customer_id: customerId })
    }
    getFolderList({
      token: oidcUser.access_token,
      param,
      t,
      setErrorMessage,
      isCCSManager
    }).then((data) => {
      const foldersResponse = data?.folders
      if (foldersResponse) {
        setListOfFolders((prevState) => [...prevState, ...foldersResponse])
        setTotalItems(data?.pagination?.total_count || itemsPerPage)
      }
    })
  }, [oidcUser.access_token, t, customerId, isCCSManager, page])

  const confirmAction = () => {
    openConfirmationModal(folderData)
    onSetOpen(false)
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          subtitle={t('move_to_folder_subtitle')}
          title={
            <Typography level="1" type="heading" testId="move-to-folder-title">
              <Trans i18nKey="move_to_folder_title" t={t} count={count}>
                <strong>{{ count }}</strong>
              </Trans>
            </Typography>
          }
        />
      }
      content={
        <Box>
          <CCSForm
            onSubmit={() => confirmAction()}
            onReset={() => onSetOpen(false)}
            errorMessage={errorMessage}
            testId="move-to-folder-form"
            validate="blur"
          >
            <Box
              margin={{ top: 'medium', bottom: 'xxsmall' }}
              direction="column"
              justify="between"
              gap="small"
            >
              <FormField
                data-testid="folder-name-field"
                label={t('folder')}
                name="foldername"
                width="medium"
                required
              >
                <Dropdown
                  name="foldername"
                  options={listOfFolders}
                  labelKey="folder_name"
                  valueKey={{ key: 'folder_name', reduce: true }}
                  value={folderData.folder_name}
                  onChange={({ option }) =>
                    setFolderData({
                      folder_name: option.folder_name,
                      folder_uuid: option.folder_uuid
                    })
                  }
                  onMore={() => {
                    if (
                      totalItems > itemsPerPage &&
                      page < totalItems / itemsPerPage - 1
                    )
                      setPage(page + 1)
                  }}
                  testId="movetofolder-name-input"
                />
              </FormField>
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    type: 'reset'
                  },
                  {
                    id: 1,
                    label: t('move_to_folder'),
                    primary: true,
                    type: 'submit',
                    testId: 'move-to-folder-action-btn'
                  }
                ]}
                testId="two-buttons"
              />
            </Box>
          </CCSForm>
        </Box>
      }
      testId="move-to-folder-modal-dialog"
      onClose={() => onSetOpen(false)}
      width="medium"
    />
  )
}

MoveToFolderModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  openConfirmationModal: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  customerId: PropTypes.string,
  isCCSManager: PropTypes.bool
}
MoveToFolderModal.defaultProps = {
  customerId: undefined,
  isCCSManager: undefined
}

export { MoveToFolderModal }
