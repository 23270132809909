import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { PageContainer } from './PageContainer'

export const PageLayout = ({
  header,
  footer,
  testId,
  banner,
  errorBanner,
  ...rest
}) => (
  <PageContainer data-testid={testId}>
    <Box height={{ min: '100%' }}>
      {header}
      {banner && <Box height={{ min: 'xxsmall' }}>{banner}</Box>}
      {errorBanner && <Box height={{ min: 'xxsmall' }}>{errorBanner}</Box>}
      <PageContainer {...rest} />
    </Box>
    {/* NO Footer for GLCP in Cancun release (Mar 23 2022) */}
    {footer}
  </PageContainer>
)

PageLayout.propTypes = {
  header: PropTypes.element,
  footer: PropTypes.element,
  banner: PropTypes.element,
  testId: PropTypes.string,
  errorBanner: PropTypes.element
}

PageLayout.defaultProps = {
  header: undefined,
  footer: undefined,
  banner: undefined,
  testId: 'glcp-page-layout',
  errorBanner: undefined
}
