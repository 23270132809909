import React from 'react'
import { Box } from 'grommet'
import { useHistory } from 'react-router-dom'
import { StatusCritical } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import { ButtonGroup, Typography } from '../../components'
import { Layout } from '../commoncomponents/layout/Layout'

function AppUnavailable() {
  // This page is shown where GLCP sessions API times out and not able to get customer accounts
  const history = useHistory()
  const { t } = useTranslation(['authn'])
  return (
    <Box
      align="center"
      justify="center"
      alignContent="center"
      data-testid="no-access"
      pad="xlarge"
    >
      <StatusCritical color="status-critical" size="large" />
      <Typography
        type="heading"
        level={2}
        margin={{ bottom: 'xsmall' }}
        testId="app-unavailable-title"
      >
        {t('acct_onboarding.app_unavailable_heading')}
      </Typography>
      <Typography type="text" size="medium" testId="app-unavailable-subtitle">
        {t('acct_onboarding.app_unavailable_desc')}
      </Typography>
      <Box margin={{ vertical: 'medium' }}>
        <ButtonGroup
          buttonList={[
            {
              label: t('acct_onboarding.return_to_signin'),
              primary: true,
              testId: 'return-to-signin-btn',
              onClick: () => {
                history.push('/sign-out')
              }
            },
            {
              label: t('acct_onboarding.contact_support'),
              secondary: true,
              testId: 'contact-support-btn',
              href: 'https://h41390.www4.hpe.com/support/index.html?form=glsupport',
              target: '_blank'
            }
          ]}
          testId="two-buttons"
        />
      </Box>
      <Box margin={{ top: 'medium' }}>
        <Typography type="text" size="medium" testId="app-status">
          {t('acct_onboarding.app_status')}{' '}
          <a
            href="https://status.greenlake.hpe.com/"
            target="_blank"
            rel="noreferrer"
          >
            status.greenlake.hpe.com
          </a>
        </Typography>
      </Box>
    </Box>
  )
}

const AppUnavailablePage = () => {
  return (
    <Layout hideHeaderOptions={['nav', 'bell', 'apps']}>
      <AppUnavailable />
    </Layout>
  )
}

export default AppUnavailablePage
