import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, List, Layer, Footer } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { post } from '../../../../utils/api-utils'
import {
  Typography,
  Button,
  Loader,
  ModalDialog,
  ModalHeader
} from '../../../../components'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { useUPSContext } from '../../../../context/ups-context'

import ListItem from './ListItem'

const EditQuickLinkModel = ({
  onSetOpen,
  quickActionPanel,
  setUpdateSuccesfull
}) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['common'])
  const [selected, setSelected] = useState(quickActionPanel)
  const [showLoader, setLoader] = useState(false)
  const [showNotification, setNotification] = useState(null)
  const { dispatchUPSContext } = useUPSContext()
  // POST API call to update Quicklinks visibility
  const saveChanges = () => {
    setLoader(true)
    const updatedQuicklinksData = selected.map((item) => {
      return { linkKey: item.linkKey, visible: item.visible }
    })
    post(
      '/user-prefs/v1alpha1/save-preferences',
      {
        category: 'quicklinks',
        data: updatedQuicklinksData
      },
      oidcUser.access_token
    )
      .then((response) => {
        if (response?.status === 200) {
          dispatchUPSContext({
            type: 'SET_UPS_DATA',
            data: {
              upsData: { quicklinks: updatedQuicklinksData }
            }
          })
        }
        onSetOpen(false)
        setUpdateSuccesfull(true)
        setLoader(false)
      })
      .catch((error) => {
        setNotification(displayApiError(error, t, setNotification))
        setLoader(false)
      })
  }

  // used to reset all checkboxes in the modal
  const resetLinks = () => {
    setSelected(
      selected.map((item) => {
        return { ...item, visible: true }
      })
    )
  }

  // TODO: create a useEffect function that will prepare an array of object for a POST call to update visible links
  return (
    <>
      {showNotification}
      {showLoader && (
        <Layer
          position="center"
          data-testid="edit-quick-link-loader-layer"
          plain
        >
          <Loader testId="loader-spinner" />
        </Layer>
      )}
      <ModalDialog
        testId="quicklink-edit-modal"
        onClose={() => onSetOpen(false)}
        onEsc={() => onSetOpen(false)}
        position="center"
        header={
          <ModalHeader
            title={
              <Typography
                type="heading"
                level="2"
                testId="edit-quick-links-model-title"
              >
                {t('dashboard.quick_links_card.edit_quick_links.title')}
              </Typography>
            }
          />
        }
        content={
          <Box gap="medium" margin={{ top: 'medium' }}>
            <Box gap="xsmall">
              <Box height="medium" overflow="auto">
                <List
                  aria-label={t(
                    'dashboard.quick_links_card.edit_quick_links.edit_list_label'
                  )}
                  data={selected}
                  primaryKey="value"
                  pad={{ vertical: 'xxsmall' }}
                >
                  {({ title, linkKey, testId, visible }) => (
                    <ListItem
                      label={title}
                      checked={visible}
                      setChecked={(v) => {
                        const nextSelected = [...selected]
                        const link = nextSelected.find(
                          (item) => item.linkKey === linkKey
                        )
                        if (link) {
                          Object.assign(link, { linkKey, visible: v })
                        }
                        setSelected(nextSelected)
                      }}
                      testId={testId}
                    />
                  )}
                </List>
              </Box>
            </Box>
          </Box>
        }
        footer={
          <Footer direction="row" gap="large">
            <Button
              default
              label={t(
                'dashboard.quick_links_card.edit_quick_links.reset_button'
              )}
              onClick={resetLinks}
              testId="quick-link-reset-btn"
            />
            <Box direction="row" gap="small">
              <Button
                default
                label={t(
                  'dashboard.quick_links_card.edit_quick_links.cancel_button'
                )}
                onClick={() => {
                  onSetOpen(false)
                }}
                testId="quick-link-cancel-btn"
              />
              <Button
                primary
                type="submit"
                label={t(
                  'dashboard.quick_links_card.edit_quick_links.save_button'
                )}
                testId="quick-link-save-changes-btn"
                onClick={saveChanges}
              />
            </Box>
          </Footer>
        }
      />
    </>
  )
}

EditQuickLinkModel.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  quickActionPanel: PropTypes.array.isRequired,
  setUpdateSuccesfull: PropTypes.func.isRequired
}

export default EditQuickLinkModel
