import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'
import { Hpe } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { Logo, Search, Typography } from '../../../../../components'
import { isCoP } from '../../../../../utils/feature-flag-utils'
import ArubaImage from '../../../../../images/aruba.png'
import { get } from '../../../../../utils/api-utils'
import { displayApiError } from '../../../../../utils/error-handling-utils'
import { isServiceCentric } from '../../../../../utils/account-utils'

const RoleDetails = ({ selectedRole }) => {
  const {
    description,
    application_name: name,
    application_logo: applicationLogo
  } = selectedRole || {}

  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const { t } = useTranslation(['authz, common'])
  const [searchTxt, setSearchTxt] = useState('')
  const { oidcUser } = useReactOidc()
  const [permissionsCount, setPermissionsCount] = useState(0)
  const [role, setRole] = useState({})
  const [policies, setPolicies] = useState(role?.resourcePolicies)
  const [resourcePolicies, setResourcePolicies] = useState([])
  const [showNotification, setShowNotification] = useState(null)

  useEffect(() => {
    get(
      `/ui-doorway/ui/v1/um/customers/roles/${selectedRole?.slug}`,
      { application_id: selectedRole?.application_id },
      oidcUser.access_token
    ).then(
      (response) => {
        const { data } = response
        setRole({
          ...data,
          resourcePolicies: cloneDeep(data?.resource_policies),
          duplicateFlag: true
        })
        if (data?.resource_policies) {
          setResourcePolicies([...data.resource_policies])
        }
      },
      (error) => {
        let pageCustomErrorMessage = ''
        if (error?.response?.status === 403) {
          pageCustomErrorMessage = t('common:error_messages.ERR_403')
        }
        setShowNotification(
          displayApiError(error, t, setShowNotification, pageCustomErrorMessage)
        )
      }
    )
  }, [oidcUser.access_token, setRole, t, selectedRole, setShowNotification])

  useEffect(() => {
    let policiesList = []
    if (!searchTxt) {
      policiesList = resourcePolicies
    } else {
      const search = searchTxt.toLowerCase()
      resourcePolicies.forEach((policy) => {
        if (policy.resource.matcher.toLowerCase().includes(search)) {
          policiesList.push(policy)
        } else {
          const permissions = policy.permissions.filter((p) =>
            p.slug.toLowerCase().includes(search)
          )
          if (permissions.length) {
            policiesList.push({
              ...policy,
              permissions
            })
          }
        }
      })
    }
    const count = policiesList.reduce(
      (res, item) => res + item.permissions.length,
      0
    )
    setPermissionsCount(count)
    setPolicies(policiesList)
  }, [searchTxt, resourcePolicies, setPolicies])

  return (
    <Box>
      {showNotification !== null && showNotification}
      <Typography
        type="text"
        weight="bold"
        size="xlarge"
        testId="details-heading"
        margin={{ vertical: 'medium' }}
      >
        {t('authz:details')}
      </Typography>
      <Typography type="text" testId="description-subheading">
        {t('authz:description')}
      </Typography>
      <Typography type="text" testId="description-value">
        {description}
      </Typography>
      <Typography
        type="text"
        margin={{ top: 'medium' }}
        testId="role-subheading"
      >
        {t('authz:roles.role_type')}
      </Typography>
      <Typography type="text" testId="role-value">
        {role.predefined
          ? t('authz:roles.build_in_role')
          : t('authz:roles.custom_role')}
      </Typography>
      <Typography
        type="text"
        margin={{ top: 'medium', bottom: 'xsmall' }}
        testId="application-subheading"
      >
        {newDashboardFlag
          ? t('authz:roles.service_manager_name_header')
          : t('authz:roles.application_name_header')}
      </Typography>
      <Box direction="row" align="center">
        {role.application_id === '00000000-0000-0000-0000-000000000000' ? (
          <Hpe color="brand" size="small" />
        ) : (
          applicationLogo !== null && (
            <Logo
              testId="application_logo"
              size="xxsmall"
              url={isCoP() ? ArubaImage : applicationLogo}
            />
          )
        )}
        <Typography
          type="text"
          margin={{ left: 'small' }}
          testId="application-value"
        >
          {role.application_id === '00000000-0000-0000-0000-000000000000'
            ? t('common:platform_application_brand_name')
            : name}
        </Typography>
      </Box>
      <Box margin={{ vertical: 'medium' }}>
        <Typography
          type="text"
          weight="bold"
          size="xlarge"
          testId="permissions-subheading"
        >
          <>
            {t('authz:assignments.permission_plural')} ({permissionsCount})
          </>
        </Typography>
      </Box>
      {resourcePolicies && resourcePolicies.length > 0 && (
        <Box>
          <Box margin={{ bottom: 'small' }}>
            <Search
              size="small"
              placeholder=""
              value={searchTxt}
              testId="search-field-permission"
              handleCustomSearch={setSearchTxt}
            />
          </Box>
          {policies.map((policy) => (
            <Box key={policy.resource.matcher} margin={{ top: 'small' }}>
              <Typography
                type="text"
                weight="bold"
                size="medium"
                testId={`permissions-heading-${policy.resource.matcher}`}
                margin={{ vertical: 'xsmall', horizontol: 'xsmall' }}
              >
                {policy.resource.name ?? policy.resource.matcher}
              </Typography>
              {policy.permissions.map((permission) => (
                <Typography
                  type="text"
                  key={permission.slug}
                  testId={`permissions-subheading-${permission.slug}`}
                  margin={{ vertical: 'xxsmall', bottom: 'xsmall' }}
                >
                  {permission.name ?? permission.slug}
                </Typography>
              ))}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

RoleDetails.propTypes = {
  selectedRole: PropTypes.object
}

RoleDetails.defaultProps = {
  selectedRole: { slug: '', application_id: '' }
}

export default RoleDetails
