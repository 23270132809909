import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Box, NameValueList, NameValuePair } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isEmpty } from 'lodash'

import { Typography } from '../../../../components'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { AccountLogo } from '../../../commoncomponents/account-logo/AccountLogo'
import {
  getCustomerAccount,
  isOrg,
  getOrganizationId
} from '../../../../utils/feature-flag-utils'
import { get } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../../utils/common-utils'
import { LIFECYCLE_STATE } from '../constants'

const AccountDetailsInfo = ({ showNameDesc, showForOrg }) => {
  const custAccountLoaded = getCustomerAccount()
  const { t } = useTranslation(['common'])
  const LDFlags = useFlags()
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const {
    nameValuePair: { name }
  } = useContext(ThemeContext)
  const orgId = getOrganizationId()
  const [orgName, setOrgName] = useState('')
  const getWorkspaceType = () => {
    if (custAccountLoaded.account_type === 'TENANT') {
      return custAccountLoaded.operational_mode === 'CUSTOMER_OWNED_INVENTORY'
        ? t('customer_managed_tenant_workspace_type')
        : t('msp_managed_tenant_workspace_type')
    }
    return t(custAccountLoaded.account_type, {
      account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
    })
  }
  // /organizations/v2alpha1/organizations/{organizationID} API is not ready from backend yet
  useEffect(() => {
    let isCurrent = true
    if (isOrg()) {
      if (orgId && orgId !== '') {
        get(`/organizations/v2alpha1/organizations/${orgId}`).then(
          (response) => {
            if (!isCurrent) return
            if (response?.data?.lifecycleState === LIFECYCLE_STATE.ACTIVE) {
              setOrgName(response?.data?.name ? response?.data?.name : '')
            }
          },
          (error) => {
            setShowErrorNotification(
              displayApiError(error, t, setShowErrorNotification)
            )
          }
        )
      }
    }
    return () => {
      isCurrent = false
    }
  }, [t, LDFlags, orgId])

  // TODO: Fix the list component to populate this
  return (
    <>
      <Box direction="row" justify="between" align="start">
        {showErrorNotification}
        {custAccountLoaded && (
          <Box gap="small">
            <AccountLogo
              account={custAccountLoaded}
              logoOnly
              avatarSize="large"
              title={custAccountLoaded.company_name}
              testId="account-details"
            />
            <Typography truncate level="2" testId="company-name" type="heading">
              {custAccountLoaded.company_name}
            </Typography>
          </Box>
        )}
      </Box>
      <Box margin={{ top: 'small' }}>
        <NameValueList pairProps={{ direction: 'column' }}>
          {showNameDesc && (
            <>
              <NameValuePair
                name={
                  <Typography testId="workspace-name-lbl" type="text" {...name}>
                    {t('common:orgs_pce_workspace.workspace_name', {
                      workspace: t('common:business_object.wkspc_capitalized')
                    })}
                  </Typography>
                }
              >
                {custAccountLoaded && (
                  <Typography testId="workspace-name-val" type="paragraph">
                    {custAccountLoaded.company_name}
                  </Typography>
                )}
              </NameValuePair>
              <NameValuePair
                name={
                  <Typography
                    testId="workspace-description-lbl"
                    type="text"
                    {...name}
                  >
                    {t('common:orgs_pce_workspace.workspace_description', {
                      workspace: t('common:business_object.wkspc_capitalized')
                    })}
                  </Typography>
                }
              >
                {custAccountLoaded && (
                  <Typography testId="account-description-val" type="paragraph">
                    {custAccountLoaded.description}
                  </Typography>
                )}
              </NameValuePair>
            </>
          )}
          {showForOrg ? (
            <>
              <NameValuePair
                name={
                  <Typography testId="workspace-id-lbl" type="text" {...name}>
                    {t('common:orgs_pce_workspace.workspace_id', {
                      workspace: t('common:business_object.wkspc_capitalized')
                    })}
                  </Typography>
                }
              >
                {custAccountLoaded && (
                  <Typography testId="workspace-id-val" type="paragraph">
                    {custAccountLoaded.platform_customer_id}
                  </Typography>
                )}
              </NameValuePair>
              <VisibilityWrapper hideFor={{ account: ['TENANT'] }}>
                <NameValuePair
                  name={
                    <Typography
                      testId="workspace-status-lbl"
                      type="text"
                      {...name}
                    >
                      {t('common:orgs_pce_workspace.workspace_status', {
                        workspace: t('common:business_object.wkspc_capitalized')
                      })}
                    </Typography>
                  }
                >
                  {custAccountLoaded && (
                    <Typography testId="workspace-status-val" type="paragraph">
                      {t(custAccountLoaded.account_status)}
                    </Typography>
                  )}
                </NameValuePair>
              </VisibilityWrapper>
              <NameValuePair
                name={
                  <Typography testId="workspace-type-lbl" type="text" {...name}>
                    {t('common:orgs_pce_workspace.workspace_type', {
                      workspace: t('common:business_object.wkspc_capitalized')
                    })}
                  </Typography>
                }
              >
                {custAccountLoaded && (
                  <Typography testId="workspace-type-val" type="paragraph">
                    {t(custAccountLoaded.account_type)}
                  </Typography>
                )}
              </NameValuePair>
            </>
          ) : (
            <>
              <NameValuePair
                name={
                  <Typography testId="account-id-lbl" type="text" {...name}>
                    {t('common:account_id', {
                      account: getWorkspaceString(
                        showWorkspaceString,
                        t,
                        WKSPC_CAPITALIZED
                      )
                    })}
                  </Typography>
                }
              >
                {custAccountLoaded && (
                  <Typography testId="account-id-val" type="paragraph">
                    {custAccountLoaded.platform_customer_id}
                  </Typography>
                )}
              </NameValuePair>
              <VisibilityWrapper hideFor={{ account: ['TENANT'] }}>
                <NameValuePair
                  name={
                    <Typography
                      testId="account-status-lbl"
                      type="text"
                      {...name}
                    >
                      {t('common:account_status', {
                        account: getWorkspaceString(
                          showWorkspaceString,
                          t,
                          WKSPC_CAPITALIZED
                        )
                      })}
                    </Typography>
                  }
                >
                  {custAccountLoaded && (
                    <Typography testId="account-status-val" type="paragraph">
                      {t(custAccountLoaded.account_status)}
                    </Typography>
                  )}
                </NameValuePair>
              </VisibilityWrapper>
              <NameValuePair
                name={
                  <Typography testId="account-type-lbl" type="text" {...name}>
                    {t('common:account_type', {
                      account: getWorkspaceString(
                        showWorkspaceString,
                        t,
                        WKSPC_CAPITALIZED
                      )
                    })}
                  </Typography>
                }
              >
                {custAccountLoaded && (
                  <Typography testId="account-type-val" type="paragraph">
                    {getWorkspaceType()}
                  </Typography>
                )}
              </NameValuePair>
              {isOrg() && !isEmpty(orgName) && (
                <NameValuePair
                  name={
                    <Typography testId="org-name-lbl" type="text" {...name}>
                      {t('common:business_object.organization')}
                    </Typography>
                  }
                >
                  {custAccountLoaded && (
                    <Typography testId="org-name-val" type="paragraph">
                      {orgName}
                    </Typography>
                  )}
                </NameValuePair>
              )}
            </>
          )}
        </NameValueList>
      </Box>
    </>
  )
}

AccountDetailsInfo.propTypes = {
  showNameDesc: PropTypes.bool,
  showForOrg: PropTypes.bool
}

AccountDetailsInfo.defaultProps = {
  showNameDesc: false,
  showForOrg: false
}

export default AccountDetailsInfo
