import { PropTypes } from 'prop-types'
import { Box, Markdown, Grid, Tag } from 'grommet'
import { useTranslation } from 'react-i18next'
import { ChatOption } from 'grommet-icons'

import ListItemLink from '../components/ResultListTemplateHelper/ListItemLink'
import { Ruler } from '../../../components'

import ExcerptFormatDate from './ExcerptFormatDate'

const ForumTemplate = ({ result, engine }) => {
  const { t } = useTranslation('support_hub_search')

  const author = result.raw.limessageauthor
  const subcategory = result.raw.hpesubcategory
  const universalDate = result.raw.hpescuniversaldate
  const date = new Date(universalDate)
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })

  const solvedBadge =
    result.raw.limessageissolution === 'True' ? (
      <Tag
        size="small"
        align="center"
        background="rgba(0, 200, 255, 0.1)"
        border={false}
        value={t('solved')}
      />
    ) : null

  return (
    <Grid
      pad={{ top: 'small' }}
      rows={['auto', 'auto']}
      columns={['auto']}
      gap="small"
    >
      <Box direction="row" align="center">
        <Box margin={{ right: 'xsmall' }}>
          <ChatOption data-testid="forum-icon" size="small" />
        </Box>
        <ListItemLink result={result} engine={engine} />
        <Box>{solvedBadge}</Box>
      </Box>

      <ExcerptFormatDate result={result} formattedDate={formattedDate} />

      <Box alignSelf="start">
        <Markdown>
          {t('posted_by', {
            author,
            subcategory
          })}
        </Markdown>
      </Box>
      <Ruler background="border" margin={{ top: 'xsmall', bottom: 'none' }} />
    </Grid>
  )
}

ForumTemplate.propTypes = {
  result: PropTypes.object.isRequired,
  engine: PropTypes.object.isRequired
}

export default ForumTemplate
