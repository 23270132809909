// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import {
  Box,
  Markdown,
  Paragraph,
  Heading,
  List,
  Anchor,
  Button,
  Footer,
  Tag
} from 'grommet'
import React from 'react'
import { Search as SearchIcon, FormNext } from 'grommet-icons'
import PropTypes from 'prop-types'

import { Typography, Loader } from '../../../../components'

import { getQueryArray } from './utils'

const MarkdownComponent = ({ data }) => {
  return (
    <Markdown
      data-testid="markdown-component"
      components={{
        p: {
          component: Paragraph,
          props: {
            fill: 'true'
          }
        },
        h1: {
          component: Heading,
          props: { level: '1', margin: { vertical: 'xsmall' } }
        },
        h2: {
          component: Heading,
          props: { level: '2', margin: { vertical: 'xsmall' } }
        },
        h3: {
          component: Heading,
          props: { level: '3', margin: { vertical: 'xsmall' } }
        },
        h4: {
          component: Heading,
          props: { level: '4', margin: { vertical: 'xsmall' } }
        },
        box: {
          component: Box,
          props: { pad: { left: 'medium' }, alignContent: 'start' }
        }
      }}
    >
      {data}
    </Markdown>
  )
}
MarkdownComponent.propTypes = {
  data: PropTypes.string.isRequired
}

const ListComponent = ({ content, handleListClick }) => {
  return (
    <List
      data={content?.items}
      onClickItem={(item) => {
        handleListClick(item.item)
      }}
      eventType="item-select"
      pad="none"
      data-testid="autocomplete-list"
    >
      {(datum, index) => (
        <Box
          gap="small"
          pad={{ horizontal: 'xsmall', vertical: 'small' }}
          direction="row"
          align="center"
          alignContent="center"
          key={index}
          data-testid={`autocomplete-list-${index}`}
        >
          <SearchIcon id="search-icon" size="small" />
          <Typography type="text" testId={`autocomplete-label-${index}`}>
            {datum}
          </Typography>
        </Box>
      )}
    </List>
  )
}

ListComponent.propTypes = {
  content: PropTypes.any.isRequired,
  handleListClick: PropTypes.func.isRequired
}

const AdditionalResults = ({ data, i18nTranslation, handleActionClick }) => (
  <Box
    pad={{ bottom: 'medium' }}
    border={{
      side: 'bottom',
      color: 'border-weak',
      size: 'small'
    }}
  >
    <Typography
      type="text"
      testId="additional-result-title"
      color="text-weak"
      size="xsmall"
      margin={{ vertical: 'small' }}
    >
      {i18nTranslation('global_search.additional_result')}
    </Typography>
    <Box gap="medium">
      {data &&
        data.map((item) => (
          <Box gap="xsmall" key={item.title}>
            <Typography
              type="heading"
              level="3"
              testId="additional-results-title"
            >
              <Anchor
                data-testid="additional-results-action"
                href={item.link}
                target="_blank"
                label={item.title}
                onClick={() => handleActionClick(item.title)}
              />
            </Typography>
            <Typography
              type="paragraph"
              maxLines={2}
              testId="additional-results-description"
              fill
            >
              <Markdown
                components={{
                  p: {
                    component: Paragraph,
                    props: {
                      fill: 'true',
                      margin: 'none'
                    }
                  }
                }}
              >
                {item.matchingLine}
              </Markdown>
            </Typography>
          </Box>
        ))}
    </Box>
  </Box>
)

AdditionalResults.propTypes = {
  data: PropTypes.array.isRequired,
  i18nTranslation: PropTypes.any.isRequired,
  handleActionClick: PropTypes.func.isRequired
}

const SimilarMatches = ({ data, handleListClick, i18nTranslation }) => (
  <Box
    pad={{ bottom: 'small' }}
    border={{
      side: 'bottom',
      color: 'border-weak',
      size: 'small'
    }}
  >
    <Typography
      type="text"
      testId="similar-search-title"
      color="text-weak"
      size="xsmall"
      margin={{ vertical: 'small' }}
    >
      {i18nTranslation('global_search.similar_searches')}
    </Typography>
    <ListComponent
      data-testid="similar-search-button"
      content={getQueryArray(data)}
      handleListClick={handleListClick}
    />
  </Box>
)

SimilarMatches.propTypes = {
  data: PropTypes.array.isRequired,
  handleListClick: PropTypes.func.isRequired,
  i18nTranslation: PropTypes.any.isRequired
}

const ActionResults = ({
  data,
  history,
  setOpenDropdown,
  i18nTranslation,
  handleActionClick
}) => (
  <Box
    pad={{ bottom: 'medium', top: 'small' }}
    border={{
      side: 'bottom',
      color: 'border-weak',
      size: 'small'
    }}
  >
    <Typography
      type="text"
      testId="next-steps-title"
      color="text-weak"
      size="xsmall"
      margin={{ vertical: 'small' }}
    >
      {i18nTranslation('global_search.next_steps')}
    </Typography>
    {data && (
      <Box gap="small">
        <Typography type="heading" level="3" testId="action-title">
          <Anchor
            onClick={() => {
              history.push(data.actionUrl)
              setOpenDropdown(false)
              handleActionClick(data.actionTitle)
            }}
            label={data.actionTitle}
            data-testid="next-steps-action"
          />
        </Typography>
        <Typography
          type="paragraph"
          maxLines={2}
          fill
          testId="action-description"
        >
          {data.actionDescription}
        </Typography>
      </Box>
    )}
  </Box>
)

ActionResults.propTypes = {
  data: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setOpenDropdown: PropTypes.func.isRequired,
  i18nTranslation: PropTypes.any.isRequired,
  handleActionClick: PropTypes.func.isRequired
}

const FooterComponent = ({ i18nTranslation }) => (
  <Box margin={{ vertical: 'small' }} pad={{ top: 'medium' }}>
    <Footer
      align="center"
      justify="end"
      gap="medium"
      background="#CBFAEB"
      round="medium"
    >
      <Box direction="column" pad="small" gap="xsmall">
        <Box direction="row">
          <Tag
            value={i18nTranslation('global_search.beta_tag')}
            testId="permission-modal-cancel-btn"
            background="green"
            border={false}
            pad={{ vertical: 'none' }}
          />
        </Box>
        <Box gap="xsmall">
          <Typography type="text" size="small">
            {i18nTranslation('global_search.beta_tag_description')}
            <Anchor
              label={i18nTranslation('global_search.beta_tag_link')}
              href="https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=gl&tof=gf"
              target="_blank"
              margin={{ left: 'xsmall' }}
            />
          </Typography>
        </Box>
      </Box>
    </Footer>
  </Box>
)

FooterComponent.propTypes = {
  i18nTranslation: PropTypes.any.isRequired
}

export const GlobalSearchPanel = ({
  content,
  handleListClick,
  handleActionClick,
  isAutoCompletion,
  showRecentSearch,
  loading,
  showFaq,
  setOpenDropdown,
  i18nTranslation
}) => {
  const history = document.querySelector('greenlake-header')?.historyObject
  return (
    <Box
      elevation="large"
      pad={{ horizontal: 'medium', vertical: 'small' }}
      width="large"
      height={{ min: 'auto', max: 'large' }}
      round="small"
      style={{ position: 'absolute', zIndex: 100 }}
      background="background"
      overflow="auto"
      direction="column"
      margin={{ horizontal: 'small' }}
      data-testid="global-search-drop-down"
    >
      {loading ? (
        <Box align="center" justify="center" margin="large">
          <Loader testId="global-search-loader" />
        </Box>
      ) : (
        <Box direction="column" flex="grow">
          {(isAutoCompletion || showFaq) && content?.items && (
            <Box
              pad={{ bottom: 'small' }}
              border={{
                side: 'bottom',
                color: 'border-weak',
                size: 'small'
              }}
            >
              {showFaq && (
                <Typography
                  size="xsmall"
                  type="text"
                  testId="faq-heading"
                  color="text-weak"
                  margin={{ vertical: 'xsmall', horizontal: 'xsmall' }}
                >
                  {i18nTranslation('global_search.faq')}
                </Typography>
              )}
              <ListComponent
                content={content}
                handleListClick={handleListClick}
                handleActionClick={handleActionClick}
              />
            </Box>
          )}
          {showRecentSearch && (
            <>
              {content?.searchFailed ? (
                <Box direction="column">
                  <Box height="auto">
                    <MarkdownComponent data={content?.searchFailed} />
                  </Box>
                </Box>
              ) : (
                <>
                  {content?.summary && content?.actual_match && (
                    <Box
                      direction="column"
                      pad={{ bottom: 'medium', top: 'small' }}
                      border={{
                        side: 'bottom',
                        color: 'border-weak',
                        size: 'small'
                      }}
                    >
                      <Typography
                        type="text"
                        testId="summary-title"
                        color="text-weak"
                        size="xsmall"
                      >
                        {i18nTranslation('global_search.summary')}
                      </Typography>
                      <Box height="auto">
                        <MarkdownComponent data={content?.summary} />
                        {content?.doc_results && (
                          <Button
                            data-testid="read-button"
                            pad={{ vertical: 'none', horizontal: 'small' }}
                            href={content?.doc_results[0].link}
                            target="_blank"
                            label={
                              <Typography
                                type="text"
                                size="medium"
                                weight="bold"
                                emphasis
                              >
                                {i18nTranslation('global_search.read_more')}
                              </Typography>
                            }
                            type="default"
                            icon={<FormNext size="large" />}
                            alignSelf="start"
                            reverse
                            align="baseline"
                            onClick={() => handleActionClick()}
                          />
                        )}
                      </Box>
                    </Box>
                  )}
                  <Box direction="column" gap="small">
                    {content?.action && (
                      <ActionResults
                        data={content?.action}
                        history={history}
                        setOpenDropdown={setOpenDropdown}
                        i18nTranslation={i18nTranslation}
                        handleActionClick={handleActionClick}
                      />
                    )}
                    {content?.results && (
                      <AdditionalResults
                        data={content?.results}
                        similarMatch={false}
                        i18nTranslation={i18nTranslation}
                        handleActionClick={handleActionClick}
                      />
                    )}
                    {content?.similar_match && (
                      <SimilarMatches
                        data={content?.similar_matches}
                        handleListClick={handleListClick}
                        i18nTranslation={i18nTranslation}
                      />
                    )}
                  </Box>
                </>
              )}
            </>
          )}
          <FooterComponent i18nTranslation={i18nTranslation} />
        </Box>
      )}
    </Box>
  )
}
GlobalSearchPanel.propTypes = {
  content: PropTypes.any.isRequired,
  handleListClick: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  isAutoCompletion: PropTypes.bool.isRequired,
  showRecentSearch: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  showFaq: PropTypes.bool.isRequired,
  setOpenDropdown: PropTypes.func.isRequired,
  i18nTranslation: PropTypes.any.isRequired
}
