import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import filter from 'lodash/filter'

import { Typography } from '../../../../../components'

const ApprovalDetails = ({ postDetails }) => {
  const { t } = useTranslation(['manage'])
  let getReasonArray
  if (postDetails?.reviewers) {
    getReasonArray = filter(postDetails?.reviewers, { reason: '' })
  }

  return (
    <Box width="100%" margin={{ bottom: 'small' }}>
      <Box
        gap="small"
        direction="row"
        alignSelf="start"
        justify="between"
        margin={{ vertical: 'medium' }}
        pad="small"
        border={{ side: 'bottom', color: 'border' }}
        width="inherit"
      >
        <Typography level="2" type="heading" testId="approval-title">
          {t('whats_new.approval.approval_status')}
        </Typography>
      </Box>
      <Box pad={{ horizontal: 'small' }}>
        <Typography type="text" size="large" testId="post-state">
          {t(`${postDetails?.state}`)}
        </Typography>
      </Box>
      <Box
        gap="small"
        direction="row"
        alignSelf="start"
        justify="between"
        margin={{ vertical: 'medium' }}
        pad="small"
        border={{ side: 'bottom', color: 'border' }}
        width="inherit"
      >
        <Typography level="2" type="heading" testId="reviewer-title">
          {t('whats_new.approval.reviewer')}
        </Typography>
      </Box>
      {postDetails?.reviewers &&
        postDetails?.reviewers.map((item, index) => (
          <Fragment key={item}>
            <Box justify="between" direction="row" pad="small">
              <Box direction="column" gap="small">
                <Typography
                  level="3"
                  type="heading"
                  testId={`reviewer-name-${index}`}
                >
                  {item?.firstName} {item?.lastName}
                </Typography>
                <Typography
                  size="small"
                  type="text"
                  testId={`reviewer-id-${index}`}
                >
                  {item?.id}
                </Typography>
              </Box>
              <Box>
                <Typography
                  size="medium"
                  type="text"
                  testId={`state-value-${index}`}
                >
                  {item?.state ? item?.state : t('whats_new.approval.pending')}
                </Typography>
              </Box>
            </Box>
          </Fragment>
        ))}

      <Box
        gap="small"
        direction="row"
        alignSelf="start"
        justify="between"
        margin={{ vertical: 'medium' }}
        pad="small"
        border={{ side: 'bottom', color: 'border' }}
        width="inherit"
      >
        <Typography level="2" type="heading" testId="comments-title">
          {t('whats_new.approval.comments')}
        </Typography>
      </Box>
      {postDetails?.reviewers &&
        postDetails?.reviewers.map((item, index) => (
          <Fragment key={item}>
            {item?.reason && (
              <Box justify="between" direction="row" pad="small">
                <Box direction="column" gap="small">
                  <Typography
                    level="3"
                    type="heading"
                    testId={`comment-reviewer-name-${index}`}
                  >
                    {item?.firstName} {item?.lastName}
                  </Typography>
                  <Typography
                    size="small"
                    type="text"
                    testId={`reason-${index}`}
                  >
                    {item?.reason}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    size="medium"
                    type="text"
                    testId={`updated-at-${index}`}
                  >
                    {dayjs(item?.updatedAt).format('MM/DD/YYYY hh:mm a')}
                  </Typography>
                </Box>
              </Box>
            )}
          </Fragment>
        ))}
      {postDetails?.reviewers &&
        getReasonArray.length === postDetails?.reviewers.length && (
          <Box direction="row" pad="small">
            <Typography
              size="small"
              color="text-weak"
              type="text"
              testId="no-comments"
            >
              {t('whats_new.approval.no_comment')}
            </Typography>
          </Box>
        )}
    </Box>
  )
}

ApprovalDetails.propTypes = {
  postDetails: PropTypes.object.isRequired
}

export default ApprovalDetails
