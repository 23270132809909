import React, { useState } from 'react'
import { Box, Grid } from 'grommet'
import PropTypes from 'prop-types'

import { PopUpImage } from './PopUpImage'

export const AppImages = ({ urls }) => {
  const [popupImgUrl, SetPopupImgUrl] = useState('')
  const handlePopup = (url) => {
    SetPopupImgUrl(url)
  }

  return (
    <Box pad={{ bottom: 'large', right: 'large' }}>
      <Grid columns={{ count: 'fill', size: '400px' }} align="start">
        {urls.map((url) => (
          <Box
            height="250px"
            width="400px"
            margin={{ top: '12px', right: '12px' }}
            key={`image_${url}`}
            style={{ cursor: 'zoom-in' }}
            background={{ size: 'cover', image: `url(${url})` }}
            onClick={() => handlePopup(url)}
            data-testid={`app-detail-image-${url}`}
          />
        ))}
      </Grid>
      {popupImgUrl.length > 0 && (
        <PopUpImage url={popupImgUrl} callBack={handlePopup} />
      )}
    </Box>
  )
}
AppImages.propTypes = {
  urls: PropTypes.array
}
AppImages.defaultProps = {
  urls: []
}
