export const getCreateBillingAccountBody = (formValues) => {
  const body = {
    name: formValues?.billingAccountName,
    description: formValues?.billingAccountDescription,
    soldTo: {
      type: 'AG',
      ...formValues?.soldToPartyDetails,
      countryCode: formValues?.soldToPartyDetails?.country?.code
    },
    billTo: {
      type: 'RE',
      ...formValues.billToPartyDetails,
      countryCode: formValues?.billToPartyDetails?.country?.code
    },
    payer: {
      type: 'RG',
      ...formValues.payerPartyDetails,
      countryCode: formValues?.payerPartyDetails?.country?.code
    },
    contacts: [
      {
        type: 'CS',
        ...formValues.soldToContactDetails,
        countryCode: formValues?.soldToContactDetails?.country?.code
      },
      {
        type: 'ZF',
        ...formValues.billToContactDetails,
        countryCode: formValues?.billToContactDetails?.country?.code
      },
      {
        type: 'IC',
        ...formValues.payerContactDetails,
        countryCode: formValues?.payerContactDetails?.country?.code
      }
    ]
  }
  return body
}
