import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'

import { Typography } from '../../../../../../../components'

const RatesReveiwContainer = ({ ...rest }) => {
  const { t } = useTranslation(['device', 'common'])

  return (
    <Box direction="column" gap="small" fill>
      <Typography
        emphasis
        type="heading"
        level={rest.level || 3}
        margin={{ top: '0px', bottom: '0px' }}
        testId="showback-rates-container-label"
      >
        {t('showback_rates.wizard_meters_showback_rates')}
      </Typography>
      <Box
        border={{ side: 'top', color: 'border-weak' }}
        pad={{ vertical: 'small' }}
        margin={{ bottom: 'small' }}
        direction="column"
        gap="small"
      >
        {rest.children}
      </Box>
    </Box>
  )
}

export { RatesReveiwContainer }
