// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  Typography,
  ModalHeader,
  ModalFooter,
  Button,
  List,
  DataTable,
  Loader
} from '../../../../../components'
import { get } from '../../../../../utils/api-utils'
import { isServiceCentric } from '../../../../../utils/account-utils'

const ViewSamlAttributes = ({ domain, onSetOpen }) => {
  const { t } = useTranslation(['authn', 'common'])
  const { oidcUser } = useReactOidc()
  const [applicationID, setApplicationID] = useState(null)
  const [entityLoginPcid, setEntityLoginPcid] = useState(null)
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    onSetOpen(false)
  }

  useEffect(() => {
    setLoading(true)
    get(
      `/ui-doorway/ui/v1/um/saml?domain=${domain}`,
      {},
      oidcUser.access_token
    ).then((response) => {
      setLoading(false)
      const samlViewAtt = response.data
      const firstThreeLabels = [
        {
          label: t('saml_sso.view_attributes.entity_id'),
          value: samlViewAtt.entity_id
        },
        {
          label: t('saml_sso.view_attributes.sign_on_url'),
          value: samlViewAtt.sign_on_url
        },
        {
          label: t('saml_sso.view_attributes.pcid'),
          value: samlViewAtt.platform_customer_id
        },
        {
          label: t('saml_sso.view_attributes.greenlake_pcid'),
          value: '00000000-0000-0000-0000-000000000000'
        },
        {
          label: newDashboardFlag
            ? t('saml_sso.view_attributes.service_manager')
            : t('saml_sso.view_attributes.applications'),
          value: ''
        }
      ]

      setEntityLoginPcid(firstThreeLabels)
      const dispObject = []
      response.data.applications.forEach((val) => {
        dispObject.push({
          appName: `${val.application_name}`,
          appID: `${val.application_id}`
        })
      })
      setApplicationID(dispObject)
    })
  }, [domain, oidcUser.access_token, t, newDashboardFlag])

  return (
    <Box>
      <ModalDialog
        testId="view-saml-attributes"
        header={
          <ModalHeader
            title={
              <Typography
                level="1"
                testId="header-title"
                type="heading"
                weight="bold"
                color="black"
              >
                {t('saml_sso.view_attributes.header')}
              </Typography>
            }
          />
        }
        content={
          <Box>
            {loading && (
              <Box align="center" margin={{ top: 'medium' }}>
                <Loader label={t('common:loading')} testId="spinner" />
              </Box>
            )}
            {entityLoginPcid && (
              <List
                customRender={(datum) => (
                  <Box gap="xxsmall" direction="column">
                    <Typography
                      size="medium"
                      testId="app-name"
                      type="text"
                      color="black"
                      weight="bold"
                    >
                      <>{datum.label}</>
                    </Typography>
                    <Typography
                      size="small"
                      testId="app-id"
                      type="text"
                      color="black"
                    >
                      <>
                        {datum.value}
                        {(datum.label ===
                          t('saml_sso.view_attributes.service_manager') ||
                          datum.label ===
                            t('saml_sso.view_attributes.applications')) && (
                          <Box>
                            {applicationID && (
                              <DataTable
                                grid={{
                                  columns: [
                                    {
                                      header: t(
                                        'customer_account.contact_name_placeholder'
                                      ),
                                      type: 'string',
                                      property: 'appName',
                                      render: (datuma) => (
                                        <Box
                                          width="xsmall"
                                          align="stretch"
                                          justify="center"
                                        >
                                          <Typography size="small" type="text">
                                            {datuma.appName}
                                          </Typography>
                                        </Box>
                                      )
                                    },
                                    {
                                      header: 'ID',
                                      type: 'string',
                                      property: 'appID',
                                      render: (datuma) => (
                                        <Box width="medium" align="stretch">
                                          <Typography size="small" type="text">
                                            {datuma.appID}
                                          </Typography>
                                        </Box>
                                      )
                                    }
                                  ],
                                  data: applicationID
                                }}
                                testId="app-name-id-table"
                              />
                            )}
                          </Box>
                        )}
                      </>
                    </Typography>
                  </Box>
                )}
                data={entityLoginPcid}
                eventType="custom-render"
                testId="key-val-list-vertical"
              />
            )}
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <Button
                label={t('customer_account.close')}
                primary
                testId="close-btn"
                onClick={handleClose}
              />
            }
          />
        }
        onClose={() => onSetOpen(false)}
      />
    </Box>
  )
}

ViewSamlAttributes.propTypes = {
  domain: PropTypes.string.isRequired,
  onSetOpen: PropTypes.func.isRequired
}

export { ViewSamlAttributes }
