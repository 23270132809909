import React, { useState } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { StatusCritical } from 'grommet-icons'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import { post, put } from '../../../utils/api-utils'
import {
  ModalDialog,
  Typography,
  Button,
  Notification
} from '../../../components'
import { useUPSContext, UPSActions } from '../../../context/ups-context'
import { getDataBasedOnSetting } from '../../../utils/ups-utils'

const DisableAnnouncement = ({
  closeAnnouncementModal,
  closeModel,
  lastVisitedBlogId
}) => {
  const { t } = useTranslation(['manage'])
  const { oidcUser } = useReactOidc()
  const [errorMessage, setErrorMessage] = useState(null)

  const { commonpreferences, dispatchUPSContext } = useUPSContext()

  const handleHideWhatsNew = () => {
    const category = 'commonpreferences'
    const showWhatsNewData = getDataBasedOnSetting(
      commonpreferences,
      'showWhatsNew'
    )
    const requestBody = {
      category,
      data: [{ ...showWhatsNewData, value: false }]
    }
    post(
      '/user-prefs/v1alpha1/save-preferences',
      requestBody,
      oidcUser.access_token
    ).then((response) => {
      if (response?.status === 200) {
        dispatchUPSContext({
          type: UPSActions.SET_CATEGORY_DATA,
          data: requestBody
        })
        sessionStorage.setItem('NewPostModel', true)
        const postRequestBody = { lastVisitedBlogId }
        put(
          '/whatsnew/v1/dismiss/dashboard/blogs',
          postRequestBody,
          oidcUser.access_token,
          {
            'x-request-id': uuidv4()
          }
        ).then((CurrentResponse) => {
          if (CurrentResponse?.status === 200) {
            closeAnnouncementModal()
            closeModel()
          }
        })
      }
    })
  }

  return (
    <Box data-testid="disable-announcement">
      <ModalDialog
        testId="disable-announcement-modal"
        onClose={() => {
          closeAnnouncementModal()
        }}
        header={
          <Box align="center">
            <Typography type="text" testId="disable-post-title" emphasis>
              {t('whats_new.disable_announcement_title')}
            </Typography>
          </Box>
        }
        content={
          <Box margin={{ vertical: 'medium' }}>
            <Typography type="text" testId="disable-post-desc">
              {t('whats_new.disable_announcement_desc')}
            </Typography>
          </Box>
        }
        footer={
          <Box align="center" gap="small" direction="row-reverse">
            <Button
              primary
              label={t('whats_new.disable_announcement_btn')}
              testId="disable-post-btn"
              onClick={handleHideWhatsNew}
            />
            <Button
              label={t('whats_new.cancel_btn')}
              testId="cancel-disable-post-btn"
              onClick={closeAnnouncementModal}
            />
          </Box>
        }
        position="center"
        width="medium"
      />

      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          icon={<StatusCritical size="medium" />}
          onClose={() => setErrorMessage(null)}
          testId="critical-notification"
          text={errorMessage}
        />
      )}
    </Box>
  )
}

DisableAnnouncement.propTypes = {
  closeAnnouncementModal: PropTypes.func.isRequired,
  closeModel: PropTypes.func.isRequired,
  lastVisitedBlogId: PropTypes.string.isRequired
}

export default DisableAnnouncement
