import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  Button,
  ModalDialog,
  ModalHeader,
  ModalFooter,
  Typography
} from '../../../../../components'

const DeleteRoleWarningModal = ({ closeWarningModal }) => {
  const { t } = useTranslation(['authz', 'common'])
  const closeModal = () => {
    closeWarningModal(false)
  }
  return (
    <ModalDialog
      onClickOutside={() => {
        closeWarningModal()
      }}
      header={
        <ModalHeader
          title={
            <Typography
              level="2"
              style={{ whiteSpace: 'nowrap' }}
              testId="header-title"
              type="heading"
              weight="bold"
            >
              {t('authz:roles.delete_role')}
            </Typography>
          }
        />
      }
      content={
        <>
          <Typography type="text" margin={{ top: 'medium' }}>
            {t('authz:roles.del_role_warning')}
          </Typography>
          <Typography type="text">
            {t('authz:roles.del_role_warning_footer')}
          </Typography>
        </>
      }
      testId="center-modal-dialog"
      footer={
        <ModalFooter
          right={
            <Button
              label={t('common:close')}
              primary
              testId="primary-btn"
              onClick={closeWarningModal}
            />
          }
        />
      }
      onClose={closeModal}
    />
  )
}

DeleteRoleWarningModal.propTypes = {
  closeWarningModal: PropTypes.func.isRequired
}

export { DeleteRoleWarningModal }
