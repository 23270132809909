import debounce from 'lodash/debounce'

const NAME_WORKSPACE_MAX_LENGTH = 100
const CITY_MAX_LENGTH = 40
const ZIPCODE_MAX_LENGTH = 10
const ADDRESS_MAX_LENGTH1 = 220
const ADDRESS_MAX_LENGTH2 = 60
const STATE_MAX_LENGTH = 220
const PHONE_MAX_LENGTH = 20

const debounceSearch = debounce((text, setSearchValue) => {
  setSearchValue(text)
}, 500)

export {
  debounceSearch,
  NAME_WORKSPACE_MAX_LENGTH,
  CITY_MAX_LENGTH,
  ZIPCODE_MAX_LENGTH,
  ADDRESS_MAX_LENGTH1,
  ADDRESS_MAX_LENGTH2,
  STATE_MAX_LENGTH,
  PHONE_MAX_LENGTH
}
