import { Box, Markdown, Button } from 'grommet'
import { ShareRounded } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { Typography } from '../shims/imports'

const FeaturedServiceMarkdown = ({ service }) => {
  const { category, contactSalesUrl, detailsPath, name, serviceSlug } = service
  const { t } = useTranslation(['dashboard', `services/${serviceSlug}`])
  const history = useHistory()

  const getServiceDetailMessage = (serviceDetailKey) =>
    t(`services:${serviceSlug}.interstitial.${serviceDetailKey}`, '')

  const ButtonGroup = () => {
    return (
      <Box
        direction="row"
        justify="start"
        gap="18px"
        pad={{ bottom: 'medium' }}
        margin={{ bottom: 'medium' }}
      >
        <Button
          as="a"
          href={`/services/service-catalog/${detailsPath}`}
          justify="center"
          align="center"
          label={t('common:get_started')}
          primary
          onClick={(e) => {
            e.preventDefault()
            history.push(`/services/service-catalog/${detailsPath}`)
          }}
        />
        <Button
          as="a"
          href={contactSalesUrl}
          justify="center"
          align="center"
          label={
            <Box direction="row" align="center" gap="xsmall">
              <Typography type="text" size="medium" emphasis>
                {t('dashboard:service_detail.contact_sales')}
              </Typography>
              <ShareRounded />
            </Box>
          }
          target="_blank"
        />
      </Box>
    )
  }

  const components = {
    h1: (props) => (
      <Typography {...props} type="heading" size="small" level="1" />
    ),
    h2: (props) => <Typography {...props} type="heading" level="2" />,
    h3: (props) => (
      <Typography
        {...props}
        type="text"
        size="large"
        emphasis
        margin={{ bottom: 'none' }}
      />
    ),
    p: {
      props: {
        fill: true,
        size: 'large',
        margin: { top: 'none', bottom: 'medium' }
      }
    },
    ul: {
      props: {
        size: 'large'
      }
    },
    ButtonGroup
  }

  const getMarkdownTemplate = (keysArray, templateStringFn = (item) => item) =>
    keysArray
      .map(getServiceDetailMessage)
      .filter(Boolean)
      .map(templateStringFn)
      .join('\n')

  const overviewTemplate = getMarkdownTemplate(['overview'])

  const topUseCaseTemplate = getMarkdownTemplate(
    [
      'top_use_cases_item1',
      'top_use_cases_item2',
      'top_use_cases_item3',
      'top_use_cases_item4',
      'top_use_cases_item5'
    ],
    (item) => `* ${item}`
  )

  const keyFeaturesTemplate = getMarkdownTemplate(
    [
      'key_features_item1',
      'key_features_item2',
      'key_features_item3',
      'key_features_item4',
      'key_features_item5'
    ],
    (item) => `* ${item}`
  )

  const mdTemplate = `
# ${name}
${t(`dashboard:common.category.${category}`)}

<ButtonGroup />

## ${t('dashboard:service_detail.overview')}
${overviewTemplate}

### ${t('dashboard:featured.top_use_cases')}
${topUseCaseTemplate}

### ${t('dashboard:featured.key_features')}
${keyFeaturesTemplate}
  `
  // eslint-disable-next-line react/prop-types
  const UnorderedList = ({ children, ...props }) => (
    <ul {...props} style={{ marginTop: 0, fontSize: 22, lineHeight: '28px' }}>
      {children}
    </ul>
  )

  return (
    <Markdown
      components={components}
      options={{
        overrides: {
          ul: {
            component: UnorderedList,
            props: {
              size: 'large'
            }
          }
        }
      }}
    >
      {mdTemplate}
    </Markdown>
  )
}

FeaturedServiceMarkdown.propTypes = {
  service: PropTypes.object.isRequired
}

export default FeaturedServiceMarkdown
