import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import isMatch from 'lodash/isMatch'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { put, getErrorMessage } from '../../../../../utils/api-utils'
import { getCustomerAccount } from '../../../../../utils/feature-flag-utils'
import {
  Typography,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ButtonGroup,
  FormInput
} from '../../../../../components'

const ApprovalModel = ({
  postData,
  closeApprovalModal,
  operation,
  setErrorMessage,
  refreshDataTable
}) => {
  const { t } = useTranslation(['manage', 'common'])
  const { oidcUser } = useReactOidc()
  const [comments, setComments] = useState('')
  const [formError, setFormError] = useState('')
  const custAccountLoaded = getCustomerAccount()
  const platformCustomerId = custAccountLoaded?.platform_customer_id || ''
  const isApproved = isMatch(operation, 'Approved')
  const commentMaxLength = 1000

  const handleCancel = () => {
    closeApprovalModal()
    setComments('')
  }

  const validateApprovalComments = () => {
    let errorLabel
    if (!isApproved) {
      if (!comments?.trim()) {
        errorLabel = t('Required')
      }
    }
    return errorLabel
  }

  const handleSubmit = () => {
    const validateRes = validateApprovalComments()
    if (validateRes) {
      setFormError(validateRes)
    } else {
      const requestBody = {
        state: operation,
        reason: comments
      }
      put(
        `/whatsnew/v1/blog/${postData.blogID}/review`,
        requestBody,
        oidcUser.access_token,
        {
          'X-Account-ID': platformCustomerId
        }
      ).then(
        (response) => {
          if (response.status === 200) {
            closeApprovalModal()
            refreshDataTable()
          }
        },
        (error) => {
          setErrorMessage(getErrorMessage(error, t))
        }
      )
    }
  }

  return (
    <ModalDialog
      position="center"
      testId="approve-dialog"
      content={
        <Box margin={{ top: 'medium' }}>
          <FormInput
            testId="whatsnew-approve-comment"
            label={
              isApproved
                ? t('whats_new.approval.add_comment')
                : t('whats_new.approval.rejection_reason')
            }
            inputType="text"
            value={comments}
            error={formError}
            maxLength={commentMaxLength}
            onChange={(event) => {
              setComments(event.target.value)
              setFormError('')
            }}
          />
          <Box alignSelf="end">
            <Typography
              type="text"
              testId="comment-count"
              size="xsmall"
              color="text-weak"
            >
              {comments.length}/{commentMaxLength}
            </Typography>
          </Box>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'approve-cancel-btn',
                  onClick: handleCancel
                },
                {
                  id: 1,
                  label: isApproved
                    ? t('whats_new.approval.approve')
                    : t('whats_new.approval.reject'),
                  primary: true,
                  testId: 'approve-submit-btn',
                  onClick: handleSubmit
                }
              ]}
              testId="approve-confirmation-buttons"
            />
          }
        />
      }
      header={
        <ModalHeader
          title={
            isApproved ? (
              <Typography
                level="2"
                type="heading"
                testId="whatsNew-approval-title"
              >
                {t('whats_new.approval.approve_post_title')}
              </Typography>
            ) : (
              <Typography
                level="2"
                type="heading"
                testId="whatsNew-reject-title"
              >
                {t('whats_new.approval.reject_post_title')}
              </Typography>
            )
          }
        />
      }
      onClose={handleCancel}
      width="large"
    />
  )
}

ApprovalModel.propTypes = {
  operation: PropTypes.string.isRequired,
  postData: PropTypes.object.isRequired,
  closeApprovalModal: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  refreshDataTable: PropTypes.func.isRequired
}

export default ApprovalModel
