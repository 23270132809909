import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useTranslation } from 'react-i18next'

import { Typography, Button, ModalDialog } from '../../../../components'
import { del, getErrorMessage } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'

const ConfirmDeleteUserModal = ({
  onSetOpen,
  onSetClose,
  emails,
  deleteUsersCb
}) => {
  const { oidcUser } = useReactOidc()
  const [notifyComponent, setNotifyComponent] = useState(null)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation(['authz', 'common'])

  const handleDeleteUser = () => {
    setLoading(true)
    del(
      `/support-assistant/v1alpha1/support-access-user`,
      { username: emails },
      oidcUser.access_token
    ).then(
      (response) => {
        setLoading(false)
        deleteUsersCb(response)
        onSetOpen(false)
        onSetClose(true)
      },
      (error) => {
        setLoading(false)
        const backendErrorMessage = getErrorMessage(error, t)
        console.error(backendErrorMessage)
        const errorComp = displayApiError(error, t, setNotifyComponent)
        setNotifyComponent(errorComp)
      }
    )
  }

  return (
    <>
      {notifyComponent}
      <ModalDialog
        width="medium"
        testId="confirm-delete-user-modal-dialog"
        content={
          <Box gap="xsmall">
            <Typography type="heading" level="2" testId="confirm-delete-title">
              {t('roles.delete_confirmation')}
            </Typography>
            <Typography type="text" testId="confirm-delete-message">
              {t('delete_confirm_modal')}
            </Typography>
          </Box>
        }
        footer={
          <Box direction="row" justify="end" gap="medium">
            <Button
              default
              label={t('roles.del_role_confirm_cancel')}
              onClick={() => {
                onSetOpen(false)
              }}
              testId="cancel-confirm-delete-btn"
            />
            <Button
              primary
              type="submit"
              label={t('roles.del_user_confirm')}
              testId="confirm-delete-user-btn"
              onClick={handleDeleteUser}
              disabled={loading}
            />
          </Box>
        }
        onClose={() => onSetOpen(false)}
      />
    </>
  )
}

ConfirmDeleteUserModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  onSetClose: PropTypes.func.isRequired,
  emails: PropTypes.array.isRequired,
  deleteUsersCb: PropTypes.func.isRequired
}

export default ConfirmDeleteUserModal
