import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import isObject from 'lodash/isObject'
import { isEmpty } from 'lodash'

import { useVisibilityContext } from '../../../context/visibility-context'

const isPermissionAllowed = (rbac, policies) =>
  policies?.effects?.[rbac.resource]?.[rbac.permission]

const checkAccountPermissions = (hideFor, hideWhen) => {
  if (!hideFor) return true
  if (hideFor && isObject(hideFor)) {
    return !(
      hideFor.account?.includes(hideWhen.account) ||
      hideWhen.feature.has(hideFor.feature) ||
      hideFor.deployment?.includes(hideWhen.deployment) ||
      hideFor?.connectivity === hideWhen?.connectivity
    )
  }
  return true
}

export const visibilityPermissions = ({
  controlled,
  hideFor,
  hideWhen,
  rbac,
  rbacPolicies,
  v2RbacPolicies
}) => {
  let result = controlled
  if (rbacPolicies || v2RbacPolicies) {
    result = checkAccountPermissions(hideFor, hideWhen)
  }
  if (result && rbac && rbac.permission) {
    return isEmpty(rbac.resource)
      ? v2RbacPolicies?.includes(rbac?.permission)
      : isPermissionAllowed(rbac, rbacPolicies)
  }
  return result
}

const VisibilityWrapper = ({
  children,
  rbac,
  hideFor,
  ifNotAllowedCallback,
  fallbackComponent
}) => {
  // -------------------------------------------------------------------
  // First check is the hideFor list is not equal to hideWhen map
  // usually hideWhen map will be from ADS/Config map
  // For example: if the logged in account is 'cop' or 'msp' or 'tenant'
  // -------------------------------------------------------------------
  const { hideWhen, rbacPolicies, v2RbacPolicies } = useVisibilityContext()
  const allowed = checkAccountPermissions(hideFor, hideWhen)
  const [controlled, setControlled] = useState(rbacPolicies ? allowed : null)

  useEffect(() => {
    const visible = visibilityPermissions({
      controlled,
      hideFor,
      hideWhen,
      rbac,
      rbacPolicies,
      v2RbacPolicies
    })
    setControlled(visible)
  }, [controlled, hideFor, hideWhen, rbac, rbacPolicies, v2RbacPolicies])

  if (!controlled && ifNotAllowedCallback) {
    ifNotAllowedCallback()
  }
  if (controlled) return <>{children}</>
  if (controlled !== null && rbacPolicies) return <>{fallbackComponent}</>
  return null
}

export default React.memo(VisibilityWrapper)

VisibilityWrapper.propTypes = {
  // The primary child component
  children: PropTypes.any.isRequired,

  // hideFor is the first check
  // Hides the child based on account type, deployment type or Launchdarkly Feature flag
  // The feature key accepts only a single string, not an array, if multiple flags need to be factored in pls use the
  // launchdarkly-utils to format the multiple flags into one flag and store in the context(hideWhen)
  // Follow the below example for using the hideFor feature
  // hideFor: { account: ['STANDALONE', 'MSP', 'TENANT'], deployment ['COP'], feature: 'glcp-home-asm-card' }
  hideFor: PropTypes.object,

  // rbac is the second check
  // resource - application resource
  // permission - the permission type for the application resource
  rbac: PropTypes.shape({
    resource: PropTypes.string,
    permission: PropTypes.string
  }),

  // This function is called if the primary component is not permitted
  ifNotAllowedCallback: PropTypes.func,

  // Component to render if the primary component is not permitted
  fallbackComponent: PropTypes.element
}

VisibilityWrapper.defaultProps = {
  rbac: null,
  hideFor: null,
  ifNotAllowedCallback: null,
  fallbackComponent: null
}
