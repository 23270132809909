// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { checkGotoChooseAccount } from '../../utils/common-utils'
import DeviceManagementRouter from '../device-management/router'
import { isInventoryOwnedWorkspace } from '../../utils/feature-flag-utils'
import { useVisibilityContext } from '../../context/visibility-context'
import { Loader } from '../../components'

import useIsBillingExportAvailable from './billing-export/useIsBillingExportAvailable'
import CopAuthenticationPage from './authentication/cop-authentication/CopAuthentication'
import ManageAccountPage from './manage-account'
import AccountDetailsRouter from './account-details/router'
import AccountTypesRouter from './account-types/router'
import ConfigureAuthenticationRouter from './authentication/configure-auth/router'
import LocalGatewayRouter from './local-gateway/router'
import AuditLogsRouter from './audit-logs/router'
import SsoRouter from './authentication/saml-sso/router'
import ArubaActivateRouter from './aruba-activate/router'
import APIRouter from './api/router'
import PortalCustomizationRouter from './portal-customization/router'
import IdentityRouter from './identity/router'
import AutomationsRouter from './automations/router'
import SubscriptionsRouter from './subscriptions/router'
import { IPAccessRulesRouter } from './ip-access-rules'
import LocationManagementRouter from './location-management/router'
import { ReportingRouter } from './reporting'
import SupportAssistantRouter from './support-access/router'
import { UsageMonitoringRouter } from './usage-monitoring'
import ZeroTouchOnboarding from './zero-touch-onboarding/ZeroTouchOnboarding'
import TagsRouter from './tags/router'
import { OrganizationGovernanceRouter } from './organization-governance/router'
import OnPremWorkspaceRouter from './onprem-workspace/router'
import BillingExportRouter from './billing-export/router'

const ManageAccountRouter = () => {
  const { path } = useRouteMatch()
  const { t } = useTranslation(['common'])
  const history = useHistory()
  const { rbacPolicies } = useVisibilityContext()
  checkGotoChooseAccount(history)
  const isBillingExportAvailable = useIsBillingExportAvailable()
  return (
    <>
      {rbacPolicies ? (
        <Switch>
          <Route exact path={path}>
            <ManageAccountPage />
          </Route>
          <Route path="/manage-account/account-details">
            <AccountDetailsRouter />
          </Route>
          <Route path="/manage-account/account-type-overview">
            <AccountTypesRouter />
          </Route>
          {/* <Route path="/manage-account/authentication">
        <AuthenticationRouter />
      </Route> */}
          <Route path="/manage-account/configure-auth">
            <ConfigureAuthenticationRouter />
          </Route>

          <Route path="/manage-account/identity">
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/authorization',
                permission: 'ccs.authorization.view'
              }}
              fallbackComponent={<Redirect to="/home" />}
            >
              <IdentityRouter />
            </VisibilityWrapper>
          </Route>
          <Route path="/manage-account/organization">
            <OrganizationGovernanceRouter />
          </Route>
          <Route path="/manage-account/local-gateway">
            <VisibilityWrapper
              hideFor={{
                feature: 'glcp-local-gateway-feature',
                deployment: ['COP']
              }}
              fallbackComponent={<Redirect to="/home" />}
            >
              <LocalGatewayRouter />
            </VisibilityWrapper>
          </Route>
          <Route path="/manage-account/sso">
            <SsoRouter />
          </Route>
          <Route path="/manage-account/activate">
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/activate/customer',
                permission: 'ccs.activate.view'
              }}
            >
              <ArubaActivateRouter />
            </VisibilityWrapper>
          </Route>
          <Route path="/manage-account/tags">
            <TagsRouter />
          </Route>
          <Route path="/manage-account/api">
            <APIRouter />
          </Route>
          <Route path="/manage-account/auditlogs">
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/audit-trail',
                permission: 'ccs.audit-trail.view'
              }}
              fallbackComponent={<Redirect to="/home" />}
            >
              <AuditLogsRouter />
            </VisibilityWrapper>
          </Route>
          {isInventoryOwnedWorkspace() ? (
            <Route path="/manage-account/locations">
              <VisibilityWrapper
                fallbackComponent={<Redirect to="/home" />}
                hideFor={{
                  deployment: ['COP', 'GLOP']
                }}
                rbac={{
                  resource: '/ccs/location-management',
                  permission: 'ccs.location-management.view'
                }}
              >
                <LocationManagementRouter />
              </VisibilityWrapper>
            </Route>
          ) : null}
          <Route path="/manage-account/portal-customization">
            <VisibilityWrapper
              hideFor={{ account: ['TENANT', 'STANDALONE'] }}
              rbac={{
                resource: '/ccs/accounts/platform/customer',
                permission: 'ccs.accounts.platform.customer.view'
              }}
              fallbackComponent={<Redirect to="/home" />}
            >
              <PortalCustomizationRouter />
            </VisibilityWrapper>
          </Route>
          <Route path="/manage-account/subscriptions">
            <SubscriptionsRouter />
          </Route>
          <Route path="/manage-account/ip-access-rules">
            <IPAccessRulesRouter />
          </Route>
          <Route path="/manage-account/authentication">
            <CopAuthenticationPage />
          </Route>
          <Route path="/manage-account/support-access">
            <VisibilityWrapper
              hideFor={{
                deployment: ['GLOP'],
                feature: 'glcp-support-access-manager'
              }}
              fallbackComponent={<Redirect to="/home" />}
            >
              <SupportAssistantRouter />
            </VisibilityWrapper>
          </Route>

          <Route path="/manage-account/device-management">
            {/* Reroute to devices page when old device-management route is hit  */}
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/device-management',
                permission: 'ccs.device-management.view'
              }}
              fallbackComponent={
                <Redirect
                  to={{
                    pathname: '/home',
                    errorMessage: t('permission_denied')
                  }}
                />
              }
            >
              <DeviceManagementRouter />
            </VisibilityWrapper>
          </Route>
          <Route path="/manage-account/automations">
            <AutomationsRouter />
          </Route>
          <Route path="/manage-account/usage-monitoring">
            <VisibilityWrapper
              fallbackComponent={<Redirect to="/home" />}
              hideFor={{
                deployment: ['COP', 'GLOP']
              }}
              rbac={{
                resource: '/ccs/consumption-data',
                permission: 'ccs.consumption-data.view'
              }}
            >
              <UsageMonitoringRouter />
            </VisibilityWrapper>
          </Route>
          {isInventoryOwnedWorkspace() ? (
            <Route path="/manage-account/reporting">
              <VisibilityWrapper
                hideFor={{
                  feature: 'glcp-reportfw-dashboard'
                }}
                fallbackComponent={<Redirect to="/home" />}
              >
                <ReportingRouter />
              </VisibilityWrapper>
            </Route>
          ) : null}
          <Route path="/manage-account/zero-touch-onboarding">
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/device-management/zto',
                permission: 'ccs.device-subscription-zto.edit'
              }}
              fallbackComponent={<Redirect to="/home" />}
            >
              <ZeroTouchOnboarding />
            </VisibilityWrapper>
          </Route>
          <Route path="/manage-account/on-prem-workspaces">
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/device-management',
                permission: 'ccs.device-management.view'
              }}
              fallbackComponent={<Redirect to="/home" />}
            >
              <OnPremWorkspaceRouter />
            </VisibilityWrapper>
          </Route>

          {isBillingExportAvailable && (
            <Route path="/manage-account/billing-export">
              <VisibilityWrapper
                fallbackComponent={<Redirect to="/home" />}
                rbac={{
                  resource: '/ccs/billing-export',
                  permission: 'ccs.billing-export.manage'
                }}
              >
                <BillingExportRouter />
              </VisibilityWrapper>
            </Route>
          )}

          <Redirect to="/not-found" />
        </Switch>
      ) : (
        <Box
          direction="row"
          align="center"
          justify="center"
          margin={{ top: 'large' }}
        >
          <Loader
            orientation="horizontal"
            label={t('common:loading')}
            testId="rbac-loader"
          />
        </Box>
      )}
    </>
  )
}

export default ManageAccountRouter
