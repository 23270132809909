import React from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Tabs, Typography } from '../../../../../components'
import { OnPremResourceDatatable } from '../../components/OnPremResourceDatatable'

const OnPremResourceTabs = () => {
  const { t } = useTranslation(['manage'])
  return (
    <Box margin={{ top: 'top' }}>
      <Box
        direction="row"
        justify="between"
        border={{ color: 'border-weak', size: 'xsmall', side: 'bottom' }}
        margin={{ bottom: 'medium' }}
        pad={{ bottom: 'small' }}
      >
        <Typography
          level="2"
          testId="workspace-assigned-resource-section-header"
          type="heading"
        >
          {t('onprem_workspace.assigned_resources')}
        </Typography>
      </Box>
      <Box>
        <Tabs
          alignControls="start"
          justify="start"
          tabsList={[
            {
              content: (
                <OnPremResourceDatatable resourceType="DEVICE" key="DEVICE" />
              ),
              id: 1,
              label: t('onprem_workspace.devices'),
              testId: 'devices-tab'
            },
            {
              content: (
                <OnPremResourceDatatable
                  resourceType="DEVICE_SUBSCRIPTION"
                  key="DEVICE_SUBSCRIPTION"
                />
              ),
              id: 2,
              label: t('onprem_workspace.device_subscriptions'),
              testId: 'device-subscriptions-tab'
            },
            {
              content: (
                <OnPremResourceDatatable
                  resourceType="SERVICE_SUBSCRIPTION"
                  key="SERVICE_SUBSCRIPTION"
                />
              ),
              id: 3,
              label: t('onprem_workspace.service_subscriptions'),
              testId: 'service-subscriptions-tab'
            }
          ]}
          testId="onprem-workspace-resource-tabs"
        />
      </Box>
    </Box>
  )
}

export { OnPremResourceTabs }
