// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useRef, useState } from 'react'
import { render } from 'react-dom'
import { PropTypes } from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import isNull from 'lodash/isNull'

import { Notification, PageLayout } from '../../../components'
import { BannerNotification } from '../banner-notification/BannerNotification'
import { COPBannerNotification } from '../banner-notification/COPBannerNotification'
import { useCCSContext } from '../../../context/ccs-context'
import { useVisibilityContext } from '../../../context/visibility-context'
import {
  getNumCustomerAccounts,
  isCoP,
  getCustomerAccount,
  isGLOP
} from '../../../utils/feature-flag-utils'
import { ResponsiveContainer } from '../contextual-help-layout/ResponsiveContainer'
import { get } from '../../../utils/api-utils'
import { isServiceCentric } from '../../../utils/account-utils'

import { updateHeader } from './header-cases/updateHeader'
import {
  HeaderWithBrandOnly,
  HeaderWithBrandAndHelp,
  HeaderWithDropMenus,
  HeaderWithAllOptions,
  GLCPManagerHeader
} from './header-cases/HeaderCases'
import { AccessRestrictedModal } from './commoncomponents/AccessRestrictedModal'
import { GlobalSearch } from './global-search/GlobalSearch'
import { AccessDeniedModal } from './commoncomponents/AccessDeniedModal'

const Layout = ({ hideIpModal, hideHeaderOptions, bannerOptions, ...rest }) => {
  const { t } = useTranslation(['header'])
  const { rbacPolicies, isSupportEngineer } = useVisibilityContext()
  /**
   * HeaderOptions can be:
   * "brand","nav","bell","help","apps","user"
   * NOTE: PLEASE COPY THE EXACT KEY FROM 'HeaderOptions'
   */
  const HeaderOptions = {
    '["no-header"]': undefined,
    // Header with "brand" only
    '["nav","bell","help","apps","user"]': <HeaderWithBrandOnly />,
    // Header with "brand" and "help" only
    '["nav","bell","apps","user"]': (
      <HeaderWithBrandAndHelp options={hideHeaderOptions} />
    ),
    // Header with "brand", "help" and "user"
    // After successful authentication but before choose the account
    '["nav","bell","apps"]': (
      <HeaderWithDropMenus options={hideHeaderOptions} />
    ),
    // Header with "brand","nav","bell","help","apps" and "user"
    // This is for all the header options
    // For all the pages after the choose the account
    '[]': <HeaderWithAllOptions options={hideHeaderOptions} />,
    // Header with "brand","glcp-manager-nav","bell","help", and "user"
    // For all the pages for glcp-manager account
    '["apps"]': <GLCPManagerHeader options={hideHeaderOptions} />
  }
  const { isIpRestricted, dispatchCCSContext } = useCCSContext()
  const numOfCustomerAccounts = getNumCustomerAccounts()
  const history = useHistory()
  const LDFlags = useFlags()
  const { oidcUser } = useReactOidc()
  const custAccountLoaded = getCustomerAccount()
  const showWCHeader = LDFlags['glcp-wc-header'] || isGLOP()
  const [errorBanner, setErrorBanner] = useState(
    useLocation()?.bannerErrorMessage
  )
  // When user navigates to Dashboard/Home/any page with successMessage state set, the success toast will be displayed
  const [successMessage, setSuccessMessage] = useState(
    useLocation().successMessage
  )
  // When user doesn't have access to Magic Link, display Access Denied Modal
  const [showMagicAccessDeniedModal, setShowMagicAccessDeniedModal] = useState(
    useLocation().isMagicAccessDenied
  )

  const bannerNotification = () => {
    const banner =
      bannerOptions !== 'no-banner' ? <BannerNotification /> : undefined

    return banner
  }
  // const [useWCHeader, setUseWCHeader] = useState(window.showWCHeader)
  // // This is used in the edge case when the window.showWCHeader is updated
  // // after the React header is already shown.
  // window.setUseWCHeader = useCallback(
  //   (newValue) => setUseWCHeader(newValue),
  //   []
  // )

  const getErrorBanner = () => {
    return (
      <Notification
        global
        background="status-critical"
        onClose={() => setErrorBanner(null)}
        message={errorBanner}
        data-testid="error-banner-notification"
      />
    )
  }

  useEffect(() => {
    const refreshTimer = null
    let refreshPollTimer = null
    const headerRootNode = document.querySelector('greenlake-header')
    async function polling() {
      const lastInteractionTime = sessionStorage.getItem(
        'lastInteractionTimeBellNotification'
      )
      const diff = dayjs().diff(lastInteractionTime)

      if (diff < 60000 * 3 && !isNull(lastInteractionTime)) {
        refreshPollTimer = setTimeout(() => {
          polling()
        }, 60000 * 3 - diff)
      } else if (isNull(lastInteractionTime) || diff >= 60000 * 3) {
        if (
          oidcUser?.access_token &&
          oidcUser?.access_token !== null &&
          custAccountLoaded !== null &&
          headerRootNode
        ) {
          let url = ''
          if (LDFlags['glcp-notifications-feature-alerts-tasks-kobe']) {
            url =
              '/notifications-svc/ui/v1alpha1/has-new-events?has_new_type=periodic'
          } else if (LDFlags['glcp-notifications-feature-edit-istanbul']) {
            url = '/notifications-svc/ui/v2/has-new?has_new_type=periodic'
          } else {
            url = '/notifications-svc/ui/v1alpha1/has-new?has_new_type=periodic'
          }
          get(url, {}, oidcUser?.access_token).then(
            (response) => {
              polling()
              sessionStorage.setItem(
                'lastInteractionTimeBellNotification',
                dayjs()
              )
              if (response?.data) {
                headerRootNode.hasNotifications = true
              } else {
                headerRootNode.hasNotifications = false
              }
            },
            (error) => {
              polling()
              sessionStorage.setItem(
                'lastInteractionTimeBellNotification',
                dayjs()
              )
              console.log(error)
            }
          )
        }
      }
    }
    if (!isGLOP()) {
      polling()
    }
    return () => {
      clearTimeout(refreshTimer)
      clearTimeout(refreshPollTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const headerRootNode = document.querySelector('greenlake-header')
    if (headerRootNode && history) {
      // Temporary accommodation for monolith app and singular MFE app
      // to prevent page reload for every route change.
      // Will not work with multiple MFE apps.
      headerRootNode.historyObject = history
    }
  }, [history])

  const prevWorkspaceNameRef = useRef(custAccountLoaded?.company_name)

  const getGlobalHeaderLeftChildElement = () => {
    const headerRootNode = document?.querySelector('greenlake-header')
    const innerChildElement = headerRootNode.shadowRoot?.querySelector('.inner')
    const leftChildElement = innerChildElement?.querySelector('.left')
    return leftChildElement
  }

  const removeGlobalSearchBar = () => {
    const leftChildElement = getGlobalHeaderLeftChildElement()
    if (leftChildElement) {
      const searchBar = leftChildElement?.querySelector('.searchbar-container')
      if (searchBar) {
        searchBar.remove()
      }
    }
  }

  const addGlobalSearchBar = () => {
    const leftChildElement = getGlobalHeaderLeftChildElement()
    if (leftChildElement) {
      if (
        (LDFlags['glcp-global-search-discover'] ||
          LDFlags['glcp-global-search-jhansi']) &&
        !leftChildElement?.querySelector('.searchbar-container')
      ) {
        const div = document.createElement('div')
        div.className = 'searchbar-container'
        leftChildElement.appendChild(div)
        render(
          <GlobalSearch
            oidcUser={oidcUser}
            i18nTranslation={t}
            LDFlags={LDFlags}
          />,
          leftChildElement?.querySelector('.searchbar-container')
        )
      } else if (
        !LDFlags['glcp-global-search-discover'] &&
        !LDFlags['glcp-global-search-jhansi'] &&
        leftChildElement?.querySelector('.searchbar-container')
      ) {
        leftChildElement?.querySelector('.searchbar-container').remove()
      }
    }
  }

  useEffect(() => {
    if (prevWorkspaceNameRef.current !== custAccountLoaded?.company_name) {
      removeGlobalSearchBar()
      prevWorkspaceNameRef.current = custAccountLoaded?.company_name
    }
    addGlobalSearchBar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser, LDFlags, t])

  useEffect(() => {
    const headerRootNode = document.querySelector('greenlake-header')
    if (headerRootNode) {
      headerRootNode.ldFlags = {
        ...LDFlags,
        'glcp-service-centric-experience-phase-1':
          isServiceCentric(LDFlags) || isGLOP(),
        'glcp-switch-to-workspace':
          LDFlags['glcp-switch-to-workspace'] || isGLOP(),
        'glcp-wc-header': LDFlags['glcp-wc-header'] || isGLOP(),
        'glcp-support-access-manager':
          custAccountLoaded &&
          !isSupportEngineer &&
          LDFlags['glcp-support-access-manager']
      }
      // Update header with new platform based on hideHeaderOptions
      updateHeader(hideHeaderOptions, oidcUser, rbacPolicies)
    }
    document.documentElement.style.setProperty(
      '--header-display',
      showWCHeader ? 'block' : 'none'
    )
  }, [
    hideHeaderOptions,
    oidcUser,
    showWCHeader,
    LDFlags,
    rbacPolicies,
    isSupportEngineer,
    custAccountLoaded
  ])

  const content = (
    <>
      <PageLayout
        header={
          showWCHeader ? null : HeaderOptions[JSON.stringify(hideHeaderOptions)]
        }
        banner={!isCoP() ? bannerNotification() : <COPBannerNotification />}
        errorBanner={errorBanner ? getErrorBanner() : undefined}
        {...rest}
      />
      {!hideIpModal && isIpRestricted && (
        <AccessRestrictedModal
          logoutOnIpRestriction={
            numOfCustomerAccounts !== 1 &&
            !window.location.pathname.includes('/choose-account')
          }
          dispatchCCSContext={dispatchCCSContext}
        />
      )}
      {showMagicAccessDeniedModal && (
        <AccessDeniedModal onSetOpen={setShowMagicAccessDeniedModal} />
      )}

      {successMessage ? (
        <Notification
          onClose={() => setSuccessMessage('')}
          position="top"
          testId="success-notification"
          text={successMessage}
        />
      ) : null}
    </>
  )

  return LDFlags['glcp-contextual-help'] ? (
    <ResponsiveContainer>{content}</ResponsiveContainer>
  ) : (
    content
  )
}

Layout.propTypes = {
  hideHeaderOptions: PropTypes.arrayOf(PropTypes.string),
  hideIpModal: PropTypes.bool,
  bannerOptions: PropTypes.string
}

Layout.defaultProps = {
  hideHeaderOptions: [],
  hideIpModal: false,
  bannerOptions: ''
}

export { Layout }
