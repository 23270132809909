import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import isEmpty from 'lodash/isEmpty'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ButtonGroup,
  FormInput
} from '../../../../../../../components'

const QuickReject = ({
  onSetOpen,
  handleReject,
  setRejectComment,
  rejectComment
}) => {
  const { t } = useTranslation(['manage', 'common'])
  const [errorMessage, setErrorMessage] = useState('')

  const handleCancel = () => {
    onSetOpen(false)
    setRejectComment('')
  }

  const handleSave = () => {
    if (isEmpty(rejectComment)) {
      setErrorMessage(
        t('notification.approval.confirm_reject_comment_validation')
      )
    } else if (rejectComment.length > 1024) {
      setErrorMessage(
        t('notification.approval.confirm_approve_length_validation')
      )
    } else {
      setErrorMessage('')
      onSetOpen(false)
      handleReject()
    }
  }

  return (
    <ModalDialog
      position="center"
      onClickOutside={() => {
        onSetOpen(false)
      }}
      testId="confirmation-dialog"
      onEsc={() => {
        onSetOpen(false)
      }}
      content={
        <Box margin={{ top: 'medium' }}>
          <Typography type="text" size="medium">
            {t('notification.approval.confirm_reject_content')}
          </Typography>
          <FormInput
            name="reject-comment-text-input"
            testId="reject-comment-text"
            inputType="text"
            value={rejectComment}
            error={errorMessage}
            onChange={(event) => {
              setRejectComment(event.target.value)
              if (!isEmpty(event.target.value)) {
                if (event.target.value?.length > 1024) {
                  setErrorMessage(
                    t('notification.approval.confirm_approve_length_validation')
                  )
                } else {
                  setErrorMessage('')
                }
              }
            }}
            required
          />
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-reject-confirm-btn',
                  onClick: handleCancel
                },
                {
                  id: 1,
                  label: t('notification.approval.reject'),
                  primary: true,
                  testId: 'confirm-reject-button',
                  onClick: handleSave
                }
              ]}
              testId="approve-confirmation-buttons"
            />
          }
        />
      }
      header={
        <ModalHeader
          title={
            <Typography level="1" type="heading" weight="bold">
              {t('notification.approval.confirm_reject_header')}
            </Typography>
          }
        />
      }
      onClose={handleCancel}
      width="large"
    />
  )
}
export { QuickReject }

QuickReject.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  setRejectComment: PropTypes.func.isRequired,
  rejectComment: PropTypes.string.isRequired
}
