import { Box } from 'grommet'
import { useHistory } from 'react-router-dom'
import { LinkNext } from 'grommet-icons'
import PropTypes from 'prop-types'

import { Typography, Button } from '../../../components'

const NewServiceCatalogCard = ({
  serviceOffer: { name, id, short_description, slug },
  t
}) => {
  const history = useHistory()

  return (
    <Button
      testId="service-catalog-card"
      key={name}
      a11yTitle={`${t(`dashboard:common.view_details`)} ${name}`}
      onClick={(e) => {
        e.preventDefault()
        history.push(`/services/service-catalog/${id}`)
      }}
      hoverIndicator
    >
      <Box fill gap="small">
        <Typography type="heading" level={4}>
          {name}
        </Typography>
        <Typography type="text">
          {t(`services:${slug}.short_description`, short_description)}
        </Typography>
        <LinkNext color="brand" />
      </Box>
    </Button>
  )
}

NewServiceCatalogCard.propTypes = {
  serviceOffer: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export default NewServiceCatalogCard
