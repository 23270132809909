import { Box } from 'grommet'
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { renameKeys } from '../../../../../../../utils/common-utils'
import { ObjectList, Typography } from '../../../../../../../components'

const UserDetails = ({ userData }) => {
  const { t } = useTranslation(['manage'])
  const getUserDetails = () => {
    const data = {
      email: userData?.email,
      joined_date: userData?.created_at
        ? dayjs(userData?.created_at).format('MMM, D, YYYY')
        : '',
      last_session: userData?.last_session
        ? dayjs(userData?.last_session).format('MMM, D, YYYY')
        : ''
    }
    const keysMap = {}
    Object.keys(data).forEach((key) => {
      keysMap[key] = t(`user_role_assignments.${key}`)
    })
    return renameKeys(keysMap, data)
  }

  return (
    <Box width="large" align="start" fill gap="medium">
      <Box
        pad={{ bottom: 'small' }}
        width="large"
        border={{ side: 'bottom', color: 'border-weak' }}
      >
        <Typography level="2" type="heading" testId="details-title">
          {t('details')}
        </Typography>
        <Typography
          size="large"
          type="text"
          weight="normal"
          testId="details-subtitle"
        >
          {t('user_role_assignments.user_detail_information')}
        </Typography>
      </Box>
      <Box pad={{ vertical: 'small' }}>
        <ObjectList data={getUserDetails()} testId="customer-user-details" />
      </Box>
    </Box>
  )
}

UserDetails.propTypes = {
  userData: PropTypes.object.isRequired
}

export { UserDetails }
