import { Route, Switch, Redirect } from 'react-router-dom'
import React from 'react'

import WhatsNewCustomerPage from './WhatsNew'

const WhatsNewRouter = () => {
  return (
    <Switch>
      <Route path="/whats-new">
        <WhatsNewCustomerPage />
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default WhatsNewRouter
