import { validateForm } from '../../utils/validation-utils'

import { IAAS_KEY_TYPE } from './utils'

const validateAddSubscriptionsFields = (formValues, t) => {
  const { selectedProductList } = formValues
  const valid = selectedProductList?.length
  return {
    isValid: valid,
    message: t('add_products.add_subscriptions_required_validation')
  }
}

export const validateAddSubscriptions = (formValues, t) => {
  return validateForm(formValues, t, validateAddSubscriptionsFields)
}

const validateAssignInstanceFields = (formValues, t) => {
  const { selectedProductList } = formValues
  const selectedIaasProductList = selectedProductList?.filter(
    (value) =>
      value?.key_type === IAAS_KEY_TYPE && value?.available_applications
  )
  const valid =
    selectedIaasProductList?.length ===
    Object.values(formValues?.selectedApplication)?.length
  return {
    isValid: valid,
    message: t('add_products.assign_instance_required_validation')
  }
}

export const validateAssignInstance = (formValues, t) => {
  return validateForm(formValues, t, validateAssignInstanceFields)
}
