import React from 'react'
import { Anchor, Box, NameValueList, NameValuePair } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Typography } from '../../../../../components'

const ViewDetails = ({ data, inCCSManager }) => {
  const { t } = useTranslation(['device'])
  const history = useHistory()
  return (
    <Box>
      <NameValueList
        valueProps={{ width: ['auto', 'medium'] }}
        data-testid="folder-details"
      >
        {Object.entries(data).map(([name, value]) => {
          return name === t('devices_tab') ? (
            <NameValuePair
              key={name}
              name={
                <Typography
                  size="medium"
                  type="text"
                  weight={500}
                  testId={`${name}-field`}
                >
                  {name}
                </Typography>
              }
            >
              {value ? (
                <Box direction="row" gap="xxsmall">
                  <Typography
                    size="medium"
                    color="text-strong"
                    testId="details-value"
                    type="text"
                  >
                    {value}
                  </Typography>
                  <Anchor
                    data-testid="devices-anchor-btn"
                    label={<strong>{t('view_devices')}</strong>}
                    onClick={() => {
                      if (inCCSManager) {
                        history.push({
                          pathname: '/manage-ccs/customers/customer-details',
                          tabName: 'devices',
                          folderDetail: {
                            folder_id: data?.[t('id')],
                            folder_name: data?.[t('name')]
                          }
                        })
                      } else {
                        history.push({
                          pathname: '/manage-account/activate/devices',
                          folderDetail: {
                            folder_id: data?.[t('id')],
                            folder_name: data?.[t('name')]
                          }
                        })
                      }
                    }}
                  />
                </Box>
              ) : (
                '--'
              )}
            </NameValuePair>
          ) : (
            <NameValuePair
              key={name}
              name={
                <Typography
                  size="medium"
                  type="text"
                  weight={500}
                  testId={`${name}-field`}
                >
                  {name}
                </Typography>
              }
              data-testid={`${name}`}
            >
              {value || '--'}
            </NameValuePair>
          )
        })}
      </NameValueList>
    </Box>
  )
}

ViewDetails.propTypes = {
  data: PropTypes.object,
  inCCSManager: PropTypes.bool
}

ViewDetails.defaultProps = {
  data: {},
  inCCSManager: false
}

export { ViewDetails }
