// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

export const getServiceDeliveryContacts = (data, t) => {
  const NO_VALUE = '--'
  let sdcName = NO_VALUE
  let sdcId = NO_VALUE
  const contactType = data?.contact_type
  if (contactType === 'NONGLP') {
    sdcName = data?.contact_id || NO_VALUE
    sdcId = t('common:email_outside_platform')
  } else {
    sdcName = data?.contact_name || NO_VALUE
    sdcId = data?.contact_id || NO_VALUE
  }
  return [sdcName, sdcId]
}
