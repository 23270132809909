// Copyright 2024 Hewlett Packard Enterprise Development LP
import { useState, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, FormField } from 'grommet'
import { Trash } from 'grommet-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  ModalHeader,
  Typography,
  CCSForm,
  FormInput,
  FormTextArea,
  Dropdown,
  ButtonGroup,
  FileInput,
  Button
} from '../../../../components'
import {
  getCustomerAccount,
  setCustomerAccount
} from '../../../../utils/feature-flag-utils'
import {
  validateEmail,
  validateLength,
  validateFileInput,
  IMAGE_FILE_SIZE,
  ALLOWED_IMAGE_TYPES
} from '../../../../utils/validation-utils'
import { get, post, put, getErrorMessage } from '../../../../utils/api-utils'
import {
  joinCityState,
  getCountryName,
  getCountryCode,
  updateWorkspaceNameInHeader,
  areGuidsEqual,
  fileToBase64
} from '../../../../utils/common-utils'
import {
  ADDRESS_MAX_LENGTH1,
  ADDRESS_MAX_LENGTH2,
  CITY_MAX_LENGTH,
  NAME_WORKSPACE_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  STATE_MAX_LENGTH,
  ZIPCODE_MAX_LENGTH
} from '../../commoncomponents/utils'

export const EditWorkspaceSideDrawer = ({
  workspaceId,
  workspaceDetails,
  setWorkspaceDetails,
  setStatus,
  onClose
}) => {
  const LDFlags = useFlags()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authz', 'manage', 'iam', 'location', 'authn'])
  const [allCountries, setAllCountries] = useState([])
  const [filteredCountries, setFilteredCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [originalState, setOriginalState] = useState({})
  const streetAddressField = LDFlags['glcp-street-address-field']
  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value, edited: true }
        case 'FORM_EDITED':
          return { ...currentState, edited: action.value }
        case 'SET_STATE':
          return { ...action.value, edited: false }
        case 'CHANGE_LOGO': {
          return {
            ...currentState,
            logo: action.value?.[0] || ''
          }
        }
        default:
          return currentState
      }
    },
    {
      company_name: '',
      description: '',
      logo: '',
      country_code: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state_or_region: '',
      zip: '',
      phone_number: '',
      email: '',
      edited: false
    }
  )

  const getStringValue = (str) => {
    return str || ''
  }

  useEffect(() => {
    const getAccountDetails = () => {
      const wsAddress = workspaceDetails?.address
      const currentCustomerAccount = {
        company_name: getStringValue(workspaceDetails?.workspaceName),
        description: getStringValue(workspaceDetails?.description),
        logo: workspaceDetails?.logo || '',
        country_code: getStringValue(wsAddress?.countryCode),
        street_address: getStringValue(wsAddress?.streetAddress),
        street_address_2: getStringValue(wsAddress?.streetAddressComplement),
        city: getStringValue(wsAddress?.city),
        state_or_region: getStringValue(wsAddress?.stateOrRegion),
        zip: getStringValue(wsAddress?.zip),
        phone_number: getStringValue(workspaceDetails?.phoneNumber),
        email: getStringValue(workspaceDetails?.email),
        edited: false
      }
      setOriginalState(JSON.parse(JSON.stringify(currentCustomerAccount)))
      currentCustomerAccount.logo = null
      dispatch({
        value: currentCustomerAccount,
        type: 'SET_STATE'
      })
    }

    const getCountries = async () => {
      let params = { status: 'AVAILABLE' }
      try {
        const doorwayStatus = await get(
          '/ui-doorway/ui/v1/status',
          {},
          oidcUser?.access_token
        )
        if (doorwayStatus?.data?.flags?.gts_compliance_flag) {
          params = {}
        }
      } catch (error) {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }

      try {
        const response = await get('/geo/ui/v1/countries', params)
        setAllCountries(response?.data?.countries)
        setFilteredCountries(response?.data?.countries)
        getAccountDetails()
      } catch (error) {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    }
    getCountries()
  }, [
    oidcUser?.access_token,
    workspaceDetails?.workspaceName,
    workspaceDetails?.description,
    workspaceDetails?.logo,
    workspaceDetails?.address,
    workspaceDetails?.address?.countryCode,
    workspaceDetails?.address?.streetAddress,
    workspaceDetails?.address?.streetAddressComplement,
    workspaceDetails?.address?.city,
    workspaceDetails?.address?.stateOrRegion,
    workspaceDetails?.address?.zip,
    workspaceDetails?.email,
    workspaceDetails?.phoneNumber,
    t
  ])

  const hasExistingLogo = () => {
    return (
      !state.logo &&
      originalState?.logo &&
      originalState?.logo?.logoFileName !== '' &&
      originalState?.logo?.logoFileName !== null &&
      Object.keys(originalState?.logo).length !== 0
    )
  }

  const setLogo = async () => {
    if (state.logo && state.logo instanceof File) {
      return {
        logoImage: await fileToBase64(state.logo),
        logoFileName: state.logo.name
      }
    }
    return {}
  }

  const handleSubmit = async () => {
    setLoadingEdit(true)

    const reqBody = {
      workspaceName: state?.company_name?.trim(),
      description: state?.description?.trim(),
      address: {
        streetAddress: state?.street_address?.trim(),
        streetAddressComplement: state?.street_address_2?.trim(),
        city: state?.city?.trim(),
        stateOrRegion: state?.state_or_region?.trim(),
        zip: state?.zip?.trim(),
        countryCode: state?.country_code
      },
      email: state?.email?.trim(),
      phoneNumber: state?.phone_number?.trim()
    }
    try {
      await put(`/organizations/v2alpha1/workspaces/${workspaceId}`, reqBody)
      dispatch({
        value: false,
        type: 'FORM_EDITED'
      })
      let updatedWorkspaceDetails = {}
      updatedWorkspaceDetails = {
        id: workspaceDetails.id,
        workspaceName: state?.company_name?.trim(),
        description: state?.description?.trim(),
        address: {
          countryCode: state?.country_code,
          streetAddress: state?.street_address?.trim(),
          streetAddressComplement: state?.street_address_2?.trim(),
          city: state?.city?.trim(),
          stateOrRegion: state?.state_or_region?.trim(),
          zip: state?.zip?.trim()
        },
        phoneNumber: state?.phone_number?.trim(),
        email: state?.email?.trim()
      }
      setWorkspaceDetails(updatedWorkspaceDetails)
      setStatus({
        message: t('iam:igc_workspaces.message_success_edit', {
          workspace: reqBody.workspaceName
        }),
        severity: 'info',
        testId: 'edit-workspace-status',
        title: t('iam:igc_workspaces.title_success_edit')
      })
      let currentAccount = getCustomerAccount()
      if (
        areGuidsEqual(
          currentAccount?.platform_customer_id,
          updatedWorkspaceDetails.id
        )
      ) {
        currentAccount = {
          ...currentAccount,
          company_name: updatedWorkspaceDetails.workspaceName,
          address: {
            address_line_1: updatedWorkspaceDetails.address.streetAddress,
            address_line_2:
              updatedWorkspaceDetails.address.streetAddressComplement,
            state_or_region: joinCityState(
              updatedWorkspaceDetails.address.city,
              updatedWorkspaceDetails.address.stateOrRegion
            ),
            country_code: updatedWorkspaceDetails.address.countryCode,
            zip: updatedWorkspaceDetails.address.zip
          },
          email: updatedWorkspaceDetails.email,
          phone_number: updatedWorkspaceDetails.phoneNumber
        }
        setCustomerAccount(currentAccount)
        updateWorkspaceNameInHeader(currentAccount.company_name)
      }
      try {
        if (!hasExistingLogo()) {
          const logoReqBody = await setLogo()
          await post(
            `/organizations/v2alpha1/workspaces/${workspaceId}/update-logo`,
            logoReqBody
          )
        }
      } catch (error) {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)

        setStatus({
          message: t('iam:igc_workspaces.message_failure_edit_logo', {
            workspace: reqBody.workspaceName
          }),
          severity: 'error',
          testId: 'edit-workspace-status',
          title: t('iam:igc_workspaces.title_failure_edit_logo')
        })
      }
      onClose(false)
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrorMessage(
          t('iam:igc_workspaces.duplicated_workspace', {
            workspace: reqBody?.workspaceName
          })
        )
      } else {
        setStatus({
          message: t('iam:igc_workspaces.message_failure_edit', {
            workspace: reqBody?.workspaceName
          }),
          severity: 'error',
          testId: 'edit-workspace-status',
          title: t('iam:igc_workspaces.title_failure_edit')
        })
        onclose(false)
      }
    } finally {
      setLoadingEdit(false)
    }
  }

  const handleDiscardChangesClick = () => {
    onClose(false)
    const newState = JSON.parse(JSON.stringify(originalState))
    newState.logo = null
    dispatch({
      value: newState,
      type: 'SET_STATE'
    })
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <>
              <Typography
                level="2"
                testId="edit-workspace-title"
                type="heading"
                style={{ maxWidth: 'inherit' }}
              >
                {t('manage:org_workspace_edit.edit_title')}
              </Typography>
            </>
          }
          onClose={() => onClose(false)}
        />
      }
      content={
        <CCSForm
          errorMessage={errorMessage}
          testId="edit-workspace-form"
          validate="blur"
          onSubmit={handleSubmit}
          onReset={handleDiscardChangesClick}
          buttons={
            <>
              <Box direction="row" gap="medium" pad={{ top: 'medium' }}>
                <ButtonGroup
                  buttonList={[
                    {
                      id: 1,
                      label: t('manage:org_workspace_edit.save_changes'),
                      type: 'submit',
                      primary: true,
                      testId: 'edit-workspace-btn-save',
                      isLoading: loadingEdit,
                      disabled: loadingEdit
                    },
                    {
                      id: 2,
                      label: t('manage:cancel'),
                      type: 'reset',
                      testId: 'edit-workspace-btn-cancel',
                      onClick: () => onClose(false)
                    }
                  ]}
                  justifyGroup="start"
                  testId="edit-workspace-btn-group"
                />
              </Box>
            </>
          }
        >
          <Box
            gap="small"
            pad={{ top: 'medium', right: 'medium' }}
            style={{ minHeight: 'initial' }}
          >
            {/* Name Input Field */}
            <FormInput
              value={state?.company_name}
              required
              disabled={loadingEdit}
              label={t('manage:org_workspace_edit.workspace_name')}
              name="company_name"
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'company_name',
                  type: 'CHANGE_FIELD'
                })
              }}
              inputType="text"
              validate={(value) => {
                return validateLength(t, value, NAME_WORKSPACE_MAX_LENGTH, true)
              }}
              testId="edit-workspace-name"
            />
            {/* Description Input Field */}
            <FormTextArea
              disabled={loadingEdit}
              value={state?.description}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'description',
                  type: 'CHANGE_FIELD'
                })
              }}
              label={t('manage:org_workspace.description')}
              name="description"
              validate={(value) => {
                return validateLength(t, value, NAME_WORKSPACE_MAX_LENGTH)
              }}
              testId="edit-workspace-description"
            />
            {/* Workspace Icon Form Field */}
            <Box direction="column" gap="xsmall">
              <FormField
                disabled={loadingEdit}
                label={t('manage:org_workspace.icon')}
                name="logo"
                data-testid="edit-workspace-icon-form-field"
                help={t('manage:org_workspace_edit.support_file_types')}
                validate={() =>
                  validateFileInput(
                    state.logo,
                    IMAGE_FILE_SIZE.logo,
                    ALLOWED_IMAGE_TYPES,
                    t
                  )
                }
              >
                <FileInput
                  testId="logo"
                  id="logo"
                  name="logo"
                  accept={ALLOWED_IMAGE_TYPES}
                  onChange={(event) => {
                    dispatch({
                      value:
                        event.target.files && event.target.files.length
                          ? [event.target.files[0], event.target.files[0].name]
                          : null,
                      type: 'CHANGE_LOGO'
                    })
                  }}
                  disabled={loadingEdit}
                />
              </FormField>
              {hasExistingLogo() ? (
                <Box
                  direction="row"
                  align="center"
                  width="medium"
                  justify="between"
                  margin={{ left: 'small' }}
                >
                  <Typography
                    type="text"
                    size="medium"
                    testId="edit-workspace-logo-filename"
                  >
                    {originalState?.logo?.logoFileName}
                  </Typography>
                  <Button
                    onClick={() => {
                      dispatch({
                        value: null,
                        field: 'logo',
                        type: 'CHANGE_FIELD'
                      })
                      setOriginalState({
                        ...originalState,
                        logo: null
                      })
                    }}
                    testId="delete-edit-workspace-logo-filename"
                    disabled={loadingEdit}
                  >
                    <Trash size="medium" />
                  </Button>
                </Box>
              ) : null}
            </Box>
            {/* Address Input Field Group */}
            <Box gap="medium">
              {/* Country Input Field */}
              <Box direction="column" gap="xsmall">
                <Box>
                  <FormField
                    required
                    disabled={loadingEdit}
                    label={t('manage:org_workspace.country')}
                    name="country_code"
                    data-testid="edit-workspace-country-dropdown-field"
                  >
                    <Dropdown
                      name="country_code"
                      options={filteredCountries?.map((country) => {
                        return country?.name
                      })}
                      value={getCountryName(
                        state?.country_code,
                        filteredCountries
                      )}
                      searchPlaceholder={t(
                        'manage:org_workspace_edit.country_search_placeholder'
                      )}
                      onSearch={(searchText) => {
                        const regexp = new RegExp(searchText, 'i')
                        setFilteredCountries(
                          allCountries?.filter((country) =>
                            country?.name?.match(regexp)
                          )
                        )
                      }}
                      onChange={({ option }) => {
                        dispatch({
                          value: getCountryCode(option, filteredCountries),
                          field: 'country_code',
                          type: 'CHANGE_FIELD'
                        })
                      }}
                      onClose={() => setFilteredCountries(allCountries || [])}
                      required
                      testId="edit-workspace-country-dropdown"
                    />
                  </FormField>
                </Box>
              </Box>
            </Box>
            {/* StreetAddress1 Input Field */}
            <FormInput
              required={!streetAddressField}
              disabled={loadingEdit}
              value={state?.street_address}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'street_address',
                  type: 'CHANGE_FIELD'
                })
              }}
              inputType="text"
              label={t('iam:igc_workspaces.street_address_label')}
              name="street_address"
              validate={(value) => {
                return validateLength(t, value, ADDRESS_MAX_LENGTH1)
              }}
              testId="edit-workspace-address-line-1"
            />
            {/* StreetAddress2 Input Field */}
            <FormInput
              disabled={loadingEdit}
              value={state?.street_address_2}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'street_address_2',
                  type: 'CHANGE_FIELD'
                })
              }}
              inputType="text"
              label={t('iam:igc_workspaces.street_address_2_label')}
              name="street_address_2"
              validate={(value) => {
                return validateLength(t, value, ADDRESS_MAX_LENGTH2)
              }}
              testId="edit-workspace-address-line-2"
            />
            <Box direction="row" gap="xsmall">
              {/* City Input Field */}
              <FormInput
                disabled={loadingEdit}
                value={state?.city}
                onChange={(event) => {
                  dispatch({
                    value: event?.target?.value,
                    field: 'city',
                    type: 'CHANGE_FIELD'
                  })
                }}
                inputType="text"
                label={t('location:city')}
                name="city"
                validate={(value) => {
                  return validateLength(t, value, CITY_MAX_LENGTH)
                }}
                testId="edit-workspace-city"
              />
              {/* State or Region Input Field */}
              <FormInput
                disabled={loadingEdit}
                value={state?.state_or_region}
                onChange={(event) => {
                  dispatch({
                    value: event?.target?.value,
                    field: 'state_or_region',
                    type: 'CHANGE_FIELD'
                  })
                }}
                inputType="text"
                label={t('location:state_province_region')}
                name="state_or_region"
                validate={(value) => {
                  return validateLength(t, value, STATE_MAX_LENGTH)
                }}
                testId="edit-workspace-state-region"
              />
            </Box>
            {/* ZipCode Input Field */}
            <FormInput
              disabled={loadingEdit}
              value={state?.zip}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'zip',
                  type: 'CHANGE_FIELD'
                })
              }}
              inputType="text"
              label={t('manage:org_workspace_edit.zip_postal_code')}
              name="zip"
              validate={(value) => {
                return validateLength(t, value, ZIPCODE_MAX_LENGTH)
              }}
              testId="edit-workspace-zipcode"
            />
            {/* PhoneNumber Input Field */}
            <FormInput
              disabled={loadingEdit}
              value={state?.phone_number}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'phone_number',
                  type: 'CHANGE_FIELD'
                })
              }}
              inputType="text"
              label={t('iam:igc_workspaces.ws_contact_phone_number')}
              name="phone_number"
              validate={(value) => {
                return validateLength(t, value, PHONE_MAX_LENGTH)
              }}
              testId="edit-workspace-phone-number"
            />
            {/* Email Input Field */}
            <FormInput
              disabled={loadingEdit}
              value={state?.email}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'email',
                  type: 'CHANGE_FIELD'
                })
              }}
              inputType="text"
              label={t('iam:igc_workspaces.ws_contact_email')}
              name="email"
              validate={(value) => {
                return validateEmail(value)
              }}
              testId="edit-workspace-email"
            />
          </Box>
        </CCSForm>
      }
      position="right"
      height="100%"
      overflow="hidden"
      testId="edit-workspace-side-drawer"
    />
  )
}

EditWorkspaceSideDrawer.propTypes = {
  /**
   * Object containing workspace details from caller.
   */
  workspaceId: PropTypes.string.isRequired,
  /**
   * Object containing workspace details from caller.
   */
  workspaceDetails: PropTypes.object.isRequired,
  /**
   * Callback to set the workspace details back to caller.
   */
  setWorkspaceDetails: PropTypes.func.isRequired,
  /**
   * Callback to set the status back to caller.
   */
  setStatus: PropTypes.func.isRequired,

  /**
   * Callback to indicate whether the modal should be opened or closed.
   */
  onClose: PropTypes.func.isRequired
}
