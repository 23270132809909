import React, { Suspense, lazy } from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'

import { ReportingContextProvider } from '../../../context/reporting-context/ReportingContext'
import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { isInventoryOwnedWorkspace } from '../../../utils/feature-flag-utils'

const ReportDashboardPage = lazy(() => import('./ReportDashboard'))
const ReportingNotAuthDashboardPage = lazy(() => import('./ReportingNotAuth'))
const ReportsRouter = lazy(() => import('./reports/router'))
const CreateReportRouter = lazy(() => import('./create-report/router'))

const ReportingRouterContent = () => {
  const { path } = useRouteMatch()
  let ReportingPageComponent = <ReportDashboardPage />
  if (!isInventoryOwnedWorkspace()) {
    ReportingPageComponent = <ReportingNotAuthDashboardPage />
  }
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/reporting/dashboard',
            permission: 'ccs.reporting.view'
          }}
          fallbackComponent={<ReportingNotAuthDashboardPage />}
        >
          <Route exact path={path}>
            {ReportingPageComponent}
          </Route>
          <Route path="/manage-account/reporting/reports">
            <ReportsRouter />
          </Route>
          <Route path="/manage-account/reporting/create-report">
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/reporting/dashboard',
                permission: 'ccs.reporting.edit'
              }}
              fallbackComponent={<Redirect to="/home" />}
            >
              <CreateReportRouter />
            </VisibilityWrapper>
          </Route>
        </VisibilityWrapper>
      </Switch>
    </Suspense>
  )
}

const ReportingRouter = () => {
  return (
    <ReportingContextProvider>
      <ReportingRouterContent />
    </ReportingContextProvider>
  )
}

export default ReportingRouter
