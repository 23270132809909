import PropTypes from 'prop-types'
import { Box, Grid } from 'grommet'
import { useTranslation } from 'react-i18next'
import { Video } from 'grommet-icons'

import ListItemLink from '../components/ResultListTemplateHelper/ListItemLink'
import { Ruler, Typography } from '../../../components'

import LastUpdatedTooltip from './LastUpdatedTooltip'

const VideoTemplate = ({ result, engine }) => {
  const { t } = useTranslation(['support_hub_search'])
  const universalDate = result?.raw?.hpescuniversaldate
  const date = new Date(universalDate)
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })

  const videoDescription = result?.raw?.kmvideodescription

  return (
    <Box fill="horizontal">
      <Grid
        rows={['auto', 'auto']}
        columns={['auto', '11%']}
        gap="small"
        areas={[
          {
            name: 'title',
            start: [0, 0],
            end: videoDescription ? [1, 0] : [0, 1]
          },
          { name: 'description', start: [0, 1], end: [0, 1] },
          { name: 'date', start: [1, 1], end: [1, 1] }
        ]}
        pad={videoDescription && { vertical: 'small' }}
      >
        {/* Title Section */}
        <Box
          gridArea={videoDescription ? 'title' : 'description'}
          direction="row"
          align="center"
        >
          <Box margin={{ right: 'xsmall' }}>
            <Video data-testid="video-icon" size="small" />
          </Box>
          <ListItemLink result={result} engine={engine} />
        </Box>

        {/* Description Section */}
        {videoDescription && (
          <Box gridArea="description" justify="center">
            <Typography type="text">{videoDescription}</Typography>
          </Box>
        )}

        {/* Date Section with Divider */}
        <Box gridArea="date" direction="row" align="center" gap="small">
          <Box height="xxsmall">
            <Ruler
              orientation="vertical"
              margin={{ top: 'xxsmall', bottom: 'xxsmall' }}
              background="border-weak"
            />
          </Box>
          <LastUpdatedTooltip
            formattedDate={formattedDate}
            lastUpdated={t('last_updated')}
          />
        </Box>
      </Grid>

      <Ruler background="border" margin={{ top: 'xsmall', bottom: 'none' }} />
    </Box>
  )
}

VideoTemplate.propTypes = {
  result: PropTypes.object.isRequired,
  engine: PropTypes.object.isRequired
}

export default VideoTemplate
