import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Alert } from 'grommet-icons'
import { v4 as uuidv4 } from 'uuid'

import { Wizard, ModalDialog, Notification } from '../../../../../../components'
import { post, getErrorResponse } from '../../../../../../utils/api-utils'

import BillingAccountDetails, {
  validateBillingAccountDetails
} from './steps/BillingAccountDetails'
import PartyDetails, { validatePartyDetails } from './steps/PartyDetails'
import ContactDetails, { validateContactDetails } from './steps/ContactDetails'
import ReviewDetails from './steps/ReviewDetails'
import { getCreateBillingAccountBody } from './utils'

const BillingAccountWizard = ({ customerId, closeWizard }) => {
  const { t } = useTranslation(['manage'])
  const { oidcUser } = useReactOidc()
  const [errorResponse, setErrorResponse] = useState(null)
  const [showBillToPartyStep, setShowBillToPartyDetails] = useState(true)
  const [showPayerPartyStep, setShowPayerPartyDetails] = useState(true)
  const [showBillToContactStep, setShowBillToContactStep] = useState(true)
  const [showPayerContactStep, setShowPayerContactStep] = useState(true)

  const handleFinish = (formValues) => {
    const requestBody = getCreateBillingAccountBody(formValues)

    return new Promise((resolve) => {
      post(
        `/marketplace/v1alpha1/billing-accounts`,
        requestBody,
        oidcUser.access_token,
        {
          'x-request-id': uuidv4(),
          'x-account-id': customerId
        }
      ).then(
        () => {
          resolve()
          closeWizard()
        },
        (error) => {
          setErrorResponse(getErrorResponse(error, t))
          resolve()
        }
      )
    })
  }

  const { transactionId } = errorResponse || {}
  return (
    <ModalDialog
      testId="create-billing-account-wizard-modal"
      position="center"
      width="100%"
      height="100%"
      onClose={() => {}}
      content={
        <>
          <Wizard
            testId="create-billing-account-form"
            title={t('Create Billing Account')}
            actionOnExit={() => {
              closeWizard()
            }}
            actionOnSubmit={(formValues) => {
              return new Promise((resolve) => {
                handleFinish(formValues).then(() => resolve())
              })
            }}
            formDefaultValues={{
              billingAccountName: '',
              billingAccountDescription: '',
              soldToPartyDetails: {},
              billToPartyDetails: {},
              payerPartyDetails: {},
              soldToContactDetails: {},
              billToContactDetails: {},
              payerContactDetails: {},
              useSoldToDetailsForBillToParty: false,
              useSoldToDetailsForPayerParty: false,
              useSoldToContactForBillTo: false,
              useSoldToContactForPayer: false
            }}
            buttonLabels={{
              finish: t('billing_accounts.create_billing_account_wizard.submit')
            }}
            disableFinishOnSubmit
            steps={[
              {
                id: 'billingAccountsStep',
                childComponents: <BillingAccountDetails />,
                title: t(
                  'billing_accounts.create_billing_account_wizard.billing_account_details_step_title'
                ),
                description: t(
                  'billing_accounts.create_billing_account_wizard.billing_account_details_step_subtitle'
                ),
                validateForm: (formValues) =>
                  validateBillingAccountDetails(formValues, t)
              },
              {
                id: 'soldToPartyStep',
                childComponents: <PartyDetails partyType="soldTo" />,
                title: t(
                  'billing_accounts.create_billing_account_wizard.sold_to_party_details_step_title'
                ),
                description: t(
                  'billing_accounts.create_billing_account_wizard.sold_to_party_details_step_subtitle'
                ),
                validateForm: (formValues) => {
                  setShowBillToPartyDetails(
                    !formValues.useSoldToDetailsForBillToParty
                  )
                  setShowPayerPartyDetails(
                    !formValues.useSoldToDetailsForPayerParty
                  )

                  return validatePartyDetails('soldTo', formValues, t)
                }
              },
              ...(showBillToPartyStep
                ? [
                    {
                      id: 'billToPartyStep',
                      childComponents: <PartyDetails partyType="billTo" />,
                      title: t(
                        'billing_accounts.create_billing_account_wizard.bill_to_party_details_step_title'
                      ),
                      description: t(
                        'billing_accounts.create_billing_account_wizard.bill_to_party_details_step_subtitle'
                      ),
                      validateForm: (formValues) => {
                        return validatePartyDetails('billTo', formValues, t)
                      }
                    }
                  ]
                : []),
              ...(showPayerPartyStep
                ? [
                    {
                      id: 'payerPartyStep',
                      childComponents: <PartyDetails partyType="payer" />,
                      title: t(
                        'billing_accounts.create_billing_account_wizard.payer_party_details_step_title'
                      ),
                      description: t(
                        'billing_accounts.create_billing_account_wizard.payer_party_details_step_subtitle'
                      ),
                      validateForm: (formValues) => {
                        return validatePartyDetails('payer', formValues, t)
                      }
                    }
                  ]
                : []),
              {
                id: 'soldToContactStep',
                childComponents: <ContactDetails contactType="soldTo" />,
                title: t(
                  'billing_accounts.create_billing_account_wizard.sold_to_contact_details_step_title'
                ),
                description: t(
                  'billing_accounts.create_billing_account_wizard.sold_to_contact_details_step_subtitle'
                ),
                validateForm: (formValues) => {
                  setShowBillToContactStep(
                    !formValues.useSoldToContactForBillTo
                  )
                  setShowPayerContactStep(!formValues.useSoldToContactForPayer)
                  return validateContactDetails('soldTo', formValues, t)
                }
              },
              ...(showBillToContactStep
                ? [
                    {
                      id: 'billToContactStep',
                      childComponents: <ContactDetails contactType="billTo" />,
                      title: t(
                        'billing_accounts.create_billing_account_wizard.bill_to_contact_details_step_title'
                      ),
                      description: t(
                        'billing_accounts.create_billing_account_wizard.bill_to_contact_details_step_subtitle'
                      ),
                      validateForm: (formValues) =>
                        validateContactDetails('billTo', formValues, t)
                    }
                  ]
                : []),
              ...(showPayerContactStep
                ? [
                    {
                      id: 'payerContactStep',
                      childComponents: <ContactDetails contactType="payer" />,
                      title: t(
                        'billing_accounts.create_billing_account_wizard.payer_contact_details_step_title'
                      ),
                      description: t(
                        'billing_accounts.create_billing_account_wizard.payer_contact_details_step_subtitle'
                      ),
                      validateForm: (formValues) =>
                        validateContactDetails('payer', formValues, t)
                    }
                  ]
                : []),
              {
                id: 'reviewStep',
                childComponents: <ReviewDetails />,
                title: t(
                  'billing_accounts.create_billing_account_wizard.review_step_title'
                ),
                description: t(
                  'billing_accounts.create_billing_account_wizard.review_step_subtitle'
                ),
                validateForm: () => {
                  return new Promise((resolve) => resolve())
                }
              }
            ]}
          />

          {errorResponse && (
            <Notification
              type="inline"
              toast={{ position: 'top', autoClose: false }}
              backgroundColor="status-critical"
              onClose={() => setErrorResponse(null)}
              testId="critical-inline-notification"
              title={
                errorResponse?.message ||
                t(
                  'billing_accounts.create_billing_account_wizard.failed_to_create_billing_account'
                )
              }
              text={
                transactionId
                  ? t('common:transaction_id', { transactionId })
                  : ''
              }
              icon={<Alert size="medium" />}
            />
          )}
        </>
      }
    />
  )
}

BillingAccountWizard.propTypes = {
  closeWizard: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired
}

export default BillingAccountWizard
