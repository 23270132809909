import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  Button
} from '../../../../components'

const WarningModal = ({ title, content, onCancel, onContinue }) => {
  const { t } = useTranslation(['device', 'common'])

  return (
    <ModalDialog
      width="large"
      testId="warning-modal-dialog"
      content={
        <Box gap="small">
          <Typography type="heading" level="1" testId="warning-modal-title">
            {title}
          </Typography>
          <Typography type="text" size="large" testId="warning-modal-desc">
            {content}
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <Box direction="row" gap="medium">
              <Button
                testId="warning-modal-cancel-btn"
                label={t('common:cancel')}
                default
                onClick={onCancel}
              />
              <Button
                testId="warning-modal-continue-btn"
                label={t('common:continue')}
                primary
                onClick={onContinue}
              />
            </Box>
          }
        />
      }
      onClose={() => onCancel()}
    />
  )
}

WarningModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired
}

export { WarningModal }
