import dayjs from 'dayjs'

import { getDateInMilliseconds } from '../../../utils/dm-sm-common-utils'

// Supported tiers to display application field in side panel
export const APP_SUPPORTED_SUBSCRIPTION_TIERS = [
  'FOUNDATION_ALLETRA',
  'ENHANCED_ALLETRA',
  'STANDARD_ALLETRA',
  'FOUNDATION_VM_BACKUP',
  'ENHANCED_VM_BACKUP',
  'STANDARD_VM_BACKUP',
  'FOUNDATION_ZERTO',
  'FOUNDATION_SFM'
]

export const CS_INVOICING_MODEL = 'CS_INVOICING_MODEL'

export const VALID_PRODUCT_ATTR_START_PATTERN = 'CS_CCM_'

export const INVALID_PRODUCT_ATTR_START_PATTERN = 'CS_CCM_MULTIV_UOM'

export const VM_COUNT_PRODUCT_ATTR = 'CS_COMMITMENT_VALUE1'

export const BACKUP_CAPACITY_PRODUCT_ATTR = 'CS_COMMITMENT_VALUE2'

export const queryParamsBasedOnTab = (tabName) => {
  const currentDateInMilliseconds = dayjs().valueOf()
  const queryParamObj = {
    expiringIn30Days: {
      expire_date_cut_off_in_millis: currentDateInMilliseconds,
      end_date_in_millis: ['30'],
      evaluation_type: ['NONE']
    },
    expiringIn90Days: {
      expire_date_cut_off_in_millis: getDateInMilliseconds(30),
      end_date_in_millis: ['90'],
      evaluation_type: ['NONE']
    },
    evalsExpiringIn30Days: {
      expire_date_cut_off_in_millis: currentDateInMilliseconds,
      end_date_in_millis: ['30'],
      evaluation_type: ['EVAL']
    },
    totalSubscriptions: {
      subscription_visibility: ['UNEXPIRED_ONLY']
    }
  }
  return queryParamObj[tabName]
}
