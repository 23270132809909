import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, Select, CheckBox } from 'grommet'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { WizardContext } from '../../../../../../../../components/wizard/WizardContext'
import { FormInput, Typography } from '../../../../../../../../components'
import { validateForm } from '../../../../../../../../utils/validation-utils'
import { get } from '../../../../../../../../utils/api-utils'
import { displayApiError } from '../../../../../../../../utils/error-handling-utils'

import {
  validatePartyFields,
  getPartyInformationUpdater,
  getPartyDetails
} from './utils'

export const validatePartyDetails = (partyType, formValues, i18nTranslate) => {
  const partyDetails = getPartyDetails(partyType, formValues)

  const errorObj = validatePartyFields(partyDetails, i18nTranslate)

  if (!isEmpty(errorObj)) {
    return Promise.reject(
      new Error(
        i18nTranslate(
          'billing_accounts.create_billing_account_wizard.missing_required_fields'
        )
      )
    )
  }

  return validateForm(formValues, i18nTranslate)
}

const PartyDetails = ({ partyType }) => {
  const { t } = useTranslation(['manage'])

  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)
  const [formError, setFormError] = useState({})
  const [countries, setCountries] = useState([])
  const [listOfCountries, setListOfCountries] = useState([])
  const [apiError, setApiError] = useState(null)

  const { useSoldToDetailsForBillToParty, useSoldToDetailsForPayerParty } =
    formValues

  const partyDetails = getPartyDetails(partyType, formValues)

  const updatePartyInformation = getPartyInformationUpdater(
    partyType,
    formValues,
    setFormValues
  )

  useEffect(() => {
    if (attemptedAdvance) {
      const errorObj = validatePartyFields(partyDetails, t)
      setFormError(errorObj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, attemptedAdvance, t])

  useEffect(() => {
    get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      (response) => {
        setListOfCountries(response.data.countries)
        setCountries(response.data.countries)
      },
      (error) => {
        setApiError(error)
      }
    )
  }, [t])

  return (
    <Box
      width="medium"
      justify="between"
      align="start"
      margin={{ bottom: 'medium' }}
    >
      <Box direction="column" gap="small" width="100%">
        <Box width="100%">
          <FormInput
            testId="party-id"
            label={t('billing_accounts.create_billing_account_wizard.partyId')}
            inputType="text"
            value={partyDetails.partyId || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.party_id_placeholder'
            )}
            error={formError.partyId}
            onChange={(event) => {
              updatePartyInformation('partyId', event.target.value)
            }}
            required
          />
        </Box>

        <Box width="100%">
          <FormInput
            testId="name"
            label={t('billing_accounts.create_billing_account_wizard.name')}
            inputType="text"
            value={partyDetails.name || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.name_placeholder'
            )}
            error={formError.name}
            onChange={(event) => {
              updatePartyInformation('name', event.target.value)
            }}
            required
          />
        </Box>

        <Typography type="heading" level="4">
          {t('billing_accounts.create_billing_account_wizard.address')}
        </Typography>

        <Box width="100%">
          <FormInput
            testId="house-number"
            label={t(
              'billing_accounts.create_billing_account_wizard.houseNumber'
            )}
            inputType="text"
            value={partyDetails.houseNumber || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.house_number_placeholder'
            )}
            error={formError.houseNumber}
            onChange={(event) => {
              updatePartyInformation('houseNumber', event.target.value)
            }}
            required
          />
        </Box>

        <Box width="100%">
          <FormInput
            testId="street-name"
            label={t(
              'billing_accounts.create_billing_account_wizard.streetName'
            )}
            inputType="text"
            value={partyDetails.streetName || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.street_name_placeholder'
            )}
            error={formError.streetName}
            onChange={(event) => {
              updatePartyInformation('streetName', event.target.value)
            }}
            required
          />
        </Box>

        <Box width="100%">
          <FormInput
            testId="city-name"
            label={t('billing_accounts.create_billing_account_wizard.cityName')}
            inputType="text"
            value={partyDetails.cityName || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.city_name_placeholder'
            )}
            error={formError.cityName}
            onChange={(event) => {
              updatePartyInformation('cityName', event.target.value)
            }}
            required
          />
        </Box>

        <Box width="100%">
          <FormInput
            testId="state"
            label={t('billing_accounts.create_billing_account_wizard.state')}
            inputType="text"
            value={partyDetails.state || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.state_placeholder'
            )}
            error={formError.state}
            onChange={(event) => {
              updatePartyInformation('state', event.target.value)
            }}
            required
          />
        </Box>

        <Box width="100%">
          <FormInput
            testId="postal-code"
            label={t(
              'billing_accounts.create_billing_account_wizard.postalCode'
            )}
            inputType="text"
            value={partyDetails.postalCode || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.postal_code_placeholder'
            )}
            error={formError.postalCode}
            onChange={(event) => {
              updatePartyInformation('postalCode', event.target.value)
            }}
            required
          />
        </Box>

        <FormField
          width="100%"
          label={t('billing_accounts.create_billing_account_wizard.country')}
          name="country"
          data-testid="country"
          error={formError.country}
          required
        >
          <Select
            id="country"
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.country_placeholder'
            )}
            options={countries}
            multiple={false}
            labelKey="name"
            value={partyDetails?.country?.code || ''}
            valueKey={{ key: 'code', reduce: true }}
            searchPlaceholder={t(
              'billing_accounts.create_billing_account_wizard.country_search_placeholder'
            )}
            emptySearchMessage={t(
              'billing_accounts.create_billing_account_wizard.no_countries_found'
            )}
            onSearch={(searchText) => {
              const regexp = new RegExp(searchText, 'i')
              setCountries(listOfCountries.filter((o) => o.name.match(regexp)))
            }}
            onChange={({ option }) => {
              updatePartyInformation('country', option)
            }}
            onClose={() => setCountries(listOfCountries)}
          />
        </FormField>

        {partyType === 'soldTo' && (
          <Box gap="small">
            <CheckBox
              id="use-sold-to-details-for-bill-to-party"
              label={t(
                'billing_accounts.create_billing_account_wizard.use_sold_to_party_details_for_bill_to_party'
              )}
              checked={useSoldToDetailsForBillToParty}
              onChange={(event) => {
                setFormValues((currFormValues) => {
                  return {
                    ...currFormValues,
                    useSoldToDetailsForBillToParty: event.target.checked,
                    billToPartyDetails: event.target.checked
                      ? currFormValues.soldToPartyDetails
                      : {}
                  }
                })
              }}
            />

            <CheckBox
              id="use-sold-to-details-for-payer-party"
              label={t(
                'billing_accounts.create_billing_account_wizard.use_sold_to_party_details_for_payer_party'
              )}
              checked={useSoldToDetailsForPayerParty}
              onChange={(event) => {
                setFormValues((currFormValues) => {
                  return {
                    ...currFormValues,
                    useSoldToDetailsForPayerParty: event.target.checked,
                    payerPartyDetails: event.target.checked
                      ? currFormValues.soldToPartyDetails
                      : {}
                  }
                })
              }}
            />
          </Box>
        )}
      </Box>

      {apiError &&
        displayApiError(
          apiError,
          t,
          () => {
            setApiError(null)
          },
          t(
            'billing_accounts.create_billing_account_wizard.failed_to_load_country_data'
          )
        )}
    </Box>
  )
}

PartyDetails.propTypes = {
  partyType: PropTypes.oneOf(['soldTo', 'billTo', 'payer']).isRequired
}

export default PartyDetails
