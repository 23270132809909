import { React, Suspense, lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

const TagsDetails = lazy(() => import('./components/TagsDetails'))

const TagsPage = lazy(() => import('./TagsPage'))

const TagsRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route path="/manage-account/tags/tag-details/:id">
          <TagsDetails />
        </Route>
        <Route path="/manage-account/tags">
          <TagsPage />
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  )
}

export default TagsRouter
