import { Box, FormField } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { ButtonGroup, Dropdown, Typography } from '../../../../components'
import { getDeviceTypeEnum } from '../../../../utils/common-utils'

const EditAutoSubscription = ({
  currentData,
  tierGroupsObj,
  onSave,
  onClose
}) => {
  const { t } = useTranslation(['device'])
  const [selectedSubscriptionTier, setSelectedSubscriptionTier] = useState('')

  return (
    <Box
      direction="row-responsive"
      justify="between"
      pad={{ bottom: 'medium', horizontal: 'small' }}
      align="center"
      width="large"
      border={{ side: 'bottom', color: 'border-weak' }}
      wrap
    >
      <Box direction="column" gap="xsmall">
        <Typography size="large" testId="device-type" type="text" weight="bold">
          {getDeviceTypeEnum(currentData?.device_type, t)}
        </Typography>
        <FormField
          data-testid="subscription-tier-form-field"
          label={t('subscription_tier')}
          name="subscription_tier"
          width="medium"
        >
          <Dropdown
            noBorder
            testId="subscription-tier-input"
            name="subscription_tier"
            defaultVal={currentData?.tierGroup}
            options={tierGroupsObj[currentData?.device_type]}
            placeholder={t('select_subscription_tier')}
            onChangeDropdown={(val) => {
              setSelectedSubscriptionTier(val)
            }}
          />
        </FormField>
      </Box>

      <ButtonGroup
        buttonList={[
          {
            id: 2,
            label: t('cancel'),
            default: true,
            testId: 'auto-subscribe-cancel-btn',
            onClick: onClose
          },
          {
            id: 1,
            label: t('save'),
            primary: true,
            testId: 'auto-subscribe-save-btn',
            onClick: () => {
              if (
                selectedSubscriptionTier &&
                selectedSubscriptionTier !== currentData?.tierGroup
              ) {
                onSave(selectedSubscriptionTier)
              } else onClose()
            }
          }
        ]}
        testId="auto-subscribe-action-buttons"
      />
    </Box>
  )
}

EditAutoSubscription.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentData: PropTypes.object.isRequired,
  tierGroupsObj: PropTypes.object.isRequired
}

export { EditAutoSubscription }
