import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ButtonGroup
} from '../../../../../../../components'
import {
  WKSPC,
  getWorkspaceString
} from '../../../../../../../utils/common-utils'

const AccountRemoveModal = ({ onSetOpen, handleRemove }) => {
  const { t } = useTranslation(['manage', 'common'])
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const handleCancel = () => {
    onSetOpen(false)
  }

  const handleSave = () => {
    onSetOpen(false)
    handleRemove()
  }

  return (
    <>
      <ModalDialog
        position="center"
        onClickOutside={() => {
          onSetOpen(false)
        }}
        testId="confirmation-dialog"
        onEsc={() => {
          onSetOpen(false)
        }}
        content={
          <Box gap="medium" margin={{ top: 'small' }}>
            <Box gap="xsmall">
              <Typography type="text" size="medium">
                {t('notification.remove_account', {
                  account: getWorkspaceString(showWorkspaceString, t, WKSPC)
                })}
              </Typography>
              <Typography type="text" size="medium" margin={{ top: 'xsmall' }}>
                {t('notification.remove_account_confirmation', {
                  account: getWorkspaceString(showWorkspaceString, t, WKSPC)
                })}
              </Typography>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('cancel'),
                    default: true,
                    testId: 'cancel-confirm-btn',
                    onClick: handleCancel
                  },
                  {
                    id: 1,
                    label: t('notification.remove'),
                    primary: true,
                    testId: 'remove-account-confirm-btn',
                    onClick: handleSave
                  }
                ]}
                testId="account-remove-buttons"
              />
            }
          />
        }
        header={
          <ModalHeader
            title={
              <Typography size="xxlarge" type="text" weight="bold">
                {t('notification.remove_header', {
                  account: getWorkspaceString(showWorkspaceString, t, WKSPC)
                })}
              </Typography>
            }
          />
        }
        onClose={handleCancel}
        width="large"
      />
    </>
  )
}
export { AccountRemoveModal }

AccountRemoveModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired
}
