import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'

import { Button } from '../../../../../components'

const ActionButtons = ({ updateLabel, tObj }) => {
  return (
    <Box direction="row" gap="medium">
      <Button
        testId="account-detail-button-discard"
        label={tObj('cancel')}
        size="medium"
        type="reset"
      />
      <Button
        primary
        testId="account-detail-button-update"
        type="submit"
        label={updateLabel}
        size="medium"
      />
    </Box>
  )
}

ActionButtons.propTypes = {
  updateLabel: PropTypes.string.isRequired,
  tObj: PropTypes.func.isRequired
}

export default ActionButtons
