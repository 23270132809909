const exists = (val) => val !== undefined

export const formatLDFlags = (LDFlags) => {
  const hideWhen = new Set(
    Object.keys(LDFlags).filter((flag) => {
      return !exists(LDFlags[flag]) || LDFlags[flag] !== true
    })
  )
  // filter LDFlags and add flags to hideWhen here
  return hideWhen
}
