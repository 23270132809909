import { Box, FormField, TextInput } from 'grommet'
import React, { useState, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import get from 'lodash/get'

import {
  ButtonGroup,
  CCSForm,
  Dropdown,
  ModalDialog,
  Typography,
  ModalHeader
} from '../../../../../components'
import { validateFieldWithEmptySpaces } from '../../../../../utils/validation-utils'
import {
  actionCreateRule,
  actionEditRule,
  getFolderList,
  getRuleDetails
} from '../../../utils'

import {
  MoveToFolderFormField,
  NotificationFormField,
  ProvisioningRuleFormField
} from './components'

const AddRuleModal = ({
  onSetOpen,
  refreshRule,
  data,
  inCCSManager,
  customerId
}) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const folderDetailParam = JSON.parse(
    sessionStorage.getItem('folderDetailParam')
  )
  const [errorMessage, setErrorMessage] = useState('')
  const [listOfFolders, setListOfFolders] = useState([])
  const [ruleDetailsResponse, setRuleDetailsResponse] = useState(null)

  // for paginating on scroll
  const itemsPerPage = 50
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(0)
  const intialState = {
    rule_name: '',
    rule_type: '',
    sub_type: '',
    value: '',
    reference_rule_name: '',
    reference_rule_id: '',
    folder_name: get(folderDetailParam, 'folder_name', ''),
    folder_id: get(folderDetailParam, 'folder_id', ''),
    move_to_folder_name: get(folderDetailParam, 'folder_name', ''),
    move_to_folder_id: get(folderDetailParam, 'folder_id', ''),
    amp_ip: '',
    shared_secret: '',
    organization: '',
    ap_group: '',
    controller: '',
    persist_controller_ip: false,
    backup_controller_ip: '',
    backup_controller: '',
    country_code: 'USA',
    config_group: '',
    redundancy_level: '',
    config_node_path: '',
    controller2: '',
    primary_ctrl_ip2: '',
    backup_controller2: '',
    vpn_mac: '',
    vpn_ip: '',
    backup_vpn_mac: '',
    vpn_mac2: '',
    vpn_ip2: '',
    backup_vpn_mac2: '',
    edit: false
  }
  const [state, dispatch] = useReducer((currentState, action) => {
    switch (action.type) {
      case 'CHANGE_FIELD':
        return { ...currentState, [action.field]: action.value }
      case 'SET_RULE_TYPE':
        return {
          ...intialState,
          [action.field]: action.value,
          rule_name: currentState.rule_name,
          edit: currentState.edit
        }
      case 'SET_STATE':
        return { ...action.value, edit: true }
      default:
        return currentState
    }
  }, intialState)

  const listOfRuleType = [
    { label: t('provisioning_rule'), value: 'provision' },
    ...(state.folder_name === 'default'
      ? [
          {
            label: t('move_to_folder'),
            value: 'folder'
          }
        ]
      : []),
    { label: t('notification'), value: 'email' }
  ]

  useEffect(() => {
    if (ruleDetailsResponse)
      dispatch({
        value: ruleDetailsResponse,
        type: 'SET_STATE'
      })
  }, [ruleDetailsResponse])

  useEffect(() => {
    if (data) {
      getRuleDetails({
        param: data,
        token: oidcUser.access_token,
        t,
        setRuleDetails: setRuleDetailsResponse,
        setErrorMessage,
        inCCSManager,
        customerId
      })
    }
  }, [data, oidcUser.access_token, t, inCCSManager, customerId])

  useEffect(() => {
    const param = {
      limit: itemsPerPage,
      page,
      ...(inCCSManager && { platform_customer_id: customerId })
    }
    getFolderList({
      token: oidcUser.access_token,
      param,
      t,
      setErrorMessage,
      isCCSManager: inCCSManager
    }).then((resp) => {
      const foldersResponse = resp?.folders
      if (foldersResponse) {
        setListOfFolders((prevState) => [...prevState, ...foldersResponse])
        setTotalItems(resp?.pagination?.total_count || itemsPerPage)
      }
    })
  }, [
    oidcUser.access_token,
    t,
    setErrorMessage,
    inCCSManager,
    customerId,
    page
  ])

  const getProvisioningReqBody = () => {
    switch (state.sub_type) {
      case 'iap':
        return {
          value: state.value,
          amp_ip: state.amp_ip,
          shared_secret: state.shared_secret,
          organization: state.organization,
          ap_group: state.ap_group,
          controller: state.controller
        }
      case 'iap_to_rap':
        return {
          value: state.value,
          ap_group: state.ap_group,
          controller: state.controller
        }
      case 'iap_to_cap':
        return {
          ap_group: state.ap_group,
          controller: state.controller,
          persist_controller_ip: state.persist_controller_ip
        }
      case 'iap_to_thirdparty':
        return {
          ap_group: state.ap_group,
          controller: state.controller
        }
      case 'switch_to_amp':
        return {
          amp_ip: state.amp_ip,
          shared_secret: state.shared_secret,
          organization: state.organization,
          ap_group: state.ap_group,
          controller: state.controller,
          backup_controller_ip: state.backup_controller_ip
        }
      case 'contrl_to_amp':
        return {
          amp_ip: state.amp_ip,
          organization: state.organization,
          ap_group: state.ap_group
        }
      case 'branch_to_master_controller':
        return {
          controller: state.controller,
          value: state.value,
          backup_controller: state.backup_controller,
          backup_controller_ip: state.backup_controller_ip,
          country_code: state.country_code,
          config_group: state.config_group
        }
      case 'managed_node_to_master_controller':
        return {
          redundancy_level: state.redundancy_level,
          config_node_path: state.config_node_path,
          ...(['L2', 'L3'].includes(state.redundancy_level) && {
            backup_controller: state.backup_controller
          }),
          controller: state.controller,
          value: state.value,
          vpn_mac: state.vpn_mac,
          vpn_ip: state.vpn_ip,
          backup_vpn_mac: state.backup_vpn_mac,
          country_code: state.country_code,
          ...(state.redundancy_level === 'L3' && {
            controller2: state.controller2,
            primary_ctrl_ip2: state.primary_ctrl_ip2,
            backup_controller2: state.backup_controller2,
            vpn_mac2: state.vpn_mac2,
            vpn_ip2: state.vpn_ip2,
            backup_vpn_mac2: state.backup_vpn_mac2
          })
        }
      case 'iap_to_athena':
        return {
          value: state.value
        }
      default:
        return {}
    }
  }

  const getMoveToFolderReqBody = () => {
    return {
      value: state.value,
      move_to_folder_name: state.move_to_folder_name,
      move_to_folder_id: state.move_to_folder_id
    }
  }

  const getNotificationReqBody = () => {
    const defaultField = {
      value: state.value
    }
    if (['provision', 'folder'].includes(state.sub_type))
      return {
        ...defaultField,
        reference_rule_name: state.reference_rule_name,
        reference_rule_id: state.reference_rule_id
      }
    if (state.sub_type === 'firmware')
      return {
        ...defaultField,
        folder_name: state.folder_name,
        folder_id: state.folder_id
      }
    const defaultFolderData = listOfFolders.find(
      (val) => val.folder_name === 'default'
    )
    return {
      ...defaultField,
      folder_name: get(defaultFolderData, 'folder_name'),
      folder_id: get(defaultFolderData, 'folder_id')
    }
  }

  const handleOnSubmit = () => {
    let requestBody
    switch (state.rule_type) {
      case 'provision':
        requestBody = getProvisioningReqBody()
        break
      case 'folder':
        requestBody = getMoveToFolderReqBody()
        break
      case 'email':
        requestBody = getNotificationReqBody()
        break
      default:
        break
    }
    requestBody = {
      rule_name: state.rule_name,
      rule_type: state.rule_type?.toUpperCase(),
      sub_type: state.sub_type?.toUpperCase(),
      ...(state.sub_type !== 'shipment' && {
        folder_name: state.folder_name,
        folder_id: state.folder_id
      }),
      enabled: true,
      ...requestBody
    }
    if (state.edit) {
      const ruleID = data.rule_id
      actionEditRule({
        token: oidcUser.access_token,
        t,
        ruleID,
        requestBody,
        onSetOpen,
        refreshRule,
        setErrorMessage,
        customerId,
        inCCSManager
      })
    } else
      actionCreateRule({
        token: oidcUser.access_token,
        t,
        requestBody,
        onSetOpen,
        refreshRule,
        setErrorMessage,
        customerId,
        inCCSManager
      })
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          subtitle={
            state.edit ? t('edit_rule_desc') : t('create_new_rule_desc')
          }
          title={
            <Typography level="1" type="heading" testId="add-rule-title">
              {state.edit ? t('edit_rule') : t('create_new_rule')}
            </Typography>
          }
        />
      }
      content={
        <Box>
          <CCSForm
            onSubmit={() => handleOnSubmit()}
            onReset={() => onSetOpen(false)}
            errorMessage={errorMessage}
            testId="add-rule-form"
            validate="blur"
          >
            <Box
              margin={{ top: 'medium', bottom: 'xxsmall' }}
              direction="column"
              justify="between"
              gap="small"
            >
              <FormField
                data-testid="rule-name-form-field"
                label={t('rule_name')}
                name="rule_name"
                width="medium"
                required={!state.edit}
                validate={() =>
                  validateFieldWithEmptySpaces(
                    state.rule_name,
                    t('invalid_name_format')
                  )
                }
              >
                {state.edit ? (
                  <Typography
                    testId="rule-type-input"
                    type="text"
                    size="medium"
                    margin={{ horizontal: 'small', vertical: 'xsmall' }}
                  >
                    {state.rule_name}
                  </Typography>
                ) : (
                  <TextInput
                    data-testid="rule-name-input"
                    name="rule_name"
                    placeholder={t('rule_name')}
                    value={state.rule_name}
                    onChange={(event) =>
                      dispatch({
                        value: event.target.value,
                        field: 'rule_name',
                        type: 'CHANGE_FIELD'
                      })
                    }
                  />
                )}
              </FormField>

              <FormField
                data-testid="rule-type-form-field"
                label={t('rule_type')}
                name="rule_type"
                width="medium"
                required={!state.edit}
              >
                {state.edit ? (
                  <Typography
                    testId="rule-type-input"
                    type="text"
                    size="medium"
                    margin={{ horizontal: 'small', vertical: 'xsmall' }}
                  >
                    {t(`rule_type_option.${state.rule_type}`)}
                  </Typography>
                ) : (
                  <Dropdown
                    name="rule_type"
                    placeholder={t('select_rule_type')}
                    options={listOfRuleType}
                    labelKey="label"
                    value={state.rule_type}
                    valueKey={{ key: 'value', reduce: true }}
                    onChangeDropdown={(val) => {
                      dispatch({
                        value: val,
                        field: 'rule_type',
                        type: 'SET_RULE_TYPE'
                      })
                    }}
                    testId="rule-type-input"
                  />
                )}
              </FormField>
              {(['provision', 'folder'].includes(state.rule_type) ||
                state.sub_type === 'firmware') && (
                <FormField
                  data-testid="folder-name-form-field"
                  label={
                    state.rule_type === 'folder'
                      ? t('move_to_folder')
                      : t('parent_folder_name')
                  }
                  name="folder_name"
                  width="medium"
                  required
                >
                  <Dropdown
                    name="folder_name"
                    placeholder={t('select_a_folder')}
                    options={listOfFolders}
                    labelKey="folder_name"
                    valueKey={{ key: 'folder_id', reduce: true }}
                    value={
                      (state.rule_type === 'folder'
                        ? state.move_to_folder_id
                        : state.folder_id) || ''
                    }
                    onChange={({ option }) => {
                      dispatch({
                        value: option.folder_name,
                        field:
                          state.rule_type === 'folder'
                            ? 'move_to_folder_name'
                            : 'folder_name',
                        type: 'CHANGE_FIELD'
                      })
                      dispatch({
                        value: option.folder_id,
                        field:
                          state.rule_type === 'folder'
                            ? 'move_to_folder_id'
                            : 'folder_id',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                    testId="folder-name-input"
                    onMore={() => {
                      if (
                        totalItems > itemsPerPage &&
                        page < totalItems / itemsPerPage - 1
                      )
                        setPage(page + 1)
                    }}
                  />
                </FormField>
              )}

              {state.rule_type === 'provision' && (
                <ProvisioningRuleFormField
                  state={state}
                  dispatch={dispatch}
                  setErrorMessage={setErrorMessage}
                  customerId={customerId}
                  inCCSManager={inCCSManager}
                />
              )}
              {state.rule_type === 'folder' && (
                <MoveToFolderFormField
                  state={state}
                  dispatch={dispatch}
                  inCCSManager={inCCSManager}
                />
              )}
              {state.rule_type === 'email' && (
                <NotificationFormField
                  state={state}
                  dispatch={dispatch}
                  customerId={customerId}
                  inCCSManager={inCCSManager}
                />
              )}
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    type: 'reset'
                  },
                  {
                    id: 1,
                    label: `${state.edit ? t('update') : t('create')}`,
                    primary: true,
                    type: 'submit',
                    testId: 'create-btn'
                  }
                ]}
                testId="two-buttons"
              />
            </Box>
          </CCSForm>
        </Box>
      }
      testId="add-rule-modal-dialog"
      onClose={() => onSetOpen(false)}
      width="medium"
    />
  )
}

AddRuleModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  refreshRule: PropTypes.func.isRequired,
  /**
   * if obj is passed to data, Model will be opened in EDIT mode.
   * if undefined is passed to data, Model will be openend in CREATE mode .
   */
  data: PropTypes.object,
  customerId: PropTypes.string,
  inCCSManager: PropTypes.bool
}

AddRuleModal.defaultProps = {
  data: undefined,
  customerId: undefined,
  inCCSManager: undefined
}

export { AddRuleModal }
