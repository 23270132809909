// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, Accordion, AccordionPanel, Grommet, Markdown } from 'grommet'
import React from 'react'
import { deepMerge } from 'grommet/utils'
import PropTypes from 'prop-types'
import { hpe } from 'grommet-theme-hpe'

import { Typography } from '../../../../../components'
import {
  getprimaryField,
  getActionField,
  getRedirectPath,
  getLabelsForFalseId,
  getFieldLabel
} from '../utils'

const panelTheme = deepMerge(hpe, {
  accordion: {
    panel: {
      border: {
        color: 'transparent'
      }
    },
    border: {
      color: 'transparent'
    }
  },
  formField: {
    border: {
      color: 'transparent'
    }
  }
})

const DetailsContent = ({ data }) => (
  <>
    <Box
      height="auto"
      margin="small"
      pad="small"
      border={{ color: 'border-weak', size: 'small' }}
      direction="row"
      data-testid="accordion-details-content"
      wrap
    >
      {data?.map((item, index) => (
        <Box
          direction="column"
          flex={index < 2 ? 'grow' : 'shrink'}
          width="50%"
          pad="xsmall"
        >
          <Typography
            type="text"
            testId={`gs-accordion-data-label-${index}`}
            emphasis
          >
            {item?.label}
          </Typography>
          <Typography
            type="text"
            testId={`gs-accordion-data-value-${index}`}
            wordBreak="break-word"
          >
            {item?.value}
          </Typography>
        </Box>
      ))}
    </Box>
  </>
)
DetailsContent.propTypes = {
  data: PropTypes.any.isRequired
}

export const AccordionComponent = ({
  content,
  renderHint,
  setOpenDropdown
}) => {
  const primaryField = getprimaryField(renderHint?.renderingHints)
  const history = document.querySelector('greenlake-header')?.historyObject
  const primaryFieldLabel = getFieldLabel(
    renderHint?.renderingHints,
    primaryField
  )
  return (
    <Box gap="small">
      <Grommet theme={panelTheme}>
        <Accordion
          style={{
            outline: 'none',
            boxShadow: 'none'
          }}
          focusIndicator="false"
        >
          {content?.results.map((item, index) => (
            <AccordionPanel
              key={item[primaryField]}
              focusIndicator="false"
              label={
                <Box
                  direction="column"
                  pad={{ left: 'xsmall' }}
                  margin={{ vertical: 'small' }}
                >
                  <Typography
                    type="text"
                    size="medium"
                    testId={`gs-accordion-title-${index}`}
                    emphasis
                  >
                    {primaryFieldLabel}: {item[primaryField]}
                  </Typography>
                  <Typography
                    type="text"
                    size="small"
                    testId={`gs-accordion-desc-${index}`}
                  >
                    {item?.highlight &&
                      item?.highlight !== 'null' &&
                      item?.highlight !== 'undefined' && (
                        <Markdown type="text" size="small">
                          {
                            item?.highlight[
                              Object.keys(item?.highlight)[0]
                            ]?.[0]
                          }
                        </Markdown>
                      )}
                  </Typography>
                </Box>
              }
            >
              <Box
                onClick={() => {
                  const actionField = getActionField(
                    renderHint?.renderingHints,
                    item?.resourceType
                  )
                  const path = getRedirectPath(actionField, item)
                  history.push(path)
                  setOpenDropdown(false)
                }}
              >
                <DetailsContent
                  data={getLabelsForFalseId(renderHint?.renderingHints, item)}
                />
              </Box>
            </AccordionPanel>
          ))}
        </Accordion>
      </Grommet>
    </Box>
  )
}
AccordionComponent.propTypes = {
  content: PropTypes.any.isRequired,
  renderHint: PropTypes.any.isRequired,
  setOpenDropdown: PropTypes.func.isRequired
}
