import { isUndefined, isEmpty } from 'lodash'

import {
  WHATS_NEW_ALLOWED_VIDEO_TYPE,
  WHATS_NEW_ALLOWED_IMAGE_TYPES
} from '../../../../../../../../utils/validation-utils'

export const getFieldArrayErrorMessage = (errorArray, index) => {
  if (isEmpty(errorArray) || isUndefined(errorArray[index])) {
    return ''
  }
  return errorArray[index]
}

export const validateSelectedFile = (file, i18nTranslate) => {
  const contentType = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4']
  let errorFile = ''
  if (contentType.includes(file.type)) {
    if (
      WHATS_NEW_ALLOWED_VIDEO_TYPE.includes(file.type) &&
      file.size > 104857600
    ) {
      errorFile = i18nTranslate('whats_new.create_post_form.file_size_limit')
    } else if (
      WHATS_NEW_ALLOWED_IMAGE_TYPES.includes(file.type) &&
      file.size > 8388608
    ) {
      errorFile = i18nTranslate('whats_new.create_post_form.file_size_limit')
    }
  } else {
    errorFile = i18nTranslate('whats_new.create_post_form.invalid_format')
  }
  return errorFile
}

export const validateRequiredLabel = (referenceLabel, i18nTranslate) => {
  let errorLabel
  if (!referenceLabel?.trim()) {
    errorLabel = i18nTranslate('required')
  }

  return errorLabel
}

export const validateReferenceLink = (referenceLinks, i18nTranslate) => {
  let errorLink
  errorLink = validateRequiredLabel(referenceLinks, i18nTranslate)
  if (!errorLink) {
    const regexp = new RegExp(
      '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
    )
    const endCheck =
      referenceLinks.endsWith('.exe') ||
      referenceLinks.endsWith('.msi') ||
      referenceLinks.endsWith('.bin')

    if (!regexp.test(referenceLinks) || endCheck) {
      errorLink = i18nTranslate('whats_new.create_post_form.invalid_link')
    }
  }

  return errorLink
}

export const validateNewDetails = (formValues, i18nTranslate) => {
  const requiredFields = ['title', 'shortDescription']
  const errorObj = {}
  requiredFields.forEach((k) => {
    if (!formValues[k]?.trim()) {
      errorObj[k] = i18nTranslate('required')
    }
  })
  if (formValues?.selectedFile?.length > 0) {
    const errorFile = validateSelectedFile(
      formValues?.selectedFile[0],
      i18nTranslate
    )
    if (errorFile) {
      errorObj.uploadFile = errorFile
    }
  }
  if (formValues?.referenceLinks) {
    const referenceLinksError = []
    const referenceLabelError = []

    formValues?.referenceLinks.forEach((item, index) => {
      if (!isEmpty(item.link) || !isEmpty(item.label)) {
        referenceLabelError[index] = validateRequiredLabel(
          item.label,
          i18nTranslate
        )
        referenceLinksError[index] = validateReferenceLink(
          item.link,
          i18nTranslate
        )
      }
    })
    // sets errorObj if any element has error sting
    if (referenceLinksError.some((i) => !isUndefined(i))) {
      errorObj.referenceLinks = referenceLinksError
    }
    if (referenceLabelError.some((i) => !isUndefined(i))) {
      errorObj.referenceLabel = referenceLabelError
    }
  }
  if (formValues?.summaryFlag) {
    if (!formValues.summary?.trim()) {
      errorObj.summary = i18nTranslate('required')
    }
  }

  return errorObj
}
