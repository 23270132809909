import React, { useState, useEffect, useContext } from 'react'
import { Box, FormField, TextInput, Text } from 'grommet'
import { useTranslation } from 'react-i18next'
import lodashGet from 'lodash/get'

import { WizardContext } from '../../../../../../components/wizard/WizardContext'

import { validateTimeoutField } from './utils/validation-utils'

const Timeout = () => {
  const { t } = useTranslation(['iam'])
  const { attemptedAdvance, formValues, setFormValues } =
    useContext(WizardContext)
  const [fieldsError, setFieldsError] = useState({
    timeout: ''
  })

  useEffect(() => {
    if (attemptedAdvance) {
      setFieldsError(validateTimeoutField(formValues, t))
    }
  }, [formValues, attemptedAdvance, t])

  return (
    <Box gap="medium" pad={{ top: 'xxsmall', bottom: 'large' }}>
      <Box width="medium" gap="medium">
        <Box gap="xsmall">
          <Text size="xsmall" margin={{ top: 'xxsmall' }} weight={500}>
            {t('iam:sso_profiles.view_edit_create.session_timeout')}*
          </Text>
          <Text size="xsmall">
            {t('iam:sso_profiles.view_edit_create.session_timeout_description')}
          </Text>
          <FormField
            htmlFor="timeout"
            name="timeout"
            error={fieldsError.timeout}
            data-testid="timeout-form-field"
            required
          >
            <TextInput
              value={lodashGet(formValues, 'timeout')}
              id="timeout"
              name="timeout_value"
              data-testid="timeout-input"
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  timeout: e.target.value
                })
              }}
            />
          </FormField>
        </Box>
      </Box>
    </Box>
  )
}

export default Timeout
