import { Box, FormField, TextArea } from 'grommet'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import debounce from 'lodash/debounce'

import { Dropdown, Typography } from '../../../../../../components'
import { getRuleList } from '../../../../utils'

const NotificationFormField = ({
  state,
  dispatch,
  customerId,
  inCCSManager
}) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const listOfEmailOn = [
    ...(state.folder_name === 'default'
      ? [
          {
            label: t('shipment'),
            value: 'shipment'
          }
        ]
      : []),
    { label: t('provisioning'), value: 'provision' },
    { label: t('firmware_upgrade'), value: 'firmware' },
    ...(state.folder_name === 'default'
      ? [
          {
            label: t('move_to_folder'),
            value: 'folder'
          }
        ]
      : [])
  ]
  const [listOfRule, setListOfRule] = useState([])
  const [defaultRuleOption, setDefaultPartOption] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const param = {
      limit: 50,
      page: 0,
      ...(inCCSManager && { platform_customer_id: customerId }),
      ...(searchTerm &&
        searchTerm.trimStart().length > 2 && { search_string: searchTerm })
    }

    getRuleList({
      inCCSManager,
      token: oidcUser.access_token,
      t,
      param,
      setErrorMessage: ''
    }).then((data) => {
      setListOfRule(data.rules)
      if (!searchTerm) setDefaultPartOption(data.rules)
    })
  }, [customerId, inCCSManager, oidcUser.access_token, searchTerm, t])

  const handleDebouncedSearchValue = debounce((value) => {
    setSearchTerm(value)
  }, 1000)

  return (
    <Box
      margin={{ bottom: 'xxsmall' }}
      direction="column"
      justify="between"
      gap="small"
    >
      <FormField
        data-testid="email-on-form-field"
        label={t('email_on')}
        name="sub_type"
        width="medium"
        required={!state.edit}
      >
        {state.edit ? (
          <Typography
            testId="email-on-input"
            type="text"
            size="medium"
            margin={{ horizontal: 'small', vertical: 'xsmall' }}
          >
            {t(`list_of_email_on_option.${state.sub_type}`)}
          </Typography>
        ) : (
          <Dropdown
            name="sub_type"
            placeholder={t('select_from_list')}
            options={listOfEmailOn}
            labelKey="label"
            value={state.sub_type}
            valueKey={{ key: 'value', reduce: true }}
            onChangeDropdown={(val) => {
              dispatch({
                value: val,
                field: 'sub_type',
                type: 'CHANGE_FIELD'
              })
            }}
            testId="email-on-input"
          />
        )}
      </FormField>

      {['provision', 'folder'].includes(state.sub_type) && (
        <FormField
          data-testid="for-rule-form-field"
          label={t('for_rule')}
          name="reference_rule_name"
          width="medium"
          required
        >
          <Dropdown
            name="reference_rule_name"
            options={listOfRule}
            labelKey="rule_name"
            valueKey={{ key: 'rule_id', reduce: true }}
            value={state.reference_rule_id}
            onChange={({ option }) => {
              dispatch({
                value: option.rule_name,
                field: 'reference_rule_name',
                type: 'CHANGE_FIELD'
              })
              dispatch({
                value: option.rule_id,
                field: 'reference_rule_id',
                type: 'CHANGE_FIELD'
              })
              setListOfRule(defaultRuleOption)
            }}
            searchPlaceholder={t('search_by_rule')}
            emptySearchMessage={t('no_rules_found')}
            onSearch={(value) => {
              handleDebouncedSearchValue(value)
            }}
            testId="for-rule-input"
          />
        </FormField>
      )}

      <FormField
        data-testid="email-to-form-field"
        label={t('email_to')}
        name="value"
        width="medium"
      >
        <TextArea
          name="value"
          data-testid="email-to-input"
          value={state.value}
          onChange={(event) =>
            dispatch({
              value: event.target.value,
              field: 'value',
              type: 'CHANGE_FIELD'
            })
          }
        />
      </FormField>
    </Box>
  )
}

NotificationFormField.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  inCCSManager: PropTypes.bool
}

NotificationFormField.defaultProps = {
  customerId: undefined,
  inCCSManager: undefined
}

export { NotificationFormField }
