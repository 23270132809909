import React, {
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
  useEffect
} from 'react'
import { Box, CheckBox, FormField, Anchor } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import { CircleInformation, StatusWarningSmall } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useHistory } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  isMSPTenantCoP,
  isGLOP
} from '../../../../../../utils/feature-flag-utils'
import {
  Typography,
  CCSForm,
  FormInput,
  Notification
} from '../../../../../../components'
import { WizardContext } from '../../../../../../components/wizard/WizardContext'
import { GLP_ONBOARD_SUPPORT_URL } from '../../../../../../utils/error-utils'

import { buildIniDataFromOrder, getServiceFromOrder } from './showbackRate'

const AddServiceSubscriptionStep = forwardRef(({ ratesOrderData }, ref) => {
  const { t } = useTranslation(['device', 'common', 'licensing'])
  const [errorMessage, setErrorMessage] = useState('')

  const [subscriptionState, setSubscriptionState] = useState(null)
  const [checkboxErrorMessage, setCheckboxErrorMessage] = useState('')
  const [checkboxShowCount, setCheckboxShowCount] = useState(0)
  const [isWarningDisplayed, setIsWarningDisplayed] = useState(false)
  const { formValues, setFormValues } = useContext(WizardContext)
  const [checkboxValue, setCheckboxValue] = useState()
  const history = useHistory()
  const [showNotification, setNotification] = useState(false)
  const { 'glcp-cds-rate-interface': showbackRateFlag } = useFlags()

  useEffect(() => {
    // Compute check box error message.
    if (
      (subscriptionState === 'SHOW_DELAYED_ACTIVATION_WARNING' ||
        subscriptionState === 'SHOW_BOTH') &&
      !checkboxValue &&
      checkboxShowCount > 1
    ) {
      setCheckboxErrorMessage('Check checkbox to acknowledge the action')
    } else {
      setCheckboxErrorMessage('')
    }
  }, [subscriptionState, checkboxShowCount, checkboxValue])

  useEffect(() => {
    if (ratesOrderData && showbackRateFlag && !isMSPTenantCoP() && !isGLOP()) {
      const ratesDataFromOrder = buildIniDataFromOrder(
        ratesOrderData,
        formValues.subscriptionKey
      )
      setFormValues((prevValues) => ({
        ...prevValues,
        ...ratesDataFromOrder,
        ratesService: getServiceFromOrder(ratesOrderData),
        initialRates: null,
        hasNoExistedRates: true,
        isRatesStepAvailable: true,
        isLoadingRatesFailed: false
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratesOrderData])

  useImperativeHandle(ref, () => ({
    handleFormValidationError: (err) => {
      if (!err) {
        return null
      }
      switch (err.message) {
        case 'SUBSCRIPTION_KEY_CLAIMED':
          setSubscriptionState('SHOW_DUPLICATE_SUBSCRIPTION_WARNING')
          // If the warning is already displayed, continue to next step in the wizard.
          if (isWarningDisplayed) {
            return null
          }
          setIsWarningDisplayed(true)
          break
        case 'DELAYED_ACTIVATION':
          setSubscriptionState('SHOW_DELAYED_ACTIVATION_WARNING')
          setCheckboxShowCount(checkboxShowCount + 1)
          return formValues.understandDuplicateSubscriptionDisclaimer
            ? null
            : err
        case 'BOTH':
          setSubscriptionState('SHOW_BOTH')
          setCheckboxShowCount(checkboxShowCount + 1)
          return formValues.understandDuplicateSubscriptionDisclaimer
            ? null
            : err
        case 'CLAIMED_IN_CURRENT_WORKSPACE':
          setErrorMessage(t('licensing:service_sub_key_current_workspace'))
          break
        case 'CLAIMED_IN_ANOTHER_WORKSPACE':
          setErrorMessage(t('licensing:service_sub_key_another_workspace'))
          setNotification(true)
          break
        case 'INVALID_KEY':
          setErrorMessage(t('licensing:enter_valid_subscription_key'))
          break
        default:
          setSubscriptionState('OTHER_ERROR')
          setErrorMessage(err.message)
          break
      }
      return err
    }
  }))
  return (
    <Box>
      <Box
        width="medium"
        margin={{ bottom: subscriptionState ? 'xsmall' : 'medium' }}
      >
        <Box margin={{ top: 'medium', bottom: 'xsmall' }}>
          <Typography size="small" type="text" testId="subscription-key-text">
            {t('subscription_key')}
          </Typography>
        </Box>
        <Box>
          <FormInput
            id="subscription_key"
            value={formValues.subscriptionKey}
            testId="subscription-key-input-field"
            color="text-strong"
            placeholder={t('enter_subscription_key')}
            width="100%"
            error={errorMessage}
            onChange={(e) => {
              setErrorMessage('')
              setNotification(false)
              setCheckboxShowCount(0)
              setCheckboxValue(false)
              setFormValues({
                ...formValues,
                subscriptionKey: e.target.value,
                understandDuplicateSubscriptionDisclaimer: false
              })
            }}
          />
        </Box>
      </Box>
      {(subscriptionState === 'SHOW_DUPLICATE_SUBSCRIPTION_WARNING' ||
        subscriptionState === 'SHOW_BOTH') && (
        <Box>
          <Box pad="small" />
          <Box
            gap="small"
            direction="row"
            data-testid="show-service-deployment-warning"
            background="validation-warning"
            margin={{ bottom: 'medium' }}
            pad="xsmall"
          >
            <Box pad={{ top: 'small', left: 'xsmall' }}>
              <StatusWarningSmall size="small" color="status-warning" />
            </Box>
            <Box pad="xsmall">
              <Typography type="text" testId="service-deployment-anchor-label">
                <Trans i18nKey="duplicate_subscription_subtitle_wizard" t={t}>
                  <Anchor
                    label={t('switch_workspace')}
                    target="_blank"
                    data-testid="service-warning-anchor"
                    onClick={(e) => {
                      e.preventDefault()
                      history.push('/switch-account')
                    }}
                  />
                </Trans>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {(subscriptionState === 'SHOW_DELAYED_ACTIVATION_WARNING' ||
        subscriptionState === 'SHOW_BOTH') && (
        <Box pad={{ bottom: 'medium' }}>
          <Typography
            type="text"
            size="small"
            emphasis
            test-id="subscription-activation-title"
          >
            {t('subscription_activation_title')}
          </Typography>
          <Typography type="text" test-id="subscription-activation-subtitle">
            {t('subscription_activation_subtitle')}
          </Typography>
          <CCSForm errorMessage={checkboxErrorMessage} testId="checkbox-form">
            <FormField htmlFor="subscription_key" flex="grow">
              <Box direction="row" gap="small" align="center">
                <CheckBox
                  id="understand_duplicate_subscription_disclaimer"
                  value={checkboxValue}
                  margin={{ top: 'medium' }}
                  data-testid="understand-duplicate-subscription-disclaimer-input-field"
                  color="text-strong"
                  placeholder={t('checkbox_context')}
                  width="100%"
                  onChange={(e) => {
                    setErrorMessage('')
                    setFormValues({
                      ...formValues,
                      understandDuplicateSubscriptionDisclaimer:
                        e.target.checked
                    })
                    setCheckboxValue(e.target.checked)
                  }}
                />
                <span> {t('checkbox_context')} </span>
              </Box>
            </FormField>
          </CCSForm>
        </Box>
      )}

      {showNotification ? (
        <Box margin={{ bottom: 'medium' }}>
          <Notification
            type="inline"
            text={
              <Typography size="small" type="text" testId="notification_error">
                <Trans i18nKey="licensing:support_info" t={t}>
                  <Anchor
                    size="xsmall"
                    weight="bold"
                    href={GLP_ONBOARD_SUPPORT_URL}
                    target="_blank"
                    data-testid="support-anchor"
                  />
                </Trans>
              </Typography>
            }
            backgroundColor="status-critical"
            icon={<CircleInformation color="text-strong" />}
            testId="error-info"
          />
        </Box>
      ) : null}
    </Box>
  )
})

AddServiceSubscriptionStep.defaultProps = {
  ratesOrderData: null
}

AddServiceSubscriptionStep.propTypes = {
  ratesOrderData: PropTypes.object
}

export { AddServiceSubscriptionStep }
