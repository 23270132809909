import React, { useState, useEffect, useMemo } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import {
  ModalDialog,
  ModalHeader,
  Typography,
  CCSAccordion,
  Loader
} from '../../../components'
import { getErrorMessage } from '../../../utils/api-utils'

import FeatureFlagsRulesDisplay from './FeatureFlagsRulesDisplay'
import FeatureFlagsVariationsDisplay from './FeatureFlagsVariationsDisplay'
import { ldFlagsStatusLang, ldFlagsEnvs, getLDDetailsPerFlag } from './utils'

const FeatureFlagsStatusDetails = ({
  flag,
  rowData,
  onClose,
  setErrorMessage,
  envDisplayNames
}) => {
  const { t } = useTranslation(['manage', 'common'])
  const [isLoading, setIsLoading] = useState(false)
  const [flagDetails, setFlagDetails] = useState()
  const isMultivariate = useMemo(() => {
    return flagDetails?.kind === 'multivariate'
  }, [flagDetails])

  const variations = useMemo(() => {
    if (flagDetails) {
      return flagDetails.variations
    }
    return []
  }, [flagDetails])

  const fetchLDFlagDetails = () => {
    setIsLoading(true)
    getLDDetailsPerFlag(flag)
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          setFlagDetails(res)
          setErrorMessage(null)
        }
      })
      .catch((error) => {
        setFlagDetails({})
        setErrorMessage(getErrorMessage(error, t))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchLDFlagDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderSingleEnvDetails = (targetEnv, dataSet) => {
    const lastModified = dayjs(dataSet.lastModified).format(
      'YYYY-MM-DD hh:mm:ss'
    )
    return (
      <Box
        direction="column"
        gap="small"
        margin={{ bottom: 'medium', top: 'small' }}
        pad={{ bottom: '0px', left: 'medium' }}
      >
        <Box direction="row" gap="small" style={{ alignItems: 'center' }}>
          <Typography
            testId={`${flag}-details-${ldFlagsEnvs[targetEnv]}-lastModify-label`}
            type="heading"
            level="3"
          >
            {ldFlagsStatusLang.details_last_modified}
          </Typography>
          <Typography
            testId={`${flag}-details-${ldFlagsEnvs[targetEnv]}-lastModify-content`}
            type="text"
            wordBreak="break-word"
          >
            {lastModified}
          </Typography>
        </Box>
        <Box>
          {flagDetails?.environments[targetEnv]?.rules?.length > 0 && (
            <Box>
              <Typography
                type="heading"
                level="3"
                testId="LD-status-details-rules-title"
              >
                Rules:
              </Typography>
            </Box>
          )}
          {flagDetails && (
            <FeatureFlagsRulesDisplay
              rules={flagDetails?.environments[targetEnv]?.rules}
            />
          )}
        </Box>
      </Box>
    )
  }

  return (
    <ModalDialog
      content={
        isLoading ? (
          <Box
            direction="row"
            align="center"
            justify="center"
            height={{ min: 'large' }}
          >
            <Loader testId="ld-status-details-loader" />
          </Box>
        ) : (
          <Box margin={{ top: 'xsmall', right: 'xsmall' }} direction="column">
            <Box direction="column" gap="xsmall">
              <Typography
                testId="LD-status-details-variations-title"
                type="heading"
                level="3"
              >
                {ldFlagsStatusLang.details_variations}
              </Typography>
            </Box>
            <Box margin={{ top: 'small' }}>
              <FeatureFlagsVariationsDisplay
                isMultivariate={isMultivariate}
                variations={variations}
              />
            </Box>
            <Box>
              <CCSAccordion
                data-testid="LD-status-details-accordion"
                testId="LD-status-details-accordion"
                data={Object.keys(ldFlagsEnvs)}
                customRender={(item) => {
                  return renderSingleEnvDetails(item, rowData[item])
                }}
                icon={() => {
                  return null
                }}
                label={(item) => {
                  return envDisplayNames[item]
                }}
              />
            </Box>
          </Box>
        )
      }
      header={
        <ModalHeader
          title={
            <Typography
              testId="LD-status-details-header-title"
              type="heading"
              level="2"
            >
              {flag}
            </Typography>
          }
          subtitle={flagDetails?.description}
          onClose={() => {
            onClose()
          }}
        />
      }
      height="100%"
      width="large"
      onClose={() => {
        onClose()
      }}
      position="right"
      testId="ld-flags-details-panel"
    />
  )
}

export default FeatureFlagsStatusDetails

FeatureFlagsStatusDetails.propTypes = {
  flag: PropTypes.string,
  rowData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  envDisplayNames: PropTypes.object.isRequired
}

FeatureFlagsStatusDetails.defaultProps = {
  flag: ''
}
