import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NameValueList, NameValuePair } from 'grommet'
import dayjs from 'dayjs'

import { SectionTitle } from './SectionTitle'

const ValidationSection = ({
  validated_at,
  validated_by,
  validation_cycle
}) => {
  const { t } = useTranslation(['location'])

  return (
    <>
      <SectionTitle title={t('validation_section_title')} />
      <NameValueList valueProps={{ width: ['auto', 'large'] }}>
        <NameValuePair name={t('validation_cycle_label')}>
          {t('validation_cycle_value', {
            validation_cycle
          })}
        </NameValuePair>
        <NameValuePair name={t('last_validated_description_label')}>
          {t('last_validated_description', {
            validated_at: dayjs(validated_at).format('MM/DD/YYYY'),
            validated_by
          })}
        </NameValuePair>
      </NameValueList>
    </>
  )
}

ValidationSection.propTypes = {
  validated_at: PropTypes.string,
  validated_by: PropTypes.string,
  validation_cycle: PropTypes.string
}

ValidationSection.defaultProps = {
  validated_at: null,
  validated_by: null,
  validation_cycle: ''
}

export { ValidationSection }
