const ChatActions = {
  UPDATE_CHAT: 'UPDATE_CHAT',
  OPEN_CHAT: 'OPEN_CHAT',
  CLEAR_CHAT: 'CLEAR_CHAT',
  ERROR_CONNECTING_CHAT: 'ERROR_CONNECTING_CHAT',
  REMOVE_LAST_MESSAGE: 'REMOVE_LAST_MESSAGE',
  UPDATE_SESSION_ID: 'UPDATE_SESSION_ID',
  UPDATE_CURRENT_POLL: 'UPDATE_CURRENT_POLL',
  SET_STATE: 'SET_STATE',
  CLEAR_STATE: 'CLEAR_STATE'
}

export default ChatActions
