import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Layer, Anchor } from 'grommet'
import PropTypes from 'prop-types'

export const PopUpImage = ({ url, callBack }) => {
  const { t } = useTranslation(['apps'])
  return (
    <Layer data-testid="app-detail-image-layer" onEsc={() => callBack('')}>
      <Box
        width={{ min: 'medium', max: 'xlarge' }}
        height={{ min: 'medium', max: 'large' }}
        background="background-front"
        overflow="hidden"
        margin={{
          horizontal: 'large',
          top: 'large',
          bottom: 'medium'
        }}
        data-testid="detail-popup-image"
      >
        <Box align="end" fill>
          <Anchor
            size="large"
            data-testid="detail-popup-close"
            onClick={() => callBack('')}
          >
            {t('close')}
          </Anchor>
        </Box>
        <Box
          margin={{ top: 'small' }}
          align="start"
          background={{ size: 'contain', image: `url(${url})` }}
          height="large"
          width="xlarge"
        />
      </Box>
    </Layer>
  )
}

PopUpImage.propTypes = {
  url: PropTypes.string,
  callBack: PropTypes.func.isRequired
}
PopUpImage.defaultProps = {
  url: ''
}
