import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { Box } from 'grommet'

import {
  ModalDialog,
  ModalHeader,
  ModalFooter,
  Typography,
  Button
} from '../../../components'

const CreationErrorModal = ({ setShowModal, nameError, gatewayName }) => {
  const { t } = useTranslation(['local-gateway', 'common'])

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Box margin={{ bottom: 'small' }}>
              <Typography
                type="heading"
                level="1"
                testId="creation-error-modal-title"
              >
                <Trans i18nKey="api_messages.creation_error_modal_title" t={t}>
                  {gatewayName}
                </Trans>
              </Typography>
            </Box>
          }
        />
      }
      content={
        <Typography type="text" size="large" testId="error-description">
          {t(
            `api_messages.${
              nameError
                ? 'gateway_creation_name_collision_error'
                : 'gateway_creation_generic_error'
            }`
          )}
        </Typography>
      }
      footer={
        <ModalFooter
          right={
            <Button
              label={t('common:close')}
              primary
              testId="creation-error-close-btn"
              onClick={() => setShowModal(false)}
            />
          }
        />
      }
      testId="creation-error-modal"
      width="large"
    />
  )
}
CreationErrorModal.defaultProps = {
  nameError: undefined
}

CreationErrorModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  gatewayName: PropTypes.string.isRequired,
  nameError: PropTypes.bool
}

export default CreationErrorModal
