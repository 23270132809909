import React, { useEffect, useState } from 'react'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { useBodyScroll } from '../../../utils/useBodyScroll'
import { useEventsRequest } from '../../utils/useEventsRequest'
import DetailsPanel from '../detailsPanel/DetailsPanel'
import DataError from '../errorPages/DataError'
import { put } from '../../../../utils/api-utils'

import CheckAll from './CheckAll'
import PaginatedList from './PaginatedList'
import SearchBox from './SearchBox'

const EventList = () => {
  const [itemsPerPage, setItemsPerPageState] = useState(10)
  const [page, setPage] = useState(1)
  const [eventForDetailsState, setEventForDetailsState] = useState()
  // we use an array of functions here because function as state has some undesirable behaviors and
  // it makes combining this with other filters easier
  const [search, setSearchState] = useState('')
  const [filters, setFiltersState] = useState([])
  const [tab, setTabState] = useState('inbox')
  const [checked, setChecked] = useState(new Set())
  const [pending, setPending] = useState(false)
  const [casesOpened, setCasesOpened] = useState(new Set())
  const { oidcUser } = useReactOidc()
  const swrOptions = {
    refreshInterval: pending ? 2000 : 0,
    revalidateIfStale: false
  }
  const {
    data,
    error,
    mutate: mutateSwr,
    clearCache
  } = useEventsRequest({
    page,
    limit: itemsPerPage,
    filters,
    tab,
    search,
    swrOptions
  })
  const events = data?.events || []
  const loading = typeof data?.events === 'undefined'
  // We default to itemsPerPage + 1 so that the pagination component is always visible when we are loading a new page
  const total = data?.total
  const mutateEvents = (apiCall, mutatedEvents) =>
    mutateSwr(apiCall, {
      optimisticData: data
        ? {
            ...data,
            events: mutatedEvents
          }
        : undefined,
      rollbackOnError: true,
      populateCache: false
    })
  // we try to find the event in the current data so we can have the freshest version of the event
  const eventForDetails = eventForDetailsState
    ? events.find((i) => i.uuid === eventForDetailsState.uuid) ||
      eventForDetailsState
    : eventForDetailsState
  useBodyScroll(!eventForDetails)
  // We have to use useState and useEffect for setting polling because we need the event data in order to change the call to the event data.
  // The logic is circular and therefore cannot be done simply inline.
  const detailsCaseState = eventForDetails?.status?.currentState
  useEffect(() => {
    if (
      !pending &&
      (detailsCaseState === 'pendingCaseCreation' ||
        detailsCaseState === 'pendingEscalation')
    ) {
      setPending(true)
    }
    if (
      pending &&
      !(
        detailsCaseState === 'pendingCaseCreation' ||
        detailsCaseState === 'pendingEscalation'
      )
    ) {
      setPending(false)
    }
  }, [detailsCaseState, pending, setPending])
  // we need to know if we opened the case this session so that we can display success state
  const caseOpened =
    !!eventForDetailsState && casesOpened.has(eventForDetailsState.uuid)
  const trackCaseOpened = () => {
    if (eventForDetailsState) {
      const updatedCasesOpened = new Set(casesOpened)
      updatedCasesOpened.add(eventForDetailsState.uuid)
      setCasesOpened(updatedCasesOpened)
    }
  }
  // We need to reset the page when setting any filters otherwise we can end up in a state
  // where we are on a page that does not exist.
  // Types are the same type as setState above
  const setSearch = (searchValue) => {
    setPage(1)
    setSearchState(searchValue)
  }
  const setFilters = (filtersValue) => {
    setPage(1)
    setFiltersState(filtersValue)
  }
  const setTab = (tabValue) => {
    setPage(1)
    setTabState(tabValue)
  }
  const setEventForDetails = (event) => {
    setEventForDetailsState(event)
    if (event && !event.read) {
      // We need to clear the swr cache any time we change user data to prevent stale data being shown
      clearCache()
      mutateEvents(
        put(
          `/wellness/v2beta1/events/${event.uuid}/tags`,
          {
            tags: {
              read: true
            }
          },
          oidcUser.access_token
        ).then(({ data: putData }) => putData),
        events.map((e) => (e.uuid === event.uuid ? { ...e, read: true } : e))
      )
    }
  }
  // convert mutateEvents into a more useful function which just mutates a single event
  const mutateEvent = (apiCall, mutatedEvent) => {
    // If this is being called from an event that has been hidden from the list view,
    // we need to make sure we update the state of the event details.
    if (
      eventForDetailsState &&
      mutatedEvent.uuid === eventForDetailsState.uuid
    ) {
      setEventForDetailsState(mutatedEvent)
    }
    return mutateEvents(
      apiCall,
      events.map((event) =>
        event.uuid === mutatedEvent.uuid ? mutatedEvent : event
      )
    )
  }
  // This function let's you mutate some subset of events (in our case this subset is always the displayList),
  // without deleting the other events from the list
  const mutateSomeEvents = (apiCall, subsetEvents) => {
    const updatedEvents = events.map(
      (event) =>
        subsetEvents.find((subsetEvent) => subsetEvent.uuid === event.uuid) ||
        event
    )
    mutateEvents(apiCall, updatedEvents)
  }
  const setItemsPerPage = (i) => {
    setItemsPerPageState(i)
    setPage(1)
  }
  const checkedCount = events.filter((event) => checked.has(event.uuid)).length
  return (
    <>
      <SearchBox
        setSearch={setSearch}
        filterCount={total}
        checkedCount={checkedCount}
        setFilters={setFilters}
      />
      <CheckAll
        checked={checked}
        setChecked={setChecked}
        events={events}
        mutateEvents={mutateSomeEvents}
        clearCache={clearCache}
      />
      {error ? (
        <DataError />
      ) : (
        <PaginatedList
          events={events}
          mutateEvent={mutateEvent}
          clearCache={clearCache}
          checked={checked}
          setChecked={setChecked}
          setEventForDetails={setEventForDetails}
          tab={tab}
          setTab={setTab}
          search={search}
          page={page}
          setPage={setPage}
          itemsPerPage={itemsPerPage}
          total={total}
          setItemsPerPage={setItemsPerPage}
          loading={loading}
        />
      )}
      {eventForDetails && (
        <DetailsPanel
          event={eventForDetails}
          mutateEvent={mutateEvent}
          clearCache={clearCache}
          closePanel={() => setEventForDetails(undefined)}
          caseOpened={caseOpened}
          trackCaseOpened={trackCaseOpened}
        />
      )}
    </>
  )
}
export default EventList
