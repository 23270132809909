import React, { useEffect, useState } from 'react'
import { Box } from 'grommet'
import { Previous, StatusCritical } from 'grommet-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button, Notification, Typography } from '../../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { get, getErrorMessage } from '../../../../utils/api-utils'
import { getRegionsForApp } from '../../../commoncomponents/notifications-dashboard/utils'
import { getStatusList } from '../../../ccs-manager/pages/notifications/utils'

import AppPreference from './AppPreference'

const PreferenceContent = () => {
  const { t } = useTranslation(['manage', 'common'])
  const history = useHistory()
  const [regions, setRegions] = useState([])
  const [apps, setApps] = useState([])
  const [triggerPreferenceRequest, setTriggerPreferenceRequest] =
    useState(false)
  const [preferenceAPIError, setPreferenceAPIError] = useState(null)
  const [inErrorState, setInErrorState] = useState(false)

  const { oidcUser } = useReactOidc()

  const refreshPreferences = () => {
    setTriggerPreferenceRequest(!triggerPreferenceRequest)
  }

  const fetchRegions = () => {
    get(
      `/notifications-svc/ui/v1alpha1/user-associated-regions`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        setRegions(response?.data)
        setInErrorState(false)
      },
      (error) => {
        setPreferenceAPIError(getErrorMessage(error, t))
        setInErrorState(true)
      }
    )
  }

  const fetchPreferences = () => {
    get(
      `/notifications-svc/ui/v1alpha1/preferences`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        setApps(response?.data)
      },
      (error) => {
        setPreferenceAPIError(getErrorMessage(error, t))
      }
    )
  }

  useEffect(() => {
    fetchPreferences()
    fetchRegions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerPreferenceRequest])

  const handleBackBtnClick = () => {
    history.goBack()
  }
  return (
    <Box data-testid="notification-preferences" justify="center">
      <Box direction="row" justify="start" pad="small">
        <Button
          default
          margin={{ vertical: 'xsmall', horizontal: 'small' }}
          label={t('notification.notification_tab')}
          icon={<Previous />}
          onClick={handleBackBtnClick}
          testId="notification-back-btn"
        />
      </Box>
      <Box
        pad={{ horizontal: 'xlarge', top: 'medium', right: 'xsmall' }}
        width="large"
        justify="between"
        align="start"
        alignSelf="center"
      >
        <Box pad={{ bottom: 'small', top: 'small' }}>
          <Typography
            level="2"
            type="heading"
            testId="notification-preferences-page-title"
          >
            {t('notification.preference_label')}
          </Typography>
          <Typography
            size="small"
            type="text"
            testId="notification-preferences-description"
          >
            {t('notification.preferences.description')}
          </Typography>
        </Box>
        {apps.map((item) => {
          return (
            <AppPreference
              appName={item.app_name}
              key={`key-${item.app_name}`}
              preferredRegions={item?.email?.region}
              preferredStatus={getStatusList(item?.email?.status)}
              allRegionsForApp={getRegionsForApp(item.app_name, regions)}
              onEditSuccess={refreshPreferences}
              inErrorState={inErrorState}
              setPreferenceAPIError={setPreferenceAPIError}
            />
          )
        })}
      </Box>
      {preferenceAPIError && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setPreferenceAPIError(null)}
          testId="critical-inline-notification"
          text={preferenceAPIError}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}

const Preferences = () => {
  return (
    <Layout>
      <PreferenceContent align="center" justify="center" />
    </Layout>
  )
}

export default Preferences
