import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from 'grommet'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import { get } from '../../../../../utils/api-utils'
import { displayApiError } from '../../../../../utils/error-handling-utils'

import CustomerAccountDetailsSection from './CustomerAccountDetailsSection'

const ViewDetails = ({ customerAccountDetails }) => {
  const [listOfCountries, setListOfCountries] = useState([])
  const { t } = useTranslation(['apps'])
  const [showErrorNotification, setShowErrorNotification] = useState(null)

  useEffect(() => {
    get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      (response) => {
        setListOfCountries(response.data.countries)
      },
      (error) => {
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
  }, [t])

  const getCountryName = (code) => {
    if (listOfCountries.length !== 0) {
      const countriesData = [...listOfCountries]
      const filteredCountries = countriesData.filter(
        (country) => country.code === code
      )
      return filteredCountries.length > 0 ? filteredCountries[0].name : ''
    }
    return ''
  }

  const {
    streetAddress,
    city,
    state,
    companyName,
    countryCode,
    description,
    zip,
    email,
    phone,
    createdAt,
    updatedAt,
    logoFilename
  } = customerAccountDetails

  let citystate = null
  if (city && state) citystate = `${city}, ${state}`
  else if (city) citystate = `${city}`
  else citystate = `${state}`

  const customerAcntDetails = {
    acntDetails: {
      account_logo: logoFilename,
      company_name_label: companyName,
      description
    },
    addressDetails: {
      country_label: getCountryName(countryCode),
      address: citystate
        ? `${streetAddress}, ${citystate}`
        : `${streetAddress}`,
      zip_code: zip
    },
    contactDetails: {
      email,
      phone_number: phone,
      created: dayjs(createdAt).format('MMMM D, YYYY'),
      last_updated: dayjs(updatedAt).format('MMMM D, YYYY')
      // Change it to the following when BE is ready
      // created: dayjs(new Date(createdAt * 1000)).format('MMMM D, YYYY'),
      // last_updated: dayjs(new Date(updatedAt * 1000)).format('MMMM D, YYYY')
    }
  }
  return (
    <>
      {showErrorNotification}
      {customerAccountDetails && (
        <Box data-testid="customer-account-details">
          <Box direction="row-responsive" pad={{ top: 'medium' }}>
            <Grid
              rows={['full']}
              direction="row-responsive"
              height={{ min: 'medium', max: 'large' }}
              fill
              columns={{
                count: 3,
                size: 'auto'
              }}
            >
              <Box direction="column" margin={{ right: 'large' }}>
                <CustomerAccountDetailsSection
                  data={customerAcntDetails.acntDetails}
                />
              </Box>
              <Box direction="column" margin={{ right: 'large' }}>
                <CustomerAccountDetailsSection
                  data={customerAcntDetails.addressDetails}
                />
              </Box>
              <Box direction="column" margin={{ right: 'large' }}>
                <CustomerAccountDetailsSection
                  data={customerAcntDetails.contactDetails}
                />
              </Box>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  )
}

ViewDetails.propTypes = {
  customerAccountDetails: PropTypes.object.isRequired
}

export default ViewDetails
