import React, { useEffect, useState } from 'react'
import { Box, Markdown, Notification } from 'grommet'
import PropTypes from 'prop-types'
import { ChatOption, ShareRounded } from 'grommet-icons'

import { Typography } from '../../components'

import ChatBotButtonsContainer from './ChatBotButtonsContainer'
import CardCarousel from './CardCarousel'

export const MessageLoader = () => {
  return (
    <Box
      background="background-front"
      pad={{ horizontal: 'medium', vertical: 'small' }}
      round="8px"
      width="xsmall"
      height="xxsmall"
      margin={{ bottom: 'xsmall' }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" data-testid="message-loader-svg">
        <circle cx="6" cy="6" r="6" fill="#01A982">
          <animate
            attributeName="cy"
            from="15"
            to="5"
            dur="1.4s"
            begin="0s"
            values="15;5;15;15"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            from="0.5"
            to="1"
            dur="1.4s"
            begin="0s"
            values="0.2;1;0.2;0.2"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="22" cy="6" r="6" fill="#01A982">
          <animate
            attributeName="cy"
            from="15"
            to="5"
            dur="1.4s"
            begin="-1.1s"
            values="15;5;15;15"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            from="0.5"
            to="1"
            dur="1.4s"
            begin="-1.1s"
            values="0.5;1;0.5;0.5"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="38" cy="6" r="6" fill="#01A982">
          <animate
            attributeName="cy"
            from="15"
            to="5"
            dur="1.4s"
            begin="-0.9s"
            values="15;5;15;15"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            from="0.5"
            to="1"
            dur="1.4s"
            begin="-0.9s"
            values="0.5;1;0.5;0.5"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </Box>
  )
}

const components = {
  ul: ({ children }) => (
    <ul
      data-testid="chatUlElement"
      style={{
        listStyle: 'initial',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '24px',
        wordWrap: 'break-word'
      }}
    >
      {children}
    </ul>
  ),
  ol: ({ children }) => (
    <ol
      data-testid="chatOlElement"
      style={{
        fontSize: '18px',
        paddingLeft: '15px',
        fontWeight: 400,
        lineHeight: '24px',
        wordWrap: 'break-word'
      }}
    >
      {children}
    </ol>
  ),

  strong: ({ children }) => (
    <strong data-testid="chatStrongElement" style={{ fontWeight: 550 }}>
      {children}
    </strong>
  ),
  button: () => (
    <ShareRounded
      style={{ marginBottom: '-4px' }}
      data-testid="shareRoundedIcon"
    />
  )
}

export const messageContainsCarousel = (chatResponse) => {
  if (chatResponse?.messages !== undefined) {
    if (
      chatResponse?.messages?.filter((b) => {
        return b.type === 'carousel'
      })?.length > 0
    )
      return true
  }
  return false
}

export const messageContainsText = (chatResponse) => {
  if (chatResponse?.messages !== undefined) {
    if (
      chatResponse?.messages?.filter((b) => {
        return b.type === 'text'
      })?.length > 0
    )
      return true
  }
  return false
}

export const messageContainsButton = (chatResponse) => {
  if (chatResponse?.messages !== undefined) {
    if (
      chatResponse?.messages?.filter((b) => {
        return b.type === 'button' || b.type === 'button_link'
      })?.length > 0
    )
      return true
  }
  return false
}

export const messageContainsProgressIndicator = (chatResponse) => {
  const messageWithProgressIndicator =
    chatResponse?.messages !== undefined
      ? chatResponse?.messages?.some(
          (message) => message?.type === 'message_loader'
        )
      : false
  return messageWithProgressIndicator
}

export const messageContainsYesButton = (chatResponse) => {
  const includeYesButton = chatResponse?.messages?.some(
    (message) => message?.type === 'button' && message?.value === 'Yes'
  )
  return includeYesButton
}

export const ChatMessageFromBotDisplayText = ({
  text,
  includesThumbsUpButton,
  index,
  textMessage
}) => {
  return (
    <Box
      background="background-front"
      pad={{ horizontal: 'medium', vertical: 'small' }}
      width={
        messageContainsButton &&
        includesThumbsUpButton &&
        index === textMessage?.length - 1
          ? { max: 'fit-content', min: '20rem' }
          : { max: 'fit-content', min: '14rem' }
      }
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems:
          messageContainsButton &&
          includesThumbsUpButton &&
          index === textMessage?.length - 1
            ? 'center'
            : 'start',
        align: 'start',
        gap: '12px',
        alignSelf:
          messageContainsButton &&
          includesThumbsUpButton &&
          index === textMessage?.length - 1
            ? 'center'
            : 'start'
      }}
      round="8px"
      margin={{ bottom: 'xsmall' }}
    >
      <Typography
        type="text"
        size="medium"
        weight="normal"
        data-testid="chatTypographyElement"
      >
        <Markdown
          components={components}
          data-testid="chatbot-message-from-bot"
          options={{ forceInline: true }}
        >
          {text?.value}
        </Markdown>
      </Typography>
    </Box>
  )
}

export const ChatMessageFromBotTriggerWarning = ({ txt }) => {
  return (
    <Box
      flex
      direction="row"
      align="start"
      round="6px"
      margin={{ bottom: 'xsmall' }}
      data-testid="chat-message-from-bot-trigger-warning"
    >
      <Notification
        status="warning"
        message={
          <Typography
            type="text"
            size="small"
            alignSelf="start"
            style={{ lineHeight: '25px' }}
          >
            {txt.replace(
              '$&#&triggerwarning',
              'Navigating away from this page will close and reset the Virtual Assistant.'
            )}
          </Typography>
        }
        size="small"
        style={{ paddingTop: '0px' }}
      />
    </Box>
  )
}

const ChatMessageFromBot = ({ chatResponse, client }) => {
  const formattedTime = chatResponse.timestamp

  const [carouselMessage] = useState(
    chatResponse?.messages?.filter((message) => {
      return message?.type === 'carousel'
    })
  )

  const [textMessage] = useState(
    chatResponse?.messages?.filter((message) => {
      return message?.type === 'text'
    })
  )

  const [buttonsMessage] = useState(
    chatResponse?.messages?.filter((message) =>
      ['button', 'button_link'].includes(message?.type)
    )
  )

  const includesThumbsUpButton = chatResponse?.messages
    ?.filter((b) => {
      return b.type === 'button'
    })
    ?.map((b) => b.value)
    .includes('ThumbsUp')

  const includesThumbsUpOrThunmpsDownButton = chatResponse?.messages?.some(
    (message) =>
      message?.type === 'button' &&
      ['ThumbsUp', 'ThumbsDown'].includes(message?.text)
  )

  useEffect(() => {
    messageContainsProgressIndicator(chatResponse)
  }, [chatResponse])

  return (
    chatResponse?.messages?.length > 0 && (
      <>
        <Box
          width="100%"
          direction="column"
          align="start"
          data-testid="chat3"
          flex="grow"
        >
          <Box
            width="100%"
            direction="row"
            alignSelf="start"
            align="start"
            gap="6px"
            flex="grow"
            pad={{ top: '0', right: '36px', bottom: '0', left: '0' }}
          >
            <Box fill="vertical" justify="end" width="31px">
              <Box
                width="1.75rem"
                height="1.75rem"
                round="full"
                background="brand"
                align="center"
                justify="center"
                margin={{ bottom: '5px' }}
              >
                <ChatOption color="white" size="small" />
              </Box>
            </Box>
            <Box style={{ width: 'fit-content' }}>
              {messageContainsCarousel && (
                <Box
                  pad="small"
                  width="fit-content"
                  style={{ minWidth: '8rem' }}
                  round="8px"
                >
                  {carouselMessage !== undefined &&
                    carouselMessage?.length > 0 && (
                      <CardCarousel carousel={carouselMessage[0].value} />
                    )}
                </Box>
              )}
              {messageContainsText &&
                textMessage?.map((text, index) =>
                  index === 0 && text?.value?.includes('$%#&triggerwarning') ? (
                    <ChatMessageFromBotTriggerWarning txt={text?.value} />
                  ) : (
                    <ChatMessageFromBotDisplayText
                      text={text}
                      includesThumbsUpButton={includesThumbsUpButton}
                      textMessage={textMessage}
                      index={index}
                    />
                  )
                )}
              {messageContainsProgressIndicator(chatResponse) && (
                <MessageLoader />
              )}
            </Box>
          </Box>

          {includesThumbsUpButton && (
            <Box
              background="background-front"
              pad={{ horizontal: 'medium', vertical: 'small' }}
              width={{ max: 'fit-content', min: '100%' }}
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
              round="8px"
              margin={{ bottom: 'xsmall' }}
            >
              {includesThumbsUpButton && (
                <Typography
                  type="text"
                  size="18px"
                  weight="normal"
                  margin={{ left: 'auto', right: 'auto' }}
                >
                  <Markdown
                    data-testid="chatbot-message-from-bot"
                    components={components}
                    options={{ forceInline: true }}
                  >
                    {textMessage[textMessage?.length - 1].value}
                  </Markdown>
                </Typography>
              )}
              {includesThumbsUpButton && (
                <Box margin={{ top: '25px', bottom: '0px' }} align="center">
                  <ChatBotButtonsContainer
                    buttons={['ThumbsUp', 'ThumbsDown']}
                    client={client}
                    includesThumbsUpButton
                  />
                </Box>
              )}
            </Box>
          )}
          <Box
            width="auto"
            direction="row"
            justify="flex-start"
            align="center"
            gap="xxsmall"
            pad={{ top: '0', right: '0', bottom: 'small', left: '36px' }}
          >
            <Typography type="text" size="xsmall" color="text-weak">
              {chatResponse?.from}
            </Typography>
            <Typography type="text" size="xsmall" color="text-weak">
              {formattedTime}
            </Typography>
          </Box>
        </Box>

        {messageContainsButton && (
          <Box
            data-testid="chatmessage-button-container"
            width={{ max: '100%' }}
            direction="column"
            pad={{ top: '0', right: '12px', bottom: '0', left: '36px' }}
            align="end"
            alignSelf="end"
            gap="small"
            flex="grow"
            margin={{ bottom: 'small' }}
          >
            {!includesThumbsUpOrThunmpsDownButton && (
              <ChatBotButtonsContainer
                buttons={buttonsMessage}
                messageId={chatResponse?.id}
                client={client}
              />
            )}
          </Box>
        )}
      </>
    )
  )
}

export default ChatMessageFromBot

ChatMessageFromBot.propTypes = {
  chatResponse: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
}

ChatMessageFromBotTriggerWarning.propTypes = {
  txt: PropTypes.object.isRequired
}

ChatMessageFromBotDisplayText.propTypes = {
  text: PropTypes.object.isRequired,
  includesThumbsUpButton: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  textMessage: PropTypes.array.isRequired
}
