export const getStepNumber = (stepID, steps) => {
  const stepIndex = steps.findIndex((step) => step.id === stepID) + 1
  return stepIndex
}

export const getFormattedPartyDetails = (data, i18n) => {
  const keys = [
    'partyId',
    'name',
    'houseNumber',
    'streetName',
    'cityName',
    'state',
    'postalCode',
    'country'
  ]
  const formattedData = {}
  keys.forEach((key) => {
    const newKey = i18n(`billing_accounts.create_billing_account_wizard.${key}`)
    formattedData[newKey] = data[key]
  })

  return formattedData
}

export const getFormattedContactDetails = (data, i18n) => {
  const keys = [
    'firstName',
    'lastName',
    'houseNumber',
    'streetName',
    'cityName',
    'state',
    'postalCode',
    'country',
    'emailAddress',
    'phoneNumber'
  ]
  const formattedData = {}
  keys.forEach((key) => {
    const newKey = i18n(`billing_accounts.create_billing_account_wizard.${key}`)
    formattedData[newKey] = data[key]
  })

  return formattedData
}
