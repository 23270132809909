import { Box, Heading, Text } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { StatusGood } from 'grommet-icons'

import { Typography, Anchor } from '../../../../components'

import FileUpload from './FileUpload'

const CaseConfirmation = ({ caseData }) => {
  const caseNumber = caseData?.caseNumber
  const { t } = useTranslation(['support_cases', 'support_hub'])
  return (
    <Box gap="medium" align="center" margin={{ bottom: 'large' }}>
      <Box align="center">
        <StatusGood
          color="brand"
          size="xlarge"
          data-testid="case-confirmation-good-status"
        />
      </Box>
      <Box gap="large" align="center">
        <Box align="center">
          <Heading margin="none" level={1}>
            {t('support_cases:create_case.case_successfully_created')}
          </Heading>
        </Box>
        <Box align="center">
          <Typography type="text" weight={500} data-testid="case-number">
            {t('support_cases:common.case_number')}: {caseNumber}
          </Typography>
          <FileUpload caseNumber={caseNumber} />
        </Box>
        <Box align="center" gap="medium">
          <Text data-testid="case-confirmation-note">
            {t('support_cases:create_case.case_created_confirmation_msg')}
            <br />
            <b>{t('support_cases:create_case.case_created_note')}</b>
          </Text>
        </Box>
      </Box>
      <Box align="center" gap="medium">
        <Typography type="text" data-testid="case-confirmation-help-msg">
          <Trans
            i18nKey="support_cases:create_case.case_confirmation_help_msg"
            t={t}
          >
            <Anchor
              href="/support"
              testId="case_confirm_support"
              label={t('support_hub:support_title')}
            />
            <Anchor
              href="/support/feedback"
              testId="case_confirm_feedback"
              label={t('support_cases:create_case.help_msg_feedback')}
            />
          </Trans>
        </Typography>
      </Box>
    </Box>
  )
}

CaseConfirmation.propTypes = {
  caseData: PropTypes.object.isRequired
}

export default CaseConfirmation
