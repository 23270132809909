import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../components'
import { useVisibilityContext } from '../../../../context/visibility-context'
import VisibilityWrapper from '../../visibility-wrapper/VisibilityWrapper'

const AccessDeniedModal = ({ onSetOpen }) => {
  const { t } = useTranslation(['licensing'])
  const history = useHistory()
  const { hideWhen } = useVisibilityContext()

  const onClose = () => {
    onSetOpen(false)
  }

  return (
    <ModalDialog
      testId="access-denied-modal"
      width="medium"
      onClose={onClose}
      header={
        <ModalHeader
          title={
            <Typography
              level="1"
              testId="access-denied-title"
              type="heading"
              weight="bold"
            >
              {t('access_denied')}
            </Typography>
          }
          subtitle={
            hideWhen?.account === 'TAC'
              ? t('access_denied_tac_desc')
              : t('access_denied_desc')
          }
          onClose={onClose}
        />
      }
      footer={
        <VisibilityWrapper
          hideFor={{ account: 'TAC' }}
          rbac={{
            resource: '/ccs/authorization',
            permission: 'ccs.authorization.view'
          }}
        >
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    id: 1,
                    label: t('add_products.view_roles_and_permissions'),
                    primary: true,
                    testId: 'view-roles-and-permissions-btn',
                    onClick: () => {
                      history.push('/manage-account/identity/roles')
                    }
                  }
                ]}
                testId="access-denied-modal-action"
              />
            }
          />
        </VisibilityWrapper>
      }
    />
  )
}

AccessDeniedModal.propTypes = {
  onSetOpen: PropTypes.bool.isRequired
}

export { AccessDeniedModal }
