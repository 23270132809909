import React from 'react'
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom'

import { checkGotoChooseAccount } from '../../../utils/common-utils'

import AddSsoProfile from './components/add-sso-profile/AddSsoProfileWizard'
import ViewSsoProfile from './components/ViewSsoProfile'
import SsoProfilesPage from './SsoProfilesPage'

const SsoProfilesRouter = () => {
  const { path } = useRouteMatch()

  const history = useHistory()
  checkGotoChooseAccount(history)

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <SsoProfilesPage />
      </Route>
      <Route exact path={`${path}/add-sso-profile`}>
        <AddSsoProfile />
      </Route>
      <Route exact path={`${path}/edit-sso-profile/:id`}>
        <AddSsoProfile />
      </Route>
      <Route path={`${path}/:id`}>
        <ViewSsoProfile />
      </Route>
    </Switch>
  )
}

export default SsoProfilesRouter
