import React from 'react'
import PropTypes from 'prop-types'

import useAccountTypeReducer from './AccountTypeReducer'

const AccountTypeContext = React.createContext()

export const AccountTypeContextProvider = ({ children }) => {
  const { accountTypeState, dispatchAccountTypeContext } =
    useAccountTypeReducer()
  return (
    <AccountTypeContext.Provider
      value={{ accountTypeState, dispatchAccountTypeContext }}
    >
      {children}
    </AccountTypeContext.Provider>
  )
}

AccountTypeContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const useAccountTypeContext = () => {
  const { accountTypeState, dispatchAccountTypeContext } =
    React.useContext(AccountTypeContext)
  return { ...accountTypeState, dispatchAccountTypeContext }
}
