import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
/* eslint-disable import/no-unresolved */
import resourcesToBackend from 'i18next-resources-to-backend'
/* eslint-enable */

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  .use(
    resourcesToBackend(async (language, namespace, callback) => {
      try {
        const resources = await import(
          /* webpackChunkName: "locales/[request]" */ `./locales/${language}/${namespace}.json`
        )
        callback(null, resources)
      } catch (error) {
        callback(error, null)
      }
    })
  )
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(detector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en-US',
    supportedLngs: [
      'en-US',
      'de-DE',
      'es-ES',
      'fr-FR',
      'ja-JP',
      'ko-KR',
      'pt-BR',
      'ru-RU',
      'zh-CN',
      'it-IT'
    ],
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage'],
      caches: ['cookie']
    },
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      useSuspense: true,
      wait: false
    }
  })
