import React, { useContext, useState } from 'react'
import { Box, DropButton, ResponsiveContext, TextInput } from 'grommet'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Search as SearchIcon } from 'grommet-icons'

/* Inputs should always be accompanied by labels for accessibility. An icon
may serve as a label if 1) the icon meaning is well understood, and 2)
has an 'aria-labelledby' attribute. For additional detail:
https://www.w3.org/WAI/tutorials/forms/labels/#using-aria-labelledby
https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/forms/Basic_form_hints
*/
const StyledTextInput = styled(TextInput).attrs(() => ({
  'aria-labelledby': 'search-icon'
}))``

const dimensions = [
  'xxsmall',
  'xsmall',
  'small',
  'medium',
  'large',
  'xlarge',
  'xxlarge',
  '100%'
]

export const Search = ({
  drop,
  initValue,
  handleCustomSearch,
  placeholder,
  responsive,
  size,
  width,
  testId,
  ...rest
}) => {
  const screenSize = useContext(ResponsiveContext)
  const [showContent, setShowContent] = useState()
  const [searchValue, setSearchValue] = useState(initValue)
  const handleSearch = (e) => {
    setSearchValue(e.target.value)
    handleCustomSearch(e.target.value)
  }
  const id = 'search-icon'

  const isDrop =
    drop || (responsive && (screenSize === 'small' || screenSize === 'xsmall'))

  const content = (
    <Box width={width}>
      <StyledTextInput
        size={size}
        icon={<SearchIcon id={isDrop ? undefined : id} />}
        placeholder={placeholder}
        value={searchValue}
        onChange={handleSearch}
        data-testid={testId}
        {...rest}
      />
    </Box>
  )

  if (!isDrop) return content

  return (
    <DropButton
      kind="toolbar"
      icon={<SearchIcon id={id} />}
      dropContent={<Box pad="small">{content}</Box>}
      open={showContent}
      onOpen={() => setShowContent(undefined)}
      onClose={() => setShowContent(undefined)}
    />
  )
}

Search.propTypes = {
  /**
   * If true this renders as a drop button that
   * opens the search field input.
   * This is optional and the default is false
   */
  drop: PropTypes.bool,

  /**
   * If true the search field changes to a drop button
   * that opens the search field if the screen size is
   * small or xsmall.
   * This is optional and the default is true
   */
  responsive: PropTypes.bool,
  /**
   * This prop defines the size of the search text.
   * This is optional and default value is medium
   */
  size: PropTypes.string,
  /**
   * This prop will be used for placeholder of the search
   */
  placeholder: PropTypes.string,
  /**
   * This prop will be used for search string initial value
   */
  initValue: PropTypes.string,
  /**
   * This is the handleCustomSearch event handler for search
   */
  handleCustomSearch: PropTypes.func,
  /**
   * width of the search field; default is medium
   */
  width: PropTypes.oneOf(dimensions),
  /**
   * It will be used for component reference to test.
   * This is mandatory.
   */
  testId: PropTypes.string.isRequired
}

Search.defaultProps = {
  drop: false,
  responsive: false,
  size: 'medium',
  placeholder: '',
  initValue: '',
  width: 'medium',
  handleCustomSearch: () => {}
}
