import { FileInput as GrommetFileInput, Box } from 'grommet'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'

import { bytesToSize } from '../utils'
import { Typography } from '../typography/Typography'

const StyledBox = styled(Box)`
  & > div button {
    z-index: 1;
  }
`

const defaultLabelsObject = {
  dropLabel: 'Drag and drop',
  replaceLabel: 'Replace File',
  selectLabel: 'Select File'
}

export const FileInput = ({
  testId,
  actionLabels = defaultLabelsObject,
  onChange,
  ...rest
}) => {
  const [numFiles, setNumFiles] = useState(0)
  const { multiple, displaySize, pad } = rest
  const message = multiple
    ? {
        dropPromptMultiple: actionLabels?.dropLabel,
        browse:
          numFiles > 0 ? actionLabels?.replaceLabel : actionLabels?.selectLabel
      }
    : {
        dropPrompt: actionLabels?.dropLabel,
        browse:
          numFiles > 0 ? actionLabels?.replaceLabel : actionLabels?.selectLabel
      }
  const handleChange = (event, files) => {
    setNumFiles(files.length)
    onChange(event)
  }
  return (
    <StyledBox>
      <GrommetFileInput
        data-testid={testId}
        messages={message}
        onChange={(event, { files }) => handleChange(event, files)}
        renderFile={(file) => {
          return (
            <Box
              direction="row"
              gap="small"
              pad={pad || 'xsmall'}
              height={{ min: 'xxsmall' }}
              align="center"
            >
              <Typography testId="filename" type="text" emphasis truncate>
                {file.name}
              </Typography>
              {displaySize ? (
                <Typography testId="filesize" type="text" color="text-weak">
                  ({bytesToSize(file.size)})
                </Typography>
              ) : null}
            </Box>
          )
        }}
        {...rest}
      />
    </StyledBox>
  )
}
FileInput.propTypes = {
  /**
   * It will be used for component reference to test.
   * This is mandatory.
   */
  testId: PropTypes.string.isRequired,
  /**
   * It will be used for component onChange event.
   */
  onChange: PropTypes.func,
  /**
   * It will be used for component customized the i18n labels.
   * dropLabel, replaceLabel, selectLabel
   */
  actionLabels: PropTypes.shape({
    dropLabel: PropTypes.string,
    replaceLabel: PropTypes.string,
    selectLabel: PropTypes.string
  })
}
FileInput.defaultProps = {
  actionLabels: undefined,
  onChange: () => {}
}
