import find from 'lodash/find'
import lodashGet from 'lodash/get'

import { get, getErrorMessage, post, put, del } from '../../utils/api-utils'
import { getApiErrorMessage } from '../../utils/error-handling-utils'

const getSlugData = (dataItem, slug, parentName = '') => {
  let returnData = null
  find(dataItem, (parent) => {
    const separator = parentName === '' ? '' : '/'
    const slugPath = parentName + separator + parent.name
    if (parent.children && parent.children.length > 0) {
      returnData = getSlugData(parent.children, slug, slugPath)
    }
    if (parent.subs && parent.subs.length > 0) {
      find(parent.subs, (sub) => {
        if (`${parent.slug}~${sub.slug}` === slug) {
          sub.matcher = `${parent.slug}~${sub.slug}`
          sub.path = slugPath
          sub.parentKey = parent.slug
          sub.type = parent.name
          returnData = sub
        }
      })
    }
    if (returnData) {
      return true
    }
    return false
  })
  return returnData
}

const createResourcePolicy = (permissions, oldPermissions) => {
  const currentRole = []
  permissions.forEach((rp) => {
    if (currentRole.length > 0 && rp) {
      const resourcePolicyIndex = currentRole.findIndex((resourcePolicy) => {
        return resourcePolicy.resource.matcher === rp.parentKey
      })
      if (
        resourcePolicyIndex !== -1 &&
        currentRole[resourcePolicyIndex].permissions.filter(
          (selectedRolePermission) => {
            return selectedRolePermission.slug === rp.slug
          }
        ).length === 0
      ) {
        currentRole[resourcePolicyIndex].permissions.push({
          slug: rp.slug,
          name: rp.name
        })
      } else {
        currentRole.push({
          effect: 'ALLOW',
          permissions: [{ slug: rp.slug, name: rp.name }],
          resource: { matcher: rp.parentKey, name: rp.type }
        })
      }
    } else if (rp) {
      currentRole.push({
        effect: 'ALLOW',
        permissions: [{ slug: rp.slug, name: rp.name }],
        resource: { matcher: rp.parentKey, name: rp.type }
      })
    }
  })

  oldPermissions.forEach((resourcePolicy) => {
    if (
      !permissions.find((filteredResourcePolicy) => {
        return (
          filteredResourcePolicy.parentKey === resourcePolicy.resource.matcher
        )
      })
    ) {
      currentRole.push({
        effect: 'ALLOW',
        permissions: [],
        resource: {
          matcher: resourcePolicy.resource.matcher,
          name: resourcePolicy.resource.name
        }
      })
    }
  })

  return currentRole
}

const getAccessRules = (index) => {
  let retAccessRules
  const accessRulesData = [
    {
      msp: true,
      tenants: ['ALL']
    },
    {
      msp: true,
      tenants: []
    },
    {
      msp: false,
      tenants: ['ALL']
    }
  ]
  if (index !== undefined) {
    retAccessRules = accessRulesData[index]
  } else {
    // eslint-disable-next-line
    retAccessRules = accessRulesData[0]
  }
  return retAccessRules
}

const getFolderList = async ({
  token,
  param,
  t,
  setErrorMessage,
  isCCSManager
}) => {
  const url = isCCSManager
    ? '/support-assistant/v1alpha1/user-folders'
    : '/ui-doorway/ui/v1/activate/folders'

  return get(url, param, token).then(
    (response) => {
      return response.data
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
      return []
    }
  )
}
const getFolder = async ({ token, folderId, t, setErrorMessage }) => {
  const url = `/ui-doorway/ui/v1/activate/folders/${folderId}`
  return get(url, {}, token).then(
    (response) => {
      return response
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
      return []
    }
  )
}

const actionCreateFolder = ({
  token,
  t,
  requestBody,
  onSetOpen,
  setErrorMessage,
  refreshDataTable,
  isCCSManager
}) => {
  const url = isCCSManager
    ? '/support-assistant/v1alpha1/folder'
    : '/ui-doorway/ui/v1/activate/folders'

  post(url, requestBody, token).then(
    (response) => {
      if (response.status === 200) {
        onSetOpen(false)
        refreshDataTable()
      }
    },
    (error) => {
      setErrorMessage(getApiErrorMessage(error, t))
    }
  )
}

const updateFolderDetails = ({
  folderID,
  isCCSManager,
  customerId,
  request,
  token,
  t,
  setErrorMessage,
  onSuccess
} = {}) => {
  const url = isCCSManager
    ? `/support-assistant/v1alpha1/activate-folder/${folderID}`
    : `/ui-doorway/ui/v1/activate/folders/${folderID}`

  const requestBody = isCCSManager
    ? { ...request, platform_customer_id: customerId }
    : request
  put(url, requestBody, token).then(
    () => {
      onSuccess()
    },
    (error) => {
      const errorMessage = getApiErrorMessage(error, t)
      setErrorMessage(errorMessage)
    }
  )
}

const actionDeleteFolder = ({
  token,
  t,
  isCCSManager,
  customerId,
  folderID,
  onSetOpen,
  setErrorMessage,
  history,
  customerData
} = {}) => {
  const url = isCCSManager
    ? `/support-assistant/v1alpha1/folder/${folderID}`
    : `/ui-doorway/ui/v1/activate/folders/${folderID}`

  const requestBody = isCCSManager ? { platform_customer_id: customerId } : {}
  del(url, requestBody, token).then(
    (response) => {
      if (response.status === 200) {
        onSetOpen(false)
        if (
          lodashGet(history, 'location.pathname') ===
          '/manage-ccs/customers/customer-details/folders/folder-details'
        )
          history.push({
            pathname: '/manage-ccs/customers/customer-details',
            customerData
          })
        else history.goBack()
      }
    },
    (error) => {
      onSetOpen(false)
      setErrorMessage(getApiErrorMessage(error, t))
    }
  )
}

const getRuleList = ({
  inCCSManager,
  token,
  t,
  param,
  setErrorMessage
} = {}) => {
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/rules`
    : `/ui-doorway/ui/v1/activate/rules`

  return get(url, param, token).then(
    (response) => {
      if (setErrorMessage) setErrorMessage('')
      return lodashGet(response, 'data', [])
    },
    (error) => {
      console.error(error)
      if (setErrorMessage) {
        const errorMessage = getApiErrorMessage(error, t)
        setErrorMessage(errorMessage)
      }
      return []
    }
  )
}

const getRuleDetails = ({
  param,
  token,
  t,
  setRuleDetails,
  setErrorMessage,
  setLoading,
  inCCSManager,
  customerId
} = {}) => {
  if (setLoading) setLoading(true)
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/folder/${param.folder_id}/rule/${param.rule_name}`
    : `/ui-doorway/ui/v1/activate/folders/${param.folder_id}/rules/${param.rule_name}`

  const request = inCCSManager ? { platform_customer_id: customerId } : {}
  get(url, request, token).then(
    (response) => {
      response.data.rule_type = response.data?.rule_type?.toLowerCase()
      response.data.sub_type = response.data?.sub_type?.toLowerCase()
      setRuleDetails(response.data)
      setErrorMessage('')
      if (setLoading) setLoading(false)
    },
    (error) => {
      const errorMessage = getApiErrorMessage(error, t)
      setErrorMessage(errorMessage)
      if (setLoading) setLoading(false)
    }
  )
}
// THIS COULD BE USED IN FUTURE FOR RULES REORDER

// const updateRulesReorder = ({
//   folderId,
//   rulesData,
//   token,
//   t,
//   setErrorMessage,
//   onSuccess
// } = {}) => {
//   const url = `/ui-doorway/ui/v1/activate/folders/${folderId}/rules`
//   put(url, { rules: rulesData }, token).then(
//     () => {
//       onSuccess()
//     },
//     (error) => {
//       const errorMessage = getApiErrorMessage(error, t)
//       setErrorMessage(errorMessage)
//     }
//   )
// }

const actionCreateRule = ({
  token,
  t,
  requestBody,
  onSetOpen,
  refreshRule,
  setErrorMessage,
  customerId,
  inCCSManager
}) => {
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/rules`
    : '/ui-doorway/ui/v1/activate/rules'

  const updatedRequestBody = inCCSManager
    ? { ...requestBody, platform_customer_id: customerId }
    : requestBody

  post(url, updatedRequestBody, token).then(
    (response) => {
      if (response.status === 200) {
        onSetOpen(false)
        refreshRule()
      }
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
    }
  )
}

const actionEditRule = ({
  token,
  t,
  ruleID,
  requestBody,
  onSetOpen,
  refreshRule,
  setErrorMessage,
  customerId,
  inCCSManager
}) => {
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/activate-rules/${ruleID}`
    : `/ui-doorway/ui/v1/activate/rules/${ruleID}`

  const updatedRequestBody = inCCSManager
    ? { ...requestBody, platform_customer_id: customerId }
    : requestBody
  put(url, updatedRequestBody, token).then(
    (response) => {
      if (response.status === 200) {
        onSetOpen(false)
        refreshRule()
      }
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
    }
  )
}

const actionDeleteRule = ({
  token,
  t,
  ruleID,
  onSetOpen,
  refreshRule,
  setErrorMessage,
  isCCSManager,
  customerId
} = {}) => {
  const url = isCCSManager
    ? `/support-assistant/v1alpha1/rule/${ruleID}`
    : `/ui-doorway/ui/v1/activate/rules/${ruleID}`

  const requestBody = isCCSManager ? { platform_customer_id: customerId } : {}
  del(url, requestBody, token).then(
    (response) => {
      if (response.status === 200) {
        onSetOpen(false)
        refreshRule()
      }
    },
    (error) => {
      onSetOpen(false)
      setErrorMessage(getApiErrorMessage(error, t))
    }
  )
}

const getAllParts = ({ token, param, isCCSManager } = {}) => {
  const url = isCCSManager
    ? '/support-assistant/v1alpha1/activate-part'
    : `/ui-doorway/ui/v1/activate/part`

  return get(url, param, token).then(
    (response) => {
      return response.data
    },
    () => {
      return []
    }
  )
}

const getAllMac = ({ token, param, inCCSManager } = {}) => {
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/deviceSummary`
    : `/ui-doorway/ui/v1/activate/devices/mac`

  return get(url, param, token).then(
    (response) => {
      return lodashGet(response, 'data.mac_addresses', [])
    },
    () => {
      return []
    }
  )
}

const getAllCountries = (
  t,
  isCurrent,
  setListOfCountries,
  setCountries,
  setErrorMessage
) => {
  get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
    (response) => {
      if (!isCurrent) return
      setListOfCountries(response.data.countries)
      setCountries(response.data.countries)
    },
    (error) => {
      const backendErrorMessage = getErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
    }
  )
}
const getCCSAppDetails = () => {
  return {
    ccsApplicationId: '00000000-0000-0000-0000-000000000000',
    ccsApplicationName: 'Common Cloud Service'
  }
}

const predefinedRolesPriority = [
  'ccs.tac-admin',
  'ccs.tac-operator',
  'ccs.tac-observer',
  'ccs.activate-tac-admin',
  'ccs.activate-tac-operator',
  'ccs.account-admin-activate-customer-admin',
  'ccs.activate-customer-admin',
  'ccs.activate-customer-observer',
  'app.admin',
  'ccs.account-admin',
  'ccs.operator',
  'ccs.observer'
]

const getCurrentCCSRoles = (roles) => {
  const { ccsApplicationId } = getCCSAppDetails()
  return roles
    .filter((role) => role.application_id === ccsApplicationId)
    .map((role) => role.slug)
}

const isActivateAdmin = (roleSlugs) => {
  for (let i = 0; i < roleSlugs.length; i += 1) {
    if (
      roleSlugs[i] === 'ccs.account-admin-activate-customer-admin' ||
      roleSlugs[i] === 'ccs.activate-customer-admin'
    )
      return true
  }
  return false
}

export const roleKindMapping = new Map([
  ['LINKED', 'Linked'],
  ['CUSTOM', 'Custom'],
  ['PREDEFINED', 'Built-in'],
  ['MANAGED', 'Managed']
])

export const showActionDropdown = (policies, actionList) => {
  return actionList.some(
    (actions) =>
      policies?.effects?.[actions?.visibility?.rbac?.resource]?.[
        actions?.visibility?.rbac?.permission
      ]
  )
}

export const showV2ActionDropdown = (policies, actionList) => {
  return actionList?.some((action) =>
    policies?.includes(`${action?.visibility?.rbac?.permission}`)
  )
}

export const showContent = (policies, rbac) => {
  return policies?.effects?.[rbac?.resource]?.[rbac?.permission]
}

export const getGLCPAppDetails = () => {
  return {
    glcpApplicationId: '00000000-0000-0000-0000-000000000000',
    glcpApplicationName: 'HPE GreenLake Platform'
  }
}

export const getUserName = (data) => {
  if (data?.subject_type === 'user') {
    if (data?.subject_name !== '')
      return `${data?.subject_name} (${data?.subject_email})`
    return data?.subject_email
  }
  return data?.subject_name
}

const updateStatusName = (name) => {
  switch (name) {
    case 'pending':
      return 'Pending'

    case 'warning':
      return 'Warning'

    case 'disabled':
      return 'Disabled'

    case 'active':
      return 'Active'

    case 'error':
      return 'Critical'

    default:
      return name || ''
  }
}

export {
  getSlugData,
  createResourcePolicy,
  getAccessRules,
  getCCSAppDetails,
  getFolderList,
  getFolder,
  updateFolderDetails,
  actionCreateFolder,
  actionDeleteFolder,
  getRuleList,
  getRuleDetails,
  actionCreateRule,
  actionEditRule,
  actionDeleteRule,
  getAllMac,
  getAllParts,
  getAllCountries,
  predefinedRolesPriority,
  getCurrentCCSRoles,
  isActivateAdmin,
  updateStatusName
}
