// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box } from 'grommet'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { CircleInformation } from 'grommet-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  DataTable,
  FilterButton,
  Typography,
  NoDataInfo,
  Notification
} from '../../../../../../components'
import TruncateButton from '../../../../../device-management/components/TruncateButton'
import { ManageSubscriptionTagsModal } from '../../../common-components/ManageSubscriptionTags'
import { isServiceCentric } from '../../../../../../utils/account-utils'
import {
  getOnPremWorkSpaces,
  getSubscriptionFilterOptions
} from '../../../../../../utils/dm-sm-common-utils'
import { isCoP, isGLOP } from '../../../../../../utils/feature-flag-utils'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  getAssignToOnPremWorkSpacePayload,
  getTags,
  getSubsExportPermission
} from '../../../utils'
import { queryParamsBasedOnTab } from '../../../constants'
import { customRenderer } from '../../../../../commoncomponents/CustomRenderer'
import { AssignToOnPremWorkSpaceModal } from '../../../../../commoncomponents/device-management'
import { useVisibilityContext } from '../../../../../../context/visibility-context'

import SideDetailsPanel from './SideDetailsPanel'

const ServiceSubscriptionsDatatable = ({
  serviceSubs,
  appData,
  pagination,
  setRefreshDataTable,
  refreshDataTable,
  setSearchTerm,
  setPage,
  tabName,
  setShowExportModal,
  sortProps,
  setSortProps,
  setNotification,
  filterOptions,
  setFilterOptions,
  subscriptionTierList
}) => {
  const { t } = useTranslation(['device', 'common', 'licensing', 'manage'])
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const { oidcUser } = useReactOidc()
  const { rbacPolicies } = useVisibilityContext()
  const [pullTagsRefresh, setPullTagsRefresh] = useState(false)
  const {
    'glcp-glasgow-reportfw-subscriptions-export':
      reportSubscriptionsExportLdFlag,
    'glcp-subscrption-tags': isServiceSubscriptionFlag,
    'glcp-subscription-expiry': subscriptionExpiryFlag,
    'glcp-dscc-dm-sm': glopDevicesPreOnBoardingFlag
  } = useFlags()
  const [rowDetails, setRowDetails] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [showDetailsPanel, setShowDetailsPanel] = useState(false)
  const [manageTagsModal, setManageTagsModal] = useState(false)
  const [manageTagsData, setManageTagsData] = useState([])
  const [allTagsList, setAllTagsList] = useState([])
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [searchTagsFilterText, setSearchTagsFilterText] = useState('')
  const history = useHistory()
  const handleRowCheckBoxSelection = (selectedRow) => {
    setSelectedRows(selectedRow)
  }
  const handleManageModalOpen = (open) => {
    setManageTagsModal(open)
  }
  const [showOnPremWorkSpaceModal, setOnPremWorkSpaceModal] = useState(false)
  const [successNotification, setSuccessNotification] = useState(null)
  const [onPremWorkSpaceData, setOnPremSpaceData] = useState([])

  // Get List of all tags if subscriptions flag is turned on
  useEffect(() => {
    if (isServiceSubscriptionFlag || isGLOP()) {
      getTags(
        oidcUser.access_token,
        t,
        setAllTagsList,
        setShowErrorNotification,
        true,
        searchTagsFilterText
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oidcUser.access_token,
    isServiceSubscriptionFlag,
    t,
    pullTagsRefresh,
    searchTagsFilterText
  ])

  useEffect(() => {
    if (glopDevicesPreOnBoardingFlag) {
      getOnPremWorkSpaces(
        oidcUser.access_token,
        t,
        setShowErrorNotification
      ).then((data) => {
        setOnPremSpaceData(data?.items)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [glopDevicesPreOnBoardingFlag])

  const columns = [
    {
      property: 'subscription_key',
      type: 'string',
      header: t('subscription_key'),
      render: (datum) => (
        <Box direction="row" align="center">
          <Typography
            type="text"
            emphasis
            size="medium"
            wordBreak="break-all"
            testId="subscription-key"
          >
            {datum?.subscription_key}
          </Typography>
        </Box>
      ),
      primary: true
    },
    ...(LDFlags['glcp-show-service-subscription-tier'] || isGLOP()
      ? [
          {
            property: 'subscription_tier_description',
            type: 'string',
            header: t('tier')
          }
        ]
      : []),
    {
      property: 'product_description',
      type: 'string',
      header: t('subscription_name')
    },
    {
      property: 'subscription_type',
      type: 'string',
      header: t('subscription_type')
    },
    {
      property: 'expiration',
      type: 'date',
      align: isServiceSubscriptionFlag ? 'start' : '',
      header: t('expiration_date'),
      sortable:
        LDFlags[
          ('glcp-subscriptions-expiry-sorting', 'glcp-subscription-expiry')
        ] ||
        isCoP() ||
        isGLOP()
    },
    ...(isServiceSubscriptionFlag && newDashboardFlag
      ? [
          {
            property: 'tags',
            type: 'string',
            header: t('tags.tags_title'),
            render: (datum) => {
              return <TruncateButton datum={datum} type="ServiceLicense" />
            }
          }
        ]
      : []),
    ...(glopDevicesPreOnBoardingFlag && onPremWorkSpaceData?.length
      ? [
          {
            property: 'on_prem_workspace_name',
            type: 'string',
            header: t('onprem_workspace'),
            render: (datum) => {
              return datum?.on_prem_workspace_name || '--'
            }
          }
        ]
      : [])
  ]

  useEffect(() => {
    if (isEmpty(filterOptions)) {
      setSelectedRows([])
    }
  }, [filterOptions])

  const formatRowDetails = (data) => {
    const formattedData = { ...data.appointments, ...data }
    delete formattedData.appointments
    return formattedData
  }

  return (
    <>
      <Box gap="xlarge" flex>
        <DataTable
          grid={{
            columns,
            resizeable: !!isServiceSubscriptionFlag || isGLOP(),
            data: serviceSubs,
            onClickRow: (data) => {
              setRowDetails(formatRowDetails(data?.datum))
              if ((isServiceSubscriptionFlag && newDashboardFlag) || isGLOP())
                history.push({
                  pathname: `/services/service-subscriptions/${data?.datum?.subscription_key}`
                })
              else setShowDetailsPanel(true)
            },
            onSort: ({ property, direction, external }) => {
              if (subscriptionExpiryFlag || isGLOP()) {
                setSortProps({ property, direction, external })
                setPage(1)
              }
            },
            sort: sortProps
          }}
          search={{
            width: 'medium',
            onSearchValueChange: (val) => {
              setSearchTerm(val)
              setPage(1)
            },
            placeholder: t('common:search_by_subscription_key')
          }}
          summary={
            (subscriptionExpiryFlag || isGLOP()) && {
              summaryText: t('common:data_table.items', {
                numOfItems: pagination
                  ? pagination?.totalItems
                  : serviceSubs && serviceSubs?.length
              })
            }
          }
          selection={
            newDashboardFlag
              ? {
                  bulkActions: {
                    actionDropdown: {
                      label: t('common:actions'),
                      visibility: {
                        rbac: {
                          resource: '/ccs/device-management',
                          permission: 'ccs.device-management.edit'
                        }
                      }
                    },
                    customRenderer: (element, visibility, index) =>
                      customRenderer(element, visibility, index),
                    actions: [
                      {
                        label: t('device:export'),
                        onClick: () => {
                          setNotification(null)
                          setShowExportModal(true)
                        },
                        hidden:
                          (!reportSubscriptionsExportLdFlag &&
                            !subscriptionExpiryFlag) ||
                          isGLOP() ||
                          !getSubsExportPermission(rbacPolicies),
                        testId: 'export-btn',
                        selectionBased: false
                      },
                      {
                        label: t('manage_tags'),
                        onClick: () => {
                          setNotification(null)
                          const data = serviceSubs?.filter((temp) =>
                            selectedRows.includes(temp?.subscription_key)
                          )
                          setManageTagsData(data)
                          setManageTagsModal(true)
                        },
                        hidden: !isServiceSubscriptionFlag || isGLOP(),
                        testId: 'manage-tags-btn'
                      },
                      {
                        label: t('manage:onprem_workspace.assign_to_onprem'),
                        onClick: () => {
                          setOnPremWorkSpaceModal(true)
                        },
                        hidden:
                          isCoP() ||
                          isGLOP() ||
                          !glopDevicesPreOnBoardingFlag ||
                          !onPremWorkSpaceData?.length,
                        testId: 'assign-to-onprem-workspace-btn'
                      }
                    ]
                  },
                  onSelectionChange: (selectedRow) =>
                    handleRowCheckBoxSelection(selectedRow),
                  selectionInfoMessage: t('common:data_table_warning_msg'),
                  primaryKey: 'subscription_key'
                }
              : {}
          }
          pagination={pagination}
          filterButton={
            <VisibilityWrapper
              hideFor={{ feature: 'glcp-subscription-expiry' }}
            >
              <Box>
                <FilterButton
                  dialogPosition="right"
                  dialogHeight="100%"
                  title={t('licensing:service_subscription_filter')}
                  initSelectedFilterValues={
                    // exclude the query param that are not part of Filter button
                    tabName === 'totalSubscriptions'
                      ? filterOptions
                      : omit(filterOptions, [
                          'end_date_in_millis',
                          'expire_date_cut_off_in_millis',
                          'evaluation_type'
                        ])
                  }
                  filterAttributes={getSubscriptionFilterOptions(
                    subscriptionTierList,
                    t,
                    allTagsList,
                    isServiceSubscriptionFlag || isGLOP(),
                    LDFlags,
                    tabName === 'totalSubscriptions',
                    setSearchTagsFilterText
                  )}
                  onFilterValuesChange={(filters) => {
                    // fetch the query param based on tab name
                    const modifiedFilters = {
                      ...filters,
                      ...(tabName !== 'totalSubscriptions' && {
                        ...(queryParamsBasedOnTab(tabName) || {})
                      })
                    }
                    setFilterOptions(modifiedFilters)
                    setPage(1)
                  }}
                  testId="service-subs-filter-search-btn"
                  clearFilterString={t('common:clear_filter')}
                />
              </Box>
            </VisibilityWrapper>
          }
          testId="service-subscriptions-datatable"
        />
        {serviceSubs.length === 0 && (
          <NoDataInfo
            icon={<CircleInformation size="xlarge" />}
            title={t('licensing:card_empty_state_title')}
            subtitle={
              subscriptionExpiryFlag
                ? t('licensing:card_empty_state_subtitle')
                : null
            }
            testId="no-tab-data-total-service-subs"
          />
        )}
      </Box>
      {showDetailsPanel && (
        <SideDetailsPanel
          data={rowDetails}
          appData={appData}
          onClose={(e) => setShowDetailsPanel(e)}
        />
      )}
      {showOnPremWorkSpaceModal && (
        <AssignToOnPremWorkSpaceModal
          setOnPremWorkSpaceModal={setOnPremWorkSpaceModal}
          onPremWorkSpaceData={onPremWorkSpaceData}
          requestPayload={getAssignToOnPremWorkSpacePayload(selectedRows)}
          onSuccess={() => {
            setSuccessNotification(true)
            setOnPremWorkSpaceModal(false)
            setRefreshDataTable(!refreshDataTable)
          }}
          selectedRows={selectedRows}
          type="service_subscriptions"
        />
      )}
      {manageTagsModal && (
        <ManageSubscriptionTagsModal
          setModalOpen={handleManageModalOpen}
          manageTagsData={manageTagsData}
          setShowErrorNotification={setShowErrorNotification}
          selectedSubscriptionsKey={selectedRows}
          onSuccess={() => {
            // Refresh tags list in filter
            setPullTagsRefresh(!pullTagsRefresh)
            setRefreshDataTable(!refreshDataTable)
          }}
        />
      )}
      {showErrorNotification}
      {successNotification && (
        <Notification
          testId="success-notification"
          status="normal"
          text={t('manage:onprem_workspace.service_subscription_notification')}
          onClose={() => {
            setSuccessNotification(false)
          }}
        />
      )}
    </>
  )
}

ServiceSubscriptionsDatatable.propTypes = {
  serviceSubs: PropTypes.array.isRequired,
  appData: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  setRefreshDataTable: PropTypes.func.isRequired,
  refreshDataTable: PropTypes.bool.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  tabName: PropTypes.string.isRequired,
  setShowExportModal: PropTypes.func.isRequired,
  sortProps: PropTypes.object.isRequired,
  setSortProps: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  filterOptions: PropTypes.object.isRequired,
  setFilterOptions: PropTypes.func.isRequired,
  subscriptionTierList: PropTypes.array.isRequired
}

export { ServiceSubscriptionsDatatable }
