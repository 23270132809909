import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Layer, FormField, CheckBox } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  CCSForm,
  Button,
  Dropdown,
  FormInput
} from '../../../../../components'
import { validatePortNumber } from '../../../../../utils/validation-utils'

const EditServer = ({
  onSetOpen,
  cb,
  serverName,
  serverInfo,
  serverType,
  serverDetail,
  editServerCode
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const [formValues, setFormValues] = useState({
    ip: serverInfo.ip || '',
    port: serverInfo.port || (serverType === 'radius' ? '1812' : '2083'),
    serverSecret: serverInfo.serverSecret || '',
    confirmServerSecret: serverInfo.confirmServerSecret || '',
    authentication: serverInfo.authentication || 'PAP',
    ...(serverType === 'radius' && {
      add_message_authenticator: serverInfo.add_message_authenticator || false
    })
  })

  const [authMethodTypes] = useState(
    serverType === 'radius' ? ['PEAP-MSCHAPV2', 'PAP'] : ['EAP-MSCHAPV2', 'PAP']
  )

  const [errorOccurred] = useState('')
  return (
    <>
      <Layer
        position="center"
        onClickOutside={() => onSetOpen(false)}
        onEsc={() => onSetOpen(false)}
        data-testid="edit-server-layer"
      >
        <Box width="medium" pad="medium" gap="small">
          <Box>
            <Typography type="heading" level="1" testId="server-title">
              {t('manage_account.radius.edit_server', { server: serverName })}
            </Typography>
            <Typography type="paragraph" size="large" testId="server-subtitle">
              {t('manage_account.radius.server_details', {
                server: serverName
              })}
            </Typography>
          </Box>
          <CCSForm
            value={formValues}
            onChange={setFormValues}
            errorMessage={errorOccurred}
            testId="edit-radiusserver-form"
            validate="blur"
            onSubmit={() => {
              cb(formValues, serverName)
              onSetOpen(false)
            }}
            buttons={
              <Box direction="row" justify="end" gap="medium">
                <Button
                  default
                  label={t('manage_account.authentication.cancel')}
                  onClick={() => {
                    onSetOpen(false)
                  }}
                  testId="cancel-btn"
                />
                <Button
                  primary
                  type="submit"
                  label={t('manage_account.authentication.add')}
                  testId="add-server-btn"
                />
              </Box>
            }
          >
            <>
              <FormInput
                testId="ip-form-field"
                required
                name="ip"
                label={t('manage_account.radius.server_ip_hostname')}
                validate={(value) => {
                  if (value === '') {
                    return t('manage_account.radius.enter_server_ip_hostname')
                  }
                  let msg = ''
                  const maxHostNameLen = 253

                  const isIP =
                    /(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(
                      value
                    )
                  const isHostname =
                    /^([a-zA-Z]|[a-zA-Z][a-zA-Z0-9-]*[a-zA-Z0-9])(\.([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9]))*$/.test(
                      value
                    )
                  const otherServerCode = editServerCode === 0 ? 1 : 0
                  if (
                    serverDetail.length === 2 &&
                    serverDetail[otherServerCode].ip === value
                  ) {
                    msg = {
                      message: t(
                        'manage_account.radius.primary_secondary_ip_unique_msg'
                      )
                    }
                  } else if (isIP) {
                    // 0.0.0.0 is disallowed as a valid ip
                    const disAllowIp1 = /^0./.test(value)
                    // 255.255.255.255 is disallowed as a valid ip
                    const disAllowIp2 = /^255./.test(value)
                    // 4 octets should be present
                    const allOctetsRegex =
                      /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(value)

                    if (disAllowIp1 || disAllowIp2 || !allOctetsRegex) {
                      msg = {
                        message: t(
                          'manage_account.radius.invalid_server_ip_hostname'
                        )
                      }
                    }
                  } else if (isHostname) {
                    if (value.length > maxHostNameLen) {
                      msg = {
                        message: t(
                          'manage_account.radius.invalid_server_ip_hostname'
                        )
                      }
                    }
                  } else {
                    msg = {
                      message: t(
                        'manage_account.radius.invalid_server_ip_hostname'
                      )
                    }
                  }

                  if (msg && value) {
                    msg = msg.message
                  }
                  return msg || false
                }}
                inputType="text"
                placeholder={t(
                  'manage_account.radius.server_ip_hostname_placeholder'
                )}
              />

              <FormInput
                testId="port-form-field"
                required
                name="port"
                label={t('manage_account.radius.port')}
                validate={(value) => {
                  if (value === '') {
                    return t('manage_account.radius.enter_port')
                  }
                  let msg = validatePortNumber(
                    value,
                    t('manage_account.radius.invalid_port')
                  )
                  if (msg && value) {
                    msg = msg.message
                  }
                  return msg
                }}
                inputType="text"
                placeholder={t('manage_account.radius.enter_port')}
              />

              <FormInput
                testId="serverSecret-form-field"
                required
                name="serverSecret"
                label={t('manage_account.radius.server_secret')}
                validate={(value) => {
                  if (value === '') {
                    return t('manage_account.radius.enter_server_secret')
                  }
                  return false
                }}
                inputType="password"
                placeholder={t('manage_account.radius.server_secret')}
              />

              <FormInput
                testId="confirmServerSecret-form-field"
                required
                name="confirmServerSecret"
                label={t('manage_account.radius.confirm_server_secret')}
                validate={(value) => {
                  if (formValues.serverSecret !== value) {
                    return t('manage_account.radius.invalid_server_secret')
                  }
                  return false
                }}
                inputType="password"
                placeholder={t('manage_account.radius.confirm_server_secret')}
              />

              {serverName === 'Primary' && (
                <Box>
                  <FormField
                    name="authentication"
                    label={t('manage_account.radius.authentication_protocol')}
                    data-testid="authentication-form-field"
                  >
                    {authMethodTypes && (
                      <Dropdown
                        name="authentication"
                        noBorder
                        options={authMethodTypes}
                        multiple={false}
                        placeholder="auth-type"
                        testId="auth-dropdown"
                        defaultVal={formValues.authentication}
                        customRender={(option) => (
                          <Typography margin="xsmall" type="text">
                            {option}
                          </Typography>
                        )}
                      />
                    )}
                  </FormField>
                </Box>
              )}
              {serverType === 'radius' && (
                <Box margin={{ bottom: 'medium' }}>
                  <FormField
                    name="add_message_authenticator"
                    data-testid="authenticator-check-box-form-field"
                  >
                    <CheckBox
                      name="add_message_authenticator"
                      label={t('manage_account.radius.message_authenticator')}
                      data-testid="message-authenticator-checkbox"
                    />
                  </FormField>
                </Box>
              )}
            </>
          </CCSForm>
        </Box>
      </Layer>
    </>
  )
}

EditServer.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  cb: PropTypes.func.isRequired,
  serverName: PropTypes.string.isRequired,
  serverInfo: PropTypes.object.isRequired,
  serverDetail: PropTypes.array.isRequired,
  serverType: PropTypes.string.isRequired,
  editServerCode: PropTypes.number.isRequired
}

export default EditServer
