// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Route, Switch, Redirect } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'
import { Box } from 'grommet'

import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'
import LazyLoading from '../commoncomponents/LazyLoading/LazyLoading'

const OverviewPage = lazy(() => import('./pages/overview/Overview'))
const CustomersPage = lazy(() => import('./pages/customers/Customers'))
const OrdersPage = lazy(() => import('./pages/orders/Orders'))
const UsersPage = lazy(() => import('./pages/users/Users'))
const SubscriptionsPage = lazy(() =>
  import('./pages/subscriptions/Subscriptions')
)
const DevicesPage = lazy(() => import('./pages/devices/Devices'))
const FirmWarePage = lazy(() => import('./pages/firmware/FirmWare'))
const NotificationPage = lazy(() =>
  import('./pages/notifications/Notifications')
)
const ApplicationsPage = lazy(() =>
  import('./pages/applications/ApplicationsPage')
)
const WhatsNewPage = lazy(() => import('./pages/whats-new/WhatsNew'))

const ManageCcsRouter = () => {
  return (
    <Suspense
      fallback={
        <Box width="100%">
          <LazyLoading />
        </Box>
      }
    >
      <Switch>
        <Route exact path="/manage-ccs/overview">
          <OverviewPage />
        </Route>
        <Route exact path="/manage-ccs/customers">
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/accounts/platform/customer',
              permission: 'ccs.accounts.platform.customer.view'
            }}
          >
            <CustomersPage />
          </VisibilityWrapper>
        </Route>
        <Route exact path="/manage-ccs/orders">
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.activate.view',
              resource: '/ccs/activate/tac'
            }}
          >
            <OrdersPage />
          </VisibilityWrapper>
        </Route>
        <Route exact path="/manage-ccs/users">
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.accounts.users.view.all',
              resource: '/ccs/accounts/user'
            }}
          >
            <UsersPage />
          </VisibilityWrapper>
        </Route>
        <Route exact path="/manage-ccs/subscriptions">
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/device-management',
              permission: 'ccs.device-management.view'
            }}
          >
            <SubscriptionsPage />
          </VisibilityWrapper>
        </Route>
        <Route exact path="/manage-ccs/devices">
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/device-management',
              permission: 'ccs.device-management.view'
            }}
          >
            <DevicesPage />
          </VisibilityWrapper>
        </Route>
        <Route exact path="/manage-ccs/firmware">
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/activate/firmware',
              permission: 'ccs.activate.firmware.view'
            }}
          >
            <FirmWarePage />
          </VisibilityWrapper>
        </Route>
        <Route exact path="/manage-ccs/notifications">
          <NotificationPage />
        </Route>
        <Route exact path="/manage-ccs/whats-new">
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.whats-new.view',
              resource: '/ccs/whats-new'
            }}
          >
            <WhatsNewPage />
          </VisibilityWrapper>
        </Route>
        <Route exact path="/manage-ccs/applications">
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/app-catalog/application',
              permission: 'ccs.app-catalog.view'
            }}
          >
            <ApplicationsPage />
          </VisibilityWrapper>
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  )
}

export default ManageCcsRouter
