import React from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'

import WebhooksRouter from '../webhooks/router'
import Event2Details from '../webhooks/Event2Details'

import AutomationPage from './Automations'

const AutomationsRouter = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <AutomationPage />
      </Route>
      <Route exact path="/manage-account/automations/webhooks">
        <WebhooksRouter />
      </Route>
      <Route exact path="/manage-account/automations/webhooks/:id">
        <Event2Details />
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default AutomationsRouter
