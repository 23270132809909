// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Anchor,
  Box,
  NameValueList,
  NameValuePair,
  Page,
  PageContent,
  PageHeader,
  ResponsiveContext
} from 'grommet'
import { FormDown, Previous } from 'grommet-icons'
import dayjs from 'dayjs'

import { get, getErrorMessage } from '../../../utils/api-utils'
import { ActionButton, Loader, Typography } from '../../../components'
import { displayNotification } from '../../../utils/notificiation-utils'
import { useVisibilityContext } from '../../../context/visibility-context'
import { showV2ActionDropdown } from '../../manage-account/utils'
import {
  getOrganizationId,
  isAssociateWorkspace
} from '../../../utils/feature-flag-utils'
import NoViewPermission from '../commoncomponents/NoViewPermission'

import { getDomainStatusText, lifeCycleStateEnum } from './constants'
import RemoveDomainModal from './components/RemoveDomainModal'
import VerifyDomainConfirmation from './components/VerifyDomainConfirmation'
import ViewDomainClaimTXTRecord from './components/ViewDomainClaimTXTRecord'

const DomainDetails = () => {
  const history = useHistory()
  const handleBackBtnClick = () => {
    history.push('/manage-account/organization/domains')
  }
  const { id } = useParams()
  const { v2RbacPolicies, v2RbacRootWkspcPolicies } = useVisibilityContext()
  const [domainDetailsData, setDomainDetailsData] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation(['common', 'iam'])
  const size = useContext(ResponsiveContext)
  const [isAssociated, setIsAssociated] = useState(false)
  // Modals
  const [showVerifyDomainDialog, setShowVerifyDomainDialog] = useState(false)
  const [showViewTxtModal, setShowViewTxtModal] = useState(false)
  const [showRemoveDomainModal, setShowRemoveDomainModal] = useState(false)
  const [domainSSoData, setDomainSSoData] = useState({})

  // Notification
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')
  const setNotificationInfo = (message, severity, title = '') => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  const tableActions = (datum) => {
    const status = datum?.lifeCycleState
    const actions = [
      {
        label: t('iam:domains.verify_action_btn'),
        onClick: () => {
          setShowVerifyDomainDialog(true)
        },
        testId: 'verify-domain-btn',
        visibility: {
          rbac: {
            permission: 'identity.domain-requests.verify'
          }
        }
      },
      {
        label: t('iam:domains.view_txt_action_btn'),
        onClick: () => {
          setShowViewTxtModal(true)
        },
        testId: 'view-txt-btn',
        visibility: {
          rbac: {
            permission: 'identity.domain-requests.read'
          }
        }
      },
      {
        label: t('common:remove'),
        onClick: () => {
          setShowRemoveDomainModal(true)
        },
        testId: 'remove-domain-btn',
        visibility: {
          rbac: {
            permission: 'identity.domain-requests.delete'
          }
        }
      }
    ]

    return status === lifeCycleStateEnum?.PENDING ||
      status === lifeCycleStateEnum?.WEAKLY_CLAIMED
      ? actions
      : [actions[2]]
  }
  useEffect(() => {
    const orgId = getOrganizationId()
    if (orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (res) => {
          if (res?.data && res?.data?.associatedWorkspace) {
            setIsAssociated(
              isAssociateWorkspace(res?.data?.associatedWorkspace?.id)
            )
          }
        },
        (err) => {
          setNotificationInfo(getErrorMessage(err, t), 'error')
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      setLoading(true)
      try {
        const result = await get(`/identity/v1alpha1/domain-requests/${id}`)
        if (result?.data) {
          if (!isMounted) return
          setDomainDetailsData(result?.data)
          setLoading(false)
        }
      } catch (error) {
        if (!isMounted) return
        setNotificationInfo(getErrorMessage(error), 'error')
        setLoading(false)
      }
    }

    fetchData()
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getDomainSSOData = async (userDomainName) => {
      setLoading(true)
      try {
        const ssoRoutingData = await get(
          `/internal-identity/v1alpha1/sso-routing-rules?domain=${userDomainName}`
        )
        if (ssoRoutingData?.data?.items) {
          const getSsoProfileId =
            ssoRoutingData?.data?.items[0]?.actions?.idp?.ssoProfileId
          if (getSsoProfileId) {
            const ssoProfileInfo = await get(
              `/identity/v1alpha1/sso-profiles/${getSsoProfileId}`
            )
            setDomainSSoData({
              source: ssoProfileInfo?.data?.name
            })
          }
          setLoading(false)
        }
      } catch (error) {
        setNotificationInfo(
          getErrorMessage(error, t),
          'error',
          t('iam:igc_users.details_section_get_ssoprofile_failed_title')
        )
        setLoading(false)
      }
    }
    if (domainDetailsData?.name) getDomainSSOData(domainDetailsData.name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainDetailsData.name, t])

  const hasPermissionToRead = () => {
    return (
      (isAssociated &&
        v2RbacRootWkspcPolicies?.includes('identity.domain-requests.read')) ||
      (!isAssociated &&
        v2RbacPolicies?.includes('identity.domain-requests.read'))
    )
  }

  const renderActionButton = () => {
    const actions = tableActions(domainDetailsData)
    const policies = isAssociated ? v2RbacRootWkspcPolicies : v2RbacPolicies

    return (
      showV2ActionDropdown(policies, actions) && (
        <ActionButton
          label={t('iam:domains.details.actions_btn')}
          actions={actions}
          reverse
          showOneActionAsDropDown
          testId="Domain-action-btn"
          icon={<FormDown />}
          customRenderer={(actionBtn, visibility) => {
            const hasPermission = isAssociated
              ? v2RbacRootWkspcPolicies?.includes(visibility?.rbac?.permission)
              : v2RbacPolicies?.includes(visibility?.rbac?.permission)
            return hasPermission && <> {actionBtn} </>
          }}
        />
      )
    )
  }

  const renderDomainDetails = () => {
    return (
      <Box>
        <NameValueList
          data-testid="domain-details-name-list"
          pairProps={{ direction: size === 'xsmall' ? 'column' : 'row' }}
        >
          <NameValuePair name={t('iam:domains.details.claim')}>
            {getDomainStatusText(domainDetailsData?.lifeCycleState, t)}
          </NameValuePair>
          <NameValuePair name={t('iam:domains.details.sso_profile')}>
            {(domainDetailsData?.lifeCycleState ===
              lifeCycleStateEnum?.VERIFIED ||
              domainDetailsData?.lifeCycleState ===
                lifeCycleStateEnum?.WEAKLY_CLAIMED) &&
            domainSSoData?.source
              ? domainSSoData.source
              : '--'}
          </NameValuePair>
          <NameValuePair name={t('iam:domains.details.txt_record')}>
            {domainDetailsData?.dnsTxtRecord || ''}
          </NameValuePair>
          <NameValuePair name={t('iam:domains.details.last_verified')}>
            {(() => {
              if (
                domainDetailsData?.lifeCycleState ===
                lifeCycleStateEnum?.VERIFIED
              ) {
                return dayjs(domainDetailsData.lastVerified).format(
                  'MM/DD/YYYY'
                )
              }
              if (
                domainDetailsData?.lifeCycleState ===
                lifeCycleStateEnum?.PENDING
              ) {
                return t('iam:domains.claim_pending_value')
              }
              if (
                domainDetailsData?.lifeCycleState ===
                  lifeCycleStateEnum?.CLAIMED_ELSEWHERE ||
                domainDetailsData?.lifeCycleState ===
                  lifeCycleStateEnum?.WEAKLY_CLAIMED
              ) {
                return '--'
              }
              return null
            })()}
          </NameValuePair>
        </NameValueList>
      </Box>
    )
  }

  return (
    <Page data-testid="domains-details-page" width="xxlarge" alignSelf="center">
      {loading ? (
        <Box direction="row" align="center" justify="center">
          <Loader testId="domain-details-loader" />
        </Box>
      ) : (
        <>
          <PageContent>
            <PageHeader
              title={
                hasPermissionToRead() && (
                  <Typography
                    type="heading"
                    level="1"
                    testId="domain-details-header-title"
                  >
                    {domainDetailsData?.name || ''}
                  </Typography>
                )
              }
              data-testid="domains-details-page-header"
              actions={
                <Box direction="row" gap="medium">
                  {renderActionButton()}
                </Box>
              }
              parent={
                <Anchor
                  label={t('iam:domains.domain_plural')}
                  icon={<Previous data-testid="domain-details-back-btn" />}
                  href="#"
                  onClick={(event) => {
                    event.preventDefault()
                    handleBackBtnClick()
                  }}
                  margin={{ bottom: 'xsmall' }}
                  testId="domains-back-btn"
                />
              }
            />
            {hasPermissionToRead() ? (
              renderDomainDetails()
            ) : (
              <NoViewPermission />
            )}
          </PageContent>
          {showVerifyDomainDialog && (
            <VerifyDomainConfirmation
              domainRequest={domainDetailsData}
              onSetOpen={setShowVerifyDomainDialog}
              setApiError={setNotificationInfo}
            />
          )}
          {showViewTxtModal && (
            <ViewDomainClaimTXTRecord
              domainClaimTXT={domainDetailsData?.dnsTxtRecord}
              domainName={domainDetailsData?.name}
              onSetOpen={setShowViewTxtModal}
            />
          )}
          {showRemoveDomainModal && (
            <RemoveDomainModal
              onSetOpen={setShowRemoveDomainModal}
              domainToRemove={domainDetailsData}
              refreshParent={() => {
                history.push('/manage-account/organization/domains', {
                  deleteDomainName: domainDetailsData?.name
                })
              }}
              setNotificationInfo={setNotificationInfo}
            />
          )}
          {notifMsg &&
            displayNotification(
              notifMsg,
              notifSeverity,
              setNotifMsg,
              notifTitle
            )}
        </>
      )}
    </Page>
  )
}

export default DomainDetails
