import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Typography,
  ModalDialog,
  ModalFooter,
  ModalHeader
} from '../../../../components'

const ProtocolchangeModal = ({ setShow, cb, selectedAuthMethod }) => {
  const { t } = useTranslation(['authn', 'common'])
  return (
    <>
      <ModalDialog
        footer={
          <ModalFooter
            right={
              <Box direction="row" justify="end" gap="medium">
                <Button
                  default
                  size="small"
                  label={t('manage_account.authentication.cancel')}
                  onClick={() => setShow(false)}
                  testId="close-auth-method-modal"
                />
                <Button
                  primary
                  size="small"
                  label={t(
                    'manage_account.authentication.confirm_auth_method_change'
                  )}
                  onClick={() => cb()}
                  testId="confirm-auth-method-change"
                />
              </Box>
            }
          />
        }
        header={
          <ModalHeader
            width="small"
            subtitle=""
            title={
              <Box direction="column" alignContent="center">
                <Typography
                  level="1"
                  testId="header-title"
                  type="heading"
                  weight="bold"
                >
                  {t('manage_account.authentication.change_auth_method')}
                </Typography>
                <Box width="medium">
                  <Typography
                    margin={{ top: 'medium' }}
                    size="medium"
                    testId="auth-change-desc"
                    type="text"
                  >
                    {t('manage_account.authentication.change_auth_method_msg')}
                  </Typography>
                  <Typography
                    margin={{ top: 'medium' }}
                    size="medium"
                    testId="authchange-todesc"
                    type="text"
                  >
                    <>
                      {t('manage_account.authentication.switch_auth_ftext')}
                      <Text
                        size="medium"
                        type="text"
                        weight="bold"
                        testId="authprotocol"
                      >
                        {selectedAuthMethod}
                      </Text>
                      {t('manage_account.authentication.switch_auth_ltext')}
                    </>
                  </Typography>
                </Box>
              </Box>
            }
          />
        }
        onClose={() => {}}
        testId="change-auth-protocol-modal"
      />
    </>
  )
}

ProtocolchangeModal.propTypes = {
  setShow: PropTypes.func.isRequired,
  cb: PropTypes.func.isRequired,
  selectedAuthMethod: PropTypes.string.isRequired
}

export default ProtocolchangeModal
