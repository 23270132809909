import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  Typography,
  ButtonGroup,
  ModalDialog,
  ModalHeader,
  ModalFooter
} from '../../../../components'
import { post } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'

const AutoSubscriptionConfirmationModal = ({
  setModalOpen,
  title,
  description,
  customerId,
  requestData,
  onSuccess
}) => {
  const { t } = useTranslation(['device'])
  const { oidcUser } = useReactOidc()
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const updateAutoSubscribe = () => {
    const url = '/support-assistant/v1alpha1/autolicense'
    post(
      url,
      {
        platform_customer_id: customerId,
        device_subscription_list: [requestData]
      },
      oidcUser.access_token
    ).then(
      () => {
        onSuccess()
      },
      (error) => {
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
  }
  return (
    <ModalDialog
      width="medium"
      testId="confirmation-modal"
      onClose={() => setModalOpen(false)}
      header={
        <ModalHeader
          title={
            <Typography
              level="1"
              testId="confirmation-modal-title"
              type="heading"
              color="text-strong"
              weight="bold"
            >
              {title}
            </Typography>
          }
        />
      }
      content={
        <>
          <Typography
            size="medium"
            margin={{ top: 'small' }}
            testId="confirmation-modal-description"
            type="text"
            color="text-strong"
          >
            {description}
          </Typography>
          {showErrorNotification}
        </>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: () => {
                    setModalOpen(false)
                  }
                },
                {
                  id: 1,
                  label: t('ok'),
                  primary: true,
                  testId: 'okay-btn',
                  onClick: () => {
                    updateAutoSubscribe()
                  }
                }
              ]}
              testId="confirmation-modal-action-buttons"
            />
          }
        />
      }
    />
  )
}

AutoSubscriptionConfirmationModal.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  requestData: PropTypes.object.isRequired
}

export { AutoSubscriptionConfirmationModal }
