import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ShareRounded } from 'grommet-icons'
import { useHistory } from 'react-router'

import { Typography, AvatarInfo, Button } from '../../../../../../components'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

const AssignedUsersTab = ({ roleDetails }) => {
  const { t } = useTranslation(['manage'])
  const { users } = roleDetails
  const history = useHistory()

  const getUserFullName = (user) => {
    const firstName = user?.user_first_name
    const lastName = user?.user_last_name
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    return firstName || lastName || user?.user_name
  }
  return (
    <>
      <Box
        fill
        gap="medium"
        direction="row"
        justify="between"
        pad={{ bottom: 'medium' }}
        margin={{ bottom: 'small', top: 'large' }}
      >
        <Typography
          level="2"
          type="heading"
          weight="normal"
          testId="dynamic-view-edit-users-form-title"
        >
          <>
            {t('customer_roles.assigned_users')} ({users?.length || 0})
          </>
        </Typography>
      </Box>
      {users.length > 0 && (
        <Box>
          {users.map((user) => (
            <Box
              gap="small"
              pad="xsmall"
              align="center"
              direction="row"
              justify="between"
              key={user?.user_name}
              margin={{ bottom: 'small' }}
            >
              <AvatarInfo
                avatarChar="two"
                testId="user-info-avatar-only"
                primaryInfo={getUserFullName(user)}
                secondaryInfo={user?.user_name}
              />
              <VisibilityWrapper
                rbac={{
                  permission: 'ccs.accounts.users.view.all',
                  resource: '/ccs/accounts/user'
                }}
              >
                <Button
                  type="button"
                  icon={<ShareRounded />}
                  testId="open-user-button"
                  onClick={() =>
                    history.push(
                      `/manage-ccs/customers/users/${user?.user_name}`
                    )
                  }
                />
              </VisibilityWrapper>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}

AssignedUsersTab.propTypes = {
  roleDetails: PropTypes.object.isRequired
}

export { AssignedUsersTab }
