import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, CheckBox, Text } from 'grommet'
import { Alert } from 'grommet-icons'

import {
  Button,
  ModalDialog,
  ModalHeader,
  ModalFooter,
  Typography,
  Card
} from '../../../components'

const DeleteGatewayModal = ({ setShowModal, deleteGateway, gatewayName }) => {
  const { t } = useTranslation(['local-gateway', 'common'])
  const [disabledDelete, setDisabledDelete] = useState(true)

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Box pad="small" align="center">
              <Alert color="red" size="large" />
              <Typography
                type="heading"
                level="1"
                testId="delete-modal-header-title"
              >
                {t('gateway_details.delete')}
              </Typography>
            </Box>
          }
        />
      }
      content={
        <Box
          height="100%"
          align="center"
          justify="between"
          pad={{ top: 'medium' }}
        >
          <Box>
            <Typography
              type="paragraph"
              size="medium"
              testId="delete-gateway-text"
              textAlign="center"
            >
              {t('gateway_details.delete_gateway_modal_text')}
              &nbsp;
              <Text
                type="text"
                size="medium"
                testId="delete-gateway-name-text"
                weight="bold"
              >
                {gatewayName}
              </Text>
              <Text
                type="text"
                size="medium"
                testId="delete-gateway-text-question"
              >
                ?
              </Text>
            </Typography>
          </Box>
          <Card
            border={{
              color: 'border-weak'
            }}
            testId="delete-gateway-card"
            CustomContent={
              <Box direction="row" align="center">
                <CheckBox
                  value={!disabledDelete}
                  onClick={() => setDisabledDelete(!disabledDelete)}
                  data-testid="delete-checkbox"
                />
                <Typography
                  type="text"
                  size="small"
                  testId="delete-gateway-confirmation-text"
                >
                  {t('gateway_details.delete_gateway_confirmation_text')}
                </Typography>
              </Box>
            }
          />
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <Box direction="row" gap="small">
              <Button
                label={t('gateway_details.keep_gateway')}
                testId="cancel-btn"
                onClick={() => setShowModal(false)}
                secondary
              />
              <Button
                margin={{ left: 'small' }}
                label={t('gateway_details.delete')}
                primary
                testId="delete-btn"
                disabled={disabledDelete}
                onClick={() => {
                  deleteGateway()
                  setShowModal(false)
                }}
              />
            </Box>
          }
        />
      }
      onClose={() => setShowModal(false)}
      testId="delete-gateway-modal-dialog"
    />
  )
}

DeleteGatewayModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  deleteGateway: PropTypes.func.isRequired,
  gatewayName: PropTypes.string.isRequired
}

export default DeleteGatewayModal
