import { useReducer } from 'react'

import AccountTypeActions from './AccountTypesActions'

const initialContext = {
  accountConversionStep: 0
}

const accountTypeReducer = (state, action) => {
  switch (action.type) {
    case AccountTypeActions.SET_ACCOUNT_CONVERSION_STEP:
      return {
        ...state,
        accountConversionStep: action.data
      }
    case AccountTypeActions.SET_ACCOUNT_CONVERSION_STATUS:
      return {
        ...state,
        accountConversionStatus: action.data
      }
    default:
      return state
  }
}

const useAccountTypeReducer = () => {
  const [accountTypeState, dispatchAccountTypeContext] = useReducer(
    accountTypeReducer,
    initialContext
  )

  return {
    accountTypeState,
    dispatchAccountTypeContext
  }
}

export default useAccountTypeReducer
