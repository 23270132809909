import React from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '../../../components'
import { ThemeMode } from '../../../components/ThemeMode'
import { Layout } from '../../commoncomponents/layout/Layout'

// Below component will be used by OIDC when fetching Token fails.
// If we are not providing this component client library will use default component
// default components provided by library are in french
const NotAuthenticatedContent = () => {
  const { t } = useTranslation(['authn'])

  return (
    <Box
      align="center"
      justify="center"
      alignContent="center"
      data-testid="not-authenticated"
    >
      <Typography
        margin={{ top: 'large', bottom: 'small' }}
        type="heading"
        level="1"
        testId="not-authenticated-title"
      >
        {t('not_authenticated.not_auth')}
      </Typography>
      <Typography
        type="text"
        size="xxlarge"
        testId="not-authenticated-subtitle"
      >
        {t('not_authenticated.not_auth_msg')}
      </Typography>
      <Button
        primary
        label={t('not_authenticated.back_to_sign_in')}
        size="large"
        margin={{ vertical: 'small' }}
        onClick={() => {
          window.location.replace('/sign-out')
        }}
        testId="back-to-signin"
      />
    </Box>
  )
}

const NotAuthenticated = () => {
  return (
    /* Wrapping up with grommet theme because,
       App will not be initialized when this comp is displayed
       This component will be displayed by OIDC library.
    */
    <ThemeMode>
      <Layout hideHeaderOptions={['no-header']}>
        <NotAuthenticatedContent />
      </Layout>
    </ThemeMode>
  )
}

export default NotAuthenticated
