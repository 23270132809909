let isStartDrag = false
let position3 = 0
let position4 = 0
let chatContainerRef

const onDrag = (event) => {
  event.preventDefault()
  const { clientX, clientY } = event

  // Calculate the difference between the new and previous mouse positions
  const deltaX = position3 - clientX
  const deltaY = position4 - clientY

  // Update the previous mouse positions
  position3 = clientX
  position4 = clientY

  // Calculate the new container position
  const newTop = chatContainerRef.current.offsetTop - deltaY
  const newLeft = chatContainerRef.current.offsetLeft - deltaX
  // Determine the boundaries
  const topBoundary = 0
  const bottomBoundary =
    window.innerHeight - chatContainerRef.current.offsetHeight
  const leftBoundary = 0
  const rightBoundary = window.innerWidth - chatContainerRef.current.offsetWidth

  // Check and update the container position within the boundaries
  chatContainerRef.current.style.top = `${Math.min(
    Math.max(newTop, topBoundary),
    bottomBoundary
  )}px`
  chatContainerRef.current.style.left = `${Math.min(
    Math.max(newLeft, leftBoundary),
    rightBoundary
  )}px`

  chatContainerRef.current.style.opacity = 0.5
}

const stopDrag = (e) => {
  e.preventDefault()
  chatContainerRef.current.style.opacity = 1
  isStartDrag = false
  window.removeEventListener('mousemove', onDrag)
  window.removeEventListener('mouseup', stopDrag)
}

const startDrag = (e, ref) => {
  e.preventDefault()
  isStartDrag = true
  chatContainerRef = ref
  position3 = e.clientX
  position4 = e.clientY
  if (isStartDrag) {
    window.addEventListener('mousemove', onDrag)
    window.addEventListener('mouseup', stopDrag)
  }
}

export { startDrag, onDrag, stopDrag }
