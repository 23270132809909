import { buildUrlManager, buildSearchStatus } from '@glcp/coveo-headless'

export const urlManagerController = (engine) => {
  const getFragment = () => window.location.hash.slice(1)

  const urlManager = buildUrlManager(engine, {
    initialState: { fragment: getFragment() }
  })

  const statusControllers = buildSearchStatus(engine)

  const onHashChange = () => {
    urlManager.synchronize(getFragment())
  }

  window.addEventListener('hashchange', onHashChange)

  const unsubscribeManager = urlManager.subscribe(() => {
    const hash = `#${urlManager.state.fragment}`

    if (!statusControllers.state.firstSearchExecuted) {
      // The purpose of using `replaceState()` instead of `pushState()` in this case is to ensure that the URL reflects the current state of the search page on the first interface load.

      // If `pushState()` were used instead, users could possibly enter in a history loop, having to click the back button multiple times without being able to return to a previous page.
      // This situation happens with components such as the Tab component, which adds a new state to the browser history stack.

      // `replaceState` instead replaces the current state of the browser history with a new state, effectively updating the URL without adding a new entry to the history stack.
      // See https://docs.coveo.com/en/headless/latest/usage/synchronize-search-parameters-with-the-url/
      window.history.replaceState(null, document.title, hash)
      return
    }

    window.history.pushState(null, document.title, hash)
  })

  return () => {
    window.removeEventListener('hashchange', onHashChange)
    unsubscribeManager()
  }
}
