import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  ButtonGroup
} from '../../../../components'

import { EditLocationModalContent } from './EditLocationModalContent'

const EditLocationModal = ({
  originalName,
  handleClose,
  updateHandler,
  description,
  type,
  name,
  setName,
  setDescription,
  setRequestBody,
  locNameFieldError,
  setLocNameFieldError,
  locDescriptionFieldError,
  setLocDescriptionFieldError
}) => {
  const { t } = useTranslation(['location'])

  return (
    <ModalDialog
      width="medium"
      header={
        <ModalHeader
          title={
            <>
              <Typography
                size="small"
                testId="header-title"
                type="text"
                weight="normal"
              >
                {originalName}
              </Typography>
              <Typography level={2} testId="header-title" type="heading">
                {t('edit_location_detail')}
              </Typography>
            </>
          }
        />
      }
      content={
        <EditLocationModalContent
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
          type={type}
          setRequestBody={setRequestBody}
          locNameFieldError={locNameFieldError}
          setLocNameFieldError={setLocNameFieldError}
          locDescriptionFieldError={locDescriptionFieldError}
          setLocDescriptionFieldError={setLocDescriptionFieldError}
        />
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  testId: 'cancel-btn',
                  onClick: handleClose
                },
                {
                  id: 1,
                  label: t('save'),
                  primary: true,
                  testId: 'save-btn',
                  onClick: updateHandler
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={handleClose}
      testId="edit-location-modal"
    />
  )
}

EditLocationModal.propTypes = {
  originalName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  setRequestBody: PropTypes.func.isRequired,
  locNameFieldError: PropTypes.string.isRequired,
  setLocNameFieldError: PropTypes.func.isRequired,
  locDescriptionFieldError: PropTypes.string.isRequired,
  setLocDescriptionFieldError: PropTypes.func.isRequired
}

export { EditLocationModal }
