// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, PageHeader, Heading } from 'grommet'
import { Previous } from 'grommet-icons'

import { Layout } from '../../commoncomponents/layout/Layout'
import { Anchor, Typography, Button, DataTable } from '../../../components'
import { get, getErrorMessage } from '../../../utils/api-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import { useVisibilityContext } from '../../../context/visibility-context'
import NoViewPermission from '../commoncomponents/NoViewPermission'
import {
  getOrganizationId,
  isAssociateWorkspace
} from '../../../utils/feature-flag-utils'

import { CreateWorkspaceSideDrawer } from './components/CreateWorkspace'

const Workspaces = () => {
  const { v2RbacPolicies, v2RbacRootWkspcPolicies } = useVisibilityContext()
  const v2WorkspaceReqReadPerm = v2RbacPolicies?.includes(
    'organization.workspace.read'
  )
  const history = useHistory()
  const { t } = useTranslation(['common', 'iam'])
  const [loading, setLoading] = useState(false)
  const [refreshCount] = useState(0)
  const [workspacesData, setWorkspacesData] = useState({ items: null })

  // Query parameters
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState('workspaceName asc')

  // Notifications
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')
  const setNotificationInfo = (message, severity, title = '') => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  const handleRowClick = (datum) => {
    history.push(`/manage-account/organization/workspaces/${datum?.id}`)
  }
  const [isAssociated, setIsAssociated] = useState(false)
  const rootWkspReadPerm = v2RbacRootWkspcPolicies?.includes(
    'organization.workspace.read'
  )
  const location = useLocation()
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [status, setStatus] = useState(false)
  const [subtitle, setSubtitle] = useState('')
  const openSidePanel = () => setShowSidePanel(true)
  const getPageIndexInfo = () => {
    const total = workspacesData?.total || 0
    if (total > 0) {
      const startIndex = 1 + (page - 1) * itemsPerPage
      const endIndex = itemsPerPage * page < total ? itemsPerPage * page : total
      return t('iam:domains.data_table_showing_label', {
        startIndex,
        endIndex,
        total
      })
    }
    return ''
  }

  const columns = [
    {
      property: 'workspaceName',
      header: t('iam:roles_table_headers.name'),
      primary: true,
      sortable: true,
      render: (datum) => {
        return (
          <Typography type="text" testId={`workspace-${datum?.id}`}>
            {datum?.workspaceName}
          </Typography>
        )
      }
    },
    {
      property: 'description',
      header: t('iam:roles_table_headers.description'),
      sortable: false,
      render: (datum) => {
        return <Typography type="text">{datum?.description || '--'}</Typography>
      }
    }
  ]

  useEffect(() => {
    const orgId = getOrganizationId()
    if (orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (res) => {
          if (res?.data && res?.data?.associatedWorkspace) {
            setIsAssociated(
              isAssociateWorkspace(res?.data?.associatedWorkspace?.id)
            )
          }
        },
        (err) => {
          setNotificationInfo(getErrorMessage(err, t), 'error')
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location?.state?.showSidePanel) {
      setShowSidePanel(true)
      window.history.replaceState({}, '')
    }
  }, [location])

  useEffect(() => {
    setLoading(true)
    const orgId = getOrganizationId()
    get(`/organizations/v2alpha1/organizations/${orgId}`)
      .then((response) => {
        setSubtitle(
          response.data.name
            ? t('iam:igc_workspaces.subtitle', {
                organization: response.data.name
              })
            : t('iam:igc_workspaces.subtitle_default')
        )
      })
      .catch(() => {
        setSubtitle(t('iam:igc_workspaces.subtitle_default'))
      })
      .finally(() => {
        setLoading(false)
      })
    const sortQuery = sort ? `&sort=${encodeURIComponent(sort)}` : ''
    get(
      `/organizations/v2alpha1/workspaces?offset=${
        (page - 1) * itemsPerPage
      }&limit=${itemsPerPage}${sortQuery}`
    )
      .then((response) => {
        if (response?.data) {
          setWorkspacesData(response?.data)
        }
      })
      .catch((error) => {
        setNotificationInfo(getErrorMessage(error, t), 'error')
        setWorkspacesData({ items: [] })
        setLoading(false)
      })
    setLoading(false)
  }, [page, itemsPerPage, refreshCount, sort, status, t])

  const handleBackBtnClick = () => {
    history.push('/manage-account/organization')
  }

  return (
    <Box
      data-testid="igc-workspaces-page"
      pad={{ horizontal: 'xlarge', bottom: 'large' }}
      width="xxlarge"
      alignSelf="center"
    >
      <PageHeader
        responsive
        title={
          <Heading
            level="1"
            type="heading"
            margin="none"
            data-testid="igc-workspaces-page-title"
          >
            {t('iam:organization.workspace_card_title', {
              workspaces: t('common:business_object.wkspc_plural')
            })}
          </Heading>
        }
        subtitle={
          <Box width="large">
            {((isAssociated &&
              v2RbacRootWkspcPolicies?.includes(
                'organization.workspace.read'
              )) ||
              (!isAssociated &&
                v2RbacPolicies?.includes('organization.workspace.read'))) && (
              <Typography
                normalize
                type="text"
                size="large"
                testId="igc-workspaces-page-subtitle"
              >
                <>{subtitle}</>
              </Typography>
            )}
          </Box>
        }
        parent={
          <Anchor
            label={t('iam:organization.governance_title')}
            icon={<Previous />}
            href="#"
            onClick={(event) => {
              event.preventDefault()
              handleBackBtnClick()
            }}
            margin={{ bottom: 'xsmall' }}
            testId="igc-workspaces-back-btn"
          />
        }
      />
      {(!isAssociated && v2WorkspaceReqReadPerm) ||
      (isAssociated && rootWkspReadPerm) ? (
        <>
          <Box direction="row" width="xxlarge" justify="end">
            {((isAssociated &&
              v2RbacRootWkspcPolicies?.includes(
                'organization.workspace.create'
              )) ||
              (!isAssociated &&
                v2RbacPolicies?.includes('organization.workspace.create'))) && (
              <Button
                secondary
                label={t('iam:igc_workspaces.create_workspace_btn')}
                onClick={openSidePanel}
                testId="igc-create-workspace-btn"
              />
            )}
          </Box>
          <DataTable
            summary={{
              entityName:
                workspacesData?.items?.length === 1
                  ? t('common:item_singular')
                  : t('common:item_plural')
            }}
            grid={{
              columns,
              data: workspacesData?.items,
              onClickRow: (event) => {
                handleRowClick(event?.datum)
              }
            }}
            loading={loading}
            pagination={{
              totalItems: workspacesData?.total || 0,
              itemsPerPage,
              setItemsPerPage,
              page,
              setPage,
              rowDropDownLabel: t('iam:domains.row_drop_down_label'),
              pageIdxInfo: getPageIndexInfo(),
              rowCountOptions: [20, 50, 100],
              testId: 'igc-workspaces-pagination'
            }}
            onSort={({ property, direction }) => {
              setSort(`${property} ${direction}`)
            }}
            testId="igc-workspaces-datatable"
          />
          {notifMsg &&
            displayNotification(
              notifMsg,
              notifSeverity,
              setNotifMsg,
              notifTitle
            )}
          {showSidePanel && (
            <CreateWorkspaceSideDrawer
              setStatus={setStatus}
              setLoading={setLoading}
              onClose={(e) => setShowSidePanel(e)}
            />
          )}
          {status
            ? displayNotification(
                status?.message,
                status?.severity,
                setStatus,
                status?.title
              )
            : null}
        </>
      ) : (
        <NoViewPermission />
      )}
    </Box>
  )
}

const WorkspacesPage = () => {
  return (
    <Layout>
      <Workspaces align="start" justify="start" />
    </Layout>
  )
}

export default WorkspacesPage
