import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Layer, Footer } from 'grommet'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { Button, Loader, Typography } from '../../../../components'
import {
  AUTHZActions,
  useAUTHZContext
} from '../../../../context/authz-context'
import { get } from '../../../../utils/api-utils'
import { useVisibilityContext } from '../../../../context/visibility-context'
import { treeBuilder, getPermissionsData } from '../../treeBuilder/treeBuilder'
import {
  getSlugData,
  createResourcePolicy,
  getCCSAppDetails
} from '../../../manage-account/utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { displayNotification } from '../../../../utils/notificiation-utils'
import RolesContext from '../../../manage-account/identity/roles/create-role/RolesContext'
import PermissionsBody from '../../../manage-account/identity/roles/create-role/PermissionsBody'

const NewPermissionsModal = ({
  setOpen,
  open,
  selectedRole,
  setRole,
  setPolicies,
  rolesPagePolicies = [],
  inCCSManager
}) => {
  const { t } = useTranslation(['authz'])
  const {
    permissionsSelected,
    selectedRoleUpdateForm,
    mandatoryResPermissions,
    dispatchAUTHZContext
  } = useAUTHZContext()
  const { rbacPolicies } = useVisibilityContext()
  const { ccsApplicationId } = getCCSAppDetails()
  const [data, setData] = useState([])
  const [value, setValue] = useState(permissionsSelected || [])
  const onClose = () => setOpen(undefined)
  const [showNotification, setShowNotification] = useState(null)
  const [loading, setLoading] = useState(true)

  const { application_id, application_name } = selectedRole
  const args = {
    mode: 'column',
    title: `${application_name} ${t('assignments.permission_plural')}`,
    subtitle: t('roles.permissions_subtitle'),
    testId: 'role-permissions',
    selectionText: t('roles.view_selected_permissions')
  }
  const { title, subtitle } = args

  const { oidcUser } = useReactOidc()

  const isActivateEditAllowed = useCallback(() => {
    const rbac = {
      permission: 'ccs.activate.edit',
      resource: '/ccs/activate/customer'
    }
    return rbacPolicies?.effects?.[rbac.resource]?.[rbac.permission]
  }, [rbacPolicies.effects])

  const createPermissions = useCallback(() => {
    dispatchAUTHZContext({
      type: AUTHZActions.PERMISSIONS_SELECTED,
      data: value
    })
    dispatchAUTHZContext({
      type: AUTHZActions.SET_PERMISSIONS_DATA,
      data
    })
  }, [value, data, dispatchAUTHZContext])

  if (!application_id) {
    throw new Error('Application is not selected')
  }
  const createResourcePolicyFromResult = useCallback(
    (result) => {
      const newResourcePolicies = createResourcePolicy(result, [])
      if (!selectedRole.resourcePolicies) {
        selectedRole.resourcePolicies = []
      }
      selectedRole.resourcePolicies = newResourcePolicies
      selectedRoleUpdateForm.resource_policies.update =
        selectedRole.resourcePolicies

      dispatchAUTHZContext({
        type: AUTHZActions.SET_SELECTED_ROLE_UPDATE_FORM,
        data: selectedRoleUpdateForm
      })
      setRole({ ...selectedRole })
      setPolicies(newResourcePolicies)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRole]
  )
  const validateMandatory = useCallback(() => {
    const mandatoryResNoPermission = []
    if (!inCCSManager) {
      mandatoryResPermissions.forEach((each) => {
        if (
          !value.find((key) => {
            const res = key?.split('~')[0] || ''
            return res === each
          })
        ) {
          mandatoryResNoPermission.push(each)
        }
      })
      if (mandatoryResNoPermission.length > 0) {
        setShowNotification(
          displayNotification(
            t('roles.mandatory_resource_no_permission', {
              resource: mandatoryResNoPermission?.join(', ')
            }),
            'warning',
            setShowNotification
          )
        )
        return false
      }
    }
    return true
  }, [value, mandatoryResPermissions, setShowNotification, t, inCCSManager])

  const addPermissions = useCallback(() => {
    if (validateMandatory()) {
      createPermissions()
      const result = value.reduce((accumulator, permissionSlug) => {
        return accumulator.concat([getSlugData(data, permissionSlug)])
      }, [])
      dispatchAUTHZContext({
        type: AUTHZActions.SET_TREE_ROLE_PERMISSIONS,
        data: result.filter((elem) => elem !== null)
      })
      setOpen(false)
      createResourcePolicyFromResult(result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPermissions, value, data, setOpen])
  useEffect(() => {
    let isCurrent = true
    setLoading(true)
    get(
      `/authorization/ui/v1/applications/${application_id}/application_resources`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        setLoading(false)
        if (!isCurrent) return
        let responseData = response?.data
        if (application_id === ccsApplicationId) {
          // activate TAC services should not be shown here, it is only available in ccs-manager
          // activate customer services should be available only for users with activate edit permission
          if (isActivateEditAllowed())
            responseData = response?.data?.filter(
              (val) => val.slug !== '/ccs/activate/tac'
            )
          else
            responseData = response?.data?.filter(
              (val) =>
                val.slug !== '/ccs/activate/tac' &&
                val.slug !== '/ccs/activate/customer'
            )
        }
        setData(treeBuilder(responseData))
        const permissionData = getPermissionsData(responseData)
        // store mandatory res data to context
        dispatchAUTHZContext({
          type: AUTHZActions.SET_MANDATORY_RES_PERMISSIONS,
          data: permissionData?.mandatoryPermission || []
        })
        let defaultSelectedValue = []

        if (rolesPagePolicies?.length === 0) {
          defaultSelectedValue = permissionData.defaultPermission
        }

        const slugsArr = []
        if (rolesPagePolicies?.length > 0) {
          rolesPagePolicies?.forEach((resourceData) => {
            resourceData.permissions.forEach((permission) => {
              slugsArr.push(
                `${resourceData.resource.matcher}~${permission.slug}`
              )
            })
          })
        }
        const uniqueArr = [...new Set(defaultSelectedValue.concat(slugsArr))]
        if (uniqueArr.length > 0) {
          setValue(uniqueArr)
          dispatchAUTHZContext({
            type: AUTHZActions.PERMISSIONS_SELECTED,
            data: uniqueArr
          })

          const result = uniqueArr.reduce((accumulator, permissionSlug) => {
            return accumulator.concat([
              getSlugData(treeBuilder(response.data), permissionSlug)
            ])
          }, [])

          dispatchAUTHZContext({
            type: AUTHZActions.SET_TREE_ROLE_PERMISSIONS,
            data: result.filter((elem) => elem !== null)
          })
        }
      },
      (error) => {
        setLoading(false)
        setShowNotification(displayApiError(error, t, setShowNotification))
      }
    )
    return () => {
      isCurrent = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    application_id,
    rolesPagePolicies,
    ccsApplicationId,
    isActivateEditAllowed
  ])

  return loading ? (
    <Loader testId="new-permissions-modal-loader" />
  ) : (
    <>
      {showNotification}
      {open && (
        <Layer position="center" animate responsive modal onEsc={onClose}>
          <Box gap="medium" pad="medium">
            <Box flex={false} gap="xsmall" margin={{ bottom: 'medium' }}>
              <Typography level={2} type="heading" testId="page-title">
                {title}
              </Typography>
              <Typography
                size="large"
                type="text"
                weight="normal"
                testId="page-subtitle"
              >
                {subtitle}
              </Typography>
            </Box>
            <PermissionsBody
              {...args}
              data={data}
              RolesContext={RolesContext}
              value={value}
              setValue={setValue}
            />
            <Footer align="center" justify="end" gap="small">
              <Button
                default
                label={t('cancel')}
                onClick={() => {
                  setOpen(false)
                }}
                testId="permission-modal-cancel-btn"
              />
              <Button
                primary
                label={t('add')}
                onClick={addPermissions}
                testId="permissions-create-btn"
              />
            </Footer>
          </Box>
        </Layer>
      )}
    </>
  )
}

export default NewPermissionsModal

NewPermissionsModal.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  application: PropTypes.object,
  selectedRole: PropTypes.any,
  setRole: PropTypes.func,
  setPolicies: PropTypes.func,
  rolesPagePolicies: PropTypes.array,
  inCCSManager: PropTypes.bool
}
NewPermissionsModal.defaultProps = {
  setOpen: () => {},
  open: false,
  application: {
    selectedApplicationName: '',
    applicationId: ''
  },
  selectedRole: {},
  setRole: null,
  setPolicies: null,
  rolesPagePolicies: [],
  inCCSManager: false
}
