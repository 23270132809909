import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

const SupportAccessPage = lazy(() => import('./SupportAccessPage'))

const SupportAssistantRouterContent = () => {
  const { path } = useRouteMatch()
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route path={`${path}`} exact>
          <SupportAccessPage />
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  )
}

const SupportAssistantRouter = () => {
  return <SupportAssistantRouterContent />
}

export default SupportAssistantRouter
