import React, { useContext } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { Edit } from 'grommet-icons'

import { Button, Notification, Typography } from '../../../../components'
import { WizardContext } from '../../../../components/wizard/WizardContext'
import { isSelectionErrorExist } from '../../utils'

const ReviewAndSubmit = () => {
  const { formValues, setActiveStep } = useContext(WizardContext)
  const { t } = useTranslation(['licensing', 'common', 'device'])

  const navigateToStep = (stepEnum) => {
    // stepEnum = 1 -> subscriptions, stepEnum = 2 -> devices
    setActiveStep(stepEnum)
  }

  return (
    <>
      <Box margin={{ top: 'medium', bottom: 'large' }} border="horizontal">
        <Box
          margin="small"
          direction="row"
          justify="between"
          data-testid="subscription-card"
        >
          <Box gap="xsmall">
            <Typography
              type="text"
              size="large"
              weight="bold"
              testId="subscriptions-header"
            >
              {t('subscriptions')}
            </Typography>
            <Typography type="text" testId="subscription-count" size="small">
              <>
                {t('common:selected_option_label', {
                  count: formValues?.selectedProductList?.length
                })}
              </>
            </Typography>
          </Box>
          <Box align="center" pad="small">
            <Button
              label={t('common:edit')}
              icon={<Edit size="small" />}
              testId="edit-subscriptions-btn"
              onClick={() => navigateToStep(1)}
              reverse
            />
          </Box>
        </Box>

        <Box border="top" />

        <Box
          margin="small"
          direction="row"
          justify="between"
          data-testid="device-card"
        >
          <Box gap="xsmall">
            <Typography
              type="text"
              size="large"
              weight="bold"
              testId="devices-header"
            >
              {t('device:devices_tab')}
            </Typography>
            <Typography type="text" testId="device-count" size="small">
              <>
                {t('common:selected_option_label', {
                  count: formValues?.selectedDevicesList?.length
                })}
              </>
            </Typography>
          </Box>
          <Box align="center" pad="small">
            <Button
              label={t('common:edit')}
              icon={<Edit size="small" />}
              testId="edit-devices-btn"
              onClick={() => navigateToStep(2)}
              reverse
            />
          </Box>
        </Box>
      </Box>
      {isSelectionErrorExist(formValues) && (
        <Box margin={{ bottom: 'medium' }}>
          <Notification
            backgroundColor="status-critical"
            testId="selection-required-error"
            text={t('add_products.selection_validation_error_message')}
            type="inline"
          />
        </Box>
      )}
    </>
  )
}

export { ReviewAndSubmit }
