import { React, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

import OnPremWorkspaces from './OnPremWorkspaces'
import { OnPremWorkspaceDetails } from './onprem-workspace-details/OnPremWorkspaceDetails'

const OnPremWorkspaceRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route exact path="/manage-account/on-prem-workspaces/:workspaceRId">
          <OnPremWorkspaceDetails />
        </Route>
        <Route exact path="/manage-account/on-prem-workspaces">
          <OnPremWorkspaces />
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  )
}

export default OnPremWorkspaceRouter
