import { Box, ResponsiveContext } from 'grommet'
import { useContext } from 'react'
import PropTypes from 'prop-types'
import { ChatOption, Close } from 'grommet-icons'
import styled from 'styled-components'

import { ChatActions } from './context/chat-context'

const HoverButton = styled(Box)`
  z-index: 2; /* Adjust this value based on your layout */

  &:hover [data-testid='chat-bot-logo'] {
    background-image: linear-gradient(
      to bottom left,
      rgba(0, 133, 103, 1),
      rgba(1, 169, 130, 0.7),
      rgba(0, 133, 103, 1)
    );
  }
  &:focus {
    outline: none;
  }
`

const ChatBotLogo = ({ openChatBot, close }) => {
  const size = useContext(ResponsiveContext)

  const onOpenChatBot = () => {
    openChatBot({ type: ChatActions.OPEN_CHAT, payload: { open: true } })
  }

  return (
    <HoverButton
      style={{
        position: 'fixed',
        bottom: '10px',
        right: '10px'
      }}
    >
      <Box
        margin="medium"
        width="3rem"
        height="3rem"
        style={{
          cursor: 'pointer',
          position:
            size === 'xsmall' || size === 'small' ? 'fixed' : 'undefined',
          right: size === 'xsmall' || size === 'small' ? '0' : 'undefined',
          bottom: size === 'xsmall' || size === 'small' ? '0' : 'undefined'
        }}
        round="full"
        background="#01A982"
        align="center"
        data-testid="chat-bot-logo"
        justify="center"
        onClick={onOpenChatBot}
      >
        {close ? (
          <Close color="white" size="small" data-testid="chatbot-close-icon" />
        ) : (
          <ChatOption
            color="white"
            size="20px"
            data-testid="chatbot-open-icon"
          />
        )}
      </Box>
    </HoverButton>
  )
}

ChatBotLogo.propTypes = {
  openChatBot: PropTypes.func.isRequired,
  close: PropTypes.bool
}

ChatBotLogo.defaultProps = {
  close: false
}

export default ChatBotLogo
