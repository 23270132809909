import {
  Anchor,
  Box,
  Button,
  Card,
  CardBody,
  Grid,
  Heading,
  Paragraph,
  Tag,
  Text,
  ThemeContext
} from 'grommet'
import React, { useContext, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { groupBy } from 'lodash'
import { Apps, AppsRounded, List, StatusCritical } from 'grommet-icons'
import { useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  useAxiosAuth,
  useRecentServices,
  useNewServiceRedirect as useServiceRedirect,
  useLocalStorage,
  useRegions
} from '../../../dashboard/hooks'
import { isMSP, isOrgWorkSpace } from '../../../utils/feature-flag-utils'
import { getErrorMessage } from '../../../utils/api-utils'
import { localStorageWhitelistWithoutPII } from '../../../utils/local-storage-utils'
import { Loader, Notification, DataTable } from '../../../components'
import {
  CATEGORY,
  DISPLAY_RECENT_SERVICES_LENGTH
} from '../../../dashboard/constants'
import { isServiceEnabled } from '../../../dashboard/utils'
import NewServiceLaunchModal from '../../../dashboard/components/NewServiceLaunchModal'

const NewRecentServices = () => {
  const axios = useAxiosAuth()
  useRegions()
  const [recentServices, setRecentServices] = useRecentServices()
  const { t, i18n } = useTranslation(['dashboard'])

  const [isLoading, setIsLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const [provisionsOfSelectedService, setProvisionsOfSelectedService] =
    useState([])

  const [recentServicesBySlug, setRecentServicesBySlug] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  const serviceRedirect = useServiceRedirect()
  const { global } = useContext(ThemeContext)
  const LDFlags = useFlags()
  const [recentServicesView, setRecentServicesView] = useLocalStorage(
    localStorageWhitelistWithoutPII.RECENT_SERVICES_VIEW,
    'grid'
  )

  const categoryMapper = (categories) =>
    categories[0] ?? CATEGORY.MANAGEMENT_AND_GOVERNANCE
  const headingTitle = t('dashboard.recent_services.title')
  const history = useHistory()

  const dateSort = (a, b) => {
    return Date.parse(b) - Date.parse(a)
  }

  const filterService = (slug) =>
    isServiceEnabled(slug, LDFlags) && (!isOrgWorkSpace() || slug === 'PCE')

  useEffect(() => {
    axios
      .get('/service-catalog/v1alpha1/my-services')
      .then(({ data: { items } }) => {
        // TO-DO code cleanup iam
        // added code to control igc as service
        // while code cleanup clean remove the code and handle in api
        items?.forEach((category) => {
          category.provisions = category?.provisions?.filter(
            ({ slug }) => slug !== 'IGC'
          )
        })
        setRecentServicesBySlug(
          groupBy(
            items?.flatMap((service) => [
              ...service.provisions.map((region) => ({
                ...region,
                regionName: service.region
              }))
            ]),
            'slug'
          ) || {}
        )
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error, t))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [axios, t, setErrorMessage])

  const recentServicesData = useMemo(() => {
    // adding serviceSlug and category to work with service launch modal
    // change last accessed when available in api
    recentServices.forEach((service) => {
      if (
        service.serviceSlug &&
        service.utc &&
        recentServicesBySlug[service.serviceSlug] &&
        // TO-DO code cleanup iam
        // added code to control igc as service
        // while code cleanup clean remove the code and handle in api
        // adding this due to avoid localstorage IGC
        !['IGC'].includes(service.serviceSlug)
      ) {
        const dateUtc = new Date(service.utc).toISOString()

        recentServicesBySlug[service.serviceSlug][0].utc = dateUtc

        recentServicesBySlug[service.serviceSlug] = [
          ...recentServicesBySlug[service.serviceSlug]
        ]
      }
    })

    const slicedRecentServices = Object.keys(recentServicesBySlug)
      .map((key) => {
        return {
          slug: key,
          date: recentServicesBySlug[key]
            // TODO: wait for new API format for more details
            // after receiving api details update the date
            ?.map(({ utc }) => utc)
            .sort(dateSort)[0]
        }
      })
      .map((value) => {
        return { ...value, date: value.date ?? new Date(0).toISOString() }
      })
      .sort((a, b) => {
        return Date.parse(b.date) - Date.parse(a.date)
      })
      .slice(0, DISPLAY_RECENT_SERVICES_LENGTH)

    return Object.values(slicedRecentServices)
      .filter((service) => recentServicesBySlug[service.slug])
      .map((service) => recentServicesBySlug[service.slug])
  }, [recentServicesBySlug, recentServices])

  const handleLaunch = (service) => {
    if (service[0]?.redirectUrl || service.length === 1) {
      serviceRedirect(
        service[0]?.service_provision || service[0]?.application_provision,
        service[0]?.slug
      )
    } else {
      setProvisionsOfSelectedService(service)
      setShowModal(true)
    }
  }

  const displayServicesView = () =>
    !isLoading && recentServicesData.length && recentServicesView

  return (
    <Box margin={{ bottom: 'large' }}>
      {showModal && (
        <NewServiceLaunchModal
          provisions={provisionsOfSelectedService}
          setShowModal={setShowModal}
        />
      )}
      {(isLoading && (
        <Loader
          testId="service-centric-home-spinner"
          orientation="horizontal"
        />
      )) ||
        (!displayServicesView() ? (
          <Box>
            <Heading level={2}>{headingTitle}</Heading>
            <Box
              margin={{ horizontal: 'auto' }}
              width="large"
              gap="medium"
              align="center"
              data-testid="service-centric-no-data"
            >
              <Box gap="small" align="center">
                <AppsRounded color={global.colors.green.dark} size="xxlarge" />
                <Paragraph margin="none" size="large" textAlign="center">
                  {t('dashboard.recent_services.empty')}
                </Paragraph>
              </Box>
              <Button
                data-testid="service-catalog-link"
                onClick={() => history.push('/services/service-catalog')}
                label={t('dashboard.recent_services.view_catalog')}
                primary
              />
            </Box>
          </Box>
        ) : (
          <Box>
            {displayServicesView() === 'grid' && (
              <Box data-testid="service-centric-home-grid-view">
                <Box direction="column" margin={{ bottom: 'medium' }}>
                  <Box
                    align="center"
                    direction="row"
                    margin={{ bottom: 'small' }}
                    justify="between"
                  >
                    <Heading level={2}>{headingTitle}</Heading>
                    <Box direction="row" align="center" gap="small">
                      <Button
                        data-testid="service-centric-list-view-icon"
                        onClick={() => {
                          setRecentServicesView('table')
                        }}
                        icon={<List />}
                        tip={t('dashboard:common.switch_to_list_view')}
                      />
                      <Anchor
                        data-testid="my-service-link-grid"
                        onClick={(e) => {
                          e.preventDefault()
                          history.push('/services/my-services')
                        }}
                        href="/services/my-services"
                      >
                        {t('common.my_services')}
                      </Anchor>
                    </Box>
                  </Box>
                  <Grid
                    pad={{ bottom: 'small' }}
                    gap="small"
                    columns={{
                      count: 'fill',
                      size: ['medium', 'flex']
                    }}
                  >
                    {recentServicesData
                      .filter((regions) => filterService(regions[0]?.slug))
                      .map((region) => (
                        <Card key={`${region[0].name}`} title={region[0].name}>
                          <CardBody
                            justify="between"
                            direction="row"
                            pad="medium"
                            align="center"
                          >
                            <Box>
                              <Heading level={3} margin="none">
                                {region[0].name}
                              </Heading>
                              <Text size="small">
                                {t(
                                  `dashboard:common.category.${categoryMapper(
                                    region[0].categories
                                  )}`
                                )}
                              </Text>
                            </Box>
                            {isMSP() &&
                            region[0]?.workspace_types?.includes('TENANT') &&
                            !region[0]?.workspace_types?.includes('MSP') ? (
                              <span>
                                <Tag
                                  value={t(
                                    'dashboard:service_details.enabled_for_customer_workspaces'
                                  )}
                                  align="center"
                                  size="small"
                                  border={{ size: 'small', color: 'brand' }}
                                  color="background"
                                  style={{
                                    whiteSpace: 'nowrap'
                                  }}
                                />
                              </span>
                            ) : (
                              <Button
                                a11yTitle={`${t('common.launch')} ${
                                  region[0].name
                                }`}
                                href={region[0]?.static_launch_url || null}
                                label={t('common.launch')}
                                onClick={() => {
                                  return region[0]?.static_launch_url
                                    ? setRecentServices(region[0]?.slug)
                                    : handleLaunch(region)
                                }}
                                secondary
                                data-testid={`recent-launch-${region[0]?.slug}`}
                              />
                            )}
                          </CardBody>
                        </Card>
                      ))}
                  </Grid>
                </Box>
              </Box>
            )}
            {displayServicesView() === 'table' && (
              <Box>
                <DataTable
                  testId="service-centric-home-list-view"
                  grid={{
                    verticalAlign: 'middle',
                    background: {
                      header: 'white'
                    },
                    border: {
                      body: {
                        color: 'border-weak',
                        side: 'bottom',
                        size: 'xsmall'
                      },
                      header: {
                        color: 'transparent',
                        side: 'bottom',
                        size: 'small'
                      }
                    },
                    pad: {
                      body: { horizontal: 'auto', vertical: 'medium' },
                      header: { horizontal: 'auto' }
                    },
                    columns: [
                      {
                        property: 'title',
                        header: (
                          <Heading level={2} margin="none">
                            {headingTitle}
                          </Heading>
                        ),

                        sortable: false
                      },
                      {
                        property: 'type',
                        header: 'Type',
                        sortable: true
                      },
                      {
                        property: 'last_accessed',
                        header: 'Last Accessed',
                        sortable: true,
                        render: ({ last_accessed }) => {
                          /* Always based on browser/computer settings */
                          const { timeZone } =
                            Intl.DateTimeFormat().resolvedOptions()
                          return last_accessed
                            ? new Date(last_accessed).toLocaleString(
                                i18n.language,
                                {
                                  dateStyle: 'medium',
                                  timeZone,
                                  timeStyle: 'short'
                                }
                              )
                            : ''
                        }
                      },
                      {
                        property: 'path',
                        header: (
                          <Box direction="row" align="center" gap="small">
                            <Button
                              data-testid="service-centric-list-grid-icon"
                              icon={<Apps />}
                              onClick={() => {
                                setRecentServicesView('grid')
                              }}
                              tip={t('dashboard:common.switch_to_grid_view')}
                            />
                            <Anchor
                              data-testid="my-service-link-list"
                              onClick={(e) => {
                                e.preventDefault()
                                history.push('/services/my-services')
                              }}
                              href="/services/my-services"
                            >
                              {t('common.my_services')}
                            </Anchor>
                          </Box>
                        ),
                        sortable: false,
                        render: (service) =>
                          isMSP() &&
                          service.provision[0]?.workspace_types?.includes(
                            'TENANT'
                          ) &&
                          !service.provision[0]?.workspace_types?.includes(
                            'MSP'
                          ) ? (
                            <span>
                              <Tag
                                value={t(
                                  'dashboard:service_details.enabled_for_customer_workspaces'
                                )}
                                align="center"
                                size="small"
                                border={{ size: 'small', color: 'brand' }}
                                color="background"
                                style={{
                                  whiteSpace: 'nowrap'
                                }}
                              />
                            </span>
                          ) : (
                            <Button
                              a11yTitle={`${t('common.launch')} ${
                                service.name
                              }`}
                              href={
                                service.provision[0]?.static_launch_url || null
                              }
                              secondary
                              label={t('common.launch')}
                              onClick={() =>
                                service.provision[0]?.static_launch_url
                                  ? setRecentServices(
                                      service.provision[0]?.slug
                                    )
                                  : handleLaunch(service.provision)
                              }
                              data-testid="service-launch-list"
                              fill="horizontal"
                            />
                          )
                      }
                    ],
                    sort: {
                      direction: 'desc',
                      property: 'last_accessed'
                    },
                    data: (() => {
                      return recentServicesData
                        .filter((regions) => filterService(regions[0]?.slug))
                        .map((service) => {
                          return {
                            provision: [...service],
                            name: service[0].name,
                            title: service[0].name,
                            type: t(
                              `dashboard:common.category.${categoryMapper(
                                service[0].categories
                              )}`
                            ),
                            last_accessed: service[0].utc,
                            path: '#'
                          }
                        })
                    })()
                  }}
                />
              </Box>
            )}
          </Box>
        ))}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          icon={<StatusCritical color="text-strong" />}
          onClose={() => setErrorMessage('')}
          position="top"
          testId="service-centric-home-error-notification"
          text={errorMessage}
        />
      )}
    </Box>
  )
}

export default NewRecentServices
