// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext } from 'react'
import { Box } from 'grommet'
import { FormNextLink } from 'grommet-icons'
import { PropTypes } from 'prop-types'

import { Button } from '../button/Button'
import { Loader } from '../loader/Loader'

import { WizardContext } from './WizardContext'

export const StepFooter = ({ finishDisabled, buttonLabels, isApiLoading }) => {
  const {
    formValues,
    setAttemptedAdvance,
    activeStep,
    setActiveStep,
    steps,
    setFormError
  } = useContext(WizardContext)
  return (
    <Box
      border={{ side: 'top', color: 'border' }}
      pad={{ vertical: 'medium' }}
      direction="row"
      justify="end"
      gap="small"
      align="end"
    >
      {finishDisabled ? <Loader testId="wizard-loader-onsubmit" /> : null}
      {buttonLabels?.otherActions?.length &&
        buttonLabels?.otherActions?.map(
          (btn, idx) =>
            btn?.showInStep === activeStep && (
              <Button
                {...btn}
                key={idx} // eslint-disable-line react/no-array-index-key
                label={btn?.label || 'Help'}
                type="button"
                onClick={(event) => {
                  if (btn?.skipTypeButton) {
                    event.preventDefault()
                    event.stopPropagation()
                    steps[activeStep - 1]
                      .validateForm(
                        formValues,
                        buttonLabels?.next?.submitForm
                          ? buttonLabels.next.submitForm
                          : false
                      )
                      .then(
                        () => {
                          setAttemptedAdvance(false)
                          setActiveStep(activeStep + 2) // Advance two steps forward to Skip the Next step
                        },
                        (error) => {
                          if (steps[activeStep - 1]?.showFormError === false) {
                            setAttemptedAdvance(false)
                          } else {
                            setAttemptedAdvance(true)
                            setFormError(error.message)
                          }
                        }
                      )
                  } else {
                    btn?.handleOnClick(formValues)
                  }
                }}
              />
            )
        )}
      {activeStep < steps.length && (
        <Button
          label={buttonLabels?.next?.label ? buttonLabels.next.label : 'Next'}
          testId="button-next"
          icon={buttonLabels?.next?.hideIcon ? undefined : <FormNextLink />}
          primary
          reverse
          disabled={isApiLoading}
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            // check for errors - form validations
            steps[activeStep - 1]
              .validateForm(
                formValues,
                buttonLabels?.next?.submitForm
                  ? buttonLabels.next.submitForm
                  : false
              )
              .then(
                () => {
                  // advance to next step if successful
                  setAttemptedAdvance(false)
                  setActiveStep(activeStep + 1)
                },
                (error) => {
                  // If `showFormError` is not explicitly set to `false`, then show form error.
                  if (steps[activeStep - 1]?.showFormError === false) {
                    // If show form error is set to false, then the error is being handled within the step
                    // In that case force the user to take action before advancing.
                    setAttemptedAdvance(false)
                  } else {
                    // mark that the user is trying to advance, so that
                    // validation will run on any errors in the future.
                    setAttemptedAdvance(true)
                    setFormError(error.message)
                  }
                }
              )
          }}
        />
      )}
      {activeStep === steps.length && (
        <Button
          label={buttonLabels?.finish ? buttonLabels.finish : 'Finish'}
          testId="button-finish"
          primary
          disabled={finishDisabled}
          type="submit"
        />
      )}
    </Box>
  )
}

StepFooter.propTypes = {
  finishDisabled: PropTypes.bool,
  isApiLoading: PropTypes.bool,
  buttonLabels: PropTypes.shape({
    next: PropTypes.shape({
      label: PropTypes.string,
      submitForm: PropTypes.bool,
      hideIcon: PropTypes.bool
    }),
    finish: PropTypes.string,
    otherActions: PropTypes.arrayOf(
      PropTypes.shape({
        skipTypeButton: PropTypes.bool, // if true, then the button will be of skip type i.e. move to the next to next step after form validation
        showInStep: PropTypes.number,
        label: PropTypes.string,
        handleOnClick: PropTypes.func,
        testId: PropTypes.string
      })
    )
  })
}

StepFooter.defaultProps = {
  finishDisabled: false,
  buttonLabels: [],
  isApiLoading: false
}
