import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ModalDialog,
  ButtonGroup,
  ModalFooter,
  ModalHeader,
  Notification,
  Typography
} from '../../../../../components'
import { getErrorMessage, del } from '../../../../../utils/api-utils'

const DeleteAliasModal = ({
  customerId,
  aliasName,
  setShowModal,
  onSuccess
}) => {
  const { t } = useTranslation(['manage'])
  const [errorMessage, setErrorMessage] = useState('')
  const { oidcUser } = useReactOidc()
  const handleDelete = () => {
    const url = `/support-assistant/v1alpha1/customer-aliases`
    del(
      url,
      { platform_customer_id: customerId, alias: aliasName },
      oidcUser.access_token
    ).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          setErrorMessage('')
          setShowModal(false)
          onSuccess()
        }
      },
      (error) => {
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
      }
    )
  }
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              testId="delete-alias-modal-title"
              level="1"
              type="heading"
            >
              {t('aliases.delete_alias')}
            </Typography>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <Typography type="text" size="medium" margin={{ bottom: 'small' }}>
            <Trans
              i18nKey="aliases.delete_alias_desc"
              t={t}
              aliasName={aliasName}
            >
              <strong>{{ aliasName }}</strong>
            </Trans>
          </Typography>
          {errorMessage.length ? (
            <Notification
              backgroundColor="status-critical"
              onClose={() => {}}
              testId="delete-alias-error-message"
              text={errorMessage}
              type="inline"
            />
          ) : null}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('aliases.cancel'),
                  secondary: true,
                  testId: 'cancel-btn',
                  onClick: () => setShowModal(false)
                },
                {
                  id: 1,
                  label: t('aliases.delete'),
                  primary: true,
                  testId: 'delete-btn',
                  onClick: handleDelete
                }
              ]}
              testId="delete-alias-buttons"
            />
          }
        />
      }
      onClose={() => setShowModal(false)}
      testId="delete-alias-modal-dialog"
      width="medium"
    />
  )
}

DeleteAliasModal.propTypes = {
  customerId: PropTypes.string.isRequired,
  aliasName: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
}

DeleteAliasModal.defaultProps = {
  onSuccess: () => {}
}

export { DeleteAliasModal }
