import { Box, Layer } from 'grommet'
import React, { useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { Close } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import {
  Button,
  CCSForm,
  FormInput,
  FormTextArea,
  Typography
} from '../../components'
import { patch } from '../../utils/api-utils'
import { displayApiError } from '../../utils/error-handling-utils'
import {
  validateEmail,
  validateOrganizationName,
  validateOrganizationDescription,
  validateOrganizationPhoneNumber
} from '../../utils/validation-utils'
import { CHARACTER_LENGTH } from '../manage-account/account-details/constants'
import { getOrganizationId } from '../../utils/feature-flag-utils'

const reducer = (currentState, { type, payload }) => {
  switch (type) {
    case 'FORM_INPUT_VALUE':
      return { ...currentState, [payload.name]: payload.value }
    case 'CHANGE_LOGO':
      return {
        ...currentState,
        logo: payload?.[0] || null,
        logoFilename: payload?.[1] || null
      }
    default:
      return currentState
  }
}
export default function EditOrganizationDetailsSidePanel({
  onClose,
  initialValues,
  onSuccess
}) {
  const { t } = useTranslation(['authn', 'common', 'iam'])
  const [errorNotification, setErrorNotification] = useState(null)
  const [formValues, dispatch] = useReducer(reducer, initialValues)
  const handleChangeInput = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    dispatch({
      type: 'FORM_INPUT_VALUE',
      payload: { name, value }
    })
  }
  const orgId = getOrganizationId()
  const saveEditOrganization = () => {
    const patchOperations = Object.entries(formValues)
      .filter(([key, value]) => value !== initialValues[key])
      .map(([key, value]) => ({
        op: 'replace',
        path: `/${key}`,
        value
      }))
    if (!patchOperations.length) {
      onClose()
      return
    }
    patch(`/organizations/v2alpha1/organizations/${orgId}`, patchOperations)
      .then((response) => {
        onSuccess(response.data)
        onClose()
      })
      .catch((error) => {
        setErrorNotification(displayApiError(error, t, setErrorNotification))
      })
  }
  return (
    <div>
      <Layer
        position="right"
        full="vertical"
        onClickOutside={onClose}
        onEsc={onClose}
      >
        <CCSForm
          onSubmit={saveEditOrganization}
          errorMessage=""
          validate="blur"
          testId="edit-organization-form"
        >
          <Box
            pad="medium"
            width="medium"
            overflow="auto"
            height={{ min: '100vh' }}
          >
            <Box gap="small">
              <Box direction="row" justify="between" align="baseline">
                <Typography
                  type="heading"
                  level={2}
                  testId="edit-organization-details"
                >
                  Edit organization details
                </Typography>
                <Button onClick={onClose} testId="close-edit-btn">
                  <Close size="medium" />
                </Button>
              </Box>
              <FormInput
                inputType="text"
                required
                label={t('common:organization_landing.Organization_name')}
                name="name"
                testId="organization-name"
                value={formValues.name}
                onChange={handleChangeInput}
                maxLength={CHARACTER_LENGTH?.ORG_NAME}
                validate={(value) => {
                  if (!validateOrganizationName(value)) {
                    return t(
                      'iam:organization.join_organization.create_new_org.name_char_validation_message'
                    )
                  }
                  return true
                }}
              />
              <FormTextArea
                inputType="text"
                label={t('common:organization_landing.description')}
                testId="company-description-field"
                name="description"
                value={formValues.description}
                onChange={handleChangeInput}
                maxLength={CHARACTER_LENGTH?.ORG_DESC}
                validate={(value) => {
                  if (!validateOrganizationDescription(value)) {
                    return t(
                      'iam:organization.join_organization.create_new_org.description_char_validation_message'
                    )
                  }
                  return true
                }}
              />
              <FormInput
                inputType="text"
                label={t(
                  'common:organization_landing.Organization_contact_phone'
                )}
                name="phoneNumber"
                testId="organization-number"
                value={formValues.phoneNumber}
                onChange={handleChangeInput}
                maxLength={CHARACTER_LENGTH?.ORG_PHONE}
                validate={(value) => {
                  if (!validateOrganizationPhoneNumber(value)) {
                    return t(
                      'iam:organization.join_organization.create_new_org.phoneNumber_char_validation_message'
                    )
                  }
                  return true
                }}
              />

              <FormInput
                inputType="text"
                label={t(
                  'common:organization_landing.Organization_contact_email'
                )}
                name="email"
                testId="organization-email"
                value={formValues.email}
                onChange={handleChangeInput}
                maxLength={CHARACTER_LENGTH?.ORG_EMAIL}
                validate={(value) => {
                  return validateEmail(value)
                }}
              />
            </Box>
            <Box
              direction="row"
              gap="small"
              align="center"
              margin={{ top: 'auto' }}
            >
              <Button
                label={t('common:organization_landing.Save_Changes')}
                primary
                type="submit"
                testId="save-changes-button"
              />
              <Button
                label={t('common:Cancel')}
                testId="cancel-changes-button"
                onClick={onClose}
              />
            </Box>
          </Box>
        </CCSForm>
      </Layer>
      {errorNotification}
    </div>
  )
}
EditOrganizationDetailsSidePanel.propTypes = {
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired
}
