// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, PageHeader } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Group, Previous } from 'grommet-icons'

import { get, getErrorMessage } from '../../../utils/api-utils'
import {
  Anchor,
  Button,
  DataTable,
  NoDataInfo,
  Typography
} from '../../../components'
import { displayNotification } from '../../../utils/notificiation-utils'
import Markdown from '../../commoncomponents/Markdown'
import { Layout } from '../../commoncomponents/layout/Layout'
import { useVisibilityContext } from '../../../context/visibility-context'
import NoViewPermission from '../commoncomponents/NoViewPermission'
import {
  getOrganizationId,
  isAssociateWorkspace
} from '../../../utils/feature-flag-utils'

import CreateGroupModal from './components/CreateGroupModal'
import { GROUP_SCHEMAS } from './constants'

const GroupsContent = ({ isWorkspace }) => {
  const { t } = useTranslation(['common', 'iam'])
  const location = useLocation()
  const history = useHistory()
  const [userGroups, setUserGroups] = useState([])
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState('')
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false)
  const path = isWorkspace
    ? 'manage-account/identity'
    : 'manage-account/organization'
  const { v2RbacPolicies, v2RbacRootWkspcPolicies } = useVisibilityContext()
  const v2GroupViewPerm = v2RbacPolicies?.includes('identity.user-groups.read')
  const ccsAuthView = v2RbacPolicies?.includes('ccs.authorization.view')
  const ccsAssociateAuthView = v2RbacRootWkspcPolicies?.includes(
    'ccs.authorization.view'
  )
  const [isAssociated, setIsAssociated] = useState(false)
  const rootWkspReadPerm = v2RbacRootWkspcPolicies?.some((perm) =>
    [
      'identity.user-groups.read',
      'ccs.authorization.view',
      'ccs.authorization.edit'
    ]?.includes(perm)
  )
  const rootWkspReadPermOrgLevel = v2RbacRootWkspcPolicies?.includes(
    'identity.user-groups.read'
  )

  // Notifications
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')
  const setNotificationInfo = (message, severity, title = '') => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  const handleRowClick = (datum) => {
    history.push(`/${path}/groups/${datum?.id}`)
  }

  const handleBackBtnClick = () => {
    history.push(`/${path}`)
  }

  const columns = [
    {
      property: 'displayName',
      header: t('common:name'),
      primary: true,
      render: (datum) => {
        const displayName = datum?.displayName || '--'
        return (
          <Box direction="row" align="center" gap="small">
            <Typography
              type="text"
              wordBreak="break-all"
              testId="group-display-name"
              emphasis
            >
              {displayName}
            </Typography>
          </Box>
        )
      }
    },
    {
      property: 'description',
      header: t('common:description'),
      render: (datum) => {
        const description = datum?.[GROUP_SCHEMAS?.PARTIAL]?.groupDescription
        return (
          <Typography type="text" testId="group-description">
            {description || '--'}
          </Typography>
        )
      }
    }
  ]

  const hasPermissions = () => {
    if (isWorkspace) {
      return (
        (!isAssociated && (v2GroupViewPerm || ccsAuthView)) ||
        (isAssociated && (rootWkspReadPerm || ccsAssociateAuthView))
      )
    }
    return (
      (!isAssociated && v2GroupViewPerm) ||
      (isAssociated && rootWkspReadPermOrgLevel)
    )
  }

  useEffect(() => {
    const orgId = getOrganizationId()
    if (orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (res) => {
          if (res?.data && res?.data?.associatedWorkspace) {
            setIsAssociated(
              isAssociateWorkspace(res?.data?.associatedWorkspace?.id)
            )
          }
        },
        (err) => {
          setNotificationInfo(getErrorMessage(err, t), 'error')
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location?.state?.deleteGroupName) {
      const message = (
        <Markdown>
          {t('iam:organization_groups.group_delete_success_msg', {
            groupName: location?.state?.deleteGroupName
          })}
        </Markdown>
      )
      setNotificationInfo(
        message,
        'info',
        t('iam:organization_groups.group_delete_success_msg_title')
      )
    }
  }, [location, t])

  useEffect(() => {
    if (location?.state?.notificationText) {
      const message = <Markdown>{location?.state?.notificationText}</Markdown>
      setNotificationInfo(message, 'info', location?.state?.notificationTitle)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    let isCurrent = true
    const nameQuery =
      filter?.trim()?.length > 0
        ? encodeURI(`?filter=displayName sw "${filter?.trim()}"`)
        : ''
    setLoading(true)
    get(`/identity/v2alpha1/scim/v2/Groups${nameQuery}`).then(
      (response) => {
        if (response?.data) {
          if (!isCurrent) return
          if (response?.data?.Resources) {
            setUserGroups(response?.data?.Resources)
          }
          setLoading(false)
          // Opening modal here instead of in the useEffect to wait for API call to finish before opening modal
          if (location?.state?.createGroup) {
            setShowCreateGroupModal(true)
          }
        }
      },
      (error) => {
        if (!isCurrent) return
        setNotificationInfo(getErrorMessage(error), 'error')
        setLoading(false)
      }
    )
    return () => {
      isCurrent = false
    }
  }, [filter, location])

  return (
    <Box
      data-testid="groups-page"
      pad={{ horizontal: 'xlarge', bottom: 'large' }}
      width="xxlarge"
      alignSelf="center"
    >
      <PageHeader
        responsive
        title={
          <Typography type="heading" level="1" testId="groups-page-title">
            {t('iam:organization_groups.title')}
          </Typography>
        }
        subtitle={
          userGroups?.length && hasPermissions() ? (
            <Typography
              type="paragraph"
              size="xlarge"
              testId="groups-page-subtitle"
            >
              {isWorkspace
                ? t('iam:workspace_groups.subtitle')
                : t('iam:organization_groups.subtitle')}
            </Typography>
          ) : null
        }
        parent={
          <Anchor
            label={
              isWorkspace
                ? t('iam:workspace_identity.title', {
                    Workspace: t('common:business_object:wkspc_capitalized')
                  })
                : t('iam:organization.governance_title')
            }
            icon={<Previous />}
            href="#"
            onClick={(event) => {
              event.preventDefault()
              handleBackBtnClick()
            }}
            margin={{ bottom: 'xsmall' }}
            testId="groups-back-btn"
          />
        }
        margin={{ bottom: 'medium' }}
      />
      {hasPermissions() ? (
        <>
          {userGroups?.length !== 0 || filter?.length > 0 || loading ? (
            <DataTable
              style={{ width: isWorkspace ? 'fit-content' : null }}
              summary={{
                entityName:
                  userGroups?.length === 1
                    ? t('common:item_singular')
                    : t('common:item_plural')
              }}
              grid={{
                columns,
                data: userGroups,
                onClickRow: (event) => {
                  handleRowClick(event?.datum)
                }
              }}
              loading={loading}
              search={{
                onSearchValueChange: (value) => {
                  setFilter(value)
                },
                placeholder: t('common:search'),
                width: isWorkspace ? 'large' : 'xlarge'
              }}
              button={
                isWorkspace ? null : (
                  <Box align="end" width="large">
                    {((isAssociated &&
                      v2RbacRootWkspcPolicies?.includes(
                        'identity.user-groups.create'
                      )) ||
                      (!isAssociated &&
                        v2RbacPolicies?.includes(
                          'identity.user-groups.create'
                        ))) && (
                      <Button
                        secondary
                        label={t('iam:organization_groups.create_group_title')}
                        onClick={() => {
                          setShowCreateGroupModal(true)
                        }}
                        testId="data-table-create-group-btn"
                      />
                    )}
                  </Box>
                )
              }
              testId="groups-data-table"
            />
          ) : (
            <Box alignSelf="center" width="large">
              <NoDataInfo
                icon={<Group size="large" />}
                title={t('iam:organization_groups.no_data_title')}
                subtitle={
                  ((isAssociated &&
                    v2RbacRootWkspcPolicies?.includes(
                      'identity.user-groups.create'
                    )) ||
                    (!isAssociated &&
                      v2RbacPolicies?.includes(
                        'identity.user-groups.create'
                      ))) &&
                  t('iam:organization_groups.no_data_subtitle')
                }
                gap="small"
                gapTitleSubtitle="small"
                action={
                  !isWorkspace &&
                  ((isAssociated &&
                    v2RbacRootWkspcPolicies?.includes(
                      'identity.user-groups.create'
                    )) ||
                    (!isAssociated &&
                      v2RbacPolicies?.includes(
                        'identity.user-groups.create'
                      ))) && (
                    <Button
                      primary
                      label={t('iam:organization_groups.create_group_title')}
                      onClick={() => {
                        setShowCreateGroupModal(true)
                      }}
                      testId="no-data-create-group-btn"
                    />
                  )
                }
                testId="group-no-data-info"
              />
            </Box>
          )}
        </>
      ) : (
        <NoViewPermission />
      )}
      {showCreateGroupModal && (
        <CreateGroupModal
          onSetOpen={setShowCreateGroupModal}
          setParentNotification={setNotificationInfo}
        />
      )}
      {notifMsg &&
        displayNotification(notifMsg, notifSeverity, setNotifMsg, notifTitle)}
    </Box>
  )
}

const Groups = ({ isWorkspace }) => {
  return (
    <Layout>
      <GroupsContent isWorkspace={isWorkspace} />
    </Layout>
  )
}

GroupsContent.propTypes = {
  /**
   * True if called from v2 workspace, false if called from IGC pages
   */
  isWorkspace: PropTypes.bool
}

GroupsContent.defaultProps = {
  isWorkspace: false
}

Groups.propTypes = {
  /**
   * True if called from v2 workspace, false if called from IGC pages
   */
  isWorkspace: PropTypes.bool
}

Groups.defaultProps = {
  isWorkspace: false
}

export default Groups
