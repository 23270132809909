import React from 'react'
import { Box } from 'grommet'
import { StatusGood } from 'grommet-icons'
import { PropTypes } from 'prop-types'

import { Typography } from '../../../components'

const FormSuccessInfo = ({ message }) => (
  <Box
    round="4px"
    gap="xsmall"
    direction="row"
    align="center"
    data-testid="success-info-box"
    background={{ light: '#c7fae8' }}
    pad="medium"
  >
    <StatusGood size="medium" />
    <Typography type="text" size="xsmall" testId="success-info-message">
      {message}
    </Typography>
  </Box>
)

FormSuccessInfo.propTypes = {
  message: PropTypes.string
}

FormSuccessInfo.defaultProps = {
  message: ''
}
export default FormSuccessInfo
