import { validateEmail } from '../../../../../../../../utils/validation-utils'

export const validatePartyFields = (partyDetails, i18nTranslate) => {
  const requiredFields = [
    'partyId',
    'name',
    'houseNumber',
    'streetName',
    'cityName',
    'state',
    'postalCode',
    'country'
  ]
  const errorObj = {}
  requiredFields.forEach((k) => {
    if (!partyDetails[k]) {
      errorObj[k] = i18nTranslate('Required')
    }
  })

  const isValidEmail = validateEmail(partyDetails.emailAddress)
  if (isValidEmail !== true) {
    errorObj.emailAddress = isValidEmail
  }
  return errorObj
}

export const getPartyInformationUpdater = (
  partyType,
  formValues,
  setFormValues
) => {
  const currentPartyDetails = {
    soldTo: formValues.soldToPartyDetails,
    billTo: formValues.billToPartyDetails,
    payer: formValues.payerPartyDetails
  }
  const partyDetailsKeyMap = {
    soldTo: 'soldToPartyDetails',
    billTo: 'billToPartyDetails',
    payer: 'payerPartyDetails'
  }

  const partyDetailsKey = partyDetailsKeyMap[partyType]
  const currentDetails = currentPartyDetails[partyType] || {}
  return (key, value) => {
    currentDetails[key] = value
    setFormValues({
      ...formValues,
      [partyDetailsKey]: currentDetails
    })
  }
}

export const getPartyDetails = (partyType, formValues) => {
  const partyDetailsKeyMap = {
    soldTo: 'soldToPartyDetails',
    billTo: 'billToPartyDetails',
    payer: 'payerPartyDetails'
  }
  return formValues[partyDetailsKeyMap[partyType]]
}
