import { React, Suspense, lazy } from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

const APILandingPage = lazy(() => import('./APILanding'))

const APIRouter = () => {
  const { path } = useRouteMatch()
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route exact path={path}>
          <APILandingPage />
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  )
}

export default APIRouter
