import { CheckBox } from 'grommet'
import { useTranslation } from 'react-i18next'

const ContactField = () => {
  const { t } = useTranslation(['support_hub_feedback'])
  return (
    <CheckBox
      id="contact"
      name="contact"
      pad="none"
      label={t('contact_field.label')}
    />
  )
}

export { ContactField }
