/**
 * Filters services arrays by the predicate identifying provisionStatus
 * @param services
 * array of services to be filtered
 * @param predicate
 * statement by which to filter through Array.some()
 * @example
 * filteredServicesByRegionalInstance(services, ({ provisionStatus }) => provisionStatus !== 'PROVISIONED')
 */
import { SERVICE_FEATURE_FLAGS, HPECC, BACKUP_AND_RECOVERY } from './constants'

export const getRegionName = (regionsLoaded, regionsData, region) => {
  return regionsLoaded
    ? regionsData.find((item) => item.code === region)?.name
    : ''
}

export const isEval = (cumulativeService) => {
  if (
    cumulativeService.slug === HPECC ||
    cumulativeService.slug === BACKUP_AND_RECOVERY
  ) {
    return true
  }
  return false
}

export const isServiceEnabled = (serviceSlug, LDFlags) =>
  !SERVICE_FEATURE_FLAGS[serviceSlug] ||
  LDFlags[SERVICE_FEATURE_FLAGS[serviceSlug]]
