import PropTypes from 'prop-types'
import { useState } from 'react'
import { Box, FormField, Layer } from 'grommet'
import { Close } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  FileInput,
  CCSForm,
  ButtonGroup,
  Button,
  Typography
} from '../../components'
import { post } from '../../utils/api-utils'
import { getGlopErrorMessage } from '../../pages/system/utils/common-utils'

const ImportResourcesLayer = ({ handleClose, onSuccess }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [importResourceFile, setImportResourceFile] = useState()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['dashboard', 'user_personalisation', 'common'])

  const importResource = () => {
    const formData = new FormData()
    formData.append('filePath', importResourceFile)
    post(
      '/ui-doorway/ui/v1/glop/onprem-import',
      formData,
      oidcUser.access_token,
      {
        'Content-Type': 'multipart/form-data'
      }
    )
      .then(() => {
        onSuccess()
        handleClose()
      })
      .catch((error) => {
        const message = getGlopErrorMessage(error, t)
        setErrorMessage(message)
      })
  }
  return (
    <Layer
      position="right"
      full="vertical"
      onClickOutside={handleClose}
      onEsc={handleClose}
      width="small"
      data-testid="import-device-modal"
    >
      <Box overflow="auto" width="medium" pad="small" direction="row">
        <Box style={{ display: 'block' }}>
          <Typography
            level={2}
            data-testid="import-devices-title"
            type="heading"
          >
            {t('dashboard:dashboard.import_resources.title')}
          </Typography>
          <Typography type="text" data-testId="import-resources-subtitle1">
            {t('dashboard:dashboard.import_resources.sub_title1')}
          </Typography>
          <Typography
            type="text"
            margin={{ vertical: 'medium' }}
            data-testId="import-resources-subtitle2"
          >
            {t('dashboard:dashboard.import_resources.sub_title2')}
          </Typography>
          <Typography type="text" data-testId="import-resources-subtitle3">
            {t('dashboard:dashboard.import_resources.sub_title3')}
          </Typography>
          <CCSForm
            errorMessage=""
            testId="import-resources-form"
            onSubmit={importResource}
            buttons={
              <Box pad={{ top: 'medium', bottom: 'small' }}>
                <ButtonGroup
                  buttonList={[
                    {
                      id: 1,
                      label: t(
                        'dashboard:dashboard.import_resources.button_text_import'
                      ),
                      primary: true,
                      testId: 'import-resources-button',
                      type: 'submit'
                    },
                    {
                      id: 2,
                      label: t('common.cancel'),
                      default: true,
                      testId: 'cancel-btn',
                      onClick: () => {
                        handleClose()
                      }
                    }
                  ]}
                  testId="import-resources-button-group"
                  justifyGroup="start"
                />
              </Box>
            }
          >
            <Box gap="medium" pad={{ top: 'medium' }}>
              <FormField
                data-testid="import-devices-file-filed"
                name="file"
                htmlFor="import-devices-file"
                contentProps={{
                  border: false
                }}
                validateOn="change"
                error={errorMessage}
                validate={(val) => {
                  if (!val || !val?.length)
                    return t(
                      'dashboard:dashboard.import_resources.required_field'
                    )
                  if (
                    val[0].type !== 'application/x-zip-compressed' &&
                    val[0].type !== 'application/zip'
                  ) {
                    return t(
                      'dashboard:dashboard.import_resources.json_format_validation_message'
                    )
                  }
                  return undefined
                }}
              >
                <FileInput
                  testId="import-devices-file"
                  name="file"
                  accept=".zip"
                  onChange={(event) => {
                    if (event?.target?.files && event?.target?.files?.length) {
                      const uploadedFile = event.target.files.length
                        ? event.target.files[0]
                        : null
                      setImportResourceFile(uploadedFile)
                    } else {
                      setErrorMessage('')
                      setImportResourceFile({})
                    }
                  }}
                />
              </FormField>
            </Box>
          </CCSForm>
        </Box>
        <Box pad={{ right: 'medium', bottom: 'small' }}>
          <Button
            icon={<Close size="small" />}
            position="right"
            onClick={handleClose}
          />
        </Box>
      </Box>
    </Layer>
  )
}
ImportResourcesLayer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
}

ImportResourcesLayer.defaultProps = {
  onSuccess: () => {}
}

export default ImportResourcesLayer
