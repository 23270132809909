import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, FormField } from 'grommet'
import { isEmpty } from 'lodash'

import {
  Button,
  CCSForm,
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  MultiSelectBox,
  Typography
} from '../../../components'
import {
  get,
  getBaseUrl,
  getErrorMessage,
  patch
} from '../../../utils/api-utils'
import { GROUP_SCHEMAS } from '../groups/constants'
import Markdown from '../../commoncomponents/Markdown'

import { debounceSearch } from './utils'

const AssignGroupsToUserModal = ({
  onSetOpen,
  onSubmit,
  setNotificationInfo,
  group
}) => {
  const { t } = useTranslation(['common', 'iam'])
  const [users, setUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [defaultOptions, setDefaultOptions] = useState()

  const closeModal = () => {
    onSetOpen(false)
  }

  const validateSelectUsers = () => {
    if (selectedUsers?.length === 0) {
      setErrorMessage(t('common:this_is_required'))
      return false
    }
    return true
  }

  const template = (option) => (
    <Box pad={{ top: 'xsmall' }}>
      <Typography size="small" type="paragraph">
        {option?.displayName || option?.userName}
      </Typography>
      {option?.displayName && (
        <Typography size="xsmall" type="paragraph">
          {option?.userName}
        </Typography>
      )}
    </Box>
  )

  useEffect(() => {
    let isMounted = true
    const getUsersAssignedToGroup = async () => {
      let usersAssignedToGroup
      await get(
        `/identity/v2alpha1/scim/v2/extensions/Groups/${group?.id}/users`
      ).then(
        (response) => {
          if (!isMounted) return
          usersAssignedToGroup = response?.data?.Resources
        },
        (error) => {
          if (!isMounted) return
          setNotificationInfo(getErrorMessage(error, t), 'error')
        }
      )
      return usersAssignedToGroup
    }

    const getUsers = async () => {
      const searchQuery =
        searchValue?.trim()?.length > 0
          ? encodeURI(
              `?filter=displayName sw "${searchValue?.trim()}" or userName sw "${searchValue?.trim()}"`
            )
          : ''
      setLoading(true)
      setUsers([])
      await get(`/identity/v2alpha1/scim/v2/Users${searchQuery}`).then(
        async (response) => {
          let usersToDisplay = []
          if (response?.data && response?.data?.Resources) {
            usersToDisplay = response?.data?.Resources
          }
          if (group?.id) {
            const usersAssignedToGroup = await getUsersAssignedToGroup()
            const usersAssignedToGroupIds = usersAssignedToGroup?.map(
              (user) => user?.id
            )
            usersToDisplay = usersToDisplay?.filter(
              (user) => !usersAssignedToGroupIds?.includes(user?.id)
            )
          }
          if (!isMounted) return
          if (isEmpty(searchValue?.trim())) {
            setDefaultOptions(usersToDisplay)
          }
          setUsers(usersToDisplay)
          setLoading(false)
        },
        (error) => {
          if (!isMounted) return
          setNotificationInfo(getErrorMessage(error, t), 'error')
          setLoading(false)
        }
      )
    }
    getUsers()
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const updateGroupUsers = async () => {
    setLoading(true)
    const value = selectedUsers?.map((u) => ({
      display: u?.displayName,
      $ref: `${getBaseUrl()}/identity/v2alpha1/scim/v2/Users/${u?.id}`,
      value: u?.id
    }))
    try {
      await patch(`/identity/v2alpha1/scim/v2/Groups/${group?.id}`, {
        schemas: [GROUP_SCHEMAS.PATCH_OP_SCHEMA],
        Operations: [
          {
            op: 'add',
            value,
            path: 'members'
          }
        ]
      })
      setNotificationInfo(
        selectedUsers?.length === 1 ? (
          <Markdown>
            {t('iam:organization_groups.user_added_message', {
              userName: `${
                selectedUsers[0]?.displayName || selectedUsers[0]?.userName
              }`,
              groupName: `${group?.displayName}`
            })}
          </Markdown>
        ) : (
          <Markdown>
            {t('iam:organization_groups.users_added_message', {
              usercount: selectedUsers?.length,
              groupName: `${group?.displayName}`
            })}
          </Markdown>
        ),
        'info',
        selectedUsers?.length === 1
          ? t('iam:organization_groups.user_added_title')
          : t('iam:organization_groups.users_added_title')
      )
      onSubmit()
    } catch (error) {
      setNotificationInfo(getErrorMessage(error, t), 'error')
    } finally {
      setLoading(false)
      closeModal()
    }
  }

  return (
    <ModalDialog
      height="100%"
      overflow="hidden"
      position="right"
      header={
        <ModalHeader
          title={
            <Box direction="column">
              <Typography
                type="heading"
                level="2"
                margin={{ bottom: 'small' }}
                emphasis
              >
                {t('iam:organization_groups.add_users_to_group_title')}
              </Typography>
              <Box width="medium">
                <Markdown>
                  {t('iam:organization_groups.add_users_to_group_desc', {
                    groupName: group?.displayName
                  })}
                </Markdown>
              </Box>
            </Box>
          }
          onClose={closeModal}
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <CCSForm errorMessage="" testId="assign-users-to-group-form">
            <FormField
              label={`${t('iam:users.select_user_label')}*`}
              error={errorMessage}
            >
              <MultiSelectBox
                options={users}
                labelKey={(user) =>
                  isEmpty(user?.displayName)
                    ? user?.userName
                    : user?.displayName
                }
                valueKey="id"
                placeholder={t('common:select')}
                onChange={(user) => {
                  setSelectedUsers(user)
                  if (selectedUsers) {
                    setErrorMessage('')
                  }
                }}
                onClose={() => setUsers(defaultOptions)}
                onSearch={(searchText) =>
                  debounceSearch(searchText, setSearchValue)
                }
                searchPlaceholder={t('common:search')}
                emptySearchMessage={
                  loading
                    ? t('common:searching')
                    : t('common:search_no_matches')
                }
                dropHeight="medium"
                disabled={loading}
                testId="assign-users-to-group-multiselect"
              >
                {template}
              </MultiSelectBox>
            </FormField>
          </CCSForm>
          <Box align="center" margin={{ top: 'small' }}>
            {loading && <Loader testId="assign-users-to-group-loader" />}
          </Box>
        </Box>
      }
      footer={
        <ModalFooter
          left={
            <Box direction="row" justify="start" gap="small">
              <Button
                primary
                label={t('common:add')}
                onClick={() => {
                  if (validateSelectUsers()) {
                    updateGroupUsers()
                  }
                }}
                disabled={loading}
                testId="assign-users-to-group-add-btn"
              />
              <Button
                default
                label={t('common:cancel')}
                onClick={closeModal}
                disabled={loading}
                testId="assign-users-to-group-cancel-btn"
              />
            </Box>
          }
        />
      }
      onClose={closeModal}
      onClickOutside={closeModal}
      testId="assign-users-to-group-modal"
    />
  )
}

AssignGroupsToUserModal.propTypes = {
  /**
   * Flag to display modal
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * onSubmit handler
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * Set notification info
   */
  setNotificationInfo: PropTypes.func,

  /**
   * The group to add users
   */
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired
  }).isRequired
}

AssignGroupsToUserModal.defaultProps = {
  setNotificationInfo: () => {}
}

export default AssignGroupsToUserModal
