// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Box, Text } from 'grommet'
import { ContactInfo } from 'grommet-icons'
import { isEmpty } from 'lodash'

import { Typography, Button, NoDataInfo } from '../../../../components'
import { get, getErrorMessage } from '../../../../utils/api-utils'
import AssignmentContent from '../../../manage-account/identity/authzV2-roles/components/AssignmentDataTable'
import DeleteRoleAssignmentConfirmation from '../../../manage-account/identity/common-components/DeleteRoleAssignmentConfirmation'
import Markdown from '../../../commoncomponents/Markdown'
import AssignRoleSideDrawer from '../../../manage-account/identity/common-components/AssignRoleSideDrawer'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

const GroupDetailsRoleAssignments = ({
  groupDetailsData,
  setNotificationInfo,
  parentLoading
}) => {
  const { t } = useTranslation(['iam', 'common'])
  const { groupId } = useParams()
  const [roleAssignments, setRoleAssignments] = useState()
  const [loading, setLoading] = useState(true)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const [page, setPage] = useState(1)
  const [refreshCount, setRefreshCount] = useState(0)

  const [openDeleteConfirmationDialog, setopenDeleteConfirmationDialog] =
    useState(false)
  const [retrievedSubjectData, setRetrievedSubjectData] = useState([])

  const handleOnDeleteBtnClick = useCallback((datum) => {
    setopenDeleteConfirmationDialog(true)
    setNotificationInfo(false)
    setRetrievedSubjectData(datum)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [showAssignRoleModal, setShowAssignRoleModal] = useState(false)

  useEffect(() => {
    let isCurrent = true
    const getRoleAssignments = async () => {
      setLoading(true)
      await get(
        `/internal-platform-tenant-ui/v2/role-assignments?group_id=${groupId}`,
        {
          limit: itemsPerPage,
          offset: (page - 1) * itemsPerPage
        }
      ).then(
        (response) => {
          if (response?.data?.role_assignments) {
            if (!isCurrent) return
            setRoleAssignments(response?.data?.role_assignments)
            setTotalItems(response?.data?.total)
          }
          setLoading(false)
        },
        (error) => {
          if (!isCurrent) return
          setTotalItems(0)
          setRoleAssignments(undefined)
          setNotificationInfo(getErrorMessage(error, t), 'error')
          setLoading(false)
        }
      )
    }
    getRoleAssignments()
    return () => {
      isCurrent = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, refreshCount, itemsPerPage, page, t])

  return (
    <>
      <Box
        gap="medium"
        pad={{ top: 'medium', vertical: 'medium' }}
        width={isEmpty(roleAssignments) ? 'medium' : 'xxlarge'}
      >
        <Box justify="start" gap="small">
          <Box direction="row" justify="between" gap="medium">
            <Typography
              type="heading"
              level="2"
              testId="groups-role-assignments-title"
            >
              {t('iam:role_assignment.role_assignments_title', {
                role: t('common:business_object.role_capitalized')
              })}
            </Typography>
            {!loading && roleAssignments?.length > 0 && (
              <VisibilityWrapper
                rbac={{
                  resource: '/ccs/authorization',
                  permission: 'ccs.authorization.edit'
                }}
              >
                <Button
                  label={t('iam:role_assignment.assign_role', {
                    roles: t('common:business_object.role_plural')
                  })}
                  secondary
                  testId="assign-roles-to-group-btn"
                  disabled={parentLoading}
                  onClick={() => {
                    setNotificationInfo(false)
                    setShowAssignRoleModal(true)
                  }}
                />
              </VisibilityWrapper>
            )}
          </Box>
          {roleAssignments?.length !== 0 || loading ? (
            <AssignmentContent
              handleOnDeleteBtnClick={handleOnDeleteBtnClick}
              retrievedAssignments={roleAssignments}
              page={page}
              setPage={setPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              fromRolesPage={false}
              parentLoading={loading}
            />
          ) : (
            <Box pad={{ top: 'medium', horizontal: 'large' }}>
              <NoDataInfo
                icon={<ContactInfo size="xlarge" />}
                gap="small"
                gapTitleSubtitle="small"
                action={
                  <VisibilityWrapper
                    rbac={{
                      resource: '/ccs/authorization',
                      permission: 'ccs.authorization.edit'
                    }}
                  >
                    <Button
                      label={t('iam:role_assignment.assign_role', {
                        roles: t('common:business_object.role_plural')
                      })}
                      onClick={() => {
                        setNotificationInfo(false)
                        setShowAssignRoleModal(true)
                      }}
                      testId="assign-roles-to-group-btn"
                      secondary
                    />
                  </VisibilityWrapper>
                }
                title={t('iam:role_assignment.no_role_assignment_title', {
                  role: t('common:business_object.role_plural')
                })}
                subtitle={
                  <VisibilityWrapper
                    rbac={{
                      resource: '/ccs/authorization',
                      permission: 'ccs.authorization.edit'
                    }}
                  >
                    {t('iam:role_assignment.assign_role_subtitle', {
                      group: t('common:business_object.group'),
                      role: t('common:business_object.role_plural')
                    })}
                  </VisibilityWrapper>
                }
                testId="no-assigned-role"
              />
            </Box>
          )}
          {openDeleteConfirmationDialog && (
            <DeleteRoleAssignmentConfirmation
              setConfirm={setopenDeleteConfirmationDialog}
              setNotificationInfo={setNotificationInfo}
              retrievedRA={retrievedSubjectData}
              onRemoved={() => {
                setNotificationInfo(
                  <Markdown>
                    {t(
                      'iam:workspace_groups.remove_role_assignment_success_notif_msg',
                      {
                        roleName: retrievedSubjectData?.role_name,
                        groupName: groupDetailsData?.displayName
                      }
                    )}
                  </Markdown>,
                  'info',
                  t(
                    'iam:workspace_groups.remove_role_assignment_success_notif_title'
                  )
                )
                setRefreshCount((prevCount) => prevCount + 1)
              }}
              title={t(
                'iam:workspace_groups.remove_role_assignment_dialog_title'
              )}
              subtitle={
                <Text testId="confirmation-text">
                  <Box
                    direction="row"
                    testId="role-assignment-delete-confirm-text"
                    wrap
                  >
                    <Markdown>
                      {t(
                        'iam:workspace_groups.remove_role_assignment_dialog_warning_msg',
                        {
                          roleName: retrievedSubjectData?.role_name,
                          groupName: groupDetailsData?.displayName
                        }
                      )}
                    </Markdown>
                    <Typography type="text" margin={{ top: 'medium' }}>
                      {t(
                        'iam:workspace_groups.remove_role_assignment_dialog_confirm_msg'
                      )}
                    </Typography>
                  </Box>
                </Text>
              }
            />
          )}
        </Box>
      </Box>
      {showAssignRoleModal && (
        <AssignRoleSideDrawer
          preSelectedSubject={{
            data: groupDetailsData,
            subjectType: 'GROUP'
          }}
          setShowAssignRoleModal={setShowAssignRoleModal}
          onAssign={() => {
            setRefreshCount((prevCount) => prevCount + 1)
          }}
          setStatusNotification={(notification) => {
            setNotificationInfo(
              notification?.message,
              notification?.severity,
              notification?.title
            )
          }}
        />
      )}
    </>
  )
}

GroupDetailsRoleAssignments.propTypes = {
  groupDetailsData: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string
  }).isRequired,
  setNotificationInfo: PropTypes.func.isRequired,
  parentLoading: PropTypes.bool.isRequired
}

export default GroupDetailsRoleAssignments
