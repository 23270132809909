import { useReactOidc } from '@axa-fr/react-oidc-context'
import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useHistory } from 'react-router-dom'

import { localStorageWhitelistWithoutPII } from '../utils/local-storage-utils'
import { isCoP, isV2 } from '../utils/feature-flag-utils'
import { setupInterceptor } from '../utils/api-utils'
import { useCCSContext } from '../context/ccs-context'

import {
  getBaseUrl,
  getCustomerAccount,
  useAppCatalogContext,
  AppCatalogActions
} from './shims/imports'
import { shimServices } from './shims/services'
import { MAX_PROVISION_TIMEOUT } from './constants'

export const useAxiosAuth = () => {
  const {
    oidcUser: { access_token }
  } = useReactOidc()

  const history = useHistory()
  const { dispatchCCSContext, csrfToken } = useCCSContext()

  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      /**
       * TODO: We're leveraging getBaseURL to access APIs temporarily. How can we change this for MFEs moving forward?
       */
      baseURL: getBaseUrl(),
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })

    setupInterceptor(dispatchCCSContext, csrfToken, history, isV2(), instance)

    return instance
  }, [access_token, csrfToken, dispatchCCSContext, history])

  return axiosInstance
}

// TODO: create an hook for axios api calls with useEffect: https://blog.openreplay.com/integrating-axios-with-react-hooks/
export const useServices = () => {
  const [provisions, setProvisions] = useState()
  const [error, setError] = useState('')
  const [isLoaded, setLoaded] = useState(false)
  const { platform_customer_id: pcid } = getCustomerAccount() || {}

  // eslint-disable-next-line no-underscore-dangle
  const axiosAuth = useAxiosAuth()
  const LDFlags = useFlags()

  // TODO: remove after merging for ccs-i18n locales
  useEffect(() => {
    let isCurrent = true
    const fetchApps = async () => {
      try {
        if (LDFlags['glcp-service-registry']) return
        const { data } = await axiosAuth.get(
          '/ui-doorway/ui/v1/applications/provisions'
        )
        if (!isCurrent) return
        setProvisions(data.provisions)
      } catch (e) {
        setError(e)
      } finally {
        setLoaded(true)
      }
    }
    if (pcid) {
      fetchApps()
    }
    return () => {
      isCurrent = false
    }
  }, [axiosAuth, LDFlags, pcid])

  const { services, servicesLegacy } = useMemo(
    () =>
      provisions
        ? shimServices(provisions, LDFlags)
        : {
            services: [],
            servicesLegacy: []
          },
    [provisions, LDFlags]
  )

  return {
    servicesLegacy,
    services,
    error,
    isLoaded
  }
}

export const useRegions = () => {
  const { regionListData, dispatchAppCatalogContext } = useAppCatalogContext()
  const [regions, setRegions] = useState(
    regionListData?.length > 0 ? regionListData : []
  )
  const [error, setError] = useState('')
  const [isLoaded, setLoaded] = useState(regionListData?.length > 0)
  // eslint-disable-next-line no-underscore-dangle
  const axiosAuth = useAxiosAuth()
  useEffect(() => {
    let isCurrent = true
    const fetchApps = async () => {
      try {
        const { data } = await axiosAuth.get('/geo/ui/v1/regions')
        if (!isCurrent) return
        dispatchAppCatalogContext({
          type: AppCatalogActions.SET_REGION_LIST,
          data: data.regions
        })
        setRegions(data.regions)
      } catch (e) {
        setError(e)
      } finally {
        setLoaded(true)
      }
    }
    if (regionListData?.length === 0) {
      fetchApps()
    }
    return () => {
      isCurrent = false
    }
  }, [axiosAuth, regionListData, dispatchAppCatalogContext])

  return {
    regions,
    error,
    isLoaded
  }
}

export const useRegionFormatter = () => {
  const { regions, isLoaded } = useRegions()
  return (region) =>
    isLoaded && regions.find((item) => item.code === region).name
}
export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = localStorage.getItem(key)
    return item ? JSON.parse(item) : initialValue
  })
  const setValue = (value) => {
    setStoredValue(value)
  }
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(storedValue))
  }, [key, storedValue])
  return [storedValue, setValue]
}

// Any changes to this hook, should also be done in enableRecentServices() of ui/src/pages/applications/utils/utils.js as well.
export const useRecentServices = () => {
  const [recentServicesStorage, setRecentServicesStorage] = useLocalStorage(
    localStorageWhitelistWithoutPII.DASHBOARD_LAST_ACCESSED,
    []
  )
  const { platform_customer_id: pcid } = getCustomerAccount() || {}
  const recentServices = recentServicesStorage?.[pcid] || []

  return [
    recentServices,
    (serviceSlug) => {
      if (
        recentServices.some((_service) => _service.serviceSlug === serviceSlug)
      ) {
        const index = recentServices.findIndex(
          (_service) => _service.serviceSlug === serviceSlug
        )
        recentServices.splice(index, 1)
      }

      setRecentServicesStorage({
        ...recentServicesStorage,
        [pcid]: [
          ...recentServices,
          { serviceSlug, utc: new Date().toUTCString() }
        ]
      })
    }
  ]
}

export const useServiceRedirect = () => {
  const [, setRecentServices] = useRecentServices()
  const { platform_customer_id: pcid } = getCustomerAccount() || {}

  const axiosAuth = useAxiosAuth()

  // fetches the login url when service manager id (app instance) is available
  const fetchLoginUrl = async (service, selectedRegion) => {
    const { serviceManagerId } = service.regions.find(
      ({ code }) => code === selectedRegion
    )

    const {
      data: { login_url }
    } = await axiosAuth.get(
      `/authn/v1/onboarding/login-url/${serviceManagerId}`
    )

    return login_url
  }

  // handles the setting and removal of web storage items
  const handleWebStorage = async ({ serviceSlug }, redirectUrl) => {
    // Remove existing slug from recent services if it exists
    setRecentServices(serviceSlug)

    sessionStorage.removeItem('redirect-query')

    // @ts-ignore
    if (window.$settings.isCoP) {
      sessionStorage.setItem('cop-central-url', redirectUrl)
      sessionStorage.removeItem('from-central')
    }
  }

  // returns a callback that redirects to the service's login url
  return async (service, selectedRegion, slug = service.serviceSlug) => {
    const redirectUrl =
      service.redirectUrl?.() || (await fetchLoginUrl(service, selectedRegion))

    // passing service manager's `application_customer_id` in as cid
    const { serviceManagerCustomerId: cid } =
      service.regions?.find(({ code }) => code === selectedRegion) || {}

    handleWebStorage(service, redirectUrl)

    const { account_type } =
      // @ts-ignore
      JSON.parse(sessionStorage.getItem('account')) || {}

    const service_offer = slug
    const queryParams = new URLSearchParams(
      Object.entries({
        account_type,
        cid,
        service_offer,
        workspace_id: pcid
      }).filter(([, paramValue]) => paramValue)
    )

    if (service.redirectUrl?.()) {
      window.open(redirectUrl, '_blank')
    } else {
      // compiles URL with account_type and application_customer_id
      window.location.replace(`${redirectUrl}?${queryParams.toString()}`)
    }
  }
}

export const useNewServiceRedirect = () => {
  const [, setRecentServices] = useRecentServices()
  const { platform_customer_id: pcid } = getCustomerAccount() || {}

  const axiosAuth = useAxiosAuth()

  // fetches the login url when service manager id (app instance) is available
  const fetchLoginUrl = async (serviceInstanceId) => {
    const {
      data: { login_url }
    } = await axiosAuth.get(
      `/authn/v1/onboarding/login-url/${serviceInstanceId}`
    )

    return login_url
  }

  // handles the setting and removal of web storage items
  const handleWebStorage = async (slug, redirectUrl) => {
    // Remove existing slug from recent services if it exists
    setRecentServices(slug)

    sessionStorage.removeItem('redirect-query')

    // @ts-ignore
    if (isCoP()) {
      sessionStorage.setItem('cop-central-url', redirectUrl)
      sessionStorage.removeItem('from-central')
    }
  }

  // returns a callback that redirects to the service's login url
  return async (provision, slug = provision.slug) => {
    const { application_instance_id, application_customer_id: cid } = provision

    const redirectUrl = await fetchLoginUrl(application_instance_id)

    handleWebStorage(slug, redirectUrl)

    const { account_type } =
      // @ts-ignore
      JSON.parse(sessionStorage.getItem('account')) || {}

    const queryParams = new URLSearchParams(
      Object.entries({
        account_type,
        cid,
        service_offer: slug,
        workspace_id: pcid
      }).filter(([, paramValue]) => paramValue)
    )

    // compiles URL with account_type and application_customer_id
    window.location.replace(`${redirectUrl}?${queryParams.toString()}`)
  }
}

export const usePollServiceDetail = (
  setServiceDetailData,
  provisions,
  id,
  isServiceProvision,
  isIGCFeature
) => {
  const axiosInstance = useAxiosAuth()
  useEffect(() => {
    let refreshTimer = null
    const provisionStatuses = provisions.map(
      ({ provision_status: provisionStatus }) => provisionStatus
    )
    async function polling() {
      if (
        provisionStatuses.filter((provisionStatus) =>
          [
            'PROVISION_INITIATED',
            'UNPROVISION_INITIATED',
            'UNPROVISION_FAILED'
          ].includes(provisionStatus)
        ).length >= 1
      ) {
        axiosInstance
          .get(`/service-catalog/v1alpha1/detailed-service-offers/${id}`)
          .then(({ data: serviceDetailData }) => {
            const provisionsObj =
              isIGCFeature &&
              serviceDetailData.org_singleton_service_provisions !== null
                ? serviceDetailData.org_singleton_service_provisions
                : serviceDetailData.provisions

            const newProvisionStatuses = provisionsObj.map((provision) => {
              let status = isServiceProvision
                ? provision.service_provision?.provision_status
                : provision.application_provision?.provision_status
              // do polling timeout for IGC
              if (isIGCFeature) {
                const updateAt = isServiceProvision
                  ? provision.service_provision?.updated_at
                  : provision.application_provision?.updated_at
                const nowlocal = new Date()
                // convert now to utc timestamp
                const nowUTC = nowlocal.getTime() + nowlocal.getTimezoneOffset()
                // parse UTC string get timestamp
                const initProvisionTime = Date.parse(updateAt)
                // if updated_at time more than 5 mins, let status to failed
                if (
                  nowUTC - initProvisionTime > MAX_PROVISION_TIMEOUT &&
                  status === 'PROVISION_INITIATED'
                ) {
                  status = 'PROVISION_FAILED'
                  if (isServiceProvision) {
                    provision.service_provision.reason = 'Provision time out'
                    provision.service_provision.provision_status =
                      'PROVISION_FAILED'
                  } else {
                    provision.application_provision.reason =
                      'Provision time out'
                    provision.application_provision.provision_status =
                      'PROVISION_FAILED'
                  }
                }
              }

              return status
            })
            if (
              JSON.stringify(newProvisionStatuses) !==
              JSON.stringify(provisionStatuses)
            )
              setServiceDetailData(serviceDetailData)
          })
        refreshTimer = setTimeout(polling, 5000)
      }
    }
    polling()
    return () => {
      clearTimeout(refreshTimer)
    }
  }, [
    setServiceDetailData,
    provisions,
    id,
    isServiceProvision,
    isIGCFeature,
    axiosInstance
  ])
}
