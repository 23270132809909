import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { Layout } from '../commoncomponents/layout/Layout'

import CustomerDetails from './components/CustomerDetails'
import CustomerAccount from './CustomerAccount'

const CustomerAccountRouterContent = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}/:customerId`}>
        <CustomerDetails />
      </Route>

      {/* NOTE: Always add any routes above this route */}
      <Route path={path}>
        <CustomerAccount />
      </Route>
    </Switch>
  )
}

const CustomerAccountRouter = () => {
  return (
    <Layout>
      <CustomerAccountRouterContent />
    </Layout>
  )
}

export default CustomerAccountRouter
