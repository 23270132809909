import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Typography, Button, Card } from '../../components'
import { AccountLogo } from '../commoncomponents/account-logo/AccountLogo'
import { getWorkspaceString, WKSPC_CAPITALIZED } from '../../utils/common-utils'

const StyledBox = styled(Box)`
  border-radius: 6px;
  background: #efefef;
`

const isMSPAccount = (account) => {
  const { account_type: accntType } = account
  return accntType === 'MSP'
}

const AccountCard = ({
  account,
  onLaunch,
  active,
  actionStatus,
  isLoading
}) => {
  const { t } = useTranslation(['authn'])
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  return (
    <Card
      cardWidth="medium"
      key={`${account.platform_customer_id}-card`}
      testId={`${account.platform_customer_id}-card`}
      CustomContent={
        <Box width="100%">
          <Box direction="row" justify="between" align="flex-start">
            <Box align="center" justify="start" direction="row" gap="small">
              <AccountLogo
                account={account}
                logoOnly
                testId="accountcard"
                title={account.company_name}
                avatarSize="medium"
                logoSize="xsmall"
              />
              {isMSPAccount(account) ? (
                <StyledBox
                  background="lightgray"
                  pad={{ horizontal: 'small', vertical: 'xxsmall' }}
                >
                  <Typography size="small" type="text">
                    {t('account_type.mode_msp_short')}
                  </Typography>
                </StyledBox>
              ) : null}
            </Box>
            <Box justify="end">
              {active ? (
                <Typography type="text" testId="current-account" weight="bold">
                  {t('account_type.current_account', {
                    account: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  })}
                </Typography>
              ) : (
                <Button
                  secondary
                  label={t('customer_account.launch')}
                  margin={{ top: 'xsmall' }}
                  onClick={() => onLaunch(account)}
                  testId={`${account.platform_customer_id}-launch-btn`}
                  isLoading={isLoading}
                />
              )}
            </Box>
          </Box>
          <Box margin={{ top: 'small' }}>
            <Typography
              type="heading"
              level="2"
              truncate
              testId={`account-title-${account.platform_customer_id}`}
            >
              {account.company_name}
            </Typography>
          </Box>
          {actionStatus && (
            <Box pad={{ top: 'small' }} width="100%">
              {actionStatus}
            </Box>
          )}
        </Box>
      }
    />
  )
}

AccountCard.propTypes = {
  account: PropTypes.object.isRequired,
  onLaunch: PropTypes.func,
  active: PropTypes.bool,
  /**
   * This prop will be used for showing the status message underneath the action button.
   */
  actionStatus: PropTypes.element,
  /**
   * This prop will be used for showing the loader for action button.
   */
  isLoading: PropTypes.bool
}

AccountCard.defaultProps = {
  active: false,
  onLaunch: null,
  actionStatus: null,
  isLoading: false
}

export { AccountCard }
