import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, TextInput, FormField } from 'grommet'

import {
  FormInput,
  CCSForm,
  Button,
  ModalDialog,
  ModalHeader,
  ModalFooter,
  Typography
} from '../../../components'

import { appInfoValidate } from './utils'

const AddCredentialModal = ({
  setShowModal,
  addCredential,
  appType,
  credentialList
}) => {
  const { t } = useTranslation(['local-gateway', 'common'])
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')
  const [startValidate, setStartValidate] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (startValidate) {
      const validation = appInfoValidate(
        { appId: appType, username, password, rePassword },
        t,
        credentialList.map((cred) => cred.username)
      )
      setErrorMessage(validation.message)
    }
  }, [
    appType,
    t,
    username,
    password,
    rePassword,
    credentialList,
    startValidate
  ])

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Box pad={{ bottom: 'medium' }}>
              <Typography type="text" testId="add-credential-header-app-name">
                {t(`${appType}.name`)}
              </Typography>
              <Typography
                type="heading"
                level="1"
                testId="add-credential-header-title"
              >
                {t('add_credentials')}
              </Typography>
            </Box>
          }
          onClose={() => {
            setShowModal(false)
          }}
        />
      }
      content={
        <Box>
          <CCSForm errorMessage={errorMessage} testId="add-credential-form">
            <Box>
              <FormField
                label={t('user_name')}
                required
                data-testid="container-user-name"
              >
                <TextInput
                  id="name-field"
                  type="text"
                  placeholder={t('configure_application_username_placeholder')}
                  autoComplete="off"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormField>
              <FormInput
                testId="password-input"
                inputType="text"
                required
                label={t('password')}
                id="password-field"
                type="password"
                placeholder={t('configure_application_password_placeholder')}
                info={t('password_length')}
                data-testid="password-input"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormField
                label={t('password')}
                required
                data-testid="container-re-password"
              >
                <TextInput
                  id="re-password-field"
                  type="password"
                  placeholder={t(
                    'configure_application_re_password_placeholder'
                  )}
                  data-testid="re-password-field"
                  autoComplete="off"
                  value={rePassword}
                  onChange={(e) => setRePassword(e.target.value)}
                />
              </FormField>
            </Box>
          </CCSForm>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <Box direction="row" gap="small">
              <Button
                label={t('common:cancel')}
                testId="cancel-btn"
                onClick={() => setShowModal(false)}
              />
              <Button
                margin={{ left: 'small' }}
                label={t('add_credentials')}
                primary
                testId="add-btn"
                onClick={() => {
                  setStartValidate(true)

                  const validation = appInfoValidate(
                    { appId: appType, username, password, rePassword },
                    t,
                    credentialList.map((cred) => cred.username)
                  )
                  setErrorMessage(validation.message)

                  if (validation.isValid) {
                    addCredential({ username, password })
                    setShowModal(false)
                  }
                }}
              />
            </Box>
          }
        />
      }
      onClose={() => setShowModal(false)}
      testId="add-credentials-modal-dialog"
      width="medium"
    />
  )
}

AddCredentialModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  addCredential: PropTypes.func.isRequired,
  appType: PropTypes.string.isRequired,
  credentialList: PropTypes.array.isRequired
}

export default AddCredentialModal
