import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, FormField, TextInput } from 'grommet'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-unresolved
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ButtonGroup,
  CCSForm,
  ModalDialog,
  ModalFooter,
  Typography
} from '../../../../../../components'
import { validLicenseKey } from '../../../../../../utils/validation-utils'
import { getApiErrorMessage } from '../../../../../../utils/error-handling-utils'
import { get, post } from '../../../../../../utils/api-utils'

const AddServiceSubscription = ({
  onSetOpen,
  onSubmitSuccess,
  onDelayedActivation,
  onDuplicateOffering
}) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const [keyValue, setKeyValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const {
    'glcp-saas-delay-activation': delayedActivationSaaSFlag,
    'glcp-saas-duplicate-warning': duplicateWarningSaaSFlag
  } = useFlags()

  const handleAddSubscriptionKey = () => {
    post(
      '/ui-doorway/ui/v1/customers/license',
      { key: keyValue },
      oidcUser.access_token
    ).then(
      () => {
        onSubmitSuccess()
        onSetOpen(false)
      },
      (error) => {
        setErrorMessage(getApiErrorMessage(error, t))
      }
    )
  }

  const checkDelayedActivationStatus = () => {
    get(
      `/ui-doorway/ui/v1/license/${keyValue}/delayedactivation/status`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        if (response?.data?.response === 'true') {
          onSetOpen(false)
          onDelayedActivation(keyValue)
        } else {
          handleAddSubscriptionKey()
        }
      },
      (error) => {
        setErrorMessage(getApiErrorMessage(error, t))
      }
    )
  }

  const checkDuplicateWarningStatus = () => {
    get(
      `/ui-doorway/ui/v1/license/${keyValue}/is-duplicate`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        if (response?.data?.isDuplicate) {
          onSetOpen(false)
          onDuplicateOffering(keyValue)
        } else if (delayedActivationSaaSFlag) checkDelayedActivationStatus()
        else handleAddSubscriptionKey()
      },
      (error) => {
        setErrorMessage(getApiErrorMessage(error, t))
      }
    )
  }

  const validateAndSubmitKey = () => {
    if (validLicenseKey(keyValue)) {
      if (duplicateWarningSaaSFlag) {
        checkDuplicateWarningStatus()
      } else if (delayedActivationSaaSFlag) {
        checkDelayedActivationStatus()
      } else {
        handleAddSubscriptionKey()
      }
    } else {
      setErrorMessage(t('invalid_key_format'))
    }
  }

  return (
    <>
      <ModalDialog
        width="medium"
        testId="add-license-keys-modal-dialog"
        onClose={() => {
          onSetOpen(false)
        }}
        content={
          <Box style={{ overflow: 'auto' }}>
            <Box gap="xsmall">
              <Typography type="heading" testId="add-licenses-title" level="2">
                {t('add_service_subscription')}
              </Typography>
              <Typography type="paragraph" testId="add-licenses-subtitle">
                {t('licensing:add_subscription_desc')}
              </Typography>
            </Box>
            <Box margin={{ top: 'small' }} gap="xsmall">
              <CCSForm errorMessage={errorMessage} testId="license-key-form">
                <FormField
                  label={t('subscription_key')}
                  htmlFor="subscription_key"
                  flex="grow"
                >
                  <TextInput
                    id="subscription_key"
                    value={keyValue}
                    margin={{ top: 'medium' }}
                    data-testid="license-key-input-field"
                    color="text-strong"
                    placeholder={t('enter_subscription_key')}
                    width="100%"
                    onChange={(e) => {
                      setErrorMessage('')
                      setKeyValue(e.target.value)
                    }}
                  />
                </FormField>
              </CCSForm>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    onClick: () => onSetOpen(false)
                  },
                  {
                    id: 1,
                    label: t('submit'),
                    primary: true,
                    testId: 'submit-licenses-btn',
                    onClick: validateAndSubmitKey
                  }
                ]}
                testId="two-buttons"
              />
            }
          />
        }
      />
    </>
  )
}

AddServiceSubscription.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onDelayedActivation: PropTypes.func.isRequired,
  onDuplicateOffering: PropTypes.func.isRequired
}

export { AddServiceSubscription }
