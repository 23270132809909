import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, TextInput, FormField, ResponsiveContext } from 'grommet'
import { PropTypes } from 'prop-types'

import { Typography, Card, FormInput } from '../../../../../components'
import { WizardContext } from '../../../../../components/wizard/WizardContext'

const appList = ['greenlake_proxy']

const AppDescription = ({ selectedApp }) => {
  const { t } = useTranslation(['local-gateway'])
  if (selectedApp) {
    return (
      <Card
        pad="medium"
        background="background-contrast"
        testId="description-card"
        width="large"
        CustomContent={
          <>
            <Box pad={{ bottom: 'medium' }}>
              <Typography
                level="3"
                type="heading"
                weight="normal"
                testId="app-title"
              >
                {t(`${selectedApp}.name`)}
              </Typography>
            </Box>
            <Box pad={{ bottom: 'small' }}>
              <Typography type="text" testId="page-description" size="medium">
                {t(`${selectedApp}.description`)}
              </Typography>
            </Box>
            <Box>
              <Typography type="text" testId="extra-info" size="medium">
                {t(`${selectedApp}.extra_info`)}
              </Typography>
            </Box>
          </>
        }
      />
    )
  }
  return null
}

AppDescription.defaultProps = {
  selectedApp: undefined
}
AppDescription.propTypes = {
  selectedApp: PropTypes.string
}

const ConfigureApp = () => {
  const { t } = useTranslation(['local-gateway'])
  const {
    formValues,
    formValues: { gatewayDetails },
    formValues: {
      gatewayDetails: { username, password, rePassword, appId }
    },
    setFormValues
  } = useContext(WizardContext)
  const size = useContext(ResponsiveContext)
  const descDirection = ['xsmall', 'small'].includes(size) ? 'column' : 'row'
  const fieldWidth = descDirection === 'column' ? 'large' : 'medium'

  useEffect(() => {
    if (!appId) {
      // TODO change this when we offer the ability to change the app
      setFormValues({
        ...formValues,
        gatewayDetails: {
          ...gatewayDetails,
          appId: appList[0]
        }
      })
    }
  }, [formValues, appId, gatewayDetails, setFormValues])

  return (
    <Box
      pad={{ bottom: 'medium' }}
      align="center"
      direction={descDirection}
      gap="medium"
    >
      <Box flex="grow">
        <FormInput
          id="application-field"
          data-testid="application-field"
          testId="application-field"
          label={t('application')}
          width={fieldWidth}
          inputType="text"
          value={t(`${appList[0]}.name`)}
          disabled
          required
        />
        <FormField label={t('user_name')} required data-testid="container-name">
          <TextInput
            id="user-name-field"
            width={fieldWidth}
            type="text"
            placeholder={t('configure_application_username_placeholder')}
            data-testid="user-name-input"
            autoComplete="off"
            value={username}
            onChange={(e) => {
              setFormValues({
                ...formValues,
                gatewayDetails: {
                  ...gatewayDetails,
                  username: e.target.value
                }
              })
            }}
          />
        </FormField>
        <FormInput
          width={fieldWidth}
          inputType="text"
          required
          label={t('password')}
          id="password-field"
          testId="password-field"
          type="password"
          placeholder={t('configure_application_password_placeholder')}
          info={t('password_length')}
          autoComplete="off"
          value={password}
          onChange={(e) => {
            setFormValues({
              ...formValues,
              gatewayDetails: {
                ...gatewayDetails,
                password: e.target.value
              }
            })
          }}
        />
        <FormField
          label={t('password')}
          required
          data-testid="container-re-password"
          width={fieldWidth}
        >
          <TextInput
            id="re-password-field"
            type="password"
            width="medium"
            placeholder={t('configure_application_re_password_placeholder')}
            data-testid="re-password-input"
            autoComplete="off"
            value={rePassword}
            onChange={(e) => {
              setFormValues({
                ...formValues,
                gatewayDetails: {
                  ...gatewayDetails,
                  rePassword: e.target.value
                }
              })
            }}
          />
        </FormField>
      </Box>
      <Box>
        <AppDescription selectedApp={appList[0]} />
      </Box>
    </Box>
  )
}
export default ConfigureApp
