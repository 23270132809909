// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, FormField, RadioButtonGroup } from 'grommet'

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../../components'

const InviteUserSelectionModal = ({ onSave, onSetOpen }) => {
  const { t } = useTranslation(['manage'])
  const [value, setValue] = useState()
  const [errorMessage, setErrorMessage] = useState('')

  const options = [
    { label: t('users.single_user_invite'), value: 'single_user' },
    {
      label: t('users.multiple_user_invite'),
      value: 'multiple_user'
    }
  ]

  return (
    <>
      <ModalDialog
        width="large"
        testId="export-modal-dialog"
        header={
          <ModalHeader
            title={
              <Typography
                testId="invite-user-title"
                type="heading"
                level="2"
                textAlign="start"
              >
                {t('invite_user')}
              </Typography>
            }
            subtitle={
              <Typography
                testId="invite-user-desc"
                type="paragraph"
                size="medium"
              >
                {t('users.select_method_choice')}
              </Typography>
            }
          />
        }
        content={
          <Box margin={{ top: 'medium' }}>
            <Box gap="xsmall">
              <Typography
                type="paragraph"
                size="small"
                testId="invite-user-selection-modal-subtitle"
              >
                {t('users.select_method_to_invite_user')}
              </Typography>
            </Box>
            <Box>
              <Box data-testid="invite-user-selection-option-radio-btn">
                <FormField
                  name="user-selection"
                  required
                  data-testid="user-selection-form-field"
                  error={errorMessage}
                >
                  <RadioButtonGroup
                    name="invite-user-selection-option"
                    options={options}
                    value={value}
                    onChange={(event) => {
                      setErrorMessage('')
                      setValue(event.target.value)
                    }}
                    required
                  />
                </FormField>
              </Box>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('aliases.cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    onClick: () => onSetOpen(false)
                  },
                  {
                    id: 1,
                    label: t('notification.next_btn_label'),
                    primary: true,
                    testId: 'next-action-btn',
                    // disabled:{!value?.length},
                    onClick: () => {
                      if (value?.length) {
                        setErrorMessage('')
                        onSave(value)
                      } else {
                        setErrorMessage(t('common:this_is_required'))
                      }
                    }
                  }
                ]}
                testId="two-buttons"
              />
            }
          />
        }
        onClose={() => onSetOpen(false)}
      />
    </>
  )
}

InviteUserSelectionModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onSetOpen: PropTypes.func.isRequired
}

export { InviteUserSelectionModal }
