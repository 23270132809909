import { Box, FormField, TextInput } from 'grommet'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { Dropdown, Typography, Ruler } from '../../../../../../components'
import { getAllMac } from '../../../../utils'

const ManagedDeviceToControllerFormField = ({
  state,
  dispatch,
  customerId,
  inCCSManager
}) => {
  const { t } = useTranslation(['device'])
  const { oidcUser } = useReactOidc()

  const [listOfMobilityConductor, setListOfMobilityConductor] = useState([])
  const [listOfMobilityConductorResponse, setListOfMobilityConductorResponse] =
    useState([])

  const [listOfBackupMobilityConductor, setListOfBackupMobilityConductor] =
    useState([])
  const [
    listOfBackupMobilityConductorResponse,
    setListOfBackupMobilityConductorResponse
  ] = useState([])

  const [listOfVpnMac, setListOfVpnMac] = useState([])
  const [listOfVpnMacResponse, setListOfVpnMacResponse] = useState([])

  const [listOfBackupVpnMac, setListOfVpnMacBackup] = useState([])
  const [listOfBackupVpnMacResponse, setListOfBackupVpnMacResponse] = useState(
    []
  )

  const listOfRedundancyLevel = [t('no_redundancy'), 'L2', 'L3']

  useEffect(() => {
    const param = {
      action: 'QUERY',
      mode: 'MC',
      ...(inCCSManager && { platform_customer_id: customerId })
    }
    getAllMac({
      token: oidcUser.access_token,
      param,
      inCCSManager
    }).then((data) => {
      setListOfMobilityConductor(data)
      setListOfMobilityConductorResponse(data)

      setListOfBackupMobilityConductor(data)
      setListOfBackupMobilityConductorResponse(data)
    })
  }, [customerId, inCCSManager, oidcUser.access_token])

  useEffect(() => {
    const param = {
      action: 'QUERY',
      mode: 'MD-VPNC',
      ...(inCCSManager && { platform_customer_id: customerId })
    }
    getAllMac({
      token: oidcUser.access_token,
      param,
      inCCSManager
    }).then((data) => {
      setListOfVpnMac(data)
      setListOfVpnMacResponse(data)

      setListOfVpnMacBackup(data)
      setListOfBackupVpnMacResponse(data)
    })
  }, [customerId, inCCSManager, oidcUser.access_token])

  const getFieldName = (status, primaryField, secondaryField) => {
    if (status === 'PRIMARY') return primaryField
    return secondaryField
  }

  function detailsBody(status = 'PRIMARY') {
    const masterContollerField = getFieldName(
      status,
      'controller',
      'controller2'
    )
    const masterContollerIpField = getFieldName(
      status,
      'value',
      'primary_ctrl_ip2'
    )
    const backupMasterContollerField = getFieldName(
      status,
      'backup_controller',
      'backup_controller2'
    )
    const vpnMacField = getFieldName(status, 'vpn_mac', 'vpn_mac2')
    const vpnIpField = getFieldName(status, 'vpn_ip', 'vpn_ip2')
    const backupVpnMacField = getFieldName(
      status,
      'backup_vpn_mac',
      'backup_vpn_mac2'
    )
    return (
      <Box
        margin={{ bottom: 'xxsmall' }}
        direction="column"
        justify="between"
        gap="small"
      >
        <FormField
          data-testid="master-controller-form-field"
          label={t('mobility_conductor')}
          name={masterContollerField}
          width="medium"
        >
          <Dropdown
            name={masterContollerField}
            value={getFieldName(status, state.controller, state.controller2)}
            placeholder={t('select_primary')}
            options={listOfMobilityConductor}
            searchPlaceholder={t('search')}
            onChangeDropdown={(val) => {
              dispatch({
                value: val,
                field: masterContollerField,
                type: 'CHANGE_FIELD'
              })
            }}
            testId="master-controller-input"
            onSearch={(searchText) => {
              setListOfMobilityConductor(
                listOfMobilityConductorResponse.filter(
                  (value) => value === searchText
                )
              )
            }}
            onClose={() => {
              setListOfMobilityConductor(listOfMobilityConductorResponse)
            }}
          />
        </FormField>

        <FormField
          data-testid="master-controller-ip-form-field"
          label={t('mobility_conductor_ip')}
          name={masterContollerIpField}
          width="medium"
        >
          <TextInput
            data-testid="master-controller-ip-input"
            name={masterContollerIpField}
            value={getFieldName(status, state.value, state.primary_ctrl_ip2)}
            onChange={(event) =>
              dispatch({
                value: event.target.value,
                field: masterContollerIpField,
                type: 'CHANGE_FIELD'
              })
            }
          />
        </FormField>

        {['L2', 'L3'].includes(state.redundancy_level) && (
          <FormField
            data-testid="backup-master-controller-form-field"
            label={t('backup_mobility_conductor')}
            name={backupMasterContollerField}
            width="medium"
          >
            <Dropdown
              name={backupMasterContollerField}
              placeholder={t('select_from_list')}
              options={listOfBackupMobilityConductor}
              searchPlaceholder={t('search')}
              value={getFieldName(
                status,
                state.backup_controller,
                state.backup_controller2
              )}
              onChangeDropdown={(val) => {
                dispatch({
                  value: val,
                  field: backupMasterContollerField,
                  type: 'CHANGE_FIELD'
                })
              }}
              testId="backup-master-controller-input"
              onSearch={(searchText) => {
                setListOfBackupMobilityConductor(
                  listOfBackupMobilityConductorResponse.filter(
                    (value) => value === searchText
                  )
                )
              }}
              onClose={() => {
                setListOfBackupMobilityConductor(
                  listOfBackupMobilityConductorResponse
                )
              }}
            />
          </FormField>
        )}

        <FormField
          data-testid="vpn-concentrator-mac-form-field"
          label={t('vpn_concentrator_mac')}
          name={vpnMacField}
          width="medium"
        >
          <Dropdown
            name={vpnMacField}
            placeholder={t('select_vpn_mac')}
            options={listOfVpnMac}
            value={getFieldName(status, state.vpn_mac, state.vpn_mac2)}
            onChangeDropdown={(val) => {
              dispatch({
                value: val,
                field: vpnMacField,
                type: 'CHANGE_FIELD'
              })
            }}
            testId="vpn-concentrator-mac-input"
            onSearch={(searchText) => {
              setListOfVpnMac(
                listOfVpnMacResponse.filter((value) => value === searchText)
              )
            }}
            onClose={() => {
              setListOfVpnMac(listOfVpnMacResponse)
            }}
          />
        </FormField>

        <FormField
          data-testid="vpn-concentrator-ip-form-field"
          label={t('vpn_concentrator_ip')}
          name={vpnIpField}
          width="medium"
        >
          <TextInput
            data-testid="vpn-concentrator-ip-input"
            name={vpnIpField}
            value={getFieldName(status, state.vpn_ip, state.vpn_ip2)}
            onChange={(event) =>
              dispatch({
                value: event.target.value,
                field: vpnIpField,
                type: 'CHANGE_FIELD'
              })
            }
          />
        </FormField>

        <FormField
          data-testid="backup-vpn-concentrator-mac-form-field"
          label={t('backup_vpn_concentrator_mac')}
          name={backupVpnMacField}
          width="medium"
        >
          <Dropdown
            name={backupVpnMacField}
            placeholder={t('select_vpn_mac')}
            searchPlaceholder={t('search')}
            options={listOfBackupVpnMac}
            value={getFieldName(
              status,
              state.backup_vpn_mac,
              state.backup_vpn_mac2
            )}
            onChangeDropdown={(val) => {
              dispatch({
                value: val,
                field: backupVpnMacField,
                type: 'CHANGE_FIELD'
              })
            }}
            testId="backup-vpn-concentrator-mac-input"
            onSearch={(searchText) => {
              setListOfVpnMacBackup(
                listOfBackupVpnMacResponse.filter(
                  (value) => value === searchText
                )
              )
            }}
            onClose={() => {
              setListOfVpnMacBackup(listOfBackupVpnMacResponse)
            }}
          />
        </FormField>
      </Box>
    )
  }

  return (
    <Box
      margin={{ bottom: 'xxsmall' }}
      direction="column"
      justify="between"
      gap="small"
    >
      <FormField
        data-testid="redundancy-level-form-field"
        label={t('redundancy_level')}
        name="redundancy_level"
        width="medium"
      >
        <Dropdown
          name="redundancy_level"
          placeholder={t('select_redundancy_level')}
          options={listOfRedundancyLevel}
          value={state.redundancy_level}
          onChangeDropdown={(val) => {
            dispatch({
              value: val,
              field: 'redundancy_level',
              type: 'CHANGE_FIELD'
            })
          }}
          testId="redundancy-level-input"
        />
      </FormField>

      <FormField
        data-testid="config-node-path-form-field"
        label={t('config_node_path')}
        name="config_node_path"
        width="medium"
      >
        <TextInput
          data-testid="config-node-path-input"
          name="config_node_path"
          value={state.config_node_path}
          onChange={(event) =>
            dispatch({
              value: event.target.value,
              field: 'config_node_path',
              type: 'CHANGE_FIELD'
            })
          }
        />
      </FormField>
      {state.redundancy_level !== 'L3' ? (
        detailsBody()
      ) : (
        <>
          <Typography type="text" testId="primary-data-center-text">
            {t('primary_data_center')}:
          </Typography>
          <Ruler testId="default-ruler" />
          {detailsBody()}
          <Typography type="text" testId="secondary-data-center-text">
            {t('secondary_data_center')}:
          </Typography>
          <Ruler testId="default-ruler" />
          {detailsBody('SECONDARY')}
        </>
      )}
    </Box>
  )
}

ManagedDeviceToControllerFormField.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  inCCSManager: PropTypes.bool
}

ManagedDeviceToControllerFormField.defaultProps = {
  customerId: undefined,
  inCCSManager: undefined
}

export { ManagedDeviceToControllerFormField }
