// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  Box,
  Button as GrommetButton,
  FormField,
  CheckBoxGroup,
  RadioButton,
  TextInput,
  DateInput,
  Markdown,
  SelectMultiple
} from 'grommet'
import { Filter, Search as SearchIcon } from 'grommet-icons'
import debounce from 'lodash/debounce'

import {
  ModalDialog,
  ModalFooter,
  ModalHeader
} from '../modal-dialog/ModalDialog'
import { ButtonGroup } from '../button-group/ButtonGroup'
import { Typography } from '../typography/Typography'
import { CCSForm } from '../form/Form'
import { dimensions, filterDialogPosition } from '../utils'
import { Dropdown } from '../dropdown/Dropdown'
/* Inputs should always be accompanied by labels for accessibility. An icon
    may serve as a label if 1) the icon meaning is well understood, and 2)
    has an 'aria-labelledby' attribute. For additional detail:
    https://www.w3.org/WAI/tutorials/forms/labels/#using-aria-labelledby
    https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/forms/Basic_form_hints
  */
const StyledTextInput = styled(TextInput).attrs(() => ({
  'aria-labelledby': 'search-icon'
}))`
  font-size: 18px;
`

const filterAttributesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    selectionType: PropTypes.oneOf([
      'checkbox',
      'radio',
      'text',
      'dropdown',
      'date-range',
      'search',
      'select-multiple-dropdown',
      'select-multiple'
    ]), // default-"checkbox"
    values: PropTypes.arrayOf(
      PropTypes.shape({
        valueName: PropTypes.string,
        valueLabel: PropTypes.string,
        searchableLabels: PropTypes.arrayOf(PropTypes.string)
      })
    ),
    height: PropTypes.oneOf(dimensions), // applicable only for checkbox and radiobutton for internal scroll

    /**
     * used to get new data to display as values
     * for now, it's used to get new values (for example using an API call in case of search)
     */
    updateValues: PropTypes.func,

    /**
     * a renderer for selected values if one is needed
     * for now, it's only used to render selected values for search
     */
    selectedValuesRenderer: PropTypes.func,
    /**
     * render date range component starting from startDate to endDate
     * Dates before startDate and after endDate will be disabled in calender
     * Both parameters are supposed to be passed or None should be passed
     */
    calendarProps: PropTypes.shape({
      startDate: PropTypes.number.isRequired,
      endDate: PropTypes.number.isRequired
    }),
    /**
     * to disable form input field
     */
    disabled: PropTypes.bool,
    /**
     * Custom no data found message for select multiple component
     */
    emptySearchMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * display help sentence in the form field
     * Any help text describing how the field works.
     */
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * display info sentence in the form field
     * Any informational text regarding the field's value.
     */
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  }).isRequired
)

const RenderFormInputField = ({
  selectionType,
  options,
  name,
  label,
  selectedFilterValues,
  setSelectedFilterValues,
  onFilterValuesChange,
  height,
  selectedValuesRenderer,
  updateValues,
  disabled,
  startDate,
  endDate,
  emptySearchMessage,
  placeholder,
  searchPlaceholder
}) => {
  const defaultDropDownOptions = [
    'dropdown',
    'search',
    'select-multiple',
    'select-multiple-dropdown'
  ].includes(selectionType)
    ? options.map((option) => {
        return {
          label: option.label,
          value: option.name,
          searchableLabels: option.searchableLabels
        }
      })
    : []
  const [dropDownOptions, setDropDownOptions] = useState(defaultDropDownOptions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchValue = useCallback(
    debounce((text) => {
      let filteredValues
      if (selectionType === 'dropdown') {
        // searchableLabels is optional, if not provided then search will be done on label
        const undefinedSearchableLabels = defaultDropDownOptions.some(
          (o) => !o.searchableLabels
        )
        if (undefinedSearchableLabels) {
          filteredValues = defaultDropDownOptions.filter((o) =>
            o?.label.includes(text)
          )
        } else {
          filteredValues = defaultDropDownOptions.filter((o) =>
            o?.searchableLabels?.some((val) => val.includes(text))
          )
        }
        setDropDownOptions(filteredValues)
      } else if (selectionType === 'select-multiple') {
        // making select-multiple search case insensitive
        const undefinedSearchableLabels = defaultDropDownOptions.some(
          (o) => !o.searchableLabels
        )
        if (undefinedSearchableLabels) {
          filteredValues = defaultDropDownOptions.filter((o) =>
            o?.label.toLowerCase().includes(text.toLowerCase())
          )
        } else {
          filteredValues = defaultDropDownOptions.filter((o) =>
            o?.searchableLabels?.some((val) => val.includes(text))
          )
        }
        setDropDownOptions(filteredValues)
      } else {
        updateValues(text)
      }
    }, 500),
    []
  )

  // On filter value change updateValues func is called with selected filter values
  // Currently applicable for radio, date-range, select-multiple-dropdown & checkbox
  const handleUpdateValuesOnChange = (selectedFilter) => {
    updateValues(selectedFilter)
  }

  const [searchTextValue, setSearchTextValue] = useState('')
  switch (selectionType) {
    case 'text': {
      const selFiltersValue = { ...selectedFilterValues }
      return (
        <TextInput
          name={name}
          value={
            selectedFilterValues[name] ? selectedFilterValues[name][0] : ''
          }
          onChange={(event) => {
            if (!event.target.value) {
              delete selFiltersValue[name]
            } else {
              selFiltersValue[name] = [event.target.value]
            }
            setSelectedFilterValues(selFiltersValue)
            onFilterValuesChange(selFiltersValue)
          }}
          placeholder={`Enter ${label}`}
          disabled={disabled}
        />
      )
    }
    case 'dropdown':
      return (
        <Dropdown
          id="select"
          name={name}
          placeholder={placeholder || 'Select'}
          options={dropDownOptions}
          testId="drop-down"
          onChangeDropdown={(val) => {
            const selFilters = { ...selectedFilterValues }
            selFilters[name] = val
            setSelectedFilterValues(selFilters)
            onFilterValuesChange(selFilters)
            setDropDownOptions(defaultDropDownOptions)
            handleUpdateValuesOnChange(selFilters)
          }}
          onSearch={(text) => {
            handleDebouncedSearchValue(text)
          }}
          value={selectedFilterValues[name]}
          disabled={disabled}
        />
      )
    case 'radio':
      return (
        <Box {...(height && { height: { max: height }, overflow: 'auto' })}>
          {options.map((data) => (
            <RadioButton
              data-testid={data.name}
              checked={
                (selectedFilterValues[name] &&
                  selectedFilterValues[name][0] === data.name) ||
                false
              }
              label={data.label}
              onChange={(event) => {
                const selFilters = { ...selectedFilterValues }
                selFilters[name] = [event.target.value]
                setSelectedFilterValues(selFilters)
                onFilterValuesChange(selFilters)
                if (updateValues) handleUpdateValuesOnChange(selFilters)
              }}
              name={name}
              value={data.name}
              key={data.name}
              disabled={disabled}
            />
          ))}
        </Box>
      )
    case 'date-range': {
      const selFiltersValue = { ...selectedFilterValues }
      const calendarProps = startDate &&
        endDate && {
          bounds: [startDate, endDate]
        }
      return (
        <DateInput
          calendarProps={calendarProps}
          format="mm/dd/yyyy-mm/dd/yyyy"
          name={name}
          value={selectedFilterValues[name] || ''}
          onChange={(event) => {
            if (!event.value) {
              delete selFiltersValue[name]
            } else {
              selFiltersValue[name] = event.value
            }
            setSelectedFilterValues(selFiltersValue)
            onFilterValuesChange(selFiltersValue)
            if (updateValues) handleUpdateValuesOnChange(selFiltersValue)
          }}
          disabled={disabled}
        />
      )
    }

    case 'select-multiple': {
      return (
        <SelectMultiple
          id="select-multiple"
          name={name}
          data-testid="select-multiple"
          placeholder={placeholder || 'Select multiple'}
          dropHeight="medium"
          dropProps={{ width: 'small' }}
          searchPlaceholder={searchPlaceholder || ''}
          emptySearchMessage={emptySearchMessage}
          options={dropDownOptions}
          value={selectedFilterValues[name]}
          onChange={({ value }) => {
            const selFilters = { ...selectedFilterValues }
            if (value.length === 0) {
              delete selFilters[name]
            } else {
              selFilters[name] = value
            }
            setSelectedFilterValues(selFilters)
            onFilterValuesChange(selFilters)
            handleUpdateValuesOnChange(selFilters)
          }}
          onSearch={(text) => {
            handleDebouncedSearchValue(text)
          }}
          onClose={() => {
            handleUpdateValuesOnChange(selectedFilterValues)
          }}
        />
      )
    }

    /* TODO: GLCP-125605 - Change Dropdown in select-multiple-dropdown case to SelectMultiple component once search bug is fixed */
    case 'select-multiple-dropdown':
      return (
        <Dropdown
          multiple
          name={name}
          options={defaultDropDownOptions}
          placeholder={placeholder || undefined}
          searchPlaceholder={searchPlaceholder || undefined}
          testId="select-multiple-dropdown"
          onChange={({ option }) => {
            const selFilters = { ...selectedFilterValues }
            const isExist = selFilters[name]?.find(
              (value) => value.value === option.value
            )
            let modifiedSelection = []
            if (isExist) {
              // if selected option already exist then remove it
              modifiedSelection = selFilters[name]?.filter(
                (value) => value.value !== option.value
              )
            } else {
              // if selected option doesn't exist then add it
              modifiedSelection = [...(selFilters[name] || []), option]
            }
            if (modifiedSelection?.length) selFilters[name] = modifiedSelection
            else delete selFilters[name]
            setSelectedFilterValues(selFilters)
            onFilterValuesChange(selFilters)
            handleUpdateValuesOnChange(selFilters)
          }}
          onSearch={(text) => {
            handleDebouncedSearchValue(text)
          }}
          value={selectedFilterValues[name]?.map((option) => option?.value)}
          disabled={disabled}
          labelKey={(option) => <Markdown>{option?.label}</Markdown>}
          valueKey={{ key: 'value', reduce: true }}
          valueLabel={
            selectedFilterValues[name]?.length ? (
              <Box
                pad={{
                  vertical: 'xsmall',
                  left: 'small'
                }}
              >
                <Typography
                  type="text"
                  emphasis
                  testId="selected-accounts-count"
                >
                  <Markdown>
                    {selectedFilterValues[name]?.length === 1
                      ? selectedFilterValues[name][0]?.label
                      : `${selectedFilterValues[name]?.length} selected`}
                  </Markdown>
                </Typography>
              </Box>
            ) : undefined
          }
          onClose={() => handleUpdateValuesOnChange(selectedFilterValues)}
          noBorder
        />
      )

    case 'search':
      return (
        <Box pad="small" focusIndicator={false} margin={{ bottom: '150px' }}>
          <StyledTextInput
            dropAlign={{ top: 'bottom', left: 'left' }}
            icon={<SearchIcon id="search-icon" />}
            placeholder={placeholder || label}
            value={searchTextValue}
            focusIndicator={false}
            onChange={(e) => {
              setSearchTextValue(e.target.value)
              handleDebouncedSearchValue(e.target.value)
            }}
            onSuggestionsOpen={() => {
              updateValues(searchTextValue)
            }}
            onSuggestionSelect={(selectedVal) => {
              const isAlreadySelected =
                selectedFilterValues[name] &&
                selectedFilterValues[name].some(
                  (item) => item.value === selectedVal.suggestion.value
                )

              if (isAlreadySelected) return

              setSearchTextValue('')

              const selFilters = { ...selectedFilterValues }
              if (selFilters[name]) {
                selFilters[name].push(selectedVal.suggestion)
              } else {
                selFilters[name] = [selectedVal.suggestion]
              }
              setSelectedFilterValues(selFilters)
              onFilterValuesChange(selFilters)
            }}
            suggestions={defaultDropDownOptions}
            data-testid="search"
            disabled={disabled}
          />
          <Box direction="row" gap="small" wrap>
            {selectedFilterValues[name] &&
              selectedFilterValues[name].map((suggestion) => {
                const onRemoveSelection = () => {
                  // remove the item
                  const selectedValues = selectedFilterValues[name].filter(
                    (selVal) => selVal.value !== suggestion.value
                  )

                  let newSelFilters
                  if (selectedValues.length === 0) {
                    newSelFilters = { ...selectedFilterValues }
                    delete newSelFilters[name]
                  } else {
                    newSelFilters = {
                      ...selectedFilterValues,
                      [name]: selectedValues
                    }
                  }

                  setSelectedFilterValues(newSelFilters)
                  onFilterValuesChange(newSelFilters)
                }

                return (
                  <React.Fragment key={suggestion.value}>
                    {selectedValuesRenderer(suggestion, onRemoveSelection)}
                  </React.Fragment>
                )
              })}
          </Box>
        </Box>
      )
    // checkbox
    default:
      return (
        <CheckBoxGroup
          name={name}
          valueKey="name"
          value={selectedFilterValues[name]}
          onChange={(event) => {
            const selFilters = { ...selectedFilterValues }
            if (!event.value || event.value.length === 0) {
              delete selFilters[name]
            } else {
              selFilters[name] = event.value
            }
            setSelectedFilterValues(selFilters)
            onFilterValuesChange(selFilters)
            if (updateValues) handleUpdateValuesOnChange(selFilters)
          }}
          options={options}
          {...(height && { height: { max: height }, overflow: 'auto' })}
          disabled={disabled}
        />
      )
  }
}

RenderFormInputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selectionType: PropTypes.oneOf([
    'checkbox',
    'radio',
    'text',
    'dropdown',
    'date-range',
    'search',
    'select-multiple',
    'select-multiple-dropdown'
  ]).isRequired,
  onFilterValuesChange: PropTypes.func.isRequired,
  selectedFilterValues: PropTypes.object.isRequired,
  setSelectedFilterValues: PropTypes.func.isRequired,
  selectedValuesRenderer: PropTypes.func,
  updateValues: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      searchableLabels: PropTypes.arrayOf(PropTypes.string) // applicable for search where the label not just consists of single string but multiple strings
    })
  ).isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  height: PropTypes.oneOf(dimensions), // applicable only for checkbox and radiobutton for internal scroll
  disabled: PropTypes.bool, // to disable form input field
  emptySearchMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // applicable for select-multiple for setting custom no data found message
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // setting placeholder for applicable fields
  searchPlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]) // setting search placeholder for search field in select-dropdown and select-dropdown-multiple
}

RenderFormInputField.defaultProps = {
  height: undefined,
  disabled: false,
  selectedValuesRenderer: () => {},
  updateValues: () => {},
  startDate: undefined,
  endDate: undefined,
  emptySearchMessage: undefined,
  searchPlaceholder: undefined,
  placeholder: undefined
}

const FormFields = ({
  filterAttributes,
  initSelectedFilterValues,
  onFilterValuesChange
}) => {
  // object of {name1: [<values>], name2: [<values]} representing selected filter values
  const [selectedFilterValues, setSelectedFilterValues] = useState(
    initSelectedFilterValues
  )

  useEffect(() => {
    setSelectedFilterValues(initSelectedFilterValues)
  }, [initSelectedFilterValues])

  const formFields = filterAttributes.map(
    (
      {
        name,
        label,
        values = [],
        selectionType = 'checkbox',
        height,
        updateValues,
        selectedValuesRenderer,
        disabled,
        startDate,
        endDate,
        emptySearchMessage,
        help,
        info,
        placeholder,
        searchPlaceholder
      },
      formFieldIdx
    ) => {
      const options = values.map(
        ({ valueName, valueLabel, searchableLabels }) => {
          return {
            label: valueLabel,
            name: valueName,
            searchableLabels
          }
        }
      )

      return (
        <Box
          key={formFieldIdx} // eslint-disable-line react/no-array-index-key
          pad={{ bottom: '12px' }}
        >
          <FormField
            name={name}
            label={label}
            data-testid={`form-field${formFieldIdx}`}
            help={help}
            info={info}
          >
            <RenderFormInputField
              selectionType={selectionType}
              options={options}
              name={name}
              label={label}
              selectedFilterValues={selectedFilterValues}
              setSelectedFilterValues={setSelectedFilterValues}
              onFilterValuesChange={onFilterValuesChange}
              height={height}
              selectedValuesRenderer={selectedValuesRenderer}
              updateValues={updateValues}
              disabled={disabled}
              startDate={startDate}
              endDate={endDate}
              emptySearchMessage={emptySearchMessage}
              placeholder={placeholder}
              searchPlaceholder={searchPlaceholder}
            />
          </FormField>
        </Box>
      )
    }
  )

  return <>{formFields}</>
}

FormFields.propTypes = {
  onFilterValuesChange: PropTypes.func.isRequired,
  filterAttributes: filterAttributesPropTypes.isRequired,
  initSelectedFilterValues: PropTypes.object
}

FormFields.defaultProps = {
  initSelectedFilterValues: {}
}

const FilterDialog = ({
  dialogHeight,
  dialogPosition,
  onDialogChangeState,
  onFilterValuesChange,
  filterAttributes,
  initSelectedFilterValues,
  onCancel,
  title
}) => {
  const { t } = useTranslation('common')
  const [selectedFilterValues, setSelectedFilterValues] = useState(
    initSelectedFilterValues
  )

  useEffect(() => {
    setSelectedFilterValues(initSelectedFilterValues)
  }, [initSelectedFilterValues])

  const buttonListByPosition = () => {
    const buttonList = [
      {
        label: t('common:cancel'),
        default: true,
        onClick: () => {
          onDialogChangeState(false)
          if (onCancel) onCancel()
        },
        margin: { top: 'small' },
        testId: 'cancel-filter-btn'
      },
      {
        label: t('common:apply_filters'),
        primary: true,
        onClick: () => {
          onFilterValuesChange(selectedFilterValues)
          onDialogChangeState(false)
        },
        margin: { top: 'small' },
        testId: 'apply-filters-btn'
      }
    ]

    return dialogPosition === 'right' ? buttonList.reverse() : buttonList
  }

  return (
    <ModalDialog
      position={dialogPosition}
      width="medium"
      header={
        <ModalHeader
          title={
            <Typography
              testId="header-title"
              type="heading"
              level={dialogPosition === 'right' ? '2' : undefined}
              weight="bold"
            >
              {title}
            </Typography>
          }
          onClose={
            dialogPosition === 'right' ? () => onDialogChangeState(false) : null
          }
        />
      }
      height={dialogHeight}
      content={
        <Box margin={{ top: '18px' }}>
          <CCSForm validate="blur" testId="filter-form" errorMessage="">
            <FormFields
              filterAttributes={filterAttributes}
              initSelectedFilterValues={selectedFilterValues}
              onFilterValuesChange={(values) => {
                setSelectedFilterValues(values)
              }}
            />
          </CCSForm>
        </Box>
      }
      footer={
        // Added button into Modal Footer to make the button sticky when content are scrollable
        <ModalFooter
          left={
            <ButtonGroup
              buttonList={buttonListByPosition()}
              testId="filter-dialog-buttons"
            />
          }
        />
      }
      onClose={() => onDialogChangeState(false)}
      testId="filter-modal-dialog"
    />
  )
}

FilterDialog.propTypes = {
  dialogHeight: PropTypes.oneOf(dimensions),
  dialogPosition: PropTypes.oneOf(filterDialogPosition),
  onDialogChangeState: PropTypes.func.isRequired,
  onFilterValuesChange: PropTypes.func.isRequired,
  filterAttributes: filterAttributesPropTypes.isRequired,
  initSelectedFilterValues: PropTypes.object,
  onCancel: PropTypes.func,
  title: PropTypes.string.isRequired
}

FilterDialog.defaultProps = {
  dialogHeight: undefined,
  dialogPosition: 'center',
  initSelectedFilterValues: {},
  onCancel: undefined
}

export const FilterButton = ({
  filterAttributes,
  dialogHeight,
  dialogPosition,
  onFilterValuesChange,
  onDialogChangeState,
  initSelectedFilterValues,
  testId,
  onCancel,
  title,
  clearFilterString
}) => {
  // if true, filter dialog is displayed
  const [filterDialogOpen, setFilterDialogOpen] = useState(false)
  // object of {name1: [<values>], name2: [<values]} representing selected filter values
  const [selectedFilterValues, setSelectedFilterValues] = useState(
    initSelectedFilterValues
  )

  const theme = useContext(ThemeContext)

  useEffect(() => {
    setSelectedFilterValues(initSelectedFilterValues || {})
  }, [initSelectedFilterValues])

  return (
    <>
      <Box direction="row" gap="small" align="center" flex={false}>
        <GrommetButton
          kind="toolbar"
          badge={
            Object.keys(selectedFilterValues).length > 0 ? (
              <Box
                round
                width={theme?.button?.badge?.size?.medium}
                height={theme?.button?.badge?.size?.medium}
                background={theme?.button?.badge?.container?.background}
                responsive={false}
                justify="center"
                align="center"
              >
                <Typography
                  size={theme?.button?.badge?.text?.size?.medium}
                  type="text"
                  testId="filter-count"
                >
                  {Object.keys(selectedFilterValues).length.toString()}
                </Typography>
              </Box>
            ) : null
          }
          type="button"
          icon={<Filter />}
          onClick={() => {
            setFilterDialogOpen(true)
          }}
          data-testid={testId}
        />
        {Object.keys(selectedFilterValues).length > 0 && (
          <GrommetButton
            alignSelf="end"
            kind="toolbar"
            label={clearFilterString}
            data-testid="clear-filters-anchor"
            onClick={() => {
              setSelectedFilterValues({})
              onFilterValuesChange({})
            }}
          />
        )}
      </Box>
      {filterDialogOpen && (
        <FilterDialog
          dialogHeight={dialogHeight}
          dialogPosition={dialogPosition}
          onDialogChangeState={(dialogState) => {
            setFilterDialogOpen(dialogState)
            onDialogChangeState()
          }}
          onFilterValuesChange={(values) => {
            setSelectedFilterValues(values)
            onFilterValuesChange(values)
          }}
          filterAttributes={filterAttributes}
          initSelectedFilterValues={selectedFilterValues}
          onCancel={onCancel}
          title={title}
        />
      )}
    </>
  )
}

FilterButton.propTypes = {
  /**
   * Array of objects representing attribute names, values and labels
   */
  filterAttributes: filterAttributesPropTypes.isRequired,
  /**
   * Handler that gets invoked when filter selection changes
   */
  onFilterValuesChange: PropTypes.func.isRequired,
  /**
   * Handler that gets invoked when filter dialog state changes
   */
  onDialogChangeState: PropTypes.func,

  dialogHeight: PropTypes.oneOf(dimensions),

  dialogPosition: PropTypes.oneOf(filterDialogPosition),

  initSelectedFilterValues: PropTypes.object,

  /**
   * It will be used for component reference to test.
   * This is mandatory.
   */
  testId: PropTypes.string.isRequired,

  /**
   * It will be called when cancel button of Filter modal is clicked
   */
  onCancel: PropTypes.func,

  /**
   * It will be displayed under Filter modal header
   */
  title: PropTypes.string,
  clearFilterString: PropTypes.string
}

FilterButton.defaultProps = {
  dialogHeight: undefined,
  dialogPosition: 'center',
  initSelectedFilterValues: {},
  onCancel: undefined,
  title: 'Filter',
  clearFilterString: 'Clear Filters',
  onDialogChangeState: () => {}
}
