/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, TextArea, CheckBox } from 'grommet'
import { isEmpty } from 'lodash'
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import validator from 'validator'
import DOMPurify from 'dompurify'

import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import { Typography } from '../../../../../../../components'
import { validateForm } from '../../../../../../../utils/validation-utils'
import VisibilityWrapper from '../../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { sanitizeInput } from '../../../utils'

const validateFields = (formValues, i18nTranslate) => {
  const {
    summary,
    description,
    url_text,
    url,
    url_checkbox,
    emailContentDetails,
    emailContentTitle,
    email
  } = formValues
  const summaryMaxLength = 192
  const descriptionMaxLength = 2048
  const urltextMaxLength = 64
  const emailContentLimit = 2048
  let errorMessage
  let descriptionMessageValid
  let descriptionerrorMessage
  let url_texterror = null
  let urlError = null
  if (isEmpty(summary?.trim())) {
    errorMessage = i18nTranslate('required')
  } else if (
    DOMPurify.sanitize(formValues?.summary, { ALLOWED_TAGS: [] }) !==
    formValues?.summary
  ) {
    errorMessage = i18nTranslate('notification.sanitise_warn')
  } else if (summary?.length >= summaryMaxLength) {
    errorMessage = i18nTranslate('notification.max_length')
  }

  if (url_checkbox && !isEmpty(url?.trim()) && isEmpty(url_text?.trim())) {
    url_texterror = i18nTranslate('required')
  } else if (sanitizeInput(formValues?.url_text) !== formValues?.url_text) {
    url_texterror = i18nTranslate('notification.sanitise_warn')
  } else if (url_text?.length >= urltextMaxLength) {
    url_texterror = i18nTranslate('notification.max_length')
  }

  if (!isEmpty(description)) {
    if (description?.length >= descriptionMaxLength) {
      descriptionMessageValid = false
      descriptionerrorMessage = i18nTranslate('notification.max_length')
    } else if (
      sanitizeInput(formValues?.description)?.length !==
      formValues?.description?.length
    ) {
      descriptionMessageValid = false
      descriptionerrorMessage = i18nTranslate('notification.sanitise_warn')
    } else {
      descriptionMessageValid = true
    }
  } else {
    descriptionMessageValid = true
  }

  const portalMessageValid =
    summary?.trim() &&
    summary?.length <= summaryMaxLength &&
    DOMPurify.sanitize(formValues?.summary, { ALLOWED_TAGS: [] }) ===
      formValues?.summary
  const urlValid =
    validator.isURL(url, {
      protocols: ['https'],
      require_protocol: true
    }) ||
    (!url_checkbox && isEmpty(url?.trim()))
  if (!urlValid) {
    if (url_checkbox && isEmpty(url?.trim())) {
      urlError = i18nTranslate('required')
    } else {
      urlError = i18nTranslate('notification.invalid_link')
    }
  }

  if (
    email &&
    (isEmpty(emailContentTitle?.trim()) || isEmpty(emailContentDetails?.trim()))
  ) {
    errorMessage = i18nTranslate('required')
  } else if (
    email &&
    (sanitizeInput(formValues?.emailContentDetails)?.length !==
      formValues?.emailContentDetails?.length ||
      DOMPurify.sanitize(formValues?.emailContentTitle, {
        ALLOWED_TAGS: []
      }) !== formValues?.emailContentTitle)
  ) {
    errorMessage = i18nTranslate('notification.sanitise_warn')
  } else if (
    email &&
    (emailContentDetails?.length >= emailContentLimit ||
      emailContentTitle?.length >= emailContentLimit)
  ) {
    errorMessage = i18nTranslate('notification.max_length')
  }

  const emailMessageValid = email
    ? emailContentDetails?.trim() &&
      emailContentDetails.length <= emailContentLimit &&
      sanitizeInput(formValues?.emailContentDetails)?.length ===
        formValues?.emailContentDetails?.length
    : true
  const emailTitleValid = email
    ? emailContentTitle?.trim() &&
      emailContentTitle.length <= summaryMaxLength &&
      DOMPurify.sanitize(formValues?.emailContentTitle, {
        ALLOWED_TAGS: []
      }) === formValues?.emailContentTitle
    : true
  return {
    summary: portalMessageValid ? '' : errorMessage,
    description: descriptionMessageValid ? '' : descriptionerrorMessage,
    url: urlValid ? '' : urlError,
    url_text: url_texterror,
    emailContentDetails: emailMessageValid ? '' : errorMessage,
    emailContentTitle: emailTitleValid ? '' : errorMessage,
    isValid:
      !!portalMessageValid &&
      !!descriptionMessageValid &&
      !!urlValid &&
      !url_texterror &&
      !!emailMessageValid &&
      !!emailTitleValid
  }
}

export const validateNotificationContent = (formValues, i18nTranslate) => {
  return validateForm(formValues, i18nTranslate, validateFields)
}

const NotificationContent = ({ isEdit, isUpdateFlow }) => {
  const { t } = useTranslation(['manage'])

  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)
  const [errorMessage, setErrorMessage] = useState({})

  useEffect(() => {
    if (attemptedAdvance) {
      const validation = validateFields(formValues, t)
      setErrorMessage(validation)
    }
  }, [formValues, attemptedAdvance, t])

  useEffect(() => {
    if (!formValues?.url_checkbox) {
      setFormValues({
        ...formValues,
        url: '',
        url_text: ''
      })
    }
  }, [formValues?.url_checkbox])

  return (
    <Box
      gap="small"
      width="medium"
      justify="between"
      align="center"
      alignSelf="center"
    >
      {' '}
      <Box width="medium">
        <Typography
          type="text"
          size="small"
          color="black"
          testId="banner-checkbox-summary-label"
        >
          {t('notification.summary_title')}
        </Typography>
        <Typography
          type="text"
          size="small"
          color="black"
          testId="banner-checkbox-summary"
        >
          {t('notification.summary_description')}
        </Typography>
        <FormField
          name="summary"
          margin={{ bottom: 'none' }}
          error={errorMessage.summary}
          data-testid="set-up-banner-summary"
          required
        >
          <TextArea
            size="medium"
            value={formValues.summary}
            name="summary"
            placeholder={t('notification.message')}
            data-testid="portal-summary"
            onChange={(event) =>
              setFormValues({
                ...formValues,
                summary: event.target.value
              })
            }
          />
        </FormField>
      </Box>
      <Box>
        <FormField name="url_checkbox" data-testid="url-checkbox-formfield">
          <CheckBox
            pad="none"
            data-testid="test-url-checkbox"
            disabled={isEdit || isUpdateFlow}
            name="url_checkbox"
            checked={formValues.url_checkbox}
            label={t('notification.add_url')}
            onChange={(event) => {
              setFormValues({
                ...formValues,
                url_checkbox: event.target.checked
              })
            }}
          />
        </FormField>
        <Typography
          type="text"
          size="small"
          color="text-strong"
          testId="banner-checkbox-description"
        >
          {t('notification.add_url_description')}
        </Typography>
      </Box>
      {formValues?.url_checkbox && (
        <VisibilityWrapper
          hideFor={{
            feature: 'glcp-notifications-feature-unsubscribe-helsinki'
          }}
        >
          <Box width="medium" margin={{ bottom: 'small' }}>
            <FormField
              name="url"
              margin={{ bottom: 'none' }}
              error={errorMessage.url}
              data-testid="set-up-url"
              label={t('notification.url')}
              required
            >
              <TextArea
                size="medium"
                value={formValues.url}
                disabled={isEdit || isUpdateFlow}
                name="summary"
                placeholder={t('notification.url_placeholder')}
                data-testid="portal-url"
                onChange={(event) => {
                  if (event.target.value.trim().length === 0) {
                    setFormValues({
                      ...formValues,
                      url_text: '',
                      url: ''
                    })
                  } else {
                    setFormValues({
                      ...formValues,
                      url: event.target.value
                    })
                  }
                }}
              />
            </FormField>
          </Box>
          <Box width="medium" margin={{ bottom: 'small' }}>
            <FormField
              name="url_text"
              margin={{ bottom: 'none' }}
              error={errorMessage.url_text}
              data-testid="set-up-url text"
              label={t('notification.url_text')}
              help={t('notification.url_text_description')}
              required
            >
              <TextArea
                size="medium"
                value={formValues.url_text}
                name="url_text"
                placeholder={t('notification.url_text_placeholder')}
                disabled={
                  formValues?.url.trim().length === 0 || isEdit || isUpdateFlow
                }
                data-testid="portal-url-text"
                onChange={(event) => {
                  if (event.target.value.trim().length > 0) {
                    setFormValues({
                      ...formValues,
                      url_text: event.target.value
                    })
                  } else {
                    setFormValues({
                      ...formValues,
                      url_text: ''
                    })
                  }
                }}
              />
            </FormField>
          </Box>
        </VisibilityWrapper>
      )}
      <Box fill>
        <Typography
          type="heading"
          size="small"
          color="black"
          testId="banner-checkbox-description-label"
          margin={{ top: 'medium', bottom: 'none' }}
        >
          {t('notification.additional_content')}
        </Typography>
      </Box>
      <Box width="medium" margin={{ bottom: 'xsmall', top: 'none' }}>
        <Typography
          type="text"
          size="small"
          color="black"
          testId="banner-checkbox-description-label"
        >
          {t('notification.notification_center')}
        </Typography>
        <Typography
          type="text"
          size="small"
          color="black"
          testId="banner-checkbox-description"
        >
          {t('notification.new_additional_content_description')}
        </Typography>
        <FormField
          name="description"
          margin={{ bottom: 'none' }}
          error={errorMessage?.description}
          data-testid="set-up-banner-description"
        >
          <TextArea
            size="medium"
            value={formValues.description}
            name="description"
            placeholder={t('notification.summary')}
            data-testid="portal-description"
            onChange={(event) =>
              setFormValues({
                ...formValues,
                description: event.target.value
              })
            }
          />
        </FormField>
      </Box>
      {formValues?.email && (
        <Box width="medium" margin={{ bottom: 'small' }}>
          <Typography
            type="text"
            size="small"
            testId="banner-checkbox-description-label"
          >
            {t('notification.email_title')}
          </Typography>
          <Typography
            type="text"
            size="small"
            color="black"
            testId="banner-checkbox-description"
          >
            {t('notification.email_title_description')}
          </Typography>
          <FormField
            name="description"
            margin={{ bottom: 'none' }}
            error={errorMessage?.emailContentTitle}
            data-testid="set-up-banner-description"
          >
            <TextArea
              size="medium"
              value={formValues.emailContentTitle}
              name="emailContentDetails"
              placeholder={t('notification.email_text_title')}
              data-testid="email-content-details"
              onChange={(event) =>
                setFormValues({
                  ...formValues,
                  emailContentTitle: event.target.value
                })
              }
            />
          </FormField>
        </Box>
      )}
      {formValues?.email && (
        <Box width="medium" margin={{ bottom: 'small' }}>
          <Typography
            type="text"
            size="small"
            testId="banner-checkbox-description-label"
          >
            {t('notification.email_body')}
          </Typography>
          <Typography
            type="text"
            size="small"
            color="black"
            testId="banner-checkbox-description"
          >
            {t('notification.email_body_description_1')}
            {t('notification.email_body_description_2')}
          </Typography>
          <FormField
            name="description"
            margin={{ bottom: 'none' }}
            error={errorMessage?.emailContentDetails}
            data-testid="set-up-banner-description"
          >
            <TextArea
              size="medium"
              value={formValues.emailContentDetails}
              name="emailContentDetails"
              placeholder={t('notification.email_text_body')}
              data-testid="email-content-details"
              onChange={(event) =>
                setFormValues({
                  ...formValues,
                  emailContentDetails: event.target.value
                })
              }
            />
          </FormField>
        </Box>
      )}
    </Box>
  )
}

NotificationContent.prototypes = {
  isEdit: PropTypes.bool.isRequired
}

export default NotificationContent
