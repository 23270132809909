import PropTypes from 'prop-types'

export const addressType = PropTypes.oneOf(['street', 'shipping_receiving'])

export const addressTypeObject = {
  type: addressType.isRequired,
  street_address: PropTypes.string.isRequired,
  street_address2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  postal_code: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired
}

export const addressErrorsType = {
  street_address: PropTypes.string.isRequired,
  street_address2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  postal_code: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired
}
