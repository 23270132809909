import PropTypes from 'prop-types'
import React from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ButtonGroup,
  Typography
} from '../../../../../components'

const ConfirmModal = ({ onClose, onSubmit }) => {
  const { t } = useTranslation(['authn', 'common'])
  return (
    <ModalDialog
      content={
        <Box
          direction="column"
          margin={{ top: 'xsmall' }}
          gap="medium"
          width="medium"
        >
          <Typography size="medium" testId="dialog-content" type="text">
            {t('cop_local_authentication.confirm_modal.text')}
          </Typography>
          <Typography size="medium" testId="dialog-question" type="text">
            {t('cop_local_authentication.confirm_modal.question')}
          </Typography>
        </Box>
      }
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="1" testId="header-title">
              {t('cop_local_authentication.confirm_modal.title')}
            </Typography>
          }
        />
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  label: t('common:cancel'),
                  default: true,
                  onClick: () => onClose(false),
                  testId: 'cancel-btn'
                },
                {
                  label: t('common:confirm'),
                  primary: true,
                  onClick: onSubmit,
                  testId: 'submit-btn'
                }
              ]}
              justifyGroup="end"
              testId="convert-account-buttons"
            />
          }
        />
      }
      onClose={() => onClose(false)}
      testId="convert-account-dialog"
    />
  )
}

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default ConfirmModal
