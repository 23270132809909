import React from 'react'
import PropTypes from 'prop-types'

import { Notification } from '../../../components'

const NotificationContent = ({ background, icon, title, messages }) => (
  <Notification
    type="inline"
    backgroundColor={background}
    icon={icon}
    status="warning"
    title={title}
    text={
      <ul>
        {messages.map((message) => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    }
    testId="warning-inline-notification"
  />
)

NotificationContent.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired,
  background: PropTypes.string.isRequired
}

export default NotificationContent
