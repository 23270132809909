import { post, get, patch, del } from '../../../utils/api-utils'
import { isCoP, getCustomerAccount } from '../../../utils/feature-flag-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import { localStorageWhitelistWithoutPII } from '../../../utils/local-storage-utils'
import { MAX_PROVISION_TIMEOUT } from '../../../dashboard/constants'

export const getFullLanguage = (lang) => {
  switch (lang) {
    case 'en-US':
      return 'English'
    case 'de-DE':
      return 'German'
    case 'es-ES':
      return 'Spanish'
    case 'fr-FR':
      return 'French'
    case 'it-IT':
      return 'Italian'
    case 'ja-JP':
      return 'Japanese'
    case 'ko-KR':
      return 'Korean'
    case 'pt-BR':
      return 'Portuguese'
    case 'zh-CN':
      return 'Chinese'
    case 'ru-RU':
      return 'Russian'
    default:
      return ''
  }
}

export const getRegionNameByRegionCode = (code, regionList) => {
  if (regionList && regionList.length > 0) {
    const region = regionList.filter((obj) => obj.code === code)
    return region && region.length > 0 && region[0].name ? region[0].name : ''
  }
  return ''
}

export const getProvisionStatus = (
  customerId,
  currStatus,
  token,
  setFunc,
  serviceProvisionId
) => {
  if (customerId && token && setFunc) {
    get(
      serviceProvisionId
        ? `/service-catalog/v1beta1/service-provisions/${serviceProvisionId}`
        : `/app-provision/ui/v1/provisions/${customerId}`,
      {},
      token
    ).then(
      (response) => {
        if (response.status === 200) {
          let provisionStatus =
            response?.data?.provision_status || response?.data?.provisionStatus
          if (serviceProvisionId) {
            const provisionInitTime =
              response?.data?.updated_at || response?.data?.updatedAt
            const nowlocal = new Date()
            // convert now to utc timestamp
            const nowUTC = nowlocal.getTime() + nowlocal.getTimezoneOffset()
            // parse UTC string get timestamp
            const initProvisionTime = Date.parse(provisionInitTime)
            if (
              nowUTC - initProvisionTime > MAX_PROVISION_TIMEOUT &&
              provisionStatus === 'PROVISION_INITIATED'
            ) {
              provisionStatus = 'PROVISION_FAILED'
            }
          }
          if (provisionStatus && provisionStatus !== currStatus) {
            setFunc({ status: provisionStatus })
          }
        }
      },
      (error) => {
        if (
          error.response.status === 404 &&
          currStatus === 'UNPROVISION_INITIATED'
        ) {
          // expected 404, set status back to ''
          setFunc({ status: '' })
        }
      }
    )
  }
}

export const getConversionStatus = (
  customerId,
  currStatus,
  token,
  setProv,
  setConv
) => {
  if (customerId && token && setProv && setConv) {
    get(`/app-provision/ui/v1/provisions/${customerId}`, {}, token).then(
      (response) => {
        if (response.status === 200) {
          const { msp_conversion_status: conversionStatus } = response.data
          if (conversionStatus && conversionStatus !== currStatus) {
            setConv({ status: currStatus })
          }
        }
      },
      (error) => {
        if (
          error.response.status === 404 &&
          currStatus === 'UNPROVISION_INITIATED'
        ) {
          // expected 404, set status back to ''
          setProv({ status: '' })
        }
      }
    )
  }
}

export const getProvisionParams = (
  provision,
  isServiceProvision,
  region,
  retryProvisionId
) => {
  if (!isServiceProvision)
    return {
      application_id: provision.application_id,
      action: 'PROVISION',
      region
    }
  return retryProvisionId
    ? {}
    : { serviceOfferId: provision.service_offer_id, region }
}
export const doProvisionAppAction = (
  appInfo,
  region,
  token,
  setFunc,
  setCustomerId,
  setShowNotification,
  t,
  onProvisionSuccess,
  isServiceProvision,
  retryProvisionId, // Leave undefined if not retry
  doLogout, // is IGC Service
  retryGeneration
) => {
  post(
    isServiceProvision
      ? `/service-catalog/v1beta1/service-provisions${
          retryProvisionId ? `/${retryProvisionId}/retry` : ''
        }`
      : '/app-provision/ui/v1/provisions',
    getProvisionParams(appInfo, isServiceProvision, region, retryProvisionId),
    token,
    retryProvisionId ? { 'If-Match': retryGeneration } : undefined
  ).then(
    (response) => {
      if ([201, 202, 204].includes(response.status)) {
        setFunc({ status: 'PROVISION_INITIATED' })
        const appCustID =
          response?.data?.application_customer_id ||
          response?.data?.applicationCustomerId
        if (appCustID) {
          setCustomerId(appCustID)
          getProvisionStatus(
            appCustID,
            'PROVISION_INITIATED',
            token,
            setFunc,
            isServiceProvision ? appInfo.id : undefined
          )
        }
        if (onProvisionSuccess) onProvisionSuccess()
        if (doLogout) {
          // force to logout for IGC
          doLogout()
        }
      }
    },
    (error) => {
      setShowNotification(displayApiError(error, t, setShowNotification))
    }
  )
}

export const doRetryServiceAction = (
  appInfo,
  region,
  token,
  setFunc,
  setCustomerId,
  setShowNotification,
  t,
  onProvisionSuccess,
  isServiceProvision,
  retryProvisionId, // Leave undefined if not retry
  doLogout // is IGC Service
) => {
  // if retryProvision is undefined, go Provision the app/service
  // if retryProvision is valid + isServiceProvision, go new retry api, need to get latest generation
  // old application code:
  //    if retryProvision is valid + !isServiceProvision, go old retry, no need to get generation
  if (!retryProvisionId || !isServiceProvision) {
    doProvisionAppAction(
      appInfo,
      region,
      token,
      setFunc,
      setCustomerId,
      setShowNotification,
      t,
      onProvisionSuccess,
      isServiceProvision,
      retryProvisionId, // Leave undefined if not retry
      doLogout, // is IGC Service
      undefined // set generation default undefined
    )
  } else {
    // if retry, get the latest generation and post retry with the latest generation
    get(
      `/service-catalog/v1beta1/service-provisions/${retryProvisionId}`,
      {},
      token
    ).then(
      (response) => {
        if (response.status === 200 && response?.data?.generation) {
          const generation = response?.data?.generation
          doProvisionAppAction(
            appInfo,
            region,
            token,
            setFunc,
            setCustomerId,
            setShowNotification,
            t,
            onProvisionSuccess,
            isServiceProvision,
            retryProvisionId, // Leave undefined if not retry
            doLogout, // is IGC Service
            generation
          )
        }
      },
      (error) => {
        setShowNotification(displayApiError(error, t, setShowNotification))
      }
    )
  }
}

export const enableRecentServices = (serviceSlug) => {
  const recentServicesStorage = JSON.parse(
    localStorage.getItem(
      localStorageWhitelistWithoutPII.DASHBOARD_LAST_ACCESSED
    )
  )

  const { platform_customer_id: pcid } = getCustomerAccount() || {}
  const recentServices = recentServicesStorage?.[pcid] || []

  if (recentServices.some((_service) => _service.serviceSlug === serviceSlug)) {
    const index = recentServices.findIndex(
      (_service) => _service.serviceSlug === serviceSlug
    )
    recentServices.splice(index, 1)
  }

  const setRecentServicesStorage = {
    ...recentServicesStorage,
    [pcid]: [...recentServices, { serviceSlug, utc: new Date().toUTCString() }]
  }

  localStorage.setItem(
    localStorageWhitelistWithoutPII.DASHBOARD_LAST_ACCESSED,
    JSON.stringify(setRecentServicesStorage)
  )
}

export const doLaunchAppAction = (
  instanceId,
  token,
  customerId,
  accountType,
  pcid,
  serviceOffer,
  newDashboardFlag
) => {
  if (newDashboardFlag) {
    enableRecentServices(serviceOffer)
  }
  if (instanceId && token) {
    get(`/authn/v1/onboarding/login-url/${instanceId}`, {}, token).then(
      (response) => {
        const { login_url: redirectURL } = response.data
        const workspaceIdParam = newDashboardFlag ? `&workspace_id=${pcid}` : ''
        if (redirectURL) {
          if (sessionStorage.getItem('redirect-query'))
            sessionStorage.removeItem('redirect-query')

          if (isCoP()) {
            sessionStorage.setItem('cop-central-url', redirectURL)
            sessionStorage.removeItem('from-central')
          }

          if (customerId) {
            window.location.replace(
              `${redirectURL}?cid=${customerId}&account_type=${accountType}${
                serviceOffer ? `&service_offer=${serviceOffer}` : ''
              }${workspaceIdParam}`
            )
          } else {
            window.location.replace(
              `${redirectURL}?account_type=${accountType}${
                serviceOffer ? `&service_offer=${serviceOffer}` : ''
              }${workspaceIdParam}`
            )
          }
        }
      },
      (error) => {
        sessionStorage.removeItem('redirect-query')
        console.error(error.message)
      }
    )
  }
}

export const doDeleteAppAction = (
  appInfo,
  token,
  setFunc,
  setCustomerId,
  setShowNotification,
  setShow409Msg,
  t,
  isServiceProvision = false,
  retryGeneration = undefined
) => {
  // to unprovision install failed case, do not use patch, use del API to remove record
  del(
    isServiceProvision
      ? `/service-catalog/v1beta1/service-provisions/${appInfo.id}`
      : `/app-provision/ui/v1/provisions/${appInfo.application_customer_id}`,
    {},
    token,
    isServiceProvision ? { 'If-Match': retryGeneration } : undefined
  ).then(
    (response) => {
      if (response.status === 204 || response.status === 202) {
        setFunc({ status: 'UNPROVISION_INITIATED' })
        const { application_customer_id: appCustID } = response.data
        if (appCustID) {
          setCustomerId(appCustID)
          getProvisionStatus(
            appCustID,
            'UNPROVISION_INITIATED',
            token,
            setFunc,
            isServiceProvision ? appInfo.id : undefined
          )
        }
      }
    },
    (error) => {
      setShowNotification(displayApiError(error, t, setShowNotification))
    }
  )
}

export const doDeleteServiceAction = (
  appInfo,
  token,
  setFunc,
  setCustomerId,
  setShowNotification,
  setShow409Msg,
  t,
  isServiceProvision = false
) => {
  get(
    `/service-catalog/v1beta1/service-provisions/${appInfo.id}`,
    {},
    token
  ).then(
    (response) => {
      if (response.status === 200 && response?.data?.generation) {
        const generation = response?.data?.generation
        doDeleteAppAction(
          appInfo,
          token,
          setFunc,
          setCustomerId,
          setShowNotification,
          setShow409Msg,
          t,
          isServiceProvision,
          generation
        )
      }
    },
    (error) => {
      setShowNotification(displayApiError(error, t, setShowNotification))
    }
  )
}

export const doUnprovisionAppAction = (
  appInfo,
  token,
  setFunc,
  setCustomerId,
  setShowNotification,
  setShow409Msg,
  t
) => {
  patch(
    `/app-provision/ui/v1/provisions/${appInfo.application_customer_id}`,
    {
      action: 'UNPROVISION'
    },
    token
  ).then(
    (response) => {
      if (response.status === 204) {
        setFunc({ status: 'UNPROVISION_INITIATED' })
        const { application_customer_id: appCustID } = response.data
        if (appCustID) {
          setCustomerId(appCustID)
          getProvisionStatus(
            appCustID,
            'UNPROVISION_INITIATED',
            token,
            setFunc,
            undefined
          )
        }
      }
    },
    (error) => {
      // Special case, display redirect modal to customer account page
      if (error.response.status === 409) {
        setShow409Msg(true)
      } else {
        setShowNotification(displayApiError(error, t, setShowNotification))
      }
    }
  )
}

export const doRetryConversion = (
  appInfo,
  token,
  setProv,
  setConv,
  setShowNotification,
  t
) => {
  post(
    `/accounts/ui/v1/customer/provisions/${appInfo.application_customer_id}/msp_convert`,
    {},
    token
  ).then(
    (response) => {
      if (response.status === 204) {
        setConv({ status: 'MSP_CONVERSION_INITIATED' })
        getConversionStatus(
          appInfo.application_customer_id,
          'MSP_CONVERSION_INITIATED',
          token,
          setProv,
          setConv
        )
      }
    },
    (error) => {
      setShowNotification(displayApiError(error, t, setShowNotification))
      console.error(error.message)
    }
  )
}
// App term link redirection function for app-catalog
export const appTermRedirection = (appDetailData, t) => {
  let appTermLink = t('apps:catalog.app_term_link_default')
  if (appDetailData.includes(t('common:compute_cloud_console.title'))) {
    appTermLink = t('apps:catalog.app_term_link_compute')
  } else if (appDetailData.includes(t('common:hpe_storage_central.title'))) {
    appTermLink = t('apps:catalog.app_term_link_storage')
  }
  return appTermLink
}
