// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useState, useEffect } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box, Page, PageContent, PageHeader } from 'grommet'
import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { Anchor, Typography, Loader } from '../../components'
import { Layout } from '../commoncomponents/layout/Layout'
import { useVisibilityContext } from '../../context/visibility-context'
import { get } from '../../utils/api-utils'
import { displayApiError } from '../../utils/error-handling-utils'
import { getOrganizationId } from '../../utils/feature-flag-utils'

import NoViewPermission from './commoncomponents/NoViewPermission'

const IGCService = () => {
  // const[organizationDetails,setOrganizationDetails]=useState({})
  const { t } = useTranslation(['common', 'iam'])
  const history = useHistory()
  const { v2RbacPolicies } = useVisibilityContext()
  const permissions = [
    'identity.users.read',
    'identity.user-groups.read',
    'organization.workspace.read',
    'identity.sso-profiles.read',
    'identity.domain-requests.read',
    'ccs.authorization.view'
  ]
  const v2TilesViewPerm = permissions?.some((permission) =>
    v2RbacPolicies?.includes(permission)
  )

  const [isLoading, setIsLoading] = useState(true)
  const { oidcUser } = useReactOidc()
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [serviceOfferID, setServiceOfferID] = useState(null)
  const organizationId = getOrganizationId()

  // Removed Destructuring and putting optional chaining so it won't break when unable to find account object.
  const account = JSON.parse(sessionStorage?.getItem('account'))
  const pcid = account?.platform_customer_id

  useEffect(() => {
    get(
      '/service-catalog/v1alpha1/service-offers',
      { static_launch_url: '/organization' },
      oidcUser.access_token
    ).then(
      (response) => {
        const serviceID = response?.data?.items[0]?.id || ''
        if (serviceID === '') {
          setIsLoading(false)
          history.push('/not-found')
        }
        // Call API to check is current workspace provisioned the IGC
        // if not, redirect to page not found
        // if yes, setServiceOfferID to display the page
        get(
          `/service-catalog/v1beta1/service-provisions`,
          {
            filter: `organizationId eq ${organizationId} and ServiceOfferId eq ${serviceID}`
          },
          oidcUser.access_token
        ).then(
          (resp) => {
            const provisionedWorkspaceId =
              resp?.data?.items[0]?.workspace?.id || ''
            setIsLoading(false)
            if (
              provisionedWorkspaceId === '' ||
              provisionedWorkspaceId !== pcid
            ) {
              history.push('/not-found')
            }
            setServiceOfferID(serviceID)
          },
          (error) => {
            setIsLoading(false)
            setShowErrorNotification(
              displayApiError(error, t, setShowErrorNotification)
            )
            history.push('/not-found')
          }
        )
      },
      (err) => {
        setIsLoading(false)
        setShowErrorNotification(
          displayApiError(err, t, setShowErrorNotification)
        )
        history.push('/not-found')
      }
    )
  }, [history, t, oidcUser, serviceOfferID, organizationId, pcid])

  return (
    <>
      {showErrorNotification}
      {isLoading && (
        <Box direction="row" align="center" justify="center">
          <Loader testId="organization-mainpage-loader" />
        </Box>
      )}
      {!isLoading && serviceOfferID && (
        <Page>
          <PageContent data-testid="igc-service">
            <Box
              direction="row"
              gap="medium"
              data-testid="igc-service-pageHeader"
            >
              <PageHeader
                responsive
                title={t('common:organization_landing.landing_page_title')}
                subtitle={
                  v2TilesViewPerm ? (
                    <Box width="large">
                      <Typography
                        normalize
                        type="text"
                        size="large"
                        testId="igc-page-subtitle"
                      >
                        <Trans
                          i18nKey="common:organization_landing.landing_page_subtitle"
                          t={t}
                        >
                          <Anchor
                            label={t('common:learn_more')}
                            weight="bold"
                            onClick={(e) => {
                              e?.preventDefault()
                              dispatchEvent(new Event('context-help'))
                            }}
                            href="#"
                            testId="learn-more-link"
                          />
                          {t('common:organization_landing.landing_page_title')}
                        </Trans>
                      </Typography>
                    </Box>
                  ) : null
                }
              />
            </Box>
            {!v2TilesViewPerm && <NoViewPermission />}
          </PageContent>
        </Page>
      )}
    </>
  )
}

const IGCServicePage = () => {
  return (
    <Layout>
      <IGCService align="start" justify="start" />
    </Layout>
  )
}
export default IGCServicePage
