import { useLDClient } from 'launchdarkly-react-client-sdk'

import useLogger from '../logs/useLogger'

export default function useLD() {
  const ldClient = useLDClient()
  const logger = useLogger()

  const ld = {
    update: (account) => {
      if (process.env.REACT_APP_ENABLE_LD) {
        const LDPromise = new Promise((resolve) => {
          const pageInfoLog = {
            page: 'Account Selection',
            section: 'Load',
            type: 'success'
          }
          ldClient?.identify(
            { key: account?.platform_customer_id },
            null,
            (err, flags) => {
              logger?.log({
                ...pageInfoLog,
                msg: `LD Context has been updated for customer ID => ${account?.platform_customer_id}`
              })
              resolve(flags)
            }
          )
          ldClient?.on('error', (error) => {
            logger?.log({
              ...pageInfoLog,
              ...{ type: 'error' },
              msg: `LD Error handler => ${error}`
            })
            resolve('failed')
          })
        })
        return LDPromise
      }
      return Promise.resolve()
    }
  }
  return ld
}
