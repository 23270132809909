// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React from 'react'
import { Carousel as GrommetCarousel, Image, Box, Video } from 'grommet'
import PropTypes from 'prop-types'

export const Carousel = ({
  screenshots,
  videos,
  controls,
  fill,
  carouselHeight,
  carouselWidth,
  testId
}) => {
  return (
    <GrommetCarousel
      fill={fill}
      height={carouselHeight}
      width={carouselWidth}
      controls={controls && screenshots.length + videos.length > 1}
      data-testid={`parent-${testId}`}
    >
      {screenshots &&
        screenshots.map((imageDetails, index) => (
          <Box
            key={imageDetails?.image}
            direction="column"
            fill
            height="small"
            data-testid={`image-carousel-${index}`}
          >
            <Image
              fit="contain"
              src={imageDetails?.image}
              alt={imageDetails?.description}
            />
          </Box>
        ))}
      {videos &&
        videos.map((videoDetails, index) => (
          <Box
            key={videoDetails?.video}
            direction="column"
            fill
            height="small"
            data-testid={`video-carousel-${index}`}
          >
            <Video
              controls="over"
              fit="cover"
              margin={{ horizontal: '58px', vertical: '40px' }}
            >
              <source key="video" src={videoDetails?.video} type="video/mp4" />
            </Video>
          </Box>
        ))}
    </GrommetCarousel>
  )
}

Carousel.propTypes = {
  screenshots: PropTypes.array,
  videos: PropTypes.array,
  controls: PropTypes.bool,
  fill: PropTypes.bool,
  carouselHeight: PropTypes.string,
  carouselWidth: PropTypes.string,
  testId: PropTypes.string
}

Carousel.defaultProps = {
  screenshots: [],
  videos: [],
  controls: true,
  fill: true,
  carouselHeight: 'medium',
  carouselWidth: 'large',
  testId: 'carousel'
}
