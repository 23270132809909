import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  Box,
  List,
  Menu,
  ResponsiveContext,
  PageHeader,
  Button,
  Text
} from 'grommet'
import { StatusCritical, More } from 'grommet-icons'
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { WizardContext } from '../../../../../../../../components/wizard/WizardContext'
import { Notification } from '../../../../../../../../components'
import { validateForm } from '../../../../../../../../utils/validation-utils'
import { get } from '../../../../../../../../utils/api-utils'
import { AccountRemoveModal } from '../AccountRemoveModal/AccountRemoveModal'
import {
  WKSPC,
  WKSPC_PLURAL_CAPITALIZED,
  getWorkspaceString
} from '../../../../../../../../utils/common-utils'

import { AddAccountModal } from './AddAccountModal'
import { EditAccountModal } from './EditAccountModal'

const validateFields = (formValues) => {
  const { accounts, workspaceMessage } = formValues

  return {
    isValid: accounts.length > 0,
    message: workspaceMessage
  }
}

export const validateAccountTypeFields = (formValues, i18nTranslate) => {
  const res = validateForm(formValues, i18nTranslate, validateFields)
  return res
}

const AccountsType = ({ isUpdateFlow }) => {
  const { t } = useTranslation(['manage', 'common'])
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const { formValues, setFormValues } = useContext(WizardContext)

  const [wizardError, setWizardError] = useState(null)
  const [openAccountModal, setOpenAccountModal] = useState(false)
  const [accountData, setAccountData] = useState([])
  const [accountLabel, setAccountLabel] = useState([])
  const { oidcUser } = useReactOidc()
  const [defaultOptions, setDefaultOptions] = useState([])
  const [openRemoveConfirmation, setOpenRemoveConfirmation] = useState(false)
  const [currentItem, setCurrentItem] = useState({})
  const [currentIndex, setCurrentIndex] = useState(0)
  const [initialDefaultOptions, setInitialDefaultOptions] = useState([])

  const [selectedAcc, setSelectedAcc] = useState({})
  const [editIndex, setEditIndex] = useState(0)
  const [openEditAccountModal, setOpenEditAccountModal] = useState(false)

  useEffect(() => {
    if (formValues?.status === 'Critical') {
      setFormValues({
        ...formValues,
        email: true
      })
    } else {
      setFormValues({
        ...formValues,
        email: false
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.status])

  useEffect(() => {
    setAccountData(formValues?.accounts)
    setAccountLabel(formValues?.selectedAccountName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // API call to get list of contact
    const url = `/support-assistant/v1alpha1/customers`
    const request = {
      limit: 20
    }

    get(url, request, oidcUser.access_token).then(
      (response) => {
        const accountArray = response?.data?.customers?.map((value) => ({
          value: value?.customer_id,
          label: value?.contact?.company_name
        }))
        setDefaultOptions(accountArray)
        setInitialDefaultOptions(accountArray)
      },
      (error) => {
        console.log('error', error)
      }
    )
  }, [oidcUser.access_token, t]) // eslint-disable-line react-hooks/exhaustive-deps

  const size = React.useContext(ResponsiveContext)

  const handleRemove = () => {
    const accountIndex = accountData.indexOf(currentItem)
    const label = LDFlags['glcp-notifications-account-role-target']
      ? accountLabel.find(
          (account) => account?.accountName?.label === currentItem?.account
        )
      : accountLabel.find((account) => account?.label === currentItem?.account)

    const labelIndex = accountLabel.indexOf(label)

    let newData = accountData.slice()
    let newDataLabel = accountLabel.slice()

    if (accountIndex > -1) {
      accountData.splice(currentIndex, 1)
      newData = accountData.slice()
      setAccountData(newData)
    }
    if (labelIndex > -1) {
      accountLabel.splice(labelIndex, 1)
      newDataLabel = accountLabel.slice()
      setAccountLabel(newDataLabel)
    }
    setFormValues({
      ...formValues,
      accounts: newData,
      selectedAccountName: newDataLabel
    })
  }

  return (
    <Box gap="medium" justify="between">
      <PageHeader
        title={getWorkspaceString(
          showWorkspaceString,
          t,
          WKSPC_PLURAL_CAPITALIZED
        )}
        subtitle={t('notification.account_subtitle', {
          account: getWorkspaceString(showWorkspaceString, t, WKSPC),
          an: showWorkspaceString
            ? t('common:article.a')
            : t('common:article.an')
        })}
        actions={
          <Button
            label={t('notification.add_account', {
              account: getWorkspaceString(showWorkspaceString, t, WKSPC)
            })}
            data-testid="add_account_button"
            onClick={() => {
              setOpenAccountModal(true)
            }}
            disabled={isUpdateFlow}
            secondary
          />
        }
        pad={{ vertical: 'medium' }}
      />
      {LDFlags['glcp-notifications-account-role-target'] ? (
        <List
          data={accountData}
          primaryKey="account"
          secondaryKey={(item) => (
            <Text
              id={`acc-role-${item?.account?.label}`}
              key={`acc-role-key-${item?.account?.label}`}
            >
              {item?.roles.length === 1 &&
              item?.roles.includes(t('notification.all_roles'))
                ? t('notification.all_roles')
                : `${item?.roles.length} Roles`}
            </Text>
          )}
          border="bottom"
          action={(item, index) => (
            <Box key={index} direction="row" align="center" gap="medium">
              <Menu
                icon={<More />}
                data-testid="remove_account_button"
                hoverIndicator
                disabled={isUpdateFlow}
                items={[
                  {
                    label: t('edit'),
                    onClick: () => {
                      setSelectedAcc(item)
                      setCurrentItem(item)
                      setEditIndex(index)
                      setOpenEditAccountModal(true)
                    }
                  },
                  {
                    label: t('notification.remove'),
                    onClick: () => {
                      setOpenRemoveConfirmation(true)
                      setCurrentItem(item)
                      setCurrentIndex(index)
                    }
                  }
                ]}
              />
            </Box>
          )}
          margin={
            ['xsmall', 'small'].includes(size) ? { bottom: 'large' } : undefined
          }
        />
      ) : (
        <List
          data={accountData}
          primaryKey="account"
          border="bottom"
          action={(item, index) => (
            <Box key={index} direction="row" align="center" gap="medium">
              <Menu
                icon={<More />}
                data-testid="remove_account_button"
                hoverIndicator
                items={[
                  {
                    label: t('notification.remove'),
                    onClick: () => {
                      setOpenRemoveConfirmation(true)
                      setCurrentItem(item)
                      setCurrentIndex(index)
                    }
                  }
                ]}
              />
            </Box>
          )}
          margin={
            ['xsmall', 'small'].includes(size) ? { bottom: 'large' } : undefined
          }
        />
      )}
      {openAccountModal && (
        <AddAccountModal
          accountData={accountData}
          setAccountData={setAccountData}
          setOpenAccountModal={setOpenAccountModal}
          setFormValues={setFormValues}
          formValues={formValues}
          defaultOptions={defaultOptions}
          accountLabel={accountLabel}
          setAccountLabel={setAccountLabel}
          setDefaultOptions={setDefaultOptions}
          initialDefaultOptions={initialDefaultOptions}
          setInitialDefaultOptions={setInitialDefaultOptions}
        />
      )}
      {openRemoveConfirmation && (
        <AccountRemoveModal
          onSetOpen={setOpenRemoveConfirmation}
          handleRemove={handleRemove}
        />
      )}
      {openEditAccountModal && (
        <EditAccountModal
          accountData={accountData}
          setAccountData={setAccountData}
          setOpenEditAccountModal={setOpenEditAccountModal}
          setFormValues={setFormValues}
          formValues={formValues}
          defaultOptions={defaultOptions}
          accountLabel={accountLabel}
          setAccountLabel={setAccountLabel}
          setDefaultOptions={setDefaultOptions}
          initialDefaultOptions={initialDefaultOptions}
          setInitialDefaultOptions={setInitialDefaultOptions}
          accountDetails={selectedAcc}
          currentIndex={editIndex}
        />
      )}

      {wizardError && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setWizardError(null)}
          testId="critical-inline-notification"
          text={wizardError}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}
AccountsType.prototypes = {
  setBannerCheck: PropTypes.func.isRequired
}

export default AccountsType
