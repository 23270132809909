import { useState, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, FormField, Anchor, CheckBox } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Trans, useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  ModalHeader,
  Typography,
  CCSForm,
  FormInput,
  FormTextArea,
  Dropdown,
  ButtonGroup
} from '../../../../components'
import {
  validateEmail,
  validateLength
} from '../../../../utils/validation-utils'
import { get, post, getErrorMessage } from '../../../../utils/api-utils'
import { getCountryName, getCountryCode } from '../../../../utils/common-utils'

export const CreateWorkspaceSideDrawer = ({
  setStatus,
  setLoading,
  onClose
}) => {
  const LDFlags = useFlags()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authz', 'manage', 'iam', 'location', 'authn'])
  const [allCountries, setAllCountries] = useState([])
  const [filteredCountries, setFilteredCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [load, setLoad] = useState(false)
  const streetAddressField = LDFlags['glcp-street-address-field']
  const handleDiscardChanges = () => {
    onClose(false)
  }

  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value, edited: true }
        case 'FORM_EDITED':
          return { ...currentState, edited: action.value }
        default:
          return currentState
      }
    },
    {
      company_name: '',
      description: '',
      country_code: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state_or_region: '',
      zip: '',
      phone_number: '',
      email: '',
      edited: false
    }
  )

  useEffect(() => {
    const getCountries = async () => {
      let params = { status: 'AVAILABLE' }
      try {
        const doorwayStatus = await get(
          '/ui-doorway/ui/v1/status',
          {},
          oidcUser?.access_token
        )
        if (doorwayStatus?.data?.flags?.gts_compliance_flag) {
          params = {}
        }
      } catch (error) {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }

      try {
        const response = await get('/geo/ui/v1/countries', params)
        setAllCountries(response?.data?.countries)
        setFilteredCountries(response?.data?.countries)
      } catch (error) {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    }
    getCountries()
  }, [oidcUser?.access_token, t])

  const handleSubmit = async () => {
    setLoading(true)
    setLoad(true)

    const reqBody = {
      workspaceName: state?.company_name?.trim(),
      description: state?.description?.trim(),
      address: {
        streetAddress: state?.street_address?.trim(),
        streetAddressComplement: state?.street_address_2?.trim(),
        city: state?.city?.trim(),
        stateOrRegion: state?.state_or_region?.trim(),
        zip: state?.zip?.trim(),
        countryCode: state?.country_code
      },
      email: state?.email?.trim(),
      phoneNumber: state?.phone_number.trim()
    }
    try {
      await post('/organizations/v2alpha1/workspaces', reqBody)
      dispatch({
        value: false,
        type: 'FORM_EDITED'
      })
      setStatus({
        message: t('iam:igc_workspaces.message_success', {
          workspace: reqBody.workspaceName
        }),
        severity: 'info',
        testId: 'create-additional-workspace-status',
        title: t('iam:igc_workspaces.title_success')
      })
      onClose(false)
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrorMessage(
          t('iam:igc_workspaces.duplicated_workspace', {
            workspace: reqBody?.workspaceName
          })
        )
      } else {
        setStatus({
          message: t('iam:igc_workspaces.message_failure', {
            workspace: reqBody?.workspaceName
          }),
          severity: 'error',
          testId: 'create-additional-workspace-status',
          title: t('iam:igc_workspaces.title_failure')
        })
      }
      setLoading(false)
    } finally {
      setLoad(false)
    }
  }

  const nameMaxLength = 100
  const addressMaxLength = 220
  const cityMaxLength = 40
  const zipcodeMaxLength = 10
  const phoneMaxLength = 20

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <>
              <Typography
                level="2"
                testId="create-additional-workspace-title"
                type="heading"
                style={{ maxWidth: 'inherit' }}
              >
                {t('iam:igc_workspaces.create_workspace_btn')}
              </Typography>
            </>
          }
          subtitle={t('iam:igc_workspaces.add_workspace_subtitle')}
          onClose={() => onClose(false)}
        />
      }
      content={
        <CCSForm
          errorMessage={errorMessage}
          testId="create-additional-workspace-form"
          validate="blur"
          onSubmit={handleSubmit}
          buttons={
            <>
              <Box direction="row" gap="medium" pad={{ top: 'medium' }}>
                <ButtonGroup
                  buttonList={[
                    {
                      id: 1,
                      label: t('iam:igc_workspaces.create_workspace_btn'),
                      type: 'submit',
                      primary: true,
                      testId: 'create-additional-workspace-btn-save',
                      isLoading: load,
                      disabled: load
                    },
                    {
                      id: 2,
                      label: t('manage:cancel'),
                      type: 'reset',
                      testId: 'create-additional-workspace-btn-cancel',
                      onClick: handleDiscardChanges
                    }
                  ]}
                  justifyGroup="start"
                  testId="create-additional-workspace-btn-group"
                />
              </Box>
            </>
          }
        >
          <Box
            gap="small"
            pad={{ top: 'medium' }}
            style={{ minHeight: 'initial' }}
          >
            {/* Name Input Field */}
            <FormInput
              value={state?.company_name}
              required
              disabled={load}
              label={t('manage:org_workspace_edit.workspace_name')}
              name="company_name"
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'company_name',
                  type: 'CHANGE_FIELD'
                })
              }}
              inputType="text"
              validate={(value) => {
                return validateLength(t, value, nameMaxLength, true)
              }}
              testId="create-additional-workspace-name"
            />
            {/* Description Input Field */}
            <FormTextArea
              disabled={load}
              value={state?.description}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'description',
                  type: 'CHANGE_FIELD'
                })
              }}
              label={t('manage:org_workspace.description')}
              name="description"
              validate={(value) => {
                return validateLength(t, value, nameMaxLength)
              }}
              testId="create-additional-workspace-description"
            />
            {/* Address Input Field Group */}
            <Box gap="medium">
              {/* Country Input Field */}
              <Box direction="column" gap="xsmall">
                <Box>
                  <FormField
                    required
                    disabled={load}
                    label={t('manage:org_workspace.country')}
                    name="country_code"
                    data-testid="create-additional-workspace-country-dropdown-field"
                  >
                    <Dropdown
                      name="country_code"
                      options={filteredCountries?.map((country) => {
                        return country?.name
                      })}
                      value={getCountryName(
                        state?.country_code,
                        filteredCountries
                      )}
                      searchPlaceholder={t(
                        'manage:org_workspace_edit.country_search_placeholder'
                      )}
                      onSearch={(searchText) => {
                        const regexp = new RegExp(searchText, 'i')
                        setFilteredCountries(
                          allCountries?.filter((country) =>
                            country?.name?.match(regexp)
                          )
                        )
                      }}
                      onChange={({ option }) => {
                        dispatch({
                          value: getCountryCode(option, filteredCountries),
                          field: 'country_code',
                          type: 'CHANGE_FIELD'
                        })
                      }}
                      onClose={() => setFilteredCountries(allCountries || [])}
                      required
                      testId="create-additional-workspace-country-dropdown"
                    />
                  </FormField>
                </Box>
              </Box>
            </Box>
            {/* StreetAddress1 Input Field */}
            <FormInput
              required={!streetAddressField}
              disabled={load}
              value={state?.street_address}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'street_address',
                  type: 'CHANGE_FIELD'
                })
              }}
              inputType="text"
              label={t('iam:igc_workspaces.street_address_label')}
              name="street_address"
              validate={(value) => {
                return validateLength(t, value, addressMaxLength)
              }}
              testId="create-additional-workspace-address-line-1"
            />
            {/* StreetAddress2 Input Field */}
            <FormInput
              disabled={load}
              value={state?.street_address_2}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'street_address_2',
                  type: 'CHANGE_FIELD'
                })
              }}
              inputType="text"
              label={t('iam:create_workspace.street_address_2_label')}
              name="street_address_2"
              validate={(value) => {
                return validateLength(t, value, addressMaxLength)
              }}
              testId="create-additional-workspace-address-line-2"
            />
            <Box direction="row" gap="xsmall">
              {/* City Input Field */}
              <FormInput
                disabled={load}
                value={state?.city}
                onChange={(event) => {
                  dispatch({
                    value: event?.target?.value,
                    field: 'city',
                    type: 'CHANGE_FIELD'
                  })
                }}
                inputType="text"
                label={t('iam:create_workspace.city_label')}
                name="city"
                validate={(value) => {
                  return validateLength(t, value, cityMaxLength)
                }}
                testId="create-additional-workspace-city"
              />
              {/* State or Region Input Field */}
              <FormInput
                disabled={load}
                value={state?.state_or_region}
                onChange={(event) => {
                  dispatch({
                    value: event?.target?.value,
                    field: 'state_or_region',
                    type: 'CHANGE_FIELD'
                  })
                }}
                inputType="text"
                label={t('iam:create_workspace.state_label')}
                name="state_or_region"
                validate={(value) => {
                  return validateLength(t, value, cityMaxLength)
                }}
                testId="create-additional-workspace-stateRegion"
              />
            </Box>
            {/* ZipCode Input Field */}
            <FormInput
              disabled={load}
              value={state?.zip}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'zip',
                  type: 'CHANGE_FIELD'
                })
              }}
              inputType="text"
              label={t('iam:create_workspace.zip_label')}
              name="zip"
              validate={(value) => {
                return validateLength(t, value, zipcodeMaxLength)
              }}
              testId="create-additional-workspace-zipcode"
            />
            {/* PhoneNumber Input Field */}
            <FormInput
              disabled={load}
              value={state?.phone_number}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'phone_number',
                  type: 'CHANGE_FIELD'
                })
              }}
              inputType="text"
              label={t('iam:create_workspace.workspace_phone_label')}
              name="phone_number"
              validate={(value) => {
                return validateLength(t, value, phoneMaxLength)
              }}
              testId="create-additional-workspace-phoneNumber"
            />
            {/* Email Input Field */}
            <FormInput
              disabled={load}
              value={state?.email}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'email',
                  type: 'CHANGE_FIELD'
                })
              }}
              inputType="text"
              label={t('iam:create_workspace.workspace_email_label')}
              name="email"
              validate={(value) => {
                return validateEmail(value)
              }}
              testId="create-additional-workspace-email"
            />
          </Box>
          <Box>
            <FormField
              margin={{ bottom: 'small' }}
              name="legal_terms"
              required
              data-testid="create-additional-workspace-legal-terms-form-field"
              disabled={load}
            >
              <CheckBox
                pad="none"
                data-testid="create-additional-workspace-legal-terms-checkbox"
                name="legal_terms"
                checked={state.legal_terms}
                label={
                  <Box width="medium">
                    <Typography
                      type="text"
                      testId="create-additional-workspace-terms"
                    >
                      <Trans
                        i18nKey="authn:acct_onboarding.check_legal_terms"
                        t={t}
                      >
                        <Anchor
                          label={t('authn:acct_onboarding.legal_terms_anchor')}
                          href="https://www.hpe.com/us/en/about/legal/terms-of-use.html"
                          target="_blank"
                        />
                      </Trans>
                    </Typography>
                  </Box>
                }
                onChange={(event) =>
                  dispatch({
                    value: event.target.checked,
                    field: 'legal_terms',
                    type: 'CHANGE_FIELD'
                  })
                }
                disabled={load}
              />
            </FormField>
          </Box>
        </CCSForm>
      }
      onClose={() => onClose(false)}
      position="right"
      height="100%"
      overflow="hidden"
      testId="create-additional-workspace-side-drawer"
    />
  )
}

CreateWorkspaceSideDrawer.propTypes = {
  /**
   * Callback to set the status back to caller.
   */
  setStatus: PropTypes.func.isRequired,

  /**
   * Callback to set loading state back to caller.
   */
  setLoading: PropTypes.func.isRequired,

  /**
   * Callback to indicate whether the modal should be opened or closed.
   */
  onClose: PropTypes.func.isRequired
}
