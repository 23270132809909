import React from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'

import { checkGotoChooseAccount } from '../../utils/common-utils'

import CustomerAccountPage from './customer-account'
import { CustomerDetails } from './customer-details'

const CustomerAccountRouter = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  checkGotoChooseAccount(history)

  return (
    <Switch>
      <Route exact path={`${path}/edit/:customerId`}>
        <CustomerDetails />
      </Route>
      <Route exact path={`${path}/view/:customerId`}>
        <CustomerDetails />
      </Route>

      {/* NOTE: Always add any routes above this route */}
      <Route path={path}>
        <CustomerAccountPage />
      </Route>
    </Switch>
  )
}

export default CustomerAccountRouter
