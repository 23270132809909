import { Route, Switch, useRouteMatch } from 'react-router-dom'
import React from 'react'

import LocalGatewayList from './LocalGatewayList'
import WelcomePage from './Welcome'
import CreateGatewayWizard from './GatewayWizard'
import RegistrationWizard from './RegistrationWizard'
import GatewayDetails from './GatewayDetails'
import AppDetails from './AppDetails'

const LocalGatewayRouter = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/welcome`}>
        <WelcomePage />
      </Route>
      <Route path={`${path}/create`}>
        <CreateGatewayWizard />
      </Route>
      <Route path={`${path}/register/:gatewayId`}>
        <RegistrationWizard />
      </Route>
      <Route path={`${path}/details/:gatewayId/:appId`}>
        <AppDetails />
      </Route>
      <Route path={`${path}/details/:gatewayId`}>
        <GatewayDetails />
      </Route>
      {/* NOTE: Always add any routes above this route */}
      <Route path={`${path}`}>
        <LocalGatewayList />
      </Route>
    </Switch>
  )
}

export default LocalGatewayRouter
