import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { Box, Tag } from 'grommet'
import { useHistory } from 'react-router-dom'

import { SectionTitle } from './SectionTitle'

const LocationDetailsTagsSection = ({ tagsData }) => {
  const { t } = useTranslation(['location', 'device'])
  const history = useHistory()

  return (
    <>
      <Box data-testid="location-tags" margin="none">
        <SectionTitle title={t('device:tags.tags_title')} />
      </Box>
      <Box gap="small" direction="row-responsive" wrap>
        {tagsData && tagsData.length !== 0
          ? tagsData.map((item, idx) => {
              return (
                <Box
                  margin={{ top: 'xsmall' }}
                  key={`tag-${item.name}-${item.value}`}
                >
                  <Tag
                    name={item.name}
                    alignSelf="start"
                    value={item.value}
                    data-testid={`tag-${idx}`}
                    onClick={() => {
                      history.push({
                        pathname: `/manage-account/tags/tag-details/${item?.name}:${item?.value}`,
                        search: 'resourceType=LOCATION'
                      })
                    }}
                  />
                </Box>
              )
            })
          : t('device:tags.no_tags_assigned')}
      </Box>
    </>
  )
}

LocationDetailsTagsSection.propTypes = {
  tagsData: PropTypes.array.isRequired
}

export { LocationDetailsTagsSection }
