import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Box, Button, DateInput, Layer, Text } from 'grommet'
import { useTranslation } from 'react-i18next'

const CustomDateSelection = ({
  show,
  setDateRange,
  setShowCalendar,
  cancelCustomDate
}) => {
  const { t } = useTranslation('unified_wellness')
  const [customDateRange, setCustomDateRange] = useState([])
  const [rangeTooLong, setRangeTooLong] = useState(false)
  const [applyAttempt, setApplyAttempt] = useState(false)
  // This is used for a workaround around an issue with the calendar component
  const [remountKey, setRemountKey] = useState(1)

  const reset = () => {
    setRangeTooLong(false)
    setApplyAttempt(false)
  }

  const cancel = () => {
    reset()
    cancelCustomDate()
  }

  const validate = (value) => {
    const start = dayjs(value[0]).startOf('day')
    const end = dayjs(value[1]).endOf('day')
    const valid = end.diff(start, 'day') + 1 < 91
    if (valid) {
      setRangeTooLong(false)
    } else {
      setRangeTooLong(true)
    }
    return valid
  }

  const applyCustomDate = () => {
    setRangeTooLong(false)
    if (customDateRange.length) {
      setApplyAttempt(true)
      if (validate(customDateRange)) {
        setDateRange([
          dayjs(customDateRange[0]).startOf('day'),
          dayjs(customDateRange[1]).endOf('day')
        ])
        setShowCalendar(false)
        reset()
      }
    } else {
      cancel()
    }
  }

  if (!show) return null
  return (
    <Layer onEsc={() => cancel()} onClickOutside={() => cancel()}>
      <Box pad="small">
        <div>
          <Text
            data-testid="custom-date-select-title"
            size="xxlarge"
            weight="bold"
            margin={{ bottom: 'medium' }}
          >
            {t('calandar.title')}
          </Text>
        </div>
        <div>
          <Text size="small">{t('calandar.date_range_label')}</Text>
        </div>
        <div>
          <DateInput
            inline
            format="mm/dd/yyyy-mm/dd/yyyy"
            value={customDateRange}
            onChange={({ value }) => {
              // Basically, if the DateInput component is fubared, increment the remountKey in order to trigger a remount
              // otherwise use the value as normal
              if (value.length && typeof value[0] === 'undefined') {
                setRemountKey(remountKey + 1)
              } else {
                // only run the validate on change after a failed attempt to apply
                setCustomDateRange(value)
                if (applyAttempt) {
                  validate(value)
                }
              }
            }}
            calendarProps={{ margin: { top: 'small' } }}
            key={remountKey}
            data-testid="custom-date-select"
          />
        </div>
        {rangeTooLong && (
          <div>
            <Box align="end">
              <Text size="small" color="red">
                {t('calandar.date_range_too_long')}
              </Text>
            </Box>
          </div>
        )}
        <div>
          <Box direction="row" justify="end" gap="small" pad={{ top: 'small' }}>
            <Button
              label={t('calandar.cancel')}
              onClick={() => cancel()}
              data-testid="cancel-custom-date-select"
            />
            <Button
              primary
              label={t('calandar.apply')}
              onClick={applyCustomDate}
              data-testid="apply-custom-date-select"
              disabled={rangeTooLong}
            />
          </Box>
        </div>
      </Box>
    </Layer>
  )
}

CustomDateSelection.propTypes = {
  show: PropTypes.bool.isRequired,
  setDateRange: PropTypes.func.isRequired,
  setShowCalendar: PropTypes.func.isRequired,
  cancelCustomDate: PropTypes.func.isRequired
}

export default CustomDateSelection
