import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Box, ResponsiveContext } from 'grommet'
import { StatusWarningSmall } from 'grommet-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Typography,
  Button,
  AvatarInfo,
  Card,
  Notification
} from '../../../components'
import { get } from '../../../utils/api-utils'

const GreenLakeAppCard = ({ gatewayId, appId }) => {
  const { t } = useTranslation(['local-gateway', 'common'])
  const history = useHistory()
  const { oidcUser } = useReactOidc()

  const handleAppClick = () => {
    history.push(`/manage-account/local-gateway/details/${gatewayId}/${appId}`)
  }

  const [loadingCredentials, setloadingCredentials] = useState(true)
  const [credentials, setCredentials] = useState([])
  const noCreds = credentials && credentials.length < 1
  const allPending =
    credentials && !credentials.map((cred) => cred.synced).includes(true)
  const size = useContext(ResponsiveContext)

  useEffect(() => {
    get(
      `/opg-proxy/v1/gateways/${gatewayId}/apps/${appId}/creds`,
      {},
      oidcUser.access_token
    )
      .then((response) => {
        if (response.data) {
          setCredentials(response.data.creds)
        }
      })
      .finally(() => setloadingCredentials(false))
  }, [gatewayId, appId, oidcUser])

  const SecondaryInfo = () => {
    if (!loadingCredentials && (noCreds || allPending)) {
      return (
        <Box direction="row" align="center">
          <StatusWarningSmall size="16px" color="orange" />
          <Typography type="text" size="small" margin={{ left: 'small' }}>
            {noCreds
              ? t('api_messages.no_credentials')
              : t('api_messages.pending_credentials')}
          </Typography>
        </Box>
      )
    }

    return null
  }

  return (
    <>
      {!loadingCredentials && noCreds && (
        <Box pad={{ bottom: 'medium' }}>
          <Notification
            testId={`card${appId}-creds-error-notification`}
            text={t('api_messages.creds_error')}
            backgroundColor="status-warning"
            type="inline"
          />
        </Box>
      )}
      <Card
        cardWidth="100%"
        testId={`${appId}-description-card`}
        key={`card${appId}`}
        CustomContent={
          <Box
            direction={size === 'small' || size === 'xsmall' ? 'column' : 'row'}
            justify="between"
            width="large"
          >
            <Box justify="start" flex={false}>
              <AvatarInfo
                primaryInfo={t('gateway_details.app_primary_info')}
                secondaryInfo={<SecondaryInfo />}
                testId="app-info"
              />
            </Box>
            <Box justify="end" align="end" flex={false}>
              <Button
                label={t('gateway_details.view_details')}
                secondary
                testId="btn-view-details"
                onClick={handleAppClick}
              />
            </Box>
          </Box>
        }
      />
    </>
  )
}

GreenLakeAppCard.propTypes = {
  gatewayId: PropTypes.string.isRequired,
  appId: PropTypes.number.isRequired
}
export default GreenLakeAppCard
