import { isGLOP } from './utils/feature-flag-utils'

const url = `${window.location.protocol}//${window.location.host}`

const getOIDCConfig = (authorityURL) => {
  const defaultConfig = {
    authority: authorityURL,
    client_id: window.$settings.client_id,
    redirect_uri: `${url}/authentication/callback`,
    response_type: 'code',
    post_logout_redirect_uri: url,
    scope: 'openid profile email',
    silent_redirect_uri: `${url}/authentication/silent_callback`,
    automaticSilentRenew: true,
    loadUserInfo: true,
    metadata: {
      issuer: authorityURL,
      jwks_uri: `${authorityURL}/pf/JWKS`,
      ping_end_session_endpoint: `${authorityURL}/idp/startSLO.ping`,
      end_session_endpoint: `${authorityURL}/idp/startSLO.ping`,
      authorization_endpoint: `${authorityURL}/as/authorization.oauth2`,
      token_endpoint: `${authorityURL}/as/token.oauth2`,
      ping_revoked_sris_endpoint: `${authorityURL}/pf-ws/rest/sessionMgmt/revokedSris`,
      revocation_endpoint: `${authorityURL}/as/revoke_token.oauth2`,
      userinfo_endpoint: `${authorityURL}/idp/userinfo.openid`,
      introspection_endpoint: `${authorityURL}/as/introspect.oauth2`,
      backchannel_authentication_endpoint: `${authorityURL}/as/bc-auth.ciba`
    }
  }
  const currentLPUser = sessionStorage.getItem('current-lp-user')
  const updatedConfig = {
    ...defaultConfig,
    extraQueryParams: { login_hint: currentLPUser }
  }
  return isGLOP() && currentLPUser ? updatedConfig : defaultConfig
}

export default getOIDCConfig
