import { React } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  Redirect
} from 'react-router-dom'

import { checkGotoChooseAccount } from '../../utils/common-utils'
import { AUTHZContextProvider } from '../../context/authz-context'

import HomePage from './home'
import GetStartedRouter from './get-started/router'

const HomeRouterContent = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  checkGotoChooseAccount(history)

  return (
    <Switch>
      <Route exact path={path}>
        <HomePage />
      </Route>
      <Route path="/home/get-started">
        <GetStartedRouter />
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  )
}

const HomeRouter = () => {
  return (
    <AUTHZContextProvider>
      <HomeRouterContent />
    </AUTHZContextProvider>
  )
}

export default HomeRouter
