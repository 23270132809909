import { useContext } from 'react'
import { Box, Grid, ResponsiveContext } from 'grommet'
import { useTranslation } from 'react-i18next'

import { SideMenu } from '../../components'
import { Layout, PageHeader, ServiceSubscriptions } from '../../shims/imports'

const ServiceSubscriptionPage = () => {
  const { t } = useTranslation(['dashboard'])
  const size = useContext(ResponsiveContext)

  return (
    <Layout>
      <Box pad={{ horizontal: 'xlarge', vertical: 'medium' }}>
        <Box margin={{ bottom: 'large' }}>
          <PageHeader
            primaryHeader={t('common.services')}
            testId="services-main"
            subHeader={t('common.services_subtitle')}
          />
        </Box>
        <Grid
          rows={['auto']}
          columns={size === 'xlarge' ? ['small', 'flex'] : ['full']}
          gap="large"
        >
          <SideMenu active="subscriptions" />
          <ServiceSubscriptions />
        </Grid>
      </Box>
    </Layout>
  )
}

export default ServiceSubscriptionPage
