import { useState, useEffect } from 'react'
import { Grid, Box } from 'grommet'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { get } from '../../../utils/api-utils'
import {
  ModalDialog,
  ModalHeader,
  Ruler,
  Typography
} from '../../../components'

const SideDetailsPanel = ({ title, data, onClose, type, hasDetails }) => {
  const { t } = useTranslation(['manage'])

  const { oidcUser } = useReactOidc()
  const [additionalInfo, setAdditionalInfo] = useState(null)

  const getValue = (value) => {
    let fieldValue
    if (value === null || value === undefined) {
      fieldValue = '-'
    } else if (typeof value === 'boolean') {
      if (value) {
        fieldValue = 'True'
      } else {
        fieldValue = 'False'
      }
    } else if (typeof value === 'object') return value.toString()
    else {
      fieldValue = value
    }
    return fieldValue
  }

  useEffect(() => {
    if (hasDetails) {
      get(
        `/auditlogs/ui/v1/details?index=${data[6].value}&audit_id=${data[0].value}`,
        {},
        oidcUser.access_token
      ).then((res) => {
        setAdditionalInfo(res.data)
      })
    }
  }, [
    data,
    data.has_details,
    data.audit_id,
    data.index,
    oidcUser.access_token,
    hasDetails
  ])

  const processEscapeChars = (str) => {
    const template = str.split('\n').map((substr) => {
      let formattedSubStr
      if (substr && substr.includes('\t')) {
        formattedSubStr = (
          <Box key={substr} margin={{ left: 'small' }}>
            {substr.replace('\t', '')}
          </Box>
        )
      } else if (substr && !substr.includes('\t')) {
        formattedSubStr = <Box key={substr}>{substr.replace('\t', '')}</Box>
      } else {
        formattedSubStr = <br key={substr} />
      }

      return formattedSubStr
    })

    return template
  }

  return (
    <ModalDialog
      content={
        <Box
          margin={{ vertical: 'medium', right: 'xsmall' }}
          direction="column"
        >
          {data.map((datum) => {
            return (
              <Box key={`${datum.key}:${datum.value}`} flex={{ shrink: 0 }}>
                {datum?.isHeading || datum?.noDataFound ? (
                  <Typography
                    testId={`${datum.key}-customer-key`}
                    wordBreak="break-word"
                    margin={{ bottom: 'xsmall' }}
                    {...(datum?.isHeading
                      ? { type: 'heading', level: 5 }
                      : { type: 'text' })}
                  >
                    {datum?.isHeading ? (
                      datum.key
                    ) : (
                      <Trans i18nKey="no_data_found" t={t}>
                        {t(`${datum.key}`)}
                      </Trans>
                    )}
                  </Typography>
                ) : (
                  <Grid
                    rows={['flex']}
                    columns={['1/3', '2/3']}
                    gap="xsmall"
                    margin={{ bottom: 'xsmall' }}
                    areas={[
                      { name: 'key', start: [0, 0], end: [0, 0] },
                      { name: 'value', start: [1, 0], end: [1, 0] }
                    ]}
                  >
                    <Typography
                      gridArea="key"
                      size="xsmall"
                      type="text"
                      testId={`${datum.key}-customer-key`}
                      color="text-strong"
                      wordBreak="break-word"
                    >
                      {datum.key}
                    </Typography>
                    <Typography
                      gridArea="value"
                      size="small"
                      type="text"
                      testId={`${datum.key}-customer-value`}
                      color="text-strong"
                      wordBreak="break-word"
                    >
                      {getValue(datum.value)}
                    </Typography>
                  </Grid>
                )}
                {datum?.addRuler && <Ruler testId="details-object" />}
              </Box>
            )
          })}
          <Box direction="row-responsive" align="start" justify="start" flex>
            {additionalInfo ? (
              <Box direction="column">
                <Box>
                  {additionalInfo?.header ? (
                    <Typography
                      size="xlarge"
                      type="text"
                      weight="bold"
                      wordBreak="break-word"
                      margin={{ bottom: 'small', top: 'small' }}
                      testId="audit-log-additional-details"
                    >
                      {additionalInfo?.header ? additionalInfo.header : ''}
                    </Typography>
                  ) : (
                    ''
                  )}
                </Box>
                {additionalInfo?.body
                  ? additionalInfo.body.map((info) => {
                      return (
                        <Box
                          key={info}
                          direction="column"
                          margin={{ top: 'small' }}
                        >
                          <Typography
                            testId="additional-info"
                            type="text"
                            size="small"
                          >
                            <>
                              <Box>{processEscapeChars(info) || ''}</Box>
                            </>
                          </Typography>
                        </Box>
                      )
                    })
                  : ''}
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Box>
      }
      header={
        <ModalHeader
          title={
            <Typography
              size="xlarge"
              testId="header-title"
              type="text"
              weight="bold"
              wordBreak="break-word"
            >
              {type ? t(`customer_details.${type}_details`) : title}
            </Typography>
          }
          onClose={() => {
            onClose(false)
          }}
        />
      }
      height="100%"
      width="medium"
      onClose={() => {
        onClose(false)
      }}
      position="right"
      testId="side-drawer-dialog"
    />
  )
}

export default SideDetailsPanel

SideDetailsPanel.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  hasDetails: PropTypes.bool
}

SideDetailsPanel.defaultProps = {
  title: '',
  type: undefined,
  hasDetails: false
}
