import { FormField, TextInput, Box } from 'grommet'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ButtonGroup,
  CCSForm,
  ModalDialog,
  Typography
} from '../../../../../components'
import { getErrorMessage, put } from '../../../../../utils/api-utils'

const EditAliasModal = ({
  customerId,
  type,
  aliasName,
  onSuccess,
  setAliasModal
}) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage'])
  const [errorMessage, setErrorMessage] = useState('')
  const [newAliasName, setNewAliasName] = useState('')

  const handleSubmit = () => {
    const requestBody = {
      alias: newAliasName,
      old_alias_name: aliasName,
      type,
      platform_customer_id: customerId
    }
    const url = `/support-assistant/v1alpha1/customer-aliases`
    put(url, requestBody, oidcUser.access_token).then(
      () => {
        setAliasModal(false)
        setErrorMessage('')
        onSuccess()
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }
  const handleClose = () => {
    setAliasModal(false)
  }
  return (
    <ModalDialog
      width="medium"
      testId="edit-alias-modal"
      content={
        <Box gap="small">
          <Typography type="heading" level="1" testId="customer-signup-title">
            {t('aliases.edit_alias')}
          </Typography>
          <CCSForm
            errorMessage={errorMessage}
            validate="blur"
            onSubmit={handleSubmit}
            testId="edit-alias-form"
          >
            <>
              <FormField
                required
                label={t('aliases.new_alias_name')}
                name="alias"
                data-testid="alias-field"
              >
                <TextInput
                  name="alias"
                  data-testid="new-alias-input"
                  placeholder={t('aliases.new_alias_name')}
                  value={newAliasName}
                  onChange={(e) => {
                    setNewAliasName(e.target.value)
                  }}
                />
              </FormField>
              <Box margin={{ vertical: 'small' }}>
                <ButtonGroup
                  buttonList={[
                    {
                      id: 2,
                      label: t('aliases.cancel'),
                      default: true,
                      testId: 'cancel-btn',
                      onClick: handleClose
                    },
                    {
                      id: 1,
                      label: t('aliases.confirm'),
                      primary: true,
                      testId: 'confirm-btn',
                      type: 'submit'
                    }
                  ]}
                  testId="two-buttons"
                />
              </Box>
            </>
          </CCSForm>
        </Box>
      }
      onClose={handleClose}
    />
  )
}
EditAliasModal.propTypes = {
  customerId: PropTypes.string.isRequired,
  aliasName: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setAliasModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
}
export { EditAliasModal }
