// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import PropTypes from 'prop-types'

const SuggestionContainer = ({ children, ...rest }) => {
  return (
    <Box
      direction="row"
      align="center"
      pad={{ horizontal: 'small', vertical: 'xsmall' }}
      {...rest}
    >
      {children}
    </Box>
  )
}

SuggestionContainer.propTypes = {
  children: PropTypes.element.isRequired
}

export default SuggestionContainer
