import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  CCSForm
} from '../../../../components'
import { TagsEdit } from '../../../commoncomponents/tags/TagsEdit'
import { updateLocationTags } from '../../../commoncomponents/location-management/updateTagsUtils'
import { post, put } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { isServiceCentric } from '../../../../utils/account-utils'
import { isGLOP } from '../../../../utils/feature-flag-utils'

const ManageSubscriptionTagsModal = ({
  setModalOpen,
  manageTagsData,
  setShowErrorNotification,
  selectedSubscriptionsKey,
  onSuccess
}) => {
  const { t } = useTranslation(['device', 'common'])
  const [initialTags, setInitialTags] = useState([])
  const [updatedTags, setUpdatedTags] = useState(initialTags)
  const [isNewAssignment, setIsNewAssignment] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()
  const { 'glcp-subscrption-tags': subcriptionTagsFlag } = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  useEffect(() => {
    // get common tags api call
    if ((subcriptionTagsFlag && newDashboardFlag) || isGLOP()) {
      post(
        `/ui-doorway/ui/v1/license/tags/stats`,
        {
          subscriptions: selectedSubscriptionsKey
        },
        oidcUser.access_token
      ).then(
        (tagsResponse) => {
          setInitialTags(tagsResponse?.data?.commonTags)
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buttonList = [
    {
      label: t('cancel'),
      default: true,
      testId: 'cancel-btn',
      onClick: () => setModalOpen(false, true)
    },
    {
      label: t('save'),
      primary: true,
      testId: 'save-btn',
      onClick: () => {
        // identify remove and create and send to BE
        const { assigned, unassigned } = updateLocationTags(
          initialTags,
          updatedTags
        )
        // if no changes, show error
        if (unassigned.length === 0 && assigned.length === 0) {
          setIsNewAssignment(true)
        } else {
          put(
            '/ui-doorway/ui/v1/license/tags',
            {
              createTags: [...assigned],
              deleteTags: [...unassigned],
              subscriptionKeys: selectedSubscriptionsKey
            },
            oidcUser.access_token
          ).then(
            () => {
              setModalOpen(false)
              onSuccess()
            },
            (error) => {
              setModalOpen(false)
              setShowErrorNotification(
                displayApiError(error, t, setErrorMessage)
              )
            }
          )
        }
      }
    }
  ]
  return (
    <>
      <ModalDialog
        width="large"
        testId="manage-tags-modal-dialog"
        onClose={() => {
          setModalOpen(false, true)
        }}
        header={
          <ModalHeader
            title={
              <Typography
                type="heading"
                level="1"
                emphasis
                testId="manage-tags-title"
              >
                {t('device:tags.manage_tags')}
              </Typography>
            }
          />
        }
        content={
          <Box flex={false}>
            <Typography
              type="text"
              size="large"
              margin={{ top: 'xsmall', bottom: '0px' }}
              testId="manage-tags-subtitle"
            >
              {t('device:tags.manage_tags_dlg_prompt')}
            </Typography>
            <CCSForm
              justify="start"
              validate="blur"
              errorMessage=""
              testId="add-form"
            >
              <TagsEdit
                isSubscriptionTagsModal
                onChange={(tags) => setUpdatedTags(tags)}
                initialTags={initialTags}
                isNewAssignment={isNewAssignment}
                setIsNewAssignment={setIsNewAssignment}
                selectedLicenseData={manageTagsData}
              />
            </CCSForm>
          </Box>
        }
        footer={
          <ModalFooter
            right={<ButtonGroup buttonList={buttonList} testId="modal-btns" />}
          />
        }
      />
      {errorMessage}
    </>
  )
}

ManageSubscriptionTagsModal.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  manageTagsData: PropTypes.array.isRequired,
  setShowErrorNotification: PropTypes.func.isRequired,
  selectedSubscriptionsKey: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export { ManageSubscriptionTagsModal }
