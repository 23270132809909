import VisibilityWrapper from './visibility-wrapper/VisibilityWrapper'

export const customRenderer = (element, visibility, key) => {
  return (
    !element?.hidden && (
      <VisibilityWrapper
        key={key}
        rbac={visibility?.rbac}
        hideFor={visibility?.hideFor}
      >
        {element}
      </VisibilityWrapper>
    )
  )
}
