import { Box, Button } from 'grommet'
import { Previous } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useServices } from '../../../hooks'
import { Layout, Typography } from '../../../shims/imports'
import FeaturedServiceMarkdown from '../../../components/FeaturedServiceMarkdown'
import { CATEGORY } from '../../../constants'

const PrivateCloud = () => {
  const { services, isLoaded } = useServices()
  const { t } = useTranslation('common')
  const history = useHistory()

  const { PRIVATE_CLOUD } = CATEGORY

  const privateCloudServices = services.filter(
    ({ category }) => category === PRIVATE_CLOUD
  )

  return (
    <Layout>
      <Box>
        <Box direction="row" pad="medium">
          <Button
            icon={<Previous color="green" weight={500} />}
            as="a"
            href="/home"
            onClick={(e) => {
              e.preventDefault()
              history.push('/home/')
            }}
            label={<Typography type="text">{t('nav_bar.home')}</Typography>}
            direction="row"
          />
        </Box>
        {isLoaded && (
          <Box
            direction="row"
            justify="center"
            pad={{ top: 'medium', bottom: 'large', horizontal: 'small' }}
            margin={{ horizontal: 'medium', bottom: 'large' }}
          >
            {privateCloudServices.map((service, index) => (
              <Box
                border={
                  index === 0 && {
                    color: 'lightgrey',
                    size: 'small',
                    side: 'right'
                  }
                }
                pad={{
                  left: `${index === 0 ? 'none' : 'xlarge'}`,
                  right: `${index === 0 ? 'xlarge' : 'none'}`,
                  vertical: 'medium'
                }}
                width="large"
                direction="column"
                key={service.serviceSlug}
              >
                <FeaturedServiceMarkdown service={service} />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Layout>
  )
}

export default PrivateCloud
