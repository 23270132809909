import React from 'react'
import { Box, Text } from 'grommet'
import { useTranslation, Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useHistory } from 'react-router-dom'

import { del, getErrorMessage } from '../../../../../utils/api-utils'
import { ModalDialog, Typography, Button } from '../../../../../components'
import { getCustomerAccount } from '../../../../../utils/feature-flag-utils'

const DeletePost = ({
  postData,
  setErrorMessage,
  refreshDataTable,
  setDeletePostMessage,
  closeDeleteModal,
  state
}) => {
  const { t } = useTranslation(['manage'])
  const history = useHistory()
  const { oidcUser } = useReactOidc()
  const custAccountLoaded = getCustomerAccount()
  const platformCustomerId = custAccountLoaded?.platform_customer_id || ''

  const { title } = postData

  const handlePostDelete = () => {
    let URL = `/whatsnew/v1/manage/blogs/${postData.blogID}`
    if (state) {
      URL = `/whatsnew/v1/review/blogs/${postData.blogID}`
    }
    del(URL, {}, oidcUser.access_token, {
      'X-Account-ID': platformCustomerId
    }).then(
      (response) => {
        if (response.status === 204) {
          closeDeleteModal()
          setDeletePostMessage(
            <Box direction="column">
              <Typography type="text" size="medium" weight="bold">
                {t('whats_new.post_deleted')}
              </Typography>
              <Typography type="text" size="medium">
                {t('whats_new.delete_post_success')}
              </Typography>
            </Box>
          )
          history.push('/manage-ccs/whats-new')
          refreshDataTable()
        }
      },
      (error) => {
        setErrorMessage(getErrorMessage(error, t))
      }
    )
  }
  return (
    <Box>
      <ModalDialog
        testId="delete-post-modal"
        onClose={() => {
          closeDeleteModal()
        }}
        header={
          <Typography type="heading" testId="delete-post-title" level={2}>
            {t('whats_new.delete_post_title')}
          </Typography>
        }
        content={
          <Box margin={{ vertical: 'medium' }}>
            <Typography type="text" testId="delete-post-desc">
              <Trans i18nKey="whats_new.delete_post_desc" t={t} title={title}>
                <Text weight={500} color="text-strong">
                  {{ title }}
                </Text>
              </Trans>
            </Typography>
          </Box>
        }
        footer={
          <Box align="center" gap="small" direction="row-reverse">
            <Button
              primary
              label={t('whats_new.delete_post_btn')}
              testId="delete-post-btn"
              onClick={handlePostDelete}
            />
            <Button
              label={t('whats_new.cancel_btn')}
              testId="cancel-delete-post-btn"
              onClick={closeDeleteModal}
            />
          </Box>
        }
        position="center"
        width="large"
      />
    </Box>
  )
}

DeletePost.propTypes = {
  postData: PropTypes.object.isRequired,
  closeDeleteModal: PropTypes.func.isRequired,
  refreshDataTable: PropTypes.func.isRequired,
  setDeletePostMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  state: PropTypes.bool.isRequired
}

export default DeletePost
