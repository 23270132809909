// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, Text } from 'grommet'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import {
  Button,
  CCSForm,
  FormInput,
  FormTextArea,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../components'
import { getErrorMessage, put } from '../../../../utils/api-utils'

import Markdown from './Markdown'

const EditRoleDetailsSidePanel = ({
  onSetOpen,
  roleDetailsData,
  setStatus
}) => {
  const { t } = useTranslation(['common', 'iam', 'manage'])
  const [errorMessage, setErrorMessage] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [description, setDescription] = useState(
    roleDetailsData[t('authz:workspace_group.description')]
  )
  const [roleDisplayName, setRoleDisplayName] = useState(
    roleDetailsData[
      t('iam:roles_details.role_name', {
        role: t('common:business_object.role_capitalized')
      })
    ]
  )

  const handleSubmit = async () => {
    const requestBody = {
      id: roleDetailsData[t('iam:roles_details.role_id')],
      name: roleDetailsData?.name,
      displayName: roleDisplayName.trim(),
      description: description?.trim(),
      releaseStage: '',
      kind: roleDetailsData[t('iam:roles_details.kind')],
      managedBy: roleDetailsData[t('iam:roles_details.managedBy')],
      permissions: roleDetailsData?.permissions
    }
    try {
      setIsDisabled(true)
      const response = await put(
        `/authorization/v2alpha1/roles/${
          roleDetailsData[t('iam:roles_details.role_id')]
        }`,
        requestBody
      )
      if (response?.data && response?.status === 200) {
        setStatus({
          title: t('iam:roles_details.edit_role_details_title', {
            role: t('common:business_object.role')
          }),
          message: t('iam:roles_details.edit_role_details_msg', {
            Role: t('common:business_object.role_capitalized')
          }),
          severity: 'info',
          testId: 'role-details-edit-status'
        })
        onSetOpen(false)
        setIsDisabled(false)
      }
    } catch (error) {
      setIsDisabled(false)
      let message = ''
      if (error?.response?.data?.httpStatusCode === 409) {
        switch (error?.response?.data?.errorCode) {
          case 'HPE_GL_AUTHORIZATION_ALREADY_CREATED':
            message = t('authz:409_error_messages.role_already_created_error')
            break
          case 'HPE_AUTHZ_RESOURCE_CONFLICT':
            message = (
              <Markdown
                components={{
                  strong: {
                    component: Text,
                    props: { size: 'xsmall', weight: 500 }
                  }
                }}
              >
                {t('authz:409_error_messages.role_name_conflict_error', {
                  name: roleDisplayName
                })}
              </Markdown>
            )
            break
          default:
            message = getErrorMessage(error, t)
            break
        }
      } else {
        message = getErrorMessage(error, t)
      }
      setErrorMessage(message)
    }
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="2">
              {t('iam:roles_details.role_edit', {
                role: t('common:business_object.role')
              })}
            </Typography>
          }
          onClose={() => onSetOpen(false)}
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <CCSForm
            errorMessage={errorMessage}
            validate="blur"
            onSubmit={handleSubmit}
            testId="edit-role-details-form"
          >
            <>
              <Box name="displayName" testId="role-name-info">
                <FormInput
                  name="displayName"
                  required
                  inputType="text"
                  label={t('iam:roles_details.role_name', {
                    role: t('common:business_object.role_capitalized')
                  })}
                  value={roleDisplayName}
                  maxLength={64}
                  onChange={(event) => {
                    if (!isEmpty(errorMessage)) {
                      setErrorMessage('')
                    }
                    setRoleDisplayName(event?.target?.value)
                  }}
                  margin={{ top: 'small' }}
                  testId="role-display-name"
                />
              </Box>
              <Box
                width={{ min: 'medium' }}
                direction="column"
                testId="role-input-fields"
              >
                <FormTextArea
                  name="description"
                  inputType="text"
                  label={t('authz:workspace_group.description', {
                    role: t('common:business_object.role_capitalized')
                  })}
                  value={description}
                  maxLength={255}
                  onChange={(event) => {
                    if (!isEmpty(errorMessage)) {
                      setErrorMessage('')
                    }
                    setDescription(event?.target?.value)
                  }}
                  margin={{ top: 'small' }}
                  testId="role-description-input"
                />
              </Box>
            </>
          </CCSForm>
        </Box>
      }
      footer={
        <ModalFooter
          left={
            <Box
              direction="row"
              gap="medium"
              margin={{ top: 'xsmall' }}
              testId="edit-role-details-btn-group"
            >
              <Button
                primary
                label={t('manage:save_changes')}
                onClick={handleSubmit}
                disabled={isDisabled}
                testId="edit-role-details-save-btn"
              />
              <Button
                default
                label={t('common:cancel')}
                onClick={() => onSetOpen(false)}
                testId="edit-role-details-cancel-btn"
              />
            </Box>
          }
        />
      }
      position="right"
      height="100%"
      onClose={() => onSetOpen(false)}
      testId="edit-role-details-modal"
    />
  )
}

EditRoleDetailsSidePanel.propTypes = {
  /**
   * Data received from the role details tab
   */
  roleDetailsData: PropTypes.object.isRequired,
  /**
   * Callback to set the status back to caller.
   */
  setStatus: PropTypes.func.isRequired,
  /**
   * Function to trigger edit role details modal
   */
  onSetOpen: PropTypes.func.isRequired
}

export default EditRoleDetailsSidePanel
