import { Route, Switch, useRouteMatch /* , Redirect */ } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../commoncomponents/LazyLoading/LazyLoading'

const ReportDownloadsPage = lazy(() => import('./ReportDownloads'))

const ReportsRouter = () => {
  const { path } = useRouteMatch()
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route exact path={path}>
          <ReportDownloadsPage />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default ReportsRouter
