import React, { useReducer, useState } from 'react'
import { Box, NameValueList } from 'grommet'
import { Hpe } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  CCSForm,
  Typography,
  Button,
  ButtonGroup,
  Logo
} from '../../../../../components'
import VisibilityWrapper from '../../../visibility-wrapper/VisibilityWrapper'
import { isCoP } from '../../../../../utils/feature-flag-utils'
import ArubaImage from '../../../../../images/aruba.png'

import RoleDynamicFormField from './RoleDynamicFormField'
import RoleDynamicViewField from './RoleDynamicViewField'

const RoleDynamicViewEditForm = ({
  detailsFields,
  values,
  readonly,
  onEdit,
  onCancel,
  onSubmit,
  isEditable
}) => {
  const { t } = useTranslation(['authz', 'common', 'manage'])
  const [errorMessage] = useState('')
  const [state, dispatch] = useReducer((currentState, action) => {
    switch (action.type) {
      case 'CHANGE_FIELD':
        return { ...currentState, [action.name]: action.value }
      case 'RESET':
        return { ...values }
      default:
        return currentState
    }
  }, values)

  const onReset = () => {
    onCancel()
    dispatch({ type: 'RESET' })
  }

  const onFieldChange = (e) => {
    dispatch({
      name: e.name,
      value: e.value,
      type: 'CHANGE_FIELD'
    })
  }

  return (
    <CCSForm
      value={state}
      onReset={onReset}
      onSubmit={onSubmit}
      errorMessage={errorMessage}
      testId="dynamic-view-edit-form"
    >
      <>
        <Box
          gap="medium"
          align="center"
          direction="row"
          justify="between"
          pad={{ bottom: 'medium' }}
          margin={{ bottom: 'small', top: 'large' }}
          border={{ side: 'bottom', color: 'border-weak' }}
        >
          <Typography
            level="2"
            type="heading"
            testId="dynamic-view-edit-role-form-title"
          >
            {t('details')}
          </Typography>
          <VisibilityWrapper
            hideFor={{
              account: ['TENANT']
            }}
            rbac={{
              resource: '/ccs/authorization',
              permission: 'ccs.authorization.edit'
            }}
          >
            <Box justify="start" direction="row" gap="small">
              {isEditable && (
                <Button
                  secondary
                  onClick={onEdit}
                  testId="edit-btn"
                  label={t('common:edit')}
                />
              )}
            </Box>
          </VisibilityWrapper>
        </Box>
        <Box direction="column">
          <NameValueList pairProps={{ direction: 'column' }}>
            {detailsFields.map((field) =>
              readonly || field.disabled ? (
                <RoleDynamicViewField
                  field={field}
                  key={field.name}
                  readonly={readonly}
                  value={
                    field?.name === 'application_name' &&
                    state?.application_id ===
                      '00000000-0000-0000-0000-000000000000'
                      ? t('common:platform_application_brand_name')
                      : state[field?.name]
                  }
                  logo={
                    field.name === 'application_name' &&
                    (state.application_id ===
                    '00000000-0000-0000-0000-000000000000' ? (
                      <Hpe color="brand" size="small" />
                    ) : (
                      state.application_logo !== null && (
                        <Logo
                          size="xxsmall"
                          testId="application_logo"
                          url={isCoP() ? ArubaImage : state?.application_logo}
                        />
                      )
                    ))
                  }
                />
              ) : (
                <RoleDynamicFormField
                  field={field}
                  key={field.name}
                  value={state[field.name] || ''}
                  onChange={onFieldChange}
                />
              )
            )}
          </NameValueList>
        </Box>
        {!readonly && (
          <Box
            direction="row"
            justify="start"
            gap="small"
            margin={{ top: 'small' }}
          >
            <ButtonGroup
              buttonList={[
                {
                  id: 1,
                  label: t('manage:save_changes'),
                  primary: true,
                  testId: 'roles-edit-save-btn',
                  type: 'submit'
                },
                {
                  id: 2,
                  label: t('manage:cancel'),
                  default: true,
                  testId: 'scope-edit-cancel-btn',
                  type: 'reset'
                }
              ]}
              testId="two-buttons"
            />
          </Box>
        )}
      </>
    </CCSForm>
  )
}

RoleDynamicViewEditForm.propTypes = {
  detailsFields: PropTypes.array,
  values: PropTypes.object,
  readonly: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEditable: PropTypes.bool
}

RoleDynamicViewEditForm.defaultProps = {
  detailsFields: [],
  values: {},
  readonly: false,
  isEditable: false
}

export default RoleDynamicViewEditForm
