import React from 'react'
import { Box, Text } from 'grommet'
import { StatusGood } from 'grommet-icons'
import { useHistory } from 'react-router-dom'

import { Button, Typography } from '../../../components'
import { Layout } from '../../commoncomponents/layout/Layout'

const ResetPasswordSuccess = () => {
  const history = useHistory()

  return (
    <Box align="center" justify="center">
      <Box width="medium">
        <StatusGood size="large" />
        <Typography
          type="heading"
          level="1"
          margin={{ top: 'medium', bottom: 'small' }}
          testId="reset-password-header"
        >
          Password Reset Successful
        </Typography>
        <Typography
          type="text"
          size="xlarge"
          margin={{ bottom: 'medium' }}
          testId="reset-password-subheader"
        >
          Your password has been reset successfully. Use your new password to
          sign in to HPE GreenLake.
        </Typography>
        <Button
          primary
          label={
            <Box pad={{ vertical: 'xsmall' }}>
              <Text size="xlarge" weight="bold">
                Sign In to HPE GreenLake
              </Text>
            </Box>
          }
          fill="horizontal"
          size="large"
          margin={{ vertical: 'small' }}
          onClick={() => {
            history.push('/')
          }}
          testId="sign-in-button"
        />
      </Box>
    </Box>
  )
}

const ResetPasswordSuccessPage = () => {
  return (
    <Layout hideHeaderOptions={['no-header']}>
      <ResetPasswordSuccess />
    </Layout>
  )
}
export default ResetPasswordSuccessPage
