import React, { useState } from 'react'
import { Box, CheckBoxGroup, Layer } from 'grommet'
import { CircleInformation } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'

import {
  ButtonGroup,
  CCSForm,
  FormOverallAlert,
  Loader,
  Typography
} from '../../../../components'
import { getErrorMessage, put } from '../../../../utils/api-utils'
import { targetMap } from '../../../ccs-manager/pages/notifications/constants'
import { getStatusIdentifiers } from '../../../ccs-manager/pages/notifications/utils'

const EditPreferenceModal = ({
  appName,
  setEditPreferenceModal,
  selectedRegions,
  selectedStatus,
  allRegions,
  onSuccess,
  setPreferenceAPIError
}) => {
  const [regions, setRegions] = useState(selectedRegions)
  const [status, setStatus] = useState(selectedStatus)
  const [errorMessage, setErrorMessage] = useState('')
  const [statusError, setStatusError] = useState(false)
  const [regionError, setRegionError] = useState(false)
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage', 'common'])
  const [onSubmit, setOnSubmit] = useState(!allRegions?.length)
  const [loader, setLoader] = useState(false)
  const [messageString, setMessageString] = useState('')
  const [reset, setReset] = useState(false)
  const [showWarning, setShowWarning] = useState(false)

  const updatePreferences = (regionList, statusList) => {
    setShowWarning(false)
    setOnSubmit(true)
    setRegionError(false)
    setStatusError(false)
    setLoader(true)

    const requestBody = {
      email: {
        region: regionList,
        status: statusList
      }
    }
    put(
      `/notifications-svc/ui/v1alpha1/preferences/${appName}`,
      requestBody,
      oidcUser.access_token
    ).then(
      () => {
        setLoader(false)
        setOnSubmit(false)
        setEditPreferenceModal(false)
        onSuccess()
      },
      (error) => {
        setLoader(false)
        setOnSubmit(false)
        setErrorMessage(getErrorMessage(error, t))
        setPreferenceAPIError(getErrorMessage(error, t))
        setEditPreferenceModal(false)
      }
    )
  }

  const validate = () => {
    const msg = allRegions?.length
      ? t('notification.preferences.region_selection_error')
      : t('notification.preferences.no_regions_found_error')
    if (!status?.length) {
      setStatusError(true)
    } else {
      setStatusError(false)
    }
    if (!regions?.length) {
      setMessageString(msg)
      setRegionError(true)
    } else {
      setRegionError(false)
    }
  }

  const confirmAction = () => {
    if (reset) {
      // update only when both regions and status are empty otherwise validate
      if (!regions?.length && !status?.length) {
        updatePreferences(regions, status)
      } else {
        validate()
      }
      setReset(false)
    } else if (
      selectedStatus?.length &&
      selectedRegions?.length &&
      !status?.length &&
      !regions?.length
    ) {
      // if manually regions and status are cleared, allow user to update
      updatePreferences(regions, status)
    } else {
      validate()
      if (status?.length && regions?.length) {
        const statusList = getStatusIdentifiers(status)
        updatePreferences(regions, statusList)
      }
    }
  }

  const clearPreferences = () => {
    if (regions?.length || status?.length) {
      setRegions([])
      setStatus([])
      setReset(true)
      setShowWarning(true)
    }
  }

  const handleCancel = () => {
    setEditPreferenceModal(false)
  }

  return (
    <Layer full="vertical" position="right">
      <Box width="medium" direction="column" pad="medium" gap="medium">
        <Box gap="xsmall">
          <Box pad={{ bottom: 'small' }}>
            <Typography type="heading" testId="edit-preferences-title">
              {targetMap[appName]}
            </Typography>
            <Typography
              type="paragraph"
              size="small"
              testId="add-exception-subtitle"
            >
              {t('notification.preferences.edit_description')}
            </Typography>
          </Box>
          <Box pad={{ top: 'small' }}>
            <Typography
              level="3"
              type="heading"
              testId="notification-preferences-page-title"
            >
              {t('notification.preferences.email_label')}
            </Typography>
          </Box>
        </Box>
        <Box flex overflow="auto" pad="xsmall">
          <CCSForm errorMessage={errorMessage} testId="add-exception-form">
            <Box
              margin={{ top: 'xxsmall', bottom: 'xxsmall' }}
              direction="column"
              justify="between"
              gap="small"
            >
              <Box gap="xsmall">
                <Typography
                  type="text"
                  size="small"
                  color="black"
                  weight="bold"
                >
                  {t('notification.preferences.regions_email')}
                </Typography>
                <Typography type="paragraph" size="small" color="black">
                  {t('notification.preferences.region_select_description')}
                </Typography>
              </Box>
              {allRegions?.length ? (
                <Box border={{ size: 'xsmall', style: 'solid' }} round="xsmall">
                  <CheckBoxGroup
                    value={regions}
                    onChange={(event) => {
                      setRegions(event.value)
                      setShowWarning(true)
                      setReset(false)
                    }}
                    options={allRegions}
                    data-testid="checkbox-group-pref-regions"
                  />
                </Box>
              ) : (
                <FormOverallAlert
                  message={t('notification.preferences.no_regions_found_error')}
                />
              )}
              {regionError && <FormOverallAlert message={messageString} />}
              <Box gap="xsmall">
                <Typography
                  type="text"
                  size="small"
                  color="black"
                  weight="bold"
                >
                  {t('notification.preferences.status_email')}
                </Typography>
                <Typography type="paragraph" size="small" color="black">
                  {t('notification.preferences.status_select_description')}
                </Typography>
              </Box>
              <Box border={{ size: 'xsmall', style: 'solid' }} round="xsmall">
                <CheckBoxGroup
                  labelKey="label"
                  valueKey="label"
                  value={status}
                  onChange={({ value: nextValue }) => {
                    setStatus(nextValue)
                    setShowWarning(true)
                    setReset(false)
                  }}
                  options={[
                    { label: 'Critical' },
                    { label: 'Warning' },
                    { label: 'Ok' },
                    { label: 'Information' }
                  ]}
                  data-testid="checkbox-group-pref-status"
                />
              </Box>
              {statusError && (
                <FormOverallAlert
                  message={t('notification.preferences.status_selection_error')}
                />
              )}
              {showWarning && (
                <Box
                  direction="row"
                  gap="xsmall"
                  pad="small"
                  background="#FFBC443D"
                >
                  <CircleInformation color="black" />
                  <Typography type="paragraph" size="small" color="black">
                    {t('notification.preferences.update_warning')}
                  </Typography>
                </Box>
              )}
              <ButtonGroup
                buttonList={[
                  {
                    id: 1,
                    label: t('save'),
                    primary: true,
                    type: 'submit',
                    testId: 'save-app-preference-btn',
                    disabled: onSubmit,
                    onClick: confirmAction
                  },
                  {
                    id: 2,
                    label: t('notification.preferences.reset'),
                    secondary: true,
                    type: 'submit',
                    testId: 'reset-app-preference-btn',
                    onClick: clearPreferences
                  },
                  {
                    id: 3,
                    label: t('cancel'),
                    default: true,
                    testId: 'cancel-preference-btn',
                    type: 'reset',
                    onClick: handleCancel
                  }
                ]}
                testId="two-buttons"
                justifyGroup="start"
              />
              {loader && (
                <Box justify="center" align="center">
                  <Loader testId="update-preference-loader" />
                </Box>
              )}
            </Box>
          </CCSForm>
        </Box>
      </Box>
    </Layer>
  )
}

EditPreferenceModal.propTypes = {
  setEditPreferenceModal: PropTypes.func.isRequired,
  appName: PropTypes.string.isRequired,
  selectedRegions: PropTypes.array.isRequired,
  selectedStatus: PropTypes.array.isRequired,
  allRegions: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setPreferenceAPIError: PropTypes.func.isRequired
}

export { EditPreferenceModal }
