// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes, { string } from 'prop-types'
import { Box, Grid } from 'grommet'
import { useHistory } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Trans, useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import lodashGet from 'lodash/get'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Typography,
  Button,
  Pagination,
  Ruler,
  Search,
  Dropdown,
  Loader
} from '../../components'
import { CCSActions, useCCSContext } from '../../context/ccs-context'
import useLD from '../../hooks/ld/useLD'
import { useVisibilityContext } from '../../context/visibility-context'
import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'
import LazyLoading from '../commoncomponents/LazyLoading/LazyLoading'
import { get } from '../../utils/api-utils'
import {
  getPaginationShowIdx,
  getWorkspaceString,
  WKSPC,
  WKSPC_PLURAL_CAPITALIZED,
  WKSPC_CAPITALIZED
} from '../../utils/common-utils'
import {
  loadCustomerAccount,
  useLoadCustomerAccount
} from '../../utils/account-utils'
import { Layout } from '../commoncomponents/layout/Layout'
import RplError from '../commoncomponents/error/RplError'
import {
  getCustomerAccount,
  getMSPCustomerAccount
} from '../../utils/feature-flag-utils'
import useLogger from '../../hooks/logs/useLogger'
import { GTS_SUPPORT_URL, SUPPORT_URL } from '../../utils/error-utils'
import { displayApiError } from '../../utils/error-handling-utils'
import { useUPSContext } from '../../context/ups-context'
import { V2_LOGGER } from '../../utils/log-util'
import GtsInfo from '../commoncomponents/error/GtsInfo'
import {
  GTS_STATUS_ENUM,
  hashFunction,
  WORKSPACE_STATUS_ENUM
} from '../acct-onboarding/utils'
import { SwapMSPNavigation } from '../acct-onboarding/choose-account/components/SwapMSPNavigation'

import { AccountCard } from './account-card'

const SwitchAccountScreen = ({
  isChooseAcctCardLayout,
  userEmail,
  ignoreCurrentWorkspace,
  isV1SessionEstablished
}) => {
  const { dispatchVisibilityContext } = useVisibilityContext()
  const { dispatchUPSContext } = useUPSContext()
  const { dispatchCCSContext } = useCCSContext()
  const { oidcUser } = useReactOidc()
  const history = useHistory()
  const [actionStatus, setActionStatus] = useState({})
  const LDFlags = useFlags()
  const ld = useLD()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const isSwitchAccountPage =
    window.location.pathname.startsWith('/switch-account')
  const itemsPerPage = 12
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState('RECENT_ACCESSED')
  const [filter, setFilter] = useState({ account_type: '', query: '' })

  const [totalAccounts, setTotalAccounts] = useState([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState([])
  const [errorMsg, setErrorMsg] = useState(null)
  const { t } = useTranslation(['authn', 'common'])
  const [custAccToBeLoaded, setCustAccToBeLoaded] = useState(null)
  const [custAcctLoading, setCustAcctLoading] = useState(null)
  const { csrfToken } = useCCSContext()
  const logger = useLogger()

  const sortByOptions = [
    { label: t('customer_account.recent'), value: 'RECENT_ACCESSED' },
    {
      label: t('customer_account.alphabetical'),
      value: 'ALPHABETIC'
    }
  ]
  const accountTypeOptions = [
    { label: t('notifications.all_label'), value: '' },
    { label: t('account_type.mode_standard_short'), value: 'STANDALONE' },
    { label: t('account_type.mode_msp_short'), value: 'MSP' }
  ]
  const pageIdxInfo = getPaginationShowIdx(page, total, itemsPerPage, t)

  const getAccounts = useCallback(
    (filters, pages, sortByAccounts) => {
      if (isV1SessionEstablished || isSwitchAccountPage) {
        setLoading(true)
        const offset = (pages - 1) * itemsPerPage
        let url = `/accounts/ui/v1/customer/list-accounts?sort_by=${sortByAccounts}&offset=${offset}&count_per_page=${itemsPerPage}`
        // if ignoreCurrentWorkspace is false, add the parameter &ignore_current_account, otherwise, do not pass this param
        if (!ignoreCurrentWorkspace) {
          url += '&ignore_current_account=false'
        }
        if (!isEmpty(filters.account_type)) {
          url += `&account_type=${filters.account_type}`
        }
        if (!isEmpty(filters.query)) {
          url += `&search_string=${filters.query}`
        }

        get(url, {}, oidcUser.access_token).then(
          (response) => {
            if (response.data.pagination) {
              setTotal(response.data.pagination.total_count)
            } else {
              setTotal(response.data.customers.length)
            }
            setTotalAccounts(response.data.customers)
            setLoading(false)
          },
          (error) => {
            setLoading(false)
            setErrorMsg(displayApiError(error, t, setErrorMsg))
          }
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      itemsPerPage,
      setTotalAccounts,
      oidcUser.access_token,
      isV1SessionEstablished
    ]
  )

  useEffect(() => {
    getAccounts({ account_type: '', query: '' }, 1, 'RECENT_ACCESSED')
  }, [getAccounts])

  const debouncedSearchAccounts = debounce((filterObject) => {
    setPage(1)
    getAccounts(filterObject, 1, sortBy)
  }, 500)

  const handleFilterChange = (name, value) => {
    // Limit the search term to only 75 characters.  This will prevent the UI from crashing on large unneccessary inputs
    const updatedFilter = { ...filter, [name]: value }
    setFilter(updatedFilter)
    debouncedSearchAccounts(updatedFilter)
  }

  const onSortChange = ({ value }) => {
    setSortBy(value)
    getAccounts(filter, page, value)
  }
  const onPageChange = (event) => {
    setPage(event.page)
    getAccounts(filter, event.page, sortBy)
  }
  const handleRoute = () => {
    if (oidcUser?.profile?.email && getCustomerAccount()) {
      hashFunction(oidcUser?.profile?.email).then(
        (encryptedEmail) => {
          ld.update({ platform_customer_id: encryptedEmail }).then(
            (LDFlagsResolved) => {
              dispatchCCSContext({
                type: CCSActions.SET_AMPLITUDE_ANALYTICS,
                data: {
                  key: 'isV2SignupCheckboxVisible',
                  value: LDFlagsResolved['glcp-iam-v2-workspace']
                }
              })
              history.push('/onboarding/set-up-account')
            }
          )
        },
        (error) => {
          console.log(error)
        }
      )
    }
    history.push('/onboarding/set-up-account')
  }

  // Moved load account error scenarios to top
  const loadAccountError = (error, account) => {
    logger?.log({
      ...V2_LOGGER,
      msg: `error while loading account`,
      type: 'error',
      data: error
    })
    setCustAccToBeLoaded(null)
    let errorInfo
    const accStatus = error?.response?.data?.account_status?.split('.')[1]
    if (
      LDFlags['glcp-gtcaas'] &&
      error?.response?.status === 423 &&
      (accStatus === GTS_STATUS_ENUM.PENDING ||
        accStatus === GTS_STATUS_ENUM.TIMEOUT)
    ) {
      errorInfo = <GtsInfo message={t('authn:gtcaas.gts_pending_message')} />
      setActionStatus({
        ...actionStatus,
        [account?.platform_customer_id]: errorInfo
      })
    } else if (error?.response?.status === 451) {
      let message
      let supportUrl
      if (lodashGet(error, 'response.data.reason') === 'IP_ACCESS_BLOCK') {
        message = error?.response?.data?.message
        supportUrl = SUPPORT_URL
      } else {
        // GTS error
        supportUrl = GTS_SUPPORT_URL
        if (
          LDFlags['glcp-gtcaas'] &&
          (accStatus === GTS_STATUS_ENUM.BLOCKED ||
            accStatus === GTS_STATUS_ENUM.CONFIRMED_BLOCKED)
        ) {
          message = t('authn:gtcaas.gts_error_message')
        } else {
          message = error?.response?.data?.message
        }
      }
      // account card to display error when account can't be launched
      errorInfo = <RplError message={message} supportUrl={supportUrl} />
      setActionStatus({
        ...actionStatus,
        [account?.platform_customer_id]: errorInfo
      })
    } else if (error?.message === WORKSPACE_STATUS_ENUM.IN_PROGRESS) {
      setActionStatus({
        ...actionStatus,
        [account?.platform_customer_id]: (
          <RplError
            message={t('authn:org_launch.wksp_configuration_in_progress')}
            supportUrl={SUPPORT_URL}
          />
        )
      })
    } else {
      setErrorMsg(displayApiError(error, t, setErrorMsg))
    }
    // stop the loading
    setCustAcctLoading(null)
  }

  // Setting custAccToBeLoaded value only when we are in Choose Account Page for Auto Login
  useEffect(() => {
    const loadedCustomerAccount = getCustomerAccount()
    if (loadedCustomerAccount && isChooseAcctCardLayout) {
      setCustAccToBeLoaded(loadedCustomerAccount)
    }
  }, [isChooseAcctCardLayout])

  // custom hook to load the customer account and redirect approriately
  const { isLoading } = useLoadCustomerAccount(
    custAccToBeLoaded,
    loadAccountError
  )
  const swappedMSPAccount = getMSPCustomerAccount()
  const custAccountLoaded = getCustomerAccount()

  return isChooseAcctCardLayout && swappedMSPAccount ? (
    <SwapMSPNavigation />
  ) : (
    <Box fill pad={{ top: 'large', left: 'xlarge', right: 'xlarge' }}>
      {isChooseAcctCardLayout ? (
        <Box width={{ max: 'large' }}>
          <Box>
            <Typography type="heading" level="1" testId="choose-account-header">
              {t('authn:choose_accounts.welcome_title')}
            </Typography>
          </Box>
          {userEmail && (
            <Box>
              <Typography
                type="text"
                size="xlarge"
                margin={{ vertical: 'medium' }}
                testId="create-account-subheader"
              >
                {showWorkspaceString ? (
                  <Trans
                    i18nKey="authn:choose_accounts.list_account_of_user_workspace"
                    t={t}
                  >
                    <b>{{ userEmail }}</b>
                  </Trans>
                ) : (
                  <Trans
                    i18nKey="authn:choose_accounts.list_account_of_user2"
                    t={t}
                  >
                    <b>{{ userEmail }}</b>
                  </Trans>
                )}
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <>
          <Box
            direction="row"
            justify="between"
            align="center"
            margin={{ right: 'xlarge' }}
          >
            <Typography
              type="heading"
              level="1"
              testId="switch-account-header"
              margin={{ bottom: 'xsmall', top: 'none' }}
            >
              {t('choose_accounts.my_accounts', {
                accounts: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_PLURAL_CAPITALIZED
                )
              })}
            </Typography>
            {/* Hide the create new workspace button for COP and Silvercreek */}
            <VisibilityWrapper hideFor={{ deployment: ['COP', 'GLOP'] }}>
              <Button
                secondary
                fill={false}
                label={t('authn:choose_accounts.create_new_account', {
                  account: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
                onClick={handleRoute}
                testId="create-new-account-button"
              />
            </VisibilityWrapper>
          </Box>
          <Typography
            type="paragraph"
            testId="switch-account-subheader"
            margin={{ bottom: 'small' }}
          >
            {t('choose_accounts.select_account_to_switch', {
              account: getWorkspaceString(showWorkspaceString, t, WKSPC)
            })}
          </Typography>
        </>
      )}
      {!isChooseAcctCardLayout && custAccountLoaded && (
        <AccountCard account={custAccountLoaded} active />
      )}

      <Box direction="row-responsive">
        <Box align="center" margin={{ top: 'large', right: 'medium' }}>
          <Search
            width="medium"
            testId="search-field"
            placeholder={t('authn:choose_accounts.search_names')}
            initValue={filter.query}
            handleCustomSearch={(value) => handleFilterChange('query', value)}
          />
        </Box>
        <Box
          align="center"
          direction="row-responsive"
          margin={{ top: 'large', right: 'medium' }}
        >
          <Typography type="text" margin={{ right: 'small' }}>
            {t('choose_accounts.account_type', {
              account: getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_CAPITALIZED
              )
            })}
          </Typography>
          <Dropdown
            name="account_type"
            options={accountTypeOptions}
            value={filter.account_type}
            onChange={({ option }) =>
              handleFilterChange('account_type', option.value)
            }
            testId="account-type-input"
          />
        </Box>
        <Box
          align="center"
          direction="row-responsive"
          margin={{ top: 'large', right: 'medium' }}
        >
          <Typography type="text" margin={{ right: 'small' }}>
            {t('customer_account.sort_by')}
          </Typography>
          <Dropdown
            name="sort_by"
            options={sortByOptions}
            value={sortBy}
            onChange={onSortChange}
            testId="sort-by-input"
          />
        </Box>
      </Box>
      <Box margin={{ top: 'xsmall', bottom: 'medium' }}>
        <Typography type="text" testId="total-accounts">
          <Trans i18nKey="authn:choose_accounts.total_accounts" t={t}>
            {{
              accounts: getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_PLURAL_CAPITALIZED
              )
            }}
            <b>{{ total }}</b>
          </Trans>
        </Typography>
      </Box>

      {!loading ? (
        <>
          <Box pad="xsmall">
            <Grid columns="medium" gap="small">
              {totalAccounts?.map((account) => (
                <AccountCard
                  actionStatus={actionStatus[account.platform_customer_id]}
                  key={account.platform_customer_id}
                  account={account}
                  isLoading={
                    custAcctLoading === account?.platform_customer_id ||
                    isLoading
                  }
                  onLaunch={() => {
                    setCustAcctLoading(account?.platform_customer_id)
                    sessionStorage.removeItem('orgLaunchInfo')
                    loadCustomerAccount(
                      account,
                      oidcUser,
                      dispatchVisibilityContext,
                      history,
                      csrfToken,
                      dispatchCCSContext,
                      ld,
                      dispatchUPSContext,
                      logger
                    ).then(
                      () => {
                        ld.update(account)
                      },
                      (error) => {
                        loadAccountError(error, account)
                      }
                    )
                  }}
                />
              ))}
            </Grid>
          </Box>
        </>
      ) : (
        <Box
          fill
          align="center"
          justify="center"
          direction="row"
          pad="large"
          gap="small"
          background={{
            color: 'background-front',
            opacity: 'strong'
          }}
        >
          <LazyLoading />
        </Box>
      )}
      {total > itemsPerPage && (
        <Box>
          <Ruler margin={{ top: 'large', bottom: 'small' }} />
          <Pagination
            totalItems={total}
            setPage={onPageChange}
            testId="pagination-switch-account"
            margin={{ bottom: isChooseAcctCardLayout ? 'none' : 'xlarge' }}
            itemsPerPage={itemsPerPage}
            pageIdxInfo={pageIdxInfo}
          />
        </Box>
      )}
      {errorMsg}
      {custAcctLoading && (
        <Loader
          modal
          testId="loader-modal"
          size="xxsmall"
          modalTitle={t('common:launching')}
          modalSubTitle={t('common:loader_dialog_message')}
          modalTimeout={1000}
        />
      )}
    </Box>
  )
}

SwitchAccountScreen.propTypes = {
  isChooseAcctCardLayout: PropTypes.bool,
  userEmail: PropTypes.string,
  ignoreCurrentWorkspace: PropTypes.bool,
  isV1SessionEstablished: PropTypes.bool
}

SwitchAccountScreen.defaultProps = {
  isChooseAcctCardLayout: false,
  userEmail: '',
  ignoreCurrentWorkspace: true,
  isV1SessionEstablished: false
}

const SwitchAccountPage = ({
  hideHeaderOptions,
  isChooseAcctCardLayout,
  userEmail,
  ignoreCurrentWorkspace = true,
  isV1SessionEstablished
}) => {
  const logger = useLogger()
  const { hideWhen } = useVisibilityContext()
  const LDFlags = useFlags()
  const { t } = useTranslation(['common'])
  const history = useHistory()
  const [apiError, setApiError] = useState(
    history?.location?.state?.apiError || null
  )
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const pageInfo = showWorkspaceString ? 'Switch Workspace' : 'Switch Account'
  const countInfo = showWorkspaceString ? 'switch-workspace' : 'switch-account'
  const pageInfoLog = {
    page: SwitchAccountPage.name || pageInfo,
    section: 'Load',
    type: 'success',
    count: countInfo
  }
  const message = showWorkspaceString
    ? 'Switch Workspace loaded successfully!'
    : 'Switch Account loaded successfully!'
  logger?.log({ ...pageInfoLog, msg: message })
  return (
    <Layout
      {...(hideWhen?.account === 'TAC'
        ? { hideHeaderOptions: ['apps'] }
        : isChooseAcctCardLayout && { hideHeaderOptions })}
    >
      {apiError?.error &&
        displayApiError(
          apiError?.error,
          t,
          setApiError,
          apiError?.customErrMsg,
          'default',
          apiError?.severity || null
        )}
      <SwitchAccountScreen
        isChooseAcctCardLayout={isChooseAcctCardLayout}
        userEmail={userEmail}
        ignoreCurrentWorkspace={ignoreCurrentWorkspace}
        isV1SessionEstablished={isV1SessionEstablished}
      />
    </Layout>
  )
}

SwitchAccountPage.propTypes = {
  hideHeaderOptions: PropTypes.arrayOf(string),
  isChooseAcctCardLayout: PropTypes.bool,
  userEmail: PropTypes.string,
  ignoreCurrentWorkspace: PropTypes.bool,
  isV1SessionEstablished: PropTypes.bool
}

SwitchAccountPage.defaultProps = {
  hideHeaderOptions: [],
  isChooseAcctCardLayout: false,
  userEmail: '',
  ignoreCurrentWorkspace: true,
  isV1SessionEstablished: false
}

export default SwitchAccountPage
