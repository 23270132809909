import { FormField, StarRating } from 'grommet'
import { useTranslation } from 'react-i18next'

const RatingField = () => {
  const { t } = useTranslation(['support_hub_feedback'])
  return (
    <FormField
      required
      name="rating"
      htmlFor="rating"
      label={t('rating_field.label')}
      data-testid="rating-field"
      contentProps={{
        border: false
      }}
    >
      <StarRating id="rating" name="rating" />
    </FormField>
  )
}

export { RatingField }
