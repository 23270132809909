import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, RadioButtonGroup, FormField } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'

import { validateForm } from '../../../../../../../utils/validation-utils'
import {
  WKSPC_PLURAL_CAPITALIZED,
  getWorkspaceString
} from '../../../../../../../utils/common-utils'

const validateFields = () => {
  return {
    isValid: true
  }
}

export const validateNotificationTypeFields = (formValues, i18nTranslate) => {
  return validateForm(formValues, i18nTranslate, validateFields)
}

const NotificationType = ({ selectedType, setSelectedType }) => {
  const { t } = useTranslation(['manage'])
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const notificationType = [
    t('notification.services'),
    getWorkspaceString(showWorkspaceString, t, WKSPC_PLURAL_CAPITALIZED),
    t('notification.email_address')
  ]
  return (
    <Box gap="medium" align="start">
      <Box width="medium" margin={{ bottom: 'xsmall' }}>
        <FormField
          margin={{ bottom: 'medium' }}
          name="notificationType"
          data-testid="notification-type-form-field"
          label={t('notification.target_type')}
          help={t('notification.target_help')}
        >
          <RadioButtonGroup
            name="notification-type-radio-button-group"
            options={notificationType}
            value={selectedType}
            onChange={(event) => setSelectedType(event.target.value)}
          />
        </FormField>
      </Box>
    </Box>
  )
}

NotificationType.prototypes = {
  setSelectedType: PropTypes.func.isRequired,
  selectedType: PropTypes.string.isRequired
}

export default NotificationType
