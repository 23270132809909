import React from 'react'
import { Markdown as GrommetMarkdown, Text } from 'grommet'

const components = {
  strong: {
    component: Text,
    props: { weight: 500 }
  }
}

export default function Markdown({ ...rest }) {
  return <GrommetMarkdown components={components} {...rest} />
}
