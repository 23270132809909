import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Markdown, Box } from 'grommet'
import { useTranslation } from 'react-i18next'

export const QuerySummary = ({ controller, onHasResultsChange }) => {
  const { t } = useTranslation('support_hub_search')
  const [state, setState] = useState(controller.state)
  const [show, setShow] = useState(false)
  let querySummary = ''

  useEffect(() => {
    const unsubscribe = controller.subscribe(() => {
      setState(controller.state)
      if (onHasResultsChange) {
        onHasResultsChange(state.hasResults)
      }
    })
    if (state.hasResults) {
      setShow(true)
    }
    return () => unsubscribe()
  }, [controller, onHasResultsChange, state.hasResults])

  const {
    hasResults,
    hasQuery,
    firstResult,
    lastResult,
    total,
    query,
    durationInSeconds
  } = state

  if (!show) {
    return null
  }

  if (!hasResults) {
    return (
      <Box direction="row" gap="xxsmall">
        <Markdown data-testid="query-summary">
          {t('no_results_summary_query', {
            query: ''
          })}
        </Markdown>
        <strong>{query}</strong>
      </Box>
    )
  }
  if (!hasQuery) {
    querySummary = t('results_summary', {
      firstResult,
      lastResult,
      total,
      durationInSeconds
    })
  } else {
    querySummary = t('results_summary_query', {
      firstResult,
      lastResult,
      total,
      query,
      durationInSeconds
    })
  }
  return <Markdown data-testid="query-summary">{querySummary}</Markdown>
}

QuerySummary.propTypes = {
  controller: PropTypes.shape({
    state: PropTypes.object.isRequired,
    subscribe: PropTypes.func.isRequired
  }).isRequired,
  onHasResultsChange: PropTypes.func.isRequired
}
