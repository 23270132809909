import React from 'react'
import { Box, Text } from 'grommet'
import { StatusCritical } from 'grommet-icons'
import { colors } from 'grommet-theme-hpe'
import { useTranslation } from 'react-i18next'

const DataError = () => {
  const { t } = useTranslation('unified_wellness')
  return (
    <Box
      pad="large"
      width="100%"
      height="100%"
      align="center"
      justify="center"
      gap="small"
      data-testid="data-error"
    >
      <StatusCritical size="large" color={colors.border.light} />
      <Text size="xlarge">{t('data_error.title')}</Text>
      <Box width="medium">
        <Text size="large" textAlign="center" color={colors.border.light}>
          {t('data_error.description')}
        </Text>
      </Box>
    </Box>
  )
}
export default DataError
