import { Box } from 'grommet'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'

/* eslint-enable */

import {
  ModalHeader,
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  Typography,
  Notification
} from '../../../../components'
import { handleToggle } from '../api-utils'

const EnableIPAccessRuleModal = ({
  setEnableIPAccessRuleModal,
  onSuccess,
  isFromCCSManger,
  customerId
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const { oidcUser } = useReactOidc()

  const handleSubmit = () => {
    const enabled = true
    const successMsg = t('ip_access_rules.enabled_succes_msg')
    handleToggle(
      enabled,
      oidcUser.access_token,
      setEnableIPAccessRuleModal,
      () => {},
      t,
      onSuccess,
      successMsg,
      isFromCCSManger,
      customerId
    )
  }

  return (
    <ModalDialog
      testId="enable-ip-access-rules-modal"
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="2" testId="enable-modal-title">
              {t('ip_access_rules.enabled_access_rules_title')}
            </Typography>
          }
        />
      }
      content={
        <Box gap="medium" margin={{ top: 'xsmall' }}>
          <Typography
            type="text"
            size="large"
            testId="enable-access-rules-desc-1"
          >
            {t('ip_access_rules.enabled_access_desc_1')}
          </Typography>
          <Box margin={{ top: 'small' }}>
            <Notification
              type="inline"
              margin={{ top: 'xsmall' }}
              backgroundColor="status-warning"
              testId="enable-ip-access-rules-warning"
              status="warning"
              text={
                <Typography type="text">
                  {t('ip_access_rules.enable_ip_access_rules_warning')}
                </Typography>
              }
            />
          </Box>
          <Typography
            type="text"
            size="medium"
            testId="enable-access-rules-desc-2"
          >
            {t('ip_access_rules.enabled_access_rules_title')}?
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('common:cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: () => {
                    setEnableIPAccessRuleModal(false)
                  }
                },
                {
                  id: 1,
                  label: t('ip_access_rules.enabled_access_rules_title'),
                  primary: true,
                  testId: 'enable-btn',
                  onClick: handleSubmit
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={() => {
        setEnableIPAccessRuleModal(false)
      }}
      width="large"
    />
  )
}

EnableIPAccessRuleModal.propTypes = {
  setEnableIPAccessRuleModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isFromCCSManger: PropTypes.bool,
  customerId: PropTypes.string
}
EnableIPAccessRuleModal.defaultProps = {
  isFromCCSManger: false,
  customerId: ''
}

export { EnableIPAccessRuleModal }
