import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { useParams } from 'react-router-dom'
import { Box, NameValueList } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { AddAddressModal } from '../../AddAddress/AddAddressModal'
import { put } from '../../../../../utils/api-utils'
import { displayApiError } from '../../../../../utils/error-handling-utils'

import { LocationAddress } from './LocationAddress'
import { SectionTitle } from './SectionTitle'

export const AddressesSection = ({
  locationDetailsData,
  setStreetAddress,
  setShippingAddress,
  streetAddress,
  shippingAddress,
  setShowErrorNotification,
  showEditAddressModal,
  setShowEditAddressModal,
  isCCSManager,
  customerId
}) => {
  const { t } = useTranslation(['location'])

  const [shippingAddressId, setShippingAddressId] = useState(null)

  const { locationId } = useParams()
  const { oidcUser } = useReactOidc()

  const updateAddressForModal = () => {
    const streetAdd = locationDetailsData.addresses.find((add) => {
      return add.type === 'street'
    })
    if (streetAdd) {
      setStreetAddress(streetAdd)
    } else {
      setStreetAddress(null)
    }
    const shippingAdd = locationDetailsData.addresses.find((add) => {
      return add.type === 'shipping_receiving'
    })
    if (shippingAdd) {
      setShippingAddress(shippingAdd)
      setShippingAddressId(shippingAdd.id)
    } else {
      setShippingAddress(null)
    }
  }

  useEffect(() => {
    updateAddressForModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = () => {
    updateAddressForModal() // discard user updates and revert to previous addresses from API
    setShowEditAddressModal(false)
  }

  const handleAddAddresses = () => {
    const addresses = []
    if (streetAddress !== null) {
      addresses.push(streetAddress)
    }
    const oldShippingAdd = locationDetailsData.addresses.find((add) => {
      return add.type === 'shipping_receiving'
    })
    if (shippingAddress === null) {
      if (locationDetailsData.addresses.length > 1) {
        // we are deleting shipping address
        addresses.push({ id: oldShippingAdd?.id, type: 'shipping_receiving' })
      }
    } else {
      shippingAddress.id = shippingAddressId
      if (
        locationDetailsData.addresses.length === 1 &&
        shippingAddress !== null
      ) {
        // this means we are creating shipping address
        delete shippingAddress.id
      } else {
        shippingAddress.id = oldShippingAdd?.id
      }
      addresses.push(shippingAddress)
    }

    const url = isCCSManager
      ? `/support-assistant/v1alpha1/location/${locationId}`
      : `/ui-doorway/ui/v1/locations/${locationId}`

    put(
      url,
      {
        name: locationDetailsData.name,
        description: locationDetailsData.description,
        type: locationDetailsData.type,
        addresses,
        ...(isCCSManager && {
          platform_customer_id: customerId
        })
      },
      oidcUser.access_token
    ).then(
      () => {
        setShowEditAddressModal(false)
      },
      (error) => {
        setShowEditAddressModal(false)
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
  }

  return (
    <Box data-testid="location-address-section">
      <SectionTitle title={t('location:addresses')} />
      <NameValueList>
        {/* Street Address */}
        {streetAddress && <LocationAddress address={streetAddress} />}
        {/* Shipping and receiving */}
        {shippingAddress && <LocationAddress address={shippingAddress} />}
      </NameValueList>

      {showEditAddressModal && (
        <AddAddressModal
          handleClose={handleClose}
          setShippingAddress={setShippingAddress}
          setStreetAddress={setStreetAddress}
          streetAddress={streetAddress}
          shippingAddress={shippingAddress}
          addAddresses={handleAddAddresses}
          isEdit
          locationName={locationDetailsData.name}
        />
      )}
    </Box>
  )
}
AddressesSection.propTypes = {
  locationDetailsData: PropTypes.object.isRequired,
  streetAddress: PropTypes.object.isRequired,
  shippingAddress: PropTypes.object,
  setStreetAddress: PropTypes.func.isRequired,
  setShippingAddress: PropTypes.func.isRequired,
  setShowErrorNotification: PropTypes.func.isRequired,
  showEditAddressModal: PropTypes.bool.isRequired,
  setShowEditAddressModal: PropTypes.func.isRequired,
  isCCSManager: PropTypes.bool,
  customerId: PropTypes.string
}

AddressesSection.defaultProps = {
  shippingAddress: null,
  customerId: null,
  isCCSManager: false
}
