// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

// Add your new feature flag here with the default value
// Make sure to keep the relevant default value which reflects in production
export const LDFeatureFlags = {
  'glcp-new-token-url': '',
  'glcp-api-client-system-jhansi': false,
  'glcp-home-asp-card': false,
  'glcp-home-asm-card': false,
  'glcp-baas': true,
  'glcp-compute-auto-subscribe': false,
  'glcp-service-catalog': false,
  'glcp-local-gateway-feature': false,
  'glcp-testing': false,
  'glcp-test-pcid-json': { pcids: [] },
  'glcp-dm-sdi': false,
  'glcp-white-list-app-provision': { appInstanceIDs: [] },
  'glcp-glc-migration': false,
  'glcp-subscriptions-expiry-sorting': false,
  'glcp-api-portal': false,
  'glcp-activate-export-csv-compression': false,
  'glcp-header-help-billing-url': false,
  'glcp-billing-accounts': false,
  'glcp-license-summary-view': false,
  'glcp-dm-hciaas': false,
  'glcp-msp-customer-table': false,
  'glcp-msp-filter': false,
  'glcp-msp-add-customer-field': false,
  'glcp-msp-sort-customer': false,
  'glcp-notifications-feature': true,
  'glcp-object-as-service': false,
  'glcp-whats-new': false,
  'glcp-manage-customer-programs': false,
  'glcp-tac-show-cak-mac': false,
  'glcp-activate-show-cak-mac': false,
  'glcp-dm-show-cak-mac': false,
  'glcp-notifications-feature-florence': false,
  'glcp-show-service-subscription-tier': false,
  'glcp-service-subscription-tiers': { serviceSubscriptionTiers: [] },
  'glcp-release-notes-card': false,
  'glcp-session-api-timeout': 0,
  'glcp-sre-manual-maintenance': false,
  'glcp-dm-msp-app-filter': false,
  'glcp-dm-claim-api-tag-integration': false,
  'glcp-contextual-help': false,
  'glcp-contextual-help-phase-two': false,
  'glcp-glasgow-sdi-ccs-manager': false,
  'glcp-switch-to-workspace': false,
  'glcp-dm-sdi-glasgow': false,
  'glcp-test-drive': false,
  'glcp-dm-attach-subscription-glasgow': false,
  'glcp-activate-enhancements-glasgow': false,
  'glcp-tac-sm-enhancement': false,
  'glcp-show-greenlake-in-api-creds': false,
  'glcp-wc-header': false,
  'glcp-service-centric-experience-phase-1': false,
  'glcp-glasgow-reportfw-devices-export': false,
  'glcp-glasgow-reportfw-subscriptions-export': false,
  'glcp-reportfw-activate-export': false,
  'glcp-reportfw-dashboard': false,
  'glcp-reportfw-dashboard-preview': false,
  'glcp-reportfw-tenants-export': false,
  'glcp-ups-phase-1': false,
  'glcp-istanbul-sa-coitenants-flag': true,
  'glcp-sa-unclaim-actions': false,
  'glcp-notifications-feature-glasgow-admin': false,
  'glcp-unified-wellness-dashboard': false,
  'glcp-opsramp-card': false,
  'glcp-notifications-feature-schedule-jhansi': false,
  'glcp-notifications-feature-filters-jhansi': false,
  'glcp-notifications-feature-alerts-tasks-kobe': false,
  'glcp-notifications-es-feature-glasgow': false,
  'glcp-notifications-account-role-target': false,
  'glcp-edit-federated-user-role': false,
  'glcp-dm-silver-peak': false,
  'glcp-notifications-approval-feature': false,
  'glcp-sdi-address-doctor': false,
  'glcp-notifications-feature-unsubscribe-helsinki': false,
  'glcp-helsinki-create-org-workflow-support-assistant': false,
  'glcp-ops-ramp': true,
  'glcp-dm-pce': false,
  'glcp-evaluation-service': false,
  'glcp-dm-uxi': false,
  'glcp-dm-view-location': false,
  'glcp-whatsnew-approval': false,
  'glcp-notifications-create-access': false,
  'glcp-service-registry': false,
  'glcp-dm-iaas': false,
  'glcp-saas-delay-activation': true,
  'glcp-notifications-pce-target': false,
  'glcp-msp-customer-own': false,
  'glcp-support-home': false,
  'glcp-support-rma': false,
  'glcp-support-feedback': false,
  'glcp-support-feedback-file-upload': false,
  'glcp-support-chat': false,
  'glcp-support-search': false,
  'glcp-support-search-data-filter': false,
  'glcp-support-search-recently-viewed': false,
  'glcp-support-search-breadcrumbs': false,
  'glcp-support-cases': false,
  'glcp-locations-tags': false,
  'glcp-contextual-help-mapping': {},
  'glcp-recommend-services': { slugs: [] },
  'glcp-msp-storage': false,
  'glcp-msp-com': false,
  'glcp-iam-v2-workspace': false,
  'glcp-notifications-feature-category-istanbul': false,
  'glcp-ipv6': false,
  'glcp-gtcaas': false,
  'glcp-subscrption-tags': false,
  'glcp-notifications-feature-edit-istanbul': false,
  'glcp-capex-subscription': false,
  'glcp-istanbul-sa-custom-role-flag': false,
  'glcp-sa-customer-roles-v2': false,
  'glcp-sm-auto-reassign': false,
  'glcp-dm-enable-default-region': false,
  'glcp-subscriptions-magic-link': false,
  'glcp-services-definition': { services: [] },
  'glcp-amplitude-analytics-istanbul': false,
  'glp-analytics-config': {
    debugMessages: false,
    allowConsentOverrides: false,
    autoAnalytics: {
      analyticsIds: ['data-testid'],
      idPatternsToTrack: ['/card-.+|-btn|-button|-card/i'],
      idPatternsToIgnore: [''],
      urlsToTrack: ['/.+/']
    },
    plugins: {
      amplitude: {
        defaultTracking: false,
        projectId: window.$settings?.aaConfig?.project_id,
        apiKey: window.$settings?.aaConfig?.api_key
      }
    }
  },
  'glcp-jit-v2': false,
  'glcp-tenant-percolation': false,
  'glcp-whatsnew-istanbul': false,
  'glcp-un-assign-location': false,
  'tenant-workspace-group': false,
  'glcp-auditlog-sort-feature': false,
  'glcp-storage-fabric-management': false,
  'glcp-notifications-feature-dynamic-app-istanbul': false,
  'glcp-eventing-webhook': false,
  'glcp-jhansi-service-detail-page-update': false,
  'glcp-saas-duplicate-warning': false,
  'glcp-service-provision': false,
  'glcp-show-central-service-in-api-creds': false,
  'glcp-red-cedar': false,
  'glcp-dynamic-eval-enablement': false,
  'glcp-edge-connect': false,
  'glcp-cds-rate-interface': false,
  'glcp-magic-link-phase2': false,
  'glcp-available-region-detail': false,
  'glcp-workspace-creation': false,
  'glcp-zto': false,
  'glcp-sa-zto': false,
  'glcp-global-search-discover': false,
  'glcp-unified-api-endpoint': '',
  'glcp-support-access-manager': false,
  'glcp-support-access-manager-phase2': false,
  'glcp-auditlog-svc-centric-arch-support': false,
  'glcp-ilo-os-hostnames': false,
  'glcp-service-pcai': false,
  'glcp-subscription-expiry': false,
  'glcp-choose-account-card-layout': false,
  'glcp-5g-bridge': false,
  'glcp-be-activate-gt-phase2': false,
  'glcp-auditlog-i18n-support': false,
  'glcp-capex-tooltip': false,
  'glcp-global-search-jhansi': false,
  'glcp-dscc-dm-sm': false,
  'glcp-customize-quick-links': false,
  'glcp-service-slugs-deep-linking': false,
  'glcp-msp-rrp': false,
  'glcp-msp-user-invite': false,
  'glcp-customize-widgets': false,
  'glcp-auditlog-reporting-framework-intg': false,
  'glcp-notifications-feature-engineering-kobe': false,
  'glcp-multi-license-support': false,
  'glcp-ld-feature-flags-view': false,
  'glcp-show-devices-support-end-date': false,
  'glcp-service-scaas': false,
  'glcp-search-administration': false,
  'glcp-service-zerto': false,
  'glcp-service-vcf': false,
  'glcp-lm-nonglp-contacts': false,
  'glcp-cds-billing-export': false,
  'glcp-pdl-as-sdi': false,
  'glcp-street-address-field': false,
  'glcp-devices-map-view': false,
  'glcp-replace-subscription': false,
  'glp-acctmgmt-ups-migrated-prefs': false,
  'glcp-enable-unclaim-and-transfer-subscriptions': false
}
