/* eslint-disable react/prop-types */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { CircleInformation } from 'grommet-icons'

import {
  Button,
  CCSForm,
  FormTextArea,
  ModalDialog,
  ModalHeader,
  Typography,
  Anchor,
  ButtonGroup
} from '../../../components'
import { getErrorMessage, post } from '../../../utils/api-utils'
import Markdown from '../../commoncomponents/Markdown'

const AddSubscription = ({
  onSetOpen,
  webhookInfo = {},
  subscriptionInfo = {},
  onToastNotification,
  setLoading
}) => {
  const { t } = useTranslation(['authz', 'common', 'automations'])
  const { oidcUser } = useReactOidc()
  const [isDisabled, setIsDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [eventTypeErrorMessage, setEventTypeErrorMessage] = useState('')
  const { id } = useParams()

  const [isOpenSubscriptionLimitDialog, setIsOpenSubscriptionLimitDialog] =
    useState(false)
  const [formValues, setFormValues] = useState({
    eventType: subscriptionInfo?.eventType
  })
  const onSuccessHandler = (successTitle, successMessage) => {
    setLoading(false)
    onToastNotification({
      title: successTitle,
      notificationMessage: successMessage,
      severity: 'info'
    })
  }
  const onErrorHandler = () => {
    setLoading(false)
  }
  const addSubscription = async () => {
    let isValid = true
    const createSuccessMessage = (
      <Markdown>
        {t('automations:webhooks.add_subs_success_msg', {
          webhookName: webhookInfo?.name
        })}
      </Markdown>
    )

    const requestBody = {
      eventType: formValues?.eventType?.trim(),
      webhook: {
        resourceUri: [`/events/v1beta1/webhooks/${id}`]
      }
    }
    if (!requestBody?.eventType?.trim()?.length) {
      setEventTypeErrorMessage(t('common:this_is_required'))
      isValid = false
    }
    if (isValid) {
      try {
        setIsDisabled(true)
        await post(
          `/events/v1beta1/subscriptions`,
          requestBody,
          oidcUser?.access_token
        ).then(() => {
          setIsDisabled(false)
          onSuccessHandler('', createSuccessMessage)
          onSetOpen(false)
        })
      } catch (error) {
        setIsDisabled(false)
        setLoading(false)
        const createErrorMessage = getErrorMessage(error, t)
        setErrorMessage(createErrorMessage)
        onErrorHandler()
      }
    }
  }
  const handleClose = () => {
    setIsOpenSubscriptionLimitDialog(false)
    onSetOpen(false)
  }

  const onCloseDialog = () => {
    onSetOpen(false)
  }

  const onChangeEventType = (event) => {
    if (event.target.value.length <= 100) {
      setEventTypeErrorMessage('')
      setFormValues({
        ...formValues,
        eventType: event?.target?.value
      })
    } else {
      setEventTypeErrorMessage(t('automations:webhooks.max_length_100_char'))
    }
  }

  return (
    <>
      <ModalDialog
        header={
          <ModalHeader
            title={
              <Typography
                type="heading"
                level="2"
                testId="events-webhook-group-side-panel-title"
              >
                {t('automations:webhooks.create_subs_panel_title')}
              </Typography>
            }
            onClose={onCloseDialog}
          />
        }
        content={
          <Box margin={{ top: 'small' }} width={{ min: 'medium', max: 'full' }}>
            <CCSForm
              value={formValues}
              messages={{ required: t('common:this_is_required') }}
              errorMessage={
                errorMessage && (
                  <Box width="auto">
                    <Typography type="text" emphasis>
                      {errorMessage}
                    </Typography>
                  </Box>
                )
              }
              validate="blur"
              onChange={setFormValues}
              onSubmit={addSubscription}
              testId="events-webhook-group-modal-form"
            >
              <Box gap="small">
                {/* Event Type Input Field */}
                <Box gap="xxsmall">
                  <Typography
                    required
                    level="6"
                    type="text"
                    testId="events-webhook-description-sub-title"
                  >
                    {t('automations:webhooks.create_subs_description_subtitle')}
                    <Anchor
                      href="https://developer.greenlake.hpe.com/docs/greenlake/standards/draft/event/event_format/"
                      target="_blank"
                    >
                      {t('automations:webhooks.greenlake_events')}
                    </Anchor>{' '}
                    {t('automations:webhooks.create_subs_description_doc')}
                  </Typography>
                </Box>
                <Typography
                  level="4"
                  type="heading"
                  testId="events-webhook-desc-title"
                >
                  {`${t('automations:webhooks.add_subs_event_type')}*`}
                </Typography>
                <Typography
                  required
                  level="6"
                  type="text"
                  testId="events-webhook-event-type-sub-title"
                >
                  {t('automations:webhooks.add_subs_event_type_desc')}
                </Typography>
                <FormTextArea
                  name="eventType"
                  error={eventTypeErrorMessage}
                  value={formValues?.eventType}
                  onChange={onChangeEventType}
                  testId="events-webhook-event-type-form-field"
                />
                <Box
                  background="background-contrast"
                  pad="small"
                  round="xsmall"
                  direction="column"
                  align="center"
                  gap="small"
                >
                  <Box direction="row" align="flex-start" gap="small">
                    <CircleInformation size="large" />
                    <Typography
                      level="4"
                      type="text"
                      testId="subscription-notification"
                    >
                      {t('automations:webhooks.subscription_notification')}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  direction="row"
                  justify="start"
                  gap="medium"
                  margin={{ top: 'small' }}
                  pad={{ top: 'medium' }}
                >
                  <ButtonGroup
                    buttonList={[
                      {
                        primary: true,
                        label: t('automations:webhooks.create_subs_btn_label'),
                        onClick: addSubscription,
                        disabled: isDisabled,
                        testId: 'add-subscription-submit-btn',
                        type: 'submit'
                      },
                      {
                        label: t('common:cancel'),
                        onClick: onCloseDialog,
                        testId: 'add-subscription-cancel-btn',
                        type: 'reset'
                      }
                    ]}
                    testId="add-subscription-button-group"
                  />
                </Box>
                <Box pad={{ bottom: 'medium' }} />
              </Box>
            </CCSForm>
          </Box>
        }
        onClose={onCloseDialog}
        position="right"
        height="100%"
        overflow="hidden"
        testId="edit-webhook-side-panel"
        width="440px"
      />
      {isOpenSubscriptionLimitDialog ? (
        <ModalDialog
          content={
            <Box>
              <Box direction="column">
                <h2 style={{ fontWeight: '500' }}>Register webhook</h2>
              </Box>
              <Box direction="column">
                {t('automations:webhooks.subs_register_limit_msg')}
              </Box>
            </Box>
          }
          footer={
            <Box style={{ display: 'flex', alignItems: 'end' }}>
              <Button
                primary
                label="Close"
                onClick={handleClose}
                testId="webhook-reach-limit-close-btn"
              />
            </Box>
          }
          onClose={handleClose}
          testId="webhook-reach-limit-modal"
          width="large"
        />
      ) : null}
    </>
  )
}
export default AddSubscription
