import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, NameValueList, NameValuePair } from 'grommet'
import { useTranslation } from 'react-i18next'
import { Hpe } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Button, Typography, Logo } from '../../../../../../../components'
import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import SelectedPermissions from '../step2/SelectedPermissions'
import { isCoP } from '../../../../../../../utils/feature-flag-utils'
import ArubaImage from '../../../../../../../images/aruba.png'
import { isServiceCentric } from '../../../../../../../utils/account-utils'

const ReviewAndCreate = ({ selectedRole }) => {
  const {
    formValues: { roleDetails },
    setActiveStep
  } = useContext(WizardContext)

  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const { t } = useTranslation(['authz', 'common'])
  const getDetailsContent = () => {
    const { appId, duplicate, originalRoleName } = roleDetails
    const { application_name, application_logo } = selectedRole
    return (
      <Box margin={{ bottom: 'large' }}>
        <Box
          direction="row"
          align="start"
          border={{ side: 'bottom', color: 'border-weak' }}
          justify="between"
          pad={{ bottom: 'small' }}
        >
          <Box>
            <Typography
              level="2"
              type="heading"
              testId="selected-permissions-title"
            >
              {t('details')}
            </Typography>
          </Box>
        </Box>
        <Box margin={{ top: 'medium' }}>
          <NameValueList>
            <NameValuePair
              name={newDashboardFlag ? t('service_manager') : t('application')}
            >
              <Box width="medium" align="start" direction="row">
                {appId === '00000000-0000-0000-0000-000000000000' ? (
                  <Hpe color="brand" size="small" />
                ) : (
                  <Logo
                    size="xxsmall"
                    url={isCoP() ? ArubaImage : application_logo}
                    testId="application-logo"
                  />
                )}
                <Typography
                  margin={{ left: 'xsmall' }}
                  size="small"
                  type="text"
                >
                  {appId === '00000000-0000-0000-0000-000000000000'
                    ? t('common:platform_application_brand_name')
                    : application_name}
                </Typography>
              </Box>
            </NameValuePair>
            {duplicate && (
              <NameValuePair name={t('roles.wiz_step3_duplicate_role')}>
                {originalRoleName}
              </NameValuePair>
            )}
          </NameValueList>
        </Box>
      </Box>
    )
  }
  const getNameDescContent = () => {
    const { name, description } = roleDetails

    return (
      <Box margin={{ bottom: 'medium' }}>
        <Box
          direction="row"
          align="start"
          border={{ side: 'bottom', color: 'border-weak' }}
          justify="between"
          pad={{ bottom: 'small' }}
        >
          <Typography
            level="2"
            type="heading"
            testId="selected-permissions-title"
          >
            {t('roles.wiz_step3_name_description')}
          </Typography>
          <Button
            aria-label={`${t('roles.wiz_modify_btn')} ${t(
              'roles.wiz_step3_name_description'
            )}`}
            testId="selected-permissions-modify-title"
            onClick={() => setActiveStep(1)}
            label={t('roles.wiz_modify_btn')}
          />
        </Box>
        <Box margin={{ top: 'medium' }}>
          <NameValueList pairProps={{ direction: 'column' }}>
            <NameValuePair name={t('name')}>{name}</NameValuePair>
            <NameValuePair name={t('description')}>
              {description.trim() === '' ? '--' : description}
            </NameValuePair>
          </NameValueList>
        </Box>
      </Box>
    )
  }

  const getPermissionsContent = () => {
    const { appId } = roleDetails
    return (
      <Box margin={{ bottom: 'large' }}>
        <Box
          direction="row"
          align="start"
          border={{ side: 'bottom', color: 'border-weak' }}
          justify="between"
          pad={{ bottom: 'small' }}
        >
          <Box>
            <Typography
              level="2"
              type="heading"
              testId="selected-permissions-title"
            >
              {t('assignments.permission_plural')}
            </Typography>
          </Box>
          <Button
            aria-label={`${t('roles.wiz_modify_btn')} ${t(
              'assignments.permission_plural'
            )}`}
            testId="selected-permissions-modify-title"
            onClick={() => setActiveStep(2)}
            label={t('roles.wiz_modify_btn')}
          />
        </Box>
        <Box margin={{ top: 'medium' }}>
          <SelectedPermissions
            application={appId}
            selectedRole={selectedRole}
          />
        </Box>
      </Box>
    )
  }

  return (
    <>
      {getDetailsContent()}

      {getNameDescContent()}

      {getPermissionsContent()}
    </>
  )
}

export default ReviewAndCreate

ReviewAndCreate.propTypes = {
  selectedRole: PropTypes.any
}

ReviewAndCreate.defaultProps = {
  selectedRole: {}
}
