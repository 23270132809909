export const SUPPORT_URL =
  'https://fs20.formsite.com/rpHc62/q1a0stqcjx/index.html'

export const GTS_SUPPORT_URL =
  'https://h41390.www4.hpe.com/support/index.html?form=globaltrade'

export const GLP_ONBOARD_SUPPORT_URL =
  'https://h41390.www4.hpe.com/support/index.html?form=greenlakesupport&issue=wuo'
export const GREENLAKE_SUPPORT_URL =
  'https://h41390.www4.hpe.com/support/index.html?form=greenlakesupport'
