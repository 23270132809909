/* eslint-disable react/prop-types,default-case,no-else-return */
import { useEffect } from 'react'

export const useBodyScroll = (letBodyScroll) =>
  useEffect(() => {
    if (!letBodyScroll) {
      document.body.style.setProperty('overflow-y', 'hidden')
    } else {
      document.body.style.removeProperty('overflow-y')
    }
  }, [letBodyScroll])
