// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import PropTypes, { object } from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { Box, Grid } from 'grommet'
import { Organization, MailOption, LinkNext, Logout } from 'grommet-icons'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { debounce } from 'lodash'
import lodashGet from 'lodash/get'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Button,
  Typography,
  Tile,
  Pagination,
  Ruler,
  Search,
  Card
} from '../../../components'
import { Layout } from '../../commoncomponents/layout/Layout'
import RplError from '../../commoncomponents/error/RplError'
import {
  getNumCustomerAccounts,
  getCustomerAccount,
  getMSPCustomerAccount,
  isGLOP,
  isCoP
} from '../../../utils/feature-flag-utils'
import {
  getPaginationShowIdx,
  getWorkspaceString,
  WKSPC,
  WKSPC_CAPITALIZED,
  WKSPC_PLURAL,
  WKSPC_PLURAL_CAPITALIZED
} from '../../../utils/common-utils'
import { AccountLogo } from '../../commoncomponents/account-logo/AccountLogo'
import Markdown from '../../commoncomponents/Markdown'
import useLogger from '../../../hooks/logs/useLogger'
import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'
import { GTS_SUPPORT_URL, SUPPORT_URL } from '../../../utils/error-utils'
import AccountsUnavailableError from '../../commoncomponents/error/AccountsUnavailableError'
import LoadAccountError from '../../commoncomponents/error/LoadAccountError'
import { useLoadCustomerAccount } from '../../../utils/account-utils'
import { get, getErrorMessage } from '../../../utils/api-utils'
import GtsInfo from '../../commoncomponents/error/GtsInfo'
import { displayApiError } from '../../../utils/error-handling-utils'
import { V2_LOGGER } from '../../../utils/log-util'
import { GTS_STATUS_ENUM, WORKSPACE_STATUS_ENUM, hashFunction } from '../utils'
import SwitchAccountPage from '../../switch-account/switch-account'
import useLD from '../../../hooks/ld/useLD'
import { CCSActions, useCCSContext } from '../../../context/ccs-context'

import { SwapMSPNavigation } from './components/SwapMSPNavigation'

const StyledTitle = styled(Typography)`
  white-space: pre-wrap;
`

const ChooseAccountListSearch = ({
  customerAccounts,
  onFiltered,
  isDisabled
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const [searchValue, setSearchValue] = useState('')
  const [filteredAccounts, setFilteredAccounts] = useState(customerAccounts)
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const numOfCustomerAccounts = getNumCustomerAccounts()
  const total = filteredAccounts?.length || 0

  useEffect(() => {
    setFilteredAccounts(customerAccounts)
  }, [customerAccounts])

  const debouncedSearchData = debounce((searchStr, setAccounts) => {
    if (customerAccounts) {
      const accounts = customerAccounts.filter((item) =>
        item.company_name.toLowerCase().includes(searchStr.toLowerCase())
      )
      setAccounts(accounts)
    }
  }, 500)

  const handleSearchInput = (value) => {
    // Limit the search term to only 75 characters.  This will prevent the UI from crashing on large unneccessary inputs
    const searchInput = value.length > 75 ? value.substring(0, 75) : value
    setSearchValue(searchInput)
    debouncedSearchData(searchInput, (accounts) => {
      setFilteredAccounts(accounts)
      onFiltered(accounts)
    })
  }

  return (
    <Box direction="column" width={{ max: 'large' }}>
      <Search
        width="large"
        value={searchValue}
        handleCustomSearch={handleSearchInput}
        placeholder={t('authn:choose_accounts.search_accounts', {
          accounts: getWorkspaceString(showWorkspaceString, t, WKSPC_PLURAL)
        })}
        testId="accounts-search-box"
        disabled={isDisabled}
      />

      {numOfCustomerAccounts !== null && (
        <Typography type="text" size="small" testId="customer-accounts-count">
          <Trans i18nKey="authn:choose_accounts.total_accounts" t={t}>
            {{
              accounts: getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_PLURAL_CAPITALIZED
              )
            }}
            <b>{{ total }}</b>
          </Trans>
        </Typography>
      )}
    </Box>
  )
}

ChooseAccountListSearch.propTypes = {
  customerAccounts: PropTypes.arrayOf(object),
  onFiltered: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
}

ChooseAccountListSearch.defaultProps = {
  customerAccounts: null,
  isDisabled: false
}

const BackToSignIn = () => {
  const { t } = useTranslation(['authn', 'common'])
  const history = useHistory()
  const LDFlags = useFlags()
  const isChooseAcctCardLayout = LDFlags['glcp-choose-account-card-layout']
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const handleBackToSignIn = (e) => {
    e.preventDefault()
    history.push('/sign-out')
  }

  return (
    <>
      {isChooseAcctCardLayout ? (
        // show new card layout consistent with switch account page layout
        <Box
          width={{ max: 'medium' }}
          margin={{ top: 'small', bottom: 'large' }}
        >
          <Card
            testId="account-fallback-tile"
            icon={<Logout size="large" />}
            elevation="none"
            background="background-contrast"
            title={
              <StyledTitle
                testId="account-fallback-title"
                type="heading"
                level={3}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {t('authn:choose_accounts.dont_see_your_account', {
                  account: getWorkspaceString(showWorkspaceString, t, WKSPC)
                })}
              </StyledTitle>
            }
            subtitle={t('authn:choose_accounts.error_back_to_sign_in')}
            action={
              <Button
                label={t('authn:choose_accounts.back_to_sign_in')}
                testId="back-to-sign-in-btn"
                icon={<LinkNext />}
                reverse
                onClick={handleBackToSignIn}
              />
            }
          />
        </Box>
      ) : (
        <Box
          width={{ max: 'large' }}
          margin={{ bottom: 'small', top: 'large' }}
        >
          <Tile
            pad="medium"
            background="background-contrast"
            actionBtn={
              <Button
                label={t('authn:choose_accounts.back_to_sign_in')}
                testId="back-to-sign-in-btn"
                icon={<LinkNext />}
                reverse
                onClick={handleBackToSignIn}
              />
            }
            layout="row"
            logo={<MailOption color="#6633BC" size="large" />}
            subTitle={t('authn:choose_accounts.error_back_to_sign_in')}
            title={
              <StyledTitle
                testId="account-fallback-title"
                type="heading"
                level={3}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {t('authn:choose_accounts.dont_see_your_account', {
                  account: getWorkspaceString(showWorkspaceString, t, WKSPC)
                })}
              </StyledTitle>
            }
            testId="account-fallback-tile"
          />
        </Box>
      )}
    </>
  )
}

const CreateAccount = () => {
  const { t } = useTranslation(['authn', 'common'])
  const history = useHistory()
  const LDFlags = useFlags()
  const { dispatchCCSContext } = useCCSContext()
  const isChooseAcctCardLayout = LDFlags['glcp-choose-account-card-layout']
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const glcpIAMV2WorkspaceFlag = LDFlags['glcp-iam-v2-workspace']

  const handleCreateAccount = (e) => {
    e.preventDefault()
    dispatchCCSContext({
      type: CCSActions.SET_AMPLITUDE_ANALYTICS,
      data: {
        key: 'isV2SignupCheckboxVisible',
        value: glcpIAMV2WorkspaceFlag
      }
    })
    history.push('/onboarding/set-up-account')
  }

  return isChooseAcctCardLayout ? (
    // show new card layout consistent with switch account page layout
    <Box width={{ max: 'medium' }} margin={{ top: 'small', bottom: 'large' }}>
      <Card
        testId="create-new-account-tile"
        icon={<Organization size="large" />}
        elevation="none"
        background="background-contrast"
        title={
          <StyledTitle
            testId="create-new-account-title"
            type="heading"
            level="2"
          >
            {t('authn:choose_accounts.create_a_new_account', {
              account: getWorkspaceString(showWorkspaceString, t, WKSPC)
            })}
          </StyledTitle>
        }
        subtitle={t('authn:choose_accounts.new_greenlake_account', {
          account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
        })}
        action={
          <Button
            label={t('authn:customer_account.createAccount', {
              account: getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_CAPITALIZED
              )
            })}
            testId="create-account-button"
            icon={<LinkNext />}
            reverse
            onClick={handleCreateAccount}
          />
        }
      />
    </Box>
  ) : (
    <Box width={{ max: 'large' }} margin={{ top: 'small', bottom: 'large' }}>
      <Tile
        pad="medium"
        background="background-contrast"
        actionBtn={
          <Button
            label={t('authn:customer_account.createAccount', {
              account: getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_CAPITALIZED
              )
            })}
            testId="create-account-button"
            icon={<LinkNext />}
            reverse
            onClick={handleCreateAccount}
          />
        }
        layout="row"
        logo={<Organization color="#00739D" size="large" />}
        subTitle={t('authn:choose_accounts.new_greenlake_account', {
          account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
        })}
        title={
          <StyledTitle
            testId="create-new-account-title"
            type="heading"
            level="2"
          >
            {t('authn:choose_accounts.create_a_new_account', {
              account: getWorkspaceString(showWorkspaceString, t, WKSPC)
            })}
          </StyledTitle>
        }
        testId="create-new-account-tile"
      />
    </Box>
  )
}

const ChooseAccountListHeading = () => {
  const { t } = useTranslation(['authn', 'common'])
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const userEmail = oidcUser.profile.email

  return (
    <Box width={{ max: 'large' }}>
      <Box>
        <Typography type="heading" level="1" testId="choose-account-header">
          {t('authn:choose_accounts.welcome_title')}
        </Typography>
      </Box>
      {userEmail && (
        <Box>
          <Typography
            type="text"
            size="xlarge"
            margin={{ vertical: 'medium' }}
            testId="create-account-subheader"
          >
            {showWorkspaceString || isGLOP() ? (
              <Trans
                i18nKey="authn:choose_accounts.list_account_of_user_workspace"
                t={t}
              >
                <b>{{ userEmail }}</b>
              </Trans>
            ) : (
              <Trans
                i18nKey="authn:choose_accounts.list_account_of_user2"
                t={t}
              >
                <b>{{ userEmail }}</b>
              </Trans>
            )}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

const ChooseAccountList = ({ customerAccounts, customerAccountsError }) => {
  const { t } = useTranslation(['authn', 'common'])
  const itemsPerPage = 5
  const { oidcUser } = useReactOidc()
  const [filteredAccounts, setFilteredAccounts] = useState(customerAccounts)
  const [displayedAccounts, setDisplayedAccounts] = useState(null)
  const [page, setPage] = useState(1)
  const [actionStatus, setActionStatus] = useState({})
  const [custAccToBeLoaded, setCustAccToBeLoaded] = useState(null)
  const [selectedCustAcct, setSelectedCustAcct] = useState(null)
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const numOfCustomerAccounts = getNumCustomerAccounts()
  const logger = useLogger()
  const v2Logger = {
    ...V2_LOGGER,
    page: ChooseAccountList?.name,
    section: 'account-launch',
    type: 'error'
  }

  useEffect(() => {
    setFilteredAccounts(customerAccounts)
    const custAccountLoaded = getCustomerAccount()
    if (custAccountLoaded) {
      setCustAccToBeLoaded(custAccountLoaded)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerAccounts])

  useEffect(() => {
    const startRow = (page - 1) * itemsPerPage
    let endRow = startRow + itemsPerPage
    if (endRow > (filteredAccounts?.length || 0)) {
      endRow = filteredAccounts?.length || 0
    }
    setDisplayedAccounts(filteredAccounts?.slice(startRow, endRow))
  }, [page, customerAccounts, filteredAccounts])

  const onLoadAccountError = (error) => {
    setCustAccToBeLoaded(null)
    let errorInfo
    const accStatus = error?.response?.data?.account_status?.split('.')[1]
    if (
      LDFlags['glcp-gtcaas'] &&
      error?.response?.status === 423 &&
      (accStatus === GTS_STATUS_ENUM.PENDING ||
        accStatus === GTS_STATUS_ENUM.TIMEOUT)
    ) {
      // Error Code 423 means GTS checks are still pending and user cannot load the account until GTS checks are successful.
      errorInfo = <GtsInfo message={t('authn:gtcaas.gts_pending_message')} />
      setActionStatus({
        ...actionStatus,
        [custAccToBeLoaded?.platform_customer_id]: errorInfo
      })
    } else if (error?.response?.status === 451) {
      let message
      let supportUrl
      if (lodashGet(error, 'response.data.reason') === 'IP_ACCESS_BLOCK') {
        message = error?.response?.data?.message
        supportUrl = SUPPORT_URL
      } else {
        // Error Code 451 in GTS means the account is blocked or Confirmed Block.
        supportUrl = GTS_SUPPORT_URL
        if (
          LDFlags['glcp-gtcaas'] &&
          (accStatus === GTS_STATUS_ENUM.BLOCKED ||
            accStatus === GTS_STATUS_ENUM.CONFIRMED_BLOCKED)
        ) {
          message = t('authn:gtcaas.gts_error_message')
        } else {
          message = error?.response?.data?.message
        }
      }
      errorInfo = <RplError message={message} supportUrl={supportUrl} />
      // account card to display error when account can't be launched
      setActionStatus({
        ...actionStatus,
        [custAccToBeLoaded?.platform_customer_id]: errorInfo
      })
    } else if (error?.error_code === 1) {
      // This happens when ld.update failed which didn't allow the user to launch the v2 workspace.
      logger?.log({
        ...v2Logger,
        msg: `error in launching v2 session as ld.update failed`
      })
      errorInfo = (
        <RplError
          message={t('authn:org_launch.error_general')}
          supportUrl={SUPPORT_URL}
        />
      )
      setActionStatus({
        ...actionStatus,
        [custAccToBeLoaded?.platform_customer_id]: errorInfo
      })
    } else if (error?.message === WORKSPACE_STATUS_ENUM.IN_PROGRESS) {
      setActionStatus({
        ...actionStatus,
        [custAccToBeLoaded?.platform_customer_id]: (
          <RplError
            message={t('authn:org_launch.wksp_configuration_in_progress')}
            supportUrl={SUPPORT_URL}
          />
        )
      })
    } else {
      setActionStatus({
        ...actionStatus,
        [custAccToBeLoaded?.platform_customer_id]: <LoadAccountError />
      })
    }
  }

  // custom hook to load the customer account and redirect approriately
  const { isLoading } = useLoadCustomerAccount(
    custAccToBeLoaded,
    onLoadAccountError
  )

  const handleAccountLaunch = (selectedAccount) => {
    // clean up temporary session storage info
    sessionStorage.removeItem('orgLaunchInfo')
    setActionStatus({
      ...actionStatus,
      [selectedAccount?.platform_customer_id]: null
    })

    if (selectedAccount?.organization_id) {
      setCustAccToBeLoaded(selectedAccount)
    } else {
      setSelectedCustAcct(selectedAccount)
      get(
        `/accounts/ui/v1/customer/list-accounts?search_string=${encodeURIComponent(
          selectedAccount?.company_name
        )}`,
        {},
        oidcUser.access_token
      ).then(
        (response) => {
          const account = response?.data?.customers?.find(
            (customer) =>
              customer?.platform_customer_id ===
              selectedAccount?.platform_customer_id
          )
          setCustAccToBeLoaded({
            ...selectedAccount,
            iam_v2_workspace: account?.iam_v2_workspace
          })
          setSelectedCustAcct(null)
        },
        (error) => {
          setSelectedCustAcct(null)
          setCustAccToBeLoaded(null)
          setActionStatus({
            ...actionStatus,
            [selectedAccount?.platform_customer_id]: (
              <RplError
                message={getErrorMessage(error, t)}
                supportUrl={SUPPORT_URL}
              />
            )
          })
        }
      )
    }
  }

  useEffect(() => {
    const accountLaunchError = sessionStorage?.getItem('orgLaunchInfo')
      ? JSON.parse(sessionStorage?.getItem('orgLaunchInfo'))
      : null
    if (accountLaunchError) {
      logger?.log({
        ...v2Logger,
        msg: `error in launching account`,
        data: accountLaunchError
      })
      // Cleanup sessionStorage if any
      if (accountLaunchError.error_code === 2) {
        setActionStatus({
          ...actionStatus,
          [accountLaunchError.workspace_id]: (
            <RplError
              message={t('authn:org_launch.error_invalid_input')}
              supportUrl={SUPPORT_URL}
            />
          )
        })
      } else if (accountLaunchError.error_code === 3) {
        setActionStatus({
          ...actionStatus,
          [accountLaunchError.workspace_id]: (
            <RplError
              message={t('authn:org_launch.error_wksp_not_ready')}
              supportUrl={SUPPORT_URL}
            />
          )
        })
      } else {
        setActionStatus({
          ...actionStatus,
          [accountLaunchError.workspace_id]: (
            <RplError
              message={t('authn:org_launch.error_general')}
              supportUrl={SUPPORT_URL}
            />
          )
        })
      }
      // Because choose-account and switch-account page uses two different APIs, however, on launch failure in both the cases users will be kicked back to the choose-account page; UI needs to calculate the pagination at this point.
      const failedLaunchWorkspaceIndex = customerAccounts?.findIndex(
        (item) => item.platform_customer_id === accountLaunchError.workspace_id
      )
      const errorPagination = Math.floor(
        failedLaunchWorkspaceIndex / itemsPerPage + 1
      )
      setPage(errorPagination)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerAccounts])

  const swappedMSPAccount = getMSPCustomerAccount()

  return (
    <>
      {swappedMSPAccount ? (
        <SwapMSPNavigation />
      ) : (
        <Box
          align="center"
          pad={{ horizontal: 'large', top: 'xlarge', bottom: 'large' }}
        >
          <Box>
            <ChooseAccountListHeading />
            <Typography
              type="heading"
              level={2}
              testId="accounts-heading"
              margin={{ bottom: 'large' }}
            >
              {t('acct_onboarding.accounts', {
                accounts: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_PLURAL_CAPITALIZED
                )
              })}
            </Typography>
            {customerAccountsError?.length > 1 ? (
              <AccountsUnavailableError email={oidcUser?.profile?.email} />
            ) : (
              <ChooseAccountListSearch
                customerAccounts={customerAccounts}
                onFiltered={(accounts) => {
                  setFilteredAccounts(accounts)
                  setPage(1)
                }}
                isDisabled={isLoading}
              />
            )}
            <Box width={{ max: 'large' }}>
              <Box justify="between">
                {displayedAccounts?.length && numOfCustomerAccounts !== null ? (
                  <>
                    <Box gap="medium" margin={{ top: 'small' }}>
                      {displayedAccounts.map((acct) => (
                        <Box
                          key={acct.platform_customer_id}
                          align="start"
                          data-testid={`tile-account-${acct.platform_customer_id}`}
                          onClick={() => {
                            if (!isLoading) {
                              handleAccountLaunch(acct)
                            }
                          }}
                          focusIndicator
                          hoverIndicator={{
                            elevation: null,
                            background: { color: 'background-contrast' }
                          }}
                          elevation="small"
                          pad="medium"
                          round="small"
                          gap="small"
                        >
                          <Box
                            direction="row-responsive"
                            align="center"
                            justify="between"
                            fill
                            gap="medium"
                          >
                            <Box direction="row" gap="small" align="center">
                              <AccountLogo
                                account={acct}
                                title={acct.company_name}
                                logoSize="xsmall"
                              />

                              <Typography
                                testId={`company-name-${acct.platform_customer_id}`}
                                type="heading"
                                level={3}
                                overflowWrap="anywhere"
                              >
                                {acct.company_name}
                              </Typography>
                            </Box>
                            <Box direction="row" justify="end" flex={false}>
                              <Button
                                label={t(
                                  'authn:choose_accounts.go_to_account',
                                  {
                                    account: getWorkspaceString(
                                      showWorkspaceString,
                                      t,
                                      WKSPC_CAPITALIZED
                                    )
                                  }
                                )}
                                secondary
                                onClick={(event) => {
                                  event.stopPropagation()
                                  handleAccountLaunch(acct)
                                }}
                                testId="tile-action-btn"
                                disabled={
                                  isLoading ||
                                  selectedCustAcct?.platform_customer_id ===
                                    acct?.platform_customer_id
                                }
                                {...((selectedCustAcct?.platform_customer_id ===
                                  acct?.platform_customer_id ||
                                  (isLoading &&
                                    custAccToBeLoaded?.platform_customer_id ===
                                      acct?.platform_customer_id)) && {
                                  isLoading: true
                                })}
                              />
                            </Box>
                          </Box>
                          {actionStatus &&
                            actionStatus[acct.platform_customer_id]}
                        </Box>
                      ))}
                    </Box>
                    {filteredAccounts?.length > itemsPerPage && (
                      <>
                        <Ruler margin={{ top: 'large', bottom: 'small' }} />
                        <Pagination
                          testId="pagination"
                          totalItems={filteredAccounts?.length || 0}
                          itemsPerPage={itemsPerPage}
                          page={page}
                          setPage={({ page: nextPage }) => {
                            setPage(nextPage)
                          }}
                          pageIdxInfo={getPaginationShowIdx(
                            page,
                            filteredAccounts?.length || 0,
                            itemsPerPage,
                            t
                          )}
                          disabled={isLoading}
                        />
                      </>
                    )}
                  </>
                ) : (
                  numOfCustomerAccounts === null && (
                    <Box
                      fill
                      align="center"
                      justify="center"
                      direction="row"
                      pad="large"
                      gap="small"
                      background={{
                        color: 'background-front',
                        opacity: 'strong'
                      }}
                    >
                      <LazyLoading />
                    </Box>
                  )
                )}
              </Box>
            </Box>

            <BackToSignIn />
            {/* No workspace creation for COP and SilverCreek */}
            {!isGLOP() && !isCoP() && <CreateAccount />}
          </Box>
        </Box>
      )}
    </>
  )
}

ChooseAccountList.propTypes = {
  customerAccounts: PropTypes.arrayOf(object),
  customerAccountsError: PropTypes.string
}

ChooseAccountList.defaultProps = {
  customerAccounts: null,
  customerAccountsError: ''
}

const ChooseAccountPage = ({
  customerAccounts,
  customerAccountsError,
  setRefreshLoadAccount,
  callListAccountApi
}) => {
  const logger = useLogger()
  const LDFlags = useFlags()
  const isChooseAcctCardLayout = LDFlags['glcp-choose-account-card-layout']
  const { oidcUser } = useReactOidc()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const pageInfo = showWorkspaceString ? 'Choose Workspaces' : 'Choose Accounts'
  const { t } = useTranslation(['common'])
  const ld = useLD()
  const history = useHistory()
  const [apiError, setApiError] = useState(
    history?.location?.state?.apiError || null
  )

  if (sessionStorage.getItem('redirectToLP')) {
    sessionStorage.removeItem('redirectToLP')
  }

  if (isCoP()) {
    window.location.replace('/')
  }
  const countInfo = showWorkspaceString
    ? 'Calling:choose-workspaces'
    : 'Calling:choose-accounts'
  const pageInfoLog = {
    page: ChooseAccountPage.name || pageInfo,
    section: 'Load',
    type: 'success',
    count: countInfo
  }
  useEffect(() => {
    setRefreshLoadAccount(history?.location?.state?.refresh)
  }, [history?.location?.state?.refresh, setRefreshLoadAccount])

  const message = showWorkspaceString
    ? 'Choose Workspaces loaded successfully!'
    : 'Choose Accounts loaded successfully!'

  logger?.log({ ...pageInfoLog, msg: message })
  const custAccountLoaded = getCustomerAccount()

  if (oidcUser?.profile?.email && !custAccountLoaded) {
    hashFunction(oidcUser?.profile?.email).then((encryptedEmail) => {
      ld.update({ platform_customer_id: encryptedEmail })
    })
  }

  return isChooseAcctCardLayout ? (
    <>
      <SwitchAccountPage
        isChooseAcctCardLayout
        hideHeaderOptions={['nav', 'bell', 'apps', 'context']}
        userEmail={oidcUser?.profile?.email}
        ignoreCurrentWorkspace={false}
        isV1SessionEstablished={callListAccountApi}
      />
      <Box gap="large" pad={{ top: 'large', left: 'xlarge', right: 'xlarge' }}>
        <Grid align="start" alignContent="start" columns="medium" gap="small">
          <BackToSignIn />
          {/* No workspace creation for COP and SilverCreek */}
          {!isGLOP() && !isCoP() && <CreateAccount />}
        </Grid>
      </Box>
    </>
  ) : (
    <Layout hideHeaderOptions={['nav', 'bell', 'apps']}>
      {apiError?.error &&
        displayApiError(
          apiError?.error,
          t,
          setApiError,
          apiError?.markdown ? (
            <Markdown>{apiError?.customErrMsg}</Markdown>
          ) : (
            apiError?.customErrMsg
          ),
          'default',
          apiError?.severity || null
        )}

      <ChooseAccountList
        customerAccounts={customerAccounts}
        customerAccountsError={customerAccountsError}
      />
    </Layout>
  )
}

ChooseAccountPage.propTypes = {
  customerAccounts: PropTypes.arrayOf(object),
  customerAccountsError: PropTypes.string,
  setRefreshLoadAccount: PropTypes.func,
  callListAccountApi: PropTypes.bool
}

ChooseAccountPage.defaultProps = {
  customerAccounts: null,
  customerAccountsError: '',
  setRefreshLoadAccount: () => {},
  callListAccountApi: false
}

export default ChooseAccountPage
