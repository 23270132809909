import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'
import { CircleInformation, StatusGoodSmall } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import { Loader } from '../../../../components/loader/Loader'
import { eventPropType } from '../../utils/data'

const CaseState = ({ event, caseOpened }) => {
  const { t } = useTranslation('unified_wellness')
  // The blending of using the escalation itself and the status.currentState
  // should possibly be combined in a central place in the UI
  if (
    event.status?.currentState !== 'pendingCaseCreation' &&
    event.status?.currentState !== 'pendingEscalation' &&
    event.status?.currentState !== 'timeoutCaseCreation' &&
    !event.escalation?.[0]
  ) {
    return null
  }
  let bgColor = ''
  let content = null
  if (
    ['pendingCaseCreation', 'pendingEscalation'].includes(
      event.status?.currentState || ''
    )
  ) {
    bgColor = '#FFBC443D'
    content = (
      <>
        {/* Loader doesnt have a size that is small enough must use pixle sizing */}
        <Loader testId="loader-spinner" size="24px" />
        <Text margin={{ horizontal: 'xsmall' }} weight="bold">
          {t('details.caseStatus.pending')}:
        </Text>
        {t('details.caseStatus.pendingDetails')}
      </>
    )
  } else if (event.status?.currentState === 'timeoutCaseCreation') {
    bgColor = '#FC61613D'
    content = (
      <>
        <CircleInformation />
        <Text margin={{ horizontal: 'xsmall' }} weight="bold">
          {t('details.caseStatus.error')}:
        </Text>
        {t('details.caseStatus.timeout')}
      </>
    )
  } else if (event.escalation?.[0]) {
    if (caseOpened) {
      bgColor = '#17EBA03D'
      content = (
        <>
          <StatusGoodSmall color="#17EBA0" />
          <Text margin={{ horizontal: 'xsmall' }} weight="bold">
            {t('details.caseStatus.success')}:
          </Text>
          {`${t('details.case')} #${event.escalation[0].caseNumber}`}
        </>
      )
    } else {
      bgColor = '#F7F7F7'
      content = `${t('details.case')} #${event.escalation[0].caseNumber}`
    }
  }
  return (
    <Box
      data-testid="details-case-number-tag"
      margin={{ bottom: 'small' }}
      pad="xsmall"
      background={bgColor}
      round="xsmall"
      direction="row"
    >
      {content}
    </Box>
  )
}

CaseState.propTypes = {
  event: eventPropType.isRequired,
  caseOpened: PropTypes.bool.isRequired
}

export default CaseState
