import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Menu, Anchor } from 'grommet'
import { useTranslation } from 'react-i18next'

import { buildClickUri } from '../ResultListTemplateHelper/clickUriUtils'

const RecentlyViewed = ({ controller, size }) => {
  const { t } = useTranslation('support_hub_search')

  // Define the useLocalStorage hook
  const useLocalStorage = (key, defaultValue = []) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key)
      return storedValue ? JSON.parse(storedValue) : defaultValue
    })

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value))
    }, [key, value])

    return { value, setValue }
  }

  // Use the useLocalStorage hook
  const { value: storedResults, setValue: setStoredResults } =
    useLocalStorage('recentResults')

  useEffect(() => {
    const unsubscribe = controller.subscribe(() => {
      setStoredResults((prevResults) => {
        // Logic to update the local storage data
        const uniqueIdsSet = new Set(
          prevResults.map((result) => result.uniqueId)
        )
        const newResults = controller.state.results.filter(
          (result) => !uniqueIdsSet.has(result.uniqueId)
        )
        // Combine the new results with the existing ones, keeping only the last 5
        // This also prevents localStorage from getting overpopulated
        const updatedResults = [...newResults, ...prevResults].slice(0, 5)
        return updatedResults
      })
    })

    return () => {
      unsubscribe()
    }
  }, [controller, setStoredResults])

  const renderRecentResults = () => {
    return storedResults.map((result) => {
      // Build clickUri for each result
      const clickUriForItem = buildClickUri(result)

      return {
        label: (
          <Box key={result.uniqueId} pad="xsmall" border={{ side: 'bottom' }}>
            <Anchor
              data-testid="recent-result-item"
              label={result.title}
              href={clickUriForItem}
              target="_blank"
            />
          </Box>
        )
      }
    })
  }

  return (
    <Box width={size === 'xsmall' || size === 'small' ? '100%' : '35%'}>
      <Menu
        alignSelf={size === 'xsmall' || size === 'small' ? 'start' : 'end'}
        data-testid="recently-viewed-menu"
        label={t('recently_viewed')}
        items={storedResults.length > 0 ? renderRecentResults() : []}
        icon={storedResults.length > 0} // If there are results, display arrow icon
        dropAlign={{ top: 'bottom', right: 'right' }}
        dropProps={{ width: { max: '50%' } }}
      />
    </Box>
  )
}

RecentlyViewed.propTypes = {
  controller: PropTypes.shape({
    state: PropTypes.object,
    subscribe: PropTypes.func
  }).isRequired,
  size: PropTypes.string.isRequired
}

export default RecentlyViewed
