// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import debounce from 'lodash/debounce'
import { Box, FormField, SelectMultiple } from 'grommet'
import { useTranslation } from 'react-i18next'
import uniqBy from 'lodash/uniqBy'
import isEqual from 'lodash/isEqual'

import { get } from '../../../../../utils/api-utils'
import { displayApiError } from '../../../../../utils/error-handling-utils'
import {
  Button,
  Loader,
  ToggleButton,
  Typography
} from '../../../../../components'
import { getCCSAppDetails } from '../../../utils'
import { allScopesRRPName } from '../../utils'

import { AssignRoleDropdownCustomRender } from './AssignRoleDropdownCustomRender'

const RRPInput = ({
  selectedDetails,
  setSelectedDetails,
  existingRoleAssignments,
  isEditRRPModal,
  inCCSManager,
  customerDetails,
  formErrorMessage,
  setFormErrorMessage,
  setShowParentModal,
  setShowAbandonProgressModal,
  allScopesRRPId
}) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authn', 'authz', 'common'])
  const { ccsApplicationId } = getCCSAppDetails()
  const [rrpList, setRrpList] = useState([])
  const [searchString, setSearchString] = useState('')
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showCreateRRPBtn, setShowCreateRRPBtn] = useState(null)

  // to recalculate showCreateRRPBtn once selected application changes
  const [prevApp, setPrevApp] = useState(null)
  if (selectedDetails?.application_id !== prevApp) {
    setPrevApp(selectedDetails?.application_id)
    setShowCreateRRPBtn(null)
  }

  // for rrp dropdown pagination
  const [totalItems, setTotalItems] = useState(50)
  const [page, setPage] = useState(1)
  const itemsPerPage = 50

  useEffect(() => {
    // get RRP list to populate RRP dropdown
    if (
      selectedDetails.application_id &&
      selectedDetails.application_id !== ccsApplicationId
    ) {
      if (showCreateRRPBtn === null) setLoading(true)
      const url = inCCSManager
        ? `/support-assistant/v1alpha1/resource-restrictions`
        : `/authorization/ui/v1/resource_restrictions`

      get(
        url,
        {
          application_id: selectedDetails.application_id,
          limit: itemsPerPage,
          offset: (page - 1) * itemsPerPage,
          ...(searchString?.trim()?.length && { name: searchString?.trim() }),
          ...(inCCSManager && { platform_cid: customerDetails?.id })
        },
        oidcUser.access_token
      ).then(
        (response) => {
          if (response.status === 200 || response.status === 204) {
            const newRRPList = [
              ...(page > 1 ? rrpList : []), // if page > 1, append the result to the previous list
              ...(!searchString?.trim()?.length // if there is no search string, add the already selected data first
                ? [...(selectedDetails?.resource_restriction_policies || [])]
                : []),
              ...(response?.data?.policies?.map((rrp) => ({
                id: rrp?.resource_restriction_policy_id,
                name: rrp?.name,
                description: rrp?.description
              })) || [])
            ].filter((rrp) => rrp?.name !== allScopesRRPName) // filter the All Scopes RRP from this list
            setRrpList(uniqBy(newRRPList, 'id'))
            setTotalItems(response?.data?.pagination?.total_count - 1 || 0)

            if (
              newRRPList?.length === 0 &&
              showCreateRRPBtn === null &&
              !inCCSManager
            ) {
              setShowCreateRRPBtn(true)
            } else {
              setShowCreateRRPBtn(false)
            }
          }
          setLoading(false)
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
          setLoading(false)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oidcUser.access_token,
    selectedDetails.application_id,
    searchString,
    t,
    ccsApplicationId,
    customerDetails?.id,
    inCCSManager,
    page
  ])

  // to auto select the previous RRP
  // only in editRRP modal
  useEffect(() => {
    if (
      selectedDetails.application_id &&
      selectedDetails.role_slug &&
      selectedDetails.application_id !== ccsApplicationId &&
      rrpList?.length &&
      isEditRRPModal
    ) {
      const res = existingRoleAssignments
        .filter(
          (data) =>
            data.application_id === selectedDetails.application_id &&
            data.slug === selectedDetails.role_slug
        )
        .map((data) => data.resource_restriction_policies)
        .flat()
      if (res.length) {
        const prevSelectedRRPs =
          res?.map((rrp) => ({
            id: rrp?.resource_restriction_policy_id,
            name: rrp?.name,
            description: rrp?.description
          })) || []
        setSelectedDetails((val) => ({
          ...val,
          limit_resource_access: !res
            ?.map((rrp) => rrp?.name)
            ?.includes(allScopesRRPName),
          resource_restriction_policies: prevSelectedRRPs
        }))
        setRrpList(
          uniqBy(
            [
              ...(prevSelectedRRPs?.filter(
                (val) => val?.name !== allScopesRRPName
              ) || []),
              ...rrpList
            ],
            'id'
          )
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedDetails.application_id,
    selectedDetails.role_slug,
    allScopesRRPId,
    ccsApplicationId,
    setSelectedDetails
  ])

  const handleDebouncedSearchValue = debounce((value) => {
    setSearchString(value)
  }, 1000)

  const showRRPDropdown = () => {
    return (
      ((isEditRRPModal && !loading) || !isEditRRPModal) && !showCreateRRPBtn
    )
  }

  return (
    <Box direction="column" gap="medium">
      <Box gap="xsmall" data-testid="toggle-btn-box">
        <FormField>
          <ToggleButton
            checked={selectedDetails.limit_resource_access}
            label={t('users.limit_resource_access')}
            onChange={(event) => {
              setFormErrorMessage('')
              setSelectedDetails({
                ...selectedDetails,
                limit_resource_access: event.target.checked,
                ...(!event.target.checked
                  ? {
                      resource_restriction_policies: [
                        ...(allScopesRRPId
                          ? [
                              {
                                id: allScopesRRPId,
                                name: allScopesRRPName
                              }
                            ]
                          : [])
                      ]
                    }
                  : {
                      resource_restriction_policies: []
                    })
              })
            }}
            testId="limit-resource-access-toggle-btn"
            value={selectedDetails.limit_resource_access || false}
          />
        </FormField>
      </Box>
      {selectedDetails.limit_resource_access && showRRPDropdown() && (
        <FormField
          label={`${t('users.resource_restriction_policy')}*`}
          name="resource_restriction_policies"
          data-testid="select-rrp-form-field"
          validate={(value) => {
            if (value && value.length) {
              return true
            }
            setFormErrorMessage(t('authz:rrp.no_rrp_selected_error_msg'))
            return t('authz:rrp.required_field')
          }}
          margin={{ bottom: formErrorMessage ? 'medium' : 'none' }}
        >
          <SelectMultiple
            id="rrp-dropdown"
            data-testid="rrp-dropdown"
            name="resource_restriction_policies"
            options={rrpList}
            labelKey="name"
            value={selectedDetails?.resource_restriction_policies?.map(
              (data) => data?.id
            )}
            valueKey={{
              key: 'id',
              reduce: true
            }}
            placeholder={t('users.select_resource_restriction_policy')}
            onChange={({ value, option }) => {
              setFormErrorMessage('')
              let result = []
              if (!isEqual(value, [])) {
                const previousSelected =
                  selectedDetails?.resource_restriction_policies
                result = previousSelected
                  ?.filter((val) => val?.name !== allScopesRRPName)
                  ?.map((val) => val?.id)
                  ?.includes(option?.id)
                  ? previousSelected?.filter((val) => val?.id !== option?.id)
                  : [
                      ...previousSelected,
                      {
                        id: option?.id,
                        name: option?.name,
                        description: option?.description
                      }
                    ]
              }
              setSelectedDetails({
                ...selectedDetails,
                resource_restriction_policies: result
              })
            }}
            onSearch={(searchText) => {
              handleDebouncedSearchValue(searchText)
            }}
            searchPlaceholder="Search Resource Restriction Policies"
            onClose={() => {
              setSearchString('')
              setPage(1)
            }}
            dropHeight="medium"
            {...(isEditRRPModal && {
              dropProps: {
                stretch: 'align',
                width: 'medium'
              }
            })}
            noBorder
            onMore={() => {
              if (totalItems > page * itemsPerPage) setPage(page + 1)
            }}
          >
            {(option, index, options, { selected }) => (
              <AssignRoleDropdownCustomRender
                name={option?.name}
                description={option?.description || ''}
                showCheckbox
                isSelected={selected}
                key={index}
              />
            )}
          </SelectMultiple>
        </FormField>
      )}
      {selectedDetails.limit_resource_access && showCreateRRPBtn && (
        <Box
          gap="small"
          align="start"
          pad={{ bottom: formErrorMessage ? 'medium' : 'none' }}
        >
          <Typography type="text" size="medium" testId="no-rrp-msg">
            {t('authz:rrp.no_rrp_exist_msg')}
          </Typography>
          <Button
            secondary
            label={t('authz:rrp.create_policy')}
            testId="create-rrp-button"
            onClick={() => {
              setShowAbandonProgressModal(true)
              setShowParentModal(false)
            }}
          />
        </Box>
      )}

      {showCreateRRPBtn === null && loading && isEditRRPModal && (
        <Box direction="row" align="center" justify="center">
          <Loader testId="loader" />
        </Box>
      )}
      {showErrorNotification}
    </Box>
  )
}

RRPInput.propTypes = {
  allScopesRRPId: PropTypes.string,
  selectedDetails: PropTypes.object.isRequired,
  setSelectedDetails: PropTypes.func.isRequired,
  existingRoleAssignments: PropTypes.array.isRequired,
  isEditRRPModal: PropTypes.bool,
  inCCSManager: PropTypes.bool,
  customerDetails: PropTypes.object,
  formErrorMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  setFormErrorMessage: PropTypes.func,
  setShowParentModal: PropTypes.func,
  setShowAbandonProgressModal: PropTypes.func
}

RRPInput.defaultProps = {
  allScopesRRPId: '',
  isEditRRPModal: false,
  inCCSManager: false,
  customerDetails: {},
  formErrorMessage: '',
  setFormErrorMessage: () => {},
  setShowParentModal: () => {},
  setShowAbandonProgressModal: () => {}
}

export { RRPInput }
