import React from 'react'
import { Box } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  ModalDialog,
  Typography,
  ModalHeader,
  ModalFooter,
  ButtonGroup
} from '../../../components'

const UnassignTenantDevicesModal = ({
  handleCancel,
  handleViewAssignedDevices,
  assignedDeviceCount
}) => {
  const { t } = useTranslation(['apps'])
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              level="1"
              testId="modal-title"
              type="heading"
              weight="bold"
            >
              {t('unassign_devices')}
            </Typography>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <Typography type="text" size="medium" testId="modal-content">
            <Trans
              i18nKey="detected_tenant_devices"
              t={t}
              assignedDeviceCount={assignedDeviceCount}
            >
              <strong>{{ assignedDeviceCount }}</strong>
            </Trans>
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: handleCancel
                },
                {
                  label: t('view_assigned_devices'),
                  primary: true,
                  testId: 'view-assigned-devices-btn',
                  onClick: handleViewAssignedDevices
                }
              ]}
              testId="modal-action-buttons"
            />
          }
        />
      }
      onClose={handleCancel}
      testId="unassign-tenant-devices-modal"
      width="large"
    />
  )
}

UnassignTenantDevicesModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleViewAssignedDevices: PropTypes.func.isRequired,
  assignedDeviceCount: PropTypes.number.isRequired
}

export { UnassignTenantDevicesModal }
