// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useState, useEffect } from 'react'
import { Box, Button, Anchor, Grid, ThemeContext } from 'grommet'
import { CircleInformation } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { NoDataInfo, Typography } from '../../../components'
import { useUPSContext } from '../../../context/ups-context'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import RunTimeWidget from './components/RunTimeWidget'
import { DismissWidgetsModal } from './components/DismissWidgetsModal'
import { AddWidgetsModal } from './components/AddWidgetsModal'
import { HomeWidgetsData } from './widgets-data'

export const WidgetsSection = ({ setNotification }) => {
  const { t } = useTranslation(['common'])
  const { homewidgets: homeWidgetsFromGet } = useUPSContext()

  const [addWidgetModal, setAddWidgetModal] = useState(false)
  const [dismissWidgetModal, setDismissWidgetsModal] = useState(false)
  const [defaultWidgetData, setDefaultWidgetData] = useState([])
  const [homeWidgetData, setHomeWidgetData] = useState([])

  const widgetsData = HomeWidgetsData()

  useEffect(() => {
    const filteredWidgetsData = widgetsData.filter((widget) => !widget.hidden)
    setDefaultWidgetData(filteredWidgetsData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showDefaultWidgetSection = !homeWidgetsFromGet?.some(
    (widget) => widget.visible
  )

  useEffect(() => {
    const updatedWidgetsData = defaultWidgetData.map((widget) => {
      const responseWidget = homeWidgetsFromGet?.find(
        (item) => item.widgetKey === widget.widgetKey
      )
      return responseWidget
        ? { ...widget, visible: responseWidget.visible }
        : widget
    })
    setHomeWidgetData(updatedWidgetsData)
  }, [homeWidgetsFromGet, defaultWidgetData])

  const { global } = useContext(ThemeContext)
  const { xsmall, small } = global.size
  // Create midpoint between small and medium derived from t-shirt sizes
  const smMd = `${
    parseInt(xsmall.replace('px', ''), 10) +
    parseInt(small.replace('px', ''), 10)
  }px`

  return (
    <>
      {showDefaultWidgetSection ? (
        <Box pad={{ bottom: 'large' }}>
          <Typography level={2} type="heading" margin="none">
            {t('dashboard.widgets.title')}
          </Typography>
          <Box align="center" justify="center" gap="medium">
            <NoDataInfo
              icon={<CircleInformation size="xlarge" color="black" />}
              title={t('dashboard.widgets.no_widgets_section.title')}
              subtitle={t('dashboard.widgets.no_widgets_section.subtitle')}
              pad={{ bottom: 'medium' }}
              testId="initial-widget-secton"
            />
            <Box gap="small" align="center">
              <Button
                secondary
                label={t('dashboard.widgets.buttons.add_widgets')}
                onClick={() => setAddWidgetModal(true)}
                data-testid="widget-add-btn"
              />
              <Anchor
                label={t('dashboard.widgets.buttons.dismiss_widgets')}
                onClick={() => setDismissWidgetsModal(true)}
                data-testid="widget-dismiss-btn"
              />
              {addWidgetModal && (
                <AddWidgetsModal
                  onClose={() => setAddWidgetModal(false)}
                  setNotification={setNotification}
                />
              )}
              {dismissWidgetModal && (
                <DismissWidgetsModal
                  onClose={() => setDismissWidgetsModal(false)}
                  setNotification={setNotification}
                />
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        // TODO: see if this block is to be moved in a different component when implimenting run time widgets (next PR)
        <Box pad={{ bottom: 'large' }}>
          <Box
            direction="row"
            align="center"
            justify="between"
            gap="small"
            pad={{ bottom: 'medium' }}
          >
            <Typography level={2} type="heading" margin="none">
              {t('dashboard.widgets.title')}
            </Typography>
            <Anchor
              label={t('dashboard.widgets.add_edit_modal.edit_title')}
              onClick={() => setAddWidgetModal(true)}
              data-testid="add-edit-modal-title-btn"
            />
            {addWidgetModal && (
              <AddWidgetsModal
                onClose={() => setAddWidgetModal(false)}
                setNotification={setNotification}
                isEditModal
              />
            )}
          </Box>
          <Grid columns={{ count: 'fit', size: smMd }} gap="medium">
            {homeWidgetData.map(
              (widget) =>
                widget.visible && (
                  <VisibilityWrapper
                    hideFor={widget.hideFor}
                    rbac={widget.rbac}
                    key={`visibility-wrapper-home-${widget.widgetKey}`}
                  >
                    <RunTimeWidget
                      chartType={widget.chartType}
                      url={widget.url}
                      data={widget.widgetData.listData}
                      subTitle={widget.subtitle}
                      title={widget.title}
                      key={widget.widgetKey}
                      testId={widget.testId}
                      onSelectClick={null}
                      action={widget.actionUrl}
                      noDataSummary={widget.noDataSummary}
                    />
                  </VisibilityWrapper>
                )
            )}
          </Grid>
        </Box>
      )}
    </>
  )
}

WidgetsSection.propTypes = {
  setNotification: PropTypes.func.isRequired
}
