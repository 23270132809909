import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import Home from './pages/Home'

const WellnessDashboardRouter = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      {/* NOTE: Always add any other routes above this route */}
      <Route path={`${path}`}>
        <Home />
      </Route>
    </Switch>
  )
}

export default WellnessDashboardRouter
