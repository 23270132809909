import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Loader } from '../../../components'

import DataLoadFailure from './DataLoadFailure'

const LoadingContainer = ({ children, loading, loadingError, testId }) => {
  const { t } = useTranslation(['common'])

  if (loadingError) {
    return <DataLoadFailure testId={testId} />
  }
  if (loading) {
    return (
      <Box
        {...(testId && { 'data-testid': `${testId}-load-container` })}
        align="center"
        justify="center"
        pad="medium"
      >
        <Loader label={t('loading')} testId="loader-spinner" />
      </Box>
    )
  }

  return <>{children}</>
}

LoadingContainer.defaultProps = {
  children: undefined,
  loadingError: undefined,
  testId: undefined
}

LoadingContainer.propTypes = {
  children: PropTypes.element,
  loading: PropTypes.bool.isRequired,
  loadingError: PropTypes.bool,
  testId: PropTypes.string
}
export default LoadingContainer
