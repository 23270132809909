// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { React, Suspense, lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

const AddDevicesToLicenses = lazy(() =>
  import('./pages/licenses/add-devices-to-licenses/AddDevicesToLicenses')
)
const LicenseDetailsPage = lazy(() =>
  import('./pages/licenses/license-details/LicenseDetails')
)
const Subscriptions = lazy(() => import('./Subscriptions'))

const OrderDetailsPage = lazy(() =>
  import('./pages/order-history/order-details/OrderDetails')
)

const SubscriptionsRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route
          exact
          path="/manage-account/subscriptions/device-subscriptions/:licenseKey"
        >
          <LicenseDetailsPage />
        </Route>
        <Route
          exact
          path="/manage-account/subscriptions/device-subscriptions/:licenseKey/add-devices-to-subscription"
        >
          <AddDevicesToLicenses />
        </Route>
        <Route
          exact
          path="/manage-account/subscriptions/order-history/:referenceId"
        >
          <OrderDetailsPage />
        </Route>
        <Route path="/manage-account/subscriptions">
          <Subscriptions />
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  )
}

export default SubscriptionsRouter
