import uniqBy from 'lodash/uniqBy'

import servicesData from '../config/services.json'
import { get } from '../../../../utils/api-utils'

export const fetchServices = (t) => {
  const supportedServices = []
  try {
    if (servicesData?.services?.length) {
      let other = {}
      servicesData.services.forEach((service) => {
        const serviceOption = {
          value: t(service.locale_key),
          name: service.name,
          category: t(service.category_locale_key),
          id: service.id,
          static: service.static,
          type: service.type,
          show_contact_preferences: service.show_contact_preferences
        }
        if (service.id === 'other') {
          other = serviceOption
        } else {
          supportedServices.push(serviceOption)
        }
      })
      supportedServices.sort((firstService, secondService) =>
        firstService.value.localeCompare(secondService.value)
      )
      supportedServices.push(other)
      return {
        id: servicesData.id,
        locale_key: servicesData.locale_key,
        placeholder_locale_key: servicesData.placeholder_locale_key,
        search_locale_key: servicesData.search_locale_key,
        api_name: servicesData.api_name,
        required: servicesData.required,
        supportedServices: [...supportedServices]
      }
    }
    return { supportedServices: [...supportedServices] }
  } catch {
    return { supportedServices: [...supportedServices] }
  }
}

// Escaping regular expression special characters: [ \ ^ $ . | ? * + ( )
const getEscapedText = (query) => query.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')

// Create the regular expression with escaped special characters.
const formatSearchExpression = (query) => new RegExp(getEscapedText(query), 'i')

export const onSearch = (query, initialOptions) => {
  const searchRegExp = formatSearchExpression(query)
  return initialOptions.filter((option) => searchRegExp.test(option.value))
}

export const fetchUsers = async (access_token, search_string) => {
  let usersData = []
  let serverSideSearch = false
  let errorMessage = ''
  try {
    const request = {
      ...(search_string?.trimStart().length && {
        search_string: search_string.trimStart()
      }),
      user_activity_status: 'ACTIVE',
      include_unverified: false
    }
    const response = await get(
      `/ui-doorway/ui/v2/um/users`,
      request,
      access_token
    )
    if (response?.data) {
      const { users, pagination } = response.data
      if (users) {
        const usersResponse = [...response.data.users]
        usersData = usersResponse.map((eachUser) => {
          return {
            name: `${eachUser.first_name} ${eachUser.last_name}`,
            email: eachUser.email,
            value: eachUser.first_name + eachUser.last_name + eachUser.email
          }
        })
      }
      if (
        (pagination?.count_per_page &&
          pagination.total_count !== pagination.count_per_page) ||
        (pagination?.limit && pagination.total_count !== pagination.limit)
      ) {
        serverSideSearch = true
      }
    } else {
      errorMessage = 'support_cases:contact.issue_when_retrieving_list_of_users'
    }
  } catch (error) {
    errorMessage = 'support_cases:contact.issue_when_retrieving_list_of_users'
  }
  return { usersData, serverSideSearch, errorMessage }
}

export const onSort = (initialOptions) => {
  return initialOptions.sort((firstOption, secondOption) =>
    firstOption.name.localeCompare(secondOption.name)
  )
}

export const onSortUnique = (initialOptions) => {
  const uniqueOptions = uniqBy(initialOptions, 'value')
  return uniqueOptions.sort((firstOption, secondOption) =>
    firstOption.name.localeCompare(secondOption.name)
  )
}
