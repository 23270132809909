import React, { useContext } from 'react'
import { Box } from 'grommet'
import { useTranslation, Trans } from 'react-i18next'
import PropTypes from 'prop-types'

import { Typography, Button, Notification } from '../../../../../components'
import { WizardContext } from '../../../../../components/wizard/WizardContext'

const ReviewAndSubmit = ({ credsError }) => {
  const { t } = useTranslation(['local-gateway'])
  const {
    formValues: {
      gatewayDetails: { name, description, appId, username, password }
    },
    setActiveStep
  } = useContext(WizardContext)

  const CredentialsError = () => {
    return (
      <Box pad={{ bottom: 'medium' }}>
        <Notification
          testId="cred_failure-warning-notification"
          text={
            <Trans
              i18nKey="api_messages.gateway_half_created_error"
              t={t}
              values={{
                gatewayName: name
              }}
              components={[<strong />]}
            />
          }
          backgroundColor="status-warning"
          type="inline"
        />
      </Box>
    )
  }

  return (
    <Box margin={{ bottom: 'medium' }}>
      <Box
        direction="row"
        justify="between"
        align="center"
        border="bottom"
        margin={{ bottom: 'medium' }}
      >
        <Typography
          level="3"
          type="heading"
          weight="normal"
          margin={{ bottom: 'small' }}
        >
          {t('details')}
        </Typography>
        <Box>
          <Button
            default
            label={t('edit')}
            testId="details-edit-btn"
            onClick={() => setActiveStep(1)}
            disabled={!!credsError}
          />
        </Box>
      </Box>
      <Box
        direction="row"
        pad={{ bottom: 'small' }}
        data-testid="container-name"
      >
        <Box width={{ min: 'small' }}>
          <Typography type="text" weight="bold">
            {t('name')}
          </Typography>
        </Box>
        <Box>
          <Typography testId="gateway-name" type="text">
            {name}
          </Typography>
        </Box>
      </Box>

      <Box
        direction="row"
        pad={{ bottom: 'small' }}
        data-testid="container-description"
      >
        <Box width={{ min: 'small' }}>
          <Typography type="text" weight="bold">
            {t('description')}
          </Typography>
        </Box>
        <Box>
          <Typography testId="gateway-description" type="text">
            {description}
          </Typography>
        </Box>
      </Box>
      <Box
        direction="row"
        justify="between"
        align="center"
        border="bottom"
        margin={{ bottom: 'medium' }}
      >
        <Typography
          level="3"
          type="heading"
          weight="normal"
          margin={{ bottom: 'small' }}
        >
          {t('application')}
        </Typography>
        <Box>
          <Button
            default
            label={t('edit')}
            testId="application-edit-btn"
            onClick={() => {
              setActiveStep(2)
            }}
            disabled={!!credsError}
          />
        </Box>
      </Box>
      {credsError && <CredentialsError name={credsError} />}
      <Box
        direction="row"
        pad={{ bottom: 'small' }}
        data-testid="container-application"
      >
        <Box width={{ min: 'small' }}>
          <Typography type="text" weight="bold">
            {t('application')}
          </Typography>
        </Box>
        <Box>
          <Typography testId="application-name" type="text">
            {t(`${appId}.name`)}
          </Typography>
        </Box>
      </Box>
      <Box
        direction="row"
        pad={{ bottom: 'small' }}
        data-testid="container-user-name"
      >
        <Box width={{ min: 'small' }}>
          <Typography type="text" weight="bold">
            {t('user_name')}
          </Typography>
        </Box>
        <Box>
          <Typography testId="user-name" type="text">
            {username}
          </Typography>
        </Box>
      </Box>
      <Box
        direction="row"
        pad={{ bottom: 'small' }}
        data-testid="container-password"
      >
        <Box width={{ min: 'small' }}>
          <Typography type="text" weight="bold">
            {t('password')}
          </Typography>
        </Box>
        <Box>
          <Typography testId="password" type="text">
            {'*'.repeat(password.length)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

ReviewAndSubmit.defaultProps = {
  credsError: undefined
}

ReviewAndSubmit.propTypes = {
  credsError: PropTypes.string
}

export default ReviewAndSubmit
