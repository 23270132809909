import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'

import {
  Button,
  ModalDialog,
  ModalHeader,
  ModalFooter,
  Typography
} from '../../../components'

const DeleteCredentialModal = ({
  setShowModal,
  deleteCredential,
  lastCredential
}) => {
  const { t } = useTranslation(['local-gateway', 'common'])

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Box pad="small">
              <Typography
                type="heading"
                level="1"
                testId="delete-modal-header-title"
              >
                {lastCredential
                  ? t('delete_last_credential_title')
                  : t('delete_credential_title')}
              </Typography>
            </Box>
          }
          onClose={() => setShowModal(false)}
        />
      }
      content={
        <Box>
          <Box pad={{ left: 'small', vertical: 'medium' }}>
            <Typography
              type="text"
              size="medium"
              testId="delete-credential-text"
            >
              {lastCredential
                ? t('delete_last_credential_text')
                : t('delete_credential_text')}
            </Typography>
          </Box>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <Box direction="row" gap="small">
              {lastCredential ? (
                <Button
                  label={t('common:close')}
                  primary
                  testId="close-btn"
                  onClick={() => setShowModal(false)}
                />
              ) : (
                <>
                  <Button
                    label={t('common:cancel')}
                    testId="cancel-btn"
                    onClick={() => setShowModal(false)}
                  />
                  <Button
                    margin={{ left: 'small' }}
                    label={t('delete_credential_button')}
                    primary
                    testId="delete-btn"
                    onClick={() => {
                      deleteCredential()
                      setShowModal(false)
                    }}
                  />
                </>
              )}
            </Box>
          }
        />
      }
      onClose={() => setShowModal(false)}
      testId="delete-credential-modal-dialog"
    />
  )
}

DeleteCredentialModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  deleteCredential: PropTypes.func.isRequired,
  lastCredential: PropTypes.bool.isRequired
}

export default DeleteCredentialModal
