import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Typography,
  ModalDialog,
  ModalFooter,
  ModalHeader
} from '../../../../components'

const RadsecProtocolChangeModal = ({ setShow, cb }) => {
  const { t } = useTranslation(['authn', 'common'])
  return (
    <ModalDialog
      footer={
        <ModalFooter
          right={
            <Box direction="row" justify="end" gap="medium">
              <Button
                default
                size="small"
                label={t('manage_account.authentication.cancel')}
                onClick={() => setShow(false)}
                testId="close-auth-method-modal"
              />
              <Button
                primary
                size="small"
                label={t('manage_account.authentication.save_change')}
                onClick={() => cb(true)}
                testId="confirm-auth-method-change"
              />
            </Box>
          }
        />
      }
      header={
        <ModalHeader
          width="small"
          subtitle=""
          title={
            <Box direction="column" alignContent="center">
              <Typography
                level="1"
                testId="header-title"
                type="heading"
                weight="bold"
              >
                {t('manage_account.authentication.save_authentication_method')}
              </Typography>
              <Box width="medium">
                <Typography
                  margin={{ top: 'medium' }}
                  size="medium"
                  testId="auth-change-desc"
                  type="text"
                >
                  {t(
                    'manage_account.authentication.change_auth_method_msg_radsec'
                  )}
                </Typography>
              </Box>
            </Box>
          }
        />
      }
      onClose={() => {}}
      testId="center-modal-dialog"
    />
  )
}

RadsecProtocolChangeModal.propTypes = {
  setShow: PropTypes.func.isRequired,
  cb: PropTypes.func.isRequired
}

export default RadsecProtocolChangeModal
