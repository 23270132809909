import { Anchor, Box, PageHeader, Heading } from 'grommet'
import { Previous } from 'grommet-icons'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'

import useLD from '../../../hooks/ld/useLD'
import {
  ActionButton,
  ButtonGroup,
  Typography,
  Loader,
  Button
} from '../../../components'
import { AccountLogo } from '../../commoncomponents/account-logo/AccountLogo'
import { customRenderer } from '../../commoncomponents/CustomRenderer'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'
import { loadCustomerAccount } from '../../../utils/account-utils'
import {
  getWorkspaceString,
  WKSPC_PLURAL_CAPITALIZED
} from '../../../utils/common-utils'
import {
  CUSTOMER_EDIT_MODE,
  CUSTOMER_READ_MODE,
  displayAccountLaunchError
} from '../utils'
import { DeleteCustomer } from '../components/DeleteCustomer'
import { useVisibilityContext } from '../../../context/visibility-context'
import { displayNotification } from '../../../utils/notificiation-utils'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { useUPSContext } from '../../../context/ups-context'
import useLogger from '../../../hooks/logs/useLogger'
import { GTS_STATUS_ENUM } from '../../acct-onboarding/utils'

import { DiscardModal } from './DiscardModal'

const CustomerDetailsPageHeader = ({
  customerAccountDetails,
  mode,
  setMode,
  loader
}) => {
  const history = useHistory()
  const { customerId } = useParams()
  const custAccountLoaded = getCustomerAccount()
  const { t } = useTranslation(['authn', 'common'])
  const { oidcUser } = useReactOidc()
  const [customer, setCustomer] = useState({})
  const [deleteCustomer, setDeleteCustomer] = useState(false)
  const [discardModal, setDiscardModal] = useState(false)
  const { dispatchVisibilityContext } = useVisibilityContext()
  const { dispatchUPSContext } = useUPSContext()
  const [errorNotification, setErrorNotification] = useState(null)
  const [warningNotification, setWarningNotification] = useState('')

  const [loading, setLoading] = useState(false)
  const [isMode, setIsMode] = useState(mode)
  const LDFlags = useFlags()
  const ld = useLD()
  const logger = useLogger()
  const handleAccountLaunch = (currentAccount, customerAccountToLaunch) => {
    setLoading(true)
    sessionStorage.setItem('swapMspAccount', JSON.stringify(currentAccount))
    customerAccountToLaunch.platform_customer_id =
      customerAccountToLaunch.customer_id
    customerAccountToLaunch.account_type = 'TENANT'
    loadCustomerAccount(
      customerAccountToLaunch,
      oidcUser,
      dispatchVisibilityContext,
      history,
      null,
      null,
      ld,
      dispatchUPSContext,
      logger
    ).then(
      () => {
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        const accStatus = error?.response?.data?.account_status?.split('.')[1]
        let notificationType
        if (
          LDFlags['glcp-gtcaas'] &&
          error?.response?.status === 423 &&
          (accStatus === GTS_STATUS_ENUM.PENDING ||
            accStatus === GTS_STATUS_ENUM.TIMEOUT)
        ) {
          notificationType = setWarningNotification
        } else {
          notificationType = setErrorNotification
        }
        displayAccountLaunchError(
          error,
          t,
          notificationType,
          customerAccountToLaunch,
          LDFlags['glcp-gtcaas']
        )
      }
    )
  }

  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const handleBackBtnClick = () => {
    history.push('/customer-account')
  }

  return (
    <Box direction="column" pad={{ bottom: 'medium' }}>
      <PageHeader
        title={
          <Box direction="row" gap="small">
            <Box flex={{ shrink: 0 }}>
              <AccountLogo
                account={customerAccountDetails}
                logoOnly
                title={customerAccountDetails?.company_name}
                avatarSize="large"
              />
            </Box>
            <Typography
              level="1"
              type="heading"
              truncate
              textAlign="center"
              testId="page-title"
            >
              {customerAccountDetails?.company_name}
            </Typography>
          </Box>
        }
        parent={
          <Anchor
            size="xsmall"
            label={t('customer_account.title', {
              accounts: getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_PLURAL_CAPITALIZED
              )
            })}
            icon={<Previous size="small" />}
            onClick={handleBackBtnClick}
            data-testid="customer-accounts-back-btn"
          />
        }
        actions={
          isMode !== CUSTOMER_EDIT_MODE && (
            <ActionButton
              actions={[
                {
                  label: t('customer_account.launch'),
                  onClick: () => {
                    handleAccountLaunch(custAccountLoaded, {
                      ...customerAccountDetails,
                      customer_id: customerId
                    })
                  },
                  testId: 'launch-btn'
                },
                {
                  label: t('common:delete'),
                  onClick: () => {
                    setCustomer({
                      ...customerAccountDetails,
                      customer_id: customerId
                    })
                    setDeleteCustomer(true)
                  },
                  visibility: {
                    rbac: {
                      resource: '/ccs/accounts/platform/customer',
                      permission: 'ccs.accounts.platform.customer.delete'
                    }
                  },
                  testId: 'delete-btn'
                }
              ]}
              testId="customer-detail-view-action-btn"
              customRenderer={customRenderer}
              responsive
            />
          )
        }
      />

      <PageHeader
        responsive={isMode === CUSTOMER_EDIT_MODE}
        title={
          <Heading level={2} margin="none" pad="none">
            {t('customer_account.customer_details')}
          </Heading>
        }
        actions={
          isMode === CUSTOMER_EDIT_MODE ? (
            <ButtonGroup
              buttonList={[
                {
                  label: t('common:cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: () => {
                    setDiscardModal(true)
                  },
                  disabled: loader,
                  style: { flexShrink: 0 }
                },
                {
                  label: t('customer_account.save_changes'),
                  primary: true,
                  type: 'submit',
                  testId: 'save-changes-btn',
                  isLoading: loader,
                  style: { flexShrink: 0 }
                }
              ]}
              testId="customer-detail-edit-action-btn"
            />
          ) : (
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/accounts/platform/customer',
                permission: 'ccs.accounts.platform.customer.edit'
              }}
            >
              <Button
                default
                label={t('common:edit')}
                onClick={() => {
                  setMode(CUSTOMER_EDIT_MODE)
                  setIsMode(CUSTOMER_EDIT_MODE)
                }}
                testId="edit-customer-details-btn"
              />
            </VisibilityWrapper>
          )
        }
        data-testid="customer-title-details-header"
        margin="none"
        pad={{ bottom: 'small' }}
      />
      <Box border={{ side: 'bottom', color: 'text-weak' }} />

      {deleteCustomer && (
        <DeleteCustomer
          customer={customer}
          setDeleteCustomer={setDeleteCustomer}
          onSuccess={() => history.push('/customer-account')}
        />
      )}
      {discardModal && (
        <DiscardModal
          onDiscard={() => setMode(CUSTOMER_READ_MODE)}
          onContinue={() => setDiscardModal(false)}
        />
      )}
      {loading && (
        <Loader
          modal
          testId="loader-modal"
          size="xxsmall"
          modalTitle={t('common:launching')}
          modalSubTitle={t('common:loader_dialog_message')}
        />
      )}
      {warningNotification &&
        displayNotification(
          warningNotification,
          'warning',
          setWarningNotification
        )}
      {errorNotification &&
        displayNotification(errorNotification, 'error', setErrorNotification)}
    </Box>
  )
}

CustomerDetailsPageHeader.propTypes = {
  customerAccountDetails: PropTypes.object.isRequired,
  mode: PropTypes.string,
  setMode: PropTypes.func,
  loader: PropTypes.bool
}

CustomerDetailsPageHeader.defaultProps = {
  mode: CUSTOMER_READ_MODE,
  setMode: () => {},
  loader: false
}

export { CustomerDetailsPageHeader }
