import React from 'react'
import { Box } from 'grommet'
import { HomeRounded, AppsRounded } from 'grommet-icons'
import { useHistory } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  MenuList,
  GLCPHeaderAppsMenu,
  Button,
  Typography
} from '../../../../components'
import {
  isTenant,
  isMSPTenantCoP,
  isCoP,
  isInventoryOwnedWorkspace
} from '../../../../utils/feature-flag-utils'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../../utils/common-utils'

const CloudConsoleMenu = () => {
  const history = useHistory()

  const handleCloudConsoleMenuItemClick = (item) => {
    if (item.linkTo.includes('http')) {
      window.open(item.linkTo, '_blank')
    } else {
      history.push(item.linkTo)
    }
  }
  return (
    <MenuList
      navStyles={{
        padding: 'none',
        gap: 'none'
      }}
      menuData={[
        {
          id: 1,
          label: 'HPE GreenLake Central',
          linkTo: 'https://client.greenlake.hpe.com',
          testId: 'header-apps-hpe-green-lake-central',
          isHidden: () => isCoP()
        },
        {
          id: 2,
          label: 'Data Services',
          linkTo: '/applications/available-apps',
          testId: 'header-apps-data-services',
          isHidden: () => isMSPTenantCoP()
        },
        {
          id: 3,
          label: 'Compute Ops Management',
          linkTo: '/applications/available-apps',
          testId: 'header-apps-compute-ops-management',
          isHidden: () => isMSPTenantCoP()
        },

        {
          id: 4,
          label: 'Aruba Central',
          linkTo: 'https://portal.central.arubanetworks.com',
          testId: 'header-apps-aruba-central',
          isHidden: () => isCoP()
        }
      ]}
      onClickMenuItem={handleCloudConsoleMenuItemClick}
      testId="cloud-console-menu"
    />
  )
}
const HPEGLAdministration = () => {
  const { t } = useTranslation(['common'])
  const history = useHistory()
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const handleHPEGLAdministrationMenuItemClick = (item) => {
    if (item.linkTo.includes('http')) {
      window.open(item.linkTo, '_blank')
    } else {
      history.push(item.linkTo)
    }
  }
  return (
    <MenuList
      navStyles={{
        padding: 'none',
        gap: 'none'
      }}
      menuData={[
        {
          id: 1,
          label: t('app_navigation.manage_account', {
            account: getWorkspaceString(
              showWorkspaceString,
              t,
              WKSPC_CAPITALIZED
            )
          }),
          linkTo: '/manage-account',
          testId: 'header-apps-manage-account'
        },
        {
          id: 2,
          label: t('app_navigation.manage_devices'),
          linkTo: '/devices/inventory',
          testId: 'header-apps-manage-devices',
          isHidden: () => !isInventoryOwnedWorkspace()
        }
      ]}
      onClickMenuItem={handleHPEGLAdministrationMenuItemClick}
      testId="hpe-gl-administration-menu"
    />
  )
}

const HPEResources = () => {
  const { t } = useTranslation(['common'])
  const history = useHistory()
  const handleHPEResourcesMenuItemClick = (item) => {
    if (item.linkTo.includes('http')) {
      window.open(item.linkTo, '_blank')
    } else {
      history.push(item.linkTo)
    }
  }
  return (
    <MenuList
      navStyles={{
        padding: 'none',
        gap: 'none'
      }}
      menuData={[
        {
          id: 1,
          label: t('app_navigation.hpe_support_center'),
          testId: 'header-apps-hpe-support-center',
          linkTo: 'https://support.hpe.com/',
          isHidden: () => isTenant()
        },
        {
          id: 2,
          label: t('app_navigation.hpe_dev_community'),
          linkTo: 'https://developer.hpe.com/',
          testId: 'header-apps-hpe-developer-community'
        },
        {
          id: 3,
          label: t('app_navigation.hpe_communities'),
          linkTo: 'https://www.hpe.com/us/en/communities.html',
          testId: 'header-apps-hpe-communities'
        },
        {
          id: 4,
          label: t('app_navigation.hpe_financial_services'),
          linkTo: 'https://www.hpe.com/us/en/services/financial-services.html',
          testId: 'header-apps-hpe-financial-services'
        }
      ]}
      onClickMenuItem={handleHPEResourcesMenuItemClick}
      testId="hpe-resources-menu"
    />
  )
}

const AppsMenuContent = () => {
  const AppsMenuData = [
    {
      title: (
        <Typography type="text" size="xsmall">
          <Trans i18nKey="common:app_navigation.cloud_consoles">
            Cloud Consoles
          </Trans>
        </Typography>
      ),
      menu: <CloudConsoleMenu />,
      isHidden: isCoP()
    },
    {
      title: (
        <Typography type="text" size="xsmall">
          <Trans i18nKey="common:app_navigation.hpe_gl_administration">
            HPE GreenLake Administration
          </Trans>
        </Typography>
      ),
      menu: <HPEGLAdministration />
    },
    {
      title: (
        <Typography type="text" size="xsmall">
          <Trans i18nKey="common:app_navigation.hpe_resources">
            HPE Resources
          </Trans>
        </Typography>
      ),
      menu: <HPEResources />
    }
  ]
  return (
    <GLCPHeaderAppsMenu
      mainApp={<MainAppData />}
      appsData={AppsMenuData}
      testId="apps-menu"
    />
  )
}
const MainAppData = () => {
  const history = useHistory()
  return (
    <Button testId="app-menu-glcp" onClick={() => history.push('/home')}>
      <Box justify="between" direction="row" margin={{ vertical: 'xsmall' }}>
        <Typography type="text" emphasis>
          HPE GreenLake
        </Typography>
        <HomeRounded />
      </Box>
    </Button>
  )
}
const AppsMenu = {
  id: 'apps',
  label: 'Applications menu',
  icon: (
    <Box
      data-testid="apps-btn"
      justify="center"
      align="center"
      round
      height="36px"
      width="36px"
    >
      <AppsRounded color="text-strong" />
    </Box>
  ),
  content: <AppsMenuContent />
}
export { AppsMenu }
