import React, { useState, useEffect, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Box, FormField, RadioButtonGroup } from 'grommet'
import lodashGet from 'lodash/get'
import { Alert } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import { defaultFieldsError } from '../utils/page-utils'
import { validateFields } from '../utils/validation-utils'
import { Typography } from '../../../../../../../components'
import {
  getWorkspaceString,
  WKSPC
} from '../../../../../../../utils/common-utils'
import { idpConfigOptions } from '../../../../constants'

import {
  ManualLeftColumn,
  MetadataFileLeftColumn,
  MetadataRightColumn
} from './components'
import MetadataUrlLeftColumn from './components/MetadataUrlLeftColumn'

export const getValidPropertyCount = (object) => {
  return Object.values(object).filter((x) => x)?.length
}

const AddIDPDetails = ({ multiDomainErrorInfo, setMultiDomainErrorInfo }) => {
  // The commented lines can be un-commented if we nedd manual upload with certificate upload
  const { formValues, attemptedAdvance, setFormValues, setAttemptedAdvance } =
    useContext(WizardContext)
  const { t } = useTranslation(['authn', 'iam'])
  const configOptions = [
    {
      label: t('iam:sso_profiles.view_edit_create.upload_metadata_file'),
      value: idpConfigOptions.UPLOAD_FROM_FILE
    },
    {
      label: t('iam:sso_profiles.view_edit_create.specify_metadata_url'),
      value: idpConfigOptions.SPECIFY_URL
    },
    {
      label: t('iam:sso_profiles.view_edit_create.manual_entry'),
      value: idpConfigOptions.MANUAL
    }
  ]

  const [fieldsError, setFieldsError] = useState(defaultFieldsError)
  const { remainingDomains, lastDomain } = multiDomainErrorInfo
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  useEffect(() => {
    if (attemptedAdvance) {
      setFieldsError(validateFields(formValues, t))
    }
  }, [formValues, attemptedAdvance, t])

  const radioButtonChanged = (event) => {
    setFormValues({
      ...formValues,
      saml_idp_config: {
        ...formValues.saml_idp_config,
        entity_id: '',
        login_url: '',
        logout_url: '',
        signing_certificate: '',
        metadata_url: '',
        file_info: {},
        config_option: event.target.value
      }
    })
    setAttemptedAdvance(false)
    setFieldsError(defaultFieldsError)
    setMultiDomainErrorInfo({})
  }

  return (
    <Box
      pad={{ top: 'xsmall', bottom: 'small' }}
      margin={{ top: 'small', bottom: 'medium' }}
    >
      <Box gap="large" direction="row" pad={{ bottom: 'xsmall' }}>
        <Box width="medium">
          <FormField
            label={`${t(
              'iam:sso_profiles.view_edit_create.choose_configuration_method'
            )}*`}
            data-testid="radio-button-form-field"
            error={fieldsError.configOptions}
          >
            <RadioButtonGroup
              name="config_option"
              options={configOptions}
              value={lodashGet(formValues, 'saml_idp_config.config_option')}
              onChange={radioButtonChanged}
              data-testid="radio-button-input"
            />
          </FormField>
          {lodashGet(formValues, 'saml_idp_config.config_option') ===
            idpConfigOptions.UPLOAD_FROM_FILE && (
            <MetadataFileLeftColumn
              fieldsError={fieldsError}
              setFieldsError={setFieldsError}
            />
          )}
          {lodashGet(formValues, 'saml_idp_config.config_option') ===
            idpConfigOptions.SPECIFY_URL && (
            <MetadataUrlLeftColumn
              fieldsError={fieldsError}
              setFieldsError={setFieldsError}
            />
          )}
          {lodashGet(formValues, 'saml_idp_config.config_option') ===
            idpConfigOptions.MANUAL && (
            <ManualLeftColumn fieldsError={fieldsError} />
          )}
        </Box>
        <Box width="medium">
          {(lodashGet(formValues, 'saml_idp_config.config_option') ===
            idpConfigOptions.UPLOAD_FROM_FILE ||
            lodashGet(formValues, 'saml_idp_config.config_option') ===
              idpConfigOptions.SPECIFY_URL) && <MetadataRightColumn />}
          {}
        </Box>
      </Box>

      {getValidPropertyCount(multiDomainErrorInfo) ? (
        <Box
          direction="row"
          background="validation-critical"
          pad="small"
          round="xsmall"
        >
          <Typography
            type="text"
            size="medium"
            icon={<Alert size="medium" />}
            testId="multi-domain-error-info"
          >
            <Trans
              i18nKey={
                getValidPropertyCount(multiDomainErrorInfo) === 1
                  ? 'manage_account.saml_sso.multi_domain_singular_error'
                  : 'manage_account.saml_sso.multi_domain_error'
              }
              t={t}
            >
              <strong>{{ remainingDomains }}</strong>
              {getValidPropertyCount(multiDomainErrorInfo) > 1 ? (
                <strong>{{ lastDomain }}</strong>
              ) : null}
              <>
                {{ account: getWorkspaceString(showWorkspaceString, t, WKSPC) }}
              </>
            </Trans>
          </Typography>
        </Box>
      ) : null}
    </Box>
  )
}

AddIDPDetails.propTypes = {
  setMultiDomainErrorInfo: PropTypes.func.isRequired,
  multiDomainErrorInfo: PropTypes.object
}

AddIDPDetails.defaultProps = {
  multiDomainErrorInfo: {}
}

export default AddIDPDetails
