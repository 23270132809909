import { post, get } from '../../../utils/api-utils'

export const SESSION_EXPIRED = {
  errorCode: '404',
  message: 'GLCP Session Not Found, it might be expired'
}

const createSession = async (accessToken, userEmail, workspaceId) => {
  try {
    const response = await post(
      `/support-chat/v1alpha1/sessions`,
      { userEmail, workspaceId },
      accessToken
    )
    return response?.data
  } catch (e) {
    return null
  }
}

const sendUtterance = async (accessToken, sessionId, text) => {
  try {
    const response = await post(
      `/support-chat/v1alpha1/sessions/${sessionId}/utterances`,
      { text },
      accessToken
    )
    return response?.data
  } catch (error) {
    if (
      error?.response?.data?.errorCode === SESSION_EXPIRED.errorCode &&
      error?.response?.data?.message === SESSION_EXPIRED.message
    ) {
      return SESSION_EXPIRED?.message
    }
    return null
  }
}

let attempt = 0
const getResponses = async (accessToken, sessionId, limit, offset) => {
  try {
    const response = await get(
      `/support-chat/v1alpha1/sessions/${sessionId}/responses`,
      { limit, offset },
      accessToken
    )
    if (response?.data?.items?.length === 0 && attempt < 5) {
      attempt += 1
      return getResponses(accessToken, sessionId, limit, offset)
    }
    return response?.data
  } catch (e) {
    return null
  }
}

const chatService = {
  createSession,
  sendUtterance,
  getResponses
}

export default chatService
