import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { PropTypes } from 'prop-types'
import { Box, NameValueList, NameValuePair } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { put } from '../../../../../utils/api-utils'
import { EditLocationModal } from '../../EditLocationModal/EditLocationModal'
import { displayApiError } from '../../../../../utils/error-handling-utils'
import { validateUserInput } from '../../../../manage-account/location-management/location-validation-utils'

import { SectionTitle } from './SectionTitle'

const LocationDetailSection = ({
  id,
  name,
  description,
  type,
  showEditLocationModal,
  setShowEditLocationModal,
  isCCSManager,
  customerId
}) => {
  const { t } = useTranslation(['location'])
  const { oidcUser } = useReactOidc()
  const [requestBody, setRequestBody] = useState({
    name,
    description,
    type
  })

  const [locName, setLocName] = useState(name)
  const [locDescription, setLocDescription] = useState(description)
  const [locType] = useState('building')
  const [showErrorNotification, setShowErrorNotification] = useState(false)
  const [locNameFieldError, setLocNameFieldError] = useState('')
  const [locDescriptionFieldError, setLocDescriptionFieldError] = useState('')
  const LDFlags = useFlags()

  const updateHandler = () => {
    let isFormValid = true
    if (locName.trim() === '') {
      setLocNameFieldError(t('required'))
      isFormValid = false
    } else {
      const fieldValidation = validateUserInput(
        locName,
        LDFlags['glcp-sdi-address-doctor']
      )
      if (fieldValidation !== undefined) {
        setLocNameFieldError(
          t(fieldValidation.message, {
            notAllowedChars: fieldValidation.notAllowedChars
          })
        )
        isFormValid = false
      }
    }
    if (locDescription !== '') {
      const fieldValidation = validateUserInput(
        locDescription,
        LDFlags['glcp-sdi-address-doctor']
      )
      if (fieldValidation !== undefined) {
        setLocDescriptionFieldError(
          t(fieldValidation.message, {
            notAllowedChars: fieldValidation.notAllowedChars
          })
        )
        isFormValid = false
      }
    }
    if (isFormValid) {
      setRequestBody({
        name: locName,
        description: locDescription,
        type: locType
      })

      const url = isCCSManager
        ? `/support-assistant/v1alpha1/location/${id}`
        : `/ui-doorway/ui/v1/locations/${id}`

      put(
        url,
        {
          name: requestBody.name,
          description: requestBody.description,
          type: requestBody.type,
          ...(isCCSManager && {
            platform_customer_id: customerId
          })
        },
        oidcUser.access_token
      ).then(
        () => {
          setShowEditLocationModal(false)
        },
        (err) => {
          setShowErrorNotification(
            displayApiError(err, t, setShowErrorNotification)
          )
        }
      )
    }
  }

  return (
    <Box data-testid="location-details-section">
      <SectionTitle title={t('location:location_details')} />
      <NameValueList
        data-testid="details-name-value-list"
        valueProps={{ width: ['auto', 'large'] }}
      >
        <NameValuePair name={t('name')}>{name}</NameValuePair>
        <NameValuePair name={t('description')}>
          {description || '--'}
        </NameValuePair>
        <NameValuePair name={t('type')}>{type}</NameValuePair>
      </NameValueList>
      {showEditLocationModal && (
        <EditLocationModal
          id={id}
          originalName={name}
          name={locName}
          description={locDescription}
          type={locType}
          handleClose={() => setShowEditLocationModal(false)}
          updateHandler={updateHandler}
          setName={setLocName}
          setDescription={setLocDescription}
          setRequestBody={setRequestBody}
          locNameFieldError={locNameFieldError}
          setLocNameFieldError={setLocNameFieldError}
          locDescriptionFieldError={locDescriptionFieldError}
          setLocDescriptionFieldError={setLocDescriptionFieldError}
        />
      )}
      {showErrorNotification}
    </Box>
  )
}

LocationDetailSection.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  showEditLocationModal: PropTypes.bool.isRequired,
  setShowEditLocationModal: PropTypes.func.isRequired,
  isCCSManager: PropTypes.bool,
  customerId: PropTypes.string
}
LocationDetailSection.defaultProps = {
  customerId: null,
  isCCSManager: false
}

export { LocationDetailSection }
