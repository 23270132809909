import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { analyticsConfig } from '../configs/analytics-config'

import { useCCSContext } from './ccs-context'

/**
 * The purpose of this file is to check for changes in the CCS analytics variable
 * Any changes will update the config that is passed to the AutoAnalyticsProvider
 */
const AmplitudeAnalyticsListener = ({ setConfig }) => {
  const { analytics } = useCCSContext()

  useEffect(() => {
    setConfig({
      customEventPropertiesObject: [
        {
          eventType: 'page-view',
          eventProperties: analytics
        }
      ],
      ...analyticsConfig()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics])
  return <></>
}

AmplitudeAnalyticsListener.propTypes = {
  setConfig: PropTypes.func.isRequired
}

export default AmplitudeAnalyticsListener
