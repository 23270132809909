import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { Anchor, Notification, Typography } from '../../../components'

const RplError = ({ message, supportUrl }) => {
  return (
    <Box flex={{ shrink: 0 }} width="100%" data-testid="gts-error">
      <Notification
        backgroundColor="status-critical"
        testId="customer-account-error"
        pad="xsmall"
        text={
          <Typography type="text" size="medium" testId="gts-error-message">
            <>
              {`${message} `}
              <Anchor
                label="Contact Support"
                size="medium"
                href="#"
                onClick={() => {
                  window.open(supportUrl, '_blank')
                }}
                testId="support-anchor"
              />
            </>
          </Typography>
        }
        type="inline"
      />
    </Box>
  )
}

RplError.propTypes = {
  message: PropTypes.string.isRequired,
  supportUrl: PropTypes.string.isRequired
}

export default RplError
