const defaultRoleDetails = {
  // Must be pre-filled when duplicate is clicked
  roleDetails: {
    name: '',
    description: '',
    appName: '',
    appId: '',
    appLogo: '',
    duplicate: false,
    originalRoleName: '',
    selectedRole: {
      resourcePolicies: []
    }
  }
}
const validateRoleDetailsForm = (formValues) => {
  if (formValues.roleDetails.name === '') {
    return Promise.reject(new Error('Error'))
  }
  return Promise.resolve({ message: 'Success' })
}
const validateDetailsForm = (formValues, t) => {
  const { name } = formValues.roleDetails
  const nameValid = name !== ''
  const formValid = nameValid
  return {
    name: nameValid ? '' : t('roles.wiz_step1_error'),
    isValid: Boolean(formValid)
  }
}
export { validateRoleDetailsForm, defaultRoleDetails, validateDetailsForm }
