import { Box, FormField } from 'grommet'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'
import uniqBy from 'lodash/uniqBy'

import {
  ButtonGroup,
  CCSForm,
  ModalDialog,
  ModalHeader,
  Typography,
  Dropdown
} from '../../../../components'
import { get, post } from '../../../../utils/api-utils'
import {
  getDeviceTypeEnum,
  displayDeviceTypeEnum
} from '../../../../utils/common-utils'
import { getApiErrorMessage } from '../../../../utils/error-handling-utils'

const SetUpAutoSubscriptionModal = ({
  setModalOpen,
  onSuccess,
  tierGroupsObj,
  customerId
}) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()
  const [deviceType, setDeviceType] = useState()
  const [subscriptionTier, setSubscriptionTier] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [deviceOptions, setDeviceOptions] = useState([])

  useEffect(() => {
    const deviceTypeOption = []
    const url = '/support-assistant/v1alpha1/autolicense-groups'
    get(url, { platform_customer_id: customerId }, oidcUser.access_token).then(
      (response) => {
        const uniqueDevices = uniqBy(response?.data, 'device_type')
        uniqueDevices?.forEach((element) => {
          const { device_type: devType } = element || ''
          if (
            !(
              (['COMPUTE', 'DHCI_COMPUTE'].includes(devType) &&
                !LDFlags['glcp-compute-auto-subscribe']) ||
              (devType === 'SENSOR' && !LDFlags['glcp-dm-uxi']) ||
              (devType === displayDeviceTypeEnum.SD_WAN_GW &&
                !LDFlags['glcp-dm-silver-peak'])
            )
          ) {
            deviceTypeOption.push({
              value: devType,
              label: getDeviceTypeEnum(devType, t)
            })
          }
        })
        setDeviceOptions(deviceTypeOption)
      },
      (error) => {
        setErrorMessage(getApiErrorMessage(error, t))
      }
    )
  }, [LDFlags, oidcUser.access_token, t, customerId])

  useEffect(() => {
    setSubscriptionTier('')
  }, [deviceType])

  const handleConfigureDeviceClick = () => {
    const requestData = [
      {
        device_type: deviceType,
        enabled: true,
        auto_license_subscription_tier_group: subscriptionTier
      }
    ]
    const url = '/support-assistant/v1alpha1/autolicense'
    post(
      url,
      {
        platform_customer_id: customerId,
        device_subscription_list: requestData
      },
      oidcUser.access_token
    ).then(
      () => {
        onSuccess()
      },
      (error) => {
        setErrorMessage(getApiErrorMessage(error, t))
      }
    )
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              level="1"
              type="heading"
              style={{ whiteSpace: 'nowrap' }}
              testId="set-up-auto-subscribe-title"
            >
              {t('setup_auto_subscribe')}
            </Typography>
          }
          subtitle={t('setup_auto_subscribe_subtitle')}
          onClose={() => setModalOpen(false)}
        />
      }
      content={
        <Box>
          <CCSForm
            onSubmit={() => handleConfigureDeviceClick()}
            errorMessage={errorMessage}
            testId="set-up-auto-subscribe-form"
          >
            <Box
              margin={{ top: 'large', bottom: 'xxsmall' }}
              direction="column"
              justify="between"
              gap="small"
            >
              <FormField
                data-testid="device-type-form-field"
                label={t('device_type')}
                name="device_type"
                width="medium"
                required
              >
                <Dropdown
                  noBorder
                  testId="device-type-input"
                  name="device_type"
                  value={deviceType}
                  options={deviceOptions}
                  placeholder={t('select_device_type')}
                  onChangeDropdown={(val) => setDeviceType(val)}
                />
              </FormField>
              <FormField
                data-testid="subscription-tier-form-field"
                label={t('subscription_tier')}
                name="subscription_tier"
                width="medium"
                required
              >
                <Dropdown
                  noBorder
                  testId="subscription-tier-input"
                  name="subscription_tier"
                  value={subscriptionTier}
                  options={tierGroupsObj[deviceType]}
                  placeholder={t('select_subscription_tier')}
                  onChangeDropdown={(val) => setSubscriptionTier(val)}
                />
              </FormField>
              <ButtonGroup
                buttonList={[
                  {
                    id: 1,
                    label: t('configure_device'),
                    type: 'submit',
                    primary: true,
                    testId: 'configure-device-button'
                  }
                ]}
                testId="setup-auto-subscribe-modal-action-button"
              />
            </Box>
          </CCSForm>
        </Box>
      }
      testId="setup-auto-subscribe-modal-dialog"
      onClose={() => setModalOpen(false)}
      width="medium"
    />
  )
}

SetUpAutoSubscriptionModal.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  tierGroupsObj: PropTypes.object.isRequired,
  customerId: PropTypes.string.isRequired
}

export { SetUpAutoSubscriptionModal }
