// Copyright 2024 Hewlett Packard Enterprise Development LP

const defaultUPSConfig = {
  commonpreferences: [
    {
      id: '',
      groupName: 'homePagePreferences',
      level: 'workspace',
      name: 'showGettingStarted',
      type: 'boolean',
      title: 'Enable Getting Started section on home page',
      value: true
    },
    {
      id: '',
      groupName: 'homePagePreferences',
      level: 'workspace',
      name: 'showWhatsNew',
      type: 'boolean',
      title: 'Enable Whats New section on home page',
      value: true
    },
    {
      groupName: 'homePagePreferences',
      level: 'workspace',
      name: 'showWidgets',
      type: 'boolean',
      title: 'Enable Widgets on the home page',
      value: false
    }
  ],
  quicklinks: [
    {
      linkKey: 'manage-workspace',
      visible: true
    },
    {
      linkKey: 'device-inventory',
      visible: true
    },
    {
      linkKey: 'service-subscriptions',
      visible: true
    },
    {
      linkKey: 'create-customer-account',
      visible: true
    },
    {
      linkKey: 'user-management',
      visible: true
    },
    {
      linkKey: 'create-location',
      visible: true
    },
    {
      linkKey: 'switch-workspace',
      visible: true
    },
    {
      linkKey: 'reporting',
      visible: true
    },
    {
      linkKey: 'feedback',
      visible: true
    },
    {
      linkKey: 'support-hub',
      visible: true
    }
  ],
  homewidgets: [
    {
      widgetKey: 'widgets-user-overview',
      visible: false,
      order: 1
    },
    {
      widgetKey: 'widgets-subscription-overview',
      visible: false,
      order: 2
    },
    {
      widgetKey: 'widgets-device-summary',
      visible: false,
      order: 3
    }
  ],
  supportAccessManager: [
    {
      groupName: 'supportAccessManager',
      name: 'autoAccessApprovalRead',
      title: 'Automatic advanced read-only access approval',
      type: 'bool',
      value: true
    }
  ]
}
export { defaultUPSConfig }
