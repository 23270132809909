import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { validateIdleSessionTimeout } from '../../../utils/validation-utils'
import { FormInput } from '../../../components'

export const PreferencesIdleTimeout = ({
  dispatch,
  state,
  minIdleTimeout,
  maxIdleTimeout
}) => {
  const { t } = useTranslation(['authn'])
  return (
    <FormInput
      label={t('preferences.idle_timeout')}
      required
      testId="timeout-number-form-field-input"
      value={state.timeout_number ?? ''}
      name="timeout_number"
      placeholder={t('preferences.idle_placeholder')}
      onChange={(event) =>
        dispatch({
          value: event.target.value,
          field: 'timeout_number',
          type: 'CHANGE_FIELD'
        })
      }
      validate={() => {
        return validateIdleSessionTimeout(
          state?.timeout_number,
          minIdleTimeout,
          maxIdleTimeout,
          t('preferences.invalid_idle_timeout')
        )
      }}
    />
  )
}
PreferencesIdleTimeout.propTypes = {
  dispatch: PropTypes.object,
  state: PropTypes.string,
  minIdleTimeout: PropTypes.number,
  maxIdleTimeout: PropTypes.number
}

PreferencesIdleTimeout.defaultProps = {
  dispatch: {},
  state: '',
  minIdleTimeout: 0,
  maxIdleTimeout: 0
}
