import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'
import { StatusCritical } from 'grommet-icons'
import omitBy from 'lodash/omit'

import {
  DataTable,
  FilterButton,
  Notification
} from '../../../../../components'
import { get, getErrorMessage } from '../../../../../utils/api-utils'
import { getPaginationShowIdx } from '../../../../../utils/common-utils'
import { getAppInstanceDetails, getAppInstances } from '../utils'

import { InstanceDetailsModal } from './InstanceDetailsModal'

const AppInstancesDataTable = ({ appId }) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage', 'common'])

  const [appInstanceData, setAppInstanceData] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [filterOptions, setFilterOptions] = useState({})
  const [regionList, setRegionList] = useState([])
  const [selectedAppInstance, setSelectedAppInstance] = useState(null)
  const [showInstanceDetailsModal, setShowInstanceDetailsModal] =
    useState(false)

  // for pagination
  const itemsPerPage = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  const columns = [
    {
      property: 'instance_id',
      type: 'string',
      header: t('applications.instance_id'),
      primary: true
    },
    {
      property: 'short_name',
      type: 'string',
      header: t('applications.short_name')
    },
    {
      property: 'ccs_region',
      type: 'string',
      header: t('applications.region'),
      render: (datum) =>
        regionList.find((value) => value?.code === datum?.ccs_region)?.name ||
        datum.ccs_region ||
        '--'
    },
    {
      property: 'status',
      type: 'string',
      header: t('applications.status')
    },
    ...(appId
      ? [
          {
            property: 'summary',
            type: 'string',
            header: t('applications.summary')
          }
        ]
      : [
          {
            property: 'app_id',
            type: 'string',
            header: t('applications.application_id')
          }
        ])
  ]

  useEffect(() => {
    if (!regionList.length) {
      get('/geo/ui/v1/regions', {}, oidcUser.access_token).then(
        (response) => {
          const { regions } = response.data
          if (regions) {
            setRegionList(regions)
          }
        },
        (error) => {
          setErrorMessage(getErrorMessage(error, t))
        }
      )
    }
  }, [oidcUser.access_token, regionList, t])

  useEffect(() => {
    let isCurrent = true
    let request = {}

    if (searchVal.trim().length > 0) {
      // if search value is provided, then search by app_instance_id,
      // get app instance details API
      getAppInstanceDetails({
        appInstanceId: searchVal,
        token: oidcUser.access_token,
        setErrorMessage,
        t
      }).then((data) => {
        if (!isCurrent) return
        if (data) {
          setAppInstanceData([data])
          setTotalItems(1)
        } else {
          setAppInstanceData([])
          setTotalItems(0)
        }
      })
    } else {
      request = {
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        ...(Object.keys(filterOptions).length > 0 && filterOptions),
        ...(appId && { appid_or_slug: appId })
      }
      getAppInstances({
        request,
        token: oidcUser.access_token,
        setErrorMessage,
        t
      }).then((data) => {
        if (!isCurrent) return
        setAppInstanceData(data?.instances)
        setTotalItems(data?.pagination?.total_count)
      })
    }
    return () => {
      isCurrent = false
    }
  }, [oidcUser.access_token, searchVal, page, t, filterOptions, appId])

  return (
    <Box margin={{ vertical: 'small' }}>
      <DataTable
        pagination={{
          totalItems,
          itemsPerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        grid={{
          columns,
          data: appInstanceData,
          onClickRow: ({ datum }) => {
            setSelectedAppInstance(datum)
            setShowInstanceDetailsModal(true)
          }
        }}
        search={{
          onSearchValueChange: (value) => {
            setPage(1)
            setSearchVal(value)
          },
          placeholder: t('applications.search_by_instance_id')
        }}
        filterButton={
          <FilterButton
            filterAttributes={[
              {
                label: t('applications.status'),
                name: 'status',
                selectionType: 'radio',
                values: [
                  { valueLabel: t('applications.UP'), valueName: 'UP' },
                  { valueLabel: t('applications.DOWN'), valueName: 'DOWN' },
                  { valueLabel: t('applications.HIDDEN'), valueName: 'HIDDEN' },
                  {
                    valueLabel: t('applications.ONBOARDED'),
                    valueName: 'ONBOARDED'
                  },
                  {
                    valueLabel: t('applications.ONBOARDING'),
                    valueName: 'ONBOARDING'
                  },
                  {
                    valueLabel: t('applications.ONBOARDING_FAILED'),
                    valueName: 'ONBOARDING_FAILED'
                  }
                ]
              },
              ...(appId
                ? []
                : [
                    {
                      label: t('applications.appid_or_slug'),
                      name: 'appid_or_slug',
                      selectionType: 'text'
                    }
                  ]),
              {
                label: t('applications.short_name'),
                name: 'short_name',
                selectionType: 'text'
              },
              {
                label: t('applications.region'),
                name: 'ccs_region',
                selectionType: 'dropdown',
                values: regionList.map((data) => ({
                  valueLabel: data?.name,
                  valueName: data?.code
                }))
              }
            ]}
            onFilterValuesChange={(filters) => {
              const formattedValues = Object.fromEntries(
                Object.entries(filters).map(([key, val]) => [
                  key,
                  Array.isArray(val)
                    ? val.map((v) => v.trimStart()).join()
                    : val.trimStart()
                ])
              )
              setFilterOptions(omitBy(formattedValues, (v) => v.length === 0))
              setPage(1)
            }}
            testId="app-instances-page-filter-btn"
          />
        }
        summary={{ entityName: t('applications.app_instances') }}
        testId="app-instance-data-table"
      />
      {showInstanceDetailsModal && (
        <InstanceDetailsModal
          instanceDetails={selectedAppInstance}
          setShowModal={setShowInstanceDetailsModal}
        />
      )}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}

AppInstancesDataTable.propTypes = {
  appId: PropTypes.string
}

AppInstancesDataTable.defaultProps = {
  appId: undefined
}

export { AppInstancesDataTable }
