import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { MenuList } from '../../../../components'
import { customRenderer } from '../../CustomRenderer'

const GLCPManagerNav = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { t } = useTranslation(['common'])
  const mainMenuData = [
    {
      id: 1,
      label: t('header_nav.dashboard'),
      routeTo: '/manage-ccs/home',
      testId: 'dashboard-nav-menu'
    }
  ]
  const getSelectedMenu = () => {
    const data = mainMenuData.find((item) => {
      return item.routeTo === pathname
    })
    return data && data.id
  }

  const MainNavigation = (
    <MenuList
      defaultActiveId={getSelectedMenu()}
      navStyles={{
        padding: 'none',
        gap: 'medium',
        direction: 'row'
      }}
      highlightOnSelect
      menuData={mainMenuData}
      menuItemStyles={{
        weight: 'bold'
      }}
      onClickMenuItem={(item) => {
        history.push(item.routeTo)
      }}
      testId="glcp_main_menu"
      customRenderer={customRenderer}
    />
  )
  return MainNavigation
}
export { GLCPManagerNav }
