import React, { useEffect, useReducer, useState } from 'react'
import { Box, FormField, Select, TextArea, TextInput } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Trash } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  CCSForm,
  Tile,
  Typography,
  ButtonGroup,
  FileInput,
  Button
} from '../../../../../components'
import { getErrorMessage, put, get } from '../../../../../utils/api-utils'
import {
  fileToBase64,
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../../../utils/common-utils'
import {
  validateCity,
  validateDescription,
  validateEmail,
  validateStreetAddress,
  validateZipCode,
  validateFileInput,
  ALLOWED_IMAGE_TYPES,
  IMAGE_FILE_SIZE
} from '../../../../../utils/validation-utils'
import { AccountLogo } from '../../../../commoncomponents/account-logo/AccountLogo'

const StyledFormField = styled(FormField)`
  & > div {
    border: none;
  }
`
// We should uncomment once AM reached pavo
const EditCustomerAccount = ({
  customerAccountDetails,
  customerId,
  getCustomerDetails,
  handleDiscard,
  logoDetails,
  submitted,
  setSubmitted
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const history = useHistory()
  const { oidcUser } = useReactOidc()
  const [listOfCountries, setListOfCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [originalState, setOriginalState] = useState({})
  const [originalLogo, setOriginalLogo] = useState({})
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value }
        case 'SET_STATE':
          return { ...action.value }
        case 'CHANGE_LOGO':
          return {
            ...currentState,
            logo: action.value?.[0] || null,
            logoFilename: action.value?.[1] || null
          }
        default:
          return currentState
      }
    },
    {
      companyName: '',
      description: '',
      countryCode: '',
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
      email: '',
      phone: '',
      logo: '',
      logoFilename: ''
    }
  )
  const accountLogoDetails = {
    ...customerAccountDetails,
    customer_logo: {
      logo: customerAccountDetails.logo,
      logo_filename: customerAccountDetails.logoFilename
    }
  }

  useEffect(() => {
    let isCurrent = true
    if (customerAccountDetails) {
      setOriginalLogo({
        logo: logoDetails?.logo || '',
        logoFilename: logoDetails?.logoFilename || ''
      })

      setOriginalState(JSON.parse(JSON.stringify(customerAccountDetails)))
      const updatedCustomerDetails = {
        ...customerAccountDetails,
        logo: null,
        logoFilename: null
      }

      dispatch({
        value: updatedCustomerDetails,
        type: 'SET_STATE'
      })
      if (!isCurrent) return
    }
    // eslint-disable-next-line consistent-return
    return () => {
      isCurrent = false
    }
  }, [customerAccountDetails, logoDetails])

  useEffect(() => {
    get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      (response) => {
        setListOfCountries(response.data.countries)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }, [t, state.countryCode])

  const getCountryName = (code) => {
    if (listOfCountries.length !== 0) {
      const countriesData = [...listOfCountries]
      const filteredCountries = countriesData.filter(
        (country) => country.code === code
      )
      return filteredCountries.length > 0 ? filteredCountries[0].name : ''
    }
    return ''
  }

  const setLogo = async (requestBody, logo) => {
    if (logo instanceof File) {
      const customerLogo = {
        logo: await fileToBase64(logo),
        logo_filename: logo.name
      }
      requestBody.customer_logo = customerLogo
    } else if (originalState?.logo) {
      if (logo) {
        requestBody.customer_logo = {
          logo: originalState.logo,
          logo_filename: originalState.logoFilename
        }
      } else {
        requestBody.customer_logo = { logo: null, logo_filename: null }
      }
    }
  }

  const updateProfileDetails = async () => {
    setSubmitted(true)
    const requestBody = {
      customer_id: customerId,
      company_name: state.companyName,
      description: state.description,
      address: {
        street_address: state.streetAddress,
        city: state.city?.trim(),
        state_or_region: state.state?.trim(),
        zip: state.zip?.trim(),
        country_code: state.countryCode
      },
      email: state.email === '' ? null : state.email,
      phone_number: state.phone === '' ? null : state.phone
    }
    const newLogo = state.logo ? state.logo : originalLogo
    if (state.logo || originalLogo) await setLogo(requestBody, newLogo)
    put(
      `/accounts/ui/v1/managed-service/tenant`,
      requestBody,
      oidcUser.access_token
    ).then(
      () => {
        history.push(`/customer-account/view/${customerId}`)
        getCustomerDetails()
        setSubmitted(false)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
        setSubmitted(false)
      }
    )
  }

  return (
    <CCSForm
      errorMessage={errorMessage}
      testId="edit-customer-form"
      validate="blur"
      onSubmit={updateProfileDetails}
    >
      <>
        <Box
          margin={{ left: 'large' }}
          border="bottom"
          pad={{ bottom: 'small' }}
        >
          <Tile
            key={customerId}
            testId="customer-account-detail"
            boxShadow={false}
            layout="row"
            actionBtn={
              <Box direction="row" gap="medium">
                <ButtonGroup
                  buttonList={[
                    {
                      id: 1,
                      label: 'Discard Changes',
                      secondary: true,
                      testId: 'discard-changes-btn',
                      onClick: handleDiscard,
                      disabled: submitted
                    },
                    {
                      id: 2,
                      label: 'Save Changes',
                      primary: true,
                      testId: 'save-changes-btn',
                      type: 'submit',
                      isLoading: submitted
                    }
                  ]}
                  testId="two-buttons"
                />
              </Box>
            }
            logo={
              <AccountLogo
                account={accountLogoDetails}
                avatarSize="large"
                title={customerAccountDetails.companyName}
                testId="tenant-detail"
              />
            }
            title={
              <Typography
                level="2"
                testId="customer-account-company-name"
                type="heading"
                weight="bold"
              >
                {customerAccountDetails.companyName}
              </Typography>
            }
          />
        </Box>
        <Box
          gridArea="content"
          direction="column"
          margin={{ left: 'large', right: 'xlarge', top: 'medium' }}
        >
          <Box border="bottom" direction="row" pad={{ bottom: 'small' }}>
            <Typography
              type="heading"
              weight="bold"
              level="3"
              testId="account-details-title"
            >
              {t('customer_account.account_details', {
                account: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_CAPITALIZED
                )
              })}
            </Typography>
          </Box>
          <Box direction="row" gap="large" margin={{ top: 'small' }}>
            <Box basis="medium">
              <Box direction="column" gap="xsmall" margin={{ bottom: 'small' }}>
                <StyledFormField
                  // width="medium"
                  label={t('customer_account.account_image', {
                    account: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  })}
                  name="account_image"
                  data-testid="edit-account-image-input"
                  validate={() =>
                    validateFileInput(
                      state.logo,
                      IMAGE_FILE_SIZE.logo,
                      ALLOWED_IMAGE_TYPES,
                      t
                    )
                  }
                  disabled={submitted}
                >
                  <FileInput
                    testId="account-image"
                    name="account_image"
                    accept={ALLOWED_IMAGE_TYPES}
                    onChange={(event) => {
                      dispatch({
                        value:
                          event.target.files && event.target.files.length
                            ? [
                                event.target.files[0],
                                event.target.files[0].name
                              ]
                            : null,
                        type: 'CHANGE_LOGO'
                      })
                    }}
                    disabled={submitted}
                  />
                </StyledFormField>
                {originalLogo?.logo && !state.logo ? (
                  <Box direction="row" align="center" justify="between">
                    <Typography
                      type="text"
                      size="medium"
                      testId="account-details-logo-filename"
                    >
                      {originalLogo.logoFilename}
                    </Typography>
                    <Button
                      onClick={() => {
                        dispatch({
                          value: null,
                          field: 'logo',
                          type: 'CHANGE_FIELD'
                        })
                        setOriginalLogo(null)
                      }}
                      testId="delete-logo-filename"
                      disabled={submitted}
                    >
                      <Trash size="medium" />
                    </Button>
                  </Box>
                ) : null}
              </Box>

              <FormField
                label={t('customer_account.company_name', {
                  company: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
                name="companyName"
                required
                data-testid="edit-company-name-form-field"
                disabled={submitted}
              >
                <TextInput
                  value={state.companyName}
                  name="companyName"
                  placeholder={t('customer_account.company_placeholder')}
                  data-testid="edit-company-name-input"
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'companyName',
                      type: 'CHANGE_FIELD'
                    })
                  }
                  disabled={submitted}
                />
              </FormField>
              <FormField
                label={t('customer_account.description')}
                name="description"
                validate={(value) => {
                  if (validateDescription(value))
                    return t('customer_account.description_error_message')
                  return true
                }}
                data-testid="edit-description-form-field"
                disabled={submitted}
              >
                <TextArea
                  size="large"
                  fill
                  data-testid="edit-description-input"
                  name="description"
                  placeholder={t('customer_account.description_placeholder')}
                  value={state.description}
                  onChange={(e) => {
                    dispatch({
                      value: e.target.value,
                      field: 'description',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  disabled={submitted}
                />
              </FormField>
            </Box>
            <Box basis="medium">
              <FormField
                label={t('customer_account.country')}
                name="countryCode"
                required
                data-testid="edit-country-form-field"
                validate={(value) => {
                  if (!value)
                    return t(
                      'account_type.check_eligibility_step_1.step_required'
                    )
                  return true
                }}
                disabled={submitted}
              >
                <Select
                  name="countryCode"
                  defaultValue={getCountryName(state.countryCode)}
                  placeholder={t('customer_account.country_placeholder')}
                  options={listOfCountries}
                  multiple={false}
                  labelKey="name"
                  value={state.countryCode}
                  valueKey={{ key: 'code', reduce: true }}
                  searchPlaceholder="Country"
                  emptySearchMessage={t(
                    'customer_account.country_empty_search_message'
                  )}
                  onSearch={(searchText) => {
                    const regexp = new RegExp(searchText, 'i')
                    setListOfCountries(
                      listOfCountries.filter((o) => o.name.match(regexp))
                    )
                  }}
                  onChange={({ option }) => {
                    dispatch({
                      value: option.code,
                      field: 'countryCode',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  onClose={() => setListOfCountries(listOfCountries)}
                  disabled={submitted}
                />
              </FormField>
              <FormField
                label={t('customer_account.company')}
                name="streetAddress"
                margin={{ bottom: 'none' }}
                required
                validate={(value) => {
                  if (!value)
                    return t(
                      'account_type.check_eligibility_step_1.step_required'
                    )
                  if (!validateStreetAddress(value))
                    return t('customer_account.max_chars_exceeded')
                  return true
                }}
                data-testid="edit-street-address-form-field"
                disabled={submitted}
              >
                <TextInput
                  value={state.streetAddress}
                  name="streetAddress"
                  placeholder={t('customer_account.street_address')}
                  data-testid="edit-street-address-input"
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'streetAddress',
                      type: 'CHANGE_FIELD'
                    })
                  }
                  disabled={submitted}
                />
              </FormField>

              <Box direction="row-responsive" gap="xsmall">
                <Box direction="column" basis="1/2" height={{ min: 'auto' }}>
                  <FormField
                    name="city"
                    data-testid="edit-city-form-field"
                    validate={(city) => {
                      if (city && !validateCity(city))
                        return t('customer_account.max_chars_exceeded_city')
                      return true
                    }}
                    disabled={submitted}
                  >
                    <TextInput
                      value={state.city}
                      name="city"
                      placeholder={t('customer_account.city')}
                      data-testid="edit-city-input"
                      onChange={(event) =>
                        dispatch({
                          value: event.target.value,
                          field: 'city',
                          type: 'CHANGE_FIELD'
                        })
                      }
                      disabled={submitted}
                    />
                  </FormField>
                </Box>
                <Box direction="column" basis="1/2" height={{ min: 'auto' }}>
                  <FormField
                    name="state"
                    data-testid="edit-state-form-field"
                    disabled={submitted}
                  >
                    <TextInput
                      value={state.state}
                      name="state"
                      placeholder={t('customer_account.state')}
                      data-testid="edit-state-input"
                      onChange={(event) =>
                        dispatch({
                          value: event.target.value,
                          field: 'state',
                          type: 'CHANGE_FIELD'
                        })
                      }
                      disabled={submitted}
                    />
                  </FormField>
                </Box>
              </Box>

              <FormField
                label={t('customer_account.zip')}
                name="zip"
                validate={(value) => {
                  if (value && !validateZipCode(value.trim()))
                    return t('customer_account.max_chars_exceeded')
                  return true
                }}
                data-testid="edit-zip-code-form-field"
                disabled={submitted}
              >
                <TextInput
                  value={state.zip}
                  name="zip"
                  placeholder={t('customer_account.zip_placeholder')}
                  data-testid="edit-zip-code-input"
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'zip',
                      type: 'CHANGE_FIELD'
                    })
                  }
                  disabled={submitted}
                />
              </FormField>
            </Box>
            <Box basis="medium">
              <FormField
                label={t('customer_account.email')}
                name="email"
                validate={(value) => {
                  return validateEmail(value)
                }}
                data-testid="edit-email-form-field"
                disabled={submitted}
              >
                <TextInput
                  value={state.email}
                  name="email"
                  placeholder={t('customer_account.email_placeholder')}
                  data-testid="edit-email-input"
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'email',
                      type: 'CHANGE_FIELD'
                    })
                  }
                  disabled={submitted}
                />
              </FormField>
              <FormField
                label={t('customer_account.phone_number')}
                name="phone"
                data-testid="edit-phone-form-field"
                disabled={submitted}
              >
                <TextInput
                  value={state.phone}
                  name="phone"
                  placeholder={t('customer_account.phone_placeholder')}
                  data-testid="edit-phone-input"
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'phone',
                      type: 'CHANGE_FIELD'
                    })
                  }
                  disabled={submitted}
                />
              </FormField>
            </Box>
          </Box>
        </Box>
      </>
    </CCSForm>
  )
}

EditCustomerAccount.propTypes = {
  customerAccountDetails: PropTypes.object.isRequired,
  customerId: PropTypes.string.isRequired,
  getCustomerDetails: PropTypes.func.isRequired,
  handleDiscard: PropTypes.func.isRequired,
  logoDetails: PropTypes.object,
  setSubmitted: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired
}
EditCustomerAccount.defaultProps = {
  logoDetails: {}
}

export default EditCustomerAccount
