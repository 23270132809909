const AUTHZActions = {
  SET_SELECTED_ROLE: 'SET_SELECTED_ROLE',
  SET_SELECTED_ROLE_DETAILS: 'SET_SELECTED_ROLE_DETAILS',
  CLEAR_SELECTED_ROLE_DETAILS: 'CLEAR_SELECTED_ROLE_DETAILS',
  SET_SELECTED_ROLE_UPDATE_FORM: 'SET_SELECTED_ROLE_UPDATE_FORM',
  CLEAR_SELECTED_ROLE_UPDATE_FORM: 'CLEAR_SELECTED_ROLE_UPDATE_FORM',
  PERMISSIONS_SELECTED: 'PERMISSIONS_SELECTED',
  CLEAR_PERMISSIONS_DATA: 'CLEAR_PERMISSIONS_DATA',
  SET_ROLES_DATA: 'SET_ROLES_DATA',
  SET_PERMISSIONS_DATA: 'SET_PERMISSIONS_DATA',
  SET_CREATE_ROLE_DATA: 'SET_CREATE_ROLE_DATA',
  SET_ROLE_APP_MAP_DATA: 'SET_ROLE_APP_MAP_DATA',
  SET_ROLES_SELECTED: 'SET_ROLES_SELECTED',
  SET_SCOPES_SELECTED: 'SET_SCOPES_SELECTED',
  SET_SCOPES_DATA: 'SET_SCOPES_DATA',
  SET_TREE_ROLE_ASSIGNMENTS: 'SET_TREE_ROLE_ASSIGNMENTS',
  SET_ROLE_ACCESS_RULES: 'SET_ROLE_ACCESS_RULES',
  SET_TREE_SCOPE_ASSIGNMENTS: 'SET_TREE_SCOPE_ASSIGNMENTS',
  SET_TREE_ROLE_PERMISSIONS: 'SET_TREE_ROLE_PERMISSIONS',
  CLEAR_TREE_SCOPE_ASSIGNMENTS: 'CLEAR_TREE_SCOPE_ASSIGNMENTS',
  CLEAR_CREATE_ASSIGNMENT_SELECTIONS: 'CLEAR_CREATE_ASSIGNMENT_SELECTIONS',
  SET_USERS: 'SET_USERS',
  SET_SELECTED_USERS: 'SET_SELECTED_USERS',
  SET_PROV_APPS_LIST: 'SET_PROV_APPS_LIST',
  SET_ROLE_ASSIGNMENT_DATA: 'SET_ROLE_ASSIGNMENT_DATA',
  SET_SCOPE_GROUPS_LIST_LENGTH: 'SET_SCOPE_GROUPS_LIST_LENGTH',
  CLEAR_SCOPE_GROUP_TABLE_DATA: 'CLEAR_SCOPE_GROUP_TABLE_DATA',
  SET_RRP_DETAILS: 'SET_RRP_DETAILS',
  SET_SCOPE_DATA_MAP: 'SET_SCOPE_DATA_MAP',
  CLEAR_SCOPE_DATA_MAP: 'CLEAR_SCOPE_DATA_MAP',
  SET_ON_MODAL_SCOPES_MAP: 'SET_ON_MODAL_SCOPES_MAP',
  CLEAR_SCOPE_MODAL_DATA: 'CLEAR_SCOPE_MODAL_DATA',
  CLEAR_PREDEFINED_FILTERS: 'CLEAR_PREDEFINED_FILTERS',
  SET_SCOPE_RESOURCE_INSTANCES: 'SET_SCOPE_RESOURCE_INSTANCES',
  CLEAR_SCOPE_RESOURCE_INSTANCES: 'CLEAR_SCOPE_RESOURCE_INSTANCES',
  SET_MANDATORY_RES_PERMISSIONS: 'SET_MANDATORY_RES_PERMISSIONS',
  SET_USER_CCS_ROLES: 'SET_USER_CCS_ROLES'
}

export default AUTHZActions
