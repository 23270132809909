import { React, Suspense, lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

const IPAccessRules = lazy(() => import('./IPAccessRules'))

const IPAccessRulesRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route path="/manage-account/ip-access-rules">
          <IPAccessRules />
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  )
}

export default IPAccessRulesRouter
