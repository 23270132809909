// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'

import StandaloneSearchBox from '../../support-search/components/StandaloneSearchBox/StandaloneSearchBox'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { LINKS, getLink } from '../utils/links-util'

import { TilesRow } from './common/TilesRow'

const KnowledgeSection = memo(() => {
  const { t } = useTranslation(['support_hub'])

  const knowledgeTiles = [
    {
      id: 1,
      title: t('knowledge_cards.manuals.title'),
      description: t('knowledge_cards.manuals.description'),
      testId: 'manuals-tile',
      href: getLink(LINKS.MANUALS)
    },
    {
      id: 2,
      title: t('knowledge_cards.release_notes.title'),
      description: t('knowledge_cards.release_notes.description'),
      testId: 'release_notes-tile',
      href: getLink(LINKS.RELEASE_NOTE)
    },
    {
      id: 3,
      title: t('knowledge_cards.daac.title'),
      description: t('knowledge_cards.daac.description'),
      testId: 'daac-tile',
      href: getLink(LINKS.DEV_API_CONTENT)
    }
  ]

  return (
    <VisibilityWrapper
      hideFor={{
        feature: 'glcp-support-search',
        deployment: ['COP', 'GLOP']
      }}
    >
      <Box gap="large">
        <StandaloneSearchBox />
        <TilesRow
          title={t('knowledge_cards.knowledge')}
          testIdTitle="knowledge-test-id"
          rows={knowledgeTiles}
        />
      </Box>
    </VisibilityWrapper>
  )
})

export { KnowledgeSection }
