import { FormField, TextArea } from 'grommet'
import { useTranslation } from 'react-i18next'

import { validateUserInputStrict } from '../../../utils/validation-utils'

const DetailsField = () => {
  const { t } = useTranslation(['support_hub_feedback'])
  return (
    <FormField
      required
      name="details"
      htmlFor="details"
      label={t('details_field.label')}
      validate={[
        (value) => {
          let result
          if (validateUserInputStrict(value)) {
            result = t('details_field.error')
          }
          return result
        },
        {
          max: 2000,
          threshold: 0.8
        }
      ]}
      data-testid="details-field"
    >
      <TextArea
        id="details"
        name="details"
        placeholder={t('details_field.placeholder')}
      />
    </FormField>
  )
}

export { DetailsField }
