import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'
import lodashGet from 'lodash/get'

import { Typography, Button, ModalDialog } from '../../../../components'

const DeleteUserModal = ({
  onSetOpen,
  onConfirmDelete,
  selectedUsers,
  selfDelete
}) => {
  const { t } = useTranslation(['authz'])
  return (
    <ModalDialog
      width="medium"
      testId="delete-user-modal-dialog"
      content={
        <Box gap="xsmall">
          {selfDelete ? (
            <Typography type="heading" level={2} testId="delete-title">
              {selectedUsers.length > 1
                ? t('self-delete.delete_users')
                : t('self-delete.delete_user')}
            </Typography>
          ) : (
            <Typography type="heading" level={2} testId="delete-title">
              {selectedUsers.length > 1
                ? t('delete_users_header')
                : t('delete_user_header')}
            </Typography>
          )}
          {selfDelete ? (
            <Typography type="text" testId="self-delete-prevent-message">
              {selectedUsers.length > 1
                ? t('self-delete.self_delete_prevention_2')
                : t('self-delete.self_delete_prevention_1')}
            </Typography>
          ) : (
            <Typography type="text" testId="delete-message">
              {selectedUsers.length > 1 ? (
                t('delete_modal_bulk_users')
              ) : (
                <>
                  {t('delete_modal')}{' '}
                  <Text type="text" weight={500} color="text-strong">
                    {lodashGet(selectedUsers[0], 'full_name')}
                  </Text>
                  ?
                </>
              )}
            </Typography>
          )}
        </Box>
      }
      footer={
        <Box direction="row" justify="end" gap="medium">
          {selfDelete ? (
            <Button
              primary
              label={t('self-delete.close')}
              onClick={() => {
                onSetOpen(false)
              }}
              testId="close-btn"
            />
          ) : (
            <>
              {' '}
              <Button
                default
                label={t('cancel')}
                onClick={() => {
                  onSetOpen(false)
                }}
                testId="cancel-delete-btn"
              />
              <Button
                primary
                type="submit"
                label={
                  selectedUsers.length > 1
                    ? t('delete_users')
                    : t('delete_user_btn')
                }
                testId="delete-user-btn"
                onClick={() => {
                  onSetOpen(false)
                  onConfirmDelete(true)
                }}
              />{' '}
            </>
          )}
        </Box>
      }
      onClose={() => onSetOpen(false)}
    />
  )
}

DeleteUserModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  selfDelete: PropTypes.bool.isRequired
}

export default DeleteUserModal
