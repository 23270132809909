import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'
import { StatusCritical } from 'grommet-icons'
import omitBy from 'lodash/omitBy'
import { useHistory } from 'react-router-dom'

import {
  AvatarInfo,
  DataTable,
  FilterButton,
  Notification,
  Typography
} from '../../../../../components'
import { getPaginationShowIdx } from '../../../../../utils/common-utils'
import { getApplicationDetails, getApplications } from '../utils'

const ApplicationsDataTable = () => {
  const history = useHistory()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage', 'common'])

  const [appsData, setAppsData] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [filterOptions, setFilterOptions] = useState({})

  // for pagination
  const itemsPerPage = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  const columns = [
    {
      property: 'name',
      type: 'string',
      header: t('name'),
      render: (datum) => (
        <Box direction="row" align="center" gap="small">
          <Box flex={{ shrink: 0 }}>
            <AvatarInfo
              avatarChar="one"
              primaryInfo={datum?.name}
              secondaryInfo=""
              testId="app-logo"
              avatarOnly
              {...(datum?.logo && { avatarSrc: datum?.logo })}
            />
          </Box>
          <Typography type="text" size="medium" weight="bold">
            {datum?.name}
          </Typography>
        </Box>
      )
    },
    {
      property: 'app_id',
      type: 'string',
      header: t('applications.application_id')
    },
    {
      property: 'company_name',
      type: 'string',
      header: t('applications.company_name')
    },
    {
      property: 'status',
      type: 'string',
      header: t('applications.status')
    }
  ]

  useEffect(() => {
    let isCurrent = true
    let request = {}

    if (searchVal.trim().length > 0) {
      // if search value is provided, then search by app_id,
      // get application details API
      getApplicationDetails({
        applicationId: searchVal,
        token: oidcUser.access_token,
        setErrorMessage,
        t
      }).then((data) => {
        if (!isCurrent) return
        if (data) {
          setAppsData([data])
          setTotalItems(1)
        } else {
          setAppsData([])
          setTotalItems(0)
        }
      })
    } else {
      request = {
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        ...(Object.keys(filterOptions).length > 0 && filterOptions)
      }
      getApplications({
        request,
        token: oidcUser.access_token,
        setErrorMessage,
        t
      }).then((data) => {
        if (!isCurrent) return
        setAppsData(data?.app_list)
        setTotalItems(data?.paginate?.total_count)
      })
    }
    return () => {
      isCurrent = false
    }
  }, [oidcUser.access_token, searchVal, page, t, filterOptions])

  return (
    <Box margin={{ vertical: 'small' }}>
      <DataTable
        pagination={{
          totalItems,
          itemsPerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        grid={{
          columns,
          data: appsData,
          pad: { body: { horizontal: 'small', vertical: 'small' } },
          onClickRow: ({ datum }) => {
            history.push({
              pathname: `/manage-ccs/applications/${datum.app_id}`
            })
          }
        }}
        search={{
          onSearchValueChange: (value) => {
            setPage(1)
            setSearchVal(value)
          },
          placeholder: t('applications.search_by_application_id')
        }}
        filterButton={
          <FilterButton
            filterAttributes={[
              {
                label: t('applications.status'),
                name: 'status',
                selectionType: 'radio',
                values: [
                  {
                    valueLabel: t('applications.ONBOARDING'),
                    valueName: 'ONBOARDING'
                  },
                  {
                    valueLabel: t('applications.ONBOARDED'),
                    valueName: 'ONBOARDED'
                  },
                  {
                    valueLabel: t('applications.REVIEWING'),
                    valueName: 'REVIEWING'
                  },
                  {
                    valueLabel: t('applications.ONBOARDING_FAILED'),
                    valueName: 'ONBOARDING_FAILED'
                  },
                  {
                    valueLabel: t('applications.PUBLISHED'),
                    valueName: 'PUBLISHED'
                  },
                  { valueLabel: t('applications.HIDDEN'), valueName: 'HIDDEN' }
                ]
              },
              {
                label: t('applications.msp_supported'),
                name: 'msp_supported',
                selectionType: 'radio',
                values: [
                  { valueLabel: t('applications.true'), valueName: 'true' },
                  { valueLabel: t('applications.false'), valueName: 'false' }
                ]
              }
            ]}
            onFilterValuesChange={(filters) => {
              const formattedValues = Object.fromEntries(
                Object.entries(filters).map(([key, val]) => [
                  key,
                  val.map((v) => v.trimStart()).join()
                ])
              )
              setFilterOptions(omitBy(formattedValues, (v) => v.length === 0))
              setPage(1)
            }}
            testId="applications-page-filter-btn"
          />
        }
        summary={{ entityName: t('applications.title') }}
        testId="applications-data-table"
      />
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}

export { ApplicationsDataTable }
