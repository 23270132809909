import React from 'react'

import { Notification } from '../components'

/*
  message: notification text
  serverity: 'error', 'warning', 'info', others default to 'unknown'
             'error': display red background color with Critical icon
             'warning': display yellow background color with Warning icon
             'info': display green background color with OK icon
             'unknown' or others: display grey background color with unknow icon
  callBack: onClose callback func to close the notification
*/
export const displayNotification = (
  message,
  severity,
  callBack,
  title,
  autoClose
) => {
  const notifySetting = { status: '', icon: undefined }
  switch (severity) {
    case 'error':
      notifySetting.status = 'status-critical'
      break
    case 'warning':
      notifySetting.status = 'status-warning'
      break
    case 'info':
      notifySetting.status = 'status-ok'
      break
    default:
      notifySetting.status = 'status-unknown'
  }

  const handleClose = () => {
    callBack(null)
  }
  const args = {
    testId: `notification-${notifySetting.status}`,
    text: message || 'No message',
    onClose: handleClose,
    backgroundColor: notifySetting.status,
    icon: notifySetting.icon,
    title,
    autoClose
  }
  return <Notification {...args} />
}
