import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  LikeFill,
  DislikeFill,
  Like,
  Dislike,
  ShareRounded
} from 'grommet-icons'

import { Typography } from '../../components'

import { ChatActions, useChatContext } from './context/chat-context'

const StyledChatButton = styled(Box)`
  box-sizing: border-box;
  border: ${(props) =>
    props.selected === props.label ? '1px solid #CBFAEB' : '1px solid #e0e0e0'};
  box-shadow: none;
  &:hover {
    border: ${(props) =>
      props.selected === props.label
        ? '1px solid #CBFAEB'
        : `1px solid ${props.theme.global.colors['background-back'].light}`};
    background-color: ${(props) =>
      props.selected === props.label
        ? `${props.theme.global.colors['validation-ok']}`
        : 'var(--light-background-contrast, rgba(0,0,0,0.04))'};
    box-shadow: none;
  }
  &:focus {
    box-shadow: ${(props) =>
      props.selected === props.label
        ? 'none'
        : `-1px -1px 3px 0px ${props.theme.global.colors['teal!']}, -1px 1px 3px 0px ${props.theme.global.colors['teal!']}, 1px -1px 3px 0px ${props.theme.global.colors['teal!']}`};
  }
  :last-child {
    margin-bottom: 0;
  }
`

const ChatBotButtonsContainer = ({ buttons, messageId, client }) => {
  const {
    selectedButtons,
    updateSelectedButton,
    sessionId,
    dispatchChatContext
  } = useChatContext()

  const sendMessageToChat = (buttonValue) => {
    const time = new Date()
    const payload = {
      from: 'You',
      text: buttonValue,
      time: `${time.getHours()}:${time.getMinutes()}`
    }

    if (sessionId) {
      dispatchChatContext({ type: ChatActions.UPDATE_CHAT, payload })
      client.send(buttonValue)
    } else {
      dispatchChatContext({ type: ChatActions.ERROR_CONNECTING_CHAT })
    }
  }

  const onSendMessage = (e, buttonValue) => {
    e.preventDefault()
    sendMessageToChat(buttonValue?.value)
    updateSelectedButton(messageId, buttonValue?.value)
  }

  const handleButtonClick = (e, button) => {
    if (selectedButtons[messageId] !== button?.value) {
      if (button === 'YesToAttachFile') {
        onSendMessage(e, { value: 'Yes' })
      } else if (button?.value === 'NoToAttachFile') {
        onSendMessage(e, { value: 'No' })
      } else {
        onSendMessage(e, button)
      }

      updateSelectedButton(messageId, button?.value)

      if (button?.type === 'button_link') {
        const newWindow = window.open(
          button?.value.startsWith('http')
            ? button?.value
            : `${window.location.origin}/${button?.value}?from=chatbot`
        )
        if (newWindow) newWindow.opener = null
      }
    }
  }

  const getIconColor = (button) => {
    if (selectedButtons[messageId] && selectedButtons[messageId] !== button) {
      return '#01a98282'
    }
    return 'brand'
  }

  const isThumbsUpOrDown = (button) => {
    return button?.value === 'ThumbsUp' || button?.value === 'ThumbsDown'
  }

  const getPointerEvents = (button) => {
    if (
      selectedButtons[messageId] &&
      selectedButtons[messageId] !== button?.value
    ) {
      return 'none'
    }
    return 'auto'
  }

  const getBackground = (button) => {
    if (
      selectedButtons[messageId] &&
      selectedButtons[messageId] === button?.value
    ) {
      return 'var(--light-background-selected-subtle-default, #CBFAEB)'
    }
    return 'background-front'
  }

  return (
    <Box direction="column" gap="6px" wrap>
      <Box direction="row" gap="12px">
        {buttons?.map((button) =>
          isThumbsUpOrDown(button) ? (
            <StyledChatButton
              key={button?.value}
              alignSelf="center"
              align="center"
              width={{ min: '29px', max: 'fit-content' }}
              plain
              style={{
                outline: 'none',
                boxShadow: 'none',
                pointerEvents: getPointerEvents(button)
              }}
              focusIndicator="false"
              role="button"
              onClick={(e) => handleButtonClick(e, button)}
              data-testid={button?.text}
              label={button?.value}
              selected={selectedButtons[messageId]}
            >
              {selectedButtons[messageId] === button?.value &&
                button?.value === 'ThumbsUp' && <LikeFill color="brand" />}
              {selectedButtons[messageId] === button?.value &&
                button?.value === 'ThumbsDown' && <DislikeFill color="brand" />}
              {selectedButtons[messageId] !== button?.value && (
                <Typography type="text" size="xsmall" weight="bold">
                  {button?.value === 'ThumbsUp' ? (
                    <Like color={getIconColor(button?.value)} />
                  ) : (
                    <Dislike color={getIconColor(button?.value)} />
                  )}
                </Typography>
              )}
            </StyledChatButton>
          ) : null
        )}
      </Box>

      <Box
        direction="row"
        wrap
        style={{ justifyContent: 'flex-end', gap: '6px' }}
      >
        {buttons?.map((button) =>
          !isThumbsUpOrDown(button) ? (
            <StyledChatButton
              key={button?.value}
              border={{
                color: 'none',
                style: 'solid',
                side: 'all'
              }}
              alignSelf="start"
              align="center"
              justify="center"
              gap="xsmall"
              direction="row"
              width={{ min: '0px', max: '100%' }}
              round="20px"
              style={{
                outline: 'none',
                padding: '5px 12px 7px 12px',
                pointerEvents: getPointerEvents(button)
              }}
              background={getBackground(button)}
              margin={{ bottom: '0px' }}
              onClick={(e) => handleButtonClick(e, button)}
              label={button?.text}
              selected={selectedButtons[messageId]}
              data-testid={button?.text}
            >
              <Typography
                type="text"
                size="16px"
                color={
                  (selectedButtons[messageId] &&
                    selectedButtons[messageId] !== button?.value) ||
                  button?.value === 'YesToAttachFile'
                    ? 'text-xweak'
                    : ''
                }
                style={{
                  textAlign: 'center',
                  fontStyle: 'normal',
                  lineHeight: '20px',
                  fontWeight: '500'
                }}
              >
                {button?.value === 'NoToAttachFile' ? 'No' : button?.text}
              </Typography>
              {button.type === 'button_link' && <ShareRounded />}
            </StyledChatButton>
          ) : null
        )}
      </Box>
    </Box>
  )
}

export default ChatBotButtonsContainer

ChatBotButtonsContainer.propTypes = {
  buttons: PropTypes.array.isRequired,
  messageId: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired
}
