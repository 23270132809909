import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import ChatMessageFromBot from './ChatMessageFromBot'
import ChatMessageFromUser from './ChatMessageFromUser'

const ChatMessage = ({ chatResponse, client, chatBodyRef }) => {
  useEffect(() => {
    const scrollToBottom = () => {
      if (chatBodyRef.current) {
        requestAnimationFrame(() => {
          const { scrollHeight, clientHeight } = chatBodyRef.current
          if (scrollHeight > clientHeight) {
            chatBodyRef.current.scrollTop = scrollHeight
          }
        })
      }
    }

    scrollToBottom()
    const observer = new MutationObserver(() => {
      scrollToBottom()
    })

    if (chatBodyRef.current) {
      observer.observe(chatBodyRef.current, { childList: true, subtree: true })
    }
    const chatBot = chatBodyRef.current

    return () => {
      if (chatBot) {
        observer.disconnect()
      }
    }
  })

  return chatResponse?.from === 'Virtual Assistant' ? (
    <ChatMessageFromBot chatResponse={chatResponse} client={client} />
  ) : (
    <ChatMessageFromUser chatResponse={chatResponse} />
  )
}

export default ChatMessage

ChatMessage.propTypes = {
  chatResponse: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  chatBodyRef: PropTypes.object.isRequired
}
