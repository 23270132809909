import React, { useEffect, useState, useContext } from 'react'
import { Box, Grid, ResponsiveContext } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { MyServicesWrapper, SideMenu } from '../../../components'
import { Layout, PageHeader } from '../../../shims/imports'
import { useServices, useRegions } from '../../../hooks'
import { Loader } from '../../../../components'
import { DEEP_LINKING } from '../../../constants'
import { localStorageWhitelistWithoutPII } from '../../../../utils/local-storage-utils'
import { getRegionName } from '../../../utils'

import TogglableMyServices from './TogglableMyServices'

const MyServices = () => {
  const [servicesToDisplay, setServicesToDisplay] = useState([])
  const [filteredServices, setFilteredServices] = useState([])
  const [regions, setRegions] = useState([])
  const [selectedRegion, setSelectedRegion] = useState('all')
  const { t } = useTranslation(['dashboard'])
  const LDFlags = useFlags()
  const enableServiceDeepLinking = LDFlags['glcp-service-slugs-deep-linking']

  const {
    servicesLegacy: services,
    isLoaded,
    services: servicesWithRegions
  } = useServices()
  const { regions: regionsData, isLoaded: regionsLoaded } = useRegions()
  const size = useContext(ResponsiveContext)

  /**
   * TODO: renable during hardening phase
   * Dependent on ResponsiveContext from Grommet
   * and useCOntext from 'react'
   */
  // const size = useContext(ResponsiveContext)

  useEffect(() => {
    const servicesToSet = services.filter(
      (service) =>
        service.provision_status === 'PROVISIONED' &&
        (service.slug === service.serviceManager ||
          (enableServiceDeepLinking &&
            service.featuresSupported.includes(DEEP_LINKING)))
    )

    if (
      !localStorage.getItem(
        localStorageWhitelistWithoutPII.DASHBOARD_LAST_ACCESSED
      )
    ) {
      localStorage.setItem(
        localStorageWhitelistWithoutPII.DASHBOARD_LAST_ACCESSED,
        '[]'
      )
    }

    setServicesToDisplay(servicesToSet)
    setFilteredServices(servicesToSet)

    // setServicesToDisplay(services)
    // setFilteredServices(services)
  }, [services, enableServiceDeepLinking])

  useEffect(() => {
    const regionsCodes = [
      'all',
      ...new Set(servicesToDisplay.map(({ region }) => region))
    ]

    const regionsToSet = regionsCodes.map((region) => ({
      label:
        region === 'all'
          ? t('common.all_regions')
          : getRegionName(regionsLoaded, regionsData, region),
      value: region
    }))

    setRegions(regionsToSet)
  }, [servicesToDisplay, t, regionsLoaded, regionsData])

  useEffect(() => {
    if (selectedRegion !== 'all') {
      const filtered = servicesToDisplay.filter(
        ({ region }) => region === selectedRegion
      )

      setFilteredServices(filtered)
    } else {
      setFilteredServices(servicesToDisplay)
    }
  }, [servicesToDisplay, selectedRegion])

  /**
   * TODO: renable during hardening phase
   */
  // const responsive = {
  //   width: {
  //     small: 'large',
  //     medium: 'large',
  //     large: 'xlarge',
  //     xlarge: 'xxlarge'
  //   }
  // }

  return (
    <Layout>
      <Box>
        <Box pad={{ horizontal: 'xlarge', vertical: 'medium' }}>
          <Box margin={{ bottom: 'large' }}>
            <PageHeader
              primaryHeader={t('common.services')}
              subHeader={t('common.services_subtitle')}
              testId="service-centric-services-header"
            />
          </Box>
          <Grid
            rows={['auto']}
            columns={size === 'xlarge' ? ['small', 'flex'] : ['full']}
            gap="large"
          >
            <SideMenu active="my-services" />
            <Box>
              <MyServicesWrapper
                regions={regions}
                setSelectedRegion={setSelectedRegion}
              />
              {isLoaded ? (
                <TogglableMyServices
                  services={filteredServices}
                  servicesWithRegions={servicesWithRegions}
                  regionsData={regionsData || []}
                  regionsLoaded={regionsLoaded}
                />
              ) : (
                <Box align="center" justify="center" alignSelf="center">
                  <Loader testId="loader-spinner" />
                </Box>
              )}
            </Box>
          </Grid>
        </Box>
      </Box>
    </Layout>
  )
}

export default MyServices
