import { getEnvConfig } from '../../config/env-config'

export function filterProtocol(uri) {
  return /^(https?|ftp|file|mailto|tel):/i.test(uri) ||
    /^(\/|\.\/|\.\.\/)/.test(uri)
    ? uri
    : ''
}

export function buildClickUri(result) {
  const envConfig = getEnvConfig()
  let clickUri = ''
  let docUri = ''

  if (result.raw.kmdocid) {
    const [docId, optionalPage] = result.raw.kmdocid.split('||')
    const page = optionalPage ? `&page=${optionalPage}` : ''
    docUri = `docId=${docId}${page}`
    clickUri = `${envConfig.SUPPORT_CENTER_DOMAIN}/hpesc/public/docDisplay?${docUri}&docLocale=${result.raw.kmdoclocale}`
  } else if (result.raw.kmvideoid) {
    clickUri = `${envConfig.SUPPORT_CENTER_DOMAIN}/hpesc/public/videoDisplay?videoId=${result.raw.kmvideoid}`
  } else {
    clickUri = result.clickUri || ''
  }

  return filterProtocol(clickUri)
}
