import React, { useCallback, useEffect, useState } from 'react'
import { Box, FormField, TextArea, TextInput } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
/* eslint-enable */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import debounce from 'lodash/debounce'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'

import { getFolderList } from '../../../utils'
import { CCSForm, Dropdown } from '../../../../../components'

const EditDetails = ({
  folderDetails,
  setFolderDetails,
  inCCSManager,
  customerId
}) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const [folderListResponse, setFolderListResponse] = useState([])

  const [errorMsg, setErrorMsg] = useState('')

  // for paginating on scroll
  const itemsPerPage = 50
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [searchedText, setSearchedText] = useState('')
  const [appendList, setAppendList] = useState(false)
  const [page, setPage] = useState(0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchValue = useCallback(
    debounce((value) => {
      setSearchedText(value)
      setAppendList(false)
      setPage(0)
    }, 500),
    []
  )
  useEffect(() => {
    const param = {
      limit: itemsPerPage,
      page,
      ...(inCCSManager && { platform_customer_id: customerId }),
      ...(searchedText ? { search_name: searchedText } : '')
    }
    getFolderList({
      token: oidcUser.access_token,
      param,
      t,
      setErrorMessage: setErrorMsg,
      isCCSManager: inCCSManager
    }).then((data) => {
      const foldersResponse = data?.folders
      if (foldersResponse) {
        if (appendList) {
          let modifiedResponse = [
            ...folderListResponse,
            ...foldersResponse,
            {
              folder_id: folderDetails.parent_folder_id,
              folder_name: folderDetails.parent_folder_name
            }
          ]
          modifiedResponse = uniqBy(
            orderBy(
              modifiedResponse,
              [(folder) => folder.folder_name?.toLowerCase()],
              ['asc']
            ),
            'folder_id'
          )
          setFolderListResponse(modifiedResponse)
        } else {
          setFolderListResponse(foldersResponse)
        }
      }

      setTotalItems(data?.pagination?.total_count || itemsPerPage)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t, inCCSManager, customerId, page, searchedText])

  return (
    <Box align="start" direction="column">
      <CCSForm
        errorMessage={errorMsg}
        validate="blur"
        onSubmit={() => {}}
        testId="without-error"
        style={{ width: '100%' }}
      >
        <>
          <FormField
            data-testid="folder-form-field"
            label={t('name')}
            name="folder_name"
            required
            margin={{ bottom: 'small' }}
          >
            <TextInput
              data-testid="folder-input"
              name="folder_name"
              value={folderDetails.folder_name}
              onChange={(e) =>
                setFolderDetails({
                  ...folderDetails,
                  folder_name: e.target.value
                })
              }
              size="small"
            />
          </FormField>
          <FormField
            data-testid="parent-folder-form-field"
            label={t('parent_folder_name')}
            name="parent_folder"
            required
            margin={{ bottom: 'small' }}
          >
            <Dropdown
              name="parent_folder"
              options={folderListResponse}
              value={
                folderDetails.parent_folder_id
                  ? folderDetails.parent_folder_id
                  : ''
              }
              onSearch={(searchText) => {
                handleDebouncedSearchValue(searchText)
              }}
              onChange={({ option }) => {
                setFolderDetails({
                  ...folderDetails,
                  parent_folder_name: option.folder_name,
                  parent_folder_id: option.folder_id
                })
                setAppendList(true)
              }}
              labelKey="folder_name"
              valueKey={{ key: 'folder_id', reduce: true }}
              size="small"
              testId="parent-folder-input"
              onMore={() => {
                if (
                  totalItems > itemsPerPage &&
                  page < totalItems / itemsPerPage - 1
                ) {
                  setPage(page + 1)
                  setAppendList(true)
                }
              }}
              onClose={() => {
                if (searchedText) {
                  setFolderListResponse([])
                  setAppendList(false)
                  setPage(0)
                  setSearchedText('')
                }
              }}
            />
          </FormField>
          <FormField
            data-testid="description-form-field"
            label={t('description')}
            name="description"
          >
            <TextArea
              data-testid="description-input"
              name="description"
              placeholder="Description"
              value={folderDetails.description}
              onChange={(e) =>
                setFolderDetails({
                  ...folderDetails,
                  description: e.target.value
                })
              }
              size="small"
            />
          </FormField>
        </>
      </CCSForm>
    </Box>
  )
}

EditDetails.propTypes = {
  folderDetails: PropTypes.object.isRequired,
  setFolderDetails: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  inCCSManager: PropTypes.bool
}
EditDetails.defaultProps = {
  customerId: undefined,
  inCCSManager: undefined
}

export { EditDetails }
