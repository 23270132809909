import { useCallback, useState, useEffect } from 'react'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { useVisibilityContext } from '../../../context/visibility-context'
import { get } from '../../../utils/api-utils'
import { getCCSAppDetails } from '../../manage-account/utils'

const useFetchIsCCSManager = () => {
  const { oidcUser } = useReactOidc()
  const { hideWhen } = useVisibilityContext()

  const ccsApplicationId = getCCSAppDetails()?.ccsApplicationId
  const isTacAccount = hideWhen?.account === 'TAC'

  const [isLoading, setIsLoading] = useState(isTacAccount) // if TAC account, set loading to true to call roles API
  const [isCCSManager, setIsCCSManager] = useState(null)
  const [error, setError] = useState(null)

  const fetchUserRoles = useCallback(async () => {
    if (isTacAccount && isCCSManager === null) {
      setIsLoading(true)
      try {
        const url = `/support-assistant/v1alpha1/tac-user-role-assignments`
        const resp = await get(url, {}, oidcUser.access_token)
        if (resp?.status === 200 && resp?.data) {
          const roleSlugs =
            resp?.data?.roles
              ?.filter((data) => data?.application_id === ccsApplicationId)
              .map((data) => data?.slug) || []
          const isTACRole = roleSlugs.length > 0
          setIsCCSManager(isTACRole)
        }
      } catch (err) {
        setError(err)
        console.error(err)
        setIsCCSManager(false)
      } finally {
        setIsLoading(false)
      }
    }
  }, [oidcUser.access_token, ccsApplicationId, isCCSManager, isTacAccount])
  useEffect(() => {
    fetchUserRoles()
  }, [fetchUserRoles])
  return { isCCSManager, isLoading, error }
}

export default useFetchIsCCSManager
