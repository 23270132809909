const UsageMonitoringActions = {
  SET_SERVICE: 'SET_SERVICE',
  SET_DATE_RANGE: 'SET_DATE_RANGE',
  SET_SUBSCRIPTION_KEY: 'SET_SUBSCRIPTION_KEY',
  SET_SUBSCRIPTIONS: 'SET_SUBSCRIPTIONS',
  SET_CURRENT_BILLING_PERIOD: 'SET_CURRENT_BILLING_PERIOD',
  SET_LOADED_WORKSPACES: 'SET_LOADED_WORKSPACES',
  SET_SELECTED_WORKSPACES: 'SET_SELECTED_WORKSPACES',
  SET_REPORT_REQUEST: 'SET_REPORT_REQUEST'
}

export default UsageMonitoringActions
