import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useHistory, useLocation } from 'react-router-dom'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ButtonGroup
} from '../../../../../components'
import { actionDeleteFolder, actionDeleteRule } from '../../../utils'
import { useVisibilityContext } from '../../../../../context/visibility-context'

const DeleteModal = ({ onSetOpen, data, refreshRule, setErrorMessage }) => {
  const { t } = useTranslation(['device', 'common'])
  const {
    folder_name: folderName,
    rule_name: ruleName,
    rule_id: ruleID,
    folder_id: folderID
  } = data || ''
  const name = refreshRule ? ruleName : folderName
  const { oidcUser } = useReactOidc()
  const history = useHistory()
  const { customerData } = useLocation()
  const { hideWhen } = useVisibilityContext()
  const isCCSManager = hideWhen?.account === 'TAC'
  const handleDelete = () => {
    if (refreshRule) {
      actionDeleteRule({
        token: oidcUser.access_token,
        t,
        isCCSManager,
        customerId: customerData?.id,
        ruleID,
        onSetOpen,
        setErrorMessage,
        refreshRule
      })
    } else {
      actionDeleteFolder({
        token: oidcUser.access_token,
        t,
        isCCSManager,
        customerId: customerData?.id,
        folderID,
        onSetOpen,
        setErrorMessage,
        history,
        customerData
      })
    }
  }

  return (
    <ModalDialog
      width="medium"
      testId="delete-modal-dialog"
      content={
        <Box>
          <Typography type="heading" testId="delete-title">
            {refreshRule ? t('delete_rule') : t('delete_folder')}
          </Typography>
          <Typography type="text" size="medium" testId="delete-subtitle">
            <Trans
              i18nKey={
                refreshRule ? 'delete_rule_subtitle' : 'delete_folder_subtitle'
              }
              t={t}
              name
            >
              <strong>{{ name }}</strong>
            </Trans>
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: () => onSetOpen(false)
                },
                {
                  id: 1,
                  label: t('ok'),
                  primary: true,
                  testId: 'ok-btn',
                  onClick: handleDelete
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={() => onSetOpen(false)}
    />
  )
}

DeleteModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  data: PropTypes.shape({
    folder_name: PropTypes.string.isRequired,
    folder_id: PropTypes.string,
    rule_name: PropTypes.string,
    rule_id: PropTypes.string
  }).isRequired,
  refreshRule: PropTypes.func,
  setErrorMessage: PropTypes.func.isRequired
}

DeleteModal.defaultProps = {
  refreshRule: undefined
}

export { DeleteModal }
