import { Box } from 'grommet'
import PropTypes from 'prop-types'
import React from 'react'

import { Typography } from '../typography/Typography'

const NoDataInfo = ({
  icon,
  title,
  titleHeadingLevel,
  subtitle,
  secondarySubtitle,
  gap,
  gapTitleSubtitle,
  action,
  testId
}) => {
  return (
    <Box align="center" gap={gap} data-testid={testId} flex={false}>
      {icon}
      <Box gap={gapTitleSubtitle}>
        {title && (
          <Typography
            type="heading"
            level={titleHeadingLevel}
            textAlign="center"
            margin="none"
            testId="no-data-title"
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            type="paragraph"
            textAlign="center"
            testId="no-data-subtitle"
            margin="none"
          >
            {subtitle}
          </Typography>
        )}
        {secondarySubtitle && (
          <Typography
            type="paragraph"
            textAlign="center"
            testId="no-data-subtitle"
            margin="none"
          >
            {secondarySubtitle}
          </Typography>
        )}
      </Box>
      {action}
    </Box>
  )
}

export default NoDataInfo

NoDataInfo.propTypes = {
  /**
   * NoDataInfo's  title.
   */
  title: PropTypes.string,
  /**
   * NoDataInfo's  title heading level.
   */
  titleHeadingLevel: PropTypes.number,
  /**
   * Icon which the user want to display.
   */
  icon: PropTypes.element,
  /**
   * NoDataInfo's  content/description showing under subtitle in smaller font.
   */
  subtitle: PropTypes.string,
  /**
   * NoDataInfo's  secondary subtitle's showing under subtitle in smaller font
   */
  secondarySubtitle: PropTypes.string,
  /**
   * Gap between children components
   */
  gap: PropTypes.string,
  /**
   * Gap between title and subtitle
   */
  gapTitleSubtitle: PropTypes.string,

  /**
   * It is used to when you have actions items such as button.
   */
  action: PropTypes.element,
  /**
   * It will be used for component reference to test.
   * This is mandatory.
   */
  testId: PropTypes.string.isRequired
}

NoDataInfo.defaultProps = {
  title: null,
  titleHeadingLevel: 3,
  icon: null,
  subtitle: null,
  gap: 'medium',
  gapTitleSubtitle: 'xsmall',
  action: null,
  secondarySubtitle: null
}
