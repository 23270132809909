import React from 'react'
import { Box, Anchor } from 'grommet'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'

import { Notification } from '../shims/imports'

const RequestEvalStatusText = ({
  requestEvalStatus,
  evalStates,
  getDaysLeft
}) => {
  const { t } = useTranslation(['common', 'authn', 'dashboard'])
  return (
    <Box>
      {(requestEvalStatus.status === evalStates.pending ||
        requestEvalStatus.status === evalStates.init) && (
        <Notification
          testId="eval-pending-notification"
          backgroundColor="status-unknown"
          text={
            <>
              {' '}
              {t('dashboard:eval.pending_status_message')}{' '}
              <Anchor
                href="/notifications"
                label={t('dashboard:eval.notification')}
              />{' '}
              {t('dashboard:eval.pending_status_message_contd')}
            </>
          }
          type="inline"
        />
      )}
      {requestEvalStatus.status === evalStates.approved && (
        <Notification
          backgroundColor="status-unknown"
          testId="eval-approved-notification"
          text={
            <Trans i18nKey="dashboard:eval.approved_status_message" t={t}>
              {{
                endDate: requestEvalStatus.endDate,
                daysLeft: getDaysLeft()
              }}
            </Trans>
          }
          type="inline"
        />
      )}
      {requestEvalStatus.status === evalStates.expired && (
        <Notification
          backgroundColor="status-warning"
          testId="eval-expired-notification"
          text={
            <>
              <Trans i18nKey="dashboard:eval.expired_status_message">
                {{ endDate: requestEvalStatus.endDate }}
              </Trans>{' '}
              <Anchor
                href="https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=gl&tof=gf"
                label={t('dashboard:eval.feedback')}
                target="_blank"
              />{' '}
              {t('dashboard:eval.expired_status_message_contd')}
            </>
          }
          type="inline"
        />
      )}
    </Box>
  )
}

RequestEvalStatusText.propTypes = {
  evalStates: PropTypes.object.isRequired,
  requestEvalStatus: PropTypes.object.isRequired,
  getDaysLeft: PropTypes.func.isRequired
}

export default RequestEvalStatusText
