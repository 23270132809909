// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, ResponsiveContext } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import VisibilityWrapper from '../visibility-wrapper/VisibilityWrapper'
import { queryParamsBasedOnTab } from '../../manage-account/subscriptions/constants'
import { CARD_COLUMNS } from '../../../utils/common-utils'
import { Card, Loader, Typography } from '../../../components'

const SubscriptionStatsCard = ({
  productType,
  tabName,
  setTabName,
  setFilterOptions,
  pullRefresh
}) => {
  const { t } = useTranslation(['licensing', 'common'])
  const { oidcUser } = useReactOidc()

  const size = useContext(ResponsiveContext)
  const cardColumns = CARD_COLUMNS[size] || CARD_COLUMNS.large

  // For Visualize and Notify Subscription Expiry
  const initialCountData = {
    licenses_expiring_30days: 0,
    licenses_expiring_90days: 0,
    evals_expiring_30days: 0,
    total_licenses: 0
  }
  const [licensesCount, setLicensesCount] = useState(initialCountData)
  const [isTabDataload, setIsTabDataload] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)

  // To fetch subscription stats
  useEffect(() => {
    let isCurrent = true
    setIsTabDataload(true)
    const url =
      productType === 'SERVICE'
        ? '/ui-doorway/ui/v1/license/stats?product_type=SERVICE&product_type=ONPREM_SERVICE'
        : '/ui-doorway/ui/v1/license/stats?product_type=DEVICE'
    get(url, {}, oidcUser.access_token)
      .then(
        (response) => {
          if (response.data) {
            const {
              total_subscriptions: totalCount,
              subscriptions_expiring_in_90_days: expireNintyDays,
              subscriptions_expiring_in_30_days: expireThirtyDays,
              evaluations_expiring_in_30_days: evalsExpireThirtyDays
            } = response.data
            if (!isCurrent) return
            setLicensesCount({
              licenses_expiring_30days: expireThirtyDays,
              licenses_expiring_90days: expireNintyDays,
              evals_expiring_30days: evalsExpireThirtyDays,
              total_licenses: totalCount
            })
          }
        },
        (error) => {
          setErrorMessage(error)
        }
      )
      .finally(() => setIsTabDataload(false))

    return () => {
      isCurrent = false
    }
  }, [oidcUser.access_token, productType, pullRefresh])

  const LicensePageTabData = [
    {
      id: 1,
      subtitle: licensesCount.licenses_expiring_30days.toString(),
      title: 'expiring_in_30days',
      tabName: 'expiringIn30Days',
      testId: 'expiring-in-30-days-tab',
      days: '30',
      visible: true
    },
    {
      id: 2,
      subtitle: licensesCount.licenses_expiring_90days.toString(),
      title: 'expiring_in_90days',
      tabName: 'expiringIn90Days',
      testId: 'expiring-in-90-days-tab',
      days: '90',
      visible: true
    },
    {
      id: 3,
      subtitle: licensesCount.evals_expiring_30days.toString(),
      title: 'evals_expiring_in_30days',
      tabName: 'evalsExpiringIn30Days',
      testId: 'evals-expiring-in-30-days-tab',
      days: '30',
      visible: true
    },
    {
      id: 4,
      subtitle: licensesCount.total_licenses.toString(),
      title: 'total_subscriptions',
      tabName: 'totalSubscriptions',
      testId: 'total-subscriptions-tab',
      visible: true
    }
  ]
  return (
    <>
      <VisibilityWrapper hideFor={{ feature: 'glcp-subscription-expiry' }}>
        <Grid columns={cardColumns} gap="small">
          {isTabDataload ? (
            <Box direction="row" align="center" justify="center">
              <Loader testId="subscription-stats-loader" />
            </Box>
          ) : (
            LicensePageTabData.map((data) => (
              <Card
                key={data.id}
                cardWidth="large"
                description={
                  <Typography
                    size="xxlarge"
                    testId="tile-title"
                    type="text"
                    emphasis
                  >
                    {data.subtitle}
                  </Typography>
                }
                onClick={() => {
                  setTabName(data.tabName)
                  // fetch the query param based on tab name
                  const modifiedQueryParam =
                    queryParamsBasedOnTab(data?.tabName) || {}
                  setFilterOptions(modifiedQueryParam)
                }}
                testId={data.testId}
                title={
                  <Typography testId="tile-subtitle" type="text">
                    {t(data.title)}
                  </Typography>
                }
                {...(data.tabName === tabName && {
                  background: 'active'
                })}
              />
            ))
          )}
        </Grid>
      </VisibilityWrapper>
      {errorMessage && displayApiError(errorMessage, t, setErrorMessage)}
    </>
  )
}

SubscriptionStatsCard.propTypes = {
  tabName: PropTypes.string.isRequired,
  setTabName: PropTypes.func.isRequired,
  setFilterOptions: PropTypes.func.isRequired,
  pullRefresh: PropTypes.bool.isRequired,
  productType: PropTypes.oneOf(['DEVICE', 'SERVICE']).isRequired
}

export { SubscriptionStatsCard }
