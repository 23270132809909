import { useState, useEffect } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import cloneDeep from 'lodash/cloneDeep'

import { get, post } from '../../../../../../../../utils/api-utils'
import {
  isBadOrderData,
  isBadRatesData,
  getRatesDataFromCdsRatesRes,
  buildIniDataFromOrder,
  getServiceFromOrder
} from '../utils'

import { useHasShowbackRatesPermission } from './useHasShowbackRatesPermission'

const useLoadingRates = ({
  initialFormValues,
  setIsLoading,
  subscriptionKey,
  setFormValues,
  skipLoadingRates,
  callBackFunc,
  hasInitialRates,
  skipSkuCheck
}) => {
  const { oidcUser } = useReactOidc()
  const [isLoadingOrder, setIsLoadingOrder] = useState(false)
  const [isLoadingSku, setIsLoadingSku] = useState(false)
  const [isLoadingRates, setIsLoadingRates] = useState(false)
  const hasRatesPerm = useHasShowbackRatesPermission()

  const handleLoadingFailed = (isForcedEnableRates) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      isLoadingRatesFailed: true,
      /*
      except force to enable rates step, 
      if skipSkuCheck, then consider it as bad data case,rates step still enable, 
      if not skip, then it consider as subscription checking failed then skip the Rates Step */
      isRatesStepAvailable: isForcedEnableRates || !!skipSkuCheck
    }))
  }

  const buildRatesFromOrderAndUpdateState = (
    orderData,
    isForcedEnableRates
  ) => {
    if (isBadOrderData(orderData)) {
      handleLoadingFailed(isForcedEnableRates)
    } else {
      const initialData = buildIniDataFromOrder(orderData, subscriptionKey)
      setFormValues((prevValues) => ({
        ...prevValues,
        ...initialData,
        ratesService: getServiceFromOrder(orderData),
        initialRates: hasInitialRates ? cloneDeep(initialData.ratesData) : null,
        hasNoExistedRates: true
      }))
    }
  }

  const handleRatesError = (error, orderData) => {
    if (error?.response?.status !== 404) {
      handleLoadingFailed(true)
    } else {
      // rates 404 means passed sku check, then will consider as data issue
      // so need force enable rates step
      buildRatesFromOrderAndUpdateState(orderData, true)
    }
  }

  const loadRates = (orderData) => {
    setIsLoadingRates(true)
    get(`/cds/v1/rates/${subscriptionKey}`, {}, oidcUser.access_token)
      .then((res) => {
        if (res?.status === 200 && res?.data) {
          if (isBadRatesData(res?.data)) {
            handleLoadingFailed(true)
            setIsLoadingRates(false)
          } else {
            const ratesData = getRatesDataFromCdsRatesRes(res.data)
            setFormValues((prevValues) => ({
              ...prevValues,
              ratesService: getServiceFromOrder(orderData),
              ratesData,
              ratesPayload: cloneDeep(ratesData),
              hasNoExistedRates: false,
              initialRates: hasInitialRates ? cloneDeep(ratesData) : null
            }))
          }
        }
      })
      .catch((error) => {
        handleRatesError(error, orderData)
      })
      .finally(() => {
        setIsLoadingRates(false)
        if (callBackFunc) {
          callBackFunc()
        }
      })
  }
  const checkSku = (skuArray, skuData) => {
    const isRatesStepAvailable =
      skipSkuCheck || skuArray.every((item) => skuData[item] === true)
    // reset the formValues by passing in the initial values
    setFormValues((prevValues) => ({
      ...prevValues,
      ...initialFormValues,
      isRatesStepAvailable
    }))
    return isRatesStepAvailable
  }

  const checkAndBuildDataFromOrder = (orderData) => {
    buildRatesFromOrderAndUpdateState(orderData)
    setIsLoadingSku(false)
    setIsLoading(false)
  }

  const loadSku = (skuArray, orderData) => {
    setIsLoadingSku(true)
    post(`/cds/v1/skus`, skuArray, oidcUser.access_token)
      .then((res) => {
        if (res?.status === 200 && res?.data) {
          const isRatesStepAvailable = checkSku(skuArray, res.data)
          if (isRatesStepAvailable && !skipLoadingRates) {
            loadRates(orderData)
          } else if (isRatesStepAvailable && skipLoadingRates) {
            checkAndBuildDataFromOrder(orderData)
          } else {
            setIsLoadingSku(false)
            setIsLoading(false)
          }
        }
      })
      .catch(() => {
        // if sku request failed then skip the rates step
        handleLoadingFailed(false)
      })
      .finally(() => {
        setIsLoadingSku(false)
        if (skipLoadingRates && callBackFunc) {
          callBackFunc()
        }
      })
  }

  const loadOrder = () => {
    setIsLoadingOrder(true)

    get(
      `/ui-doorway/ui/v1/license/${subscriptionKey}/order`,
      {},
      oidcUser.access_token
    )
      .then((res) => {
        if (res?.status === 200 && res?.data) {
          const sku = res?.data?.entitlements[0]?.product?.sku
          loadSku([sku], res?.data)
        }
      })
      .catch(() => {
        // skip the rates step since the very first request failed
        setFormValues((prevValues) => ({
          ...prevValues,
          isLoadingRatesFailed: true,
          isRatesStepAvailable: false
        }))
      })
      .finally(() => {
        setIsLoadingOrder(false)
      })
  }

  useEffect(() => {
    if (
      isLoadingOrder === false &&
      isLoadingSku === false &&
      isLoadingRates === false
    )
      setIsLoading(false)
  }, [isLoadingOrder, isLoadingRates, isLoadingSku, setIsLoading])

  return () => {
    if (hasRatesPerm) {
      setIsLoading(true)
      loadOrder()
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        isRatesStepAvailable: false
      }))
      setIsLoading(false)
    }
  }
}

export default useLoadingRates
