import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import {
  StatusGoodSmall,
  StatusCriticalSmall,
  StatusWarningSmall
} from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../components'

import { convertStatus } from './utils'

const StatusDisplay = ({ status: statusId, statusMessage }) => {
  const { t } = useTranslation(['local-gateway'])
  const status = convertStatus(statusId)

  if (status === 'bad_status') return null

  let statusIcon
  switch (status) {
    case 'running':
    case 'starting':
      statusIcon = <StatusGoodSmall size="16px" color="green" />
      break
    case 'warning':
    case 'unregistered':
      statusIcon = <StatusWarningSmall size="16px" color="orange" />
      break
    case 'offline':
    case 'error':
      statusIcon = <StatusCriticalSmall size="16px" color="red" />
      break
    default:
      statusIcon = undefined
  }

  return (
    <Box>
      <Box direction="row" align="center">
        {statusIcon}
        <Typography
          type="text"
          testId="status-table-text"
          size="medium"
          margin={{ left: 'small' }}
        >
          {t(`status.${status}`)}
        </Typography>
      </Box>
      {statusMessage && (
        <Box>
          <Typography type="text" testId="status-table-text" size="medium">
            {t(`status_messages.${statusMessage}`)}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

StatusDisplay.defaultProps = {
  status: undefined,
  statusMessage: undefined
}

StatusDisplay.propTypes = {
  status: PropTypes.number,
  statusMessage: PropTypes.string
}

export default StatusDisplay
