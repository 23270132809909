import React from 'react'
import { Box, NameValuePair } from 'grommet'
import PropTypes from 'prop-types'

import { Typography } from '../../../../../components'

const RoleDynamicViewField = ({ field, readonly, value, logo }) => {
  const { label, width = 'medium', disabled } = field

  return (
    <NameValuePair name={label}>
      <Box
        width={width}
        align="center"
        round="xxsmall"
        direction="row"
        background={
          disabled && !readonly ? 'rgba(0, 0, 0, 0.04)' : 'background-default'
        }
      >
        {logo}
        <Typography
          margin={{ left: logo ? 'xsmall' : '' }}
          type="text"
          testId={`dynamic-view-${field.name}`}
        >
          {value}
        </Typography>
      </Box>
    </NameValuePair>
  )
}

RoleDynamicViewField.propTypes = {
  field: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
  value: PropTypes.any,
  logo: PropTypes.any
}

RoleDynamicViewField.defaultProps = {
  readonly: false,
  logo: '',
  value: ''
}

export default RoleDynamicViewField
