import React, { useState, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { v4 as uuidv4 } from 'uuid'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  Form,
  Anchor,
  Box,
  Button,
  ResponsiveContext,
  Page,
  PageContent,
  PageHeader,
  Paragraph,
  Notification
} from 'grommet'
import { CircleInformation } from 'grommet-icons'

import { Layout } from '../commoncomponents/layout/Layout'
import { NoDataInfo } from '../../components'
import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'

import { TopicField } from './components/TopicField'
import { RatingField } from './components/RatingField'
import { DetailsField } from './components/DetailsField'
import { FilesField } from './components/FilesField'
import { ContactField } from './components/ContactField'
import { sendFormData } from './utils'

const pageContentProps = (size) => {
  if (size === 'xsmall') {
    return { pad: { horizontal: 'medium' } }
  }
  return {}
}

const getDefaultValue = (referrerTopics, referrerPage) => {
  return {
    topic: referrerTopics[referrerPage],
    rating: '',
    details: '',
    contact: false,
    files: []
  }
}

const SupportFeedbackPageContent = () => {
  const { t, i18n } = useTranslation(['support_hub_feedback'])
  const LDFlags = useFlags()

  const { oidcUser } = useReactOidc()

  const idempotentKey = useMemo(() => uuidv4(), [])

  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  )
  const referrerPage = searchParams.get('from')
  const skipSubmit = searchParams.get('skipSubmit')

  const referrerTopics = useMemo(
    () => ({
      support: 1,
      search: 2,
      ...(LDFlags['glcp-support-chat'] && { chatbot: 3 }),
      caseManagement: 4
    }),
    [LDFlags]
  )

  const topics = useMemo(
    () => [
      { label: t('topic_field.option1'), value: 1 },
      { label: t('topic_field.option2'), value: 2 },
      ...(LDFlags['glcp-support-chat']
        ? [{ label: t('topic_field.option3'), value: 3 }]
        : []),
      { label: t('topic_field.option4'), value: 4 }
    ],
    [t, LDFlags]
  )

  const defaultValue = useMemo(
    () => getDefaultValue(referrerTopics, referrerPage),
    [referrerTopics, referrerPage]
  )

  const [value, setValue] = useState(defaultValue)
  const [fails, setFails] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isErrorNotification, setIsErrorNotification] = useState(false)
  const [isErrorScreen, setIsErrorScreen] = useState(false)

  const fileToBase64DataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = Object.assign(new FileReader(), {
        onload: () => resolve(reader.result),
        onerror: () => reject(reader.error)
      })
      reader.readAsDataURL(file)
    })
  }

  const sendData = async (data) => {
    setIsLoading(true)
    const formData = new FormData()
    const metadata = {
      skipSubmit: skipSubmit !== 'false',
      idempotentKey,
      topic: data.topic,
      rating: data.rating,
      details: data.details,
      contact: data.contact ? 1 : 2,
      url: window.location.href,
      userLanguage: i18n.language
    }
    formData.append('metadata', JSON.stringify(metadata))

    if (data.files?.length) {
      const filesData = await Promise.all(
        data.files.map(async (file) => {
          const base64DataUrl = await fileToBase64DataUrl(file)
          const fileData = base64DataUrl.split(';base64,').pop()
          const bytes = Uint8Array.from(fileData, (ch) => ch.charCodeAt(0))
          return {
            data: new Blob([bytes.buffer], { type: file.type }),
            name: file.name
          }
        })
      )

      filesData.forEach((item) => {
        formData.append('files', item.data, item.name)
      })
    }

    return sendFormData(formData, oidcUser?.access_token)
  }

  const submitForm = (data) => {
    if (isLoading) {
      return
    }
    sendData(data)
      .then(() => {
        setIsSubmitted(true)
      })
      .catch(() => {
        const nextFails = fails + 1
        setFails(nextFails)
        if (nextFails === 1) {
          setIsErrorNotification(true)
        }
        if (nextFails === 2) {
          setIsErrorScreen(true)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const resetForm = () => {
    setValue(defaultValue)
    setIsErrorNotification(false)
  }

  const history = useHistory()

  const supportLinkClickHandler = (e) => {
    e.preventDefault()
    history.push('/support')
  }

  const size = useContext(ResponsiveContext)

  return (
    <Page kind="narrow">
      <PageContent {...pageContentProps(size)}>
        {!isSubmitted && !isErrorScreen && (
          <>
            <PageHeader
              title={t('title')}
              subtitle={
                <Box pad={{ top: 'medium' }}>
                  <Paragraph fill margin="none">
                    {t('subtitle1')}
                  </Paragraph>
                  <Paragraph fill margin={{ top: 'small', bottom: 'none' }}>
                    <Trans i18nKey="subtitle2" t={t}>
                      <Anchor
                        href="/support"
                        onClick={supportLinkClickHandler}
                        data-testid="support-link"
                      />
                    </Trans>
                  </Paragraph>
                </Box>
              }
              data-testid="page-header"
              gridProps={{
                gap: 'none',
                columns: '1'
              }}
            />
            <Form
              data-testid="support-feedback-form"
              value={value}
              onChange={(nextValue) => setValue(nextValue)}
              onReset={() => resetForm()}
              onSubmit={() => submitForm(value)}
              messages={{
                required: t('required_message')
              }}
              validate="blur"
            >
              <Box pad={{ top: 'medium', bottom: 'large' }} gap="medium">
                <TopicField topics={topics} />
                <RatingField />
                <DetailsField />
                <VisibilityWrapper
                  hideFor={{
                    feature: 'glcp-support-feedback-file-upload',
                    deployment: ['COP', 'GLOP']
                  }}
                >
                  <FilesField />
                </VisibilityWrapper>
                <ContactField />
              </Box>
              <Box pad={{ bottom: 'large' }} gap="small">
                {isErrorNotification && (
                  <Notification
                    status="critical"
                    icon={<CircleInformation height="medium" />}
                    message={t('error_notification')}
                  />
                )}
                <Box direction="row" gap="small">
                  <Button
                    type="submit"
                    primary
                    label={t('submit')}
                    data-testid="submit-button"
                    busy={isLoading}
                  />
                  <Button
                    type="reset"
                    label={t('clear_form')}
                    data-testid="reset-button"
                  />
                </Box>
              </Box>
            </Form>
            <Box
              pad={{ top: 'medium', bottom: 'large' }}
              border={{ side: 'top', color: 'brand' }}
            >
              <Paragraph fill margin="none">
                <Trans i18nKey="privacy_statement" t={t}>
                  <Anchor
                    href="https://www.hpe.com/us/en/legal/privacy.html"
                    target="_blank"
                    weight="normal"
                  />
                </Trans>
              </Paragraph>
            </Box>
          </>
        )}
        {isSubmitted && (
          <Box pad={{ vertical: 'large' }}>
            <NoDataInfo
              title={t('success_screen.title')}
              titleHeadingLevel={1}
              action={
                <Anchor
                  alignSelf="center"
                  label={t('success_screen.subtitle')}
                  href="/support"
                  onClick={supportLinkClickHandler}
                  data-testid="support-link"
                />
              }
              icon={<CircleInformation size="xlarge" />}
              testId="success-info"
            />
          </Box>
        )}
        {isErrorScreen && (
          <Box pad={{ vertical: 'large' }}>
            <NoDataInfo
              title={t('error_screen.title')}
              titleHeadingLevel={1}
              action={
                <Paragraph margin="none">
                  <Trans i18nKey="error_screen.subtitle" t={t}>
                    <Anchor
                      href="/support"
                      onClick={supportLinkClickHandler}
                      data-testid="support-link"
                    />
                  </Trans>
                </Paragraph>
              }
              icon={<CircleInformation size="xlarge" />}
              testId="error-info"
            />
          </Box>
        )}
      </PageContent>
    </Page>
  )
}

const SupportFeedbackPage = () => {
  return (
    <Layout>
      <VisibilityWrapper
        hideFor={{
          feature: 'glcp-support-feedback',
          deployment: ['COP', 'GLOP']
        }}
      >
        <SupportFeedbackPageContent />
      </VisibilityWrapper>
    </Layout>
  )
}

export default SupportFeedbackPage
