const miraUrl = 'mira.ccs.arubathena.com'
const pavoUrl = 'pavo.common.cloud.hpe.com'
const aquilaUrl = 'common.cloud.hpe.com'

const { origin, hostname } = window.location

export const getMfeCloudBaseUrl = `${origin}/api/glp`

export const getMfeCloudOrgBaseUrl = `${origin}/api/glp-org`

export const getMfeCloudNewApiUrl =
  origin === miraUrl ? `${origin}/api/glp-v2` : ''

export const isMfeCloud = [miraUrl, pavoUrl, aquilaUrl].some(
  (url) => url === hostname || hostname.endsWith('greenlake-preview.com')
)
