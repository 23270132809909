import React, { useState } from 'react'
import { Box, Grid } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '../../../../components'
import { statusOptions } from '../../../commoncomponents/notifications-dashboard/constants'
import { checkIfAll } from '../../../ccs-manager/pages/notifications/utils'
import { targetMap } from '../../../ccs-manager/pages/notifications/constants'

import { EditPreferenceModal } from './EditPreferenceModal'

const AppPreference = ({
  appName,
  preferredRegions,
  preferredStatus,
  allRegionsForApp,
  onEditSuccess,
  inErrorState,
  setPreferenceAPIError
}) => {
  const [editPreferenceModal, setEditPreferenceModal] = useState(false)
  const isAllRegions = checkIfAll(
    allRegionsForApp.sort(),
    preferredRegions.sort()
  )
  const isAllStatus = checkIfAll(statusOptions.sort(), preferredStatus.sort())
  const { t } = useTranslation(['manage', 'common'])

  return (
    <Box pad={{ top: 'small' }}>
      <Box
        direction="row"
        justify="between"
        width="large"
        border={{ side: 'bottom', color: 'border-weak' }}
        pad={{ bottom: 'small', top: 'small' }}
      >
        <Typography
          level="3"
          type="heading"
          testId={`notification-preferences-${appName}`}
        >
          {targetMap[appName]}
        </Typography>
        <Button
          secondary
          color="status-ok"
          onClick={() => setEditPreferenceModal(true)}
          label={t('edit')}
          testId={`edit-notification-preferences-${appName}`}
          disabled={inErrorState}
        />
      </Box>
      <Box>
        <Grid
          gap={{ row: 'small', column: 'small' }}
          fill="horizontal"
          columns={['small', 'auto']}
          pad={{ top: 'small' }}
        >
          <Typography type="text" size="small" color="black" weight="bold">
            {t('notification.preferences.regions_email')}
          </Typography>
          <Typography type="text" size="small" color="black">
            {preferredRegions?.length ? preferredRegions.join(', ') : '--'}
          </Typography>
          <Typography type="text" size="small" color="black" weight="bold">
            {t('notification.preferences.status_email')}
          </Typography>
          <Typography type="text" size="small" color="black">
            {preferredStatus?.length ? preferredStatus.join(', ') : '--'}
          </Typography>
        </Grid>
      </Box>
      {editPreferenceModal && (
        <EditPreferenceModal
          appName={appName}
          setEditPreferenceModal={setEditPreferenceModal}
          selectedRegions={isAllRegions ? allRegionsForApp : preferredRegions}
          selectedStatus={isAllStatus ? statusOptions : preferredStatus}
          allRegions={allRegionsForApp}
          onSuccess={onEditSuccess}
          setPreferenceAPIError={setPreferenceAPIError}
        />
      )}
    </Box>
  )
}

AppPreference.propTypes = {
  appName: PropTypes.string.isRequired,
  preferredRegions: PropTypes.array.isRequired,
  preferredStatus: PropTypes.array.isRequired,
  allRegionsForApp: PropTypes.array.isRequired,
  onEditSuccess: PropTypes.func.isRequired,
  inErrorState: PropTypes.bool.isRequired,
  setPreferenceAPIError: PropTypes.func.isRequired
}

export default AppPreference
