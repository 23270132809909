import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, FormField, TextInput } from 'grommet'
import { FormDown, Search, UserNew } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Typography,
  Dropdown,
  Button,
  Pagination,
  Ruler,
  ActionButton,
  Loader
} from '../../components'
import { Layout } from '../commoncomponents/layout/Layout'
import { get, post } from '../../utils/api-utils'
import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  getPaginationShowIdx,
  getWorkspaceString,
  WKSPC_CAPITALIZED,
  WKSPC_PLURAL,
  WKSPC_PLURAL_CAPITALIZED
} from '../../utils/common-utils'
import ExportModal from '../commoncomponents/export-csv/ExportModal'
import { displayNotification } from '../../utils/notificiation-utils'
import { displayApiError } from '../../utils/error-handling-utils'

import { CustomerCard } from './components'
import AddTenantModal from './components/AddTenantModal'
import { getExportReportStatus } from './common-utils'

const CustomerAccountScreen = () => {
  const { t } = useTranslation(['authn', 'common', 'device'])
  const { oidcUser } = useReactOidc()
  const [searchValue, setSearchValue] = useState('')
  const [customerAccountLoading, setCustomerAccountLoading] = useState(false)
  const sortOptions = [
    {
      label: t('customer_account.alphabetical'),
      value: 'ACCOUNT_SORT_BY_ALPHABETIC'
    },
    {
      label: t('customer_account.recently_accessed'),
      value: 'ACCOUNT_SORT_BY_RECENT'
    },
    {
      label: t('customer_account.recently_created'),
      value: 'ACCOUNT_SORT_BY_RECENT_CREATE'
    }
  ]
  const [sortBy, setSortBy] = useState('ACCOUNT_SORT_BY_RECENT')
  const [customerAccounts, setCustomerAccounts] = useState([])
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false)
  const [showNotification, setNotification] = useState(null)
  const [showExportModal, setShowExportModal] = useState(false)
  const [debounceTerms, setDebounceTerms] = useState({
    page: 1,
    searchString: ''
  })
  const [pollingTerms, setPollingTerms] = useState({
    status: null,
    id: null
  })
  const [loading, setLoading] = useState(false)
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const countPerPage = 15
  // for tracking total entries and searched entries
  const [currentTotal, setCurrentTotal] = useState(0)
  const [total, setTotal] = useState(0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearch = useCallback(
    debounce((debouncedSearch) => {
      setDebounceTerms({
        ...debounceTerms,
        page: 1,
        searchString: debouncedSearch
      })
    }, 500),
    []
  )
  const fetchTenants = useCallback(() => {
    const { page, searchString } = debounceTerms
    const offset = (page - 1) * countPerPage
    const url = `/accounts/ui/v1/managed-service/tenants?offset=${offset}&count_per_page=${countPerPage}&sort_by=${sortBy}&search_string=${searchString}`
    get(url, {}, oidcUser.access_token).then((resp) => {
      if (resp.data.tenants) {
        setCustomerAccounts(resp.data.tenants)
      }
      if (resp.data.pagination) {
        if (
          isEqual(debounceTerms, {
            page: 1,
            searchString: ''
          })
        ) {
          setTotal(resp.data.pagination.total_count)
        }
        setCurrentTotal(resp.data.pagination.total_count)
      }
      if (
        offset === resp.data.pagination.total_count &&
        resp.data.pagination.total_count !== 0
      ) {
        setDebounceTerms({ ...debounceTerms, page: 1 })
      }
    })
  }, [oidcUser.access_token, sortBy, debounceTerms])

  const onPageChange = ({ page }) => {
    setDebounceTerms({ ...debounceTerms, page })
  }

  useEffect(() => {
    fetchTenants()
  }, [fetchTenants])

  const onSearch = (e) => {
    const debouncedSearchValue = e.target.value
    setSearchValue(e.target.value)
    updateSearch(debouncedSearchValue)
  }

  useEffect(() => {
    let refreshTimer = null
    const polling = () => {
      if (
        (pollingTerms.status === 'IN_PROGRESS' ||
          pollingTerms.status === 'TODO') &&
        pollingTerms.id
      ) {
        getExportReportStatus(
          pollingTerms.id,
          pollingTerms.status,
          (val) => setPollingTerms((p) => ({ ...p, status: val })),
          oidcUser.access_token
        )
        refreshTimer = setTimeout(() => {
          polling()
        }, 5000)
      }
    }
    polling()
    if (pollingTerms.status === 'DONE') {
      setNotification(
        displayNotification(
          t('common:export_modal.report_complete'),
          'info',
          () => setNotification(false)
        )
      )
    }
    return () => {
      clearTimeout(refreshTimer)
    }
  }, [pollingTerms.id, pollingTerms.status, oidcUser.access_token, t])

  const onGenerateReport = ({ email, all_entries, columns }) => {
    setLoading(true)
    const request = {
      email,
      ...(!all_entries && { search_string: debounceTerms?.searchString || '' }),
      columns
    }
    post(
      '/accounts/ui/v1/managed-service/generate-csv',
      request,
      oidcUser.access_token
    ).then(
      (response) => {
        if (response?.data?.task_tracking_id) {
          setPollingTerms({
            status: 'IN_PROGRESS',
            id: response?.data?.task_tracking_id
          })
        }
        setLoading(false)
        setShowExportModal(false)
        setNotification(
          displayNotification(
            t('common:export_modal.generating_report'),
            'info',
            () => setNotification(false)
          )
        )
      },
      (error) => {
        setNotification(displayApiError(error, t))
        setLoading(false)
      }
    )
  }

  return (
    <Box margin={{ horizontal: 'xlarge', vertical: 'large' }}>
      <Box direction="row" justify="between" margin={{ horizontal: 'xlarge' }}>
        <Typography
          level="1"
          type="heading"
          weight="normal"
          testId="customer-account-title"
        >
          {t('customer_account.title', {
            accounts: getWorkspaceString(
              showWorkspaceString,
              t,
              WKSPC_PLURAL_CAPITALIZED
            )
          })}
        </Typography>
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/accounts/platform/customer',
            permission: 'ccs.accounts.platform.customer.edit'
          }}
        >
          <Button
            secondary
            fill={false}
            label={t('customer_account.addCustomerButton')}
            onClick={() => setShowCreateCustomerModal(true)}
            testId="add-customer-account-button"
          />
        </VisibilityWrapper>
      </Box>
      <Typography
        size="large"
        type="text"
        weight="normal"
        testId="customer-account-subtitle"
        margin={{ horizontal: 'xlarge' }}
      >
        {t('customer_account.subtitle', {
          accounts: getWorkspaceString(showWorkspaceString, t, WKSPC_PLURAL)
        })}
      </Typography>
      <Box
        direction="row"
        justify="between"
        margin={{ top: 'large', horizontal: 'xlarge' }}
        align="center"
      >
        <Box direction="row-responsive">
          <FormField width="medium" data-testid="search-bar">
            <TextInput
              value={searchValue}
              placeholder={t('customer_account.search_placeholder')}
              onChange={onSearch}
              icon={<Search />}
            />
          </FormField>
          <FormField
            label={t('customer_account.sort_by')}
            direction="row"
            width="large"
            align="center"
            gap="xsmall"
            margin={{ left: 'medium' }}
          >
            <Dropdown
              name="sort_by"
              noBorder
              options={sortOptions}
              value={sortBy}
              onChange={({ option }) => setSortBy(option.value)}
              testId="sort-dropdown"
            />
          </FormField>
        </Box>
        {customerAccounts?.length ? (
          <ActionButton
            actions={[
              {
                label: t('device:export'),
                onClick: () => {
                  setShowExportModal(true)
                },
                hidden: !customerAccounts?.length
              }
            ]}
            showOneActionAsDropDown
            testId="oneaction-action-btn"
            label={t('common:actions')}
            icon={<FormDown />}
            reverse
            kind="toolbar"
          />
        ) : null}
      </Box>
      <Typography
        type="text"
        size="small"
        testId="customer-accounts-count"
        margin={{ horizontal: 'xlarge' }}
      >
        <>
          <strong>{currentTotal}</strong>{' '}
          {t('customer_account.title', {
            accounts: getWorkspaceString(
              showWorkspaceString,
              t,
              WKSPC_PLURAL_CAPITALIZED
            )
          })}
        </>
      </Typography>
      {customerAccounts.length || searchValue.length ? (
        <>
          <Box margin={{ top: 'medium', horizontal: 'xlarge' }}>
            <Grid columns={{ count: 'fit', size: 'medium' }} gap="medium">
              {customerAccounts.map((customer) => {
                return (
                  <CustomerCard
                    customer={customer}
                    key={`${customer.customer_id}-key`}
                    setCustomerAccountLoading={setCustomerAccountLoading}
                  />
                )
              })}
            </Grid>
            {currentTotal > countPerPage && (
              <Ruler margin={{ top: 'large', bottom: 'small' }} />
            )}
            <Pagination
              pageIdxInfo={getPaginationShowIdx(
                debounceTerms.page,
                currentTotal,
                countPerPage,
                t
              )}
              totalItems={currentTotal}
              itemsPerPage={countPerPage}
              page={debounceTerms.page}
              setPage={onPageChange}
              testId="pagination-customer-accounts"
            />
          </Box>
        </>
      ) : (
        <Box align="center">
          <Box align="center" width="medium" margin={{ top: 'large' }}>
            <UserNew size="large" />
            <Typography
              type="heading"
              level="3"
              margin={{ top: 'small', bottom: 'none' }}
              testId="zero-accounts-title"
            >
              {t('customer_account.add_a_customer')}
            </Typography>
            <Typography
              type="text"
              size="medium"
              textAlign="center"
              margin={{ top: 'xsmall' }}
              testId="zero-accounts-subtitle"
            >
              {t('customer_account.add_a_customer_sub')}
            </Typography>
          </Box>
        </Box>
      )}
      {showCreateCustomerModal && (
        <AddTenantModal
          setShowCreateCustomerModal={setShowCreateCustomerModal}
          setNotification={setNotification}
          updateCustomerAccounts={fetchTenants}
        />
      )}
      {customerAccountLoading && (
        <Loader
          modal
          testId="loader-modal"
          size="xxsmall"
          modalTitle={t('common:launching')}
          modalSubTitle={t('common:loader_dialog_message')}
        />
      )}
      {showNotification}
      {showExportModal && (
        <ExportModal
          setShowModal={setShowExportModal}
          title={t('customer_account.export_customer_accounts_title', {
            account: getWorkspaceString(
              showWorkspaceString,
              t,
              WKSPC_CAPITALIZED
            )
          })}
          description={
            showWorkspaceString
              ? t('customer_account.export_customer_accounts_desc_workspace')
              : t('customer_account.export_customer_accounts_desc2')
          }
          columns={{
            header: t('customer_account.customer_account_details', {
              account: getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_CAPITALIZED
              )
            }),
            data: Object.keys(customerAccounts[0]).map((val) => ({
              label: t(`customer_account.${val}`, {
                company: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_CAPITALIZED
                )
              }),
              value: val
            }))
          }}
          includedEntries={{
            all_entries_label: t(
              'customer_account.all_customer_accounts',
              {
                count: total
              },
              {
                account: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_PLURAL_CAPITALIZED
                )
              }
            ),
            searched_entries_label: t(
              'customer_account.current_search_results',
              { count: currentTotal }
            )
          }}
          filteredDevices={currentTotal}
          onClickGenerateReport={onGenerateReport}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </Box>
  )
}

const CustomerAccountPage = () => {
  return (
    <Layout>
      <CustomerAccountScreen />
    </Layout>
  )
}

export default CustomerAccountPage
