import PropTypes from 'prop-types'
import { Box, Anchor } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  FormInput,
  ModalDialog,
  ModalHeader,
  Typography,
  ButtonGroup,
  CCSForm
} from '../../../../../components'
import { put } from '../../../../../utils/api-utils'
import { GREENLAKE_SUPPORT_URL } from '../../../../../utils/error-utils'
import { getApiErrorMessage } from '../../../../../utils/error-handling-utils'

const EditOnPremWorkspaceDetails = ({
  setEditWorkspacePanel,
  onPremData,
  onSuccess
}) => {
  const { t } = useTranslation(['manage', 'common'])
  const [updatedWorkspaceName, setUpdatedWorkspaceName] = useState(
    onPremData?.onPremWorkspaceName
  )
  const [errorMsg, setErrorMsg] = useState('')
  const { oidcUser } = useReactOidc()

  // To perform edit onprem workspace action
  const handleSubmitOnpremFieldChange = () => {
    put(
      `/ui-doorway/ui/v1/onprem-workspaces/${onPremData?.id}`,
      {
        onPremWorkspaceId: onPremData?.onPremWorkspaceId,
        onPremWorkspaceName: updatedWorkspaceName
      },
      oidcUser.access_token
    ).then(
      (response) => {
        setEditWorkspacePanel(false)
        setErrorMsg('')
        onSuccess(response)
      },
      (error) => {
        setErrorMsg(getApiErrorMessage(error, t))
      }
    )
  }

  return (
    <ModalDialog
      content={
        <Box margin={{ vertical: 'medium' }} direction="column" gap="medium">
          <Box testId="edit-onprem-workspace-id-container">
            <CCSForm
              errorMessage={errorMsg}
              testId="edit-workspace-form"
              validate="blur"
              onSubmit={handleSubmitOnpremFieldChange}
            >
              <>
                <FormInput
                  label={t('onprem_workspace.onprem_workspace_id')}
                  labelHelper={
                    <Typography
                      size="xsmall"
                      type="text"
                      margin={{ bottom: 'xxsmall', top: 'xxsmall' }}
                      testId="edit-workspace-id-subtitle"
                      color="text-strong"
                    >
                      {t('onprem_workspace.edit_workspace_id_subtitle')}
                    </Typography>
                  }
                  info={
                    <Typography
                      type="text"
                      size="xsmall"
                      textId="edit-workspace-id-info"
                      margin={{ bottom: 'xxsmall' }}
                    >
                      <Trans
                        i18nKey="onprem_workspace.edit_workspace_id_desc"
                        t={t}
                      >
                        <Anchor
                          weight="bold"
                          href={GREENLAKE_SUPPORT_URL}
                          testId="edit-workspace-id-desc"
                          target="_blank"
                        />
                      </Trans>
                    </Typography>
                  }
                  margin={{ bottom: 'xxsmall' }}
                  size="small"
                  disabled
                  value={onPremData?.onPremWorkspaceId}
                  inputType="text"
                  name="edit-onprem-workspace-id-input"
                  testId="edit-onprem-workspace-id-input"
                  required
                />
                <FormInput
                  label={t('onprem_workspace.onprem_workspace_name')}
                  labelHelper={
                    <Typography
                      size="xsmall"
                      type="text"
                      margin={{ bottom: 'xxsmall', top: 'xxsmall' }}
                      testId="edit-workspace-name-desc"
                      color="text-strong"
                    >
                      {t('onprem_workspace.edit_workspace_name_desc')}
                    </Typography>
                  }
                  size="small"
                  inputType="text"
                  value={updatedWorkspaceName}
                  name="edit-onprem-workspace-name"
                  testId="edit-onprem-workspace-name"
                  onChange={(event) =>
                    setUpdatedWorkspaceName(event.target.value)
                  }
                  margin={{ bottom: 'medium' }}
                  required
                />
                <ButtonGroup
                  buttonList={[
                    {
                      label: t('manage:onprem_workspace.submit_changes'),
                      primary: true,
                      testId: 'submit-btn',
                      type: 'submit'
                    },
                    {
                      label: t('manage:cancel'),
                      onClick: () => {
                        setEditWorkspacePanel(false)
                      },
                      testId: 'cancel-btn'
                    }
                  ]}
                  justifyGroup="start"
                  testId="edit-workspace-buttons"
                />
              </>
            </CCSForm>
          </Box>
        </Box>
      }
      header={
        <ModalHeader
          title={
            <Typography
              level="2"
              testId="edit-workspace-title"
              type="heading"
              weight="bold"
            >
              {t('onprem_workspace.edit_onprem_workspace_details')}
            </Typography>
          }
          onClose={() => {
            setEditWorkspacePanel(false)
          }}
        />
      }
      height="100%"
      width="medium"
      position="right"
      testId="edit-workspace-side-drawer"
      onClose={() => {
        setEditWorkspacePanel(false)
      }}
    />
  )
}
EditOnPremWorkspaceDetails.propTypes = {
  setEditWorkspacePanel: PropTypes.func.isRequired,
  onPremData: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired
}
export default EditOnPremWorkspaceDetails
