export const LIFECYCLE_STATE = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING'
})
export const WORKSPACE_TYPE = Object.freeze({
  STANDALONE: 'STANDALONE',
  MSP: 'MSP'
})
export const CHARACTER_LENGTH = Object.freeze({
  ORG_NAME: 256,
  ORG_DESC: 4096,
  ORG_PHONE: 30,
  ORG_EMAIL: 320
})
