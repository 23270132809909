import React, { useEffect, useState } from 'react'
import { Anchor, Box } from 'grommet'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'
import { CircleInformation } from 'grommet-icons'

import { Button, DataTable, Loader, NoDataInfo } from '../../../../components'
import { get } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { getPaginationShowIdx } from '../../../../utils/common-utils'

const OnPremResourceDatatable = ({ resourceType }) => {
  const { workspaceRId } = useParams()
  const { t } = useTranslation(['manage', 'common'])
  const { oidcUser } = useReactOidc()
  const history = useHistory()

  const [viewData, setViewData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [apiError, setApiError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  // pagination for resources
  const itemsPerPage = 20
  const [page, setPage] = useState(1)
  // Total items in current page wrt search/filter
  const [totalSearchResults, setTotalSearchResults] = useState(50)
  // Total items in current page irrespective search/filter
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  // To display pagination summary
  const pageIdxInfo = getPaginationShowIdx(
    page,
    totalSearchResults,
    itemsPerPage,
    t
  )

  // Columns in the datatable
  const columns = {
    DEVICE: [
      {
        property: 'serial_number',
        header: t('onprem_workspace.serial_number')
      },
      { property: 'device_model', header: t('onprem_workspace.model_number') }
    ],
    DEVICE_SUBSCRIPTION: [
      {
        property: 'subscription_key',
        header: t('onprem_workspace.subscription_key')
      },
      {
        property: 'subscription_tier_description',
        header: t('onprem_workspace.tier')
      }
    ],
    SERVICE_SUBSCRIPTION: [
      {
        property: 'subscription_key',
        header: t('onprem_workspace.subscription_key')
      },
      {
        property: 'subscription_tier_description',
        header: t('onprem_workspace.tier')
      }
    ]
  }

  // Search placeholder in the datatable
  const searchPlaceholder = {
    DEVICE: t('onprem_workspace.search_by_serial_number'),
    DEVICE_SUBSCRIPTION: t('onprem_workspace.search_by_subscription_key'),
    SERVICE_SUBSCRIPTION: t('onprem_workspace.search_by_subscription_key')
  }

  // summary info in the datatable
  const summaryI18n = {
    DEVICE: t('onprem_workspace.devices_summary'),
    DEVICE_SUBSCRIPTION: t('onprem_workspace.subscriptions_summary'),
    SERVICE_SUBSCRIPTION: t('onprem_workspace.subscriptions_summary')
  }

  // No data component info in the datatable
  const noDataInfoI18n = {
    DEVICE: {
      buttonLabel: t('onprem_workspace.no_assigned_devices_action'),
      buttonRouteTo: '/devices/inventory',
      buttonTestId: 'view-inventory-btn',
      subtitle: t('onprem_workspace.no_assigned_devices_desc'),
      title: t('onprem_workspace.no_assigned_devices'),
      testId: 'devices-no-data-info',
      anchorLabel: t('onprem_workspace.learn_more_devices_hyperlink'),
      anchorHref: '#', // TODO: Update once UX confirms the requirement
      anchorTestId: 'learn-adding-devices'
    },
    DEVICE_SUBSCRIPTION: {
      buttonLabel: t(
        'onprem_workspace.no_assigned_device_subscriptions_action'
      ),
      buttonRouteTo: '/devices/subscriptions',
      buttonTestId: 'view-device-subscriptions-btn',
      subtitle: t('onprem_workspace.no_assigned_device_subscriptions_desc'),
      title: t('onprem_workspace.no_assigned_device_subscriptions'),
      testId: 'device-subscriptions-no-data-info',
      anchorLabel: t(
        'onprem_workspace.learn_more_device_subscriptions_hyperlink'
      ),
      anchorHref: '#', // TODO: Update once UX confirms the requirement
      anchorTestId: 'learn-adding-device-subscriptions'
    },
    SERVICE_SUBSCRIPTION: {
      buttonLabel: t(
        'onprem_workspace.no_assigned_service_subscriptions_action'
      ),
      buttonRouteTo: '/services/service-subscriptions',
      buttonTestId: 'view-service-subscriptions-btn',
      subtitle: t('onprem_workspace.no_assigned_service_subscriptions_desc'),
      title: t('onprem_workspace.no_assigned_service_subscriptions'),
      testId: 'service-subscriptions-no-data-info',
      anchorLabel: t(
        'onprem_workspace.learn_more_service_subscriptions_hyperlink'
      ),
      anchorHref: '#', // TODO: Update once UX confirms the requirement
      anchorTestId: 'learn-adding-service-subscriptions'
    }
  }

  // To fetch resource(device/subscriptions) list specific to the onprem workspace resource id based on resource type(DEVICE/DEVICE_SUBSCRIPTION/SERVICE_SUBSCRIPTION)
  useEffect(() => {
    let isCurrent = true
    get(
      `/ui-doorway/ui/v1/onprem-workspaces/${workspaceRId}/resources`,
      {
        resource_type: resourceType,
        ...(searchTerm?.length && { search_string: searchTerm }),
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage
      },
      oidcUser.access_token
    )
      .then(
        (response) => {
          if (!isCurrent) return
          setViewData(response?.data?.resources || [])
          setTotalSearchResults(response?.data?.pagination?.total_count)
          // To display no data info
          if (!searchTerm?.length)
            setTotalItemsCount(response?.data?.pagination?.total_count)
        },
        (error) => setApiError(error)
      )
      .finally(() => setIsLoading(false))
    return () => {
      isCurrent = false
    }
  }, [workspaceRId, oidcUser.access_token, resourceType, searchTerm, page])

  const getContent = () => {
    if (totalItemsCount)
      return (
        <DataTable
          pagination={{
            totalItems: totalSearchResults,
            itemsPerPage,
            page,
            setPage,
            pageIdxInfo
          }}
          grid={{ columns: columns[resourceType], data: viewData }}
          search={{
            width: 'large',
            onSearchValueChange: (val) => {
              setPage(1)
              setSearchTerm(val)
            },
            placeholder: searchPlaceholder[resourceType],
            responsive: true
          }}
          summary={{
            entityName: summaryI18n[resourceType]
          }}
          testId="onprem-resource-datatable"
        />
      )
    return (
      <Box align="center" gap="medium">
        <NoDataInfo
          action={
            <Button
              label={noDataInfoI18n[resourceType]?.buttonLabel}
              onClick={() => {
                history.push(noDataInfoI18n[resourceType]?.buttonRouteTo)
              }}
              primary
              testId={noDataInfoI18n[resourceType]?.buttonTestId}
            />
          }
          icon={<CircleInformation size="large" />}
          subtitle={noDataInfoI18n[resourceType]?.subtitle}
          testId={noDataInfoI18n[resourceType]?.testId}
          title={noDataInfoI18n[resourceType]?.title}
          titleHeadingLevel={2}
        />
        <Anchor
          label={noDataInfoI18n[resourceType]?.anchorLabel}
          data-testid={noDataInfoI18n[resourceType]?.anchorTestId}
          onClick={() => dispatchEvent(new Event('context-help'))}
        />
      </Box>
    )
  }

  return (
    <Box margin={{ vertical: 'medium' }}>
      {isLoading ? (
        <Box align="center" justify="center" alignSelf="center">
          <Loader testId="onprem-workspace-detail-loader" />
        </Box>
      ) : (
        getContent()
      )}

      {apiError && displayApiError(apiError, t, setApiError)}
    </Box>
  )
}

OnPremResourceDatatable.propTypes = {
  resourceType: PropTypes.oneOf([
    'DEVICE',
    'DEVICE_SUBSCRIPTION',
    'SERVICE_SUBSCRIPTION'
  ]).isRequired
}

export { OnPremResourceDatatable }
