import PropTypes from 'prop-types'
import { ShareRounded } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import { ButtonGroup } from '../../components'
import { EVAL_NO_QUOTE_SERVICES } from '../constants'

const EvalButtonGroup = ({
  requestEvalStatus,
  setEvalModalFormOpen,
  evalUrl,
  evalStates,
  serviceSlug
}) => {
  const { t } = useTranslation(['dashboard'])
  const getButtonList = () => {
    const showGetQuoteButton = !EVAL_NO_QUOTE_SERVICES.includes(serviceSlug)
    const buttonList = []
    if (showGetQuoteButton) {
      buttonList.push({
        testId: `${serviceSlug}-service-detail-get-quote-button`,
        id: 1,
        label: t('dashboard:eval.get_quote'),
        icon: <ShareRounded />,
        reverse: true,
        href: evalUrl,
        target: '_blank'
      })
    }
    if (
      requestEvalStatus.status === evalStates.null ||
      requestEvalStatus.status === evalStates.expired ||
      requestEvalStatus.status === ''
    ) {
      buttonList.push({
        id: showGetQuoteButton ? 2 : 1,
        label: t('dashboard:eval.request_eval'),
        secondary: true,
        onClick: () => {
          setEvalModalFormOpen(true)
        },
        testId: `${serviceSlug}-service-detail-request-eval-button`
      })
    }
    return buttonList
  }
  return <ButtonGroup testId="eval-btn-group" buttonList={getButtonList()} />
}

EvalButtonGroup.propTypes = {
  requestEvalStatus: PropTypes.object.isRequired,
  setEvalModalFormOpen: PropTypes.func.isRequired,
  evalStates: PropTypes.object.isRequired,
  evalUrl: PropTypes.string.isRequired,
  serviceSlug: PropTypes.string.isRequired
}

export default EvalButtonGroup
