import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Text } from 'grommet'
import { PropTypes } from 'prop-types'
import lodashGet from 'lodash/get'
import styled from 'styled-components'

import { StyledTextArea } from '../../utils/page-utils'
import { WizardContext } from '../../../../../../../../components/wizard/WizardContext'
import { idpConfigOptions } from '../../../../../constants'

export const MetadataRightColumn = () => {
  const { t } = useTranslation(['iam'])
  const { formValues } = useContext(WizardContext)

  const MetadataBox = ({ label, value }) => {
    return (
      <Box pad="xsmall" border={false}>
        <Text weight="bold">{label}</Text>
        <Text>{value}</Text>
      </Box>
    )
  }
  MetadataBox.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }

  const MetadataValuesArea = styled(Box)`
    background: rgba(0, 0, 0, 0.04);
    color: #444444;
    opacity: 1;
    word-wrap: break-word;
    border: none;
  `
  const metadataUploaded = lodashGet(formValues, 'saml_idp_config.entity_id')
  return (
    <>
      {metadataUploaded ? (
        <>
          <MetadataValuesArea>
            <MetadataBox
              htmlFor="entity_id"
              label={t('iam:sso_profiles.view_edit_create.entity_id')}
              value={lodashGet(formValues, 'saml_idp_config.entity_id')}
              data-testid="metadata-entity-id"
            />
            <MetadataBox
              label={t('iam:sso_profiles.view_edit_create.domain_login_url')}
              value={lodashGet(formValues, 'saml_idp_config.login_url')}
            />
            <MetadataBox
              label={t('iam:sso_profiles.view_edit_create.domain_logout_url')}
              value={lodashGet(formValues, 'saml_idp_config.logout_url')}
            />
            <MetadataBox
              label={t('iam:sso_profiles.view_edit_create.signing_certificate')}
              value={lodashGet(
                formValues,
                'saml_idp_config.signing_certificate'
              )}
            />
          </MetadataValuesArea>
        </>
      ) : (
        <StyledTextArea
          style={{ height: '100%', resize: 'none' }}
          name="right-column-without-data"
          id="right-column-without-data"
          readOnly
          value={
            lodashGet(formValues, 'saml_idp_config.config_option') ===
            idpConfigOptions.UPLOAD_FROM_FILE
              ? t('iam:sso_profiles.view_edit_create.metadata_appears_here')
              : t('iam:sso_profiles.view_edit_create.url_metadata_appears_here')
          }
          data-testid="right-column-without-data"
        />
      )}
    </>
  )
}

export default MetadataRightColumn
