import React, { useContext } from 'react'
import {
  Box,
  Grid,
  ResponsiveContext,
  Page,
  PageContent,
  PageHeader
} from 'grommet'
import { useTranslation } from 'react-i18next'

import { Layout } from '../commoncomponents/layout/Layout'
import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { ChatBot } from '../support-chat/ChatBot'

import { KnowledgeSection } from './components/KnowledgeSection'
import { CaseManagementSection } from './components/CaseManagementSection'
import { InsightsSection } from './components/InsightsSection'
import { OtherResources } from './components/OtherResources'
import { CommunitiesSection } from './components/CommunitiesSection'

const pagePad = (sizeArg) => {
  const pageSizesMap = {
    xsmall: { bottom: 'xlarge' },
    small: { bottom: 'xlarge' }
  }
  return (
    pageSizesMap[sizeArg] || {
      bottom: 'large'
    }
  )
}

const pageContentPad = {
  xsmall: { horizontal: 'medium', top: 'large', bottom: 'xlarge' },
  small: { horizontal: 'large', top: 'large', bottom: 'xlarge' },
  medium: { horizontal: 'medium', vertical: 'large' },
  large: { horizontal: 'large', vertical: 'large' },
  xlarge: { horizontal: 'large', vertical: 'large' }
}

const parentGrid = {
  columns: {
    small: ['auto'],
    medium: ['flex'],
    large: ['flex'],
    xlarge: ['flex', 'medium']
  }
}

const titleGap = (sizeArg) => {
  const titleGapMap = {
    medium: 'medium'
  }

  return titleGapMap[sizeArg] || 'large'
}

const SupportPageContent = () => {
  const size = useContext(ResponsiveContext)
  const { t } = useTranslation(['support_hub'])

  return (
    <Page pad={pagePad(size)}>
      <PageContent pad={pageContentPad[size]}>
        <Box gap={titleGap(size)}>
          <PageHeader
            responsive
            title={t('support_title')}
            pad="none"
            testId="support-page"
            gridProps={{
              gap: 'none',
              columns: '1'
            }}
            actions={null}
          />

          <Grid columns={parentGrid.columns[size]} gap="large">
            <Box gap="large">
              <KnowledgeSection />

              <CaseManagementSection />

              <CommunitiesSection />

              <InsightsSection />
            </Box>

            <OtherResources />
          </Grid>
        </Box>
      </PageContent>
      <VisibilityWrapper hideFor={{ feature: 'glcp-support-chat' }}>
        <ChatBot />
      </VisibilityWrapper>
    </Page>
  )
}

const SupportPage = () => {
  return (
    <Layout>
      <VisibilityWrapper
        hideFor={{
          feature: 'glcp-support-home',
          deployment: ['COP', 'GLOP']
        }}
      >
        <SupportPageContent />
      </VisibilityWrapper>
    </Layout>
  )
}

export default SupportPage
