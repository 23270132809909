import React, { useEffect, useState, Children } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../components'

const FacetManager = ({ controller, children, size }) => {
  const { t } = useTranslation(['support_hub_search'])

  const [, setState] = useState(controller.state)

  useEffect(() => {
    const unsubscribe = controller.subscribe(() => setState(controller.state))
    return () => unsubscribe()
  }, [controller])

  const columns = {
    xsmall: 'auto',
    small: ['auto'],
    medium: ['auto', 'auto'],
    large: ['auto', 'auto', 'auto', 'auto']
  }

  return (
    <Box>
      {children[0]?.props?.controller?.state?.values?.length > 0 && (
        <Typography type="text" testId="filter-results">
          {t('filter_results')}
        </Typography>
      )}
      <Grid
        columns={columns[size]}
        gap={size !== 'xlarge' ? 'medium' : 'small'}
      >
        {Children.toArray(children).map((facet) => (
          <Box width="100%" key={facet.props.controller.state.facetId}>
            {facet}
          </Box>
        ))}
      </Grid>
    </Box>
  )
}

FacetManager.propTypes = {
  controller: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  size: PropTypes.string.isRequired
}

export default FacetManager
