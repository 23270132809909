import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { Typography } from '../shims/imports'

const SectionHeading = ({
  title,
  titleLevel,
  subtitle,
  subtitleSize,
  margin,
  pad
}) => {
  return (
    <Box margin={margin} pad={pad} gap="xsmall">
      <Typography
        type="heading"
        level={titleLevel}
        margin={{ horizontal: 'none', vertical: 'none' }}
      >
        {title}
      </Typography>
      <Typography
        type="paragraph"
        size={subtitleSize}
        margin={{ horizontal: 'none', vertical: 'none' }}
      >
        {subtitle}
      </Typography>
    </Box>
  )
}

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  titleLevel: PropTypes.oneOf(['1', '2', '3', '4', '5', '6', 1, 2, 3, 4, 5, 6]),
  subtitle: PropTypes.string,
  subtitleSize: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pad: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

SectionHeading.defaultProps = {
  titleLevel: 1,
  subtitle: null,
  subtitleSize: undefined,
  margin: 'none',
  pad: 'none'
}

export default SectionHeading
