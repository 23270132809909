import { React, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  ModalDialog,
  ModalHeader,
  ModalFooter,
  Typography,
  ButtonGroup
} from '../../../../components'
import { put } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'

const EnableDisableZtoModal = ({ onSuccess, onSetOpen, ztoState, ztoId }) => {
  const { t } = useTranslation(['manage', 'common'])
  const { oidcUser } = useReactOidc()
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const modifyZtoSettings = () => {
    put(
      `/ui-doorway/ui/v1/zto-settings/${ztoId}`,
      { enabled: !ztoState },
      oidcUser.access_token
    )
      .then((resp) => {
        onSetOpen(false)
        onSuccess(resp?.data?.enabled)
      })
      .catch((error) => {
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      })
  }
  return (
    <>
      <ModalDialog
        onClose={() => {
          onSetOpen(false)
        }}
        header={
          <ModalHeader
            title={
              <Typography
                level="2"
                style={{ whiteSpace: 'nowrap' }}
                type="heading"
                weight="bold"
                testId="enable-disable-zto-title"
              >
                {ztoState
                  ? t('manage:zto.disable_z-to')
                  : t('manage:zto.enable_z-to')}
              </Typography>
            }
          />
        }
        content={
          <>
            <Typography
              type="text"
              margin={{ top: 'medium' }}
              testId="enable-disable-desc"
            >
              {ztoState
                ? t('manage:zto.disable_zto_desc')
                : t('manage:zto.enable_zto_desc')}
            </Typography>
            <Typography
              type="text"
              margin={{ top: 'medium' }}
              testId="enable-disable-zto-confirm"
            >
              {ztoState
                ? t('manage:zto.disable_zto_confirm')
                : t('manage:zto.enable_zto_confirm')}
            </Typography>
          </>
        }
        footer={
          <ModalFooter
            right={
              <Box direction="row" gap="medium">
                <ButtonGroup
                  buttonList={[
                    {
                      label: t('common:cancel'),
                      onClick: () => onSetOpen(false),
                      testId: 'cancel-btn'
                    },
                    {
                      label: ztoState
                        ? t('manage:zto.disable_zto_short')
                        : t('manage:zto.enable_zto_short'),
                      primary: true,
                      onClick: () => {
                        modifyZtoSettings()
                      },
                      testId: 'zto-toggle'
                    }
                  ]}
                />
              </Box>
            }
          />
        }
        width="medium"
      />
      {showErrorNotification}
    </>
  )
}

EnableDisableZtoModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onSetOpen: PropTypes.func.isRequired,
  ztoState: PropTypes.bool.isRequired,
  ztoId: PropTypes.string.isRequired
}

export { EnableDisableZtoModal }
