const defaultGatewayDetails = {
  gatewayDetails: {
    name: '',
    description: '',
    appId: undefined,
    username: '',
    password: '',
    rePassword: ''
  }
}

const PASSWORD_LENGTH = 16

const gatewayInfoValidate = (formValues, t, existingList) => {
  const strippedName = formValues.gatewayDetails.name
    ? formValues.gatewayDetails.name.trim()
    : undefined
  const nameExists = strippedName && strippedName !== ''
  const uniqueName =
    !existingList ||
    (existingList && !existingList.includes(formValues.gatewayDetails.name))

  let message = ''
  if (!nameExists) {
    message = t('validation_errors.name')
  } else if (!uniqueName) {
    message = t('validation_errors.duplicate_name')
  }

  return {
    message,
    isValid: !!nameExists && uniqueName
  }
}

const appInfoValidate = (
  { appId, username, password, rePassword },
  t,
  existingList
) => {
  const appIdValid = appId !== undefined
  const strippedName = username ? username.trim() : undefined
  const usernameExists = strippedName && strippedName !== ''
  const passwordValid =
    password && password !== '' && password.length >= PASSWORD_LENGTH
  const rePasswordValid = password === rePassword

  const uniqueUsername =
    !existingList || (existingList && !existingList.includes(strippedName))

  let usernameMessage = ''
  if (!usernameExists) {
    usernameMessage = t('validation_errors.user_name')
  } else if (!uniqueUsername) {
    usernameMessage = t('validation_errors.duplicate_user_name')
  }
  const message = `${
    appIdValid ? '' : t('validation_errors.app_id')
  } ${usernameMessage} ${
    passwordValid ? '' : t('validation_errors.password')
  } ${rePasswordValid ? '' : t('validation_errors.re_password')}`
    .trim()
    // remove double spaces when null values
    .replace(/\s+(?=\s)/g, '')

  return {
    message,
    isValid:
      !!appIdValid &&
      !!usernameExists &&
      uniqueUsername &&
      passwordValid &&
      rePasswordValid
  }
}

const convertAppToId = () => {
  const appId = 1
  // We can add switch case if we have different cases
  // switch (app) {
  //   case 'greenlake_proxy':
  //     appId = 1
  //     break
  //   default:
  //     appId = 1
  // }
  return appId
}

const convertAppIdToType = () => {
  const appType = 'greenlake_proxy'
  // We can add switch case if we have different cases
  // switch (app) {
  //   case 1:
  //     appType = 'greenlake_proxy'
  //     break
  //   default:
  //     appType = 'greenlake_proxy'
  // }
  return appType
}

const convertStatus = (statusID) => {
  let status
  switch (statusID) {
    case -1:
      status = 'starting'
      break
    case 0:
      status = 'error'
      break
    case 1:
      status = 'warning'
      break
    case 2:
      status = 'running'
      break
    case 3:
      status = 'unregistered'
      break
    case 4:
      status = 'offline'
      break
    default:
      status = 'bad_status'
  }
  return status
}

export {
  defaultGatewayDetails,
  gatewayInfoValidate,
  appInfoValidate,
  convertStatus,
  convertAppToId,
  convertAppIdToType
}
