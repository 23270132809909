import { Typography } from '../../../../components'

export const getStatusForMultiStatusResponse = (response) => {
  let status = 'info'

  if (
    Boolean(response?.data?.failed?.length > 0) &&
    Boolean(response.data?.success?.length > 0)
  ) {
    status = 'warning'
  } else if (
    Boolean(response?.data?.failed?.length > 0) &&
    (!response?.data?.success || Boolean(response?.data?.success?.length === 0))
  ) {
    status = 'error'
  }
  return status
}

export const getNotificationMessageForMultiStatusResponse = (
  smsg,
  stype,
  fmsg,
  ftype
) => {
  return (
    <>
      {smsg && stype && (
        <>
          <Typography type="text" testId="sa-notification-desc" weight="bold">
            {stype}
          </Typography>

          <Typography
            level="7"
            wordBreak="break-word"
            color="text-strong"
            type="text"
            testId="sa-notification-title"
          >
            {smsg}
          </Typography>
        </>
      )}

      {fmsg && ftype && (
        <>
          <Typography type="text" testId="sa-notification-desc" weight="bold">
            {ftype}
          </Typography>

          <Typography
            level="7"
            wordBreak="break-word"
            color="text-strong"
            type="text"
            testId="sa-notification-title"
          >
            {fmsg}
          </Typography>
        </>
      )}
    </>
  )
}
