import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Box, CheckBox, Text } from 'grommet'
import { Archive, Flag, FlagFill } from 'grommet-icons'
import { colors } from 'grommet-theme-hpe'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { eventPropType, serviceValues } from '../../utils/data'
import { put } from '../../../../utils/api-utils'

import SeverityIcon from './SeverityIcon'
import Spacer from './Spacer'

const CustFlagFill = styled(FlagFill)`
  path[fill-rule='evenodd'] {
    stroke: #000;
    stroke-width: 2;
    fill: #000;
  }
`
const Row = ({
  event,
  mutateEvent,
  clearCache,
  checked,
  setChecked,
  setEventForDetails,
  tab,
  search
}) => {
  const { t } = useTranslation('unified_wellness')
  const { oidcUser } = useReactOidc()
  const changeFlag = () => {
    // We need to clear the swr cache any time we change user data to prevent stale data being shown
    clearCache()
    mutateEvent(
      put(
        `/wellness/v2beta1/events/${event.uuid}/tags`,
        {
          tags: {
            flag: !event.flag
          }
        },
        oidcUser.access_token
      ),
      {
        ...event,
        flag: !event.flag
      }
    )
  }
  return (
    <Box data-testid="event-list-row" direction="row" border="bottom">
      <Box direction="row" align="center" pad={{ vertical: 'medium' }}>
        <CheckBox
          pad="none"
          checked={checked}
          onChange={() => setChecked(!checked)}
          data-testid={
            checked ? 'event-list-row-checked' : 'event-list-row-unchecked'
          }
        />
        <Box margin={{ left: 'small' }} onClick={changeFlag}>
          {event.flag ? <CustFlagFill /> : <Flag />}
        </Box>
        <Box margin={{ left: 'small' }}>
          <SeverityIcon severity={event.condition.severity} />
        </Box>
        {(tab === 'flagged' || search) && event.archive && (
          <Box margin={{ left: 'small' }}>
            <Archive color={colors.border.light} />
          </Box>
        )}
      </Box>
      <Box
        direction="row"
        onClick={() => {
          setEventForDetails(event)
        }}
        justify="between"
        align="center"
        fill="horizontal"
        pad={{ vertical: 'medium' }}
      >
        <Box data-testid="event-list-row-text" margin={{ left: 'small' }}>
          <Text
            data-testid="event-list-row-title"
            size="large"
            weight={event.read ? 'normal' : 'bolder'}
            className={event.read ? 'read' : 'unread'}
          >
            {event.title || '--'}
          </Text>
          {/* TODO: What is the service name?  Do we have that data? */}
          <Text data-testid="event-list-row-service" size="small">
            {event.glcpAttributes?.serviceName &&
            serviceValues.includes(event.glcpAttributes?.serviceName)
              ? t(`services.${event.glcpAttributes?.serviceName}`)
              : event.glcpAttributes?.serviceName || '--'}
          </Text>
        </Box>
        <Spacer />
        {/* TODO: double check the best way to get case number */}
        {event.escalation?.[0]?.caseNumber && (
          <Box margin={{ right: 'medium' }}>
            <Text
              data-testid="event-list-row-case-number"
              size="small"
              color="black"
              textAlign="end"
            >
              Case #{event.escalation[0].caseNumber}
            </Text>
          </Box>
        )}
        <Box margin={{ left: 'xsmall' }} width="small">
          <Text data-testid="event-list-row-date" textAlign="end">
            {event.status?.initialTimestamp &&
              dayjs(event.status.initialTimestamp).format('MMMM D, YYYY')}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

Row.propTypes = {
  event: eventPropType.isRequired,
  mutateEvent: PropTypes.func.isRequired,
  clearCache: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
  setEventForDetails: PropTypes.func.isRequired,
  tab: PropTypes.oneOf(['inbox', 'flagged', 'archived']).isRequired,
  search: PropTypes.string.isRequired
}

export default Row
