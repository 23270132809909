import {
  validateCity,
  validateStreetAddress,
  validateTenantDescription,
  validateTenantName,
  validateZipCode
} from '../../utils/validation-utils'

import { DESCRIPTION_MAX_LIMIT } from './utils'

export const CUSTOMER_WORKSPACE_MAX_LIMIT = 100
export const CITY_MAX_LIMIT = 40
export const STREET_ADDRESS_MAX_LIMIT = 220
export const ZIP_CODE_MAX_LIMIT = 10

export const validateOperationalModeField = (value, t) => {
  if (!value) return t('common:required')
  return ''
}

export const validateWorspaceNameField = (value, t) => {
  if (!value) return t('common:required')
  if (validateTenantName(value))
    return t('customer_account.tenant_name_error_message')
  return ''
}

export const validateDescriptionField = (value, t) => {
  if (!value) return t('common:required')

  const descriptionValidationMessage = validateTenantDescription(
    value,
    DESCRIPTION_MAX_LIMIT,
    t('customer_account.length_validation_error', {
      fieldName: t('customer_account.description'),
      maxLength: DESCRIPTION_MAX_LIMIT
    })
  )
  if (descriptionValidationMessage?.status === 'error')
    return descriptionValidationMessage?.message
  return ''
}

export const validateCountryField = (value, t) => {
  if (!value) return t('common:required')
  return ''
}

export const validateStreetAddressField = (value, t) => {
  if (!value?.trim()) return t('common:required')
  if (!validateStreetAddress(value))
    return t('customer_account.length_validation_error', {
      fieldName: t('customer_account.street_address'),
      maxLength: STREET_ADDRESS_MAX_LIMIT
    })
  return ''
}

export const validateStreetAddress2Field = (value, t) => {
  if (value?.trim() && !validateStreetAddress(value))
    return t('customer_account.length_validation_error', {
      fieldName: t('customer_account.apt_suite_building'),
      maxLength: STREET_ADDRESS_MAX_LIMIT
    })
  return ''
}

export const validateCityField = (value, t) => {
  if (value?.trim() && !validateCity(value))
    return t('customer_account.length_validation_error', {
      fieldName: t('customer_account.city'),
      maxLength: CITY_MAX_LIMIT
    })
  return ''
}

export const validateZipField = (value, t) => {
  if (value?.trim() && !validateZipCode(value))
    return t('customer_account.length_validation_error', {
      fieldName: t('customer_account.zip_placeholder'),
      maxLength: ZIP_CODE_MAX_LIMIT
    })
  return ''
}
