import React, { useContext } from 'react'
import { Box, NameValueList, NameValuePair } from 'grommet'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import { Anchor, Typography } from '../../../../../../../components'
import ServiceRolePermissions from '../ServiceRolePermissions'

const ReviewAndCreate = () => {
  const {
    nameValuePair: { name }
  } = useContext(ThemeContext)

  const { t } = useTranslation(['common', 'iam'])
  const { formValues, setFormValues, setActiveStep } = useContext(WizardContext)
  return (
    <Box
      width="large"
      gap="medium"
      justify="between"
      align="start"
      alignSelf="center"
      margin={{ top: 'medium', bottom: 'large' }}
    >
      <Box
        width="large"
        direction="row-responsive"
        justify="between"
        border="bottom"
        pad={{ bottom: 'medium' }}
      >
        <Typography type="heading" level="2" size="medium">
          {t('iam:roles.general_information_title')}
        </Typography>
        <Anchor
          label={
            <Typography type="text" weight="bold" size="small">
              {t('iam:roles.edit_create_role_step_one_label')}
            </Typography>
          }
          href="#"
          onClick={(event) => {
            event.preventDefault()
            setFormValues({
              ...formValues,
              editInStep1: true
            })

            setActiveStep(1)
          }}
          testId="edit-step-1-anchor"
        />
      </Box>
      <Box align="start" fill gap="medium">
        <NameValueList nameProps={{ width: 'xsmall' }}>
          <NameValuePair
            name={
              <Typography testId="role-name-text" type="text" {...name}>
                {t('iam:roles_table_headers.name')}
              </Typography>
            }
          >
            <Typography testId="role-name-text-value" type="text">
              {formValues?.isCreateNewRole
                ? formValues?.roleName
                : formValues?.newRoleName}
            </Typography>
          </NameValuePair>

          <NameValuePair
            name={
              <Typography testId="role-description-text" type="text" {...name}>
                {t('iam:roles_table_headers.description')}
              </Typography>
            }
          >
            <Typography testId="role-description-value" truncate type="text">
              {formValues?.description}
            </Typography>
          </NameValuePair>
        </NameValueList>
      </Box>
      <Box width="large" direction="row-responsive" justify="between">
        <Typography type="heading" level="2" size="medium">
          {t('iam:roles_permission.permission_title')}
        </Typography>
        <Anchor
          label={
            <Typography type="text" weight="bold" size="small">
              {t('iam:roles.edit_create_role_step_two_label')}
            </Typography>
          }
          href="#"
          onClick={(event) => {
            event.preventDefault()
            setActiveStep(2)
          }}
          testId="edit-step-2-anchor"
        />
      </Box>
      <ServiceRolePermissions />
    </Box>
  )
}

export default ReviewAndCreate
