import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  ButtonGroup,
  ModalDialog,
  ModalHeader,
  ModalFooter,
  Typography
} from '../../../../components'

const ConfirmDeleteModal = ({ id, setDeleteClicked, deleteHandler }) => {
  const { t } = useTranslation(['common', 'location'])
  return (
    <ModalDialog
      width="medium"
      testId="confirmation-modal"
      onClose={() => {}}
      header={
        <ModalHeader
          title={
            <Typography
              testId="confirmation-header-title"
              type="heading"
              color="text-strong"
              weight="bold"
              level="1"
            >
              {t('location:delete_confirmation_header')}
            </Typography>
          }
        />
      }
      content={
        <>
          <Typography
            size="medium"
            margin={{ top: 'small' }}
            testId="confirmation-header-subtitle"
            type="text"
            color="text-strong"
          >
            {t('location:delete_confirmation_subheader1')}
          </Typography>
          <ul>
            <li>{t('location:delete_confirmation_subheader2')}</li>
            <li>{t('location:delete_confirmation_subheader3')}</li>
            <li>{t('location:delete_confirmation_subheader4')}</li>
          </ul>
        </>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('common:cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: () => setDeleteClicked(false)
                },
                {
                  id: 1,
                  label: t('common:confirm'),
                  primary: true,
                  testId: 'okay-btn',
                  onClick: () => {
                    deleteHandler(id)
                  }
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
    />
  )
}

ConfirmDeleteModal.propTypes = {
  id: PropTypes.string.isRequired,
  setDeleteClicked: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired
}

export default ConfirmDeleteModal
