import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ButtonGroup,
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Notification,
  Typography
} from '../../../../components'
import { del, getErrorMessage } from '../../../../utils/api-utils'

const DeleteUserModal = ({
  email,
  customerId,
  setShowModal,
  refreshUsers,
  deleteAcrossCustomers
}) => {
  const { t } = useTranslation(['manage'])
  const [errorMessage, setErrorMessage] = useState('')
  const [deleteLoading, setDeleteLoading] = useState(false)
  const { oidcUser } = useReactOidc()
  const handleDelete = () => {
    let request = {
      username: email
    }
    if (deleteAcrossCustomers) {
      request = { ...request, delete_across_customers: true }
    } else if (customerId) {
      request = { ...request, customer_id: customerId }
    }
    if (deleteAcrossCustomers || customerId) {
      setDeleteLoading(true)
      const url = `/support-assistant/v1alpha1/user`
      del(url, request, oidcUser.access_token).then(
        (response) => {
          if (response.status === 200 || response.status === 204) {
            setDeleteLoading(false)
            setErrorMessage('')
            setShowModal(false)
            refreshUsers()
          }
        },
        (error) => {
          const errorMsg = getErrorMessage(error, t)
          setErrorMessage(errorMsg)
          setDeleteLoading(false)
        }
      )
    }
  }
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              testId="delete-user-modal-title"
              level="1"
              type="heading"
            >
              {t('delete_user')}
            </Typography>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          {deleteAcrossCustomers ? (
            <Typography type="text" size="medium" margin={{ bottom: 'small' }}>
              <Trans i18nKey="delete_user_desc_multiple" t={t} email={email}>
                <strong>{{ email }}</strong>
              </Trans>
            </Typography>
          ) : (
            <Typography type="text" size="medium" margin={{ bottom: 'small' }}>
              <Trans i18nKey="delete_user_desc" t={t} email={email}>
                <strong>{{ email }}</strong>
              </Trans>
            </Typography>
          )}
          {errorMessage.length ? (
            <Notification
              backgroundColor="status-critical"
              onClose={() => {}}
              testId="delete-user-error-message"
              text={errorMessage}
              type="inline"
            />
          ) : null}
          {deleteLoading && (
            <Box direction="row" align="center" justify="center">
              <Loader testId="delete-users-loader" />
            </Box>
          )}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  secondary: true,
                  testId: 'cancel-btn',
                  onClick: () => {
                    setShowModal(false)
                    refreshUsers()
                  }
                },
                {
                  id: 1,
                  label: t('delete'),
                  primary: true,
                  testId: 'delete-btn',
                  onClick: handleDelete,
                  disabled: deleteLoading
                }
              ]}
              testId="delete-modal-buttons"
            />
          }
        />
      }
      onClose={() => setShowModal(false)}
      testId="delete-user-modal-dialog"
      width="medium"
    />
  )
}

DeleteUserModal.propTypes = {
  email: PropTypes.string.isRequired,
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setShowModal: PropTypes.func.isRequired,
  refreshUsers: PropTypes.func,
  deleteAcrossCustomers: PropTypes.bool
}

DeleteUserModal.defaultProps = {
  refreshUsers: () => {},
  customerId: undefined,
  deleteAcrossCustomers: false
}

export { DeleteUserModal }
