import join from 'lodash/join'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'

export const getFormattedData = (formData) => {
  const formattedData = { ...formData }
  formattedData.fileName = ''
  formattedData.serviceCategoryList = join(formData?.serviceCategory, ', ')
  if (formData?.media) {
    formattedData.fileName = formData?.media.substring(
      formData?.media.lastIndexOf('/') + 1
    )
  }
  formattedData.summaryEnabled = formattedData?.summaryFlag ? 'Yes' : 'No'
  return formattedData
}

export const getSubHeading = (formData, LDIstanbulFlag, t) => {
  const dataDetailsSubHeading = {
    title: t('whats_new.create_post_form.title_field_label'),
    serviceCategoryList: t('whats_new.create_post_form.service_catalog'),
    shortDescription: t('whats_new.create_post_form.full_description'),
    fileName: t('whats_new.create_post_form.media_label'),
    publicationLocation: t('whats_new.publication_location'),
    referenceLinks: t('whats_new.create_post_form.reference_link_title')
  }
  if (LDIstanbulFlag) {
    dataDetailsSubHeading.summaryEnabled = t(
      'whats_new.create_post_form.home_page_announcement'
    )
  }

  if (LDIstanbulFlag && formData?.summaryFlag) {
    dataDetailsSubHeading.summary = t(
      'whats_new.create_post_form.summary_title'
    )
  }
  return dataDetailsSubHeading
}

export const validateSchedule = (formValues, schedulePost, i18nTranslate) => {
  const requiredFields = ['scheduleDate', 'scheduleTime']
  const errorObj = {}
  const regexp = new RegExp('^([1-9]|0[1-9]|1[0-2]):[0134][05] ([AaPp][Mm])$')
  if (schedulePost) {
    requiredFields.forEach((k) => {
      if (isUndefined(formValues[k]) || isEmpty(formValues[k])) {
        errorObj[k] = i18nTranslate('required')
      } else if (!regexp.test(formValues.scheduleTime)) {
        errorObj.scheduleTime = i18nTranslate(
          'whats_new.create_post_form.invalid_format'
        )
      }
    })
  }

  return errorObj
}
