import { Text, Box, Tag, Markdown } from 'grommet'
import { capitalize } from 'lodash'
import {
  StatusCriticalSmall,
  StatusGoodSmall,
  CircleInformation,
  StatusWarningSmall
} from 'grommet-icons'
import React from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import DOMPurify from 'dompurify'

import { Typography } from '../../../../components'
import {
  WKSPC_PLURAL_CAPITALIZED,
  getWorkspaceString
} from '../../../../utils/common-utils'

import { statusArr, targetMap } from './constants'

const config = {
  ALLOWED_TAGS: [
    'a',
    'table',
    'tr',
    'th',
    'td',
    'h1',
    'h2',
    'h3',
    'strong',
    'em',
    'p',
    'ul',
    'ol',
    'li',
    'br',
    'sub',
    'sup',
    'hr'
  ],
  ALLOWED_ATTR: [
    'href',
    'name',
    'target',
    'title',
    'id',
    'rel',
    'style',
    'width',
    'border'
  ]
}

export const sanitizeInput = (value) => {
  return DOMPurify.sanitize(value, config)
}
export const getIcons = (status) => {
  const severity = status ? status.toLowerCase() : ''
  let element
  switch (severity) {
    case 'critical':
      element = (
        <Box pad={{ top: 'xsmall' }}>
          <StatusCriticalSmall size="small" color="status-critical" />
        </Box>
      )
      break
    case 'warning':
      element = (
        <Box pad={{ top: 'xsmall' }}>
          <StatusWarningSmall size="small" color="status-warning" />
        </Box>
      )
      break
    case 'ok':
    case 'resolved':
      element = (
        <Box pad={{ top: 'xsmall' }}>
          <StatusGoodSmall size="small" color="status-ok" />
        </Box>
      )
      break
    default:
      element = (
        <Box pad={{ top: 'xsmall' }}>
          <CircleInformation size="small" />
        </Box>
      )
  }
  return element
}

export const getTime = (expirationDate) => {
  dayjs.extend(utc)
  const expDate = dayjs(expirationDate).format('MM-DD-YYYY')

  return expDate
}

export const getDetailsForReview = (
  t,
  notificationData,
  colsDisplayed,
  selectedType,
  LDFlags,
  isEdit,
  compareEditedNoti
) => {
  const html = []
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const categoryFlag = LDFlags['glcp-notifications-feature-category-istanbul']
  const isDynamic = LDFlags['glcp-notifications-feature-dynamic-app-istanbul']

  colsDisplayed.forEach((c) => {
    switch (c) {
      case 'target':
        if (selectedType === t('notification.services')) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`notification.${c}`)}
            </Typography>
          )
          html.push(
            <Text id={`${c}_value`} key={`${c}value`}>
              {isDynamic && notificationData?.targetType === 'SERVICES'
                ? notificationData?.target_name
                : targetMap[notificationData[c]]}
            </Text>
          )
        }
        break
      case 'service':
        if (selectedType === t('notification.services')) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`notification.${c}`)}
            </Typography>
          )
          if (
            isDynamic &&
            (notificationData?.targetType === 'SERVICES' ||
              notificationData?.targetType === 'PLATFORM')
          ) {
            if (notificationData?.target_name) {
              html.push(
                <Text id={`${c}_value`} key={`${c}value`}>
                  {notificationData?.target_name}
                </Text>
              )
            } else {
              html.push(
                <Text id={`${c}_value`} key={`${c}value`}>
                  {t(`notification.platform`)}
                </Text>
              )
            }
          } else {
            html.push(
              <Text id={`${c}_value`} key={`${c}value`}>
                {targetMap[notificationData[c]]}
              </Text>
            )
          }
        }
        break
      case 'emailAddresses':
        if (selectedType === t('notification.email_address')) {
          const emails = notificationData?.emailAddresses.split(',')
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`Email`)}
            </Typography>
          )
          html.push(
            <Box direction="column">
              {emails?.map((email) => (
                <Text testId={`${email}_value`} key={`${email}value`}>
                  {email}
                </Text>
              ))}
            </Box>
          )
        }
        break
      case 'accounts':
        if (
          selectedType ===
          getWorkspaceString(showWorkspaceString, t, WKSPC_PLURAL_CAPITALIZED)
        ) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_PLURAL_CAPITALIZED
              )}
            </Typography>
          )
          if (LDFlags['glcp-notifications-account-role-target']) {
            html.push(
              <Box direction="column">
                {notificationData?.accounts.map((accountData) => (
                  <Box direction="column">
                    <Text
                      testId={`${accountData?.account}_value`}
                      key={`${accountData?.account}value`}
                      weight="bold"
                    >
                      {accountData?.account}
                    </Text>
                    <Text
                      testId={`${accountData?.accountID}_role_value`}
                      key={`${accountData?.accountID}rolevalue`}
                    >
                      ({t('notification.roles')}:{' '}
                      {accountData?.roleNames.join(', ')})
                    </Text>
                  </Box>
                ))}
              </Box>
            )
          } else {
            const accounts = notificationData?.accounts.map(
              (value) => value?.account
            )
            html.push(
              <Box direction="column">
                {accounts?.map((account) => (
                  <Text testId={`${account}_value`} key={`${account}value`}>
                    {account}
                  </Text>
                ))}
              </Box>
            )
          }
        }
        break
      case 'regions':
        if (selectedType === t('notification.services')) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`Regions`)}
            </Typography>
          )
          html.push(
            <Box direction="column">
              {notificationData?.region?.map((region) => (
                <Text testId={`${region}_value`} key={`${region}value`}>
                  {region}
                </Text>
              ))}
            </Box>
          )
        }
        break
      case 'role':
        if (selectedType === t('notification.services')) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`Roles`)}
            </Typography>
          )
          html.push(
            <Box direction="column">
              {notificationData?.selectedRoleName?.map((role) => (
                <Text testId={`${role}_value`} key={`${role}value`}>
                  {role}
                </Text>
              ))}
            </Box>
          )
        }
        break
      case 'instance':
        if (selectedType === t('notification.services')) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`Instances`)}
            </Typography>
          )
          html.push(
            <Box direction="column">
              {notificationData?.selectedInstanceName.map((instance) => (
                <Text testId={`${instance}_value`} key={`${instance}value`}>
                  {instance}
                </Text>
              ))}
            </Box>
          )
        }
        break
      case 'status':
        html.push(
          <Text weight="bold" id={`notification-detail-${c}`} key={c}>
            {t(`notification.${c}`)}
          </Text>
        )
        html.push(
          <Box direction="row" id={`${c}_value`} key={`${c}value`}>
            {getIcons(notificationData[c])}
            <Text
              margin={{ left: 'xsmall', right: 'small' }}
              key={`${c}$state_value`}
            >
              {capitalize(notificationData[c])}
            </Text>
            {isEdit && compareEditedNoti?.status && (
              <Tag value={t('notification.edited')} size="small" />
            )}
          </Box>
        )
        break
      case 'message':
        html.push(
          <Typography
            id={`notification-detail-${c}`}
            key={c}
            type="text"
            size="small"
            color="text-strong"
            weight="bold"
          >
            {t(`notification.${c}`)}
          </Typography>
        )
        html.push(
          <Box direction="row" id={`${c}_value`} key={`${c}value`}>
            <Text
              margin={{ right: 'small' }}
              type="text"
              key={`${c}$state_value`}
            >
              {notificationData?.summary}
            </Text>
            {isEdit && compareEditedNoti?.summary && (
              <Tag value={t('notification.edited')} size="small" />
            )}
          </Box>
        )
        break
      case 'additional_content':
        html.push(
          <Typography
            id={`notification-detail-${c}`}
            key={c}
            type="text"
            size="small"
            color="text-strong"
            weight="bold"
          >
            {t(`notification.${c}`)}
          </Typography>
        )
        html.push(
          <Box direction="row" id={`${c}_value`} key={`${c}value`}>
            <Markdown key={`${c}$state_value`} margin={{ right: 'small' }}>
              {notificationData?.description}
            </Markdown>
            <Box justify="center">
              {isEdit && compareEditedNoti?.emailContentDetails && (
                <Tag value={t('notification.edited')} size="small" />
              )}
            </Box>
          </Box>
        )
        break
      case 'delivery':
        html.push(
          <Typography
            id={`notification-detail-${c}`}
            key={c}
            type="text"
            size="small"
            color="text-strong"
            weight="bold"
          >
            {t(`notification.delivery_medium`)}
          </Typography>
        )
        if (notificationData?.banner && !notificationData?.email) {
          html.push(
            <Box direction="row" id={`${c}_value`} key={`${c}value`}>
              <Text id={`${c}_value`} key={`${c}value`}>
                {t(`notification.banner_delivery_only`)}
              </Text>
              {isEdit && compareEditedNoti?.email && (
                <Tag value={t('notification.edited')} size="small" />
              )}
            </Box>
          )
        } else if (notificationData?.email && !notificationData?.banner) {
          html.push(
            <Box direction="row" id={`${c}_value`} key={`${c}value`}>
              <Text id={`${c}_value`} key={`${c}value`}>
                {t(`notification.email_delivery_only`)}
              </Text>
              {isEdit && compareEditedNoti?.email && (
                <Tag value={t('notification.edited')} size="small" />
              )}
            </Box>
          )
        } else if (notificationData?.email && notificationData?.banner) {
          html.push(
            <Box direction="row" id={`${c}_value`} key={`${c}value`}>
              <Text id={`${c}_value`} key={`${c}value`}>
                {t(`notification.banner_email_delivery`)}
              </Text>
              {isEdit && compareEditedNoti?.email && (
                <Tag value={t('notification.edited')} size="small" />
              )}
            </Box>
          )
        } else {
          html.push(
            <Box direction="row" id={`${c}_value`} key={`${c}value`}>
              <Text id={`${c}_value`} key={`${c}value`}>
                {t(`notification.no_banner_email_delivery`)}
              </Text>
              {isEdit && compareEditedNoti?.email && (
                <Tag value={t('notification.edited')} size="small" />
              )}
            </Box>
          )
        }
        break
      case 'expiration':
        if (notificationData?.expirationDate) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`notification.${c}`)}
            </Typography>
          )
          html.push(
            <Box direction="row">
              <Box>
                <Text
                  id={`${c}_value`}
                  key={`${c}value`}
                  margin={{ right: 'small' }}
                >
                  {getTime(notificationData?.expirationDate)}
                </Text>{' '}
              </Box>
              <Box>
                <Text
                  id={`${c}_value`}
                  key={`${c}value`}
                  margin={{ right: 'small' }}
                >
                  {notificationData?.expirationTime}
                </Text>
              </Box>
              {isEdit &&
                (compareEditedNoti?.expirationDate ||
                  compareEditedNoti?.expirationTime) && (
                  <Tag value={t('notification.edited')} size="small" />
                )}
            </Box>
          )
        }
        break
      case 'banner_summary':
        html.push(
          <Typography
            id={`notification-detail-${c}`}
            key={c}
            type="text"
            size="small"
            color="text-strong"
            weight="bold"
          >
            {t(`notification.banner_summary`)}
          </Typography>
        )
        html.push(
          <Box direction="row" id={`${c}_value`} key={`${c}value`}>
            <Text key={`${c}$state_value`} margin={{ right: 'xsmall' }}>
              {notificationData?.summary}
            </Text>
            {isEdit && compareEditedNoti?.summary && (
              <Tag value={t('notification.edited')} size="small" />
            )}
          </Box>
        )
        break
      case 'banner_additional_content':
        html.push(
          <Typography
            id={`notification-detail-${c}`}
            key={c}
            type="text"
            size="small"
            color="text-strong"
            weight="bold"
          >
            {t(`notification.banner_additional_content`)}
          </Typography>
        )
        html.push(
          <Box direction="row" id={`${c}_value`} key={`${c}value`}>
            <Markdown key={`${c}$state_value`} margin={{ right: 'xsmall' }}>
              {notificationData?.description}
            </Markdown>
            <Box justify="center">
              {isEdit && compareEditedNoti?.emailContentDetails && (
                <Tag value={t('notification.edited')} size="small" />
              )}
            </Box>
          </Box>
        )

        break
      case 'url':
        if (notificationData?.url?.length > 0) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`notification.${c}`)}
            </Typography>
          )
          html.push(
            <Text id={`${c}_value`} key={`${c}value`}>
              {notificationData?.url}
            </Text>
          )
        }
        break

      case 'url_text':
        if (notificationData?.url_text?.length > 0) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`notification.${c}`)}
            </Typography>
          )
          html.push(
            <Text id={`${c}_value`} key={`${c}value`}>
              {notificationData?.url_text}
            </Text>
          )
        }

        break

      case 'email_subject':
        html.push(
          <Typography
            id={`notification-detail-${c}`}
            key={c}
            type="text"
            size="small"
            color="text-strong"
            weight="bold"
          >
            {t(`notification.email_content_title`)}
          </Typography>
        )
        html.push(
          <Box direction="row" id={`${c}_value`} key={`${c}value`}>
            <Text key={`${c}$state_value`} margin={{ right: 'small' }}>
              {t('notification.email_header')}
              {notificationData?.emailContentTitle}
            </Text>
            <Box justify="center">
              {isEdit && compareEditedNoti?.emailContentDetails && (
                <Tag value={t('notification.edited')} size="small" />
              )}
            </Box>
          </Box>
        )
        break
      case 'email_content':
        html.push(
          <Typography
            id={`notification-detail-${c}`}
            key={c}
            type="text"
            size="small"
            color="text-strong"
            weight="bold"
          >
            {t(`notification.email_content_details`)}
          </Typography>
        )
        html.push(
          <Box direction="row" id={`${c}_value`} key={`${c}value`}>
            <Markdown key={`${c}$state_value`} margin={{ right: 'small' }}>
              {notificationData?.emailContentDetails}
            </Markdown>
            <Box justify="center">
              {isEdit && compareEditedNoti?.emailContentDetails && (
                <Tag value={t('notification.edited')} size="small" />
              )}
            </Box>
          </Box>
        )

        break
      case 'category':
        if (categoryFlag && notificationData?.categoryService) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`notification.category`)}
            </Typography>
          )
          html.push(
            <Box direction="column">
              <Text key={`${c}$state_value`}>
                {!isDynamic
                  ? notificationData?.categoryService
                  : notificationData?.appName}
              </Text>
              <Text>
                {notificationData?.subService &&
                  notificationData?.subService !== t('notification.default') &&
                  notificationData?.subService}
              </Text>
              <Text>{notificationData?.topic && notificationData?.topic}</Text>
              <Text>
                {notificationData?.subTopic && notificationData?.subTopic}
              </Text>
            </Box>
          )
        }

        break
      case 'state':
        if (isEdit) {
          html.push(
            <Text weight="bold" id={`notification-detail-${c}`} key={c}>
              {t(`notification.${c}`)}
            </Text>
          )
          html.push(
            <Box direction="row" id={`${c}_value`} key={`${c}value`}>
              <Text
                margin={{ left: 'xsmall', right: 'small' }}
                key={`${c}$state_value`}
              >
                {capitalize(notificationData[c])}
              </Text>
              {isEdit && compareEditedNoti?.state && (
                <Tag value={t('notification.edited')} size="small" />
              )}
            </Box>
          )
        }
        break

      default:
        html.push(<></>)
    }
  })

  return html
}

export const getBannerReviewDetails = (
  t,
  notificationData,
  colsDisplayed,
  isEdit,
  compareEditedNoti
) => {
  const html = []

  colsDisplayed.forEach((c) => {
    switch (c) {
      case 'date':
        if (notificationData?.expirationDate) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`notification.${c}`)}
            </Typography>
          )
          html.push(
            <Box direction="row">
              <Box>
                <Text
                  id={`${c}_value`}
                  key={`${c}value`}
                  margin={{ right: 'small' }}
                >
                  {getTime(notificationData?.expirationDate)}
                </Text>{' '}
              </Box>
              {isEdit &&
                (compareEditedNoti?.expirationDate ||
                  compareEditedNoti?.expirationTime) && (
                  <Tag value={t('notification.edited')} size="small" />
                )}
            </Box>
          )
        }
        break
      case 'time':
        if (notificationData?.expirationDate) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`time`)}
            </Typography>
          )
          html.push(
            <Box direction="row">
              <Text
                id={`${c}_value`}
                key={`${c}value`}
                margin={{ right: 'small' }}
              >
                {notificationData?.expirationTime}
              </Text>
              {isEdit &&
                (compareEditedNoti?.expirationDate ||
                  compareEditedNoti?.expirationTime) && (
                  <Tag value={t('notification.edited')} size="small" />
                )}
            </Box>
          )
        }
        break

      default:
        html.push(<></>)
    }
  })

  return html
}

export const getScheduleReviewDetails = (
  t,
  notificationData,
  colsDisplayed
) => {
  const html = []
  colsDisplayed.forEach((c) => {
    switch (c) {
      case 'date':
        if (notificationData?.start_date) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`notification.${c}`)}
            </Typography>
          )
          html.push(
            <Box direction="row">
              <Box>
                <Text
                  id={`${c}_value`}
                  key={`${c}value`}
                  margin={{ right: 'small' }}
                >
                  {getTime(notificationData?.start_date)}
                </Text>{' '}
              </Box>
            </Box>
          )
        }
        break
      case 'time':
        if (notificationData?.start_time) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`time`)}
            </Typography>
          )
          html.push(
            <Box>
              <Text
                id={`${c}_value`}
                key={`${c}value`}
                margin={{ right: 'small' }}
              >
                {notificationData?.start_time}
              </Text>
            </Box>
          )
        }
        break

      default:
        html.push(<></>)
    }
  })

  return html
}

export const getNotificationContentReviewDetails = (
  t,
  notificationData,
  colsDisplayed,
  isEdit,
  compareEditedNoti
) => {
  const html = []

  colsDisplayed.forEach((c) => {
    switch (c) {
      case 'summary_title':
        html.push(
          <Typography
            id={`notification-detail-${c}`}
            key={c}
            type="text"
            size="small"
            color="text-strong"
            weight="bold"
          >
            {t(`notification.${c}`)}
          </Typography>
        )
        html.push(
          <Box direction="row" id={`${c}_value`} key={`${c}value`}>
            <Text
              margin={{ right: 'small' }}
              type="text"
              key={`${c}$state_value`}
            >
              {notificationData?.summary}
            </Text>
            {isEdit && compareEditedNoti?.summary && (
              <Tag value={t('notification.edited')} size="small" />
            )}
          </Box>
        )
        break
      case 'additional_content':
        html.push(
          <Typography
            id={`notification-detail-${c}`}
            key={c}
            type="text"
            size="small"
            color="text-strong"
            weight="bold"
          >
            {t(`notification.${c}`)}
          </Typography>
        )
        html.push(
          <Box direction="row" id={`${c}_value`} key={`${c}value`}>
            <Markdown key={`${c}$state_value`} margin={{ right: 'small' }}>
              {notificationData?.description}
            </Markdown>
            <Box justify="center">
              {isEdit && compareEditedNoti?.description && (
                <Tag value={t('notification.edited')} size="small" />
              )}
            </Box>
          </Box>
        )

        break
      case 'email_subject':
        if (notificationData?.email) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`notification.email_content_title`)}
            </Typography>
          )
          html.push(
            <Box direction="row" id={`${c}_value`} key={`${c}value`}>
              <Text key={`${c}$state_value`} margin={{ right: 'small' }}>
                {t('notification.email_header')}
                {notificationData?.emailContentTitle}
              </Text>
              {isEdit && compareEditedNoti?.emailContentTitle && (
                <Tag value={t('notification.edited')} size="small" />
              )}
            </Box>
          )
        }
        break
      case 'email_content':
        if (notificationData?.email) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`notification.email_content_details`)}
            </Typography>
          )
          html.push(
            <Box direction="row" id={`${c}_value`} key={`${c}value`}>
              <Markdown key={`${c}$state_value`} margin={{ right: 'small' }}>
                {notificationData?.emailContentDetails}
              </Markdown>
              <Box justify="center">
                {isEdit && compareEditedNoti?.emailContentDetails && (
                  <Tag value={t('notification.edited')} size="small" />
                )}
              </Box>
            </Box>
          )
        }

        break

      default:
        html.push(<></>)
    }
  })

  return html
}

export const getCategoryReviewDetails = (
  t,
  notificationData,
  colsDisplayed,
  isDynamic
) => {
  const html = []

  colsDisplayed.forEach((c) => {
    switch (c) {
      case 'service':
        html.push(
          <Typography
            id={`notification-detail-${c}`}
            key={c}
            type="text"
            size="small"
            color="text-strong"
            weight="bold"
          >
            {t(`notification.${c}`)}
          </Typography>
        )
        html.push(
          <Text testId={`${c}_value`} key={`${c}value`}>
            {!isDynamic
              ? notificationData?.categoryService
              : notificationData?.appName}
          </Text>
        )
        break

      case 'sub_service':
        if (
          notificationData?.subService &&
          notificationData?.subService !== t('notification.default')
        ) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`notification.${c}`)}
            </Typography>
          )

          html.push(
            <Text testId={`${c}_value`} key={`${c}value`}>
              {notificationData?.subService}
            </Text>
          )
        }
        break

      case 'topics':
        if (notificationData?.topic) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`notification.${c}`)}
            </Typography>
          )
          html.push(
            <Text testId={`${c}_value`} key={`${c}value`}>
              {notificationData?.topic}
            </Text>
          )
        }
        break

      case 'sub_topics':
        if (notificationData?.subTopic) {
          html.push(
            <Typography
              id={`notification-detail-${c}`}
              key={c}
              type="text"
              size="small"
              color="text-strong"
              weight="bold"
            >
              {t(`notification.${c}`)}
            </Typography>
          )
          html.push(
            <Text testId={`${c}_value`} key={`${c}value`}>
              {notificationData?.subTopic}
            </Text>
          )
        }
        break

      default:
        html.push(<></>)
    }
  })

  return html
}

export const getTargetEnum = (
  LDFlags,
  targetType,
  region,
  applicationInstance,
  role
) => {
  let target
  if (!LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
    switch (targetType) {
      case 'ARUBA_CENTRAL':
        target = {
          type: 'CENTRALS',
          ids: ['ARUBA_CENTRAL'],
          regions: region,
          applicationInstances: applicationInstance,
          roles: role
        }
        break
      case 'COMPUTE_CENTRAL':
        target = {
          type: 'CENTRALS',
          ids: ['COMPUTE_CENTRAL'],
          regions: region,
          applicationInstances: applicationInstance,
          roles: role
        }
        break
      case 'STORAGE_CENTRAL':
        target = {
          type: 'CENTRALS',
          ids: ['STORAGE_CENTRAL'],
          regions: region,
          applicationInstances: applicationInstance,
          roles: role
        }
        break
      case 'PCE':
        target = {
          type: 'CENTRALS',
          ids: ['PCE'],
          regions: region,
          applicationInstances: applicationInstance,
          roles: role
        }
        break
      case 'OPS_RAMP':
        target = {
          type: 'CENTRALS',
          ids: ['OPS_RAMP'],
          regions: region,
          applicationInstances: applicationInstance,
          roles: role
        }
        break
      case 'SUSTAINABLITY_IC':
        target = {
          type: 'CENTRALS',
          ids: ['SUSTAINABLITY_IC'],
          regions: region,
          applicationInstances: applicationInstance,
          roles: role
        }
        break
      case 'GREENLAKE_FLEX':
        target = {
          type: 'CENTRALS',
          ids: ['GREENLAKE_FLEX'],
          regions: region,
          applicationInstances: applicationInstance,
          roles: role
        }
        break
      default:
        target = {
          type: 'PLATFORM',
          ids: [],
          regions: region,
          applicationInstances: applicationInstance,
          roles: role
        }
    }
    return target
  }

  switch (targetType) {
    case 'PLATFORM':
      target = {
        type: 'PLATFORM',
        ids: [],
        regions: region,
        applicationInstances: applicationInstance,
        roles: role
      }
      break
    default:
      target = {
        type: 'SERVICES',
        ids: [targetType],
        regions: region,
        applicationInstances: applicationInstance,
        roles: role
      }
  }
  return target
}

export const checkIfAll = (allPref, selectedPref) => {
  return (
    allPref?.length &&
    selectedPref?.length &&
    allPref.length === selectedPref.length &&
    allPref.every((element, index) => {
      return element === selectedPref[index]
    })
  )
}

export const formExpiration = (formValues) => {
  dayjs.extend(utc)

  const { expirationDate, expirationTime } = formValues
  const expDate = dayjs(expirationDate).format('MM/DD/YYYY')
  const withExpTime = dayjs(`${expDate} ${expirationTime}`).unix()

  return withExpTime
}

export const getStatusList = (statusIdentifier) => {
  const statusList = []
  statusIdentifier.map((i) => statusList.push(statusArr[i]))
  return statusList
}

export const getStatusIdentifiers = (statusList) => {
  const statusMap = {
    Critical: 1,
    Information: 0,
    Warning: 2,
    Ok: 3
  }

  const statusIdentifiers = []
  statusList.map((s) => statusIdentifiers.push(statusMap[s]))
  return statusIdentifiers
}
