import { Box, Tip } from 'grommet'
import { PropTypes } from 'prop-types'

import { Typography } from '../../../components'

const LastUpdatedTooltip = ({ formattedDate, lastUpdated }) => {
  return (
    <Typography type="text">
      <Tip
        content={
          <Box gap="small">
            <Typography type="text">{lastUpdated}</Typography>
          </Box>
        }
        dropProps={{
          align: { bottom: 'top' }
        }}
      >
        {formattedDate}
      </Tip>
    </Typography>
  )
}

LastUpdatedTooltip.propTypes = {
  formattedDate: PropTypes.string.isRequired,
  lastUpdated: PropTypes.string.isRequired
}

export default LastUpdatedTooltip
