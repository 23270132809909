import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, FormField, TextInput } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ButtonGroup,
  CCSForm
} from '../../../../../components'
import { get, getErrorMessage } from '../../../../../utils/api-utils'

const ClaimDomainModal = ({
  onSetOpen,
  onSetAckDomainOpen,
  setDomain,
  setClaimedAccountsCount,
  handleAcknowledgeAndContinue
}) => {
  const { t } = useTranslation(['authn'])
  const { oidcUser } = useReactOidc()

  const [domainName, setDomainName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleCancel = () => {
    onSetOpen(false)
  }

  const handleContinue = () => {
    get(
      '/authn/v1/saml/validate_domain',
      { domain: domainName },
      oidcUser.access_token
    ).then(
      (response) => {
        if (response.status === 200) {
          setErrorMessage('')
          setDomain(domainName)
          get(
            `/authn/v1/saml/claims/${domainName}`,
            {},
            oidcUser.access_token
          ).then(
            (resp) => {
              onSetOpen(false)
              setClaimedAccountsCount(resp.data.num_of_accounts[0])
              if (resp.data.num_of_accounts[0]) {
                onSetAckDomainOpen(true)
              } else {
                handleAcknowledgeAndContinue(domainName)
              }
            },
            (error) => {
              const backendErrorMessage = getErrorMessage(error, t)
              setErrorMessage(backendErrorMessage)
            }
          )
        }
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }

  return (
    <Box>
      <ModalDialog
        width="medium"
        testId="claim-domain-modal"
        content={
          <Box gap="xsmall">
            <Typography type="text" size="small" testId="add-saml-connection">
              {t('saml_sso.add_saml_connection')}
            </Typography>
            <Typography type="heading" level={2} testId="claim-domain">
              {t('saml_sso.claim_domain')}
            </Typography>
            <Typography
              type="paragraph"
              size="small"
              testId="claim-domain-description"
            >
              {t('saml_sso.claim_domain_desc')}
            </Typography>
            <Box margin={{ top: 'small' }} gap="xsmall">
              <Typography
                type="heading"
                level={3}
                testId="organization-domain-name"
              >
                {t('saml_sso.organization_domain_name')}
              </Typography>
              <Typography
                type="text"
                size="xsmall"
                testId="claim-domain-disclaimer"
              >
                {t('saml_sso.claim_domain_disclaimer')}
              </Typography>
            </Box>
            <CCSForm errorMessage={errorMessage} testId="add-domain-form">
              <FormField
                data-testid="add-domain-form-field"
                name="domain_name"
                width="medium"
                required
              >
                <TextInput
                  data-testid="domain-name-input"
                  name="domain_name"
                  placeholder="example.com"
                  value={domainName}
                  onChange={(event) => setDomainName(event.target.value)}
                />
              </FormField>
            </CCSForm>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('saml_sso.cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    onClick: handleCancel
                  },
                  {
                    id: 1,
                    label: t('saml_sso.continue'),
                    primary: true,
                    testId: 'continue-btn',
                    onClick: handleContinue
                  }
                ]}
                testId="two-buttons"
              />
            }
          />
        }
        onClose={() => onSetOpen(false)}
      />
    </Box>
  )
}

ClaimDomainModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  onSetAckDomainOpen: PropTypes.func.isRequired,
  setDomain: PropTypes.func.isRequired,
  setClaimedAccountsCount: PropTypes.func.isRequired,
  handleAcknowledgeAndContinue: PropTypes.func.isRequired
}

export { ClaimDomainModal }
