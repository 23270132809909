import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { LINKS, getLink } from '../utils/links-util'

import { TilesRow } from './common/TilesRow'

const CommunitiesSection = memo(() => {
  const { t } = useTranslation(['support_hub'])

  const communitiesTiles = [
    {
      id: 1,
      title: t('communities_cards.hpe_greenlake_community.title'),
      testId: 'hpe_greenLake_community-tile',
      isLinkExternal: true,
      isIconInTitle: true,
      href: getLink(LINKS.HPE_GREENLAKE_COMM),
      target: LINKS.HPE_GREENLAKE_COMM
    },
    {
      id: 2,
      title: t('communities_cards.hpe_developer_community.title'),
      testId: 'hpe_developer_community-tile',
      isLinkExternal: true,
      isIconInTitle: true,
      href: getLink(LINKS.HPE_DEV_COMM),
      target: LINKS.HPE_DEV_COMM
    },
    {
      id: 3,
      title: t('communities_cards.airheads_community.title'),
      testId: 'airheads_community-tile',
      isLinkExternal: true,
      isIconInTitle: true,
      href: getLink(LINKS.AIRHEADS_COMM),
      target: LINKS.AIRHEADS_COMM
    }
  ]

  return (
    <TilesRow
      title={t('communities_cards.communities')}
      testIdTitle="communities-test-id"
      rows={communitiesTiles}
    />
  )
})

export { CommunitiesSection }
