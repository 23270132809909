import React, { Suspense, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
/* eslint-disable */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import PropTypes from 'prop-types'

import { AuthenticationProvider } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'
/* eslint-enable */

import { AutoAnalyticsProvider } from '@glcp/greenlake-analytics'

import { ThemeMode } from './components/ThemeMode'
import CommonAppRouter from './router/common-app-router'
import getOIDCConfig from './oidc-config'
import StubbedAuthProvider from './context/stubbed-auth-context'
import { NotAuthorized, NotAuthenticated } from './pages/acct-onboarding'
import { CCSContextProvider } from './context/ccs-context'
import { VisibilityContextProvider } from './context/visibility-context'
import { UPSContextProvider } from './context/ups-context'
import LazyLoading from './pages/commoncomponents/LazyLoading/LazyLoading'
import { enableOidcLogs } from './utils/log-util'
import useLogger from './hooks/logs/useLogger'
import { Loader } from './components'
import { ContextualHelpFeedbackProvider } from './pages/commoncomponents/contextual-help-layout/ContextualHelpFeedbackProvider'
import { analyticsConfig } from './configs/analytics-config'
import { isGLOP } from './utils/feature-flag-utils'
import AmplitudeAnalyticsListener from './context/AmplitudeAnalyticsListener'

function App() {
  const logger = useLogger()
  const pageInfoLog = { page: 'App', section: 'Initialize', type: 'success' }
  logger?.log({ ...pageInfoLog, msg: 'App loaded successfully!' })
  /*
    Below line should be removed when environment settings are 
    read from `settings.json`. 
  */
  const { authorityURL } = window.$settings

  // TODO: move this under util
  // getting the current user to send to SSO for login_hint
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const loginHint = urlParams.get('login_hint')
  if (loginHint && !sessionStorage.getItem('current-lp-user') && isGLOP()) {
    sessionStorage.setItem('current-lp-user', loginHint)
  }

  const OIDCConfig = getOIDCConfig(authorityURL)

  const CommonContextLayer = () => (
    <VisibilityContextProvider>
      <UPSContextProvider>
        <ContextualHelpFeedbackProvider>
          <BrowserRouter>
            <ThemeMode>
              <CommonAppRouter />
            </ThemeMode>
          </BrowserRouter>
        </ContextualHelpFeedbackProvider>
      </UPSContextProvider>
    </VisibilityContextProvider>
  )

  const analyticsLD = sessionStorage?.getItem(
    'glcp-amplitude-analytics-istanbul'
  )
  const analyticsFlag = analyticsLD === 'true'

  const CommonContextLayerWithAnalytics = () => {
    const { oidcUser } = useReactOidc()
    const [config, setConfig] = useState(analyticsConfig())
    return (
      <VisibilityContextProvider>
        <UPSContextProvider>
          <AmplitudeAnalyticsListener setConfig={setConfig} />
          <AutoAnalyticsProvider
            analyticsConfig={config}
            userInfo={oidcUser}
            userAPIEndpointConfig={{
              url: '/accounts/ui/v1/user/profile/contact'
            }}
          >
            <ContextualHelpFeedbackProvider>
              <BrowserRouter>
                <ThemeMode>
                  <CommonAppRouter />
                </ThemeMode>
              </BrowserRouter>
            </ContextualHelpFeedbackProvider>
          </AutoAnalyticsProvider>
        </UPSContextProvider>
      </VisibilityContextProvider>
    )
  }

  /* Avoid OpenID authentication while mocking back-end with mirage
     We don't want authentication during development
     npm start - will set REACT_APP_PROXY_MIRAGE=true */
  return (
    <Suspense
      fallback={
        <Box
          height="xsmall"
          margin={{ top: 'small' }}
          width="100%"
          align="center"
          justify="center"
          alignSelf="center"
        >
          <Loader
            label="Loading..."
            orientation="horizontal"
            testId="app-loader"
          />
        </Box>
      }
    >
      <CCSContextProvider>
        {process.env.REACT_APP_PROXY_MIRAGE ? (
          <StubbedAuthProvider>
            {analyticsFlag ? (
              <CommonContextLayerWithAnalytics />
            ) : (
              <CommonContextLayer />
            )}
          </StubbedAuthProvider>
        ) : (
          <AuthenticationProvider
            configuration={OIDCConfig}
            loggerLevel={enableOidcLogs()}
            isEnabled
            callbackComponentOverride={() => {
              return <LazyLoading />
            }}
            notAuthenticated={NotAuthenticated}
            notAuthorized={NotAuthorized}
          >
            {analyticsFlag ? (
              <CommonContextLayerWithAnalytics />
            ) : (
              <CommonContextLayer />
            )}
          </AuthenticationProvider>
        )}
      </CCSContextProvider>
    </Suspense>
  )
}
export default App
