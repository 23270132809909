import React, { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, ResponsiveContext, Text, FormField } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'

import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Dropdown,
  Typography
} from '../../../../components'

const SupportModal = ({
  title,
  subtitle,
  testId,
  testIdDropDown,
  options,
  onDialogChangeState,
  onChangeDropdown,
  onCancel,
  value,
  canContinue,
  href,
  dropDownRestProps
}) => {
  const { t } = useTranslation(['support_hub'])
  const size = useContext(ResponsiveContext)
  const [setlectedOption, setSelectedOption] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)

  const modalWidthResponsive = (sizeArg) => {
    return ['small', 'xsmall'].includes(sizeArg) ? undefined : 'medium'
  }

  const handlerCancel = () => onDialogChangeState(false)

  const textTranslate = useMemo(() => {
    if (!setlectedOption.note1 || !setlectedOption.note2) {
      return null
    }

    const transComponent = (i18nkey) => (
      <Trans
        t={t}
        i18nKey={i18nkey}
        components={[<Text size="xsmall" weight="bold" />]}
      />
    )

    return (
      <Text size="xsmall" margin={{ top: 'small' }}>
        {transComponent(setlectedOption.note1)}
        <Box margin={{ top: 'small' }}>
          {transComponent(setlectedOption.note2)}
        </Box>
      </Text>
    )
  }, [setlectedOption.note1, setlectedOption.note2, t])

  const onCloseHandler = () => {
    handlerCancel()
    onCancel(false)
  }

  return (
    <ModalDialog
      width={modalWidthResponsive(size)}
      header={
        <ModalHeader
          title={
            <Typography type="heading" level={2}>
              {title}
            </Typography>
          }
          subtitle={subtitle}
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <FormField
            htmlFor="dropdown_case-list"
            error={errorMessage}
            contentProps={{
              border: null,
              margin: null
            }}
          >
            <Dropdown
              name="dropdown_case-list"
              options={options}
              onChangeDropdown={(selectedKey) => {
                setErrorMessage(null)

                setSelectedOption(
                  options.find((option) => option.value === selectedKey)
                )

                onChangeDropdown(selectedKey)
              }}
              value={value}
              testId={testIdDropDown}
              placeholder={t('select')}
              dropHeight="small"
              {...dropDownRestProps}
            />
          </FormField>
          {textTranslate}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <Box direction="row" justify="end" gap="small">
              <Button label={t('cancel')} onClick={handlerCancel} />
              <Button
                primary
                label={t('continue')}
                onClick={() => {
                  if (!canContinue) {
                    setErrorMessage(t('required_field'))
                    return
                  }

                  handlerCancel()
                }}
                href={href}
                target="_blank"
              />
            </Box>
          }
        />
      }
      onClose={onCloseHandler}
      testId={testId}
    />
  )
}

SupportModal.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  testIdDropDown: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      note1: PropTypes.string,
      note2: PropTypes.string
    })
  ).isRequired,
  onDialogChangeState: PropTypes.func.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  value: PropTypes.string,
  canContinue: PropTypes.bool.isRequired,
  href: PropTypes.string,
  dropDownRestProps: PropTypes.object
}

SupportModal.defaultProps = {
  onCancel: () => {},
  value: undefined,
  href: undefined,
  dropDownRestProps: undefined
}

export default SupportModal
