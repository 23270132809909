import React, { useContext } from 'react'
import { Box } from 'grommet'

import { List, Typography } from '../../../../components'
import { WizardContext } from '../../../../components/wizard/WizardContext'
import { getExpirationValue } from '../../utils'
import { WizardSubscriptionListHeader } from '../../components'

const ReviewAndFinish = () => {
  const { formValues } = useContext(WizardContext)

  const getSubscriptionDetails = (datum) => {
    const selectedApp = formValues?.selectedApplication[datum?.subscription_key]
    if (selectedApp)
      return `${datum?.subscription_key} | ${selectedApp?.application_name} ${selectedApp?.ccs_region}`
    return datum?.subscription_key
  }

  return (
    <>
      <WizardSubscriptionListHeader />
      <List
        data={formValues?.selectedProductList}
        pad={{ vertical: 'medium' }}
        border="top"
        customRender={(datum) => (
          <Box direction="row" justify="between">
            <Box gap="xsmall">
              {/* TODO: Modify subscription tier after BE confirms the field */}
              <Typography type="heading" level="2" testId="subscription-tier">
                {datum.subscription_tier}
              </Typography>
              <Typography type="text" size="small" testId="subscription-key">
                {getSubscriptionDetails(datum)}
              </Typography>
            </Box>
            <Box>
              {/* TODO: Modify expiration date after BE confirms the field */}
              <Typography type="heading" level="2" testId="expires">
                {getExpirationValue(datum?.end_date, 'MONTH_DAY_YEAR_BY_SLASH')}
              </Typography>
            </Box>
          </Box>
        )}
        eventType="custom-render"
        testId="selected-subscriptions-list"
      />
    </>
  )
}

export { ReviewAndFinish }
