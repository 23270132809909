import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Anchor,
  Grid,
  Page,
  PageContent,
  PageHeader,
  Heading
} from 'grommet'
import { Previous, Install } from 'grommet-icons'
import dayjs from 'dayjs'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { debounce } from 'lodash'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Typography,
  Button,
  DataTable,
  ActionButton,
  Notification,
  Ruler,
  NoDataInfo,
  ButtonGroup
} from '../../../components'
import { Layout } from '../../commoncomponents/layout/Layout'
import { get, post, del, getErrorResponse } from '../../../utils/api-utils'

import DeleteCredentialModal from './DeleteCredentialModal'
import AddCredentialModal from './AddCredentialModal'
import { convertAppIdToType } from './utils'
import StatusDisplay from './StatusDisplay'
import LoadingContainer from './LoadingContainer'

dayjs.extend(advancedFormat)

const itemsPerPage = 20

const DetailsSection = ({ app }) => {
  const { t } = useTranslation(['local-gateway', 'common'])

  return (
    <Box margin={{ bottom: 'medium' }}>
      <Box
        direction="row"
        pad={{ bottom: 'small' }}
        data-testid="container-status"
      >
        <Box width="medium">
          <Typography type="text" weight="bold">
            {t('status_text')}
          </Typography>
        </Box>
        <Box direction="row" width="large" align="center">
          <StatusDisplay status={app.status} />
        </Box>
      </Box>

      <Box
        direction="row"
        pad={{ bottom: 'small' }}
        data-testid="container-name"
      >
        <Box width="medium">
          <Typography type="text" weight="bold">
            {t('name')}
          </Typography>
        </Box>
        <Box data-testid="name-text" width="large">
          {t(`${convertAppIdToType()}.name`)}
        </Box>
      </Box>
      <Box
        direction="row"
        pad={{ bottom: 'small' }}
        data-testid="container-creation-date"
      >
        <Box width="medium">
          <Typography type="text" weight="bold">
            {t('creation_date_table_header')}
          </Typography>
        </Box>
        <Box data-testid="date-text" width="large">
          {dayjs(app.creationDate).format('MMMM Do, YYYY')}
        </Box>
      </Box>
    </Box>
  )
}

DetailsSection.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.number,
    creationDate: PropTypes.number
  }).isRequired
}

const AppDetailsContent = () => {
  const history = useHistory()
  const { appId, gatewayId } = useParams()
  const { t } = useTranslation(['local-gateway'])
  const { oidcUser } = useReactOidc()

  const handleBackBtnClick = () => {
    history.push(`/manage-account/local-gateway/details/${gatewayId}`)
  }

  const [appData, setAppData] = useState({})
  const [userCredentialsData, setUserCredentialsData] = useState([])
  const [filteredUserCredentialsData, setFilteredUserCredentialsData] =
    useState([])
  const [showAddModal, setShowAddModal] = useState(false)
  const [page, setPage] = useState(1)
  const [credentialToDelete, setCredentialToDelete] = useState()
  const [refreshList, setrefreshList] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSetSearchTerm = debounce(setSearchTerm, 500)

  const [loadingAppData, setLoadingAppData] = useState(true)
  const [loadingAppDataError, setLoadingAppDataError] = useState(false)
  const [deletedCredSuccess, setDeletedCredSuccess] = useState(false)
  const [deleteCredError, setDeleteCredError] = useState(false)
  const [addedCred, setAddedCred] = useState({})
  const [addedCredSuccess, setAddedCredSuccess] = useState(false)
  const [addCredError, setAddCredError] = useState(false)
  const [loadingCredsData, setLoadingCredsData] = useState(true)
  const [loadingCredsDataError, setLoadingCredsDataError] = useState(false)

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0) {
      const filteredData = userCredentialsData.filter((cred) =>
        cred.username.includes(searchTerm)
      )
      setFilteredUserCredentialsData(filteredData)
    } else {
      setFilteredUserCredentialsData(undefined)
    }
  }, [searchTerm, setFilteredUserCredentialsData, userCredentialsData])

  const deleteCredential = () => {
    setDeletedCredSuccess(false)
    setDeleteCredError(false)
    del(
      `/opg-proxy/v1/gateways/${gatewayId}/apps/${appId}/creds/${credentialToDelete}`,
      {},
      oidcUser.access_token
    ).then(
      () => {
        setrefreshList(!refreshList)
        setDeletedCredSuccess(true)
      },
      () => {
        setDeleteCredError(true)
      }
    )
  }

  const addCredential = ({ username, password }) => {
    setAddCredError(false)
    setAddedCredSuccess(false)
    setAddedCred({ username, password })
    const requestBody = {
      app: {},
      creds: {
        username,
        password
      }
    }
    post(
      `/opg-proxy/v1/gateways/${gatewayId}/apps/${appId}/creds`,
      requestBody,
      oidcUser.access_token
    ).then(
      () => {
        setrefreshList(!refreshList)
        setAddedCredSuccess(true)
      },
      (error) => {
        const { code } = getErrorResponse(error, t)
        const nameError = code === 409
        setAddCredError(
          nameError ? 'add_credential_name_error' : 'add_credential_error'
        )
      }
    )
  }

  useEffect(() => {
    setLoadingAppData(true)
    setLoadingAppDataError(false)
    // apps dont have their own data yet
    get(`/platform/acpmgr/gateway/${gatewayId}`, {}, oidcUser.access_token)
      .then(
        (response) => {
          if (response.data) {
            const app = {
              id: appId,
              status: response.data.status,
              creationDate: response.data.creationDate
            }
            setAppData(app)
          }
        },
        () => {
          setLoadingAppDataError(true)
        }
      )
      .finally(() => setLoadingAppData(false))
  }, [gatewayId, appId, setAppData, oidcUser])

  useEffect(() => {
    setLoadingCredsData(true)
    setLoadingCredsDataError(false)
    get(
      `/opg-proxy/v1/gateways/${gatewayId}/apps/${appId}/creds`,
      {},
      oidcUser.access_token
    )
      .then(
        (response) => {
          if (response.data) {
            setUserCredentialsData(response.data.creds)
          }
        },
        () => {
          setLoadingCredsDataError(true)
        }
      )
      .finally(() => setLoadingCredsData(false))
  }, [gatewayId, appId, setUserCredentialsData, refreshList, oidcUser])

  const columns = [
    {
      primary: true,
      type: 'string',
      property: 'username',
      header: (
        <Typography
          type="text"
          testId="name-table-header"
          size="large"
          weight="bold"
        >
          {t('user_name')}
        </Typography>
      ),
      render: (datum) => (
        <Grid
          rows={['auto', 'auto']}
          columns={['small', 'small']}
          areas={[
            { name: 'user', start: [0, 1], end: [0, 1] },
            { name: 'synced', start: [1, 1], end: [1, 1] }
          ]}
          align="center"
        >
          <Box gridArea="user">
            <Typography
              type="text"
              testId="name-table-header"
              size="large"
              weight="bold"
            >
              {datum.username}
            </Typography>
          </Box>
          <Box gridArea="synced">
            {!datum.synced && (
              <Notification
                type="inline"
                backgroundColor="status-warning"
                testId="warning-inline-notification"
                text={t(`pending_${datum.deleted ? 'deletion' : 'addition'}`)}
                pad={{ vertical: 'xsmall' }}
              />
            )}
          </Box>
        </Grid>
      )
    },
    {
      property: 'created',
      type: 'date',
      header: (
        <Typography
          type="text"
          testId="creation-date-table-header"
          size="large"
          weight="bold"
        >
          {t('creation_date_table_header')}
        </Typography>
      ),
      render: (datum) => {
        return (
          <Box direction="row" align="center">
            <Typography
              type="text"
              testId="description-table-text"
              size="medium"
              margin={{ left: 'small' }}
            >
              {dayjs(datum.created).format('MMMM Do, YYYY')}
            </Typography>
          </Box>
        )
      }
    },
    {
      size: 'auto',
      render: (datum) => {
        if (!datum.synced) return null
        return (
          <Box>
            <ActionButton
              actions={[
                {
                  label: t('delete_credential_title'),
                  onClick: () => {
                    setCredentialToDelete(datum.username)
                  }
                }
              ]}
              showOneActionAsDropDown
              dropAlign={{ top: 'bottom', left: 'left' }}
              testId="drop-action-btn"
            />
          </Box>
        )
      }
    }
  ]

  return (
    <>
      {(addedCredSuccess || addCredError) && (
        <Notification
          backgroundColor={addedCredSuccess ? 'status-ok' : 'status-critical'}
          text={
            <span
              data-testid={`add-${
                addedCredSuccess ? 'success' : 'error'
              }-notification`}
            >
              {addedCredSuccess ? (
                <Trans i18nKey="api_messages.add_credential_success" t={t}>
                  {addedCred.username}
                </Trans>
              ) : (
                t(`api_messages.${addCredError}`)
              )}
            </span>
          }
          onClose={() => {
            setAddCredError(false)
            setAddedCredSuccess(false)
          }}
        />
      )}
      {(deletedCredSuccess || deleteCredError) && (
        <Notification
          backgroundColor={deletedCredSuccess ? 'status-ok' : 'status-critical'}
          testId={`delete-${
            deletedCredSuccess ? 'success' : 'error'
          }-notification`}
          text={
            <span
              data-testid={`delete-${
                deletedCredSuccess ? 'success' : 'error'
              }-notification`}
            >
              {deletedCredSuccess
                ? t('api_messages.delete_credential_success')
                : t(`api_messages.delete_credential_error`)}
            </span>
          }
          onClose={() => {
            setDeleteCredError(false)
            setDeletedCredSuccess(false)
          }}
        />
      )}
      <Page kind="narrow">
        <PageContent
          className="app-details-card"
          data-testid="app-details-card"
        >
          <PageHeader
            title={t(`${convertAppIdToType()}.name`)}
            parent={
              <Anchor
                label={`${t('common:local_gateway.tile_title')} ${t(
                  `${convertAppIdToType()}.name`
                )}`}
                icon={<Previous />}
                onClick={handleBackBtnClick}
                data-testid="back-btn"
              />
            }
            data-testid="tile-page-header"
          />
          <PageHeader
            pad={{ vertical: 'none' }}
            title={
              <Heading level={2} margin="none">
                {t('details')}
              </Heading>
            }
          />
          <Ruler margin={{ vertical: 'small' }} />
          <LoadingContainer
            loading={loadingAppData}
            loadingError={loadingAppDataError}
            testId="app-details-section"
          >
            <DetailsSection app={appData} />
          </LoadingContainer>
          <PageHeader
            responsive
            pad={{ vertical: 'none' }}
            title={
              <Heading level={2} margin="none">
                {t('user_credentials')}
              </Heading>
            }
            subtitle={t('pending_explanation')}
            actions={
              !!userCredentialsData &&
              userCredentialsData.length > 0 && (
                <ButtonGroup
                  buttonList={[
                    {
                      label: t('add_credentials'),
                      secondary: true,
                      testId: 'add-credentials-btn',
                      onClick: () => {
                        setShowAddModal(true)
                      },
                      style: { flexShrink: 0 }
                    }
                  ]}
                />
              )
            }
          />
          <Ruler />
          <LoadingContainer
            loading={loadingCredsData}
            loadingError={loadingCredsDataError}
            testId="credential-section"
          >
            <>
              {userCredentialsData && userCredentialsData.length === 0 ? (
                <Box align="center" justify="center" pad="medium">
                  <NoDataInfo
                    icon={<Install size="large" />}
                    subtitle={t('no_creds_create_message')}
                    action={
                      <Button
                        margin={{ top: 'medium' }}
                        label={t('add_credentials')}
                        primary
                        testId="no-creds-create-btn"
                        onClick={() => setShowAddModal(true)}
                      />
                    }
                    testId="no-creds-container"
                  />
                </Box>
              ) : (
                <DataTable
                  summary={{
                    entityName:
                      userCredentialsData &&
                      (filteredUserCredentialsData || userCredentialsData)
                        .length === 1
                        ? t('username').toLowerCase()
                        : t('usernames')
                  }}
                  pagination={{
                    totalItems: userCredentialsData
                      ? (filteredUserCredentialsData || userCredentialsData)
                          .length
                      : 0,
                    itemsPerPage,
                    page,
                    setPage
                  }}
                  search={{
                    width: 'large',
                    onSearchValueChange: (val) => {
                      setPage(1)
                      debouncedSetSearchTerm(val)
                    },
                    placeholder: t('gateway_search_box_placeholder')
                  }}
                  grid={{
                    columns,
                    data: (
                      filteredUserCredentialsData || userCredentialsData
                    ).slice(
                      (page - 1) * itemsPerPage,
                      (page - 1) * itemsPerPage + itemsPerPage
                    )
                  }}
                  testId="credentials-table"
                />
              )}
            </>
          </LoadingContainer>
        </PageContent>
        {credentialToDelete && (
          <DeleteCredentialModal
            setShowModal={setCredentialToDelete}
            deleteCredential={deleteCredential}
            lastCredential={
              !userCredentialsData || userCredentialsData.length === 1
            }
          />
        )}
        {showAddModal && (
          <AddCredentialModal
            setShowModal={setShowAddModal}
            addCredential={addCredential}
            appType={convertAppIdToType()}
            credentialList={userCredentialsData}
          />
        )}
      </Page>
    </>
  )
}

const AppDetails = () => {
  return (
    <Layout>
      <AppDetailsContent />
    </Layout>
  )
}

export default AppDetails
