// Copyright 2024 Hewlett Packard Enterprise Development LP

import { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, ResponsiveContext, Box } from 'grommet'

import { Widget } from '../../../../components'
import { useUPSContext } from '../../../../context/ups-context'
import { HomeWidgetsData } from '../widgets-data'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

const grid = {
  columns: {
    small: {
      count: 3,
      size: 'medium'
    },
    medium: {
      count: 3,
      size: 'medium'
    },
    large: {
      count: 3,
      size: 'medium'
    },
    xlarge: {
      count: 3,
      size: 'medium'
    }
  },
  rows: ['auto'],
  gap: {
    small: 'medium',
    medium: 'medium',
    large: 'medium',
    xlarge: 'medium'
  }
}

const TemplateWidgets = ({ handleSelectedWidgets }) => {
  const size = useContext(ResponsiveContext)
  const { homewidgets: homeWidgetsFromGet } = useUPSContext()

  const [selectedWidgetKeys, setSelectedWidgetKeys] = useState([])

  const widgetsData = HomeWidgetsData()
  const filteredWidgetsData = widgetsData.filter((widget) => !widget.hidden)

  const templateWidgets = filteredWidgetsData.map((widget) => {
    const responseWidget = homeWidgetsFromGet?.find(
      (item) => item.widgetKey === widget.widgetKey
    )
    return responseWidget
      ? { ...widget, visible: responseWidget.visible }
      : widget
  })

  const handleSelectClick = (widgetKey) => {
    if (selectedWidgetKeys.includes(widgetKey)) {
      setSelectedWidgetKeys((prevWidgetKeys) =>
        prevWidgetKeys.filter((key) => key !== widgetKey)
      )
    } else {
      setSelectedWidgetKeys((prevWidgetKeys) => [...prevWidgetKeys, widgetKey])
    }
  }

  useEffect(() => {
    handleSelectedWidgets(selectedWidgetKeys)
  }, [selectedWidgetKeys, handleSelectedWidgets])

  useEffect(() => {
    const preSelectedWidgets = homeWidgetsFromGet
      ?.filter((widget) => widget.visible)
      .map((widget) => widget.widgetKey)
    setSelectedWidgetKeys(preSelectedWidgets)
  }, [homeWidgetsFromGet])

  const availableWidgets = templateWidgets.map((widget) => (
    <VisibilityWrapper
      hideFor={widget.hideFor}
      rbac={widget.rbac}
      key={`visibility-wrapper-${widget.widgetKey}`}
    >
      <Box width="medium" height="600px">
        <Widget
          chartType={widget.chartType}
          data={widget.widgetData.listData}
          footerMessage={widget.footerMessage}
          onSelectClick={() => handleSelectClick(widget.widgetKey)}
          subTitle={widget.subtitle}
          title={widget.title}
          key={widget.widgetKey}
          testId={widget.testId}
          isWidgetSelected={widget.visible}
        />
      </Box>
    </VisibilityWrapper>
  ))

  return (
    <Grid
      rows={['auto']}
      width={{ min: 'medium' }}
      columns={grid.columns[size]}
      gap={grid.gap[size]}
      data-testid="template-widegts"
    >
      {availableWidgets}
    </Grid>
  )
}

TemplateWidgets.propTypes = {
  handleSelectedWidgets: PropTypes.func.isRequired
}

export default TemplateWidgets
