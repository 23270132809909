import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useState } from 'react'

import {
  Button,
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../components'
import { get, del, getErrorMessage } from '../../../../utils/api-utils'
import Markdown from '../../../commoncomponents/Markdown'
import { lifeCycleStateEnum } from '../constants'

const RemoveDomainModal = ({
  onSetOpen,
  domainToRemove,
  refreshParent,
  setNotificationInfo
}) => {
  const { t } = useTranslation(['iam', 'common'])
  const [loading, setLoading] = useState(false)
  let content
  let footer

  const closeModal = () => {
    onSetOpen(false)
  }

  const removeDomain = () => {
    setLoading(true)
    // remove domain dialog for non-verified state
    if (
      domainToRemove?.lifeCycleState !== lifeCycleStateEnum?.VERIFIED &&
      domainToRemove?.lifeCycleState !== lifeCycleStateEnum?.WEAKLY_CLAIMED
    ) {
      del(`/identity/v1alpha1/domain-requests/${domainToRemove?.id}`).then(
        () => {
          closeModal()
          setLoading(false)
          refreshParent()
          setNotificationInfo(
            <Markdown>
              {t('iam:domains.domain_delete_success_msg', {
                domainName: domainToRemove?.name
              })}
            </Markdown>,
            'info'
          )
        },
        (error) => {
          closeModal()
          setLoading(false)
          setNotificationInfo(
            getErrorMessage(error, t),
            'error',
            t('iam:domains.domain_delete_notification_failed')
          )
        }
      )
    } else {
      // remove domain dialog for verified state with no sso profile associated
      get(`/identity/v1alpha1/domains?name=${domainToRemove?.name}`).then(
        (response) => {
          if (response?.data?.items?.length === 1) {
            del(
              `/identity/v1alpha1/domains/${response?.data?.items[0]?.id}`
            ).then(
              () => {
                closeModal()
                setLoading(false)
                refreshParent()
                setNotificationInfo(
                  <Markdown>
                    {t('iam:domains.domain_delete_success_msg', {
                      domainName: domainToRemove?.name
                    })}
                  </Markdown>,
                  'info'
                )
              },
              (error) => {
                closeModal()
                setLoading(false)
                setNotificationInfo(
                  getErrorMessage(error, t),
                  'error',
                  t('iam:domains.domain_delete_notification_failed')
                )
              }
            )
          }
        },
        (error) => {
          closeModal()
          setLoading(false)
          setNotificationInfo(
            getErrorMessage(error, t),
            'error',
            t('iam:domains.domain_delete_notification_failed')
          )
        }
      )
    }
  }

  const domainFooter = (
    <ModalFooter
      right={
        <Box direction="row" gap="medium">
          <Button
            label={t('common:cancel')}
            onClick={closeModal}
            testId="remove-domain-cancel-btn"
          />
          <Button
            primary
            label={t('common:remove')}
            onClick={removeDomain}
            testId="remove-domain-confirm-btn"
          />
        </Box>
      }
    />
  )

  if (loading) {
    content = (
      <Box
        fill
        align="center"
        justify="center"
        pad={{ vertical: 'small', horizontal: 'large' }}
      >
        <Loader label={t('common:removing')} testId="remove-domain-loader" />
      </Box>
    )
  } else if (domainToRemove?.lifeCycleState !== 'VERIFIED') {
    // if domain is not in a verified state
    content = (
      <Box width="medium" margin={{ top: 'medium' }}>
        <Markdown>
          {t('iam:domains.remove_unverfied_domain_msg', {
            domainName: domainToRemove?.name
          })}
        </Markdown>
      </Box>
    )
    footer = domainFooter
  } else if (
    domainToRemove?.lifeCycleState === 'VERIFIED' &&
    !domainToRemove?.ssoProfile?.length
  ) {
    // if there is no sso profile associated for a domain with verified state
    content = (
      <Box width="medium" margin={{ top: 'medium' }}>
        <Markdown>
          {t('iam:domains.remove_verified_domain_msg', {
            domainName: domainToRemove?.name
          })}
        </Markdown>
      </Box>
    )
    footer = domainFooter
  } else {
    // TODO: temporarily add sso info in the domainRequest to mock domain objet with SSO info
    // if there is an sso profile associated for a domain with verified state
    content = (
      <Box width="medium" margin={{ top: 'medium' }}>
        <Markdown>
          {t('iam:domains.remove_verified_sso_domain_msg', {
            domainName: domainToRemove?.name,
            ssoProfile: domainToRemove?.ssoProfile
          })}
        </Markdown>
      </Box>
    )
    footer = (
      <ModalFooter
        right={
          <Box direction="row" gap="medium">
            <Button
              secondary
              label={t('iam:domains.view_sso_profile_btn')}
              onClick={() => {
                // TODO: Add link to SSP Profile button
              }}
              testId="view-sso-profile-btn"
            />
            <Button
              primary
              label={t('common:close')}
              onClick={closeModal}
              testId="remove-domain-cancel-btn"
            />
          </Box>
        }
      />
    )
  }

  return (
    <ModalDialog
      header={
        loading ? null : (
          <ModalHeader
            title={
              <Typography type="heading" level="2">
                {t('iam:domains.remove_domain')}
              </Typography>
            }
          />
        )
      }
      content={content}
      footer={footer}
      onClose={closeModal}
      onClickOutside={closeModal}
      testId="remove-domain-modal"
    />
  )
}

RemoveDomainModal.propTypes = {
  /**
   * Callback function to close modal
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * Domain to remove
   */
  domainToRemove: PropTypes.object.isRequired,

  /**
   * refresh page
   */
  refreshParent: PropTypes.func,

  /**
   * set notification info
   */
  setNotificationInfo: PropTypes.func
}

RemoveDomainModal.defaultProps = {
  refreshParent: () => {},
  setNotificationInfo: () => {}
}

export default RemoveDomainModal
