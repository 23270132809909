import React, { useContext } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import { CCSForm, FormInput } from '../../../../../../components'
import { WizardContext } from '../../../../../../components/wizard/WizardContext'

export const validateOnpremInfo = (formValues) => {
  if (!formValues.workspaceId || !formValues.workspaceName) {
    return Promise.reject(new Error())
  }
  return Promise.resolve({ message: 'success' })
}

const OnpremInfoStep = () => {
  const { formValues, setFormValues } = useContext(WizardContext)
  const { t } = useTranslation(['licensing', 'common'])
  return (
    <Box
      width="medium"
      margin={{ top: 'medium', left: 'xsmall', bottom: 'large' }}
    >
      <CCSForm errorMessage="" validate="blur" testId="workspace-form">
        <FormInput
          id="workspace_id"
          value={formValues.workspaceId}
          testId="workspace-id-input-field"
          color="text-strong"
          label={`${t('onprem_workspace_id')}*`}
          name="workspace_id"
          labelHelper={t('onprem_workspace_id_desc')}
          validate={(value) => {
            if (!value) return t('licensing:workspace_id_required')
            return true
          }}
          width="100%"
          onChange={(e) => {
            setFormValues({
              ...formValues,
              workspaceId: e?.target?.value
            })
          }}
          margin={{ bottom: 'small' }}
        />
        <FormInput
          id="workspace_name"
          value={formValues.workspaceName}
          testId="workspace-name-input-field"
          color="text-strong"
          label={`${t('onprem_workspace_name')}*`}
          name="workspace_name"
          labelHelper={t('onprem_workspace_name_desc')}
          validate={(value) => {
            if (!value) return t('licensing:workspace_name_required')
            return true
          }}
          width="100%"
          onChange={(e) => {
            setFormValues({
              ...formValues,
              workspaceName: e?.target?.value,
              isOnpremWorkspace: true
            })
          }}
        />
      </CCSForm>
    </Box>
  )
}

export default OnpremInfoStep
