import React from 'react'
import { useTranslation } from 'react-i18next'
import { Anchor, Page, PageContent, PageHeader } from 'grommet'
import { Previous, Technology } from 'grommet-icons'
import { useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Button, NoDataInfo } from '../../../components'
import { Layout } from '../../commoncomponents/layout/Layout'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'

const WelcomePage = () => {
  const { t } = useTranslation(['local-gateway', 'common'])
  const history = useHistory()
  const handleBackBtnClick = () => {
    history.push('/manage-account')
  }
  const handleCreateBtnClick = () => {
    history.push('create')
  }
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  return (
    <Layout>
      <Page>
        <PageContent>
          <PageHeader
            responsive
            title={t('welcome_title')}
            subtitle={t('welcome_subheading')}
            parent={
              <Anchor
                label={t('common:manage_account_label', {
                  account: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
                icon={<Previous />}
                onClick={handleBackBtnClick}
                testId="manage-account-btn"
              />
            }
            data-testid="welcome-page-header"
          />
          <NoDataInfo
            subtitle={t('welcome_button_introduction')}
            icon={<Technology size="large" />}
            action={
              <Button
                primary
                margin={{ vertical: 'xsmall' }}
                label={t('welcome_button')}
                onClick={handleCreateBtnClick}
                testId="create-gateway-btn"
              />
            }
            testId="welcome-button-container"
          />
        </PageContent>
      </Page>
    </Layout>
  )
}
export default WelcomePage
