import React from 'react'
import { Button } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'

const ContextHelp = () => {
  const LDFlags = useFlags()
  return LDFlags['glcp-contextual-help'] ? (
    <Button onClick={() => dispatchEvent(new Event('context-help'))} />
  ) : null
}

export { ContextHelp }
