import React from 'react'
import { useLocation } from 'react-router-dom'

import LocationDetailsPage from '../../../commoncomponents/location-management/location-details/LocationDetailsPage'

const CCSLocationDetails = () => {
  const location = useLocation()
  const { customerId } = location.state

  return (
    <>
      <LocationDetailsPage customerId={customerId} isCCSManager />
    </>
  )
}

export default CCSLocationDetails
