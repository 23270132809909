import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'

import { Search } from '../../components'

const ServiceSearch = ({ debouncedSearchData, placeholder, testId }) => {
  const [searchValue, setSearchValue] = useState('')

  const handleSearchInput = (value) => {
    // Limit the search term to only 75 characters.  This will prevent the UI from crashing on large unneccessary inputs
    const searchInput = value.length > 75 ? value.substring(0, 75) : value
    setSearchValue(searchInput)
    debouncedSearchData(searchInput)
  }
  return (
    <Box direction="column" width={{ max: 'medium' }} gap="small">
      <Search
        width="large"
        value={searchValue}
        handleCustomSearch={handleSearchInput}
        placeholder={placeholder}
        testId={testId}
      />
    </Box>
  )
}

ServiceSearch.propTypes = {
  /**
   * Functionality to pass the search value
   */
  debouncedSearchData: PropTypes.func.isRequired,

  /**
   * Placeholder for the search
   */
  placeholder: PropTypes.string.isRequired,

  /**
   * Test-id for the search
   */
  testId: PropTypes.string.isRequired
}

export default ServiceSearch
