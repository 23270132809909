import React from 'react'
import PropTypes from 'prop-types'

import useAuditLogsReducer from './reducer'

const AuditLogsContext = React.createContext()

export const AuditLogsContextProvider = ({ children }) => {
  const stateAndDispatch = useAuditLogsReducer()
  return (
    <AuditLogsContext.Provider value={stateAndDispatch}>
      {children}
    </AuditLogsContext.Provider>
  )
}

AuditLogsContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const useAuditLogsContext = () => {
  const { AuditLogsState, dispatchAuditLogsContext } =
    React.useContext(AuditLogsContext)
  return { ...AuditLogsState, dispatchAuditLogsContext }
}
