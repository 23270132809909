import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  AvatarInfo,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../../components'
import { EditAccountDetails } from '../customer-actions/EditAccountDetails'

const EditTenantModal = ({ tenantData, setShowModal, refreshTenantsList }) => {
  const { t } = useTranslation(['manage'])
  const [customerDataResponse, setCustomerDataResponse] = useState(tenantData)
  const [dataChanged, setDataChanged] = useState(false)
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Box pad="small" direction="row" gap="medium">
              <AvatarInfo
                avatarOnly
                avatarSize="large"
                avatarChar="one"
                primaryInfo={customerDataResponse.name}
                secondaryInfo={t('edit_customer_subtitle')}
                testId="account-info"
                {...(customerDataResponse?.logo && {
                  avatarSrc: customerDataResponse?.logo
                })}
              />
              <Box>
                <Typography
                  level="1"
                  type="heading"
                  testId="edit-tenant-modal-title"
                >
                  {`${t('account_details')} - ${customerDataResponse.name}`}
                </Typography>
                <Typography
                  type="text"
                  size="large"
                  testId="edit-tenant-subtitle"
                >
                  {t('edit_tenant_subtitle')}
                </Typography>
              </Box>
            </Box>
          }
        />
      }
      content={
        <Box margin={{ left: 'medium', top: 'medium' }}>
          <EditAccountDetails
            customerDataResponse={customerDataResponse}
            setCustomerDataResponse={setCustomerDataResponse}
            setShowModal={setShowModal}
            fetchCustomerDetails={refreshTenantsList}
            dataChanged={dataChanged}
            setDataChanged={setDataChanged}
            isTenantAccount
          />
        </Box>
      }
      onClose={() => setShowModal(false)}
      testId="edit-tenant-modal-dialog"
      width="large"
    />
  )
}

EditTenantModal.propTypes = {
  tenantData: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired,
  refreshTenantsList: PropTypes.func
}

EditTenantModal.defaultProps = {
  refreshTenantsList: () => {}
}

export { EditTenantModal }
