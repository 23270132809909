import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Button,
  ModalDialog,
  ModalHeader,
  Notification,
  Tabs,
  Typography
} from '../../../../components'
import {
  AUTHZActions,
  useAUTHZContext
} from '../../../../context/authz-context'
import VisibilityWrapper from '../../visibility-wrapper/VisibilityWrapper'
import AssignedUsers from '../AssignedUsers'
import RoleDetails from '../../../manage-account/identity/roles/role-details-modal/RoleDetails'

const RoleDetailsModal = ({ onClose, inCCSManager }) => {
  const { t } = useTranslation(['authz'])
  const history = useHistory()
  const LDFlags = useFlags()
  const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']

  const { selectedRole, roleAppMap, dispatchAUTHZContext } = useAUTHZContext()
  const onEditClick = () => {
    dispatchAUTHZContext({
      type: AUTHZActions.SET_SELECTED_ROLE,
      data: {
        ...selectedRole,
        application: selectedRole.application_id,
        resourcePolicies: selectedRole.resource_policies
      }
    })
    onClose(true)
    history.push({
      pathname: `/${
        inCCSManager && saCustomRoleLDFlag ? 'manage-ccs' : 'manage-account'
      }/identity/roles/roleviewedit/${selectedRole.application_id}/${
        selectedRole.slug
      }`
    })
  }

  const onDuplicateClick = () => {
    dispatchAUTHZContext({
      type: AUTHZActions.SET_CREATE_ROLE_DATA,
      data: {
        name: selectedRole.name,
        createOption: 'duplicate_existing',
        application: selectedRole.application_id
      }
    })
    dispatchAUTHZContext({
      type: AUTHZActions.SET_SELECTED_ROLE,
      data: {
        ...selectedRole,
        application: selectedRole.application_id,
        resourcePolicies: selectedRole.resource_policies
      }
    })
    const permissionsSelected = []
    const res = selectedRole.resource_policies
    for (let j = 0; j < res.length; j += 1) {
      for (let i = 0; i < res[j].permissions.length; i += 1) {
        permissionsSelected.push(
          `${res[j].resource.matcher}~${res[j].permissions[i].slug}`
        )
      }
    }
    dispatchAUTHZContext({
      type: AUTHZActions.PERMISSIONS_SELECTED,
      data: permissionsSelected
    })
    history.push(
      `/${
        inCCSManager && saCustomRoleLDFlag ? 'manage-ccs' : 'manage-account'
      }/identity/roles/create/${selectedRole.application_id}/${
        selectedRole.slug
      }`
    )
    roleAppMap.set(selectedRole.application_id, {
      ...roleAppMap.get(selectedRole.application_id),
      duplicateFlag: true
    })
  }
  return (
    <ModalDialog
      onClickOutside={onClose}
      header={
        <ModalHeader
          title={
            <Typography
              style={{ wordBreak: 'break-word' }}
              level="1"
              testId="header-title"
              type="heading"
              weight="bold"
            >
              {selectedRole.name}
            </Typography>
          }
          onClose={onClose}
        />
      }
      content={
        <Box margin={{ vertical: 'medium' }} direction="column">
          <Tabs
            justify="start"
            alignControls="start"
            testId="role-details-tabs"
            tabsList={[
              {
                content: (
                  <Box>
                    <RoleDetails selectedRole={selectedRole} />
                  </Box>
                ),
                id: 1,
                label: t('details'),
                testId: 'role-details-tab'
              },
              {
                content: (
                  <Box>
                    <AssignedUsers
                      role={selectedRole}
                      inCCSManager={inCCSManager}
                    />
                  </Box>
                ),
                id: 2,
                label: t('roles.assigned_users'),
                testId: 'assigned-users-tab'
              }
            ]}
          />
        </Box>
      }
      footer={
        <>
          {selectedRole.predefined && (
            <Notification
              type="inline"
              backgroundColor="status-unknown"
              text={t('roles.modal_notification')}
              testId="warning-inline-notification"
            />
          )}

          {!selectedRole.predefined && (
            <VisibilityWrapper
              hideFor={{
                account: ['TENANT']
              }}
              rbac={{
                resource: '/ccs/authorization',
                permission: 'ccs.authorization.edit'
              }}
            >
              <Box
                justify="start"
                direction="row"
                margin={{ top: 'medium' }}
                height="xxsmall"
                gap="small"
              >
                <>
                  {!selectedRole?.tags?.readonly && (
                    <Button
                      primary
                      testId="duplicate-button"
                      onClick={onDuplicateClick}
                      label={t('roles.duplicate')}
                    />
                  )}
                  <Button
                    primary
                    testId="edit-button"
                    margin={{ left: 'small' }}
                    onClick={onEditClick}
                    label={t('roles.edit_role')}
                  />
                </>
              </Box>
            </VisibilityWrapper>
          )}
        </>
      }
      height="100%"
      width="medium"
      position="right"
      onClose={onClose}
      testId="role-details-dialog"
    />
  )
}

RoleDetailsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  inCCSManager: PropTypes.bool
}

RoleDetailsModal.defaultProps = {
  inCCSManager: false
}

export default RoleDetailsModal
