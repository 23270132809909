import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, NameValuePair, NameValueList, Notification, Tag } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { WizardContext } from '../../../../../components/wizard/WizardContext'
import { Typography } from '../../../../../components'
import { Contacts } from '../../location-details/components/Contacts'

export const ReviewLocationDetails = ({ isCCSManager }) => {
  const { t } = useTranslation(['location'])
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()

  const { formValues } = useContext(WizardContext)

  const primaryContacts = []
  const securityContacts = []
  const operationContacts = []
  const shippingContacts = []

  formValues.contacts.forEach((contact) => {
    const contactType = contact.type

    switch (contactType) {
      case 'primary':
        primaryContacts.push(contact)
        break
      case 'shipping_receiving':
        shippingContacts.push(contact)
        break
      case 'security':
        securityContacts.push(contact)
        break
      case 'operations':
        operationContacts.push(contact)
        break
      default:
        break
    }
  })

  const streetAddress = formValues.addresses.find((add) => {
    return add.type === 'street'
  })

  const shippingAdd = formValues.addresses.find((add) => {
    return add.type === 'shipping_receiving'
  })

  return (
    <>
      <Box pad={{ top: 'xsmall', bottom: 'xsmall' }}>
        <Typography type="heading" level={2}>
          {t('details')}
        </Typography>
      </Box>
      <hr />
      <NameValueList>
        <NameValuePair name={t('location_name')}>
          <Typography testId="location-name" type="text">
            {formValues.name}
          </Typography>
        </NameValuePair>
        <NameValuePair name={t('description')}>
          <Typography testId="location-description" type="text">
            {formValues.description || '--'}
          </Typography>
        </NameValuePair>
      </NameValueList>
      {/* address */}
      {streetAddress && (
        <>
          <Box pad={{ top: 'large', bottom: 'xsmall' }}>
            <Typography type="heading" level={2}>
              {t('addresses')}
            </Typography>
          </Box>
          <hr />
        </>
      )}
      <NameValueList>
        {/* street Address */}
        {streetAddress && (
          <NameValuePair name={t('street_address')}>
            <Typography testId={`${streetAddress.type}-street`} type="text">
              {streetAddress.street_address}
            </Typography>
            <Typography testId={`${streetAddress.type}-street-2`} type="text">
              {streetAddress.street_address2}
            </Typography>
            <Box direction="row" gap="xsmall">
              <Typography testId={`${streetAddress.type}-city`} type="text">
                {streetAddress.city}
              </Typography>
              <Typography testId={`${streetAddress.type}-state`} type="text">
                {streetAddress.state}
              </Typography>
              <Typography
                testId={`${streetAddress.type}-postal-code`}
                type="text"
              >
                {streetAddress.postal_code}
              </Typography>
            </Box>
            <Typography testId={`${streetAddress.type}-country`} type="text">
              {streetAddress.country}
            </Typography>
          </NameValuePair>
        )}
        {/* shipping address */}
        {shippingAdd && (
          <NameValuePair name={t('shipping_and_receiving')}>
            <Typography testId={`${shippingAdd.type}-street`} type="text">
              {shippingAdd.street_address}
            </Typography>
            <Typography testId={`${shippingAdd.type}-street-2`} type="text">
              {shippingAdd.street_address2}
            </Typography>
            <Box direction="row" gap="xsmall">
              <Typography testId={`${shippingAdd.type}-city`} type="text">
                {shippingAdd.city}
              </Typography>
              <Typography testId={`${shippingAdd.type}-state`} type="text">
                {shippingAdd.state}
              </Typography>
              <Typography
                testId={`${shippingAdd.type}-postal-code`}
                type="text"
              >
                {shippingAdd.postal_code}
              </Typography>
            </Box>
            <Typography testId={`${shippingAdd.type}-country`} type="text">
              {shippingAdd.country}
            </Typography>
          </NameValuePair>
        )}
      </NameValueList>
      {/* contacts */}
      <Box pad={{ top: 'large', bottom: 'xsmall' }}>
        <Typography type="heading" level={2}>
          {t('contacts')}
        </Typography>
      </Box>
      <hr />
      <NameValueList>
        <Contacts contacts={primaryContacts} type={t('location:primary')} />
        {shippingContacts.length > 0 && (
          <Contacts
            contacts={shippingContacts}
            type={t('location:shipping_and_receiving')}
          />
        )}
        {securityContacts.length > 0 && (
          <Contacts contacts={securityContacts} type={t('location:security')} />
        )}
        {operationContacts.length > 0 && (
          <Contacts
            contacts={operationContacts}
            type={t('location:operations')}
          />
        )}
      </NameValueList>

      {/* Review Tags */}
      {!isCCSManager && LDFlags['glcp-locations-tags'] && (
        <>
          <Box pad={{ top: 'large', bottom: 'xsmall' }}>
            <Typography type="heading" level={2}>
              {t('location:tags_assigned')}
            </Typography>
          </Box>
          <hr />
          <Box gap="small" direction="row-responsive" wrap>
            {formValues.tags && formValues.tags.length !== 0
              ? formValues.tags.map((item, idx) => {
                  return (
                    <Box
                      margin={{ top: 'small' }}
                      key={`tag-${item.name}-${item.value}`}
                    >
                      <Tag
                        name={item.name}
                        alignSelf="start"
                        value={item.value}
                        data-testid={`tag-${idx}`}
                      />
                    </Box>
                  )
                })
              : t('device:tags.no_tags_assigned')}
          </Box>
        </>
      )}

      {/* Validate Location */}
      {!isCCSManager && (
        <>
          <Box pad={{ top: 'large', bottom: 'xsmall' }}>
            <Typography type="heading" level={2}>
              {t('Validate Location')}
            </Typography>
          </Box>
          <hr />
          <NameValueList>
            <NameValuePair name={t('validation_cycle_label')}>
              <Typography testId="location-validation-cycle" type="text">
                {`${formValues.validation_cycle} ${t('Months')}`}
              </Typography>
            </NameValuePair>
            <NameValuePair name={t('validated_by')}>
              <Typography testId="location-validated-by" type="text">
                {`${oidcUser.profile.given_name} ${oidcUser.profile.family_name}`}
              </Typography>
            </NameValuePair>
          </NameValueList>
          <hr />
        </>
      )}
      <Notification
        status="info"
        message={t('assign_device')}
        margin={{ vertical: 'medium' }}
      />
    </>
  )
}

ReviewLocationDetails.propTypes = {
  isCCSManager: PropTypes.bool
}

ReviewLocationDetails.defaultProps = {
  isCCSManager: false
}
