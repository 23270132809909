import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Pagination, Box } from 'grommet'

const SearchPagination = ({ controller, onMaxPagesChange }) => {
  const [currentPage, setCurrentPage] = useState(controller.state.currentPage)
  const [maxPage, setMaxPage] = useState(controller.state.maxPage)

  // Subscribe to updates from the controller
  useEffect(() => {
    const handleUpdate = () => {
      setCurrentPage(controller.state.currentPage)
      setMaxPage(controller.state.maxPage)
      if (onMaxPagesChange) {
        onMaxPagesChange(controller.state.maxPage)
      }
    }
    const unsubscribe = controller.subscribe(handleUpdate)
    return () => unsubscribe()
  }, [controller, onMaxPagesChange])

  const setPage = (pageNumber) => {
    controller.selectPage(pageNumber)
  }

  if (maxPage <= 1) {
    return null
  }

  const numberItems = Math.min(maxPage * 10, 1000)

  return (
    <Box pad="small" gap="medium">
      <Pagination
        numberItems={numberItems}
        page={currentPage}
        onChange={({ page: nextPage }) => {
          const updatedPage = !nextPage ? 1 : nextPage
          setPage(updatedPage)
        }}
        show={5}
      />
    </Box>
  )
}

SearchPagination.propTypes = {
  controller: PropTypes.shape({
    state: PropTypes.object.isRequired,
    subscribe: PropTypes.func.isRequired,
    selectPage: PropTypes.func.isRequired
  }).isRequired,
  onMaxPagesChange: PropTypes.func.isRequired
}

export default SearchPagination
