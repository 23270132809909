// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react'
import { Box, Heading, PageHeader } from 'grommet'
import {
  CircleInformation,
  Previous,
  StatusCriticalSmall,
  StatusGoodSmall,
  StatusInfoSmall,
  StatusWarningSmall
} from 'grommet-icons'
import { useHistory, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { Trans, useTranslation } from 'react-i18next'

import {
  ActionButton,
  Anchor,
  Button,
  DataTable,
  NoDataInfo,
  Typography
} from '../../../components'
import { Layout } from '../../commoncomponents/layout/Layout'
import { get, getErrorMessage } from '../../../utils/api-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import {
  isV2,
  isAssociateWorkspace,
  getOrganizationId
} from '../../../utils/feature-flag-utils'
import Markdown from '../../commoncomponents/Markdown'
import { useVisibilityContext } from '../../../context/visibility-context'
import NoViewPermission from '../commoncomponents/NoViewPermission'
import { showV2ActionDropdown } from '../../manage-account/utils'

import { lifeCycleStateEnum, getDomainStatusText } from './constants'
import RemoveDomainModal from './components/RemoveDomainModal'
import VerifyDomainConfirmation from './components/VerifyDomainConfirmation'
import ViewDomainClaimTXTRecord from './components/ViewDomainClaimTXTRecord'

const Domains = () => {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation(['common', 'iam'])
  const { v2RbacPolicies, v2RbacRootWkspcPolicies } = useVisibilityContext()
  const v2DomainsReadPerm = v2RbacPolicies?.includes(
    'identity.domain-requests.read'
  )
  const v2AssociateDomainsReadPerm = v2RbacRootWkspcPolicies?.includes(
    'identity.domain-requests.read'
  )
  const [loading, setLoading] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)
  const [domainRequestsData, setDomainRequestsData] = useState({ items: [] })
  const [domainRequest, setDomainRequest] = useState(null)

  // Modals
  const [showVerifyDomainDialog, setShowVerifyDomainDialog] = useState(false)
  const [showViewTxtModal, setShowViewTxtModal] = useState(false)
  const [showRemoveDomainModal, setShowRemoveDomainModal] = useState(false)

  // Query parameters
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [page, setPage] = useState(1)
  const [name, setName] = useState('')
  const [sort, setSort] = useState('name asc')

  // Notifications
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')
  const [isAssociated, setIsAssociated] = useState(false)
  const setNotificationInfo = (message, severity, title = '') => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }
  const rootWkspReadPerm = v2RbacRootWkspcPolicies?.includes(
    'identity.domain-requests.read'
  )
  const wkspcReadPerm = v2RbacPolicies?.includes(
    'identity.domain-requests.read'
  )
  const getPageIndexInfo = () => {
    const total = domainRequestsData?.total || 0
    if (total > 0) {
      const startIndex = 1 + (page - 1) * itemsPerPage
      const endIndex = itemsPerPage * page < total ? itemsPerPage * page : total
      return t('iam:domains.data_table_showing_label', {
        startIndex,
        endIndex,
        total
      })
    }
    return ''
  }

  const handleBackBtnClick = () => {
    history.push('/manage-account/organization')
  }

  const tableActions = (datum) => {
    const status = datum?.lifeCycleState
    const actions = [
      {
        label: t('iam:domains.verify_action_btn'),
        onClick: () => {
          setDomainRequest(datum)
          setShowVerifyDomainDialog(true)
        },
        testId: 'verify-domain-request-btn',
        visibility: {
          rbac: {
            permission: 'identity.domain-requests.verify'
          }
        }
      },
      {
        label: t('iam:domains.view_txt_action_btn'),
        onClick: () => {
          setShowViewTxtModal(true)
          setDomainRequest(datum)
        },
        testId: 'view-txt-btn',
        visibility: {
          rbac: {
            permission: 'identity.domain-requests.read'
          }
        }
      },
      {
        label: t('common:remove'),
        onClick: () => {
          setDomainRequest(datum)
          setShowRemoveDomainModal(true)
        },
        testId: 'remove-domain-btn',
        visibility: {
          rbac: {
            permission: 'identity.domain-requests.delete'
          }
        }
      }
    ]

    return status === lifeCycleStateEnum?.PENDING ||
      status === lifeCycleStateEnum?.WEAKLY_CLAIMED
      ? actions
      : [actions[2]]
  }

  const columns = [
    {
      property: 'name',
      header: t('iam:domains.domain_single'),
      primary: true,
      sortable: true,
      render: (datum) => {
        return (
          <Anchor
            href="#"
            label={datum?.name}
            testId="domain-request-name"
            onClick={(event) => {
              event?.preventDefault()
              history?.push(`/manage-account/organization/domains/${datum?.id}`)
            }}
          />
        )
      }
    },
    {
      property: 'lifeCycleState',
      header: t('iam:domains.claim_label'),
      render: (datum) => {
        const status = datum?.lifeCycleState
        const text = getDomainStatusText(status, t)
        return (
          <Box direction="row" gap="xsmall" justify="center">
            <Box justify="center">
              {status === lifeCycleStateEnum?.VERIFIED ? (
                <StatusGoodSmall size="small" color="status-ok" />
              ) : null}
              {status === lifeCycleStateEnum?.PENDING ? (
                <StatusInfoSmall color="status-unknown" />
              ) : null}
              {status === lifeCycleStateEnum?.CLAIMED_ELSEWHERE ? (
                <StatusCriticalSmall color="status-critical" />
              ) : null}
              {status === lifeCycleStateEnum?.WEAKLY_CLAIMED ? (
                <StatusWarningSmall color="status-warning" />
              ) : null}
            </Box>
            <Typography type="text">{text}</Typography>
          </Box>
        )
      }
    },
    {
      property: 'lastVerified',
      header: t('iam:domains.last_verified_label'),
      sortable: true,
      render: (datum) => {
        return (
          <Typography size="medium" type="text">
            {datum?.lifeCycleState === lifeCycleStateEnum?.VERIFIED
              ? dayjs(datum.lastVerified).format('MM/DD/YYYY')
              : null}
            {datum?.lifeCycleState === lifeCycleStateEnum?.PENDING
              ? t('iam:domains.claim_pending_value')
              : null}
            {datum?.lifeCycleState === lifeCycleStateEnum?.CLAIMED_ELSEWHERE ||
            datum?.lifeCycleState === lifeCycleStateEnum?.WEAKLY_CLAIMED
              ? '--'
              : null}
          </Typography>
        )
      }
    },
    {
      property: 'ssoProfile',
      header: t('iam:domains.sso_profile_label'),
      render: (datum) => {
        return (
          <Typography size="medium" type="text">
            {datum?.ssoProfile?.length
              ? datum?.ssoProfile
              : t('common:not_available_shorthand')}
          </Typography>
        )
      }
    },
    {
      property: '',
      header: '',
      render: (datum) => {
        return (
          showV2ActionDropdown(
            isAssociated ? v2RbacRootWkspcPolicies : v2RbacPolicies,
            tableActions(datum)
          ) && (
            <ActionButton
              actions={tableActions(datum)}
              showOneActionAsDropDown
              customRenderer={(actionBtn, visibility) => {
                return (
                  ((isAssociated &&
                    v2RbacRootWkspcPolicies?.includes(
                      visibility?.rbac?.permission
                    )) ||
                    (!isAssociated &&
                      v2RbacPolicies?.includes(
                        visibility?.rbac?.permission
                      ))) && <> {actionBtn} </>
                )
              }}
              testId="domain-request-action-btn"
            />
          )
        )
      }
    }
  ]

  useEffect(() => {
    const orgId = getOrganizationId()
    if (orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (res) => {
          if (res?.data && res?.data?.associatedWorkspace) {
            setIsAssociated(
              isAssociateWorkspace(res?.data?.associatedWorkspace?.id)
            )
          }
        },
        (err) => {
          setNotificationInfo(getErrorMessage(err, t), 'error')
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location?.state?.deleteDomainName) {
      const message = (
        <Markdown>
          {t('iam:domains.domain_delete_success_msg', {
            domainName: location?.state?.deleteDomainName
          })}
        </Markdown>
      )
      setNotificationInfo(message, 'info')
    }
  }, [location, t])

  useEffect(() => {
    if (location?.state?.notificationText) {
      const addDomainNotification = (
        <Markdown>{location?.state?.notificationText}</Markdown>
      )
      setNotificationInfo(addDomainNotification, 'info')
    }
  }, [location?.state?.notificationText, t])

  useEffect(() => {
    let isMounted = true
    setLoading(true)

    const handleSuccess = (response) => {
      if (isMounted && response?.data) {
        setDomainRequestsData(response.data)
      }
      setLoading(false)
    }

    const handleError = (error) => {
      if (isMounted) {
        setNotificationInfo(getErrorMessage(error, t), 'error')
        setDomainRequestsData({ items: [] })
      }
      setLoading(false)
    }

    const buildQueryParams = () => {
      const nameQuery =
        name?.trim()?.length > 0
          ? `&name=${encodeURIComponent(name.trim())}`
          : ''
      const sortQuery = sort ? `&sort=${encodeURIComponent(sort)}` : ''
      return `${nameQuery}${sortQuery}`
    }

    const getDomainRequestData = async () => {
      const queryParams = buildQueryParams()
      const url = `/internal-platform-tenant-ui/v2/domain-requests?offset=${
        (page - 1) * itemsPerPage
      }&limit=${itemsPerPage}${queryParams}`

      try {
        const response = await get(url)
        handleSuccess(response)
      } catch (error) {
        handleError(error)
      }
    }

    if (
      (!isAssociated && v2DomainsReadPerm) ||
      (isAssociated && v2AssociateDomainsReadPerm)
    ) {
      getDomainRequestData()
    }

    return () => {
      isMounted = false
    }
  }, [
    page,
    itemsPerPage,
    name,
    refreshCount,
    sort,
    t,
    v2DomainsReadPerm,
    v2AssociateDomainsReadPerm,
    isAssociated
  ])

  return (
    <Box
      data-testid="domains-page"
      pad={{ horizontal: 'xlarge', bottom: 'large' }}
      width="xxlarge"
      alignSelf="center"
    >
      <PageHeader
        responsive
        title={
          <Heading
            level="1"
            type="heading"
            margin="none"
            data-testid="domains-page-title"
          >
            {t('iam:domains.domain_plural')}
          </Heading>
        }
        subtitle={
          ((isAssociated &&
            v2RbacRootWkspcPolicies?.includes(
              'identity.domain-requests.read'
            )) ||
            (!isAssociated &&
              v2RbacPolicies?.includes('identity.domain-requests.read'))) && (
            <Box width="large">
              <Typography
                normalize
                type="text"
                size="large"
                testId="domains-page-subtitle"
              >
                <Trans i18nKey="iam:domains.page_header_desc" t={t}>
                  <Anchor
                    label={t('common:learn_more')}
                    weight="bold"
                    onClick={() => dispatchEvent(new Event('context-help'))}
                    testId="domain_learn_more_anchor"
                  />
                </Trans>
              </Typography>
            </Box>
          )
        }
        parent={
          <Anchor
            label={t('iam:organization.governance_title')}
            icon={<Previous />}
            href="#"
            onClick={(event) => {
              event.preventDefault()
              handleBackBtnClick()
            }}
            margin={{ bottom: 'xsmall' }}
            testId="domains-back-btn"
          />
        }
      />
      {(isAssociated && rootWkspReadPerm) ||
      (!isAssociated && wkspcReadPerm) ? (
        <>
          {domainRequestsData?.items?.length !== 0 ||
          name?.length > 0 ||
          loading ? (
            <DataTable
              summary={{
                entityName:
                  domainRequestsData?.items?.length === 1
                    ? t('common:item_singular')
                    : t('common:item_plural')
              }}
              grid={{
                columns,
                data: domainRequestsData?.items
              }}
              loading={loading}
              search={{
                onSearchValueChange: (value) => {
                  setPage(1)
                  setName(value)
                },
                placeholder: t('common:search')
              }}
              button={
                isV2() ? (
                  <Box align="end" width="small">
                    {((isAssociated &&
                      v2RbacRootWkspcPolicies?.includes(
                        'identity.domain-requests.create'
                      )) ||
                      (!isAssociated &&
                        v2RbacPolicies?.includes(
                          'identity.domain-requests.create'
                        ))) && (
                      <Button
                        secondary
                        label={t('iam:domains.add_domain')}
                        onClick={() => {
                          history.push(
                            '/manage-account/organization/domains/add-domain'
                          )
                        }}
                        testId="data-table-add-domain-btn"
                      />
                    )}
                  </Box>
                ) : null
              }
              pagination={{
                totalItems: domainRequestsData?.total || 0,
                itemsPerPage,
                setItemsPerPage,
                page,
                setPage,
                rowDropDownLabel: t('iam:domains.row_drop_down_label'),
                pageIdxInfo: getPageIndexInfo(),
                rowCountOptions: [20, 50, 100],
                testId: 'domain-request-pagination'
              }}
              onSort={({ property, direction }) => {
                const propQuery =
                  property === 'lifeCycleState' ? 'status' : property
                setSort(`${propQuery} ${direction}`)
              }}
              testId="domain-request-datatable"
            />
          ) : (
            <Box width="large">
              <NoDataInfo
                icon={<CircleInformation size="large" />}
                title={t('iam:domains.no_domain_requests_title')}
                subtitle={t('iam:domains.no_domain_requests_subtitle')}
                gap="small"
                gapTitleSubtitle="small"
                action={
                  isV2()
                    ? ((isAssociated &&
                        v2RbacRootWkspcPolicies?.includes(
                          'identity.domain-requests.create'
                        )) ||
                        (!isAssociated &&
                          v2RbacPolicies?.includes(
                            'identity.domain-requests.create'
                          ))) && (
                        <Button
                          primary
                          label={t('iam:domains.add_domain')}
                          onClick={() => {
                            history.push(
                              '/manage-account/organization/domains/add-domain'
                            )
                          }}
                          testId="no-data-add-domain-btn"
                        />
                      )
                    : null
                }
                testId="domain-request-no-data-info"
              />
            </Box>
          )}
        </>
      ) : (
        (v2RbacPolicies || v2RbacRootWkspcPolicies) && <NoViewPermission />
      )}
      {showVerifyDomainDialog && (
        <VerifyDomainConfirmation
          domainRequest={domainRequest}
          onSetOpen={setShowVerifyDomainDialog}
          setApiError={setNotificationInfo}
          refreshParent={() => {
            setRefreshCount((prevCount) => prevCount + 1)
          }}
        />
      )}
      {showViewTxtModal && (
        <ViewDomainClaimTXTRecord
          domainClaimTXT={domainRequest?.dnsTxtRecord}
          domainName={domainRequest?.name}
          onSetOpen={setShowViewTxtModal}
        />
      )}
      {showRemoveDomainModal && (
        <RemoveDomainModal
          onSetOpen={setShowRemoveDomainModal}
          domainToRemove={domainRequest}
          refreshParent={() => {
            setRefreshCount((prevCount) => prevCount + 1)
          }}
          setNotificationInfo={setNotificationInfo}
        />
      )}
      {notifMsg &&
        displayNotification(notifMsg, notifSeverity, setNotifMsg, notifTitle)}
    </Box>
  )
}

const DomainsPage = () => {
  return (
    <Layout>
      <Domains align="start" justify="start" />
    </Layout>
  )
}

export default DomainsPage
