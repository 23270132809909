// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, CheckBox, FormField, Heading, RadioButtonGroup } from 'grommet'
import { Close, Trash } from 'grommet-icons'
import { debounce } from 'lodash'

import {
  Typography,
  CCSForm,
  Button,
  FormInput,
  Loader,
  ModalDialog,
  Dropdown,
  DataTable,
  ActionButton,
  Notification
} from '../../../../components'
import { get, post, getErrorMessage } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import {
  getCustomerAccount,
  getOrganizationId
} from '../../../../utils/feature-flag-utils'
import { glcpServiceID } from '../constants'
import Markdown from '../common-components/Markdown'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { MessageStatusModal } from '../../../notifications/components/MessageStatusModal'
import AssignRoleSideDrawer from '../common-components/AssignRoleSideDrawer'
import DeleteRoleAssignmentConfirmation from '../common-components/DeleteRoleAssignmentConfirmation'
import { mapRoleAssignments } from '../utils'
import { LIFECYCLE_STATE } from '../../account-details/constants'

const AddUserModal = ({ onSetOpen, setStatus }) => {
  const { t } = useTranslation(['authz', 'iam', 'common'])
  const [loading, setLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [apiError, setApiError] = useState(null)
  const [showAssignRoleModal, setShowAssignRoleModal] = useState(false)
  const [roleAssignmentList, setRoleAssignmentList] = useState([])
  const [userListData, setUserListData] = useState([])
  const [usersDropdownOptions, setUsersDropdownOptions] = useState([])
  const [createUserOption, setCreateUserOption] = useState(
    'user_from_organization_directory'
  )
  const workspaceId = getCustomerAccount()?.platform_customer_id
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false)
  const [showPlatformRoleWarning, setShowPlatformRoleWarning] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [filter, setFilter] = useState('')
  const [selectRoleAssignment, setSelectRoleAssignment] = useState()
  const [
    deleteRoleAssignmentConfirmation,
    setDeleteRoleAssignmentConfirmation
  ] = useState(false)
  const orgId = getOrganizationId()
  const [orgInfo, setOrgInfo] = useState('')
  const platformWarningTitle = t('iam:role_assignment.platform_warning_title', {
    role: t('common:business_object.role')
  })
  const platformWarningMessage = t('iam:role_assignment.platform_warning_msg', {
    role: t('common:business_object.role')
  })
  const createUserOptions = [
    {
      label: t('iam:users.radio_button_label'),
      value: 'user_from_organization_directory'
    },
    {
      label: t('iam:users.radio_button_label_new'),
      value: 'new_user'
    }
  ]

  useEffect(() => {
    setLoading(true)
    if (orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (response) => {
          if (response?.data) setOrgInfo(response?.data)
          setLoading(false)
        },
        (error) => {
          setLoading(false)
          setApiError(error)
        }
      )
    }
  }, [t, orgId])

  useEffect(() => {
    const nameQuery =
      filter?.trim()?.length > 0
        ? encodeURI(
            `?filter=displayName sw "${filter?.trim()}" or userName sw "${filter?.trim()}"`
          )
        : ''
    setSearchLoading(true)
    const fetchUsersList = async () => {
      try {
        const response = await get(
          `/internal-platform-tenant-ui/v2/users${nameQuery}`
        )
        if (response.data?.items) {
          const userList = response?.data?.items.map((item) => {
            return {
              ...item,
              displayName: item?.displayName ? item?.displayName : '--'
            }
          })
          setUserListData(userList)
          setUsersDropdownOptions(userList)
        }
      } catch (error) {
        setApiError(error)
      }
      setSearchLoading(false)
    }
    fetchUsersList()
  }, [filter])

  const dropdownUserRender = (user) => (
    <Box
      direction="row"
      justify="between"
      fill
      pad={{ vertical: 'xsmall', horizontal: 'small' }}
    >
      <Typography emphasis type="text" size="small">
        {user?.displayName}
      </Typography>
      <Typography type="text" size="small">
        {user?.userName}
      </Typography>
    </Box>
  )

  const generateRoleAssignments = (roleArray, scopeArray) => {
    const roleAssignments = mapRoleAssignments(
      roleArray,
      scopeArray,
      roleAssignmentList
    )
    const newRoleAssignmentList = roleAssignmentList.concat(roleAssignments)
    setRoleAssignmentList(newRoleAssignmentList)
  }

  const refreshRoleAssignmentList = (roleAssignment) => {
    setRoleAssignmentList(
      roleAssignmentList.filter(
        (role) =>
          !(
            role.role_id === roleAssignment?.role_id &&
            role.scope === roleAssignment?.scope
          )
      )
    )
  }

  const handleDeleteRoleAssignment = (roleAssignment) => {
    if (
      roleAssignment?.service_id === glcpServiceID ||
      roleAssignment?.managedBy === 'ccs'
    ) {
      setSelectRoleAssignment(roleAssignment)
      setDeleteRoleAssignmentConfirmation(true)
    } else {
      refreshRoleAssignmentList(roleAssignment)
    }
  }

  const [formValues, setFormValues] = useState({
    email: ''
  })
  const currentWorkspace = getCustomerAccount()?.company_name
  const wkspc = t('common:business_object.wkspc')
  const onClose = useCallback(() => {
    onSetOpen(false)
  }, [onSetOpen])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchValue = useCallback(
    debounce((text) => {
      setFilter(text)
    }, 500),
    []
  )

  const handleComplete = useCallback(
    (message, severity = 'info', title = t('iam:users.add_user_title')) => {
      setIsSubmitting(true)
      onClose()
      setStatus({
        title,
        message,
        severity,
        testId: 'add-user-status'
      })
    },
    [onClose, setStatus, t]
  )

  const handleFailure = useCallback(
    (error) => {
      const msg = typeof error === 'string' ? error : getErrorMessage(error, t)
      handleComplete(msg, 'error')
    },
    [handleComplete, t]
  )

  const handleSuccess = useCallback(() => {
    const successMessage = (name, workspace) => {
      const user = t('common:business_object.user_capitalized')
      return (
        <Markdown>
          {t('iam:users.add_user_success_msg', {
            user,
            name,
            workspace,
            wkspc
          })}
        </Markdown>
      )
    }
    const message = successMessage(formValues.email, currentWorkspace)
    handleComplete(message)
  }, [handleComplete, formValues, t, currentWorkspace, wkspc])

  const handlePartialSuccess = useCallback(
    (response) => {
      // Create partial success message containing (role, scope) assignments
      const failedObject = response?.data?.failed
      const message = (
        <Box style={{ wordBreak: 'break-word' }}>
          {failedObject?.map((error, errorIndex) => {
            const errId = errorIndex
            const errorResponseObject = error?.error_response
            return (
              <Box key={errId}>
                {errorResponseObject.length > 0 && (
                  <Markdown>
                    {t('iam:users.add_user_partial_success_msg_roles', {
                      userName: formValues.email,
                      rolesFailed: errorResponseObject?.length,
                      totalRoleAssignments: roleAssignmentList?.length
                    })}
                  </Markdown>
                )}
                {errorResponseObject.map((errorSubject, errorSubjectIndex) => {
                  const errorSubjectId = errorSubjectIndex
                  return (
                    <Markdown key={errorSubjectId}>
                      {t('iam:users.assign_role_to_user_partial_success', {
                        role: errorSubject?.role,
                        scope: errorSubject?.scope,
                        backendErrorMsg: getErrorMessage(
                          errorSubject?.details,
                          t
                        )
                      })}
                    </Markdown>
                  )
                })}
              </Box>
            )
          })}
        </Box>
      )
      handleComplete(message, 'warning')
    },
    [roleAssignmentList, handleComplete, t, formValues.email]
  )

  const addUser = async () => {
    setIsSubmitting(true)
    const roleList = roleAssignmentList.map((assignment) => ({
      role_grn: assignment?.role_grn
    }))
    const scopes = roleAssignmentList.map((item) => item?.scope)
    const rolesGrn = roleList?.map((role) => role?.role_grn)
    const assignments = {
      roles_grn: rolesGrn,
      scopes
    }
    await post('/internal-platform-tenant-ui/v2/users', {
      user_name: formValues?.email?.trim(),
      assignments,
      sendWelcomeEmail,
      workspaceId
    }).then(
      (response) => {
        if (response?.data?.failed) {
          handlePartialSuccess(response)
        } else {
          handleSuccess()
        }
      },
      (error) => {
        handleFailure(error)
      }
    )
  }

  const roleAssignmentColumns = [
    {
      property: 'role_display_name',
      header: t('common:business_object.role_capitalized')
    },
    {
      property: 'scope',
      header: t('authz:assignments.scope_header')
    },
    {
      property: 'Edit',
      sortable: false,
      header: '',
      align: 'end',
      render: (datum) => {
        return (
          <ActionButton
            actions={[
              {
                icon: <Trash />,
                onClick: (roleAssignment) => {
                  handleDeleteRoleAssignment(roleAssignment)
                }
              }
            ]}
            datum={datum}
            testId="role-default-action-btn"
          />
        )
      }
    }
  ]
  return (
    <>
      <ModalDialog
        position="center"
        height={loading ? null : '100%'}
        width={loading ? 'small' : '100%'}
        overflow="hidden"
        pad="small"
        testId="add-user-dialog"
        onClose={() => onSetOpen(false)}
        content={
          loading ? (
            <Box
              fill
              align="center"
              justify="center"
              pad={{ vertical: 'small' }}
            >
              <Loader testId="add-user-loader" />
            </Box>
          ) : (
            <Box alignSelf="center" direction="column" width="50%">
              <CCSForm
                errorMessage=""
                testId="add-user-form"
                validate="submit"
                onSubmit={addUser}
                buttons={
                  <Box direction="row" justify="start" gap="medium">
                    <Button
                      testId="add-user-create-btn"
                      label={t('common:add')}
                      disabled={isSubmitting}
                      type="submit"
                      primary
                    />
                    <Button
                      testId="add-user-cancel-btn"
                      onClick={onClose}
                      label={t('common:cancel')}
                      default
                    />
                  </Box>
                }
              >
                <>
                  <Box justify="end" align="end" pad="none">
                    <Button
                      testId="close-button"
                      icon={<Close size="large" />}
                      plain
                      onClick={onClose}
                    />
                  </Box>
                  <Box gap="xsmall" margin={{ top: 'small', bottom: 'small' }}>
                    <Heading
                      margin="none"
                      size="xxlarge"
                      weight="normal"
                      testId="page-title"
                    >
                      {t('iam:users.add_user_title')}
                    </Heading>
                    <Markdown>{t('iam:users.add_user_subtitle')}</Markdown>
                  </Box>
                  {orgInfo?.lifecycleState === LIFECYCLE_STATE.ACTIVE && (
                    <FormField
                      name="user-create-form-field-radio"
                      data-testid="user-create-form-field-radio"
                    >
                      <RadioButtonGroup
                        labelKey="label"
                        valueKey="value"
                        color="brand"
                        name="radio-group-deal-id"
                        options={createUserOptions}
                        value={createUserOption}
                        onChange={(event) => {
                          setCreateUserOption(event.target.value)
                        }}
                      />
                    </FormField>
                  )}
                  {orgInfo?.lifecycleState === LIFECYCLE_STATE.ACTIVE &&
                    createUserOption === 'user_from_organization_directory' && (
                      <FormField
                        required
                        label="User"
                        name="user-duplicate"
                        data-testid="user-to-duplicate"
                      >
                        <Dropdown
                          name="user-duplicate"
                          testId="actions-dropdown"
                          placeholder="Select"
                          multiple={false}
                          options={usersDropdownOptions}
                          labelKey={(user) =>
                            user?.displayName !== '--'
                              ? user?.displayName
                              : user?.userName
                          }
                          valueKey="userName"
                          value={formValues?.email}
                          searchPlaceholder={t('search')}
                          onSearch={(value) =>
                            handleDebouncedSearchValue(value)
                          }
                          emptySearchMessage={
                            searchLoading
                              ? t('common:loading')
                              : t('iam:roles_error.no_matches_msg')
                          }
                          onClose={() => {
                            setUsersDropdownOptions(userListData)
                            setFilter('')
                          }}
                          onChangeDropdown={(user) => {
                            setFormValues({
                              email: user.userName
                            })
                          }}
                          customRender={(user) => dropdownUserRender(user)}
                          size="small"
                        />
                      </FormField>
                    )}
                  <Box width="100%" margin={{ top: 'small' }}>
                    {((orgInfo?.lifecycleState === LIFECYCLE_STATE.ACTIVE &&
                      createUserOption === 'new_user') ||
                      orgInfo?.lifecycleState === LIFECYCLE_STATE.INACTIVE) && (
                      <FormInput
                        inputType="text"
                        name="email"
                        onChange={(event) => {
                          setFormValues({
                            email: event.target.value
                          })
                        }}
                        required
                        label={t('iam:users.email_label')}
                        labelHelper={
                          <Typography type="text" truncate size="xsmall">
                            {t('iam:users.email_label_helper')}
                          </Typography>
                        }
                        margin={{ top: 'xsmall' }}
                        testId="email-form-field"
                      />
                    )}
                  </Box>
                  <Box gap="medium" pad={{ vertical: 'medium' }}>
                    <Box justify="start" gap="small">
                      <Typography type="heading" level="2">
                        {t('iam:users.assign_role_title')}
                      </Typography>
                      <Markdown>
                        {t('iam:users.assign_role_user_subtitle')}
                      </Markdown>
                      <Notification
                        status="info"
                        testId="assign-role-inline-notification"
                        text={t(
                          'iam:role_assignment.assign_role_inline_notification'
                        )}
                        type="inline"
                      />
                      {roleAssignmentList?.length > 0 && (
                        <Box margin={{ top: 'small' }}>
                          <DataTable
                            testId="role-assignments-datatable"
                            grid={{
                              columns: roleAssignmentColumns,
                              data: roleAssignmentList,
                              primaryKey: 'primaryKey'
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    <Box justify="end" align="end">
                      <VisibilityWrapper
                        rbac={{
                          resource: '/ccs/authorization',
                          permission: 'ccs.authorization.edit'
                        }}
                      >
                        <Button
                          testId="assign-role-btn"
                          onClick={() => setShowAssignRoleModal(true)}
                          label={t('iam:role_assignment.assign_role', {
                            roles: t('common:business_object.role_plural')
                          })}
                          secondary
                        />
                      </VisibilityWrapper>
                    </Box>
                    <Box
                      justify="start"
                      gap="small"
                      data-testid="send-email-title"
                    >
                      <Typography type="heading" level="2">
                        {t('iam:users.send_invitation_email')}
                      </Typography>
                      <Markdown>
                        {t('iam:users.send_invitation_email_subtitle', {
                          currentWorkspace
                        })}
                      </Markdown>
                    </Box>
                  </Box>
                  <Box
                    gap="medium"
                    pad={{ vertical: 'medium' }}
                    data-testid="send-welcome-email"
                  >
                    <CheckBox
                      onClick={() => setSendWelcomeEmail(!sendWelcomeEmail)}
                      value={sendWelcomeEmail}
                      id="contact"
                      name="contact"
                      pad="none"
                      label={t('iam:users.send_welcome_email')}
                    />
                  </Box>
                </>
              </CCSForm>
            </Box>
          )
        }
      />
      {loading && <Loader testId="role-details-loader" />}
      {isSubmitting && (
        <Loader
          modal
          testId="loader-modal"
          size="xxsmall"
          modalTitle={t('iam:users.loader_title')}
          modalSubTitle={t('iam:users.loader_subtitle')}
        />
      )}
      {showAssignRoleModal && (
        <AssignRoleSideDrawer
          preSelectedSubject={{ data: {}, subjectType: 'USER' }}
          setShowAssignRoleModal={setShowAssignRoleModal}
          onAssign={generateRoleAssignments}
        />
      )}
      {deleteRoleAssignmentConfirmation && (
        <DeleteRoleAssignmentConfirmation
          setConfirm={setDeleteRoleAssignmentConfirmation}
          retrievedRA={selectRoleAssignment}
          onRemoved={() => {
            refreshRoleAssignmentList(selectRoleAssignment)
            setDeleteRoleAssignmentConfirmation(false)
          }}
          deleteFromBackend={false}
          title={t('iam:role_assignment.remove_role_assignment_title_v2', {
            roleAssignment: t('common:business_object.role_assignment')
          })}
          subtitle={
            <Markdown>
              {t(
                'iam:role_assignment.remove_platform_role_assignment_warning',
                {
                  RoleAssignmentName: selectRoleAssignment?.role_name,
                  role: t('common:business_object.role')
                }
              )}
            </Markdown>
          }
        />
      )}
      {showPlatformRoleWarning && (
        <MessageStatusModal
          onSetOpen={setShowPlatformRoleWarning}
          title={platformWarningTitle}
          message={platformWarningMessage}
          onAdd={addUser}
          buttonLabel={t('common:continue')}
          cancellable
        />
      )}
      {apiError && displayApiError(apiError, t, setApiError)}
    </>
  )
}

AddUserModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  /**
   * Callback to indicate whether the modal should be opened or closed
   */
  setStatus: PropTypes.func.isRequired
  /**
   * Callback to set the status back to caller
   */
}

export default AddUserModal
