/**
 * SSO Wizard Constants:
 *
 * These constants represent the fields to display in view and create SSO.
 */

export const SAML = 'SAML / SSO'
export const STATIC = 'Static / Local'
export const MSP = 'MSP workspace'
export const CUSTOMER = 'Customer workspaces'
export const MSP_CUSTOMER = 'MSP and customer workspaces'
export const AUTHENTICATION = 'AUTHENTICATION_ONLY'
export const AUTHORIZATION = 'AUTHORIZATION'
export const TENANT = 'AUTHORIZATION_MSP_MANAGED'
export const AUTHENTICATION_MSP_TENANT = 'AUTHENTICATION_ONLY_MSP_MANAGED'
export const AUTHENTICATION_TENANT =
  'AUTHENTICATION_ONLY_MSP_MANAGED_TENANT_ONLY'
export const AUTHORIZATION_TENANT = 'AUTHORIZATION_MSP_MANAGED_TENANT_ONLY'
export const RETAIN_USERS_AND_ROLES = 'RETAIN_USERS_AND_ROLES'
export const RETAIN_USERS = 'RETAIN_USERS'
export const NO_RETENTION = 'NO_RETENTION'
