import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, FormField, Select, TextInput } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { get, put, getErrorMessage } from '../../../utils/api-utils'
import { Typography, Button, CCSForm, AvatarInfo } from '../../../components'
import ProfileButtons from '../ProfileButtons'
import FormSuccessInfo from '../../commoncomponents/form-success/FormSuccessInfo'
import { isFederatedAccount } from '../../../utils/common-utils'

const ProfileDetails = ({ user }) => {
  const { oidcUser } = useReactOidc()
  const [originalState, setOriginalState] = useState({})
  const [countries, setCountries] = useState([])
  const [listOfCountries, setListOfCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const isFederated = isFederatedAccount()
  const { t } = useTranslation(['common', 'authn'])
  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value, edited: true }
        case 'FORM_EDITED':
          return { ...currentState, edited: action.value }
        case 'SET_STATE':
          return { ...action.value, edited: false }
        default:
          return currentState
      }
    },
    {
      first_name: '',
      last_name: '',
      nickname: '',
      email: '',
      phone: '',
      country_code: '',
      edited: false
    }
  )

  useEffect(() => {
    get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      (response) => {
        setListOfCountries(response.data.countries)
        setCountries(response.data.countries)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }, [t])

  useEffect(() => {
    get('/accounts/ui/v1/user/profile/contact', {}, oidcUser.access_token).then(
      (response) => {
        const currentUser = {
          first_name: response.data.first_name ? response.data.first_name : '',
          last_name: response.data.last_name ? response.data.last_name : '',
          nickname: response.data.nickname ? response.data.nickname : '',
          email: response.data.email ? response.data.email : '',
          phone: response.data.phone_number ? response.data.phone_number : '',
          country_code: response.data.address.country_code
            ? response.data.address.country_code
            : '',
          edited: false
        }
        dispatch({
          value: currentUser,
          type: 'SET_STATE'
        })
        setOriginalState(currentUser)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }, [oidcUser.access_token, t])

  const handleDiscardChangesClick = () => {
    dispatch({
      value: originalState,
      type: 'SET_STATE'
    })
  }

  const handleSubmit = () => {
    setErrorMessage('')

    const body = {
      first_name: state.first_name,
      last_name: state.last_name,
      nickname: state.nickname,
      email: state.email,
      address: { country_code: state.country_code }
    }
    if (state.phone) {
      body.phone_number = state.phone
    }

    put(
      '/accounts/ui/v1/user/profile/contact',
      body,
      oidcUser.access_token
    ).then(
      (response) => {
        setOriginalState(state)
        dispatch({
          value: false,
          type: 'FORM_EDITED'
        })
        if (response && response.data && response.data.message) {
          setUpdateSuccess(true)
        }
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }

  return (
    <Box width="xlarge" height="large">
      <Typography
        level="2"
        type="heading"
        weight="normal"
        testId="details-title"
      >
        Profile Details
      </Typography>
      <Typography
        size="medium"
        type="text"
        weight="normal"
        testId="details_description"
      >
        Edit your member profile information
      </Typography>

      <CCSForm
        value={state}
        buttons={
          !isFederated && (
            <ProfileButtons
              updateLabel="Update Profile"
              edited={state.edited}
            />
          )
        }
        onReset={handleDiscardChangesClick}
        onSubmit={handleSubmit}
        errorMessage={errorMessage}
        testId="profile-details-form"
      >
        <Box direction="row" gap="xlarge" pad={{ vertical: 'medium' }}>
          <Box basis="small">
            <Typography
              size="xsmall"
              type="text"
              emphasis
              testId="profile-image-label"
              margin={{ bottom: 'small' }}
            >
              Profile Image
            </Typography>
            <AvatarInfo
              avatarOnly
              avatarSize="xlarge"
              avatarChar={
                user.name && user.name.split(' ').length > 1 ? 'two' : 'one'
              }
              primaryInfo={user.name}
              secondaryInfo={user.email}
              justify="center"
              testId="profile-image"
            />
            {/* TODO - CCS-2018: enable button after selecting an image is supported */}
            <Button
              secondary
              label="Choose Image"
              disabled
              testId="change-profile-image-button"
              margin={{ vertical: 'medium' }}
            />

            {/* TODO - CCS-2018: update string and unhide after selecting an image is supported */}
            <Typography
              size="xsmall"
              type="text"
              weight="normal"
              testId="max-min-file-size-details"
            >
              Your profile image should be a minimum of 288x288 pixels and less
              than 1 MB in size.
            </Typography>
          </Box>
          <Box gap="xsmall" basis="medium">
            <FormField
              label="First Name"
              name="first_name"
              required
              data-testid="profile-details-first-name-form-field"
            >
              <TextInput
                data-testid="profile-details-first-name-input"
                value={state.first_name}
                name="first_name"
                placeholder="Jane"
                disabled={isFederated}
                onChange={(event) => {
                  dispatch({
                    value: event.target.value,
                    field: 'first_name',
                    type: 'CHANGE_FIELD'
                  })
                }}
              />
            </FormField>

            <FormField
              label="Last Name"
              name="last_name"
              required
              data-testid="profile-details-last-name-form-field"
            >
              <TextInput
                data-testid="profile-details-last-name-input"
                value={state.last_name}
                name="last_name"
                disabled={isFederated}
                placeholder="Smith"
                onChange={(event) => {
                  dispatch({
                    value: event.target.value,
                    field: 'last_name',
                    type: 'CHANGE_FIELD'
                  })
                }}
              />
            </FormField>

            <FormField
              label="Nickname"
              name="nickname"
              data-testid="profile-details-nickname-form-field"
            >
              <TextInput
                data-testid="profile-details-nickname-input"
                value={state.nickname}
                name="nickname"
                disabled={isFederated}
                placeholder="JSmith"
                onChange={(event) => {
                  dispatch({
                    value: event.target.value,
                    field: 'nickname',
                    type: 'CHANGE_FIELD'
                  })
                }}
              />
            </FormField>
          </Box>
          <Box gap="xsmall" basis="medium">
            <FormField
              label="Country"
              name="country_code"
              required
              data-testid="profile-details-country-form-field"
            >
              <Select
                name="country_code"
                placeholder="Canada, Japan, etc."
                options={countries}
                multiple={false}
                disabled={isFederated}
                labelKey="name"
                value={state.country_code}
                valueKey={{ key: 'code', reduce: true }}
                searchPlaceholder="Region"
                emptySearchMessage="No regions found"
                onSearch={(searchText) => {
                  const regexp = new RegExp(searchText, 'i')
                  setCountries(
                    listOfCountries.filter((o) => o.name.match(regexp))
                  )
                }}
                onChange={({ option }) => {
                  dispatch({
                    value: option.code,
                    field: 'country_code',
                    type: 'CHANGE_FIELD'
                  })
                }}
                onClose={() => setCountries(listOfCountries)}
              />
            </FormField>

            <FormField
              label="Phone Number (optional)"
              name="phone"
              data-testid="profile-details-phone-form-field"
            >
              <TextInput
                data-testid="profile-details-phone-input"
                value={state.phone}
                name="email"
                disabled={isFederated}
                placeholder="Phone Number (optional)"
                onChange={(event) => {
                  dispatch({
                    value: event.target.value,
                    field: 'phone',
                    type: 'CHANGE_FIELD'
                  })
                }}
              />
            </FormField>

            <FormField
              label="Email"
              name="email"
              required
              disabled
              data-testid="profile-details-email-form-field"
            >
              <TextInput
                data-testid="profile-details-email-input"
                value={state.email}
                name="email"
                placeholder="jane.smith@att.com"
                disabled
              />
            </FormField>
            {updateSuccess && (
              <FormSuccessInfo
                message={t('authn:saml_sso.view_edit.upd_success')}
              />
            )}
          </Box>
        </Box>
      </CCSForm>
    </Box>
  )
}

export default ProfileDetails

ProfileDetails.propTypes = {
  user: PropTypes.object.isRequired
}
