import { useReducer } from 'react'

import UPSActions from './actions'
import { defaultUPSConfig } from './defaultUPSConfig'

const initialContext = {
  upsDataResolved: false,
  upsApiCallInProgress: false,
  upsGetApiCallErrored: false,
  userName: null,
  ...defaultUPSConfig
}
const VisibilityReducer = (state, action) => {
  switch (action.type) {
    case UPSActions.SET_UPS_DATA: {
      const { upsData } = action.data
      return {
        ...state,
        ...upsData
      }
    }
    case UPSActions.RESET_UPS_DATA: {
      return {
        ...initialContext
      }
    }
    case UPSActions.SET_CATEGORY_DATA: {
      const { category, data } = action.data
      const categoryState = state[category]
      const updatedCategory = categoryState.map((setting) => {
        data.forEach((reqSetting) => {
          if (setting.name === reqSetting.name) {
            setting = reqSetting
          }
        })
        return setting
      })
      return {
        ...state,
        [category]: updatedCategory
      }
    }
    case UPSActions.SET_UPS_RESOLVED_FLAG: {
      return {
        ...state,
        upsDataResolved: action.data,
        upsApiCallInProgress: !action.data
      }
    }
    case UPSActions.SET_UPS_USER_DETAILS: {
      return {
        ...state,
        ...action.data
      }
    }
    case UPSActions.SET_UPS_SERVICE_ERROR: {
      return {
        ...state,
        upsGetApiCallErrored: action.data
      }
    }

    default:
      return state
  }
}

const useUPSReducer = () => {
  const [UPSState, dispatchUPSContext] = useReducer(
    VisibilityReducer,
    initialContext
  )
  return { UPSState, dispatchUPSContext }
}

export default useUPSReducer
