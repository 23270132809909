import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Box } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ModalDialog,
  ModalFooter,
  Typography,
  ButtonGroup
} from '../../../components'
import { del } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'

const DetachDevices = ({ onSetOpen, datum, openSuccessModal, mode }) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const devicesCount = datum?.length || 0
  const [errorMessage, setErrorMessage] = useState(null)
  const handledetach = () => {
    del('/ui-doorway/ui/v1/license/devices', datum, oidcUser.access_token).then(
      () => {
        onSetOpen(false)
        openSuccessModal()
      },
      (error) => {
        setErrorMessage(displayApiError(error, t, setErrorMessage))
      }
    )
  }

  return (
    <ModalDialog
      width={mode === 'DEVICE' ? 'large' : 'medium'}
      testId="detach-devices-modal-dialog"
      content={
        <Box>
          <Typography type="heading" testId="detach-devices-title">
            {mode === 'DEVICE' ? t('detach_subscription') : t('detach_devices')}
          </Typography>
          <Typography
            type="text"
            size="large"
            testId="detach-devices-description"
          >
            {mode === 'DEVICE' ? (
              <>
                <Typography type="text" size="large">
                  <Trans
                    i18nKey={
                      devicesCount === 1
                        ? 'detach_subscription_desc_singular'
                        : 'detach_subscription_desc_plural'
                    }
                    t={t}
                  >
                    <strong>{{ devicesCount }}</strong>
                  </Trans>
                </Typography>
              </>
            ) : (
              t('detach_devices_desc')
            )}
          </Typography>
          {errorMessage}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: () => onSetOpen(false)
                },
                {
                  id: 1,
                  label:
                    mode === 'DEVICE' ? t('detach_subscription') : t('detach'),
                  primary: true,
                  testId: 'detach-btn',
                  onClick: handledetach
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={() => onSetOpen(false)}
    />
  )
}

DetachDevices.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  datum: PropTypes.array.isRequired,
  openSuccessModal: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['DEVICE', 'SUBSCRIPTION']).isRequired
}

export { DetachDevices }
