import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'

import {
  Button,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../../../components'

const ExportWorkspaceResources = ({
  setExportWorkspaceModal,
  onPremWorkspaceName
}) => {
  const { t } = useTranslation(['manage'])
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              level="2"
              testId="export-resources-title"
              type="heading"
              weight="bold"
            >
              {t('onprem_workspace.export_resources')}
            </Typography>
          }
        />
      }
      content={
        <Box gap="medium">
          <Typography type="paragraph" testId="export-resources-subtitle">
            <Trans i18nKey="onprem_workspace.export_workspaces_desc" t={t}>
              {{ onPremWorkspaceName }}
              {{ importText: `"${t('onprem_workspace.import_text')}"` }}
            </Trans>
          </Typography>
          <Button
            primary
            label={t('done')}
            onClick={() => {
              setExportWorkspaceModal(false)
            }}
            testId="export-resources-done-btn"
          />
        </Box>
      }
      width="medium"
      testId="export-workspaces-modal"
      onClose={() => {
        setExportWorkspaceModal(false)
      }}
    />
  )
}
ExportWorkspaceResources.propTypes = {
  setExportWorkspaceModal: PropTypes.func.isRequired,
  onPremWorkspaceName: PropTypes.string.isRequired
}
export default ExportWorkspaceResources
