import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'

import { Dropdown, List, Typography } from '../../../../components'
import { WizardContext } from '../../../../components/wizard/WizardContext'
import { IAAS_KEY_TYPE } from '../../utils'

const AssignInstance = () => {
  const { t } = useTranslation(['licensing'])
  const { formValues, setFormValues } = useContext(WizardContext)

  const getProductForAssignInstance = () => {
    return formValues?.selectedProductList?.filter(
      (value) =>
        value.key_type === IAAS_KEY_TYPE &&
        value?.available_applications?.length
    )
  }

  return (
    <>
      <Box
        direction="row"
        justify="between"
        margin={{ bottom: 'small', top: 'medium' }}
      >
        <Typography
          type="heading"
          level="2"
          color="text-xweak"
          style={{
            fontWeight: '700'
          }}
          testId="subscription-header"
        >
          {t('subscription')}
        </Typography>
        <Typography
          type="heading"
          level="2"
          color="text-xweak"
          style={{
            fontWeight: '700'
          }}
          testId="service-instance-header"
        >
          {t('service_instance')}
        </Typography>
      </Box>
      <List
        data={getProductForAssignInstance()}
        pad={{ vertical: 'medium' }}
        border="top"
        customRender={(datum) => (
          <Box direction="row" justify="between" key={datum?.subscription_key}>
            <Box gap="xsmall">
              {/* TODO: Modify subscription key after BE confirms the field */}
              <Typography type="heading" level="2" testId="subscription-tier">
                {datum?.subscription_tier}
              </Typography>
              <Typography type="text" size="small" testId="subscription-key">
                {datum?.subscription_key}
              </Typography>
            </Box>
            <Box>
              <Dropdown
                options={datum?.available_applications || []}
                placeholder={t('add_products.select_service_instance')}
                testId="service-instance-dropdown"
                labelKey={(option) => {
                  return `${option?.application_name} ${option?.ccs_region}`
                }}
                valueKey={{ key: 'application_customer_id' }}
                value={
                  formValues?.selectedApplication[datum?.subscription_key] || ''
                }
                onChange={({ option }) => {
                  setFormValues({
                    ...formValues,
                    selectedApplication: {
                      ...formValues.selectedApplication,
                      [datum?.subscription_key]: option
                    }
                  })
                }}
              />
            </Box>
          </Box>
        )}
        eventType="custom-render"
        testId="selected-subscriptions-list"
      />
    </>
  )
}

export { AssignInstance }
