import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { Box, FormField, TextInput } from 'grommet'
import lodashGet from 'lodash/get'

import { WizardContext } from '../../../../../../../../components/wizard/WizardContext'
import { Button } from '../../../../../../../../components'
import { getErrorMessage, post } from '../../../../../../../../utils/api-utils'
import { StyledFormField, defaultFieldsError } from '../../utils/page-utils'

const validateUrl = (
  fieldsError,
  setFieldsError,
  formValues,
  setFormValues,
  setFormError,
  setAttemptedAdvance,
  setIsLoading,
  i18nTranslate
) => {
  const url = lodashGet(formValues, 'saml_idp_config.metadata_url').trim()
  if (!url) {
    setFieldsError({
      ...fieldsError,
      metadataUrl: i18nTranslate('iam:sso_profiles.view_edit_create.required')
    })
    setIsLoading(false)
  } else {
    const urlValidate = new RegExp(/^(http|https):\/\/[^ "]+$/)
    if (!urlValidate.test(url)) {
      setFieldsError({
        ...fieldsError,
        metadataUrl: i18nTranslate(
          'iam:sso_profiles.view_edit_create.invalid_url'
        )
      })
      setIsLoading(false)
    } else {
      setFieldsError(defaultFieldsError)

      setAttemptedAdvance(false)

      post('/identity/v1alpha1/sso-profiles/idp-url', {
        url
      }).then(
        (response) => {
          setFormValues({
            ...formValues,
            saml_idp_config: {
              ...formValues.saml_idp_config,
              entity_id: response.data.entityId,
              login_url: response.data.loginUrl,
              logout_url: response.data.logoutUrl,
              signing_certificate: response.data.certificate
            }
          })
          setIsLoading(false)
        },
        (error) => {
          setFormError(getErrorMessage(error, i18nTranslate))
          setIsLoading(false)
        }
      )
    }
  }
}

const conditionallyResetValues = (
  event,
  fieldsError,
  setFieldsError,
  formValues,
  setFormValues
) => {
  if (event.target.value.trim() !== '') {
    setFieldsError({ ...fieldsError, metadataUrl: '' })
  }

  if (lodashGet(formValues, 'saml_idp_config.entity_id') !== '') {
    setFormValues({
      ...formValues,
      saml_idp_config: {
        ...formValues.saml_idp_config,
        metadata_url: event.target.value,
        signing_certificate: '',
        logout_url: '',
        entity_id: '',
        login_url: ''
      }
    })
  } else {
    setFormValues({
      ...formValues,
      saml_idp_config: {
        ...formValues.saml_idp_config,
        metadata_url: event.target.value
      }
    })
  }
}

export const MetadataUrlLeftColumn = ({ fieldsError, setFieldsError }) => {
  const { formValues, setFormValues, setAttemptedAdvance, setFormError } =
    useContext(WizardContext)
  const { t } = useTranslation(['iam'])
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <FormField
        name="metadata_url"
        label={`${t('iam:sso_profiles.view_edit_create.metadata_url')}*`}
        margin="none"
        error={fieldsError.metadataUrl}
        data-testid="metadata-url-form-field"
      >
        <TextInput
          name="metadata_url"
          value={lodashGet(formValues, 'saml_idp_config.metadata_url')}
          onChange={(event) => {
            conditionallyResetValues(
              event,
              fieldsError,
              setFieldsError,
              formValues,
              setFormValues
            )
          }}
          data-testid="metadata-url-input"
        />
      </FormField>
      <Box align="start">
        <StyledFormField data-testid="validate-url-form-field">
          <Button
            secondary
            isLoading={isLoading}
            label={t('iam:sso_profiles.view_edit_create.validate_url')}
            testId="validate-url-button"
            onClick={() => {
              setIsLoading(true)
              validateUrl(
                fieldsError,
                setFieldsError,
                formValues,
                setFormValues,
                setFormError,
                setAttemptedAdvance,
                setIsLoading,
                t
              )
            }}
          />
        </StyledFormField>
      </Box>
    </>
  )
}

MetadataUrlLeftColumn.propTypes = {
  fieldsError: PropTypes.shape({
    metadataUrl: PropTypes.string.isRequired,
    validateUrl: PropTypes.string.isRequired
  }).isRequired,
  setFieldsError: PropTypes.func.isRequired
}

export default MetadataUrlLeftColumn
