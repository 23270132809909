import React from 'react'
import {
  StatusGoodSmall,
  StatusCriticalSmall,
  StatusWarningSmall,
  StatusPlaceholderSmall
} from 'grommet-icons'

const StatusIcon = ({ status }) => {
  let statusIcon
  switch (status) {
    case 'ACTIVE':
      statusIcon = <StatusGoodSmall size="small" color="status-ok" />
      break
    case 'ERROR':
      statusIcon = <StatusCriticalSmall size="small" color="status-critical" />
      break
    case 'WARNING':
      statusIcon = <StatusWarningSmall size="small" color="status-warning" />
      break
    case 'PENDING':
    case 'DISABLED':
      statusIcon = (
        <StatusPlaceholderSmall size="small" color="status-unknown" />
      )
      break
    default:
      statusIcon = null
  }

  return statusIcon
}

export default StatusIcon
