import React, { useEffect, useState } from 'react'
import { Box, Markdown } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { Loader, Typography, Wizard } from '../../../components'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'
import { get } from '../../../utils/api-utils'
import {
  IAAS_KEY_TYPE,
  NOT_CLAIMED_STATUS,
  isSelectionErrorExist
} from '../utils'
import { AddProductsEmptyTemplate } from '../components'
import { validateAssignInstance } from '../validation-utils'
import { handleProductClaim } from '../api-utils'
import { ServiceDeploymentWizard } from '../components/ServiceDeploymentWizard'

import {
  AddDevices,
  AddSubscriptions,
  AssignInstance,
  ReviewAndSubmit
} from './steps'

const AddProductsWizardPhase2 = () => {
  const { company_name: workspaceName } = getCustomerAccount()
  const { t } = useTranslation(['licensing', 'common'])
  const { oidcUser } = useReactOidc()
  const history = useHistory()
  const { productSquid } = useParams()
  const [showAssignInstance, setShowAssignInstance] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [productList, setProductList] = useState([])
  const [activeWizard, setActiveWizard] = useState('')
  const [showDevices, setShowDevices] = useState(false)

  // To handle subscriptions list response
  const handleSubscriptionsResponse = (subscriptionsResp) => {
    if (subscriptionsResp?.status === 'fulfilled') {
      const subscriptionList =
        subscriptionsResp?.value?.data?.subscription_keys?.filter(
          (value) => value.claim_status === NOT_CLAIMED_STATUS
        )

      // If subscriptions exist, check if Service deployed or Add products wizard needs to be displayed
      if (subscriptionList?.length) {
        const serviceRequiredSubscriptionList = subscriptionList?.filter(
          (value) =>
            value.key_type === IAAS_KEY_TYPE &&
            !value.available_applications?.length
        )

        // If we have subscription that requires service provision, set subscriptions state as 'SERVICE_DEPLOYMENT' else set as 'ADD_PRODUCTS'
        if (serviceRequiredSubscriptionList?.length) {
          setProductList(subscriptionList)
          return 'SERVICE_DEPLOYMENT'
        }
        return 'ADD_PRODUCTS'
      }
    }
    // if we don't have subscriptions or API errors out, set subscriptions state as 'EMPTY_STATE'
    return 'EMPTY_STATE'
  }

  // To handle devices list response
  const handleDevicesResponse = (devicesResp) => {
    if (devicesResp?.status === 'fulfilled') {
      const deviceListCount = devicesResp?.value?.data?.pagination?.total_count
      // if we have devices for user selection, set devices state as 'ADD_PRODUCTS'
      if (deviceListCount) {
        setShowDevices(true)
        return 'ADD_PRODUCTS'
      }
    }
    // if we don't have devices or API errors out, set devices state as 'EMPTY_STATE'
    return 'EMPTY_STATE'
  }

  useEffect(() => {
    setIsLoading(true)
    const promises = [
      get(
        '/ui-doorway/ui/v1/license/orders',
        { squid: productSquid },
        oidcUser.access_token
      ),
      get(
        '/ui-doorway/ui/v1/devices',
        { limit: 20, offset: 0, squid: productSquid },
        oidcUser.access_token
      )
    ]
    Promise.allSettled(promises)
      .then(([subscriptionsResp, devicesResp]) => {
        // If subscriptions with service provision required, set activeWizardState as SERVICE_DEPLOYMENT
        // If both subscriptions & devices are empty, set activeWizardState as EMPTY_STATE
        // If either iaas subscription with service provisioned or non iaas subscription or devcies exist, set activeWizardState as ADD_PRODUCTS

        // subscriptions api response - /ui-doorway/ui/v1/license/orders
        const subscriptionsState =
          handleSubscriptionsResponse(subscriptionsResp)
        // devices api response - /ui-doorway/ui/v1/devices
        const devicesState = handleDevicesResponse(devicesResp)

        if (subscriptionsState === 'SERVICE_DEPLOYMENT')
          setActiveWizard('SERVICE_DEPLOYMENT')
        else if (
          subscriptionsState === 'ADD_PRODUCTS' ||
          devicesState === 'ADD_PRODUCTS'
        )
          setActiveWizard('ADD_PRODUCTS')
        else setActiveWizard(subscriptionsState || devicesState)
      })
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token])

  // To handle continue button click in Service required wizard
  const handleContinue = () => {
    setActiveWizard('ADD_PRODUCTS')
  }

  // To display continue button in Service required wizard
  // Display button if we have iaas subscription with service provisioned or non iaas subscription or devices exist for selection
  const isContinueButtonRequired = () => {
    return (
      !!productList?.find(
        (value) =>
          value.key_type !== IAAS_KEY_TYPE ||
          value?.available_applications?.length
      ) || showDevices
    )
  }

  // To get default formValues
  const getDefaultFormValues = () => {
    return {
      serviceRequiredProductList: productList,
      selectedProductList: [],
      selectedApplication: {},
      selectedDevicesList: [],
      claimedAllDevices: false,
      claimedAllSubscriptions: false
    }
  }

  // To get active wizard to display in UI
  const getActiveWizard = () => {
    switch (activeWizard) {
      case 'EMPTY_STATE':
        return (
          <AddProductsEmptyTemplate
            title={t('add_products.no_available_products')}
            description={t('add_products.no_available_products_desc')}
          />
        )

      case 'SERVICE_DEPLOYMENT':
        return (
          <ServiceDeploymentWizard
            handleContinue={handleContinue}
            isContinueButtonRequired={isContinueButtonRequired}
            defaultFormValues={getDefaultFormValues()}
          />
        )
      case 'ADD_PRODUCTS':
        return (
          <Wizard
            buttonLabels={{
              finish: t('common:submit'),
              next: { hideIcon: true }
            }}
            actionOnExit={() => {
              history.push('/home')
            }}
            actionOnSubmit={(formValues) => {
              if (!isSelectionErrorExist(formValues))
                handleProductClaim({
                  formValues,
                  history,
                  t,
                  oidcUser,
                  squid: productSquid
                })
            }}
            formDefaultValues={getDefaultFormValues()}
            steps={[
              {
                childComponents: (
                  <AddSubscriptions
                    onAssignInstance={(status) => {
                      setShowAssignInstance(status)
                    }}
                  />
                ),
                description: (
                  <Typography
                    type="paragraph"
                    testId="step-description"
                    size="xlarge"
                  >
                    <Markdown>
                      {t('add_products.add_subscriptions_desc_phase2', {
                        workspaceName
                      })}
                    </Markdown>
                  </Typography>
                ),
                title: t('add_products.add_subscriptions'),
                validateForm: () => ({
                  then: (resolve) => {
                    resolve()
                  }
                })
              },
              {
                childComponents: <AddDevices />,
                description: (
                  <Typography
                    type="paragraph"
                    testId="step-description"
                    size="xlarge"
                  >
                    <Markdown>
                      {t('add_products.add_devices_desc', {
                        workspaceName
                      })}
                    </Markdown>
                  </Typography>
                ),
                title: t('add_products.add_devices'),
                validateForm: () => ({
                  then: (resolve) => {
                    resolve()
                  }
                })
              },
              ...(showAssignInstance
                ? [
                    {
                      childComponents: <AssignInstance />,
                      description: (
                        <Typography
                          type="paragraph"
                          testId="step-description"
                          size="xlarge"
                        >
                          <Markdown>
                            {t('add_products.assign_instance_desc')}
                          </Markdown>
                        </Typography>
                      ),
                      title: t('add_products.assign_instance'),
                      validateForm: (formValues) =>
                        validateAssignInstance(formValues, t)
                    }
                  ]
                : []),
              {
                childComponents: <ReviewAndSubmit />,
                description: (
                  <Typography
                    type="paragraph"
                    testId="step-description"
                    size="xlarge"
                  >
                    <Markdown>
                      {t('add_products.review_and_submit_desc_phase2', {
                        workspaceName
                      })}
                    </Markdown>
                  </Typography>
                ),
                title: t('add_products.review_and_submit_phase2')
              }
            ]}
            testId="add-products-wizard"
            title={t('add_products.add_products')}
          />
        )
      default:
        return null
    }
  }

  return (
    <Box>
      {isLoading ? <Loader testId="loader-spinner" /> : getActiveWizard()}
    </Box>
  )
}

export default AddProductsWizardPhase2
