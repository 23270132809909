import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { MenuList } from '../../components'
import { isInventoryOwnedWorkspace } from '../../utils/feature-flag-utils'
import { customRenderer } from '../../pages/commoncomponents/CustomRenderer'

const SideMenu = ({ view }) => {
  const { t } = useTranslation(['dashboard'])
  const history = useHistory()
  const { pathname } = useLocation()
  const LDFlags = useFlags()
  const smAutoReassignFlag = LDFlags['glcp-sm-auto-reassign']
  const dmEnableDefaultRegion = LDFlags['glcp-dm-enable-default-region']

  const myServiceMenuData = [
    {
      id: 1,
      label: t('common.my_services'),
      path: '/services/my-services',
      active: 'my-services',
      testId: 'service-centric-menulist-my-services-link',
      visibility: {
        rbac: {
          resource: '/ccs/app-provision/provisions',
          permission: 'ccs.app-provision.view'
        }
      }
    },
    {
      id: 2,
      label: t('common.subscriptions'),
      path: '/services/service-subscriptions',
      active: 'subscriptions',
      testId: 'service-centric-menulist-service-subscriptions-link',
      visibility: {
        rbac: {
          resource: '/ccs/authorization',
          permission: 'ccs.authorization.view'
        }
      },
      isHidden: () => !isInventoryOwnedWorkspace()
    },
    {
      id: 3,
      label: t('common.catalog'),
      path: '/services/service-catalog',
      active: 'catalog',
      testId: 'service-centric-menulist-service-catalog-link',
      visibility: {
        rbac: {
          resource: '/ccs/app-provision/provisions',
          permission: 'ccs.app-provision.view'
        }
      }
    }
  ]

  const devicesMenuData = [
    {
      id: 1,
      label: t('common.inventory'),
      path: '/devices/inventory',
      active: 'inventory'
    },
    {
      id: 2,
      label: t('common.tags'),
      path: '/devices/tags',
      active: 'tags'
    },
    {
      id: 3,
      label: t('common.device_subscriptions'),
      path: '/devices/subscriptions',
      active: 'subscriptions'
    },
    ...(smAutoReassignFlag || dmEnableDefaultRegion
      ? [
          {
            id: 4,
            label: t('common.device_configuration'),
            routeTo: '/devices/device-configuration',
            testId: 'device-configuration-link'
          }
        ]
      : [
          {
            id: 4,
            label: t('common.auto_subscribe'),
            path: '/devices/auto-subscribe',
            active: 'auto-subscribe'
          }
        ])
  ]

  const chosenSideMenuData =
    view === 'my-services' ? myServiceMenuData : devicesMenuData

  const getSelectedListId = () => {
    const data = chosenSideMenuData.find((item) => pathname.includes(item.path))
    return data ? data.id : 1
  }

  return (
    <MenuList
      defaultActiveId={getSelectedListId()}
      highlightOnSelect
      menuData={chosenSideMenuData}
      menuItemStyles={{ weight: 500 }}
      testId="service-centric-menulist"
      onClickMenuItem={({ path }) => {
        history.push(path)
      }}
      navStyles={{ padding: 'none', gap: 'small' }}
      customRenderer={customRenderer}
    />
  )
}

SideMenu.propTypes = {
  view: PropTypes.oneOf(['my-services', 'devices'])
}

SideMenu.defaultProps = {
  view: 'my-services'
}

export default SideMenu
