import PropTypes from 'prop-types'

export const COPCertificateRedirection = ({ routerPath }) => {
  if (process.env.NODE_ENV === 'development') {
    if (routerPath) {
      window.location.replace(
        `${window.location.protocol}//${window.location.host}${routerPath}`
      )
    } else window.location.replace(`/`)
    return null
  }
  const redirectURL = encodeURIComponent(
    `${window.location.protocol}//${window.location.host}${routerPath}`
  )
  const url = `${window.$settings.baseUrl}/ui-doorway/ui/v1/copcertificate?url=${redirectURL}`
  window.location.replace(url)
  return null
}

COPCertificateRedirection.propTypes = {
  routerPath: PropTypes.string
}

COPCertificateRedirection.defaultProps = {
  routerPath: ''
}
