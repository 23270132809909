import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, Select } from 'grommet'
import PropTypes from 'prop-types'

import { fetchServices, onSearch } from '../utils/FormUtil'
import { Typography } from '../../../../components'

let servicesData = {}

const ServiceElement = ({ service, caseFormCallBack }) => {
  const { t } = useTranslation(['support_cases'])

  const [serviceOptions, setServiceOptions] = useState([])
  const [initialServiceOptions, setInitialServiceOptions] = useState([])

  const serviceNotListed = useMemo(() => {
    const serviceNotListedLocal = {
      value: t('support_cases:create_case.service_not_listed_msg'),
      name: '',
      category: '',
      id: 'service_not_listed',
      static: true
    }
    return serviceNotListedLocal
  }, [t])

  useEffect(() => {
    // fetching services
    servicesData = fetchServices(t)
    if (servicesData?.supportedServices?.length) {
      setServiceOptions([...servicesData.supportedServices, serviceNotListed])
      setInitialServiceOptions(servicesData.supportedServices)
    }
  }, [serviceNotListed, t])

  return (
    <>
      <Typography level="2" type="heading">
        {t('support_cases:common.issue_details')}
      </Typography>
      <FormField
        htmlFor={servicesData.id}
        name={servicesData.api_name}
        label={t(servicesData.locale_key)}
        required={servicesData.required}
        data-testid={servicesData.id}
        margin={{ top: 'xsmall' }}
      >
        <Select
          id={servicesData.id}
          name={servicesData.api_name}
          placeholder={t(servicesData.placeholder_locale_key)}
          searchPlaceholder={t(servicesData.search_locale_key)}
          options={serviceOptions}
          disabled={[serviceOptions.length - 1]}
          dropHeight="medium"
          dropProps={{
            stretch: 'align',
            width: 'large'
          }}
          labelKey={(serviceOption) => (
            <Box justify="between" direction="row">
              <Typography type="text" size="medium" weight={500}>
                {serviceOption.value}
              </Typography>
              <Typography type="text" size="small">
                {serviceOption.category}
              </Typography>
            </Box>
          )}
          valueKey={{ key: 'value', reduce: true }}
          valueLabel={
            <Box
              data-testid="case-form-selected-service"
              pad={{
                top: 'xsmall',
                bottom: 'xsmall'
              }}
              margin={{ horizontal: 'small' }}
            >
              <Typography
                type="text"
                size="medium"
                weight={service.value ? 500 : 'normal'}
                color={service.value ? undefined : 'text-weak'}
              >
                {service.value || t(servicesData.placeholder_locale_key)}
              </Typography>
            </Box>
          }
          onOpen={() => {
            setServiceOptions([...initialServiceOptions, serviceNotListed])
          }}
          onChange={({ option }) => {
            caseFormCallBack({
              data: {
                selectedService: option,
                renderContact: false
              }
            })
          }}
          onSearch={(query) => {
            setServiceOptions([
              ...onSearch(query, initialServiceOptions),
              serviceNotListed
            ])
          }}
        />
      </FormField>
    </>
  )
}

ServiceElement.propTypes = {
  service: PropTypes.object.isRequired,
  caseFormCallBack: PropTypes.func
}

ServiceElement.defaultProps = {
  caseFormCallBack: () => {}
}

export default ServiceElement
