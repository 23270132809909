import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import { LDFeatureFlags } from './configs/glcp-ld-feature-flags'
import './i18n'
import { ContextualHelp } from './pages/commoncomponents/contextual-help-layout/ContextualHelp'
import * as serviceWorker from './serviceWorker'
import { get } from './utils/api-utils'
import { isGLOP, isCoP } from './utils/feature-flag-utils'
import './utils/local-storage-utils'
import {
  getMfeCloudBaseUrl,
  getMfeCloudNewApiUrl,
  getMfeCloudOrgBaseUrl,
  isMfeCloud
} from './utils/settings-utils'

function startServer() {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_PROXY_MIRAGE
  ) {
    /* eslint-disable global-require */
    const mirageServer = require('./mock-server/mirage-server').default
    const { Server, Response } = require('miragejs')
    /* eslint-enable */
    if (window.Cypress) {
      // Refer - https://miragejs.com/quickstarts/cypress/
      const cyServer = new Server({
        environment: 'test',
        routes() {
          // this passthrough is required for letting this call go through cuz of json consumption
          this.passthrough('/regions.json')

          // TODO: CCS-1384 - update this to be populated based on settings.json
          this.urlPrefix = window.$settings.baseUrl

          const methods = ['get', 'put', 'patch', 'post', 'delete']
          methods.forEach((method) => {
            this[method]('/*', async (schema, request) => {
              const [status, headers, body] = await window.handleFromCypress(
                request
              )
              return new Response(status, headers, body)
            })
          })
          this.passthrough('https://app.launchdarkly.com/**')
          this.passthrough('https://bam.nr-data.net/**')
          this.passthrough(
            'https://hewlettpackardnonproduction2mogyl6nm.org.coveo.com/**'
          )
        }
      })
      cyServer.logging = false
    } else if (
      process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_PROXY_MIRAGE
    ) {
      mirageServer()
    }
  }
}

async function renderApp() {
  // TODO: Remove the window.Cypress check for bootstrap after integrate the LD SDK for Cypress instead of mockLD fn
  const LDConfig = {
    clientSideID: window.$settings?.ldClientID,
    reactOptions: {
      useCamelCaseFlagKeys: false
    },
    flags: LDFeatureFlags,
    options: {
      ...(window.Cypress
        ? { streaming: false }
        : { bootstrap: LDFeatureFlags }),
      ...(process.env.REACT_APP_VERCEL
        ? { streaming: false, baseUrl: '/launch-darkly' }
        : {})
    }
  }

  const LDProvider =
    !isGLOP() && !isCoP() ? await asyncWithLDProvider(LDConfig) : null

  const hasLD = () => {
    // Enable LD only for Cloud and when REACT_APP_ENABLE_LD is set as true or Cypress is true
    if (window.$settings?.isCoP || window.$settings?.isGLOP) {
      return false
    }
    if (
      process.env.REACT_APP_ENABLE_LD ||
      (window.Cypress && !window.$settings?.isCoP)
    ) {
      return true
    }
    return false
  }
  const GLCPApp = () => {
    return hasLD() ? (
      <LDProvider>
        <App />
      </LDProvider>
    ) : (
      <App />
    )
  }

  const GLCPContextualHelp = () => {
    return hasLD() ? (
      <LDProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <ContextualHelp proxyOverride={process.env.REACT_APP_HELP_PROXY} />
        </Suspense>
      </LDProvider>
    ) : (
      <ContextualHelp proxyOverride={process.env.REACT_APP_HELP_PROXY} />
    )
  }
  ReactDOM.render(<GLCPApp />, document.getElementById('root'))
  ReactDOM.render(<GLCPContextualHelp />, document.getElementById('help'))
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
}

async function bootstrap() {
  // This is a shim for legacy code in the new GLP/react package to work with this app as well as all others using the new package.
  window.NODE_ENV = process.env.NODE_ENV
  try {
    const { data: settings } = await get(
      `/settings.json?${process.env.REACT_APP_BUILD_TS}`,
      {},
      undefined,
      true
    )
    if (window.Cypress && window.isCoP) {
      settings.isCoP = true
    }
    if (window.Cypress && window.isGLOP) {
      settings.isGLOP = true
    }
    // TODO: use json validator instead
    if (settings?.authorityURL && settings.client_id) {
      if (!settings.baseUrl) {
        // settings.baseUrl = window.location.origin
        settings.baseUrl = `${window.location.origin}/api`
      }
      if (isMfeCloud) {
        console.log('index settings', settings)
        settings.baseUrl = getMfeCloudBaseUrl
        settings.orgBaseUrl = getMfeCloudOrgBaseUrl
        settings.newAPIHost = getMfeCloudNewApiUrl
      }
      window.$settings = settings
      Object.freeze(window.$settings)
      startServer()
      renderApp()
    } else {
      throw new Error(`Bad settings.json: ${JSON.stringify(settings)}`)
    }
  } catch (e) {
    console.error(e)
  }
}

bootstrap()
