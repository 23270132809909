import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'
import { UsageMonitoringContextProvider } from '../../../context/usage-monitoring-context'

const UsageReportPage = lazy(() => import('./UsageReportPage'))

const UsageMonitoringRouterContent = () => {
  const { path } = useRouteMatch()

  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route path={`${path}`}>
          <UsageReportPage />
        </Route>
      </Switch>
    </Suspense>
  )
}

const UsageMonitoringRouter = () => {
  return (
    <UsageMonitoringContextProvider>
      <UsageMonitoringRouterContent />
    </UsageMonitoringContextProvider>
  )
}

export default UsageMonitoringRouter
