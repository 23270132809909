let chatChannel

if (typeof BroadcastChannel !== 'undefined') {
  chatChannel = new BroadcastChannel('chat_channel')
}

const MAX_MESSAGES = 50
let shouldBroadcast = true
let broadcastTimeout = null
export const saveChatToLocalStorage = (chatState) => {
  const limitedChatState = {
    ...chatState,
    chat: chatState?.chat?.slice(-MAX_MESSAGES),
    selectedButtons: chatState.selectedButtons
  }

  localStorage.setItem('chatState', JSON.stringify(limitedChatState))

  if (shouldBroadcast && chatChannel) {
    clearTimeout(broadcastTimeout)
    broadcastTimeout = setTimeout(() => {
      chatChannel.postMessage(limitedChatState)
    }, 500)
  } else {
    shouldBroadcast = true
  }
}

export const loadChatFromLocalStorage = () => {
  const savedChat = localStorage.getItem('chatState')

  return savedChat
    ? JSON.parse(savedChat)
    : {
        chat: [],
        open: false,
        progressIndicator: false,
        currentPoll: 0,
        sessionId: null,
        selectedButtons: {}
      }
}

let updateChatState = (newState) => {
  return newState
}

export const registerUpdateChatState = (callback) => {
  updateChatState = callback
}

if (chatChannel) {
  chatChannel.onmessage = (event) => {
    const newChatState = event.data
    if (newChatState) {
      shouldBroadcast = false
      updateChatState(newChatState)
    }
  }
}
