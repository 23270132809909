import { Box } from 'grommet'
import { React, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  ButtonGroup,
  CCSForm,
  ModalDialog,
  Typography
} from '../../../../../components'
import { put, getErrorMessage } from '../../../../../utils/api-utils'

const ZTOToggleModal = ({
  setZTOModal,
  customerId,
  setAddAliasNotification,
  ztoResourceId,
  enabled
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage'])
  const ztoTags = enabled
    ? {
        title: t('zto.enable_zto'),
        subTitle: t('zto.enable_zto_confirm'),
        desc: t('zto.enable_zto_desc'),
        submitBtn: t('zto.enable_zto_short'),
        submitId: 'enable-zto-btn'
      }
    : {
        title: t('zto.disable_zto'),
        subTitle: t('zto.disable_zto_confirm'),
        desc: t('zto.disable_zto_desc'),
        submitBtn: t('zto.disable_zto_short'),
        submitId: 'disable-zto-btn'
      }

  const handleSubmit = () => {
    const url = `/support-assistant/v1alpha1/customers/${customerId}/zto-settings/${ztoResourceId}`
    const requestBody = enabled ? { enabled: true } : { enabled: false }
    put(url, requestBody, oidcUser.access_token).then(
      () => {
        setZTOModal(false)
        setAddAliasNotification(true)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }

  const handleClose = () => {
    setZTOModal(false)
  }
  return (
    <ModalDialog
      width="large"
      testId="zto-toggle-modal"
      content={
        <Box gap="small" width="large">
          <CCSForm
            errorMessage={errorMessage}
            validate="blur"
            onSubmit={handleSubmit}
            testId="toggle-zto-form"
          >
            <Typography type="heading" testId="customer-signup-title">
              {ztoTags.title}
            </Typography>
            <Typography
              type="text"
              size="large"
              testId="invite-subtitle"
              margin={{ vertical: 'small' }}
            >
              <Box margin={{ vertical: 'small' }}>{ztoTags.desc}</Box>
              <Box>{ztoTags.subTitle}</Box>
            </Typography>
            <Box margin={{ vertical: 'small' }}>
              <ButtonGroup
                buttonList={[
                  {
                    id: 1,
                    label: ztoTags.submitBtn,
                    primary: true,
                    testId: ztoTags.submitId,
                    type: 'submit'
                  },
                  {
                    id: 2,
                    label: 'Cancel',
                    default: true,
                    testId: 'cancel-btn',
                    onClick: handleClose
                  }
                ]}
                testId="two-buttons"
              />
            </Box>
          </CCSForm>
        </Box>
      }
      onClose={handleClose}
    />
  )
}
ZTOToggleModal.propTypes = {
  setZTOModal: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  setAddAliasNotification: PropTypes.func.isRequired,
  ztoResourceId: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired
}
export { ZTOToggleModal }
