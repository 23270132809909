import React, { useContext, memo } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Heading, ResponsiveContext } from 'grommet'

import { SupportCard } from './SupportCard'

const TilesRow = memo(
  ({ rows, title, testIdTitle, columns: columnsProp }) => {
    const size = useContext(ResponsiveContext)

    const columns = () => {
      // set 1 column for small devices
      if (size === 'xsmall') {
        return '1'
      }

      if (columnsProp) {
        return new Array(columnsProp).fill(['200px', `1/${columnsProp}`])
      }

      return { count: 'fill', size: size === 'large' ? '1/3' : '252px' }
    }

    const titleGap = (sizeArg) => {
      const titleGapMap = {
        xsmall: 'large',
        small: 'large'
      }

      return titleGapMap[sizeArg] || 'medium'
    }

    return (
      <Box gap={titleGap(size)}>
        <Heading
          level="2"
          testId={testIdTitle}
          margin={{ bottom: 'none', top: 'none' }}
        >
          {title}
        </Heading>
        <Grid columns={columns()} rows="auto" gap="medium">
          {rows.map(({ id, renderWrapper, ...props }) => {
            const renderCard = () => <SupportCard key={id} {...props} />

            if (renderWrapper) {
              return (
                <React.Fragment key={id}>
                  {renderWrapper(renderCard)}
                </React.Fragment>
              )
            }

            return renderCard()
          })}
        </Grid>
      </Box>
    )
  },
  (old, newS) => JSON.stringify(old) === JSON.stringify(newS)
)

TilesRow.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      isLinkExternal: PropTypes.bool,
      isIconInTitle: PropTypes.bool,
      callback: PropTypes.func,
      href: PropTypes.string,
      target: PropTypes.string
    })
  ),
  title: PropTypes.string.isRequired,
  testIdTitle: PropTypes.string.isRequired,
  columns: PropTypes.number
}

TilesRow.defaultProps = {
  rows: [],
  columns: undefined
}

export { TilesRow }
