import lodashGet from 'lodash/get'

import { post, getErrorMessage } from '../../../../../../../utils/api-utils'
import {
  isCoP,
  isGLOP,
  isMSP
} from '../../../../../../../utils/feature-flag-utils'
import {
  validateCity,
  validateCompanyName,
  validateForm,
  validateNumberRange
} from '../../../../../../../utils/validation-utils'
import { getDomainsInfo } from '../../../common-utils'
import {
  SAML,
  STATIC,
  MSP,
  CUSTOMER,
  MSP_CUSTOMER,
  TENANT,
  AUTHENTICATION,
  AUTHORIZATION,
  AUTHENTICATION_MSP_TENANT,
  AUTHENTICATION_TENANT,
  AUTHORIZATION_TENANT,
  RETAIN_USERS_AND_ROLES,
  RETAIN_USERS,
  NO_RETENTION
} from '../constants'

export const compareScopeOfSSO = (scopeOfSSOArray, i18nTranslate) => {
  const mspWorkspace = i18nTranslate(
    'authn:manage_account.saml_sso.msp_wksp_sso_scope.msp'
  )
  const customerWorkspace = i18nTranslate(
    'authn:manage_account.saml_sso.msp_wksp_sso_scope.customer_wksp'
  )
  const formatScopeOfSSO = () => {
    if (scopeOfSSOArray.length === 1) {
      return scopeOfSSOArray[0]
    }
    if (
      scopeOfSSOArray.length > 1 &&
      scopeOfSSOArray.includes(mspWorkspace) &&
      scopeOfSSOArray.includes(customerWorkspace)
    ) {
      return i18nTranslate(
        'authn:manage_account.saml_sso.msp_wksp_sso_scope.msp_customer_wksp'
      )
    }
    return ''
  }
  const formattedScopeOfSSO = formatScopeOfSSO()
  return formattedScopeOfSSO
}

export const validatePercolateAuthSettings = (formValues, i18nTranslate) => {
  const errorMessage = i18nTranslate('common:form_error')
  const authMethod = lodashGet(formValues, 'auth_method').trim()
  const isValidOption =
    authMethod ===
      i18nTranslate(
        'authn:manage_account.saml_sso.auth_method_options.static'
      ) ||
    authMethod ===
      i18nTranslate('authn:manage_account.saml_sso.auth_method_options.saml')
  if (!isValidOption) {
    return Promise.reject(new Error(errorMessage))
  }

  if (isMSP()) {
    const scopeSelected = compareScopeOfSSO(
      lodashGet(formValues, 'scope_of_sso'),
      i18nTranslate
    ).trim()
    const isValidScope =
      scopeSelected ===
        i18nTranslate('authn:manage_account.saml_sso.msp_wksp_sso_scope.msp') ||
      scopeSelected ===
        i18nTranslate(
          'authn:manage_account.saml_sso.msp_wksp_sso_scope.customer_wksp'
        ) ||
      scopeSelected ===
        i18nTranslate(
          'authn:manage_account.saml_sso.msp_wksp_sso_scope.msp_customer_wksp'
        )
    if (!isValidScope) {
      return Promise.reject(new Error(errorMessage))
    }
  }
  return validateForm(formValues, i18nTranslate)
}

export const validateAuthSettings = (formValues, i18nTranslate) => {
  const errorMessage = i18nTranslate('common:form_error')
  const authMethod = lodashGet(formValues, 'auth_method').trim()
  const isValidOption =
    authMethod ===
      i18nTranslate(
        'authn:manage_account.saml_sso.auth_method_options.static'
      ) ||
    authMethod ===
      i18nTranslate('authn:manage_account.saml_sso.auth_method_options.saml')
  if (!isValidOption) {
    return Promise.reject(new Error(errorMessage))
  }

  const scopeSelected = lodashGet(formValues, 'scope_of_sso').trim()
  const isValidScope =
    scopeSelected ===
      i18nTranslate('authn:manage_account.saml_sso.msp_wksp_sso_scope.msp') ||
    scopeSelected ===
      i18nTranslate(
        'authn:manage_account.saml_sso.msp_wksp_sso_scope.customer_wksp'
      )
  if (!isValidScope) {
    return Promise.reject(new Error(errorMessage))
  }
  return validateForm(formValues, i18nTranslate)
}

export const validateRetentionModeInput = (formValues, i18nTranslate) => {
  const requiredString = i18nTranslate('saml_sso.required')
  const retianPolicyRaw = lodashGet(formValues, 'retention_mode')
  if (typeof retianPolicyRaw !== 'string') {
    return Promise.reject(new Error(requiredString))
  }
  const retentionMode = retianPolicyRaw.trim()
  const isValidRetentionMode =
    retentionMode === RETAIN_USERS_AND_ROLES ||
    retentionMode === RETAIN_USERS ||
    retentionMode === NO_RETENTION
  if (!isValidRetentionMode) {
    return Promise.reject(new Error(requiredString))
  }
  return validateForm(formValues, i18nTranslate)
}

export const validateFields = (formValues, i18nTranslate) => {
  const requiredString = i18nTranslate('saml_sso.required')
  const errorString = i18nTranslate('saml_sso.invalid_char')
  const userInput = new RegExp(/^[^<>(){}]*$/)
  const urlValidate = new RegExp(/^(http|https):\/\/[^ "]+$/)
  // After a discussion with the BE team, they confirmed they are doing all
  // validation checks on these items. The UI will only do a check to make
  // sure they are not empty.

  const entityId = lodashGet(formValues, 'saml_idp_config.entity_id').trim()
  const loginUrl = lodashGet(formValues, 'saml_idp_config.login_url').trim()
  const logoutUrl = lodashGet(formValues, 'saml_idp_config.logout_url').trim()
  const signingCert = lodashGet(
    formValues,
    'saml_idp_config.signing_certificate'
  ).trim()
  const userInputValidate =
    urlValidate.test(logoutUrl) &&
    urlValidate.test(loginUrl) &&
    userInput.test(entityId) &&
    userInput.test(loginUrl) &&
    userInput.test(logoutUrl) &&
    userInput.test(signingCert)
  const metadataUrl = lodashGet(
    formValues,
    'saml_idp_config.metadata_url'
  ).trim()
  const formValid =
    userInputValidate && entityId && loginUrl && logoutUrl && signingCert
  return {
    entityId:
      (entityId ? '' : requiredString) ||
      (userInput.test(entityId) ? '' : errorString),
    loginUrl:
      (loginUrl ? '' : requiredString) ||
      (userInput.test(loginUrl) ? '' : errorString) ||
      (urlValidate.test(loginUrl) ? '' : i18nTranslate('saml_sso.invalid_url')),
    logoutUrl:
      (logoutUrl ? '' : requiredString) ||
      (userInput.test(logoutUrl) ? '' : errorString) ||
      (urlValidate.test(logoutUrl)
        ? ''
        : i18nTranslate('saml_sso.invalid_url')),
    signingCert:
      (signingCert ? '' : requiredString) ||
      (userInput.test(signingCert) ? '' : errorString),
    uploadFile: formValid ? '' : requiredString,
    metadataFile: formValid ? '' : requiredString,
    metadataUrl: metadataUrl ? '' : requiredString,
    validateUrl: formValid ? '' : requiredString,
    isValid: !!formValid
  }
}

export const validateIDPDetails = (
  formValues,
  i18nTranslate,
  oidcUser,
  setConfigValues,
  setMultiDomainErrorInfo
) => {
  const requestBody = {
    entity_id: lodashGet(formValues, 'saml_idp_config.entity_id').trim(),
    login_url: lodashGet(formValues, 'saml_idp_config.login_url').trim(),
    logout_url: lodashGet(formValues, 'saml_idp_config.logout_url').trim(),
    signing_certificate: lodashGet(
      formValues,
      'saml_idp_config.signing_certificate'
    ).trim()
  }

  const validateMethod = (resolve, reject) => {
    post(
      `/authn/v1/saml/metadata/manual/${lodashGet(
        formValues,
        'domain'
      ).trim()}`,
      requestBody,
      oidcUser.access_token
    ).then(
      (res) => {
        if (res?.data?.multi_domain) {
          const lastName = res?.data?.attribute_mapping.last_name
          const firstName = res?.data?.attribute_mapping?.first_name
          const hpeCcsAttribute =
            res?.data?.attribute_mapping?.hpe_ccs_attribute
          const email = res?.data?.attribute_mapping?.email
          const idpValue = res?.data?.attribute_mapping?.email

          if (email === 'NameId') {
            setConfigValues({
              lastName,
              firstName,
              hpeCcsAttribute,
              email
            })
          } else {
            setConfigValues({
              lastName,
              firstName,
              hpeCcsAttribute,
              email: 'Custom',
              idpValue
            })
          }
          setMultiDomainErrorInfo([])
        }
        resolve({ message: 'success' })
      },
      (error) => {
        // localize the backend error message
        const domainsMatched = error?.response?.data?.domains_matched
        if (error?.response?.data?.multi_domain)
          setMultiDomainErrorInfo(getDomainsInfo(domainsMatched))
        else {
          reject(new Error(getErrorMessage(error, i18nTranslate)))
        }
      }
    )
  }
  return validateForm(formValues, i18nTranslate, validateFields, validateMethod)
}

export const validateXMLFile = (file) => {
  const allowedExtensions = /(\.xml)$/i
  if (!allowedExtensions.exec(file.name)) {
    return false
  }
  return true
}
export const validateSessionBasedSamlAuthModal = (formValue, i18nTranslate) => {
  const requiredString = i18nTranslate('saml_sso.required')
  const errorString = i18nTranslate('saml_sso.invalid_char')
  const userInput = new RegExp(/^[^<>(){}]*$/)
  const hpeCcsAttribute = formValue
  const userInputValidate = userInput.test(hpeCcsAttribute)
  const valid = userInputValidate && hpeCcsAttribute

  return {
    hpeCcsAttribute:
      (hpeCcsAttribute ? '' : requiredString) ||
      (userInput.test(hpeCcsAttribute) ? '' : errorString),
    isValid: valid
  }
}

export const validateConfigureSettingsFields = (formValues, i18nTranslate) => {
  const requiredString = i18nTranslate('saml_sso.required')
  const errorString = i18nTranslate('saml_sso.invalid_char')
  const userInput = new RegExp(/^[^<>(){}]*$/)
  const email = lodashGet(formValues, 'attribute_mapping.email')
  const firstName = lodashGet(formValues, 'attribute_mapping.firstName')
  const lastName = lodashGet(formValues, 'attribute_mapping.lastName')
  const idpValue = lodashGet(formValues, 'attribute_mapping.idpValue')
  const hpeCcsAttribute = lodashGet(
    formValues,
    'attribute_mapping.hpeCcsAttribute'
  )
  const timeout = Number(lodashGet(formValues, 'attribute_mapping.idleTimeout'))
  const idleTimeout = !Number.isNaN(timeout) && timeout > 5 && timeout <= 1440
  const userInputValidate =
    userInput.test(firstName) &&
    userInput.test(lastName) &&
    userInput.test(idpValue) &&
    userInput.test(hpeCcsAttribute)
  const samlEncryptionValidation = formValues?.attribute_mapping
    ?.assertionEncrytion
    ? formValues?.attribute_mapping?.encryptionMethod
    : true
  const valid =
    userInputValidate &&
    email &&
    hpeCcsAttribute &&
    idleTimeout &&
    samlEncryptionValidation &&
    (lodashGet(formValues, 'attribute_mapping.email') === 'Custom'
      ? idpValue
      : true)
  return {
    email: email ? '' : i18nTranslate('saml_sso.required'),
    firstName: userInput.test(firstName) ? '' : errorString,
    lastName: userInput.test(lastName) ? '' : errorString,
    idpValue:
      (idpValue ? '' : requiredString) ||
      (userInput.test(idpValue) ? '' : errorString),
    hpeCcsAttribute:
      (hpeCcsAttribute ? '' : requiredString) ||
      (userInput.test(hpeCcsAttribute) ? '' : errorString),
    idleTimeout: idleTimeout ? '' : i18nTranslate('saml_sso.invalid_timeout'),
    encryptionMethod: samlEncryptionValidation
      ? ''
      : i18nTranslate(
          'saml_sso.saml_encryption.certificate_signing_option_validation'
        ),
    isValid: valid
  }
}

export const validateConfigureSettings = (formValues, i18nTranslate) => {
  return validateForm(
    formValues,
    i18nTranslate,
    validateConfigureSettingsFields
  )
}

export const validateRecoveryPassword = (password, username) => {
  const passwordRegex = new RegExp(
    /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=\w*[\W_]).*$/
  )
  return passwordRegex.test(password) && !password.includes(username)
}

export const validateRecoveryUser = (formValues, i18nTranslate) => {
  const passwordValid = validateRecoveryPassword(
    lodashGet(formValues, 'recovery_user.recoveryPassword'),
    lodashGet(formValues, 'recovery_user.username')
  )
  const emailRegex = RegExp('[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+') // NOSONAR
  const emailValid = emailRegex.test(
    lodashGet(formValues, 'recovery_user.pocEmail')
  )
  const formValid =
    !lodashGet(formValues, 'recovery_user.recoveryUserEnabled') ||
    (emailValid && passwordValid)
  return {
    pocEmail: emailValid ? '' : i18nTranslate('saml_sso.invalid_email'),
    recoveryPassword: passwordValid
      ? ''
      : i18nTranslate('saml_sso.invalid_password'),
    isValid: Boolean(formValid)
  }
}

export const validateGenerateCertificate = (formValues, i18nTranslate) => {
  const {
    saml_encryption: {
      key_algorithm: keyAlgorithm,
      key_size: keySize,
      signature_algorithm: signatureAlgorithm,
      common_name: commonName,
      country_code: countryCode,
      state,
      city,
      organization,
      valid_days: validDays
    }
  } = formValues || ''

  const commonNameValid = commonName && !validateCompanyName(commonName)
  const cityValid = city && validateCity(city)
  const organizationValid = organization && !validateCompanyName(organization)
  const validDaysValid = !validateNumberRange(validDays, 1, 365)

  const validationSuccess =
    keyAlgorithm &&
    keySize &&
    signatureAlgorithm &&
    countryCode &&
    state &&
    commonNameValid &&
    cityValid &&
    organizationValid &&
    validDaysValid

  return {
    key_algorithm: keyAlgorithm ? '' : i18nTranslate('saml_sso.required'),
    key_size: keySize ? '' : i18nTranslate('saml_sso.required'),
    signature_algorithm: signatureAlgorithm
      ? ''
      : i18nTranslate('saml_sso.required'),
    country_code: countryCode ? '' : i18nTranslate('saml_sso.required'),
    state: state ? '' : i18nTranslate('saml_sso.required'),
    common_name:
      (!commonName && i18nTranslate('saml_sso.required')) ||
      (validateCompanyName(commonName) &&
        i18nTranslate('saml_sso.saml_encryption.common_name_validation', {
          maxLength: 100
        })) ||
      '',
    city:
      (!city && i18nTranslate('saml_sso.required')) ||
      (!validateCity(city) &&
        i18nTranslate('saml_sso.saml_encryption.city_validation', {
          maxLength: 40
        })) ||
      '',
    organization:
      (!organization && i18nTranslate('saml_sso.required')) ||
      (validateCompanyName(organization) &&
        i18nTranslate('saml_sso.saml_encryption.organization_validation', {
          maxLength: 100
        })) ||
      '',
    valid_days:
      (validateNumberRange(validDays, 1, 365) &&
        i18nTranslate('saml_sso.saml_encryption.valid_days_validation', {
          minValue: 1,
          maxValue: 365
        })) ||
      '',
    isValid: validationSuccess
  }
}

export const nullCheck = (val) => {
  return val === '' ? null : val
}
export const validateReviewAndCreate = (
  formValues,
  i18nTranslate,
  oidcUser,
  custAccountLoaded,
  authSettingsFlagOrGLOP
) => {
  const recoveryUser =
    !isCoP() &&
    !isGLOP() &&
    lodashGet(formValues, 'recovery_user.recoveryUserEnabled')
      ? {
          username: `sso_re_${custAccountLoaded?.platform_customer_id}@${formValues.domain}`,
          password: lodashGet(formValues, 'recovery_user.recoveryPassword'),
          recovery_email: lodashGet(formValues, 'recovery_user.pocEmail')
        }
      : null
  const {
    attribute_mapping: { assertionEncrytion, encryptionMethod } = {},
    saml_encryption: {
      key_algorithm: keyAlgorithm,
      key_size: keySize,
      signature_algorithm: signatureAlgorithm,
      common_name: commonName,
      country_code: countryCode,
      state,
      city,
      organization,
      organization_unit: organizationUnit,
      valid_days: validDays,
      subject_alternative_names: subjectAlternativeNames
    }
  } = formValues || ''
  const samlEncryption =
    isCoP() && assertionEncrytion
      ? {
          encryption_method: encryptionMethod,
          regenerate_encryption: false,
          key_algorithm: keyAlgorithm,
          key_size: keySize,
          signature_algorithm: signatureAlgorithm,
          common_name: commonName,
          country_code: countryCode,
          state,
          city,
          organization,
          organization_unit: organizationUnit,
          valid_days: validDays,
          subject_alternative_names: subjectAlternativeNames
        }
      : null

  let sso_mode
  if (authSettingsFlagOrGLOP) {
    const auth_method = lodashGet(formValues, 'auth_method')
    const scope_of_sso = lodashGet(formValues, 'scope_of_sso')
    const compareResult = compareScopeOfSSO(scope_of_sso, i18nTranslate)

    // sso mode for standalone workspace
    switch (true) {
      case auth_method === STATIC:
        sso_mode = AUTHENTICATION
        break
      case auth_method === SAML:
        sso_mode = AUTHORIZATION
        break
      default:
        break
    }

    // sso mode for MSP workspace
    switch (true) {
      case auth_method === SAML && compareResult === MSP:
        sso_mode = AUTHORIZATION
        break
      case auth_method === STATIC && compareResult === MSP:
        sso_mode = AUTHENTICATION
        break
      case auth_method === STATIC && compareResult === CUSTOMER:
        sso_mode = AUTHENTICATION_TENANT
        break
      case auth_method === SAML && compareResult === CUSTOMER:
        sso_mode = authSettingsFlagOrGLOP ? AUTHORIZATION_TENANT : TENANT
        break
      case auth_method === STATIC && compareResult === MSP_CUSTOMER:
        sso_mode = AUTHENTICATION_MSP_TENANT
        break
      case auth_method === SAML && compareResult === MSP_CUSTOMER:
        sso_mode = TENANT
        break
      default:
        break
    }
  } else if (lodashGet(formValues, 'auth_method') === STATIC) {
    sso_mode = AUTHENTICATION
  } else if (
    lodashGet(formValues, 'auth_method') === SAML &&
    lodashGet(formValues, 'scope_of_sso') !== CUSTOMER
  ) {
    sso_mode = AUTHORIZATION
  } else if (lodashGet(formValues, 'scope_of_sso') === MSP) {
    sso_mode = AUTHORIZATION
  } else if (lodashGet(formValues, 'scope_of_sso') === CUSTOMER) {
    sso_mode = TENANT
  }

  const requestBody = {
    domain: lodashGet(formValues, 'domain'),
    saml_idp_config: {
      entity_id: lodashGet(formValues, 'saml_idp_config.entity_id'),
      login_url: lodashGet(formValues, 'saml_idp_config.login_url'),
      logout_url: lodashGet(formValues, 'saml_idp_config.logout_url'),
      signing_certificate: lodashGet(
        formValues,
        'saml_idp_config.signing_certificate'
      ),
      ...(isCoP() && {
        assertion_encryption: assertionEncrytion,
        encryption_properties: samlEncryption
      })
    },
    attribute_mapping: {
      email:
        lodashGet(formValues, 'attribute_mapping.email') === 'Custom'
          ? lodashGet(formValues, 'attribute_mapping.idpValue')
          : lodashGet(formValues, 'attribute_mapping.email'),
      first_name: nullCheck(
        lodashGet(formValues, 'attribute_mapping.firstName')
      ),
      last_name: nullCheck(lodashGet(formValues, 'attribute_mapping.lastName')),
      idle_session_timeout: lodashGet(
        formValues,
        'attribute_mapping.idleTimeout'
      ),
      ...(lodashGet(formValues, 'auth_method') !== STATIC && {
        hpe_ccs_attribute: lodashGet(
          formValues,
          'attribute_mapping.hpeCcsAttribute'
        )
      })
    },
    ...(!isGLOP() && { recovery_user: recoveryUser }),
    sso_mode
  }
  const validateMethod = (resolve, reject) => {
    post(
      `/authn/v1/saml/async/config`,
      requestBody,
      oidcUser.access_token
    ).then(
      (response) => {
        resolve({ taskTrackingId: response?.data?.task_tracking_id })
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, i18nTranslate)
        reject(new Error(backendErrorMessage))
      }
    )
  }

  return validateForm(formValues, i18nTranslate, null, validateMethod)
}
