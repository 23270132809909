import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, FormField } from 'grommet'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import mapValues from 'lodash/mapValues'
import { StatusGood } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ButtonGroup,
  CCSForm,
  Dropdown,
  FormInput,
  FormTextArea,
  Loader,
  Notification
} from '../../../../components'
import { get, getErrorMessage, put } from '../../../../utils/api-utils'
import {
  validateEmail,
  validateStreetAddress,
  validateZipCode
} from '../../../../utils/validation-utils'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../../utils/common-utils'

const EditAccountDetails = ({
  customerDataResponse,
  setCustomerDataResponse,
  setShowModal,
  fetchCustomerDetails,
  dataChanged,
  setDataChanged,
  isTenantAccount
}) => {
  const { t } = useTranslation(['manage', 'authn'])
  const LDFlags = useFlags()
  const { oidcUser } = useReactOidc()
  const [listOfCountries, setListOfCountries] = useState([])
  const [countries, setCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [showSuccessNotification, setShowSuccessNotification] = useState(false)
  const [loading, setLoading] = useState(false)
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const accountDetailsFields = [
    'id',
    'name',
    'email',
    'phone_number',
    'street_address',
    'street_address_2',
    'city',
    'state_or_region',
    'country',
    'zip',
    'description',
    'logo',
    'logo_filename'
  ]

  const joinCityState = (city = '', stateRegion = '') =>
    city && stateRegion ? `${city},${stateRegion}` : city

  const getFormattedResponseData = () => {
    const data = {
      ...pick(customerDataResponse, accountDetailsFields),
      city_state_or_region: joinCityState(
        customerDataResponse.city,
        customerDataResponse.state_or_region
      )
    }
    return mapValues(data, (value) => {
      return value || ''
    })
  }

  const [accountDetails, setAccountDetails] = useState(
    getFormattedResponseData()
  )

  useEffect(() => {
    get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      (response) => {
        setListOfCountries(response.data.countries)
        setCountries(response.data.countries)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }, [t, accountDetails.country])

  const getCountryName = (code) => {
    if (listOfCountries.length !== 0) {
      const countriesData = [...listOfCountries]
      const filteredCountries = countriesData.filter(
        (country) => country.code === code
      )
      return filteredCountries.length > 0 ? filteredCountries[0].name : ''
    }
    return ''
  }

  const handleDiscardChanges = () => {
    setAccountDetails(getFormattedResponseData())
  }

  const handleSaveChanges = () => {
    setLoading(true)
    const data = mapValues(accountDetails, (value) => {
      return value || null
    })
    const requestBody = {
      email: data?.email,
      company_name: data?.name,
      description: data?.description,
      address: {
        street_address: data?.street_address,
        street_address_2: data?.street_address_2,
        city: data?.city_state_or_region?.split(',')[0]?.trim(),
        state_or_region: data?.city_state_or_region?.split(',')[1]?.trim(),
        zip: data?.zip,
        country_code: data?.country
      },
      phone_number: data?.phone_number,
      customer_id: data?.id,
      customer_logo: {
        logo: data?.logo,
        logo_filename: data?.logo_filename
      }
    }
    let url = ''

    if (isTenantAccount) {
      url = `/support-assistant/v1alpha1/tenant/${oidcUser.profile.email}`
    } else {
      url = '/support-assistant/v1alpha1/customer-contact'
    }

    put(url, requestBody, oidcUser.access_token).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          setErrorMessage('')
          setCustomerDataResponse({
            ...customerDataResponse,
            city: accountDetails.city_state_or_region?.split(',')[0]?.trim(),
            state_or_region: accountDetails.city_state_or_region
              ?.split(',')[1]
              ?.trim(),
            ...omit(accountDetails, ['city_state_or_region'])
          })
          setDataChanged(true)
          setShowSuccessNotification(true)
          setLoading(false)
        }
      },
      (error) => {
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
        handleDiscardChanges()
        setLoading(false)
      }
    )
  }

  const isDataEdited = () => {
    return isEqual(getFormattedResponseData(), accountDetails)
  }

  return (
    <CCSForm
      value={accountDetails}
      errorMessage={errorMessage}
      testId="edit-customer-form"
      validate="blur"
      onSubmit={handleSaveChanges}
      style={{ width: '100%' }}
      buttons={
        <Box direction="row" justify="end" margin={{ top: 'small' }}>
          <ButtonGroup
            buttonList={[
              {
                id: 1,
                label: t('close'),
                secondary: true,
                testId: 'close-btn',
                onClick: () => {
                  setShowModal(false)
                  if (dataChanged) fetchCustomerDetails()
                }
              },
              {
                id: 2,
                label: t('discard_changes'),
                secondary: true,
                testId: 'discard-changes-btn',
                onClick: handleDiscardChanges,
                disabled: isDataEdited() || loading
              },
              {
                id: 3,
                label: t('save_changes'),
                primary: true,
                testId: 'save-changes-btn',
                type: 'submit',
                disabled: isDataEdited() || loading
              }
            ]}
            testId="two-buttons"
          />
        </Box>
      }
    >
      <>
        <Box direction="row" gap="xlarge" margin={{ right: 'large' }}>
          <Box gap="xsmall" basis="medium">
            <FormInput
              disabled
              inputType="text"
              label={t('customer_id', {
                account: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_CAPITALIZED
                )
              })}
              name="id"
              testId="account-id-field"
              value={accountDetails.id}
            />
            <FormInput
              inputType="text"
              label={t('company_name', {
                company: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_CAPITALIZED
                )
              })}
              testId="company-name-field"
              name="name"
              value={accountDetails.name}
              onChange={(event) =>
                setAccountDetails({
                  ...accountDetails,
                  name: event.target.value
                })
              }
              placeholder={t('enter_company_name')}
              disabled={loading}
              required
            />
            <FormTextArea
              inputType="text"
              label={t('description')}
              testId="company-description-field"
              name="description"
              value={accountDetails.description}
              onChange={(event) =>
                setAccountDetails({
                  ...accountDetails,
                  description: event.target.value
                })
              }
              placeholder={t('enter_description')}
              disabled={loading}
            />
            <FormInput
              inputType="text"
              label={t('company_address', {
                company: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_CAPITALIZED
                )
              })}
              testId="company-address-field"
              name="street_address"
              value={accountDetails.street_address}
              onChange={(event) =>
                setAccountDetails({
                  ...accountDetails,
                  street_address: event.target.value
                })
              }
              required
              validate={(value) => {
                if (!validateStreetAddress(value))
                  return t('authn:customer_account.max_chars_exceeded')
                return true
              }}
              placeholder={t('enter_company_address')}
              disabled={loading}
            />
            {LDFlags['glcp-msp-add-customer-field'] ? (
              <FormInput
                inputType="text"
                label={t('authn:account_details.street_address_2_lbl')}
                testId="customer-details-street-address-2-field"
                name="street_address_2"
                value={accountDetails?.street_address_2 || ''}
                onChange={(event) =>
                  setAccountDetails({
                    ...accountDetails,
                    street_address_2: event.target.value
                  })
                }
                validate={(value) => {
                  if (value && !validateStreetAddress(value))
                    return t('authn:customer_account.max_chars_exceeded')
                  return true
                }}
                placeholder={t('authn:customer_account.apt_suite_building')}
                disabled={loading}
              />
            ) : null}
            <FormInput
              inputType="text"
              testId="state-or-region-field"
              name="city_state_or_region"
              value={accountDetails.city_state_or_region}
              onChange={(event) => {
                const data = event.target.value
                const splitData = data.split(',')
                const city = splitData[0] ? splitData[0]?.trim() : ''
                const state_or_region =
                  splitData.length > 1 && splitData[1]
                    ? splitData[1]?.trim()
                    : ''
                setAccountDetails({
                  ...accountDetails,
                  city_state_or_region: data,
                  city,
                  state_or_region
                })
              }}
              placeholder={t('enter_city_state')}
              disabled={loading}
            />
            <FormInput
              inputType="text"
              label={t('zip')}
              testId="company-zip-code-field"
              name="zip"
              value={accountDetails.zip}
              onChange={(event) =>
                setAccountDetails({
                  ...accountDetails,
                  zip: event.target.value
                })
              }
              validate={(value) => {
                if (!validateZipCode(value))
                  return t('authn:customer_account.max_chars_exceeded')
                return true
              }}
              placeholder={t('enter_zip_code')}
              disabled={loading}
            />
            <FormField
              label={t('country')}
              name="country"
              required
              data-testid="edit-country-form-field"
            >
              <Dropdown
                testId="countries-dropdown"
                name="country"
                defaultValue={getCountryName(accountDetails.country)}
                options={countries}
                multiple={false}
                labelKey="name"
                value={accountDetails.country}
                valueKey={{ key: 'code', reduce: true }}
                searchPlaceholder={t('country')}
                placeholder={t('select_country')}
                emptySearchMessage={t('country_empty_search_message')}
                onSearch={(searchText) => {
                  const regexp = new RegExp(searchText, 'i')
                  setCountries(
                    listOfCountries.filter((o) => o.name.match(regexp))
                  )
                }}
                onChangeDropdown={(option) => {
                  setAccountDetails({ ...accountDetails, country: option })
                }}
                onClose={() => setCountries(listOfCountries)}
                disabled={loading}
              />
            </FormField>
          </Box>
          <Box gap="xsmall" basis="medium">
            <FormInput
              inputType="text"
              label={t('phone_number')}
              testId="company-phone-number-field"
              name="phone_number"
              value={accountDetails.phone_number}
              onChange={(event) =>
                setAccountDetails({
                  ...accountDetails,
                  phone_number: event.target.value
                })
              }
              placeholder={t('enter_phone_number')}
              disabled={loading}
            />
            <FormInput
              inputType="text"
              label={t('email_address')}
              testId="company-email-field"
              name="email"
              value={accountDetails.email}
              onChange={(event) =>
                setAccountDetails({
                  ...accountDetails,
                  email: event.target.value
                })
              }
              validate={(value) => {
                return validateEmail(value)
              }}
              placeholder={t('enter_email')}
              disabled={loading}
            />
          </Box>
        </Box>
        {showSuccessNotification && (
          <Notification
            icon={<StatusGood color="text-strong" />}
            onClose={() => setShowSuccessNotification(false)}
            testId="status-good-notification"
            text={t('account_details_updated_successfully')}
          />
        )}
        {loading && (
          <Box direction="row" align="center" justify="center" margin="medium">
            <Loader testId="edit-account-loader" />
          </Box>
        )}
      </>
    </CCSForm>
  )
}

EditAccountDetails.propTypes = {
  customerDataResponse: PropTypes.object.isRequired,
  setCustomerDataResponse: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  fetchCustomerDetails: PropTypes.func.isRequired,
  dataChanged: PropTypes.bool.isRequired,
  setDataChanged: PropTypes.func.isRequired,
  isTenantAccount: PropTypes.bool
}

EditAccountDetails.defaultProps = {
  isTenantAccount: false
}

export { EditAccountDetails }
