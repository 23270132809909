// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

const isValidNumberString = (input) => {
  if (typeof input !== 'string') {
    return false
  }
  const num = Number(input)
  return !Number.isNaN(num)
}

export const countDecimalPlaces = (number) => {
  let count = 0
  if (
    isValidNumberString(number) ||
    (Number.isFinite(number) && !Number.isNaN(number))
  ) {
    const stringifiedNumber = number.toString()
    const decimalIndex = stringifiedNumber.indexOf('.')

    if (decimalIndex !== -1) {
      count = stringifiedNumber.length - decimalIndex - 1
    }
  }

  return count
}

export const getRateRoundedValue = (value, numDecimals) => {
  const roundedValue =
    numDecimals !== undefined &&
    Number.isInteger(numDecimals) &&
    value !== undefined
      ? Math.round(value * 10 ** numDecimals) / 10 ** numDecimals
      : value || 0
  return roundedValue
}

export const ratesFormatter = ({
  numDecimals,
  currency = 'USD',
  locale,
  isFullValue,
  value
}) => {
  const getCalculatedDecimalNum = () => {
    return countDecimalPlaces(value) >= 6 ? 6 : countDecimalPlaces(value)
  }
  const roundedValue = getRateRoundedValue(value, numDecimals)
  if (numDecimals === undefined) {
    numDecimals = getCalculatedDecimalNum()
  }
  let formatter
  if (currency) {
    formatter = new Intl.NumberFormat(locale || 'en-US', {
      style: 'currency',
      currency,
      notation: isFullValue ? 'standard' : 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 2,
      minimumIntegerDigits: 1,
      maximumFractionDigits: 6
    })
    return formatter.format(roundedValue)
  }

  formatter = new Intl.NumberFormat(locale || 'en-US', {
    notation: isFullValue ? 'standard' : 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 0,
    // restrict the decimal spaces to one
    maximumFractionDigits: 2,
    minimumIntegerDigits: 6
  })
  return formatter.format(roundedValue)
}

export const getCurrencySymbol = (currency = 'USD', locale) => {
  return (0)
    .toLocaleString(locale || 'en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 6
    })
    .replace(/\d/g, '')
    .replace(/\./g, '')
    .trim()
}
