import React from 'react'
import { useHistory } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../../../components'
import { get, post } from '../../../../../../utils/api-utils'

const DuplicateOfferingWarning = ({
  onSetOpen,
  selectedKey,
  onDelayedActivation,
  setErrorMessage,
  onSubmitSuccess
}) => {
  const { t } = useTranslation(['licensing', 'common'])
  const { oidcUser } = useReactOidc()
  const history = useHistory()
  const { 'glcp-saas-delay-activation': delayedActivationSaaSFlag } = useFlags()

  const handleAddSubscriptionKey = () => {
    post(
      '/ui-doorway/ui/v1/customers/license',
      { key: selectedKey },
      oidcUser.access_token
    ).then(
      () => {
        onSubmitSuccess()
        onSetOpen(false)
      },
      (error) => setErrorMessage(error)
    )
  }

  const checkDelayedActivationStatus = () => {
    get(
      `/ui-doorway/ui/v1/license/${selectedKey}/delayedactivation/status`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        if (response?.data?.response === 'true') {
          onSetOpen(false)
          onDelayedActivation(selectedKey)
        } else {
          handleAddSubscriptionKey()
        }
      },
      (error) => setErrorMessage(error)
    )
  }

  return (
    <ModalDialog
      testId="duplicate-offering-warning-modal-dialog"
      header={
        <ModalHeader
          title={
            <Typography
              type="heading"
              level="2"
              testId="duplicate-offering-warning-title"
            >
              {t('duplicate_service_subscription')}
            </Typography>
          }
          subtitle={t('duplicate_service_subscription_desc')}
          onClose={() => onSetOpen(false)}
        />
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  label: t('add_subscription'),
                  testId: 'add-subscription-btn',
                  onClick: () =>
                    delayedActivationSaaSFlag
                      ? checkDelayedActivationStatus()
                      : handleAddSubscriptionKey()
                },
                {
                  label: t('switch_workspace'),
                  primary: true,
                  testId: 'switch-workspace-btn',
                  onClick: () => {
                    history.push('/switch-account')
                  }
                }
              ]}
              testId="duplicate-offering-warning-modal-actions"
            />
          }
        />
      }
      onClose={() => onSetOpen(false)}
    />
  )
}

DuplicateOfferingWarning.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  selectedKey: PropTypes.string.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onDelayedActivation: PropTypes.func.isRequired
}

export { DuplicateOfferingWarning }
