import {
  isIntegrationCluster,
  isProductCluster,
  isQACluster,
  isStagingCluster
} from '../../../utils/feature-flag-utils'

export const LINKS = Object.freeze({
  MANUALS: 'MANUALS',
  RELEASE_NOTE: 'RELEASE_NOTE',
  DEV_API_CONTENT: 'DEV_API_CONTENT',
  CONTACT_HPE_SUPPORT: 'CONTACT_HPE_SUPPORT',
  CASE_CREATION_URL: 'CASE_CREATION_URL',
  HPE_GREENLAKE_COMM: 'HPE_GREENLAKE_COMM',
  HPE_DEV_COMM: 'HPE_DEV_COMM',
  AIRHEADS_COMM: 'AIRHEADS_COMM',
  WELLNESS_DASH: 'WELLNESS_DASH',
  HPE_INFO_SIGHT: 'HPE_INFO_SIGHT',
  HPE_SUPP_CENTER: 'HPE_SUPP_CENTER',
  HPE_ARUBA_SUPP_PORTAL: 'HPE_ARUBA_SUPP_PORTAL',
  NETW_HDWR_TRBLSHNG_AND_REPLCMNT: 'NETW_HDWR_TRBLSHNG_AND_REPLCMNT',
  FEEDBACK: 'FEEDBACK',
  FEEDBACK_SEARCH: 'FEEDBACK_SEARCH',
  HPE_COMMUNITIES: 'HPE_COMMUNITIES',
  MODAL_LINKS: {
    ARUBA_CENTRAL: 'ARUBA_CENTRAL',
    BILLING_METERING_SUBSCRIPTIONS: 'BILLING_METERING_SUBSCRIPTIONS',
    COMPUTE_OPS_MANAGEMENT: 'COMPUTE_OPS_MANAGEMENT',
    DATA_SERVICES: 'DATA_SERVICES',
    HPE_MANAGED_CLOUD_SERVICES: 'HPE_MANAGED_CLOUD_SERVICES',
    HPE_GREENLAKE_DEV_PORTAL: 'HPE_GREENLAKE_DEV_PORTAL',
    HPE_GREENLAKE_FLEX_SOLUTIONS: 'HPE_GREENLAKE_FLEX_SOLUTIONS',
    OPSRAMP: 'OPSRAMP',
    PRIVATE_CLOUD: 'PRIVATE_CLOUD',
    WORKSPACE_USER_ONBOARDING: 'WORKSPACE_USER_ONBOARDING',
    OTHER: 'OTHER'
  }
})

const commonLinks = {
  RELEASE_NOTE:
    '/support/search#f-kmtargetl5namelist=HPE%20GreenLake%20Edge-to-Cloud%20Platform&f-kmdoctypedetails=cv66000002',
  MANUALS:
    '/support/search#f-kmtargetl5namelist=HPE%20GreenLake%20Edge-to-Cloud%20Platform&f-kmdoctypedetails=cv66000005,cv66000034,cv66000035',
  DEV_API_CONTENT:
    '/support/search#f-kmtargetl5namelist=HPE%20GreenLake%20Edge-to-Cloud%20Platform&f-kmdoctypedetails=cv66000033',
  WELLNESS_DASH: '/wellness-dashboard',
  CONTACT_HPE_SUPPORT:
    'https://www.hpe.com/us/en/contact-hpe.html#contactSupport',
  CASE_CREATION_URL: '/support/cases/create',
  HPE_GREENLAKE_COMM:
    'https://community.hpe.com/t5/hpe-greenlake/ct-p/HPE_GreenLake',
  HPE_DEV_COMM: 'https://developer.hpe.com/',
  AIRHEADS_COMM: 'https://community.arubanetworks.com/home',
  HPE_INFO_SIGHT: 'https://infosight.hpe.com/landing',
  FEEDBACK: '/support/feedback?from=support',
  FEEDBACK_SEARCH: '/support/feedback?from=search',
  HPE_COMMUNITIES: 'https://community.hpe.com/'
}

export const LINKS_PAVO = Object.freeze({
  ...commonLinks,
  NETW_HDWR_TRBLSHNG_AND_REPLCMNT:
    'https://asp-stg-develop.eks-stg-use1.getaws.arubanetworks.com/rma;autoLogin=true',
  HPE_SUPP_CENTER: 'https://itg-test-ext.support.hpe.com/connect/s',
  HPE_ARUBA_SUPP_PORTAL:
    'https://asp-stg-develop.eks-stg-use1.getaws.arubanetworks.com/home;autoLogin=true',
  CREATE_CASE: {
    ARUBA_CENTRAL: 'https://hp--test.sandbox.my.site.com/customerportal/',
    BILLING_METERING_SUBSCRIPTIONS:
      'https://h41390.www4.hpe.com/support/staging/index.html?form=greenlakesupport_test&issue=bms',
    COMPUTE_OPS_MANAGEMENT:
      'https://h41390.www4.hpe.com/support/staging/index.html?form=greenlakesupport_test&issue=com',
    DATA_SERVICES:
      'https://h41390.www4.hpe.com/support/staging/index.html?form=greenlakesupport_test&issue=ds',
    HPE_MANAGED_CLOUD_SERVICES:
      'https://h41390.www4.hpe.com/support/staging/index.html?form=greenlakesupport_test&issue=mcs',
    HPE_GREENLAKE_DEV_PORTAL:
      'https://h41390.www4.hpe.com/support/staging/index.html?form=developerportal',
    HPE_GREENLAKE_FLEX_SOLUTIONS:
      'https://h41390.www4.hpe.com/support/staging/index.html?form=greenlakesupport_test&issue=flex',
    OPSRAMP:
      'https://h41390.www4.hpe.com/support/staging/index.html?form=greenlakesupport_test&issue=opsramp',
    PRIVATE_CLOUD:
      'https://h41390.www4.hpe.com/support/staging/index.html?form=greenlakesupport_test&issue=pc',
    WORKSPACE_USER_ONBOARDING:
      'https://h41390.www4.hpe.com/support/staging/index.html?form=greenlakesupport_test&issue=wuo',
    OTHER:
      'https://h41390.www4.hpe.com/support/staging/index.html?form=greenlakesupport_test&issue=other'
  },
  VIEW_CASES: {
    ARUBA_CENTRAL: 'https://hp--test.sandbox.my.site.com/customerportal/',
    BILLING_METERING_SUBSCRIPTIONS:
      'https://itg-test-ext.support.hpe.com/connect/s/viewcases',
    COMPUTE_OPS_MANAGEMENT:
      'https://itg-test-ext.support.hpe.com/connect/s/viewcases',
    DATA_SERVICES: 'https://itg-test-ext.support.hpe.com/connect/s/viewcases',
    HPE_MANAGED_CLOUD_SERVICES:
      'https://client.greenlake.hpe.com/core/my-support-cases',
    HPE_GREENLAKE_DEV_PORTAL:
      'https://itg-test-ext.support.hpe.com/connect/s/viewcases',
    HPE_GREENLAKE_FLEX_SOLUTIONS:
      'https://client.greenlake.hpe.com/core/my-support-cases',
    OPSRAMP: 'https://itg-test-ext.support.hpe.com/connect/s/viewcases',
    PRIVATE_CLOUD: 'https://itg-test-ext.support.hpe.com/connect/s/viewcases',
    WORKSPACE_USER_ONBOARDING:
      'https://itg-test-ext.support.hpe.com/connect/s/viewcases',
    OTHER: 'https://itg-test-ext.support.hpe.com/connect/s/viewcases'
  }
})

export const LINKS_PROD = Object.freeze({
  ...commonLinks,
  NETW_HDWR_TRBLSHNG_AND_REPLCMNT:
    'https://networkingsupport.hpe.com/rma;autoLogin=true',
  HPE_SUPP_CENTER: 'https://support.hpe.com/connect/s',
  HPE_ARUBA_SUPP_PORTAL:
    'https://networkingsupport.hpe.com/home;autoLogin=true',
  CREATE_CASE: {
    ARUBA_CENTRAL: 'https://supportcase.arubanetworks.com/',
    BILLING_METERING_SUBSCRIPTIONS:
      'https://h41390.www4.hpe.com/support/index.html?form=greenlakesupport&issue=bms',
    COMPUTE_OPS_MANAGEMENT:
      'https://h41390.www4.hpe.com/support/index.html?form=greenlakesupport&issue=com',
    DATA_SERVICES:
      'https://h41390.www4.hpe.com/support/index.html?form=greenlakesupport&issue=ds',
    HPE_MANAGED_CLOUD_SERVICES:
      'https://h41390.www4.hpe.com/support/index.html?form=greenlakesupport&issue=mcs',
    HPE_GREENLAKE_DEV_PORTAL:
      'https://h41390.www4.hpe.com/support/index.html?form=developerportal',
    HPE_GREENLAKE_FLEX_SOLUTIONS:
      'https://h41390.www4.hpe.com/support/index.html?form=greenlakesupport&issue=flex',
    OPSRAMP:
      'https://h41390.www4.hpe.com/support/index.html?form=greenlakesupport&issue=opsramp',
    PRIVATE_CLOUD:
      'https://h41390.www4.hpe.com/support/index.html?form=greenlakesupport&issue=pc',
    WORKSPACE_USER_ONBOARDING:
      'https://h41390.www4.hpe.com/support/index.html?form=greenlakesupport&issue=wuo',
    OTHER:
      'https://h41390.www4.hpe.com/support/index.html?form=greenlakesupport&issue=other'
  },
  VIEW_CASES: {
    ARUBA_CENTRAL: 'https://supportcase.arubanetworks.com/',
    BILLING_METERING_SUBSCRIPTIONS:
      'https://support.hpe.com/connect/s/viewcases',
    COMPUTE_OPS_MANAGEMENT: 'https://support.hpe.com/connect/s/viewcases',
    DATA_SERVICES: 'https://support.hpe.com/connect/s/viewcases',
    HPE_MANAGED_CLOUD_SERVICES:
      'https://client.greenlake.hpe.com/core/my-support-cases',
    HPE_GREENLAKE_DEV_PORTAL: 'https://support.hpe.com/connect/s/viewcases',
    HPE_GREENLAKE_FLEX_SOLUTIONS:
      'https://client.greenlake.hpe.com/core/my-support-cases',
    OPSRAMP: 'https://support.hpe.com/connect/s/viewcases',
    PRIVATE_CLOUD: 'https://support.hpe.com/connect/s/viewcases',
    WORKSPACE_USER_ONBOARDING: 'https://support.hpe.com/connect/s/viewcases',
    OTHER: 'https://support.hpe.com/connect/s/viewcases'
  }
})

export const LINKS_MIRA = LINKS_PAVO
export const LINKS_HOKU = LINKS_PAVO

export const getLink = (link, modalLinks) => {
  let links

  switch (true) {
    case isStagingCluster():
      links = LINKS_PAVO
      break

    case isProductCluster():
      links = LINKS_PROD
      break

    case isQACluster():
      links = LINKS_MIRA
      break

    case isIntegrationCluster():
      links = LINKS_HOKU
      break

    default:
      links = LINKS_PAVO
      break
  }

  return modalLinks ? links[modalLinks][link] : links[link]
}
