import { useContext } from 'react'
import DayJS from 'react-dayjs'
import PropTypes from 'prop-types'
import { Box, NameValueList, NameValuePair, ResponsiveContext } from 'grommet'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Loader, Typography } from '../../../../components'

const UserDetailDetailsSection = ({ userDetailsData, parentLoading }) => {
  const { t } = useTranslation(['common', 'iam'])
  const {
    nameValuePair: { name }
  } = useContext(ThemeContext)
  const size = useContext(ResponsiveContext)

  return (
    <Box width="xxlarge" pad={{ top: 'medium' }}>
      <Typography type="heading" level="2">
        {t('iam:igc_users.details_section_main_title')}
      </Typography>
      <Box pad={{ top: 'medium' }} direction="column" justify="start">
        <Box width="large" align="start" fill gap="medium">
          {parentLoading ? (
            <Loader testId="user-details-name-value-pair-loader" />
          ) : (
            <NameValueList
              nameProps={{ width: 'small' }}
              pairProps={{ direction: size === 'xsmall' ? 'column' : 'row' }}
            >
              <NameValuePair
                name={
                  <Typography
                    testId="user-details-first-name-text"
                    type="text"
                    {...name}
                  >
                    {t('iam:igc_users.details_section_first_name_title')}
                  </Typography>
                }
              >
                <Typography testId="user-details-first-name" type="text">
                  {userDetailsData?.givenName || '--'}
                </Typography>
              </NameValuePair>

              <NameValuePair
                name={
                  <Typography
                    testId="user-details-last-name-text"
                    type="text"
                    {...name}
                  >
                    {t('iam:igc_users.details_section_last_name_title')}
                  </Typography>
                }
              >
                <Typography testId="user-details-last-name" type="text">
                  {userDetailsData?.familyName || '--'}
                </Typography>
              </NameValuePair>

              <NameValuePair
                name={
                  <Typography
                    testId="user-details-email-text"
                    type="text"
                    {...name}
                  >
                    {t('iam:igc_users.details_section_email_title')}
                  </Typography>
                }
              >
                <Typography testId="user-details-email" type="text">
                  {userDetailsData?.userName || '--'}
                </Typography>
              </NameValuePair>

              <NameValuePair
                name={
                  <Typography
                    testId="user-details-verified-text"
                    type="text"
                    {...name}
                  >
                    {t('iam:igc_users.details_section_verified_title')}
                  </Typography>
                }
              >
                <Typography testId="user-details-verified" type="text">
                  {userDetailsData?.verified === true
                    ? t('iam:verified_status_yes')
                    : t('iam:verified_status_no')}
                </Typography>
              </NameValuePair>

              <NameValuePair
                name={
                  <Typography
                    testId="user-details-joined-date-text"
                    type="text"
                    {...name}
                  >
                    {t('iam:igc_users.details_section_joined_date_title')}
                  </Typography>
                }
              >
                <Typography testId="user-details-joined-date" type="text">
                  {userDetailsData?.created ? (
                    <DayJS data-testid="user-details-joined-date" format="LL">
                      {userDetailsData?.created}
                    </DayJS>
                  ) : (
                    '--'
                  )}
                </Typography>
              </NameValuePair>

              <NameValuePair
                name={
                  <Typography
                    testId="user-details-last-login-text"
                    type="text"
                    {...name}
                  >
                    {t('iam:igc_users.details_section_last_login_title')}
                  </Typography>
                }
              >
                <Typography testId="user-details-last-login" type="text">
                  {userDetailsData?.lastLogin ? (
                    <DayJS data-testid="user-details-last-login" format="LL">
                      {userDetailsData?.lastLogin || '--'}
                    </DayJS>
                  ) : (
                    '--'
                  )}
                </Typography>
              </NameValuePair>
            </NameValueList>
          )}
        </Box>
      </Box>
    </Box>
  )
}

UserDetailDetailsSection.propTypes = {
  userDetailsData: PropTypes.object.isRequired,
  parentLoading: PropTypes.bool
}

UserDetailDetailsSection.defaultProps = {
  parentLoading: false
}

export { UserDetailDetailsSection }
