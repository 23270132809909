import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, FormField, SelectMultiple } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import isEmpty from 'lodash/isEmpty'
import { CircleInformation } from 'grommet-icons'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ButtonGroup,
  Loader
} from '../../../../../../components'
import { get } from '../../../../../../utils/api-utils'
import {
  REVIEW_REQUEST_TYPE,
  UPDATE_REQUEST_TYPE_WITH_APPROVAL
} from '../../constants'

const SubmitForReviewModal = ({
  onSetOpen,
  handleFinish,
  formValues,
  isEdit
}) => {
  const { t } = useTranslation(['manage', 'common'])
  const { oidcUser } = useReactOidc()
  const [reviewersLoading, setReviewersLoading] = useState(false)
  const [reviewerOptions, setReviewerOptions] = useState([])
  const [defaultReviewerOptions, setDefaultReviewerOptions] = useState([])
  const [selectedReviewers, setSelectedReviewers] = useState([])
  const [showEmptyError, setShowEmptyError] = useState('')
  const [message, setMessage] = useState('')

  const handleCancel = () => {
    onSetOpen(false)
  }

  const handleSubmit = () => {
    if (!isEmpty(selectedReviewers)) {
      onSetOpen(false)
      const type = isEdit
        ? UPDATE_REQUEST_TYPE_WITH_APPROVAL
        : REVIEW_REQUEST_TYPE
      handleFinish(formValues, selectedReviewers, type, false)
    } else {
      setShowEmptyError(true)
      setMessage(t('notification.approval.no_reviewers_added_error'))
    }
  }

  useEffect(() => {
    setReviewersLoading(true)
    // API call to get list of approvers
    get(
      '/notifications-svc/ui/v1alpha1/approver',
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        if (response?.data?.length > 0) {
          const reviewerArray = []
          response?.data?.map((value) => {
            if (value?.id !== oidcUser?.profile?.email) {
              reviewerArray.push({
                value: value?.id,
                label: `${value?.first_name} ${value?.last_name} <${value?.id}> ${value?.bu}`
              })
            }
            return reviewerArray
          })
          setReviewerOptions(reviewerArray)
          setDefaultReviewerOptions(reviewerArray)
        }
        setReviewersLoading(false)
      },
      () => {
        setReviewersLoading(false)
      }
    )
  }, [oidcUser.access_token, t]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalDialog
      position="center"
      onClickOutside={() => {
        onSetOpen(false)
      }}
      testId="submit-for-review-dialog"
      onEsc={() => {
        onSetOpen(false)
      }}
      header={
        <ModalHeader
          title={
            <Box gap="xsmall">
              <Typography
                type="heading"
                level="1"
                testId="submit-for-approval-title"
              >
                {t('notification.approval.submit_for_approval_title')}
              </Typography>
              <Typography
                type="paragraph"
                size="small"
                testId="submit-for-approval-subtitle"
              >
                {t('notification.approval.submit_for_approval_subtitle')}
              </Typography>
            </Box>
          }
        />
      }
      content={
        <Box margin={{ top: 'small' }}>
          {reviewersLoading ? (
            <Box direction="row" align="center" justify="center">
              <Loader testId="reviewer-loader" />
            </Box>
          ) : (
            <FormField
              name="addReviewers"
              data-testid="add-reviewer-select-formfield"
              label={t('notification.approval.form_field_reviewer_label')}
              margin={{ bottom: 'large' }}
            >
              <SelectMultiple
                data-testid="add-reviewer-select-field"
                id="reviewer"
                name="reviewer"
                placeholder={t('notification.approval.reviewer_placeholder')}
                searchPlaceholder={t(
                  'notification.approval.placeholder_reviewer_search'
                )}
                options={reviewerOptions || []}
                value={selectedReviewers}
                labelKey="label"
                valueKey={{ key: 'value', reduce: true }}
                onChange={({ value: nextValue }) => {
                  setSelectedReviewers(nextValue)
                }}
                onSearch={(text) => {
                  // The line below escapes regular expression special characters:
                  // [ \ ^ $ . | ? * + ( )
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    '\\$&'
                  )

                  // Create the regular expression with modified value which
                  // handles escaping special characters. Without escaping special
                  // characters, errors will appear in the console
                  const exp = new RegExp(escapedText, 'i')
                  setReviewerOptions(
                    defaultReviewerOptions.filter((o) => exp.test(o?.label))
                  )
                }}
                onClose={() => setReviewerOptions(defaultReviewerOptions)}
              />
            </FormField>
          )}
          {showEmptyError && (
            <Box
              margin={{ top: 'large' }}
              direction="row"
              background="validation-critical"
              pad="small"
              round="xsmall"
            >
              <Typography
                type="text"
                size="medium"
                testId="account-error-text"
                icon={<CircleInformation />}
              >
                {message}
              </Typography>
            </Box>
          )}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-add-reviewer-btn',
                  onClick: handleCancel
                },
                {
                  id: 1,
                  label: t('submit'),
                  primary: true,
                  testId: 'confirm-add-reviewer-button',
                  onClick: handleSubmit
                }
              ]}
              testId="submit-for-review-buttons"
            />
          }
        />
      }
      onClose={handleCancel}
      width="medium"
    />
  )
}
export { SubmitForReviewModal }

SubmitForReviewModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  handleFinish: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired
}
