// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useCallback, useEffect, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import isNull from 'lodash/isNull'
import { useFlags } from 'launchdarkly-react-client-sdk'
import dayjs from 'dayjs'
import { Notification } from 'grommet'

import { get, patch, post } from '../../../utils/api-utils'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'
import { isSameDomain } from '../notifications-dashboard/utils'

const BannerNotification = () => {
  const [viewData, setViewData] = useState({})
  const [totalNotification, setTotalNotification] = useState(0)
  const LDFlags = useFlags()
  const timeMultiplier = 1

  const custAccountLoaded = getCustomerAccount()
  const { oidcUser } = useReactOidc()

  const getStatus = (severityEnum) => {
    let element
    switch (severityEnum) {
      case 0:
        element = 'info'
        break
      case 1:
        element = 'critical'
        break
      case 2:
        element = 'warning'
        break
      case 3:
        element = 'normal'
        break
      default:
        element = 'unknown'
    }
    return element
  }

  const fetchNotifications = useCallback(() => {
    if (oidcUser && oidcUser?.access_token && custAccountLoaded !== null) {
      let sortBy = 'createdAt'
      if (LDFlags['glcp-notifications-feature-edit-istanbul']) {
        sortBy = 'updatedAt'
      }
      get(
        `/notifications-svc/ui/v1alpha1/notifications?channel=BANNER&sort=${sortBy}&dismissed=false&state=1`,
        {},
        oidcUser?.access_token
      ).then(
        (response) => {
          if (response?.data?.count > 0) {
            setViewData(response?.data?.items[0])
            setTotalNotification(response?.data?.items?.length)
          } else {
            setViewData({})
            setTotalNotification(0)
          }
        },
        (error) => {
          setViewData({})
          console.log(error)
        }
      )
    }
  }, [oidcUser]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let refreshTimer = null
    async function polling(timeInterval) {
      if (timeInterval <= 32) {
        fetchNotifications()
        refreshTimer = setTimeout(() => {
          polling(timeInterval * 2)
        }, 60000 * timeInterval)
      }
    }
    polling(timeMultiplier)
    return () => {
      clearTimeout(refreshTimer)
      setViewData({})
      setTotalNotification(0)
    }
  }, [fetchNotifications])

  const patchDismissed = () => {
    let requestBody = []
    if (LDFlags['glcp-notifications-feature-edit-istanbul']) {
      requestBody = [
        {
          op: 'replace',
          path: '/isDismissed',
          value: 'true'
        },
        {
          op: 'replace',
          path: '/version',
          value: viewData?.updated_version
        }
      ]
    } else {
      requestBody = [
        {
          op: 'replace',
          path: '/isDismissed',
          value: 'true'
        }
      ]
    }
    patch(
      `/notifications-svc/ui/v1alpha1/notifications/${viewData?.id}`,
      requestBody,
      oidcUser.access_token
    ).then(
      () => {
        setViewData({})
        fetchNotifications()
      },
      (error) => {
        console.log(error)
      }
    )
  }

  useEffect(() => {
    let refreshTimer = null
    let refreshPollTimer = null
    let isComponentMounted = true
    async function polling() {
      const lastInteractionTime = sessionStorage.getItem('lastInteractionTime')

      const diff = dayjs().diff(lastInteractionTime)

      if (diff < 60000 * 3 && !isNull(lastInteractionTime)) {
        refreshPollTimer = setTimeout(() => {
          polling()
        }, 60000 * 3 - diff)
      } else if (isNull(lastInteractionTime) || diff >= 60000 * 3) {
        if (
          oidcUser?.access_token &&
          oidcUser?.access_token !== null &&
          custAccountLoaded !== null &&
          sessionStorage.getItem('callUpdateTimeline') === 'yes'
        ) {
          sessionStorage.setItem('callUpdateTimeline', 'no')
          post(
            `/notifications-svc/ui/v1alpha1/notifications/update-timeline`,
            {},
            oidcUser?.access_token,
            undefined,
            undefined
          ).then(
            (response) => {
              sessionStorage.setItem('lastInteractionTime', dayjs())

              if (isComponentMounted) {
                sessionStorage.setItem('callUpdateTimeline', 'yes')
                if (response?.data?.next) {
                  sessionStorage.removeItem('lastInteractionTime')
                  polling()
                } else {
                  fetchNotifications()
                  polling()
                }
              } else if (response?.data?.next) {
                sessionStorage.removeItem('lastInteractionTime')
              }
            },
            (error) => {
              console.log(error)
              if (isComponentMounted) {
                sessionStorage.setItem('callUpdateTimeline', 'yes')
                sessionStorage.setItem('lastInteractionTime', dayjs())
                polling()
              }
            }
          )
        } else if (sessionStorage.getItem('callUpdateTimeline') === 'no') {
          refreshTimer = setTimeout(() => {
            if (isComponentMounted) {
              polling()
              sessionStorage.setItem('callUpdateTimeline', 'yes')
            }
          }, 5000)
        }
      }
    }
    polling()
    return () => {
      clearTimeout(refreshTimer)
      isComponentMounted = false
      clearTimeout(refreshPollTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isNull(sessionStorage.getItem('callUpdateTimeline'))) {
      sessionStorage.setItem('callUpdateTimeline', 'yes')
    }
  }, [])

  return (
    <>
      {totalNotification > 0 && (
        <Notification
          global
          status={getStatus(viewData?.severity)}
          onClose={() => {
            patchDismissed()
            setTotalNotification(0)
          }}
          message={viewData.summary ? viewData.summary : ''}
          actions={
            viewData.url && [
              {
                label: viewData.url_text ? viewData.url_text : viewData.url,
                href: viewData.url,
                target: isSameDomain(viewData?.url, 'target'),
                rel: isSameDomain(viewData?.url, 'rel')
              }
            ]
          }
          data-testid="banner-notification-test-id"
        />
      )}
    </>
  )
}

export { BannerNotification }
