import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { Box } from 'grommet'
import { Alert } from 'grommet-icons'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ButtonGroup
} from '../../../../../../components'
import { CREATE_REQUEST_TYPE, UPDATE_REQUEST_TYPE } from '../../constants'

const ConfirmationModal = ({ onSetOpen, handleFinish, formValues, isEdit }) => {
  const { t } = useTranslation(['manage', 'common'])
  const boldText = t('notification.create_bold_message')
  const boldTextWarning = t('notification.create_warning_bold')

  const handleCancel = () => {
    onSetOpen(false)
  }

  const handleSave = () => {
    onSetOpen(false)
    if (isEdit) {
      handleFinish(formValues, [], UPDATE_REQUEST_TYPE, true)
    } else {
      handleFinish(formValues, [], CREATE_REQUEST_TYPE, true)
    }
  }

  return (
    <>
      <ModalDialog
        position="center"
        onClickOutside={() => {
          onSetOpen(false)
        }}
        testId="confirmation-dialog"
        onEsc={() => {
          onSetOpen(false)
        }}
        content={
          <Box gap="medium" margin={{ top: 'medium' }}>
            <Box gap="xsmall" direction="row">
              <Box margin={{ top: 'small' }}>
                <Alert color="status-critical" size="xlarge" />
              </Box>
              <Box round="small" margin={{ left: 'medium' }}>
                <Typography type="text" size="medium">
                  {t('notification.create_confirmation_message1')}
                </Typography>
                <Typography type="text" size="medium" margin={{ top: 'small' }}>
                  <Trans
                    i18nKey="notification.create_confirmation_message2"
                    t={t}
                    boldText={boldText}
                  >
                    <strong>{{ boldText }}</strong>
                  </Trans>
                </Typography>
                <Typography
                  type="text"
                  size="medium"
                  margin={{ top: 'xsmall' }}
                >
                  <Trans
                    i18nKey="notification.create_confirmation_message3"
                    t={t}
                    boldText={boldTextWarning}
                  >
                    <strong>{{ boldTextWarning }}</strong>
                  </Trans>
                </Typography>
              </Box>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('cancel'),
                    default: true,
                    testId: 'cancel-confirm-btn',
                    onClick: handleCancel
                  },
                  {
                    id: 1,
                    label: t('Yes'),
                    primary: true,
                    testId: 'confirm-create-button',
                    onClick: handleSave
                  }
                ]}
                testId="create-confirmation-buttons"
              />
            }
          />
        }
        header={
          <ModalHeader
            title={
              <Typography size="xxlarge" type="text" weight="bold">
                {t('notification.confirm_action')}
              </Typography>
            }
          />
        }
        onClose={handleCancel}
        width="large"
      />
    </>
  )
}
export { ConfirmationModal }

ConfirmationModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  handleFinish: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired
}
