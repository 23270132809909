import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  Typography,
  Button,
  ModalDialog,
  ModalFooter
} from '../../../../components'
import { doDisableMFA } from '../utils/api-utils'

export const DisableMFAModal = ({
  handleCloseDisable,
  handleDisableSuccess
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const { oidcUser } = useReactOidc()

  const doDisableAction = () => {
    // call disable api
    doDisableMFA(oidcUser.access_token, handleDisableSuccess, t)
  }

  return (
    <>
      <ModalDialog
        content={
          <Box>
            <Box gap="small">
              <Typography type="heading" level="1" testId="disable-mfa-title">
                {t('security.disable_mfa')}
              </Typography>
              <Typography
                type="paragraph"
                size="large"
                testId="disable-mfa-subtitle"
              >
                {t('security.disable_mfa_desc')}
              </Typography>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <Box direction="row" gap="medium">
                <Button
                  default
                  label={t(`common:cancel`)}
                  onClick={handleCloseDisable}
                  testId="cancel-disable-mfa-btn"
                />
                <Button
                  primary
                  type="submit"
                  label={t('security.disable_mfa')}
                  testId="disable-mfa-btn"
                  onClick={doDisableAction}
                />
              </Box>
            }
          />
        }
        onClose={handleCloseDisable}
        testId="disable-mfa-modal-dialog"
        width="medium"
      />
    </>
  )
}

DisableMFAModal.propTypes = {
  handleCloseDisable: PropTypes.func.isRequired,
  handleDisableSuccess: PropTypes.func.isRequired
}
