// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  CheckBoxGroup,
  FormField,
  RadioButtonGroup,
  CheckBox,
  Text
} from 'grommet'
import isEqual from 'lodash/isEqual'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Anchor,
  ButtonGroup,
  CCSForm,
  FormInput,
  FormTextArea,
  ModalDialog,
  ModalHeader,
  Notification,
  Search,
  Typography
} from '../../../components'
import {
  validateEmail,
  validateReportName
} from '../../../utils/validation-utils'
import { get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import {
  LinkToReporting,
  ModalFailureMessage
} from '../../../utils/reporting-utils'

import GeneratingReportsModal from './GeneratingReportsModal'

const ExportModal = ({
  setShowModal,
  title,
  description,
  columns: { header: columnsHeader, data: columnsData },
  includedEntries,
  filteredDevices,
  onClickGenerateReport,
  loading,
  setLoading,
  checkProgressReports
}) => {
  const LDFlags = useFlags()
  const { 'glcp-reportfw-dashboard': reportingDashboardFlag } = LDFlags
  const { t } = useTranslation(['common', 'reporting_dashboard'])
  const { oidcUser } = useReactOidc()
  const [selectedDetails, setSelectedDetails] = useState({
    report_name: '',
    description: '',
    email: '',
    all_entries: filteredDevices === 0,
    columns: columnsData.map((col) => col?.value)
  })
  const allDetailsColumn = { label: t('export_modal.all_details'), value: '' }
  const [viewColumns, setViewColumns] = useState([
    allDetailsColumn,
    ...columnsData
  ])
  const [generatingReports, setGeneratingReports] = useState([])
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [showGenerateWarning, setShowGenerateWarning] = useState(false)

  // To display validation error
  const [showValidationWarning, setShowValidationWarning] = useState('')

  // modals
  const [selectDetailsModal, setSelectDetailsModal] = useState(true) // modal where we select the email, data, columns
  const [generatingReportsModal, setGeneratingReportsModal] = useState(false)

  const [apiError, setApiError] = useState(null)
  const isAllColumnsSelected = () => {
    return isEqual(
      columnsData.map((col) => col?.value),
      selectedDetails.columns
    )
  }

  const includedEntriesOptions =
    filteredDevices === 0
      ? [
          {
            label: includedEntries?.all_entries_label,
            value: 'all_entries'
          }
        ]
      : [
          {
            label: includedEntries?.searched_entries_label,
            value: 'searched_entries'
          },
          {
            label: includedEntries?.all_entries_label,
            value: 'all_entries'
          }
        ]

  const handleOnClick = () => {
    setLoading(true)
    if (checkProgressReports) {
      // check for in progress reports
      // if in progress reports < 3, generate report, else show warning
      get('/ui-doorway/ui/v1/csv-reports', {}, oidcUser.access_token).then(
        (repsonse) => {
          setGeneratingReports(repsonse?.data)
          if (repsonse?.data?.length < 3) {
            onClickGenerateReport(selectedDetails)
          } else {
            setShowGenerateWarning(true)
            setLoading(false)
          }
          setShowErrorNotification(null)
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
          setLoading(false)
        }
      )
    } else {
      onClickGenerateReport(
        selectedDetails,
        setApiError,
        setShowValidationWarning
      )
    }
  }

  return (
    <>
      {selectDetailsModal && (
        <ModalDialog
          header={
            <ModalHeader
              title={
                <Typography testId="header-title" type="heading" level="2">
                  {title}
                </Typography>
              }
              onClose={() => setShowModal(false)}
            />
          }
          content={
            <Box gap="medium" flex={false}>
              <Typography size="medium" testId="dialog-content" type="text">
                {description}
              </Typography>
              <CCSForm
                value={selectedDetails}
                onSubmit={handleOnClick}
                buttons={
                  <Box margin={{ top: 'medium' }}>
                    <ButtonGroup
                      buttonList={[
                        {
                          id: 2,
                          label: reportingDashboardFlag
                            ? t(
                                'reporting_dashboard:create_report.create_report_title'
                              )
                            : t('export_modal.generate_report'),
                          primary: true,
                          testId: 'generate-report-btn',
                          type: 'submit',
                          disabled: loading,
                          style: { whiteSpace: 'nowrap' }
                        },
                        {
                          id: 1,
                          label: t('cancel'),
                          default: true,
                          testId: 'cancel-btn',
                          onClick: () => setShowModal(false),
                          style: { whiteSpace: 'nowrap' }
                        }
                      ]}
                      testId="export-modal-buttons"
                      justifyGroup="start"
                    />
                  </Box>
                }
                errorMessage={
                  showGenerateWarning ? (
                    <Typography
                      type="text"
                      size="xsmall"
                      textId="edit-rrp-message1"
                    >
                      <Trans
                        i18nKey="export_modal.max_reports_exceeded_message"
                        t={t}
                      >
                        <Anchor
                          label={t('export_modal.view_generating_reports')}
                          weight="bold"
                          href="#"
                          onClick={() => {
                            setSelectDetailsModal(false)
                            setGeneratingReportsModal(true)
                          }}
                          testId="view-generating-reports-anchor"
                        />
                      </Trans>
                    </Typography>
                  ) : (
                    ''
                  )
                }
                testId="export-data-modal-form"
                validate="blur"
              >
                <Box gap="small">
                  {reportingDashboardFlag && !checkProgressReports && (
                    <Box>
                      <FormInput
                        inputType="text"
                        label={t(
                          'reporting_dashboard:recipient_details.report_name'
                        )}
                        name="report_name"
                        required
                        maxLength="100"
                        testId="report-name"
                        validate={(value) => validateReportName(value, t)}
                        onChange={(event) =>
                          setSelectedDetails({
                            ...selectedDetails,
                            report_name: event.target.value
                          })
                        }
                      />
                      <Box alignSelf="start">
                        <Typography
                          type="text"
                          color="text-weak"
                          size="xsmall"
                          testId="report-name-summary-count"
                        >
                          {100 - (selectedDetails?.report_name?.length || 0)}
                          {t(
                            'reporting_dashboard:reporting_export_modal.characters_left'
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box>
                    <FormInput
                      inputType="text"
                      label={
                        reportingDashboardFlag && !checkProgressReports
                          ? t(
                              'reporting_dashboard:reporting_export_modal.recipient'
                            )
                          : t('export_modal.email_address')
                      }
                      name="email"
                      required
                      testId="email-address-form"
                      validate={(value) => validateEmail(value)}
                      labelHelper={
                        !reportingDashboardFlag ||
                        (reportingDashboardFlag && checkProgressReports)
                          ? t('export_modal.email_address_label_helper')
                          : null
                      }
                      placeholder={
                        !reportingDashboardFlag ||
                        (reportingDashboardFlag && checkProgressReports)
                          ? t('export_modal.email_address_placeholder')
                          : null
                      }
                      onChange={(event) =>
                        setSelectedDetails({
                          ...selectedDetails,
                          email: event.target.value
                        })
                      }
                    />
                    {reportingDashboardFlag && !checkProgressReports && (
                      <Typography
                        type="paragraph"
                        size="xsmall"
                        testId="email-note"
                      >
                        {t(
                          'reporting_dashboard:reporting_export_modal.recipient_note'
                        )}
                      </Typography>
                    )}
                  </Box>
                  {reportingDashboardFlag && !checkProgressReports && (
                    <Box>
                      <FormTextArea
                        label={t(
                          'reporting_dashboard:recipient_details.description'
                        )}
                        name="description"
                        testId="report-description"
                        labelHelper={t(
                          'reporting_dashboard:recipient_details.description_helper'
                        )}
                        maxLength="256"
                        onChange={(event) =>
                          setSelectedDetails({
                            ...selectedDetails,
                            description: event.target.value
                          })
                        }
                      />
                      <Box alignSelf="start">
                        <Typography
                          type="text"
                          size="xsmall"
                          color="text-weak"
                          testId="summary-count"
                        >
                          {256 - (selectedDetails?.description?.length || 0)}
                          {t(
                            'reporting_dashboard:reporting_export_modal.characters_left'
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <FormField
                    data-testid="included-entries-form-field"
                    label="Included Entries"
                    name="included_entries"
                  >
                    <RadioButtonGroup
                      name="all_entries"
                      options={includedEntriesOptions}
                      value={
                        selectedDetails?.all_entries
                          ? 'all_entries'
                          : 'searched_entries'
                      }
                      onChange={(event) =>
                        setSelectedDetails({
                          ...selectedDetails,
                          all_entries: event.target.value === 'all_entries'
                        })
                      }
                    />
                  </FormField>
                  <FormField
                    data-testid="columns-form-field"
                    label={columnsHeader}
                    name="columns"
                    required
                    info={
                      <Typography testId="column-form-text" type="text">
                        <Trans
                          i18nKey="export_modal.columns_info"
                          t={t}
                          values={{
                            selectedCount:
                              selectedDetails?.columns?.length || 0,
                            totalCount: columnsData?.length || 0
                          }}
                          components={[<strong />]}
                        />
                      </Typography>
                    }
                    {...(showGenerateWarning && {
                      margin: { bottom: 'medium' }
                    })}
                  >
                    <Box pad={{ horizontal: 'small', top: 'small' }}>
                      <Search
                        handleCustomSearch={(value) => {
                          setViewColumns(
                            [...columnsData, allDetailsColumn].filter((col) =>
                              col?.label
                                ?.toLowerCase()
                                .includes(value.toLowerCase())
                            )
                          )
                        }}
                        testId="search-field"
                        placeholder={t('export_modal.search')}
                      />
                      <Box
                        height={{ max: 'small' }}
                        overflow="auto"
                        margin={{ top: 'small' }}
                        data-testid="check-boxes-layer"
                      >
                        {viewColumns.find(
                          (col) => col.label === t('export_modal.all_details')
                        ) && (
                          <CheckBox
                            label={t('export_modal.all_details')}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setSelectedDetails({
                                  ...selectedDetails,
                                  columns: columnsData.map((col) => col?.value)
                                })
                              } else {
                                setSelectedDetails({
                                  ...selectedDetails,
                                  columns: []
                                })
                              }
                            }}
                            {...(selectedDetails?.columns?.length &&
                            !isAllColumnsSelected()
                              ? { indeterminate: true, checked: false }
                              : {})}
                            {...(isAllColumnsSelected() && { checked: true })}
                          />
                        )}
                        <CheckBoxGroup
                          name="columns"
                          options={viewColumns.filter(
                            (col) => col.label !== t('export_modal.all_details')
                          )}
                          margin={{ top: '0' }}
                          onChange={({ value }) => {
                            setSelectedDetails({
                              ...selectedDetails,
                              columns: value
                            })
                          }}
                          value={selectedDetails?.columns}
                        />
                      </Box>
                    </Box>
                  </FormField>
                  {reportingDashboardFlag && !checkProgressReports && (
                    <LinkToReporting t={t} />
                  )}
                  {reportingDashboardFlag &&
                    !checkProgressReports &&
                    apiError && (
                      <ModalFailureMessage t={t} errorCode={apiError} />
                    )}
                  {showValidationWarning && (
                    <Notification
                      backgroundColor="status-warning"
                      onClose={() => setShowValidationWarning('')}
                      testId="filter-warning"
                      text={
                        <Text color="text-strong">{showValidationWarning}</Text>
                      }
                      type="inline"
                      pad={{ horizontal: 'small', vertical: 'xsmall' }}
                    />
                  )}
                </Box>
              </CCSForm>
              {showErrorNotification}
            </Box>
          }
          onClose={() => setShowModal(false)}
          position="right"
          testId="export-modal-dialog"
          height="100%"
          width="medium"
        />
      )}
      {generatingReportsModal && (
        <GeneratingReportsModal
          reportsData={generatingReports}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

ExportModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // for columns
  columns: PropTypes.shape({
    header: PropTypes.string.isRequired,
    // array of oblects having label, value
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  // for included entries filed labels
  includedEntries: PropTypes.shape({
    all_entries_label: PropTypes.string.isRequired,
    searched_entries_label: PropTypes.string.isRequired
  }).isRequired,
  filteredDevices: PropTypes.number.isRequired,
  //  Handler that gets invoked when generate report is clicked
  onClickGenerateReport: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  checkProgressReports: PropTypes.bool
}

ExportModal.defaultProps = {
  loading: false,
  setLoading: () => {},
  checkProgressReports: true
}

export default ExportModal
