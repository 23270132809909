import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, CheckBox, Menu } from 'grommet'
import { CaretDownFill, CircleInformation } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { Notification } from '../../../../components/notification/Notification'
import { put } from '../../../../utils/api-utils'
import { eventPropType } from '../../utils/data'

import Spacer from './Spacer'

const CheckAll = ({
  checked,
  setChecked,
  events,
  mutateEvents,
  clearCache
}) => {
  const { t } = useTranslation('unified_wellness')
  const { oidcUser } = useReactOidc()
  const [showBulkActionsAlert, setShowBulkActionsAlert] = useState()
  const allSelected =
    events.length > 0 && events.every((event) => checked.has(event.uuid))
  const someSelected =
    events.length > 0 &&
    events.some((event) => checked.has(event.uuid)) &&
    events.some((event) => !checked.has(event.uuid))
  // Before each check all action, we clear the checked list in case there are checked
  // events which have since been hidden by filters or search
  const onAll = () => {
    const newChecked = new Set()
    events.forEach((event) => {
      newChecked.add(event.uuid)
    })
    setChecked(newChecked)
  }
  const onNone = () => {
    setChecked(new Set())
  }
  const onUnread = () => {
    const newChecked = new Set()
    events.forEach((event) => {
      if (!event.read) {
        newChecked.add(event.uuid)
      }
    })
    setChecked(newChecked)
  }
  const onRead = () => {
    const newChecked = new Set()
    events.forEach((event) => {
      if (event.read) {
        newChecked.add(event.uuid)
      }
    })
    setChecked(newChecked)
  }
  const onFlagged = () => {
    const newChecked = new Set()
    events.forEach((event) => {
      if (event.flag) {
        newChecked.add(event.uuid)
      }
    })
    setChecked(newChecked)
  }
  const onUnflagged = () => {
    const newChecked = new Set()
    events.forEach((event) => {
      if (!event.flag) {
        newChecked.add(event.uuid)
      }
    })
    setChecked(newChecked)
  }
  const onArchived = () => {
    const newChecked = new Set()
    events.forEach((event) => {
      if (event.archive) {
        newChecked.add(event.uuid)
      }
    })
    setChecked(newChecked)
  }
  // helper function since all these bulk actions are somewhat complicated and mostly the same
  const bulkTagActionCreator = (tag, value) => () => {
    if (!checked.size) {
      const timer = setTimeout(() => setShowBulkActionsAlert(undefined), 10000)
      setShowBulkActionsAlert(timer)
    }
    // We need to clear the swr cache any time we change user data to prevent stale data being shown
    clearCache()
    mutateEvents(
      put(
        '/wellness/v2beta1/bulk-tags',
        {
          uuids: events.flatMap((event) =>
            checked.has(event.uuid) ? [event.uuid] : []
          ),
          tags: {
            [tag]: value
          }
        },
        oidcUser.access_token
      ),
      events.map((event) => ({
        ...event,
        [tag]: checked.has(event.uuid) ? value : event[tag]
      }))
    )
  }
  const markAsRead = bulkTagActionCreator('read', true)
  const markAsUnread = bulkTagActionCreator('read', false)
  const flag = bulkTagActionCreator('flag', true)
  const unflag = bulkTagActionCreator('flag', false)
  const archive = bulkTagActionCreator('archive', true)
  const unarchive = bulkTagActionCreator('archive', false)
  const onChange = () => {
    if (!allSelected && !someSelected) {
      onAll()
    } else {
      onNone()
    }
  }

  useEffect(() => {
    if (checked.size) {
      if (showBulkActionsAlert) {
        clearTimeout(showBulkActionsAlert)
      }
      setShowBulkActionsAlert(undefined)
    }
    return () => {
      if (showBulkActionsAlert) {
        clearTimeout(showBulkActionsAlert)
      }
    }
  }, [checked, showBulkActionsAlert, setShowBulkActionsAlert])

  return (
    <>
      <Box direction="row" margin={{ vertical: 'medium' }}>
        <CheckBox
          onChange={onChange}
          checked={allSelected}
          indeterminate={!allSelected && someSelected}
          data-testid="events-checkall-btn"
        />
        <Menu
          data-testid="events-checkall-menu"
          items={[
            { label: t('filter.all'), onClick: onAll },
            { label: t('filter.unread'), onClick: onUnread },
            { label: t('filter.read'), onClick: onRead },
            { label: t('filter.flagged'), onClick: onFlagged },
            { label: t('filter.unflagged'), onClick: onUnflagged },
            { label: t('filter.archived'), onClick: onArchived }
          ]}
          plain
          icon={<CaretDownFill />}
        />
        <Spacer />
        <Menu
          data-testid="events-bulk-action-menu"
          label={t('details.actions')}
          items={[
            { label: t('bulk_actions.mark_as_read'), onClick: markAsRead },
            { label: t('bulk_actions.mark_as_unread'), onClick: markAsUnread },
            { label: t('bulk_actions.flag'), onClick: flag },
            { label: t('bulk_actions.unflag'), onClick: unflag },
            { label: t('bulk_actions.archive'), onClick: archive },
            { label: t('bulk_actions.unarchive'), onClick: unarchive }
          ]}
          kind="toolbar"
        />
      </Box>
      {showBulkActionsAlert && (
        <Box pad={{ bottom: 'medium' }}>
          <Notification
            icon={
              <Box align="center" justify="center" height="100%">
                <CircleInformation />
              </Box>
            }
            testId="bulk-action-notification"
            text={t('bulk_actions.none_selected')}
            onClose={() => setShowBulkActionsAlert(false)}
            type="inline"
            backgroundColor="status-unknown"
          />
        </Box>
      )}
    </>
  )
}

CheckAll.propTypes = {
  checked: PropTypes.instanceOf(Set).isRequired,
  setChecked: PropTypes.func.isRequired,
  events: PropTypes.arrayOf(eventPropType).isRequired,
  mutateEvents: PropTypes.func.isRequired,
  clearCache: PropTypes.func.isRequired
}

export default CheckAll
