import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Button, ModalDialog, ModalHeader } from '../../../../components'

import ViewDomainClaimContent from './ViewDomainClaimContent'

const ViewDomainClaimTXTRecord = ({
  domainClaimTXT,
  onSetOpen,
  domainName
}) => {
  const { t } = useTranslation('iam', 'common')

  const onClose = () => {
    onSetOpen(false)
  }

  return (
    <ModalDialog
      testId="view-domain-text-record-modal"
      width="large"
      header={
        <ModalHeader
          title={
            <Heading
              level={2}
              margin={{ top: 'none', bottom: 'medium' }}
              size="medium"
              emphasis
              data-testid="domain-text-record-title"
            >
              {t('domains.view_text_modal_title')}
            </Heading>
          }
        />
      }
      content={
        <ViewDomainClaimContent
          domainName={domainName}
          domainClaimTXT={domainClaimTXT}
        />
      }
      footer={
        <Button
          label={t('common:close')}
          primary
          testId="close-modal-btn"
          margin={{ left: 'auto' }}
          onClick={onClose}
        />
      }
      onClose={onClose}
    />
  )
}

ViewDomainClaimTXTRecord.propTypes = {
  domainClaimTXT: PropTypes.string.isRequired,
  onSetOpen: PropTypes.func.isRequired,
  domainName: PropTypes.string.isRequired
}

export default ViewDomainClaimTXTRecord
