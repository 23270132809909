import React, { useState, useCallback, useContext, lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CheckBox, Form, FormField, Header } from 'grommet'
import { CircleInformation } from 'grommet-icons'
import PropTypes from 'prop-types'

import { getCustomerAccount } from '../../../../utils/feature-flag-utils'
import {
  Notification,
  ButtonGroup,
  Typography,
  Anchor
} from '../../../../components'
import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'
import { CaseCreationContext } from '../context/CaseCreationContext'

import ServiceElement from './ServiceElement'
import Contact from './Contact'

const CaseFormElements = lazy(() => import('./CaseFormElements'))

const CaseForm = ({ caseCreationCallback }) => {
  const { t } = useTranslation(['support_cases'])
  const custAccountLoaded = getCustomerAccount()
  const workspaceName = custAccountLoaded?.company_name || ''
  const [service, setService] = useState({})
  const [busy, setBusy] = useState(false)
  const [showCaseFormElements, setShowCaseFormElements] = useState(false)
  const [errorInfo, setErrorInfo] = useState({})
  const [renderContact, setRenderContact] = useState(false)
  const [showConfirmCheckBox, setShowConfirmCheckBox] = useState(false)
  const caseFormContextData = useContext(CaseCreationContext)
  const {
    loggedInUserContact,
    setSelectedPrimaryContact,
    setSelectedAlternateContact
  } = caseFormContextData

  const onSubmit = useCallback(
    ({ value }) => {
      setBusy(true)
      if (value) {
        const currentDate = new Date()
        const currentTime = currentDate.getTime().toString()
        caseCreationCallback({
          data: {
            caseNumber: currentTime,
            caseId: currentTime
          }
        })
        setBusy(false)
      }
    },
    [caseCreationCallback]
  )

  const onReset = useCallback(() => {
    setService({})
    setErrorInfo({})
    setShowCaseFormElements(false)
    setRenderContact(false)
    setShowConfirmCheckBox(false)
    setSelectedPrimaryContact(loggedInUserContact)
    setSelectedAlternateContact({})
  }, [
    loggedInUserContact,
    setSelectedPrimaryContact,
    setSelectedAlternateContact
  ])

  const renderContactAndConfirmCheckBox = useCallback((data) => {
    if ('renderContact' in data) {
      if (data.renderContact) {
        setRenderContact(data.renderContact)
      } else {
        setShowConfirmCheckBox(false)
        setRenderContact(false)
      }
    }
    if ('showConfirmCheckbox' in data) {
      setShowConfirmCheckBox(data.showConfirmCheckbox)
    }
  }, [])

  const handleCallback = useCallback(
    ({ error, data }) => {
      if (error) {
        setErrorInfo(error)
        if (!error.showFormElements) {
          setShowCaseFormElements(error.showFormElements)
        }
      } else if (data) {
        // Check if errorInfo is not empty
        if (Object.keys(errorInfo).length !== 0) {
          setErrorInfo({})
        }
        if (data.selectedService) {
          setService(data.selectedService)
          setShowCaseFormElements(true)
        }
        renderContactAndConfirmCheckBox(data)
      }
    },
    [errorInfo, renderContactAndConfirmCheckBox]
  )

  return (
    <Box
      gap="medium"
      margin={{ horizontal: 'auto' }}
      pad={{ horizontal: 'auto' }}
    >
      <Header pad={{ top: 'large' }}>
        <Typography level="1" type="heading" testId="case-form-heading">
          {t('support_cases:common.hpe_greenlake_support')}
        </Typography>
      </Header>

      <Notification
        status="info"
        type="inline"
        testId="status-workspace-info-notification"
        text={
          <>
            {t('support_cases:create_case.workspace_notification', {
              workspace: workspaceName
            })}{' '}
            <Anchor
              href="/switch-account"
              label={t('support_cases:create_case.change_workspace')}
              testId="anchor-workspace-info-notification"
            />
          </>
        }
      />
      <Form
        messages={{
          required: t('support_cases:common.this_is_required_field')
        }}
        onSubmit={onSubmit}
        onReset={onReset}
      >
        <ServiceElement service={service} caseFormCallBack={handleCallback} />
        {showCaseFormElements && (
          <Suspense fallback={<LazyLoading />}>
            <CaseFormElements
              serviceId={service.id}
              caseFormCallBack={handleCallback}
            />
          </Suspense>
        )}
        {errorInfo?.showError && (
          <Box margin={{ top: 'xsmall' }}>
            <Notification
              icon={<CircleInformation />}
              status="critical"
              text={errorInfo.message}
              type="inline"
              testId="case-form-error-notification"
            />
          </Box>
        )}
        {renderContact && (
          <Contact
            showContactPreference={service.show_contact_preferences}
            caseFormCallBack={handleCallback}
          />
        )}
        {showConfirmCheckBox && (
          <Box margin={{ top: 'large' }}>
            <FormField name="case-submit-confirm-check" required>
              <CheckBox
                pad="none"
                id="case-submit-confirm-check"
                data-testid="case-submit-confirm-check"
                name="case-submit-confirm-check"
                label={t('support_cases:create_case.case_submit_confirm_check')}
              />
            </FormField>
          </Box>
        )}

        {service.type !== 'Link' && (
          <Box
            direction="row"
            gap="small"
            margin={{ top: showConfirmCheckBox ? 'small' : 'large' }}
          >
            <ButtonGroup
              buttonList={[
                {
                  label: t('support_cases:common.submit'),
                  primary: true,
                  disabled: errorInfo?.showError,
                  testId: 'case-form-submit',
                  type: 'submit',
                  busy
                },
                {
                  label: t('support_cases:common.clear'),
                  testId: 'case-form-reset',
                  type: 'reset'
                }
              ]}
              testId="case-form-button-group"
            />
          </Box>
        )}
      </Form>
    </Box>
  )
}

CaseForm.propTypes = {
  caseCreationCallback: PropTypes.func
}

CaseForm.defaultProps = {
  caseCreationCallback: () => {}
}

export default CaseForm
