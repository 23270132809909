// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { Box, TextInput } from 'grommet'
import { Search } from 'grommet-icons'
import { useHistory } from 'react-router-dom'

import { buildClickUri } from '../ResultListTemplateHelper/clickUriUtils'
import { Loader } from '../../../../components'

import RecentSearches from './templates/RecentSearches'
import PopularSearches from './templates/PopularSearches'
import SuggestedSearches from './templates/SuggestedSearches'
import TopHits from './templates/TopHits'

const SearchBoxRenderer = ({
  controller,
  recentQueriesListController,
  instantResultsController,
  engine
}) => {
  const { t } = useTranslation('support_hub_search')
  const [state, setState] = useState(controller.state)
  const [popularSearchState, setPopularSearchState] = useState(controller.state)
  const [instantResultsState, setInstantResultsState] = useState(
    recentQueriesListController.state
  )
  const inputRef = useRef('')
  const router = useHistory()
  const [suggestions, setSuggestions] = useState([])
  const [isSuggestionVisible, setIsSuggestionVisible] = useState(true)
  const [loading] = useState(false)

  const [recentQueries, setRecentQueries] = useState(() => {
    const existingRecentQueries =
      JSON.parse(localStorage.getItem('latestRecentQueries')) || []
    return existingRecentQueries
  })

  useEffect(() => {
    const unsubscribeController = controller.subscribe(() => {
      setState(controller.state)
      setPopularSearchState(controller.state)
    })

    const unsubscribeInstantResutsController =
      instantResultsController.subscribe(() => {
        setInstantResultsState(instantResultsController.state)
      })

    return () => {
      unsubscribeController()
      unsubscribeInstantResutsController()
    }
  }, [controller, instantResultsController])

  // Recent queries local storage
  useEffect(() => {
    const existingRecentQueries =
      JSON.parse(localStorage.getItem('latestRecentQueries')) || []

    // Combine queries from the recent query controller and existing queries from local storage
    const combinedQueries = [
      ...new Set([
        ...recentQueriesListController.state.queries,
        ...existingRecentQueries
      ])
    ]
    const latestRecentQueries = combinedQueries.slice(0, 5)

    // Update state and local storage only if there's a change
    setRecentQueries((prevRecentQueries) => {
      const recentQueriesString = JSON.stringify(latestRecentQueries)
      if (JSON.stringify(prevRecentQueries) !== recentQueriesString) {
        localStorage.setItem('latestRecentQueries', recentQueriesString)
        return latestRecentQueries
      }
      return prevRecentQueries
    })
  }, [recentQueriesListController.state.queries])

  useEffect(() => {
    // Keep input value synchronized with controller's state
    if (controller.state.value !== inputRef.current) {
      inputRef.current = controller.state.value
    }
  }, [controller.state.value])

  function isEnterKey(e) {
    return e.key === 'Enter'
  }

  // Update suggestions when allSuggestions change
  useEffect(() => {
    const allSuggestions = () => {
      if (!isSuggestionVisible) return []
      if (loading) {
        return [
          {
            value: 'loading',
            label: (
              <Box align="center" pad="large" height="small">
                <Loader testId="suggestion-loader" size="36px" />
              </Box>
            )
          }
        ]
      }
      if (inputRef.current === '') {
        return [
          ...recentQueries.map((query, index) => ({
            value: query,
            label: <RecentSearches query={query} index={index} t={t} />,
            onClick: () => controller.selectSuggestion(query)
          })),
          ...popularSearchState.suggestions.map((query, index) => ({
            value: query.rawValue,
            label: (
              <PopularSearches query={query.rawValue} index={index} t={t} />
            ),
            onClick: () => controller.selectSuggestion(query.rawValue)
          }))
        ]
      }
      return [
        ...state.suggestions.map((query, index) => ({
          value: query.rawValue,
          label: (
            <SuggestedSearches query={query.rawValue} index={index} t={t} />
          ),
          onClick: () => controller.selectSuggestion(query.rawValue)
        })),
        ...instantResultsState.results.map((result, index) => ({
          value: 'instantResult',
          label: (
            <TopHits result={result} index={index} engine={engine} t={t} />
          ),
          onClick: () => {
            const clickUri = buildClickUri(result)
            window.open(clickUri, '_blank')
          }
        }))
      ]
    }
    setSuggestions(allSuggestions())
  }, [
    isSuggestionVisible, // Depend on this state to control visibility
    recentQueries,
    popularSearchState.suggestions,
    state.suggestions,
    instantResultsState.results,
    t,
    engine,
    loading,
    controller
  ])

  const [debouncedValue, setDebouncedValue] = useState('')

  useEffect(() => {
    const handler = setTimeout(() => {
      if (debouncedValue.length >= 3) {
        // Fetch top hits using instant search controller
        instantResultsController.updateQuery(debouncedValue)
      }
    }, 600)

    return () => {
      clearTimeout(handler)
    }
  }, [debouncedValue, instantResultsController])

  const specialCharacterHandler = (inputValue) => {
    // Check if there are at least 3 letters in a row
    const consecutiveLetters = inputValue.match(/[a-zA-Z]{3,}/)
    // Cut all letters and numbers and leave only special characters to check length of the special characters
    const specialCharacterCount = inputValue.replace(/[a-zA-Z0-9]/g, '').length

    let shouldShowSuggestions = true

    // Check if there are special characters and check if there are less than 3 letters in a row
    if (
      specialCharacterCount > 0 &&
      (!consecutiveLetters ||
        consecutiveLetters[0].length < 3 ||
        inputValue === '')
    ) {
      shouldShowSuggestions = false
    }

    setIsSuggestionVisible(shouldShowSuggestions)
    controller.updateText(inputValue)
  }

  const handleInputChange = (e) => {
    const { value } = e.target
    inputRef.current = value
    specialCharacterHandler(value)
    setDebouncedValue(value) // Set debounced value for triggering instant search
  }

  const handleOnKeyDown = (e) => {
    if (e.key === 'Tab') {
      setIsSuggestionVisible(false)
    }
    if (isEnterKey(e)) {
      controller.submit()
      setIsSuggestionVisible(false)
      e.target.blur()
    }
  }

  const handleOnSuggestionSelect = (e) => {
    const selectedSuggestion = e.suggestion
    setIsSuggestionVisible(false)
    if (selectedSuggestion.onClick) {
      selectedSuggestion.onClick()
    }
  }

  const handleOnFocus = () => {
    specialCharacterHandler(inputRef.current)
    instantResultsController.updateQuery(inputRef.current)
  }

  useEffect(() => {
    if (state?.redirectTo) {
      const { redirectTo, value, analytics } = state
      const data = JSON.stringify({ value, analytics })
      sessionStorage.setItem('coveo_standalone_search_box_data', data)
      router.push(`${redirectTo}#q=${encodeURIComponent(state.value)}`)
    }
  }, [state, router])

  if (!state) {
    return null
  }

  return (
    <Box fill align="center" justify="start">
      <TextInput
        data-testid="standalone-search-box-input-search"
        type="search"
        value={inputRef.current}
        onChange={handleInputChange}
        onKeyDown={handleOnKeyDown}
        placeholder={t('search_placeholder')}
        onFocus={handleOnFocus}
        icon={<Search aria-hidden="true" />}
        onSuggestionSelect={handleOnSuggestionSelect}
        suggestions={suggestions}
      />
    </Box>
  )
}

SearchBoxRenderer.propTypes = {
  controller: PropTypes.shape({
    state: PropTypes.object,
    subscribe: PropTypes.func,
    updateText: PropTypes.func,
    submit: PropTypes.func,
    selectSuggestion: PropTypes.func,
    clear: PropTypes.func,
    showSuggestions: PropTypes.func
  }).isRequired,
  recentQueriesListController: PropTypes.shape({
    state: PropTypes.object,
    subscribe: PropTypes.func,
    updateText: PropTypes.func,
    submit: PropTypes.func,
    selectSuggestion: PropTypes.func,
    clear: PropTypes.func,
    showSuggestions: PropTypes.func
  }).isRequired,
  instantResultsController: PropTypes.shape({
    state: PropTypes.object,
    subscribe: PropTypes.func,
    updateQuery: PropTypes.func,
    submit: PropTypes.func,
    selectSuggestion: PropTypes.func,
    clear: PropTypes.func,
    showSuggestions: PropTypes.func,
    isLoading: PropTypes.bool
  }).isRequired,
  engine: PropTypes.object.isRequired
}

export default SearchBoxRenderer
