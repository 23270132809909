import dayjs from 'dayjs'
import { Box } from 'grommet'
import { colors } from 'grommet-theme-hpe'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

const Chart = ({ data, timeScale, type }) => {
  const timeAxisFormatter = (tick) => {
    let formattedDate = ''
    switch (timeScale) {
      case 'hour':
        formattedDate = dayjs(tick).format('h:mm A')
        break
      case 'day':
        formattedDate = dayjs(tick).format('M/DD')
        break
      default:
        formattedDate = dayjs(tick).format('MM/DD/YYYY')
    }
    return formattedDate
  }
  return (
    <Box height="small" width="100%" pad={{ vertical: 'small' }}>
      <ResponsiveContainer width="100%" height="100%">
        {type === 'bar' ? (
          <BarChart data={data}>
            <CartesianGrid />
            <XAxis
              dataKey="time"
              tickLine={false}
              tickFormatter={timeAxisFormatter}
              interval={data.length > 24 ? 'preserveStartEnd' : 0}
              tickMargin={10}
              style={{
                fontSize: 12
              }}
            />
            <YAxis
              style={{
                fontSize: 12
              }}
            />
            <Tooltip
              labelFormatter={(label) => timeAxisFormatter(label)}
              formatter={(value, name) => [
                value,
                `${name.charAt(0).toUpperCase()}${name.slice(1)}`
              ]}
            />
            <Bar dataKey="critical" fill={colors['status-critical'].light} />
            <Bar dataKey="warning" fill={colors['status-warning'].light} />
            <Bar dataKey="notice" fill={colors.blue.light} />
          </BarChart>
        ) : (
          <LineChart data={data}>
            <CartesianGrid />
            <XAxis
              dataKey="time"
              tickLine={false}
              tickFormatter={timeAxisFormatter}
              interval={data.length > 24 ? 'preserveStartEnd' : 0}
              tickMargin={10}
              padding={{ right: 25, left: 25 }}
              style={{
                fontSize: 12
              }}
            />
            <YAxis
              style={{
                fontSize: 12
              }}
            />
            <Tooltip
              labelFormatter={(label) => timeAxisFormatter(label)}
              formatter={(value, name) => [
                value,
                `${name.charAt(0).toUpperCase()}${name.slice(1)}`
              ]}
            />
            <Line dataKey="critical" stroke={colors['status-critical'].light} />
            <Line dataKey="warning" stroke={colors['status-warning'].light} />
            <Line dataKey="notice" stroke={colors.blue.light} />
          </LineChart>
        )}
      </ResponsiveContainer>
    </Box>
  )
}

Chart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      notice: PropTypes.number,
      critical: PropTypes.number,
      warning: PropTypes.number
    })
  ).isRequired,
  timeScale: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['bar', 'line']).isRequired
}

export default Chart
