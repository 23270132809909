import { React, Suspense, lazy } from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'
import { isCoP } from '../../../../utils/feature-flag-utils'

const SsoConnectionWizard = lazy(() =>
  import('./sso-connection/SsoConnectionWizard')
)
const ViewSSODetails = lazy(() => import('./pages/ViewSsoDetails'))
const EditSSODetails = lazy(() => import('./pages/EditSsoDetails'))
const SetupComplete = lazy(() => import('./pages/SetupComplete'))
const SsoConnectionSuccess = lazy(() => import('./pages/SsoConnectionSuccess'))

const SsoRouter = () => {
  const { path } = useRouteMatch()
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route exact path={`${path}/sso-connection/download/:domain`}>
          {isCoP() ? <SsoConnectionSuccess /> : <SetupComplete />}
        </Route>
        <Route exact path={`${path}/sso-connection`}>
          <SsoConnectionWizard />
        </Route>
        <Route exact path={`${path}/:domain/details`}>
          <ViewSSODetails />
        </Route>
        <Route exact path={`${path}/:domain/edit`}>
          <EditSSODetails />
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  )
}

export default SsoRouter
