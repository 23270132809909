/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useHistory } from 'react-router-dom'
import { Box } from 'grommet'
import { Group, Trash } from 'grommet-icons'
import { isEmpty } from 'lodash'

import {
  ActionButton,
  Anchor,
  Typography,
  Button,
  DataTable,
  NoDataInfo
} from '../../../../components'
import { get, getErrorMessage, post } from '../../../../utils/api-utils'
import Markdown from '../../../commoncomponents/Markdown'
import AssignGroupsToUserModal from '../../commoncomponents/AssignGroupsToUserModal'
import RemoveUserFromGroupConfirmation from '../../commoncomponents/RemoveUserFromGroupConfirmation'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

const DetailsUserGroup = ({ user, setNotificationInfo }) => {
  const history = useHistory()
  const { t } = useTranslation(['iam', 'common'])
  const { userId } = useParams()
  const ref = useRef(false)
  const [showRemoveGroupDialog, setShowRemoveGroupDialog] = useState(false)
  const [userGroups, setUserGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState()
  const [noAvailableGroups, setNoAvailableGroups] = useState(false)
  const [loading, setLoading] = useState(true)
  const [showAssignGroupModal, setShowAssignGroupModal] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)

  // for pagination; post MS1 work
  // const [itemsPerPage, setItemsPerPage] = useState(20)
  // const [page, setPage] = useState(1)

  // const getPageIndexInfo = () => {
  //   const total = userGroups?.length || 0
  //   console.log('pagination ->', total)
  //   if (total > 0) {
  //     const startIndex = 1 + (page - 1) * itemsPerPage
  //     const endIndex = itemsPerPage * page < total ? itemsPerPage * page : total
  //     return `Showing ${startIndex}-${endIndex} of ${total}` // require pagination
  //   }
  //   return ''
  // }

  useEffect(() => {
    ref.current = true
    setLoading(true)
    const getUserGroups = async () => {
      await get(
        `/identity/v2alpha1/scim/v2/extensions/Users/${userId}/groups`
      ).then(
        (response) => {
          if (!ref.current) return
          if (response?.data?.Resources) {
            setUserGroups(response?.data?.Resources)
          }
          setLoading(false)
        },
        (error) => {
          if (!ref.current) return
          setNotificationInfo(getErrorMessage(error, t), 'error')
          setLoading(false)
        }
      )
    }
    getUserGroups()
    return () => {
      ref.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount])

  useEffect(() => {
    ref.current = true
    setLoading(true)
    const getAllUserGroups = async () => {
      await get(`/identity/v2alpha1/scim/v2/Groups`).then(
        (response) => {
          if (!ref.current) return
          if (isEmpty(response?.data?.Resources)) {
            setNoAvailableGroups(true)
          }
          setLoading(false)
        },
        (error) => {
          if (!ref.current) return
          setNotificationInfo(getErrorMessage(error, t), 'error')
          setLoading(false)
        }
      )
    }
    getAllUserGroups()
    return () => {
      ref.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateUserGroups = async (groups, operation) => {
    setLoading(true)
    const groupIds = groups?.map((group) => group?.id)
    const userIds = [user?.id]
    try {
      const response = await post(
        '/internal-platform-tenant-ui/v2/bulk-users',
        {
          user_ids: userIds,
          group_ids: groupIds,
          operation
        }
      )
      if (response?.data?.failed?.length) {
        // TODO Partial success scenario
        const message = (
          <Box>
            {response?.data?.failed[0]?.error_response?.map((error) => {
              const group = error?.group
              const backendErrorMsg = getErrorMessage(error?.details, t)
              return (
                <Markdown key={error?.group}>
                  {t('iam:users.add_users_to_groups_partial_success', {
                    group,
                    backendErrorMsg
                  })}
                </Markdown>
              )
            })}
          </Box>
        )
        setNotificationInfo(message, 'warning')

        // TODO: update list of partial success
      } else {
        // Success scenario
        if (operation === 'remove') {
          setNotificationInfo(
            <Markdown>
              {t('iam:igc_users.remove_user_from_group_notif_msg', {
                username: user?.displayName || user?.userName,
                groupName: groups[0]?.displayName
              })}
            </Markdown>,
            'info',
            t('iam:igc_users.remove_user_from_group_notif_title')
          )
        }
        if (operation === 'add') {
          const msg = (
            <Markdown>
              {groupIds?.length > 1
                ? t(
                    'iam:igc_users.assign_to_group_user_details_notif_msg_plural',
                    {
                      username: user?.displayName || user?.userName,
                      groupNumber: groupIds?.length
                    }
                  )
                : t(
                    'iam:igc_users.assign_to_group_user_details_notif_msg_singular',
                    {
                      username: user?.displayName,
                      groupName: groups[0]?.displayName
                    }
                  )}
            </Markdown>
          )
          const title =
            groupIds?.length > 1
              ? t(
                  'iam:igc_users.assign_to_group_user_details_notif_title_plural'
                )
              : t(
                  'iam:igc_users.assign_to_group_user_details_notif_title_singular'
                )
          setNotificationInfo(msg, 'info', title)
        } else {
          setUserGroups((state) => {
            return state?.filter(
              (item) => item?.displayName !== groups[0]?.displayName
            )
          })
        }
      }
      setRefreshCount((prevCount) => prevCount + 1)
    } catch (error) {
      // Error scenario
      setNotificationInfo(
        getErrorMessage(
          error?.response?.data?.failed?.length
            ? error?.response?.data?.failed[0]
            : error,
          t
        ),
        'error'
      )
    } finally {
      setShowAssignGroupModal(false)
      setShowRemoveGroupDialog(false) // ensures the loading is set to false regardless of the outcome
      setLoading(false)
    }
  }

  const removeGroup = (group) => {
    updateUserGroups([group], 'remove')
  }

  const addToGroup = (groupsToAdd) => {
    // TODO check if BE remove duplicate items between groups and userGroups
    updateUserGroups(groupsToAdd, 'add')
  }

  const userGroupColumns = [
    {
      property: 'name',
      header: t('iam:igc_users.add_user_add_to_group_name_column'),
      align: 'start',
      render: (datum) => {
        // TODO link to group?
        return (
          <Anchor
            href="#"
            label={datum?.displayName}
            testId="user-groups-anchor"
            onClick={() => {
              history.push(`/manage-account/organization/groups/${datum?.id}`)
            }}
          />
        )
      }
    },
    {
      property: '',
      sortable: false,
      header: '',
      align: 'end',
      render: (datum) => {
        return (
          <VisibilityWrapper
            rbac={{
              permission: 'identity.user-group-members.update'
            }}
          >
            <ActionButton
              actions={[
                {
                  icon: <Trash />,
                  onClick: (group) => {
                    setSelectedGroup(group)
                    setShowRemoveGroupDialog(true)
                  }
                }
              ]}
              datum={datum}
              testId="user-group-action-btn"
            />
          </VisibilityWrapper>
        )
      }
    }
  ]

  const getNoDataInfo = () => {
    let noDataInfo
    if (noAvailableGroups) {
      noDataInfo = (
        <NoDataInfo
          icon={<Group size="large" />}
          gap="small"
          gapTitleSubtitle="small"
          action={
            <VisibilityWrapper
              rbac={{
                permission: 'identity.user-groups.create'
              }}
            >
              <Button
                label={t('iam:users.create_user_group')}
                onClick={() => {
                  history.push('/manage-account/organization/groups', {
                    createGroup: true
                  })
                }}
                testId="create-user-group-btn"
                secondary
              />
            </VisibilityWrapper>
          }
          title={t('iam:igc_users.add_user_assign_to_group_no_groups_to_add')}
          subtitle={t('iam:igc_users.add_user_assign_to_group_create_group')}
          testId="no-available-group"
        />
      )
    } else if (userGroups?.length === 0) {
      noDataInfo = (
        <NoDataInfo
          icon={<Group size="large" />}
          gap="small"
          gapTitleSubtitle="small"
          action={
            <VisibilityWrapper
              rbac={{
                permission: 'identity.user-group-members.update'
              }}
            >
              <Button
                label={t('iam:igc_users.add_user_assign_to_group_btn')}
                onClick={() => {
                  setShowAssignGroupModal(true)
                }}
                testId="add-to-group-btn"
                secondary
              />
            </VisibilityWrapper>
          }
          title={t(
            'iam:igc_users.add_user_assign_to_group_no_group_assignments'
          )}
          subtitle={
            <VisibilityWrapper
              rbac={{
                permission: 'identity.user-group-members.update'
              }}
            >
              {t(
                'iam:igc_users.add_user_assign_to_group_add_user_to_local_user_group'
              )}
            </VisibilityWrapper>
          }
          testId="no-assigned-group"
        />
      )
    }
    return noDataInfo
  }

  return (
    <>
      <Box
        gap="medium"
        pad={{ top: 'medium', vertical: 'medium' }}
        width={isEmpty(userGroups) ? 'xxlarge' : 'large'}
      >
        <Box justify="start" gap="small">
          <Box
            direction="row"
            justify="between"
            gap="medium"
            pad={{ top: 'large' }}
          >
            <Typography
              type="heading"
              level="2"
              testId="user-details-group_title"
            >
              {t('iam:igc_users.add_user_assign_to_group_title')}
            </Typography>
            {!loading && userGroups?.length > 0 && (
              <VisibilityWrapper
                rbac={{
                  permission: 'identity.user-group-members.update'
                }}
              >
                <Button
                  label={t('iam:igc_users.add_user_assign_to_group_btn')}
                  secondary
                  testId="add-user-to-group-btn"
                  onClick={() => {
                    setShowAssignGroupModal(true)
                  }}
                />
              </VisibilityWrapper>
            )}
          </Box>
          {userGroups?.length !== 0 || loading ? (
            <Box width="large" margin={{ top: 'small' }}>
              <DataTable
                // post MS1 work
                // pagination={{
                //   totalItems: userGroups?.length || 0,
                //   itemsPerPage,
                //   setItemsPerPage,
                //   page,
                //   setPage,
                //   rowDropDownLabel: 'Items per page', // require localization
                //   pageIdxInfo: getPageIndexInfo(),
                //   rowCountOptions: [20, 50, 100]
                // }}
                grid={{
                  data: userGroups,
                  columns: userGroupColumns
                }}
                loading={loading}
                testId="user-groups-datatable"
              />
            </Box>
          ) : (
            <Box pad={{ top: 'medium' }}>{getNoDataInfo()}</Box>
          )}
        </Box>
      </Box>
      {showAssignGroupModal && (
        <AssignGroupsToUserModal
          onSetOpen={setShowAssignGroupModal}
          onSubmit={addToGroup}
          user={user}
          setNotificationInfo={setNotificationInfo}
        />
      )}
      {showRemoveGroupDialog && (
        <RemoveUserFromGroupConfirmation
          loading={loading}
          onSetOpen={setShowRemoveGroupDialog}
          user={user}
          userGroupToRemove={selectedGroup}
          handleRemove={removeGroup}
        />
      )}
    </>
  )
}

DetailsUserGroup.propTypes = {
  user: PropTypes.object.isRequired,
  // TODO array of what?
  setNotificationInfo: PropTypes.func.isRequired
}

export default DetailsUserGroup
