import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Button,
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../components'
import { del, getErrorMessage } from '../../../../utils/api-utils'
import Markdown from '../../../commoncomponents/Markdown'
import { roleAssignmentEnum } from '../constants'

const RemoveUserModal = ({
  onSetOpen,
  userToRemove,
  setNotificationInfo,
  retrievedData
}) => {
  const history = useHistory()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['iam', 'common'])
  const [loading, setLoading] = useState(false)

  const closeModal = () => {
    onSetOpen(false)
  }

  const userName = userToRemove?.displayName || userToRemove?.userName
  const workspaceName = userToRemove?.workspaceName
  const hasInheritedRoleAssignment = retrievedData.some(
    (roleAssignment) => roleAssignment.source !== roleAssignmentEnum?.DIRECT
  )
  const isOwnAccount = userToRemove?.userName === oidcUser?.profile?.email

  const removeUser = async () => {
    setLoading(true)
    // eslint-disable-next-line prettier/prettier
    del(
      `/workspaces/v2alpha1/workspaces/${userToRemove?.workspaceId}/users/${userToRemove?.id}`
    ).then(
      () => {
        history.push('/manage-account/identity/users', {
          notificationText: t(
            'iam:igc_users.remove_workspace_user_success_msg',
            {
              userName,
              workspaceName
            }
          ),
          notificationTitle: t('iam:igc_users.remove_user_success_title')
        })
      },
      (error) => {
        setLoading(false)
        closeModal()
        setNotificationInfo(
          getErrorMessage(error, t),
          'error',
          t('iam:igc_users.remove_user_failed_msg')
        )
      }
    )
  }

  const getModalContent = () => {
    let content
    if (isOwnAccount) {
      content = (
        <Typography type="text" margin={{ top: 'medium' }}>
          {t('iam:igc_users.remove_workspace_user_own_account_not_allowed_msg')}
        </Typography>
      )
    } else if (loading) {
      content = (
        <Box
          pad={{ vertical: 'small', horizontal: 'large' }}
          justify="center"
          align="center"
          fill
        >
          <Loader
            testId="remove-user-loader"
            label={t('common:removing')}
            orientation="horizontal"
          />
        </Box>
      )
    } else {
      content = (
        <Box width="medium" margin={{ top: 'medium' }}>
          <Markdown>
            {isEmpty(userToRemove?.sourceId) && !hasInheritedRoleAssignment
              ? t('iam:igc_users.remove_from_workspace_msg', {
                  userName,
                  workspaceName
                })
              : t('iam:igc_users.cannot_remove_workspace_user_msg', {
                  userName
                })}
          </Markdown>
        </Box>
      )
    }
    return content
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="2">
              {t('iam:igc_users.remove_from_workspace_title')}
            </Typography>
          }
        />
      }
      content={getModalContent()}
      footer={
        !loading && !isOwnAccount && !hasInheritedRoleAssignment ? (
          <ModalFooter
            right={
              <Box direction="row" gap="medium">
                <Button
                  label={t('common:cancel')}
                  onClick={closeModal}
                  testId="remove-user-cancel-btn"
                />
                <Button
                  primary
                  label={t('iam:igc_users.remove_user_btn')}
                  onClick={removeUser}
                  testId="remove-user-remove-btn"
                />
              </Box>
            }
          />
        ) : (
          <ModalFooter
            right={
              <Box direction="row" gap="medium">
                <Button
                  primary
                  label={t('common:close')}
                  onClick={closeModal}
                  testId="remove-user-close-btn"
                />
              </Box>
            }
          />
        )
      }
      onClose={closeModal}
      onClickOutside={closeModal}
      testId="remove-user-modal"
    />
  )
}

RemoveUserModal.propTypes = {
  /**
   * Callback function to close modal
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * User to remove
   */
  userToRemove: PropTypes.object.isRequired,

  /**
   * set notification info
   */
  setNotificationInfo: PropTypes.func.isRequired,

  /**
   * role assignments data to check for indirect role assignments
   */
  retrievedData: PropTypes.array.isRequired
}

export default RemoveUserModal
