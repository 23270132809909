// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { Box, PageHeader } from 'grommet'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Previous } from 'grommet-icons'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { get, getErrorMessage } from '../../../utils/api-utils'
import { Anchor, Button, DataTable, Typography } from '../../../components'
import { displayNotification } from '../../../utils/notificiation-utils'
import { Layout } from '../../commoncomponents/layout/Layout'
import Markdown from '../../commoncomponents/Markdown'
import { useVisibilityContext } from '../../../context/visibility-context'
import NoViewPermission from '../commoncomponents/NoViewPermission'
import {
  getOrganizationId,
  isAssociateWorkspace
} from '../../../utils/feature-flag-utils'

import AddUserModal from './AddUserModal'

const UsersContent = () => {
  const { t } = useTranslation(['common', 'iam'])
  const location = useLocation()
  const history = useHistory()
  const { v2RbacPolicies, v2RbacRootWkspcPolicies } = useVisibilityContext()
  // Note: need to implement like this for RBAC OR logic
  const v2UserReadPerm = v2RbacPolicies?.includes('identity.users.read')
  const [isAssociated, setIsAssociated] = useState(false)
  const rootWkspReadPerm = v2RbacRootWkspcPolicies?.includes(
    'identity.users.read'
  )

  const [usersList, setUsersList] = useState([])
  const [loading, setLoading] = useState(true)
  const [showAddUserDialog, setShowAddUserDialog] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)
  const [filter, setFilter] = useState('')

  // Notifications
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')
  const setNotificationInfo = (message, severity, title = '') => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  const handleRowClick = (datum) => {
    history.push(`/manage-account/organization/users/${datum?.id}`)
  }

  const handleBackBtnClick = () => {
    history.push('/manage-account/organization')
  }

  const columns = [
    {
      property: 'displayName',
      header: t('iam:users_table_headers.name'),
      render: (datum) => {
        const displayName = datum.displayName || '--'
        return (
          <Box direction="row" align="center" gap="small">
            <Typography
              type="text"
              wordBreak="break-all"
              testId="user-display-name"
              emphasis
            >
              {displayName}
            </Typography>
          </Box>
        )
      }
    },
    {
      property: 'userName',
      primary: true,
      header: t('iam:users_table_headers.email')
    },
    {
      property: 'verified',
      header: t('iam:users_table_headers.verified'),
      render: (datum) => {
        return datum?.verified === true
          ? t('iam:verified_status_yes')
          : t('iam:verified_status_no')
      }
    },
    {
      property: 'lastLogin',
      header: t('iam:users_table_headers.last_login'),
      render: (datum) => {
        return datum?.lastLogin
          ? `${dayjs(datum?.lastLogin, 'YYYY-MM-DD hh:mm A').format(
              'MMM DD, YYYY'
            )}, ${dayjs(datum?.lastLogin, 'YYYY-MM-DD hh:mm A').format(
              'hh:mm A'
            )}`
          : '--'
      }
    }
  ]

  useEffect(() => {
    const orgId = getOrganizationId()
    if (orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (res) => {
          if (res?.data && res?.data?.associatedWorkspace) {
            setIsAssociated(
              isAssociateWorkspace(res?.data?.associatedWorkspace?.id)
            )
          }
        },
        (err) => {
          setNotificationInfo(getErrorMessage(err, t), 'error')
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location?.state?.notificationText) {
      const message = <Markdown>{location?.state?.notificationText}</Markdown>
      setNotificationInfo(message, 'info', location?.state?.notificationTitle)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    let isCurrent = true
    const nameQuery =
      filter?.trim()?.length > 0
        ? encodeURI(
            `?filter=displayName sw "${filter?.trim()}" or userName sw "${filter?.trim()}"`
          )
        : ''
    setLoading(true)
    get(`/internal-platform-tenant-ui/v2/users${nameQuery}`, {
      // Pagination is commented out for now
      // count: itemsPerPage,
      // cursor
    }).then(
      (response) => {
        if (response?.data) {
          if (!isCurrent) return
          dayjs.extend(customParseFormat)
          if (response.data?.items) {
            setUsersList(response.data?.items)
          }
          setLoading(false)
          // Pagination is commented out for now
          // setCursor(response?.data?.nextCursor)
        }
      },
      (error) => {
        if (!isCurrent) return
        setNotificationInfo(getErrorMessage(error), 'error')
        setLoading(false)
      }
    )
    return () => {
      isCurrent = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount, filter])

  return (
    <Box
      data-testid="users-page"
      pad={{ horizontal: 'xlarge', bottom: 'large' }}
      width="xxlarge"
      alignSelf="center"
    >
      <PageHeader
        responsive
        title={
          <Typography type="heading" level="1" testId="users-page-title">
            {t('iam:organization.organization_users_card_title')}
          </Typography>
        }
        subtitle={
          ((!isAssociated && v2UserReadPerm) ||
            (isAssociated && rootWkspReadPerm)) && (
            <Typography
              type="paragraph"
              size="xlarge"
              testId="users-page-subtitle"
            >
              {t('iam:igc_users.subtitle')}
            </Typography>
          )
        }
        parent={
          <Anchor
            label={t('iam:organization.governance_title')}
            icon={<Previous />}
            href="#"
            onClick={(event) => {
              event.preventDefault()
              handleBackBtnClick()
            }}
            margin={{ bottom: 'xsmall' }}
            testId="users-back-btn"
          />
        }
        margin={{ bottom: 'medium' }}
      />
      {(!isAssociated && v2UserReadPerm) ||
      (isAssociated && rootWkspReadPerm) ? (
        <DataTable
          grid={{
            columns,
            data: usersList,
            onClickRow: (event) => {
              handleRowClick(event?.datum)
            }
          }}
          loading={loading}
          search={{
            onSearchValueChange: (value) => {
              setFilter(value)
            },
            placeholder: t('common:search'),
            width: 'large'
          }}
          button={
            <Box align="end" width="medium">
              {((isAssociated &&
                v2RbacRootWkspcPolicies?.includes('identity.users.create')) ||
                (!isAssociated &&
                  v2RbacPolicies?.includes('identity.users.create'))) && (
                <Button
                  secondary
                  label={t('iam:igc_users.add_user_btn')}
                  onClick={() => {
                    setShowAddUserDialog(true)
                  }}
                  testId="data-table-add-user-btn"
                />
              )}
            </Box>
          }
          testId="user-data-table"
        />
      ) : (
        <NoViewPermission />
      )}
      {showAddUserDialog && (
        <AddUserModal
          onSetOpen={setShowAddUserDialog}
          refreshParent={() => {
            setRefreshCount((prevCount) => prevCount + 1)
          }}
          setParentNotification={setNotificationInfo}
        />
      )}
      {notifMsg &&
        displayNotification(notifMsg, notifSeverity, setNotifMsg, notifTitle)}
    </Box>
  )
}

const Users = () => {
  return (
    <Layout>
      <UsersContent align="start" justify="start" />
    </Layout>
  )
}

export default Users
