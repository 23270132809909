import React, { useContext, useEffect, useState } from 'react'
// import React, { useContext, useState } from 'react'
import { Box } from 'grommet'
import { Key } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Button,
  Loader,
  NoDataInfo,
  Typography
} from '../../../../../../../components'
import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import { validateForm } from '../../../../../../../utils/validation-utils'
import ServiceRolePermissions from '../ServiceRolePermissions'
import AddPermissionModal from '../../../../common-components/AddPermissionModal'
import { get, getErrorMessage } from '../../../../../../../utils/api-utils'
import { getGLCPAppDetails } from '../../../../../utils'

export const validatePermissions = (formValues, i18nTranslate) => {
  const validateOk =
    formValues?.permissions &&
    formValues?.permissions?.length > 0 &&
    (Object.is(false, formValues?.isCreateNewRole) ||
      formValues?.appId ||
      formValues?.application?.application_id)
  if (!validateOk) {
    return Promise.reject(new Error(''))
  }
  return validateForm(formValues, i18nTranslate)
}

const AddPermissions = () => {
  const {
    formValues,
    setFormValues,
    formError,
    setFormError,
    attemptedAdvance,
    setAttemptedAdvance
  } = useContext(WizardContext)
  const { oidcUser } = useReactOidc()
  const [showPermissionDialog, setShowPermissionDialog] = useState(false)
  const [permissionList, setPermissionList] = useState(formValues?.permissions)
  const [duplicateApplication, setDuplicateApplication] = useState({
    name: formValues?.serviceName,
    application_id: formValues?.appId
  })
  const { t } = useTranslation(['common', 'iam', 'authz'])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setFormValues({
      ...formValues,
      permissions: permissionList
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionList])

  useEffect(() => {
    if (formValues && attemptedAdvance) {
      if (!permissionList || permissionList?.length === 0) {
        setAttemptedAdvance(false)
        setFormError(t('authz:roles.permission_error_message'))
      } else if (formError) {
        setFormError(formError)
        setAttemptedAdvance(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemptedAdvance])

  const getDuplicateCustomApplication = async (permissions) => {
    try {
      setIsLoading(true)
      const resourceProviderName = permissions[0]?.permission_name.split('.')[0]
      const response = await get('/authorization/v2alpha1/resource-providers', {
        name: resourceProviderName
      })
      if (response?.data) {
        const appId = response?.data?.items[0]?.applicationId
        if (appId) {
          const appDetails = getGLCPAppDetails()
          if (appId === appDetails.glcpApplicationId) {
            setDuplicateApplication({
              name: appDetails.glcpApplicationName,
              application_id: appId
            })
          } else {
            const appResponse = await get(
              '/ui-doorway/ui/v1/applications/provisions',
              {
                application_id: appId
              },
              oidcUser.access_token
            )
            if (appResponse?.data && appResponse?.data?.provisions[0]?.name) {
              setDuplicateApplication({
                name: appResponse?.data?.provisions[0]?.name,
                application_id: appId
              })
            }
          }
        }
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      setFormError(getErrorMessage(error))
    }
  }

  const isCustomRole = () => {
    // Role can be retrieved from Authorization API or UID.
    // The former returns role kind as 'kind', whereas the latter returns as 'type'
    return (
      formValues?.roleToDuplicate &&
      (formValues?.roleToDuplicate?.kind === 'CUSTOM' ||
        formValues?.roleToDuplicate?.type === 'CUSTOM')
    )
  }

  const handleShowPermissionDialog = (value) => {
    setShowPermissionDialog(value)
  }

  useEffect(() => {
    if (isCustomRole() && !isEmpty(formValues?.permissions)) {
      getDuplicateCustomApplication(formValues?.permissions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box margin={{ top: 'medium', bottom: 'large' }}>
      {formValues?.permissions?.length === 0 ? (
        <Box gap="large">
          <Typography type="heading" level="2" size="medium">
            {t('iam:roles_permission.permission_title')} (0)
          </Typography>
          <Box pad="medium">
            <NoDataInfo
              action={
                <Button
                  label={t('iam:roles_permission.add_permission_title')}
                  onClick={() => {
                    setFormError('')
                    setShowPermissionDialog(true)
                  }}
                  secondary
                  testId="add-permissions-btn"
                />
              }
              icon={<Key size="large" />}
              testId="no-permissions"
              subtitle={t('iam:roles_permission.add_permission_subtitle', {
                role: t('common:business_object.role')
              })}
            />
          </Box>
        </Box>
      ) : (
        <Box gap="large">
          {formValues?.permissions?.length > 0 ? (
            <Box
              width="large"
              align="center"
              direction="row-responsive"
              justify="between"
            >
              <Typography type="heading" level="2">
                {t('iam:roles_permission.permission_title')}
              </Typography>
              <Button
                label={t('iam:roles_permission.edit_permission_label')}
                onClick={() => {
                  setShowPermissionDialog(true)
                }}
                secondary
                testId="edit-permissions-btn"
              />
            </Box>
          ) : null}
          <ServiceRolePermissions
            showPermissionDialog={handleShowPermissionDialog}
          />
        </Box>
      )}
      {isLoading && <Loader testId="role-permissions-loader" />}
      {showPermissionDialog && (
        <AddPermissionModal
          setShowPermissionDialog={setShowPermissionDialog}
          setPermissionsList={(permissions) => {
            setPermissionList(permissions)
          }}
          permissionsList={formValues?.permissions}
          selectedApplication={
            formValues?.isCreateNewRole
              ? formValues?.application
              : duplicateApplication
          }
          setSelectedApplication={(application) => {
            setFormValues({
              ...formValues,
              application
            })
            setDuplicateApplication(application)
          }}
          hideServiceDropdown={formValues?.permissions?.length}
        />
      )}
    </Box>
  )
}

export default AddPermissions
