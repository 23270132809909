import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  Button,
  CCSForm,
  Loader,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../components'
import EmailTextInput from '../commoncomponents/add-user/EmailTextInput'
import { getErrorMessage, post } from '../../../utils/api-utils'
import AddToGroup from '../commoncomponents/add-user/AddToGroup'
import { displayNotification } from '../../../utils/notificiation-utils'
import Markdown from '../../commoncomponents/Markdown'

const AddUserModal = ({ onSetOpen, refreshParent, setParentNotification }) => {
  const { t } = useTranslation(['common', 'iam'])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formValues, setFormValues] = useState({
    email: ''
  })
  const [selectedGroups, setSelectedGroups] = useState([])
  const [emailError, setEmailError] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')

  const setNotificationInfo = (message, severity) => {
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  const closeModal = () => {
    onSetOpen(false)
  }

  const onAddUser = async () => {
    setIsSubmitting(true)
    const groupIds = selectedGroups?.map((group) => group?.id)
    await post('/internal-platform-tenant-ui/v2/users', {
      user_name: formValues?.email?.trim(),
      group_ids: groupIds,
      assignments: {
        roles_grn: [],
        scopes: []
      },
      displayName: formValues?.email?.trim()
    }).then(
      (response) => {
        setIsSubmitting(false)
        closeModal()
        refreshParent()
        if (response?.data?.failed) {
          // Partial success scenario
          const groupsAddedSuccesfully =
            groupIds?.length - response?.data?.failed?.length
          setParentNotification(
            <Markdown>
              {t(`iam:igc_users.add_user_notification_partial_success_msg`, {
                groupsAddedSuccesfully,
                groupTotal: groupIds?.length
              })}
            </Markdown>,
            'warning',
            t('iam:igc_users.add_user_notification_success_title')
          )
        } else {
          // Success scenario
          setParentNotification(
            <Markdown>
              {t('iam:igc_users.add_user_notification_success_msg', {
                userName: formValues?.email
              })}
            </Markdown>,
            'info',
            t('iam:igc_users.add_user_notification_success_title')
          )
        }
      },
      (error) => {
        setIsSubmitting(false)
        closeModal()
        setParentNotification(getErrorMessage(error, t), 'error')
      }
    )
  }

  return (
    <>
      <ModalDialog
        position="center"
        width="100%"
        height="100%"
        pad="small"
        header={
          <Box alignSelf="center" width="large">
            <ModalHeader
              title={
                <Box direction="column">
                  <Typography
                    type="heading"
                    level="2"
                    testId="add-user-modal-title"
                  >
                    {t('iam:igc_users.add_user_title')}
                  </Typography>
                  <Typography type="paragraph" testId="add-user-modal-subtitle">
                    {t('iam:igc_users.add_user_subtitle')}
                  </Typography>
                </Box>
              }
              onClose={closeModal}
            />
          </Box>
        }
        content={
          <Box direction="column" alignSelf="center" width="large">
            <CCSForm
              value={formValues}
              onChange={setFormValues}
              onSubmit={onAddUser}
              buttons={
                <Box direction="row" gap="small">
                  <Button
                    primary
                    type="submit"
                    label={t('common:add')}
                    testId="add-user-add-btn"
                  />
                  <Button
                    label={t('common:cancel')}
                    onClick={closeModal}
                    testId="add-user-cancel-btn"
                  />
                </Box>
              }
              errorMessage=""
              testId="add-user-form"
            >
              <Box direction="column">
                <Box margin={{ top: 'medium', bottom: 'medium' }}>
                  <EmailTextInput
                    isSubmitting={isSubmitting}
                    errorMsg={emailError}
                    setErrorMsg={setEmailError}
                  />
                </Box>
                <Box margin={{ top: 'medium', bottom: 'medium' }}>
                  <AddToGroup
                    selectedGroups={selectedGroups}
                    setSelectedGroups={setSelectedGroups}
                    setNotificationInfo={setNotificationInfo}
                  />
                </Box>
              </Box>
            </CCSForm>
            {isSubmitting && (
              <Loader
                modal
                testId="add-user-loader"
                size="xxsmall"
                modalTitle={t('iam:users.loader_title')}
                modalSubTitle={t('iam:users.loader_subtitle')}
              />
            )}
          </Box>
        }
        onClose={closeModal}
        onClickOutside={closeModal}
        testId="add-user-dialog"
      />
      {notifMsg && displayNotification(notifMsg, notifSeverity, setNotifMsg)}
    </>
  )
}

AddUserModal.propTypes = {
  /**
   * Callback to show add user modal
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * Used to refresh parent upon adding user
   */
  refreshParent: PropTypes.func,

  /**
   * Set notification
   */
  setParentNotification: PropTypes.func
}

AddUserModal.defaultProps = {
  refreshParent: () => {},
  setParentNotification: () => {}
}

export default AddUserModal
