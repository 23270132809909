import React from 'react'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import {
  User,
  Monitor,
  AppsRounded,
  UserManager,
  Gateway,
  Secure,
  License,
  Aruba,
  Aggregate,
  Configure
} from 'grommet-icons'
import { Box } from 'grommet'
import dayjs from 'dayjs'
import i18next from 'i18next'

import { Typography } from '../../../../components'

const getIconByCategoryMap = {
  Inventory: <Monitor />,
  'App Management': <AppsRounded />,
  'SSO SAML': <Secure />,
  'User Management': <UserManager />,
  RBAC: <User />,
  Licensing: <License />,
  Configuration: <Configure />,
  'Gateway Management': <Gateway />,
  'Firmware Registry': <Aggregate />
}

export const sortEnabledColumnsMap = Object.freeze({
  category: 'category',
  description: 'description',
  username: 'username',
  audit_created_at: 'audit_created_at',
  customer_name: 'customer_name',
  account_type: 'account_type',
  account_name: 'additional_info.account_name',
  ip_address: 'additional_info.ip_address',
  target: 'additional_info.target',
  device_type: 'additional_info.device_type',
  server_serial: 'additional_info.server_serial',
  server_name: 'additional_info.server_name',
  server_product_id: 'additional_info.server_product_id',
  tenant_name: 'additional_info.tenant_name',
  groups: 'additional_info.groups'
})

function getCategoryMap(t) {
  return {
    Category: {
      property: 'category',
      header: t('auditlogs:dashboard_columns.Category'),
      render: (datum) => (
        <Box width={{ max: 'small' }}>
          <Typography
            size="medium"
            weight="bold"
            type="text"
            wordBreak="break-word"
          >
            {datum.category ? datum.category : '--'}
          </Typography>
        </Box>
      )
    },
    Description: {
      property: 'description',
      header: t('auditlogs:dashboard_columns.Description'),
      render: (datum) => (
        <Box width={{ max: 'medium' }}>
          <Typography size="medium" type="text" wordBreak="break-word">
            {datum.description ? datum.description : '--'}
          </Typography>
        </Box>
      )
    },
    'IP Address': {
      property: 'ip_address',
      header: t('auditlogs:dashboard_columns.IP Address'),
      render: (datum) => (
        <Box width={{ max: 'small' }}>
          <Typography size="medium" type="text" wordBreak="break-word">
            {datum.ip_address ? datum.ip_address : '--'}
          </Typography>
        </Box>
      )
    },
    Time: {
      property: 'audit_created_at',
      header: t('auditlogs:dashboard_columns.Time'),
      render: (datum) => (
        <Box width={{ max: 'small' }}>
          <Typography size="medium" type="text" wordBreak="break-word">
            {dayjs(datum.time).format('MMM DD, YYYY, HH:mm:ss')}
          </Typography>
        </Box>
      )
    },
    Target: {
      property: 'target',
      header: t('auditlogs:dashboard_columns.Target'),
      render: (datum) => (
        <Box
          width={{ max: 'small' }}
          direction="row-responsive"
          align="start"
          justify="start"
        >
          {datum.target ? (
            <>
              <Box margin={{ right: 'small' }}>
                {getIconByCategoryMap[datum.category] ? (
                  getIconByCategoryMap[datum.category]
                ) : (
                  <Aruba />
                )}
              </Box>
              <Typography size="medium" type="text" wordBreak="break-word">
                {datum.target}
              </Typography>
            </>
          ) : (
            '--'
          )}
        </Box>
      )
    },
    Username: {
      property: 'username',
      header: t('auditlogs:dashboard_columns.Username'),
      render: (datum) => (
        <Box width={{ max: 'small' }}>
          <Typography size="medium" type="text" wordBreak="break-word">
            {datum.username ? datum.username : '--'}
          </Typography>
        </Box>
      )
    }
  }
}

export const getColumnsBasedOnCategoriesSelected = (
  columns,
  appConfig = {},
  enableAuditLogSort,
  t
) => {
  let columnsToRender = []
  if (columns && columns.length > 0) {
    const columnsOrder = []
    const orderMap = {}
    columns.forEach((column) => {
      orderMap[appConfig.ORDER[column]] = column
      columnsOrder.push(appConfig.ORDER[column])
    })

    const sortedColumns = sortBy(columnsOrder)
    const orderedColumns = sortedColumns.map((columnKey) => {
      return orderMap[columnKey]
    })
    orderedColumns.forEach((column) => {
      const columnInContext = {
        property: appConfig.DASHBOARD_COLUMNS.ALL[column],
        header: i18next.exists(`auditlogs:dashboard_columns.${column}`)
          ? t(`auditlogs:dashboard_columns.${column}`)
          : column,
        render: (datum) => (
          <Box width={{ max: 'small' }}>
            <Typography size="medium" type="text" wordBreak="break-word">
              {Object.keys(appConfig).length > 0 &&
              datum[appConfig.DASHBOARD_COLUMNS.ALL[column]]
                ? datum[appConfig.DASHBOARD_COLUMNS.ALL[column]]
                : '--'}
            </Typography>
          </Box>
        )
      }
      const categoryMap = getCategoryMap(t)
      const columnObj = categoryMap[column]
        ? categoryMap[column]
        : columnInContext

      if (enableAuditLogSort && sortEnabledColumnsMap[columnObj.property]) {
        columnObj.sortable = true
      }

      columnsToRender.push(columnObj)
    })
  } else {
    columnsToRender = [
      {
        property: 'category',
        header: t('auditlogs:dashboard_columns.Category'),
        render: (datum) => (
          <Box width={{ max: 'small' }}>
            <Typography color="text-strong" type="text">
              {datum.category ? datum.category : '--'}
            </Typography>
          </Box>
        ),
        sortable: enableAuditLogSort
      },
      {
        property: 'description',
        header: t('auditlogs:dashboard_columns.Description'),
        render: (datum) => (
          <Box width={{ max: 'medium' }}>
            <Typography size="medium" type="text">
              {datum.description ? datum.description : '--'}
            </Typography>
          </Box>
        ),
        sortable: enableAuditLogSort
      },
      {
        property: 'ip_address',
        header: t('auditlogs:dashboard_columns.IP Address'),
        render: (datum) => (
          <Box width={{ max: 'small' }}>
            <Typography size="medium" type="text">
              {datum.ip_address ? datum.ip_address : '--'}
            </Typography>
          </Box>
        ),
        sortable: enableAuditLogSort
      },
      {
        property: 'target',
        header: t('auditlogs:dashboard_columns.Target'),
        render: (datum) => (
          <Box
            width={{ max: 'small' }}
            direction="row-responsive"
            align="start"
            justify="start"
          >
            <Box margin={{ right: 'small' }}>
              {getIconByCategoryMap[datum.category] ? (
                getIconByCategoryMap[datum.category]
              ) : (
                <Aruba />
              )}
            </Box>
            <Typography size="medium" type="text">
              {datum.target ? datum.target : '--'}
            </Typography>
          </Box>
        ),
        sortable: enableAuditLogSort
      },
      {
        property: 'audit_created_at',
        header: t('auditlogs:dashboard_columns.Time'),
        render: (datum) => (
          <Box width={{ max: 'small' }}>
            <Typography size="medium" type="text">
              {dayjs(datum.time).format('MMM DD, YYYY, HH:mm:ss')}
            </Typography>
          </Box>
        ),
        sortable: enableAuditLogSort
      }
    ]
  }
  return columnsToRender
}

const getDynamicUiSearchFields = (appConfig = {}, t, initValues, lang) => {
  let content = []
  if (Object.keys(appConfig.length !== 0) && appConfig.UI_SEARCH_FIELDS) {
    const detailFields = (() => {
      const fields = {}

      const excludeFields = {
        Category: true,
        Time: true
      }

      if (lang !== 'en-US') {
        excludeFields.Description = true
        excludeFields.Target = true
        excludeFields['Workspace Name'] = true
      }

      Object.keys(appConfig.UI_SEARCH_FIELDS).forEach((field) => {
        if (!excludeFields[field]) {
          fields[field] = appConfig.UI_SEARCH_FIELDS[field]
        }
      })

      return fields
    })()

    Object.entries(detailFields).forEach((uifield) => {
      content.push({
        name: i18next.exists(`auditlogs:dashboard_columns.${uifield[0]}`)
          ? t(`auditlogs:dashboard_columns.${uifield[0]}`)
          : uifield[0],
        key: uifield[1],
        label: `${
          i18next.exists(`auditlogs:dashboard_columns.${uifield[0]}`)
            ? t(`auditlogs:dashboard_columns.${uifield[0]}`)
            : uifield[0]
        }`,
        selectionType: uifield[0] === 'IP Address' ? 'ip' : 'text',
        values: initValues[uifield[1]] || [''],
        testId: `${uifield[1]}-field`
      })
    })
  } else {
    content = [
      {
        name: t('auditlogs:dashboard_columns.description'),
        key: 'description',
        label: `${t('auditlogs:search')} ${t(
          'auditlogs:dashboard_columns.description'
        )}`,
        selectionType: 'text',
        testId: 'description-field',
        values: initValues.description || ['']
      },
      {
        name: t('auditlogs:dashboard_columns.IP Address'),
        key: 'ipaddress',
        label: `${t('auditlogs:search')} ${t(
          'auditlogs:dashboard_columns.IP Address'
        )}`,
        selectionType: 'ip',
        testId: 'ipaddress-field',
        values: initValues.ipaddress || ['']
      },
      {
        name: t('auditlogs:dashboard_columns.Target'),
        key: 'target',
        label: `${t('auditlogs:search')} ${t(
          'auditlogs:dashboard_columns.Target'
        )}`,
        selectionType: 'text',
        testId: 'target-field',
        values: initValues.target || ['']
      }
    ]
  }

  return content
}

export const getFilterAttributes = (
  categories = [],
  appConfig = {},
  t,
  initValues,
  lang = 'en-US'
) => {
  const initialTimeVal = initValues?.time && initValues.time[0]
  return [
    {
      name: 'Category',
      label: t('auditlogs:advanced_search_table.categories'),
      key: 'category',
      search: true,
      selectionType: 'multi-select',
      values: categories && categories.length > 0 ? [].concat(categories) : [],
      testId: 'category-field'
    },
    ...getDynamicUiSearchFields(appConfig, t, initValues, lang),
    {
      name: 'Time',
      key: 'time',
      label: t('auditlogs:dashboard_columns.Time'),
      customTime: true,
      customTimeKey: 'Custom Date Range',
      customTimeVal:
        initialTimeVal === 'Custom Date Range'
          ? initValues['Custom Date Range']
          : [],
      selectionType: 'dropdown',
      testId: 'timeselection-field',
      bounds: [dayjs().subtract(90, 'd').toISOString(), dayjs().toISOString()],
      dateRangeInfo: t('auditlogs:advanced_search_table.date_range_info'),
      values: [
        {
          name: 'Last Hour',
          label: t('auditlogs:advanced_search_table.time_options.last_hour'),
          checked: initialTimeVal === 'Last Hour'
        },
        {
          name: 'Last 3 Hours',
          label: t('auditlogs:advanced_search_table.time_options.last_3_hours'),
          checked: initialTimeVal === 'Last 3 Hours'
        },
        {
          name: 'Last Day',
          label: t('auditlogs:advanced_search_table.time_options.last_day'),
          checked: initialTimeVal === 'Last Day'
        },
        {
          name: 'Last Week',
          label: t('auditlogs:advanced_search_table.time_options.last_week'),
          checked: initialTimeVal === 'Last Week'
        },
        {
          name: 'Last Month',
          label: t('auditlogs:advanced_search_table.time_options.last_month'),
          checked: initialTimeVal === 'Last Month'
        },
        {
          name: 'Last 3 Months',
          label: t(
            'auditlogs:advanced_search_table.time_options.last_3_months'
          ),
          checked: initialTimeVal === 'Last 3 Months'
        },
        {
          name: 'Custom Date Range',
          label: t(
            'auditlogs:advanced_search_table.time_options.custom_time_range'
          ),
          checked: initialTimeVal === 'Custom Date Range'
        }
      ]
    }
  ]
}

export const editColumnsList = (selectedColumns = [], appConfig = {}, t) => {
  const tableItems = [
    {
      name: t('auditlogs:edit_columns_table_column'),
      label: t('auditlogs:edit_columns_table_column'),
      key: 'columns',
      search: true,
      maxLen: 6,
      subTitle: t('auditlogs:edit_columns_selected', {
        interpolation: { skipOnVariables: true }
      }),
      selectionType: 'checkbox',
      values: [],
      testId: 'editColumnsList'
    }
  ]

  if (isEmpty(appConfig)) {
    return tableItems
  }

  const editColumnsListDefault = Object.entries(
    appConfig.DASHBOARD_COLUMNS.ALL
  ).map((columnKeyVal) => {
    return {
      name: columnKeyVal[0],
      label: i18next.exists(`auditlogs:audit_logs.${columnKeyVal[0]}`)
        ? t(`auditlogs:audit_logs.${columnKeyVal[0]}`)
        : columnKeyVal[0],
      disabled: false,
      checked: false
    }
  })
  const editColumnsDisabledProperties = !isEmpty(
    appConfig.DASHBOARD_COLUMNS.FIXED
  )
    ? appConfig.DASHBOARD_COLUMNS.FIXED
    : {}

  if (!isEmpty(editColumnsListDefault)) {
    editColumnsListDefault.forEach((column) => {
      const isSelectedColumn = selectedColumns.includes(column.name)
      tableItems[0].values.push({
        name: column.name,
        label: column.label,
        disabled: editColumnsDisabledProperties[column.name]
          ? Boolean(editColumnsDisabledProperties[column.name])
          : false,
        checked: isSelectedColumn
      })
    })
    tableItems[0].values = sortBy(tableItems[0].values, (val) => val.name)
  }

  return tableItems
}

export const getTime = (state) => {
  const timeToReturn = {
    startTime: '',
    endTime: dayjs().unix()
  }

  const dayjsInstance = dayjs
  if (state.time && state.time.length > 0) {
    switch (state.time[0]) {
      case 'Last Hour': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'h').unix()
        break
      }
      case 'Last 3 Hours': {
        timeToReturn.startTime = dayjsInstance().subtract(3, 'h').unix()
        break
      }
      case 'Last Day': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'd').unix()
        break
      }
      case 'Last Week': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'w').unix()
        break
      }
      case 'Last Month': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'M').unix()
        break
      }
      case 'Last 3 Months': {
        timeToReturn.startTime = dayjsInstance().subtract(3, 'M').unix()
        break
      }
      case 'Last 6 Months': {
        timeToReturn.startTime = dayjsInstance().subtract(6, 'M').unix()
        break
      }
      case 'Last 1 Year': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'Y').unix()
        break
      }
      case 'Custom Date Range': {
        timeToReturn.endTime = state['Custom Date Range']
          ? dayjsInstance(state['Custom Date Range'][1]).endOf('day').unix()
          : dayjsInstance().endOf('day').unix()
        timeToReturn.startTime = state['Custom Date Range']
          ? dayjsInstance(state['Custom Date Range'][0]).startOf('day').unix()
          : dayjsInstance().subtract(90, 'd').startOf('day').unix()
        break
      }
      default: {
        timeToReturn.startTime = dayjsInstance().subtract({ M: 3 }).unix()
        break
      }
    }
  }

  return timeToReturn
}
export const getQueryUrl = (state, categories, appConfig = {}) => {
  let url = ''
  const copyState = { ...state }
  const formattedCategories = categories.map((category) => category.name)
  if (copyState.time) {
    const time = getTime(copyState)
    url += `&start_time=${time.startTime}&end_time=${time.endTime}`
  }
  if (copyState.category && copyState.category.length > 0) {
    const isAll = copyState.category.length === categories.length

    const exludedCategories = formattedCategories.filter((item) => {
      return !copyState.category.includes(item) ? item : false
    })

    copyState.category = copyState.category.filter(
      (category) => category !== 'All Categories'
    )

    if (isAll) {
      url += `&category=_all`
    } else if (exludedCategories.length < copyState.category.length) {
      url +=
        `&category=_all` +
        `${
          exludedCategories.length > 0
            ? `&exclude_category=${exludedCategories.join(',')}`
            : ''
        }`
    } else {
      url += `&category=${copyState.category.join(',')}`
    }
  }

  if (copyState.search && state.search[0].length > 0) {
    url += `&_all=${encodeURIComponent(copyState.search[0])}`
  }

  if (appConfig.UI_SEARCH_FIELDS) {
    const uiFieldValuesExcludingCategory = Object.values(
      appConfig.UI_SEARCH_FIELDS
    ).filter((uifield) => uifield !== 'category')
    if (Object.keys(appConfig).length > 0 && appConfig.UI_SEARCH_FIELDS) {
      uiFieldValuesExcludingCategory.forEach((uifieldKey) => {
        if (copyState[uifieldKey] && copyState[uifieldKey].length > 0) {
          url += `&${uifieldKey}=${encodeURIComponent(
            copyState[uifieldKey][0]
          )}`
        }
      })
    }
  }

  return url
}

export const getIntervalForTrendChart = (interval) => {
  const intervalMap = {
    'Last Hour': '1h',
    'Last 3 Hours': '3h',
    'Last Day': '24h',
    'Last Week': '7d',
    'Last Month': '30d',
    'Last 3 Months': '90d'
  }

  return intervalMap[interval] || '1h'
}

export const dateRangeMap = {
  '1h': {
    tickCountY: 6,
    tickFormat: 'h:mm A',
    skipInterval: 1,
    actualCount: 24,
    toolTipFormat: 'h:mm A'
  },
  '3h': {
    tickCountY: 6,
    tickFormat: 'h:mm A',
    skipInterval: 1,
    actualCount: 24,
    toolTipFormat: 'h:mm A'
  },
  '24h': {
    tickCountY: 6,
    tickFormat: 'h:mm A',
    skipInterval: 1,
    actualCount: 24,
    toolTipFormat: 'h:mm A'
  },
  '7d': {
    tickCountY: 6,
    tickFormat: 'MM/DD',
    skipInterval: 0,
    actualCount: 7,
    toolTipFormat: 'MMM D, YYYY'
  },
  '30d': {
    tickCountY: 6,
    tickFormat: 'MM/DD',
    skipInterval: 3,
    actualCount: 30,
    toolTipFormat: 'MMM D, YYYY'
  },
  '90d': {
    tickCountY: 6,
    tickFormat: 'MM/DD',
    skipInterval: 10,
    actualCount: 90,
    toolTipFormat: 'MMM D, YYYY'
  }
}
