import React from 'react'
import PropTypes from 'prop-types'

import useUPSReducer from './ups-reducer'

const UPSContext = React.createContext()

export const UPSContextProvider = ({ children }) => {
  const stateAndDispatch = useUPSReducer()
  return (
    <UPSContext.Provider value={stateAndDispatch}>
      {children}
    </UPSContext.Provider>
  )
}

UPSContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const useUPSContext = () => {
  const { UPSState, dispatchUPSContext } = React.useContext(UPSContext)
  return { ...UPSState, dispatchUPSContext }
}
