import { ServiceCatalogRouter } from '../dashboard'
import DashboardHome from '../dashboard/pages/home'
import PublishApplicationRouter from '../pages/applications/router'
import { HomeRouter } from '../pages/home'

const experienceMap = (serviceCentric) => {
  return {
    Home: serviceCentric ? DashboardHome : HomeRouter,
    ServiceOfferings: serviceCentric
      ? ServiceCatalogRouter
      : PublishApplicationRouter
  }
}

export default experienceMap
