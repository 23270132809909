import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { Alert } from 'grommet-icons'
import PropTypes from 'prop-types'

import { Typography } from '../../../../../../../components'

const DataErrorHandling = ({ height }) => {
  const { t } = useTranslation(['device', 'common'])
  return (
    <Box
      direction="column"
      gap="medium"
      justify="center"
      height={{ min: height || 'medium' }}
    >
      <Box alignSelf="center">
        <Alert color="status-warning" />
      </Box>
      <Box direction="column" gap="small" alignSelf="center" align="center">
        <Typography
          type="heading"
          level={3}
          margin={{ top: '0px', bottom: '0px' }}
          testId="manage-showback-bad-data-title"
        >
          {t('showback_rates.wizard_meters_msg_bad_data_title')}
        </Typography>
        <Typography
          type="text"
          margin={{ top: '0px', bottom: '0px' }}
          testId="manage-showback-bad-data-des"
        >
          {t('showback_rates.wizard_meters_msg_bad_data_des')}
        </Typography>
      </Box>
    </Box>
  )
}

DataErrorHandling.defaultProps = {
  height: undefined
}

DataErrorHandling.propTypes = {
  height: PropTypes.string
}

export default DataErrorHandling
