import { React, Suspense, lazy } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'

import { AppCatalogContextProvider } from '../../context/app-catalog-context'
import { checkGotoChooseAccount } from '../../utils/common-utils'
import LazyLoading from '../commoncomponents/LazyLoading/LazyLoading'

const PublishApplicationPage = lazy(() => import('./PublishApps'))
const InstalledAppsRouter = lazy(() => import('./installed-apps/router'))
const DetailsRouter = lazy(() => import('./app-details/router'))
const ServiceDetailsRouter = lazy(() =>
  import('./app-details/service-catalog/router')
)

const PublishApplicationRouterContent = () => {
  const { path } = useRouteMatch()

  const history = useHistory()
  checkGotoChooseAccount(history)

  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route exact path={`${path}/installed-apps/:appId/:fromServiceSubs?`}>
          <InstalledAppsRouter />
        </Route>
        <Route exact path={`${path}/app-details/:appId/:region?`}>
          <DetailsRouter />
        </Route>
        <Route exact path={`${path}/service-details/:appId/:serviceId?`}>
          <ServiceDetailsRouter />
        </Route>
        {/* NOTE: Always add any routes above this route */}
        <Route path={path}>
          <PublishApplicationPage />
        </Route>
      </Switch>
    </Suspense>
  )
}

const PublishApplicationRouter = () => {
  return (
    <AppCatalogContextProvider>
      <PublishApplicationRouterContent />
    </AppCatalogContextProvider>
  )
}

export default PublishApplicationRouter
