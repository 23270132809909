// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import PropTypes from 'prop-types'
import { React, useCallback, useEffect, useReducer, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import { isEmpty } from 'lodash'
import { Box, FormField, RadioButtonGroup, Select, Text } from 'grommet'

import {
  Button,
  CCSForm,
  Loader,
  Typography,
  ModalDialog,
  ModalHeader,
  MultiSelectBox,
  Dropdown,
  Notification
} from '../../../../components'
import { get, post } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { roleKindMapping } from '../../utils'
import { useCCSContext } from '../../../../context/ccs-context'
import {
  getResourceTypeName,
  filterScopelessResourceTypes,
  getAllInstancesOfResourceTypeSlug
} from '../utils'
import { USER_SCHEMAS, glcpServiceID } from '../constants'
import { LIFECYCLE_STATE } from '../../account-details/constants'
import { getOrganizationId } from '../../../../utils/feature-flag-utils'

import Markdown from './Markdown'
import SelectContainer from './SelectContainer'
import { DropdownCustomRender } from './DropdownCustomRender'

const AssignRoleSideDrawer = ({
  preSelectedSubject,
  preSelectedRole,
  setShowAssignRoleModal,
  setStatusNotification,
  onAssign,
  appId,
  roleAssignments
}) => {
  const { oidcUser } = useReactOidc()
  const isNewSubject =
    preSelectedSubject && Object.keys(preSelectedSubject.data)?.length === 0
  const multiSelectBoxLimit = 10
  const orgId = getOrganizationId()
  const { workSpaceGrn } = useCCSContext()
  const { t } = useTranslation(['common', 'authn', 'iam', 'auditlogs', 'authz'])
  const [loading, setLoading] = useState(false)
  const [roleAssignedLoading, setRoleAssignedLoading] = useState(false)
  const [apiError, setApiError] = useState(null)
  const [searchedText, setSearchedText] = useState('')
  const [oneRoleApp, setOneRoleApp] = useState(false)
  const [roleAssigned, setRoleAssigned] = useState(false) // For one role applications
  const [serviceName, setServiceName] = useState(null)
  const [isCCSRole, setIsCCSRole] = useState(false) // CCS roles are assigned entire scope
  const [servicesProvisioned, setServicesProvisioned] = useState(null)
  const [showUserGroupOption, setShowUserGroupOption] = useState(false)
  const [rolesOptionsLoading, setRolesOptionsLoading] = useState(false)

  // error states
  const [subjectTypeError, setSubjectTypeError] = useState('')
  const [subjectError, setSubjectError] = useState('')
  const [roleError, setRoleError] = useState('')
  const [scopeError, setScopeError] = useState('')
  const [resourceError, setResourceError] = useState('')
  const [serviceError, setServiceError] = useState('')
  const [regionError, setRegionError] = useState('')
  const [resourceTypeError, setResourceTypeError] = useState('')
  const [resourceInstanceError, setResourceInstanceError] = useState('')
  const [scopeGroupInstanceError, setScopeGroupInstanceError] = useState('')
  const [alreadyExistingRoleError, setAlreadyExistingRoleError] =
    useState(false)

  const initState = {
    roles: [],
    roleOptions: [],
    subject: null,
    subjectOptions: [],
    subjectType: null,
    scope: '',
    service: null,
    serviceOptions: [],
    region: null,
    regionOptions: [],
    resourceType: null,
    resourceTypeOptions: [],
    resource: '',
    resourceInstances: [],
    resourceInstanceOptions: [],
    scopeGroupInstance: null,
    scopeGroupInstanceOptions: []
  }

  const [state, dispatch] = useReducer((currentState, action) => {
    switch (action?.type) {
      case 'CHANGE_FIELD':
        return { ...currentState, [action?.field]: action?.value }
      case 'INIT_ROLE_STATE':
        return { ...currentState, roles: [] }
      case 'INIT_SUBJECT_STATE':
        return {
          ...currentState,
          subject: null,
          subjectOptions: []
        }
      case 'INIT_SUBJECT_TYPE_STATE':
        return { ...currentState, subjectType: null }
      case 'INIT_SCOPE_STATE':
        return { ...currentState, scope: '' }
      case 'INIT_SERVICE_STATE':
        return { ...currentState, service: null }
      case 'INIT_REGION_STATE':
        return { ...currentState, region: null, regionOptions: [] }
      case 'INIT_RESOURCE_TYPE_STATE':
        return {
          ...currentState,
          resourceType: null,
          resourceTypeOptions: []
        }
      case 'INIT_RESOURCE_STATE':
        return { ...currentState, resource: null }
      case 'INIT_RESOURCE_INSTANCE_STATE':
        return {
          ...currentState,
          resourceInstances: [],
          resourceInstanceOptions: []
        }
      case 'INIT_SCOPE_GROUP_INSTANCE_STATE':
        return {
          ...currentState,
          scopeGroupInstance: null,
          scopeGroupInstanceOptions: []
        }
      default:
        return currentState
    }
  }, initState)

  const getScopeTypeName = (scopeType) => {
    return scopeType?.name || ''
  }

  const getSubjectName = (subject, type) => {
    let name
    // use user's displayName or userName if not found
    if (type === 'USER') name = subject?.displayName || subject?.userName
    else if (type === 'GROUP') name = subject?.displayName
    else name = subject?.name // APIClient
    return name
  }

  const subjectTypeOptions = [
    { label: t('iam:users.user'), value: 'USER' },
    ...(oneRoleApp || !showUserGroupOption
      ? []
      : [
          {
            label: t('common:business_object.group_capitalized'),
            value: 'GROUP'
          }
        ])
    // Commenting out APICLIENT option
    // {
    //   label: t('iam:assign_roles_side_panel.api_client_label'),
    //   value: 'APICLIENT'
    // }
  ]

  const scopeOptions = [
    {
      label: t('iam:assign_roles_side_panel.access_all_workspace_label', {
        workspace: t('common:business_object.wkspc')
      }),
      value: 'ALL_WORKSPACE_OPTION'
    },
    {
      label: t(
        'iam:assign_roles_side_panel.access_to_scope_group_resources_label'
      ),
      value: 'SCOPE_GROUP_OPTION'
    }
    // TODO Hide the Resource option as per GLCP-186839: Hide Specific resource category or instances in Assign role panel
    // ,
    // {
    //   label: t('iam:assign_roles_side_panel.access_one_resource_label'),
    //   value: 'RESOURCE_OPTION'
    // }
  ]

  const resourceOptions = () => {
    if (!state?.resourceType?.value) {
      return [
        {
          label: t('iam:assign_roles_side_panel.select_all_resource_label'),
          value: 'ALL_RESOURCES_OPTION'
        },
        {
          label: t('iam:assign_roles_side_panel.select_one_resource_label'),
          value: 'SOME_RESOURCES_OPTION'
        }
      ]
    }

    const resourceTypeName = getResourceTypeName(state?.resourceType?.value)
    return [
      {
        label: (
          <Markdown>
            {t(
              'iam:assign_roles_side_panel.select_all_resource_with_type_label',
              {
                resourceType: resourceTypeName
              }
            )}
          </Markdown>
        ),
        value: 'ALL_RESOURCES_OPTION'
      },
      {
        label: (
          <Markdown>
            {t(
              'iam:assign_roles_side_panel.select_one_resource_with_type_label',
              {
                resourceType: resourceTypeName
              }
            )}
          </Markdown>
        ),
        value: 'SOME_RESOURCES_OPTION'
      }
    ]
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchValue = useCallback(
    debounce((text) => {
      setSearchedText(text)
    }, 500),
    []
  )

  const roleTemplate = (option) => {
    const role = option?.value
    return (
      <Box
        direction="row"
        justify="between"
        align="center"
        pad={{ horizontal: 'small', vertical: 'small' }}
        fill
      >
        <Box>
          <Typography size="small" type="text">
            {role?.service_name}
          </Typography>
          <Typography type="text" emphasis>
            {option?.label}
          </Typography>
          <Typography size="small" type="text">
            {role?.role_description}
          </Typography>
        </Box>
        <Typography size="small" type="text">
          {roleKindMapping.get(role?.type)}
        </Typography>
      </Box>
    )
  }

  const resourceInstanceTemplate = (option) => {
    const resourceInstance = option?.value
    return (
      <Box>
        <Typography type="text">{option?.label}</Typography>
        <Typography type="text">
          {t('iam:assign_roles_side_panel.select_resource_instance_option', {
            resourceInstance: resourceInstance?.grn.substring(
              resourceInstance?.grn.lastIndexOf('/')
            )
          })}
        </Typography>
      </Box>
    )
  }

  const resourceTypeTemplate = (option) => {
    const resourceType = option?.value
    return (
      <Box
        justify="between"
        pad={{ horizontal: 'small', vertical: 'xsmall' }}
        fill
      >
        <Typography emphasis type="text">
          {option?.label}
        </Typography>
        <Typography size="small" type="text">
          {resourceType?.provider_disply_name || resourceType?.provider_name}
        </Typography>
      </Box>
    )
  }

  const handleDiscardChanges = () => {
    setShowAssignRoleModal(false)
  }

  const fieldsToValidate = () => {
    let result = false
    const isSubjectTypeSelected = state?.subjectType?.value
    setSubjectTypeError(
      !isSubjectTypeSelected
        ? t('iam:assign_roles_side_panel.select_subject_type_error_msg')
        : ''
    )
    if (!isSubjectTypeSelected) return result

    const isSubjectSelected = state?.subject?.value
    setSubjectError(
      !isSubjectSelected
        ? t('iam:assign_roles_side_panel.select_subject_error_msg')
        : ''
    )
    if (!isSubjectSelected) return result

    const isRoleSelected = state?.roles?.length > 0
    setRoleError(
      !isRoleSelected
        ? t('iam:assign_roles_side_panel.select_role_error_msg', {
            role: t('common:business_object.role')
          })
        : ''
    )
    if (!isRoleSelected) return result

    const isScopeSelected = state?.scope !== ''
    setScopeError(
      !isScopeSelected
        ? t('iam:assign_roles_side_panel.select_scope_error_msg')
        : ''
    )
    if (!isScopeSelected) return result

    if (state?.scope === 'ALL_WORKSPACE_OPTION') {
      result = true
    } else if (state?.scope === 'RESOURCE_OPTION') {
      setServiceError(
        !state?.service?.value
          ? t('iam:assign_roles_side_panel.select_service_error_msg')
          : ''
      )
      setRegionError(
        !state?.region?.value
          ? t('iam:assign_roles_side_panel.select_region_error_msg')
          : ''
      )
      setResourceTypeError(
        !state?.resourceType?.value
          ? t('iam:assign_roles_side_panel.select_resource_type_error_msg')
          : ''
      )
      setResourceError(
        !state?.resource
          ? t('iam:assign_roles_side_panel.select_resource_error_msg')
          : ''
      )
      setResourceInstanceError(
        state?.resourceInstances?.length === 0
          ? t('iam:assign_roles_side_panel.select_resource_instance_error_msg')
          : ''
      )
      const resourcesDataSelected =
        state?.service?.value &&
        state?.region?.value &&
        state?.resourceType?.value &&
        state?.resource
      if (state?.resource === 'ALL_RESOURCES_OPTION') {
        result = resourcesDataSelected
      } else if (state?.resource === 'SOME_RESOURCES_OPTION') {
        result = resourcesDataSelected && state?.resourceInstances?.length > 0
      }
    } else if (state?.scope === 'SCOPE_GROUP_OPTION') {
      setScopeGroupInstanceError(
        !state?.scopeGroupInstance
          ? t('iam:assign_roles_side_panel.select_scope_group_error_msg')
          : ''
      )
      result = !!(state?.scopeGroupInstance && state?.scopeGroupInstance?.value)
    }
    return result
  }

  const checkIfOneRoleApp = (provisions) => {
    // Filter
    const oneRoleApplications = provisions?.filter(
      (app) => app?.support_one_role_only
    )
    // Determine if role is one role assignment per app
    oneRoleApplications?.forEach((app) => {
      if (app?.application_id === appId) {
        setOneRoleApp(true)
      }
    })
  }

  const checkIfRoleAssigned = (user) => {
    setRoleAssignedLoading(true)
    get(
      `/internal-platform-tenant-ui/v2/role-assignments?subject=${
        user[USER_SCHEMAS?.EXTENSIONS]?.hpe_principal
      }`
    ).then(
      (res) => {
        const directRoleAssignments = res?.data?.role_assignments?.filter(
          (roleAssignment) => !roleAssignment?.source_Id
        )
        directRoleAssignments?.forEach((roleAssignment) => {
          if (
            roleAssignment?.service_id === appId ||
            roleAssignment?.custom_role_service_id === appId
          ) {
            setRoleAssigned(true)
            if (
              roleAssignment?.service_name ||
              roleAssignment?.custom_role_service_name
            ) {
              setServiceName(
                roleAssignment?.service_name ||
                  roleAssignment?.custom_role_service_name
              )
            }
          }
        })
        setRoleAssignedLoading(false)
      },
      (err) => {
        setApiError(err)
        setRoleAssignedLoading(false)
      }
    )
  }

  const getOneRoleAppIds = (provisions) => {
    return provisions
      ?.filter((app) => app?.support_one_role_only)
      .map((service) => service?.application_id)
  }

  const getServicesProvisioned = async () => {
    let provisionsService = []
    try {
      const response = await get(
        '/ui-doorway/ui/v1/applications/provisions',
        {
          provision_status: 'PROVISIONED'
        },
        oidcUser?.access_token
      )
      provisionsService = response?.data?.provisions
    } catch (error) {
      setApiError(error)
    }
    return provisionsService
  }

  const getRoles = async () => {
    // call get roles API when:
    // - isEmpty(preSelectedRole) => Not from Role details page
    // - !isEmpty(state?.subjectType) =>
    //     When the subject type is selected
    if (isEmpty(preSelectedRole) && !isEmpty(state?.subjectType)) {
      setRolesOptionsLoading(true)
      try {
        const response = await get('/internal-platform-tenant-ui/v2/roles', {
          service: 'all',
          ...(searchedText.trimStart().length > 0 && {
            search: searchedText.trimStart()
          })
        })
        const options = []
        if (response?.data?.roles?.length) {
          let roles = response?.data?.roles
          if (
            preSelectedSubject?.subjectType === 'GROUP' ||
            state?.subjectType?.value === 'GROUP'
          ) {
            const provisions = await getServicesProvisioned()
            setServicesProvisioned(provisions)
            const oneRoleApps = provisions
              ?.filter((service) => service.support_one_role_only)
              ?.map((application) => application.application_id)
            roles = roles?.filter(
              (role) =>
                !oneRoleApps.includes(
                  role?.service_id || role?.custom_role_service_id
                )
            )
          }
          roles?.forEach((role) => {
            options.push({
              label: role.role_display_name,
              value: role
            })
          })
        }
        setRolesOptionsLoading(false)
        dispatch({
          value: options,
          field: 'roleOptions',
          type: 'CHANGE_FIELD'
        })
      } catch (error) {
        setApiError(error)
      }
      setRolesOptionsLoading(false)
    }
  }

  const handleDispatch = async (option, field) => {
    dispatch({
      value: option,
      field,
      type: 'CHANGE_FIELD'
    })

    if (field === 'subjectType' && option) {
      // clear state if subject type changed
      if (!preSelectedRole) {
        dispatch({ type: 'INIT_ROLE_STATE' })
        setRoleError('')
      }
      dispatch({ type: 'INIT_SUBJECT_STATE' })
      setSubjectError('')
      if (!isCCSRole) dispatch({ type: 'INIT_SCOPE_STATE' })
      setScopeError('')
      dispatch({ type: 'INIT_SERVICE_STATE' })
      setServiceError('')
      dispatch({ type: 'INIT_REGION_STATE' })
      setRegionError('')
      dispatch({ type: 'INIT_RESOURCE_TYPE_STATE' })
      setResourceTypeError('')
      dispatch({ type: 'INIT_RESOURCE_STATE' })
      setResourceError('')
      dispatch({ type: 'INIT_RESOURCE_INSTANCE_STATE' })
      setResourceInstanceError('')
      dispatch({ type: 'INIT_SCOPE_GROUP_INSTANCE_STATE' })
      setScopeGroupInstanceError('')

      let getSubjectURL = '/identity/v2alpha1/scim/v2/Users'
      if (option?.value === 'GROUP') {
        getSubjectURL = '/identity/v2alpha1/scim/v2/Groups'
      } else if (option?.value === 'APICLIENT') {
        getSubjectURL = '/internal-identity/v1alpha1/pce-api-clients'
      }

      try {
        const response = await get(getSubjectURL, {})
        const subjects =
          option?.value === 'APICLIENT'
            ? response?.data?.items
            : response?.data?.Resources

        const options = []
        // update subject options
        subjects?.forEach((subject) => {
          options?.push({
            label: getSubjectName(subject, option?.value),
            value: subject
          })
        })

        dispatch({
          value: options,
          field: 'subjectOptions',
          type: 'CHANGE_FIELD'
        })
      } catch (error) {
        setApiError(error)
      }
    }

    if (field === 'scope' && state?.scopeGroupInstanceOptions?.length === 0) {
      try {
        const response = await get('/authorization/v2alpha1/scope-groups')
        const scopeGroups = response?.data?.items
        const scopeGroupOptions = []
        scopeGroups?.forEach((scopeGroup) => {
          scopeGroupOptions.push({
            label: scopeGroup?.name,
            desc: scopeGroup?.description,
            value: scopeGroup
          })
          dispatch({
            value: scopeGroupOptions,
            field: 'scopeGroupInstanceOptions',
            type: 'CHANGE_FIELD'
          })
        })
      } catch (error) {
        setApiError(error)
      }
    }

    if (field === 'roles') {
      try {
        setRoleAssignedLoading(true)
        setRoleAssigned(false) // Reset value for role selection change
        const provisionedServices =
          servicesProvisioned !== null
            ? servicesProvisioned
            : await getServicesProvisioned()
        if (preSelectedRole) {
          checkIfOneRoleApp(provisionedServices)
        }

        if (preSelectedSubject?.subjectType === 'USER') {
          const selectedRoleServiceId =
            option[0]?.value?.service_id ||
            option[0]?.value?.custom_role_service_id
          const oneRoleIds = getOneRoleAppIds(provisionedServices)

          const isOneRoleApp = oneRoleIds?.includes(selectedRoleServiceId)

          const roleIsAssigned = roleAssignments?.some(
            (roleAssignment) =>
              roleAssignment?.service_id === selectedRoleServiceId ||
              roleAssignment?.custom_role_service_id === selectedRoleServiceId
          )
          if (isOneRoleApp && roleIsAssigned) {
            setRoleAssigned(true)
            setServiceName(
              option[0]?.value?.service_name ||
                option[0]?.value?.custom_role_service_name
            )
          }
        }
        setRoleAssignedLoading(false)

        if (state?.scopeGroupInstanceOptions?.length === 0) {
          const appOptions = []
          const appIds = []

          const serviceToRegions = {}
          // build region list
          provisionedServices?.forEach((service) => {
            if (!serviceToRegions[service.application_id]) {
              serviceToRegions[service.application_id] = [
                { region_name: service.region_name, region: service.region }
              ]
            } else {
              serviceToRegions[service.application_id].push({
                region_name: service.region_name,
                region: service.region
              })
            }
          })

          provisionedServices?.forEach((service) => {
            // don't list multiples b/c of app instances
            if (!appIds.includes(service.application_id)) {
              appIds.push(service.application_id)

              service.regions = serviceToRegions[service.application_id]
              appOptions.push({
                label: service.name,
                value: service
              })
            }
          })

          dispatch({
            value: appOptions,
            field: 'serviceOptions',
            type: 'CHANGE_FIELD'
          })
        }
      } catch (error) {
        setApiError(error)
      }
    }

    if (field === 'service') {
      state.regionOptions.length = 0
      // try {
      // const response = await get(
      //   `/ui-doorway/ui/v1/applications/provisions?application_id=${option.value.application_id}`,
      //   {}
      // )
      // const provisions = response?.data?.provisions
      const regionOptions = []
      option?.value?.regions?.forEach((item) => {
        regionOptions.push({
          label: item?.region_name,
          value: item?.region
        })
      })

      dispatch({
        value: regionOptions,
        field: 'regionOptions',
        type: 'CHANGE_FIELD'
      })
      // } catch (error) {
      //   state?.regionOptions?.length = 0
      //   setApiError(error)
      // }

      state.resourceTypeOptions.length = 0
      try {
        const response = await get(
          '/internal-platform-tenant-ui/v2/resource-types',
          {
            application_id: option?.value?.application_id
          }
        )
        const resourceTypeOptions = filterScopelessResourceTypes(
          response?.data?.resource_types
        )
        dispatch({
          value: resourceTypeOptions,
          field: 'resourceTypeOptions',
          type: 'CHANGE_FIELD'
        })
      } catch (error) {
        state.resourceTypeOptions.length = 0
        setApiError(error)
      }
    }

    if (field === 'resourceType') {
      state.resourceInstanceOptions.length = 0
      // resourceType's option value is the slug
      const allInstancesOfResourceTypeSlug =
        getAllInstancesOfResourceTypeSlug(option)
      const resourceTypeGrn = `${workSpaceGrn}/regions/${state?.region?.value}/providers/${allInstancesOfResourceTypeSlug}`
      try {
        const response = await get(
          '/internal-authorization/v2alpha1/resources',
          {
            grn: resourceTypeGrn
          }
        )
        const resourceInstances = response?.data?.items
        const options = []
        resourceInstances?.forEach((resIns) => {
          options.push({
            label: resIns?.displayName,
            value: resIns
          })
        })

        dispatch({
          value: options,
          field: 'resourceInstanceOptions',
          type: 'CHANGE_FIELD'
        })
      } catch (error) {
        setApiError(error)
        state.resourceInstanceOptions.length = 0
      }
    }
  }

  useEffect(() => {
    let isMounted = true
    if (orgId && orgId !== '' && !preSelectedSubject) {
      setLoading(true)
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (response) => {
          if (!isMounted) return
          if (response?.data?.lifecycleState === LIFECYCLE_STATE.ACTIVE) {
            setShowUserGroupOption(true)
          }
          setLoading(false)
        },
        (error) => {
          setApiError(error)
          setLoading(false)
        }
      )
    }

    return () => {
      isMounted = false
    }
  }, [t, orgId, preSelectedSubject])

  useEffect(() => {
    getRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedText, state?.subjectType?.value])

  useEffect(() => {
    if (preSelectedRole) {
      // change role to ui-doorway format; UID should fix post MVP
      const uidRole = {
        role_id: preSelectedRole.id,
        role_name: preSelectedRole.name,
        role_display_name: preSelectedRole.displayName,
        role_description: preSelectedRole.description,
        role_grn: preSelectedRole.grn,
        type: preSelectedRole.type
      }

      handleDispatch(
        [
          {
            label: preSelectedRole.displayName,
            value: uidRole
          }
        ],
        'roles'
      )
    }

    if (preSelectedSubject) {
      let subjectTypeLabel
      if (preSelectedSubject?.subjectType === 'USER')
        subjectTypeLabel = t('common:business_object.user_capitalized')
      else if (preSelectedSubject?.subjectType === 'GROUP')
        subjectTypeLabel = t('common:business_object.group_capitalized')
      else subjectTypeLabel = t('iam:assign_roles_side_panel.api_client_label')
      dispatch({
        value: {
          label: subjectTypeLabel,
          value: preSelectedSubject?.subjectType
        },
        field: 'subjectType',
        type: 'CHANGE_FIELD'
      })

      handleDispatch(
        {
          label: getSubjectName(
            preSelectedSubject?.data,
            preSelectedSubject?.subjectType
          ),
          value: preSelectedSubject?.data
        },
        'subject'
      )
    }

    if (appId && appId === glcpServiceID) {
      // in case we are assigning role to roles with ccs permission from roles detail
      handleDispatch('ALL_WORKSPACE_OPTION', 'scope')
      setIsCCSRole(true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /* eslint consistent-return: "off" */
  const handleAssignClick = () => {
    if (fieldsToValidate()) {
      let scopes = null
      if (state?.scope === 'ALL_WORKSPACE_OPTION') {
        scopes = [workSpaceGrn]
      } else if (state?.resource === 'ALL_RESOURCES_OPTION') {
        const allInstancesOfResourceTypeSlug =
          getAllInstancesOfResourceTypeSlug(state?.resourceType)
        const resourceTypeGRN = `${workSpaceGrn}/regions/${state?.region?.value}/providers/${allInstancesOfResourceTypeSlug}`
        scopes = [resourceTypeGRN]
      } else if (state?.scope === 'SCOPE_GROUP_OPTION') {
        scopes = [state?.scopeGroupInstance?.value?.grn] // grn of selected scope group
      } else {
        scopes = state?.resourceInstances?.map((option) => {
          return option?.value?.grn
        })
      }

      if (isNewSubject) {
        const roles = state?.roles?.map((role) => {
          return role?.value
        })
        onAssign(roles, scopes)
        setShowAssignRoleModal(false)
      } else {
        const postBody = {
          assignments: {
            roles_grn: state?.roles?.map((roleOption) => {
              const role = roleOption?.value
              return role?.role_grn
            }),
            scopes
          }
        }

        const subjectId = state?.subject?.value?.id
        if (state?.subjectType?.value === 'USER') {
          postBody.user_id = subjectId
        } else if (state?.subjectType?.value === 'GROUP') {
          postBody.group_id = subjectId
        } else {
          postBody.client_id = subjectId
        }
        return new Promise((resolve) => {
          post(
            '/internal-platform-tenant-ui/v2/role-assignments',
            postBody
          ).then(
            (response) => {
              resolve()
              const totalRoles = state?.roles?.length
              const subjectName = state?.subject?.label
              const getIsUserText = () => {
                switch (state?.subjectType?.value) {
                  case 'GROUP':
                    return t('common:business_object.group')
                  case 'APICLIENT':
                    return t('iam:assign_roles_side_panel.api_client_label')
                  default:
                    return t('common:business_object.user')
                }
              }

              const PartialSuccessMessage = t(
                'iam:assign_roles_side_panel.partial_success_notification_role_msg'
              )

              const failedNotificationMessage = (
                <Markdown>
                  {t(
                    'iam:assign_roles_side_panel.failed_notification_role_msg',
                    {
                      roleName: state?.roles?.[0].label,
                      role: t('common:business_object.role'),
                      subjectName,
                      isUserText: getIsUserText()
                    }
                  )}
                </Markdown>
              )
              const successNotificationMessage =
                totalRoles === 1 ? (
                  <Markdown>
                    {t(
                      'iam:assign_roles_side_panel.success_notification_role_singular_msg',
                      {
                        roleName: state?.roles?.[0].label,
                        role: t('common:business_object.role'),
                        subjectName,
                        isUserText: getIsUserText()
                      }
                    )}
                  </Markdown>
                ) : (
                  <Markdown>
                    {t(
                      'iam:assign_roles_side_panel.success_notification_roles_plural_msg',
                      {
                        totalRoles,
                        roles: t('common:business_object.role_plural'),
                        subjectName,
                        isUserText: getIsUserText()
                      }
                    )}
                  </Markdown>
                )

              const alreadyExistingRoleCondition =
                response?.status === 206 &&
                response?.data?.failed[0]?.error_response[0]?.details
                  ?.httpStatusCode === 409 &&
                response?.data?.failed[0]?.error_response[0]?.details
                  ?.errorCode === 'HPE_GL_AUTHORIZATION_ALREADY_CREATED'

              if (alreadyExistingRoleCondition) {
                setAlreadyExistingRoleError(true)
                return
              }

              let notification = {
                message:
                  // eslint-disable-next-line no-nested-ternary
                  response?.status === 206 &&
                  response?.data?.success &&
                  response?.data?.failed
                    ? PartialSuccessMessage
                    : response?.status === 206 && response?.data?.success
                    ? successNotificationMessage
                    : failedNotificationMessage,
                severity: response?.data?.success ? 'info' : 'error',
                testId: 'assign-role-status',
                title: response?.data?.success
                  ? t('iam:assign_roles_side_panel.assign_role_success_msg', {
                      role: t('common:business_object.role_capitalized')
                    })
                  : t('iam:assign_roles_side_panel.assign_role_failure_msg')
              }
              if (response?.status === 201) {
                notification = {
                  ...notification,
                  message: successNotificationMessage
                }
              }
              setShowAssignRoleModal(false)
              setStatusNotification(notification)
              onAssign()
            },
            (error) => {
              setShowAssignRoleModal(false)
              setStatusNotification({
                message: error.message,
                severity: 'error',
                testId: 'assign-role-status',
                title: t('iam:assign_roles_side_panel.assign_role_failure_msg')
              })
            }
          )
        })
      }
    }
  }

  const subjectGivenOrSelected = state?.subject?.value || isNewSubject
  const roleGivenOrSelected = state?.roles?.length > 0
  const resourceTypeName = getResourceTypeName(state?.resourceType?.value)
  const scopeTypeName = getScopeTypeName(state?.scopeGroupInstance?.value)

  return (
    <>
      {apiError && displayApiError(apiError, t, setApiError)}
      <>
        {loading ? (
          <ModalDialog
            content={
              <Box
                fill
                align="center"
                justify="center"
                direction="row"
                pad={{ vertical: 'small', horizontal: 'large' }}
                background={{ color: 'background-front', opacity: 'strong' }}
              >
                <Loader label="Loading..." testId="assign-roles-loader" />
              </Box>
            }
            onClose={() => setShowAssignRoleModal(false)}
            testId="assign-role-side-drawer-loading-modal"
          />
        ) : (
          <ModalDialog
            header={
              <ModalHeader
                title={
                  <>
                    <Typography
                      level="2"
                      testId="assign-role-title"
                      type="heading"
                    >
                      {t('iam:role_assignment.assign_role', {
                        roles: t('common:business_object.role')
                      })}
                    </Typography>
                    {preSelectedSubject && !isNewSubject ? (
                      <Box width="medium" pad={{ top: 'small' }}>
                        {state?.subjectType?.value === 'GROUP' ? (
                          <>
                            <Markdown>
                              {t(
                                'iam:assign_roles_side_panel.subtitle_with_group',
                                {
                                  users: t(
                                    'common:business_object.user_plural'
                                  ),
                                  subjectName: state?.subject?.label,
                                  group: t('common:business_object.group'),
                                  roles: t('common:business_object.role_plural')
                                }
                              )}
                            </Markdown>
                            <Box margin={{ top: 'medium' }}>
                              <Notification
                                testId="assign-role-side-drawer-notification"
                                type="inline"
                                status="info"
                                text={t(
                                  'iam:assign_roles_side_panel.subtitle_with_group_info'
                                )}
                              />
                            </Box>
                          </>
                        ) : (
                          <Markdown>
                            {t(
                              'iam:assign_roles_side_panel.subtitle_with_subject',
                              {
                                role: t('common:business_object.role')
                              }
                            )}
                          </Markdown>
                        )}
                      </Box>
                    ) : (
                      <Box pad={{ top: 'small' }}>
                        <Typography type="text" color="light">
                          {state?.subjectType?.value === 'USER'
                            ? t('iam:assign_roles_side_panel.subtitle_user', {
                                role: t('common:business_object.role')
                              })
                            : t('iam:assign_roles_side_panel.subtitle', {
                                role: t('common:business_object.role')
                              })}
                        </Typography>
                      </Box>
                    )}
                  </>
                }
                onClose={() => setShowAssignRoleModal(false)}
              />
            }
            content={
              <>
                {loading ? (
                  <Loader testId="assign-role-side-drawer-loader" />
                ) : (
                  <CCSForm
                    errorMessage=""
                    testId="assign-role-side-drawer-form"
                  >
                    <Box
                      height="100%"
                      pad={{ right: 'small', bottom: 'small' }}
                      data-testid="assign-role-content-box"
                    >
                      <Box pad={{ top: 'small' }}>
                        {/*  subject selection */}
                        {!preSelectedSubject && !isNewSubject ? (
                          <Box>
                            <Box>
                              <SelectContainer
                                title={t(
                                  'iam:assign_roles_side_panel.subject_type_title'
                                )}
                                subtitle={t(
                                  'iam:assign_roles_side_panel.subject_type_subtitle'
                                )}
                              />
                              <FormField
                                required
                                error={subjectTypeError}
                                data-testid="subject-type-select-form"
                              >
                                <Select
                                  placeholder={t(
                                    'iam:users.multi_select_box_placeholder'
                                  )}
                                  options={subjectTypeOptions}
                                  labelKey="label"
                                  valueKey="value"
                                  value={state?.subjectType}
                                  onChange={({ option }) => {
                                    handleDispatch(option, 'subjectType')
                                    if (option) setSubjectTypeError('')
                                    setAlreadyExistingRoleError(false)
                                  }}
                                  required
                                />
                              </FormField>
                            </Box>

                            {state?.subjectType?.value && (
                              <Box>
                                <SelectContainer
                                  title={t(
                                    'iam:assign_roles_side_panel.subject_title'
                                  )}
                                />
                                <FormField
                                  required
                                  error={subjectError}
                                  data-testid="subject-select-form"
                                >
                                  <Select
                                    placeholder={t(
                                      'iam:users.multi_select_box_placeholder'
                                    )}
                                    options={state?.subjectOptions}
                                    labelKey="label"
                                    valueKey="value"
                                    value={state?.subject}
                                    onChange={({ option }) => {
                                      handleDispatch(option, 'subject')
                                      if (oneRoleApp) {
                                        setRoleAssigned(false) // Need to reset for when another user is selected
                                        checkIfRoleAssigned(option?.value)
                                      }
                                      if (option) setSubjectError('')
                                      setAlreadyExistingRoleError(false)
                                    }}
                                    required
                                  />
                                </FormField>
                              </Box>
                            )}
                          </Box>
                        ) : null}
                        {(!preSelectedRole && subjectGivenOrSelected) ||
                        isNewSubject ? (
                          <Box>
                            <SelectContainer
                              title={t(
                                'common:business_object.role_capitalized'
                              )}
                            />
                            <FormField
                              required
                              error={roleError}
                              data-testid="roles-select-form"
                              name="role-to-duplicate"
                            >
                              <Dropdown
                                placeholder={t(
                                  'iam:users.multi_select_box_placeholder'
                                )}
                                value={state?.roles}
                                labelKey="label"
                                valueKey="value"
                                dropHeight="medium"
                                options={state?.roleOptions}
                                searchPlaceholder={t('search')}
                                onChangeDropdown={(selectedRoles) => {
                                  handleDispatch('', 'scope')
                                  setIsCCSRole(false)
                                  if (
                                    selectedRoles?.value?.service_id ===
                                      glcpServiceID ||
                                    selectedRoles?.value
                                      ?.custom_role_service_id === glcpServiceID
                                  ) {
                                    // checking if role is ccs role or that role has ccs permission
                                    handleDispatch(
                                      'ALL_WORKSPACE_OPTION',
                                      'scope'
                                    )
                                    setIsCCSRole(true)
                                  }
                                  const roles = [selectedRoles]
                                  handleDispatch(roles, 'roles')
                                  if (selectedRoles) setRoleError('')
                                  setAlreadyExistingRoleError(false)
                                }}
                                onSearch={(searchText) => {
                                  handleDebouncedSearchValue(searchText)
                                }}
                                emptySearchMessage={
                                  rolesOptionsLoading ? (
                                    <Loader testId="roles-options-loader" />
                                  ) : (
                                    t('iam:roles_error.no_matches_msg')
                                  )
                                }
                                customRender={(role) => roleTemplate(role)}
                                testId="roles-multi-select"
                              />
                            </FormField>
                          </Box>
                        ) : null}
                        {!roleAssigned && !roleAssignedLoading && (
                          <>
                            {/* scope selection */}
                            {subjectGivenOrSelected &&
                            roleGivenOrSelected &&
                            !isCCSRole ? (
                              <Box pad={{ top: 'medium' }}>
                                <SelectContainer
                                  heading={t(
                                    'iam:assign_roles_side_panel.specify_role_scope_title',
                                    {
                                      role: t('common:business_object.role')
                                    }
                                  )}
                                  title={t(
                                    'authz:scope_groups.tab_labels.scope'
                                  )}
                                />
                                <FormField
                                  required
                                  error={scopeError}
                                  data-testid="scope-radio-btn-form"
                                >
                                  <RadioButtonGroup
                                    name="scope"
                                    options={scopeOptions}
                                    value={state?.scope}
                                    labelKey="label"
                                    valueKey="value"
                                    onChange={(event) => {
                                      const { value } = event.target
                                      handleDispatch(value, 'scope')
                                      setAlreadyExistingRoleError(false)
                                      if (event.target.value) setScopeError('')
                                    }}
                                  />
                                </FormField>
                              </Box>
                            ) : null}

                            {/* resource category selection */}
                            {roleGivenOrSelected &&
                              (state?.scope === 'RESOURCE_OPTION' ? (
                                <Box>
                                  <Box>
                                    <SelectContainer
                                      title={t(
                                        'auditlogs:audit_logs.service_manager'
                                      )}
                                      subtitle={t(
                                        'iam:assign_roles_side_panel.select_service_subtitle'
                                      )}
                                      value={state?.service?.value}
                                    />

                                    <FormField
                                      required
                                      error={serviceError}
                                      data-testid="service-select-form"
                                    >
                                      <Select
                                        placeholder={t(
                                          'iam:users.multi_select_box_placeholder'
                                        )}
                                        options={state?.serviceOptions}
                                        labelKey="label"
                                        valueKey="value"
                                        value={state?.service}
                                        onChange={({ option }) => {
                                          handleDispatch(option, 'service')
                                          if (option) setServiceError('')
                                        }}
                                        required
                                      />
                                    </FormField>
                                  </Box>
                                  <Box>
                                    <SelectContainer
                                      title={t(
                                        'iam:assign_roles_side_panel.select_region_title'
                                      )}
                                      subtitle={t(
                                        'iam:assign_roles_side_panel.select_region_subtitle'
                                      )}
                                    />
                                    <FormField
                                      required
                                      error={regionError}
                                      data-testid="region-select-form"
                                    >
                                      <Select
                                        placeholder={t(
                                          'iam:users.multi_select_box_placeholder'
                                        )}
                                        options={state?.regionOptions}
                                        labelKey="label"
                                        valueKey="value"
                                        value={state?.region}
                                        onChange={({ option }) => {
                                          handleDispatch(option, 'region')
                                          if (option) setRegionError('')
                                        }}
                                        required
                                      />
                                    </FormField>
                                  </Box>
                                  <Box>
                                    <SelectContainer
                                      title={t(
                                        'iam:assign_roles_side_panel.select_resource_type_title'
                                      )}
                                      subtitle={t(
                                        'iam:assign_roles_side_panel.select_resource_type_subtitle'
                                      )}
                                    />
                                    <FormField
                                      required
                                      error={resourceTypeError}
                                      data-testid="resourceType-select-form"
                                    >
                                      <Dropdown
                                        placeholder={t(
                                          'iam:users.multi_select_box_placeholder'
                                        )}
                                        options={state?.resourceTypeOptions}
                                        labelKey="label"
                                        valueKey="value"
                                        value={state?.resourceType}
                                        onChange={({ option }) => {
                                          handleDispatch(option, 'resourceType')
                                          if (option) setResourceTypeError('')
                                        }}
                                        customRender={(resourceType) =>
                                          resourceTypeTemplate(resourceType)
                                        }
                                        testId="resourceType-dropdown"
                                        required
                                      />
                                    </FormField>
                                  </Box>
                                  {state?.resourceType && (
                                    <Box>
                                      <SelectContainer
                                        title={t(
                                          'iam:users.multi_select_box_placeholder'
                                        )}
                                      />
                                      <FormField
                                        required
                                        error={resourceError}
                                        data-testid="resource-radio-btn-form"
                                      >
                                        <RadioButtonGroup
                                          name="resource-radio-btn-group"
                                          id="resource-radio-btn-group"
                                          value={state?.resource}
                                          options={resourceOptions()}
                                          labelKey="label"
                                          valueKey="value"
                                          onChange={(event) => {
                                            handleDispatch(
                                              event.target.value,
                                              'resource'
                                            )
                                            handleDispatch(
                                              [],
                                              'resourceInstances'
                                            )
                                            if (event.target.value)
                                              setResourceError('')
                                          }}
                                        />
                                      </FormField>
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                <Box width="medium">
                                  {state?.scope === 'ALL_WORKSPACE_OPTION' && (
                                    <Typography
                                      type="text"
                                      testId="role-scoped-to-all-workspace-text"
                                    >
                                      {t(
                                        'iam:assign_roles_side_panel.select_all_resource_msg',
                                        {
                                          workspace: t(
                                            'common:business_object.wkspc'
                                          )
                                        }
                                      )}
                                    </Typography>
                                  )}
                                </Box>
                              ))}
                            {/* resource instance selection */}
                            {roleGivenOrSelected &&
                              state?.scope === 'RESOURCE_OPTION' &&
                              (state?.resource === `SOME_RESOURCES_OPTION` ? (
                                <Box>
                                  <SelectContainer
                                    title={t(
                                      'iam:assign_roles_side_panel.select_resource_instance_title'
                                    )}
                                    subtitle={t(
                                      'iam:assign_roles_side_panel.select_resource_instance_subtitle',
                                      {
                                        role: t('common:business_object.role')
                                      }
                                    )}
                                  />
                                  <FormField
                                    required
                                    error={resourceInstanceError}
                                    data-testid="resource-instance-multi-select-form"
                                  >
                                    <MultiSelectBox
                                      limit={multiSelectBoxLimit}
                                      placeholder={t(
                                        'iam:users.multi_select_box_placeholder'
                                      )}
                                      labelKey="label"
                                      valueKey="value"
                                      options={state.resourceInstanceOptions}
                                      dropHeight="medium"
                                      help={
                                        <Box
                                          data-testid="multiselect-help-description"
                                          direction="row"
                                          justify="between"
                                          flex={false}
                                          pad={{
                                            horizontal: 'xsmall',
                                            bottom: 'xsmall'
                                          }}
                                        >
                                          <Text>
                                            {t('iam:users.select_up_to_msg', {
                                              limit: multiSelectBoxLimit
                                            })}
                                          </Text>
                                        </Box>
                                      }
                                      onChange={(selectedArr) => {
                                        handleDispatch(
                                          selectedArr,
                                          'resourceInstances'
                                        )
                                        if (selectedArr)
                                          setResourceInstanceError('')
                                      }}
                                      testId="resource-instance-multi-select"
                                    >
                                      {resourceInstanceTemplate}
                                    </MultiSelectBox>
                                  </FormField>
                                </Box>
                              ) : (
                                state?.resource === `ALL_RESOURCES_OPTION` && (
                                  <Box width="medium">
                                    <Typography type="text">
                                      <Markdown>
                                        {t(
                                          'iam:assign_roles_side_panel.select_scope_with_all_resource_msg',
                                          {
                                            resourceType: resourceTypeName,
                                            region: state?.region?.label,
                                            service: state?.service?.label
                                          }
                                        )}
                                      </Markdown>
                                    </Typography>
                                  </Box>
                                )
                              ))}

                            {/* selection summary text */}
                            {roleGivenOrSelected &&
                              state?.scope === 'RESOURCE_OPTION' &&
                              state?.resource === `SOME_RESOURCES_OPTION` &&
                              state?.resourceInstances?.length > 0 &&
                              (state?.resourceInstances?.length === 1 ? (
                                <Box width="medium">
                                  <Typography type="text">
                                    <Markdown>
                                      {t(
                                        'iam:assign_roles_side_panel.select_one_scope_msg',
                                        {
                                          resourceType: resourceTypeName
                                        }
                                      )}
                                    </Markdown>
                                  </Typography>
                                </Box>
                              ) : (
                                <Box width="medium">
                                  <Typography type="text">
                                    <Markdown>
                                      {t(
                                        'iam:assign_roles_side_panel.select_specific_scope_msg',
                                        {
                                          totalSelected:
                                            state?.resourceInstances?.length,
                                          resourceType: resourceTypeName,
                                          region: state?.region?.label,
                                          service: state?.service?.label
                                        }
                                      )}
                                    </Markdown>
                                  </Typography>
                                </Box>
                              ))}

                            {roleGivenOrSelected &&
                              (state?.scope === 'SCOPE_GROUP_OPTION' ? (
                                <Box>
                                  <Box>
                                    <SelectContainer
                                      title={t(
                                        'iam:assign_roles_side_panel.select_scope_group_title'
                                      )}
                                      value={state?.service?.value}
                                    />

                                    <FormField
                                      required
                                      error={scopeGroupInstanceError}
                                      data-testid="scope-group-select-form"
                                    >
                                      <Dropdown
                                        id="scope-group-dropdown"
                                        testId="scope-group-dropdown"
                                        name="scope_group_instance"
                                        multiple={false}
                                        options={
                                          state?.scopeGroupInstanceOptions
                                        }
                                        labelKey="label"
                                        valueKey="value"
                                        value={state?.scopeGroupInstance}
                                        dropAlign={{ bottom: 'top' }} // Dropdown values comes on top the Select box
                                        required
                                        placeholder={t(
                                          'iam:users.multi_select_box_placeholder'
                                        )}
                                        onChange={({ option }) => {
                                          handleDispatch(
                                            option,
                                            'scopeGroupInstance'
                                          )
                                          if (option)
                                            setScopeGroupInstanceError('')
                                          setAlreadyExistingRoleError(false)
                                        }}
                                        customRender={(option) => (
                                          <DropdownCustomRender
                                            name={option.label}
                                            description={option.desc || ''}
                                          />
                                        )}
                                      />
                                    </FormField>
                                  </Box>
                                </Box>
                              ) : null)}
                            {state?.scopeGroupInstance &&
                              state?.scope !== 'ALL_WORKSPACE_OPTION' && (
                                <Box width="medium">
                                  <Typography type="text">
                                    <Markdown>
                                      {t(
                                        'iam:assign_roles_side_panel.select_a_scope_msg',
                                        {
                                          scopeType: scopeTypeName
                                        }
                                      )}
                                    </Markdown>
                                  </Typography>
                                </Box>
                              )}
                          </>
                        )}
                        {roleAssignedLoading && (
                          <Box alignSelf="center" pad={{ top: 'small' }}>
                            <Loader testId="role-assigned-loader" />
                          </Box>
                        )}
                        {roleAssigned && (
                          <Box pad={{ top: 'small' }}>
                            <Notification
                              type="inline"
                              text={
                                <Markdown>
                                  {t(
                                    preSelectedSubject?.subjectType === 'USER'
                                      ? 'iam:assign_roles_side_panel.one_role_assigned'
                                      : 'iam:assign_roles_side_panel.role_assigned_on_subject',
                                    {
                                      serviceName
                                    }
                                  )}
                                </Markdown>
                              }
                              status="critical"
                              testId="one-role-app-warning"
                            />
                          </Box>
                        )}
                        {alreadyExistingRoleError && (
                          <Box pad={{ top: 'small' }}>
                            <Notification
                              type="inline"
                              text={
                                <Markdown>
                                  {t(
                                    'authz:409_error_messages.role_assignment_already_created_error'
                                  )}
                                </Markdown>
                              }
                              status="critical"
                              testId="already-existing-role-warning"
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </CCSForm>
                )}
              </>
            }
            footer={
              <Box direction="row" gap="medium">
                <Button
                  primary
                  label={t('common:assign')}
                  type="submit"
                  onClick={() => {
                    if (!roleAssigned) {
                      handleAssignClick()
                    }
                  }}
                  testId="btn-assign"
                />
                <Button
                  label={t('common:cancel')}
                  type="reset"
                  onClick={handleDiscardChanges}
                  testId="btn-cancel"
                />
              </Box>
            }
            onClose={() => setShowAssignRoleModal(false)}
            position="right"
            height="100%"
            width={{ min: 'full', max: 'medium' }}
            overflow="hidden"
            testId="assign-role-side-drawer"
          />
        )}
      </>
    </>
  )
}

AssignRoleSideDrawer.propTypes = {
  /**
   * Pre-selected subject contains a 'string' subject type and a subject.
   */
  preSelectedSubject: PropTypes.object,

  /**
   * preselected role object
   */
  preSelectedRole: PropTypes.object,

  /**
   * Callback to indicate whether the modal should be opened or closed.
   */
  setShowAssignRoleModal: PropTypes.func.isRequired,

  /**
   * Callback to set the success or error status back to caller.
   */
  setStatusNotification: PropTypes.func,

  /**
   * Callback to pass role and scope array back to the caller when the AssignRoleSideDrawer is called from another Modal or do do refresh
   */
  onAssign: PropTypes.func,

  /**
   * Application id of the role - needed to determine if one role application
   */
  appId: PropTypes.string,

  /**
   * role assigned to user
   */
  roleAssignments: PropTypes.array
}

AssignRoleSideDrawer.defaultProps = {
  preSelectedSubject: null,
  preSelectedRole: null,
  setStatusNotification: null,
  onAssign: null,
  appId: null,
  roleAssignments: []
}

export default AssignRoleSideDrawer
