import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { hpe } from 'grommet-theme-hpe'
import { times } from 'lodash-es'

const SkeletonList = ({ rowCount }) => {
  const largeHeight = parseInt(hpe.text?.large?.height || '28px', 10)
  const smallHeight = parseInt(hpe.text?.small?.height || '22px', 10)
  const skeletons = times(rowCount, (i) => (
    <Box border="bottom" pad={{ vertical: 'medium' }} key={i}>
      <Box
        // The actual text has line heights bigger than the text itself so we add an arbitrary margin between
        // them of 4px, 2 from each height for a nicer looking skeleton
        margin={{ left: 'medium', bottom: '4px' }}
        width="large"
        height={`${largeHeight - 2}px`}
        background="status-unknown"
      />
      <Box
        margin={{ left: 'medium' }}
        width="small"
        height={`${smallHeight - 2}px`}
        background="status-unknown"
      />
    </Box>
  ))
  return <>{skeletons}</>
}

SkeletonList.propTypes = {
  rowCount: PropTypes.number.isRequired
}

export default SkeletonList
