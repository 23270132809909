import React from 'react'
import { ShareRounded, LinkNext } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  isStagingCluster,
  isQACluster,
  getCustomerAccount
} from '../../../utils/feature-flag-utils'

import glcFeaturedAppImage from './greenlake-central-featured-app.png'

const FeatureAppsCardData = () => {
  const custAccountLoaded = getCustomerAccount()
  const LDFlags = useFlags()
  const opsRampFlag = LDFlags['glcp-ops-ramp']
  const platformCid = custAccountLoaded?.platform_customer_id || ''

  const getASMCluster = () => {
    if (isStagingCluster()) {
      return 'https://asm-stg.arubanetworks.com/login'
    }
    return 'https://si.arubanetworks.com/'
  }
  const getASPCluster = () => {
    if (isStagingCluster()) {
      return 'https://asp-stg-develop.k8s-prod.getaws.arubanetworks.com/'
    }
    return 'https://asp.arubanetworks.com/'
  }
  const getInfoSightCluster = () => {
    if (isQACluster()) {
      return 'https://dev.infosight.hpe.com'
    }
    if (isStagingCluster()) {
      return 'https://beta.infosight.hpe.com'
    }
    return 'https://infosight.hpe.com/'
  }
  const getClusterSlugs = (slug) => {
    // if defined other staging clusters' slug here, only for staging cluster display
    switch (slug) {
      case 'CENTRAL':
        return isStagingCluster() ? ['CEN-YDA'] : ['CENTRAL']
      case 'HPECC':
        return ['HPECC']
      case 'INFOSTE':
        return ['INFOSTE']
      case 'DATASVS':
        return isStagingCluster() ? ['SCDEV-H'] : ['DATASVS']
      case 'ASP':
        return ['ASP']
      case 'ASM':
        return ['ASM']
      default:
        return []
    }
  }

  return [
    {
      id: 1,
      title: 'aruba_network_manager.title',
      description: 'aruba_network_manager.description',
      background: 'validation-warning',
      gap: 'small',
      align: 'stretch',
      textAlign: 'center',
      actionBtnLbl: 'aruba_network_manager.btn_lbl',
      actionBtnColor: 'orange',
      anchor: {
        label: 'aruba_network_manager.anchor_lbl',
        href: 'https://www.hpe.com/us/en/networking.html'
      },
      testId: 'nms-card',
      visible: true,
      rbac: {
        resource: '/ccs/geo',
        permission: 'ccs.geo.view'
      },
      native: true,
      slug: getClusterSlugs('CENTRAL') // Aruba Central
    },
    {
      id: 2,
      title: 'hpe_storage_central.title',
      description: 'hpe_storage_central.description',
      background: 'validation-ok',
      gap: 'small',
      align: 'stretch',
      textAlign: 'center',
      actionBtnLbl: 'hpe_storage_central.btn_lbl',
      actionBtnColor: 'green',
      anchor: {
        label: 'hpe_storage_central.anchor_lbl',
        href: 'https://www.hpe.com/us/en/storage.html'
      },
      testId: 'storage-card',
      visible: true,
      hideFor: { account: ['MSP', 'TENANT'], deployment: ['COP'] },
      rbac: {
        resource: '/ccs/geo',
        permission: 'ccs.geo.view'
      },
      native: true,
      slug: getClusterSlugs('DATASVS') // Data Services Cloud Console
    },
    {
      id: 3,
      title: 'compute_cloud_console.title',
      description: 'compute_cloud_console.description',
      background: 'background-back',
      gap: 'small',
      align: 'stretch',
      textAlign: 'center',
      actionBtnLbl: 'compute_cloud_console.btn_lbl',
      actionBtnColor: 'green',
      anchor: {
        label: 'compute_cloud_console.anchor_lbl',
        href: 'https://www.hpe.com/us/en/compute.html'
      },
      testId: 'compute_cloud_card',
      visible: true,
      hideFor: { account: ['MSP', 'TENANT'], deployment: ['COP'] },
      rbac: {
        resource: '/ccs/geo',
        permission: 'ccs.geo.view'
      },
      native: true,
      slug: getClusterSlugs('HPECC') // Compute Cloud Console
    },
    {
      id: 4,
      title: 'hpe_greenlake_central.title',
      description: 'hpe_greenlake_central.description',
      background: {
        image: `url(${glcFeaturedAppImage})`,
        size: 'cover',
        repeat: 'no-repeat',
        dark: false
      },
      gap: 'small',
      align: 'stretch',
      textAlign: 'center',
      foreground: '#ffffff',
      actionBtnLbl: 'hpe_greenlake_central.btn_lbl',
      actionBtnColor: 'green',
      anchor: {
        label: 'hpe_greenlake_central.anchor_lbl',
        href: 'https://www.hpe.com/us/en/greenlake.html'
      },
      actionBtnIcon: <ShareRounded color="black" />,
      testId: 'hpe_greenlake_central-card',
      hrefTo: `https://client.greenlake.hpe.com?pcid=${platformCid}`,
      visible: true,
      hideFor: { account: ['MSP', 'TENANT'], deployment: ['COP'] },
      native: false,
      slug: 'CCS'
    },
    {
      id: 5,
      title: 'opsramp.title',
      description: 'opsramp.description',
      background: 'background-contrast',
      gap: 'small',
      align: 'start',
      direction: 'row',
      elevation: 'none',
      actionBtnLbl: 'opsramp.btn_lbl',
      actionBtnIcon: opsRampFlag ? <LinkNext /> : <ShareRounded />,
      testId: 'opsramp-card',
      hrefTo: 'https://sdk.opsramp.net/setup.do?action=view',
      visible: true,
      hideFor: {
        account: ['MSP', 'TENANT'],
        deployment: ['COP'],
        feature: 'glcp-opsramp-card'
      },
      rbac: {
        resource: '/ccs/geo',
        permission: 'ccs.geo.view'
      },
      native: opsRampFlag,
      slug: 'OPSRAMP'
    },
    {
      id: 6,
      title: 'aruba_support_portal.title',
      description: 'aruba_support_portal.description',
      background: 'background-contrast',
      gap: 'small',
      align: 'start',
      direction: 'row',
      elevation: 'none',
      actionBtnLbl: 'aruba_support_portal.btn_lbl',
      actionBtnIcon: <ShareRounded />,
      testId: 'aruba-support-portal-card',
      hrefTo: getASPCluster(),
      visible: true,
      hideFor: {
        account: ['TENANT'],
        deployment: ['COP'],
        feature: 'glcp-home-asp-card'
      },
      rbac: {
        resource: '/ccs/geo',
        permission: 'ccs.geo.view'
      },
      native: false,
      slug: getClusterSlugs('ASP')
    },
    {
      id: 7,
      title: 'hpe_infoSight.title',
      description: 'hpe_infoSight.description',
      background: 'background-contrast',
      gap: 'small',
      align: 'start',
      direction: 'row',
      elevation: 'none',
      actionBtnLbl: 'hpe_infoSight.btn_lbl',
      actionBtnIcon: <ShareRounded />,
      testId: 'hpe-infosight-card',
      hrefTo: getInfoSightCluster(),
      visible: true,
      hideFor: {
        account: ['MSP', 'TENANT'],
        deployment: ['COP']
      },
      rbac: {
        resource: '/ccs/geo',
        permission: 'ccs.geo.view'
      },
      native: false,
      slug: getClusterSlugs('INFOSTE')
    },
    {
      id: 8,
      title: 'hpe_greenlake_for_aruba.title',
      description: 'hpe_greenlake_for_aruba.description',
      background: 'background-contrast',
      gap: 'small',
      align: 'start',
      direction: 'row',
      elevation: 'none',
      actionBtnLbl: 'hpe_greenlake_for_aruba.btn_lbl',
      actionBtnIcon: <ShareRounded />,
      testId: 'hpe-greenlake-for-aruba-card',
      hrefTo: getASMCluster(),
      visible: true,
      hideFor: { deployment: ['COP'], feature: 'glcp-home-asm-card' },
      rbac: {
        resource: '/ccs/geo',
        permission: 'ccs.geo.view'
      },
      native: false,
      slug: getClusterSlugs('ASM')
    },
    {
      id: 9,
      title: 'hpe_greenlake_developer_portal.title',
      description: 'hpe_greenlake_developer_portal.description',
      background: 'background-contrast',
      gap: 'small',
      align: 'start',
      direction: 'row',
      elevation: 'none',
      actionBtnLbl: 'hpe_greenlake_developer_portal.btn_lbl',
      actionBtnIcon: <ShareRounded />,
      testId: 'hpe-greenlake-developer-portal',
      hrefTo: 'https://developer.greenlake.hpe.com/idp-login',
      visible: true,
      hideFor: { deployment: ['COP'], feature: 'glcp-api-portal' },
      native: false,
      slug: 'CCS'
    },
    {
      id: 10,
      title: 'wellness_dashboard.title',
      description: 'wellness_dashboard.description',
      background: 'background-contrast',
      gap: 'small',
      align: 'start',
      direction: 'row',
      elevation: 'none',
      actionBtnLbl: 'wellness_dashboard.btn_lbl',
      actionBtnIcon: <ShareRounded />,
      testId: 'wellness-dashboard',
      routeTo: '/wellness-dashboard',
      visible: true,
      hideFor: {
        feature: 'glcp-unified-wellness-dashboard',
        account: ['TENANT', 'MSP'],
        deployment: ['COP']
      },
      rbac: {
        resource: '/ccs/wellness-dashboard',
        permission: 'ccs.wellness-dashboard.view'
      },
      native: false,
      slug: 'CCS'
    }
  ]
}

export { FeatureAppsCardData }
