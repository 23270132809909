import React from 'react'
import { Box } from 'grommet'
import { StatusCritical } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import { ButtonGroup, PageLayout, Typography } from '../../components'
import { HeaderWithBrandOnly } from '../commoncomponents/layout/header-cases/HeaderCases'

// This page can be turned on manually through an LD flag by SRE team when pingfed is down
// or other conditions exist when login page cannot be displayed.
function SREMaintenance() {
  const { t } = useTranslation(['authn'])

  return (
    <Box direction="column" align="center" justifyContent="center" pad="xlarge">
      <StatusCritical color="status-critical" size="large" />
      <Box pad={{ top: 'medium', bottom: 'medium' }}>
        <Typography
          type="heading"
          level="3"
          testId="greenlake-unavailable-title"
        >
          {t('acct_onboarding.app_unavailable_heading')}
        </Typography>
        <Typography
          type="text"
          size="large"
          testId="greenlake-unavailable-subtitle"
        >
          {t('acct_onboarding.app_unavailable_desc')}
        </Typography>
      </Box>
      <Box direction="row" justify="center">
        <ButtonGroup
          buttonList={[
            {
              label: t('acct_onboarding.go_to_hpe'),
              primary: true,
              onClick: () => {
                window.location.replace(
                  'https://www.hpe.com/us/en/greenlake.html'
                )
              },
              margin: { top: 'xsmall' },
              testId: 'go-to-hpe-btn'
            },
            {
              label: t('acct_onboarding.contact_support'),
              secondary: true,
              onClick: () => {
                window.open(
                  'https://h41390.www4.hpe.com/support/index.html?form=loginsupport',
                  '_blank',
                  'noopener'
                )
              },
              type: 'submit',
              margin: { top: 'xsmall' },
              testId: 'contact-support-btn'
            }
          ]}
          testId="login-error-buttons"
        />
      </Box>
    </Box>
  )
}

const SREMaintenancePage = () => {
  return (
    <PageLayout header={<HeaderWithBrandOnly />}>
      <SREMaintenance />
    </PageLayout>
  )
}

export default SREMaintenancePage
