import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import PropTypes from 'prop-types'
import { Markdown } from 'grommet'

import { Typography, Wizard } from '../../../components'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'
import { isServiceCentric } from '../../../utils/account-utils'

import { ServiceDeploymentRequired } from './steps'

const ServiceDeploymentWizard = ({
  handleContinue,
  isContinueButtonRequired,
  defaultFormValues
}) => {
  const { t } = useTranslation(['licensing', 'common'])
  const history = useHistory()
  const { company_name: workspaceName } = getCustomerAccount()
  const LDFlags = useFlags
  const newDashboardFlag = isServiceCentric(LDFlags)

  const handleViewServiceCatalog = () => {
    if (newDashboardFlag) history.push('/services/service-catalog')
    else history.push('/applications/available-apps')
  }

  return (
    <Wizard
      actionOnExit={() => {
        history.push('/home')
      }}
      actionOnSubmit={handleViewServiceCatalog}
      formDefaultValues={defaultFormValues}
      cancelStrings={{
        continueLabel: t('common:continue'),
        exitLabel: t('exit'),
        heading: t('exit'),
        text: t('exit_desc')
      }}
      buttonLabels={{
        ...(isContinueButtonRequired() && {
          otherActions: [
            {
              label: t('common:continue'),
              handleOnClick: handleContinue,
              testId: 'continue-btn',
              secondary: true,
              showInStep: 1
            }
          ]
        }),
        finish: t('view_services'),
        cancel: { label: t('exit') }
      }}
      steps={[
        {
          childComponents: <ServiceDeploymentRequired />,
          description: (
            <Typography type="paragraph" testId="step-description" size="large">
              <Markdown>
                {t(
                  isContinueButtonRequired()
                    ? 'service_deployment_required_continue_desc'
                    : 'service_deployment_required_desc',
                  {
                    workspaceName
                  }
                )}
              </Markdown>
            </Typography>
          ),
          disableStepCount: true,
          title: t('service_deployment_required')
        }
      ]}
      testId="service-deployment-required-wizard"
      title={t('service_deployment_required')}
    />
  )
}
ServiceDeploymentWizard.propTypes = {
  handleContinue: PropTypes.func.isRequired,
  isContinueButtonRequired: PropTypes.func.isRequired,
  defaultFormValues: PropTypes.object.isRequired
}

export { ServiceDeploymentWizard }
