import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StatusWarningSmall } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ModalDialog,
  ButtonGroup,
  ModalHeader,
  Typography,
  CCSForm,
  Notification
} from '../../../../components'
import { handleDelete } from '../api-utils'
import Markdown from '../../../commoncomponents/Markdown'

const DeleteIPAccessModal = ({
  setDeleteIPAccessModal,
  selectedRows,
  onSuccess,
  deletingAllRules,
  setInitCount,
  ipAccessRulesEnabled,
  data
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const [errorMessage, setErrorMessage] = useState('')
  const { oidcUser } = useReactOidc()
  const isLastData = data.length === 1
  const isSingleRuleSelected = selectedRows.length === 1
  const successMsg = isSingleRuleSelected
    ? t('ip_access_rules.single_deletion_success_msg')
    : t('ip_access_rules.deletion_success_msg')
  const handleConfirm = () => {
    handleDelete(
      [selectedRows[0].ip_access_rule_id],
      oidcUser.access_token,
      setDeleteIPAccessModal,
      setErrorMessage,
      t,
      onSuccess,
      successMsg,
      deletingAllRules,
      setInitCount
    )
  }
  const getDeleteModalTitle = () => {
    const title = t('ip_access_rules.single_delete_modal_title')
    return title
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              testId="delete-access-modal-title"
              type="heading"
              level="1"
            >
              {getDeleteModalTitle()}
            </Typography>
          }
        />
      }
      content={
        <CCSForm
          errorMessage={errorMessage}
          testId="delete-ip-access-rules-form"
        >
          <>
            <Box margin={{ top: 'medium' }} gap="xsmall">
              {ipAccessRulesEnabled && !isLastData && (
                <>
                  <Typography
                    type="text"
                    margin={{ bottom: 'small' }}
                    testId="delete-access-modal-desc-1"
                  >
                    <Markdown>
                      {t('ip_access_rules.delete_ip_access_message_1', {
                        ip_access_rule: selectedRows[0]?.access_rule
                      })}
                    </Markdown>
                  </Typography>

                  <Notification
                    type="inline"
                    margin={{ vertical: 'xsmall' }}
                    icon={
                      <StatusWarningSmall size="large" color="status-warning" />
                    }
                    backgroundColor="status-warning"
                    testId="warning-inline-notification"
                    status="warning"
                    text={t('ip_access_rules.delete_ip_access_message_warn')}
                  />
                </>
              )}
              {!ipAccessRulesEnabled && !isLastData && (
                <>
                  <Typography
                    type="text"
                    margin={{ bottom: 'small' }}
                    testId="delete-access-modal-desc-2"
                  >
                    <Markdown>
                      {t('ip_access_rules.delete_ip_access_message_2', {
                        ip_access_rule: selectedRows[0]?.access_rule
                      })}
                    </Markdown>
                  </Typography>
                </>
              )}
              {isLastData && ipAccessRulesEnabled && (
                <>
                  <Typography
                    type="text"
                    margin={{ bottom: 'small' }}
                    testId="delete-access-modal-desc-3"
                  >
                    {t('ip_access_rules.delete_ip_access_message_4')}
                  </Typography>
                </>
              )}
              <Typography
                type="text"
                margin={{ bottom: 'xsmall' }}
                testId="confirm-delete"
              >
                {t('ip_access_rules.delete_ip_access_message_3')}
              </Typography>
            </Box>
            <Box margin={{ vertical: 'xsmall' }}>
              <ButtonGroup
                buttonList={[
                  {
                    id: 0,
                    label: t('common:cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    onClick: () => setDeleteIPAccessModal(false)
                  },
                  {
                    id: 1,
                    label: t('ip_access_rules.delete_ip_button'),
                    primary: true,
                    testId: 'confirm-btn',
                    onClick: handleConfirm
                  }
                ]}
                testId="delete-access-buttons"
              />
            </Box>
          </>
        </CCSForm>
      }
      onClose={() => setDeleteIPAccessModal(false)}
      testId="delete-access-modal-dialog"
    />
  )
}

DeleteIPAccessModal.propTypes = {
  setDeleteIPAccessModal: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
  deletingAllRules: PropTypes.bool.isRequired,
  setInitCount: PropTypes.func.isRequired,
  ipAccessRulesEnabled: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired
}

export { DeleteIPAccessModal }
