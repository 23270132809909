import { API_DATE_FORMAT } from '@glcp/coveo-headless'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export function getDaysBetweenDates(date, format) {
  const parsedDate = dayjs(date, format)

  if (!parsedDate.isValid() && !format) {
    return dayjs().diff(dayjs(date, API_DATE_FORMAT), 'day')
  }

  return dayjs().diff(parsedDate, 'day')
}
