import { CATEGORY, OTHER } from '../constants'
import { getServiceDetailsPath } from '../../utils/common-utils'
import { isStagingCluster, isQACluster } from '../../utils/feature-flag-utils'

/**
 * map of all external apps that are not currently managed via the login-url endpoint
 * note that these endpoints will eventually be abstracted via the MFE reverse proxy,
 * and at that point this map will no longer be needed
 * */
const serviceRedirectMap = {
  WD: () => '/wellness-dashboard',
  INFOSTE: () => {
    // eslint-disable-next-line no-nested-ternary
    const subdomain = isQACluster() ? 'dev.' : isStagingCluster() ? 'beta.' : ''
    return `https://${subdomain}infosight.hpe.com`
  },
  OPSRAMP: () => 'https://sdk.opsramp.net/setup.do?action=view'
}

/**
 * @typedef {Object} Definition
 * @property {string[]} [workspaceTypes]
 * @property {string} [serviceManagerSlug]
 * @property {string} serviceSlug
 * @property {string} name
 * @property {string} category
 * @property {string} [testDriveUrl]
 * @property {string} [contactSalesUrl]
 * @property {string} [termsOfServiceUrl]
 * @property {string} [documentationUrl] // this may be required in the future
 * */

/**
 * @property {string[]} workspaceTypes
 * @property {string} serviceManagerSlug
 * @property {string} serviceSlug
 * @property {boolean} external
 * @property {boolean} isServiceManager
 * @property {string} name
 * @property {string} detailsPath
 * @property {string} category
 * @property {string} testDriveUrl
 * @property {string} documentationUrl
 * @property {string} contactSalesUrl
 * @property {string} termsOfServiceUrl
 * */

export class Service {
  /** @param {Definition} definition */
  constructor({
    workspaceTypes = ['STANDALONE'],
    serviceManagerSlug = OTHER,
    serviceSlug,
    name,
    category = CATEGORY.MANAGEMENT_AND_GOVERNANCE,
    testDriveUrl,
    documentationUrl = '',
    contactSalesUrl = 'https://www.hpe.com/us/en/contact-hpe.html',
    termsOfServiceUrl = 'https://www.hpe.com/psnow/doc/a50009054ENW',
    bypassStaticRedirectUrl,
    quoteUrl = '',
    featuresSupported = []
  }) {
    this.workspaceTypes = workspaceTypes
    this.serviceManagerSlug = serviceManagerSlug
    this.serviceSlug = serviceSlug
    this.isServiceManager = serviceManagerSlug === serviceSlug
    this.detailsPath = getServiceDetailsPath(name)
    /**
     * There's a situation that we want to render a service as static service or
     * a provisionable/launchable service manager depending on the LD flag.
     * bypassStaticRedirectUrl is created to bypass static redirect url lookup,
     * thus preventing static launch button rendered when LD flag is set to true
     */
    this.redirectUrl = bypassStaticRedirectUrl
      ? undefined
      : serviceRedirectMap[serviceSlug]
    this.name = name
    this.category = category
    this.testDriveUrl = testDriveUrl
    this.documentationUrl = documentationUrl
    this.contactSalesUrl = contactSalesUrl
    this.termsOfServiceUrl = termsOfServiceUrl
    this.quoteUrl = quoteUrl
    this.featuresSupported = featuresSupported
  }
}
