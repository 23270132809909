import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, ResponsiveContext } from 'grommet'
import { Previous } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Button, Tabs } from '../../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { isCoP } from '../../../../utils/feature-flag-utils'
import ConfigureAuthenticationPage from '../configure-auth/ConfigureAuthenticationPage'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../../utils/common-utils'

import { LocalAuthentication, Sessions } from './pages'
// import Authentication from './pages/Authentication'

const CopAuthentication = () => {
  const history = useHistory()
  const { t } = useTranslation(['authn', 'common'])
  const handleBackBtnClick = () => {
    history.push('/manage-account')
  }
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const size = useContext(ResponsiveContext)
  const responsiveSize = ['xsmall', 'small']

  return (
    <>
      <Box
        direction="row"
        justify="start"
        pad={{ top: 'small', bottom: 'small' }}
      >
        <Button
          default
          pad={{
            vertical: 'xsmall',
            horizontal: responsiveSize.includes(size) ? 'xsmall' : 'small'
          }}
          label={t('common:manage_account_label', {
            account: getWorkspaceString(
              showWorkspaceString,
              t,
              WKSPC_CAPITALIZED
            )
          })}
          icon={<Previous />}
          onClick={handleBackBtnClick}
          testId="manage-account-btn"
        />
      </Box>
      <Box align="center" pad={{ horizontal: 'medium' }}>
        <Box>
          {isCoP() ? (
            <Tabs
              alignControls="start"
              justify="start"
              tabsList={[
                {
                  content: <ConfigureAuthenticationPage />,
                  id: 1,
                  label: 'Authentication',
                  testId: 'cop-authentication-tab'
                },
                {
                  content: <LocalAuthentication />,
                  id: 2,
                  label: t('cop_local_authentication.title'),
                  testId: 'cop_local_authentication.description'
                },
                {
                  content: <Sessions />,
                  id: 3,
                  label: t('cop_local_authentication.sessions'),
                  testId: 'cop-sessions-tab'
                }
              ]}
              testId="simpleTabs"
            />
          ) : (
            <ConfigureAuthenticationPage />
          )}
        </Box>
      </Box>
    </>
  )
}

const CopAuthenticationPage = () => {
  return (
    <Layout>
      <CopAuthentication align="start" justify="start" />
    </Layout>
  )
}
export default CopAuthenticationPage
