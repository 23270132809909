export const analyticsConfig = () => {
  const storedLdConfigFromSession = JSON.parse(
    sessionStorage?.getItem('glp-analytics-config')
  )
  return {
    ...storedLdConfigFromSession,
    plugins: {
      amplitude: {
        defaultTracking: false,
        projectId: window.$settings?.aaConfig?.project_id,
        apiKey: window.$settings?.aaConfig?.api_key
      }
    }
  }
}
