import React from 'react'
import PropTypes from 'prop-types'

import useCCSManagerReducer from './CCSManagerReducer'

const CCSManagerContext = React.createContext()

export const CCSManagerContextProvider = ({ children }) => {
  const stateAndDispatch = useCCSManagerReducer()
  return (
    <CCSManagerContext.Provider value={stateAndDispatch}>
      {children}
    </CCSManagerContext.Provider>
  )
}

CCSManagerContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const useCCSManagerContext = () => {
  const { CCSManagerState, dispatchCCSManagerContext } =
    React.useContext(CCSManagerContext)
  return { ...CCSManagerState, dispatchCCSManagerContext }
}
