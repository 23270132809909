import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Notification,
  Typography
} from '../../../../components'
import { del, getErrorMessage } from '../../../../utils/api-utils'

const CancelSubscriptionModal = ({
  customerId,
  subscriptionKey,
  setShowModal,
  onSuccess
}) => {
  const { t } = useTranslation(['manage'])
  const [errorMessage, setErrorMessage] = useState('')
  const { oidcUser } = useReactOidc()
  const handleCancel = () => {
    const url = `/support-assistant/v1alpha1/subscription`
    del(
      url,
      {
        subscription_key: subscriptionKey,
        platform_customer_id: customerId
      },
      oidcUser.access_token
    ).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          setErrorMessage('')
          setShowModal(false)
          onSuccess()
        }
      },
      (error) => {
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
      }
    )
  }
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              level="1"
              testId="cancel-subscription-title"
              type="heading"
            >
              {t('cancel_subscription')}
            </Typography>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }} gap="medium">
          <Typography type="text" size="medium" testId="cancel-sub-desc1">
            {t('cancel_subscription_description')}
          </Typography>
          <Typography type="text" size="medium" testId="cancel-sub-desc2">
            <Trans
              i18nKey="cancel_subscription_confirm_msg"
              t={t}
              subscriptionKey={subscriptionKey}
            >
              <strong>{{ subscriptionKey }}</strong>
            </Trans>
          </Typography>
          {errorMessage.length ? (
            <Notification
              backgroundColor="status-critical"
              onClose={() => setErrorMessage('')}
              testId="cancel-subscription-error-message"
              text={errorMessage}
              type="inline"
            />
          ) : null}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  secondary: true,
                  testId: 'cancel-btn',
                  onClick: () => setShowModal(false)
                },
                {
                  id: 1,
                  label: t('cancel_subscription'),
                  primary: true,
                  testId: 'cancel-subscription-btn',
                  onClick: handleCancel
                }
              ]}
              testId="cancel-subscription-buttons"
            />
          }
        />
      }
      onClose={() => setShowModal(false)}
      testId="cancel-subscription-modal-dialog"
      width="medium"
    />
  )
}

CancelSubscriptionModal.propTypes = {
  customerId: PropTypes.string.isRequired,
  subscriptionKey: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
}

CancelSubscriptionModal.defaultProps = {
  onSuccess: () => {}
}

export { CancelSubscriptionModal }
