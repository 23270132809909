// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useState, useEffect, useRef } from 'react'
import { Box, TextArea, Anchor, Text } from 'grommet'
import PropTypes from 'prop-types'
import { Send } from 'grommet-icons'
import styled from 'styled-components'

import { Typography } from '../../components'

import { ChatActions, useChatContext } from './context/chat-context'

const StyledTextArea = styled(TextArea)`
  all: unset;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  overflow-y: ${(props) => (props.showScrollbar ? 'scroll' : 'hidden')};
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: black;
  padding: 0px 11px;
  word-wrap: break-word;
  white-space: pre-wrap;
`

const StyledText = styled(Box)`
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.global.colors.border.light};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  overflow-y: ${(props) => (props.showScrollbar ? 'scroll' : 'hidden')};
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: transparent; /* Match the color of the text to be visible */
  word-wrap: break-word;
  white-space: pre-wrap;
  padding: 0px 11px;
  & span::after {
    content: attr(data-highlighted);
    background-color: ${(props) =>
      `${props.theme.global.colors['status-critical'].light}1f`};
  }
`

const ChatFooter = ({ client, closeChatBot, isChatEmpty }) => {
  const [message, setMessage] = useState('')
  const [showScrollbar, setShowScrollbar] = useState(false)
  const [numberOfLines, setNumberOfLines] = useState(0)
  const [prevMessageLength, setPrevMessageLength] = useState(0)
  const [isResizing, setIsResizing] = useState(false)
  const [over, setOver] = useState(false)
  const CHAR_MAX_LIMIT = 250
  const margin = 7.5

  const {
    sessionId,
    dispatchChatContext,
    disableInput,
    textAreaHeight,
    setTextAreaHeight
  } = useChatContext()

  useEffect(() => {
    const savedMessage = sessionStorage.getItem('chatInputMessage')
    if (savedMessage) {
      setMessage(savedMessage)
    }
  }, [])

  useEffect(() => {
    sessionStorage.setItem('chatInputMessage', message)
  }, [message])

  const sendMessage = () => {
    const time = new Date()
    if (message.length > 0 && message.trim() !== '') {
      const payload = {
        from: 'You',
        text: message,
        time: `${time.getHours()}:${time.getMinutes()}`
      }
      if (sessionId) {
        dispatchChatContext({ type: ChatActions.UPDATE_CHAT, payload })
        client.send(message)
      } else dispatchChatContext({ type: ChatActions.ERROR_CONNECTING_CHAT })
      setMessage('')
      sessionStorage.removeItem('chatInputMessage')
    }
  }
  const onClearChatBot = async () => {
    setMessage('')
    sessionStorage.removeItem('chatInputMessage')
    closeChatBot({ type: ChatActions.CLEAR_CHAT })
  }

  const textareaRef = useRef()
  const styledTextRef = useRef()
  const BoxareaRef = useRef()

  const handleTextAreaChange = (e) => {
    setMessage(e.target.value)
  }

  const handleMouseDown = () => {
    setIsResizing(true)
  }

  const handleMouseUp = () => {
    setIsResizing(false)
  }

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (isResizing) {
        const textArea = textareaRef.current
        const newHeight = Math.max(25, textArea.clientHeight - event.movementY)
        const maxHeight = 300
        textArea.style.height = `${Math.min(newHeight, maxHeight)}px`
        styledTextRef.current.style.height = textArea.style.height
        const box = parseInt(textArea.style.height, 10)
        BoxareaRef.current.style.height = `${box + 15}px`
        setTextAreaHeight(textArea.style.height)
      }
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isResizing, setTextAreaHeight])

  useEffect(() => {
    const textArea = textareaRef.current
    const currentMessageLength = message.length

    const calculatedNumberOfLines = Math.floor(textArea.scrollHeight / 25)

    if (currentMessageLength < prevMessageLength) {
      const newBoxHeight = Math.max(25, textArea.clientHeight - 25)
      textArea.style.height = `${newBoxHeight}px`
      textArea.style.top = `${margin}px`
      textArea.style.bottom = `${margin}px`
      styledTextRef.current.style.top = `${margin}px`
      styledTextRef.current.style.bottom = `${margin}px`
    }
    if (message.trim() === '') {
      setTextAreaHeight(25)
      setNumberOfLines(0)
      setShowScrollbar(false)
    } else {
      if (calculatedNumberOfLines > 1) {
        textArea.style.height = `${Math.min(textArea.scrollHeight, 150)}px`
        textArea.style.top = `${margin}px`
        textArea.style.bottom = `${margin}px`
        styledTextRef.current.style.top = `${margin}px`
        styledTextRef.current.style.bottom = `${margin}px`
        setTextAreaHeight(textArea.style.height)
        setNumberOfLines(calculatedNumberOfLines)
        setShowScrollbar(calculatedNumberOfLines > 4)
      }
      setPrevMessageLength(currentMessageLength)
    }

    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight
    }
    if (textareaRef.current && styledTextRef.current) {
      const textAreaWidth = textareaRef.current.getBoundingClientRect().width
      const textheight = textareaRef.current.getBoundingClientRect().height
      styledTextRef.current.style.width = `${textAreaWidth}px`
      styledTextRef.current.style.height = `${textheight}px`
      const box = parseInt(textArea.style.height, 10)
      BoxareaRef.current.style.height = `${box + 15}px`
      textArea.style.top = `${margin}px`
      textArea.style.bottom = `${margin}px`
      styledTextRef.current.style.top = `${margin}px`
      styledTextRef.current.style.bottom = `${margin}px`
      BoxareaRef.current.style.width = `${textAreaWidth}px`
      styledTextRef.current.scrollTop = textareaRef.current.scrollTop
      BoxareaRef.current.scrollTop = textareaRef.current.scrollTop
    }
  }, [message, prevMessageLength, setTextAreaHeight, numberOfLines])

  const handleKeyDown = (event) => {
    if (
      event.key === 'Enter' &&
      !event.shiftKey &&
      message.length <= CHAR_MAX_LIMIT
    ) {
      event.preventDefault()
      sendMessage()
    }
  }

  const count = message.length
  const overLimit = count - CHAR_MAX_LIMIT
  const setDownBackground = () => {
    if (disableInput || overLimit > 0 || isChatEmpty) {
      return '#01A98284'
    }
    if (over) {
      return '#007159'
    }
    return 'rgb(1, 169, 130)'
  }

  useEffect(() => {
    const updateLayout = () => {
      const textArea = textareaRef.current
      if (!textArea) return

      const textAreaWidth = textArea.getBoundingClientRect().width
      const updateStyles = (ref) => {
        if (ref.current) {
          textArea.style.top = `${margin}px`
          textArea.style.bottom = `${margin}px`
          styledTextRef.current.style.top = `${margin}px`
          styledTextRef.current.style.bottom = `${margin}px`
          ref.current.style.width = `${textAreaWidth}px`
          ref.current.scrollTop = textArea.scrollTop
        }
      }

      updateStyles(styledTextRef)
      updateStyles(BoxareaRef)
    }

    const handleResize = () => updateLayout()

    const handleScroll = () => {
      const textArea = textareaRef.current
      const syncScroll = (ref) => {
        if (ref.current) {
          textArea.style.top = `${margin}px`
          textArea.style.bottom = `${margin}px`
          styledTextRef.current.style.top = `${margin}px`
          styledTextRef.current.style.bottom = `${margin}px`
          ref.current.scrollTop = textArea.scrollTop
        }
      }

      syncScroll(styledTextRef)
      syncScroll(BoxareaRef)
    }

    const throttle = (func, limit) => {
      let resizeTimeout
      return (...args) => {
        if (!resizeTimeout) {
          resizeTimeout = setTimeout(() => {
            func(...args)
            resizeTimeout = null
          }, limit)
        }
      }
    }

    const throttledResize = throttle(handleResize, 5)
    const resizeObserver = new ResizeObserver(throttledResize)

    const textArea = textareaRef.current
    if (textArea) {
      resizeObserver.observe(textArea)
      textArea.addEventListener('scroll', handleScroll)
      updateLayout()
    }

    return () => {
      if (textArea) {
        resizeObserver.unobserve(textArea)
        textArea.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const textAreaContent = textareaRef.current ? textareaRef.current.value : ''

  const firstPart = textAreaContent.slice(0, 250)
  const secondPart = textAreaContent.slice(250)
  return (
    <Box
      data-testid="MouseDownUp"
      direction="column"
      pad={{ top: 'xsmall' }}
      background="background-back"
      style={{ position: 'relative' }}
    >
      <Box
        data-testid="chat-footer"
        direction="row"
        align="center"
        pad={{ horizontal: 'small' }}
        justify="start"
        background="background-back"
        gap="0px"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{
          top: '0px',
          bottom: '0px',
          position: 'relative',
          width: '100%'
        }}
      >
        <Box
          ref={BoxareaRef}
          data-testid="boxarea"
          height={{ min: '40px' }}
          flex="grow"
          direction="row"
          align="initial"
          round="8px"
          border={{ color: 'lightgray', size: 'xsmall' }}
          background="background-front"
          style={{
            lineHeight: '25px',
            minWidth: '294px',
            position: 'relative'
          }}
        >
          <StyledTextArea
            ref={textareaRef}
            showScrollbar={showScrollbar}
            width={{ min: '100%' }}
            height={{ min: '100%' }}
            placeholder="Type your issue..."
            plain
            focusIndicator={false}
            resize={false}
            autoFocus
            value={message}
            onChange={handleTextAreaChange}
            onKeyDown={handleKeyDown}
            disabled={disableInput || isChatEmpty}
            data-testid="textarea"
            style={{
              bottom: '0px',
              left: '0px',
              right: '0px',
              height: `${textAreaHeight}px`,
              position: 'absolute',
              caretColor: 'black',
              boxSizing: 'border-box',
              zIndex: 9,
              cursor: isChatEmpty || disableInput ? 'not-allowed' : 'text',
              display: 'inline-flex'
            }}
          />
          <StyledText
            ref={styledTextRef}
            height={{ min: '100%' }}
            width={{ min: '100%' }}
            data-testid="styled-text"
            showScrollbar={showScrollbar}
            style={{
              bottom: '0px',
              left: '0px',
              right: '0px',
              position: 'absolute',
              boxSizing: 'border-box',
              zIndex: 2,
              minHeight: 'inherit',
              cursor: isChatEmpty || disableInput ? 'not-allowed' : 'text',
              display: 'inline-block'
            }}
          >
            <Text
              data-highlighted={secondPart}
              style={{
                lineHeight: '25px'
              }}
            >
              {firstPart}
            </Text>
          </StyledText>
        </Box>
        <Box
          data-testid="chat-send-icon"
          flex="shrink"
          pad="9px"
          background="brand"
          round="full"
          align="center"
          justify="center"
          margin={{ left: '6px' }}
          width="auto"
          onMouseOver={() => setOver(true)}
          onMouseOut={() => setOver(false)}
          style={{
            minWidth: '36px',
            minHeight: '36px',
            zIndex: '1',
            background: setDownBackground()
          }}
        >
          <Send
            size="18px"
            id="chat-send-ico"
            data-testid="send-icon"
            onClick={sendMessage}
            style={{
              cursor:
                isChatEmpty || disableInput || overLimit > 0
                  ? 'not-allowed'
                  : 'pointer',
              pointerEvents:
                isChatEmpty || disableInput || overLimit > 0 ? 'none' : 'auto'
            }}
          />
        </Box>
      </Box>
      <Box
        direction="column"
        pad={{ horizontal: 'small', top: 'xsmall', bottom: 'medium' }}
        align="center"
        justify="center"
        style={{ cursor: 'default' }}
      >
        <Box
          direction="column"
          pad={{ horizontal: 'xxsmall', top: 'xsmall', bottom: 'small' }}
          data-testid="characterCount"
          align="center"
          justify="center"
          style={{ marginRight: 'auto' }}
        >
          <Typography
            margin={{ right: '400px' }}
            type="text"
            size="xsmall"
            color={250 - count >= 0 ? 'text-weak' : 'status-critical'}
            style={{
              lineHeight: '10px',
              whiteSpace: 'nowrap'
            }}
          >
            {250 - count < 0
              ? `${overLimit} characters over limit`
              : `${250 - count} characters left`}
          </Typography>
        </Box>
        <Box
          direction="row"
          align="center"
          justify="between"
          fill="horizontal"
          pad={{ horizontal: 'xxsmall' }}
        >
          <Typography
            type="text"
            size="xsmall"
            color="text-weak"
            weight="500"
            data-testid="clear-chat-span"
            onClick={() => {
              if (!isChatEmpty || disableInput) {
                onClearChatBot()
              }
            }}
            style={{
              cursor: !isChatEmpty || disableInput ? 'pointer' : 'not-allowed',
              marginRight: 'auto',
              textDecoration: 'underline'
            }}
          >
            New conversation
          </Typography>

          <Typography type="text" size="xsmall" color="text-weak">
            <Anchor
              color="rgba(117, 117, 117)"
              href="https://www.hpe.com/us/en/legal/privacy.html#privacystatement"
              label="HPE Privacy Statement"
              target="_blank"
              style={{ marginLeft: 'auto' }}
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ChatFooter

ChatFooter.propTypes = {
  client: PropTypes.object.isRequired,
  closeChatBot: PropTypes.func.isRequired,
  isChatEmpty: PropTypes.object.isRequired
}
