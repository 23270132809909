import { localStorageWhitelistWithoutPII } from '../../utils/local-storage-utils'

const CustomConsoleLog = ({
  prefix = 'GLCP',
  page,
  section,
  data,
  msg,
  type = 'default',
  count = ''
}) => {
  // Only do any of this if 'console.log' is supported
  // console.table() - Displays tabular data as a table.

  const isGLCPLogsEnabled = JSON.parse(
    localStorage.getItem(localStorageWhitelistWithoutPII.GLCP_LOGS)
  )
  if (typeof window.console === 'object' && isGLCPLogsEnabled) {
    // Colors codes copied from:
    // https://design-system.hpe.design/templates/status-indicator
    // https://design-system.hpe.design/foundation/color
    const colors = {
      default: { bg: '#CCCCCC', fg: '#000000' }, // unknown
      info: { bg: '#00C8FF', fg: '#ffffff' },
      error: { bg: '#FED8D9', fg: '#FD595A' }, // critical
      warning: { bg: '#FFBC44', fg: '#000000' }, // warning
      success: { bg: '#1BEBA0', fg: '#000000' } // normal
    }
    const customStyle = `color: ${colors[type].fg}; background-color: ${colors[type].bg};padding: 2px`
    const pageInfo = `${page ? `[${page}]` : ''}${
      section ? `[${section}]` : ''
    }`
    window.console.log(`%c[${prefix}]${pageInfo}: ${msg}`, customStyle)
    if (data) window.console.log(data)
    if (count) window.console.count(count)
  }
}

export default CustomConsoleLog
