import React, { lazy, Suspense } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom'

import { checkGotoChooseAccount } from '../../utils/common-utils'
import LazyLoading from '../commoncomponents/LazyLoading/LazyLoading'

const System = lazy(() => import('./System'))

const NodeDetailsPage = lazy(() => import('./performance/pages/NodeDetails'))

const SystemRouter = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  checkGotoChooseAccount(history)

  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route exact path={`${path}/performance/:ip`}>
          <NodeDetailsPage />
        </Route>
        <Route path={`${path}`}>
          <System />
        </Route>
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  )
}

export default SystemRouter
