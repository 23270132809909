import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'

import { Typography } from '../../../components'

const WizardSubscriptionListHeader = () => {
  const { t } = useTranslation(['licensing'])

  return (
    <>
      <Box
        direction="row"
        justify="between"
        margin={{ bottom: 'small', top: 'medium' }}
      >
        <Typography
          type="heading"
          level="2"
          color="text-xweak"
          style={{
            fontWeight: '700'
          }}
          testId="subscription-header"
        >
          {t('subscription')}
        </Typography>
        <Typography
          type="heading"
          level="2"
          color="text-xweak"
          style={{
            fontWeight: '700'
          }}
          testId="expires-header"
        >
          {t('expires')}
        </Typography>
      </Box>
    </>
  )
}

export { WizardSubscriptionListHeader }
