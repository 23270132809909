import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box } from 'grommet'

import { Loader } from '../../../components'

const LazyLoading = ({ label }) => {
  const { t } = useTranslation(['common'])

  return (
    <Box
      fill
      align="center"
      justify="center"
      direction="row"
      pad="large"
      gap="small"
      background={{ color: 'background-front', opacity: 'strong' }}
    >
      <Loader
        label={label || t('loading')}
        orientation="horizontal"
        testId="horizontal-spinner-with-text"
      />
    </Box>
  )
}
LazyLoading.propTypes = {
  label: PropTypes.string
}

LazyLoading.defaultProps = {
  label: undefined
}
export default LazyLoading
