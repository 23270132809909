import React, { useState, useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Box,
  FormField,
  TextInput,
  CheckBox,
  Anchor,
  Text,
  Select
} from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Button, Typography, CCSForm } from '../../../components'
import { get, post, getErrorMessage } from '../../../utils/api-utils'
import { Layout } from '../../commoncomponents/layout/Layout'
import { validateEmail } from '../../../utils/validation-utils'
import { isCoP, isGLOP } from '../../../utils/feature-flag-utils'
import {
  getArticleString,
  getWorkspaceString,
  WKSPC
} from '../../../utils/common-utils'

const SignUpForm = () => {
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const history = useHistory()
  const { t } = useTranslation(['common', 'authn'])
  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value }
        default:
          return currentState
      }
    },
    {
      first_name: '',
      last_name: '',
      email: '',
      country_code: '',
      accepted_terms_of_use: false
    }
  )
  const [errorMessage, setErrorMessage] = useState('')
  const [countries, setCountries] = useState([])
  const [listOfCountries, setListOfCountries] = useState([])

  useEffect(() => {
    const acceptedCopCertificate = sessionStorage.getItem('ccs-cop-ss-cert-api')
    if (!isCoP() || acceptedCopCertificate) {
      get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
        (response) => {
          setListOfCountries(response.data.countries)
          setCountries(response.data.countries)
        },
        (error) => {
          const backendErrorMessage = getErrorMessage(error, t)
          setErrorMessage(backendErrorMessage)
        }
      )
    }
  }, [t])

  // Criteria to force user to accept certificate for COP and GLOP when visiting sign up page.
  useEffect(() => {
    if (
      (isCoP() || isGLOP()) &&
      !sessionStorage.getItem('ccs-cop-ss-cert-api')
    ) {
      sessionStorage.setItem('ccs-cop-ss-cert-api', true)
      history.push('/accept-certificate/signup')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSignUp = () => {
    setErrorMessage('')
    const requestBody = {
      first_name: state.first_name,
      last_name: state.last_name,
      email: state.email,
      address: {
        country_code: state.country_code
      }
    }
    post('/accounts/ui/v1/user/signup', requestBody).then(
      () => {
        const url = `/onboarding/verify-email/${state.email}`
        history.push(url)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }

  const signUpButton = (
    <Button
      primary
      testId="sign-up-submit"
      type="submit"
      label="Sign Up"
      fill="horizontal"
      size="large"
    />
  )

  const EscapedMsg = () => {
    return (
      <Box>
        {t('authn:acct_onboarding.sign-up-subheader', {
          account: getWorkspaceString(showWorkspaceString, t, WKSPC),
          article: getArticleString(showWorkspaceString, t),
          service: isGLOP()
            ? t('authn:acct_onboarding.on_premises_services')
            : t('authn:acct_onboarding.cloud_services')
        })}
      </Box>
    )
  }

  return (
    <Box align="center" justify="center">
      <Box margin={{ top: 'xlarge' }} width="medium">
        <Typography type="heading" level="1" testId="sign-up-header">
          Sign Up for HPE GreenLake
        </Typography>
        <Typography
          type="text"
          size="large"
          testId="sign-up-subheader"
          style={{ margin: '12px 0px' }}
        >
          <EscapedMsg />
        </Typography>

        <CCSForm
          value={state}
          buttons={signUpButton}
          onSubmit={handleSignUp}
          errorMessage={errorMessage}
          testId="sign-up-form"
        >
          <>
            <FormField
              label="First Name"
              name="first_name"
              required
              data-testid="sign-up-first-name-form-field"
            >
              <TextInput
                data-testid="sign-up-first-name-input"
                value={state.first_name}
                name="first_name"
                placeholder="Jane"
                onChange={(event) =>
                  dispatch({
                    value: event.target.value,
                    field: 'first_name',
                    type: 'CHANGE_FIELD'
                  })
                }
              />
            </FormField>

            <FormField
              label="Last Name"
              name="last_name"
              required
              data-testid="sign-up-last-name-form-field"
            >
              <TextInput
                data-testid="sign-up-last-name-input"
                value={state.last_name}
                name="last_name"
                placeholder="Smith"
                onChange={(event) => {
                  dispatch({
                    value: event.target.value,
                    field: 'last_name',
                    type: 'CHANGE_FIELD'
                  })
                }}
              />
            </FormField>

            <FormField
              label="Email"
              name="email"
              required
              data-testid="sign-up-email-form-field"
              validate={(value) => {
                return validateEmail(value)
              }}
            >
              <TextInput
                data-testid="sign-up-email-input"
                value={state.email}
                name="email"
                placeholder="example@my.com"
                onChange={(event) =>
                  dispatch({
                    value: event.target.value,
                    field: 'email',
                    type: 'CHANGE_FIELD'
                  })
                }
              />
            </FormField>

            <FormField
              label="Country"
              name="country_code"
              required
              data-testid="sign-up-country-form-field"
            >
              <Select
                name="country_code"
                placeholder="Select Country"
                options={countries}
                multiple={false}
                labelKey="name"
                value={state.country_code}
                valueKey={{ key: 'code', reduce: true }}
                searchPlaceholder="Country"
                emptySearchMessage="No countries found"
                onSearch={(searchText) => {
                  const regexp = new RegExp(searchText, 'i')
                  setCountries(
                    listOfCountries.filter((o) => o.name.match(regexp))
                  )
                }}
                onChange={({ option }) => {
                  dispatch({
                    value: option.code,
                    field: 'country_code',
                    type: 'CHANGE_FIELD'
                  })
                }}
                onClose={() => setCountries(listOfCountries)}
              />
            </FormField>
            <FormField
              margin={{ bottom: 'medium' }}
              name="accepted_terms_of_use"
              required
              data-testid="sign-up-accept-terms-form-field"
            >
              <CheckBox
                pad="none"
                data-testid="sign-up-accept-terms-checkbox"
                name="accepted_terms_of_use"
                checked={state.accepted_terms_of_use}
                label={
                  <Typography
                    type="text"
                    size="medium"
                    testId="sign-up-accept-terms"
                  >
                    <>
                      I accept{' '}
                      <Anchor
                        label={
                          <Text weight="bold" size="xsmall">
                            HPE Terms of Use
                          </Text>
                        }
                        href="https://www.hpe.com/us/en/about/end-user-agreement-terms.html"
                        target="_blank"
                      />{' '}
                      and agree to the processing of my personal data as
                      described in the{' '}
                      <Anchor
                        label={
                          <Text weight="bold" size="xsmall">
                            HPE Privacy Notice
                          </Text>
                        }
                        href="https://www.hpe.com/us/en/legal/privacy.html'"
                        target="_blank"
                      />
                      .*
                    </>
                  </Typography>
                }
                onChange={(event) =>
                  dispatch({
                    value: event.target.checked,
                    field: 'accepted_terms_of_use',
                    type: 'CHANGE_FIELD'
                  })
                }
              />
            </FormField>
          </>
        </CCSForm>
        <Typography type="text" testId="sign-up-existing-message">
          <p>
            Already have an HPE GreenLake account?{' '}
            <Anchor
              label={
                <Text weight="bold" size="xsmall">
                  Sign In
                </Text>
              }
              onClick={() => {
                history.push('/')
              }}
            />
          </p>
        </Typography>
      </Box>
    </Box>
  )
}

const SignUpPage = () => {
  return (
    <Layout hideHeaderOptions={['no-header']}>
      <SignUpForm />
    </Layout>
  )
}

export default SignUpPage
