import React, { useEffect, useState } from 'react'
import { Box, NameValueList, NameValuePair } from 'grommet'
import PropTypes from 'prop-types'
import { FormDown, FormUp, Install } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useTranslation } from 'react-i18next'
import groupBy from 'lodash/groupBy'
import { useParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Button, Loader, NoDataInfo, Typography } from '../../../components'
import {
  getRegionNameByRegionCode,
  MAX_LIST_LENGTH,
  TRUNC_LIST_LENGTH
} from '../utils'
import { get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import { isServiceCentric } from '../../../utils/account-utils'

const DeployedApplicationDetails = () => {
  const { t } = useTranslation(['authn', 'common'])
  const { oidcUser } = useReactOidc()
  const { customerId } = useParams()

  const [provisionedApp, setProvisionedApp] = useState({})
  const [regionList, setRegionList] = useState([])
  const [showAllServices, setShowAllServices] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorNotification, setErrorNotification] = useState(null)
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)

  useEffect(() => {
    if (!regionList?.length) {
      get('/geo/ui/v1/regions', {}, oidcUser.access_token).then(
        (response) => {
          const { regions } = response.data
          if (regions) {
            setRegionList(regions)
          }
        },
        (error) => {
          setErrorNotification(error)
        }
      )
    }
  }, [oidcUser.access_token, regionList, t])

  useEffect(() => {
    setLoading(true)
    get(
      `/ui-doorway/ui/v1/applications/provisions/${customerId}`,
      { provision_status: 'PROVISIONED' },
      oidcUser.access_token
    )
      .then(
        (response) => {
          const { provisions } = response?.data || []
          if (provisions?.length) {
            const group = groupBy(provisions, 'application_name')
            setProvisionedApp(group)
          }
        },
        (error) => {
          setErrorNotification(error)
        }
      )
      .finally(() => {
        setLoading(false)
      })
  }, [oidcUser.access_token, customerId, t])

  const TruncateButton = ({ showAll, setShowAll }) => (
    <Button
      alignSelf="start"
      size="small"
      label={
        !showAll
          ? t('customer_account.show_all')
          : t('customer_account.show_less')
      }
      testId="show-list-btn"
      onClick={() => setShowAll(!showAll)}
      icon={!showAll ? <FormDown /> : <FormUp />}
      reverse
    />
  )

  TruncateButton.propTypes = {
    showAll: PropTypes.bool.isRequired,
    setShowAll: PropTypes.func.isRequired
  }

  return (
    <>
      {loading ? (
        <Box direction="row" align="center" justify="center" margin="medium">
          <Loader testId="deployed-application-loader" />
        </Box>
      ) : (
        <Box direction="column" pad={{ top: 'small', bottom: 'large' }}>
          <Box
            margin={{ bottom: 'small' }}
            border={{ side: 'bottom', color: 'text-weak' }}
          >
            <Box
              direction="row-responsive"
              justify="between"
              fill
              margin={{ bottom: 'medium' }}
            >
              <Typography level="2" type="heading" testId="details-header">
                {newDashboardFlag
                  ? t('customer_account.deployed_service_manager')
                  : t('customer_account.deployed_applications')}
              </Typography>
            </Box>
          </Box>
          {Object.keys(provisionedApp)?.length ? (
            <Box pad="small">
              <NameValueList
                nameProps={{ width: ['xsmall', 'small'] }}
                valueProps={{ width: ['auto', 'medium'] }}
                data-testid="deployed-app-list"
              >
                {Object.entries(provisionedApp)?.map((datum) => (
                  <NameValuePair name={datum[0]} key={datum[0]}>
                    <>
                      {datum[1]
                        ?.slice(
                          0,
                          showAllServices || datum[1]?.length < MAX_LIST_LENGTH
                            ? datum[1]?.length
                            : TRUNC_LIST_LENGTH
                        )
                        ?.map((app) => (
                          <Typography
                            type="text"
                            size="medium"
                            key={app?.region}
                            testId={app?.region}
                          >
                            {getRegionNameByRegionCode(app?.region, regionList)}
                          </Typography>
                        ))}
                      {datum[1]?.length >= MAX_LIST_LENGTH && (
                        <TruncateButton
                          showAll={showAllServices}
                          setShowAll={setShowAllServices}
                        />
                      )}
                    </>
                  </NameValuePair>
                ))}
              </NameValueList>
            </Box>
          ) : (
            <NoDataInfo
              subtitle={t('customer_account.no_data_found')}
              icon={<Install size="large" />}
              testId="no-data-info"
            />
          )}
          {errorNotification &&
            displayApiError(errorNotification, t, setErrorNotification)}
        </Box>
      )}
    </>
  )
}

export { DeployedApplicationDetails }
