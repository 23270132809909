import { Route, Switch, Redirect } from 'react-router-dom'

import VisibilityWrapper from '../commoncomponents/visibility-wrapper/VisibilityWrapper'

import CaseCreationPage from './CaseCreationPage'

const CasesPageRouter = () => {
  return (
    <Switch>
      <Route exact path="/support/cases(|/create)">
        <VisibilityWrapper
          hideFor={{
            feature: 'glcp-support-cases',
            deployment: ['COP', 'GLOP']
          }}
          fallbackComponent={<Redirect to="/support" />}
        >
          <CaseCreationPage />
        </VisibilityWrapper>
      </Route>
      <Redirect to="/support" />
    </Switch>
  )
}

export default CasesPageRouter
