import { FormField, FileInput, Box, Text } from 'grommet'
import { CircleAlert } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

const nameRegex = /^[\p{L}\d_\s-]+\.(png|jpg|jpeg|gif)$/iu
const allowedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
const maxSize = 3145728

const FilesField = () => {
  const { t } = useTranslation(['support_hub_feedback'])
  return (
    <FormField
      name="files"
      htmlFor="files"
      label={t('files_field.label')}
      info={t('files_field.info')}
      validate={(files) => {
        let typeError
        let sizeError
        let nameError
        let result
        files?.forEach((file) => {
          typeError = !allowedFileTypes.includes(file.type)
          sizeError = file.size > maxSize
          nameError = !nameRegex.test(file.name)
        })
        if (typeError && sizeError) {
          result = t('files_field.type_size_error')
        } else if (typeError) {
          result = t('files_field.type_error')
        } else if (sizeError) {
          result = t('files_field.size_error')
        } else if (nameError) {
          result = t('files_field.name_error')
        }
        return result
      }}
      validateOn="submit"
      data-testid="files-field"
    >
      <FileInput
        id="files"
        name="files"
        messages={{
          browse: t('files_field.browse_message'),
          dropPrompt: t('files_field.drop_message')
        }}
        accept={allowedFileTypes}
        renderFile={(file) => {
          const showAlert =
            !allowedFileTypes.includes(file.type) ||
            file.size > maxSize ||
            !nameRegex.test(file.name)
          return (
            <Box direction="row" align="center" gap="xsmall" margin="small">
              {showAlert && <CircleAlert />}
              <Text weight={500} truncate>
                {file.name}
              </Text>
            </Box>
          )
        }}
      />
    </FormField>
  )
}

export { FilesField }
