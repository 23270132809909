import React from 'react'
import { Box } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  Typography,
  ModalHeader,
  ModalFooter,
  ButtonGroup
} from '../../../components'
import {
  getWorkspaceString,
  WKSPC_PLURAL,
  WKSPC_PLURAL_CAPITALIZED
} from '../../../utils/common-utils'

const GoCustAcctModal = ({
  handleClose,
  handleRedirect,
  appRegion,
  appName
}) => {
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const { t } = useTranslation(['apps', 'common'])
  return (
    <ModalDialog
      content={
        <Box gap="medium" margin={{ top: 'small' }}>
          <Typography
            type="text"
            size="medium"
            testId="redirect-cust-acct-desc"
          >
            <Trans
              i18nKey="redirect_to_customer_accounts_message"
              t={t}
              name={{ appName }}
              appRegion={appRegion}
            >
              {{
                workspace: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_PLURAL
                )
              }}
              <strong>{{ appName }}</strong>
              <strong>{{ appRegion }}</strong>
            </Trans>
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-remove-btn',
                  onClick: handleClose
                },
                {
                  id: 1,
                  label: t('customer_accounts', {
                    workspace: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_PLURAL_CAPITALIZED
                    )
                  }),
                  primary: true,
                  testId: 'go-cust-acct-btn',
                  onClick: handleRedirect
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      header={
        <ModalHeader
          title={
            <Typography
              size="xxlarge"
              testId="before-continue-modal-title"
              type="text"
              weight="bold"
            >
              {t('before_you_continue')}
            </Typography>
          }
        />
      }
      onClose={handleClose}
      testId="go-cust-acct-modal"
      width="medium"
    />
  )
}

GoCustAcctModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleRedirect: PropTypes.func.isRequired,
  appRegion: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired
}

export { GoCustAcctModal }
