// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, Grommet, TextInput } from 'grommet'
import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Search as SearchIcon } from 'grommet-icons'
import { deepMerge } from 'grommet/utils'
import PropTypes from 'prop-types'
import { hpe } from 'grommet-theme-hpe'
import { StyleSheetManager } from 'styled-components'
import Root from 'react-shadow'
import debounce from 'lodash/debounce'

import { post, get } from '../../../../utils/api-utils'

import { getErrorMessage } from './utils'
import { GlobalSearchPanel } from './GlobalSearchPanel'
import { SearchDropDown } from './SearchDropDown'

const panelTheme = deepMerge(hpe, {
  TextInput: {
    border: {
      radius: '24px'
    }
  }
})

export const GlobalSearch = ({ oidcUser, i18nTranslation, LDFlags }) => {
  const globalSearchBoxRef = useRef({})
  const [searchData, setSearchData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [showRecentSearch, setShowRecentSearch] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [rootRef, setRootRef] = React.useState(null)
  const [isAutoCompletion, setIsAutoCompletion] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showFaq, setShowFaq] = useState(false)
  const [faqData, setFaqData] = useState([])
  const [txnId, setTxnId] = useState('')
  const [dropdownContent, setDropdownContent] = useState([])
  const isDiscover = LDFlags['glcp-global-search-discover']
  const next = '0'
  const [renderHints, setRenderHints] = useState([])
  const isJhansi = LDFlags['glcp-global-search-jhansi']
  const [countData, setCountData] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (oidcUser?.access_token) {
      if (isDiscover) {
        get('/global-search/v1alpha1/qna/faq', {}, oidcUser.access_token).then(
          (response) => {
            if (response?.data) {
              setFaqData(response?.data)
            }
          }
        )
      } else if (isJhansi) {
        get(
          '/global-search/v1alpha1/kernel/rendering-hints',
          {},
          oidcUser.access_token
        ).then((response) => {
          if (response?.data) {
            setRenderHints(response?.data)
          }
        })
        get(
          '/global-search/v1alpha1/monitoring/faq',
          {},
          oidcUser.access_token
        ).then((response) => {
          if (response?.data) {
            setFaqData(response?.data)
          }
        })
      }
    }
  }, [oidcUser, isDiscover, isJhansi])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchValue = useCallback(
    debounce((text) => {
      if (text.length > 2 && !showSearch) {
        setLoading(true)
        let url
        if (isDiscover) {
          url = '/global-search/v1alpha1/qna/auto-complete'
        } else if (isJhansi) {
          url = `/global-search/v1alpha1/greenlake/completion?limit=5&next=${next}`
        }
        const requestPayload = {
          searchString: text
        }
        post(url, requestPayload, oidcUser.access_token, false).then(
          (response) => {
            if (response?.data) {
              setDropdownContent(response?.data)
              setIsAutoCompletion(true)
              setOpenDropdown(true)
              setLoading(false)
            }
          }
        )
      }
    }, 500),
    []
  )

  useEffect(() => {
    // Close the dropdown when clicked outside
    window.addEventListener('click', (event) => {
      if (openDropdown) {
        const headerRootNode = document.querySelector('greenlake-header')
        const innerChildElement =
          headerRootNode.shadowRoot?.querySelector('.inner')
        const leftChildElement = innerChildElement?.querySelector('.left')
        const dropContent = leftChildElement?.querySelector(
          '.searchbar-container'
        )
        if (!event.composedPath().includes(dropContent)) {
          setOpenDropdown(false)
          setShowSearch(false)
          setShowFaq(false)
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  useEffect(() => {
    if (faqData?.items?.length > 0) {
      if (searchValue.length > 2 && openDropdown) {
        setShowFaq(false)
      } else {
        setShowFaq(true)
        setDropdownContent(faqData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, openDropdown])

  const handleClickEvent = (searchString, eventType, txnid) => {
    const clickUrl = '/global-search/v1alpha1/monitoring/click'
    const data = {
      domainId: '',
      domainName: '',
      docId: txnid
    }
    const request = {
      searchString,
      eventType,
      data
    }
    post(clickUrl, request, oidcUser.access_token, false)
  }

  const getCountData = (searchString) => {
    const countRequestPayload = {
      searchString,
      filters: []
    }
    post(
      '/global-search/v1alpha1/greenlake/count',
      countRequestPayload,
      oidcUser.access_token
    ).then((response) => {
      if (response?.data) {
        setCountData(response?.data)
      }
    })
  }

  const handleSearchSubmitJhansi = (searchString, eventType) => {
    setLoading(true)
    if (searchString.length > 2) {
      setDropdownContent([])
      setSearchData([])
      getCountData(searchString)

      const requestPayload = {
        searchString
      }

      post(
        '/global-search/v1alpha1/greenlake/search?limit=20',
        requestPayload,
        oidcUser.access_token,
        false
      ).then(
        (response) => {
          if (response?.status === 200) {
            setTxnId(response?.headers.get('Ccs-transaction-id'))

            if (response?.data?.searchResult?.domainHits?.length > 0) {
              setDropdownContent(response?.data)
              setShowRecentSearch(true)
              setIsAutoCompletion(false)
              setSearchData(response?.data)
            } else {
              const errorDetails = {
                title: i18nTranslation('global_search.no_results'),
                subtitle: i18nTranslation('global_search.no_results_subtitle'),
                secondarySubtitle: i18nTranslation(
                  'global_search.no_results_suggestions'
                ),
                icon: 'SEARCH'
              }
              setErrorMessage(errorDetails)
            }
            setLoading(false)
            setShowSearch(true)
            setOpenDropdown(true)
            handleClickEvent(
              searchString,
              eventType,
              response?.headers.get('Ccs-transaction-id')
            )
          }
        },
        (error) => {
          setErrorMessage(getErrorMessage(error))
          setShowSearch(true)
          setOpenDropdown(true)
          setLoading(false)
        }
      )
    } else {
      setLoading(false)
      const errorDetails = {
        title: i18nTranslation('global_search.search_small_title'),
        subtitle: i18nTranslation('global_search.search_small_subtitle'),
        icon: 'INFO'
      }
      setErrorMessage(errorDetails)
      setOpenDropdown(true)
      setShowSearch(true)
    }
  }

  const handleSearchSubmitDiscover = (searchString, eventType) => {
    setLoading(true)
    if (searchString.length > 2) {
      setDropdownContent([])
      setSearchData([])
      const requestPayload = {
        searchString
      }
      post(
        '/global-search/v1alpha1/qna/search',
        requestPayload,
        oidcUser.access_token,
        false
      ).then((response) => {
        if (response?.data) {
          setTxnId(response?.headers.get('Ccs-transaction-id'))
          setDropdownContent(response?.data)
          setShowSearch(true)
          setShowRecentSearch(true)
          setIsAutoCompletion(false)
          setOpenDropdown(true)
          setSearchData(response?.data)
          setLoading(false)
        }
        handleClickEvent(
          searchString,
          eventType,
          response?.headers.get('Ccs-transaction-id')
        )
      })
    } else {
      setDropdownContent([])
      setShowSearch(false)
      setLoading(false)
    }
  }

  const handleListClick = (item) => {
    if (isDiscover) {
      setSearchValue(item)
      let eventName = 'SEARCH_RESULT_CLICK'
      if (showFaq) {
        eventName = 'SEARCH_FAQ_CLICK'
      } else if (isAutoCompletion) {
        eventName = 'SEARCH_AUTOCOMPLETE_CLICK'
      }
      handleSearchSubmitDiscover(item, eventName)
    } else {
      setSearchValue(item.value)
      let eventName = 'SEARCH_RESULT_CLICK'
      if (showFaq) {
        eventName = 'SEARCH_FAQ_CLICK'
      } else if (isAutoCompletion) {
        eventName = 'SEARCH_AUTOCOMPLETE_CLICK'
      }
      handleSearchSubmitJhansi(item.value, eventName)
    }
  }

  const handleActionClick = (searchString) => {
    if (searchString === undefined) {
      searchString = searchValue
    }
    const eventType = 'SEARCH_RESULT_CLICK'
    handleClickEvent(searchString, eventType, txnId)
  }

  // const onSearchFocus = () => {
  //   const greenlakeHeader = document.querySelector('greenlake-header')
  //   const greenlakeHeaderRoot = greenlakeHeader?.shadowRoot

  //   let leftEle = null
  //   let rightEle = null

  //   const workspaceMenu = greenlakeHeaderRoot?.querySelector('workspace-menu')
  //   let computedStyle = window.getComputedStyle(workspaceMenu)

  //   if (computedStyle.getPropertyValue('display') !== 'none') {
  //     leftEle = workspaceMenu
  //   } else {
  //     const moreMenu = greenlakeHeaderRoot?.querySelector('more-menu')
  //     leftEle = moreMenu
  //   }

  //   const centerNav = greenlakeHeaderRoot?.querySelector('center-nav')
  //   computedStyle = window.getComputedStyle(centerNav)
  //   if (computedStyle.getPropertyValue('display') !== 'none') {
  //     rightEle = centerNav
  //   }

  //   if (rightEle && leftEle) {
  //     const golbalsearchBoxWidth =
  //       rightEle.getBoundingClientRect().left -
  //       leftEle.getBoundingClientRect().right -
  //       30

  //     globalSearchBoxRef.current.style.width = `${golbalsearchBoxWidth}px`
  //   }
  // }

  const handleSearchBarClick = () => {
    if (searchValue.length === 0 && faqData?.items?.length > 0) {
      setDropdownContent(faqData)
      setShowFaq(true)
      setOpenDropdown(true)
    } else if (searchValue.length > 0 && dropdownContent) {
      setShowFaq(false)
      setShowSearch(true)
      setDropdownContent(searchData)
      setOpenDropdown(true)
    }
  }

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <Root.div ref={setRootRef} style={{ width: '100%' }}>
      {rootRef && (
        <StyleSheetManager target={rootRef.shadowRoot}>
          <Grommet
            theme={panelTheme}
            style={{
              zIndex: 10
            }}
          >
            <Box
              data-testid="global-search"
              margin={{ left: 'small' }}
              ref={globalSearchBoxRef}
            >
              <Box
                width="auto"
                direction="row"
                margin="small"
                align="center"
                round="medium"
                focusIndicator
                border
              >
                <TextInput
                  plain
                  name="search"
                  icon={<SearchIcon id="search-icon" />}
                  type="search"
                  value={searchValue}
                  placeholder={
                    isDiscover
                      ? i18nTranslation('global_search.search_placeholder')
                      : i18nTranslation('global_search.searchbar_placeholder')
                  }
                  onChange={(event) => {
                    setErrorMessage('')
                    setSearchValue(event.target.value)
                    handleDebouncedSearchValue(event.target.value.trim())
                  }}
                  width="100%"
                  maxLength={255}
                  focusIndicator={false}
                  data-testid="global-search-search-field"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      if (isDiscover) {
                        handleSearchSubmitDiscover(searchValue, 'SEARCH_ENTER')
                      } else {
                        handleSearchSubmitJhansi(searchValue, 'SEARCH_ENTER')
                      }
                    }
                  }}
                  onClick={() => {
                    handleSearchBarClick()
                  }}
                />
              </Box>
              {(showSearch || isAutoCompletion || showFaq) && openDropdown && (
                <Box
                  gap="large"
                  direction="row-responsive"
                  id="drop-content-search"
                >
                  {isDiscover ? (
                    <GlobalSearchPanel
                      round="small"
                      content={dropdownContent}
                      handleListClick={handleListClick}
                      handleActionClick={handleActionClick}
                      showRecentSearch={showRecentSearch}
                      isAutoCompletion={isAutoCompletion}
                      loading={loading}
                      showFaq={showFaq}
                      setOpenDropdown={setOpenDropdown}
                      i18nTranslation={i18nTranslation}
                    />
                  ) : (
                    <SearchDropDown
                      round="small"
                      content={dropdownContent}
                      handleListClick={handleListClick}
                      handleActionClick={handleActionClick}
                      showRecentSearch={showRecentSearch}
                      isAutoCompletion={isAutoCompletion}
                      loading={loading}
                      showFaq={showFaq}
                      setOpenDropdown={setOpenDropdown}
                      i18nTranslation={i18nTranslation}
                      renderHints={renderHints}
                      countData={countData}
                      errorMessage={errorMessage}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Grommet>
        </StyleSheetManager>
      )}
    </Root.div>
  )
}

GlobalSearch.propTypes = {
  oidcUser: PropTypes.object,
  i18nTranslation: PropTypes.any.isRequired,
  LDFlags: PropTypes.any.isRequired
}

GlobalSearch.defaultProps = {
  oidcUser: {}
}
