import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Typography } from '../../../../../components'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../../../utils/common-utils'

function CustomerAccountDetailsSection({ data }) {
  const { t } = useTranslation(['authn'])
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  return (
    <Box>
      {Object.entries(data).map((datum) => (
        <Box
          direction="row-responsive"
          align="start"
          justify="start"
          margin={{ top: 'small' }}
          key={datum[0]}
        >
          <Box width="small" align="start">
            <Typography
              size="xsmall"
              data-testid={t(`customer-account-details-key-${datum[0]}`)}
              type="text"
              truncate="tip"
            >
              {t(`customer_account.${datum[0]}`, {
                account: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_CAPITALIZED
                ),
                company: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_CAPITALIZED
                )
              })}
            </Typography>
          </Box>
          <Box
            width="medium"
            align="start"
            margin={{ left: 'medium' }}
            pad={{ left: 'small' }}
          >
            <Typography
              size="medium"
              color="text-strong"
              data-testid={t(`customer-account-details-value-${datum[0]}`)}
              type="text"
              truncate="tip"
            >
              {datum[1] ? datum[1] : '--'}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

CustomerAccountDetailsSection.propTypes = {
  data: PropTypes.object
}

CustomerAccountDetailsSection.defaultProps = {
  data: {}
}

export default React.memo(CustomerAccountDetailsSection)
