import PropTypes from 'prop-types'
import { Box, Button, CheckBox, Anchor, FormField } from 'grommet'
import { CircleInformation } from 'grommet-icons'
import { Trans, useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useHistory } from 'react-router-dom'

import {
  Typography,
  doProvisionAppAction as doProvisionServiceAction,
  CCSForm,
  Dropdown,
  ModalDialog,
  ModalHeader,
  ModalFooter,
  Notification,
  doRetryServiceAction
} from '../shims/imports'
import { isMSP } from '../../utils/feature-flag-utils'

const NewProvisionModal = ({
  regionsCanBeProvisioned,
  serviceDetailData,
  closeModal,
  type,
  setPageData,
  setShowErrorNotification,
  isServiceProvision,
  setConfigureDeviceNotification,
  setActiveTabIndex,
  isOrgServiceProvision
}) => {
  const { t } = useTranslation([
    'dashboard',
    'services_catalog',
    'common',
    'apps'
  ])
  const history = useHistory()
  const [selectedRegion, setSelectedRegion] = useState(
    regionsCanBeProvisioned[0]?.code
  )
  const { oidcUser } = useReactOidc()
  const regionOptions = regionsCanBeProvisioned.map(({ code, name }) => ({
    label: name,
    value: code
  }))
  const { service_offer: serviceOffer } = serviceDetailData
  const serviceManagerName =
    serviceDetailData?.service_manager?.name || serviceOffer?.name
  const {
    id: serviceOfferId,
    is_service_manager: isServiceManager,
    name: serviceName,
    pre_provision_message: preProvisionMsg,
    workspace_types: workspaceTypes = []
  } = serviceOffer
  const provisions = serviceDetailData?.provisions || []
  const isTenantOnlySupported =
    workspaceTypes?.includes('TENANT') && !workspaceTypes?.includes('MSP')

  const [checked, setChecked] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleTerms = (value) => {
    setChecked(value)
    setErrorMessage('')
  }
  const appTermLink = 'https://www.hpe.com/psnow/doc/a50009054ENW'

  const selectedRegionProvision = provisions.find(
    ({ service_provision }) => service_provision?.region === selectedRegion
  )

  const handleAddInstance = (e) => {
    if (checked) {
      const retryId =
        selectedRegionProvision?.service_provision?.provision_status ===
        'PROVISION_FAILED'
          ? selectedRegionProvision?.service_provision?.id
          : undefined
      if (retryId) {
        doRetryServiceAction(
          { service_offer_id: serviceOfferId, ...serviceOffer },
          selectedRegion,
          oidcUser.access_token,
          setPageData,
          () => {},
          setShowErrorNotification,
          t,
          () => {},
          isServiceProvision,
          retryId,
          () => {
            // force to logout for IGC
            if (isOrgServiceProvision) {
              e.preventDefault()
              history.push('/sign-out')
            }
          }
        )
      } else {
        doProvisionServiceAction(
          { service_offer_id: serviceOfferId, ...serviceOffer },
          selectedRegion,
          oidcUser.access_token,
          setPageData,
          () => {},
          setShowErrorNotification,
          t,
          () => {
            setConfigureDeviceNotification(true)
          },
          isServiceProvision,
          retryId,
          () => {
            // force to logout for IGC
            if (isOrgServiceProvision) {
              e.preventDefault()
              history.push('/sign-out')
            }
          },
          undefined
        )
      }
      if (isServiceManager || isServiceProvision) {
        setActiveTabIndex(1)
      }
      closeModal()
    } else {
      setErrorMessage(t('apps:terms_of_service_text'))
    }
  }

  const renderTitle =
    isMSP() && isTenantOnlySupported
      ? t('dashboard:service_details.enable_modal_title')
      : t(
          `dashboard:service_details.${
            isServiceProvision && !isServiceManager ? 'service_' : ''
          }provision_modal_title`
        )

  const SubTitle = () => {
    let subTitle = (
      <Trans i18nKey="dashboard:service_details.setup_modal_subtitle">
        {{ serviceName, serviceManagerName }}
      </Trans>
    )
    if (isOrgServiceProvision) {
      subTitle = (
        <Trans
          i18nKey={
            type === 'provision'
              ? 'dashboard:service_details.igc_provision_modal_subtitle'
              : 'apps:add_region_subtitle'
          }
        >
          {{ serviceName }}
        </Trans>
      )
    } else if (isMSP() && isTenantOnlySupported) {
      subTitle = (
        <Trans
          i18nKey={
            type === 'provision'
              ? 'dashboard:service_details.enable_modal_subtitle'
              : 'apps:add_region_subtitle'
          }
        >
          {{ serviceName }}
        </Trans>
      )
    } else if (isServiceManager || isServiceProvision) {
      subTitle = (
        <Trans
          i18nKey={
            type === 'provision'
              ? 'dashboard:service_details.provision_modal_subtitle'
              : 'apps:add_region_subtitle'
          }
        >
          {{ serviceName }}
        </Trans>
      )
    }
    return (
      <Typography type="paragraph" textAlign="start">
        {subTitle}
      </Typography>
    )
  }

  const modalContentHeader = (
    <Box gap="xsmall">
      <Typography type="text" size="xsmall">
        {t('dashboard:common:deployment_region')}
      </Typography>
      <Dropdown
        options={regionOptions}
        onChangeDropdown={(value) => {
          setSelectedRegion(value)
        }}
        defaultVal={regionOptions[0]?.value}
        testId="service-centric-provision-dropdown"
      />
    </Box>
  )

  const termSection = (
    <Box margin={{ top: 'small' }}>
      <CCSForm errorMessage={errorMessage} testId="app-term-form">
        <FormField>
          <CheckBox
            id="app-term-checkbox"
            data-testid="app-term-checkbox"
            name="app_terms"
            checked={checked}
            label={
              <Typography
                type="text"
                size="medium"
                testId="app-terms-checkbox-label"
              >
                <Trans i18nKey="apps:terms_of_service_confirm_text" t={t}>
                  <Anchor
                    label={t('apps:terms_of_service')}
                    weight={500}
                    href={appTermLink}
                    target="_blank"
                    testId="app-terms-anchor"
                  />
                </Trans>
              </Typography>
            }
            onChange={(event) => handleTerms(event.target.checked)}
          />
        </FormField>
      </CCSForm>
    </Box>
  )

  const showIGCMessage = isOrgServiceProvision && (
    <Box margin={{ top: 'medium' }}>
      <Notification
        backgroundColor="status-unknown"
        icon={
          <CircleInformation style={{ marginTop: '3px', marginLeft: '4px' }} />
        }
        testId="igc_logout_info"
        text={preProvisionMsg || ''}
        type="inline"
      />
    </Box>
  )

  const showModalContent = (
    <Box margin={{ top: 'medium' }}>
      {modalContentHeader}
      {termSection}
      {showIGCMessage}
    </Box>
  )

  return (
    <ModalDialog
      testId="service-centric-provision-model"
      width="large"
      header={
        <ModalHeader
          title={
            <>
              <Typography type="heading" level="2" textAlign="start">
                {type === 'provision' ? renderTitle : t('apps:add_region')}
              </Typography>
              <SubTitle />
            </>
          }
        />
      }
      content={showModalContent}
      footer={
        <ModalFooter
          right={
            <Box direction="row" justify="end" gap="medium">
              <Button label={t('common.cancel')} onClick={closeModal} />
              <Button
                primary
                label={
                  type === 'provision' && isMSP() && isTenantOnlySupported
                    ? t('dashboard:service_details.enable_service')
                    : t('apps:deploy')
                }
                onClick={handleAddInstance}
              />
            </Box>
          }
        />
      }
      onClose={() => {}}
    />
  )
}

NewProvisionModal.propTypes = {
  regionsCanBeProvisioned: PropTypes.array.isRequired,
  serviceDetailData: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['provision', 'add']).isRequired,
  setPageData: PropTypes.func.isRequired,
  setShowErrorNotification: PropTypes.func.isRequired,
  isServiceProvision: PropTypes.bool.isRequired,
  setConfigureDeviceNotification: PropTypes.func.isRequired,
  setActiveTabIndex: PropTypes.func.isRequired,
  isOrgServiceProvision: PropTypes.bool
}

NewProvisionModal.defaultProps = {
  isOrgServiceProvision: false
}
export default NewProvisionModal
