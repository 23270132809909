import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { DislikeFill, LikeFill } from 'grommet-icons'

import { Typography } from '../../components'

const ChatMessageFromUser = ({ chatResponse }) => {
  const formattedTime = chatResponse.timestamp

  return (
    <Box
      width={{ max: '70%' }}
      direction="column"
      align="end"
      alignSelf="end"
      gap="none"
      flex="grow"
      margin={{ bottom: 'small' }}
    >
      <Box style={{ width: 'fit-content' }}>
        {chatResponse?.text !== undefined && (
          <Box
            data-testid="userMessage"
            direction="row-reverse"
            background="validation-ok"
            pad="small"
            round="8px"
            style={{
              width: 'fit-content',
              minWidth:
                chatResponse?.text === 'ThumbsUp' ||
                chatResponse?.text === 'ThumbsDown'
                  ? '2rem'
                  : '0rem',
              alignSelf: 'end'
            }}
          >
            {chatResponse?.text === 'ThumbsUp' ||
            chatResponse?.text === 'ThumbsDown' ? (
              <Typography type="text" size="18px">
                {chatResponse?.text === 'ThumbsUp' ? (
                  <LikeFill data-testid="userLikeBtn" color="brand" />
                ) : (
                  <DislikeFill color="brand" />
                )}
              </Typography>
            ) : (
              <Typography
                type="text"
                size="18px"
                style={{ wordBreak: 'break-word' }}
              >
                {chatResponse?.text}
              </Typography>
            )}
          </Box>
        )}
        <Box
          width="auto"
          direction="row"
          gap="xxsmall"
          justify="end"
          align="center"
          pad={{ bottom: 'xxsmall' }}
        >
          <Typography type="text" size="xsmall" color="text-weak">
            {chatResponse?.from}
          </Typography>
          <Typography type="text" size="xsmall" color="text-weak">
            {formattedTime}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ChatMessageFromUser

ChatMessageFromUser.propTypes = {
  chatResponse: PropTypes.object.isRequired
}
