import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../../../../components'

import { RateReview } from './RateReview'
import { RatesReveiwContainer } from './RatesReveiwContainer'

const ReviewShowbackRatesStep = ({ ratesData, currency }) => {
  const { pricePlans } = ratesData || {}
  const { t } = useTranslation(['device', 'common'])
  return (
    <RatesReveiwContainer level={2}>
      {ratesData ? (
        pricePlans?.map((pricePlan) => {
          return (
            <RateReview
              pricePlan={pricePlan}
              key={pricePlan.uom}
              currency={currency}
            />
          )
        })
      ) : (
        <Typography
          type="text"
          margin={{ top: '0px', bottom: '0px' }}
          testId="showback-rates-details-no-existed-rates"
        >
          {t('showback_rates.manage_rates_msg_no_rates_added')}
        </Typography>
      )}
    </RatesReveiwContainer>
  )
}

ReviewShowbackRatesStep.propTypes = {
  ratesData: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired
}

export { ReviewShowbackRatesStep }
