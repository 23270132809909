// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import isEmpty from 'lodash/isEmpty'
import lodashSet from 'lodash/set'
import groupBy from 'lodash/groupBy'
import isArray from 'lodash/isArray'

import { Notification } from '../components/notification/Notification'

export const commonMessages = {
  ERR_400: 'ERR_400',
  ERR_401: 'ERR_401',
  ERR_403: 'ERR_403',
  ERR_404: 'ERR_404',
  ERR_412: 'ERR_412',
  ERR_422: 'ERR_422',
  ERR_451: 'ERR_451',
  ERR_500: 'ERR_500',
  ERR_502: 'ERR_502',
  ERR_503: 'ERR_503',
  ERR_COMMON: 'ERR_COMMON'
}

// devices API error is not returning JSON format. It is a string so extracting error message from the string.
const getMessage = (message) => {
  let msg
  if (isEmpty(message)) {
    msg = null
  } else if (isArray(message)) {
    // If message is of type Array return it without modification
    msg = message
  } else {
    const messages = message.split("'")
    if (messages.includes('message')) {
      const msgIndex = messages.indexOf('message')
      msg = messages[msgIndex + 2]
    } else if (messages.includes('response')) {
      const msgIndex = messages.indexOf('response')
      msg = messages[msgIndex + 2]
    } else {
      msg = message
    }
  }
  return msg
}

const getMessageFromDetail = (detail) => {
  let detailMessage
  if (isArray(detail)) {
    if (detail?.length && detail[0]?.msg) {
      // Checking if its validation error
      const group = groupBy(detail, 'msg')
      detailMessage = Object.keys(group)
        .map((msg) => {
          const fields = group[msg].map((obj) => obj.loc.pop()).join()
          return `${msg}: ${fields}`
        })
        .join(' / ')
    } else detailMessage = detail
  } else {
    detailMessage = getMessage(detail)
  }
  return detailMessage
}

const getMessageByCode = ({ code, message, Message, detail, response }) => {
  return (
    getMessage(message) ||
    getMessage(Message) ||
    getMessageFromDetail(detail) ||
    getMessage(response) ||
    commonMessages[code] ||
    commonMessages.ERR_COMMON
  )
}

export const formatErrorObject = (error) => {
  if (typeof error.response.data === 'string') {
    error.response.data = JSON.parse(error.response.data)
  }
  error.response.data.status = error.response.status
  error.response.data.code =
    error.response.data.code || `ERR_${error.response.status}`
  error.response.data.message = getMessageByCode(error.response.data)
  delete error.response.data.detail
}

const metaMessages = {
  not_found_devices: 'common:error_messages.not_found_devices',
  already_added_devices: 'common:error_messages.already_added_devices',
  blocked_devices: 'common:error_messages.blocked_devices'
}

const getMetaMessage = (data, t) => {
  const keys = Object.keys(data.meta)
  const msgs = keys
    .filter((msg) => !!metaMessages[msg])
    .map((key) =>
      t(metaMessages[key], {
        value: data.meta[key]
      })
    )
  return msgs.length ? msgs.join(', ') : t(`common:error_messages.ERR_COMMON`)
}

export const getApiErrorMessage = (error, t, overrideMessage) => {
  let text
  if (!overrideMessage) {
    const msg = error?.response?.data?.message
    if (error?.response?.data?.meta) {
      text = getMetaMessage(error.response.data, t)
    } else {
      const messageKey = `error_messages.${msg}`
      const translated = t(`common:${messageKey}`)
      text = translated.includes('error_messages.') ? msg : translated
    }
  } else {
    text = overrideMessage
  }
  // Showing error message for toast notification when session timedout
  if (error?.request?.requestError) {
    text = t('common:error_messages.ERR_session_timedout')
    lodashSet(error, 'request.requestError', false)
  }
  return text || t('common:error_messages.ERR_COMMON')
}

export const displayApiError = (
  error,
  t,
  callback,
  overrideMessage,
  type = 'default',
  status,
  title
) => {
  const text = getApiErrorMessage(error, t, overrideMessage)
  const handleClose = () => {
    callback(null)
  }

  return (
    <Notification
      testId="notification_error"
      text={text}
      onClose={handleClose}
      backgroundColor="status-critical"
      type={type}
      status={status}
      title={title}
    />
  )
}
