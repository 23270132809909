import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { WizardContext } from '../../../../components/wizard/WizardContext'
import { Typography } from '../../../../components'
import { validateForm } from '../../../../utils/validation-utils'
import { TagsEdit } from '../../../commoncomponents/tags/TagsEdit'

const validateFields = () => {
  return {
    isValid: true
  }
}

export const validateAssignTags = (formValues, i18nTranslate) => {
  return validateForm(formValues, i18nTranslate, validateFields)
}

const AssignTags = () => {
  const { t } = useTranslation(['device', 'common'])
  const { formValues, setFormValues } = useContext(WizardContext)

  const onTagListChange = (tagList) => {
    setFormValues({
      ...formValues,
      tagsAssigned: tagList
    })
  }
  const [isNewAssignment, setIsNewAssignment] = useState(false)

  return (
    <>
      {formValues.deviceList.length >= 1 && (
        <Typography
          type="text"
          size="large"
          margin={{ top: '0px', bottom: '0px' }}
          testId="devices-count-status"
        >
          {formValues.deviceList.length === 1
            ? t('device:tags.assign_tags_prompt_single', {
                num_devices_assign_tags: formValues.deviceList.length
              })
            : t('device:tags.assign_tags_prompt_multi', {
                num_devices_assign_tags: formValues.deviceList.length
              })}
        </Typography>
      )}
      <TagsEdit
        onChange={onTagListChange}
        isNewAssignment={isNewAssignment}
        setIsNewAssignment={setIsNewAssignment}
      />
    </>
  )
}

export { AssignTags }
