const CCSActions = {
  SET_IS_IP_RESTRICTED: 'SET_IS_IP_RESTRICTED',
  SET_IDLE_TIMEOUT: 'SET_IDLE_TIMEOUT',
  SET_WORKSPACE_GRN: 'SET_WORKSPACE_GRN',
  SET_CSRF_TOKEN: 'SET_CSRF_TOKEN',
  SET_UPLOAD_VERSION_PERCENTAGE: 'SET_UPLOAD_VERSION_PERCENTAGE',
  SET_AMPLITUDE_ANALYTICS: 'SET_AMPLITUDE_ANALYTICS'
}

export default CCSActions
