import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import DayJS from 'react-dayjs'
import { ThemeContext } from 'styled-components'
import { Box, FormField, NameValueList, NameValuePair } from 'grommet'
import { PropTypes } from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Typography, Dropdown, Anchor } from '../../../../components'
import { getFullLanguage, appTermRedirection } from '../../utils/utils'
import { getCustomerAccount } from '../../../../utils/feature-flag-utils'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../../utils/common-utils'

export const AppDetailList = ({
  app,
  regionList,
  currentRegion,
  setCurrentRegion,
  appName
}) => {
  const { t } = useTranslation(['apps', 'common'])
  const appTermLink = appTermRedirection(appName, t)
  // Allow HPE theme styling to spread onto Typography instances
  // for names in a name value pair
  const {
    nameValuePair: { name }
  } = useContext(ThemeContext)
  const custAccountLoaded = getCustomerAccount()
  const accountType = custAccountLoaded?.account_type
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const getLanguagesList = (appLanguages) => {
    const languageList = appLanguages.map((val) => {
      return getFullLanguage(val)
    })
    return languageList.join(', ')
  }
  return (
    <Box gap="medium" width={{ min: 'xsmall', max: 'medium' }}>
      <Box>
        <Typography type="heading" level="2">
          {t('app_detail.details')}
        </Typography>
      </Box>

      <Box width="medium">
        <NameValueList pairProps={{ direction: 'column' }}>
          {regionList && regionList.length === 1 ? (
            <NameValuePair
              name={
                <Typography
                  type="text"
                  testId="app-detail-list-languages-label"
                  {...name}
                >
                  {t('app_detail.region')}
                </Typography>
              }
            >
              <Typography type="text" testId="app-detail-region-text">
                {regionList[0].label}
              </Typography>
            </NameValuePair>
          ) : (
            <FormField
              name="region"
              label={
                <Typography type="text" {...name}>
                  {t('app_detail.region')}
                </Typography>
              }
              htmlFor="region__input"
            >
              <Dropdown
                name="region"
                id="region"
                testId="app-detail-region-dropdown"
                noBorder
                value={currentRegion}
                options={regionList}
                onChangeDropdown={(values) => setCurrentRegion(values)}
              />
            </FormField>
          )}
          <NameValuePair
            name={
              <Typography
                type="text"
                testId="app-detail-list-languages-label"
                {...name}
              >
                {t('app_detail.languages')}
              </Typography>
            }
          >
            <Typography type="text" testId="app-detail-list-languages">
              {getLanguagesList(app && app.languages ? app.languages : [])}
            </Typography>
          </NameValuePair>
          <NameValuePair
            name={
              <Typography
                type="text"
                testId="app-detail-supported-account-types"
                {...name}
              >
                {t('app_detail.supported_account_types', {
                  workspace: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
              </Typography>
            }
          >
            {accountType ? t(`common:${accountType}`) : '--'}
          </NameValuePair>
          <NameValuePair
            name={
              <Typography
                type="text"
                testId="app-detail-list-documents-label"
                {...name}
              >
                {t('app_detail.documentation')}
              </Typography>
            }
          >
            {app?.docs && (
              <Anchor
                href={app.docs}
                target="_blank"
                label={app.docs}
                testId="application-detail-list-documents"
              />
            )}
          </NameValuePair>
          <NameValuePair
            name={
              <Typography
                type="text"
                testId="app-detail-list-terms-label"
                {...name}
              >
                {t('app_detail.terms')}
              </Typography>
            }
          >
            <Anchor
              href={appTermLink}
              target="_blank"
              label={appTermLink}
              testId="application-detail-list-terms"
            />
          </NameValuePair>
          <NameValuePair
            name={
              <Typography type="text" testId="app-detail-published" {...name}>
                {t('app_detail.published')}
              </Typography>
            }
          >
            {app?.created_at && (
              <DayJS
                data-testid="app-detail-list-created"
                format="MMMM D, YYYY"
              >
                {app.created_at}
              </DayJS>
            )}
          </NameValuePair>
        </NameValueList>
      </Box>
    </Box>
  )
}

AppDetailList.propTypes = {
  app: PropTypes.object,
  regionList: PropTypes.array,
  currentRegion: PropTypes.string.isRequired,
  setCurrentRegion: PropTypes.func.isRequired,
  appName: PropTypes.string
}
AppDetailList.defaultProps = {
  app: null,
  regionList: [],
  appName: ''
}
