import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  AvatarInfo,
  MenuList,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../../components'
import {
  getWorkspaceString,
  WKSPC,
  WKSPC_CAPITALIZED
} from '../../../../utils/common-utils'

import { EditAccountDetails } from './EditAccountDetails'
import { EditMFADetails } from './EditMFADetails'
import { EditCustomerPrograms } from './EditCustomerPrograms'

const EditCustomerModal = ({
  customerData,
  setShowModal,
  fetchCustomerDetails
}) => {
  const { t } = useTranslation(['manage'])
  const [currentView, setCurrentView] = useState('account-details')
  const [customerDataResponse, setCustomerDataResponse] = useState(customerData)
  const [dataChanged, setDataChanged] = useState(false)
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const accountMenuList = [
    {
      id: 1,
      label: t('account_details', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      }),
      view: 'account-details',
      testId: 'menu-item-account-details'
    },
    {
      id: 2,
      label: t('multi_factor_auth'),
      view: 'manage-mfa',
      testId: 'menu-item-manage-mfa'
    },
    {
      id: 3,
      label: t('programs'),
      view: 'manage-programs',
      testId: 'menu-item-manage-programs',
      isHidden: () => !LDFlags['glcp-manage-customer-programs']
    }
  ]

  const menuItemViews = {
    'account-details': (
      <EditAccountDetails
        customerDataResponse={customerDataResponse}
        setCustomerDataResponse={setCustomerDataResponse}
        setShowModal={setShowModal}
        fetchCustomerDetails={fetchCustomerDetails}
        dataChanged={dataChanged}
        setDataChanged={setDataChanged}
      />
    ),
    'manage-mfa': (
      <EditMFADetails
        customerDataResponse={customerDataResponse}
        setCustomerDataResponse={setCustomerDataResponse}
        setShowModal={setShowModal}
        fetchCustomerDetails={fetchCustomerDetails}
        dataChanged={dataChanged}
        setDataChanged={setDataChanged}
      />
    ),
    'manage-programs': (
      <EditCustomerPrograms
        setShowModal={setShowModal}
        customerId={customerDataResponse?.id}
      />
    )
  }

  const getSelectedListId = () => {
    const data = accountMenuList.find((item) => item.view === currentView)
    return data.id
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Box pad="small" direction="row" gap="medium">
              <AvatarInfo
                avatarOnly
                avatarSize="large"
                avatarChar="one"
                primaryInfo={customerDataResponse.name}
                secondaryInfo={t('edit_customer_subtitle', {
                  account: getWorkspaceString(showWorkspaceString, t, WKSPC)
                })}
                testId="account-info"
                {...(customerDataResponse?.logo && {
                  avatarSrc: customerDataResponse?.logo
                })}
              />
              <Box>
                <Typography
                  type="heading"
                  level="1"
                  testId="company-account-page-title"
                >
                  {`${t('account_details', {
                    account: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  })} - ${customerDataResponse.name}`}
                </Typography>
                {showWorkspaceString ? (
                  <Typography
                    type="text"
                    size="large"
                    testId="company-account-page-subtitle"
                  >
                    {t('edit_customer_subtitle_workspace')}
                  </Typography>
                ) : (
                  <Typography
                    type="text"
                    size="large"
                    testId="company-account-page-subtitle"
                  >
                    {t('edit_customer_subtitle2')}
                  </Typography>
                )}
              </Box>
            </Box>
          }
        />
      }
      content={
        <Box gap="large" direction="row" margin={{ top: 'large' }}>
          <Box direction="column" width="small">
            <MenuList
              defaultActiveId={getSelectedListId()}
              highlightOnSelect
              menuData={accountMenuList}
              menuItemPadding={{
                horizontal: 'small',
                vertical: 'xsmall'
              }}
              testId="edit-account-menu-list"
              onClickMenuItem={(item) => setCurrentView(item.view)}
            />
          </Box>
          {menuItemViews[currentView]}
        </Box>
      }
      onClose={() => setShowModal(false)}
      testId="edit-customer-modal-dialog"
      width="xlarge"
    />
  )
}

EditCustomerModal.propTypes = {
  customerData: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired,
  fetchCustomerDetails: PropTypes.func
}

EditCustomerModal.defaultProps = {
  fetchCustomerDetails: () => {}
}

export { EditCustomerModal }
