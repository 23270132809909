import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, TextArea } from 'grommet'
import { isEmpty } from 'lodash'
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import validator from 'validator'
import DOMPurify from 'dompurify'

import { WizardContext } from '../../../../../../../components/wizard/WizardContext'
import { Typography } from '../../../../../../../components'
import { validateForm } from '../../../../../../../utils/validation-utils'
import VisibilityWrapper from '../../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { sanitizeInput } from '../../../utils'

const validateFields = (formValues, i18nTranslate) => {
  const { summary, description, url_text, url } = formValues
  const summaryMaxLength = 256
  const descriptionMaxLength = 2048
  const urltextMaxLength = 64
  let errorMessage
  let descriptionerrorMessage
  let descriptionMessageValid
  let url_texterror = null
  if (isEmpty(summary?.trim())) {
    errorMessage = i18nTranslate('required')
  } else if (DOMPurify.sanitize(formValues?.summary) !== formValues?.summary) {
    errorMessage = i18nTranslate('notification.sanitise_warn')
  } else if (summary?.length >= summaryMaxLength) {
    errorMessage = i18nTranslate('notification.max_length')
  }
  if (!isEmpty(url?.trim()) && isEmpty(url_text?.trim())) {
    url_texterror = i18nTranslate('required')
  } else if (sanitizeInput(formValues?.url_text) !== formValues?.url_text) {
    url_texterror = i18nTranslate('notification.sanitise_warn')
  } else if (url_text?.length >= urltextMaxLength) {
    url_texterror = i18nTranslate('notification.max_length')
  }

  if (!isEmpty(description)) {
    if (description?.length >= descriptionMaxLength) {
      descriptionMessageValid = false
      descriptionerrorMessage = i18nTranslate('notification.max_length')
    } else if (
      sanitizeInput(formValues?.description)?.length !==
      formValues?.description?.length
    ) {
      descriptionMessageValid = false
      descriptionerrorMessage = i18nTranslate('notification.sanitise_warn')
    } else {
      descriptionMessageValid = true
    }
  } else {
    descriptionMessageValid = true
  }

  const portalMessageValid =
    summary?.trim() &&
    summary?.length <= summaryMaxLength &&
    DOMPurify.sanitize(formValues?.summary) === formValues?.summary
  const urlValid =
    validator.isURL(url, {
      protocols: ['https'],
      require_protocol: true
    }) || isEmpty(url?.trim())

  return {
    summary: portalMessageValid ? '' : errorMessage,
    description: descriptionMessageValid ? '' : descriptionerrorMessage,
    isValid:
      !!portalMessageValid &&
      !!descriptionMessageValid &&
      !!urlValid &&
      !url_texterror,
    url: urlValid ? '' : i18nTranslate('notification.invalid_link'),
    url_text: url_texterror
  }
}

export const validatePortalMessage = (formValues, i18nTranslate) => {
  return validateForm(formValues, i18nTranslate, validateFields)
}

const PortalNotification = ({ isEdit }) => {
  const { t } = useTranslation(['manage'])

  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)
  const [errorMessage, setErrorMessage] = useState({})

  useEffect(() => {
    if (attemptedAdvance) {
      const validation = validateFields(formValues, t)
      setErrorMessage(validation)
    }
  }, [formValues, attemptedAdvance, t])

  return (
    <Box
      gap="small"
      width="medium"
      justify="between"
      align="center"
      alignSelf="center"
    >
      {' '}
      <Box width="medium" margin={{ bottom: 'small' }}>
        <Typography
          type="text"
          size="small"
          color="black"
          testId="banner-checkbox-summary-label"
        >
          {t('notification.content')}
        </Typography>
        <Typography
          type="text"
          size="small"
          color="black"
          testId="banner-checkbox-summary"
        >
          {t('notification.length_check')}
        </Typography>
        <FormField
          name="summary"
          margin={{ bottom: 'none' }}
          error={errorMessage.summary}
          data-testid="set-up-banner-summary"
          required
        >
          <TextArea
            size="medium"
            value={formValues.summary}
            name="summary"
            placeholder={t('notification.message')}
            data-testid="portal-summary"
            onChange={(event) =>
              setFormValues({
                ...formValues,
                summary: event.target.value
              })
            }
          />
        </FormField>
      </Box>
      <Box width="medium" margin={{ bottom: 'small' }}>
        <Typography
          type="text"
          size="small"
          color="black"
          testId="banner-checkbox-description-label"
        >
          {t('notification.additional_content_label')}
        </Typography>
        <Typography
          type="text"
          size="small"
          color="black"
          testId="banner-checkbox-description"
        >
          {t('notification.additional_content_description')}
        </Typography>
        <FormField
          name="description"
          margin={{ bottom: 'none' }}
          error={errorMessage?.description}
          data-testid="set-up-banner-description"
        >
          <TextArea
            size="medium"
            value={formValues.description}
            name="description"
            placeholder={t('notification.summary')}
            data-testid="portal-description"
            onChange={(event) =>
              setFormValues({
                ...formValues,
                description: event.target.value
              })
            }
          />
        </FormField>
      </Box>
      <VisibilityWrapper
        hideFor={{
          feature: 'glcp-notifications-feature-unsubscribe-helsinki'
        }}
      >
        <Box width="medium" margin={{ bottom: 'small' }}>
          <Typography
            type="text"
            size="small"
            color="black"
            testId="banner-checkbox-description"
          >
            {t('notification.url')}
          </Typography>
          <Typography
            type="text"
            size="small"
            color="black"
            testId="banner-checkbox-summary"
          >
            {t('notification.url_label')}
          </Typography>

          <FormField
            name="url"
            margin={{ bottom: 'none' }}
            error={errorMessage.url}
            data-testid="set-up-url"
          >
            <TextArea
              size="medium"
              value={formValues.url}
              disabled={isEdit}
              name="summary"
              placeholder={t('notification.url_placeholder')}
              data-testid="portal-url"
              onChange={(event) => {
                if (event.target.value.trim().length === 0) {
                  setFormValues({
                    ...formValues,
                    url_text: '',
                    url: ''
                  })
                } else {
                  setFormValues({
                    ...formValues,
                    url: event.target.value
                  })
                }
              }}
            />
          </FormField>
        </Box>
        <Box width="medium" margin={{ bottom: 'large' }}>
          <Typography
            type="text"
            size="small"
            color="black"
            testId="banner-checkbox-urltext"
          >
            {t('notification.url_text')}
          </Typography>
          <Typography
            type="text"
            size="small"
            color="black"
            testId="banner-checkbox-summary"
          >
            {t('notification.url_text_label')}
          </Typography>

          <FormField
            name="url_text"
            margin={{ bottom: 'none' }}
            error={errorMessage.url_text}
            data-testid="set-up-url text"
          >
            <TextArea
              size="medium"
              value={formValues.url_text}
              name="url_text"
              placeholder={t('notification.url_text_placeholder')}
              disabled={formValues?.url.trim().length === 0 || isEdit}
              data-testid="portal-url-text"
              onChange={(event) => {
                if (event.target.value.trim().length > 0) {
                  setFormValues({
                    ...formValues,
                    url_text: event.target.value
                  })
                } else {
                  setFormValues({
                    ...formValues,
                    url_text: ''
                  })
                }
              }}
            />
          </FormField>
        </Box>
      </VisibilityWrapper>
    </Box>
  )
}

PortalNotification.prototypes = {
  isEdit: PropTypes.bool.isRequired
}

export default PortalNotification
