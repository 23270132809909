const AuditLogsActions = {
  SET_APPLICATION: 'SET_APPLICATION',
  SET_AUDIT_RESPONSE: 'SET_AUDIT_RESPONSE',
  SET_APPLICATION_CONFIG: 'SET_APPLICATION_CONFIG',
  CLEAR_ADVANCED_SEARCH: 'CLEAR_ADVANCED_SEARCH',
  SET_ADVANCED_SEARCH_PAYLOAD: 'SET_ADVANCED_SEARCH_PAYLOAD',
  SET_ADVANCED_SEARCH_QUERY: 'SET_ADVANCED_SEARCH_QUERY',
  SET_SELECTED_COLUMNS: 'SET_SELECTED_COLUMNS',
  SET_ALL_LOGS_COUNT: 'SET_ALL_LOGS_COUNT',
  SET_TOTAL_AUDIT_LOGS_COUNT: 'SET_TOTAL_AUDIT_LOGS_COUNT',
  CLEAR_AUDIT_LOGS: 'CLEAR_AUDIT_LOGS',
  SET_SORT_CONFIG: 'SET_SORT_CONFIG'
}

export default AuditLogsActions
