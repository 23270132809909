import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  ModalDialog,
  ModalHeader,
  Logo,
  Button,
  Typography
} from '../../../../../../components'
import { AddApplicationModal } from '../../../../../applications/components'
import { AppCatalogContextProvider } from '../../../../../../context/app-catalog-context'
import {
  APP_SUPPORTED_SUBSCRIPTION_TIERS,
  VALID_PRODUCT_ATTR_START_PATTERN,
  VM_COUNT_PRODUCT_ATTR,
  BACKUP_CAPACITY_PRODUCT_ATTR
} from '../../../constants'
import { getServiceDefinitionNameBySlug } from '../../../utils'
import { navigateToServicePageViaSubscription } from '../../../../../../utils/common-utils'

const SideDetailsPanel = ({ data, appData, onClose }) => {
  const { t } = useTranslation(['device'])
  const { t: appsT } = useTranslation(['apps'])
  const LDFlags = useFlags()
  const [showAddAppModal, setShowAddAppModal] = useState()
  const history = useHistory()
  const [errorMessage, setErrormessage] = useState(null)
  const [appName, setAppName] = useState('')
  const { oidcUser } = useReactOidc()

  useEffect(() => {
    const serviceDefinitionName = getServiceDefinitionNameBySlug(
      LDFlags,
      appData
    )
    setAppName(serviceDefinitionName)
  }, [LDFlags, appData])

  const sidePanelKeys = {
    app_id: {
      renderer: () => {
        return (
          <Box margin={{ bottom: 'small' }} key="app">
            <Typography
              size="xsmall"
              type="text"
              margin={{ bottom: 'xsmall' }}
              testId="app-key"
              color="text-strong"
              weight="bold"
            >
              {t('application')}
            </Typography>
            <Typography
              size="medium"
              type="text"
              margin={{ bottom: 'small' }}
              testId="app-value"
              color="text-strong"
              icon={
                <Logo
                  testId="app-logo"
                  size="xxsmall"
                  url={appData?.application?.logo}
                />
              }
            >
              {appName}
            </Typography>
            <Box width="small">
              {appData?.go_to_application_flag ? (
                <Button
                  secondary
                  label={t('go_to_application')}
                  size="small"
                  testId="view-app-btn"
                  disabled={!appData?.application_id}
                  onClick={() => {
                    navigateToServicePageViaSubscription({
                      history,
                      appId: appData?.application_id,
                      appName,
                      appSlug: appData?.application?.slug,
                      LDFlags,
                      oidcUser
                    })
                  }}
                />
              ) : (
                <Button
                  secondary
                  label={t('add')}
                  size="small"
                  testId="add-app-btn"
                  disabled={!appData?.application_id}
                  onClick={() => {
                    setShowAddAppModal(true)
                  }}
                />
              )}
            </Box>
          </Box>
        )
      }
    },
    product_description: t('name'),
    subscription_key: t('key'),
    subscription_type: t('type'),
    ...(LDFlags['glcp-show-service-subscription-tier'] && {
      subscription_tier_description: t('tier')
    }),
    license_state_type: t('license_state_type'),
    quantity: t('quantity'),
    subscription_start: t('start_date'),
    subscription_end: t('expiration_date'),
    vm_count: t('vm_count'),
    backup_capacity: t('backup_capacity')
  }

  const createRowdata = (datum) => {
    const additionalSubscriptionProductDetails = []
    datum?.product_attributes?.forEach((productAttr) => {
      // All Product Attributes starts with CS_CCM_ excluding CS_CCM_MULTIV_UOM needs to be displayed in Side Panel
      if (productAttr?.name?.startsWith(VALID_PRODUCT_ATTR_START_PATTERN)) {
        additionalSubscriptionProductDetails?.push({
          key: productAttr?.name_display,
          value: productAttr?.value_display,
          testId: productAttr?.name
        })
      }
    })

    const subscriptiondata = Object.keys(sidePanelKeys).map((key) => {
      if (['vm_count', 'backup_capacity'].includes(key)) {
        const valueObj = datum?.product_attributes?.find(
          (value) =>
            value.name ===
            (key === 'vm_count'
              ? VM_COUNT_PRODUCT_ATTR
              : BACKUP_CAPACITY_PRODUCT_ATTR)
        )
        let valueString = `${valueObj?.value || ''}`
        if (valueString) {
          const unit = key === 'vm_count' ? ' VM' : ' GB'
          valueString += unit
          return {
            key: sidePanelKeys[key],
            value: valueString || '',
            testId: key
          }
        }
        return { key: null, value: null }
      }

      if (
        key === 'app_id' &&
        !APP_SUPPORTED_SUBSCRIPTION_TIERS?.includes(data?.subscription_tier)
      ) {
        return { key: null, value: null }
      }

      return typeof sidePanelKeys[key] !== 'object'
        ? {
            key: sidePanelKeys[key],
            value: datum[key],
            testId: key
          }
        : {
            data: datum,
            renderer: sidePanelKeys[key].renderer,
            testId: key
          }
    })
    return [...subscriptiondata, ...additionalSubscriptionProductDetails]
  }

  const panelData = createRowdata(data)

  const getValue = (value) => {
    let fieldValue = value
    if (!value) {
      fieldValue = '--'
    }
    return fieldValue
  }

  return (
    <>
      <ModalDialog
        content={
          <Box
            margin={{ vertical: 'medium' }}
            direction="column"
            flex={{ shrink: 0 }}
          >
            {panelData.map((datum) => {
              if (datum?.renderer) {
                return datum?.renderer(datum?.data)
              }
              return datum?.key ? (
                <Box key={`${datum?.key}-data`}>
                  <Typography
                    size="xsmall"
                    type="text"
                    margin={{ bottom: 'xsmall' }}
                    testId={`${datum?.testId}-key`}
                    color="text-strong"
                    weight="bold"
                  >
                    {datum?.key}
                  </Typography>
                  <Typography
                    size="small"
                    type="text"
                    margin={{ bottom: 'small' }}
                    testId={`${datum?.testId}-value`}
                    color="text-strong"
                  >
                    {getValue(datum?.value)}
                  </Typography>
                </Box>
              ) : null
            })}
          </Box>
        }
        header={
          <ModalHeader
            title={
              <Typography
                level="1"
                testId="service-subscription-side-panel-title"
                type="heading"
                weight="bold"
              >
                {t('subscription_details')}
              </Typography>
            }
            onClose={() => {
              onClose(false)
            }}
          />
        }
        height="100%"
        width="medium"
        onClose={() => {
          onClose(false)
        }}
        position="right"
        testId="service-subscription-side-drawer-dialog"
      />
      {showAddAppModal && (
        <AppCatalogContextProvider>
          <AddApplicationModal
            handleClose={() => setShowAddAppModal(false)}
            appId={appData.application_id}
            appName={appName}
            appSlug={appData?.application?.slug}
            setShowErrorNotification={setErrormessage}
            fromServiceSubs
            modalTitle={appsT('deploy_application')}
            modalSubtitle={
              <Trans i18nKey="add_region_subtitle" t={appsT}>
                {appData.application.name}
              </Trans>
            }
            addBtnText={appsT('deploy')}
          />
        </AppCatalogContextProvider>
      )}
      {errorMessage}
    </>
  )
}

export default SideDetailsPanel

SideDetailsPanel.propTypes = {
  data: PropTypes.object,
  appData: PropTypes.object,
  onClose: PropTypes.func.isRequired
}

SideDetailsPanel.defaultProps = {
  data: {},
  appData: {}
}
