import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, FormField } from 'grommet'
import { CircleAlert } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import isEmpty from 'lodash/isEmpty'

import { Typography, ToggleButton } from '../../../components'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import {
  DisableMFAModal,
  SetupMFAModal,
  ViewEnabledAccounts
} from './components'
import { getMFAStatus, doEnableMFA, getMfaAccounts } from './utils/api-utils'

const ProfileSecurity = () => {
  const { t } = useTranslation(['authn'])
  const { oidcUser } = useReactOidc()
  const [status, setStatus] = useState({
    MFA: false,
    mfaEnforced: null
  })

  const [disableMFAModalLayerOpen, setDisableMFAModalLayerOpen] =
    useState(false)
  const [viewEnabledAccountsModal, setViewEnabledAccountsModal] =
    useState(false)
  const [showSetupMFAModal, setShowSetupMFAModal] = useState(false)
  const [showNotification, setShowNotification] = useState(null)
  const [MFAData, setMFAData] = useState({})
  const [MfaAccount, setMfaAccount] = useState([])

  const updateMFAData = useCallback((data) => {
    setMFAData(data)
    if (!isEmpty(data)) {
      setShowSetupMFAModal(true)
    }
  }, [])

  const updateMfaAccounts = useCallback((data) => {
    setMfaAccount(data)
    if (!isEmpty(data)) {
      setViewEnabledAccountsModal(true)
    }
  }, [])
  useEffect(() => {
    getMFAStatus(oidcUser.access_token, setStatus, setShowNotification, t)
  }, [oidcUser.access_token, setStatus, setShowNotification, t])

  const showSetupMFA = (checked) => {
    if (checked) {
      doEnableMFA(oidcUser.access_token, updateMFAData, setShowNotification, t)
    }
    setDisableMFAModalLayerOpen(!checked)
    setStatus({ ...status, MFA: checked })
  }
  const handleMFASetupClose = () => {
    setShowSetupMFAModal(false)
    setStatus({ ...status, MFA: false })
  }
  const handleMFASetupSuccess = (obj) => {
    setShowSetupMFAModal(!status.MFA)
    setShowNotification(obj)
  }
  const handleDisableMFASuccess = (obj) => {
    setDisableMFAModalLayerOpen(false)
    setShowNotification(obj)
  }
  const handleDisableMFAClose = () => {
    setDisableMFAModalLayerOpen(false)
    setStatus({ ...status, MFA: true })
  }
  const handleOpenViewEnabled = () => {
    getMfaAccounts(
      oidcUser.access_token,
      updateMfaAccounts,
      setShowNotification,
      t
    )
  }

  const handleCloseViewEnabled = () => {
    setViewEnabledAccountsModal(false)
  }
  return (
    <>
      <Box width="xlarge" height="large">
        <Typography level="2" type="heading" testId="security-title">
          {t('security.title')}
        </Typography>
        <Typography size="medium" type="text" testId="security-description">
          {t('security.description')}
        </Typography>
        <Box direction="row" gap="xlarge">
          <Box basis="medium">
            <Typography
              level="3"
              type="heading"
              margin={{ top: 'medium', bottom: 'none' }}
              testId="mfa-header"
            >
              {t('security.mfa_subhdr')}
            </Typography>
            <Typography
              size="medium"
              type="paragraph"
              margin={{ top: 'xsmall', bottom: 'small' }}
              testId="mfa-subheader"
            >
              {t('security.mfa_description')}
            </Typography>

            <Typography size="xsmall" type="text" testId="mfa-toggle-header">
              {t('security.mfa_toggle_header')}
            </Typography>
            <FormField name="MFA" data-testid="enable-mfa-form-field">
              <VisibilityWrapper
                rbac={{
                  resource: '/ccs/accounts/platform/customer',
                  permission: 'ccs.accounts.platform.customer.edit'
                }}
              >
                <ToggleButton
                  label={t('security.mfa_toggle_label')}
                  onChange={(event) => showSetupMFA(event.target.checked)}
                  checked={status.MFA}
                  testId="mfa-toggle-btn"
                  disabled={status.mfaEnforced}
                />
              </VisibilityWrapper>
            </FormField>
            {status.mfaEnforced && (
              <>
                <Box
                  round="4px"
                  gap="xsmall"
                  direction="row"
                  align="center"
                  data-testid="remind-info-box"
                >
                  <CircleAlert size="small" />
                  <Typography
                    type="text"
                    size="xsmall"
                    testId="success-info-message"
                  >
                    {t('security.mfa_remind_info')}
                  </Typography>
                </Box>
                <Box
                  direction="row"
                  align="start"
                  justify="between"
                  pad={{ top: 'medium', bottom: 'small' }}
                >
                  <Button
                    secondary
                    label={t('security.view_enabled_accounts_btn')}
                    onClick={handleOpenViewEnabled}
                    testId="view-enabled-accounts-btn"
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {viewEnabledAccountsModal && MfaAccount && (
        <ViewEnabledAccounts
          handleCloseViewEnabled={handleCloseViewEnabled}
          accounts={MfaAccount}
        />
      )}
      {showNotification}
      {disableMFAModalLayerOpen && (
        <DisableMFAModal
          handleCloseDisable={handleDisableMFAClose}
          handleDisableSuccess={handleDisableMFASuccess}
        />
      )}
      {showSetupMFAModal && !isEmpty(MFAData) && (
        <SetupMFAModal
          handleClose={handleMFASetupClose}
          handleSuccess={handleMFASetupSuccess}
          MFAData={MFAData}
        />
      )}
    </>
  )
}

export default ProfileSecurity
