import React from 'react'
import { Box } from 'grommet'

import { Typography } from '../../../components'

import FeatureFlagsStatusTable from './FeatureFlagsStatusTable'
import { ldFlagsStatusLang } from './utils'

const FeatureFlagsStatus = () => {
  return (
    <Box gap="medium" pad="xsmall" width="xxlarge" direction="column">
      <Box>
        <Typography level="2" type="heading" testId="ldFlagsStatus-page-title">
          {ldFlagsStatusLang.title}
        </Typography>
      </Box>
      <Box margin={{ top: 'medium' }}>
        <FeatureFlagsStatusTable />
      </Box>
    </Box>
  )
}
export default FeatureFlagsStatus
