import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min'
import { Box } from 'grommet'
import { User, Trash } from 'grommet-icons'
import { isEmpty } from 'lodash'

import {
  ActionButton,
  Anchor,
  Typography,
  Button,
  DataTable,
  NoDataInfo
} from '../../../../components'
import {
  get,
  getBaseUrl,
  getErrorMessage,
  patch
} from '../../../../utils/api-utils'
import AssignUsersToGroupModal from '../../commoncomponents/AssignUsersToGroupModal'
import RemoveUserFromGroupConfirmation from '../../commoncomponents/RemoveUserFromGroupConfirmation'
import Markdown from '../../../commoncomponents/Markdown'
import { USER_SCHEMAS } from '../../users/constants'
import { GROUP_SCHEMAS } from '../constants'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

const GroupDetailsUsers = ({
  groupDetailsData,
  setNotificationInfo,
  parentLoading,
  isWorkspace
}) => {
  const { t } = useTranslation(['iam', 'common'])
  const history = useHistory()
  const { groupId } = useParams()
  const [groupUsers, setGroupUsers] = useState()
  const [loading, setLoading] = useState(true)
  const [refreshCount, setRefreshCount] = useState(0)
  const [selectedUser, setSelectedUser] = useState()
  const [showAddUsersDialog, setShowAddUsersDialog] = useState(false)
  const [showDeleteUsersDialog, setShowDeleteUsersDialog] = useState(false)

  useEffect(() => {
    let isCurrent = true
    const getGroupUsers = async () => {
      await get(
        `/identity/v2alpha1/scim/v2/extensions/Groups/${groupId}/users`
      ).then(
        (response) => {
          if (!isCurrent) return
          if (response?.data?.Resources) {
            setGroupUsers(response?.data?.Resources)
          }
          setLoading(false)
        },
        (error) => {
          if (!isCurrent) return
          setNotificationInfo(getErrorMessage(error, t), 'error')
          setLoading(false)
        }
      )
    }
    getGroupUsers()
    return () => {
      isCurrent = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount])

  const removeUserFromGroup = async () => {
    setLoading(true)
    const user = selectedUser
    const value = [
      {
        display: user?.displayName,
        $ref: `${getBaseUrl()}/identity/v2alpha1/scim/v2/Users/${user?.id}`,
        value: user?.id
      }
    ]
    try {
      await patch(`/identity/v2alpha1/scim/v2/Groups/${groupId}`, {
        schemas: [GROUP_SCHEMAS.PATCH_OP_SCHEMA],
        Operations: [
          {
            op: 'remove',
            value,
            path: 'members'
          }
        ]
      })
      setLoading(false)
      setNotificationInfo(
        <Markdown>
          {t('iam:igc_users.remove_user_from_group_notif_msg', {
            username: user?.displayName || user?.userName,
            groupName: groupDetailsData?.displayName
          })}
        </Markdown>,
        'info',
        t('iam:users.user_removed_title')
      )
      setGroupUsers((state) => {
        return state?.filter((item) => item?.userName !== user?.userName)
      })
      setRefreshCount((prevCount) => prevCount + 1)
    } catch (error) {
      setLoading(false)
      setNotificationInfo(getErrorMessage(error, t), 'error')
    } finally {
      setShowDeleteUsersDialog(false)
    }
  }

  const userTableColumns = [
    {
      property: 'name',
      header: t('common:name'),
      align: 'start',
      render: (datum) => {
        const label = datum?.displayName || '--'
        return isWorkspace ? (
          <Typography type="text" emphasis>
            {label}
          </Typography>
        ) : (
          <Anchor
            href="#"
            label={label}
            testId="group-user-anchor"
            onClick={(event) => {
              event?.preventDefault()
              history.push(`/manage-account/organization/users/${datum?.id}`)
            }}
          />
        )
      }
    },
    {
      property: 'userName',
      header: t('common:email'),
      align: 'start',
      primary: true
    },
    {
      property: 'verified',
      header: t('common:verified'),
      align: 'start',
      render: (datum) => {
        const verified = datum[USER_SCHEMAS?.EXTENSIONS]?.primaryEmailVerified
        return (
          <Typography testId="user-verified" type="text">
            {verified === true
              ? t('iam:verified_status_yes')
              : t('iam:verified_status_no')}
          </Typography>
        )
      }
    },
    ...(!isWorkspace
      ? [
          {
            property: '',
            sortable: false,
            header: '',
            align: 'end',
            render: (datum) => {
              return (
                <VisibilityWrapper
                  rbac={{
                    permission: 'identity.user-group-members.update'
                  }}
                >
                  <ActionButton
                    actions={[
                      {
                        icon: <Trash />,
                        onClick: () => {
                          setSelectedUser(datum)
                          setShowDeleteUsersDialog(true)
                        }
                      }
                    ]}
                    datum={datum}
                    testId="group-user-action-btn"
                  />
                </VisibilityWrapper>
              )
            }
          }
        ]
      : [])
  ]

  return (
    <>
      <Box
        gap="medium"
        pad={{ top: 'medium', vertical: 'medium' }}
        width={isEmpty(groupUsers) ? 'medium' : 'large'}
      >
        <Box justify="start" gap="small">
          <Box
            direction="row"
            justify="between"
            gap="medium"
            pad={{ top: 'medium' }}
          >
            <Typography
              type="heading"
              level="2"
              testId="user-details-group-title"
            >
              {t('iam:users.users')}
            </Typography>
            {!isWorkspace && !loading && groupUsers?.length > 0 && (
              <VisibilityWrapper
                rbac={{
                  permission: 'identity.user-group-members.update'
                }}
              >
                <Button
                  label={t('iam:users.add_users_label')}
                  secondary
                  testId="add-user-to-group-btn"
                  disabled={parentLoading}
                  onClick={() => {
                    setShowAddUsersDialog(true)
                  }}
                />
              </VisibilityWrapper>
            )}
          </Box>
          {((groupUsers && groupUsers?.length !== 0) || loading) && (
            <Box width="large" margin={{ top: 'small' }}>
              <DataTable
                grid={{
                  data: groupUsers,
                  columns: userTableColumns
                }}
                loading={loading || parentLoading}
                testId="user-groups-datatable"
              />
            </Box>
          )}
          {groupUsers && groupUsers?.length === 0 && (
            <Box pad={{ top: 'medium' }}>
              {isWorkspace ? (
                <NoDataInfo
                  icon={<User size="large" />}
                  gap="small"
                  title={t('iam:users.no_users_title')}
                  testId="no-users"
                />
              ) : (
                <NoDataInfo
                  icon={<User size="large" />}
                  gap="small"
                  gapTitleSubtitle="small"
                  action={
                    <VisibilityWrapper
                      rbac={{
                        permission: 'identity.user-group-members.update'
                      }}
                    >
                      <Button
                        label={t('iam:users.add_users_label')}
                        onClick={() => {
                          setShowAddUsersDialog(true)
                        }}
                        testId="add-user-to-group-btn"
                        secondary
                      />
                    </VisibilityWrapper>
                  }
                  title={t('iam:organization_groups.no_add_user_added')}
                  subtitle={
                    <VisibilityWrapper
                      rbac={{
                        permission: 'identity.user-group-members.update'
                      }}
                    >
                      {t(
                        'iam:organization_groups.get_started_add_users_to_group'
                      )}
                    </VisibilityWrapper>
                  }
                  testId="no-assigned-user"
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      {showDeleteUsersDialog && (
        <RemoveUserFromGroupConfirmation
          loading={loading}
          onSetOpen={setShowDeleteUsersDialog}
          user={selectedUser}
          userGroupToRemove={groupDetailsData}
          handleRemove={removeUserFromGroup}
        />
      )}
      {showAddUsersDialog && (
        <AssignUsersToGroupModal
          onSetOpen={setShowAddUsersDialog}
          onSubmit={() => {
            setRefreshCount((prevCount) => prevCount + 1)
          }}
          group={groupDetailsData}
          setNotificationInfo={setNotificationInfo}
        />
      )}
    </>
  )
}

GroupDetailsUsers.propTypes = {
  groupDetailsData: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string
  }).isRequired,
  setNotificationInfo: PropTypes.func.isRequired,
  parentLoading: PropTypes.bool.isRequired,
  /**
   * True if called from v2 workspace, false if called from IGC pages
   */
  isWorkspace: PropTypes.bool
}

GroupDetailsUsers.defaultProps = {
  isWorkspace: false
}
export default GroupDetailsUsers
