import React from 'react'
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useHistory
} from 'react-router-dom'

import { checkGotoChooseAccount } from '../../../../utils/common-utils'

import { RolesPage } from './RolesPage'
import { RoleDetails } from './RoleDetails'

const AuthzV2RolesRouter = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  checkGotoChooseAccount(history)

  return (
    <Switch>
      <Route exact path={path}>
        <RolesPage />
      </Route>
      <Route exact path={`${path}/:grn`}>
        <RoleDetails />
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  )
}

export { AuthzV2RolesRouter }
