import React from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { Copy } from 'grommet-icons'

import { Button, Typography, Tooltip } from '../../../../../../components'
import Markdown from '../../../../../commoncomponents/Markdown'
import { idp } from '../../../constants'

import { handleCopy } from './utils/page-utils'

const ConfigureIdp = () => {
  const { t } = useTranslation(['iam'])

  const ssoSetupGuideUrl = 'www.hpe.com/info/SSO-Setup-Guide'

  const copyButtonFn = ({ hover, focus }) => (
    <Tooltip
      dropProps={{ align: { left: 'right' } }}
      info={
        <>
          {hover && !focus ? t('authz:copy') : null}
          {hover && focus ? t('authz:copied') : null}
        </>
      }
      testId="copy-tooltip"
    >
      <Copy size="medium" />
    </Tooltip>
  )

  return (
    <Box flex={false}>
      <Typography
        type="heading"
        level={2}
        data-testid="copy-service-provider-details-title"
        color="light"
        margin={{ top: 'none', bottom: 'xsmall' }}
      >
        {t('iam:sso_profiles.view_edit_create.copy_service_provider_details')}
      </Typography>

      <Box>
        <Markdown>
          {t('iam:sso_profiles.view_edit_create.copy_sso_configuration')}
        </Markdown>
      </Box>

      <Box direction="row" align="end">
        <Box direction="column" basis="80%">
          <Typography
            margin={{ top: 'medium' }}
            size="small"
            testId="entity-id"
            type="text"
          >
            <>
              <strong>
                {t('iam:sso_profiles.view_edit_create.entity_id')}
              </strong>
            </>
          </Typography>
          <Typography
            size="xsmall"
            margin={{ bottom: 'small' }}
            testId="entity-id-text"
            type="text"
          >
            <>{t('iam:sso_profiles.view_edit_create.entity_id_description')}</>
          </Typography>
          <Box direction="row" align="center">
            <Typography
              type="text"
              emphasis
              data-testid="entity-id-url"
              color="light"
            >
              {idp.entityIdUrl}
            </Typography>
          </Box>
        </Box>
        <Box direction="column" align="start" basis="20%">
          <Button
            default
            pad={{ vertical: 'none' }}
            onClick={() => handleCopy(idp.entityIdUrl)}
            hoverIndicator="none"
            testId="entity-id-url-copy-btn"
          >
            {copyButtonFn}
          </Button>
        </Box>
      </Box>

      <Box direction="row" align="end">
        <Box direction="column" basis="80%">
          <Typography
            margin={{ top: 'medium' }}
            size="small"
            testId="acs"
            type="text"
          >
            <>
              <strong>{t('iam:sso_profiles.view_edit_create.acs_url')}</strong>
            </>
          </Typography>
          <Typography
            size="xsmall"
            margin={{ bottom: 'small' }}
            testId="acs-text"
            type="text"
          >
            <>{t('iam:sso_profiles.view_edit_create.acs_url_description')}</>
          </Typography>
          <Box direction="row" align="center">
            <Typography
              type="text"
              emphasis
              data-testid="acs-url"
              color="light"
            >
              {idp.acsUrl}
            </Typography>
          </Box>
        </Box>
        <Box direction="column" align="start" basis="20%">
          <Button
            default
            pad={{ vertical: 'none' }}
            onClick={() => handleCopy(idp.acsUrl)}
            hoverIndicator="none"
            testId="acs-url-copy-btn"
          >
            {copyButtonFn}
          </Button>
        </Box>
      </Box>

      <Box direction="row" align="end">
        <Box direction="column" basis="80%">
          <Typography
            margin={{ top: 'medium' }}
            size="small"
            testId="acs"
            type="text"
          >
            <>
              <strong>
                {t('iam:sso_profiles.view_edit_create.default_relay_state')}
              </strong>
            </>
          </Typography>
          <Typography
            size="xsmall"
            margin={{ bottom: 'small' }}
            testId="default-relay-state-text"
            type="text"
          >
            <>
              {t(
                'iam:sso_profiles.view_edit_create.default_relay_state_description'
              )}
            </>
          </Typography>
          <Box direction="row" align="center">
            <Typography
              type="text"
              emphasis
              data-testid="default-relay-state-url"
              color="light"
              margin={{ bottom: 'medium' }}
            >
              {idp.defaultRelayStateUrl}
            </Typography>
          </Box>
        </Box>
        <Box direction="column" align="start" basis="20%">
          <Button
            default
            pad={{ vertical: 'none' }}
            onClick={() => handleCopy(idp.defaultRelayStateUrl)}
            hoverIndicator="none"
            testId="default-relay-state-url-copy-btn"
            margin={{ bottom: 'medium' }}
          >
            {copyButtonFn}
          </Button>
        </Box>
      </Box>

      <Box pad={{ top: 'medium' }}>
        <Box direction="row" align="center" justify="between">
          <Typography
            type="heading"
            level={2}
            data-testid="text-record-administrator-title"
            color="light"
          >
            {t('iam:sso_profiles.view_edit_create.not_idp_administrator')}
          </Typography>

          <Box direction="row" align="center">
            <Typography
              type="text"
              data-testid="domain-verification-guide-title"
              size="small"
              weight={600}
              color="light"
            >
              {t('iam:sso_profiles.view_edit_create.sso_setup_guide')}
            </Typography>

            <Button
              default
              onClick={() => {
                handleCopy(ssoSetupGuideUrl)
              }}
              hoverIndicator="none"
              testId="domain-verification-guide-copy-btn"
            >
              {copyButtonFn}
            </Button>
          </Box>
        </Box>

        <Typography type="text" margin={{ bottom: 'medium' }}>
          {t(
            'iam:sso_profiles.view_edit_create.share_details_with_administrator'
          )}
        </Typography>
      </Box>
    </Box>
  )
}

export default ConfigureIdp
