import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import ReuseableDiscardChangesModal from '../../manage-account/commoncomponent/folder-details/components/ReuseableDiscardChangesModal'

const DiscardChangesModal = ({ onContinue, closeModal }) => {
  const { t } = useTranslation('common')

  return (
    <ReuseableDiscardChangesModal
      testId="discard-changes-modal-dialog"
      footerButtonslist={[
        {
          label: t('common:cancel'),
          default: true,
          testId: 'cancel-btn',
          onClick: closeModal
        },
        {
          label: t('common:continue'),
          primary: true,
          testId: 'continue-btn',
          onClick: onContinue
        }
      ]}
      footerTestId="discard-changes-action-buttons"
      onClose={closeModal}
    />
  )
}

DiscardChangesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired
}

export { DiscardChangesModal }
