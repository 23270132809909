import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'
import { AuditLogsContextProvider } from '../../../context/auditlogs-context'

const AuditLogsPage = lazy(() => import('./AuditLogs'))

const AuditLogsRouterContent = () => {
  const { path } = useRouteMatch()
  return (
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route path={`${path}`}>
          <AuditLogsPage />
        </Route>
      </Switch>
    </Suspense>
  )
}

const AuditLogsRouter = () => {
  return (
    <AuditLogsContextProvider>
      <AuditLogsRouterContent />
    </AuditLogsContextProvider>
  )
}

export default AuditLogsRouter
