import React, { useContext, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { ResponsiveContext } from 'grommet'

import { LINKS, getLink } from '../utils/links-util'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import { TilesRow } from './common/TilesRow'

const OtherResources = memo(() => {
  const { t } = useTranslation(['support_hub'])

  const size = useContext(ResponsiveContext)

  const columns = { xlarge: 1, large: 2, medium: 2, small: 2, xsmall: 1 }

  const otherResourcesTiles = [
    {
      id: 1,
      title: t('other_resources_cards.hpe_support_center.title'),
      description: t('other_resources_cards.hpe_support_center.description'),
      testId: 'hpe_support_center-tile',
      isIconInTitle: true,
      isLinkExternal: true,
      href: getLink(LINKS.HPE_SUPP_CENTER),
      target: LINKS.HPE_SUPP_CENTER
    },
    {
      id: 2,
      title: t('other_resources_cards.hpe_aruba_portal.title'),
      description: t('other_resources_cards.hpe_aruba_portal.description'),
      testId: 'hpe_aruba_networking_support_portal-tile',
      isIconInTitle: true,
      isLinkExternal: true,
      href: getLink(LINKS.HPE_ARUBA_SUPP_PORTAL),
      target: LINKS.HPE_ARUBA_SUPP_PORTAL,
      renderWrapper: (component) => (
        <VisibilityWrapper
          hideFor={{
            feature: 'glcp-support-rma',
            deployment: ['COP', 'GLOP']
          }}
        >
          {component()}
        </VisibilityWrapper>
      )
    },
    {
      id: 3,
      title: t('other_resources_cards.network_hardware_troubleshooting.title'),
      description: t(
        'other_resources_cards.network_hardware_troubleshooting.description'
      ),
      testId: 'networking_hardware_troubleshooting_and_replacement-tile',
      isIconInTitle: true,
      isLinkExternal: true,
      href: getLink(LINKS.NETW_HDWR_TRBLSHNG_AND_REPLCMNT),
      target: LINKS.NETW_HDWR_TRBLSHNG_AND_REPLCMNT,
      renderWrapper: (component) => (
        <VisibilityWrapper
          hideFor={{
            feature: 'glcp-support-rma',
            deployment: ['COP', 'GLOP']
          }}
        >
          {component()}
        </VisibilityWrapper>
      )
    },
    {
      id: 4,
      title: t('other_resources_cards.feedback.title'),
      description: t('other_resources_cards.feedback.description'),
      testId: 'feedback-tile',
      isIconInTitle: true,
      isLinkExternal: true,
      href: getLink(LINKS.FEEDBACK),
      target: LINKS.FEEDBACK,
      renderWrapper: (component) => (
        <VisibilityWrapper
          hideFor={{
            feature: 'glcp-support-feedback',
            deployment: ['COP', 'GLOP']
          }}
        >
          {component()}
        </VisibilityWrapper>
      )
    }
  ]

  return (
    <TilesRow
      title={t('other_resources_cards.other_resources')}
      testIdTitle="otherResources-test-id"
      rows={otherResourcesTiles}
      columns={columns[size]}
    />
  )
})

export { OtherResources }
