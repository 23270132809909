import { React, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, CheckBox, FormField } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  ModalDialog,
  ModalFooter,
  Typography,
  ButtonGroup
} from '../../../../../../components'
import { post } from '../../../../../../utils/api-utils'

const ActivationModal = ({
  onSetOpen,
  setErrorMessage,
  selectedKey,
  onSubmitSuccess
}) => {
  const { t } = useTranslation(['device', 'common'])
  const [checked, setChecked] = useState(false)
  const { oidcUser } = useReactOidc()
  const handleTerms = (value) => {
    setChecked(value)
  }

  const activateSubscription = () => {
    if (checked) {
      post(
        '/ui-doorway/ui/v1/customers/license',
        { key: selectedKey },
        oidcUser.access_token
      ).then(
        () => {
          onSetOpen(false)
          onSubmitSuccess()
        },
        (error) => {
          setErrorMessage(error)
        }
      )
    }
  }

  return (
    <ModalDialog
      width="medium"
      testId="activation-modal-dialog"
      content={
        <Box>
          <Typography type="heading" level="2" testId="activation-modal-title">
            {t('subscription_activation_title')}
          </Typography>
          <Typography
            type="paragraph"
            margin={{ bottom: 'medium' }}
            testId="activation-modal-description"
          >
            {t('subscription_activation_subtitle')}
          </Typography>
          <Typography
            type="heading"
            level="4"
            testId="activation-modal-checkbox-heading"
          >
            {t('subscription_activation_confirmation')}
          </Typography>
          <FormField>
            <CheckBox
              data-testid="set-up-account-legal-terms-checkbox"
              name="legal_terms"
              checked={checked}
              label={
                <Typography
                  type="text"
                  size="small"
                  testId="activation-checkbox-context"
                >
                  {t('checkbox_context')}
                </Typography>
              }
              onChange={(event) => handleTerms(event.target.checked)}
            />
          </FormField>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <Box justify="end" direction="row" gap="medium">
              <ButtonGroup
                buttonList={[
                  {
                    label: t('cancel'),
                    testId: 'cancel-btn',
                    onClick: () => onSetOpen(false)
                  },
                  {
                    label: t('activate'),
                    primary: true,
                    testId: 'activate-btn',
                    onClick: activateSubscription
                  }
                ]}
                testId="activation-modal-buttons"
              />
            </Box>
          }
        />
      }
      onClose={() => onSetOpen(false)}
    />
  )
}

ActivationModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  selectedKey: PropTypes.string.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired
}

export { ActivationModal }
