import React from 'react'
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  Redirect
} from 'react-router-dom'

import { checkGotoChooseAccount } from '../../utils/common-utils'

import LdFlagsHomePage from './featureflagsHome'

const LdFlagsApplicationRouter = () => {
  const { path } = useRouteMatch()

  const history = useHistory()
  checkGotoChooseAccount(history)

  return (
    <Switch>
      <Route exact path={path}>
        <LdFlagsHomePage />
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default LdFlagsApplicationRouter
