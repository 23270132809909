import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../../../../components'

import { RateReview } from './RateReview'
import { RatesReveiwContainer } from './RatesReveiwContainer'
import { CheckingLoader } from './CheckingLoader'
import DataErrorHandling from './DataErrorHandling'
import { ManageSubscriptionRatesModal } from './ManageSubscriptionRatesModal'
import FailedToRetrieveRatesModal from './ManageSubscriptionRatesModal/FailedToRetrieveRatesModal'
import useLoadingRates from './hooks/useLoadingRates'

const ReviewShowbackRatesDetails = ({
  manageRatesModal,
  handleManageRatesModal,
  setErrorNotification,
  subscriptionKey,
  setIsRatesStepAvailable
}) => {
  const { t } = useTranslation(['device', 'common'])

  const [isLoading, setIsLoading] = useState(false)
  const [isSavingRatesSuccess, setIsSavingRatesSuccess] = useState(false)
  const initialFormValues = {
    isRatesStepOn: true,
    ratesErrors: {},
    subscriptionKey
  }
  const [formValues, setFormValues] = useState(initialFormValues)

  const loadingRates = useLoadingRates({
    initialFormValues,
    setIsLoading,
    subscriptionKey,
    setFormValues,
    skipLoadingRates: false,
    hasInitialRates: true,
    skipSkuCheck: false,
    callBackFunc: null
  })

  useEffect(() => {
    if (subscriptionKey) {
      loadingRates()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsRatesStepAvailable(!!formValues.isRatesStepAvailable)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.isRatesStepAvailable])

  useEffect(() => {
    if (isSavingRatesSuccess) {
      loadingRates()
      setTimeout(() => {
        setIsSavingRatesSuccess(false)
      }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSavingRatesSuccess])

  const renderModal = () => {
    return !isLoading && formValues.isLoadingRatesFailed ? (
      <FailedToRetrieveRatesModal onSetOpen={handleManageRatesModal} />
    ) : (
      <ManageSubscriptionRatesModal
        setModalOpen={handleManageRatesModal}
        setShowErrorNotification={setErrorNotification}
        subscriptionKey={subscriptionKey}
        onSuccess={setIsSavingRatesSuccess}
        formValues={formValues}
        setFormValues={setFormValues}
      />
    )
  }

  return (
    <>
      {isLoading && <CheckingLoader />}
      {!isLoading && formValues.isRatesStepAvailable && (
        <RatesReveiwContainer>
          {formValues.isLoadingRatesFailed && (
            <DataErrorHandling height="small" />
          )}
          {!formValues.isLoadingRatesFailed &&
            formValues?.hasNoExistedRates === false &&
            formValues?.isRatesStepAvailable &&
            formValues?.initialRates?.pricePlans?.map((pricePlan) => {
              return (
                <RateReview
                  pricePlan={pricePlan}
                  key={pricePlan.uom}
                  // if rates data exist from cds then extract currency from there
                  currency={formValues.initialRates.currency}
                />
              )
            })}
          {!formValues.isLoadingRatesFailed &&
            formValues?.hasNoExistedRates === true && (
              <Typography
                type="text"
                margin={{ top: '0px', bottom: '0px' }}
                testId="showback-rates-details-no-existed-rates"
              >
                {t('showback_rates.manage_rates_msg_no_rates_added')}
              </Typography>
            )}
        </RatesReveiwContainer>
      )}

      {manageRatesModal && renderModal()}
    </>
  )
}

ReviewShowbackRatesDetails.propTypes = {
  manageRatesModal: PropTypes.bool.isRequired,
  handleManageRatesModal: PropTypes.func.isRequired,
  setErrorNotification: PropTypes.func.isRequired,
  subscriptionKey: PropTypes.string.isRequired,
  setIsRatesStepAvailable: PropTypes.func.isRequired
}

export default ReviewShowbackRatesDetails
