import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Box } from 'grommet'
import { ShareRounded } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { displayApiError } from '../../../../../../../utils/error-handling-utils'
import { getUserName } from '../../../../../../../utils/common-utils'
import {
  AvatarInfo,
  DataTable,
  Loader,
  Typography
} from '../../../../../../../components'
import VisibilityWrapper from '../../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { get } from '../../../../../../../utils/api-utils'

const RRPUsers = ({ customerId, rrpId }) => {
  const { t } = useTranslation(['manage', 'common', 'authz'])
  const { oidcUser } = useReactOidc()
  const history = useHistory()

  const [usersList, setUsersList] = useState([])
  const [viewData, setViewData] = useState([])
  const [showNotification, setShowNotification] = useState(null)
  const [loading, setLoading] = useState(true)

  // for pagination
  const itemsPerPage = 5
  const [page, setPage] = useState(1)

  const columns = [
    {
      property: 'name',
      type: 'string',
      header: t('authz:name'),
      render: (datum) => (
        <Box direction="row" gap="small" pad="xsmall">
          <AvatarInfo
            avatarChar="one"
            primaryInfo={datum?.user_full_name}
            secondaryInfo=""
            testId="user-info-avatar-only"
          />
        </Box>
      )
    },
    {
      property: '',
      header: '',
      render: (datum) => (
        <VisibilityWrapper
          rbac={{
            permission: 'ccs.accounts.users.view.all',
            resource: '/ccs/accounts/user'
          }}
        >
          <Box
            direction="row"
            justify="center"
            onClick={() => {
              history.push(`/manage-ccs/customers/users/${datum?.user_name}`)
            }}
          >
            <ShareRounded color="text-strong" />
            <Typography type="text" margin={{ left: 'xsmall' }}>{` ${t(
              'authz:view'
            )} `}</Typography>
          </Box>
        </VisibilityWrapper>
      )
    }
  ]

  useEffect(() => {
    setLoading(true)
    const url = `/support-assistant/v1alpha1/resource-restrictions-users`
    get(
      url,
      {
        platform_cid: customerId,
        resource_restriction_policy_id: rrpId
      },
      oidcUser.access_token
    ).then(
      (resp) => {
        const users = resp?.data?.users_details?.map((user) => ({
          ...user,
          user_full_name: getUserName(
            user?.user_first_name,
            user?.user_last_name,
            user?.user_name
          )
        }))
        setUsersList(users)
        setViewData(users)
        setShowNotification(null)
        setLoading(false)
      },
      (error) => {
        setShowNotification(displayApiError(error, t))
        setLoading(false)
      }
    )
  }, [rrpId, oidcUser, t, customerId])

  return (
    <Box>
      <Box
        direction="column"
        justify="center"
        gap="small"
        pad={{ bottom: 'medium' }}
        margin={{ bottom: 'small' }}
      >
        {loading ? (
          <Loader testId="rrp-users-loader" />
        ) : (
          <DataTable
            pagination={{
              totalItems: viewData?.length,
              itemsPerPage,
              page,
              setPage
            }}
            grid={{
              columns,
              data: viewData.slice(
                (page - 1) * itemsPerPage,
                page * itemsPerPage
              ), // UI based pagiantion
              pad: { body: { horizontal: 'small', vertical: 'xsmall' } },
              onClickRow: ({ datum }) => {
                history.push(`/manage-ccs/customers/users/${datum?.user_name}`)
              }
            }}
            summary={{
              entityName: t('authz:users_title')
            }}
            search={{
              onSearchValueChange: (value) => {
                if (value.trim() !== '') {
                  const filteredUsers = usersList.filter((user) =>
                    user?.user_full_name
                      ?.toLowerCase()
                      .includes(value?.toLowerCase())
                  )
                  setViewData(filteredUsers)
                } else {
                  setViewData(usersList)
                }
                setPage(1)
              },
              placeholder: t('authz:search_by_user_name'),
              width: 'medium'
            }}
            testId="rrp-user-assignments-table"
          />
        )}
      </Box>
      {showNotification}
    </Box>
  )
}

RRPUsers.propTypes = {
  customerId: PropTypes.string.isRequired,
  rrpId: PropTypes.string.isRequired
}

export { RRPUsers }
