import React, { useState, useEffect } from 'react'
import {
  Box,
  CheckBox,
  FormField,
  Notification,
  RadioButtonGroup
} from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Typography } from '../../../../components'

import { AddressInput } from './AddressInput'
import { addressTypeObject, addressErrorsType } from './types'

const checkIfShippingSameAsStreet = (streetAddress, shippingAddress) => {
  let isSame = true
  if (shippingAddress === null) {
    return isSame
  }

  Object.keys(shippingAddress).forEach((key) => {
    if (key !== 'type' && key !== 'id' && streetAddress[key] !== '') {
      if (shippingAddress[key] !== streetAddress[key]) {
        isSame = false
      }
    }
  })

  return isSame
}

const AddAddressModalContent = ({
  listOfCountries,
  setStreetAddress,
  setShippingAddress,
  streetAddress,
  shippingAddress,
  streetFieldErrors,
  setFieldErrors,
  shippingFieldErrors,
  setShippingFieldErrors
}) => {
  const { t } = useTranslation(['location'])
  const [addShippingAddress, setAddShippingAddress] = useState(
    !!shippingAddress
  )

  const isShippingAddressSame = [
    {
      label: t('same_as_street_address'),
      value: 'yes'
    },
    {
      label: t('different_shipping and receiving address'),
      value: 'no'
    }
  ]
  const [value, setValue] = useState(
    checkIfShippingSameAsStreet(streetAddress, shippingAddress)
      ? isShippingAddressSame[0].value
      : isShippingAddressSame[1].value
  )

  useEffect(() => {
    if (!addShippingAddress) {
      setShippingAddress(null)
    } else if (value === 'yes') {
      setShippingAddress((prevState) => ({
        ...prevState,
        ...streetAddress,
        ...{ type: 'shipping_receiving' }
      }))
    }
  }, [value, addShippingAddress, setShippingAddress, streetAddress])

  return (
    <>
      <Box pad={{ bottom: 'xsmall', top: 'small' }}>
        <Typography type="heading" level={3}>
          {t('street_address')}
        </Typography>
        <Typography size="medium" type="text">
          {t('add_address_main_description')}
        </Typography>
        <Typography size="medium" type="text">
          {t('add_address_sub_description')}
        </Typography>
      </Box>
      <AddressInput
        listOfCountries={listOfCountries}
        setAddress={setStreetAddress}
        address={streetAddress}
        fieldErrors={streetFieldErrors}
        setFieldErrors={setFieldErrors}
        type="street"
      />
      <Box pad={{ bottom: 'small', top: 'small' }}>
        <CheckBox
          label={t('add_shipping_receiving_address')}
          name="form-checkbox-input"
          data-testid="add-shipping-address-checkbox"
          checked={addShippingAddress}
          pad="none"
          onChange={(event) => {
            setAddShippingAddress(event.target.checked)
          }}
        />
      </Box>
      <Notification
        status="info"
        message={t('shipping_and_receiving_address_note')}
      />

      {addShippingAddress && (
        <>
          <Box pad={{ bottom: 'xsmall', top: 'small' }}>
            <Typography type="heading" level={3}>
              {t('shipping_receiving_address')}
            </Typography>
          </Box>
          <FormField>
            <RadioButtonGroup
              name="radio"
              labelKey="label"
              valueKey="value"
              data-testid="is-shipping-address-same-radio-button"
              options={isShippingAddressSame}
              value={value}
              onChange={(event) => {
                setValue(event.target.value)
                if (event.target.value === 'yes') {
                  setShippingAddress((prevState) => ({
                    ...prevState,
                    ...streetAddress,
                    ...{ type: 'shipping_receiving' }
                  }))
                } else {
                  const emptyAddress = {
                    country: '',
                    street_address: '',
                    street_address2: '',
                    city: '',
                    state: '',
                    postal_code: '',
                    type: 'shipping_receiving'
                  }
                  setShippingAddress(emptyAddress)
                }
              }}
            />
          </FormField>
        </>
      )}
      {addShippingAddress && value === 'no' && shippingAddress && (
        <>
          <AddressInput
            listOfCountries={listOfCountries}
            setAddress={setShippingAddress}
            address={shippingAddress}
            fieldErrors={shippingFieldErrors}
            setFieldErrors={setShippingFieldErrors}
            type="shipping_receiving"
          />
        </>
      )}
    </>
  )
}
AddAddressModalContent.propTypes = {
  listOfCountries: PropTypes.array.isRequired,
  setStreetAddress: PropTypes.func.isRequired,
  setShippingAddress: PropTypes.func.isRequired,
  streetAddress: PropTypes.shape(addressTypeObject).isRequired,
  shippingAddress: PropTypes.shape(addressTypeObject),
  streetFieldErrors: PropTypes.shape(addressErrorsType).isRequired,
  setFieldErrors: PropTypes.func.isRequired,
  shippingFieldErrors: PropTypes.shape(addressErrorsType).isRequired,
  setShippingFieldErrors: PropTypes.func.isRequired
}
AddAddressModalContent.defaultProps = {
  shippingAddress: null
}

export { AddAddressModalContent }
