import PropTypes from 'prop-types'
import { Box, Button, CheckBox, Anchor, FormField } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Typography,
  doProvisionAppAction as doProvisionServiceAction,
  CCSForm,
  Dropdown,
  ModalDialog,
  ModalHeader,
  ModalFooter
} from '../shims/imports'
import { useRegions } from '../hooks'
import { getRegionName } from '../utils'

const ProvisionModal = ({
  availableRegions,
  serviceData,
  closeModal,
  type,
  refreshAppList,
  setShowErrorNotification,
  setActiveTabIndex,
  isServiceManager,
  serviceManagerName,
  serviceManagerRegions,
  setConfigureDeviceNotification,
  accountType
}) => {
  const { t } = useTranslation([
    'dashboard',
    'services_catalog',
    'common',
    'apps'
  ])
  const [selectedRegion, setSelectedRegion] = useState(availableRegions[0])
  const { oidcUser } = useReactOidc()
  const { regions: regionsData, isLoaded: regionsLoaded } = useRegions()
  const regionOptions = availableRegions.map((region) => ({
    label: getRegionName(regionsLoaded, regionsData, region),
    value: region
  }))

  const [checked, setChecked] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleTerms = (value) => {
    setChecked(value)
    setErrorMessage('')
  }

  const appTermLink = 'https://www.hpe.com/psnow/doc/a50009054ENW'

  const getFinalServiceData = () => {
    if (!isServiceManager) {
      const matchedRegion = serviceManagerRegions.find(
        (region) => region.code === selectedRegion
      )

      if (matchedRegion) {
        return {
          ...serviceData,
          application_id: matchedRegion?.application_id
        }
      }
    }

    return serviceData
  }

  const handleAddInstance = () => {
    if (checked) {
      doProvisionServiceAction(
        getFinalServiceData(),
        selectedRegion,
        oidcUser.access_token,
        refreshAppList,
        () => {},
        setShowErrorNotification,
        t,
        () => {
          setConfigureDeviceNotification(true)
        },
        false,
        undefined,
        () => {},
        undefined
      )
      closeModal()
      if (isServiceManager) {
        setActiveTabIndex(1)
      }
    } else {
      setErrorMessage(t('apps:terms_of_service_text'))
    }
  }

  const tenantOnlySupportedModalTitle =
    accountType === 'MSP' && serviceData?.tenant_only_supported
      ? t('dashboard:service_details.enable_modal_title')
      : t('dashboard:service_details.provision_modal_title')

  const tenantOnlySupportedModalSubtitle =
    accountType === 'MSP' && serviceData?.tenant_only_supported
      ? 'dashboard:service_details.enable_modal_subtitle'
      : 'dashboard:service_details.provision_modal_subtitle'

  return (
    <ModalDialog
      width="large"
      testId="provision-modal"
      header={
        <ModalHeader
          title={
            <>
              <Typography type="heading" level="2" textAlign="start">
                {type === 'provision'
                  ? tenantOnlySupportedModalTitle
                  : t('apps:add_region')}
              </Typography>
              <Typography type="paragraph" textAlign="start" fill>
                {isServiceManager ? (
                  <Trans
                    i18nKey={
                      type === 'provision'
                        ? tenantOnlySupportedModalSubtitle
                        : 'apps:add_region_subtitle'
                    }
                  >
                    {{ serviceName: serviceData.name }}
                  </Trans>
                ) : (
                  <Trans i18nKey="dashboard:service_details.setup_modal_subtitle">
                    {{ serviceName: serviceData.name, serviceManagerName }}
                  </Trans>
                )}
              </Typography>
            </>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <Box gap="xsmall">
            <Typography type="text" size="xsmall">
              {t('dashboard:common:deployment_region')}
            </Typography>
            <Dropdown
              options={regionOptions}
              onChangeDropdown={(value) => {
                setSelectedRegion(value)
              }}
              defaultVal={regionOptions[0]?.value}
              testId="service-centric-provision-dropdown"
            />
          </Box>
          <Box margin={{ top: 'small' }}>
            <CCSForm errorMessage={errorMessage} testId="app-term-form">
              <FormField>
                <CheckBox
                  id="app-term-checkbox"
                  data-testid="app-term-checkbox"
                  name="app_terms"
                  checked={checked}
                  label={
                    <Typography
                      type="text"
                      size="medium"
                      testId="app-terms-checkbox-label"
                    >
                      <Trans i18nKey="apps:terms_of_service_confirm_text" t={t}>
                        <Anchor
                          label={t('apps:terms_of_service')}
                          weight={500}
                          href={appTermLink}
                          target="_blank"
                          testId="app-terms-anchor"
                        />
                      </Trans>
                    </Typography>
                  }
                  onChange={(event) => handleTerms(event.target.checked)}
                />
              </FormField>
            </CCSForm>
          </Box>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <Box direction="row" justify="end" gap="medium">
              <Button label={t('common.cancel')} onClick={closeModal} />
              <Button
                primary
                label={
                  accountType === 'MSP' && serviceData?.tenant_only_supported
                    ? t('dashboard:service_details.enable_service')
                    : t('apps:deploy')
                }
                onClick={handleAddInstance}
              />
            </Box>
          }
        />
      }
    />
  )
}

ProvisionModal.propTypes = {
  availableRegions: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['provision', 'add']).isRequired,
  serviceData: PropTypes.object.isRequired,
  refreshAppList: PropTypes.func.isRequired,
  setShowErrorNotification: PropTypes.func.isRequired,
  setActiveTabIndex: PropTypes.func.isRequired,
  isServiceManager: PropTypes.bool.isRequired,
  serviceManagerName: PropTypes.string.isRequired,
  serviceManagerRegions: PropTypes.array.isRequired,
  setConfigureDeviceNotification: PropTypes.func.isRequired,
  accountType: PropTypes.string.isRequired
}

export default ProvisionModal
