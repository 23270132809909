import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, TextArea } from 'grommet'
import isEmpty from 'lodash/isEmpty'

import { WizardContext } from '../../../../../../../../components/wizard/WizardContext'
import { Typography } from '../../../../../../../../components'
import { validateForm } from '../../../../../../../../utils/validation-utils'

const validateFieldsFlorence = (formValues, i18nTranslate) => {
  const { emailContentTitle, emailContentDetails } = formValues
  const emailSubjectLimit = 256
  const emailContentLimit = 2048
  let errorMessage
  let errorMessageSubject

  if (isEmpty(emailContentDetails?.trim())) {
    errorMessage = i18nTranslate('required')
  } else if (emailContentDetails?.length >= emailContentLimit) {
    errorMessage = i18nTranslate('notification.max_length')
  }

  if (isEmpty(emailContentTitle?.trim())) {
    errorMessageSubject = i18nTranslate('required')
  } else if (emailContentTitle?.length >= emailSubjectLimit) {
    errorMessageSubject = i18nTranslate('notification.max_length')
  }

  const emailMessageValid =
    emailContentDetails?.trim() &&
    emailContentDetails.length <= emailContentLimit

  const emailSubjectValid =
    emailContentTitle?.trim() && emailContentTitle.length <= emailSubjectLimit

  const formValid = emailMessageValid && emailSubjectValid

  return {
    emailContentDetails: emailMessageValid ? '' : errorMessage,
    emailContentTitle: emailSubjectValid ? '' : errorMessageSubject,
    isValid: !!formValid
  }
}

export const validateEmailMessage = (formValues, i18nTranslate) => {
  return validateForm(formValues, i18nTranslate, validateFieldsFlorence)
}

export const validateEmailMessageFlorence = (formValues, i18nTranslate) => {
  return validateForm(formValues, i18nTranslate, validateFieldsFlorence)
}

const EmailNotification = (bannerCheck) => {
  const { t } = useTranslation(['manage'])

  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)
  const [errorMessage, setErrorMessage] = useState({})

  useEffect(() => {
    if (attemptedAdvance) {
      const validation = validateFieldsFlorence(formValues, t)
      setErrorMessage(validation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, attemptedAdvance, t])

  useEffect(() => {
    if (bannerCheck && isEmpty(formValues?.emailContentTitle)) {
      setFormValues({
        ...formValues,
        emailContentTitle: formValues?.summary,
        emailContentDetails: formValues?.description
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      gap="medium"
      width="medium"
      justify="between"
      align="start"
      alignSelf="center"
    >
      <Box
        direction="column"
        gap="medium"
        align="center"
        margin={{ bottom: 'large' }}
      >
        <Box width="medium" margin={{ bottom: 'small' }}>
          <Typography
            type="text"
            size="small"
            color="black"
            testId="banner-checkbox-summary-label"
          >
            {t('notification.subject')}
          </Typography>
          <Typography
            type="text"
            size="small"
            color="black"
            testId="email-message-summary"
          >
            {t('notification.length_check')}
          </Typography>
          <FormField
            name="summary"
            margin={{ bottom: 'none' }}
            error={errorMessage.emailContentTitle}
            data-testid="email-subject"
            required
          >
            <TextArea
              size="medium"
              value={formValues?.emailContentTitle}
              name="emailContentTitle"
              placeholder={t('notification.message')}
              data-testid="email-summary"
              onChange={(event) => {
                setFormValues({
                  ...formValues,
                  emailContentTitle: event.target.value
                })
              }}
            />
          </FormField>
        </Box>
        <Box width="medium" margin={{ bottom: 'large' }}>
          <Typography
            type="text"
            size="small"
            color="black"
            testId="banner-checkbox-description-label"
          >
            {t('notification.content')}
          </Typography>
          <Typography
            type="text"
            size="small"
            color="black"
            testId="banner-checkbox-description"
          >
            {t('notification.additional_content_description')}
          </Typography>
          <FormField
            name="description"
            margin={{ bottom: 'none' }}
            error={errorMessage?.emailContentDetails}
            data-testid="set-up-banner-description"
          >
            <TextArea
              size="medium"
              value={formValues.emailContentDetails}
              name="emailContentDetails"
              placeholder={t('notification.summary')}
              data-testid="set-up-description"
              onChange={(event) =>
                setFormValues({
                  ...formValues,
                  emailContentDetails: event.target.value
                })
              }
            />
          </FormField>
        </Box>
      </Box>
    </Box>
  )
}

export default EmailNotification
