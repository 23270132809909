import { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { isGLOP } from '../../../../../utils/feature-flag-utils'

import InviteUserModal from './InviteUserModal'
import { InviteUserSelectionModal } from './InviteUserSelectionModal'

const InviteUser = ({ inviteUserOpen, path, onSetOpen, onSuccess }) => {
  const history = useHistory()
  const [userInviteType, setUserInviteType] = useState('')

  const onSaveInviteUserSelection = (val) => {
    if (val === 'multiple_user') {
      history.push('/manage-account/identity/users/invite-bulk-users', { path })
    }
    onSetOpen(false)
    setUserInviteType(val)
  }

  const onInviteUserClose = () => {
    onSetOpen(false)
    setUserInviteType('')
  }

  return (
    <>
      {isGLOP() && inviteUserOpen && (
        <InviteUserSelectionModal
          onSave={onSaveInviteUserSelection}
          onSetOpen={onSetOpen}
        />
      )}
      {userInviteType === 'single_user' || (inviteUserOpen && !isGLOP()) ? (
        <InviteUserModal onSetOpen={onInviteUserClose} onSuccess={onSuccess} />
      ) : null}
    </>
  )
}

InviteUser.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  inviteUserOpen: PropTypes.bool.isRequired,
  path: PropTypes.string,
  onSuccess: PropTypes.func
}

InviteUser.defaultProps = {
  path: '/manage-account/identity/users',
  onSuccess: () => {}
}

export default InviteUser
