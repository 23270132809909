import { displayNotification } from '../../../../utils/notificiation-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { get, put, post, getErrorMessage } from '../../../../utils/api-utils'

export const getMFAStatus = (token, setStatus, setShowNotification, t) => {
  get('/accounts/ui/v1/user/profile/preferences', {}, token).then(
    (response) => {
      const { multi_fa_enabled: MFA, mfa_enforced: mfaEnforced } = response.data
      if (response.data) {
        setStatus({ MFA, mfaEnforced })
      }
    },
    (error) => {
      setShowNotification(displayApiError(error, t, setShowNotification))
    }
  )
}
export const doEnableMFA = (token, setData, setShowNotification, t) => {
  post('/accounts/ui/v1/user/mfa/enable', {}, token, undefined, {
    responseType: 'arraybuffer'
  }).then(
    (response) => {
      if (response.status === 200) {
        if (response.headers) {
          const {
            'ccs-user-idp-id': idpUserId,
            'ccs-totp-secret': totpSecret,
            'ccs-totp-device-id': deviceId,
            'content-type': contentType
          } = response.headers
          if (contentType === 'application/json') {
            setShowNotification(
              displayNotification(
                t('authn:security.mfa_success_enabled'),
                'info',
                setShowNotification
              )
            )
          } else {
            const imgSrc = `data:image/png;base64,${btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            )}`
            setData({ idpUserId, totpSecret, deviceId, imgSrc })
          }
        }
      }
    },
    (error) => {
      setShowNotification(displayApiError(error, t, setShowNotification))
    }
  )
}

export const doDisableMFA = (token, setShowNotification, t) => {
  post('/accounts/ui/v1/user/mfa/disable', {}, token).then(
    (response) => {
      if (response.status === 200) {
        setShowNotification(
          displayNotification(
            t('authn:security.mfa_removed'),
            'info',
            setShowNotification
          )
        )
      }
    },
    (error) => {
      setShowNotification(displayApiError(error, t, setShowNotification))
    }
  )
}

export const doEnableCustomerMFA = (
  token,
  setShowNotification,
  t,
  successHandler
) => {
  post('/accounts/ui/v1/customer/mfa/enable', {}, token).then(
    (response) => {
      if (response.status === 200) {
        if (successHandler) {
          successHandler()
        }
        setShowNotification(
          displayNotification(
            t('authn:security.mfa_success_enabled'),
            'info',
            setShowNotification
          )
        )
      }
    },
    (error) => {
      setShowNotification(displayApiError(error, t, setShowNotification))
    }
  )
}

export const doDisableCustomerMFA = (
  token,
  setShowNotification,
  t,
  successHandler
) => {
  post('/accounts/ui/v1/customer/mfa/disable', {}, token).then(
    (response) => {
      if (response.status === 200) {
        if (successHandler) {
          successHandler()
        }
        setShowNotification(
          displayNotification(
            t('authn:security.mfa_removed'),
            'info',
            setShowNotification
          )
        )
      }
    },
    (error) => {
      setShowNotification(displayApiError(error, t, setShowNotification))
    }
  )
}

export const doActivateMFA = (
  token,
  requestBody,
  setShowNotification,
  setErrorMessage,
  t
) => {
  if (requestBody && token) {
    post('/accounts/ui/v1/user/mfa/activate', requestBody, token).then(
      (response) => {
        if (response.status === 200) {
          setShowNotification(
            displayNotification(
              t('authn:security.mfa_success_enabled'),
              'info',
              setShowNotification
            )
          )
        }
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }
}

export const getMfaAccounts = (token, setAccounts, setShowNotification, t) => {
  get('/accounts/ui/v1/user/mfa/mandated-accounts', {}, token).then(
    (response) => {
      const { accounts } = response.data
      if (accounts && accounts.length > 0) {
        setAccounts(accounts)
      } else {
        setAccounts([])
      }
    },
    (error) => {
      setShowNotification(displayApiError(error, t, setShowNotification))
    }
  )
}

export const getCustomerMFAStatus = (
  token,
  setData,
  setShowNotification,
  t
) => {
  get('/accounts/ui/v1/customer/profile/preferences', {}, token).then(
    (response) => {
      if (response.status === 200) {
        const { customer_id: customerId, multi_fa_enabled: mfa } = response.data
        setData({ customerId, mfa })
      }
    },
    (error) => {
      setShowNotification(displayApiError(error, t, setShowNotification))
    }
  )
}

export const updateCustomerMFAStatus = (
  requestBody,
  token,
  setShowNotification,
  t,
  successHandler
) => {
  put('/accounts/ui/v1/customer/profile/preferences', requestBody, token).then(
    (response) => {
      if (response.status === 200) {
        if (successHandler) {
          successHandler()
        }
        setShowNotification(
          displayNotification(
            requestBody.multi_fa_enabled
              ? 'MFA activation email sent'
              : 'MFA deactivated',
            'info',
            setShowNotification
          )
        )
      }
    },
    (error) => {
      setShowNotification(displayApiError(error, t, setShowNotification))
    }
  )
}
