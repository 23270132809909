import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Box, FormField, TextInput, TextArea, Select } from 'grommet'
import { useTranslation } from 'react-i18next'
import { CircleInformation } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  Typography,
  ButtonGroup,
  CCSForm
} from '../../../components'
import { get, getErrorMessage, post } from '../../../utils/api-utils'
import {
  validateTenantName,
  validateDescription,
  validateStreetAddress,
  validateZipCode,
  validateCity
} from '../../../utils/validation-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import {
  getWorkspaceString,
  WKSPC,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'

function AddTenantModal({
  setNotification,
  setShowCreateCustomerModal,
  updateCustomerAccounts
}) {
  const { oidcUser } = useReactOidc()
  const [countries, setCountries] = useState([])
  const [listOfCountries, setListOfCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const { t } = useTranslation(['authn', 'common'])
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value }
        default:
          return currentState
      }
    },
    {
      company_name: '',
      description: '',
      country_code: '',
      street_address: '',
      city: '',
      state: '',
      zip_code: ''
    }
  )
  const openSuccessModal = () => {
    setNotification(
      displayNotification(
        t('customer_account.customer_added_message'),
        'info',
        () => setNotification(false)
      )
    )
  }

  const loadCustomerAccount = (data) => {
    setShowCreateCustomerModal(false)
    openSuccessModal(true)
    updateCustomerAccounts(data)
  }
  const handleSetUpAccount = () => {
    setSubmitted(true)
    setErrorMessage('')
    const requestBody = {
      company_name: state.company_name,
      description: state.description,
      address: {
        street_address: state.street_address,
        city: state.city?.trim(),
        state_or_region: state.state?.trim(),
        zip: state.zip_code?.trim(),
        country_code: state.country_code
      }
    }

    post(
      '/accounts/ui/v1/managed-service/tenant',
      requestBody,
      oidcUser.access_token
    ).then(
      (response) => {
        loadCustomerAccount(response.data)
        setSubmitted(false)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
        setSubmitted(false)
      }
    )
  }
  useEffect(() => {
    get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      (response) => {
        setListOfCountries(response.data.countries)
        setCountries(response.data.countries)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }, [t])
  return (
    <ModalDialog
      width="medium"
      testId="customer-signup-modal-dialog"
      content={
        <Box gap="small">
          <Box flex={{ shrink: 0 }}>
            <Typography type="heading" level="1" testId="customer-signup-title">
              {t('customer_account.addCustomerModalTitle', {
                account: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_CAPITALIZED
                )
              })}
            </Typography>
            <Typography type="paragraph" testId="customer-signup-title">
              {t('customer_account.addCustomerModalSubTitle', {
                account: getWorkspaceString(showWorkspaceString, t, WKSPC)
              })}
            </Typography>
          </Box>
          <CCSForm
            value={state}
            onReset={() => {
              setShowCreateCustomerModal(false)
            }}
            onSubmit={handleSetUpAccount}
            errorMessage={errorMessage}
            validate="blur"
            testId="set-up-account-form"
          >
            <>
              <FormField
                label={t('customer_account.company_name', {
                  company: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
                name="company_name"
                required
                data-testid="set-up-account-company-name-form-field"
                validate={(value) => {
                  if (validateTenantName(value))
                    return t('customer_account.tenant_name_error_message')
                  return true
                }}
                disabled={submitted}
              >
                <TextInput
                  value={state.company_name}
                  name="company_name"
                  placeholder={t('customer_account.company_placeholder', {
                    company: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  })}
                  data-testid="set-up-account-company-name-input"
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'company_name',
                      type: 'CHANGE_FIELD'
                    })
                  }
                  disabled={submitted}
                />
              </FormField>
              <Typography
                type="text"
                size="small"
                icon={
                  <CircleInformation
                    size="small"
                    color={submitted ? 'status-disabled' : 'text'}
                  />
                }
                testId="tenant-name-info-icon"
                color={submitted ? 'status-disabled' : 'text'}
              >
                {t('customer_account.tenant_name_info')}
              </Typography>
              <FormField
                label={t('customer_account.description')}
                name="description"
                validate={(value) => {
                  if (validateDescription(value))
                    return t('customer_account.description_error_message')
                  return true
                }}
                data-testid="set-up-account-description-form-field"
                disabled={submitted}
              >
                <TextArea
                  size="large"
                  fill
                  data-testid="set-up-account-description-input"
                  name="company_description"
                  placeholder={t('customer_account.description_placeholder', {
                    company: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  })}
                  value={state.description}
                  onChange={(e) => {
                    dispatch({
                      value: e.target.value,
                      field: 'description',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  disabled={submitted}
                />
              </FormField>
              <Typography
                type="text"
                size="small"
                icon={
                  <CircleInformation
                    size="small"
                    color={submitted ? 'status-disabled' : 'text'}
                  />
                }
                testId="description-info-icon"
                color={submitted ? 'status-disabled' : 'text'}
              >
                {t('customer_account.description_info')}
              </Typography>
              <FormField
                label={t('customer_account.country')}
                name="country_code"
                required
                data-testid="set-up-account-country-form-field"
                disabled={submitted}
              >
                <Select
                  name="country_code"
                  placeholder={t('customer_account.country_placeholder')}
                  options={countries}
                  multiple={false}
                  labelKey="name"
                  value={state.country_code}
                  valueKey={{ key: 'code', reduce: true }}
                  searchPlaceholder="Country"
                  emptySearchMessage={t(
                    'customer_account.country_empty_search_message'
                  )}
                  onSearch={(searchText) => {
                    const regexp = new RegExp(searchText, 'i')
                    setCountries(
                      listOfCountries.filter((o) => o.name.match(regexp))
                    )
                  }}
                  onChange={({ option }) => {
                    dispatch({
                      value: option.code,
                      field: 'country_code',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  onClose={() => setCountries(listOfCountries)}
                  disabled={submitted}
                />
              </FormField>
              <FormField
                label={t('customer_account.company', {
                  company: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
                name="street_address"
                margin={{ bottom: 'none' }}
                required
                validate={(value) => {
                  if (!validateStreetAddress(value))
                    return t('customer_account.max_chars_exceeded')
                  return true
                }}
                data-testid="set-up-account-street-address-form-field"
                disabled={submitted}
              >
                <TextInput
                  value={state.street_address}
                  name="street_address"
                  placeholder={t('customer_account.street_address')}
                  data-testid="set-up-account-street-address-input"
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'street_address',
                      type: 'CHANGE_FIELD'
                    })
                  }
                  disabled={submitted}
                />
              </FormField>
              <Box direction="row-responsive" gap="xsmall">
                <Box direction="column" basis="1/2" height={{ min: 'auto' }}>
                  <FormField
                    name="city"
                    data-testid="set-up-account-city-form-field"
                    validate={(city) => {
                      if (city && !validateCity(city.trim()))
                        return t('customer_account.max_chars_exceeded_city')
                      return true
                    }}
                    disabled={submitted}
                  >
                    <TextInput
                      value={state.city}
                      name="city"
                      placeholder={t('customer_account.city')}
                      data-testid="set-up-account-city-input"
                      onChange={(event) =>
                        dispatch({
                          value: event.target.value,
                          field: 'city',
                          type: 'CHANGE_FIELD'
                        })
                      }
                      disabled={submitted}
                    />
                  </FormField>
                </Box>
                <Box direction="column" basis="1/2" height={{ min: 'auto' }}>
                  <FormField
                    name="state"
                    data-testid="set-up-account-state-form-field"
                    disabled={submitted}
                  >
                    <TextInput
                      value={state.state}
                      name="state"
                      placeholder={t('customer_account.state')}
                      data-testid="set-up-account-state-input"
                      onChange={(event) =>
                        dispatch({
                          value: event.target.value,
                          field: 'state',
                          type: 'CHANGE_FIELD'
                        })
                      }
                      disabled={submitted}
                    />
                  </FormField>
                </Box>
              </Box>
              <FormField
                label={t('customer_account.zip')}
                name="zip_code"
                validate={(value) => {
                  if (value && !validateZipCode(value.trim()))
                    return t('customer_account.max_chars_exceeded')
                  return true
                }}
                data-testid="set-up-account-zip-code-form-field"
                disabled={submitted}
              >
                <TextInput
                  value={state.zipCode}
                  name="zip_code"
                  placeholder={t('customer_account.zip_placeholder')}
                  data-testid="set-up-account-zip-code-input"
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'zip_code',
                      type: 'CHANGE_FIELD'
                    })
                  }
                  disabled={submitted}
                />
              </FormField>
              <Box margin={{ vertical: 'small' }}>
                <ButtonGroup
                  buttonList={[
                    {
                      id: 2,
                      label: t('customer_account.cancel'),
                      default: true,
                      testId: 'cancel-btn',
                      type: 'reset'
                    },
                    {
                      id: 1,
                      label: t('customer_account.createAccount', {
                        account: getWorkspaceString(
                          showWorkspaceString,
                          t,
                          WKSPC_CAPITALIZED
                        )
                      }),
                      primary: true,
                      type: 'submit',
                      testId: 'create-btn',
                      isLoading: submitted
                    }
                  ]}
                  testId="two-buttons"
                />
              </Box>
            </>
          </CCSForm>
        </Box>
      }
      onClose={() => setShowCreateCustomerModal(false)}
    />
  )
}

AddTenantModal.propTypes = {
  setNotification: PropTypes.func.isRequired,
  setShowCreateCustomerModal: PropTypes.func.isRequired,
  updateCustomerAccounts: PropTypes.func
}
AddTenantModal.defaultProps = {
  updateCustomerAccounts: () => {}
}

export default AddTenantModal
