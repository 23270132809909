import { Box, NameValueList, NameValuePair, ResponsiveContext } from 'grommet'
import PropTypes from 'prop-types'
import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../../components'
import { displayNotification } from '../../../../../utils/notificiation-utils'
import EditRoleDetailsSidePanel from '../../common-components/EditRoleDetailsSidePanel'

const DetailsTab = ({
  roleDetails,
  showEditDetailPanel,
  setShowEditDetailPanel
}) => {
  const [notificationStatus, setNotificationStatus] = useState(false)
  const { t } = useTranslation(['iam', 'common', 'authz'])
  const size = useContext(ResponsiveContext)

  return (
    <Box width="large" pad={{ left: 'xxsmall' }}>
      <Box
        direction="row"
        justify="between"
        margin={{ vertical: 'medium', top: 'large' }}
      >
        <Typography type="heading" level="2" testId="role-details-page-title">
          {t('iam:roles_details.details_title')}
        </Typography>
      </Box>
      <Box direction="row-responsive">
        <NameValueList
          pairProps={{ direction: size === 'xsmall' ? 'column' : 'row' }}
          valueProps={{ width: 'auto', align: 'start' }}
          data-testid="role-details-list"
        >
          {Object?.entries(roleDetails)?.map((datum) =>
            datum[0] !== t('iam:roles_details.role_id') &&
            datum[0] !== t('iam:roles_details.kind') &&
            datum[0] !== 'name' &&
            datum[0] !== 'permissions' &&
            datum[1] !== undefined ? (
              <NameValuePair
                name={
                  <Typography
                    type="text"
                    emphasis
                    testId="role-details-object-key"
                  >
                    {datum[0]}
                  </Typography>
                }
                key={datum[0]}
              >
                <Typography type="text" testId="role-details-object-value">
                  {datum[1]?.length ? datum[1] : '--'}
                </Typography>
              </NameValuePair>
            ) : null
          )}
        </NameValueList>
      </Box>
      {showEditDetailPanel && (
        <EditRoleDetailsSidePanel
          onSetOpen={setShowEditDetailPanel}
          roleDetailsData={roleDetails}
          setStatus={setNotificationStatus}
        />
      )}
      {notificationStatus &&
        displayNotification(
          notificationStatus?.message,
          notificationStatus?.severity,
          setNotificationStatus
        )}
    </Box>
  )
}

DetailsTab.propTypes = {
  /**
   * Data from the role API call
   */
  roleDetails: PropTypes.object.isRequired,
  /**
   * Value that show the edit role details modal
   */
  showEditDetailPanel: PropTypes.bool.isRequired,
  /**
   * Callback to set the show modal back to caller.
   */
  setShowEditDetailPanel: PropTypes.func.isRequired
}

export { DetailsTab }
