import { Trans } from 'react-i18next'
import { Text } from 'grommet'

import { isMSP } from '../../../utils/feature-flag-utils'

const getNameAndType = (scopeResource, scopesData) => {
  let name = ''
  const scopeInstance = scopesData?.find(
    (scopeData) =>
      scopeData?.bundledData?.application_instance_id ===
      scopeResource?.application_instance_id
  )
  const matchedChild = scopeInstance?.bundledData?.children?.find((child) => {
    const foundPf = child?.predefined_filters?.find((pf) => {
      return pf.slug === scopeResource.slug
    })
    if (foundPf) {
      name = foundPf?.name
      return true
    }
    const foundSri = child?.scope_resource_instances?.find((sri) => {
      return sri.slug === scopeResource.slug
    })
    if (foundSri) {
      name = foundSri?.name
      return true
    }
    return false
  })
  const matchedType = scopeInstance?.bundledData?.scope_resources?.find(
    (sr) => sr?.slug === matchedChild?.scope
  )
  if (name === '') {
    return {}
  }
  return {
    name,
    scope_type_name: matchedType?.name || '',
    scope_type_slug: matchedType?.slug,
    type: matchedType?.slug
  }
}

const getUpdatedScopeMapFromScopesResponse = (
  onModalScopesMap,
  scopeResources,
  scopeTypeSlug,
  scopeTypeName
) => {
  onModalScopesMap.forEach((value, key) => {
    const slug = key.split('~')[1]
    if (
      !value?.name ||
      value?.name === 'generated_via_migration' ||
      value?.name === slug ||
      !value?.scope_type_name ||
      value?.scope_type_name === ''
    ) {
      const matchedScope = scopeResources.find((val) => val.slug === slug)
      if (matchedScope)
        onModalScopesMap.set(key, {
          ...value,
          ...{
            name: matchedScope?.name,
            scope_type_slug: scopeTypeSlug,
            scope_type_name: scopeTypeName,
            type: scopeTypeSlug
          }
        })
    }
  })
  return onModalScopesMap
}

const getSelectedScopeMap = (scopeResources = [], scopesData) => {
  const scopeMap = new Map()
  scopeResources.forEach((scopeResource) => {
    let foundScopeItem = {}
    const { application_instance_id: applicationInstanceId, slug } =
      scopeResource
    if (
      !scopeResource.name ||
      scopeResource?.name === 'generated_via_migration' ||
      scopeResource?.name === slug ||
      !scopeResource?.scope_type_name ||
      scopeResource?.scope_type_name === '' ||
      (scopeResource?.name === '' && scopesData)
    ) {
      foundScopeItem = getNameAndType(scopeResource, scopesData)
    }
    const key = `${applicationInstanceId}~${slug}`
    scopeMap.set(key, { ...scopeResource, ...foundScopeItem })
  })
  return scopeMap
}

const getRoleAssignmentSuccessText = (roleName, userName, t) => {
  return (
    // Text wrapper necessary for emphasized text to appear inline
    // with default text
    <Text>
      <Trans
        i18nKey="authz:assignments.role_assignment_success_notification_text"
        t={t}
        values={{
          roleName,
          userName
        }}
        components={[<Text weight={500} color="text-strong" />]}
      />
    </Text>
  )
}

const getEditRRPSuccessText = (appName, t) => {
  return (
    <Trans
      i18nKey="authz:rrp.edit_rrp_success_notification_text"
      t={t}
      values={{
        appName
      }}
      components={[<strong />]}
    />
  )
}

// this will return updateAction Type, based on existingRole and selectedRole for assignRoleModal
const getRoleUpdateAction = (
  filteredPrevRolesByApp,
  filteredPrevRolesByRoleSlug,
  selectedRoleDetails,
  ccsApplicationId,
  inCCSmanager
) => {
  // MSP case
  if (isMSP()) {
    let hasSameRole = false
    let hasMSP = false
    let hasTanent = false
    let hasMSPForDiffRole = false
    let hasTanentForDiffRole = false
    filteredPrevRolesByApp.forEach((item) => {
      if (item?.role_name === selectedRoleDetails?.role_name) {
        hasSameRole = true
        if (item?.access_rules?.msp) {
          hasMSP = true
        }
        if (item?.access_rules?.tenants?.length) {
          hasTanent = true
        }
      } else {
        if (item?.access_rules?.msp) {
          hasMSPForDiffRole = true
        }
        if (item?.access_rules?.tenants?.length) {
          hasTanentForDiffRole = true
        }
      }
    })
    let action = 'ADD'
    if (selectedRoleDetails.support_one_role_only) {
      if (
        !isMSP() ||
        (hasTanent && hasMSP) ||
        (hasMSP &&
          selectedRoleDetails?.access_rules?.msp &&
          hasTanent &&
          selectedRoleDetails?.access_rules?.tenants?.length) ||
        (hasMSP && selectedRoleDetails?.access_rules?.msp)
      ) {
        action = 'OVERWRITE'
      } else if (
        hasSameRole &&
        ((hasMSP && selectedRoleDetails?.access_rules?.tenants?.length) ||
          (hasTanent && selectedRoleDetails?.access_rules?.msp))
      ) {
        action = 'MERGE'
      } else if (
        !hasSameRole &&
        ((hasMSPForDiffRole && selectedRoleDetails?.access_rules?.msp) ||
          (hasTanentForDiffRole &&
            selectedRoleDetails?.access_rules?.tenants?.length))
      ) {
        action = 'MERGE_OVERWRITE'
      }
    } else if (selectedRoleDetails.application_id === ccsApplicationId) {
      // ccs appid - MSP
      if (isMSP()) {
        if (filteredPrevRolesByRoleSlug.length) action = 'SWAP_ROLE'
      }
    } else if (filteredPrevRolesByRoleSlug.length) action = 'SWAP_ROLE'
    return action
  }
  // non-MSP case
  if (selectedRoleDetails.support_one_role_only) {
    if (filteredPrevRolesByApp.length) return 'OVERWRITE'
    return 'ADD'
  }
  if (
    !inCCSmanager &&
    selectedRoleDetails.application_id === ccsApplicationId
  ) {
    return 'ADD'
  }
  if (filteredPrevRolesByRoleSlug.length) return 'SWAP_ROLE' // RRP got updated
  return 'ADD'
}

const mapRoleAssignments = (roleArray, scopeArray, roleAssignmentList) => {
  const roleAssignments = []
  roleArray?.forEach((role) => {
    scopeArray?.forEach((scope) => {
      if (
        !roleAssignmentList?.find(
          (assignment) =>
            assignment.role_id === role.role_id && assignment.scope === scope
        )
      ) {
        const primaryKey = role?.role_id?.concat(scope)
        roleAssignments?.push({
          primaryKey,
          role_id: role?.role_id,
          role_name: role?.role_name,
          role_display_name: role?.role_display_name,
          role_grn: role?.role_grn,
          service_id: role?.service_id,
          managedBy: role?.managedBy,
          scope
        })
      }
    })
  })
  return roleAssignments
}

const replaceEndWith = (original, target, replacement) => {
  if (!original) return ''
  const lastIndex = original.lastIndexOf(target)
  return lastIndex === -1
    ? original
    : original.substring(0, lastIndex) + replacement
}

const getResourceTypeName = (resType) => {
  // name attribute from UID, maps to BE displayName or '' if no display name was provided
  // display name is currently optional; use slug if not provided
  return resType?.name || replaceEndWith(resType?.slug, '/{uuid}', '')
}

const getAllInstancesOfResourceTypeSlug = (resourceType) => {
  return replaceEndWith(resourceType?.value?.slug, '{uuid}', '*')
}

const filterScopelessResourceTypes = (resourceTypes) => {
  const resTypeOptions = []
  resourceTypes?.forEach((resType) => {
    // filter scopeless resource types, ie ones which don't end with '{uuid}'
    if (resType?.slug?.endsWith('{uuid}'))
      resTypeOptions.push({
        label: getResourceTypeName(resType),
        value: resType
      })
  })
  return resTypeOptions
}

const allScopesRRPName = 'AllScopes' // TODO: AllScopes is the suggested name, we need to change the string if the name of RRP is changed
const mspDefaultRRPId = '11111111-1111-1111-1111-111111111111'
const nonScopedDefaultRRPId = '00000000-0000-0000-0000-000000000002'

export {
  getSelectedScopeMap,
  allScopesRRPName,
  getUpdatedScopeMapFromScopesResponse,
  mspDefaultRRPId,
  nonScopedDefaultRRPId,
  getRoleAssignmentSuccessText,
  getEditRRPSuccessText,
  getRoleUpdateAction,
  mapRoleAssignments,
  getResourceTypeName,
  filterScopelessResourceTypes,
  getAllInstancesOfResourceTypeSlug
}
