import { PropTypes } from 'prop-types'
import { Box, Text } from 'grommet'
import { StatusWarningSmall } from 'grommet-icons'

const NonGLPUserWarning = ({ t }) => {
  /*  TO DO: Use grommet color constant for background */
  return (
    <Box direction="row" background="rgba(255, 188, 68, 0.24)">
      <Box pad={{ top: 'xxsmall', right: 'xsmall' }}>
        <StatusWarningSmall size="small" color="status-warning" />
      </Box>
      <Text size="small" data-testid="non-glp-user-warning">
        {t('common:non_GLP_user_warning')}
      </Text>
    </Box>
  )
}

NonGLPUserWarning.propTypes = {
  t: PropTypes.func.isRequired
}
export { NonGLPUserWarning }
