import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  CardBody,
  Grid,
  Heading,
  Tag,
  ThemeContext
} from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ShareRounded, Apps, AppsRounded, List } from 'grommet-icons'
import { useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { isGLOP, isMSP } from '../../../utils/feature-flag-utils'
import { DataTable, Typography } from '../../../components'
import {
  useNewServiceRedirect,
  useRecentServices
} from '../../../dashboard/hooks'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { isServiceEnabled } from '../../../dashboard/utils'
import { INTERNAL_REDIRECT_SERVICES } from '../../../dashboard/constants'

const ServiceLaunchButton = ({ serviceData, serviceRedirect, t }) => {
  const [, setRecentServices] = useRecentServices()
  const { workspace_types: workspaceTypes = [] } = serviceData
  const isTenantOnlySupported =
    workspaceTypes?.includes('TENANT') && !workspaceTypes?.includes('MSP')
  return isMSP() && isTenantOnlySupported ? (
    <span>
      <Tag
        value={t('dashboard:service_details.enabled_for_customer_workspaces')}
        align="center"
        size="small"
        border={{ size: 'small', color: 'brand' }}
        color="background"
        style={{
          whiteSpace: 'nowrap'
        }}
      />
    </span>
  ) : (
    <Button
      secondary
      label={t('common.launch')}
      a11yTitle={`${t('common.launch')} ${serviceData.name}, ${
        serviceData.region
      }`}
      href={serviceData.static_launch_url || null}
      icon={
        !INTERNAL_REDIRECT_SERVICES.includes(serviceData.slug) &&
        serviceData.static_launch_url ? (
          <ShareRounded />
        ) : (
          ''
        )
      }
      reverse
      onClick={() => {
        if (serviceData.static_launch_url) {
          setRecentServices(serviceData.slug)
        } else {
          serviceRedirect(
            serviceData.service_provision || serviceData.application_provision,
            serviceData.slug
          )
        }
      }}
      data-testid={`service-${serviceData.slug}-launch-button`}
    />
  )
}

const NewTogglableMyServices = ({ myServicesData, selectedRegion }) => {
  const [servicesToDisplay, setServicesToDisplay] = useState([])
  const [renderState, setRenderState] = useState('grid')
  const { t } = useTranslation(['dashboard', 'services'])
  const history = useHistory()
  const serviceRedirect = useNewServiceRedirect()
  const {
    global: { colors }
  } = React.useContext(ThemeContext)
  const LDFlags = useFlags()

  useEffect(() => {
    if (selectedRegion !== 'all') {
      const filteredRegion = myServicesData?.filter(
        ({ region }) => region === selectedRegion
      )
      setServicesToDisplay(filteredRegion)
    } else {
      setServicesToDisplay(myServicesData)
    }
  }, [myServicesData, selectedRegion])

  return (
    <Box>
      {servicesToDisplay?.length === 0 && (
        <Box>
          <Box
            direction="column"
            align="center"
            width="50%"
            margin={{ horizontal: 'auto' }}
          >
            <AppsRounded size="large" color={colors.green.dark} />
            <Box pad={{ top: 'small', bottom: 'medium' }}>
              <Typography type="text" size="large" textAlign="center">
                {t('my_services.empty')}
              </Typography>
            </Box>
            <Button
              label={t('my_services.view_catalog')}
              primary
              onClick={() => history.push('/services/service-catalog')}
            />
          </Box>
        </Box>
      )}
      {servicesToDisplay?.length > 0 && (
        <Box>
          {renderState === 'grid' && (
            <Box margin={{ vertical: '3px' }} pad={{ horizontal: '3px' }}>
              {servicesToDisplay.map((item, i, appEntries) => {
                const { region, provisions } = item
                return (
                  <Box
                    direction="column"
                    border={{
                      color: 'border-weak',
                      size: 'xsmall',
                      style: appEntries.length - 1 === i ? 'hidden' : 'solid',
                      side: 'bottom'
                    }}
                    key={region}
                    pad={{ top: i > 0 ? 'small' : 'none', bottom: 'medium' }}
                    margin={{ bottom: 'medium' }}
                  >
                    <Box
                      direction="row"
                      justify="between"
                      align="center"
                      margin={{ bottom: 'small' }}
                    >
                      <VisibilityWrapper hideFor={{ deployment: ['GLOP'] }}>
                        <Heading level={3} margin="none">
                          {region}
                        </Heading>
                      </VisibilityWrapper>
                      {i === 0 && (
                        <Box direction="row" justify="end">
                          <Button
                            onClick={() => {
                              setRenderState('table')
                            }}
                            tip={t('dashboard:common.switch_to_list_view')}
                            icon={<List />}
                          />
                        </Box>
                      )}
                    </Box>
                    <Grid
                      columns={{
                        count: 'fill',
                        size: ['medium', 'flex']
                      }}
                      gap="small"
                      pad={{ bottom: 'small' }}
                    >
                      {provisions
                        .filter(({ slug }) => isServiceEnabled(slug, LDFlags))
                        .map((service) => (
                          <Card
                            key={region + service.name}
                            title={service.name}
                          >
                            <CardBody
                              pad="medium"
                              direction="row"
                              align="center"
                              justify="between"
                            >
                              <Box>
                                <Heading
                                  level={4}
                                  margin="none"
                                  data-testid={`service-${service.slug}-title`}
                                >
                                  {service.name}
                                </Heading>
                                <Typography
                                  type="text"
                                  testId="service-category"
                                >
                                  {t(
                                    `dashboard:common.category.${service.categories?.at(
                                      0
                                    )}`
                                  )}
                                </Typography>
                              </Box>
                              {myServicesData.length > 0 && (
                                <ServiceLaunchButton
                                  serviceData={service}
                                  serviceRedirect={serviceRedirect}
                                  t={t}
                                />
                              )}
                            </CardBody>
                          </Card>
                        ))}
                    </Grid>
                  </Box>
                )
              })}
            </Box>
          )}
          {renderState === 'table' && (
            <Box>
              <DataTable
                grid={{
                  columns: [
                    {
                      header: 'Name',
                      property: 'title',
                      render: ({ title }) => (
                        <Typography
                          type="text"
                          testId="toggle-table-title"
                          emphasis
                        >
                          {title}
                        </Typography>
                      ),
                      sortable: true
                    },
                    ...(isGLOP()
                      ? []
                      : [
                          {
                            header: 'Region',
                            property: 'region',
                            sortable: true
                          }
                        ]),
                    {
                      header: 'Type',
                      property: 'type',
                      sortable: true
                    },
                    {
                      header: (
                        <Box direction="row" justify="end">
                          <Button
                            tip={t('dashboard:common.switch_to_grid_view')}
                            onClick={() => {
                              setRenderState('grid')
                            }}
                            icon={<Apps />}
                          />
                        </Box>
                      ),
                      property: 'launchButton',
                      sortable: false,
                      render: ({ service }) => (
                        <Box direction="row" justify="end">
                          {myServicesData.length > 0 && (
                            <ServiceLaunchButton
                              serviceData={service}
                              serviceRedirect={serviceRedirect}
                              t={t}
                            />
                          )}
                        </Box>
                      )
                    }
                  ],
                  data: (() => {
                    const sorted = servicesToDisplay.flatMap((item) => {
                      const { region, provisions } = item
                      return provisions
                        .filter(({ slug }) => isServiceEnabled(slug, LDFlags))
                        .map((service) => {
                          const { name, categories } = service
                          const regionData = region
                          return {
                            service,
                            name,
                            id: regionData + name,
                            title: name,
                            region: regionData,
                            type: t(
                              `dashboard:common.category.${categories?.at(0)}`
                            )
                          }
                        })
                    })
                    return sorted.sort((a, b) => {
                      if (a.title < b.title) {
                        return -1
                      }

                      if (a.title > b.title) {
                        return 1
                      }

                      return 0
                    })
                  })(),
                  primaryKey: 'id',
                  verticalAlign: 'middle',
                  border: {
                    header: {
                      color: 'transparent',
                      side: 'bottom',
                      size: 'small'
                    },
                    body: {
                      color: 'border-weak',
                      side: 'bottom',
                      size: 'xsmall'
                    }
                  },
                  pad: {
                    header: { bottom: 'medium' },
                    body: { horizontal: 'none', vertical: 'medium' }
                  }
                }}
                testId="my-services-data-table"
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

ServiceLaunchButton.propTypes = {
  serviceData: PropTypes.object.isRequired,
  serviceRedirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

NewTogglableMyServices.propTypes = {
  myServicesData: PropTypes.array.isRequired,
  selectedRegion: PropTypes.string.isRequired
}

export default NewTogglableMyServices
