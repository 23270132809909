import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { createIntl, createIntlCache } from '@formatjs/intl'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { get, getErrorMessage } from '../../../../../utils/api-utils'
import { Typography, Loader } from '../../../../../components'
import { dateRangeMap } from '../advancedFiltersUtil'

import { getSkipInterval } from './utils'
import EmptyStateChart from './EmptyStateChart'
import CustomTooltip from './CustomTooltip'

const TrendChart = ({ filterOptions }) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['auditlogs', 'common'])
  const [loader, hideLoader] = useState(false)
  const [selectedTimeRange, setSelectedTimeRange] = useState(
    filterOptions?.interval || '90d'
  )

  dayjs.extend(utc)
  const cache = createIntlCache()
  const intl = createIntl(
    {
      locale: 'en-US',
      messages: {}
    },
    cache
  )

  const [chartData, setChartData] = useState(null)

  const gridLinesColor = '#BBBBBB'
  const tickColor = '#444444'

  const fetchTrendCharts = useCallback(async () => {
    try {
      hideLoader(true)
      if (filterOptions?.interval) {
        setSelectedTimeRange(filterOptions?.interval)
      }

      const url = `/support-assistant/v1alpha1/auditlogs-search-graphs`
      const resp = await get(url, filterOptions, oidcUser.access_token)
      if (resp?.status === 200) {
        if (resp?.data?.data?.length) {
          setChartData(resp?.data?.data[0]?.audit_data)
        } else {
          setChartData(null)
        }
        hideLoader(false)
      }
    } catch (error) {
      hideLoader(false)
      const backendErrorMessage = getErrorMessage(error, t)
      console.error(backendErrorMessage)
    }
  }, [oidcUser.access_token, t, filterOptions])

  useEffect(() => {
    fetchTrendCharts()
  }, [fetchTrendCharts])

  const tickFormatterY = (value) => {
    return intl.formatNumber(value, {
      notation: 'compact',
      compactDisplay: 'short',
      maximumSignificantDigits: 4,
      maximumFractionDigits: 3,
      roundingIncrement: 5
    })
  }

  const getSkipIntervalCount = (count, n = 10) => {
    let intervalCount = 1
    if (count / n <= 10) {
      intervalCount = Math.round(count / n)
    } else {
      for (let j = 10; j < count; j += 10) {
        if (Math.round(count / j) <= 10) {
          intervalCount = j
          break
        }
      }
    }
    return intervalCount
  }

  return (
    <Box
      direction="column"
      pad={{ top: 'small', bottom: 'small' }}
      width={{ width: '100%', max: 'xxlarge' }}
      justify="between"
      data-testid="trend-chart"
    >
      {chartData && chartData?.actuals?.length !== 0 ? (
        <Box>
          <Box>
            {chartData?.subtitle && (
              <Box direction="row" align="center" margin={{ bottom: 'medium' }}>
                <Box
                  round="100%"
                  pad="xsmall"
                  background="#FF8300"
                  margin={{ right: 'xsmall' }}
                />
                <Box>
                  <Typography type="text" size="xsmall" testId="chart-legend">
                    {chartData?.subtitle}
                  </Typography>
                </Box>
              </Box>
            )}
            <Box width="100%" height={{ min: '300px' }}>
              <ResponsiveContainer>
                <AreaChart
                  width="100%"
                  height="100%"
                  data={chartData?.actuals}
                  margin={{
                    top: 10,
                    right: 30,
                    left: chartData?.conversion ? 15 : 0,
                    bottom: 30
                  }}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="timestamp"
                    tickLine={false}
                    stroke={gridLinesColor}
                    tick={{ fill: tickColor, fontSize: 14 }}
                    tickFormatter={(tick) => {
                      return dayjs
                        .utc(tick)
                        .local()
                        .format(dateRangeMap[selectedTimeRange]?.tickFormat)
                    }}
                    tickMargin={10}
                    /*  interval={
                chartData?.actuals?.length <= 10
                  ? 0
                  : dateRangeMap[selectedTimeRange]?.skipInterval
              } */
                    interval={
                      chartData?.actuals?.length &&
                      getSkipInterval(
                        chartData?.actuals?.length,
                        getSkipIntervalCount(chartData?.actuals?.length),
                        chartData?.actuals?.length
                      )
                    }
                    /* tickCount={dateRangeMap[selectedTimeRange]?.tickCountX} */
                  />

                  <YAxis
                    dataKey="size"
                    type="number"
                    strokeDasharray="4"
                    stroke={gridLinesColor}
                    tickLine={false}
                    tick={{ fill: tickColor, fontSize: 14 }}
                    tickFormatter={(value) => tickFormatterY(value)}
                    tickMargin={10}
                    tickCount={6}
                  />
                  <Tooltip
                    content={
                      <CustomTooltip
                        yAxisUnit={chartData?.y_axis_unit}
                        tooltipText={chartData.tooltip_text}
                        toolTipFormat={
                          dateRangeMap[selectedTimeRange]?.toolTipFormat
                        }
                      />
                    }
                  />
                  <Area
                    dataKey="size"
                    stroke="#FF8300"
                    fill="#FF8300"
                    fillOpacity={0.6}
                    strokeWidth={3}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box align="center" justify="center">
          {loader ? (
            <Box justify="center">
              <Loader testId="loader-spinner" />
            </Box>
          ) : (
            <EmptyStateChart emptyStateMsg="No data available" />
          )}
        </Box>
      )}
    </Box>
  )
}

TrendChart.propTypes = {
  filterOptions: PropTypes.object
}

TrendChart.defaultProps = {
  filterOptions: {}
}

export default React.memo(TrendChart)
