import React, { useContext, useState, useEffect } from 'react'
import { Box, FormField } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { FormInput, Dropdown } from '../../../../../components'
import { WizardContext } from '../../../../../components/wizard/WizardContext'

export const ValidateLocation = () => {
  const { t } = useTranslation(['location'])
  const { formValues, setFormValues } = useContext(WizardContext)
  const { oidcUser } = useReactOidc()

  const [validationCycle, setValidationCycle] = useState(
    formValues.validation_cycle || '6'
  )

  useEffect(() => {
    setFormValues({
      ...formValues,
      validation_cycle: validationCycle
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationCycle])

  return (
    <Box width="medium" pad={{ top: 'small', bottom: 'small' }}>
      <FormField label={t('validation_cycle_label')}>
        <Dropdown
          placeholder={t('select')}
          multiple={false}
          onChange={({ option }) => {
            setValidationCycle(option.value)
          }}
          value={validationCycle}
          options={[
            { label: `6 ${t('months')}`, value: '6' },
            {
              label: `12 ${t('months')}`,
              value: '12'
            },
            { label: `18 ${t('months')}`, value: '18' }
          ]}
          testId="validation-cycle-dropdown"
        />
      </FormField>
      <FormInput
        inputType="text"
        label={t('validated_by')}
        name="validated_by"
        testId="location-validated-by"
        disabled
        value={`${oidcUser.profile.given_name} ${oidcUser.profile.family_name}`}
      />
    </Box>
  )
}
