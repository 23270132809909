import React from 'react'
import { Box, Markdown } from 'grommet'
import PropTypes from 'prop-types'

import { Typography } from '../../../components'

const AddProductsTemplateHeader = ({ header, title, description }) => {
  return (
    <>
      <Box
        background="background-contrast"
        width="100%"
        align="center"
        pad={{ vertical: 'medium' }}
        margin={{ bottom: 'medium' }}
      >
        <Typography type="text" weight="bold" testId="page-header">
          {header}
        </Typography>
      </Box>

      <Box gap="xsmall" width="large">
        <Typography
          type="heading"
          level="1"
          testId="page-title"
          color="text-strong"
        >
          {title}
        </Typography>
        <Typography
          type="paragraph"
          testId="page-description"
          size="xlarge"
          margin={{ top: 'xsmall' }}
        >
          <Markdown>{description}</Markdown>
        </Typography>
      </Box>
    </>
  )
}

AddProductsTemplateHeader.propTypes = {
  header: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export { AddProductsTemplateHeader }
