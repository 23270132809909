import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import isEmpty from 'lodash/isEmpty'

import { WizardContext } from '../../../../../../../../components/wizard/WizardContext'
import { FormInput } from '../../../../../../../../components'
import { validateForm } from '../../../../../../../../utils/validation-utils'

import { validateBillingAccountFields } from './utils'

export const validateBillingAccountDetails = (formValues, i18nTranslate) => {
  const errorObj = validateBillingAccountFields(formValues, i18nTranslate)

  if (!isEmpty(errorObj)) {
    return Promise.reject(
      new Error(
        i18nTranslate(
          'billing_accounts.create_billing_account_wizard.missing_required_fields'
        )
      )
    )
  }

  return validateForm(formValues, i18nTranslate)
}

const BillingAccountDetails = () => {
  const { t } = useTranslation(['manage'])

  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)
  const [formError, setFormError] = useState({})

  const updateBillingAccountDetails = (key, value) => {
    setFormValues({
      ...formValues,
      [key]: value
    })
  }

  useEffect(() => {
    if (attemptedAdvance) {
      const errorObj = validateBillingAccountFields(formValues, t)
      setFormError(errorObj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, attemptedAdvance, t])
  return (
    <Box
      width="medium"
      justify="between"
      align="start"
      margin={{ bottom: 'medium' }}
    >
      <Box direction="column" gap="small" width="100%">
        <Box width="100%">
          <FormInput
            testId="billing-account-name"
            label={t(
              'billing_accounts.create_billing_account_wizard.billingAccountName'
            )}
            inputType="text"
            value={formValues.billingAccountName || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.billing_account_name_placeholder'
            )}
            error={formError.name}
            onChange={(event) => {
              updateBillingAccountDetails(
                'billingAccountName',
                event.target.value
              )
            }}
            required
          />
        </Box>

        <Box width="100%">
          <FormInput
            testId="billing-account-description"
            label={t(
              'billing_accounts.create_billing_account_wizard.billingAccountDescription'
            )}
            inputType="text"
            value={formValues.billingAccountDescription || ''}
            placeholder={t(
              'billing_accounts.create_billing_account_wizard.billing_account_description_placeholder'
            )}
            error={formError.description}
            onChange={(event) => {
              updateBillingAccountDetails(
                'billingAccountDescription',
                event.target.value
              )
            }}
            required
          />
        </Box>
      </Box>
    </Box>
  )
}

export default BillingAccountDetails
